import { FC } from "react";
import useDocumentTitle from "hooks/useDocumentTitle";
import { madSDK } from "lib/sdk";

export const LoginLogo: FC = () => {
  /**
   * Set document title to base app name.
   */
  useDocumentTitle();

  const { hostname } = window.location;
  return (
    <img
      className="logo"
      src={`${madSDK.urls.burnsBaseUrl}/public/images/app-logo.svg`}
      alt={`Logo for ${hostname}`}
    />
  );
};

import { RootState } from "rootReducer";

export const selectCampaignsDashboard = (state: RootState) =>
  state.campaigns.dashboard;

export const selectSearchTerm = (state: RootState) =>
  state.campaigns.dashboard.searchTerm;

export const selectCampaignSelectableValues = (state: RootState) =>
  state.campaigns.dashboard.campaignFilterSelectableValues;

export const selectCampaignSelectableValuesCancellationSource = (
  state: RootState
) =>
  state.campaigns.dashboard.campaignFilterSelectableValues.cancellationSource;

import {
  init,
  event as logEvent,
  identify,
  anonymize
} from "@fullstory/browser";
import { User as MadSDKUser } from "@madhive/mad-sdk";
import type { Event, IListener, IUser } from "@madhive/mad-tracker";

import { DATE_OF_CURRENT_RELEASE } from "lib/constants/config";
import { prettyPrintDate } from "lib/utils/date";

export class FullStory implements IListener<MadSDKUser> {
  /** Full story Organization ID is how full story ties to our specific setup. */
  static orgId = "M3A31";

  constructor() {
    init({
      orgId: FullStory.orgId
    });
  }

  user = {
    set: (user: IUser<MadSDKUser>) => {
      const { name, email } = user.traits || {};

      identify(user.id, {
        displayName: name || email,
        email,
        versionReleaseDate: prettyPrintDate(DATE_OF_CURRENT_RELEASE)
      });
    },
    reset: () => {
      anonymize();
    }
  };

  track = ({
    event,
    properties
  }: {
    event: Event;
    properties?: Record<string, unknown>;
  }) => {
    logEvent(event, properties || {});
  };
}

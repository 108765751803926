import axios from "axios";
import { Uploader } from "../handlers";
import { UploadSaveFailed } from "../handlers/errors";
class Uploads extends Uploader {
    constructor(madSDK) {
        super(madSDK);
        this.baseUrl = this.sdk.urls.baseAPIUrl;
    }
    async saveUpload(file) {
        const blob = new Blob([file], {
            type: "application/json; charset=utf-8;"
        });
        try {
            await axios.post(`${this.baseUrl}/template/campaignUpload`, blob, {
                headers: {
                    Accept: "application/json"
                }
            });
        }
        catch (err) {
            throw new UploadSaveFailed(err, "Failed to create campaign(s) from template.");
        }
    }
}
export default Uploads;

import { IconName } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  LinearDimensionList,
  OTTDimensionList,
  DayOfTheWeek
} from "@madhive/mad-sdk";

export * from "./dimensions";

export const MODIFIED_START_DATE = "Modified Start Date";
export const MODIFIED_END_DATE = "Modified End Date";

export const REQUIRED_ERROR_MESSAGE = "Required fields are missing";
export const START_DATE_OVER_END_DATE_ERROR_MESSAGE =
  "Start date must be before end date";
export const OVERLAPPING_2023_2024_DATES_ERROR =
  "Reports cannot span across year 2023 to 2024";
export const LOOKBACK_WINDOW_ERROR_MESSAGE =
  "Lookback window must be between 1 and 30 days";

// Report page tab IDs
export enum MediaTypeLabel {
  OTT = "OTT",
  LINEAR = "Linear",
  DISPLAY = "Display"
}

export const CHRONOLOGICAL_DIMENSIONS = [
  OTTDimensionList.DAILY,
  OTTDimensionList.MONTHLY,
  OTTDimensionList.WEEKLY,
  OTTDimensionList.DAY_OF_THE_WEEK,
  OTTDimensionList.DAYPART,
  OTTDimensionList.FREQUENCY,
  LinearDimensionList.DAILY,
  LinearDimensionList.MONTHLY,
  LinearDimensionList.WEEKLY,
  LinearDimensionList.HOURLY,
  LinearDimensionList.DAY_OF_THE_WEEK,
  LinearDimensionList.DAYPART,
  LinearDimensionList.FREQUENCY
];

// Dimensions used to populate charts but not displayed directly for user's selection on creation, views, filters and exports
export const HIDDEN_DIMENSIONS = {
  FREQUENCY: "frequency",
  DAYS_TO_CONVERSION: "daystoconversion"
};

export const GEOTYPE = "geoType";

export enum GeoTypeNames {
  DMA = "DMA",
  COUNTRY = "Country"
}

export const GEOTYPE_OPTIONS = [
  { id: GeoTypeNames.DMA, name: GeoTypeNames.DMA },
  { id: GeoTypeNames.COUNTRY, name: GeoTypeNames.COUNTRY }
];

/**
 * This enum represent the set of id of the main sections in the CustomReportingDetailsView
 * They are used
 */
export enum Sections {
  OVERLAP_CHART = "OverlapChart",
  CONVERSION_BY_RATE_CHART = "ConversionByRateChart",
  DISTRIBUTION_CHART = "DistributionChart",
  FREQUENCY_CHART = "FrequencyChart",
  EFFECTIVE_FREQUENCY_CHART = "EffectiveFrequencyChart",
  PERFORMANCE_CHART = "PerformanceChart",
  CONVERSION_CHART = "ConversionChart",
  DEVICE_DISTRIBUTION_CHART = "DeviceDistributionChart",
  DAYPART_CHART = "DayPartChart",
  HHREACH_CHART = "HHReachChart",
  DAYS_TO_CONVERSION_CHART = "DaysToConversion"
}

/**
 * This enum represents the set of table's ids that are exportable via PPTX in the CustomReportingDetailsView
 */
export enum ExportableTables {
  METRICS = "deduplicated-metrics"
}

export const TitleBySections = [
  {
    id: Sections.OVERLAP_CHART,
    name: "OTT & Linear Overlap"
  },
  {
    id: Sections.CONVERSION_BY_RATE_CHART,
    name: "Conversion Rate By Platform"
  },
  {
    id: Sections.DISTRIBUTION_CHART,
    name: "Conversion Distribution"
  },
  {
    id: Sections.FREQUENCY_CHART,
    name: "Avg. Frequency"
  },
  {
    id: Sections.EFFECTIVE_FREQUENCY_CHART,
    name: "Effective Frequency"
  },
  {
    id: Sections.PERFORMANCE_CHART,
    name: "Performance Chart"
  },
  {
    id: Sections.CONVERSION_CHART,
    name: "Conversion Chart"
  },
  {
    id: Sections.DEVICE_DISTRIBUTION_CHART,
    name: "Device Distribution"
  },
  {
    id: Sections.DAYPART_CHART,
    name: "DayPart"
  },
  {
    id: Sections.HHREACH_CHART,
    name: "Reach"
  }
];

/**
 *  Charts
 */

export const CHART_HEIGHT = 450;
export const X_AXIS_LABEL = "Frequency";
export const CHART_UNIT = "%";

export enum AllMetrics {
  CONVERSION_DISTRIBUTION = "Conversion Distribution %",
  IMPRESSION_DISTRIBUTION = "Impression Distribution %",
  CONVERSION_INDEX = "Conversion Index",
  OTT_ONLY = "OTT Only",
  OTT = "OTT",
  LINEAR_ONLY = "Linear Only",
  LINEAR = "Linear",
  OTT_AND_LINEAR_OVERLAP = "Linear & OTT Overlap",
  OVERLAP_HOUSEHOLDS = "Overlap Households",
  OTT_AND_LINEAR_HH_OVERLAP_PERCENT = "OTT & Linear Overlap %",
  CONVERSIONS = "Conversions",
  IMPRESSIONS = "Impressions",
  REACH = "Reach",
  REACH_DISTRIBUTION = "Reach Distribution",
  AVG_FREQUENCY = "Avg. Frequency",
  CONVERSION_RATE_BY_PLATFORM = "Conversion Rate by Platform",
  TOTAL_CONVERSION_DISTRIBUTION = "Conversion Distribution",
  CONVERSION_RATE = "Conversion Rate %",
  FREQUENCY = "Frequency",
  UNIQUE_CONVERSION_RATE = "Unique Conversion Rate %",
  UNIQUE_CONVERSIONS = "Unique Conversions",
  DEVICE_DISTRIBUTION = "Device Distribution",
  HH_REACH = "Reach",
  HH_REACH_DISTRIBUTION = "Reach Distribution %",
  UNIQUE_HH_CONVERSION_DISTRIBUTION = "Unique Conversion Distribution %"
}

export const MetricsToOTTFields = {
  [AllMetrics.CONVERSION_DISTRIBUTION]: "ott_conversion_distribution",
  [AllMetrics.IMPRESSION_DISTRIBUTION]: "ott_impression_distribution",
  [AllMetrics.CONVERSION_INDEX]: "ott_conversion_index",
  [AllMetrics.CONVERSION_RATE]: "ott_conversion_rate",
  [AllMetrics.CONVERSIONS]: "ott_conversions",
  [AllMetrics.FREQUENCY]: "ott_frequency",
  [AllMetrics.IMPRESSIONS]: "ott_impressions",
  [AllMetrics.REACH]: "ott_reach",
  [AllMetrics.REACH_DISTRIBUTION]: "ott_reach_distribution",
  [AllMetrics.UNIQUE_CONVERSION_RATE]: "ott_unique_conversion_rate",
  [AllMetrics.UNIQUE_CONVERSIONS]: "ott_unique_conversions",
  [AllMetrics.HH_REACH_DISTRIBUTION]: "ott_hh_reach_distribution",
  [AllMetrics.UNIQUE_HH_CONVERSION_DISTRIBUTION]:
    "ott_unique_hh_conversion_distribution"
};

export const MetricsToLinearFields = {
  [AllMetrics.CONVERSION_DISTRIBUTION]: "linear_conversion_distribution",
  [AllMetrics.IMPRESSION_DISTRIBUTION]: "linear_impression_distribution",
  [AllMetrics.CONVERSION_INDEX]: "linear_conversion_index",
  [AllMetrics.CONVERSION_RATE]: "linear_conversion_rate",
  [AllMetrics.CONVERSIONS]: "linear_conversions",
  [AllMetrics.FREQUENCY]: "linear_frequency",
  [AllMetrics.IMPRESSIONS]: "linear_impressions",
  [AllMetrics.REACH]: "linear_reach",
  [AllMetrics.REACH_DISTRIBUTION]: "linear_reach_distribution",
  [AllMetrics.UNIQUE_CONVERSION_RATE]: "linear_unique_conversion_rate",
  [AllMetrics.UNIQUE_CONVERSIONS]: "linear_unique_conversions",
  [AllMetrics.HH_REACH_DISTRIBUTION]: "linear_hh_reach_distribution",
  [AllMetrics.UNIQUE_HH_CONVERSION_DISTRIBUTION]:
    "linear_unique_hh_conversion_distribution"
};

export const OTT_COLOR = "var(--green)";
export const LINEAR_COLOR = "#0142F5";
export const LINEAR_OVERLAP_COLOR = "#DF456E";

export const COLORS_TO_LABEL = {
  [AllMetrics.OTT_ONLY]: OTT_COLOR,
  [AllMetrics.LINEAR_ONLY]: LINEAR_COLOR,
  [AllMetrics.OTT_AND_LINEAR_OVERLAP]: LINEAR_OVERLAP_COLOR
};

export enum DeviceType {
  CONNECTED_TV = "TV",
  MOBILE = "Mobile",
  DESKTOP = "Desktop",
  TABLET = "Tablet"
}

export const COLORS_TO_LABEL_DEVICE = {
  [DeviceType.CONNECTED_TV]: "#6DE070",
  [DeviceType.MOBILE]: "#0142F5",
  [DeviceType.DESKTOP]: "#DF456E",
  [DeviceType.TABLET]: "#FBE04F"
};

/**
 * TopLine Metrics
 */

export enum TopLineMetricsColumn {
  PLATFORM = "platform",
  UNIQUE_CONVERSION_RATE = "uniqueHHConversionsRate",
  IMPRESSIONS = "impressions",
  CAMPAIGN_REACH_SHARE = "campaignReachShare",
  UNIQUE_HH_REACH = "HHReach",
  AVG_FREQUENCY = "frequency"
}

export enum SummaryMetricsColumn {
  PLATFORM = "platform",
  UNIQUE_HH_CONVERSIONS = "uniqueHHConversions",
  UNIQUE_CONVERSION_RATE = "uniqueHHConversionsRate",
  IMPRESSIONS = "impressions",
  CAMPAIGN_REACH_SHARE = "campaignReachShare",
  UNIQUE_HH_REACH = "HHReach",
  AVG_FREQUENCY = "frequency",
  TOTAL_CONVERSION = "totalConversion",
  CONVERSION_RATE = "conversionRate",
  AVG_CONVERSION_FREQUENCY = "avgConversionFrequency",
  VCR = "VCR",
  COMPLETES = "completes"
}

export enum SummaryMetricsColumnLinear {
  PLATFORM = "platform",
  UNIQUE_HH_CONVERSIONS = "uniqueHHConversions",
  UNIQUE_CONVERSION_RATE = "uniqueHHConversionsRate",
  IMPRESSIONS = "impressions",
  CAMPAIGN_REACH_SHARE = "campaignReachShare",
  UNIQUE_HH_REACH = "HHReach",
  AVG_FREQUENCY = "frequency",
  TOTAL_CONVERSION = "totalConversion",
  CONVERSION_RATE = "conversionRate",
  AVG_CONVERSION_FREQUENCY = "avgConversionFrequency",
  REACH_PERCENT = "reachPercent"
}

export const TOP_LINE_METRICS_COLUMNS = [
  {
    id: TopLineMetricsColumn.PLATFORM,
    label: "Platform"
  },
  {
    id: TopLineMetricsColumn.CAMPAIGN_REACH_SHARE,
    label: "Campaign Reach Share %"
  },
  {
    id: TopLineMetricsColumn.IMPRESSIONS,
    label: "Impressions"
  },
  {
    id: TopLineMetricsColumn.UNIQUE_HH_REACH,
    label: "Unique Reach"
  },
  {
    id: TopLineMetricsColumn.AVG_FREQUENCY,
    label: "Avg. Frequency"
  }
];

export const TOP_LINE_METRICS_COLUMNS_WITH_CONVERSION_COLUMNS = [
  {
    id: TopLineMetricsColumn.PLATFORM,
    label: "Platform"
  },
  {
    id: TopLineMetricsColumn.CAMPAIGN_REACH_SHARE,
    label: "Campaign Reach Share %"
  },
  {
    id: TopLineMetricsColumn.IMPRESSIONS,
    label: "Impressions"
  },
  {
    id: TopLineMetricsColumn.UNIQUE_HH_REACH,
    label: "Unique Reach"
  },
  {
    id: TopLineMetricsColumn.AVG_FREQUENCY,
    label: "Avg. Frequency"
  },
  {
    id: TopLineMetricsColumn.UNIQUE_CONVERSION_RATE,
    label: "Unique Conversion Rate"
  }
];

export const SUMMARY_METRICS_COLUMNS = [
  {
    id: SummaryMetricsColumn.PLATFORM,
    label: "Platform"
  },
  {
    id: SummaryMetricsColumn.IMPRESSIONS,
    label: "Impressions"
  },
  {
    id: SummaryMetricsColumn.UNIQUE_HH_REACH,
    label: "Unique Reach"
  },
  {
    id: SummaryMetricsColumn.AVG_FREQUENCY,
    label: "Avg. Frequency"
  },
  {
    id: SummaryMetricsColumn.COMPLETES,
    label: "Completes"
  },
  {
    id: SummaryMetricsColumn.VCR,
    label: "VCR"
  }
];

export const SUMMARY_METRICS_WITH_CONVERSIONS_COLUMNS = [
  {
    id: SummaryMetricsColumn.PLATFORM,
    label: "Platform"
  },
  {
    id: SummaryMetricsColumn.IMPRESSIONS,
    label: "Impressions"
  },
  {
    id: SummaryMetricsColumn.UNIQUE_HH_REACH,
    label: "Unique Reach"
  },
  {
    id: SummaryMetricsColumn.AVG_FREQUENCY,
    label: "Avg. Frequency"
  },
  {
    id: SummaryMetricsColumn.TOTAL_CONVERSION,
    label: "Total Conversion"
  },
  {
    id: SummaryMetricsColumn.CONVERSION_RATE,
    label: "Conversion Rate"
  },
  {
    id: SummaryMetricsColumn.UNIQUE_HH_CONVERSIONS,
    label: "Unique Conversions"
  },
  {
    id: SummaryMetricsColumn.UNIQUE_CONVERSION_RATE,
    label: "Unique Conversion Rate"
  },
  {
    id: SummaryMetricsColumn.COMPLETES,
    label: "Completes"
  },
  {
    id: SummaryMetricsColumn.VCR,
    label: "VCR"
  }
];

export const SUMMARY_METRICS_WITH_CONVERSIONS_COLUMNS_LINEAR = [
  {
    id: SummaryMetricsColumnLinear.IMPRESSIONS,
    label: "Impressions"
  },
  {
    id: SummaryMetricsColumnLinear.UNIQUE_HH_REACH,
    label: "Reach"
  },
  {
    id: SummaryMetricsColumnLinear.AVG_FREQUENCY,
    label: "Avg. Frequency"
  },
  {
    id: SummaryMetricsColumnLinear.REACH_PERCENT,
    label: "Reach %"
  },
  {
    id: SummaryMetricsColumnLinear.TOTAL_CONVERSION,
    label: "Total Conversion"
  },
  {
    id: SummaryMetricsColumnLinear.CONVERSION_RATE,
    label: "Conversion Rate"
  },
  {
    id: SummaryMetricsColumnLinear.UNIQUE_HH_CONVERSIONS,
    label: "Unique Conversions"
  },
  {
    id: SummaryMetricsColumnLinear.UNIQUE_CONVERSION_RATE,
    label: "Unique Conversion Rate"
  }
];

export const SUMMARY_METRICS_WITH_CONVERSIONS_COLUMNS_LINEAR_NO_PIXEL = [
  {
    id: SummaryMetricsColumnLinear.IMPRESSIONS,
    label: "Impressions"
  },
  {
    id: SummaryMetricsColumnLinear.UNIQUE_HH_REACH,
    label: "Reach"
  },
  {
    id: SummaryMetricsColumnLinear.AVG_FREQUENCY,
    label: "Avg. Frequency"
  },
  {
    id: SummaryMetricsColumnLinear.REACH_PERCENT,
    label: "Reach %"
  }
];

export const metricsDropDown = [
  AllMetrics.CONVERSION_DISTRIBUTION,
  AllMetrics.IMPRESSION_DISTRIBUTION,
  AllMetrics.CONVERSION_INDEX,
  AllMetrics.CONVERSION_RATE,
  AllMetrics.CONVERSIONS,
  AllMetrics.FREQUENCY,
  AllMetrics.IMPRESSIONS,
  AllMetrics.REACH,
  AllMetrics.UNIQUE_CONVERSION_RATE,
  AllMetrics.UNIQUE_CONVERSIONS,
  AllMetrics.HH_REACH_DISTRIBUTION,
  AllMetrics.UNIQUE_HH_CONVERSION_DISTRIBUTION
];

export const metricsDropDownNoPixel = [
  AllMetrics.IMPRESSION_DISTRIBUTION,
  AllMetrics.FREQUENCY,
  AllMetrics.IMPRESSIONS,
  AllMetrics.REACH,
  AllMetrics.HH_REACH_DISTRIBUTION
];

export enum SortDirection {
  ASCENDING = "ascending",
  DESCENDING = "descending",
  CHRONOLOGICAL = "chronological"
}

export const sortDirectionLabels = {
  [SortDirection.ASCENDING]: "Ascending",
  [SortDirection.DESCENDING]: "Descending",
  [SortDirection.CHRONOLOGICAL]: "Chronological"
};

export const sortDirectionIcon = {
  [SortDirection.ASCENDING]: IconNames.SORT_ASC,
  [SortDirection.DESCENDING]: IconNames.SORT_DESC,
  [SortDirection.CHRONOLOGICAL]: IconNames.CALENDAR
} as Record<SortDirection, IconName>;

export const ALL_DEVICE_TYPES = [
  DeviceType.CONNECTED_TV,
  DeviceType.MOBILE,
  DeviceType.DESKTOP,
  DeviceType.TABLET
];

enum ExposureType {
  OTT_ONLY = "OTT Only",
  LINEAR_ONLY = "Linear Only",
  BOTH = "Both OTT & Linear"
}
export const PLATFORM_SORT_INDEX = {
  [ExposureType.OTT_ONLY]: 0,
  [ExposureType.LINEAR_ONLY]: 1,
  [ExposureType.BOTH]: 2
};
export const DAYS_MAP = {
  1: DayOfTheWeek.MONDAY,
  2: DayOfTheWeek.TUESDAY,
  3: DayOfTheWeek.WEDNESDAY,
  4: DayOfTheWeek.THURSDAY,
  5: DayOfTheWeek.FRIDAY,
  6: DayOfTheWeek.SATURDAY,
  7: DayOfTheWeek.SUNDAY
};

export const HOUR_SEGMENT_MAP = {
  0: "12am - 1am",
  1: "1am - 2am",
  2: "2am - 3am",
  3: "3am - 4am",
  4: "4am - 5am",
  5: "5am - 6am",
  6: "6am - 7am",
  7: "7am - 8am",
  8: "8am - 9am",
  9: "9am - 10am",
  10: "10am - 11am",
  11: "11am - 12pm",
  12: "12pm - 1pm",
  13: "1pm - 2pm",
  14: "2pm - 3pm",
  15: "3pm - 4pm",
  16: "4pm - 5pm",
  17: "5pm - 6pm",
  18: "6pm - 7pm",
  19: "7pm - 8pm",
  20: "8pm - 9pm",
  21: "9pm - 10pm",
  22: "10pm - 11pm",
  23: "11pm - 12am"
};
export enum DayOfTheWeekShort {
  SUNDAY = "Sun",
  MONDAY = "Mon",
  TUESDAY = "Tue",
  WEDNESDAY = "Wed",
  THURSDAY = "Thurs",
  FRIDAY = "Fri",
  SATURDAY = "Sat"
}

export const DAYS_MAP_SHORT = {
  1: DayOfTheWeekShort.MONDAY,
  2: DayOfTheWeekShort.TUESDAY,
  3: DayOfTheWeekShort.WEDNESDAY,
  4: DayOfTheWeekShort.THURSDAY,
  5: DayOfTheWeekShort.FRIDAY,
  6: DayOfTheWeekShort.SATURDAY,
  0: DayOfTheWeekShort.SUNDAY
};

export const INITIAL_SELECTIONS = {
  campaigns: {
    total: 0,
    toRemove: 0
  },
  pixels: {
    total: 0,
    toRemove: 0
  },
  commercials: {
    total: 0,
    toRemove: 0
  }
};

export const REPORT_TYPE_QUERY_PARAM = "type";

export const ONE_DECIMAL_PLACE_PRECISION = 1;

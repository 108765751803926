export const SIXTY_DAYS = 60 * 24 * 60 * 60 * 1000;
export const dateFromFirestoreDate = (fsDate) => {
    if (!fsDate.toDate) {
        return new Date(fsDate.seconds * 1000);
    }
    return fsDate.toDate();
};
export const convertTimeToUnixTimestamp = (date) => {
    // If date is null return current date in unix timestamp
    if (!date) {
        return Math.round(new Date().getTime() / 1000);
    }
    return Math.round(new Date(date).getTime() / 1000);
};
/** Careful DRYing these functions, their names make the intent in the parts of the code where they're used very clear. */
export const addOneMonthToDate = (date) => {
    const dateCopy = new Date(date);
    dateCopy.setMonth(dateCopy.getMonth() + 1);
    return dateCopy;
};
export const subtractXMonthsToDate = (x, date) => {
    const dateCopy = new Date(date);
    dateCopy.setMonth(dateCopy.getMonth() - x);
    return dateCopy;
};
/**
 * formats dates in the following format yyyy-MM-dd
 */
export const formatDate = (date) => {
    const year = date.getFullYear();
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const formatTwoDigits = (n) => (n >= 10 ? n.toString() : `0${n}`);
    return `${year}-${formatTwoDigits(month)}-${formatTwoDigits(day)}`;
};
/**
 * Creates a new date object from a string in the format yyyy-MM-dd,
 * setting the time to midnight of system timezone.
 * @param date string in the format yyyy-MM-dd
 */
export const dateFromFormat = (date) => {
    const [year, month, day] = date.split("-").map(Number);
    const newDate = setDateToMidnight(new Date());
    return new Date(newDate.setFullYear(year, month - 1, day));
};
export const addDaysToDate = (date, numberOfDaysToAdd) => {
    const dateCopy = new Date(date);
    dateCopy.setDate(dateCopy.getDate() + numberOfDaysToAdd);
    return dateCopy;
};
export const addYearsToDate = (date, yearsToAdd) => {
    const dateCopy = new Date(date);
    dateCopy.setFullYear(dateCopy.getFullYear() + yearsToAdd);
    return dateCopy;
};
export const setDateToMidnight = (date) => {
    const dateCopy = new Date(date);
    dateCopy.setHours(0, 0, 0, 0);
    return dateCopy;
};

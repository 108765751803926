import { FC, PropsWithChildren, useState } from "react";
import { Icon } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { css } from "@emotion/react";
import { Typography } from "madhive/components";

interface Props {
  /** Bolded title to appear at the top of the accordion */
  title: string;
  /** Whether the accordion is able to be opened/closed/dismissed */
  disabled?: boolean;
  /** Function to run when the user clicks the close button */
  onDismiss?: () => void;
}

const styles = {
  accordion: css`
    min-width: 480px;
    border-radius: 3px;
    background: var(--white);
  `,
  title: css`
    background: #f3f4f7;
    border-radius: 3px;
    padding: var(--spacing-16) var(--spacing-24);
    display: flex;
    justify-content: space-between;
  `,
  icon: css`
    cursor: pointer;
  `
};

const Accordion: FC<PropsWithChildren<Props>> = props => {
  const { title, disabled, onDismiss } = props;

  const [isOpen, setIsOpen] = useState(true);

  /** prevent state where a closed accordion is dismissable */
  const iconName = !isOpen
    ? IconNames.CHEVRON_UP
    : onDismiss
    ? IconNames.CROSS
    : IconNames.CHEVRON_DOWN;

  return (
    <div css={styles.accordion}>
      <div css={styles.title}>
        <Typography bold>{title}</Typography>
        <Icon
          data-testid={`accordion-action-${iconName}`}
          icon={iconName}
          color="var(--gray-4)"
          size={14}
          css={disabled || styles.icon}
          onClick={() => {
            if (disabled) return;

            if (onDismiss && isOpen) {
              onDismiss();
              return;
            }
            setIsOpen(prev => !prev);
          }}
        />
      </div>
      {isOpen && props.children}
    </div>
  );
};

export default Accordion;

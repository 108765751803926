import { Hydrater } from "../handlers";
export class CustomReportsHydrater extends Hydrater {
    /**
     * It hydrates the list of reports with the advertiser name
     *
     * @param reports The list of available reports
     * @param fields The fields in the report to hydrate, currently only supports "parameters.advertiserName"
     * @returns An updated list of reports with the selected fields hydrated with the advertiser name
     */
    hydrateItems(reports, fields) {
        return new Promise((resolve) => {
            if (reports.length === 0 || fields.length === 0) {
                resolve(reports);
            }
            else {
                this.sdk.advertisers.find().then((allAdvertisers) => {
                    const advertisers = new Map();
                    for (const advertiser of allAdvertisers) {
                        advertisers.set(advertiser.id, advertiser.name);
                    }
                    resolve(this.hydrateReports(reports, advertisers));
                });
            }
        });
    }
    /**
     * Summarizes each with an advertiser name.
     * This is necessary for any request for custom reporting data table, as advertiser names are displayed on the table
     * @param reports: the reports to hydrate info.
     * @param advertisers: what to fill advertisers with.
     */
    async hydrateReports(reports, advertisers) {
        if (reports.length === 0) {
            return [];
        }
        return reports.map((report) => {
            report.parameters.advertiserName = advertisers.get(report.parameters.advertiser) || "--";
            return report;
        });
    }
}

import { css } from "@emotion/react";
import { SmithersNotificationFormatted } from "types";
import { Icon, Intent, Position, Tooltip, IconSize } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import {
  NotificationCategory,
  archiveFirestoreNotification
} from "lib/utils/notifications";
import { dateFullDisplayWithTime, isValidDate } from "lib/utils/date";
import { ToasterContext } from "lib/context";
import {
  DefaultDownloadIcon,
  DefaultWorkflowIcon,
  DefaultActionIcon,
  DefaultReleaseIcon,
  DefaultMaintenanceIcon,
  DefaultBulkCreativeUploadIcon
} from "components/Svg/NotificationsIcons";
import { FC, useContext } from "react";
import NotificationBody from "./body";
import Button from "./buttons";

const classes = {
  notificationContainer: css`
    display: grid;
    grid-template-columns: 65px 3fr 150px 60px;
    margin-bottom: 15px;
  `,
  notificationTitleContainer: css`
    display: flex;
    align-items: center;
    line-height: 14px;
  `,
  notificationTitleText: css`
    font-weight: 500;
  `,
  notificationIconContainer: css`
    display: flex;
  `,
  notificationIcon: css`
    margin-left: 15px;
  `,
  notificationSubtitleText: css`
    margin-top: var(--spacing-8);
    color: var(--semi-dark);
    font-size: 12px;
    letter-spacing: 0.01em;
    line-height: 14px;
  `,
  notificationContentContainer: css`
    margin-left: 5px;
  `,
  notificationTimestamp: css`
    color: #b4bfc5;
    font-size: 12px;
    text-align: right;
  `,
  archiveButton: css`
    color: #dadada;
    margin: 0px 10px;
    cursor: pointer;
    position: relative;
    bottom: 6px;
  `,
  unread: css`
    background: var(--primary-color);
  `,
  read: css`
    background: transparent;
  `,
  circle: css`
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-top: 5px;
  `,
  horizontalLine: css`
    margin: 20px 0px;
    border: 0.5px solid var(--border-light);
  `
};

const getNotificationIcon = (category: NotificationCategory): JSX.Element => {
  switch (category) {
    case NotificationCategory.WORKFLOW:
      return <DefaultWorkflowIcon />;
    case NotificationCategory.MAINTENANCE:
      return <DefaultMaintenanceIcon />;
    case NotificationCategory.RELEASE:
      return <DefaultReleaseIcon />;
    case NotificationCategory.CREATIVE_DELIVERY:
    case NotificationCategory.DOWNLOAD:
      return <DefaultDownloadIcon />;
    case NotificationCategory.ACTION:
      return <DefaultActionIcon />;
    case NotificationCategory.BULK_CREATIVE_UPLOAD:
      return <DefaultBulkCreativeUploadIcon />;
    default:
      return <DefaultActionIcon />;
  }
};

const Container: FC<{
  notification: SmithersNotificationFormatted;
}> = ({ notification }) => {
  const { category, title, createdDate, id, read, actions, meta } =
    notification;
  const { addToast } = useContext(ToasterContext);

  const archiveNotification = (notificationId: string) => {
    archiveFirestoreNotification(notificationId)
      .then(() => {
        addToast({
          intent: Intent.SUCCESS,
          message: "Successfully archived this notification."
        });
      })
      .catch(() => {
        addToast({
          intent: Intent.DANGER,
          message: "Failed to archive this notification.",
          action: {
            text: "Try again",
            /** Recursively call this function. */
            onClick: () => archiveNotification(notificationId)
          }
        });
      });
  };

  return (
    <div key={`base-${id}`}>
      <div css={classes.notificationContainer}>
        <div css={classes.notificationIconContainer}>
          <div
            css={css(classes.circle, !read ? classes.unread : classes.read)}
            data-testid={`${read ? "marked-read" : "marked-unread"}`}
          />

          <div css={classes.notificationIcon}>
            {getNotificationIcon(category)}
          </div>
        </div>

        <div css={classes.notificationContentContainer}>
          <div css={classes.notificationTitleContainer}>
            <div css={classes.notificationTitleText}>{title}</div>
          </div>

          <p
            css={classes.notificationSubtitleText}
            key={`unique-notification-id-${id}`}
          >
            {id}
          </p>

          <NotificationBody notification={notification} />

          <div css={classes.notificationTitleContainer}>
            {actions.map(notificationAction => (
              <div key={`actions-${id}-${notificationAction.actionId}`}>
                <Button
                  action={notificationAction}
                  filePath={meta && meta.path}
                  id={id}
                />
              </div>
            ))}
          </div>
        </div>

        {isValidDate(createdDate) && (
          <div>
            <div css={classes.notificationTimestamp}>
              {dateFullDisplayWithTime(createdDate)}
            </div>
          </div>
        )}

        <Tooltip
          content={<span>Delete</span>}
          position={Position.TOP}
          usePortal={false}
        >
          <div
            css={classes.archiveButton}
            onClick={() => archiveNotification(id)}
            data-testid="notifications-archive"
          >
            <Icon icon={IconNames.TRASH} iconSize={IconSize.LARGE} />
          </div>
        </Tooltip>
      </div>

      <div css={classes.horizontalLine} />
    </div>
  );
};

export default Container;

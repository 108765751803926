import { validateTrackingPixels, validateTrackingPixelUrl } from "./validators";
import { Base } from "../handlers";
const validators = (sdk) => ({
    url: (item, value) => {
        return validateTrackingPixelUrl(value, sdk.creatives.trackingPixels.getUrls(item));
    },
    urls: (item, value) => {
        return validateTrackingPixels(item, value, true).values().next().value;
    }
});
/**
 * Handler for lineItem.attachedCreatives to provide validation
 */
export class TrackingPixels extends Base {
    constructor(sdk) {
        super(sdk, "trackingPixels");
        this.validators = validators(sdk);
    }
    validate(item, key, value) {
        return super.validate(Array.isArray(item) ? this.normalize(item) : item, key, value);
    }
    /**
     * Combine tracking pixels with the same type
     * @param trackingPixels - array of tracking pixel objects
     * @returns - array of tracking pixel objects with unique types
     */
    normalize(trackingPixels) {
        const trackerMap = new Map();
        trackingPixels.forEach(({ type, urls }) => {
            const current = trackerMap.get(type) || { type: type, urls: [] };
            current.urls.push(...(urls || []));
            trackerMap.set(type, current);
        });
        return Array.from(trackerMap.values());
    }
    /**
     * Get URLs from all tracker objects
     */
    getUrls(trackingPixels) {
        return trackingPixels.flatMap(({ urls }) => urls || []);
    }
    /**
     * Get full validation map for tracking pixels - keyed by errored URL.
     * @param trackingPixels
     * @returns
     */
    getValidationMap(trackingPixels) {
        return validateTrackingPixels(this.normalize(trackingPixels));
    }
    /**
     * key to put the parent object on the child object when validating
     */
    getKeyToPutParentOn() {
        return "creative";
    }
    /**
     * identifier key for the child object
     */
    getIdentifierKey() {
        return "type";
    }
}

import { ReactNode, ButtonHTMLAttributes } from "react";
import { Alignment } from "@blueprintjs/core";
import { IconName } from "@blueprintjs/icons";

export enum Variant {
  OUTLINED = "outlined",
  CONTAINED = "contained",
  TEXT = "text",
  DEFAULT = "default",
  ICON = "icon"
}

export enum Color {
  INHERIT = "inherit",
  PRIMARY = "primary",
  SECONDARY = "secondary",
  DEFAULT = "default",
  DANGER = "danger",
  SAVE = "save"
}

interface ButtonBaseProps {
  /**
   * Text alignment within button. By default, icons and text will be
   * centered within the button. Passing "left" or "right" will align
   * the button text to that side and push icon and rightIcon to either edge.
   * Passing "center" will center the text and icons together.
   */
  alignText?: Alignment;
  /**
   * A space delimited list of classes to be applied. If it exists no base styles will be
   * applied inside the Button component.
   */
  className?: string;
  /**
   * Whether the Button is disabled or not
   */
  disabled?: boolean;
  /**
   * Whether this button should expand to fill the width its container.
   */
  fill?: boolean;
  /**
   * Same as `<a href="">` Renders a link tag around the button
   */
  href?: string;
  /**
   * If true no base styles will be applied inside the Button component.
   */
  ignoreBaseStyle?: boolean;
  /**
   * Specify an icon to be rendered on the left side of the button (before the text)
   */
  icon?: JSX.Element;
  /**
   * Whether this button should use large styles.
   */
  large?: boolean;
  /** Button becomes disabled and renders a spinner when true. */
  loading?: boolean;
  /**
   * Name of a Blueprint UI icon (or an icon element) to render after the text.
   */
  rightIcon?: IconName | JSX.Element;
  /**
   * Whether this button should use small styles.
   */
  small?: boolean;
  /**
   * Action text. Can be any single React renderable.
   */
  text?: ReactNode;
}

interface PrimaryOrDefaultButton extends ButtonBaseProps {
  /**
   * Color is optional for these variants, it will fallback to default.
   */
  color?: Color.PRIMARY | Color.DEFAULT; //
  variant?:
    | Variant.CONTAINED
    | Variant.DEFAULT
    | Variant.OUTLINED
    | Variant.TEXT;
}

/**
 * Secondary Buttons can only be of variant CONTAINED
 */
interface SecondaryButton extends ButtonBaseProps {
  color: Color.SECONDARY | Color.SAVE;
  variant: Variant.CONTAINED; // Secondary buttons can only be of type contained
}

/**
 * Danger Buttons can only be of variant CONTAINED
 */
interface DangerButton extends ButtonBaseProps {
  color: Color.DANGER;
  variant: Variant.CONTAINED; // Danger buttons can only be of type contained
}

interface IconButton extends ButtonBaseProps {
  color?: never; // Icon buttons cannot have a color defined
  /**
   * Whether this button should expand to fill its container.
   */
  fill?: never; // Icon buttons size are static.
  variant: Variant.ICON;
}

type ComponentSpecificProps =
  | IconButton
  | SecondaryButton
  | DangerButton
  | PrimaryOrDefaultButton;

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  ComponentSpecificProps;

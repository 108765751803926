import { DEFAULT_COLOR } from "./constants";
import { Color } from "./types";

interface ParsedColor {
  content: string;
  circle?: {
    outline?: string;
    fill?: string;
  };
}

/**
 * @param color: the color prop to parse.
 * @return: a parsed color object.
 */
export const parseColor = (color: Color | undefined): ParsedColor => {
  let result: ParsedColor = {
    content: DEFAULT_COLOR,
    circle: {
      outline: DEFAULT_COLOR
    }
  };

  if (typeof color === "string") {
    result = {
      content: color,
      circle: {
        outline: color
      }
    };
  } else if (typeof color === "object") {
    if (color.content) {
      result.content = color.content;
    }
    if (color.circle) {
      result.circle = color.circle;
    } else if (color.content) {
      result.circle = {
        outline: color.content
      };
    }
  }

  return result;
};

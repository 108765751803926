import { KnownOrganizationNames, OTTDimensionList } from "@madhive/mad-sdk";

import { DimensionOption } from "features/CustomReporting/types";

/**
 * These are default dimensions for OTT reports.
 * These will only be used if the given user/org has no value configured for the relevant `Dimension`.
 *
 * @deprecated: long-term, this live within the UX config system.
 */
export const DEFAULT_OTT_DIMENSIONS = [
  OTTDimensionList.CAMPAIGNS,
  OTTDimensionList.PUBLISHER,
  OTTDimensionList.DEVICE_TYPE
];

/** All OTT dimensions that exist. */
export const OTT_DIMENSIONS: DimensionOption[] = [
  {
    name: "Campaigns",
    id: OTTDimensionList.CAMPAIGNS
  },
  {
    name: "Publishers",
    id: OTTDimensionList.PUBLISHER
  },
  {
    name: "Line Items",
    id: OTTDimensionList.LINE_ITEMS
  },
  {
    name: "Device Types",
    id: OTTDimensionList.DEVICE_TYPE
  },
  {
    name: "Day of the Week",
    id: OTTDimensionList.DAY_OF_THE_WEEK
  },
  {
    name: "DMA",
    id: OTTDimensionList.DMA
  },
  {
    name: "Dayparts",
    id: OTTDimensionList.DAYPART
  },
  {
    name: "Creatives",
    id: OTTDimensionList.CREATIVE
  },
  {
    name: "Daily",
    id: OTTDimensionList.DAILY
  },
  {
    name: "Weekly",
    id: OTTDimensionList.WEEKLY
  },
  {
    name: "Monthly",
    id: OTTDimensionList.MONTHLY
  },
  {
    name: "Zip Code",
    id: OTTDimensionList.ZIP_CODE
  },

  {
    name: "State",
    id: OTTDimensionList.STATE
  },
  {
    name: "Congressional District",
    id: OTTDimensionList.CONGRESSIONAL_DISTRICT
  },

  {
    name: "Hours of the Week",
    id: OTTDimensionList.HOUR_OF_THE_WEEK
  },
  {
    name: "Hourly",
    id: OTTDimensionList.HOURLY
  }
];

/** Default static options for the OTT dimension selector. These defaults are true for *most* orgs. */
export const DEFAULT_STATIC_OTT_DIMENSIONS = [
  OTTDimensionList.CAMPAIGNS,
  OTTDimensionList.PUBLISHER,
  OTTDimensionList.DEVICE_TYPE
];

export const STATIC_OTT_DIMENSIONS_BY_ORGANIZATION = new Map<
  KnownOrganizationNames,
  string[]
>([
  [
    KnownOrganizationNames.IHEART,
    [OTTDimensionList.CAMPAIGNS, OTTDimensionList.DEVICE_TYPE]
  ]
]);

export const INITIAL_OTT_DIMENSIONS_BY_ORGANIZATION: Map<
  KnownOrganizationNames,
  string[]
> = new Map([
  [
    KnownOrganizationNames.SCRIPPS,
    [
      OTTDimensionList.CAMPAIGNS,
      OTTDimensionList.PUBLISHER,
      OTTDimensionList.DEVICE_TYPE,
      OTTDimensionList.LINE_ITEMS,
      OTTDimensionList.CREATIVE,
      OTTDimensionList.ZIP_CODE,
      OTTDimensionList.MONTHLY
    ]
  ],
  [
    KnownOrganizationNames.IHEART,
    [OTTDimensionList.CAMPAIGNS, OTTDimensionList.DEVICE_TYPE]
  ]
]);

export const INITIAL_OTT_MULTI_DIMENSIONS_BY_ORGANIZATION: Map<
  KnownOrganizationNames,
  string[][]
> = new Map([
  [
    KnownOrganizationNames.SCRIPPS,
    [
      [OTTDimensionList.DEVICE_TYPE, OTTDimensionList.LINE_ITEMS],
      [OTTDimensionList.ZIP_CODE, OTTDimensionList.LINE_ITEMS],
      [OTTDimensionList.CREATIVE, OTTDimensionList.LINE_ITEMS],
      [OTTDimensionList.LINE_ITEMS, OTTDimensionList.CAMPAIGNS],
      [OTTDimensionList.CREATIVE, OTTDimensionList.CAMPAIGNS],
      [OTTDimensionList.MONTHLY, OTTDimensionList.CAMPAIGNS]
    ]
  ]
]);

import { combineReducers } from "redux";
import {
  uniquesHistogramInitialState,
  uniquesHistogramReducer
} from "./uniquesHistogramReducer/reducer";
import {
  campaignSummaryReducer,
  campaignSummaryInitialState
} from "./campaignSummaryReducer/reducer";
import { DashboardReducerKeys } from "./types";
import {
  dashboardReportsInitialState,
  dashboardReportsReducer
} from "./reports/reducer";

export const initialDashboardState = {
  [DashboardReducerKeys.CAMPAIGN_SUMMARY]: campaignSummaryInitialState,
  [DashboardReducerKeys.UNIQUES_HISTOGRAM]: uniquesHistogramInitialState,
  [DashboardReducerKeys.REPORTS]: dashboardReportsInitialState
};

export const dashboardReducer = combineReducers({
  [DashboardReducerKeys.CAMPAIGN_SUMMARY]: campaignSummaryReducer,
  [DashboardReducerKeys.UNIQUES_HISTOGRAM]: uniquesHistogramReducer,
  [DashboardReducerKeys.REPORTS]: dashboardReportsReducer
});

import { ArchiveFilter, ServiceStatus } from "../../types";
export var FootTrafficDimension;
(function (FootTrafficDimension) {
    FootTrafficDimension["ADDRESS"] = "address_name";
    FootTrafficDimension["ADVERTISER"] = "advertiser_name";
    FootTrafficDimension["CAMPAIGN"] = "campaign_name";
    FootTrafficDimension["CREATIVE"] = "creative_name";
    FootTrafficDimension["DAY_OF_VISIT"] = "visit_date_et";
    FootTrafficDimension["DAY_OF_IMPRESSION"] = "impression_date_et";
    FootTrafficDimension["DAY_OF_WEEK_VISIT"] = "day_of_week_visit";
    FootTrafficDimension["DEVICE_TYPE"] = "device_type";
    FootTrafficDimension["DMA"] = "dma_name";
    FootTrafficDimension["PUBLISHER"] = "publisher_name";
    FootTrafficDimension["POSTAL_CODE"] = "postal_code";
    FootTrafficDimension["LINE_ITEM"] = "line_item_name";
    FootTrafficDimension["BRAND"] = "brand";
    FootTrafficDimension["ESTIMATE"] = "estimate";
    FootTrafficDimension["VISIT_ZIP"] = "visit_zip";
    // location dimension replaces address_name dimension as of v3.0.0
    FootTrafficDimension["LOCATION"] = "location";
})(FootTrafficDimension || (FootTrafficDimension = {}));
export var FootTrafficMetric;
(function (FootTrafficMetric) {
    // TODO: BE will change total_unique_ips into the friendly name "reach"
    FootTrafficMetric["REACH"] = "total_unique_ips";
    FootTrafficMetric["IMPRESSIONS"] = "impressions_total";
    FootTrafficMetric["VISITS"] = "visits";
    FootTrafficMetric["VISITORS"] = "visitors";
    // Address dimension metrics
    FootTrafficMetric["VISIT_CITY"] = "visit_city";
    FootTrafficMetric["VISIT_STATE"] = "visit_state";
    FootTrafficMetric["VISIT_ZIP"] = "visit_zip";
})(FootTrafficMetric || (FootTrafficMetric = {}));
export var FootTrafficReportStatus;
(function (FootTrafficReportStatus) {
    FootTrafficReportStatus["IN_PROGRESS"] = "RUNNING";
    FootTrafficReportStatus["DONE"] = "SUCCESS";
    FootTrafficReportStatus["ERROR"] = "FAILED";
})(FootTrafficReportStatus || (FootTrafficReportStatus = {}));
export var FootTrafficToplineDimension;
(function (FootTrafficToplineDimension) {
    // v1 reports topline
    FootTrafficToplineDimension["TOPLINE"] = "topline";
    // v2 reports topline
    FootTrafficToplineDimension["TOPLINE_IMPRESSIONS"] = "foot_traffic_topline_impressions";
    FootTrafficToplineDimension["TOPLINE_VISITS"] = "foot_traffic_topline_visits";
})(FootTrafficToplineDimension || (FootTrafficToplineDimension = {}));
export var FootTrafficReportVersion;
(function (FootTrafficReportVersion) {
    FootTrafficReportVersion["v1_0_0"] = "1.0.0";
    /**
     * Foot Traffic v2 contains breaking changes adding new dimensions "foot_traffic_topline_impressions", "foot_traffic_topline_visits" and "day_of_week_visit"
     * Also removes  "advertiser_name" dimension (previously used as topline summary metrics)
     */
    FootTrafficReportVersion["v2_0_0"] = "2.0.0";
    /**
     * Foot Traffic v3 contains legal changes where address_name dimension is being removed from reports and replaced with location dimension
     * Visits by Address chart is also being removed and replaced with Visits by zip code chart;
     * adds location and visits_zip dimensions
     */
    FootTrafficReportVersion["v3_0_0"] = "3.0.0";
})(FootTrafficReportVersion || (FootTrafficReportVersion = {}));
export const getStatusesByArchiveFilter = (archiveFilter) => {
    switch (archiveFilter) {
        case ArchiveFilter.ACTIVE:
            return [ServiceStatus.READY];
        case ArchiveFilter.ARCHIVED:
            return [ServiceStatus.ARCHIVED];
        case ArchiveFilter.BOTH:
        default:
            return [ServiceStatus.READY, ServiceStatus.ARCHIVED];
    }
};

import { FC } from "react";

const MadhiveFooterLogo: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="232"
    height="80"
    viewBox="80 80 963 330"
  >
    <path
      d="M417.08,114.25h28.07v103.4h-17.43v-85.68l-27.77,85.68h-20.68l-28.36-85.23v85.23h-17.58v-103.4h28.36l28.07,84.79
        L417.08,114.25z M457.12,180.73c0-25.26,14.92-38.41,32.06-38.41c12.11,0,19.2,5.61,22.75,11.82V143.8h16.99v73.86h-16.99v-10.34
        c-3.69,6.2-10.64,11.82-22.75,11.82C472.78,219.13,457.12,205.99,457.12,180.73z M493.46,205.25c9.75,0,18.47-8.72,18.47-24.52
        c0-15.81-8.72-24.52-18.47-24.52h-1.33c-9.9,0-18.02,9.31-18.02,24.37c0,15.22,8.12,24.67,18.02,24.67H493.46z M540.14,180.73
        c0-21.86,12.85-38.41,33.09-38.41c10.34,0,18.61,5.61,21.86,11.37v-39.44h16.99v103.4h-16.99v-9.9
        c-3.25,5.76-12.26,11.37-22.6,11.37C552.4,219.13,540.14,202.59,540.14,180.73z M576.62,205.25c9.6,0,18.47-8.86,18.47-24.52
        c0-15.66-8.86-24.52-18.47-24.52h-1.18c-10.34,0-18.32,8.86-18.32,24.37c0,15.66,7.98,24.67,18.32,24.67H576.62z M629.66,217.66
        v-103.4h16.99v40.18c4.43-7.09,12.41-12.11,22.9-12.11c17.43,0,26.15,11.23,26.15,27.18v48.16H678.7v-45.65
        c0-9.9-4.43-15.81-13.15-15.81h-1.18c-9.45,0-17.73,8.27-17.73,20.24v41.21H629.66z M711.35,132.57v-18.32h18.47v18.32H711.35z
         M712.09,217.66V143.8h16.99v73.86H712.09z M798.35,143.8h17.43l-26.29,73.86H766.3L740,143.8h17.87l20.24,59.83L798.35,143.8z
         M817.56,180.73c0-23.64,14.62-38.41,34.71-38.41c20.98,0,32.79,14.92,32.79,35.31c0,2.51-0.15,5.02-0.3,7.53H834.1
        c0.89,13,10.05,20.09,18.47,20.09h1.18c7.24,0,12.7-4.14,14.18-10.34h16.84c-3.1,14.77-15.22,24.23-32.2,24.23
        C834.1,219.13,817.56,205.69,817.56,180.73z M868.37,172.01c-0.59-10.64-7.68-15.81-15.81-15.81h-1.18
        c-7.98,0-15.51,5.02-16.84,15.81H868.37z"
    />
    <path
      d="M171.21,165c0-12.33,3.63-25.03,17.85-44.69c0.79-1.07,0.67-2.59-0.28-3.58l-6.95-6.97c-0.96-0.94-2.45-1.07-3.54-0.27
        c-16.37,11.75-30.62,17.88-44.51,17.88c-12.28,0-24.93-3.62-44.49-17.92c-1.1-0.8-2.59-0.67-3.55,0.27l-6.96,7.02
        c-0.96,0.94-1.06,2.46-0.28,3.58C90.21,136.75,96.3,151.05,96.3,165c0,12.33-3.63,25.03-17.85,44.69
        c-0.79,1.12-0.66,2.64,0.28,3.58l6.95,6.97c0.96,0.98,2.46,1.07,3.55,0.31c16.37-11.8,30.62-17.92,44.51-17.92
        c12.29,0,24.93,3.66,44.5,17.96c1.09,0.76,2.59,0.62,3.54-0.31l6.95-6.97c0.96-0.98,1.06-2.46,0.29-3.57
        c-11.72-16.45-17.82-30.75-17.82-44.69L171.21,165z"
    />
    <path
      d="M243.38,218c29.12,0,52.73-23.73,52.73-53c0-29.23-23.61-52.96-52.73-52.96s-52.73,23.73-52.73,52.96
        C190.65,194.27,214.26,218,243.38,218"
    />
  </svg>
);

export default MadhiveFooterLogo;

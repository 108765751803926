import { Link } from "react-router-dom";
import { SmithersNotificationActionFormatted } from "types";
import { startCase } from "lodash";
import { Button, ButtonColor, ButtonVariant } from "madhive/components";
import { NotificationActionCode } from "lib/utils/notifications";
import { FC } from "react";
import { css } from "@emotion/react";
import ReportDownloadButton from "./ReportDownload";

const classes = {
  actionBtn: css`
    margin-top: 10px;
    margin-right: 25px;
    cursor: pointer;
    font-size: var(--font-size-12);
    color: var(--primary-color);
    font-weight: bold;
    text-transform: uppercase;
  `,
  link: css`
    &:hover: {
      text-decoration: none;
    }
  `
};

const NotificationButton: FC<{
  action: SmithersNotificationActionFormatted;
  filePath: string | undefined;
  id: string;
}> = ({ action, filePath, id }) => {
  const { actionId, buttonText, destinationUrl } = action;

  switch (actionId) {
    case NotificationActionCode.DOWNLOAD:
      return (
        <ReportDownloadButton
          notificationID={id}
          filePath={filePath}
          buttonText={buttonText}
        />
      );
    case NotificationActionCode.VIEW_CAMPAIGNS:
    case NotificationActionCode.WHATS_NEW:
    case NotificationActionCode.LEARN_MORE:
    case NotificationActionCode.VIEW_CREATIVES:
      return (
        <Link css={classes.link} to={destinationUrl || ""}>
          <Button variant={ButtonVariant.TEXT} color={ButtonColor.PRIMARY}>
            {startCase(buttonText)}
          </Button>
        </Link>
      );
    case NotificationActionCode.REMOVE_CAPS:
    case NotificationActionCode.REMIND_ME:
      return (
        <Button variant={ButtonVariant.TEXT} color={ButtonColor.PRIMARY}>
          {startCase(buttonText)}
        </Button>
      );
    case NotificationActionCode.SEND_TO:
      return (
        <Button variant={ButtonVariant.TEXT} color={ButtonColor.PRIMARY}>
          {startCase(buttonText)}
        </Button>
      );
    default:
      return <></>;
  }
};
export default NotificationButton;

import { PropsWithChildren } from "react";
import { css } from "@emotion/react";

interface Props {
  /** The value displayed within the badge */
  badgeContent?: number;
  /** Background color of badge */
  color: "splash" | "danger" | "success" | "warning" | "info";
  /** Whether or not the badge displays. If null is passed, it wil be displayed and will show 0 */
  invisible?: boolean;
}

const style = {
  root: css`
    position: relative;
    display: inline-flex;
    vertical-align: middle;
  `,
  span: css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    box-sizing: border-box;
    font-family: RedHatText;
    font-weight: 500;
    font-size: 0.75rem;
    min-width: 20px;
    padding: 0 4px;
    height: 20px;
    border-radius: 10px;
    z-index: 1;
    transform: scale(1) translate(50%, -50%);
    transform-origin: 100% 0%;
  `,
  invisible: css`
    transform: scale(0) translate(50%, -50%);
    transform-origin: 100% 0%;
  `,
  splash: css`
    background-color: var(--blue);
  `,
  danger: css`
    background-color: var(--red);
  `,
  success: css`
    background-color: var(--green);
  `,
  info: css`
    background-color: var(--blue-utility);
  `,
  warning: css`
    background-color: var(--orange);
  `
};

const Badge = ({
  badgeContent,
  color,
  children,
  invisible
}: PropsWithChildren<Props>) => (
  <div css={[style.root]}>
    {children}
    <span
      className="badge-wrapper"
      data-testid="smithers-badge"
      css={[
        style.span,
        invisible && style.invisible,
        color === "splash" && style.splash,
        color === "danger" && style.danger,
        color === "success" && style.success,
        color === "info" && style.info,
        color === "warning" && style.warning
      ]}
    >
      {badgeContent}
    </span>
  </div>
);

export default Badge;

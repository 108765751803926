import { IToastProps } from "@blueprintjs/core";
import { Advertiser, AdvertiserBase } from "@madhive/mad-sdk";

export const GET_AVAILABLE_ADVERTISERS_PENDING =
  "GET_AVAILABLE_ADVERTISERS_PENDING";
export const GET_AVAILABLE_ADVERTISERS_SUCCESS =
  "GET_AVAILABLE_ADVERTISERS_SUCCESS";
export const GET_AVAILABLE_ADVERTISERS_FAILURE =
  "GET_AVAILABLE_ADVERTISERS_FAILURE";
export const GET_RUNNING_ADVERTISERS_PENDING =
  "GET_RUNNING_ADVERTISERS_PENDING";
export const GET_RUNNING_ADVERTISERS_SUCCESS =
  "GET_RUNNING_ADVERTISERS_SUCCESS";
export const GET_RUNNING_ADVERTISERS_FAILURE =
  "GET_RUNNING_ADVERTISERS_FAILURE";
export const CREATE_ADVERTISER_SUCCESS = "CREATE_ADVERTISER_SUCCESS";
export const UPDATE_ADVERTISER_PENDING = "UPDATE_ADVERTISER_PENDING";
export const UPDATE_ADVERTISER_SUCCESS = "UPDATE_ADVERTISER_SUCCESS";
export const UPDATE_ADVERTISER_FAILURE = "UPDATE_ADVERTISER_FAILURE";
export const ABORT_ADVERTISER_REQUEST = "ABORT_ADVERTISER_REQUEST";

export interface AdvertisersState {
  readonly byId: Record<string, Advertiser>;
  readonly updatingById: Record<string, Advertiser>;
  readonly creatingByRequestId: Record<string, Advertiser | AdvertiserBase>;
  readonly isAllLoading: boolean;
  readonly receivedAllAt: number | null;
  readonly isRunningLoading: boolean;
  readonly runningAdvertisers: Record<string, Advertiser>;
}

interface GetAvailableAdvertisersPendingAction {
  type: typeof GET_AVAILABLE_ADVERTISERS_PENDING;
}

interface GetAvailableAdvertisersSuccessAction {
  type: typeof GET_AVAILABLE_ADVERTISERS_SUCCESS;
  payload: Record<string, Advertiser>;
  meta: {
    timestamp: number;
  };
}

interface GetAvailableAdvertisersFailureAction {
  type: typeof GET_AVAILABLE_ADVERTISERS_FAILURE;
  meta: {
    error: {
      message: string;
    };
  };
}

interface GetRunningAdvertisersPendingAction {
  type: typeof GET_RUNNING_ADVERTISERS_PENDING;
}

interface GetRunningAdvertisersSuccessAction {
  type: typeof GET_RUNNING_ADVERTISERS_SUCCESS;
  payload: Record<string, Advertiser>;
  meta: {
    timestamp: number;
  };
}

interface GetRunningAdvertisersFailureAction {
  type: typeof GET_RUNNING_ADVERTISERS_FAILURE;
  meta: {
    error: {
      message: string;
    };
  };
}

interface UpdateAdvertiserPendingAction {
  type: typeof UPDATE_ADVERTISER_PENDING;
  meta: { advertiser: Advertiser };
}

interface UpdateAdvertiserSuccessAction {
  type: typeof UPDATE_ADVERTISER_SUCCESS;
  payload: Advertiser;
  meta: {
    success: { message: string };
  };
}

interface CreateAdvertiserSuccessAction {
  type: typeof CREATE_ADVERTISER_SUCCESS;
  payload: Advertiser;
  meta: {
    requestId: string;
    success: { message: string };
  };
}

interface UpdateAdvertiserFailureAction {
  type: typeof UPDATE_ADVERTISER_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
    id: string;
  };
}

interface AbortAdvertiserRequestAction {
  type: typeof ABORT_ADVERTISER_REQUEST;
}

export type AdvertisersActionTypes =
  | GetAvailableAdvertisersPendingAction
  | GetAvailableAdvertisersSuccessAction
  | GetAvailableAdvertisersFailureAction
  | GetRunningAdvertisersPendingAction
  | GetRunningAdvertisersSuccessAction
  | GetRunningAdvertisersFailureAction
  | UpdateAdvertiserPendingAction
  | UpdateAdvertiserSuccessAction
  | CreateAdvertiserSuccessAction
  | UpdateAdvertiserFailureAction
  | AbortAdvertiserRequestAction;

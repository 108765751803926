import { RootState } from "rootReducer";
import { createSelector } from "reselect";
import { PixelReportFormatted } from "pixel-types";
import { shapeDataForMultiLineChart, calculateOverlapTableData } from "./utils";

export const selectLastReceivedPixelReport = (state: RootState) =>
  state.pixelReport.lastReceivedReportAt;

export const selectLoadPixelReportCancellationSource = (state: RootState) =>
  state.pixelReport.cancellationSource;

export const isPixelReportLoading = (state: RootState) =>
  !!state.pixelReport.cancellationSource;

export const selectPixelReport = (state: RootState) =>
  state.pixelReport.reportData;

export const selectGetPixelReportError = (state: RootState) =>
  state.pixelReport.error;

export const selectPixelReportChartData = createSelector(
  selectPixelReport,
  (
    results: PixelReportFormatted | null
  ): Array<{
    label: string;
    primary: number;
    secondary: number;
  }> =>
    results
      ? shapeDataForMultiLineChart(
          results.allOverlapData.impressions.dataPoints,
          "allOverlap",
          results.dateRange.startDate,
          results.dateRange.endDate
        )
      : []
);

export const selectPixelReportOverlapTableData = createSelector(
  selectPixelReport,
  (results: PixelReportFormatted | null) =>
    results
      ? calculateOverlapTableData(results)
      : {
          dailyOverlap: [],
          providerOverlap: []
        }
);

export const selectPixelReportOverlapData = createSelector(
  selectPixelReport,
  (results: PixelReportFormatted | null) =>
    results ? results.allOverlapData : null
);

import axios from "axios";
import { NotImplementedError } from "../errors";
import { ObservableHandler } from "./handlers";
import { Observable } from "rxjs";
import { GeoDetailByCode, GeoDetailById } from "../models/geo";
export default class Geo extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "geo");
    }
    findItems() {
        return new Observable((subscriber) => {
            this.cache
                .promise("geo", () => {
                return new Promise((resolve, reject) => {
                    axios
                        .get(`${this.sdk.urls.baseAPIUrl}/postals`, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    })
                        .then(({ data }) => {
                        const geoServiceData = data.data;
                        const geo = new Map();
                        for (let i = 0; i < geoServiceData.length; i++) {
                            const item = geoServiceData[i];
                            if (!geo.has(item.country_code)) {
                                geo.set(item.country_code, {
                                    postalCodes: new GeoDetailByCode(),
                                    regions: new GeoDetailByCode(),
                                    dmas: new GeoDetailByCode(),
                                    districts: new GeoDetailById(),
                                    code: item.country_code,
                                    name: item.country_name
                                });
                            }
                            const details = geo.get(item.country_code);
                            const dmas = [];
                            const regions = [];
                            const districts = [];
                            if (item.congressional_districts.length > 0) {
                                item.congressional_districts.forEach((district) => {
                                    districts.push(district.district_id);
                                    if (details.districts.has(district.district_id)) {
                                        details.districts
                                            .get(district.district_id)
                                            .postalCodes.push(item.postal_code);
                                    }
                                    else {
                                        details.districts.add({
                                            id: district.district_id,
                                            name: district.district_name,
                                            number: district.district_number,
                                            regions: district.state_code,
                                            postalCodes: [item.postal_code]
                                        });
                                    }
                                });
                            }
                            if (item.dmas.length > 0) {
                                item.dmas.forEach((dma) => {
                                    dmas.push(dma.dma_code);
                                    if (details.dmas.has(dma.dma_code)) {
                                        details.dmas.get(dma.dma_code).postalCodes.push(item.postal_code);
                                    }
                                    else {
                                        details.dmas.add({
                                            code: dma.dma_code,
                                            name: dma.dma_name,
                                            postalCodes: [item.postal_code]
                                        });
                                    }
                                });
                            }
                            if (item.regions.length > 0) {
                                item.regions.forEach((region) => {
                                    regions.push(region.region_code);
                                    if (details.regions.has(region.region_code)) {
                                        details.regions.get(region.region_code).postalCodes.push(item.postal_code);
                                    }
                                    else {
                                        details.regions.add({
                                            code: region.region_code,
                                            name: region.region_name,
                                            postalCodes: [item.postal_code]
                                        });
                                    }
                                });
                            }
                            if (details.postalCodes[item.postal_code]) {
                                details.postalCodes.get(item.postal_code).dmas.push(...dmas);
                                details.postalCodes.get(item.postal_code).regions.push(...regions);
                                details.postalCodes.get(item.postal_code).districts.push(...districts);
                            }
                            else {
                                details.postalCodes.add({
                                    dmas: dmas,
                                    regions: regions,
                                    districts: districts,
                                    code: item.postal_code
                                });
                            }
                        }
                        resolve(geo);
                    })
                        .catch((error) => {
                        reject(error);
                    });
                });
            })
                .then((geo) => {
                subscriber.next(geo);
                subscriber.complete();
            });
        });
    }
    /**
     * Make isn't implemented for users.
     */
    make() {
        throw new NotImplementedError("make");
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    saveItem(geo) {
        throw new NotImplementedError("save");
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}

import { User, KnownOrganizationIds, UserType, ViewId } from "@madhive/mad-sdk";
import { madSDK } from "lib/sdk";

export enum ManageUsersTableColumns {
  EMAIL = "email",
  NAME = "name",
  USER_TYPE = "user-type",
  AFFILIATION_TYPE = "affiliation-type",
  AFFILIATION = "affiliation",
  CREATED_DATE = "created-date",
  LAST_LOGIN_DATE = "last-login-date"
}

export const MANAGE_USERS_COLUMN_HEADINGS: Array<{
  id: ManageUsersTableColumns;
  label: string;
}> = [
  { id: ManageUsersTableColumns.EMAIL, label: "Email" },
  { id: ManageUsersTableColumns.NAME, label: "Name" },
  { id: ManageUsersTableColumns.USER_TYPE, label: "User Type" },
  { id: ManageUsersTableColumns.AFFILIATION_TYPE, label: "Affiliation Type" },
  { id: ManageUsersTableColumns.AFFILIATION, label: "Affiliation" },
  { id: ManageUsersTableColumns.CREATED_DATE, label: "Created Date" },
  { id: ManageUsersTableColumns.LAST_LOGIN_DATE, label: "Last Login Date" }
];

export enum ManageUsersPrimaryUserTypes {
  INTERNAL_STANDARD = "Internal (Non-Admin)",
  INTERNAL_ADMIN = "Internal (Admin)",
  EXTERNAL = "Client"
}

export enum ManageUsersSecondaryUserTypes {
  ADVERTISER = "advertiser",
  AGENCY = "agency"
}

export enum ManageUsersAffiliationTypes {
  STATION = "Station",
  ADVERTISER = "Advertiser",
  AGENCY = "Agency",
  CORPORATE = "Corporate"
}

export enum ManageUsersDrawerStatus {
  CREATING_NEW_USER = "CREATING NEW USER",
  EDITING_EXISTING_USER = "EDITING EXISTING USER",
  VIEWING_EXISTING_USER = "VIEWING EXISTING USER"
}

export enum ManageUsersActionStatus {
  PRE_FETCH = "PRE_FETCH",
  IN_PROGRESS = "IN_PROGRESS",
  COMPLETE = "COMPLETE",
  ERROR = "ERROR"
}

export const EMPTY_HYDRATED_USER: User = madSDK.users.toUser({
  email: "",
  name: "",
  views: [] as ViewId[],
  primary_organization_id: KnownOrganizationIds.MADHIVE_DEFAULT,
  type: UserType.ORGANIZATION,
  admin: false,
  developer: false,
  team_id: null,
  agency_id: null,
  advertiser_id: null,
  id: "",
  created: null,
  last_login: null,
  owners: [],
  parent: "",
  updated: null,
  abilities: []
});

export const PRIMARY_USER_TYPES_DROPDOWN: Array<{
  id: ManageUsersPrimaryUserTypes;
  label: string;
}> = [
  {
    id: ManageUsersPrimaryUserTypes.INTERNAL_STANDARD,
    label: "Internal (Non-Admin)"
  },
  { id: ManageUsersPrimaryUserTypes.INTERNAL_ADMIN, label: "Internal (Admin)" },
  { id: ManageUsersPrimaryUserTypes.EXTERNAL, label: "Client" }
];

export const CLIENT_USER_TYPES_DROPDOWN: Array<{
  id: ManageUsersSecondaryUserTypes;
  label: string;
}> = [
  { id: ManageUsersSecondaryUserTypes.ADVERTISER, label: "Advertiser" },
  { id: ManageUsersSecondaryUserTypes.AGENCY, label: "Agency" }
];

export const FILTER_TYPE_TO_LABEL = {
  userTypes: "User Type",
  affiliationTypes: "Affiliation Type",
  affiliations: "Affiliation"
};

export const VIEW_MODE_PARAM = "mode";

export enum ScreenViewMode {
  READONLY = "readonly",
  EDIT = "edit",
  CREATE = "create"
}

import {
  CampaignStatus,
  NON_READY_CAMPAIGN_STATUSES,
  UNEDITABLE_CAMPAIGN_STATUSES
} from "lib/constants/campaign";

const calculateUserShouldBeAbleToSetInstructionLive = (
  status: CampaignStatus
): boolean =>
  !NON_READY_CAMPAIGN_STATUSES.has(status) &&
  !UNEDITABLE_CAMPAIGN_STATUSES.has(status);

export default calculateUserShouldBeAbleToSetInstructionLive;

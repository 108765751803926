import { madSDK } from "lib/sdk";
import { FilterTypes, FilterField, IABCategory } from "@madhive/mad-sdk";

/**
 * Extracts the IAB category and subcategory IDs from a given ID.
 * This is useful for when you are unsure of whether a given ID is a category (aka "tier 1") or a subcategory (aka "tier 2").
 * @param categoryOrSubcategoryId An ID that could represent and IAB category or subcategory.
 * @param categories An array of available IAB categories.
 * @returns An object containing the IAB category and subcategory ID.
 *
 * @example
 *   extractIabCategoryIds("IAB1-1") // { iabCategoryId: "IAB1", iabSubcategoryId: "IAB1-1" }
 */
export const extractIabCategoryIds = (
  categoryOrSubcategoryId: string | undefined
): {
  iabCategoryId: string;
  iabSubcategoryId: string;
} => {
  const iabCategoryIds = {
    iabCategoryId: "",
    iabSubcategoryId: ""
  };

  if (!categoryOrSubcategoryId) {
    return iabCategoryIds;
  }

  iabCategoryIds.iabCategoryId = categoryOrSubcategoryId.includes("-")
    ? categoryOrSubcategoryId.slice(0, categoryOrSubcategoryId.indexOf("-"))
    : categoryOrSubcategoryId;

  iabCategoryIds.iabSubcategoryId = categoryOrSubcategoryId.includes("-")
    ? categoryOrSubcategoryId
    : "";

  return iabCategoryIds;
};

/**
 * Gets the name related to the specified IAB category or subcategory ID.
 * @param categoryOrSubcategoryId An ID that could represent and IAB category or subcategory.
 * @param categories An array of IAB categories.
 * @returns The related label, if one was found.
 */
export const getIabCategoryNameById = (
  categoryOrSubcategoryId: string | undefined,
  categories: IABCategory[]
): string => {
  const matchingCategory = categories.find(category => {
    return category.rtb_id === categoryOrSubcategoryId;
  });

  return matchingCategory?.description || "";
};

/**
 * Gets the name related to the specified IAB category or subcategory ID.
 * This is the asynchronous version of getIabCategoryNameById, which fetches a list of categories on its own.
 * @param categoryOrSubcategoryId The iabSubCategoryId or iabCategoryId.
 * @returns The name of the iabSubcategory or iabCategory, or an empty string if not found.
 */
export const getIabCategoryNameByIdAsync = async (
  categoryOrSubcategoryId: string | undefined
): Promise<string> => {
  if (!categoryOrSubcategoryId) {
    return "";
  }

  const query: FilterField<IABCategory> = {
    field: "rtb_id",
    type: FilterTypes.EQ,
    value: categoryOrSubcategoryId
  };

  const category = await madSDK.categories.find_once({ where: [query] });

  return category?.description || "";
};

/**
 * Gets the ID related to the specified IAB category or subcategory name.
 * @param name The IAB category or subcategory name.
 * @returns The ID of the subcategory or category, or an empty string if not found.
 */
export const getIabCategoryIdByName = async (name: string): Promise<string> => {
  if (!name) {
    return "";
  }

  const query: FilterField<IABCategory> = {
    field: "description",
    type: FilterTypes.EQ,
    value: name
  };

  const category = await madSDK.categories.find_once({ where: [query] });

  return category?.rtb_id || "";
};

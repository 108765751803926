import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";

const Check: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-circled-check"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M16.5303 10.5303C16.8232 10.2374 16.8232 9.76256 16.5303 9.46967C16.2374 9.17678 15.7626 9.17678 15.4697 9.46967L11 13.9393L8.53033 11.4697C8.23744 11.1768 7.76256 11.1768 7.46967 11.4697C7.17678 11.7626 7.17678 12.2374 7.46967 12.5303L10.4697 15.5303C10.7626 15.8232 11.2374 15.8232 11.5303 15.5303L16.5303 10.5303Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25C6.61479 2.25 2.25 6.61479 2.25 12C2.25 17.3852 6.61479 21.75 12 21.75C17.3852 21.75 21.75 17.3852 21.75 12C21.75 6.61479 17.3852 2.25 12 2.25ZM3.75 12C3.75 7.44321 7.44321 3.75 12 3.75C16.5568 3.75 20.25 7.44321 20.25 12C20.25 16.5568 16.5568 20.25 12 20.25C7.44321 20.25 3.75 16.5568 3.75 12Z"
      />
    </svg>
  );
};

export default Check;

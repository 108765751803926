export class BulkSaveMissingStatus extends Error {
    /**
     * @param id: id of the campaign missing a status.
     */
    constructor(id) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, BulkSaveMissingStatus);
        }
        this.name = "BulkSaveMissingStatus";
        this.message = `Bulk campaign updates require a status be set for each campaign.${id ? ` '${id}' is missing one.` : ""}`;
    }
}

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const PaceToBudget: FC<CommonIconProps> = ({ fill = "#5C6370" }) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 14 14" fill="none">
      <path
        d="M7.17945 2.8976C7.17945 2.61438 6.94985 2.38478 6.66663 2.38478C6.38341 2.38478 6.15381 2.61438 6.15381 2.8976V3.41042H6.05603C4.97691 3.41042 4.10253 4.28481 4.10253 5.36393C4.10253 6.25965 4.71176 7.04038 5.58051 7.25827L7.50325 7.74032C7.91584 7.84382 8.20509 8.21454 8.20509 8.63983C8.20509 9.15235 7.78938 9.56769 7.27723 9.56769H6.72617C6.70664 9.56544 6.68677 9.56427 6.66663 9.56427C6.64649 9.56427 6.62663 9.56544 6.6071 9.56769H6.15381C5.58712 9.56769 5.12817 9.10874 5.12817 8.54205H4.10253C4.10253 9.67519 5.02067 10.5933 6.15381 10.5933V11.1027C6.15381 11.386 6.38341 11.6156 6.66663 11.6156C6.94985 11.6156 7.17945 11.386 7.17945 11.1027V10.5933H7.27723C8.35551 10.5933 9.23074 9.71911 9.23074 8.63983C9.23074 7.7441 8.62151 6.96337 7.75275 6.74549L5.83001 6.26344C5.41742 6.15994 5.12817 5.78922 5.12817 5.36393C5.12817 4.85125 5.54336 4.43606 6.05603 4.43606H7.17945C7.74614 4.43606 8.20509 4.89501 8.20509 5.46171H9.23074C9.23074 4.32857 8.31259 3.41042 7.17945 3.41042V2.8976Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.3807 11.7142C13.9842 9.11072 13.9842 4.88962 11.3807 2.28612C8.77722 -0.317379 4.55612 -0.317378 1.95263 2.28612C-0.650876 4.88961 -0.650874 9.11071 1.95262 11.7142C4.55611 14.3177 8.77721 14.3177 11.3807 11.7142ZM2.67786 3.01136C4.88082 0.808398 8.45252 0.808399 10.6555 3.01136C12.8582 5.21434 12.8584 8.7861 10.6555 10.989C8.45251 13.1919 4.88081 13.1919 2.67786 10.989C0.474901 8.78601 0.474904 5.2143 2.67786 3.01136Z"
        fill={fill}
      />
    </svg>
  );
};

export default PaceToBudget;

import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";

const Pending: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-circled-pending"
      width="100%"
      height="100%"
      viewBox="0 0 14 14"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.88629 1.85478C4.04822 2.07846 3.99817 2.39106 3.77449 2.55299C3.42155 2.8085 3.101 3.1024 2.82022 3.43129C2.64093 3.64131 2.32533 3.66622 2.11531 3.48693C1.90529 3.30763 1.88038 2.99203 2.05968 2.78201C2.39357 2.3909 2.77302 2.04347 3.18808 1.74298C3.41176 1.58105 3.72436 1.6311 3.88629 1.85478Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7487 11.7096C10.9024 11.939 10.841 12.2495 10.6116 12.4032C9.57874 13.0952 8.3364 13.4998 6.99997 13.4998C6.34122 13.4998 5.70626 13.4001 5.10789 13.2182C4.84368 13.1379 4.69461 12.8586 4.77492 12.5944C4.85523 12.3302 5.13452 12.1811 5.39873 12.2615C5.90569 12.4156 6.44273 12.4998 6.99997 12.4998C8.13155 12.4998 9.18121 12.1578 10.055 11.5724C10.2844 11.4188 10.595 11.4801 10.7487 11.7096Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.51563 4.52884C1.77592 4.62105 1.91218 4.9068 1.81997 5.1671C1.6156 5.74398 1.5 6.35828 1.5 7.00014C1.5 7.27628 1.27614 7.50014 1 7.50014C0.723858 7.50014 0.5 7.27628 0.5 7.00014C0.5 6.23666 0.63773 5.50963 0.877366 4.83318C0.969576 4.57288 1.25534 4.43663 1.51563 4.52884Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.47534 4.8335V5.4815C5.47534 5.51491 5.48602 5.54958 5.50761 5.57977C5.5075 5.57963 5.50771 5.57991 5.50761 5.57977L6.32367 6.70695C6.45031 6.88186 6.45035 7.11831 6.32376 7.29327L5.50597 8.42348C5.48673 8.44986 5.47534 8.48367 5.47534 8.5195V9.16683H8.52468V8.5315C8.52468 8.49442 8.51224 8.45842 8.48988 8.42964L7.6189 7.30658C7.47904 7.12624 7.47904 6.87408 7.6189 6.69375L8.48957 5.57108C8.51193 5.5423 8.52468 5.5059 8.52468 5.46883V4.8335H5.47534ZM4.47534 4.66683C4.47534 4.20669 4.84853 3.8335 5.30868 3.8335H8.69134C9.15148 3.8335 9.52468 4.20669 9.52468 4.66683V5.46883C9.52468 5.72767 9.4385 5.97959 9.27963 6.18411C9.27958 6.18418 9.27968 6.18404 9.27963 6.18411L8.64675 7.00016L9.27947 7.81602C9.27942 7.81595 9.27952 7.81608 9.27947 7.81602C9.43834 8.02054 9.52468 8.27265 9.52468 8.5315V9.3335C9.52468 9.79364 9.15148 10.1668 8.69134 10.1668H5.30868C4.84853 10.1668 4.47534 9.79364 4.47534 9.3335V8.5195C4.47534 8.27567 4.55174 8.03516 4.69742 7.83504C4.69763 7.83475 4.69784 7.83447 4.69805 7.83418L5.30145 7.00025L4.6961 6.16413C4.55389 5.96642 4.47534 5.72792 4.47534 5.4815V4.66683Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.99997 1.5C6.44273 1.5 5.90569 1.58428 5.39873 1.73839C5.13452 1.8187 4.85523 1.66963 4.77492 1.40542C4.69461 1.14121 4.84368 0.861927 5.10789 0.781614C5.70626 0.599721 6.34122 0.5 6.99997 0.5C10.5901 0.5 13.5 3.40986 13.5 7C13.5 8.34176 13.0851 9.58087 12.39 10.6127C12.2357 10.8417 11.925 10.9023 11.696 10.748C11.4669 10.5937 11.4063 10.283 11.5606 10.054C12.1509 9.1778 12.5 8.13157 12.5 7C12.5 3.96214 10.0378 1.5 6.99997 1.5Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.18251 8.52902C1.4427 8.43652 1.72861 8.57247 1.82111 8.83266C2.19716 9.8905 2.88315 10.8006 3.77508 11.4482C3.99854 11.6104 4.04817 11.9231 3.88593 12.1466C3.72369 12.37 3.41103 12.4196 3.18757 12.2574C2.13283 11.4916 1.32282 10.4164 0.878873 9.16761C0.786377 8.90742 0.922321 8.62151 1.18251 8.52902Z"
        fill={fill}
      />
    </svg>
  );
};

export default Pending;

import { Dispatch, SetStateAction, useState, useEffect } from "react";
import { useDebouncedValue } from "../useDebouncedValue";

/**
 * Hook to delay change effects propagating from search inputs while maintaining
 * a controlled local input state. This allows a user's typing to render on-screen
 * without each change event triggering any other (expensive) operations.
 *
 * Usage:
 *
 * ```
 * const [debouncedSearchTerm, setDebouncedSearchTerm] = useState("");
 *
 * useEffect(() => {
 *  // very expensive operation
 * }, [debouncedSearchTerm]);
 *
 * const [rawSearchTerm, setRawSearchTerm] = useDebouncedSearch(
 *  setDebouncedSearchTerm,
 *  debouncedSearchTerm
 * );
 *
 * <input type="text" onChange={setRawSearchTerm}>{rawSearchTerm}</input>
 *
 * // As the user types into a text input, `rawSearchTerm` will be updated as they type,
 * // But the value of `debouncedSearchTerm` will only change after they have stopped
 * // typing for at least `SEARCH_DEBOUNCE_DELAY` milliseconds
 * ```
 * */

export const SEARCH_DEBOUNCE_DELAY = 300; // milliseconds

export const useDebouncedSearch = (
  onDebouncedSearchTermChange: (newDebouncedSearchTerm: string) => any,
  initialTerm: string = "",
  delay: number = SEARCH_DEBOUNCE_DELAY
): [string, Dispatch<SetStateAction<string>>] => {
  const [rawSearchTerm, setRawSearchTerm] = useState(initialTerm);

  const debouncedSearchTerm = useDebouncedValue(rawSearchTerm, delay);

  useEffect(() => {
    onDebouncedSearchTermChange(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return [rawSearchTerm, setRawSearchTerm];
};

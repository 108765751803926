import { css } from "@emotion/react";
import { kitText } from "../../../styles";

export const styles = {
  singleWrapper: css`
    --icon-fill-color: var(--color-splash-primary);
    --color-background-selected: #e7e9ef; // remove when value is corrected in Burns
    background-color: var(--color-background-primary);
    border: none;
    user-select: none;
    width: 100%;
    padding: var(--spacing-8);
    border-radius: 2px;
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-8);
    align-items: center;
    cursor: pointer;
    .clear-item-icon {
      opacity: 0;
    }
    &:hover {
      background-color: var(--color-background-tertiary);
    }
    &:focus {
      outline: none;
      background-color: var(--color-background-tertiary);
    }
  `,
  iconAndLabel: css`
    min-width: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: var(--spacing-8);
  `,
  label: css`
    ${kitText.variant.body};
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  selected: css`
    background-color: var(--color-background-selected);
    cursor: default;
    &:hover,
    &:focus {
      background-color: var(--color-background-selected);
    }
  `,
  isDisabled: css`
    cursor: not-allowed;
    pointer-events: none;
    background: var(--color-background-tertiary);
    color: var(--color-tertiary);
    --icon-fill-color: var(--color-tertiary);
  `,
  clearableSelected: css`
    &:hover,
    &:focus {
      cursor: pointer;
      .clear-item-icon {
        opacity: 1;
      }
    }
  `
};

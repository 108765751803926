import { keyBy } from "lodash";
import {
  GET_FW_PRODUCTS_PENDING,
  GET_FW_PRODUCTS_SUCCESS,
  GET_FW_PRODUCTS_FAILURE,
  FreewheelProductsState,
  FwProductsActionTypes
} from "./types";

export const freewheelProductsInitialState: FreewheelProductsState = {
  byId: {},
  legacyById: {},
  isAllLoading: false,
  receivedAllAt: null,
  error: false
};

export const freewheelProductsReducer = (
  state = freewheelProductsInitialState,
  action: FwProductsActionTypes
): FreewheelProductsState => {
  switch (action.type) {
    case GET_FW_PRODUCTS_PENDING:
      return {
        ...state,
        isAllLoading: true,
        error: false
      };
    case GET_FW_PRODUCTS_SUCCESS:
      return {
        ...state,
        byId: keyBy(action.payload.products, "id"),
        legacyById: keyBy(action.payload.legacyProducts, "id"),
        isAllLoading: false,
        receivedAllAt: action.meta.timestamp,
        error: false
      };
    case GET_FW_PRODUCTS_FAILURE:
      return {
        ...state,
        isAllLoading: false,
        error: true
      };
    default:
      return state;
  }
};

import { FC } from "react";
import { FourDirection, CommonIconProps } from "./types";

type Props = CommonIconProps & {
  direction?: FourDirection;
};

const chevronPaths: Record<FourDirection, string> = {
  up: "M11.47 9.47a.75.75 0 0 1 1.06 0l4 4a.75.75 0 1 1-1.06 1.06L12 11.06l-3.47 3.47a.75.75 0 0 1-1.06-1.06l4-4Z",
  down: "M7.47 9.47a.75.75 0 0 1 1.06 0L12 12.94l3.47-3.47a.75.75 0 1 1 1.06 1.06l-4 4a.75.75 0 0 1-1.06 0l-4-4a.75.75 0 0 1 0-1.06Z",
  left: "M14.53 7.47a.75.75 0 0 1 0 1.06L11.06 12l3.47 3.47a.75.75 0 1 1-1.06 1.06l-4-4a.75.75 0 0 1 0-1.06l4-4a.75.75 0 0 1 1.06 0Z",
  right:
    "M9.47 7.47a.75.75 0 0 1 1.06 0l4 4a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 1 1-1.06-1.06L12.94 12 9.47 8.53a.75.75 0 0 1 0-1.06Z"
};

const Chevron: FC<Props> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)",
  direction = "down"
}) => {
  const selectedPath = chevronPaths[direction] ?? chevronPaths.down;

  return (
    <svg
      className={`icon-chevron-${direction}`}
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path fillRule="evenodd" clipRule="evenodd" d={selectedPath} />
    </svg>
  );
};

export default Chevron;

import { NotImplementedError } from "../../../errors";
import { Handler } from "../../handlers";
import { PlanStatus, isIncompletePlanStatus, isServiceScenario, serviceToClientPlan, toClientScenarioResults } from "../../../types";
import axios from "axios";
import { parseInclusionFilter } from "../../handlers/filter";
import { BadScenarioSavePayload, CorruptedScenarioData, ExpiredScenario, ScenarioFetchFailed, ScenarioSaveFailure } from "../errors";
const SCENARIO_GCS_BUCKET = "x-screen-planner";
class LegacyScenarios extends Handler {
    constructor(sdk) {
        super(sdk, "scenarios");
    }
    setPlanPoll(poller) {
        this.poller = poller;
    }
    /**
     * @param filters: 'Where' filters for planId (parent plan's id) and id
     * (scenario's id) must be passed, or the method will throw.
     * @returns: If the status of a scenario is pending, failed, or unknown in
     * the backend, this method returns that status. Otherwise it returns a
     * single, formatted scenario with forecast results included. This method
     * should not be used to fetch multiple scenarios; fetching a plan should
     * suffice for that use case.
     * */
    async findItems(filters) {
        const scenarioIdFilter = parseInclusionFilter(filters, "id", ["string"]);
        const planIdFilter = parseInclusionFilter(filters, "planId", ["string"]);
        if (scenarioIdFilter.size !== 1 || planIdFilter.size !== 1) {
            throw new ScenarioFetchFailed();
        }
        const scenarioId = scenarioIdFilter.values().next().value;
        const planId = planIdFilter.values().next().value;
        const possiblyScenarioData = await this.cache.promise(scenarioId, () => this.getScenario(planId, scenarioId));
        if (isIncompletePlanStatus(possiblyScenarioData)) {
            // don't cache the scenario if the result is a status, as the status will more than likely change quickly
            this.cache.remove(scenarioId);
            return [possiblyScenarioData];
        }
        return [possiblyScenarioData];
    }
    async getScenario(planId, scenarioId) {
        // for now, to get a single scenario we need to fetch an entire plan
        // this will change after FORECAST-32
        const scenario = await axios
            .get(this.collectionUrl(planId))
            .then((res) => serviceToClientPlan(res.data).scenarios.find(({ id }) => id === scenarioId))
            .catch(() => {
            throw new ScenarioFetchFailed();
        });
        if (!scenario) {
            throw new ScenarioFetchFailed();
        }
        if (!scenario.forecastId) {
            if (scenario.status === PlanStatus.READY) {
                throw new CorruptedScenarioData();
            }
            if (scenario.status === PlanStatus.EXPIRED) {
                throw new ExpiredScenario();
            }
            return scenario.status;
        }
        return this.sdk.madFire
            .getDownloadUrl(`forecast/${scenario.forecastId}.json`, `gs://${this.sdk.madFire.projectId}-${SCENARIO_GCS_BUCKET}`)
            .then((downloadUrl) => axios
            .get(downloadUrl, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => res.data))
            .then((scenarioData) => toClientScenarioResults(scenarioData, scenario))
            .catch(() => {
            throw new ScenarioFetchFailed();
        });
    }
    async saveItem(scenario) {
        let planId;
        if (Array.isArray(scenario)) {
            planId = scenario[0].plan_id;
        }
        else {
            planId = isServiceScenario(scenario) ? scenario.plan_id : scenario.planId;
        }
        if (!planId) {
            throw new BadScenarioSavePayload();
        }
        /**
         * In the backend API, there's no decoupling of plans and scenarios (in
         * the way that, for example, campaigns and line items have their own
         * distinct endpoints); to update a scenario, you need to pass the
         * entire plan object to the API. As a result, splitting plans and
         * scenarios into separate modules in madSDK requires some
         * unconventional behavior. Below, we fetch the scenario's parent plan
         * to satisfy the API schema and avoid re-running expensive forecast
         * jobs (see comment in this.update).
         * */
        const plan = await axios.get(this.collectionUrl(planId)).then((res) => res.data);
        if (Array.isArray(scenario) || isServiceScenario(scenario)) {
            return this.create(scenario, plan).catch(() => {
                new ScenarioSaveFailure();
            });
        }
        return this.update(scenario, plan).catch(() => {
            new ScenarioSaveFailure();
        });
    }
    async create(scenario, plan) {
        const updatedPlan = {
            name: plan.name,
            scenarios: Array.isArray(scenario) ? scenario : [scenario]
        };
        await axios.put(this.collectionUrl(plan.id), updatedPlan, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
    async update(scenario, plan) {
        /**
         * The only part of a scenario that can be updated is its name.
         * However, when updating a scenario, the EXACT plan and scenario body
         * must be passed to the API, or it will trigger a re-processing of the
         * scenario forecast (this would be extremely wasteful, given that we
         * only want to update a name). The safest way to ensure this happens
         * is to fetch the parent plan and use it for the PUT payload.
         */
        const update = {
            ...plan,
            scenarios: plan.scenarios
                .filter((serviceScenario) => serviceScenario.id === scenario.id)
                .map((serviceScenario) => ({
                ...serviceScenario,
                ott_constraints: {
                    ...serviceScenario.ott_constraints,
                    // Backend tech debt: we receive device caps as numbers, but must pass them back to the API as strings.
                    device_caps: serviceScenario.ott_constraints.device_caps.map(({ type, cap }) => ({
                        type,
                        cap: typeof cap === "string" ? parseInt(cap, 10) : cap
                    }))
                },
                name: scenario.name.trim()
            }))
        };
        return axios
            .put(`${this.collectionUrl(plan.id)}?skip_processing_if_possible=true`, update, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
            if (scenario.id) {
                this.cache.remove(scenario.id);
            }
            const clientPlan = serviceToClientPlan(res.data);
            this.poller?.updateSubscribers([clientPlan]);
        });
    }
    /**
     * Deletes a scenario based on given ID.
     *
     * @param id ID of the scenario to be deleted.
     */
    async deleteItem(id) {
        await axios.delete(`${this.sdk.urls.basePlannerServiceUrl}/scenario/${id}`);
        this.cache.remove(id);
        if (this.poller) {
            this.poller.updateSubscribers((prevPlanState) => (prevPlanState || []).map((plan) => ({
                ...plan,
                scenarios: plan.scenarios.filter((scenario) => scenario.id !== id)
            })));
        }
    }
    collectionUrl(planId) {
        return `${this.sdk.urls.basePlannerServiceUrl}/plan/${planId}`;
    }
    /**
     * Make isn't implemented for scenarios.
     */
    make() {
        throw new NotImplementedError("make");
    }
}
export default LegacyScenarios;

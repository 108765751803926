import { selectCurrentUser } from "appReducers/authReducer";
import { showError, showSuccess } from "appReducers/toasterReducer/actions";
import {
  generateColumnsFromCustomization,
  clearColumnCustomization as requestColumnCustomizationClear,
  saveColumnCustomization as requestColumnCustomizationSave
} from "features/ManageCampaigns/utils";
import { AppThunk } from "types";
import { getRawRootCampaigns } from "api/manageCampaigns";
import axios, { CancelTokenSource } from "axios";
import { InstructionFilterCategory } from "features/ManageCampaigns/constants";
import { ManageCampaignColumn } from "@madhive/mad-sdk";
import { MESSAGE_GETTER_DICTIONARY } from "./constants";
import {
  selectCampaignsDashboard,
  selectCampaignSelectableValuesCancellationSource
} from "./selectors";
import {
  CampaignsDashboardActionTypes,
  CampaignsDashboardState,
  UPDATE_CAMPAIGNS_DASHBOARD,
  COPY_APPLIED_FILTERS_TO_SELECTED_FILTERS,
  CampaignIdAndName,
  SET_CAMPAIGNS_SELECTABLE_VALUES_SUCCESS,
  SET_CAMPAIGNS_SELECTABLE_VALUES_FAILURE,
  SET_CAMPAIGNS_SELECTABLE_VALUES_PENDING
} from "./types";
import { createUrlParamsWithAppliedFilters } from "../actions";
import { WITHOUT_GEO } from "../utils";

export const updateCampaignsDashboard = (
  dashboardState: Partial<CampaignsDashboardState>
): CampaignsDashboardActionTypes => ({
  type: UPDATE_CAMPAIGNS_DASHBOARD,
  payload: dashboardState
});

export const copyAppliedFiltersToSelectedFilters =
  (): CampaignsDashboardActionTypes => ({
    type: COPY_APPLIED_FILTERS_TO_SELECTED_FILTERS
  });

// util to generate actions for updating campaign dashboard fields
const createCampaignDashboardUpdater =
  <T extends keyof CampaignsDashboardState>(keyName: T) =>
  (newValue: CampaignsDashboardState[typeof keyName]): AppThunk<void> =>
  async dispatch =>
    dispatch(updateCampaignsDashboard({ [keyName]: newValue }));

export const setSearchTerm = createCampaignDashboardUpdater("searchTerm");

export const setAppliedFilters =
  createCampaignDashboardUpdater("appliedFilters");

export const setSelectedFilters =
  createCampaignDashboardUpdater("selectedFilters");

export const setSelectedTabId = createCampaignDashboardUpdater("selectedTabId");

export const setIsSettingsDrawerOpen = createCampaignDashboardUpdater(
  "isSettingsDrawerOpen"
);

export const setIsFilterDialogOpen =
  createCampaignDashboardUpdater("isFilterDialogOpen");

export const setIsDownloadDialogOpen = createCampaignDashboardUpdater(
  "isDownloadDialogOpen"
);

export const setIsCreativeDeliveryLoading = createCampaignDashboardUpdater(
  "isCreativeDeliveryLoading"
);

export const setIsBulkAssignDrawerOpen = createCampaignDashboardUpdater(
  "isBulkAssignDrawerOpen"
);

export const setIsBulkEditing = createCampaignDashboardUpdater("isBulkEditing");

export const setDates = createCampaignDashboardUpdater("dates");

export const setIsFilterByAdvertiserClicked = createCampaignDashboardUpdater(
  "isFilterByAdvertiserClicked"
);

export const toggleCampaignExpand =
  (campaignId: string): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const { expandedCampaignIds } = selectCampaignsDashboard(getState());
    if (expandedCampaignIds.has(campaignId)) {
      expandedCampaignIds.delete(campaignId);
    } else {
      expandedCampaignIds.add(campaignId);
    }
    dispatch(
      updateCampaignsDashboard({
        expandedCampaignIds: new Set(expandedCampaignIds)
      })
    );
  };

export const toggleLineItemExpand =
  (lineItemId: string): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const { expandedLineItemIds } = selectCampaignsDashboard(getState());
    if (expandedLineItemIds.has(lineItemId)) {
      expandedLineItemIds.delete(lineItemId);
    } else {
      expandedLineItemIds.add(lineItemId);
    }
    dispatch(
      updateCampaignsDashboard({
        expandedLineItemIds: new Set(expandedLineItemIds)
      })
    );
  };

export const saveColumnCustomization =
  (
    userId: string,
    columnCustomization: ManageCampaignColumn[]
  ): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const user = selectCurrentUser(getState());
      const res = await requestColumnCustomizationSave(
        userId,
        columnCustomization
      );
      dispatch(
        updateCampaignsDashboard({
          columnsForUser: generateColumnsFromCustomization(
            user,
            columnCustomization
          )
        })
      );
      dispatch(
        showSuccess(MESSAGE_GETTER_DICTIONARY.UPDATE_TABLE_SETTINGS_SUCCESS())
      );
      return Promise.resolve(res);
    } catch (error) {
      dispatch(
        showError(MESSAGE_GETTER_DICTIONARY.UPDATE_TABLE_SETTINGS_FAILURE())
      );
      return Promise.reject(error);
    }
  };

export const clearColumnCustomization =
  (userId: string): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    try {
      const user = selectCurrentUser(getState());
      const res = await requestColumnCustomizationClear(userId);
      dispatch(
        updateCampaignsDashboard({
          columnsForUser: generateColumnsFromCustomization(user, null)
        })
      );
      dispatch(
        showSuccess(MESSAGE_GETTER_DICTIONARY.UPDATE_TABLE_SETTINGS_SUCCESS())
      );
      return Promise.resolve(res);
    } catch (error) {
      dispatch(
        showError(MESSAGE_GETTER_DICTIONARY.UPDATE_TABLE_SETTINGS_FAILURE())
      );
      return Promise.reject(error);
    }
  };

export const setCampaignsSelectableValuesSuccess = (
  values: CampaignIdAndName[],
  query: string
): CampaignsDashboardActionTypes => ({
  type: SET_CAMPAIGNS_SELECTABLE_VALUES_SUCCESS,
  values,
  query
});

export const setCampaignsSelectableValuesPending = (
  cancellationSource: CancelTokenSource
): CampaignsDashboardActionTypes => ({
  type: SET_CAMPAIGNS_SELECTABLE_VALUES_PENDING,
  cancellationSource
});

export const setCampaignsSelectableValuesFailure =
  (): CampaignsDashboardActionTypes => ({
    type: SET_CAMPAIGNS_SELECTABLE_VALUES_FAILURE
  });

export const getCampaignsForSelectableValues =
  (searchTerm: string): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const prevCancelSource = selectCampaignSelectableValuesCancellationSource(
      getState()
    );
    const { dates: dashboardDates, selectedFilters } = selectCampaignsDashboard(
      getState()
    );
    const advertiserAndAgencyFilters = selectedFilters.filter(
      a =>
        a.category === InstructionFilterCategory.ADVERTISER ||
        a.category === InstructionFilterCategory.AGENCY
    );
    const filtersParam = createUrlParamsWithAppliedFilters(
      advertiserAndAgencyFilters
    );

    if (prevCancelSource) {
      prevCancelSource.cancel();
    }

    const datesToSend = {
      startDate: dashboardDates.startDate.toISOString(),
      endDate: dashboardDates.endDate.toISOString()
    };

    const cancelTokenSource = axios.CancelToken.source();
    dispatch(setCampaignsSelectableValuesPending(cancelTokenSource));
    try {
      const campaignsResponse = await getRawRootCampaigns(cancelTokenSource, {
        page_size: "50",
        withoutGeo: WITHOUT_GEO.toString(),
        runsBetween: `${datesToSend.startDate}|${datesToSend.endDate}`,
        fields: "name",
        ...(searchTerm && { search: searchTerm }),
        ...filtersParam
      });
      dispatch(
        setCampaignsSelectableValuesSuccess(
          campaignsResponse.campaigns?.map(campaign => ({
            id: campaign.id,
            name: campaign.name
          })) || [],
          searchTerm
        )
      );
    } catch (error) {
      if (axios.isCancel(error) || error.name === "AbortError") {
        console.log("Campaigns endpoint request aborted");
      } else {
        dispatch(setCampaignsSelectableValuesFailure());
        throw error;
      }
    }
  };

import { getDetailedErrorMessage } from "../../utils";
export class LanguageContentObjectsFetchFailed extends Error {
    /**
     * @param apiError: the error received from the API.
     * @param fallbackMessage: the message to use for the error, if the API error does not contain anything relevant.
     */
    constructor(apiError, fallbackMessage = "Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, LanguageContentObjectsFetchFailed);
        }
        this.name = "LanguageContentObjectsFetchFailed";
        this.message = `Failed to fetch language(s). ${getDetailedErrorMessage(apiError, fallbackMessage)}`;
    }
}
export class GenreContentObjectsFetchFailed extends Error {
    /**
     * @param apiError: the error received from the API.
     * @param fallbackMessage: the message to use for the error, if the API error does not contain anything relevant.
     */
    constructor(apiError, fallbackMessage = "Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, GenreContentObjectsFetchFailed);
        }
        this.name = "GenreContentObjectsFetchFailed";
        this.message = `Failed to fetch genre(s). ${getDetailedErrorMessage(apiError, fallbackMessage)}`;
    }
}

import { NotImplementedError } from "../../errors";
import axios from "axios";
import { UXConfigHandler } from "../handlers/ux";
class Switches extends UXConfigHandler {
    constructor(sdk) {
        super(sdk, "switches");
    }
    /**
     * Currently we don't need to support filtering on the find
     * @returns
     */
    async findItems() {
        const res = await axios.get(`${this.sdk.urls.baseAPIUrl}/ux/switches`);
        return res.data.data || [];
    }
    /**
     * API to save switches takes an array of partial or full switches and will update/create them
     * @param switchData The switch data to update
     * @returns Promise that will resolve with the created/updated switch
     */
    async saveItem(switchData) {
        return axios
            .post(`${this.sdk.urls.baseAPIUrl}/ux/switches`, [switchData], {
            headers: { "Content-Type": "application/json" }
        })
            .then((saved) => {
            return saved.data.data[0];
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async deleteItem() {
        throw new NotImplementedError("delete");
    }
}
export default Switches;

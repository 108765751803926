import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Notification: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-notification"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.41699 4.833C9.41699 3.40705 10.5725 2.25 12 2.25C13.4275 2.25 14.583 3.40705 14.583 4.833V5.17847C16.6784 5.87912 18.188 7.85754 18.188 10.188V12.985C18.188 13.3159 18.3198 13.6342 18.5543 13.8687L19.1953 14.5097C19.7108 15.0252 20.001 15.7249 20.001 16.454C20.001 17.9122 18.8192 19.094 17.361 19.094H15.0178C14.8281 20.5919 13.5495 21.75 12 21.75C10.4505 21.75 9.17147 20.5917 8.98216 19.094H6.64C5.18179 19.094 4 17.9122 4 16.454C4 15.7249 4.29018 15.0252 4.80567 14.5097L5.44667 13.8687C5.68118 13.6342 5.813 13.3159 5.813 12.985V10.188C5.813 7.85712 7.32269 5.87966 9.41699 5.17884V4.833ZM10.917 4.90992C10.9758 4.90799 11.0348 4.90701 11.094 4.90701H12.907C12.9659 4.90701 13.0246 4.90798 13.083 4.90989V4.833C13.083 4.23495 12.5985 3.75 12 3.75C11.4015 3.75 10.917 4.23495 10.917 4.833V4.90992ZM9.71429 17.594C9.7122 17.594 9.7101 17.594 9.70801 17.594C9.70591 17.594 9.70382 17.594 9.70173 17.594H6.64C6.01021 17.594 5.5 17.0838 5.5 16.454C5.5 16.1231 5.63182 15.8048 5.86633 15.5703L6.50733 14.9293C7.02282 14.4138 7.313 13.7142 7.313 12.985V10.188C7.313 8.09933 9.00611 6.40701 11.094 6.40701H12.907C14.9957 6.40701 16.688 8.10012 16.688 10.188V12.985C16.688 13.7142 16.9782 14.4138 17.4937 14.9293L18.1347 15.5703C18.3692 15.8049 18.501 16.1231 18.501 16.454C18.501 17.0838 17.9908 17.594 17.361 17.594H14.3316C14.3185 17.5933 14.3053 17.593 14.292 17.593C14.2787 17.593 14.2655 17.5933 14.2524 17.594H9.71429ZM10.5065 19.094C10.6776 19.7585 11.2812 20.25 12 20.25C12.7185 20.25 13.322 19.7589 13.4933 19.094H10.5065Z"
      />
    </svg>
  );
};

export default Notification;

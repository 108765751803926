import { Base } from "../handlers/base";
import { ObjType } from "../../types";
import { NoFileGiven, MintedKeyFailure, InvalidResultFromUpload, NoMetadata, NoAuthorizedUser } from "../../errors";
import { getStorageLocation, getFileExtensionFromFileType } from "../../models/creatives";
import { ref, getMetadata, uploadBytesResumable, updateMetadata, getDownloadURL } from "firebase/storage";
import { isVast } from "../../models/creatives/vast";
import { validateAsset } from "./validators/asset";
import { isValidUrlWithProtocol } from "../../utils";
import Specifications from "./specifications";
class Asset extends Base {
    constructor(sdk) {
        super(sdk, "creative-asset");
        /**
         *
         * @param creative to attempt to upload or handle a url
         * @returns new creative
         */
        this.create = async (creative) => {
            // Going forward we will support having either
            // file or a url as an asset, this code will determine
            // is the url a vasturl or cdn
            if (creative.asset?.file) {
                // If the creative has a file we need to upload it
                const locationStorage = getStorageLocation(creative.id ? creative.id : "", !!creative.asset.isBulk, getFileExtensionFromFileType(creative.asset.file.type));
                const uploadedAsset = await this.uploadFileToPath(creative.asset.file, locationStorage);
                return {
                    ...creative,
                    cloudStorageUrl: uploadedAsset.url
                };
            }
            if (creative.asset?.url) {
                // validateAsset 400s then we have most likely been
                // given a vastUrl and need to treat it as such
                try {
                    await validateAsset(creative.asset.url, this.sdk.urls.madhiveEncoderBaseUrl);
                    return {
                        ...creative,
                        cloudStorageUrl: creative.asset.url
                    };
                }
                catch (error) {
                    console.error(error);
                    return {
                        ...creative,
                        vastUrl: creative.asset.url
                    };
                }
            }
            return creative;
        };
        /**
         * After uploading a asset we need to be able to update that metadata about that asset
         * @param asset The asset that was uploaded
         * @param url Url is the location of the asset to be updated
         */
        this.updateFileMetadata = async (asset, url) => {
            if (!asset.metadata) {
                throw new NoMetadata();
            }
            const reference = ref(this.sdk.madFire.dropStorage, url);
            const metadata = {
                customMetadata: {
                    user: asset.metadata.customMetadata.user,
                    organization: asset.metadata.customMetadata.organization,
                    height: asset.metadata.customMetadata.height || "0",
                    width: asset.metadata.customMetadata.width || "0",
                    creativeMediaKey: asset.metadata.customMetadata.creativeMediaKey || ""
                }
            };
            await updateMetadata(reference, metadata);
        };
        /**
         * Handles uploading files.
         * TODO: Review is this should live in a different collection specifically for uploading files
         * @param file file to be uploaded
         * @param path id of where to upload the file
         * @param onUploadUpdate call back for process tracking
         * @return Url of the stored location
         */
        this.uploadFileToPath = async (file, path, onUploadUpdate) => {
            const reference = ref(this.sdk.madFire.dropStorage, path);
            const task = uploadBytesResumable(reference, file);
            return new Promise((resolve, reject) => {
                task.on("state_changed", 
                // TODO: this should not be any
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (snapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    if (onUploadUpdate) {
                        onUploadUpdate(+snapshot.bytesTransferred);
                    }
                }, (uploadError) => {
                    reject(uploadError);
                }, async () => {
                    try {
                        const downloadURL = await getDownloadURL(task.snapshot.ref);
                        if (!task.snapshot.metadata.md5Hash || !task.snapshot.metadata.contentType) {
                            throw new NoMetadata();
                        }
                        // asset hashes stored in hex format
                        resolve({
                            hash: task.snapshot.metadata.md5Hash,
                            filename: file.name,
                            fileSize: task.snapshot.metadata.size,
                            url: downloadURL,
                            gs: `gs://${task.snapshot.ref.bucket}/${task.snapshot.ref.fullPath}`,
                            contentType: task.snapshot.metadata.contentType,
                            lastModified: new Date(file.lastModified)
                        });
                    }
                    catch (e) {
                        reject(e);
                    }
                });
            });
        };
        /**
         * handles uploading and/or validating user submitted asset
         * asset can be in form of:
         * - file (uploaded from computer)
         * - cdn url
         * - third-party tag (basic or vast)
         * - vast url
         */
        this.submit = {
            /**
             * handles uploading user submitted file
             * @param file: file user has submitted from desktop
             * @param onUploadUpdate Callback method that is called on status updates of the upload
             */
            file: (file, onUploadUpdate) => {
                const user = this.sdk.getCurrentUser();
                if (!user) {
                    throw new NoAuthorizedUser();
                }
                const asset = {
                    file,
                    metadata: {
                        customMetadata: {
                            user: user.email,
                            organization: user.primaryOrganizationName
                        }
                    }
                };
                return this.uploadFile(asset, onUploadUpdate);
            },
            /**
             * handles user submitted cdn url
             * @param url: cdn url
             */
            cdn: (url) => {
                return validateAsset(url, this.sdk.urls.madhiveEncoderBaseUrl);
            },
            /**
             * handles user submitted tag or vast url
             * @param tagOrVastUrl: this can be a tag, vast tag, or vast url
             * @param onUploadUpdate Callback method that is called on status updates of the upload
             */
            tag: (tagOrVastUrl) => {
                if (isValidUrlWithProtocol(tagOrVastUrl) || isVast(tagOrVastUrl)) {
                    return validateAsset(tagOrVastUrl, this.sdk.urls.madhiveEncoderBaseUrl);
                }
                return validateAsset(tagOrVastUrl, this.sdk.urls.madhiveEncoderBaseUrl);
            }
        };
        /**
         * This currently only supports getting non bulk uploaded
         * information.
         * @param id Id of the creative to get meta for
         */
        this.getFileMetaData = async (id) => {
            const path = getStorageLocation(id, false, "mp4");
            const reference = ref(this.sdk.madFire.dropStorage, path);
            return getMetadata(reference);
        };
        this.specifications = new Specifications(sdk);
    }
    /**
     * Uploads a single asset to our madhive storage locations
     * @param asset Asset to be uploaded
     * @param onUploadUpdate Callback method that is called on status updates of the upload
     * @returns ValidationDetails of the uploaded asset.
     */
    async uploadFile(asset, onUploadUpdate) {
        if (!asset.file) {
            throw new NoFileGiven();
        }
        // We need a ID when just uploading a file not attached to any creative
        const mintedKey = await this.sdk.cryptography.mintKey(ObjType.CREATIVE_VID);
        if (!mintedKey) {
            throw new MintedKeyFailure();
        }
        // With single upload we used the path for non-bulk storage
        const path = getStorageLocation(mintedKey, false, getFileExtensionFromFileType(asset.file.type));
        const uploadResults = await this.uploadFileToPath(asset.file, path, onUploadUpdate);
        if (asset.metadata) {
            // metadata given use it to update meta for the given file
            await this.updateFileMetadata({
                ...asset,
                metadata: {
                    customMetadata: {
                        ...asset.metadata.customMetadata,
                        height: uploadResults?.validationDetails?.assetMetadata.dimensions?.height?.toString(),
                        width: uploadResults?.validationDetails?.assetMetadata.dimensions?.width?.toString(),
                        creativeMediaKey: uploadResults.id
                    }
                }
            }, path);
        }
        // we validate the upload when doing a direct upload.
        if (typeof uploadResults.url === "string") {
            const validationDetails = await validateAsset(uploadResults.url, this.sdk.urls.madhiveEncoderBaseUrl);
            const results = {
                ...uploadResults,
                id: mintedKey,
                validationDetails: {
                    ...uploadResults
                }
            };
            ["problems", "specificationsCheckedAgainst", "macroReplacements"].forEach((key) => {
                if (typeof validationDetails[key] !== "undefined") {
                    results.validationDetails[key] = validationDetails[key];
                }
            });
        }
        if (uploadResults) {
            return { id: mintedKey, ...uploadResults };
        }
        throw new InvalidResultFromUpload();
    }
}
export default Asset;

import {
  createContext,
  useContext,
  useState,
  FC,
  useMemo,
  PropsWithChildren
} from "react";
import { Intent } from "@blueprintjs/core";
import { selectCurrentUser } from "appReducers/authReducer";
import { ToasterContext } from "lib/context";
import { madSDK } from "lib/sdk";
import {
  isErrorResponseValid,
  CreativeLegacy,
  isTruthy
} from "@madhive/mad-sdk";
import {
  createNewFirestoreNotification,
  FirestoreNotificationStatuses,
  NotificationActionCode,
  NotificationCategory
} from "lib/utils/notifications";

import { useSelector } from "react-redux";
import { selectAvailableAdvertisers } from "appReducers/advertisersReducer";
import { SmithersNotificationRaw } from "types";
import useLoadAdvertisers from "hooks/useAdvertisers";
import { deriveErrorResponse } from "lib/utils/api";
import {
  BulkCreativeAssetType,
  BulkXlsRow,
  FileMappedToMetadata
} from "./constants";
import { generateTrackers } from "./utils";

// ** Unlike in the normal creative upload process, we don't want one faulty creative to cause the upload to error out. Thus we throw all recognized errors during the upload as BulkErrors, update the process' error state, and catch the thrown errors, returning null from any individual upload that has errored out. * /

enum BulkErrorTypes {
  VAST_ERROR = "vastError",
  CSV_ERROR = "csvError",
  FIREBASE_ERROR = "firebaseUploadError",
  VALIDATION_ERROR = "validationError",
  POST_ERROR = "postError",
  FATAL_ERROR = "fatalError"
}

interface BulkError {
  type: BulkErrorTypes;
  message: string;
  key: string;
}

const isBulkError = (err: any): err is BulkError =>
  typeof err === "object" &&
  typeof err.type === "string" &&
  typeof err.message === "string" &&
  typeof err.key === "string";

export const BulkCreativeUploaderContext = createContext<
  BulkCreativeUploaderContextValue | undefined
>(undefined);

export const useBulkCreativeUploaderData = () => {
  const contextValue = useContext(BulkCreativeUploaderContext);

  if (!contextValue) {
    throw new Error(
      "You tried to use BulkCreativeUploaderContext in a component without rendering its provider somewhere above it in the tree."
    );
  }

  return contextValue;
};

const useBulkAssetUpload = () => {
  const user = useSelector(selectCurrentUser);
  const { addToast } = useContext(ToasterContext);
  const [isUploading, setIsUploading] = useState(false);

  useLoadAdvertisers();

  const advertisers = useSelector(selectAvailableAdvertisers);

  const [fatalError, setFatalError] = useState<string>();

  const [isReadyForBulkUpload, setIsReadyForBulkUpload] = useState(false);

  const [selectedAdvertiserId, setSelectedAdvertiserId] = useState<
    string | undefined
  >();

  const [uploadType, setUploadType] = useState<
    BulkCreativeAssetType | undefined
  >();

  const [hasContinued, setHasContinued] = useState(false);

  const [assetErrors, setAssetErrors] = useState<
    Record<string, Record<BulkErrorTypes, string>>
  >({});

  const [creativeIdToVideoAsset, setCreativeIdToVideoAsset] = useState<
    FileMappedToMetadata[]
  >([]);

  const [creativeIdToVastCdnAsset, setCreativeIdToVastCdnAsset] = useState<
    BulkXlsRow[]
  >([]);

  const [postedAssets, setPostedAssets] = useState<
    Record<string, CreativeLegacy>
  >({});

  const [xls, setXls] = useState<File>();

  const [success, setSuccess] = useState(false);

  //* * There's probably a better solution for error handling here, but for the time being we're taking advantage of the hook's closure to use tempAssetErrors as global error state for all uploading assets, then setting the hook's actual error state 'assetErrors' at the end of the upload process */

  let tempAssetErrors: Record<string, Record<BulkErrorTypes, string>> = {};

  const setAssetErrorsFromWithinPromise = (
    key: string,
    errorType: BulkErrorTypes,
    message: string
  ) => {
    tempAssetErrors[key] = {
      ...tempAssetErrors[key],
      [errorType]: message
    };
    setAssetErrors({
      ...tempAssetErrors,
      [key]: {
        ...tempAssetErrors[key],
        [errorType]: message
      }
    });
  };

  const handleDesktopAssetUpload = async (
    asset: File,
    metadata: BulkXlsRow
  ): Promise<CreativeLegacy | null> => {
    if (!selectedAdvertiserId) {
      throw "user has not selected an advertiser id";
    }
    const trackers = generateTrackers(metadata);
    const creative = {
      advertiserId: selectedAdvertiserId,
      clickThroughUrl: metadata.clickthroughUrl,
      id: metadata.creativeId,
      name: metadata.creativeName,
      iabCategoryId: metadata.iabCategoryId,
      iabSubcategoryId: metadata.iabSubcategoryId,
      trackingPixels: trackers,
      isciCode: metadata.isciCode,
      asset: {
        file: asset,
        isBulk: true
      }
    };

    const newCreative = await madSDK.legacy.creatives.create(creative);
    return newCreative;
  };

  const uploadSingleDesktopAsset = async (
    asset: File,
    metadata: BulkXlsRow
  ) => {
    try {
      const postedAsset = await handleDesktopAssetUpload(asset, metadata);
      return postedAsset;
    } catch (e) {
      if (isBulkError(e)) {
        setAssetErrorsFromWithinPromise(e.key, e.type, e.message);
      }
      throw e;
    }
  };

  // ** This is a weird implementation. The processes for uploading VAST and CDN assets are very different, yet the bulk creative spreadsheet template makes the user enter the URL for both in a single column. Thus, as far as identifying whether a URL is a VAST or CDN, the proof is in the pudding. We first attempt to upload as a CDN, and, if that fails out, we attempt as a VAST.  * /
  const uploadSingleVastCdn = async (vastCdnMetadata: BulkXlsRow) => {
    try {
      if (!selectedAdvertiserId) {
        throw "No advertiser selected";
      }

      const creative = {
        id: vastCdnMetadata.creativeId,
        name: vastCdnMetadata.creativeName,
        iabCategoryId: vastCdnMetadata.iabCategoryId,
        iabSubcategoryId: vastCdnMetadata.iabSubcategoryId,
        advertiserId: selectedAdvertiserId,
        isciCode: vastCdnMetadata.isciCode,
        clickThroughUrl: vastCdnMetadata.clickthroughUrl,
        trackingPixels: generateTrackers(vastCdnMetadata),
        asset: {
          url: vastCdnMetadata.assetFileName
        }
      };

      return await madSDK.legacy.creatives.create(creative);
    } catch (e) {
      setAssetErrorsFromWithinPromise(e.key, e.type, e.message);
      throw e;
    }
  };

  const resetAssetUploadState = () => {
    tempAssetErrors = {};
    setIsUploading(false);
    setAssetErrors({});
    setPostedAssets({});
    setCreativeIdToVideoAsset([]);
    setSuccess(false);
    setSelectedAdvertiserId(undefined);
    setUploadType(undefined);
    setHasContinued(false);
    setXls(undefined);
  };

  // ** MASTER BULK CR UPLOAD FN-- start from here if you're trying to get a handle on this hook * /
  const requestBulkCreativeUpload = async () => {
    try {
      if (!uploadType) {
        throw "Upload Type not selected.";
      }
      setIsUploading(true);
      const allAssets =
        uploadType === BulkCreativeAssetType.DESKTOP
          ? await Promise.all(
              creativeIdToVideoAsset.map(fileAndData =>
                uploadSingleDesktopAsset(fileAndData.file, fileAndData.metadata)
              )
            )
          : await Promise.all(
              creativeIdToVastCdnAsset.map(vastCdnMetadata =>
                uploadSingleVastCdn(vastCdnMetadata)
              )
            );

      setAssetErrors(tempAssetErrors);

      setSuccess(true);

      // ** When all assets have either successfully uploaded or errored out (errors are represented by a null return from the above Promise.all), we filter out null values and set postedAsset state to the resulting array * /
      setPostedAssets(
        allAssets.reduce((acc, vid) => {
          if (vid === null) {
            return acc;
          }
          acc[vid.id] = vid;
          return acc;
        }, {})
      );

      setIsUploading(false);
      // ** create a notification in firebase onSuccess * /
      try {
        if (!user) {
          throw "user is not logged in";
        }
        const now = new Date();
        const notificationBaseTemplate: SmithersNotificationRaw = {
          title: "Bulk Upload Creatives",
          content: `Finished uploading ${
            uploadType === BulkCreativeAssetType.DESKTOP
              ? creativeIdToVideoAsset.length
              : createNewFirestoreNotification.length
          } to the Creative Library.`,
          status: FirestoreNotificationStatuses.SENT,
          category: NotificationCategory.BULK_CREATIVE_UPLOAD,
          recipientId: user.email,
          actions: {
            [NotificationActionCode.VIEW_CREATIVES]: {
              buttonText: "view creatives",
              destinationUrl: "creative-library"
            }
          },
          meta: {
            submittedDate: now
          }
        };

        const notificationID = await createNewFirestoreNotification(
          notificationBaseTemplate,
          user,
          now
        );
        if (!notificationID) {
          throw "Unable to create new firestore notification";
        }
      } catch (e) {
        console.error("Unable to create notification");
      }
    } catch (err) {
      // ** Here we make sure to catch any unanticipated errors (an anticipated error being, for instance, an invalid clickthrough) that cause the bulk upload to error out early. * /
      setFatalError(err.toString());

      addToast({
        message: `Something went wrong: ${
          isErrorResponseValid(err)
            ? deriveErrorResponse(err).filter(isTruthy).join(",")
            : err
        }`,

        intent: Intent.DANGER
      });
      resetAssetUploadState();
    }
  };

  return {
    isUploading,
    resetAssetUploadState,
    setCreativeIdToVideoAsset,
    creativeIdToVideoAsset,
    creativeIdToVastCdnAsset,
    setCreativeIdToVastCdnAsset,
    requestBulkCreativeUpload,
    postedAssets,
    assetErrors,
    success,
    advertisers,
    selectedAdvertiserId,
    setSelectedAdvertiserId,
    fatalError,
    xls,
    setXls,
    uploadType,
    setUploadType,
    hasContinued,
    setHasContinued,
    isReadyForBulkUpload,
    setIsReadyForBulkUpload
  };
};

type BulkCreativeUploaderContextValue = ReturnType<typeof useBulkAssetUpload>;

export const BulkAssetUploadDataProvider: FC<PropsWithChildren> = props => {
  const {
    isUploading,
    resetAssetUploadState,
    setCreativeIdToVideoAsset,
    creativeIdToVideoAsset,
    creativeIdToVastCdnAsset,
    setCreativeIdToVastCdnAsset,
    postedAssets,
    requestBulkCreativeUpload,
    assetErrors,
    success,
    advertisers,
    selectedAdvertiserId,
    setSelectedAdvertiserId,
    fatalError,
    xls,
    setXls,
    uploadType,
    setUploadType,
    hasContinued,
    setHasContinued,
    isReadyForBulkUpload,
    setIsReadyForBulkUpload
  } = useBulkAssetUpload();
  const assetsMemoized = useMemo(
    (): BulkCreativeUploaderContextValue => ({
      isUploading,
      resetAssetUploadState,
      setCreativeIdToVideoAsset,
      creativeIdToVideoAsset,
      creativeIdToVastCdnAsset,
      setCreativeIdToVastCdnAsset,
      postedAssets,
      requestBulkCreativeUpload,
      assetErrors,
      success,
      advertisers,
      selectedAdvertiserId,
      setSelectedAdvertiserId,
      fatalError,
      xls,
      setXls,
      uploadType,
      setUploadType,
      hasContinued,
      setHasContinued,
      isReadyForBulkUpload,
      setIsReadyForBulkUpload
    }),
    [
      isUploading,
      resetAssetUploadState,
      setCreativeIdToVideoAsset,
      creativeIdToVideoAsset,
      creativeIdToVastCdnAsset,
      setCreativeIdToVastCdnAsset,
      postedAssets,
      requestBulkCreativeUpload,
      assetErrors,
      success,
      advertisers,
      selectedAdvertiserId,
      setSelectedAdvertiserId,
      fatalError,
      xls,
      setXls,
      uploadType,
      setUploadType,
      hasContinued,
      setHasContinued,
      isReadyForBulkUpload,
      setIsReadyForBulkUpload
    ]
  );

  return (
    <BulkCreativeUploaderContext.Provider value={assetsMemoized}>
      {/*  eslint-disable-next-line react/destructuring-assignment */}
      {props.children}
    </BulkCreativeUploaderContext.Provider>
  );
};

export default BulkAssetUploadDataProvider;

import { SVG } from "./containers";
import { Icon } from "./types";
import { Circle } from "./shapes";
import { parseColor } from "./utils";

export const Warning = ({ color, circled, id, aria }: Icon) => {
  const { content, circle } = parseColor(color);

  return (
    <SVG viewBox="0 0 20 20" id={id} aria={aria}>
      <Circle color={circle} visible={circled}>
        <path
          d="m 9.9992 10.8961 v -2.992 m -0.0008 5.3958 c -0.1104 0 -0.2 0.0896 -0.1992 0.2 c 0 0.1104 0.0896 0.2 0.2 0.2 c 0.1104 0 0.2 -0.0896 0.2 -0.2 c 0 -0.1104 -0.0896 -0.2 -0.2008 -0.2 m 1.6254 -9.5886 l 6.1272 10.7232 c 0.712 1.2463 -0.188 2.7976 -1.6232 2.7976 h -12.2544 c -1.436 0 -2.336 -1.5512 -1.6232 -2.7976 l 6.1272 -10.7232 c 0.7176 -1.2568 2.5288 -1.2568 3.2464 0 z"
          stroke={content}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Circle>
    </SVG>
  );
};

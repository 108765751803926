import { Icon, IconSize, NonIdealState } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { DATE_OF_CURRENT_RELEASE, __PROD__ } from "lib/constants/config";
import { FullStoryCustomEvent } from "lib/constants/fullStory";
import { Button, ButtonColor, ButtonVariant } from "madhive/components";
import * as FullStory from "@fullstory/browser";
import { Component, PropsWithChildren } from "react";
import { reportError } from "api/unhandledErrorReporter";
import Layout from "./Reusable/Layout";
import SmithersCard from "./Reusable/SmithersCard";
import HappyFace from "./Svg/HappyFace";

interface Props {}

interface State {
  isLoading: boolean;
  error: any;
}

class CrashScreen extends Component<PropsWithChildren<Props>, State> {
  public readonly state: State = {
    isLoading: false,
    error: null
  };

  componentDidCatch(error: Error) {
    if (__PROD__) {
      FullStory.event(FullStoryCustomEvent.FATAL_CRASH, {
        releaseVersionNumber: DATE_OF_CURRENT_RELEASE
      });
    }

    // report error to stackdriver
    reportError(error);

    this.setState(prevState => ({
      ...prevState,
      error
    }));
  }

  render() {
    return this.state.error ? (
      <Layout fillPage>
        <SmithersCard.Card grow centerContent>
          <NonIdealState
            icon={<HappyFace />}
            className="SmithersCrashScreen"
            title="The platform needs a little zhuzh!"
            description="Refresh to get the latest and greatest."
            action={
              <Button
                name="repair"
                loading={this.state.isLoading}
                data-testid="crash-repair-button"
                color={ButtonColor.PRIMARY}
                variant={ButtonVariant.CONTAINED}
                icon={
                  <Icon icon={IconNames.REFRESH} iconSize={IconSize.LARGE} />
                }
                onClick={() => {
                  if (!("serviceWorker" in navigator)) {
                    window.location.reload();
                    return;
                  }

                  this.setState(prevState => ({
                    ...prevState,
                    isLoading: true
                  }));
                  navigator.serviceWorker
                    .getRegistrations()
                    .then(registrations => {
                      registrations.forEach(registration => {
                        registration.unregister();
                      });
                    })
                    .then(() => {
                      window.location.reload();
                    })
                    .catch(() => {
                      this.setState(prevState => ({
                        ...prevState,
                        isLoading: false
                      }));
                    });
                }}
              >
                Refresh Now
              </Button>
            }
          />
        </SmithersCard.Card>
      </Layout>
    ) : (
      this.props.children
    );
  }
}

export default CrashScreen;

export var OTTDimensionBase;
(function (OTTDimensionBase) {
    OTTDimensionBase["CAMPAIGNS"] = "campaign";
    OTTDimensionBase["PUBLISHER"] = "pub";
    OTTDimensionBase["LINE_ITEMS"] = "lineitem";
    OTTDimensionBase["DEVICE_TYPE"] = "device";
    OTTDimensionBase["DAY_OF_THE_WEEK"] = "dayofweek";
    OTTDimensionBase["DAYPART"] = "daypart";
    OTTDimensionBase["DMA"] = "dma";
    OTTDimensionBase["CREATIVE"] = "creative";
    OTTDimensionBase["DAILY"] = "day";
    OTTDimensionBase["WEEKLY"] = "week";
    OTTDimensionBase["MONTHLY"] = "month";
    OTTDimensionBase["HOUR_OF_THE_WEEK"] = "hourofweek";
    OTTDimensionBase["HOURLY"] = "hourofday";
    OTTDimensionBase["ZIP_CODE"] = "postalcode";
    OTTDimensionBase["STATE"] = "region";
    OTTDimensionBase["CONGRESSIONAL_DISTRICT"] = "cd";
    OTTDimensionBase["FREQUENCY"] = "frequency";
    OTTDimensionBase["DAYS_TO_CONVERSION"] = "daystoconversion";
})(OTTDimensionBase || (OTTDimensionBase = {}));
// Dimension available for Linear related data
export var LinearDimensionBase;
(function (LinearDimensionBase) {
    LinearDimensionBase["NETWORK"] = "network";
    LinearDimensionBase["PROGRAM"] = "program";
    LinearDimensionBase["DAY_OF_THE_WEEK"] = "dayofweek";
    LinearDimensionBase["DAYPART"] = "daypart";
    LinearDimensionBase["DMA"] = "dma";
    LinearDimensionBase["CREATIVE"] = "creative";
    LinearDimensionBase["DAILY"] = "day";
    LinearDimensionBase["WEEKLY"] = "week";
    LinearDimensionBase["MONTHLY"] = "month";
    LinearDimensionBase["HOUR_OF_THE_WEEK"] = "hourofweek";
    LinearDimensionBase["HOURLY"] = "hourofday";
    LinearDimensionBase["NETWORK_DAYPART"] = "network-daypart";
    LinearDimensionBase["FREQUENCY"] = "frequency";
    LinearDimensionBase["DAYS_TO_CONVERSION"] = "daystoconversion";
})(LinearDimensionBase || (LinearDimensionBase = {}));
// FE names
export const dimensionNames = {
    0: "CAMPAIGNS",
    1: "PUBLISHER",
    2: "LINE_ITEMS",
    3: "DEVICE_TYPE",
    4: "DAY_OF_THE_WEEK",
    5: "DAYPART",
    6: "DMA",
    7: "CREATIVE",
    8: "DAILY",
    9: "WEEKLY",
    10: "MONTHLY",
    11: "HOUR_OF_THE_WEEK",
    12: "HOURLY",
    13: "ZIP_CODE",
    14: "STATE",
    15: "CONGRESSIONAL_DISTRICT",
    16: "FREQUENCY",
    17: "DAY_TO_CONVERSION"
};
// BE KEYS
export const dimensionKeys = {
    0: "campaign",
    1: "pub",
    2: "lineitem",
    3: "device",
    4: "dayofweek",
    5: "daypart",
    6: "dma",
    7: "creative",
    8: "day",
    9: "week",
    10: "month",
    11: "hourofweek",
    12: "hourofday",
    13: "postalcode",
    14: "region",
    15: "cd",
    16: "frequency",
    17: "daystoconversion"
};
export const getDimensionMatch = (url) => {
    const regex = /(?<=%2F).*(?=-0000)/g;
    const match = url.match(regex);
    return match ? match[0] : null;
};
const checkResultKey = (ResultKeysSet, key) => !ResultKeysSet.has(key) && !ResultKeysSet.has(`${"linear"}${key}`);
export const getMultiDimensions = (ResultKeys, results, dimensions) => {
    const ResultKeysSet = new Set(ResultKeys);
    // get dimensions from url
    const allDimensions = results ? results.map((r) => getDimensionMatch(r)) : dimensions;
    // check against single dimensions and extract multi dimensions
    const multiDimensions = allDimensions
        ? allDimensions.filter((d) => d && checkResultKey(ResultKeysSet, d))
        : [];
    // get each key (campaign-day ===> "campaign", "day")
    const keys = multiDimensions.flatMap((dm) => dm && dm?.split("-"));
    // compare keys to single dimension keys
    const invalidKeys = keys.filter((key) => key && checkResultKey(ResultKeysSet, key));
    // if there are invalid keys (should not really happen, but adding a check), filtering out multi dimensions
    return invalidKeys.length
        ? multiDimensions.filter((multidm) => {
            const invalid = invalidKeys.find((k) => {
                return k && multidm?.includes(k);
            });
            return !invalid ? multidm : null;
        })
        : multiDimensions;
};
/** get the greatest common denominator between two numbers */
const getGreatestCommonDenominator = (a, b) => {
    const formatA = Math.abs(Math.floor(a));
    const formatB = Math.abs(Math.floor(b));
    if (b === 0) {
        return formatA;
    }
    return getGreatestCommonDenominator(formatB, formatA % formatB);
};
export const getAspectRatio = (width, height) => {
    const gcd = getGreatestCommonDenominator(width, height);
    const aspectRatioWidth = width / gcd;
    const aspectRatioHeight = height / gcd;
    return [aspectRatioWidth, aspectRatioHeight];
};

import { IToastProps } from "@blueprintjs/core";
import { Audience } from "types";
import { BooleanExpression, RecipeType } from "@madhive/mad-sdk";

export enum Pending {
  GET = "get",
  UPDATE = "update"
}

export interface AudienceToCreate {
  name: string;
  description: string;
  type: RecipeType;
  expression: BooleanExpression;
}

export const GET_ALL_AUDIENCES_PENDING = "GET_ALL_AUDIENCES_PENDING";
export const GET_ALL_AUDIENCES_FAILURE = "GET_ALL_AUDIENCES_FAILURE";
export const GET_ALL_AUDIENCES_SUCCESS = "GET_ALL_AUDIENCES_SUCCESS";
export const GET_CACHED_AUDIENCES_SUCCESS = "GET_CACHED_AUDIENCES_SUCCESS";

export const GET_AUDIENCE_PENDING = "GET_AUDIENCE_PENDING";
export const GET_AUDIENCE_FAILURE = "GET_AUDIENCE_FAILURE";
export const GET_AUDIENCE_SUCCESS = "GET_AUDIENCE_SUCCESS";

export const CREATE_AUDIENCE_PENDING = "CREATE_AUDIENCE_PENDING";
export const CREATE_AUDIENCE_FAILURE = "CREATE_AUDIENCE_FAILURE";
export const CREATE_AUDIENCE_SUCCESS = "CREATE_AUDIENCE_SUCCESS";

export const UPDATE_AUDIENCE_PENDING = "UPDATE_AUDIENCE_PENDING";
export const UPDATE_AUDIENCE_FAILURE = "UPDATE_AUDIENCE_FAILURE";
export const UPDATE_AUDIENCE_SUCCESS = "UPDATE_AUDIENCE_SUCCESS";
export const BULK_UPDATE_AUDIENCES_SUCCESS = "BULK_UPDATE_AUDIENCES_SUCCESS";
export const BULK_UPDATE_AUDIENCES_FAILURE = "BULK_UPDATE_AUDIENCES_FAILURE";

export const CHECK_FOR_AUDIENCE_BY_EXPRESSION_PENDING =
  "CHECK_FOR_AUDIENCE_BY_EXPRESSION_PENDING";
export const CHECK_FOR_AUDIENCE_BY_EXPRESSION_FAILURE =
  "CHECK_FOR_AUDIENCE_BY_EXPRESSION_FAILURE";
export const CHECK_FOR_AUDIENCE_BY_EXPRESSION_FOUND =
  "CHECK_FOR_AUDIENCE_BY_EXPRESSION_FOUND";
export const CHECK_FOR_AUDIENCE_BY_EXPRESSION_NOT_FOUND =
  "CHECK_FOR_AUDIENCE_BY_EXPRESSION_NOT_FOUND";

interface GetAllAudiencesPendingAction {
  type: typeof GET_ALL_AUDIENCES_PENDING;
}

interface GetAllAudiencesFailureAction {
  type: typeof GET_ALL_AUDIENCES_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface GetAllAudiencesSuccessAction {
  type: typeof GET_ALL_AUDIENCES_SUCCESS;
  payload: Audience[];
  meta: {
    timestamp: number;
  };
}

interface GetCachedAudiencesSuccessAction {
  type: typeof GET_CACHED_AUDIENCES_SUCCESS;
  payload: Audience[];
}

interface GetAudiencePendingAction {
  type: typeof GET_AUDIENCE_PENDING;
  meta: {
    id: string;
  };
}

interface GetAudienceFailureAction {
  type: typeof GET_AUDIENCE_FAILURE;
  meta: {
    id?: string;
    error: {
      message: string;
    } & IToastProps;
  };
}

interface GetAudienceSuccessAction {
  type: typeof GET_AUDIENCE_SUCCESS;
  payload: Audience;
}

interface CreateAudiencePendingAction {
  type: typeof CREATE_AUDIENCE_PENDING;
  meta: {
    requestId: string;
    audience: AudienceToCreate;
  };
}

interface CreateAudienceFailureAction {
  type: typeof CREATE_AUDIENCE_FAILURE;
  meta: {
    requestId: string;
    error: {
      message: string;
    } & IToastProps;
  };
}

interface CreateAudienceSuccessAction {
  type: typeof CREATE_AUDIENCE_SUCCESS;
  payload: Audience;
  meta: {
    requestId: string;
    success: {
      message: string;
    } & IToastProps;
  };
}

interface CheckForAudienceByExpressionPendingAction {
  type: typeof CHECK_FOR_AUDIENCE_BY_EXPRESSION_PENDING;
  meta: {
    requestId: string;
    expression: BooleanExpression;
  };
}

interface CheckForAudienceByExpressionFailureAction {
  type: typeof CHECK_FOR_AUDIENCE_BY_EXPRESSION_FAILURE;
  meta: {
    requestId?: string;
    error: {
      message: string;
    } & IToastProps;
  };
}

interface CheckForAudienceByExpressionFoundAction {
  type: typeof CHECK_FOR_AUDIENCE_BY_EXPRESSION_FOUND;
  payload: Audience;
  meta: {
    requestId: string;
  };
}

interface CheckForAudienceByExpressionNotFoundAction {
  type: typeof CHECK_FOR_AUDIENCE_BY_EXPRESSION_NOT_FOUND;
  meta: {
    requestId: string;
  };
}

interface UpdateAudiencePendingAction {
  type: typeof UPDATE_AUDIENCE_PENDING;
  meta: {
    audience: Audience;
  };
}

interface UpdateAudienceFailureAction {
  type: typeof UPDATE_AUDIENCE_FAILURE;
  meta: {
    audienceId: string;
    error: {
      message: string;
    } & IToastProps;
  };
}

interface UpdateAudienceSuccessAction {
  type: typeof UPDATE_AUDIENCE_SUCCESS;
  payload: Audience;
  meta: {
    success: {
      message: string;
    } & IToastProps;
  };
}

interface bulkUpdateAudiencesFailureAction {
  type: typeof BULK_UPDATE_AUDIENCES_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface bulkUpdateAudiencesSuccessAction {
  type: typeof BULK_UPDATE_AUDIENCES_SUCCESS;
  payload: Audience[];
  meta: {
    success: {
      message: string;
    } & IToastProps;
  };
}

export interface AudiencesState {
  readonly byId: Record<string, Audience>;
  readonly isAllLoading: boolean;
  readonly isCachedLoaded: boolean;
  readonly pendingById: Record<string, Pending>;
  readonly creatingByRequestId: Record<string, AudienceToCreate>;
  readonly receivedAllAt: number | null;
  readonly checkingExpressionByRequestId: Record<string, BooleanExpression>;
}

export type AudiencesActionTypes =
  | GetAllAudiencesPendingAction
  | GetAllAudiencesFailureAction
  | GetAllAudiencesSuccessAction
  | GetCachedAudiencesSuccessAction
  | GetAudiencePendingAction
  | GetAudienceFailureAction
  | GetAudienceSuccessAction
  | CreateAudiencePendingAction
  | CreateAudienceFailureAction
  | CreateAudienceSuccessAction
  | CheckForAudienceByExpressionPendingAction
  | CheckForAudienceByExpressionFailureAction
  | CheckForAudienceByExpressionFoundAction
  | CheckForAudienceByExpressionNotFoundAction
  | UpdateAudiencePendingAction
  | UpdateAudienceFailureAction
  | UpdateAudienceSuccessAction
  | bulkUpdateAudiencesFailureAction
  | bulkUpdateAudiencesSuccessAction;

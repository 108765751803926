import { KnownProjectIds } from "@madhive/mad-sdk";
import { DocumentData } from "firebase/firestore";

/**
 * Map of dev-only query strings to override current firebase project
 */
const SEARCH_PARAMS_TO_FIREBASE_PROJECT: Readonly<
  Record<string, KnownProjectIds>
> = {
  master: KnownProjectIds.MAD_MASTER,
  staging: KnownProjectIds.MAD_STAGING,
  qa: KnownProjectIds.MAD_QA,
  hack: KnownProjectIds.MAD_HACK,
  testing: KnownProjectIds.MADHIVE_TESTING,
  demo: KnownProjectIds.MAD_DEMO,
  talon: KnownProjectIds.MAD_TALON
};

const KNOWN_ENVIRONMENT_SEARCH_PARAMS: Readonly<
  Array<keyof typeof SEARCH_PARAMS_TO_FIREBASE_PROJECT>
> = ["master", "staging", "qa", "hack", "testing", "demo", "talon"];

export const readFirebaseEnvironmentFromQueryString = (
  queryString: string
): KnownProjectIds | undefined => {
  const searchParams = new URLSearchParams(queryString);

  const maybeKnownEnvInQueryString = KNOWN_ENVIRONMENT_SEARCH_PARAMS.find(
    param => searchParams.has(param)
  );

  const correspondingEnvironment = maybeKnownEnvInQueryString
    ? SEARCH_PARAMS_TO_FIREBASE_PROJECT[maybeKnownEnvInQueryString]
    : undefined;

  return correspondingEnvironment;
};

export const getFirebaseDocument = (docs: DocumentData[]) => docs[0] || null;

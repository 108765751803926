import { NotImplementedError } from "../../errors";
import { Handler } from "../handlers";
import Switches from "./switches";
import Values from "./values";
/**
 * Collection of application level configuration options.
 */
class Application extends Handler {
    constructor(sdk) {
        super(sdk, "application");
        this.switches = new Switches(sdk);
        this.values = new Values(sdk);
    }
    /**
     * Find the default settings. Currenlty only supports returning all the settings.
     * @return: a promise that resolves with all default settings.
     */
    async findItems() {
        throw new NotImplementedError("findItem");
    }
    /* eslint-disable-next-line class-methods-use-this, no-shadow, no-unused-vars */
    async saveItem() {
        throw new NotImplementedError("saveItem");
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async deleteItem() {
        throw new NotImplementedError("delete");
    }
}
export default Application;

import { Status, serviceReportJobResultToReportJobResult } from "../../../types";
import axios from "axios";
import { ApiVersion, StatusCode } from "../types";
import { buildUrl } from "../utils";
import { Poller } from "../../handlers/poller";
class StoragePoller extends Poller {
    constructor(sdk, storage, config) {
        super(sdk, {
            subject: config?.subject,
            interval: config?.interval,
            data: config?.data
        });
        this.storage = storage;
    }
    async fetchReportAndUpdateResults(id, name) {
        try {
            const url = `${buildUrl(this.sdk, ApiVersion.V1, "reports")}/${id}?filename=${name}.csv`;
            const res = await axios.get(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const { data, status } = res;
            const reportResults = serviceReportJobResultToReportJobResult(data);
            if (status === StatusCode.NO_CONTENT) {
                throw new Error("Error generating report");
            }
            if (status === StatusCode.SUCCESS) {
                if (!data.results || data.results.length === 0) {
                    throw new Error("No results in response");
                }
                return await this.storage.updateExportStatus(id, Status.DONE, reportResults.results);
            }
        }
        catch (error) {
            console.error(error);
            return await this.storage.updateExportStatus(id, Status.ERROR);
        }
    }
    async fetchData() {
        return new Promise((resolve, reject) => {
            const reportsToRefresh = [];
            for (const [id, report] of this.data.entries()) {
                if (report.status === Status.IN_PROGRESS) {
                    reportsToRefresh.push({ id, name: report.name });
                }
            }
            if (!reportsToRefresh.length) {
                return resolve([]);
            }
            Promise.all(
            // refetch all reports in progress and update their status based on response
            reportsToRefresh.map(({ id, name }) => this.fetchReportAndUpdateResults(id, name)))
                .then((reports) => {
                const updatedReports = reports.filter((report) => !!report);
                // only return the reports if any of them were updated
                if (updatedReports.length) {
                    // get most recent reports from storage;
                    this.storage.getExports().then((mostRecentReports) => {
                        this.updateSubscribers(mostRecentReports);
                        return resolve(mostRecentReports);
                    });
                }
                return resolve([]);
            })
                .catch((error) => {
                reject(error);
            });
        });
    }
    async refresh() {
        const updatedReports = await this.storage.getExports();
        this.updateSubscribers(updatedReports);
    }
}
export default StoragePoller;

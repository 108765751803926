import { NotImplementedError } from "../../errors";
import { Handler } from "../handlers";
import axios from "axios";
import { validators } from "./validators";
export default class PIIHandler extends Handler {
    constructor(sdk) {
        super(sdk, "pii");
        /**
         * Validate PII information that will be treated as a csv when saving.
         */
        this.validators = validators;
    }
    make() {
        return Promise.resolve({
            data: [],
            name: "",
            description: "",
            row: [],
            invalidRowNumbers: []
        });
    }
    findItems() {
        throw new NotImplementedError("Find");
    }
    saveItem(pii) {
        // Need to covert the data into a single string object
        const fileData = pii.data.map((row) => row.join(",")).join("\n");
        // Use the string to create a csvFile
        const csvFile = new Blob([fileData], { type: "text/csv" });
        // build multipart form data to upload a file and data
        const formData = new FormData();
        formData.append("file", csvFile);
        formData.append("segment_name", pii.name);
        formData.append("segment_description", pii.description);
        formData.append("invalid_row_count", String(pii.invalidRowNumbers?.length));
        formData.append("invalid_row_numbers", JSON.stringify(pii.invalidRowNumbers));
        return new Promise((resolve, reject) => {
            axios
                .post(`${this.sdk.urls.madhivePIIBaseUrl}/identifiers/upload`, formData, {
                headers: {
                    "content-type": "multipart/form-data"
                }
            })
                .then((res) => {
                resolve({
                    ...pii,
                    id: res.data.segment_id
                });
            })
                .catch((error) => {
                reject(error);
            });
        });
    }
    deleteItem() {
        throw new NotImplementedError("Delete");
    }
}

import { PropsWithChildren } from "react";
import { css } from "@emotion/react";

interface Props {
  button?: boolean;
  className?: string;
  tabIndex?: number;
  onClick?: (event: any) => void;
}

const style = {
  root: css`
    width: 100%;
    display: flex;
    position: relative;
    box-sizing: border-box;
    text-align: left;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
    justify-content: flex-start;
    text-decoration: none;
    &:hover {
      text-decoration: none;
      background-color: rgba(0, 0, 0, 0.08);
      cursor: pointer;
    }
  `
};

const Button = ({
  children,
  className,
  tabIndex,
  onClick
}: PropsWithChildren<Props>) => (
  <div
    className={className}
    css={style.root}
    tabIndex={tabIndex}
    data-testid="smithers-list-item"
    onClick={onClick}
    role="button"
    onKeyDown={() => onClick}
  >
    {children}
  </div>
);

const ListElement = ({
  children,
  className,
  tabIndex
}: PropsWithChildren<Props>) => (
  <li
    className={className}
    css={style.root}
    tabIndex={tabIndex}
    data-testid="smithers-list-item"
  >
    {children}
  </li>
);

const ListItem = ({
  button,
  children,
  className,
  onClick
}: PropsWithChildren<Props>): JSX.Element => {
  const Wrapper = button ? Button : ListElement;
  return (
    <Wrapper
      className={className}
      data-testid="smithers-list-item"
      onClick={onClick}
    >
      {children}
    </Wrapper>
  );
};

export default ListItem;

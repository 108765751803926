import { FilterTypes } from "../../../../types";
/**
 * @param filters: map of filters, keys being the filter name, values being the applied values.
 * @return: filters parsed into valid SDK-style filter objects.
 */
export const toFilterObjects = (filters = new Map()) => {
    if (typeof filters?.get !== "function") {
        return { where: [] };
    }
    const fields = [];
    ["search", "groupType"].forEach((field) => {
        const value = filters.get(field);
        if (value?.length) {
            fields.push({
                field,
                type: FilterTypes.EQ,
                value: value[0]
            });
        }
    });
    [
        "id",
        "status",
        "archiveStatus",
        "videoTypes",
        "mediaTypes",
        "dealTypes",
        "priceTypes",
        "minDate",
        "maxDate"
    ].forEach((field) => {
        const value = filters.get(field);
        if (value?.length) {
            fields.push({
                field,
                type: FilterTypes.IN,
                value
            });
        }
    });
    return { where: fields };
};

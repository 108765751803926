import { getDetailedErrorMessage } from "../../../utils/validation";
export class DmpsFetchFailed extends Error {
    constructor(apiError, fallbackMessage = "Failed to retrieve data management platforms. Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, DmpsFetchFailed);
        }
        this.name = "DmpsFetchFailed";
        this.message = getDetailedErrorMessage(apiError, fallbackMessage);
    }
}

import { Spinner, SpinnerSize } from "@blueprintjs/core";
import { MouseEvent } from "react";
import { styles } from "../styles";
import Icon from "../../Icon";
import Button from "../../Button";

type UploaderFileListItemProps = {
  file: File;
  onDelete: (file: File) => void;
  error?: string;
  onReselect?: (file: File) => void;
  processing?: boolean;
};

const UploaderFileListItem = ({
  file,
  onDelete,
  error,
  processing,
  onReselect
}: UploaderFileListItemProps) => {
  const reselect = (event: MouseEvent) => {
    event.stopPropagation();
    onReselect && onReselect(file);
  };
  const deleteFile = (event: MouseEvent) => {
    event.stopPropagation();
    onDelete(file);
  };
  return (
    <div
      className="subKit-UploaderFileListItem"
      css={styles.fileRow}
      key={`${file.name}-${file.lastModified}`}
    >
      <div css={[styles.leftContent]}>
        {!!processing && <Spinner size={SpinnerSize.SMALL} />}
        {!processing && !!error && (
          <Icon
            name="Circled.Exclamation"
            size="small"
            fill="var(--color-danger)"
          />
        )}
        {!processing && !error && (
          <Icon name="Circled.Check" fill="var(--color-success)" />
        )}
        <div>
          <span css={styles.fileName}>{file.name}</span>
          {!!error && <span css={styles.fileError}>{error}</span>}
        </div>
      </div>
      <div css={styles.rightContent}>
        {!!onReselect && (
          <Button variant="ghost" onClick={reselect}>
            Reselect
          </Button>
        )}
        <Button
          variant="ghost"
          prefix={
            <Icon
              name={error || processing ? "X" : "Trash"}
              fill="var(--color-secondary, var(--color-primary))"
            />
          }
          onClick={deleteFile}
        />
      </div>
    </div>
  );
};

export default UploaderFileListItem;

/* eslint-disable camelcase */
import {
  AssetValidation,
  CreativeLegacy,
  TrackerType,
  URL_REGEX_WITH_REQUIRED_PROTOCOL_OR_PROTO_TAG,
  UploadedFileResultsLegacy
} from "@madhive/mad-sdk";
import { css } from "@emotion/react";
import { AssetValidation_Result } from "@madhive/mad-sdk/models/generated/mad/creative";
import { buildMediaQuery } from "lib/utils/styles";
import { TrackerObject } from "newnewyork";
import { BASIC_VALIDATION_PUBLISHER } from "../ExistingCreativesScreen/constants";

export const FILE_DISPLAY_MIME_TYPES = new Set([
  "image/gif",
  "image/jpeg",
  "image/png"
]);
export const FILE_VIDEO_MIME_TYPES = new Set(["video/mp4"]);
export const FILE_AUDIO_MIME_TYPES = new Set([
  "audio/mp3",
  "audio/aac",
  "audio/wav",
  "audio/mpeg",
  "audio/ogg"
]);
export const ACCEPTED_FILE_VIDEO_MIME_TYPES = [...FILE_VIDEO_MIME_TYPES].join(
  ", "
);

export const ACCEPTED_FILE_AUDIO_MIME_TYPES = [...FILE_AUDIO_MIME_TYPES].join(
  ", "
);
export const ACCEPTED_FILE_DISPLAY_MIME_TYPES = [
  ...FILE_DISPLAY_MIME_TYPES
].join(", ");
// PK TODO: Include ACCEPTED_FILE_AUDIO_MIME_TYPES once we find out we can allow display AND audio per client
export const ACCEPTED_ALL_FILE_MIME_TYPES = [
  ACCEPTED_FILE_VIDEO_MIME_TYPES,
  ACCEPTED_FILE_DISPLAY_MIME_TYPES,
  ACCEPTED_FILE_AUDIO_MIME_TYPES
].join(", ");
export const SUPPORTED_FILE_TYPES = ["video", "audio", "image"];

export const getAcceptedMimeTypes = (
  isDisplayActivation: boolean = false,
  isAudioActivation: boolean = false
) =>
  new Set([
    ...FILE_VIDEO_MIME_TYPES,
    ...(isDisplayActivation ? FILE_DISPLAY_MIME_TYPES : []),
    ...(isAudioActivation ? FILE_AUDIO_MIME_TYPES : [])
  ]);

export const clickableCardClasses = {
  toggleContainer: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
  `,
  toggleButton: css`
    height: 180px;
    border: 1px solid #eeeef2;
  `,
  stepText: css`
    color: var(--gray);
    font-size: 14px;
    margin-bottom: 20px;
  `,
  tileContainer: css`
    display: grid;
    grid-gap: 30px;
    grid-template-columns: repeat(2, 1fr);
    ${buildMediaQuery.max("sm")} {
      grid-template-columns: repeat(1, 1fr);
    }
  `,
  subtitleText: css`
    margin-bottom: 20px;
    font-size: var(--font-size-16);
    letter-spacing: 0.01rem;
  `,
  noPaddingTop: css`
    padding-top: 0px;
  `,
  iconAndTextLayout: css`
    display: grid;
    grid-template-columns: 1fr 2fr;
    padding: 25px 50px;
    border: solid 1px var(--gray);
    border-radius: var(--main-radius);
    cursor: pointer;
    outline: none;
  `,
  subduedText: css`
    color: var(--semi-dark);
  `,
  smallBodyText: css`
    font-size: var(--font-size-12);
  `,
  svgIcon: css`
    height: 100px;
    width: 100px;
  `,
  categoryLabel: css`
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 0.01em;
    line-height: 35px;
  `,
  uploadErrorStateContainer: css`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 30px;
    margin: auto;
  `,
  categoryHelpText: css``,
  selectedTile: css`
    border: solid 1px var(--primary-color);
  `
};

export const uploadCardClasses = {
  stepText: css`
    color: var(--gray);
    font-size: 14px;
    margin-bottom: 20px;
  `,
  vastButton: css`
    height: 2.5rem;
  `,
  verticalFlex: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `,
  errorText: css`
    font-size: var(--font-size-12);
  `,
  buttonsContainer: css`
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  `,
  removeButton: css`
    margin-right: var(--spacing-32);
  `,
  errorContainer: css`
    text-align: left;
  `,
  fileName: css`
    font-family: RedHatText;
    font-size: 18px;
    letter-spacing: 1px;
    margin-top: 15px;
    text-align: center;
  `,
  fileSize: css`
    font-family: RedHatText;
    font-size: 18px;
    letter-spacing: 1px;
    margin-top: 15px;
    color: var(--gray);
    text-align: center;
  `,
  unstyledOrderedList: css`
    margin: 0px;
    padding: 0px;
    list-style: none;
    & > * {
      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  `,
  waitingText: css`
    color: var(--gray);
    text-align: center;
    font-size: 18px;
  `,
  vastErrorMessage: css`
    margin-top: 10px;
    color: var(--red);
    & > a {
      text-decoration: underline;
      color: inherit;
    }
  `,
  macroHeader: css`
    margin-top: 1.9rem;
    padding: 0 1rem;
  `,
  macroSubHeader: css`
    padding: 1.8rem 1rem 0.5rem 1rem;
  `,
  subHeaderHelpText: css`
    color: var(--semi-dark);
    font-size: 12px;
    margin-bottom: 20px;
  `,
  vastUrlPreviewBanner: css`
    padding: 0 1rem;
    font-size: 14px;
    line-height: 20px;
    color: var(--color-primary);
  `,
  macrosListWrapper: css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0.25rem 0.5rem;
    &:nth-child(odd) {
      background-color: var(--gray-1);
    }
    &:nth-child(even) {
      background-color: var(--color-background-secondary);
    }
  `,
  macroListHeader: css`
    font-size: 14px;
    color: var(--color-primary);
    font-weight: bold;
    font-family: RedHatText;
  `,
  macroText: css`
    font-size: 14px;
    color: var(--color-primary);
    font-family: RedHatText;
  `,
  unsupportedMacroText: css`
    font-size: 14px;
  `,
  vastUrlNonHighlightedText: css`
    word-break: break-all;
  `,
  headerWrapper: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  header: css`
    margin: 15px 0px;
    font-weight: 500;
    font-size: 16px;
  `,
  inputWrapper: css`
    margin-top: 10px;
    & > .bp4-input-group > .bp4-input-action {
      top: 2px;
    }
  `,
  detailCategoryInputLabel: css`
    font-weight: bold;
    margin-top: 30px;
    display: inline-block;
  `,
  sourceTagUrlText: css`
    overflow-wrap: anywhere;
  `
};

const convertKBToMB = (val: number) =>
  val > 1000 ? `${(val / 1000).toFixed(2)}MB` : `${val.toFixed(2)}KB`;

export const displayUploadProgress = (
  bytesTransferred: number,
  assetSizeInBytes: number
) => {
  if (!assetSizeInBytes) {
    return "";
  }

  const total = assetSizeInBytes / 1000;
  const uploaded = bytesTransferred / 1000;
  const pct = Math.round((bytesTransferred / assetSizeInBytes) * 100);

  return `${convertKBToMB(uploaded)} of ${convertKBToMB(
    total
  )} (${pct}% complete)`;
};

export const getTrackers = (
  el: TrackerObject,
  labelMap: Partial<Record<TrackerType, string>>
) =>
  el.urls.length > 1 || (el.urls.length === 1 && el.urls[0] !== "")
    ? labelMap[el.type] || "N/A"
    : null;

export const validateDisplayMimeTypes = (type: string) =>
  FILE_DISPLAY_MIME_TYPES.has(type);

export const validateVideoMimeTypes = (type: string) =>
  FILE_VIDEO_MIME_TYPES.has(type);

export const validateAudioMimeTypes = (type: string) =>
  FILE_AUDIO_MIME_TYPES.has(type);

export const validateUploadedFileMimeType = (type: string) =>
  validateVideoMimeTypes(type) || validateDisplayMimeTypes(type);

export const validateUploadedFileMimeTypeForAudio = (type: string) =>
  validateAudioMimeTypes(type);

export const validateFileType = (type: string) =>
  !!SUPPORTED_FILE_TYPES.find(supportedType => type.includes(supportedType));

export const isURL = (url: string) => {
  try {
    const urlObject = new URL(url);
    return !!urlObject;
  } catch (e) {
    return false;
  }
};

export const isURLorVastURL = (url: string) =>
  isURL(url) || URL_REGEX_WITH_REQUIRED_PROTOCOL_OR_PROTO_TAG.test(url);

export const hasSuccesfulPublisherValidation = (
  asset: UploadedFileResultsLegacy | CreativeLegacy
): boolean => {
  if (!asset.asset_validations) return false;

  return !asset.asset_validations.some((validation: AssetValidation) => {
    return validation.validations
      ? validation.validations.some(
          (validation: AssetValidation_Result) => !validation.valid
        )
      : false;
  });
};

/**
 *
 * @param asset Creative Asset to validate
 * @returns true if all Basic Publisher Validations passed. Otherwise, it returns false
 */
export const passedAllBasicValidations = (
  asset: UploadedFileResultsLegacy | CreativeLegacy
): boolean => {
  if (!asset.asset_validations) return false;

  return !asset.asset_validations.some((validation: AssetValidation) => {
    return validation.validations
      ? validation.validations.some(
          (validation: AssetValidation_Result) =>
            validation.publisher === BASIC_VALIDATION_PUBLISHER &&
            !validation.valid
        )
      : false;
  });
};

/**
 * Returns an array of all required validation failures for an asset
 * @param asset The asset to check for required validation failures
 * @returns An array of all required names for validation failures for an asset
 */
export const findAllRequiredValidationFailures = (
  asset: UploadedFileResultsLegacy | CreativeLegacy
): string[] => {
  const failures: string[] = [];
  if (!asset.asset_validations) return failures;

  asset.asset_validations.forEach((validation: AssetValidation) => {
    if (!validation.validations || !validation.attribute?.required) return;

    validation.validations.forEach((result: AssetValidation_Result) => {
      if (!result.valid && !failures.includes(validation.attribute!.name)) {
        failures.push(validation.attribute!.name);
      }
    });
  });

  return failures;
};

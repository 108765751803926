import axios from "axios";
import { parseIdFilter } from "../handlers/filter";
import { Handler } from "../../modules/handlers";
import { FilterTypes, FilterValueTypes, validateFilterStructure } from "../../modules/handlers/filter";
import { FilterErrorName, MadSDKAbortError, NotImplementedError, PublisherListCreationFailed, PublisherListInvalidNameSaveFailure, PublisherListInvalidPublisherSaveFailure, PublisherListSameNameCreationFailure, PublisherListsFetchFailed, PublisherListUpdateFailed } from "../../errors";
import { listToServiceList, serviceListToList } from "../../models/publisher";
import { parseNewNewYorkApiResponse } from "../../utils";
import { isErrorResponseValid } from "../../utils/validation";
import Audits from "./audits";
import { toQueryParams } from "./utils/query";
class Lists extends Handler {
    /**
     * Child handlers also need access to MadSDK, so we must instantiate them with it.
     * @param sdk: instance of MadSDK to use for lookups.
     */
    constructor(sdk) {
        super(sdk, "publishers-lists");
        /**
         * @param lists: a set of publisher lists.
         * @param filters: the filters to apply.
         * @return: the filtered set of lists.
         * @throws: FilterInvalidError if a filter is defined, and it is not of the form {FilterTypes.EQ, "id"}.
         */
        this.filterLists = (lists, filters) => {
            const idFilter = filters.where?.find((filter) => filter.field === "id")
                ?.value;
            if (idFilter) {
                const matchedList = lists.find((list) => list.id === idFilter);
                if (matchedList) {
                    return [matchedList];
                }
                return [];
            }
            return lists;
        };
        /**
         * @param error: the error to whittle down into something more specific/lists-or-sdk-related.
         * @param list: optionally, the list that was being saved. Leave it out if irrelevant at call time.
         * @return: an appropriate Error if a specific error can be determined. Else, null.
         */
        this.getSpecificListError = (error, list) => {
            if (!error) {
                return null; // can't determine a specific error if error is falsy
            }
            if (axios.isCancel(error)) {
                return new MadSDKAbortError();
            }
            if (error.name && error.name === FilterErrorName.FilterInvalidError) {
                return error;
            }
            if (isErrorResponseValid(error)) {
                // Invalid name, name already exists
                if (PublisherListSameNameCreationFailure.is(error.response.data.errors) && list) {
                    return new PublisherListSameNameCreationFailure(list.name);
                }
                // Invalid name type
                if (PublisherListInvalidNameSaveFailure.is(error.response.data.errors) && list) {
                    return new PublisherListInvalidNameSaveFailure(list.name);
                }
                // Invalid publisher being saved among valid publishers
                if (PublisherListInvalidPublisherSaveFailure.is(error.response.data.errors)) {
                    const invalidId = PublisherListInvalidPublisherSaveFailure.parseInvalidPublisherId(error.response.data.errors);
                    return new PublisherListInvalidPublisherSaveFailure(invalidId);
                }
            }
            // no specific error determined, so null
            return null;
        };
        this.audits = new Audits(sdk);
    }
    /**
     * Find publisher list/lists using filters.
     *
     * @param filters: filters publisher lists.
     *          Supports:
     *              {FilterTypes.EQ, "id"}
     * @param sort: optional. Sort by field and direction.
     * @return: an array of publisher lists.
     */
    async findItems(filters, 
    /**
     * @default: { field: "name", direction: "asc" }
     */
    sort = { field: "name", direction: "asc" }) {
        validateFilterStructure(filters, [
            { filterType: FilterTypes.EQ, valueType: FilterValueTypes.STRING }
        ]);
        const idFilter = parseIdFilter(filters);
        return idFilter.size === 1
            ? this.getPublisherGroup(idFilter.values().next().value).then((publisher) => [publisher])
            : this.getPublisherGroups(filters, sort);
    }
    getPublisherGroup(id) {
        const url = `${this.sdk.urls.baseAPIUrl}/publisher-group/${id}`;
        return this.cache.promise(id, () => axios
            .get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
            const { data: rawPublisherList } = parseNewNewYorkApiResponse(res);
            return serviceListToList(rawPublisherList);
        })
            .catch((error) => {
            throw this.getSpecificListError(error) || new PublisherListsFetchFailed();
        }));
    }
    getPublisherGroups(filters, sort) {
        const url = `${this.sdk.urls.baseAPIUrl}/publisher-groups${toQueryParams(sort)}`;
        return this.cache.promise(url, () => axios
            .get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
            const { data: rawPublisherLists } = parseNewNewYorkApiResponse(res);
            const publisherLists = rawPublisherLists.map((list) => serviceListToList(list));
            return this.filterLists(publisherLists, filters);
        })
            .catch((error) => {
            throw this.getSpecificListError(error) || new PublisherListsFetchFailed();
        }));
    }
    /**
     * Saves the provided list, handling internally whether to do a create or an update.
     * @param list: the list to save.
     * @returns: a resolved promise containing the frontend representation of the saved list, or a rejected promise containing a relevant error.
     */
    async saveItem(list) {
        // we are going to clear the cache in case a new
        // fetch is made before the timeout
        // this behavior will change eventually with further updates to the sdk
        this.cache.clear();
        if ("id" in list) {
            return this.update(list);
        }
        return this.create(list);
    }
    /**
     * Creates the provided list.
     * @param list: the list to create.
     * @returns: a resolved promise containing the frontend representation of the created list, or a rejected promise containing a relevant error.
     */
    async create(list) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${this.sdk.urls.baseAPIUrl}/publisher-group`, listToServiceList(list), {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(async (res) => {
                // TODO: validate that this update works, as the endpoint should return the saved list now
                const { data: saved } = parseNewNewYorkApiResponse(res);
                resolve(serviceListToList(saved));
            })
                .catch((error) => {
                reject(this.getSpecificListError(error, list) || new PublisherListCreationFailed());
            });
        });
    }
    /**
     * Updates the provided list.
     * @param list: the list to update.
     * @returns: a resolved promise containing the frontend representation of the updated list, or a rejected promise containing a relevant error.
     */
    async update(list) {
        return new Promise((resolve, reject) => {
            axios
                .post(`${this.sdk.urls.baseAPIUrl}/publisher-group`, listToServiceList(list), {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((res) => {
                // parse just to ensure we don't have NNY code !== OK
                parseNewNewYorkApiResponse(res);
                resolve(list);
            })
                .catch((error) => reject(this.getSpecificListError(error, list) || new PublisherListUpdateFailed()));
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}
export default Lists;

import { FC, useEffect, useRef } from "react";
import * as Icons from "../../Icons";
import { DropdownItemProps } from "./types";
import { styles } from "./styles";
import { getIconFromStringOrElement } from "../../Icons/utils";

const DropdownItem: FC<DropdownItemProps> = ({
  item,
  isSelected,
  disabled,
  focus,
  lookupId,
  lookupLabel,
  lookupIcon,
  onSelectItem,
  onBlur,
  onClick,
  onFocus,
  onKeyDown,
  onKeyUp,
  clearable
}) => {
  const id = lookupId(item);
  const itemRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    if (focus) {
      itemRef.current?.focus();
    } else if (
      itemRef.current === document.activeElement ||
      itemRef.current?.contains(document.activeElement)
    ) {
      itemRef?.current?.blur();
    }
  }, [focus]);

  const handleOnClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (!id || (!clearable && isSelected)) {
      return;
    }
    onSelectItem(id);
    event.currentTarget.blur();
    onClick?.(event);
  };

  const handleOnKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (id && ["Enter", " "].includes(event.key)) {
      onSelectItem(id);
    }
    onKeyDown?.(event);
  };

  if (!item) {
    return null;
  }

  return (
    <li
      ref={itemRef}
      css={[
        styles.singleWrapper,
        isSelected && styles.selected,
        isSelected && clearable && styles.clearableSelected,
        disabled && !isSelected && styles.isDisabled
      ]}
      className="subKit-DropdownItem"
      role="option"
      aria-label={lookupLabel(item)}
      aria-disabled={disabled && !isSelected}
      aria-selected={isSelected}
      tabIndex={0}
      onClick={handleOnClick}
      onKeyDown={handleOnKeyDown}
      onKeyUp={onKeyUp}
      onBlur={onBlur}
      onFocus={onFocus}
    >
      <div css={[styles.iconAndLabel]}>
        {getIconFromStringOrElement(lookupIcon?.(item))}
        <span css={[styles.label]}>{lookupLabel(item)}</span>
      </div>
      <div className="clear-item-icon">
        <Icons.Display size="small">
          {clearable && isSelected && (
            <Icons.Circled.X fill="var(--color-secondary)" />
          )}
        </Icons.Display>
      </div>
    </li>
  );
};

export default DropdownItem;
export * from "./types";

import { selectCurrentUser } from "appReducers/authReducer";
import ProcessingList, {
  ProcessingListItem
} from "frontier/lib/components/ProcessingList";
import { Dispatch, SetStateAction } from "react";
import { useSelector } from "react-redux";

export interface ProcessingListContextValue {
  items: ProcessingListItem[];
  setItems: Dispatch<SetStateAction<ProcessingListItem[]>>;
}

type ProcessingPaneProps = ProcessingListContextValue & {
  title: string;
};

const ProcessingPane: React.FC<ProcessingPaneProps> = ({
  items,
  setItems,
  title
}) => {
  const user = useSelector(selectCurrentUser);
  const canDismiss = items.every(item => item.status !== "loading");

  /** ensure this pane can't show on the login screen */
  return user && items.length ? (
    <ProcessingList
      title={canDismiss ? `${title} is complete` : `${title} is in progress`}
      items={items}
      dismissable={canDismiss}
      setIsDismissed={() => setItems([])}
    />
  ) : null;
};

export default ProcessingPane;

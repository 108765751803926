import { dollarsToSatoshis, satoshisToDollars } from "../utils";
export var ServiceChargeType;
(function (ServiceChargeType) {
    ServiceChargeType["UNASSIGNED"] = "UNASSIGNED";
    ServiceChargeType["RATE_CARD"] = "RATE_CARD";
    ServiceChargeType["COST_PLUS"] = "COST_PLUS";
})(ServiceChargeType || (ServiceChargeType = {}));
export var ChargeType;
(function (ChargeType) {
    ChargeType["RATE_CARD"] = "Rate Card";
    ChargeType["COST_PLUS"] = "Cost Plus";
})(ChargeType || (ChargeType = {}));
/**
 * Maps a service charge type to a charge type.
 * @param status - The service charge type.
 * @returns The corresponding charge type.
 */
export const serviceToChargeType = (status) => {
    switch (status) {
        case ServiceChargeType.RATE_CARD:
            return ChargeType.RATE_CARD;
        case ServiceChargeType.COST_PLUS:
            return ChargeType.COST_PLUS;
        default:
            return undefined;
    }
};
/**
 * Converts a charge type to a service charge type.
 * @param status - The charge type to convert.
 * @returns The corresponding service charge type.
 */
export const chargeTypeToService = (status) => {
    switch (status) {
        case ChargeType.RATE_CARD:
            return ServiceChargeType.RATE_CARD;
        case ChargeType.COST_PLUS:
            return ServiceChargeType.COST_PLUS;
        default:
            return ServiceChargeType.UNASSIGNED;
    }
};
/**
 * Converts the service specific supply costs to madhive supply costs.
 * @param costValues - The service specific supply costs.
 * @returns The Madhive supply costs.
 */
export const serviceToMadhiveSupplyCosts = (costValues = {}) => {
    const { madhive_supply_creatives = {}, madhive_supply_cost, client_supply_cost, o_and_o_fee, charge_type } = costValues;
    const { cpm_15s, cpm_30s, cpm_60s, cpm_90s } = madhive_supply_creatives || {};
    const madhiveSupplyCreatives = {
        ...("cpm_15s" in madhive_supply_creatives &&
            !!cpm_15s && { cpm_15s: satoshisToDollars(cpm_15s) }),
        ...("cpm_30s" in madhive_supply_creatives &&
            !!cpm_30s && { cpm_30s: satoshisToDollars(cpm_30s) }),
        ...("cpm_60s" in madhive_supply_creatives &&
            !!cpm_60s && { cpm_60s: satoshisToDollars(cpm_60s) }),
        ...("cpm_90s" in madhive_supply_creatives &&
            !!cpm_90s && { cpm_90s: satoshisToDollars(cpm_90s) })
    };
    return {
        ...("charge_type" in costValues && { chargeType: serviceToChargeType(charge_type) }),
        ...("madhive_supply_creatives" in costValues && { madhiveSupplyCreatives }),
        ...("madhive_supply_cost" in costValues && {
            madhiveSupplyCost: madhive_supply_cost ?? 0
        }),
        ...("client_supply_cost" in costValues && {
            clientSupplyCost: client_supply_cost ?? 0
        }),
        ...("o_and_o_fee" in costValues && { oAndOFee: satoshisToDollars(o_and_o_fee ?? 0) })
    };
};
/**
 * Converts Madhive supply costs to service supply costs.
 * @param costValues - The Madhive supply costs to convert.
 * @returns The converted ServiceMadhiveSupplyCosts object.
 */
export const madhiveSupplyCostsToService = (costValues = {}) => {
    const { chargeType } = costValues;
    let madhive_supply_creatives = {};
    if (chargeType === ChargeType.RATE_CARD && costValues.madhiveSupplyCreatives) {
        const { madhiveSupplyCreatives } = costValues;
        const { cpm_15s, cpm_30s, cpm_60s, cpm_90s } = madhiveSupplyCreatives || {};
        madhive_supply_creatives = {
            ...("cpm_15s" in madhiveSupplyCreatives && {
                cpm_15s: dollarsToSatoshis(cpm_15s ?? 0)
            }),
            ...("cpm_30s" in madhiveSupplyCreatives && {
                cpm_30s: dollarsToSatoshis(cpm_30s ?? 0)
            }),
            ...("cpm_60s" in madhiveSupplyCreatives && {
                cpm_60s: dollarsToSatoshis(cpm_60s ?? 0)
            }),
            ...("cpm_90s" in madhiveSupplyCreatives && {
                cpm_90s: dollarsToSatoshis(cpm_90s ?? 0)
            })
        };
    }
    return {
        ...("madhiveSupplyCreatives" in costValues && { madhive_supply_creatives }),
        ...("madhiveSupplyCost" in costValues && {
            madhive_supply_cost: chargeType === ChargeType.COST_PLUS ? costValues.madhiveSupplyCost : 0
        }),
        ...("clientSupplyCost" in costValues && {
            client_supply_cost: costValues.clientSupplyCost ?? 0
        }),
        ...("oAndOFee" in costValues && {
            o_and_o_fee: dollarsToSatoshis(costValues.oAndOFee ?? 0)
        }),
        charge_type: chargeTypeToService(chargeType)
    };
};

import { GeographicEntityTypes } from "lib/utils/geography";

export const GET_GEOGRAPHY_PENDING = "GET_GEOGRAPHY_PENDING";
export const GET_GEOGRAPHY_SUCCESS = "GET_GEOGRAPHY_SUCCESS";
export const GET_GEOGRAPHY_FAILURE = "GET_GEOGRAPHY_FAILURE";
export const ABORT_GEOGRAPHY_REQUEST = "ABORT_GEOGRAPHY_REQUEST";

export interface IndividualRegion {
  name: string;
  id: RegionId;
}

type ZipCode = string;

type RegionId = string;

type CountryId = string;

export type ZipMappedToAllGeos = Record<
  GeographicEntityTypes,
  IndividualRegion[]
>;

export type GeoIdsToCorrespondingRegionTypes = Record<
  GeographicEntityTypes,
  RegionId[]
>;

export type ZipMappedToAllGeoIds = Record<
  ZipCode,
  GeoIdsToCorrespondingRegionTypes
>;

export type RegionToZipCodes = Record<
  GeographicEntityTypes,
  Record<RegionId, ZipCode[]>
>;

export type CountryToRegionToZipCodes = Record<CountryId, RegionToZipCodes>;

export interface GeographyState {
  readonly unfilteredGeoInfo: ZipMappedToAllGeos[];
  readonly isLoading: boolean;
  readonly isLoaded: boolean;
  readonly error: string | null;
}

interface GetGeographyPendingAction {
  type: typeof GET_GEOGRAPHY_PENDING;
}

interface GetGeographySuccessAction {
  type: typeof GET_GEOGRAPHY_SUCCESS;
  payload: ZipMappedToAllGeos[];
}

interface GetGeographyFailureAction {
  type: typeof GET_GEOGRAPHY_FAILURE;
  meta: {
    error: string;
  };
}

interface AbortGeographyRequestAction {
  type: typeof ABORT_GEOGRAPHY_REQUEST;
}

export type GeographyActionTypes =
  | GetGeographyPendingAction
  | GetGeographySuccessAction
  | GetGeographyFailureAction
  | AbortGeographyRequestAction;

import { CommonIconProps } from "../Icon/types";

function MonitorCube({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}: CommonIconProps) {
  return (
    <svg
      className="icon-monitor-cube"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 19 0.261719 C 18.382812 -0.0859375 17.628906 -0.0859375 17.015625 0.261719 L 13.585938 2.222656 C 12.960938 2.578125 12.578125 3.242188 12.578125 3.960938 L 12.578125 7.859375 C 12.578125 8.578125 12.960938 9.242188 13.585938 9.597656 L 17.015625 11.558594 C 17.628906 11.910156 18.382812 11.910156 19 11.558594 L 22.429688 9.597656 C 23.054688 9.242188 23.4375 8.578125 23.4375 7.859375 L 23.4375 3.960938 C 23.4375 3.242188 23.054688 2.578125 22.429688 2.222656 Z M 17.863281 1.753906 C 17.953125 1.703125 18.0625 1.703125 18.148438 1.753906 L 20.851562 3.296875 L 18.007812 4.921875 L 15.160156 3.296875 Z M 14.292969 4.773438 L 14.292969 7.859375 C 14.292969 7.960938 14.347656 8.058594 14.433594 8.109375 L 17.148438 9.660156 L 17.148438 6.40625 Z M 18.863281 9.660156 L 21.578125 8.109375 C 21.667969 8.058594 21.722656 7.960938 21.722656 7.859375 L 21.722656 4.773438 L 18.863281 6.40625 Z M 18.863281 9.660156 "
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M 2.324219 3.46875 C 2.714844 3.078125 3.246094 2.859375 3.796875 2.859375 L 10.003906 2.859375 C 10.476562 2.859375 10.859375 2.472656 10.859375 2 C 10.859375 1.527344 10.476562 1.144531 10.003906 1.144531 L 3.796875 1.144531 C 2.789062 1.144531 1.824219 1.542969 1.113281 2.253906 C 0.398438 2.96875 0 3.933594 0 4.941406 L 0 15.066406 C 0 16.074219 0.398438 17.039062 1.113281 17.753906 C 1.824219 18.464844 2.789062 18.863281 3.796875 18.863281 L 7.890625 18.863281 L 7.53125 21.722656 L 6.738281 21.722656 C 6.265625 21.722656 5.878906 22.105469 5.878906 22.582031 C 5.878906 23.054688 6.265625 23.4375 6.738281 23.4375 L 15.558594 23.4375 C 16.03125 23.4375 16.414062 23.054688 16.414062 22.582031 C 16.414062 22.105469 16.03125 21.722656 15.558594 21.722656 L 14.761719 21.722656 L 14.40625 18.863281 L 18.496094 18.863281 C 19.503906 18.863281 20.472656 18.464844 21.183594 17.75 C 21.894531 17.039062 22.292969 16.074219 22.292969 15.066406 L 22.292969 13.433594 C 22.292969 12.960938 21.910156 12.578125 21.4375 12.578125 C 20.964844 12.578125 20.578125 12.960938 20.578125 13.433594 L 20.578125 15.066406 C 20.582031 15.621094 20.359375 16.148438 19.96875 16.539062 C 19.578125 16.929688 19.050781 17.148438 18.496094 17.148438 L 13.449219 17.148438 C 13.441406 17.148438 13.429688 17.148438 13.421875 17.148438 L 8.875 17.148438 C 8.863281 17.148438 8.855469 17.148438 8.847656 17.148438 L 3.796875 17.148438 C 3.246094 17.148438 2.714844 16.929688 2.324219 16.539062 C 1.933594 16.148438 1.714844 15.621094 1.714844 15.066406 L 1.714844 4.941406 C 1.714844 4.386719 1.933594 3.859375 2.324219 3.46875 Z M 13.035156 21.722656 L 12.675781 18.863281 L 9.617188 18.863281 L 9.261719 21.722656 Z M 13.035156 21.722656 "
      />
    </svg>
  );
}

export default MonitorCube;

export enum ForecasterJobStatus {
  NOT_STARTED = "NOT_STARTED",
  RUNNING = "RUNNING",
  ERROR = "ERROR",
  FINISHED = "FINISHED"
}

/** UI level Segment categories */
export enum SegmentType {
  HOUSEHOLD = "household",
  CONSUMER = "consumer",
  DEMOGRAPHIC = "demographic",
  CUSTOM = "custom"
}

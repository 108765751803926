import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Search: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-search"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9335 7.11853C12.7754 4.96049 9.27656 4.96049 7.11853 7.11853C4.96049 9.27656 4.96049 12.7754 7.11853 14.9335C9.27656 17.0915 12.7754 17.0915 14.9335 14.9335C17.0915 12.7754 17.0915 9.27656 14.9335 7.11853ZM15.9941 6.05787C13.2503 3.31404 8.80169 3.31404 6.05787 6.05787C3.31404 8.80169 3.31404 13.2503 6.05787 15.9941C8.80169 18.738 13.2503 18.738 15.9941 15.9941C18.738 13.2503 18.738 8.80169 15.9941 6.05787Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.9297 14.9297C15.2226 14.6368 15.6974 14.6368 15.9903 14.9297L20.2803 19.2197C20.5732 19.5126 20.5732 19.9874 20.2803 20.2803C19.9874 20.5732 19.5126 20.5732 19.2197 20.2803L14.9297 15.9903C14.6368 15.6974 14.6368 15.2226 14.9297 14.9297Z"
        fill={fill}
      />
    </svg>
  );
};

export default Search;

import { FC, useMemo, useRef } from "react";
import { InputGroup } from "@blueprintjs/core";
import { v4 as uuid } from "uuid";
import * as Icons from "../../Icons";
import { useInputDateRange } from "./hooks/useInputDateRange";
import { useFocusOnMount } from "../../../hooks/useFocusOnMount";
import { InputDateRangeProps } from "../types";
import { DatePlaceholders } from "./constants";

/**
 * `<Input type="date" value={Array<Date | undefined>} />` allows user to input date range (start and end date).
 */
export const InputDateRange: FC<InputDateRangeProps> = ({
  value,
  error,
  disabled = false,
  id: incomingId,
  focusOnMount = false,
  time = false,
  placeholder: incomingPlaceholder,
  pickerPlacement = "start",
  picker,
  name,
  min,
  max,
  setEndToEndOfDay,
  onChange,
  setError,
  onKeyDown,
  onKeyUp,
  onFocus,
  onBlur,
  onInput
}) => {
  const startInputRef = useRef<HTMLInputElement | null>(null);

  const id = useMemo(
    () => incomingId || [`kit-${uuid()}`, `kit-${uuid()}`],
    [incomingId]
  );

  const [start, end] = useInputDateRange({
    error,
    value,
    time,
    min,
    max,
    setEndToEndOfDay,
    setError,
    onChange
  });

  useFocusOnMount(startInputRef, focusOnMount);

  const placeholder = useMemo(() => {
    if (incomingPlaceholder) {
      return incomingPlaceholder;
    }
    return time ? DatePlaceholders.WITH_TIME : DatePlaceholders.SIMPLE;
  }, [time, incomingPlaceholder]);

  return (
    <>
      <InputGroup
        tabIndex={0}
        data-testid="start-date-input"
        className="start-date-input"
        inputRef={startInputRef}
        value={start.rawValue}
        disabled={disabled}
        type="text"
        id={id[0]}
        name={name?.[0]}
        placeholder={placeholder}
        leftElement={pickerPlacement === "start" ? picker : undefined}
        onChange={e => start.setRawValue(e.target.value)}
        onInput={e => onInput?.(e, "start")}
        onKeyDown={e => onKeyDown?.(e, "start")}
        onKeyUp={e => onKeyUp?.(e, "start")}
        onFocus={e => onFocus?.(e, "start")}
        onBlur={e => {
          start.commitChange();
          onBlur?.(e, "start");
        }}
      />
      <div className="date-range-divider">
        <Icons.Arrow direction="right" />
      </div>
      <InputGroup
        tabIndex={0}
        data-testid="end-date-input"
        className="end-date-input"
        value={end.rawValue}
        disabled={disabled}
        type="text"
        id={id[1]}
        name={name?.[1]}
        placeholder={placeholder}
        rightElement={pickerPlacement === "end" ? picker : undefined}
        onChange={e => end.setRawValue(e.target.value)}
        onInput={e => onInput?.(e, "end")}
        onKeyDown={e => onKeyDown?.(e, "end")}
        onKeyUp={e => onKeyUp?.(e, "end")}
        onFocus={e => onFocus?.(e, "end")}
        onBlur={e => {
          end.commitChange();
          onBlur?.(e, "end");
        }}
      />
    </>
  );
};

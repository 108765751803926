import { useRef, useState } from "react";
import { clampNumberInput, sanitizeOnInput, sanitizeOnKeyDown } from "../utils";
import { useInputNumberProps } from "./types";

export const useInputNumber = ({
  onBlur,
  onChange,
  onKeyDown,
  precision,
  value,
  min,
  max
}: useInputNumberProps) => {
  const [rawValue, setRawValue] = useState<string>(value?.toString() || "");
  const [lastVisualKey, setlastVisualKey] = useState<string>("");
  const inputRef = useRef<HTMLInputElement | null>(null);

  const parseNumber = (value: number | string) => {
    return clampNumberInput(value?.toString(), min, max, precision!);
  };

  const setOnInput = (e: React.FormEvent<HTMLInputElement>) => {
    // sanitizing incoming input
    const sanitizeValue = sanitizeOnInput(
      rawValue,
      e.currentTarget?.value,
      (e.nativeEvent as InputEvent)?.data!
    );
    setRawValue(sanitizeValue);
  };

  const setOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    sanitizeOnKeyDown(rawValue, lastVisualKey, setlastVisualKey, e);
    onKeyDown?.(e);
  };

  const setInputOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const parsedValue = parseNumber(e.target.value);
    // `!= null` prevents null and undefined from being converted to a string like "null" and "undefined"
    setRawValue(parsedValue != null ? String(parsedValue) : "");

    if (parsedValue === null) {
      onChange?.(null);
    } else {
      onChange?.(Number(parsedValue));
    }
    onBlur?.(e);
  };

  return {
    setInputOnBlur,
    setOnInput,
    setOnKeyDown,
    inputRef,
    rawValue,
    setRawValue,
    parseNumber
  };
};

import { dealToService, serviceToDeal } from "./deals";
/**
 * Converts an array of `Deal` objects to an array of `ServiceDeal` objects.
 *
 * @param inventories - An array of grouped `Deal` objects representing the inventories.
 * @returns An array of `ServiceDeal` objects.
 */
export const inventoriesToServiceInventories = (inventories) => {
    return inventories?.map((deal) => dealToService(deal));
};
/**
 * Converts an array of ServiceDeal objects to an array of Deal objects.
 *
 * @param inventories - An array of grouped ServiceDeal objects to be converted.
 * @returns An array of Deal objects.
 */
export const serviceInventoriesToInventories = (inventories) => {
    return inventories?.map((deal) => serviceToDeal(deal));
};

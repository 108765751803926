import { KnownCreativeAssetSpecificationId } from "lib/constants/creatives";
import { SortDirection } from "lib/utils/search";
import { CreativeLibraryColumn } from "./ExistingCreativesScreen/constants";

export {
  ServiceMediaTypeLegacy,
  InscapeStatuses as InscapeStatus
} from "@madhive/mad-sdk";

export enum ScreenViewMode {
  READONLY = "readonly",
  EDIT = "edit"
}

export enum CreativeType {
  VIDEO = "Video",
  Linear = "Linear"
}

export enum CreativeArchiveStatus {
  ARCHIVED = "ARCHIVED",
  ACTIVE = "READY",
  BOTH = ""
}

export const VIEW_MODE_PARAM = "mode";

export enum CreativeLibraryFilterCategory {
  ADVERTISER = "Advertiser",
  CATEGORY = "Category",
  DURATION = "Duration",
  SECONDARY_DURATION = "Secondary Duration",
  INSCAPE_STATUS = "Inscape Status",
  CREATIVE_TYPE = "Creative Type",
  ARCHIVED_STATUS = "Archived Status",
  VIDEO_STANDARD = "Video Standards",
  PUBLISHER = "Publisher",
  PUBLISHER_STATUS = "Publisher Status"
}

export enum CreativeLibraryDurationOperators {
  EQUAL_TO = "Equal to",
  NOT_EQUAL_TO = "Not equal to",
  GREATER_THAN = "Greater than",
  LESS_THAN = "Less than",
  GREATER_THAN_OR_EQUAL_TO = "Greater than or equal to",
  LESS_THAN_OR_EQUAL_TO = "Less than or equal to",
  IS_BETWEEN = "Is between"
}

export enum CreativeLibraryDurationOptions {
  FIFTEEN = "15.000",
  THIRTY = "30.000",
  SIXTY = "60.000"
}

export const creativeLibraryDurationValidation: Record<
  CreativeLibraryDurationOperators,
  (
    crDuration: number,
    durationOption: CreativeLibraryDurationOptions | string
  ) => boolean
> = {
  [CreativeLibraryDurationOperators.EQUAL_TO]: (
    crDuration: number,
    durationOption: CreativeLibraryDurationOptions | string
  ) => crDuration === parseInt(durationOption, 10),
  [CreativeLibraryDurationOperators.NOT_EQUAL_TO]: (
    crDuration: number,
    durationOption: CreativeLibraryDurationOptions | string
  ) => crDuration !== parseInt(durationOption, 10),
  [CreativeLibraryDurationOperators.GREATER_THAN]: (
    crDuration: number,
    durationOption: CreativeLibraryDurationOptions | string
  ) => crDuration > parseInt(durationOption, 10),
  [CreativeLibraryDurationOperators.LESS_THAN]: (
    crDuration: number,
    durationOption: CreativeLibraryDurationOptions | string
  ) => crDuration < parseInt(durationOption, 10),
  [CreativeLibraryDurationOperators.GREATER_THAN_OR_EQUAL_TO]: (
    crDuration: number,
    durationOption: CreativeLibraryDurationOptions | string
  ) => crDuration >= parseInt(durationOption, 10),
  [CreativeLibraryDurationOperators.LESS_THAN_OR_EQUAL_TO]: (
    crDuration: number,
    durationOption: CreativeLibraryDurationOptions | string
  ) => crDuration <= parseInt(durationOption, 10),
  [CreativeLibraryDurationOperators.IS_BETWEEN]: (
    crDuration: number,
    durationOption: CreativeLibraryDurationOptions | string
  ) => crDuration >= parseInt(durationOption, 10)
};

export const ORG_TAB_TO_UI_ERROR_LABEL: Partial<
  Record<KnownCreativeAssetSpecificationId, string>
> = {
  [KnownCreativeAssetSpecificationId.BASIC]: "Standards",
  [KnownCreativeAssetSpecificationId.PREMION]: "Premion Standards"
};

export enum AssetDetailsColumnLabels {
  STANDARDS = "Standards",
  ASSET_DETAILS = "Asset Details"
}

export const ASSET_DETAIL_COLUMNS_HEADERS = [
  AssetDetailsColumnLabels.STANDARDS,
  AssetDetailsColumnLabels.ASSET_DETAILS
];

export enum VideoStandardsStatus {
  FAILED_DURATION = "Invalid Duration",
  FAILED_NON_DURATION_SPEC = "Didn't Pass",
  PASSED = "Passed"
}

export enum AudioStandardsStatus {
  FAILED_DURATION = "Invalid Duration",
  FAILED_NON_DURATION_SPEC = "Didn't Pass",
  PASSED = "Passed"
}

export const DEFAULT_PAGE_SIZE = 50;

export const DEFAULT_CREATIVE_SORT_COLUMN = CreativeLibraryColumn.LAST_UPDATED;

export const DEFAULT_CREATIVE_COLUMN_SORT_DIRECTION = {
  id: CreativeLibraryColumn.LAST_UPDATED,
  direction: SortDirection.DESCENDING
};

export interface MacroMetadataWithOriginalFlattened {
  macro: string;
  isSupported: boolean;
  original: { macro: string; index: number; isCheckedByUser: boolean };
  isReplaced: boolean;
}

import { useEffect, useState } from "react";
import { useIABCategories } from "@madhive/mad-hooks";
import { AvailableOption } from "components/Reusable/SmithersMultiSelect/SmithersMultiSelect";
import { AdvertiserFilterCategory } from "features/ManageAdvertisers/constants";

interface AvailableOptionIABCategory extends AvailableOption {
  category: AdvertiserFilterCategory.CATEGORY;
}
/**
 * Calls MadHooks useIABCategories and also generates a list of AvailableOption for filters
 * @returns The result of the hook call and a filterCategories array of AvailableOption
 */
export const useIABCategoriesSmithers = () => {
  const [filterCategories, setFilterCategories] =
    useState<AvailableOptionIABCategory[]>();
  const categoryResult = useIABCategories();
  const { data: allCategories } = categoryResult;

  useEffect(() => {
    let filterList: AvailableOptionIABCategory[] = [];

    if (allCategories) {
      // Sorts the categories so that each parent category (Tier 1) is followed by
      // its subcategories (Tier 2), and then the next parent category is
      // followed by its subcategories, and so on...
      filterList = allCategories.reduce(
        (acc, categoryOrSubcategory): AvailableOptionIABCategory[] => {
          const {
            description: name,
            parent_rtb_id,
            rtb_id: id,
            iab_subcategories: subcategories
          } = categoryOrSubcategory;

          // Return early if it's a subcategory, since we get the subcategories from its parent category below
          if (parent_rtb_id) return acc;

          acc.push({ id, name, category: AdvertiserFilterCategory.CATEGORY });

          subcategories?.forEach(({ rtb_id: id, description: name }) => {
            acc.push({ id, name, category: AdvertiserFilterCategory.CATEGORY });
          });
          return acc;
        },
        filterList
      );
    }
    setFilterCategories(filterList);
  }, [allCategories]);

  return {
    ...categoryResult,
    filterCategories
  };
};

import { deriveErrorResponse } from "lib/utils/api";
import { isTruthy } from "lib/utils/fp";
import { isErrorResponseValid, NewNewYorkAxiosError } from "@madhive/mad-sdk";

const formatErrorResponse = (e: NewNewYorkAxiosError) =>
  isErrorResponseValid(e)
    ? `${deriveErrorResponse(e).filter(isTruthy).join(",")}.`
    : "";

export const MESSAGE_GETTER_DICTIONARY = {
  GET_ALL_FAILURE: () => "There was an error retrieving all campaigns.",
  GET_ONE_FAILURE: (id?: string) =>
    `Failed to retrieve data for campaign with ID: ${id}`,
  SAVE_FAILURE: () => "Failed to save campaign, please try again.",
  CREATE_SUCCESS: () => "This campaign was successfully created.",
  UPDATE_SUCCESS: () => "Updates to campaign successfully saved.",
  ARCHIVE_SUCCESS: () => "This campaign has been successfully archived.",
  ARCHIVE_FAILURE: () => "This campaign could not be archived.",
  CURRENTLY_UPDATING: () =>
    "Campaign is currently being updated, please try again later.",
  FORBIDDEN_FROM_UPDATING_FAILURE: () =>
    "User is not permitted to perform this action.",
  LATEST_DATA_UNABLE_TO_BE_FETCHED_FAILURE: () =>
    "This campaign was created but the latest data for it could not be retrieved. It's recommended to refresh in order to see the latest data.",
  TEMPLATE_DOWNLOAD_SUCCESS: () => "Successfully downloaded template.",
  TEMPLATE_DOWNLOAD_FAILURE: () => "Failed to download template.",
  TEMPLATE_UPLOAD_SUCCESS: () => "Successfully uploaded template",
  TEMPLATE_UPLOAD_FAILURE: () => "Failed to upload template",
  BULK_UPDATE_FAILURE: (e: NewNewYorkAxiosError) =>
    `Failed to bulk update: ${formatErrorResponse(e)}`,
  UPLOAD_TEMPLATE_FAILURE: (e: NewNewYorkAxiosError) =>
    `Failed to upload template: ${formatErrorResponse(e)}`,
  EDITS_SAVE_SUCCESS: () => "These edits have been successfully saved.",
  REFRESH_DATA_FAILURE: () =>
    `Failed to get the latest data after making these changes. It's recommended you refresh the page to see the latest data.`,
  UPLOADED_TEMPLATE_SUCCESS: () =>
    `Successfully uploaded template. Please wait while the most up to date data is retrieved...`,
  UPLOADED_TEMPLATE_SUCCESS_DATA_FETCHED_SUCCESS: () =>
    `Successfully uploaded template and fetched latest data!`,
  UPLOADED_TEMPLATE_SUCCESS_DATA_FETCHED_FAILURE: () =>
    `Successfully uploaded template but failed to fetch latest data, please refresh your page to get latest data`
};

export const BACKEND_ERROR_IDENTIFIERS = {
  OUT_OF_SYNC:
    "Version out of sync. Local data may be stale. Please refresh your browser to resolve."
};

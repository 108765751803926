import {
  UniquesHistogramState,
  GET_UNIQUES_HISTOGRAM_PENDING,
  GET_UNIQUES_HISTOGRAM_SUCCESS,
  GET_UNIQUES_HISTOGRAM_FAILURE,
  ABORT_GET_UNIQUES_HISTOGRAM,
  UniquesHistogramActions
} from "./types";

export const uniquesHistogramInitialState: UniquesHistogramState = {
  cancellationSource: null,
  lastReceived: null,
  data: [],
  error: null
};

export const uniquesHistogramReducer = (
  state = uniquesHistogramInitialState,
  action: UniquesHistogramActions
): UniquesHistogramState => {
  switch (action.type) {
    case GET_UNIQUES_HISTOGRAM_PENDING:
      return {
        ...state,
        cancellationSource: action.meta.cancellationSource,
        error: null
      };
    case GET_UNIQUES_HISTOGRAM_SUCCESS:
      return {
        ...state,
        data: action.payload,
        cancellationSource: null,
        lastReceived: action.meta.timestamp,
        error: null
      };
    case GET_UNIQUES_HISTOGRAM_FAILURE:
      return {
        ...state,
        cancellationSource: null,
        error: action.meta.error
      };
    case ABORT_GET_UNIQUES_HISTOGRAM:
      return {
        ...state,
        cancellationSource: null
      };
    default:
      return state;
  }
};

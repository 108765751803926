import axios, { AxiosError, AxiosResponse } from "axios";
import { NewNewYorkAxiosError, isErrorResponseValid } from "@madhive/mad-sdk";
import { madSDK } from "../sdk";
import { pluralize } from "./formatting";
import { isTruthy } from "./fp";

export { isErrorResponseValid };

axios.interceptors.request.use(config => {
  const token = madSDK.authentication.getAuthorizationToken();
  if (token) {
    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization: `Bearer ${token}`
      }
    };
  }
  return config;
});

export const getAuthToken = async () => {
  const { currentUser } = madSDK.madFire.getAuth();

  if (!currentUser) {
    throw new Error("User is not logged in. ");
  }

  return currentUser.getIdToken();
};

export interface AxiosErrorResponseRequired extends AxiosError {
  response: AxiosResponse;
}

export const deriveErrorResponse = (e: NewNewYorkAxiosError): string[] =>
  e.response && e.response.data ? e.response.data.errors : [];

export const isHttpStatusForbidden = (e: NewNewYorkAxiosError) =>
  e && e.response && e.response.status === 403;

// PK: Decided to pull out the error message derivation logic to its own function since it was getting hairy in the catch block
// Ideally, we'd want to trim this down even more so it doesn't rely on as many parameters.
export const deriveCreativeCreationErrorMessage = (
  e: NewNewYorkAxiosError,
  maybeValidationErrors: boolean,
  validationErrors: string[]
): string =>
  // eslint-disable-next-line no-nested-ternary
  maybeValidationErrors
    ? // Validation errors (e.g name, advertiser missing)
      `Could not publish creative due to the following ${pluralize("error")(
        validationErrors.length
      )}: ${validationErrors.join(" ")}`
    : // Has visible error from backend, show errors
    isErrorResponseValid(e)
    ? `This creative could not be published to your library: ${deriveErrorResponse(
        e
      )
        .filter(isTruthy)
        .join(", ")}.`
    : // Generic error
      `This creative could not be published to your library.`;

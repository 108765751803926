import { ORGS_WITH_OPTIONAL_STATION, StationGroupOrganizations } from "../../../types";
import { validateStatus } from "./status";
import { validateStatus as validateLineItemStatus } from "../lineitems/validators";
import { requiredString } from "./generic";
export * from "./dates";
export * from "./frequency";
export * from "./dayparts";
export * from "./deviceCaps";
export * from "./pacing";
export * from "./status";
export function validators(sdk) {
    return {
        name: (campaign, value) => requiredString(value),
        advertiserId: (campaign, value) => requiredString(value),
        stationId: (campaign, value) => {
            const currentOrgId = sdk.getCurrentUser()?.primaryOrganizationId;
            if (!currentOrgId ||
                ORGS_WITH_OPTIONAL_STATION.has(currentOrgId) ||
                !StationGroupOrganizations.has(currentOrgId)) {
                return undefined;
            }
            return requiredString(value);
        },
        /**
         * status validates if the current or future state of status jives with the rest of the line item.
         */
        statusChange: (campaign, value, current, updating) => {
            return validateStatus(campaign, value, current, updating, (lineItem, value) => {
                const currentValue = lineItem?.status;
                return validateLineItemStatus(lineItem, typeof value === "undefined" ? currentValue : value, currentValue, typeof value !== "undefined");
            });
        }
    };
}

export class AdvertisersFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvertisersFetchFailed);
        }
        this.name = "AdvertisersFetchFailed";
        this.message = "Failed to retrieve advertisers. Please try again at a later time.";
    }
}
export class AdvertiserCreationFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvertiserCreationFailed);
        }
        this.name = "AdvertiserCreationFailed";
        this.message = "Failed to create advertiser. Please try again at a later time.";
    }
}
export class AdvertiserCreationFailedHost extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvertiserCreationFailedHost);
        }
        this.name = "AdvertiserCreationFailedHost";
        this.message = "Failed to create advertiser. The Advertiser Domain is invalid.";
    }
}
export class AdvertiserCreationFailedIdExists extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvertiserCreationFailedIdExists);
        }
        this.name = "AdvertiserCreationFailedIdExists";
        this.message =
            "Failed to create advertiser. An advertiser with this External ID already exists.";
    }
}
export class AdvertiserUpdateFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvertiserUpdateFailed);
        }
        this.name = "AdvertiserUpdateFailed";
        this.message = "Failed to update advertiser. Please try again at a later time.";
    }
}
export class AdvertiserUpdateFailedHost extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvertiserUpdateFailedHost);
        }
        this.name = "AdvertiserUpdateFailedHost";
        this.message = "Failed to update advertiser. The Advertiser Domain is invalid.";
    }
}
export class AdvertiserUpdateFailedIdExists extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvertiserUpdateFailedIdExists);
        }
        this.name = "AdvertiserUpdateFailedIdExists";
        this.message =
            "Failed to update advertiser. An advertiser with this External ID already exists.";
    }
}
export class AdvertiserDeleteFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvertiserDeleteFailed);
        }
        this.name = "AdvertiserDeleteFailed";
        this.message = "Failed to delete advertiser. Please try again at a later time.";
    }
}
export class AdvertiserIdCreationFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvertiserIdCreationFailed);
        }
        this.name = "AdvertiserIdCreationFailed";
        this.message = "Failed to create a ID for the new advertiser";
    }
}
export class AdvertiserInvalidAdomainSaveFailure extends Error {
    constructor(adomain) {
        super();
        this.adomain = adomain;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvertiserInvalidAdomainSaveFailure);
        }
        this.name = "AdvertiserInvalidAdomainSaveFailure";
        this.message = `"${this.adomain}" is an invalid ad domain for an advertiser. Please try again.`;
    }
    static get apiErrorMessage() {
        return "invalid adomain";
    }
    static is(errors) {
        return errors.some((e) => e.includes(this.apiErrorMessage));
    }
}

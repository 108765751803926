import { CampaignStatus } from "../../models/campaigns/instruction";
export const REGEX = {
    CampaignStatus: {
        /** matches any string that looks like the value of CampaignStatus.LIVE_WARNING or 'warning', excluding parentheses and casing */
        LIVE_WARNING: new RegExp(`^${CampaignStatus.LIVE_WARNING.replace(/[()]/g, "\\$&?")}$|^warning$`, "i"),
        /** matches any string that looks like the value of CampaignStatus.MISSING_WHITELIST, ignoring casing */
        MISSING_WHITELIST: new RegExp(`^${CampaignStatus.MISSING_WHITELIST}$`, "i"),
        /** matches any string that looks like the value of CampaignStatus.READY or 'ready', ignoring casing */
        READY: new RegExp(`^${CampaignStatus.READY}$|^ready$`, "i"),
        /** matches any string that looks like the value of CampaignStatus.WAITING or 'waiting', ignoring casing */
        WAITING: new RegExp(`^${CampaignStatus.WAITING}$|^waiting$`, "i")
    }
};

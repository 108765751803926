import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const HeartbeatMonitor: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-heartbeat-monitor"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5001 3.75C17.5336 3.75 16.7501 4.5335 16.7501 5.5C16.7501 6.4665 17.5336 7.25 18.5001 7.25C19.4666 7.25 20.2501 6.4665 20.2501 5.5C20.2501 4.5335 19.4666 3.75 18.5001 3.75ZM15.2501 5.5C15.2501 3.70507 16.7052 2.25 18.5001 2.25C20.295 2.25 21.7501 3.70507 21.7501 5.5C21.7501 7.29493 20.295 8.75 18.5001 8.75C16.7052 8.75 15.2501 7.29493 15.2501 5.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 3.75C5.65279 3.75 3.75 5.65279 3.75 8V16C3.75 18.3472 5.65279 20.25 8 20.25H16C18.3472 20.25 20.25 18.3472 20.25 16V11C20.25 10.5858 20.5858 10.25 21 10.25C21.4142 10.25 21.75 10.5858 21.75 11V16C21.75 19.1756 19.1756 21.75 16 21.75H8C4.82436 21.75 2.25 19.1756 2.25 16V8C2.25 4.82436 4.82436 2.25 8 2.25H13C13.4142 2.25 13.75 2.58579 13.75 3C13.75 3.41421 13.4142 3.75 13 3.75H8Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0639 9.00271C11.3363 9.02591 11.5745 9.19555 11.6856 9.44542L13.132 12.7002L13.8763 11.5839C14.0154 11.3753 14.2496 11.25 14.5003 11.25H17.0001C17.4143 11.25 17.7501 11.5858 17.7501 12C17.7501 12.4142 17.4143 12.75 17.0001 12.75H14.9017L13.624 14.6661C13.4723 14.8936 13.2088 15.0205 12.9364 14.9973C12.6639 14.9741 12.4257 14.8045 12.3147 14.5546L10.8682 11.2998L10.1239 12.4161C9.98482 12.6247 9.75066 12.75 9.49992 12.75H7.00012C6.58591 12.75 6.25012 12.4142 6.25012 12C6.25012 11.5858 6.58591 11.25 7.00012 11.25H9.09858L10.3762 9.33392C10.5279 9.10642 10.7914 8.9795 11.0639 9.00271Z"
      />
    </svg>
  );
};

export default HeartbeatMonitor;

/**
 * Recursively searches for an exact match of a string inside anything
 * @param searchTerm String to find
 * @param data Thing to search for the string
 * @returns boolean indicating if the search term is found in the thing to search
 */
const recursiveExactMatchSearch = (
  data: any,
  key: string | undefined,
  searchTerm: string,
  path: string = ""
): boolean => {
  if (data === undefined || data === null || (key && !key.startsWith(path))) {
    return false;
  }

  if (typeof data === "string") {
    return data.toLowerCase().includes(searchTerm.toLowerCase());
  }
  if (typeof data === "number") {
    return data.toString().includes(searchTerm);
  }

  if (!key) {
    return false;
  }
  if (typeof data === "object") {
    return Object.keys(data).some(myKey => {
      const value = data[myKey];
      let myPath = path ? `${path}.${myKey}` : myKey;
      if (Array.isArray(data)) {
        myPath = path;
      }
      return recursiveExactMatchSearch(value, key, searchTerm, myPath);
    });
  }
  return false;
};

/**
 * Performs an exact match search on the given data and keys and search term
 * @param data Data to search
 * @param keys Fields to include in the search. Can be a path, e.g., "myArray.label"
 * @param searchTerm The term to search for
 * @returns An array of the data that matches the search term
 */
export const includesSearch = <T extends Record<string, any> | string>(
  data: T[] = [],
  keys: string[] | string,
  searchTerm: string | undefined | null
): T[] => {
  if (searchTerm && data.length) {
    let normalizedKeys = Array.isArray(keys) ? keys : [keys];

    /**
     * If no keys are passed in, and first item is typeof string,
     * we normalize the keys to be an array of 1 empty string.
     * The empty string will evaluate to false,
     * and recursiveExactMatchSearch will search on the string passed in.
     */
    if (normalizedKeys.filter(k => !!k).length === 0) {
      if (typeof data[0] !== "string") {
        return data;
      }
      normalizedKeys = [""];
    }

    return data.filter(item =>
      normalizedKeys.some(key => {
        return recursiveExactMatchSearch(item, key, searchTerm);
      })
    );
  }
  return data;
};

import {
  CommonCampaignExternalMetadata,
  PremionCampaignExternalMetadata,
  ScrippsCampaignExternalMedatata
} from "campaign-types";
import { madSDK } from "lib/sdk";

export const BASE_API_URL = madSDK.urls.baseAPIUrl;

export const BASE_HERMES_URL = madSDK.urls.baseHermesUrl;

export const BASE_HERMES_URL_STATIC_ASSETS =
  madSDK.urls.baseHermesUrlStaticAssets;

export const MADHIVE_REPORTING_BASE_URL = madSDK.urls.madhiveReportingBaseUrl;

export const MADHIVE_ENCODER_BASE_URL = madSDK.urls.madhiveEncoderBaseUrl;

export const CREATIVE_ASSET_FOLDER_BASE_URL =
  madSDK.urls.creativeAssetFolderBaseUrl;

export const ADBOOK_CAMPAIGNS_BASE_URL = madSDK.urls.adbookCampaignsBaseUrl;

export const WIDEORBIT_ERRORS_BASE_URL = madSDK.urls.wideorbitErrorsBaseUrl;

export const DEFAULT_CAMPAIGN_METADATA_FIELDS: Partial<
  CommonCampaignExternalMetadata &
    PremionCampaignExternalMetadata &
    ScrippsCampaignExternalMedatata
> = {
  client_code: undefined,
  client_estimate_code: undefined,
  rfp_name: undefined,
  rfp_detail_name: undefined,
  revenue_type: undefined,
  adbook_consumer: undefined,
  adbook_cpm: undefined,
  adbook_demo: undefined,
  adbook_geo: undefined,
  adbook_package: undefined,
  adbook_product: undefined,
  adbook_status: undefined,
  adbook_market: undefined,
  product_code: undefined,
  wide_orbit_id: undefined
};

import axios from "axios";
import { FootTrafficAttributionReportsFetchFailure } from "./errors";
import { Poller } from "../handlers/poller";
class FootTrafficPoller extends Poller {
    constructor(sdk, config) {
        super(sdk, {
            data: config.data.filter((p) => p.status === "RUNNING"),
            subject: config.subject,
            interval: config?.interval,
            unsubscribeUponDataCompletion: true
        });
        this.clearCache = config.clearCache;
    }
    fetchData() {
        const resultKeys = [];
        for (const data of this.data.values()) {
            resultKeys.push(data.resultKey);
        }
        return new Promise((resolve, reject) => axios
            .post(`${this.sdk.urls.madhiveReportingBaseUrl}/foot_traffic/reports`, {
            resultKeys
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async ({ data }) => {
            const refetchedReports = data?.items || [];
            const updatedReports = this.updateReports(refetchedReports);
            resolve(updatedReports);
        })
            .catch(() => {
            reject(new FootTrafficAttributionReportsFetchFailure());
        }));
    }
    updateReports(reports) {
        const updatedData = [];
        for (const report of reports) {
            if (report.status !== "RUNNING") {
                // when report has changed status remove them from the data array and add them to the updatedData array so they can be sent to the subject
                updatedData.push(report);
                // when a repport has finished remove it from the data array
                this.data.delete(report.id);
            }
        }
        if (updatedData.length) {
            this.clearCache?.();
        }
        return updatedData;
    }
}
export default FootTrafficPoller;

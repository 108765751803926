import { FC } from "react";
import { EmptyContentProps } from "./types";
import { styles } from "./styles";
import Icon from "../Icon";

const EmptyContent: FC<EmptyContentProps> = ({
  accepts = [],
  multiple = false
}) => (
  <div className="subKit-UploaderEmptyContent" css={styles.instructionsWrapper}>
    <span css={styles.instructions}>
      <Icon name="Upload" size="small" />

      <span>
        Drop your file{multiple ? "s" : ""} here or{" "}
        <strong css={styles.clickableText}>click to upload</strong>
      </span>
    </span>

    {accepts.length ? (
      <span css={styles.acceptedFileTypes}>Accepts {accepts.join(", ")}</span>
    ) : null}
  </div>
);

export default EmptyContent;

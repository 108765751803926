import { Plugin } from "..";
export var PERMISSIONS;
(function (PERMISSIONS) {
    PERMISSIONS[PERMISSIONS["NONE"] = 0] = "NONE";
    PERMISSIONS[PERMISSIONS["EXECUTE"] = 1] = "EXECUTE";
    PERMISSIONS[PERMISSIONS["WRITE"] = 2] = "WRITE";
    PERMISSIONS[PERMISSIONS["WRITE_EXECUTE"] = 3] = "WRITE_EXECUTE";
    PERMISSIONS[PERMISSIONS["READ"] = 4] = "READ";
    PERMISSIONS[PERMISSIONS["READ_EXECUTE"] = 5] = "READ_EXECUTE";
    PERMISSIONS[PERMISSIONS["READ_WRITE"] = 6] = "READ_WRITE";
    PERMISSIONS[PERMISSIONS["ALL"] = 7] = "ALL";
})(PERMISSIONS || (PERMISSIONS = {}));
/**
 * Security class to handle check if a User has access to different domains
 */
export class Security extends Plugin {
    /**
     * To create a new secuirty class we will need a way to get the user / or default to only
     * support a passed user
     * @param gatherer a collection of needed method to get a User
     */
    constructor(sdk) {
        super(sdk);
    }
    getCurrentUser() {
        return this.sdk.getCurrentUser() || null;
    }
    /**
     * Method will return if a user has a specific type of access for a domain
     * @param domain Domain to find access for
     * @param user User to find the domain for
     * @param typeOfAccess Permission to check against, defauls to PERMISSIONS.NONE
     * @returns boolean true if the User has access and false if they do not have access of that type
     */
    hasAccessOfType(domain, user, typeOfAccess = PERMISSIONS.NONE) {
        if (!domain || domain.length === 0) {
            return false;
        }
        const accessLevel = this.getAccessLevel(user, domain);
        return !!(accessLevel & typeOfAccess);
    }
    /**
     * Helper method to get a list of the PERMISSIONS a user has for a specific domain.
     * @param domain The domain to get the permissions for
     * @param user Optional User to get the permission of (will use the given gatherer to getCurrentUser if no user supplied)
     * @returns A Object of permissions with a boolean value if the User has or has no permission of that type
     */
    getPermissions(domain, user) {
        const permission = this.getAccessLevel(user || this.getCurrentUser(), domain);
        return {
            none: permission === PERMISSIONS.NONE,
            execute: !!(permission & PERMISSIONS.EXECUTE),
            write: !!(permission & PERMISSIONS.WRITE),
            read: !!(permission & PERMISSIONS.READ)
        };
    }
    /**
     * Method is a generic method to test any type of access and return a boolean
     * result for the given User (or current User) and the domain.
     * @param domain Domain to check permission for
     * @param typeOfAccess Optional access type to find access for, defaults to READ_WRITE
     * @param user Optional User to check against, defaults to gatherer.getCurrentUser()
     * @returns Boolean if the User has that permission type for that domain
     */
    hasAccess(domain, typeOfAccess = PERMISSIONS.READ_WRITE, user = this.getCurrentUser()) {
        return this.hasAccessOfType(domain, user, typeOfAccess);
    }
    /**
     * Method is a helper method to just shortcut a write access for a domain
     * @param domain Domain to check write access for
     * @param user User to check for that access (defaults to this.gatherer.getCurrentUser())
     * @returns Boolean of true if user has write access and false if they don't
     */
    hasWriteAccess(domain, user) {
        return this.hasAccess(domain, PERMISSIONS.WRITE, user);
    }
    /**
     * Method is a helper method to just shortcut a read access for a domain
     * @param domain Domain to check read access for
     * @param user User to check for that access (defaults to this.gatherer.getCurrentUser())
     * @returns Boolean of true if user has read access and false if they don't
     */
    hasReadAccess(domain, user) {
        return this.hasAccess(domain, PERMISSIONS.READ, user);
    }
    /**
     * Method is a helper method to just shortcut a execute access for a domain
     * @param domain Domain to check execute access for
     * @param user User to check for that access (defaults to this.gatherer.getCurrentUser())
     * @returns Boolean of true if user has execute access and false if they don't
     */
    hasExecuteAccess(domain, user) {
        return this.hasAccess(domain, PERMISSIONS.EXECUTE, user);
    }
}

import { DayOfTheWeek } from "../types";
/**
 * @param dayparts: dayparts structured in a key-value, day-hours format.
 * @return: an array of dayparts in a human-friendly format (i.e., an array, with each entry being a day + its list of *included* hours).
 */
export const formatDayparts = (dayparts) => {
    const parts = [];
    const hours = [
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23
    ];
    // dayparts are stored as excluded vals, but we want to show included vals for the formatted version
    for (const day of Object.values(DayOfTheWeek)) {
        const entry = dayparts && dayparts[day];
        let includedHours = [...hours];
        if (entry) {
            const excludedHours = new Set(entry);
            includedHours = includedHours.filter((hour) => !excludedHours.has(hour));
        }
        parts.push({
            day,
            hours: includedHours.join(", ")
        });
    }
    return parts;
};
/**
 * @param deviceCaps: a set of device caps.
 * @return: the device cap set in a human-friendly format.
 */
export const formatDeviceCaps = (deviceCaps) => {
    return {
        Desktop: deviceCaps?.desktop ? `${deviceCaps.desktop.toFixed(2)}%` : "none",
        Mobile: deviceCaps?.mobile ? `${deviceCaps.mobile.toFixed(2)}%` : "none",
        TV: deviceCaps?.tv ? `${deviceCaps.tv.toFixed(2)}%` : "none",
        Tablet: deviceCaps?.tablet ? `${deviceCaps.tablet.toFixed(2)}%` : "none"
    };
};
export const convertBitsToKilobits = (bits) => bits / 1000;
export const convertBitsToMegabits = (bits) => bits / 1000 / 1000;

import { setDateToMidnight } from "../../utils/dates";
import { DealBidRuleType, DealPriceType, DealSource, DealStatus, DealType } from "../../models/deals";
import { FilterTypes, RemoteConfigProperties } from "../../types";
import { lastValueFrom } from "rxjs";
export function validators(sdk) {
    return {
        name: (deal, value) => {
            if (!value?.trim()) {
                return "A deal must have a name.";
            }
            return undefined;
        },
        "price.type": (deal, value) => {
            const priceType = value?.trim();
            const dealType = deal?.deal?.type;
            if (!priceType) {
                return "A deal must have a cost type.";
            }
            if (!Object.values(DealPriceType).includes(priceType)) {
                return "A deal must have a valid cost type.";
            }
            if (dealType &&
                [DealType.PG, DealType.SUPER_TAG].includes(dealType) &&
                priceType !== DealPriceType.FIXED) {
                return `A ${dealType} deal must have a cost type of "${DealPriceType.FIXED}".`;
            }
            return undefined;
        },
        "price.cpm": (deal, value) => {
            const dealType = deal?.deal?.type;
            if (dealType === DealType.SUPER_TAG && !sdk.getCurrentUser()?.isDev) {
                return undefined;
            }
            if (typeof value !== "number") {
                if (dealType === DealType.PMP) {
                    return undefined;
                }
                return `CPM is required.`;
            }
            if (value < 0) {
                return `A${dealType ? ` "${dealType}"` : ""} deal's CPM must be greater than or equal to 0.`;
            }
            if (value >= 100) {
                return `A deal's CPM must be less than $100.00.`;
            }
            return undefined;
        },
        "price.bidRule.type": (deal, value) => {
            const bidRuleType = value?.trim();
            const dealPriceType = deal?.price?.type;
            if (!bidRuleType) {
                return undefined;
            }
            if (!Object.values(DealBidRuleType).includes(bidRuleType)) {
                return "A deal must have a valid bid instruction.";
            }
            if (dealPriceType !== DealPriceType.FLOOR) {
                return `Only deals with a cost type of "${DealPriceType.FLOOR}" can have a bid instruction.`;
            }
            return undefined;
        },
        "price.bidRule.cpm": (deal, value) => {
            const bidRuleType = deal?.price?.bidRule?.type;
            if (!bidRuleType) {
                return undefined;
            }
            if (typeof value !== "number") {
                return `${bidRuleType} CPM required.`;
            }
            if (value < 1) {
                return `${bidRuleType} CPM must be greater than or equal to 1.`;
            }
            const min = deal?.price?.cpm || 1;
            if (value < min) {
                return `${bidRuleType} CPM must be greater than or equal to ${min}.`;
            }
            if (value >= 100) {
                return `${bidRuleType} CPM must be less than $100.00.`;
            }
            return undefined;
        },
        status: (deal, value) => {
            const status = value?.trim();
            if (!status) {
                return "A deal must have a status.";
            }
            if (!Object.values(DealStatus).includes(status)) {
                return "A deal must have a valid status.";
            }
            const dealType = deal?.deal?.type;
            if (dealType &&
                [DealType.PMP, DealType.PG].includes(dealType) &&
                status === DealStatus.TEST) {
                return `A "${dealType}" deal cannot have status of "${DealStatus.TEST}".`;
            }
            return undefined;
        },
        "deal.type": (deal, value) => {
            const dealType = value?.trim();
            if (!dealType) {
                return "A deal must have a type.";
            }
            if (dealType && !Object.values(DealType).includes(dealType)) {
                return "A deal must have a valid type.";
            }
            return undefined;
        },
        "deal.id": (deal, value) => {
            const dealId = value?.trim();
            const dealType = deal?.deal?.type;
            if (dealType && [DealType.PMP, DealType.PG].includes(dealType)) {
                if (!dealId) {
                    return `A "${dealType}" deal must have an ID.`;
                }
                if (dealId.includes(" ")) {
                    return `A deal ID cannot contain spaces.`;
                }
                return undefined;
            }
            else if (dealType && dealId) {
                return `A "${dealType}" deal cannot have an ID.`;
            }
            return undefined;
        },
        "parentOrg.id": async (deal, value) => {
            if (!sdk.isRootUser()) {
                return undefined;
            }
            const parentOrgId = value?.trim();
            if (!parentOrgId) {
                return `A${deal?.deal?.type ? ` "${deal?.deal?.type}"` : ""} deal must have a Parent Org ID.`;
            }
            const org = await sdk.organizations
                .find_once({
                where: [
                    {
                        field: "id",
                        type: FilterTypes.EQ,
                        value: parentOrgId
                    }
                ]
            })
                .catch(() => undefined);
            if (org?.id !== parentOrgId) {
                return "Must be a valid Org ID.";
            }
            return undefined;
        },
        "billingOrg.id": async (deal, value) => {
            if (!sdk.isRootUser()) {
                return undefined;
            }
            const billingOrgId = value?.trim();
            const dealType = deal?.deal?.type;
            if (!billingOrgId) {
                if (dealType && [DealType.PMP, DealType.PG].includes(dealType)) {
                    return `A "${dealType}" deal must have a Billing Org ID.`;
                }
                else {
                    return undefined;
                }
            }
            const org = await sdk.organizations
                .find_once({
                where: [
                    {
                        field: "id",
                        type: FilterTypes.EQ,
                        value: billingOrgId
                    }
                ]
            })
                .catch(() => undefined);
            if (org?.id !== billingOrgId) {
                return "Must be a valid Org ID.";
            }
            return undefined;
        },
        endDate: (deal, value) => {
            const endDateTime = value instanceof Date ? setDateToMidnight(value)?.getTime?.() : undefined;
            const startDateTime = deal?.startDate instanceof Date
                ? setDateToMidnight(deal?.startDate)?.getTime?.()
                : undefined;
            if (endDateTime && (!startDateTime || endDateTime <= startDateTime)) {
                return "A deal's end date must come after its start date.";
            }
            return undefined;
        },
        "ssp.code": async (deal, value) => {
            if (deal?.deal?.type === DealType.SUPER_TAG) {
                return undefined;
            }
            const sspCode = value?.trim();
            if (!sspCode) {
                return "A deal must have an SSP.";
            }
            const ssp = await lastValueFrom(sdk.ssps.find_once({
                where: [
                    {
                        field: "code",
                        type: FilterTypes.EQ,
                        value: sspCode
                    }
                ]
            })).catch(() => undefined);
            if (ssp?.code !== sspCode) {
                return "Must be a valid SSP.";
            }
            return undefined;
        },
        source: (deal, value) => {
            // if not dev user don't run source validation
            if (!sdk.getCurrentUser()?.isDev) {
                return undefined;
            }
            const isInventorySourceEnabled = sdk.featureFlags.isFlagEnabled(RemoteConfigProperties.INTERNAL_MANAGE_DEALS_INVENTORY_SOURCE);
            if (!isInventorySourceEnabled) {
                return undefined;
            }
            const source = value?.trim();
            if (!source) {
                return "A deal must have a source.";
            }
            if (!Object.values(DealSource).includes(source)) {
                return "A deal must have a valid source.";
            }
            return undefined;
        }
    };
}

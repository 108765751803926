import { css } from "@emotion/react";

const baseVariant = {
  /** Heading variants only have one style so !important is used to prevent the application
   * of styles (like bold, monospace, etc.). */
  heading: css`
    font-family: var(--font-family-heading) !important;
    font-weight: var(--font-weight-heading) !important;
    text-decoration: none !important;
    font-style: none !important;
    text-transform: none !important;
  `,
  body: css`
    font-family: var(--font-family-body);
  `
};

export const variant = {
  heading1: css`
    ${baseVariant.heading}
    font-size: var(--font-size-heading-lg);
    line-height: var(--font-line-height-heading-lg);
  `,
  heading2: css`
    ${baseVariant.heading}
    font-size: var(--font-size-heading-md);
    line-height: var(--font-line-height-heading-md);
  `,
  heading3: css`
    ${baseVariant.heading}
    font-size: var(--font-size-heading-sm);
    line-height: var(--font-line-height-heading-sm);
  `,
  heading4: css`
    ${baseVariant.heading}
    font-size: 1rem;
    line-height: 1rem;
  `,
  body: css`
    ${baseVariant.body}
    font-size: var(--font-size-body);
    line-height: var(--font-line-height-body);
  `,
  micro: css`
    ${baseVariant.body}
    font-size: var(--font-size-micro);
    line-height: var(--font-line-height-micro);
  `
};

export const style = {
  normal: css`
    font-weight: var(--font-weight-normal);
  `,
  bold: css`
    font-weight: var(--font-weight-bold);
  `,
  italic: css`
    font-style: italic;
  `,
  underline: css`
    font-style: underline;
  `,
  uppercase: css`
    text-transform: uppercase;
  `,
  monospace: css`
    font-family: var(--font-family-data), "Red Hat Mono", mono;
  `
};

export const color = {
  primary: css`
    color: var(--color-primary);
  `,
  secondary: css`
    color: var(--color-secondary);
  `,
  error: css`
    color: var(--indicator-bad);
  `
};

import { formatToNearestPrecision } from "./number";

export const displayCurrency = (value: string | number) => {
  const n = +value;
  // eslint-disable-next-line no-restricted-globals
  return typeof n !== "number" || isNaN(n)
    ? "--"
    : n.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
};

export const displayCurrencyFromFirestore = (value: string | number) =>
  // eslint-disable-next-line no-restricted-globals
  typeof value !== "number" || isNaN(value)
    ? "--"
    : displayCurrency(value / 10000);

/**
 * Adds a dollar sign and decimals to a number. E.g. `formatAsUsd(123)` => "$123.00"
 */
export const formatAsUsd = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2
}).format;

export const formatAsUsdWholeNumber = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0
}).format;

export const formatCurrencyWithDollar = (num: number): string =>
  `$${formatToNearestPrecision(num)}`;

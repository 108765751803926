import { Handler } from "../modules/handlers";
import { GetHouseholdsCountError, NotImplementedError } from "../errors";
import axios from "axios";
class HouseholdsCount extends Handler {
    constructor(sdk) {
        super(sdk, "households-count");
    }
    /* eslint-disable-next-line class-methods-use-this, no-shadow, no-unused-vars */
    async findItems(filters) {
        const zipCodes = filters?.where?.find((filter) => filter.field === "zipCodes")?.value;
        if (!zipCodes) {
            throw "No zips were supplied to the endpoint, therefore households count will be zero";
        }
        // Shouldn't cache this; the size of the generated cache keys (i.e. joined zipcodes) would be a perf concern
        return axios
            .post(`${this.sdk.urls.baseHermesUrlStaticAssetsTempV2}/geo_zip_hhs`, { Ids: zipCodes }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => res.data)
            .catch((err) => {
            throw new GetHouseholdsCountError(err);
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async saveItem(count) {
        throw new NotImplementedError("save");
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}
export default HouseholdsCount;

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Minus: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-minus"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 11.75C4 11.3358 4.33579 11 4.75 11H19.75C20.1642 11 20.5 11.3358 20.5 11.75C20.5 12.1642 20.1642 12.5 19.75 12.5H4.75C4.33579 12.5 4 12.1642 4 11.75Z"
      />
    </svg>
  );
};

export default Minus;

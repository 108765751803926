import { useSelector } from "react-redux";
import { ViewId } from "@madhive/mad-sdk";
import { selectProductionViews } from "appReducers/authReducer";
import useDocumentTitle from "hooks/useDocumentTitle";
import { FC, PropsWithChildren } from "react";
import { mapViewIdToView } from "./utils";

interface Props {
  viewId: ViewId | ViewId[];
}

const DocumentTitler: FC<PropsWithChildren<Props>> = props => {
  const orgProductionViews = useSelector(selectProductionViews);

  const view = mapViewIdToView(
    Array.isArray(props.viewId) ? props.viewId[0] : props.viewId,
    orgProductionViews
  );

  useDocumentTitle(view.title);

  return <>{props.children}</>;
};

export default DocumentTitler;

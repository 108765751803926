import {
  PixelReportState,
  PixelReportActionTypes,
  GET_PIXEL_REPORT_PENDING,
  GET_PIXEL_REPORT_FAILURE,
  GET_PIXEL_REPORT_SUCCESS,
  ABORT_GET_PIXEL_REPORT_REQUEST
} from "./types";

export const pixelReportInitialState: PixelReportState = {
  reportData: null,
  cancellationSource: null,
  lastReceivedReportAt: null,
  error: null
};

export const pixelReportReducer = (
  state = pixelReportInitialState,
  action: PixelReportActionTypes
): PixelReportState => {
  switch (action.type) {
    case GET_PIXEL_REPORT_PENDING:
      return {
        ...state,
        error: null,
        cancellationSource: action.meta.cancellationSource
      };
    case GET_PIXEL_REPORT_FAILURE:
      return {
        ...state,
        error: action.meta.error.message,
        cancellationSource: null
      };
    case GET_PIXEL_REPORT_SUCCESS: {
      return {
        reportData: action.payload,
        error: null,
        cancellationSource: null,
        lastReceivedReportAt: action.meta.timestamp
      };
    }
    case ABORT_GET_PIXEL_REPORT_REQUEST:
      return {
        ...state,
        cancellationSource: null,
        error: null
      };
    default:
      return state;
  }
};

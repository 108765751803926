import { ReactNode, FC } from "react";
import { IRef, Menu as BlueprintMenu } from "@blueprintjs/core";
import { css } from "@emotion/react";
import { DataAttributes } from "../types";

type Props = {
  children: ReactNode;
  ulRef?: IRef<HTMLUListElement>;
  minWidth?: "inherit" | "auto" | `var(--spacing-${string})`; // default: inherited
} & DataAttributes;

export const Menu: FC<Props> = ({
  ulRef,
  children,
  minWidth,
  ...dataAttributes
}: Props) => (
  <BlueprintMenu
    css={css`
      min-width: ${minWidth || "inherit"};
    `}
    ulRef={ulRef}
    {...dataAttributes}
  >
    {children}
  </BlueprintMenu>
);

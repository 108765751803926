import { useMadSDKList, useMadSDKSingular } from "../../../hooks/madSDK";
/**
 * Hook that returns a single IAB category
 * @param id the category ID
 * @param options
 * @returns {SingularData<IABCategory>}
 */
export const useIABCategory = (id, options) => useMadSDKSingular("categories", id, options);
/**
 * Hook that returns an array of IAB categories
 * @param options
 * @returns {ListData<IABCategory>}
 */
export const useIABCategories = (options) => useMadSDKList("categories", options);

import { SVG } from "./containers";
import { Circle } from "./shapes";
import { Icon } from "./types";
import { parseColor } from "./utils";

export const Cross = ({ color, circled, id, aria }: Icon) => {
  const { content, circle } = parseColor(color);

  return (
    <SVG viewBox="0 0 20 20" id={id} aria={aria}>
      <Circle color={circle} visible={circled}>
        <path
          d="M 13 13 L 7 7 M 13 7 L 7 13"
          stroke={content}
          strokeWidth="1"
        />
      </Circle>
    </SVG>
  );
};

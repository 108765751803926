import axios from "axios";
import { UnauthenticatedError } from "../../errors";
import { Handler } from "../handlers";
import { productToServiceProduct, serviceProductToProduct } from "../../models/product";
import { parseIdFilter } from "../handlers/filter";
import { ObjType, ServiceStatus } from "../../types";
import { ProductDeletionFailed, ProductsFetchFailed, ProductIdCreationFailed, ProductSaveFailed } from "./errors";
import FreewheelProducts from "./freewheel";
import { toQueryParams } from "./utils";
import { validators } from "./validators";
import { InventoryPackages } from "./inventoryPackages";
/**
 * Handles products collections find and management.
 */
class Products extends Handler {
    constructor(sdk) {
        super(sdk, "products", { atomize: true });
        this.validators = validators(sdk);
        this.freewheelProducts = new FreewheelProducts(sdk);
        this.inventoryPackages = new InventoryPackages(sdk);
    }
    async findItems(filters, sort = { field: "name", direction: "asc" }) {
        const ids = parseIdFilter(filters);
        const promise = ids.size === 1
            ? this.getProduct(ids.values().next().value).then((product) => [product])
            : this.getProducts({ base: filters, ids }, sort);
        return promise.catch((error) => {
            throw new ProductsFetchFailed(error);
        });
    }
    /**
     * @param filters: filters to narrow down the product set.
     * @param filters.base: object-style filters on any valid product filter field.
     * @param filters.ids: optional. Inclusion filter on ID (e.g., only products with IDs in this set are returned).
     * @return: the set of products adhering to the filters provided.
     */
    async getProducts(filters, sort) {
        const url = `${this.sdk.urls.baseAPIUrl}/products${toQueryParams(filters.base, sort)}`;
        const products = await this.cache.promise(url, () => axios
            .get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => res.data.map((product) => this.toProduct(product))));
        return !filters.ids || filters.ids.size === 0
            ? products
            : products.filter((product) => filters.ids?.has(product.id));
    }
    /**
     * @param id: the ID of the product to get.
     * @return: the product.
     */
    getProduct(id) {
        return this.cache.promise(id, () => axios
            .get(`${this.sdk.urls.baseAPIUrl}/product/${id}`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => {
            return this.toProduct(res.data);
        }));
    }
    /**
     * @param product: the product to save.
     * @return: the saved representation.
     */
    async saveItem(product, options) {
        const { skipValidation } = options || {};
        if (!skipValidation) {
            const errors = await this.validate(product);
            if (errors.size) {
                throw new Error(errors.values().next().value);
            }
        }
        return this.normalize(product).then((normalized) => axios
            .post(`${this.sdk.urls.baseAPIUrl}/product`, this.fromProduct(normalized), {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => {
            // currently when saving an item the back end doesn't return
            // a correct updated date. We will override it for now there is
            // work to update the service.
            return {
                ...this.toProduct(res.data),
                updated: new Date()
            };
        })
            .catch((error) => {
            throw new ProductSaveFailed(error);
        }));
    }
    /**
     * @param product: a product, or a base product.
     * @return: the input normalized to the `Product` interface.
     */
    async normalize(product) {
        const user = this.sdk.getCurrentUser();
        if (!user) {
            throw new UnauthenticatedError();
        }
        const id = product.id || (await this.sdk.cryptography.mintKey(ObjType.PROD));
        if (!id) {
            throw new ProductIdCreationFailed();
        }
        const parent = product.parent || user.primaryOrganizationId;
        const status = product.status || ServiceStatus.READY;
        return {
            ...product,
            id,
            status,
            parent
        };
    }
    /**
     * Deletes the product with the provided ID.
     * @param id: the id of the product to delete.
     * @return: the deleted product's latest rep.
     */
    async deleteItem(id) {
        await axios
            .delete(`${this.sdk.urls.baseAPIUrl}/product/${id}`)
            .catch((error) => {
            throw new ProductDeletionFailed(error);
        });
        // the API does not return anything on product deletions, so let's fetch its latest rep
        return this.getProduct(id).catch((error) => {
            throw new ProductsFetchFailed(error);
        });
    }
    toProduct(product) {
        return serviceProductToProduct(product);
    }
    fromProduct(product) {
        return productToServiceProduct(product);
    }
}
export default Products;

var ServiceInventoryNetwork;
(function (ServiceInventoryNetwork) {
    ServiceInventoryNetwork["MADHIVE_AND_SUPERTAG"] = "madhive";
    ServiceInventoryNetwork["FREEWHEEL"] = "freewheel";
})(ServiceInventoryNetwork || (ServiceInventoryNetwork = {}));
export var NetworkInventoryId;
(function (NetworkInventoryId) {
    NetworkInventoryId["MADHIVE"] = "MadHive";
    NetworkInventoryId["FREEWHEEL"] = "FreeWheel";
    NetworkInventoryId["SUPERTAG"] = "SuperTag";
    NetworkInventoryId["MADHIVE_AND_SUPERTAG"] = "MadHiveAndSuperTag";
})(NetworkInventoryId || (NetworkInventoryId = {}));
const SERVICE_INVENTORY_TO_CLIENT = {
    [ServiceInventoryNetwork.MADHIVE_AND_SUPERTAG]: NetworkInventoryId.MADHIVE_AND_SUPERTAG,
    [ServiceInventoryNetwork.FREEWHEEL]: NetworkInventoryId.FREEWHEEL
};
export const toPioProductPackage = (servicePackage) => {
    const { package_id, package_name, product_ids, product_names, inventory_source, percent_allocations } = servicePackage;
    return {
        id: package_id,
        name: package_name,
        products: product_ids.map((productId, index) => ({
            id: productId,
            name: product_names[index],
            inventorySource: SERVICE_INVENTORY_TO_CLIENT[inventory_source[index]],
            percentAllocation: percent_allocations[index] ? percent_allocations[index] / 100 : 0
        }))
    };
};

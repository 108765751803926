export enum PacingStatus {
  WAY_OVER = "way-over",
  OVER = "over",
  WAITING = "waiting",
  ON = "on",
  UNDER = "under",
  WAY_UNDER = "way-under",
  FUTURE = "future",
  ACTIVE_WAITING = "active-waiting"
}

export enum PacingType {
  ACTIVE = "active",
  COMPLETED = "completed",
  WAITING = "waiting",
  ACTIVE_WAITING = "active-waiting"
}

export const PACING_SELECT_OPTION_ID_TO_LABEL = {
  ALL: "All Paces",
  ALL_UNDER_PACE: "All Under Pace",
  ALL_OVER_PACE: "All Over Pace",
  [PacingStatus.WAITING]: "Scheduled",
  [PacingStatus.WAY_OVER]: "Way Over Pace",
  [PacingStatus.WAY_UNDER]: "Way Under Pace",
  [PacingStatus.ON]: "On Target",
  [PacingStatus.UNDER]: "Under Pace",
  [PacingStatus.OVER]: "Over Pace",
  [PacingStatus.ACTIVE_WAITING]: "Not Yet Delivering"
};

export const PACING_TO_SORT_INDEX: Readonly<Record<PacingStatus, number>> = {
  [PacingStatus.WAITING]: 0,
  [PacingStatus.ACTIVE_WAITING]: 1,
  [PacingStatus.WAY_UNDER]: 2,
  [PacingStatus.UNDER]: 3,
  [PacingStatus.ON]: 4,
  [PacingStatus.OVER]: 5,
  [PacingStatus.WAY_OVER]: 6,
  [PacingStatus.FUTURE]: 7
};

export const PACING_STATUS_TO_DEFAULT_COLOR: Readonly<
  Record<PacingStatus, string>
> = {
  [PacingStatus.FUTURE]: "#757575",
  [PacingStatus.ON]: "#66bb6a",
  [PacingStatus.OVER]: "rgb(49, 162, 226)",
  [PacingStatus.UNDER]: "rgb(242, 153, 74)",
  [PacingStatus.WAITING]: "#757575",
  [PacingStatus.ACTIVE_WAITING]: "#757575",
  [PacingStatus.WAY_OVER]: "#BB6BD9",
  [PacingStatus.WAY_UNDER]: "rgb(229, 85, 85)"
};

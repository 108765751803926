import { toClientScenarioResults } from "../../index";
import { serviceToClientScenario } from "../../types";
import { ObservableHandler } from "../handlers";
import { validators } from "./validators";
import { parseIdFilter } from "../handlers/filter";
import { Observable } from "rxjs";
import axios from "axios";
import { ScenarioFetchFailed } from "./errors";
const SCENARIO_GCS_BUCKET = "x-screen-planner";
/**
 * Note: This is a transitional module that should be renamed after migrating from the old Scenarios module.
 */
class Scenarios extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "scenarios", { atomize: true });
        this.validators = validators;
    }
    findItems(filters) {
        return new Observable((subscriber) => {
            const idFilter = parseIdFilter(filters);
            // Returning empty array for multiple ids because there isn't an endpoint to get multiple
            // scenarios yet.
            const promise = idFilter.size === 1
                ? this.getScenario(idFilter.values().next().value)
                : Promise.resolve([]);
            promise.then((scenarios) => subscriber.next(scenarios));
        });
    }
    async getScenario(id) {
        // Note: The "_nonlegacy" salt is added to differentiate the items cached by this module
        // and the legacy version. The two do not use the same data, so they must be separate.
        // Also, the atomize flag is turned off to work around a bug in the cache categorization
        // that is assuming iterables should be atomized.
        const scenarios = await this.cache.promise(id + "_nonlegacy", () => axios
            .get(`${this.sdk.urls.basePlannerServiceUrl}/scenario/${id}`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => [serviceToClientScenario(res.data, {})]), { atomize: false });
        if (!scenarios[0]) {
            throw new ScenarioFetchFailed();
        }
        scenarios[0].results = await this.sdk.madFire
            .getDownloadUrl(`forecast/${scenarios[0].forecastId}.json`, `gs://${this.sdk.madFire.projectId}-${SCENARIO_GCS_BUCKET}`)
            .then((downloadUrl) => axios
            .get(downloadUrl, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => res.data))
            .then((scenarioData) => toClientScenarioResults(scenarioData, scenarios[0]))
            .catch(() => {
            throw new ScenarioFetchFailed();
        });
        return scenarios;
    }
}
export default Scenarios;

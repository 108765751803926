export const ENVIRONMENT = {
    PROJECT_ID: import.meta.env.VITE_PROJECT_ID || "mad-hack",
    STORAGE_ID: import.meta.env.VITE_STORAGE_ID || "mad-data",
    MIN_FETCH_INTERVAL: Number(import.meta.env.VITE_MIN_FETCH_INTERVAL) || 240000,
    DEBUG: import.meta.env.VITE_DEBUG === "true",
    USE_REMOTE_CONFIG: import.meta.env.VITE_USE_REMOTE_CONFIG === "true",
    BASE_CI_URL: import.meta.env.VITE_BASE_CI_URL || "",
    TYPE: import.meta.env.VITE_NAME || "development",
    RELEASE_DATE: import.meta.env.VITE_RELEASE_DATE
        ? new Date(import.meta.env.VITE_RELEASE_DATE)
        : new Date(),
    RELEASE_VERSION: import.meta.env.VITE_RELEASE_VERSION || "mad-hooks-0.0.0",
    GITHUB_TOKEN: import.meta.env.VITE_GITHUB_TOKEN || ""
};

import { Observable } from "rxjs";
/**
 * An ObservableBuilder wraps an already-created observable,
 * allowing extenders to append extra methods on top of it.
 * @template {T}: the type of thing returned from the observable.
 */
export class ObservableBuilder extends Observable {
    /** @param observable: a function which returns the observable this builder utilizes. */
    constructor(observable) {
        super();
        this.observable = observable;
    }
    subscribe(next, error, complete) {
        const observable = this.observable();
        // Essentially, we're saying, hey, I'm really that observable.
        // Though these are deprecated, and will be removed in rxjs v8,
        // this is far, far more maintanable than trying to `implement`
        // Observable instead of `extending` it (the type definitions alone are 50+ lines).
        // When v8 is released and we upgrade to it, then we can deal with this.
        this.source = observable;
        this.operator = observable.operator;
        return observable.subscribe(next, error, complete);
    }
}

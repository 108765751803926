/* eslint-disable camelcase */
/**
 * PRODUCT_ID is the "current" version of a freewheel product.
 * SITE_GROUP_ID is the legacy version.
 */
export var FreewheelProductType;
(function (FreewheelProductType) {
    FreewheelProductType["PRODUCT_ID"] = "product-id";
    FreewheelProductType["SITE_GROUP_ID"] = "site-group-id";
})(FreewheelProductType || (FreewheelProductType = {}));
export var SupportedFilterField;
(function (SupportedFilterField) {
    SupportedFilterField["TYPE"] = "type";
})(SupportedFilterField || (SupportedFilterField = {}));
/**
 * @param product: a service freewheel product, of all valid kinds.
 * @return: the equivalent non-service version of the freewheel product.
 */
export const serviceFreewheelProductToFreewheelProduct = (product) => {
    if (isSiteGroupIdServiceFreeWheelProduct(product)) {
        return {
            id: product.site_group_id,
            name: product.site_group_name,
            type: FreewheelProductType.SITE_GROUP_ID
        };
    }
    return {
        id: product.product_id,
        name: product.product_name,
        type: FreewheelProductType.PRODUCT_ID
    };
};
/**
 * @param product: a serviced freewheel product that may or may not be legacy.
 * @return: true if the service product is a freewheel product dealing with the site group ID, else false.
 */
export const isSiteGroupIdServiceFreeWheelProduct = (product) => "site_group_id" in product;

import { KnownOrganizationIds, Organization } from "@madhive/mad-sdk";

export const GET_ORGANIZATION_PENDING = "GET_ORGANIZATION_PENDING";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";
export const GET_ORGANIZATION_FAILURE = "GET_ORGANIZATION_FAILURE";

export interface OrganizationsState {
  readonly byId: { [key in KnownOrganizationIds]?: Organization };
  readonly isLoading: boolean;
  readonly error: string | null;
}

interface GetOrganizationPendingAction {
  type: typeof GET_ORGANIZATION_PENDING;
}

interface GetOrganizationSuccessAction {
  type: typeof GET_ORGANIZATION_SUCCESS;
  payload: Organization;
}

interface GetOrganizationFailureAction {
  type: typeof GET_ORGANIZATION_FAILURE;
  meta: {
    error: string;
  };
}

export type OrganizationsActionTypes =
  | GetOrganizationPendingAction
  | GetOrganizationSuccessAction
  | GetOrganizationFailureAction;

import { IToastProps } from "@blueprintjs/core";
import {
  List as PublisherList,
  ListBase as PublisherListBase
} from "@madhive/mad-sdk";

export const GET_ALL_PUBLISHER_LISTS_PENDING =
  "GET_ALL_PUBLISHER_LISTS_PENDING";
export const GET_ALL_PUBLISHER_LISTS_FAILURE =
  "GET_ALL_PUBLISHER_LISTS_FAILURE";
export const GET_ALL_PUBLISHER_LISTS_SUCCESS =
  "GET_ALL_PUBLISHER_LISTS_SUCCESS";

export const ABORT_PUBLISHER_LISTS_REQUEST = "ABORT_PUBLISHER_LISTS_REQUEST";

export const SAVE_PUBLISHER_LIST_PENDING = "SAVE_PUBLISHER_LIST_PENDING";
export const SAVE_PUBLISHER_LIST_FAILURE = "SAVE_PUBLISHER_LIST_FAILURE";
export const SAVE_PUBLISHER_LIST_SUCCESS = "SAVE_PUBLISHER_LIST_SUCCESS";

export const GET_PUBLISHER_LIST_BYID_PENDING =
  "GET_PUBLISHER_LIST_BYID_PENDING";
export const GET_PUBLISHER_LIST_BYID_FAILURE =
  "GET_PUBLISHER_LIST_BYID_FAILURE";
export const GET_PUBLISHER_LIST_BYID_SUCCESS =
  "GET_PUBLISHER_LIST_BYID_SUCCESS";

export interface PublisherListsState {
  readonly byId: Record<string, PublisherList>;
  readonly archivedById: Record<string, PublisherList>;
  readonly isAllLoading: boolean;
  readonly receivedAllAt: number | null;
  readonly savingById: Record<string, PublisherList>;
  readonly getError: string | null;
  readonly saveError: string | null;
  readonly isGetByIdLoading: boolean;
}

interface GetPublisherListByIdPendingAction {
  type: typeof GET_PUBLISHER_LIST_BYID_PENDING;
}
interface GetPublisherListByIdFailureAction {
  type: typeof GET_PUBLISHER_LIST_BYID_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}
interface GetPublisherListByIdSuccessAction {
  type: typeof GET_PUBLISHER_LIST_BYID_SUCCESS;
  payload: PublisherList;
  meta: {
    timestamp: number;
  };
}

interface GetAllPublisherListsPendingAction {
  type: typeof GET_ALL_PUBLISHER_LISTS_PENDING;
}

interface GetAllPublisherListsFailureAction {
  type: typeof GET_ALL_PUBLISHER_LISTS_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface GetAllPublisherListsSuccessAction {
  type: typeof GET_ALL_PUBLISHER_LISTS_SUCCESS;
  payload: PublisherList[];
  meta: {
    timestamp: number;
  };
}

interface AbortPublisherListsRequestAction {
  type: typeof ABORT_PUBLISHER_LISTS_REQUEST;
}

interface SavePublisherListsPendingAction {
  type: typeof SAVE_PUBLISHER_LIST_PENDING;
  meta: {
    publisherList: PublisherList | PublisherListBase;
  };
}

interface SavePublisherListsFailureAction {
  type: typeof SAVE_PUBLISHER_LIST_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
    publisherListId?: string;
  };
}

interface SavePublisherListsSuccessAction {
  type: typeof SAVE_PUBLISHER_LIST_SUCCESS;
  payload: { publisherListId: string };
}

export type PublisherListsActionTypes =
  | GetAllPublisherListsPendingAction
  | GetAllPublisherListsFailureAction
  | GetAllPublisherListsSuccessAction
  | AbortPublisherListsRequestAction
  | SavePublisherListsPendingAction
  | SavePublisherListsFailureAction
  | SavePublisherListsSuccessAction
  | GetPublisherListByIdPendingAction
  | GetPublisherListByIdFailureAction
  | GetPublisherListByIdSuccessAction;

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const SearchEmpty: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-search-empty"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.11542 6.163C7.40832 5.87011 7.88319 5.87011 8.17608 6.163L10.1769 8.16383C10.4698 8.45673 10.4698 8.9316 10.1769 9.22449C9.88402 9.51739 9.40915 9.51739 9.11626 9.22449L7.11542 7.22366C6.82253 6.93077 6.82253 6.45589 7.11542 6.163Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.1769 6.163C10.4698 6.45589 10.4698 6.93077 10.1769 7.22366L8.17608 9.22449C7.88319 9.51739 7.40832 9.51739 7.11542 9.22449C6.82253 8.9316 6.82253 8.45673 7.11542 8.16383L9.11626 6.163C9.40915 5.87011 9.88402 5.87011 10.1769 6.163Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.6771 11.5689C11.9714 11.2773 12.4463 11.2795 12.7378 11.5738L16.5344 15.4057C16.826 15.7 16.8238 16.1749 16.5295 16.4664C16.2353 16.7579 15.7604 16.7557 15.4689 16.4615L11.6722 12.6295C11.3807 12.3353 11.3829 11.8604 11.6771 11.5689Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.64617 2.79382C5.94002 2.79382 3.74625 4.98759 3.74625 7.69375C3.74625 10.3999 5.94002 12.5937 8.64617 12.5937C11.3523 12.5937 13.5461 10.3999 13.5461 7.69375C13.5461 4.98759 11.3523 2.79382 8.64617 2.79382ZM2.24625 7.69375C2.24625 4.15917 5.11159 1.29382 8.64617 1.29382C12.1807 1.29382 15.0461 4.15917 15.0461 7.69375C15.0461 11.2283 12.1807 14.0937 8.64617 14.0937C5.11159 14.0937 2.24625 11.2283 2.24625 7.69375Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1651 3.99664C12.1651 3.58242 12.5009 3.24664 12.9151 3.24664H17.0021C19.6264 3.24664 21.7537 5.37403 21.7537 7.9983V17.0021C21.7537 19.6263 19.6264 21.7537 17.0021 21.7537H7.99833C5.37406 21.7537 3.24666 19.6263 3.24666 17.0021V10.903C3.24666 10.4888 3.58245 10.153 3.99666 10.153C4.41088 10.153 4.74666 10.4888 4.74666 10.903V17.0021C4.74666 18.7979 6.20248 20.2537 7.99833 20.2537H17.0021C18.7979 20.2537 20.2537 18.7979 20.2537 17.0021V7.9983C20.2537 6.20246 18.7979 4.74664 17.0021 4.74664H12.9151C12.5009 4.74664 12.1651 4.41085 12.1651 3.99664Z"
        fill={fill}
      />
    </svg>
  );
};

export default SearchEmpty;

import { InvalidURL } from "../../../errors";
import { isVast, serviceToValidationDetails, isHtmlTag } from "../../../models/creatives";
import { addTrailingSlash, isValidUrlWithProtocol } from "../../../utils";
import axios from "axios";
/**
 * Method that takes a asset url and runs the service validation on that url
 * @param url url to a valid asset (will error if it isn't a supported type)
 * @param madhiveEncoderBaseUrl sdk.urls.madhiveEncoderBaseUrl
 * @returns ValidationDetails returned about that asset
 */
export const validateAsset = async (urlOrTag, madhiveEncoderBaseUrl) => {
    const url = addTrailingSlash(urlOrTag);
    const key = (!isHtmlTag(urlOrTag) || isValidUrlWithProtocol(url)) && !isVast(urlOrTag) ? "url" : "tag";
    if (key === "url" && !isValidUrlWithProtocol(url) && !isVast(urlOrTag)) {
        throw new InvalidURL();
    }
    const serviceValidationDetails = await axios
        .post(`${madhiveEncoderBaseUrl}/validate`, {
        [key]: urlOrTag
    }, {
        headers: {
            "Content-Type": "application/json"
        }
    })
        .then((res) => res.data.data);
    return serviceToValidationDetails(serviceValidationDetails);
};

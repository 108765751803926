import axios from "axios";
import * as FullStory from "@fullstory/browser";
import { useSelector } from "react-redux";
import { startCase } from "lodash";
import { css } from "@emotion/react";
import { Intent } from "@blueprintjs/core";
import { selectCurrentUser } from "appReducers/authReducer";
import { ToasterContext } from "lib/context";
import { downloadCSVReport } from "api/notifications";
import { firebaseProjectId, madSDK } from "lib/sdk";
import { FullStoryCustomEvent } from "lib/constants/fullStory";
import { BASE_APP_NAME } from "lib/constants/config";
import { Button, ButtonColor, ButtonVariant } from "madhive/components";
import {
  compareOrgRules,
  RULE_WHITE_LABEL_ORGS_IDS
} from "lib/constants/orgRules";
import { FC, useContext, useState } from "react";

interface ReportDownloadProps {
  notificationID: string;
  buttonText: string;
  filePath: string | undefined;
}

const classes = {
  actionBtn: css`
    margin-top: 10px;
    margin-right: 25px;
    cursor: pointer;
    font-size: var(--font-size-12);
    color: var(--primary-color);
    font-weight: bold;
    text-transform: uppercase;
  `
};

const ReportDownloadButton: FC<ReportDownloadProps> = ({
  notificationID,
  buttonText,
  filePath
}) => {
  const { addToast } = useContext(ToasterContext);
  const [isLoading, setIsLoading] = useState(false);

  const currentUser = useSelector(selectCurrentUser);
  if (!currentUser) {
    return null;
  }

  return (
    <div css={classes.actionBtn}>
      <Button
        color={ButtonColor.PRIMARY}
        variant={ButtonVariant.TEXT}
        disabled={isLoading}
        loading={isLoading}
        onClick={async () => {
          if (!filePath) {
            return;
          }

          try {
            const cancellationSource = axios.CancelToken.source();
            setIsLoading(true);

            /**
             * CSV reports are located within the default `mad-data` cloud storage bucket. The default
             * storage bucket is scoped by firebase project ID as it serves multiple projects.
             *
             * The CSV file path is stored in the notification doc under the `meta.path` field. The file path also includes the file extension.
             *
             * Example file path structure: `{{DEFAULT_MAD_DATA_STORAGE_BUCKET}}/{{firebaseProjectId}}/{{userId}}/{{reportId}}.csv`
             */

            const downloadURL = await madSDK.madFire.getDownloadUrlMadData(
              `gs://mad-data.appspot.com/${firebaseProjectId}/${filePath}`
            );

            addToast(
              {
                intent: Intent.PRIMARY,
                message:
                  "Your CSV download has started. We'll notify you when the download is complete."
              },
              notificationID
            );

            const now = new Date().toISOString();
            const fileName = compareOrgRules(
              currentUser.primaryOrganizationId,
              RULE_WHITE_LABEL_ORGS_IDS
            )
              ? `${notificationID}-${now}`
              : `${BASE_APP_NAME}-${notificationID}-${now}`;

            await downloadCSVReport(downloadURL, cancellationSource, fileName);
            FullStory.event(
              FullStoryCustomEvent.DASHBOARD_CSV_REPORT_SUCCESS,
              {}
            );

            addToast(
              {
                intent: Intent.SUCCESS,
                message: "Your report has successfully finished downloading."
              },
              notificationID
            );
          } catch (e) {
            console.error(e.message);

            FullStory.event(
              FullStoryCustomEvent.DASHBOARD_CSV_REPORT_FAILURE,
              {}
            );

            addToast(
              {
                intent: Intent.WARNING,
                message:
                  "There was an error downloading this report. We've notified our engineers of this issue."
              },
              notificationID
            );
          }

          setIsLoading(false);
        }}
      >
        {startCase(buttonText)}
      </Button>
    </div>
  );
};

export default ReportDownloadButton;

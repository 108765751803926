import axios from "axios";
import { Observable } from "rxjs";
import { ObservableHandler } from "../../../modules/handlers";
import { serviceCampaignToCampaign } from "../../../models/campaigns";
import { NotImplementedError } from "../../../errors";
export class CampaignsAudits extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "campaigns-audits");
    }
    findItems(filters) {
        const idFilter = filters.where?.find((filter) => filter.field === "id");
        if (!idFilter) {
            throw Error("Audits need to be filtered by id.");
        }
        return new Observable((subscriber) => {
            axios
                .get(`${this.sdk.urls.baseAPIUrl}/campaign/${idFilter.value}/audit`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((res) => {
                const convertCampaigns = [];
                res.data.data.forEach((serviceCampaign) => {
                    convertCampaigns.push(this.toCampaign(serviceCampaign));
                });
                Promise.all(convertCampaigns).then((campaigns) => subscriber.next(campaigns));
            })
                .catch((error) => {
                console.error("Error", error);
            });
        });
    }
    /**
     * Make isn't implemented for users.
     */
    make() {
        throw new NotImplementedError("make");
    }
    saveItem() {
        throw new NotImplementedError("save");
    }
    deleteItem() {
        throw new NotImplementedError("delete");
    }
    async toCampaign(serviceCampaign) {
        // Although the api documentation defines the `audit` endpoint's response as
        // using the same model as campaigns, it is missing `status_details`.
        // This will be addressed in https://madhive.atlassian.net/browse/ADOPS-1808
        if (!serviceCampaign.status_details) {
            // Defaulting all `canBe` states to false so they aren't used from the audit.
            const status_details = {
                summary: serviceCampaign.derived_status,
                code: serviceCampaign.status,
                canBe: {
                    archived: false,
                    unarchived: false,
                    live: false,
                    edited: false,
                    paused: false
                }
            };
            serviceCampaign.status_details = status_details;
            for (const descendantId in serviceCampaign.descendants) {
                serviceCampaign.descendants[descendantId].status_details = status_details;
            }
        }
        return serviceCampaignToCampaign(serviceCampaign, this.sdk.cryptography);
    }
}

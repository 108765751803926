import { getDetailedErrorMessage } from "../../utils/validation";
export class RecipeFetchFailed extends Error {
    /**
     * @param apiError: the error received from the API.
     * @param fallbackMessage: the message to use for the error, if the API error does not contain anything relevant.
     */
    constructor(apiError, fallbackMessage = "Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, RecipeFetchFailed);
        }
        this.name = "RecipeFetchFailed";
        this.message = `Failed to fetch recipe(s). ${getDetailedErrorMessage(apiError, fallbackMessage)}`;
    }
}
export class RecipeSaveFailed extends Error {
    /**
     * @param apiError: the error received from the API.
     * @param fallbackMessage: the message to use for the error, if the API error does not contain anything relevant.
     */
    constructor(apiError, fallbackMessage = "Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, RecipeSaveFailed);
        }
        this.name = "RecipeSaveFailed";
        this.message = `Failed to save recipe(s). ${getDetailedErrorMessage(apiError, fallbackMessage)}`;
    }
}
export class RecipeMatchFailed extends Error {
    /**
     * @param apiError: the error received from the API.
     * @param fallbackMessage: the message to use for the error, if the API error does not contain anything relevant.
     */
    constructor(apiError, fallbackMessage = "Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, RecipeMatchFailed);
        }
        this.name = "RecipeMatchFailed";
        this.message = `Failed to check if recipe matches any other. ${getDetailedErrorMessage(apiError, fallbackMessage)}`;
    }
}

import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";

const Minus: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-circled-minus"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M8 11.25C7.58579 11.25 7.25 11.5858 7.25 12C7.25 12.4142 7.58579 12.75 8 12.75H16C16.4142 12.75 16.75 12.4142 16.75 12C16.75 11.5858 16.4142 11.25 16 11.25H8Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25C6.61479 2.25 2.25 6.61479 2.25 12C2.25 17.3852 6.61479 21.75 12 21.75C17.3852 21.75 21.75 17.3852 21.75 12C21.75 6.61479 17.3852 2.25 12 2.25ZM3.75 12C3.75 7.44321 7.44321 3.75 12 3.75C16.5568 3.75 20.25 7.44321 20.25 12C20.25 16.5568 16.5568 20.25 12 20.25C7.44321 20.25 3.75 16.5568 3.75 12Z"
      />
    </svg>
  );
};

export default Minus;

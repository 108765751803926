import { css } from "@emotion/react";

export const styles = {
  wrapper: css`
    --icon-fill-color: var(--color-splash-primary);
    color: var(--color-primary);
    background-color: var(--chip-color-background, #e5eaff);
    font-size: var(--spacing-12);
    line-height: var(--spacing-16);
    font-weight: var(--font-weight-medium);
    padding: var(--spacing-4) var(--spacing-8);
    border-radius: var(--spacing-4);
    max-height: 24px;
    gap: 3px;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-content: center;
    border: 1px solid transparent;
    &:focus {
      outline: none;
    }
    button svg {
      --icon-fill-color: var(--color-secondary);
    }
  `,
  isInteractive: css`
    cursor: pointer;
    &:focus {
      outline: none;
      border-color: var(--color-splash-primary);
    }
  `,
  isDisabled: css`
    --icon-fill-color: var(--color-secondary);
    background-color: var(--chip-disabled-color-background, #e7e9ef);
    border-color: transparent;
    color: var(--color-secondary);
  `,
  removeButton: css`
    background: none;
    border: none;
    padding: 0;
    outline: none;
    cursor: pointer;
    line-height: 0;
  `,
  label: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
  intent: {
    error: css`
      --chip-color-background: var(--color-background-danger);
    `
  }
};

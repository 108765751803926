/* eslint-disable camelcase */
import { PublisherListInvalidServiceDefinition } from "../../errors";
import { publisherToServicePublisher, servicePublisherToPublisher } from "../../models/publisher/publisher";
/**
 * @param publisherList: a publisher list obtained from the frontend/adhering to List/ListBase.
 * @return: an equivalent object formatted to the ServiceList/ServiceListBase interface.
 */
export const listToServiceList = (publisherList) => {
    return {
        id: publisherList.id,
        name: publisherList.name.trim(),
        cap: Math.trunc(publisherList.cap || 0),
        last_update: publisherList.updated.toISOString(),
        publishers: publisherList.publishers.map((publisher) => publisherToServicePublisher(publisher)),
        status: publisherList.status,
        updated_by: publisherList.updatedBy,
        version: publisherList.version,
        media_type: publisherList.mediaType,
        ...(publisherList.productId ? { product_id: publisherList.productId } : {})
    };
};
/**
 * @param publisherList: a publisher list obtained from the backend/adhering to ServiceList.
 * @return: an equivalent object formatted to the List interface.
 * @throws: PublisherListInvalidServiceDefinition if the provided publisher list is missing updated_by, version, or last_update definitions.
 */
export const serviceListToList = (publisherList) => {
    // these fields are "optional", because we don't send them to the API when we're sending requests (b/c it ignores them)
    // however, they are *always* provided to us when we're GET'ing info
    // so, to avoid having weird defaults, we throw an error here if it is (for some reason) missing the data we think it should have on GET
    if (publisherList.updated_by === undefined ||
        publisherList.version === undefined ||
        publisherList.last_update === undefined) {
        throw new PublisherListInvalidServiceDefinition();
    }
    return {
        id: publisherList.id,
        name: publisherList.name,
        cap: publisherList.cap,
        updated: new Date(publisherList.last_update),
        publishers: publisherList.publishers.map((publisher) => servicePublisherToPublisher(publisher)),
        status: publisherList.status,
        updatedBy: publisherList.updated_by,
        version: publisherList.version,
        mediaType: publisherList.media_type,
        ...(publisherList.product_id ? { productId: publisherList.product_id } : {})
    };
};

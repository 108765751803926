import { CampaignsFetchFailed, FiltersRequiredError, NotImplementedError, formatDate } from "../../index";
import { Handler } from "../handlers";
import axios from "axios";
class Campaigns extends Handler {
    constructor(sdk) {
        super(sdk, "reporting-advertisers", { atomize: true });
    }
    async findItems(filters) {
        const ids = filters.where?.find((filter) => filter.field === "advertiserIDs")?.value;
        const startDate = filters.where?.find((filter) => filter.field === "startDate")?.value;
        const endDate = filters.where?.find((filter) => filter.field === "endDate")?.value;
        if (!ids || !startDate || !endDate) {
            throw new FiltersRequiredError();
        }
        const requestBody = {
            advertiserIDs: ids,
            startDate: formatDate(startDate),
            endDate: formatDate(endDate)
        };
        const cacheKey = `${this.campaignsUrl}/${requestBody.advertiserIDs.join("/")}/${requestBody.startDate}/${requestBody.endDate}`;
        return this.cache.promise(cacheKey, () => axios
            .post(this.campaignsUrl, requestBody, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data }) => data)
            .catch(() => {
            throw new CampaignsFetchFailed();
        }));
    }
    get campaignsUrl() {
        return `${this.sdk.urls.madhiveReportingBaseUrl}/campaigns`;
    }
    /**
     * saveItem isn't implemented for reporting campaigns.
     */
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    saveItem(campaign) {
        throw new NotImplementedError("save");
    }
    /**
     * deleteItem isn't implemented for reporting campaigns.
     */
    deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
}
export default Campaigns;

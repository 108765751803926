import classNames from "classnames";
import { useSelector } from "react-redux";
import { KnownOrganizationNames } from "@madhive/mad-sdk";
import { selectCurrentOrganization } from "appReducers/organizationsReducer";
import {
  compareOrgRules,
  RULE_WHITE_LABEL_ORGS_IDS
} from "lib/constants/orgRules";
import { selectCurrentUser } from "appReducers/authReducer";
import { FC } from "react";
import "./Footer.scss";
import FooterWithDisclaimer, {
  Props as FooterWithDisclaimerProps
} from "./FooterWithDisclaimer";
import SimpleFooter from "./SimpleFooter";

export const COPYRIGHT_TEXT = `Copyright © ${new Date().getFullYear()} MadHive`;

export const Footer: FC = () => {
  const currentOrganization = useSelector(selectCurrentOrganization);
  const user = useSelector(selectCurrentUser);

  if (!currentOrganization || !user) {
    return null;
  }

  if (!user.settings.footer) {
    return null;
  }

  if (currentOrganization.name === KnownOrganizationNames.PREMION) {
    return <FooterWithDisclaimer />;
  }

  // disable footer for the profiles listed on RULE_WHITE_LABEL_ORGS_IDS
  if (compareOrgRules(currentOrganization.id, RULE_WHITE_LABEL_ORGS_IDS)) {
    return null;
  }

  return <SimpleFooter />;
};

interface DisclaimerFooterProps {
  disclaimer: string;
}

export const DisclaimerFooter: FC<DisclaimerFooterProps> = ({
  disclaimer
}: FooterWithDisclaimerProps) => (
  <footer className="SmithersFooter">
    <div className="SmithersFooter__Content" style={{ flexDirection: "row" }}>
      <p className={classNames("SmithersFooter__Disclaimer")}>{disclaimer}</p>
    </div>
  </footer>
);

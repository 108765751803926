import { Team } from "@madhive/mad-sdk";

export const GET_AVAILABLE_TEAMS_PENDING = "GET_AVAILABLE_TEAMS_PENDING";
export const GET_AVAILABLE_TEAMS_SUCCESS = "GET_AVAILABLE_TEAMS_SUCCESS";
export const GET_AVAILABLE_TEAMS_FAILURE = "GET_AVAILABLE_TEAMS_FAILURE";
export const ABORT_TEAMS_REQUEST = "ABORT_TEAMS_REQUEST";

export interface TeamsState {
  readonly byId: Record<string, Team>;
  readonly isLoading: boolean;
  readonly isSaving: boolean;
  readonly isLoaded: boolean;
  readonly updatingIds: string[];
  readonly error: string | null;
}

interface GetAvailableTeamsPendingAction {
  type: typeof GET_AVAILABLE_TEAMS_PENDING;
}

interface GetAvailableTeamsSuccessAction {
  type: typeof GET_AVAILABLE_TEAMS_SUCCESS;
  payload: Team[];
}

interface GetAvailableTeamsFailureAction {
  type: typeof GET_AVAILABLE_TEAMS_FAILURE;
  meta: {
    error: string;
  };
}

interface AbortTeamsRequestAction {
  type: typeof ABORT_TEAMS_REQUEST;
}

export type TeamActionTypes =
  | GetAvailableTeamsPendingAction
  | GetAvailableTeamsSuccessAction
  | GetAvailableTeamsFailureAction
  | AbortTeamsRequestAction;

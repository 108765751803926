import axios from "axios";
import { lastValueFrom, Observable } from "rxjs";
import { ObjType, Validation } from "../../index";
import { NotImplementedError } from "../../errors";
import { FilterTypes, parseIdFilter } from "../handlers/filter";
import { ObservableHandler } from "../handlers/observable";
import { serviceToDeal, dealToService } from "../../models/deals";
import { DealSaveFailed, DealsFetchFailed } from "./errors";
import { filtersHasEmptyIdParam, toFilterObjects, toQueryParams } from "./utils";
import { isServicePage } from "../handlers/page";
import { validators } from "./validators";
import Templates from "./templates";
import Uploads from "./uploads";
class Deals extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "deals", { atomize: true });
        this.url = `${this.sdk.urls.baseAPIUrl}/inventory`;
        this.validators = validators(sdk);
        this.templates = new Templates(sdk);
        this.uploads = new Uploads(sdk);
    }
    findItems(filters, sort) {
        return new Observable((subscriber) => {
            const idFilter = parseIdFilter(filters);
            if (filtersHasEmptyIdParam(filters.where)) {
                subscriber.next([]);
                subscriber.complete();
            }
            else {
                const promise = idFilter.size === 1
                    ? this.getDeal(idFilter.values().next().value).then((deal) => [deal])
                    : this.getDeals(filters, sort);
                promise
                    .then((deals) => {
                    subscriber.next(deals);
                    subscriber.complete();
                })
                    .catch((error) => {
                    subscriber.error(error);
                });
            }
        });
    }
    /**
     * @param id: the id of a deal to get.
     * @return: a promise resolving to that deal.
     */
    async getDeal(id) {
        const params = `?&ids=["${id}"]`;
        return this.cache.promise(`${this.url}${params}`, () => axios
            .get(`${this.url}${params}`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => res?.data?.map((deal) => this.toDeal(deal))[0])
            .catch((error) => {
            throw new DealsFetchFailed(error);
        }));
    }
    /**
     * @param filters: filters to determine what Deals to get.
     * @return: the array of gotten Deals.
     */
    async getDeals(filters = {}, sort) {
        const params = toQueryParams(filters, sort);
        const pageSize = filters?.paging?.size || 10;
        return this.cache.promise(`${this.url}${params}`, () => axios
            .get(`${this.url}${params}`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => {
            const deals = res.data?.map(this.toDeal) || [];
            let results = deals;
            if (isServicePage(res)) {
                results = {
                    page: {
                        count: res.paging_info.count,
                        token: res.paging_info.token,
                        size: pageSize
                    },
                    data: deals
                };
            }
            return results;
        })
            .catch((error) => {
            throw new DealsFetchFailed(error);
        }));
    }
    saveItem(data, options) {
        const { skipValidation } = options || {};
        const promise = this.normalized(data).then((normalizedDeal) => "id" in normalizedDeal && !!normalizedDeal.id
            ? this.update(normalizedDeal, skipValidation)
            : this.create(normalizedDeal, skipValidation));
        return new Observable((observer) => {
            promise
                .then(({ data: res }) => {
                const deal = this.toDeal(res.data[0]);
                this.cache.reconcile("save", deal);
                observer.next(deal);
                observer.complete();
            })
                .catch((error) => {
                if (error instanceof Validation) {
                    observer.error(new Error(error.values().next().value));
                    return;
                }
                observer.error(new DealSaveFailed(error));
            });
        });
    }
    async create(dealBase, skipValidation = false) {
        const deal = await this.make(dealBase);
        if (!skipValidation) {
            const errors = await this.validate(deal);
            if (errors.size) {
                throw errors;
            }
        }
        return axios.post(this.url, { data: [this.toService(deal)] }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
    async update(deal, skipValidation = false) {
        if (!skipValidation) {
            const errors = await this.validate(deal);
            if (errors.size) {
                throw errors;
            }
        }
        return axios.patch(this.url, { data: [this.toService(deal)] }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
    /**
     * Normalizes a deal based on the current user.
     * @param deal - The deal to be normalized.
     * @returns A promise that resolves to the normalized deal.
     */
    async normalized(deal) {
        // if root user and billingOrg.id is provided, return the deal as is
        if (this.sdk.isRootUser() && deal.billingOrg?.id) {
            return deal;
        }
        const normalizedDeal = { ...deal };
        normalizedDeal.billingOrg = {
            id: await this.getBillingOrgId(deal.ssp?.code || "")
        };
        if (!this.sdk.isRootUser()) {
            normalizedDeal.parentOrg = {
                id: this.sdk.getCurrentUser()?.immediateParent
            };
        }
        return normalizedDeal;
    }
    /**
     * Uploads a file and returns an array of Deals.
     * @param file: the file to upload.
     * @return: a promise resolving to an array of Deals.
     */
    async upload(file) {
        const deals = (await this.uploads.save(file))?.map(this.toDeal);
        this.cache.reconcile("save", deals);
        return deals;
    }
    parseFilter(filters) {
        return toFilterObjects(filters || new Map());
    }
    /**
     * Used to make a default line item.
     * @param defaults these will be used to override the standard defaults
     * @return Promise<Deal> that will return the default line item object
     */
    make(defaults) {
        return new Promise((resolve, reject) => {
            this.sdk.cryptography
                .mintKey(ObjType.VAST)
                .then((id) => {
                const deal = {
                    id,
                    name: "",
                    price: {
                        cpm: undefined,
                        type: undefined,
                        bidRule: {
                            type: undefined,
                            cpm: undefined
                        }
                    },
                    contentOwner: undefined,
                    negotiatedVolume: undefined,
                    geoTargeting: undefined,
                    audienceTargeting: undefined,
                    isGroup: undefined,
                    status: undefined,
                    parentOrg: {
                        id: undefined,
                        name: undefined
                    },
                    billingOrg: {
                        id: undefined,
                        name: undefined
                    },
                    deal: {
                        id: undefined,
                        type: undefined
                    },
                    ssp: {
                        code: undefined,
                        name: undefined
                    },
                    inventorySet: {},
                    createdBy: undefined,
                    updatedBy: undefined,
                    startDate: undefined,
                    endDate: undefined,
                    createdAt: undefined,
                    updatedAt: undefined,
                    mediaTypes: [],
                    videoTypes: [],
                    notes: undefined,
                    ...defaults
                };
                resolve(deal);
            })
                .catch((error) => {
                reject(error);
            });
        });
    }
    async deleteItem() {
        return new Promise((_, reject) => {
            reject(new NotImplementedError("delete"));
        });
    }
    /**
     * Converts from a service Deal to a client Deal.
     * @param deal: the Deal to convert.
     * @return: the converted Deal.
     */
    toDeal(deal) {
        return serviceToDeal(deal);
    }
    /**
     * Converts from a client Deal to a service Deal.
     * @param deal: the Deal to convert.
     * @return: the converted Deal.
     */
    toService(deal) {
        return dealToService(deal);
    }
    /**
     * Retrieves the billing organization ID for a selected SSP code.
     * @param sspCode The SSP code.
     * @returns A promise that resolves to the billing organization ID, or undefined if not found.
     */
    async getBillingOrgId(sspCode) {
        return lastValueFrom(this.sdk.ssps.find_once({
            where: [
                {
                    field: "code",
                    type: FilterTypes.EQ,
                    value: sspCode
                }
            ]
        })).then((ssp) => ssp?.billing_org_id);
    }
}
export default Deals;

import { FC } from "react";
import { DatePickerFlyoutProps } from "./types";
import DatePicker from ".";
import Flyout from "../../../Flyout";

const DatePickerFlyout: FC<DatePickerFlyoutProps> = ({
  isOpen,
  setIsOpen,
  disabled,
  children,
  selecting,
  setSelecting,
  range,
  selected,
  setSelected,
  setEndToEndOfDay,
  time,
  min,
  max,
  transitionMs,
  presets,
  hasBackdrop = false
}) => {
  return disabled ? (
    children
  ) : (
    <Flyout
      target={children}
      isOpen={!!isOpen || !!selecting}
      onClose={() => setIsOpen?.(false)}
      onInteraction={nextOpenState => {
        setIsOpen?.(nextOpenState);
        if (!nextOpenState) {
          setSelecting?.(undefined);
        }
      }}
      keepOpen={range && !!selecting}
      hasBackdrop={hasBackdrop}
      transitionMs={transitionMs}
      offset="0,4"
      autoFocus={false}
      enforceFocus={false}
    >
      <DatePicker
        presets={presets}
        selected={selected}
        time={time}
        min={min}
        max={max}
        range={range}
        selecting={selecting}
        setSelected={setSelected}
        setSelecting={setSelecting}
        setEndToEndOfDay={setEndToEndOfDay}
      />
    </Flyout>
  );
};

export default DatePickerFlyout;

/* eslint-disable camelcase */
/**
 * @param publisher: a publisher obtained from the frontend/adhering to Publisher.
 * @return: an equivalent object formatted to the ServicePublisher interface.
 */
export const publisherToServicePublisher = (publisher) => {
    return {
        id: publisher.id,
        name: publisher.name.trim(),
        bundles: publisher.bundles?.map((el) => ({
            id: el.id
        })),
        cap: Math.trunc(publisher.cap || 0),
        last_update: publisher.updated ? publisher.updated.toISOString() : undefined
        // rtbImps is missing here since we should never be sending it to the backend
    };
};
/**
 * @param publisher: a publisher obtained from the backend/adhering to ServicePublisher.
 * @return: an equivalent object formatted to the Publisher interface.
 */
export const servicePublisherToPublisher = (publisher) => {
    return {
        id: publisher.id,
        name: publisher.name,
        bundles: publisher.bundles?.map((el) => ({
            id: el.id
        })),
        cap: publisher.cap,
        updated: publisher.last_update ? new Date(publisher.last_update) : undefined,
        rtbImps: publisher.rtbImps
    };
};

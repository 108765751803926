import { BooleanExpression } from "@madhive/mad-sdk";

export interface SelectableData {
  id: string;
  label: string;
  status: string;
  typeLabel: string;
  type: OptionCategory;
  composition?: BooleanExpression;
  dmpId: string;
  /**
   * @param price if entity is a recipe, this is the max price (max cpm). if entity is a segment, this is the only price (cpm).
   */
  price: number;
  /**
   * @param priceRange if entity is a recipe, this is a range of min to max price as a string
   * if entity is a segment, this is just the price as a string.
   */
  priceRange: string;
  archived?: boolean;
}

export enum OptionCategory {
  STANDARD = "standard",
  CUSTOM = "custom",
  PREFERRED = "preferred",
  EMPTY = "",
  THIRD_PARTY = "thirdParty"
}

export const LIVERAMP_DMP_ID = "q2mliCP5Es77JjSDyfEX1E3OMkUu";

export const MADHIVE_DMP_ID = "0LDY6rsTok3wcPtS29QFr97Mtpw7";

export const UNKNOWN_THIRD_PARTY = "unknownThirdParty";

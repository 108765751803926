// importing 'css' from "@emotion/react" allows interpolation of 'dynamic style';
// importing from 'css' from "@emotion/css" does not.
import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { colors, shadow, zIndex } from "./style";
import { StickyColumn } from "./types";

interface TableWrapperProps {
  stickyColumns: StickyColumn[];
  widths: number[];
  leftItemCount: number;
  isScrolledRight: boolean;
  containContentHeight?: boolean;
  maxHeight?: string;
}

const generateLeftOffset = (props: TableWrapperProps) =>
  props.leftItemCount * 1.5 + (props.leftItemCount ? 1.5 : 0);

const getMaxHeight = (
  props: Pick<TableWrapperProps, "maxHeight" | "containContentHeight">
) => {
  if (props.maxHeight) {
    return props.maxHeight;
  }

  if (props.containContentHeight) {
    return "";
  }

  return "auto";
};

const COUNT_COLUMN_DIVS_BEFORE_DATA = 2;

const dynamicColumnStyle = (props: TableWrapperProps) => css`
  > div:not(.header-row) > div > :not(.not-cell),
  > div.header-row > :not(.not-cell),
  div.subrow-wrapper > div > div:not(.not-cell) {
    ${props.widths.map((width, index) => {
      const stickyColumn = props.stickyColumns.find(
        c => c.columnIndex === index
      );
      const stickyStyles = stickyColumn
        ? `
          position: sticky;
          background: ${colors.white};
          z-index: ${zIndex.rightMenuContentRenderFn};
          ${props.isScrolledRight ? `box-shadow: ${shadow};` : ""}
          left: calc(
            ${stickyColumn.left || 0}px +
              var(--spacing-16) * ${generateLeftOffset(props)}
          );
          `
        : "";

      return `
        &:nth-of-type(${index + COUNT_COLUMN_DIVS_BEFORE_DATA}) {
          ${stickyStyles}
          flex: 0 0 ${
            props.widths.length > index ? width - (index === 0 ? 16 : 0) : 0
          }px;
        }`;
    })}
  }
`;

/* eslint-disable no-param-reassign */
const TableWrapper = styled.div<TableWrapperProps>`
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  max-height: ${props => getMaxHeight(props)};
  background-color: var(--white);
  > div > div.expanded-height-mono-row > :not(.not-cell) {
    &:nth-of-type(1) {
      width: ${props => props.widths.reduce((acc, el) => (acc += el), 0)}px;
      background-color: var(--gray-1);
    }
  }
  overflow: ${props => (props.containContentHeight ? "visible" : "auto")};
  ${dynamicColumnStyle};
`;
/* eslint-enable no-param-reassign */

export default TableWrapper;

import { NotImplementedError } from "../../../errors";
import { formatAndDedupOptions } from "../../../models/advancedExports";
import axios from "axios";
import { Handler } from "../../handlers";
import { ApiVersion } from "../types";
import { buildUrl } from "../utils";
import { parseInclusionFilter } from "../../handlers/filter";
class Options extends Handler {
    constructor(sdk) {
        super(sdk, "advanced-exports-options", { atomize: true });
    }
    async findItems(filters) {
        const optionFilter = parseInclusionFilter(filters, "optionType", ["string"]);
        const optionType = optionFilter.values().next().value;
        if (!optionType || !["metrics", "filters", "dimensions"].includes(optionType)) {
            throw new Error("No option type specified: must fetch metrics, filters, or dimensions");
        }
        const url = buildUrl(this.sdk, ApiVersion.V1, optionType);
        return this.cache.promise(url, () => axios
            .get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: options }) => formatAndDedupOptions(options))
            .catch(() => {
            throw new Error(`Failed to fetch ${optionType}`);
        }));
    }
    async saveItem() {
        throw new NotImplementedError("saveItem");
    }
    async deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
}
export default Options;

import axios from "axios";
import { Uploader } from "../handlers";
import { UploadSaveFailed } from "../handlers/errors";
class Uploads extends Uploader {
    constructor(sdk) {
        super(sdk);
        this.url = `${this.sdk.urls.baseAPIUrl}/inventory`;
    }
    async saveUpload(file) {
        const blob = new Blob([file], {
            type: "application/json; charset=utf-8;"
        });
        return axios
            .post(`${this.url}/bulk`, blob, {
            headers: {
                Accept: "application/json"
            }
        })
            .then(({ data: res }) => res.data)
            .catch((err) => {
            throw new UploadSaveFailed(err, "Failed to create deals(s) from template.");
        });
    }
}
export default Uploads;

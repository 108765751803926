import { InvalidPage } from "../../page/errors";
import { isPagingFilterValid } from "../utils";
import { FilterTypes } from "../types";
/**
 * @param filters: some set of filters.
 * @param field: the field to parse any applicable filters for.
 * @return: an array containing all the values that are either EQ or IN, related to that field. Empty, if none.
 */
export const parseInclusionFilter = (filters, field, types) => {
    if (!filters?.where?.length) {
        return new Set();
    }
    const relevant = filters.where.filter((filter) => filter.field === field);
    const parsed = new Set();
    for (const filter of relevant) {
        if (filter.type !== FilterTypes.EQ && filter.type !== FilterTypes.IN) {
            continue;
        }
        const values = (Array.isArray(filter.value) ? filter.value : [filter.value]);
        for (const value of values) {
            if (types.includes(typeof value)) {
                parsed.add(value);
            }
        }
    }
    return parsed;
};
/**
 * @param filters: some set of filters.
 * @return: a set containing all the ids to include. Empty, if there are none.
 */
export const parseIdFilter = (filters) => {
    return parseInclusionFilter(filters, "id", ["string", "number"]);
};
/**
 * @param filters: some set of filters.
 * @return: true if the filters contain an empty id param; ELSE false
 */
export const filtersHasEmptyIdParam = (whereFilters = []) => !!whereFilters.find(({ field, value }) => {
    if (field === "id") {
        if (typeof value === "undefined") {
            return true;
        }
        if ((typeof value === "string" || Array.isArray(value)) && value.length === 0) {
            return true;
        }
    }
    return false;
});
/**
 * @param filters: some set of filters.
 * @param of: optional. What this paging is for.
 * @return: the query param version of the paging section of the filters. Empty string, if there is none.
 * @throws: `InvalidPage` if the paging filter is provided, but is invalid.
 */
export const parsePagingQuery = (paging, of) => {
    let params = "";
    if (paging) {
        if (!isPagingFilterValid(paging)) {
            throw new InvalidPage(of);
        }
        const pageSize = paging.size || 10;
        params = `${params}&page_size=${pageSize}`;
        if (paging.token) {
            params = `${params}&page_token=${paging.token}&offset=${paging.offset}`;
        }
    }
    return params;
};
/**
 * @param fields: some set of filter fields.
 * @param desired: what fields are desired.
 * @return: the set of fields that are in both `desired` and `fields`. Empty if none.
 */
export const parseFilterFields = (fields, desired) => {
    const result = [];
    const set = new Set(desired);
    if (fields) {
        for (const field of fields) {
            if (set.has(field)) {
                result.push(field);
            }
            if (result.length === desired.length) {
                break;
            }
        }
    }
    return result;
};

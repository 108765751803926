/**
 * Usage:
 *
 * Sort A -> Z
 *
 * arrayOfStrings.sort((a, b) => sortAlphabetically(a, b))
 *
 * or:
 *
 * arrayOfStrings.sort(sortAlphabetically)
 *
 * Sort Z -> A
 *
 * arrayOfStrings.sort((a, b) => sortAlphabetically(b, a)) *Note we reversed the order of arguments.*
 */
export const sortAlphabetically = (a: string, b: string) => {
  // PK: Intl.Collator objects enable language sensitive string comparison
  // When comparing large numbers of strings (e.g sorting large arrays) it's much faster to use
  // this and utilize the .compare method (e.g took ~1.1s when sorting in dashboard with localeCompare vs. 550ms)
  const collator = new Intl.Collator("en", {
    sensitivity: "base",
    numeric: true
  });
  return collator.compare(a, b);
};

export const compareDates = (a: Date, b: Date): number =>
  a.getTime() - b.getTime();

/**
 * To sort by descending, reverse the order of your arguments when you pass them in.
 *
 * someArray.sort((a, b) => sortNullableValueAscending(a, b)) // Ascending
 * someArray.sort((a, b) => sortNullableValueAscending(b, a)) // Descending
 */
export const sortNullableValueAscending = <T>(
  a: T | undefined | null,
  b: T | undefined | null,
  sortFn: (a: T, b: T) => number
) => {
  if (!a && !b) {
    return 0;
  }

  if (!a) {
    return -1;
  }

  if (!b) {
    return 1;
  }
  return sortFn(a, b);
};

import {
  KnownApiKeys,
  KnownAppIds,
  KnownMeasurementIds,
  KnownOrganizationNames,
  KnownProjectIds,
  User
} from "@madhive/mad-sdk";
import { buildPublicUrl } from "lib/utils/files";
import { KnownHostName, OrganizationName } from "types";
import {
  __DEV__,
  __PROD__,
  DATE_OF_CURRENT_RELEASE_ENV
} from "lib/viteEnvConfig";
/* Comment to prevent ts document automation from removing an empty line here. (PR: #25142) */
export { __DEV__, __PROD__ };
export const DATE_OF_CURRENT_RELEASE = new Date(
  DATE_OF_CURRENT_RELEASE_ENV || new Date()
);
export enum BaseAppNames {
  MADHIVE = "MadHive",
  MADHIVE_MEDIA_GROUP = "MadHive Media Group",
  PREMION = "Premion",
  SCRIPPS = "Scripps Octane",
  BOBMILLS = "Bob Mills",
  RUNNINGMATE = "Running Mate Media",
  BEACHFRONT = "Beachfront",
  FUTURESMEDIA = "Futures Media",
  MEDIAMATH = "MediaMath",
  DIGILANT_ARCHIVED = "Digilant Archived",
  BIG_SANDY_PROMOTIONS = "BSP",
  ASSOCIATED_VOLUME_BUYERS = "AVB",
  BTC_MEDIA = "BTC Media",
  UAT_MEDIA = "UAT Media",
  FOX = "Fox",
  NEWSY = "Newsy",
  RESIDENT_HOME = "Resident Home",
  VOICE_MEDIA = "Voice Media",
  LEGAL_ACTION_MEDIA = "Legal Action Media",
  FOSTERS_LAB = "Fosters Lab",
  MODUS_DIRECT = "Modus Direct",
  TEAM_LEWIS = "Team Lewis",
  KING = "King",
  DX_AGENCY = "DX Agency",
  LINCOLN_DIGITAL_GROUP = "Lincoln Digital Group",
  NMPI = "NMPi",
  PATHFINDER_INTERACTIVE = "Pathfinder Interactive",
  CTV_MEDIA = "CTV Media",
  NEWS_PRESS_GAZETTE = "News Press Gazette",
  TALON = "Talon",
  IHEART = "iHeart",
  OMNICOM = "Omnicom",
  CBC = "Cunningham Broadcasting Company",
  GROUNDTRUTH = "GroundTruth",
  COX = "Cox",
  LOCALFACTOR = "LocalFactor",
  ALLENMEDIA = "LocalNow",
  GRIFFIN = "Griffin Digital Solutions",
  HEARST = "Hearst",
  CAPITOL_BROADCASTING = "Capitol Broadcasting",
  MCAFEE = "McAfee",
  JUICE_MEDIA = "Juice Media",
  GOOD_KARMA = "Good Karma",
  UNIVISION = "Univision",
  GYDIGITAL = "Leap4",
  MADHIVE_INTEGRATION_TESTING_ORG = "MadHive Integration Tests",
  KATZDIGITAL = "KATZ Digital",
  SPARKLIGHT = "Sparklight",
  HP = "HP",
  NOM = "NOM",
  BEN_HER = "Ben Her",
  THE_REAL_REAL = "The Real Real",
  BRANT_MEDIA = "Brant Media",
  GAMUT = "Gamut",
  EVERGREEN = "Evergreen",
  BAHAKEL = "Bahakel",
  ANALYTICS_OWL = "Analytics Owl",
  DLM_AGENCY = "DLM Agency",
  MEDICX = "MedicX",
  TOWNSQUARE = "Townsquare",
  DIGITAL_ANALYTICS_PRO = "Digital Analytics Pro",
  BLOCK_COMMUNICATIONS = "Block Communications",
  TURNER_SPORTS = "Turner Sports",
  HOMESLICE_GROUP = "HomeSlice Group",
  MONDAY = "Monday",
  WIN_DIGITAL = "Win Digital",
  CHARLTON_MARKETING = "Charlton Marketing",
  TALMONT = "Talmont",
  FIVE_STAR_MARKETING_ADVISORS = "5-Star Marketing Advisors",
  EVIDNT = "Evidnt",
  DISH_SLING = "Dish - Sling",
  T_MOBILE = "T-Mobile",
  AI_DIGITAL = "AI Digital",
  TRIP_ADVISOR = "Trip Advisor",
  LIFTOFF = "LiftOff",
  MiQ = "MiQ",
  OMG = "OMG",
  OTTERA = "OTTERA",
  VOGLIO = "Voglio Marketing",
  ARKADAS_GROUP = "Arkadas Group",
  SAKS_OFF_FIFTH = "Saks Off Fifth",
  BUCKEYE_BROADBAND = "Buckeye Broadband",
  LIFEBRANDS_D2C = "LifeBrands D2C",
  KILL_YOUR_COMPETITION = "Kill Your Competition",
  SONOBI = "Sonobi",
  BATEMAN_COLLECTIVE = "Bateman Collective",
  FEDERATED_DIGITAL_SOLUTIONS = "Federated Digital Solutions",
  KR8_VENTURES = "Kr8 Ventures",
  ESSENTIALISM_ADVERTISING = "Essentialism Advertising",
  RED_VENTURES = "Red Ventures",
  HUDDLED_MASSES = "Huddled Masses",
  AUTOMATED_TESTING = "Automated Testing",
  GREEN_AND_WOOD_MEDIA = "Green & Wood Media",
  TASTEMADE = "Tastemade",
  DIGILANT = "Digilant",
  CHAMPIONSHIP_ADVERTISING = "Championship Advertising",
  CTV_BUYER = "CTV Buyer",
  LEV_LANE = "LevLane",
  THE_DIGITAL_RUCKUS = "The Digital Ruckus",
  BRAVE = "Brave",
  VIDEOHEROES = "VideoHeroes",
  ENTHUSIAST_GAMING = "Enthusiast Gaming",
  CBS = "CBS",
  NIMBUS99 = "Nimbus99",
  CONTINUUM_MEDIA = "Continuum Media",
  NEXSTAR = "Nexstar",
  BLAM_RETAIL = "Blam Retail",
  VICA_AI = "Vica AI",
  NIGHTLIFE_TELEVISION = "Nightlife Television",
  JRR_ADVERTISING = "JRR Advertising",
  ESTRELLA_MEDIA = "Estrella Media",
  PERFORMCB = "Perform[cb]",
  NEW_ROAD_ADVERTISING = "New Road Advertising",
  QUICKTURN_MEDIA = "Quickturn Media",
  CONNECTADTV = "ConnectADtv",
  LILLY_BROADCASTING = "Lilly Broadcasting"
}
export enum KnownHostNames {
  PROD_MADHIVE_DEFAULT = "app.madhive.com",
  PROD_SCRIPPS_LEGACY = "scripps.madhive.com",
  PROD_SCRIPPS_OCTANE = "portal.scrippsoctane.com",
  PROD_SCRIPPS_OCTANE_BETA = "octanebeta.madhive.com",
  PROD_PREMION_LEGACY = "premion.madhive.com",
  PROD_PREMION_CONSOLE = "premion-console.madhive.com",
  PROD_BOBMILLS = "bobmills.madhive.com",
  PROD_RUNNINGMATE_LEGACY = "runningmate.madhive.com",
  PROD_RUNNINGMATE_PLATFORM = "platform.runningmate.media",
  PROD_BEACHFRONT = "beachfront.madhive.com",
  PROD_FUTURESMEDIA = "chiefmedia.madhive.com",
  PROD_MEDIAMATH = "mediamath.madhive.com",
  PROD_MEDIAMATH_TV_PLATFORM = "tv-platform.mediamath.com",
  PROD_DIGILANT_ARCHIVED = "digilant-archived.madhive.com",
  PROD_BIG_SANDY_PROMOTIONS_DEFAULT = "bsp.madhive.com",
  PROD_ASSOCIATED_VOLUME_BUYERS_DEFAULT = "avb.madhive.com",
  PROD_BIG_SANDY_PROMOTIONS_OCTILLION = "bsp.octillionmedia.com",
  PROD_ASSOCIATED_VOLUME_BUYERS_OCTILLION = "avb.octillionmedia.com",
  PROD_BTC_MEDIA = "btcmedia.madhive.com",
  PROD_FOX = "flx.madhive.com",
  PROD_MADHIVE_MEDIA_GROUP = "mmg.madhive.com",
  PROD_NEWSY = "newsy.madhive.com",
  PROD_NEWSY_WITH_PORTAL_PREFIX = "portal.newsy.com",
  STAGING_DEFAULT = "staging.madhive.com",
  STAGING_PREMION = "premion-staging.madhive.com",
  STAGING_SCRIPPS = "scripps-staging.madhive.com",
  INTERNAL_QA = "qa.madhive.com",
  INTERNAL_HACK = "hack.madhive.com",
  DEMO_DEFAULT = "demo.madhive.com",
  DEMO_LEGACY = "demo.mdhv.io",
  PROD_RESIDENT_HOME = "residenthome.madhive.com",
  PROD_VOICE_MEDIA = "voicemedia.madhive.com",
  PROD_LEGAL_ACTION_MEDIA = "legalactionmedia.madhive.com",
  PROD_MODUS_DIRECT = "modusdirect.madhive.com",
  PROD_TEAM_LEWIS = "teamlewis.madhive.com",
  PROD_KING = "king.madhive.com",
  PROD_DX_AGENCY = "dxagency.madhive.com",
  PROD_LINCOLN_DIGITAL_GROUP = "lincolndigitalgroup.madhive.com",
  PROD_NMPI = "nmpi.madhive.com",
  PROD_PATHFINDER_INTERACTIVE = "pathfinder.madhive.com",
  PROD_CTV_MEDIA = "ctvmedia.madhive.com",
  PROD_CTV_MEDIA_DASHBOARD = "dashboard.ctvmedia.com",
  TESTING_MADHIVE_DEFAULT = "madhive-testing.firebaseapp.com",
  PROD_TALON = "talonooh.madhive.com",
  PROD_IHEART = "iheart.madhive.com",
  PROD_OMNICOM = "omnicom.madhive.com",
  PROD_CBC = "cbc.madhive.com",
  PROD_GROUNDTRUTH = "groundtruth.madhive.com",
  PROD_COX = "cmi.madhive.com",
  PROD_LOCALFACTOR = "localfactor.madhive.com",
  PROD_ALLENMEDIA = "localnow.madhive.com",
  PROD_GRIFFIN = "griffindigitalsolutions.madhive.com",
  PROD_HEARST = "hearst.madhive.com",
  PROD_CAPITOL_BROADCASTING = "cbcanott.com",
  PROD_MCAFEE = "mcafee.madhive.com",
  PROD_JUICE_MEDIA = "juicemedia.madhive.com",
  PROD_GOOD_KARMA = "goodkarma.madhive.com",
  PROD_UNIVISION = "univision.madhive.com",
  PROD_UNIVISION_ANALYTICS = "analytics.televisaunivision.com",
  PROD_GYDIGITAL = "gydigital.madhive.com",
  PROD_KATZDIGITAL = "katzdigital.madhive.com",
  PROD_SPARKLIGHT = "sparklight.madhive.com",
  PROD_HP = "hp.madhive.com",
  PROD_NOM = "nom.madhive.com",
  PROD_BEN_HER = "benher.madhive.com",
  PROD_THE_REAL_REAL = "therealreal.madhive.com",
  PROD_BRANT_MEDIA = "brantmedia.madhive.com",
  PROD_GAMUT = "gamut.madhive.com",
  PROD_EVERGREEN = "evergreen.madhive.com",
  PROD_BAHAKEL = "bahakel.madhive.com",
  PROD_BAHAKEL_DIGITAL_MEDIA = "dashboard.bahakeldigitalmedia.com",
  PROD_ANALYTICS_OWL = "analyticsowl.madhive.com",
  PROD_DLM_AGENCY = "dlmagency.madhive.com",
  PROD_MEDICX = "medicx.madhive.com",
  PROD_TOWNSQUARE = "townsquare.madhive.com",
  PROD_DIGITAL_ANALYTICS_PRO = "digitalanalyticspro.madhive.com",
  PROD_BLOCK_COMMUNICATIONS = "blockcommunications.madhive.com",
  PROD_TURNER_SPORTS = "turnersports.madhive.com",
  PROD_HOMESLICE_GROUP = "homeslicegroup.madhive.com",
  PROD_MONDAY = "monday.madhive.com",
  PROD_WIN_DIGITAL = "windigital.madhive.com",
  PROD_CHARLTON_MARKETING = "charltonmarketing.madhive.com",
  PROD_TALMONT = "talmont.madhive.com",
  PROD_FIVE_STAR_MARKETING_ADVISORS = "5star.madhive.com",
  PROD_EVIDNT = "evidnt.madhive.com",
  PROD_DISH_SLING = "dish-sling.madhive.com",
  PROD_T_MOBILE = "t-mobileadvertisingsolutions.madhive.com",
  PROD_AI_DIGITAL = "aidigital.madhive.com",
  PROD_TRIP_ADVISOR = "tripadvisor.madhive.com",
  PROD_LIFTOFF = "liftoff.madhive.com",
  // eslint-disable-next-line camelcase
  PROD_MiQ = "miq.madhive.com",
  PROD_OMG = "omg.madhive.com",
  PROD_OTTERA = "ottera.madhive.com",
  PROD_VOGLIO = "voglio.madhive.com",
  PROD_ARKADAS_GROUP = "arkadasgroup.madhive.com",
  PROD_SAKS_OFF_FIFTH = "saksofffifth.madhive.com",
  PROD_BUCKEYE_BROADBAND = "buckeyebroadband.madhive.com",
  PROD_LIFEBRANDS_D2C = "lifebrandsd2c.madhive.com",
  PROD_KILL_YOUR_COMPETITION = "killyourcompetition.madhive.com",
  PROD_SONOBI = "sonobi.madhive.com",
  PROD_BATEMAN_COLLECTIVE = "batemancollective.madhive.com",
  PROD_FEDERATED_DIGITAL_SOLUTIONS = "federateddigitalsolutions.madhive.com",
  PROD_KR8_VENTURES = "kr8ventures.madhive.com",
  PROD_ESSENTIALISM_ADVERTISING = "essentialismadvertising.madhive.com",
  PROD_RED_VENTURES = "redventures.madhive.com",
  PROD_HUDDLED_MASSES = "huddledmasses.madhive.com",
  PROD_AUTOMATED_TESTING = "automatedtesting.madhive.com",
  PROD_GREEN_AND_WOOD_MEDIA = "greenandwood.madhive.com",
  PROD_TASTEMADE = "tastemade.madhive.com",
  PROD_DIGILANT = "digilant.madhive.com",
  PROD_CHAMPIONSHIP_ADVERTISING = "championshipadvertising.madhive.com",
  PROD_CTV_BUYER = "ctvbuyer.madhive.com",
  PROD_LEV_LANE = "levlane.madhive.com",
  PROD_THE_DIGITAL_RUCKUS = "thedigitalruckus.madhive.com",
  PROD_BRAVE = "brave.madhive.com",
  PROD_VIDEOHEROES = "videoheroes.madhive.com",
  PROD_ENTHUSIAST_GAMING = "enthusiastgaming.madhive.com",
  PROD_CBS = "cbs.madhive.com",
  PROD_NIMBUS99 = "nimbusadserver.nimbus99.com",
  PROD_CONTINUUM_MEDIA = "continuummedia.madhive.com",
  PROD_NEXSTAR = "nexstar.madhive.com",
  PROD_BLAM_RETAIL = "blamretail.madhive.com",
  PROD_VICA_AI = "vica-ai.madhive.com",
  PROD_NIGHTLIFE_TELEVISION = "promo.nightlifetelevision.com",
  PROD_JRR_ADVERTISING = "jrr.madhive.com",
  PROD_ESTRELLA_MEDIA = "estrellamedia.madhive.com",
  PROD_PERFORMCB = "performcb.madhive.com",
  PROD_NEW_ROAD_ADVERTISING = "smartstreamott.com",
  PROD_QUICKTURN_MEDIA = "login.quickturn.tv",
  PROD_CONNECTADTV = "connectadtv.madhive.com",
  PROD_LILLY_BROADCASTING = "app.powerplaydigital.io"
}
/**
 * Map of hostname to corresponding firebase project ID
 */
export const HOSTNAME_TO_FIREBASE_PROJECT: Record<
  KnownHostNames,
  KnownProjectIds
> = {
  [KnownHostNames.PROD_MADHIVE_DEFAULT]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_MADHIVE_MEDIA_GROUP]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_PREMION_LEGACY]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_PREMION_CONSOLE]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_SCRIPPS_LEGACY]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_SCRIPPS_OCTANE]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_SCRIPPS_OCTANE_BETA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_BOBMILLS]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_BEACHFRONT]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_FUTURESMEDIA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_MEDIAMATH]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_DIGILANT_ARCHIVED]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_RUNNINGMATE_LEGACY]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_RUNNINGMATE_PLATFORM]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_BIG_SANDY_PROMOTIONS_DEFAULT]:
    KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_ASSOCIATED_VOLUME_BUYERS_DEFAULT]:
    KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_BIG_SANDY_PROMOTIONS_OCTILLION]:
    KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_ASSOCIATED_VOLUME_BUYERS_OCTILLION]:
    KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_BTC_MEDIA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_FOX]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_NEWSY]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_NEWSY_WITH_PORTAL_PREFIX]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.STAGING_DEFAULT]: KnownProjectIds.MAD_STAGING,
  [KnownHostNames.STAGING_PREMION]: KnownProjectIds.MAD_STAGING,
  [KnownHostNames.STAGING_SCRIPPS]: KnownProjectIds.MAD_STAGING,
  [KnownHostNames.INTERNAL_QA]: KnownProjectIds.MAD_QA,
  [KnownHostNames.INTERNAL_HACK]: KnownProjectIds.MAD_HACK,
  [KnownHostNames.DEMO_DEFAULT]: KnownProjectIds.MAD_DEMO,
  [KnownHostNames.DEMO_LEGACY]: KnownProjectIds.MAD_DEMO,
  [KnownHostNames.PROD_RESIDENT_HOME]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_VOICE_MEDIA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_LEGAL_ACTION_MEDIA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_MODUS_DIRECT]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_TEAM_LEWIS]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_KING]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_DX_AGENCY]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_LINCOLN_DIGITAL_GROUP]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_NMPI]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_PATHFINDER_INTERACTIVE]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_CTV_MEDIA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_CTV_MEDIA_DASHBOARD]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.TESTING_MADHIVE_DEFAULT]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_TALON]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_IHEART]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_OMNICOM]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_CBC]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_GROUNDTRUTH]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_COX]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_LOCALFACTOR]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_ALLENMEDIA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_GRIFFIN]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_HEARST]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_CAPITOL_BROADCASTING]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_MCAFEE]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_JUICE_MEDIA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_GOOD_KARMA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_UNIVISION]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_UNIVISION_ANALYTICS]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_GYDIGITAL]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_KATZDIGITAL]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_SPARKLIGHT]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_HP]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_NOM]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_BEN_HER]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_THE_REAL_REAL]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_BRANT_MEDIA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_GAMUT]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_EVERGREEN]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_BAHAKEL]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_BAHAKEL_DIGITAL_MEDIA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_ANALYTICS_OWL]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_DLM_AGENCY]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_MEDICX]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_TOWNSQUARE]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_DIGITAL_ANALYTICS_PRO]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_BLOCK_COMMUNICATIONS]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_TURNER_SPORTS]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_HOMESLICE_GROUP]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_MONDAY]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_MEDIAMATH_TV_PLATFORM]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_WIN_DIGITAL]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_CHARLTON_MARKETING]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_TALMONT]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_FIVE_STAR_MARKETING_ADVISORS]:
    KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_EVIDNT]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_DISH_SLING]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_T_MOBILE]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_AI_DIGITAL]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_TRIP_ADVISOR]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_LIFTOFF]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_MiQ]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_OMG]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_OTTERA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_VOGLIO]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_ARKADAS_GROUP]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_SAKS_OFF_FIFTH]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_BUCKEYE_BROADBAND]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_LIFEBRANDS_D2C]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_KILL_YOUR_COMPETITION]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_SONOBI]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_BATEMAN_COLLECTIVE]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_FEDERATED_DIGITAL_SOLUTIONS]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_KR8_VENTURES]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_ESSENTIALISM_ADVERTISING]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_RED_VENTURES]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_HUDDLED_MASSES]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_AUTOMATED_TESTING]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_GREEN_AND_WOOD_MEDIA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_TASTEMADE]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_DIGILANT]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_CHAMPIONSHIP_ADVERTISING]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_CTV_BUYER]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_LEV_LANE]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_THE_DIGITAL_RUCKUS]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_BRAVE]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_VIDEOHEROES]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_ENTHUSIAST_GAMING]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_CBS]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_NIMBUS99]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_CONTINUUM_MEDIA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_NEXSTAR]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_BLAM_RETAIL]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_VICA_AI]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_NIGHTLIFE_TELEVISION]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_JRR_ADVERTISING]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_ESTRELLA_MEDIA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_PERFORMCB]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_NEW_ROAD_ADVERTISING]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_QUICKTURN_MEDIA]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_CONNECTADTV]: KnownProjectIds.MAD_MASTER,
  [KnownHostNames.PROD_LILLY_BROADCASTING]: KnownProjectIds.MAD_MASTER
};
/**
 * Map of firebase project ID to corresponding GCP API key
 */
export const FIREBASE_PROJECT_ID_TO_API_KEY: Record<
  KnownProjectIds,
  KnownApiKeys
> = {
  [KnownProjectIds.MAD_MASTER]: KnownApiKeys.MAD_MASTER,
  [KnownProjectIds.MAD_STAGING]: KnownApiKeys.MAD_STAGING,
  [KnownProjectIds.MAD_QA]: KnownApiKeys.MAD_QA,
  [KnownProjectIds.MAD_HACK]: KnownApiKeys.MAD_HACK,
  [KnownProjectIds.MAD_DEMO]: KnownApiKeys.MAD_DEMO,
  [KnownProjectIds.MAD_DATA]: KnownApiKeys.MAD_DATA,
  [KnownProjectIds.MADHIVE_TESTING]: KnownApiKeys.MADHIVE_TESTING,
  [KnownProjectIds.MAD_TALON]: KnownApiKeys.MAD_TALON
};
/**
 * Map of firebase project ID to firebase analytics measurement ID
 */
export const FIREBASE_PROJECT_ID_TO_ANALYTICS_MEASUREMENT_ID: Record<
  KnownProjectIds,
  KnownMeasurementIds
> = {
  [KnownProjectIds.MAD_MASTER]: KnownMeasurementIds.MAD_MASTER,
  [KnownProjectIds.MAD_STAGING]: KnownMeasurementIds.MAD_STAGING,
  [KnownProjectIds.MAD_QA]: KnownMeasurementIds.MAD_QA,
  [KnownProjectIds.MAD_HACK]: KnownMeasurementIds.MAD_HACK,
  [KnownProjectIds.MAD_DEMO]: KnownMeasurementIds.MAD_DEMO,
  [KnownProjectIds.MAD_DATA]: KnownMeasurementIds.MAD_DATA,
  [KnownProjectIds.MADHIVE_TESTING]: KnownMeasurementIds.MADHIVE_TESTING,
  [KnownProjectIds.MAD_TALON]: KnownMeasurementIds.MAD_TALON
};
/**
 * Map of firebase project ID to firebase app  ID
 */
export const FIREBASE_PROJECT_ID_TO_APP_ID: Record<
  KnownProjectIds,
  KnownAppIds
> = {
  [KnownProjectIds.MAD_MASTER]: KnownAppIds.MAD_MASTER,
  [KnownProjectIds.MAD_STAGING]: KnownAppIds.MAD_STAGING,
  [KnownProjectIds.MAD_QA]: KnownAppIds.MAD_QA,
  [KnownProjectIds.MAD_HACK]: KnownAppIds.MAD_HACK,
  [KnownProjectIds.MAD_DEMO]: KnownAppIds.MAD_DEMO,
  [KnownProjectIds.MAD_DATA]: KnownAppIds.MAD_DATA,
  [KnownProjectIds.MADHIVE_TESTING]: KnownAppIds.MADHIVE_TESTING,
  [KnownProjectIds.MAD_TALON]: KnownAppIds.MAD_TALON
};
export { FIREBASE_PROJECT_TO_GOOGLE_ENDPOINTS } from "@madhive/mad-sdk";
/**
 * Map of hostname to fav icon
 */
export const HOSTNAME_TO_FAV_ICON: Record<KnownHostNames, string> = {
  [KnownHostNames.PROD_MADHIVE_DEFAULT]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_MADHIVE_MEDIA_GROUP]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_PREMION_LEGACY]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_PREMION_CONSOLE]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_SCRIPPS_LEGACY]: buildPublicUrl(
    "scrippsoctane_favicon.ico"
  ),
  [KnownHostNames.PROD_SCRIPPS_OCTANE]: buildPublicUrl(
    "scrippsoctane_favicon.ico"
  ),
  [KnownHostNames.PROD_SCRIPPS_OCTANE_BETA]: buildPublicUrl(
    "scrippsoctane_favicon.ico"
  ),
  [KnownHostNames.PROD_BOBMILLS]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_BEACHFRONT]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_MEDIAMATH]: buildPublicUrl("media-math-favicon.jpeg"),
  [KnownHostNames.PROD_MEDIAMATH_TV_PLATFORM]: buildPublicUrl(
    "media-math-favicon.jpeg"
  ),
  [KnownHostNames.PROD_DIGILANT_ARCHIVED]: buildPublicUrl("favicon.jpeg"),
  [KnownHostNames.PROD_FUTURESMEDIA]: buildPublicUrl("chiefmedia-favicon.svg"),
  [KnownHostNames.PROD_RUNNINGMATE_LEGACY]: buildPublicUrl(
    "runningmate_favicon.png"
  ),
  [KnownHostNames.PROD_RUNNINGMATE_PLATFORM]: buildPublicUrl(
    "runningmate_favicon.png"
  ),
  [KnownHostNames.PROD_BIG_SANDY_PROMOTIONS_DEFAULT]: buildPublicUrl(
    "octillion_favicon.png"
  ),
  [KnownHostNames.PROD_ASSOCIATED_VOLUME_BUYERS_DEFAULT]:
    buildPublicUrl("avb-favicon.png"),
  [KnownHostNames.PROD_BIG_SANDY_PROMOTIONS_OCTILLION]: buildPublicUrl(
    "octillion_favicon.png"
  ),
  [KnownHostNames.PROD_ASSOCIATED_VOLUME_BUYERS_OCTILLION]: buildPublicUrl(
    "octillion_favicon.png"
  ),
  [KnownHostNames.PROD_BTC_MEDIA]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_FOX]: buildPublicUrl("flx-favicon.png"),
  [KnownHostNames.PROD_NEWSY]: buildPublicUrl("newsy-favicon.png"),
  [KnownHostNames.PROD_NEWSY_WITH_PORTAL_PREFIX]:
    buildPublicUrl("newsy-favicon.png"),
  [KnownHostNames.PROD_RESIDENT_HOME]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_VOICE_MEDIA]: buildPublicUrl("voice-media-favicon.png"),
  [KnownHostNames.PROD_LEGAL_ACTION_MEDIA]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_TALON]: buildPublicUrl("talon-favicon.png"),
  [KnownHostNames.STAGING_DEFAULT]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.STAGING_PREMION]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.STAGING_SCRIPPS]: buildPublicUrl("scrippsoctane_favicon.ico"),
  [KnownHostNames.INTERNAL_QA]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.INTERNAL_HACK]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.DEMO_DEFAULT]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.DEMO_LEGACY]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_TEAM_LEWIS]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_KING]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_DX_AGENCY]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_LINCOLN_DIGITAL_GROUP]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_NMPI]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_PATHFINDER_INTERACTIVE]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_CTV_MEDIA]: buildPublicUrl("ctv-favicon.png"),
  [KnownHostNames.PROD_CTV_MEDIA_DASHBOARD]: buildPublicUrl("ctv-favicon.png"),
  [KnownHostNames.TESTING_MADHIVE_DEFAULT]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_IHEART]: buildPublicUrl("iheart-favicon.png"),
  [KnownHostNames.PROD_OMNICOM]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_CBC]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_GROUNDTRUTH]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_COX]: buildPublicUrl("cox-favicon.svg"),
  [KnownHostNames.PROD_LOCALFACTOR]: buildPublicUrl("localfactor-favicon.png"),
  [KnownHostNames.PROD_ALLENMEDIA]: buildPublicUrl("allenmedia-favicon.ico"),
  [KnownHostNames.PROD_GRIFFIN]: buildPublicUrl("griffin-favicon.png"),
  [KnownHostNames.PROD_HEARST]: buildPublicUrl("hearst-favicon.svg"),
  [KnownHostNames.PROD_CAPITOL_BROADCASTING]: buildPublicUrl(
    "capitol-broadcasting-favicon.png"
  ),
  [KnownHostNames.PROD_MCAFEE]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_JUICE_MEDIA]: buildPublicUrl("juice-media-favicon.png"),
  [KnownHostNames.PROD_GOOD_KARMA]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_MODUS_DIRECT]: buildPublicUrl("modus-direct-logo.png"),
  [KnownHostNames.PROD_UNIVISION]: buildPublicUrl("univision-favicon.svg"),
  [KnownHostNames.PROD_UNIVISION_ANALYTICS]: buildPublicUrl(
    "univision-favicon.svg"
  ),
  [KnownHostNames.PROD_GYDIGITAL]: buildPublicUrl("gydigital-favicon.svg"),
  [KnownHostNames.PROD_KATZDIGITAL]: buildPublicUrl("katz-digital-favicon.svg"),
  [KnownHostNames.PROD_SPARKLIGHT]: buildPublicUrl("sparklight-favicon.svg"),
  [KnownHostNames.PROD_HP]: buildPublicUrl("hp-favicon.ico"),
  [KnownHostNames.PROD_NOM]: buildPublicUrl("nom-favicon.png"),
  [KnownHostNames.PROD_BEN_HER]: buildPublicUrl("ben-her-favicon.svg"),
  [KnownHostNames.PROD_THE_REAL_REAL]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_BRANT_MEDIA]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_GAMUT]: buildPublicUrl("gamut-favicon.svg"),
  [KnownHostNames.PROD_EVERGREEN]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_BAHAKEL]: buildPublicUrl("bahakel-favicon.svg"),
  [KnownHostNames.PROD_BAHAKEL_DIGITAL_MEDIA]: buildPublicUrl(
    "bahakel-favicon.svg"
  ),
  [KnownHostNames.PROD_ANALYTICS_OWL]: buildPublicUrl(
    "analytics-owl-favicon.svg"
  ),
  [KnownHostNames.PROD_DLM_AGENCY]: buildPublicUrl("dlm-favicon.svg"),
  [KnownHostNames.PROD_MEDICX]: buildPublicUrl("medicx-favicon.svg"),
  [KnownHostNames.PROD_TOWNSQUARE]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_DIGITAL_ANALYTICS_PRO]: buildPublicUrl(
    "digital-analytics-fav.png"
  ),
  [KnownHostNames.PROD_BLOCK_COMMUNICATIONS]: buildPublicUrl("bci-fav.png"),
  [KnownHostNames.PROD_TURNER_SPORTS]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_HOMESLICE_GROUP]: buildPublicUrl("hs-group-round.png"),
  [KnownHostNames.PROD_MONDAY]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_WIN_DIGITAL]: buildPublicUrl("win-digital-favicon.ico"),
  [KnownHostNames.PROD_CHARLTON_MARKETING]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_TALMONT]: buildPublicUrl("talmont-favicon.svg"),
  [KnownHostNames.PROD_FIVE_STAR_MARKETING_ADVISORS]:
    buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_EVIDNT]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_DISH_SLING]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_T_MOBILE]: buildPublicUrl("t-mobile-favicon.ico"),
  [KnownHostNames.PROD_AI_DIGITAL]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_TRIP_ADVISOR]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_LIFTOFF]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_MiQ]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_OMG]: buildPublicUrl("omg-favicon.png"),
  [KnownHostNames.PROD_OTTERA]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_VOGLIO]: buildPublicUrl("voglio-favicon.png"),
  [KnownHostNames.PROD_ARKADAS_GROUP]: buildPublicUrl(
    "arkadas-group-favicon.ico"
  ),
  [KnownHostNames.PROD_SAKS_OFF_FIFTH]: buildPublicUrl(
    "saks-off-fifth-fav.png"
  ),
  [KnownHostNames.PROD_BUCKEYE_BROADBAND]: buildPublicUrl(
    "buckeye-broadband-favicon.ico"
  ),
  [KnownHostNames.PROD_LIFEBRANDS_D2C]: buildPublicUrl(
    "lifebrands-d2c-fav.png"
  ),
  [KnownHostNames.PROD_KILL_YOUR_COMPETITION]: buildPublicUrl(
    "kill-your-competition-favicon.ico"
  ),
  [KnownHostNames.PROD_SONOBI]: buildPublicUrl("sonobi-favicon.ico"),
  [KnownHostNames.PROD_BATEMAN_COLLECTIVE]: buildPublicUrl(
    "bateman-collective-favicon.png"
  ),
  [KnownHostNames.PROD_FEDERATED_DIGITAL_SOLUTIONS]: buildPublicUrl(
    "federated-digital-solutions-favicon.png"
  ),
  [KnownHostNames.PROD_KR8_VENTURES]: buildPublicUrl(
    "kr8-ventures-favicon.png"
  ),
  [KnownHostNames.PROD_ESSENTIALISM_ADVERTISING]: buildPublicUrl(
    "essentialism-advertising-favicon.png"
  ),
  [KnownHostNames.PROD_RED_VENTURES]: buildPublicUrl(
    "red-ventures-favicon.png"
  ),
  [KnownHostNames.PROD_HUDDLED_MASSES]: buildPublicUrl(
    "huddled-masses-favicon.png"
  ),
  [KnownHostNames.PROD_AUTOMATED_TESTING]: buildPublicUrl(
    "automated-testing-favicon.svg"
  ),
  [KnownHostNames.PROD_GREEN_AND_WOOD_MEDIA]: buildPublicUrl(
    "green-and-wood-media-favicon.png"
  ),
  [KnownHostNames.PROD_TASTEMADE]: buildPublicUrl("tastemade-favicon.png"),
  [KnownHostNames.PROD_DIGILANT]: buildPublicUrl("digilant-favicon.png"),
  [KnownHostNames.PROD_CHAMPIONSHIP_ADVERTISING]: buildPublicUrl(
    "championship-advertising-favicon.png"
  ),
  [KnownHostNames.PROD_CTV_BUYER]: buildPublicUrl("ctv-buyer-favicon.png"),
  [KnownHostNames.PROD_LEV_LANE]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_THE_DIGITAL_RUCKUS]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_BRAVE]: buildPublicUrl("brave-favicon.png"),
  [KnownHostNames.PROD_VIDEOHEROES]: buildPublicUrl("videoheroes-favicon.png"),
  [KnownHostNames.PROD_ENTHUSIAST_GAMING]: buildPublicUrl(
    "enthusiastgaming-favicon.png"
  ),
  [KnownHostNames.PROD_CBS]: buildPublicUrl("favicon.ico"),
  [KnownHostNames.PROD_NIMBUS99]: buildPublicUrl("nimbus99-favicon.png"),
  [KnownHostNames.PROD_CONTINUUM_MEDIA]: buildPublicUrl(
    "continuum-media-favicon.png"
  ),
  [KnownHostNames.PROD_NEXSTAR]: buildPublicUrl("nexstar.svg"),
  [KnownHostNames.PROD_BLAM_RETAIL]: buildPublicUrl("blam-retail-favicon.png"),
  [KnownHostNames.PROD_VICA_AI]: buildPublicUrl("vica-ai-favicon.png"),
  [KnownHostNames.PROD_NIGHTLIFE_TELEVISION]: buildPublicUrl(
    "nightlife-television.ico"
  ),
  [KnownHostNames.PROD_JRR_ADVERTISING]: buildPublicUrl(
    "jrr-advertising-favicon.png"
  ),
  [KnownHostNames.PROD_ESTRELLA_MEDIA]: buildPublicUrl(
    "estrella-media-favicon.png"
  ),
  [KnownHostNames.PROD_PERFORMCB]: buildPublicUrl("performcb-favicon.png"),
  [KnownHostNames.PROD_NEW_ROAD_ADVERTISING]: buildPublicUrl(
    "new-road-advertising-favicon.svg"
  ),
  [KnownHostNames.PROD_QUICKTURN_MEDIA]: buildPublicUrl(
    "quickturn-media-favicon.png"
  ),
  [KnownHostNames.PROD_CONNECTADTV]: buildPublicUrl("connectadtv-favicon.png"),
  [KnownHostNames.PROD_LILLY_BROADCASTING]: buildPublicUrl(
    "lilly-broadcasting-favicon.png"
  )
};
export const HOSTNAME_TO_ORG_NAME: Record<
  KnownHostNames,
  KnownOrganizationNames
> = {
  [KnownHostNames.PROD_ALLENMEDIA]: KnownOrganizationNames.ALLENMEDIA,
  [KnownHostNames.PROD_TURNER_SPORTS]: KnownOrganizationNames.TURNER_SPORTS,
  [KnownHostNames.PROD_ANALYTICS_OWL]: KnownOrganizationNames.ANALYTICS_OWL,
  [KnownHostNames.PROD_ASSOCIATED_VOLUME_BUYERS_DEFAULT]:
    KnownOrganizationNames.ASSOCIATED_VOLUME_BUYERS,
  [KnownHostNames.PROD_ASSOCIATED_VOLUME_BUYERS_OCTILLION]:
    KnownOrganizationNames.ASSOCIATED_VOLUME_BUYERS,
  [KnownHostNames.PROD_BAHAKEL]: KnownOrganizationNames.BAHAKEL,
  [KnownHostNames.PROD_BAHAKEL_DIGITAL_MEDIA]: KnownOrganizationNames.BAHAKEL,
  [KnownHostNames.PROD_BEACHFRONT]: KnownOrganizationNames.BEACHFRONT,
  [KnownHostNames.PROD_BEN_HER]: KnownOrganizationNames.BEN_HER,
  [KnownHostNames.PROD_BIG_SANDY_PROMOTIONS_DEFAULT]:
    KnownOrganizationNames.BIG_SANDY_PROMOTIONS,
  [KnownHostNames.PROD_BIG_SANDY_PROMOTIONS_OCTILLION]:
    KnownOrganizationNames.BIG_SANDY_PROMOTIONS,
  [KnownHostNames.PROD_BLOCK_COMMUNICATIONS]:
    KnownOrganizationNames.BLOCK_COMMUNICATIONS,
  [KnownHostNames.PROD_BOBMILLS]: KnownOrganizationNames.BOBMILLS,
  [KnownHostNames.PROD_BRANT_MEDIA]: KnownOrganizationNames.BRANT_MEDIA,
  [KnownHostNames.PROD_BTC_MEDIA]: KnownOrganizationNames.BTC_MEDIA,
  [KnownHostNames.PROD_CAPITOL_BROADCASTING]:
    KnownOrganizationNames.CAPITOL_BROADCASTING,
  [KnownHostNames.PROD_CBC]: KnownOrganizationNames.CBC,
  [KnownHostNames.PROD_COX]: KnownOrganizationNames.COX,
  [KnownHostNames.PROD_CTV_MEDIA]: KnownOrganizationNames.CTV_MEDIA,
  [KnownHostNames.PROD_CTV_MEDIA_DASHBOARD]: KnownOrganizationNames.CTV_MEDIA,
  [KnownHostNames.PROD_DIGITAL_ANALYTICS_PRO]:
    KnownOrganizationNames.DIGITAL_ANALYTICS_PRO,
  [KnownHostNames.PROD_DLM_AGENCY]: KnownOrganizationNames.DLM_AGENCY,
  [KnownHostNames.PROD_DX_AGENCY]: KnownOrganizationNames.DX_AGENCY,
  [KnownHostNames.PROD_EVERGREEN]: KnownOrganizationNames.EVERGREEN,
  [KnownHostNames.PROD_FOX]: KnownOrganizationNames.FOX,
  [KnownHostNames.PROD_FUTURESMEDIA]: KnownOrganizationNames.FUTURESMEDIA,
  [KnownHostNames.PROD_GAMUT]: KnownOrganizationNames.GAMUT,
  [KnownHostNames.PROD_GOOD_KARMA]: KnownOrganizationNames.GOOD_KARMA,
  [KnownHostNames.PROD_GRIFFIN]: KnownOrganizationNames.GRIFFIN,
  [KnownHostNames.PROD_GROUNDTRUTH]: KnownOrganizationNames.GROUNDTRUTH,
  [KnownHostNames.PROD_GYDIGITAL]: KnownOrganizationNames.GYDIGITAL,
  [KnownHostNames.PROD_HEARST]: KnownOrganizationNames.HEARST,
  [KnownHostNames.PROD_HP]: KnownOrganizationNames.HP,
  [KnownHostNames.PROD_IHEART]: KnownOrganizationNames.IHEART,
  [KnownHostNames.PROD_KATZDIGITAL]: KnownOrganizationNames.KATZ_DIGITAL,
  [KnownHostNames.PROD_KING]: KnownOrganizationNames.KING,
  [KnownHostNames.PROD_LEGAL_ACTION_MEDIA]:
    KnownOrganizationNames.LEGAL_ACTION_MEDIA,
  [KnownHostNames.PROD_LINCOLN_DIGITAL_GROUP]:
    KnownOrganizationNames.LINCOLN_DIGITAL_GROUP,
  [KnownHostNames.PROD_LOCALFACTOR]: KnownOrganizationNames.LOCALFACTOR,
  [KnownHostNames.PROD_MADHIVE_DEFAULT]: KnownOrganizationNames.MADHIVE_DEFAULT,
  [KnownHostNames.PROD_MADHIVE_MEDIA_GROUP]:
    KnownOrganizationNames.MADHIVE_MEDIA_GROUP,
  [KnownHostNames.PROD_MCAFEE]: KnownOrganizationNames.MCAFEE,
  [KnownHostNames.PROD_MEDIAMATH]: KnownOrganizationNames.MEDIAMATH,
  [KnownHostNames.PROD_MEDICX]: KnownOrganizationNames.MEDICX,
  [KnownHostNames.PROD_MODUS_DIRECT]: KnownOrganizationNames.MODUS_DIRECT,
  [KnownHostNames.PROD_NEWSY]: KnownOrganizationNames.NEWSY,
  [KnownHostNames.PROD_NEWSY_WITH_PORTAL_PREFIX]: KnownOrganizationNames.NEWSY,
  [KnownHostNames.PROD_NMPI]: KnownOrganizationNames.NMPI,
  [KnownHostNames.PROD_NOM]: KnownOrganizationNames.NOM,
  [KnownHostNames.PROD_OMNICOM]: KnownOrganizationNames.OMNICOM,
  [KnownHostNames.PROD_PATHFINDER_INTERACTIVE]:
    KnownOrganizationNames.PATHFINDER_INTERACTIVE,
  [KnownHostNames.PROD_PREMION_CONSOLE]: KnownOrganizationNames.PREMION,
  [KnownHostNames.PROD_PREMION_LEGACY]: KnownOrganizationNames.PREMION,
  [KnownHostNames.PROD_RESIDENT_HOME]: KnownOrganizationNames.RESIDENT_HOME,
  [KnownHostNames.PROD_RUNNINGMATE_LEGACY]: KnownOrganizationNames.RUNNINGMATE,
  [KnownHostNames.PROD_RUNNINGMATE_PLATFORM]:
    KnownOrganizationNames.RUNNINGMATE,
  [KnownHostNames.PROD_SCRIPPS_LEGACY]: KnownOrganizationNames.SCRIPPS,
  [KnownHostNames.PROD_SCRIPPS_OCTANE]: KnownOrganizationNames.SCRIPPS,
  [KnownHostNames.PROD_SCRIPPS_OCTANE_BETA]: KnownOrganizationNames.SCRIPPS,
  [KnownHostNames.PROD_SPARKLIGHT]: KnownOrganizationNames.SPARKLIGHT,
  [KnownHostNames.PROD_TALON]: KnownOrganizationNames.TALON,
  [KnownHostNames.PROD_TEAM_LEWIS]: KnownOrganizationNames.TEAM_LEWIS,
  [KnownHostNames.PROD_THE_REAL_REAL]: KnownOrganizationNames.THE_REAL_REAL,
  [KnownHostNames.PROD_TOWNSQUARE]: KnownOrganizationNames.TOWNSQUARE,
  [KnownHostNames.PROD_JUICE_MEDIA]: KnownOrganizationNames.JUICE_MEDIA,
  [KnownHostNames.PROD_UNIVISION]: KnownOrganizationNames.UNIVISION,
  [KnownHostNames.PROD_UNIVISION_ANALYTICS]: KnownOrganizationNames.UNIVISION,
  [KnownHostNames.PROD_VOICE_MEDIA]: KnownOrganizationNames.VOICE_MEDIA,
  [KnownHostNames.STAGING_DEFAULT]: KnownOrganizationNames.MADHIVE_DEFAULT,
  [KnownHostNames.STAGING_PREMION]: KnownOrganizationNames.PREMION,
  [KnownHostNames.STAGING_SCRIPPS]: KnownOrganizationNames.SCRIPPS,
  [KnownHostNames.INTERNAL_QA]: KnownOrganizationNames.MADHIVE_DEFAULT,
  [KnownHostNames.INTERNAL_HACK]: KnownOrganizationNames.MADHIVE_DEFAULT,
  [KnownHostNames.DEMO_DEFAULT]: KnownOrganizationNames.MADHIVE_DEFAULT,
  [KnownHostNames.DEMO_LEGACY]: KnownOrganizationNames.MADHIVE_DEFAULT,
  [KnownHostNames.TESTING_MADHIVE_DEFAULT]:
    KnownOrganizationNames.MADHIVE_DEFAULT,
  [KnownHostNames.PROD_HOMESLICE_GROUP]: KnownOrganizationNames.HOMESLICE_GROUP,
  [KnownHostNames.PROD_MONDAY]: KnownOrganizationNames.MONDAY,
  [KnownHostNames.PROD_MEDIAMATH_TV_PLATFORM]: KnownOrganizationNames.MEDIAMATH,
  [KnownHostNames.PROD_WIN_DIGITAL]: KnownOrganizationNames.WIN_DIGITAL,
  [KnownHostNames.PROD_CHARLTON_MARKETING]:
    KnownOrganizationNames.CHARLTON_MARKETING,
  [KnownHostNames.PROD_DIGILANT_ARCHIVED]:
    KnownOrganizationNames.DIGILANT_ARCHIVED,
  [KnownHostNames.PROD_TALMONT]: KnownOrganizationNames.TALMONT,
  [KnownHostNames.PROD_FIVE_STAR_MARKETING_ADVISORS]:
    KnownOrganizationNames.FIVE_STAR_MARKETING_ADVISORS,
  [KnownHostNames.PROD_EVIDNT]: KnownOrganizationNames.EVIDNT,
  [KnownHostNames.PROD_DISH_SLING]: KnownOrganizationNames.DISH_SLING,
  [KnownHostNames.PROD_T_MOBILE]: KnownOrganizationNames.T_MOBILE,
  [KnownHostNames.PROD_AI_DIGITAL]: KnownOrganizationNames.AI_DIGITAL,
  [KnownHostNames.PROD_TRIP_ADVISOR]: KnownOrganizationNames.TRIP_ADVISOR,
  [KnownHostNames.PROD_LIFTOFF]: KnownOrganizationNames.LIFTOFF,
  [KnownHostNames.PROD_MiQ]: KnownOrganizationNames.MiQ,
  [KnownHostNames.PROD_OMG]: KnownOrganizationNames.OMG,
  [KnownHostNames.PROD_OTTERA]: KnownOrganizationNames.OTTERA,
  [KnownHostNames.PROD_VOGLIO]: KnownOrganizationNames.VOGLIO,
  [KnownHostNames.PROD_ARKADAS_GROUP]: KnownOrganizationNames.ARKADAS_GROUP,
  [KnownHostNames.PROD_SAKS_OFF_FIFTH]: KnownOrganizationNames.SAKS_OFF_FIFTH,
  [KnownHostNames.PROD_BUCKEYE_BROADBAND]:
    KnownOrganizationNames.BUCKEYE_BROADBAND,
  [KnownHostNames.PROD_LIFEBRANDS_D2C]: KnownOrganizationNames.LIFEBRANDS_D2C,
  [KnownHostNames.PROD_KILL_YOUR_COMPETITION]:
    KnownOrganizationNames.KILL_YOUR_COMPETITION,
  [KnownHostNames.PROD_SONOBI]: KnownOrganizationNames.SONOBI,
  [KnownHostNames.PROD_BATEMAN_COLLECTIVE]:
    KnownOrganizationNames.BATEMAN_COLLECTIVE,
  [KnownHostNames.PROD_FEDERATED_DIGITAL_SOLUTIONS]:
    KnownOrganizationNames.FEDERATED_DIGITAL_SOLUTIONS,
  [KnownHostNames.PROD_KR8_VENTURES]: KnownOrganizationNames.KR8_VENTURES,
  [KnownHostNames.PROD_ESSENTIALISM_ADVERTISING]:
    KnownOrganizationNames.ESSENTIALISM_ADVERTISING,
  [KnownHostNames.PROD_RED_VENTURES]: KnownOrganizationNames.RED_VENTURES,
  [KnownHostNames.PROD_HUDDLED_MASSES]: KnownOrganizationNames.HUDDLED_MASSES,
  [KnownHostNames.PROD_AUTOMATED_TESTING]:
    KnownOrganizationNames.MADHIVE_DEFAULT,
  [KnownHostNames.PROD_GREEN_AND_WOOD_MEDIA]:
    KnownOrganizationNames.GREEN_AND_WOOD_MEDIA,
  [KnownHostNames.PROD_TASTEMADE]: KnownOrganizationNames.MADHIVE_DEFAULT,
  [KnownHostNames.PROD_DIGILANT]: KnownOrganizationNames.DIGILANT,
  [KnownHostNames.PROD_CHAMPIONSHIP_ADVERTISING]:
    KnownOrganizationNames.CHAMPIONSHIP_ADVERTISING,
  [KnownHostNames.PROD_CTV_BUYER]: KnownOrganizationNames.CTV_BUYER,
  [KnownHostNames.PROD_LEV_LANE]: KnownOrganizationNames.LEV_LANE,
  [KnownHostNames.PROD_THE_DIGITAL_RUCKUS]:
    KnownOrganizationNames.THE_DIGITAL_RUCKUS,
  [KnownHostNames.PROD_BRAVE]: KnownOrganizationNames.BRAVE,
  [KnownHostNames.PROD_VIDEOHEROES]: KnownOrganizationNames.VIDEOHEROES,
  [KnownHostNames.PROD_ENTHUSIAST_GAMING]:
    KnownOrganizationNames.ENTHUSIAST_GAMING,
  [KnownHostNames.PROD_CBS]: KnownOrganizationNames.CBS,
  [KnownHostNames.PROD_NIMBUS99]: KnownOrganizationNames.NIMBUS99,
  [KnownHostNames.PROD_CONTINUUM_MEDIA]: KnownOrganizationNames.CONTINUUM_MEDIA,
  [KnownHostNames.PROD_NEXSTAR]: KnownOrganizationNames.NEXSTAR,
  [KnownHostNames.PROD_BLAM_RETAIL]: KnownOrganizationNames.BLAM_RETAIL,
  [KnownHostNames.PROD_VICA_AI]: KnownOrganizationNames.VICA_AI,
  [KnownHostNames.PROD_NIGHTLIFE_TELEVISION]:
    KnownOrganizationNames.NIGHTLIFE_TELEVISION,
  [KnownHostNames.PROD_JRR_ADVERTISING]: KnownOrganizationNames.JRR_ADVERTISING,
  [KnownHostNames.PROD_ESTRELLA_MEDIA]: KnownOrganizationNames.ESTRELLA_MEDIA,
  [KnownHostNames.PROD_PERFORMCB]: KnownOrganizationNames.PERFORMCB,
  [KnownHostNames.PROD_NEW_ROAD_ADVERTISING]:
    KnownOrganizationNames.NEW_ROAD_ADVERTISING,
  [KnownHostNames.PROD_QUICKTURN_MEDIA]: KnownOrganizationNames.QUICKTURN_MEDIA,
  [KnownHostNames.PROD_CONNECTADTV]: KnownOrganizationNames.CONNECTADTV,
  [KnownHostNames.PROD_LILLY_BROADCASTING]:
    KnownOrganizationNames.LILLY_BROADCASTING
};
/**
 * Map of organization to fav icon. Used as a fallback if hostname is not known.
 * This should only be used in a developer environment (i.e. localhost)
 */
export const ORGANIZATION_NAME_TO_FAV_ICON: Record<OrganizationName, string> = {
  [KnownOrganizationNames.MADHIVE_DEFAULT]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.MADHIVE_INTEGRATION_TESTING_ORG]:
    buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.MADHIVE_DEMO]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.MADHIVE_MEDIA_GROUP]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.PREMION]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.SCRIPPS]: buildPublicUrl("scrippsoctane_favicon.ico"),
  [KnownOrganizationNames.BOBMILLS]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.FUTURESMEDIA]: buildPublicUrl(
    "chiefmedia-favicon.svg"
  ),
  [KnownOrganizationNames.RUNNINGMATE]: buildPublicUrl(
    "runningmate_favicon.png"
  ),
  [KnownOrganizationNames.BEACHFRONT]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.MEDIAMATH]: buildPublicUrl("media-math-favicon.jpeg"),
  [KnownOrganizationNames.DIGILANT_ARCHIVED]: buildPublicUrl(
    "media-math-favicon.jpeg"
  ),
  [KnownOrganizationNames.BIG_SANDY_PROMOTIONS]: buildPublicUrl(
    "octillion_favicon.png"
  ),
  [KnownOrganizationNames.ASSOCIATED_VOLUME_BUYERS]: buildPublicUrl(
    "octillion_favicon.png"
  ),
  [KnownOrganizationNames.BTC_MEDIA]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.UAT_MEDIA]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.FOX]: buildPublicUrl("flx-favicon.png"),
  [KnownOrganizationNames.NEWSY]: buildPublicUrl("newsy-favicon.png"),
  [KnownOrganizationNames.RESIDENT_HOME]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.VOICE_MEDIA]: buildPublicUrl(
    "voice-media-favicon.png"
  ),
  [KnownOrganizationNames.LEGAL_ACTION_MEDIA]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.FOSTERS_LAB]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.TEAM_LEWIS]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.KING]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.DX_AGENCY]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.LINCOLN_DIGITAL_GROUP]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.NMPI]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.PATHFINDER_INTERACTIVE]:
    buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.CTV_MEDIA]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.NEWS_PRESS_GAZETTE]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.TALON]: buildPublicUrl("talon-favicon.png"),
  [KnownOrganizationNames.IHEART]: buildPublicUrl("iheart-favicon.png"),
  [KnownOrganizationNames.OMNICOM]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.CBC]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.GROUNDTRUTH]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.COX]: buildPublicUrl("cox-favicon.svg"),
  [KnownOrganizationNames.LOCALFACTOR]: buildPublicUrl(
    "localfactor-favicon.png"
  ),
  [KnownOrganizationNames.ALLENMEDIA]: buildPublicUrl("allenmedia-favicon.ico"),
  [KnownOrganizationNames.GRIFFIN]: buildPublicUrl("griffin-favicon.png"),
  [KnownOrganizationNames.HEARST]: buildPublicUrl("hearst-favicon.svg"),
  [KnownOrganizationNames.CAPITOL_BROADCASTING]: buildPublicUrl(
    "capitol-broadcasting-favicon.png"
  ),
  [KnownOrganizationNames.MCAFEE]: buildPublicUrl("mcafee-favicon.svg"),
  [KnownOrganizationNames.JUICE_MEDIA]: buildPublicUrl(
    "juice-media-favicon.png"
  ),
  [KnownOrganizationNames.GOOD_KARMA]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.MODUS_DIRECT]: buildPublicUrl(
    "modus-direct-logo.png"
  ),
  [KnownOrganizationNames.UNIVISION]: buildPublicUrl("univision-favicon.svg"),
  [KnownOrganizationNames.GYDIGITAL]: buildPublicUrl("gydigital-favicon.svg"),
  [KnownOrganizationNames.KATZ_DIGITAL]: buildPublicUrl(
    "katz-digital-favicon.svg"
  ),
  [KnownOrganizationNames.SPARKLIGHT]: buildPublicUrl("sparklight-favicon.svg"),
  [KnownOrganizationNames.HP]: buildPublicUrl("hp-favicon.ico"),
  [KnownOrganizationNames.NOM]: buildPublicUrl("nom-favicon.png"),
  [KnownOrganizationNames.BEN_HER]: buildPublicUrl("ben-her-favicon.svg"),
  [KnownOrganizationNames.THE_REAL_REAL]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.BRANT_MEDIA]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.GAMUT]: buildPublicUrl("gamut-favicon.svg"),
  [KnownOrganizationNames.EVERGREEN]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.BAHAKEL]: buildPublicUrl("bahakel-favicon.svg"),
  [KnownOrganizationNames.ANALYTICS_OWL]: buildPublicUrl(
    "analytics-owl-favicon.svg"
  ),
  [KnownOrganizationNames.DLM_AGENCY]: buildPublicUrl("dlm-favicon.svg"),
  [KnownOrganizationNames.MEDICX]: buildPublicUrl("medicx-favicon.svg"),
  [KnownOrganizationNames.TOWNSQUARE]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.DIGITAL_ANALYTICS_PRO]: buildPublicUrl(
    "digital-analytics-fav.png"
  ),
  [KnownOrganizationNames.BLOCK_COMMUNICATIONS]: buildPublicUrl("bci-fav.png"),
  [KnownOrganizationNames.TURNER_SPORTS]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.HOMESLICE_GROUP]:
    buildPublicUrl("hs-group-round.png"),
  [KnownOrganizationNames.MONDAY]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.WIN_DIGITAL]: buildPublicUrl(
    "win-digital-favicon.ico"
  ),
  [KnownOrganizationNames.CHARLTON_MARKETING]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.TALMONT]: buildPublicUrl("talmont-favicon.svg"),
  [KnownOrganizationNames.FIVE_STAR_MARKETING_ADVISORS]:
    buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.EVIDNT]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.DISH_SLING]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.T_MOBILE]: buildPublicUrl("t-mobile-favicon.ico"),
  [KnownOrganizationNames.AI_DIGITAL]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.TRIP_ADVISOR]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.LIFTOFF]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.MiQ]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.OMG]: buildPublicUrl("omg-favicon.png"),
  [KnownOrganizationNames.OTTERA]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.VOGLIO]: buildPublicUrl("voglio-favicon.png"),
  [KnownOrganizationNames.ARKADAS_GROUP]: buildPublicUrl(
    "arkadas-group-favicon.ico"
  ),
  [KnownOrganizationNames.SAKS_OFF_FIFTH]: buildPublicUrl(
    "saks-off-fifth-fav.png"
  ),
  [KnownOrganizationNames.BUCKEYE_BROADBAND]: buildPublicUrl(
    "buckeye-broadband-favicon.ico"
  ),
  [KnownOrganizationNames.LIFEBRANDS_D2C]: buildPublicUrl(
    "lifebrands-d2c-fav.png"
  ),
  [KnownOrganizationNames.KILL_YOUR_COMPETITION]: buildPublicUrl(
    "kill-your-competition-favicon.ico"
  ),
  [KnownOrganizationNames.SONOBI]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.BATEMAN_COLLECTIVE]: buildPublicUrl(
    "bateman-collective-favicon.png"
  ),
  [KnownOrganizationNames.FEDERATED_DIGITAL_SOLUTIONS]: buildPublicUrl(
    "federated-digital-solutions-favicon.png"
  ),
  [KnownOrganizationNames.KR8_VENTURES]: buildPublicUrl(
    "kr8-ventures-favicon.png"
  ),
  [KnownOrganizationNames.ESSENTIALISM_ADVERTISING]: buildPublicUrl(
    "essentialism-advertising-favicon.png"
  ),
  [KnownOrganizationNames.RED_VENTURES]: buildPublicUrl(
    "red-ventures-favicon.png"
  ),
  [KnownOrganizationNames.HUDDLED_MASSES]: buildPublicUrl(
    "huddled-masses-favicon.png"
  ),
  [KnownOrganizationNames.AUTOMATED_TESTING]: buildPublicUrl(
    "automated-testing-favicon.svg"
  ),
  [KnownOrganizationNames.GREEN_AND_WOOD_MEDIA]: buildPublicUrl(
    "green-and-wood-media-favicon.png"
  ),
  [KnownOrganizationNames.TASTEMADE]: buildPublicUrl("tastemade-favicon.png"),
  [KnownOrganizationNames.DIGILANT]: buildPublicUrl("digilant-favicon.png"),
  [KnownOrganizationNames.CHAMPIONSHIP_ADVERTISING]: buildPublicUrl(
    "championship-advertising-favicon.png"
  ),
  [KnownOrganizationNames.CTV_BUYER]: buildPublicUrl("ctv-buyer-favicon.png"),
  [KnownOrganizationNames.LEV_LANE]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.THE_DIGITAL_RUCKUS]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.BRAVE]: buildPublicUrl("brave-favicon.png"),
  [KnownOrganizationNames.VIDEOHEROES]: buildPublicUrl(
    "videoheroes-favicon.png"
  ),
  [KnownOrganizationNames.ENTHUSIAST_GAMING]: buildPublicUrl(
    "enthusiastgaming-favicon.png"
  ),
  [KnownOrganizationNames.CBS]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.NIMBUS99]: buildPublicUrl("nimbus99-favicon.png"),
  [KnownOrganizationNames.CONTINUUM_MEDIA]: buildPublicUrl(
    "continuum-media-favicon.png"
  ),
  [KnownOrganizationNames.NEXSTAR]: buildPublicUrl("nexstar.svg"),
  [KnownOrganizationNames.BLAM_RETAIL]: buildPublicUrl(
    "blam-retail-favicon.png"
  ),
  [KnownOrganizationNames.VICA_AI]: buildPublicUrl("vica-ai-favicon.png"),
  [KnownOrganizationNames.NIGHTLIFE_TELEVISION]: buildPublicUrl(
    "nightlife-television.ico"
  ),
  [KnownOrganizationNames.JRR_ADVERTISING]: buildPublicUrl(
    "jrr-advertising-favicon.png"
  ),
  [KnownOrganizationNames.ESTRELLA_MEDIA]: buildPublicUrl(
    "estrella-media-favicon.png"
  ),
  [KnownOrganizationNames.PERFORMCB]: buildPublicUrl("performcb-favicon.png"),
  [KnownOrganizationNames.NEW_ROAD_ADVERTISING]: buildPublicUrl(
    "new-road-advertising-favicon.svg"
  ),
  [KnownOrganizationNames.QUICKTURN_MEDIA]: buildPublicUrl(
    "quickturn-media-favicon.png"
  ),
  [KnownOrganizationNames.CONNECTADTV]: buildPublicUrl(
    "connectadtv-favicon.png"
  ),
  [KnownOrganizationNames.NRG_MEDIA]: buildPublicUrl("favicon.ico"),
  [KnownOrganizationNames.LILLY_BROADCASTING]: buildPublicUrl(
    "lilly-broadcasting-favicon.ico"
  )
};
export const SET_OF_DEMO_DOMAINS = new Set([
  KnownHostNames.DEMO_DEFAULT,
  KnownHostNames.DEMO_LEGACY
]);
export const SET_OF_NON_PROD_DOMAINS = new Set([
  KnownHostNames.STAGING_DEFAULT,
  KnownHostNames.INTERNAL_QA,
  KnownHostNames.INTERNAL_HACK,
  KnownHostNames.TESTING_MADHIVE_DEFAULT,
  KnownHostNames.STAGING_SCRIPPS,
  KnownHostNames.STAGING_PREMION
]);
/**
 * Map of hostname to base app name. The base app name is used as both the document title and
 * for custom naming of downloaded files (e.g. CSV exports).
 */
export const HOSTNAME_TO_BASE_APP_NAME: Record<KnownHostNames, BaseAppNames> = {
  [KnownHostNames.PROD_MADHIVE_DEFAULT]: BaseAppNames.MADHIVE,
  [KnownHostNames.PROD_MADHIVE_MEDIA_GROUP]: BaseAppNames.MADHIVE_MEDIA_GROUP,
  [KnownHostNames.PROD_PREMION_LEGACY]: BaseAppNames.MADHIVE,
  [KnownHostNames.PROD_PREMION_CONSOLE]: BaseAppNames.MADHIVE,
  [KnownHostNames.PROD_SCRIPPS_LEGACY]: BaseAppNames.SCRIPPS,
  [KnownHostNames.PROD_SCRIPPS_OCTANE]: BaseAppNames.SCRIPPS,
  [KnownHostNames.PROD_SCRIPPS_OCTANE_BETA]: BaseAppNames.SCRIPPS,
  [KnownHostNames.PROD_BOBMILLS]: BaseAppNames.MADHIVE,
  [KnownHostNames.PROD_BEACHFRONT]: BaseAppNames.MADHIVE,
  [KnownHostNames.PROD_MEDIAMATH]: BaseAppNames.MEDIAMATH,
  [KnownHostNames.PROD_MEDIAMATH_TV_PLATFORM]: BaseAppNames.MEDIAMATH,
  [KnownHostNames.PROD_FUTURESMEDIA]: BaseAppNames.FUTURESMEDIA,
  [KnownHostNames.PROD_RUNNINGMATE_PLATFORM]: BaseAppNames.RUNNINGMATE,
  [KnownHostNames.PROD_RUNNINGMATE_LEGACY]: BaseAppNames.RUNNINGMATE,
  [KnownHostNames.PROD_BIG_SANDY_PROMOTIONS_DEFAULT]:
    BaseAppNames.BIG_SANDY_PROMOTIONS,
  [KnownHostNames.PROD_ASSOCIATED_VOLUME_BUYERS_DEFAULT]:
    BaseAppNames.ASSOCIATED_VOLUME_BUYERS,
  [KnownHostNames.PROD_BIG_SANDY_PROMOTIONS_OCTILLION]:
    BaseAppNames.BIG_SANDY_PROMOTIONS,
  [KnownHostNames.PROD_ASSOCIATED_VOLUME_BUYERS_OCTILLION]:
    BaseAppNames.ASSOCIATED_VOLUME_BUYERS,
  [KnownHostNames.PROD_BTC_MEDIA]: BaseAppNames.BTC_MEDIA,
  [KnownHostNames.PROD_FOX]: BaseAppNames.FOX,
  [KnownHostNames.STAGING_DEFAULT]: BaseAppNames.MADHIVE,
  [KnownHostNames.STAGING_PREMION]: BaseAppNames.MADHIVE,
  [KnownHostNames.STAGING_SCRIPPS]: BaseAppNames.SCRIPPS,
  [KnownHostNames.INTERNAL_QA]: BaseAppNames.MADHIVE,
  [KnownHostNames.INTERNAL_HACK]: BaseAppNames.MADHIVE,
  [KnownHostNames.DEMO_DEFAULT]: BaseAppNames.MADHIVE,
  [KnownHostNames.DEMO_LEGACY]: BaseAppNames.MADHIVE,
  [KnownHostNames.PROD_NEWSY]: BaseAppNames.NEWSY,
  [KnownHostNames.PROD_NEWSY_WITH_PORTAL_PREFIX]: BaseAppNames.NEWSY,
  [KnownHostNames.PROD_RESIDENT_HOME]: BaseAppNames.RESIDENT_HOME,
  [KnownHostNames.PROD_VOICE_MEDIA]: BaseAppNames.VOICE_MEDIA,
  [KnownHostNames.PROD_LEGAL_ACTION_MEDIA]: BaseAppNames.LEGAL_ACTION_MEDIA,
  [KnownHostNames.PROD_MODUS_DIRECT]: BaseAppNames.MODUS_DIRECT,
  [KnownHostNames.PROD_TEAM_LEWIS]: BaseAppNames.TEAM_LEWIS,
  [KnownHostNames.PROD_KING]: BaseAppNames.KING,
  [KnownHostNames.PROD_DX_AGENCY]: BaseAppNames.DX_AGENCY,
  [KnownHostNames.PROD_LINCOLN_DIGITAL_GROUP]:
    BaseAppNames.LINCOLN_DIGITAL_GROUP,
  [KnownHostNames.PROD_NMPI]: BaseAppNames.NMPI,
  [KnownHostNames.PROD_PATHFINDER_INTERACTIVE]:
    BaseAppNames.PATHFINDER_INTERACTIVE,
  [KnownHostNames.PROD_CTV_MEDIA]: BaseAppNames.CTV_MEDIA,
  [KnownHostNames.PROD_CTV_MEDIA_DASHBOARD]: BaseAppNames.CTV_MEDIA,
  [KnownHostNames.TESTING_MADHIVE_DEFAULT]: BaseAppNames.MADHIVE,
  [KnownHostNames.PROD_TALON]: BaseAppNames.TALON,
  [KnownHostNames.PROD_IHEART]: BaseAppNames.IHEART,
  [KnownHostNames.PROD_OMNICOM]: BaseAppNames.OMNICOM,
  [KnownHostNames.PROD_CBC]: BaseAppNames.CBC,
  [KnownHostNames.PROD_GROUNDTRUTH]: BaseAppNames.GROUNDTRUTH,
  [KnownHostNames.PROD_COX]: BaseAppNames.COX,
  [KnownHostNames.PROD_LOCALFACTOR]: BaseAppNames.LOCALFACTOR,
  [KnownHostNames.PROD_ALLENMEDIA]: BaseAppNames.ALLENMEDIA,
  [KnownHostNames.PROD_GRIFFIN]: BaseAppNames.GRIFFIN,
  [KnownHostNames.PROD_HEARST]: BaseAppNames.HEARST,
  [KnownHostNames.PROD_CAPITOL_BROADCASTING]: BaseAppNames.CAPITOL_BROADCASTING,
  [KnownHostNames.PROD_MCAFEE]: BaseAppNames.MCAFEE,
  [KnownHostNames.PROD_JUICE_MEDIA]: BaseAppNames.JUICE_MEDIA,
  [KnownHostNames.PROD_GOOD_KARMA]: BaseAppNames.GOOD_KARMA,
  [KnownHostNames.PROD_UNIVISION]: BaseAppNames.UNIVISION,
  [KnownHostNames.PROD_UNIVISION_ANALYTICS]: BaseAppNames.UNIVISION,
  [KnownHostNames.PROD_GYDIGITAL]: BaseAppNames.GYDIGITAL,
  [KnownHostNames.PROD_KATZDIGITAL]: BaseAppNames.KATZDIGITAL,
  [KnownHostNames.PROD_SPARKLIGHT]: BaseAppNames.SPARKLIGHT,
  [KnownHostNames.PROD_HP]: BaseAppNames.HP,
  [KnownHostNames.PROD_NOM]: BaseAppNames.NOM,
  [KnownHostNames.PROD_BEN_HER]: BaseAppNames.BEN_HER,
  [KnownHostNames.PROD_THE_REAL_REAL]: BaseAppNames.THE_REAL_REAL,
  [KnownHostNames.PROD_BRANT_MEDIA]: BaseAppNames.BRANT_MEDIA,
  [KnownHostNames.PROD_GAMUT]: BaseAppNames.GAMUT,
  [KnownHostNames.PROD_EVERGREEN]: BaseAppNames.EVERGREEN,
  [KnownHostNames.PROD_BAHAKEL]: BaseAppNames.BAHAKEL,
  [KnownHostNames.PROD_BAHAKEL_DIGITAL_MEDIA]: BaseAppNames.BAHAKEL,
  [KnownHostNames.PROD_ANALYTICS_OWL]: BaseAppNames.ANALYTICS_OWL,
  [KnownHostNames.PROD_DLM_AGENCY]: BaseAppNames.DLM_AGENCY,
  [KnownHostNames.PROD_MEDICX]: BaseAppNames.MEDICX,
  [KnownHostNames.PROD_TOWNSQUARE]: BaseAppNames.TOWNSQUARE,
  [KnownHostNames.PROD_TURNER_SPORTS]: BaseAppNames.TURNER_SPORTS,
  [KnownHostNames.PROD_DIGITAL_ANALYTICS_PRO]:
    BaseAppNames.DIGITAL_ANALYTICS_PRO,
  [KnownHostNames.PROD_BLOCK_COMMUNICATIONS]: BaseAppNames.BLOCK_COMMUNICATIONS,
  [KnownHostNames.PROD_HOMESLICE_GROUP]: BaseAppNames.HOMESLICE_GROUP,
  [KnownHostNames.PROD_MONDAY]: BaseAppNames.MONDAY,
  [KnownHostNames.PROD_WIN_DIGITAL]: BaseAppNames.WIN_DIGITAL,
  [KnownHostNames.PROD_CHARLTON_MARKETING]: BaseAppNames.CHARLTON_MARKETING,
  [KnownHostNames.PROD_DIGILANT_ARCHIVED]: BaseAppNames.DIGILANT_ARCHIVED,
  [KnownHostNames.PROD_TALMONT]: BaseAppNames.TALMONT,
  [KnownHostNames.PROD_FIVE_STAR_MARKETING_ADVISORS]:
    BaseAppNames.FIVE_STAR_MARKETING_ADVISORS,
  [KnownHostNames.PROD_EVIDNT]: BaseAppNames.EVIDNT,
  [KnownHostNames.PROD_DISH_SLING]: BaseAppNames.DISH_SLING,
  [KnownHostNames.PROD_T_MOBILE]: BaseAppNames.T_MOBILE,
  [KnownHostNames.PROD_AI_DIGITAL]: BaseAppNames.AI_DIGITAL,
  [KnownHostNames.PROD_TRIP_ADVISOR]: BaseAppNames.TRIP_ADVISOR,
  [KnownHostNames.PROD_LIFTOFF]: BaseAppNames.LIFTOFF,
  [KnownHostNames.PROD_MiQ]: BaseAppNames.MiQ,
  [KnownHostNames.PROD_OMG]: BaseAppNames.OMG,
  [KnownHostNames.PROD_OTTERA]: BaseAppNames.OTTERA,
  [KnownHostNames.PROD_VOGLIO]: BaseAppNames.VOGLIO,
  [KnownHostNames.PROD_ARKADAS_GROUP]: BaseAppNames.ARKADAS_GROUP,
  [KnownHostNames.PROD_SAKS_OFF_FIFTH]: BaseAppNames.SAKS_OFF_FIFTH,
  [KnownHostNames.PROD_BUCKEYE_BROADBAND]: BaseAppNames.BUCKEYE_BROADBAND,
  [KnownHostNames.PROD_LIFEBRANDS_D2C]: BaseAppNames.LIFEBRANDS_D2C,
  [KnownHostNames.PROD_KILL_YOUR_COMPETITION]:
    BaseAppNames.KILL_YOUR_COMPETITION,
  [KnownHostNames.PROD_SONOBI]: BaseAppNames.SONOBI,
  [KnownHostNames.PROD_BATEMAN_COLLECTIVE]: BaseAppNames.BATEMAN_COLLECTIVE,
  [KnownHostNames.PROD_FEDERATED_DIGITAL_SOLUTIONS]:
    BaseAppNames.FEDERATED_DIGITAL_SOLUTIONS,
  [KnownHostNames.PROD_KR8_VENTURES]: BaseAppNames.KR8_VENTURES,
  [KnownHostNames.PROD_ESSENTIALISM_ADVERTISING]:
    BaseAppNames.ESSENTIALISM_ADVERTISING,
  [KnownHostNames.PROD_RED_VENTURES]: BaseAppNames.RED_VENTURES,
  [KnownHostNames.PROD_HUDDLED_MASSES]: BaseAppNames.HUDDLED_MASSES,
  [KnownHostNames.PROD_AUTOMATED_TESTING]: BaseAppNames.AUTOMATED_TESTING,
  [KnownHostNames.PROD_GREEN_AND_WOOD_MEDIA]: BaseAppNames.GREEN_AND_WOOD_MEDIA,
  [KnownHostNames.PROD_TASTEMADE]: BaseAppNames.TASTEMADE,
  [KnownHostNames.PROD_DIGILANT]: BaseAppNames.DIGILANT,
  [KnownHostNames.PROD_CHAMPIONSHIP_ADVERTISING]:
    BaseAppNames.CHAMPIONSHIP_ADVERTISING,
  [KnownHostNames.PROD_CTV_BUYER]: BaseAppNames.CTV_BUYER,
  [KnownHostNames.PROD_LEV_LANE]: BaseAppNames.LEV_LANE,
  [KnownHostNames.PROD_THE_DIGITAL_RUCKUS]: BaseAppNames.THE_DIGITAL_RUCKUS,
  [KnownHostNames.PROD_BRAVE]: BaseAppNames.BRAVE,
  [KnownHostNames.PROD_VIDEOHEROES]: BaseAppNames.VIDEOHEROES,
  [KnownHostNames.PROD_ENTHUSIAST_GAMING]: BaseAppNames.ENTHUSIAST_GAMING,
  [KnownHostNames.PROD_CBS]: BaseAppNames.CBS,
  [KnownHostNames.PROD_NIMBUS99]: BaseAppNames.NIMBUS99,
  [KnownHostNames.PROD_CONTINUUM_MEDIA]: BaseAppNames.CONTINUUM_MEDIA,
  [KnownHostNames.PROD_NEXSTAR]: BaseAppNames.NEXSTAR,
  [KnownHostNames.PROD_BLAM_RETAIL]: BaseAppNames.BLAM_RETAIL,
  [KnownHostNames.PROD_VICA_AI]: BaseAppNames.VICA_AI,
  [KnownHostNames.PROD_NIGHTLIFE_TELEVISION]: BaseAppNames.NIGHTLIFE_TELEVISION,
  [KnownHostNames.PROD_JRR_ADVERTISING]: BaseAppNames.JRR_ADVERTISING,
  [KnownHostNames.PROD_ESTRELLA_MEDIA]: BaseAppNames.ESTRELLA_MEDIA,
  [KnownHostNames.PROD_PERFORMCB]: BaseAppNames.PERFORMCB,
  [KnownHostNames.PROD_NEW_ROAD_ADVERTISING]: BaseAppNames.NEW_ROAD_ADVERTISING,
  [KnownHostNames.PROD_QUICKTURN_MEDIA]: BaseAppNames.QUICKTURN_MEDIA,
  [KnownHostNames.PROD_CONNECTADTV]: BaseAppNames.CONNECTADTV,
  [KnownHostNames.PROD_LILLY_BROADCASTING]: BaseAppNames.LILLY_BROADCASTING
};
// PK TODO (settings): Replace all instances of BASE_APP_NAME with this below
export const getBaseAppName = (user: User): string => {
  if (!user) {
    return BaseAppNames.MADHIVE;
  }
  return user.settings.baseAppName || BaseAppNames.MADHIVE;
};
export const BASE_APP_NAME =
  HOSTNAME_TO_BASE_APP_NAME[window.location.hostname as KnownHostName] ||
  BaseAppNames.MADHIVE;
export const OOH_APOLLO_URI = __PROD__
  ? "https://ooh-planner-server-ap3eghiwlq-uk.a.run.app"
  : "http://localhost:3333";
// SENTRY_DSN is not sensitive from a security perspective
export const SENTRY_DSN =
  "https://c241e6b52894449381313da6caf500a7@o549446.ingest.sentry.io/5672244";
/**
 * Map of hostname to login background color classname.
 */
export const HOSTNAME_TO_BACKGROUND_CLASSNAME: Partial<
  Record<KnownHostNames, string>
> = {
  [KnownHostNames.PROD_MEDIAMATH]: "Smithers--mediamath-background",
  [KnownHostNames.PROD_MADHIVE_DEFAULT]: "Smithers--primary-background",
  [KnownHostNames.PROD_MADHIVE_MEDIA_GROUP]: "Smithers--primary-background",
  [KnownHostNames.TESTING_MADHIVE_DEFAULT]: "Smithers--primary-background",
  [KnownHostNames.PROD_MEDIAMATH_TV_PLATFORM]: "Smithers--mediamath-background"
};

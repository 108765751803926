// eslint-disable-next-line import/prefer-default-export
export const MESSAGE_GETTER_DICTIONARY = {
  GET_ALL_FAILURE: () =>
    "There was an error retrieving the list of available products.",
  ALREADY_UPDATING_FAILURE: () =>
    "Cannot perform multiple updates simultaneously. Please try again once in-flight update has completed.",
  FAILURE_TO_MINE_KEY: () => "Failed to create unique ID for product",
  FAILURE_TO_UPDATE: () => "Failed to update product",
  FAILURE_TO_DELETE: () => "Failed to delete product",
  DELETE_SUCCESS: () => "Product deleted"
};

import { IToastProps } from "@blueprintjs/core";
import { HouseholdsCountType } from "@madhive/mad-sdk";

export const GET_HH_COUNT_PENDING = "GET_HH_COUNT_PENDING";
export const GET_HH_COUNT_SUCCESS = "GET_HH_COUNT_SUCCESS";
export const GET_HH_COUNT_FAILURE = "GET_HH_COUNT_FAILURE";

export interface HHCountByForecastId {
  isLoading: boolean;
  count: number | null;
  error: boolean;
}

export interface HHCountState {
  byForecastId: Record<string, HHCountByForecastId | undefined>;
}

interface GetHHCountForForecastPendingAction {
  type: typeof GET_HH_COUNT_PENDING;
  meta: {
    forecastId: string;
  };
}

interface GetHHCountForForecastSuccessAction {
  type: typeof GET_HH_COUNT_SUCCESS;
  payload: HouseholdsCountType[];
  meta: {
    forecastId: string;
  };
}

interface GetHHCountForForecastFailureAction {
  type: typeof GET_HH_COUNT_FAILURE;
  meta: {
    forecastId: string;
    error: {
      message: string;
    } & IToastProps;
  };
}

export type HHCountActionTypes =
  | GetHHCountForForecastPendingAction
  | GetHHCountForForecastSuccessAction
  | GetHHCountForForecastFailureAction;

import {
  CampaignStatus,
  District,
  DMA,
  GeoDetails,
  GeoTypeKeys,
  Geography,
  GeographyTargets,
  GeographyTargetingDetails,
  GeoType,
  LineItem,
  PostalCode,
  Region
} from "@madhive/mad-sdk";
import { AvailableOption } from "components/Reusable/SmithersMultiSelect/SmithersMultiSelect";
import { LogicalOperator } from "frontier/lib/kit/types";
import { PaceStrategy } from "types";

export enum TargetedGeoZipTableColumns {
  DISTRICT = "district",
  DMA = "dma",
  STATE = "state",
  ZIP_CODE = "zipCode"
}

export type TargetedGeoFieldToColumnMap<T> = {
  [F in keyof T]: TargetedGeoZipTableColumns;
};

export type TargetedGeoZipTableRow = {
  [TargetedGeoZipTableColumns.STATE]: string;
  [TargetedGeoZipTableColumns.DISTRICT]: string;
  [TargetedGeoZipTableColumns.DMA]: string;
  [TargetedGeoZipTableColumns.ZIP_CODE]: string;
};

export const targetedZipCodeFieldToColumnMap: TargetedGeoFieldToColumnMap<PostalCode> =
  {
    code: TargetedGeoZipTableColumns.ZIP_CODE,
    districts: TargetedGeoZipTableColumns.DISTRICT,
    dmas: TargetedGeoZipTableColumns.DMA,
    regions: TargetedGeoZipTableColumns.STATE
  };

export const targetedZipCodeFieldToColumnNameMap: Record<
  TargetedGeoZipTableColumns,
  string
> = {
  [TargetedGeoZipTableColumns.ZIP_CODE]: "ZIP CODE",
  [TargetedGeoZipTableColumns.DMA]: "DMA",
  [TargetedGeoZipTableColumns.STATE]: "STATE",
  [TargetedGeoZipTableColumns.DISTRICT]: "DISTRICT"
};

/**
 *
 * @param lineItem LineItem
 * @returns the LineItem's pacing strategy: either pacing to Impressions, or pacing to Budget
 */
export const getPacingStrategy = (
  lineItem: Pick<LineItem, "budget" | "meta">
): PaceStrategy.BUDGET | PaceStrategy.IMPRESSIONS => {
  if (lineItem?.meta?.bookedPaceStrategy) {
    return lineItem?.meta?.bookedPaceStrategy as PaceStrategy;
  }

  return lineItem.budget ? PaceStrategy.BUDGET : PaceStrategy.IMPRESSIONS;
};

export const isPacingToImpressions = (lineItem: LineItem): boolean => {
  return getPacingStrategy(lineItem) === PaceStrategy.IMPRESSIONS;
};

/** eCPM is not editable if the LineItem's current status is in this list */
export const ecpmNonEditableLineItemStatuses = [
  CampaignStatus.COMPLETED,
  CampaignStatus.CANCELLED,
  CampaignStatus.ARCHIVED
];

/**
 *
 * @param status CampaignStatus
 * @returns true if the LineItem's Budget and Impressions can be edited based on the status. False if it cannot be edited
 */
export const canEditBudgetOrImpressions = (status: CampaignStatus): boolean => {
  const nonEditableStatuses = [
    CampaignStatus.COMPLETED,
    CampaignStatus.ARCHIVED,
    CampaignStatus.CANCELLED
  ];

  if (nonEditableStatuses.includes(status)) {
    return false;
  }
  return true;
};

/**
 * Generates an array of kit-friendly dropdown options for a given country and geo field.
 * @param geoData The geo data returned by mad-sdk.
 * @param country The country (e.g. "US" or "CA").
 * @param field The geo field (e.g. "regions", "districts", "dmas", or "postalCodes").
 * @returns An array of dropdown options.
 */
export const getGeoDropdownOptions = (
  geoData: Geography,
  country: string,
  field: GeoTypeKeys
): AvailableOption[] => {
  return (
    geoData
      .get(country)
      ?.[field].data.map((item: GeoType) => {
        if (field === "postalCodes") {
          return {
            id: (item as PostalCode).code,
            name: (item as PostalCode).code
          };
        }

        return {
          id:
            field === "districts"
              ? (item as District).id
              : (item as DMA | Region).code,
          name:
            field === "districts"
              ? `${(item as District).regions} ${(item as District).name}`
              : (item as DMA | Region).name
        };
      })
      .sort((a: AvailableOption, b: AvailableOption) =>
        a.name.localeCompare(b.name)
      ) || []
  );
};

/**
 * Returns the zip codes that are being targeted by a line item, given the user-selected "include" and "exclude" values for each geo type.
 * Each zip code is represented as an object with human-readable values, specifically, an object that contains a field
 * ... for each geo type that is related to that zip code (e.g. "district", "DMA", and "region").
 * @param geoTargeting The user-selected "include" and "exclude" values for each geo type.
 * @param geoDetails The geo details for the currently selected country.
 * @returns An array of zip code objects (aka "rows") that can be used to populate UI tables or CSV files.
 */
export const getGeoTargetingTableRows = (
  geoTargeting: GeographyTargetingDetails,
  geoDetails?: GeoDetails
): TargetedGeoZipTableRow[] => {
  let targetedZips = new Set<string>();

  if (!geoDetails) return [];

  // Step 1: Loop through each geo type and add the included zip codes to a set
  for (const entry of Object.entries(geoTargeting.include)) {
    const geoType = entry[0] as keyof GeographyTargets;
    const included = entry[1];

    if (geoType === "country") continue;

    for (const geoId of included) {
      const zipsToAdd =
        geoType === "postalCodes"
          ? [geoDetails[geoType].get(geoId).code]
          : geoDetails[geoType].get(geoId).postalCodes;

      targetedZips = new Set([...targetedZips, ...zipsToAdd]);
    }
  }

  // Step 2: If no geo types are explicitly included, then we'll include all of the country's zip codes by default
  if (!targetedZips.size) {
    targetedZips = new Set(geoDetails.postalCodes.values);
  }

  // Step 3: Next, loop through each geo type and remove the excluded zip codes from the set
  for (const entry of Object.entries(geoTargeting.exclude)) {
    const geoType = entry[0] as keyof GeographyTargets;
    const excluded = entry[1];

    for (const geoId of excluded) {
      if (geoType === "country") continue;

      const zipsToDelete =
        geoType === "postalCodes"
          ? [geoDetails[geoType].get(geoId).code]
          : geoDetails[geoType].get(geoId).postalCodes;

      for (const postalCode of zipsToDelete) {
        targetedZips.delete(postalCode);
      }
    }
  }

  // Step 4: Convert the array of zip code numbers into an array of PostalCode objects
  return [...targetedZips].map(zipCode => {
    const zipCodeDataWithIds = geoDetails.postalCodes.get(zipCode);
    const zipCodeDataWithNames = {} as TargetedGeoZipTableRow;

    // Step 5: Finally, convert each PostalCode object into one that contains human-readable values
    for (const entry of Object.entries(zipCodeDataWithIds)) {
      const geoType = entry[0] as keyof PostalCode;
      const geoLocations = entry[1];

      if (!Object.keys(targetedZipCodeFieldToColumnMap).includes(geoType))
        continue;

      const geoIds = Array.isArray(geoLocations)
        ? geoLocations
        : [geoLocations];

      const columnName = targetedZipCodeFieldToColumnMap[geoType];

      if (!geoIds.length) {
        zipCodeDataWithNames[columnName] = "No Data";
        continue;
      }

      zipCodeDataWithNames[columnName] =
        geoType === "code"
          ? (geoIds.pop()?.padStart(5, "0") as string)
          : // this future-proofs the client-side code for if and when zip codes are associated with multiple geo types
            // ... e.g. if a given zip code spans multiple states
            // ... which currently occurs in real life, but is not (yet) represented in our geo data model
            geoIds.map(geoId => geoDetails[geoType].get(geoId).name).join(", ");
    }

    return zipCodeDataWithNames;
  });
};

/**
 * Gets the operator (either "include" or "exclude") currently being used by a given geography field.
 * @param field The geography field being updated.
 * @param existingGeoTargetingDetails The existing geography targeting details.
 * @returns An object that can be passed into the change() function.
 */
export const getCurrentOperator = (
  field: keyof GeographyTargets,
  existingGeoTargetingDetails?: GeographyTargetingDetails
): LogicalOperator => {
  return existingGeoTargetingDetails &&
    existingGeoTargetingDetails.exclude[field].length
    ? LogicalOperator.EXCLUDE
    : LogicalOperator.INCLUDE;
};

/**
 * Encapsulates the logic for generating the correct data to pass into the useLineItem hook's change() function
 * ... whenever the field value is changed. It's meant to help keep the markup clean and human-readable.
 * @param field The geography field being updated.
 * @param values The value(s) selected by the user.
 * @param existingGeoTargetingDetails The existing geography targeting details.
 * @returns An object that can be passed into the change() function.
 */
export const getChanges = (
  field: keyof GeographyTargets,
  values: Array<string | number>,
  existingGeoTargetingDetails: GeographyTargetingDetails
): {
  geoTargeting: GeographyTargetingDetails;
} => {
  const operator = getCurrentOperator(field, existingGeoTargetingDetails);

  if (!values.length) {
    return {
      geoTargeting: {
        ...existingGeoTargetingDetails,

        [operator]: {
          ...existingGeoTargetingDetails[operator],
          [field]: []
        }
      }
    };
  }

  return {
    geoTargeting: {
      ...existingGeoTargetingDetails,

      [operator]: {
        ...existingGeoTargetingDetails[operator],

        [field]: values
      }
    }
  };
};

/**
 * Encapsulates the logic for generating the correct data to pass into the useLineItem hook's change() function
 * ... whenever the operator is changed. It's meant to help keep the markup clean and human-readable.
 * @param field The geography field being updated.
 * @param operator The logical operator selected by the user.
 * @param existingGeoTargetingDetails The existing geography targeting details.
 * @returns An object that can be passed into the change() function.
 */
export const getOperatorChanges = (
  field: keyof GeographyTargets,
  operator: LogicalOperator,
  existingGeoTargetingDetails: GeographyTargetingDetails
): {
  geoTargeting: GeographyTargetingDetails;
} => {
  const oldOperator =
    operator === LogicalOperator.INCLUDE
      ? LogicalOperator.EXCLUDE
      : LogicalOperator.INCLUDE;

  const changes = {
    geoTargeting: {
      ...existingGeoTargetingDetails,

      [operator]: {
        ...existingGeoTargetingDetails[operator],
        [field]: existingGeoTargetingDetails[oldOperator][field]
      },

      [oldOperator]: {
        ...existingGeoTargetingDetails[oldOperator],
        [field]: []
      }
    }
  };

  // this checks to see if there's still at least one other non-country geo field being excluded
  // ... if there isn't, we need to clear the country value from the exclude object
  // ... otherwise the BE API will thrown an error, as you cannot exclude an entire country
  if (operator === LogicalOperator.INCLUDE) {
    const hasNonCountryGeosExcluded = Object.entries(
      changes.geoTargeting.exclude
    ).some(([key, value]) => {
      if (key === "country") {
        return false;
      }

      return !!value.length;
    });

    if (!hasNonCountryGeosExcluded) {
      changes.geoTargeting.exclude.country = "";
    }
  }

  // we cannot exclude any geo fields without specifying a country
  // ... so this ensures that one is populated
  if (operator === LogicalOperator.EXCLUDE) {
    changes.geoTargeting.exclude.country =
      existingGeoTargetingDetails.include.country;
  }

  return changes;
};

/**
 * Removes any duplicate or invalid zip codes from a given array of zip code values.
 * @param zipCodes An array of zip codes.
 * @param geoDetails The geo details for the currently selected country.
 * @returns A new array of zip codes, with any duplicate or invalid ones removed.
 */
export const sanitizeZipCodes = (
  zipCodes: string[],
  geoDetails?: GeoDetails
): string[] => {
  const dedupedZips = Array.from(new Set(zipCodes));

  if (!geoDetails) return dedupedZips;

  return dedupedZips.filter(zipCode => !!geoDetails.postalCodes.get(zipCode));
};

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Dashboard: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-dashboard"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V6.5C11.25 6.91421 11.5858 7.25 12 7.25C12.4142 7.25 12.75 6.91421 12.75 6.5V6Z" />
      <path d="M8.28765 7.22702C7.99476 6.93413 7.51989 6.93413 7.22699 7.22702C6.9341 7.51992 6.9341 7.99479 7.22699 8.28768L7.58054 8.64123C7.87344 8.93413 8.34831 8.93413 8.6412 8.64123C8.9341 8.34834 8.9341 7.87347 8.6412 7.58057L8.28765 7.22702Z" />
      <path d="M5.25 12C5.25 11.5858 5.58579 11.25 6 11.25H6.5C6.91421 11.25 7.25 11.5858 7.25 12C7.25 12.4142 6.91421 12.75 6.5 12.75H6C5.58579 12.75 5.25 12.4142 5.25 12Z" />
      <path d="M16.75 12C16.75 11.5858 17.0858 11.25 17.5 11.25H18C18.4142 11.25 18.75 11.5858 18.75 12C18.75 12.4142 18.4142 12.75 18 12.75H17.5C17.0858 12.75 16.75 12.4142 16.75 12Z" />
      <path d="M16.773 8.28769C17.0659 7.9948 17.0659 7.51993 16.7731 7.22703C16.4802 6.93413 16.0053 6.93413 15.7124 7.22702L15.3588 7.58057C15.0659 7.87346 15.0659 8.34833 15.3588 8.64123C15.6517 8.93412 16.1266 8.93413 16.4195 8.64124L16.773 8.28769Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.2408 15.1181C21.571 14.1391 21.75 13.0904 21.75 12C21.75 6.61522 17.3848 2.25 12 2.25C6.61522 2.25 2.25 6.61522 2.25 12C2.25 13.0904 2.42901 14.1391 2.75925 15.1181C2.77373 15.2097 2.80479 15.2957 2.84924 15.3731C4.22185 19.0955 7.80093 21.75 12 21.75C16.1991 21.75 19.7782 19.0955 21.1508 15.3731C21.1952 15.2957 21.2263 15.2097 21.2408 15.1181ZM12 3.75C7.44365 3.75 3.75 7.44365 3.75 12C3.75 12.78 3.85825 13.5348 4.06055 14.25H11.25V10C11.25 9.58579 11.5858 9.25 12 9.25C12.4142 9.25 12.75 9.58579 12.75 10V14.25H19.9395C20.1417 13.5348 20.25 12.78 20.25 12C20.25 7.44365 16.5563 3.75 12 3.75ZM19.3504 15.75H4.64956C6.0151 18.4213 8.79399 20.25 12 20.25C15.206 20.25 17.9849 18.4213 19.3504 15.75Z"
      />
    </svg>
  );
};

export default Dashboard;

import { FocusStyleManager } from "@blueprintjs/core";
import {
  __PROD__,
  DATE_OF_CURRENT_RELEASE,
  BASE_APP_NAME,
  __DEV__
} from "lib/constants/config";
import { prettyPrintDate } from "lib/utils/date";
import { hijackEffects } from "stop-runaway-react-effects";
import { madSDK } from "lib/sdk";
import { madTracker } from "frontier/lib/tracker";
import { initializeSentry } from "./sentry";
import { trackPageLoadTime } from "./heap";
import { initializeStyles } from "./styles";

export const initializeSideEffects = async () => {
  /**
   * Only show focus ring when tabbing. See: https://blueprintjs.com/docs/#core/accessibility.focus-management
   */
  FocusStyleManager.onlyShowFocusOnTabs();

  if (__DEV__) {
    hijackEffects({ callCount: 1000, timeLimit: 1000 }); // See https://github.com/kentcdodds/stop-runaway-react-effects
  }

  if (__PROD__) {
    // eslint-disable-next-line
    console.info(
      `%c${BASE_APP_NAME} - current version release date: ${prettyPrintDate(
        DATE_OF_CURRENT_RELEASE
      )}`,
      "background: black; color: white; padding: 15px"
    );
  }

  madSDK.authentication.currentAccount.subscribe(account => {
    if (account?.user) {
      // The current user is automatically set on the mad-tracker instance.
      madTracker.user.set(account.user.id, account.user);
      trackPageLoadTime();
    } else if (madTracker.user.get()) {
      madTracker.user.reset();
    }
  });

  initializeSentry();

  return new Promise<void>(resolve => {
    initializeStyles().then(() => {
      resolve();
    });
  });
};

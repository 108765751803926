import { ReactText, HTMLAttributes, ReactElement } from "react";
import { MhBaseProps } from "../types";

export enum TextColor {
  DEFAULT = "var(--text-color)",
  LIGHT = "var(--text-color-light)",
  ERROR = "var(--indicator-bad)",
  INHERIT = "inherit",
  PRIMARY = "var(--primary-color)",
  SECONDARY = "var(--secondary-color)",
  TOOLTIP = "var(--white)",
  BLACK = "var(--black)",
  DESCRIPTION_TEXT = "var(--gray-5)",
  LIGHT_GRAY = "var(--gray-4)"
}

export enum TextVariant {
  BODY = "p",
  BODY_2 = "p2",
  MICRO = "span",
  H_ONE = "h1",
  H_TWO = "h2",
  H_THREE = "h3"
}

export enum TitleVariant {
  MAIN = "main",
  SUB = "sub"
}

export const TEXT_VARIANT_TO_FONT_SIZE: Record<TextVariant, string> = {
  [TextVariant.MICRO]: "var(--font-size-8)",
  [TextVariant.BODY]: "var(--font-size-12)",
  [TextVariant.BODY_2]: "var(--font-size-14)",
  [TextVariant.H_ONE]: "var(--font-size-32)",
  [TextVariant.H_TWO]: "var(--font-size-24)",
  [TextVariant.H_THREE]: "var(--font-size-16)"
};

type ReactTextOrNull = ReactText | null | ReactElement;

interface TypographyBaseProps {
  children: ReactTextOrNull | ReactTextOrNull[];
  variant?: TextVariant;
  color?: TextColor;
  italic?: boolean;
  bold?: boolean;
  underline?: boolean;
  uppercase?: boolean;
  capitalize?: boolean;
  ellipsize?: boolean;
}

type OmitChildren<T> = Omit<T, "children">;

type Paragraph = {
  asElement?: "p";
} & OmitChildren<HTMLAttributes<HTMLParagraphElement>>;

type Span = {
  asElement: "span";
} & OmitChildren<HTMLAttributes<HTMLSpanElement>>;

type Header = {
  asElement: "h6" | "h5" | "h4" | "h3" | "h2" | "h1";
} & OmitChildren<HTMLAttributes<HTMLHeadingElement>>;

type TypographyHTMLTagProps = Paragraph | Span | Header;

type ComponentSpecificProps = TypographyBaseProps & TypographyHTMLTagProps;

export type TypographyProps = MhBaseProps<ComponentSpecificProps>;

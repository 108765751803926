import axios from "axios";
export default class ZipcodesInRadius {
    // eslint-disable-next-line no-useless-constructor,no-empty-function
    constructor(sdk) {
        this.sdk = sdk;
    }
    async find(coords, radius) {
        const res = await axios.get(`${this.sdk.urls.burnsBaseUrl}/zipcodes-in-radius?radius=${radius}&latitude=${coords.latitude}&longitude=${coords.longitude}`);
        return res.data;
    }
}

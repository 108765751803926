import { Station } from "@madhive/mad-sdk";

export const GET_AVAILABLE_STATIONS_PENDING = "GET_AVAILABLE_STATIONS_PENDING";
export const GET_AVAILABLE_STATIONS_SUCCESS = "GET_AVAILABLE_STATIONS_SUCCESS";
export const GET_AVAILABLE_STATIONS_FAILURE = "GET_AVAILABLE_STATIONS_FAILURE";

export const CREATE_STATION_PENDING = "CREATE_STATION_PENDING";
export const CREATE_STATION_SUCCESS = "CREATE_STATION_SUCCESS";
export const CREATE_STATION_FAILURE = "CREATE_STATION_FAILURE";

export const UPDATE_STATION_PENDING = "UPDATE_STATION_PENDING";
export const UPDATE_STATION_SUCCESS = "UPDATE_STATION_SUCCESS";
export const UPDATE_STATION_FAILURE = "UPDATE_STATION_FAILURE";

export const ABORT_STATION_REQUEST = "ABORT_STATION_REQUEST";

export interface StationState {
  readonly byId: Record<string, Station>;
  readonly isLoading: boolean;
  readonly isSaving: boolean;
  readonly isLoaded: boolean;
  readonly updatingIds: string[];
  readonly error: string | null;
}

interface GetAvailableStationsPendingAction {
  type: typeof GET_AVAILABLE_STATIONS_PENDING;
}

interface GetAvailableStationsSuccessAction {
  type: typeof GET_AVAILABLE_STATIONS_SUCCESS;
  payload: Station[];
}

interface GetAvailableStationsFailureAction {
  type: typeof GET_AVAILABLE_STATIONS_FAILURE;
  meta: {
    error: string;
  };
}

interface CreateStationPendingAction {
  type: typeof CREATE_STATION_PENDING;
}

interface CreateStationSuccessAction {
  type: typeof CREATE_STATION_SUCCESS;
  payload: Station;
}

interface CreateStationFailureAction {
  type: typeof CREATE_STATION_FAILURE;
  meta: {
    error: string;
  };
}

interface UpdateStationPendingAction {
  type: typeof UPDATE_STATION_PENDING;
  meta: {
    id: string;
  };
}

interface UpdateStationSuccessAction {
  type: typeof UPDATE_STATION_SUCCESS;
  payload: Station;
}

interface UpdateStationFailureAction {
  type: typeof UPDATE_STATION_FAILURE;
  meta: {
    error: string;
  };
}

interface AbortStationRequestAction {
  type: typeof ABORT_STATION_REQUEST;
}

export type StationsActionTypes =
  | GetAvailableStationsPendingAction
  | GetAvailableStationsSuccessAction
  | GetAvailableStationsFailureAction
  | CreateStationPendingAction
  | CreateStationSuccessAction
  | CreateStationFailureAction
  | UpdateStationPendingAction
  | UpdateStationSuccessAction
  | UpdateStationFailureAction
  | AbortStationRequestAction;

import { FC } from "react";
import { CommonIconProps } from "./types";

const CheckList: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, none)",
  stroke = "var(--icon-stroke-color, var(--color-primary))"
}) => {
  return (
    <svg
      className="icon-check-list"
      width="100%"
      height="100%"
      viewBox="0 0 17 17"
      fill={fill}
      stroke={stroke}
    >
      <path d="M7.5235 3.3314H14.1929" />
      <path d="M2.52142 2.33093L4.52226 4.33177" />
      <path d="M2.52142 4.33177L4.52226 2.33093" />
      <path d="M7.5235 7.99998H14.1929" />
      <path d="M7.5235 12.6687H14.1929" />

      <path d="M4.18882 9.33379H2.85493C2.48659 9.33379 2.18799 9.03519 2.18799 8.66685V7.33296C2.18799 6.96462 2.48659 6.66602 2.85493 6.66602H4.18882C4.55716 6.66602 4.85577 6.96462 4.85577 7.33296V8.66685C4.85577 9.03519 4.55716 9.33379 4.18882 9.33379Z" />
      <path d="M4.18882 14.0025H2.85493C2.48659 14.0025 2.18799 13.7039 2.18799 13.3356V12.0017C2.18799 11.6333 2.48659 11.3347 2.85493 11.3347H4.18882C4.55716 11.3347 4.85577 11.6333 4.85577 12.0017V13.3356C4.85577 13.7039 4.55716 14.0025 4.18882 14.0025Z" />
    </svg>
  );
};

export default CheckList;

export * from "./objects";
export * from "./params";
export * from "./sort";
/**
 * filters out falsy id values to determine if an id filter is present, but empty.
 * @param whereFilters: array of where filters.
 * @return: `true` if there is an id filter present but no valid string ids — otherwise `false`.
 */
export const filtersHasEmptyIdParam = (whereFilters = []) => !!whereFilters.find(({ field, value }) => {
    if (field === "id") {
        if (typeof value === "undefined") {
            return true;
        }
        if ((typeof value === "string" || Array.isArray(value)) && value.length === 0) {
            return true;
        }
    }
    return false;
});

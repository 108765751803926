import axios, { CancelTokenSource } from "axios";
import { dateDisplayTime } from "lib/utils/date";
import { BASE_APP_NAME } from "lib/constants/config";
import { downloadBlob } from "frontier/lib/utils/files";
import { logErrorToStackdriver } from "./logErrors";

export const downloadCSVReport = async (
  reportPath: string,
  cancellationToken: CancelTokenSource,
  customFileName = ""
) => {
  try {
    await axios
      .get(`${reportPath}`, {
        cancelToken: cancellationToken.token,
        responseType: "blob",
        withCredentials: false
      })
      .then(({ data }) => {
        const now = dateDisplayTime(new Date());
        const fileTitle = customFileName || `${BASE_APP_NAME}-${now}`;

        downloadBlob(new Blob([data]), `${fileTitle}.csv`);
      });
  } catch (error) {
    logErrorToStackdriver(error);
    throw error;
  }
};

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Trash: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-trash"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M10 2.25a.75.75 0 0 0 0 1.5h4a.75.75 0 0 0 0-1.5h-4ZM14 9.25a.75.75 0 0 1 .75.75v7a.75.75 0 0 1-1.5 0v-7a.75.75 0 0 1 .75-.75ZM10.75 10a.75.75 0 0 0-1.5 0v7a.75.75 0 0 0 1.5 0v-7Z" />
      <path
        fillRule="evenodd"
        d="M3.75 6a.75.75 0 0 1 .75-.75h15a.75.75 0 0 1 0 1.5h-.75v12c0 1.666-1.371 3-3.019 3h-7.5a2.983 2.983 0 0 1-2.981-3v-12H4.5A.75.75 0 0 1 3.75 6Zm3 12.75v-12h10.5v12c0 .82-.683 1.5-1.519 1.5h-7.5c-.82 0-1.481-.663-1.481-1.5Z"
      />
    </svg>
  );
};

export default Trash;

/* eslint-disable camelcase */
import { LinearDimensionBase, OTTDimensionBase } from "../../utils/dimensions";
export const OTTDimensionList = {
    ...OTTDimensionBase
};
// Display dimensions base are the same as OTTDimensionBase
export const DisplayDimensionList = {
    ...OTTDimensionBase
};
export const LinearDimensionList = {
    ...LinearDimensionBase
};
export var CustomReportStatus;
(function (CustomReportStatus) {
    CustomReportStatus["IN_PROGRESS"] = "RUNNING";
    CustomReportStatus["DONE"] = "DONE";
    CustomReportStatus["ERROR"] = "FAILED";
    CustomReportStatus["TOO_LARGE"] = "TOO_LARGE";
})(CustomReportStatus || (CustomReportStatus = {}));
export var CustomReportType;
(function (CustomReportType) {
    // legacy custom reporting
    CustomReportType["OTT"] = "ott";
    CustomReportType["LINEAR"] = "linear";
    CustomReportType["LINEAR_AND_OTT"] = "ott_linear";
    CustomReportType["DISPLAY"] = "display";
    // new custom reporting
    CustomReportType["OTT_V1"] = "ott_v1";
    CustomReportType["LINEAR_ADIMPACT"] = "linear_adimpact";
    CustomReportType["LINEAR_AND_OTT_ADIMPACT"] = "ott_linear_adimpact";
    CustomReportType["DISPLAY_V1"] = "display_v1";
})(CustomReportType || (CustomReportType = {}));
/**
 * Converts a ServiceCustomReport object to a standard CustomReport
 * @param {ServiceCustomReport} report
 * @returns {CustomReport}
 */
export const serviceCustomReportToCustomReport = (report) => {
    return {
        email: report.email,
        errors: report.errors || "",
        campaignNames: report.campaign_names,
        fullReports: report.full_reports || {},
        jobID: report.job_id,
        org: report.org,
        parameters: {
            advertiser: report.parameters.advertiser,
            beacons: report.parameters.beacons || [],
            campaigns: report.parameters.campaigns || [],
            dimensions: report.parameters.dimensions,
            dmas: report.parameters.dmas,
            endDate: report.parameters.end_date,
            linearCommercials: report.parameters.commercials,
            linearDimensions: report.parameters.linear_dimensions,
            lookback: report.parameters.lookback,
            startDate: report.parameters.start_date,
            timezone: report.parameters.timezone
        },
        queryDuration: report.query_duration || 0,
        queryFinished: report.query_finished || 0,
        queryStarted: report.query_started || 0,
        rawQuery: report.raw_query || "",
        resultKey: report.result_key,
        status: report.job_status,
        reportType: getServiceCustomReportType(report)
    };
};
const getReportType = ({ hasOttData, hasLinearData, isUsingAdImpactData }) => {
    if (hasOttData && hasLinearData) {
        if (isUsingAdImpactData) {
            return CustomReportType.LINEAR_AND_OTT_ADIMPACT;
        }
        return CustomReportType.LINEAR_AND_OTT;
    }
    if (hasLinearData) {
        if (isUsingAdImpactData) {
            return CustomReportType.LINEAR_ADIMPACT;
        }
        return CustomReportType.LINEAR;
    }
    if (hasOttData) {
        return CustomReportType.OTT;
    }
    return CustomReportType.DISPLAY;
};
export const getServiceCustomReportType = (report) => {
    // report.report_type is not being saved yet and previous reports will not have them set.
    // For reports that do not have report_type set we need to verify it according to the fields that are set
    // Reports will have report_type set as of the addition of Display type.
    if (report.report_type) {
        return report.report_type;
    }
    const hasOttData = report.report_type !== CustomReportType.DISPLAY && report.parameters.campaigns
        ? report.parameters.campaigns.length > 0
        : false;
    const hasLinearData = report.parameters.commercials.length > 0;
    const isUsingAdImpactData = report.report_type === CustomReportType.LINEAR_ADIMPACT ||
        report.report_type === CustomReportType.LINEAR_AND_OTT_ADIMPACT;
    return getReportType({ hasOttData, hasLinearData, isUsingAdImpactData });
};
function toLowerCaseKeys(obj) {
    return Object.keys(obj).reduce((acc, key) => {
        // eslint-disable-next-line no-param-reassign
        acc[`${key.charAt(0).toLowerCase()}${key.slice(1)}`] = obj[key];
        return acc;
    }, {});
}
/**
 * Converts a FirestoreCustomReport object to a standard CustomReport
 * @param {FirestoreCustomReport} report
 * @returns {CustomReport}
 */
export const firestoreCustomReportToCustomReport = (report) => {
    const parameters = {
        advertiser: report.Parameters.Advertiser,
        beacons: report.Parameters.Beacons,
        campaigns: report.Parameters.Campaigns,
        dimensions: report.Parameters.Dimensions,
        dmas: report.Parameters.DMAs,
        endDate: report.Parameters.EndDate,
        linearCommercials: report.Parameters.LinearCommercials,
        linearDimensions: report.Parameters.LinearDimensions,
        lookback: report.Parameters.Lookback,
        startDate: report.Parameters.StartDate,
        timezone: report.Parameters.Timezone
    };
    const parsedReport = toLowerCaseKeys(report);
    return {
        ...parsedReport,
        parameters,
        reportType: getFirestoreCustomReportType(report)
    };
};
export const getFirestoreCustomReportType = (report) => {
    // report.report_type is not being saved yet and previous reports will not have them set.
    // For reports that do not have report_type set we need to verify it according to the fields that are set
    // Reports will have report_type set as of the addition of Display type.
    if (report.ReportType) {
        return report.ReportType;
    }
    const hasOttData = report.ReportType !== CustomReportType.DISPLAY && report.Parameters.Campaigns.length > 0;
    const hasLinearData = report.Parameters.LinearCommercials.length > 0;
    return getReportType({ hasOttData, hasLinearData });
};
export const customReportBaseToServiceCustomReportBase = (report, isConversionBlacklistFilteringEnabled) => {
    return {
        advertiser: report.advertiser,
        campaigns: report.campaigns,
        beacons: report.beacons,
        commercials: report.commercials,
        dimensions: report.dimensions,
        dmas: report.dmas,
        start_date: report.startDate,
        end_date: report.endDate,
        timezone: report.timezone,
        lookback: report.lookback,
        linear_dimensions: report.linearDimensions,
        report_type: report.reportType,
        conversions_blacklist: isConversionBlacklistFilteringEnabled ? true : false
    };
};
const OVERLAP_PLATFORMS = [
    "OTT Only",
    "Both OTT & Linear",
    "Linear Only"
];
// values from the overlap report not currently used in frontend code
const UNUSED_OVERLAP_VALUES = {
    dma: "--",
    households: "0",
    m_universe: "0",
    multiplier: 0
};
/**
 * In the rare event that an overlap report has no overlap
 * between OTT and linear, sometimes no overlap report data
 * is available; the file is just a blank csv or empty json.
 * In this case, we populate a 'blank' overlap report using
 * the available OTT and linear topline data.
 * @param ottTopline: topline data for a ott-only report
 * @param linearTopline: topline data for a linear-only report
 * @return an overlap report with populated, valid OTT-only and linear-only topline data, and zeroed overlap data.
 * */
export const makeZeroedOverlap = (ottTopline, linearTopline) => {
    return OVERLAP_PLATFORMS.map((platform) => {
        if (platform === "OTT Only") {
            return {
                ...UNUSED_OVERLAP_VALUES,
                actual_hhs: ottTopline.ott_households.toString(),
                actual_imps: ottTopline.total_impressions.toString(),
                conversion_rate: (ottTopline.ott_total_unique_conversions / ottTopline.ott_households).toString(),
                exposure_type: platform,
                // nielson hh comes from linear report
                hh: linearTopline.nielson_hh,
                linear_impressions: linearTopline.linear_impressions.toString(),
                ott_impressions: ottTopline.ott_impressions,
                /**
                 * in the case of zero overlap, projected values must be the
                 * same as 'actual' values
                 * */
                projected_hh: ottTopline.ott_households,
                projected_imp: ottTopline.ott_impressions.toString()
            };
        }
        if (platform === "Linear Only") {
            return {
                ...UNUSED_OVERLAP_VALUES,
                actual_hhs: linearTopline.linear_households.toString(),
                actual_imps: linearTopline.total_impressions.toString(),
                conversion_rate: (linearTopline.linear_total_unique_conversions / linearTopline.linear_households).toString(),
                exposure_type: platform,
                hh: linearTopline.nielson_hh,
                linear_impressions: linearTopline.linear_impressions.toString(),
                ott_impressions: ottTopline.ott_impressions,
                /**
                 * in the case of zero overlap, projected values must be the
                 * same as 'actual' values
                 * */
                projected_hh: linearTopline.linear_households,
                projected_imp: linearTopline.linear_impressions.toString()
            };
        }
        return {
            ...UNUSED_OVERLAP_VALUES,
            actual_hhs: "0",
            actual_imps: "0",
            conversion_rate: "0",
            exposure_type: platform,
            hh: "0",
            linear_impressions: "0",
            ott_impressions: 0,
            projected_hh: 0,
            projected_imp: "0"
        };
    });
};

const Burst = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-burst"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 5.46611C12.5 5.33857 12.3636 5.25854 12.2524 5.3204L7.58549 7.91318C7.53321 7.94221 7.5 7.99812 7.5 8.05877V13.2001C7.5 13.3264 7.63603 13.4075 7.74785 13.3457C7.74776 13.3457 7.74794 13.3456 7.74785 13.3457L12.4136 10.7535C12.4135 10.7536 12.4137 10.7535 12.4136 10.7535C12.4673 10.7234 12.5 10.6672 12.5 10.6074V5.46611ZM11.7665 4.44636C12.5446 4.01378 13.5 4.57639 13.5 5.46611V10.6074C13.5 11.0316 13.2698 11.4208 12.9011 11.6267L12.9002 11.6272L8.23349 14.2199C7.45603 14.6511 6.5 14.0897 6.5 13.2001V8.05877C6.5 7.63547 6.72953 7.24469 7.09984 7.03903M7.09984 7.03903L11.7665 4.44636L7.09984 7.03903Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4348 2.75252C11.5711 2.99264 11.487 3.29784 11.2469 3.43422L5.58426 6.65022L5.58345 6.65067C5.53233 6.67958 5.5 6.73425 5.5 6.79477V12.3328C5.5 12.6089 5.27614 12.8328 5 12.8328C4.72386 12.8328 4.5 12.6089 4.5 12.3328V6.79477C4.5 6.37549 4.7248 5.98766 5.0907 5.7805C5.09053 5.7806 5.09087 5.7804 5.0907 5.7805L10.7531 2.56466C10.9932 2.42829 11.2984 2.5124 11.4348 2.75252Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.43709 1.4233C9.57119 1.6647 9.4842 1.9691 9.2428 2.1032L3.58642 5.24533C3.58633 5.24539 3.58652 5.24528 3.58642 5.24533C3.53266 5.27549 3.5 5.33167 3.5 5.39144V10.3328C3.5 10.6089 3.27614 10.8328 3 10.8328C2.72386 10.8328 2.5 10.6089 2.5 10.3328V5.39144C2.5 4.96731 2.73018 4.5781 3.09891 4.37222L8.7572 1.22902C8.99859 1.09492 9.30299 1.18191 9.43709 1.4233Z"
      />
    </svg>
  );
};

export default Burst;

import { ReactNode, HTMLAttributes } from "react";
import { MhBaseProps } from "../types";

export enum PersistentBannerVariant {
  INFO = "info",
  WARNING = "warning",
  SUCCESS = "success",
  DANGER = "danger"
}

export interface PersistentBottomBannerProps {
  message: string | ReactNode;
  variant: PersistentBannerVariant;
  action?: ReactNode;
  id: string;
}

export type BannerProps = MhBaseProps<
  HTMLAttributes<HTMLDivElement> & PersistentBottomBannerProps
>;

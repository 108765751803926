/* eslint-disable no-plusplus, no-bitwise */
import { ObjType } from "../types";
export class Cryptography {
    constructor(crypto) {
        this.uint6ToB64 = (nUint6) => {
            if (nUint6 < 26) {
                return nUint6 + 65;
            }
            if (nUint6 < 52) {
                return nUint6 + 71;
            }
            if (nUint6 < 62) {
                return nUint6 - 4;
            }
            if (nUint6 === 62) {
                return 43;
            }
            if (nUint6 === 63) {
                return 47;
            }
            return 65;
        };
        this.b64ToUint6 = (nChr) => {
            if (nChr > 64 && nChr < 91) {
                return nChr - 65;
            }
            if (nChr > 96 && nChr < 123) {
                return nChr - 71;
            }
            if (nChr > 47 && nChr < 58) {
                return nChr + 4;
            }
            if (nChr === 43) {
                return 62;
            }
            if (nChr === 47) {
                return 63;
            }
            return 0;
        };
        this.keytype = (c) => {
            return this.crypto.subtle.digest("SHA-256", c).then((digestValue) => {
                return new Uint8Array(digestValue)[31];
            });
        };
        this.base64EncArr = (aBytes) => {
            const eqLen = (3 - (aBytes.length % 3)) % 3;
            let sB64Enc = "";
            for (let nMod3, nLen = aBytes.length, nUint24 = 0, nIdx = 0; nIdx < nLen; nIdx++) {
                nMod3 = nIdx % 3;
                nUint24 |= aBytes[nIdx] << ((16 >>> nMod3) & 24);
                if (nMod3 === 2 || aBytes.length - nIdx === 1) {
                    sB64Enc += String.fromCharCode(this.uint6ToB64((nUint24 >>> 18) & 63), this.uint6ToB64((nUint24 >>> 12) & 63), this.uint6ToB64((nUint24 >>> 6) & 63), this.uint6ToB64(nUint24 & 63));
                    nUint24 = 0;
                }
            }
            return eqLen === 0
                ? sB64Enc
                : sB64Enc.substring(0, sB64Enc.length - eqLen) + (eqLen === 1 ? "=" : "==");
        };
        this.base64DecToArr = (sBase64, nBlockSize) => {
            /**
             * Can't use native browser's `TextEncoder` API for decoding base64 encoded strings
             * due to the "Unicode" problem outlined in below source.
             *
             * MDN Source: https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
             */
            // eslint-disable-next-line no-useless-escape
            const sB64Enc = sBase64.replace(/[^A-Za-z0-9\+\/]/g, "");
            const nInLen = sB64Enc.length;
            const nOutLen = nBlockSize
                ? Math.ceil(((nInLen * 3 + 1) >>> 2) / nBlockSize) * nBlockSize
                : (nInLen * 3 + 1) >>> 2;
            const aBytes = new Uint8Array(nOutLen);
            for (let nMod3, nMod4, nUint24 = 0, nOutIdx = 0, nInIdx = 0; nInIdx < nInLen; nInIdx++) {
                nMod4 = nInIdx & 3;
                nUint24 |= this.b64ToUint6(sB64Enc.charCodeAt(nInIdx)) << (18 - 6 * nMod4);
                if (nMod4 === 3 || nInLen - nInIdx === 1) {
                    for (nMod3 = 0; nMod3 < 3 && nOutIdx < nOutLen; nMod3++, nOutIdx++) {
                        aBytes[nOutIdx] = (nUint24 >>> ((16 >>> nMod3) & 24)) & 255;
                    }
                    nUint24 = 0;
                }
            }
            return aBytes;
        };
        this.mintKey = async (ktype) => {
            // eslint-disable-next-line no-restricted-globals
            if (typeof ktype !== "number" || isNaN(ktype) || ktype < 0 || ktype > 128) {
                return;
            }
            const c = new Uint8Array(21);
            let enc;
            for (let i = 0; i < 10000; i++) {
                this.crypto.getRandomValues(c);
                enc = this.base64EncArr(c);
                if (enc.indexOf("+") < 0 && enc.indexOf("/") < 0) {
                    const p = await this.keytype(c).then((val) => val === ktype);
                    if (p) {
                        break;
                    }
                }
            }
            return enc;
        };
        this.getOrganizationTypeFromOrgKey = async (key) => {
            const b = this.base64DecToArr(key);
            const digestValue = await this.crypto.subtle.digest("SHA-256", b);
            return new Uint8Array(digestValue)[30];
        };
        /**
         * An Org key must fulfill two checks:
         *
         * 1. The Uint8Array representation of the string's 31st byte must equal ObjType.ORG
         * 2. The Uint8Array representation of the string's 30th byte must be a valid member of the OrganizationType enum.
         */
        this.mintOrgKey = async (OrganizationType) => {
            const MAX_SAFE_ITERATIONS = 10000;
            let currentIterationCount = 0;
            let OrganizationTypeOfMaybeOrgKey;
            let mintedKey;
            while (OrganizationTypeOfMaybeOrgKey !== OrganizationType &&
                currentIterationCount < MAX_SAFE_ITERATIONS) {
                currentIterationCount++;
                mintedKey = await this.mintKey(ObjType.ORG);
                if (mintedKey) {
                    OrganizationTypeOfMaybeOrgKey = await this.getOrganizationTypeFromOrgKey(mintedKey);
                }
            }
            /** It's possible that we weren't able to generate a key within MAX_SAFE_ITERATIONS attempts. */
            if (!mintedKey) {
                throw new Error("KEY_GEN_FAILURE");
            }
            return mintedKey;
        };
        this.getTypeFromKey = async (key) => {
            const b = this.base64DecToArr(key);
            try {
                return await this.keytype(b);
            }
            catch (err) {
                throw new Error(err);
            }
        };
        this.crypto = crypto;
    }
}

import axios from "axios";
import { madSDK } from "lib/sdk";
import { Product, ProductBase } from "@madhive/mad-sdk";
import { AppThunk } from "types";
import { showSuccess } from "appReducers/toasterReducer/actions";
import { MESSAGE_GETTER_DICTIONARY } from "./constants";
import { selectIsProductUpdating } from "./selectors";
import {
  ABORT_PRODUCT_REQUEST,
  CREATE_PRODUCT_PENDING,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_PENDING,
  DELETE_PRODUCT_SUCCESS,
  GET_ALL_PRODUCTS_FAILURE,
  GET_ALL_PRODUCTS_PENDING,
  GET_ALL_PRODUCTS_SUCCESS,
  ProductsActionTypes,
  SAVE_PRODUCT_FAILURE,
  SAVE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_PENDING
} from "./types";

const setGetAllProductsPending = (): ProductsActionTypes => ({
  type: GET_ALL_PRODUCTS_PENDING
});

const setGetAllProductsFailure = (error?: Error): ProductsActionTypes => ({
  type: GET_ALL_PRODUCTS_FAILURE,
  meta: {
    error: {
      message:
        (error && error.message) || MESSAGE_GETTER_DICTIONARY.GET_ALL_FAILURE()
    }
  }
});

const setGetAllProductSuccess = (products: Product[]): ProductsActionTypes => ({
  type: GET_ALL_PRODUCTS_SUCCESS,
  payload: products,
  meta: {
    timestamp: Date.now()
  }
});

const setAbortProductRequest = (): ProductsActionTypes => ({
  type: ABORT_PRODUCT_REQUEST
});

const setCreateProductPending = (): ProductsActionTypes => ({
  type: CREATE_PRODUCT_PENDING
});

const setUpdateProductPending = (product: Product): ProductsActionTypes => ({
  type: UPDATE_PRODUCT_PENDING,
  meta: { product }
});

const setSaveProductSuccess = (product: Product): ProductsActionTypes => ({
  type: SAVE_PRODUCT_SUCCESS,
  payload: { product }
});

const setSaveProductFailure = (error?: Error): ProductsActionTypes => ({
  type: SAVE_PRODUCT_FAILURE,
  meta: {
    error: {
      message:
        (error && error.message) ||
        MESSAGE_GETTER_DICTIONARY.FAILURE_TO_UPDATE()
    }
  }
});

const setDeleteProductPending = (): ProductsActionTypes => ({
  type: DELETE_PRODUCT_PENDING
});

const setDeleteProductSuccess = (productId: string): ProductsActionTypes => ({
  type: DELETE_PRODUCT_SUCCESS,
  payload: { productId }
});

const setDeleteProductFailure = (error?: Error): ProductsActionTypes => ({
  type: DELETE_PRODUCT_FAILURE,
  meta: {
    error: {
      message:
        (error && error.message) ||
        MESSAGE_GETTER_DICTIONARY.FAILURE_TO_DELETE()
    }
  }
});

export const getAllProducts = (): AppThunk<Promise<Product[]>> => dispatch => {
  dispatch(setGetAllProductsPending());

  return madSDK.products
    .find()
    .then(products => {
      dispatch(setGetAllProductSuccess(products));
      return products;
    })
    .catch(error => {
      if (axios.isCancel(error)) {
        dispatch(setAbortProductRequest());
      } else {
        dispatch(setGetAllProductsFailure(error));
      }
      return [];
    });
};

export const createProduct =
  (product: ProductBase): AppThunk<Promise<Product | null>> =>
  (dispatch, getState) => {
    if (selectIsProductUpdating(getState())) {
      const alreadyUpdatingError = new Error(
        MESSAGE_GETTER_DICTIONARY.ALREADY_UPDATING_FAILURE()
      );

      dispatch(setSaveProductFailure(alreadyUpdatingError));
      return Promise.reject(null);
    }

    dispatch(setCreateProductPending());

    return madSDK.products
      .save(product, { skipValidation: true })
      .then(createdProduct => {
        dispatch(setSaveProductSuccess(createdProduct));
        return createdProduct;
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          dispatch(setAbortProductRequest());
        } else {
          dispatch(setSaveProductFailure(error));
        }
        return null;
      });
  };

export const updateProduct =
  (product: Product): AppThunk<Promise<Product | null>> =>
  (dispatch, getState) => {
    if (selectIsProductUpdating(getState())) {
      const alreadyUpdatingError = new Error(
        MESSAGE_GETTER_DICTIONARY.ALREADY_UPDATING_FAILURE()
      );

      dispatch(setSaveProductFailure(alreadyUpdatingError));
      return Promise.reject(null);
    }

    dispatch(setUpdateProductPending(product));

    return madSDK.products
      .save(product, { skipValidation: true })
      .then(updatedProduct => {
        dispatch(setSaveProductSuccess(updatedProduct));
        return updatedProduct;
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          dispatch(setAbortProductRequest());
        } else {
          dispatch(setSaveProductFailure(error));
        }
        return null;
      });
  };

export const deleteProduct =
  (productId: string): AppThunk<Promise<string | null>> =>
  (dispatch, getState) => {
    if (selectIsProductUpdating(getState())) {
      const alreadyUpdatingError = new Error(
        MESSAGE_GETTER_DICTIONARY.ALREADY_UPDATING_FAILURE()
      );

      dispatch(setSaveProductFailure(alreadyUpdatingError));
      return Promise.reject(null);
    }

    dispatch(setDeleteProductPending());

    return madSDK.products
      .delete(productId)
      .then(deleted => {
        dispatch(setDeleteProductSuccess(deleted.id));
        dispatch(showSuccess(MESSAGE_GETTER_DICTIONARY.DELETE_SUCCESS()));
        return deleted.id;
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          dispatch(setAbortProductRequest());
        } else {
          dispatch(setDeleteProductFailure(error));
        }
        return null;
      });
  };

/* eslint-disable camelcase */
export const teamToServiceTeam = (team) => {
    return {
        id: team.id,
        name: team.name,
        external_id: team.externalId
    };
};
export const serviceTeamToTeam = (team) => {
    return {
        id: team.id,
        name: team.name,
        externalId: team.external_id
    };
};

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Warning: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-warning"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M12 8.63C12.4142 8.63 12.75 8.96579 12.75 9.38V13.12C12.75 13.5342 12.4142 13.87 12 13.87C11.5858 13.87 11.25 13.5342 11.25 13.12V9.38C11.25 8.96579 11.5858 8.63 12 8.63Z" />
      <path d="M11 16.3777C10.9975 15.8215 11.4487 15.375 11.999 15.375C12.5503 15.375 13 15.8208 13 16.375C13 16.9272 12.5522 17.375 12 17.375C11.4487 17.375 11.0015 16.9287 11 16.3777Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.0465 21.5C21.9814 21.5 23.1949 19.4086 22.2349 17.7282L13.974 3.27084C13.0065 1.57639 10.5646 1.57639 9.59714 3.27084L1.33626 17.7282C0.375243 19.4086 1.58865 21.5 3.52471 21.5H20.0465ZM11.0021 4.07305C11.3487 3.46615 12.2225 3.46615 12.5691 4.07305L20.8302 18.5308C21.1742 19.1333 20.7386 19.8821 20.0465 19.8821H3.52471C2.83077 19.8821 2.39698 19.1324 2.74099 18.5308L11.0021 4.07305Z"
      />
    </svg>
  );
};

export default Warning;

import { isPage } from "../modules/handlers/page";
/**
 * @param resource: a cache resource.
 * @return: an iterable over that resource, if one can be determined. Else, an empty array.
 */
const defaultToIterable = (resource) => {
    if (isPage(resource)) {
        return resource.data;
    }
    else if (Array.isArray(resource)) {
        return resource;
    }
    else if (resource instanceof Map || resource instanceof Set) {
        return resource.values();
    }
    return [];
};
/**
 * @param item: an individual item.
 * @return: the `id` attribute in that item, if one exists. Else, the empty string.
 */
const defaultKey = (item) => {
    return item?.id?.toString();
};
/**
 * @param config: optional. Provides controls over how atomization is done.
 * @param config.key: optional. how to key an individual item. Defaults to assuming there is a unique `id` property on the object.
 * @param config.toIterable: optional. How to turn the given cache resource types into iterables. If none specified, a best-effort will be made.
 * @return: a generator which will iterate over every member of the collection, keying them as it goes.
 */
export const atomization = (config) => {
    const { toIterable = defaultToIterable, key = defaultKey } = config || {};
    return function* (resource) {
        for (const item of toIterable(resource)) {
            const itemKey = key(item);
            if (itemKey?.length) {
                yield { key: itemKey, value: item };
            }
        }
    };
};
/**
 * Assumes anything that starts with `https://` or `http://` or `?` is a resource. Else, it's an atom.
 * @param key: some key into a namespace cache.
 * @return: the category it should belong to.
 */
export const categorize = (key) => {
    if (/^https?:\/\/|^\?/.test(key)) {
        return "resources";
    }
    return "atoms";
};

import { FC } from "react";
import { CommonIconProps, LeftRightDirection } from "./types";

type Props = CommonIconProps & {
  direction: LeftRightDirection;
};

const Arrow: FC<Props> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)",
  direction = "right"
}) => {
  return (
    <svg
      className={`icon-arrow-${direction}`}
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      {direction === "left" && (
        <path d="M9.46967 17.5303C9.76256 17.8232 10.2374 17.8232 10.5303 17.5303C10.8232 17.2374 10.8232 16.7626 10.5303 16.4697L6.81066 12.75L19 12.75C19.4142 12.75 19.75 12.4142 19.75 12C19.75 11.5858 19.4142 11.25 19 11.25L6.81066 11.25L10.5303 7.53033C10.8232 7.23744 10.8232 6.76256 10.5303 6.46967C10.2374 6.17678 9.76256 6.17678 9.46967 6.46967L4.46967 11.4697C4.32322 11.6161 4.25 11.8081 4.25 12C4.25 12.1023 4.27048 12.1998 4.30757 12.2887C4.34416 12.3765 4.39819 12.4589 4.46967 12.5303L9.46967 17.5303Z" />
      )}
      {direction === "right" && (
        <path d="M14.5303 6.46967C14.2374 6.17678 13.7626 6.17678 13.4697 6.46967C13.1768 6.76256 13.1768 7.23744 13.4697 7.53033L17.1893 11.25H5C4.58579 11.25 4.25 11.5858 4.25 12C4.25 12.4142 4.58579 12.75 5 12.75H17.1893L13.4697 16.4697C13.1768 16.7626 13.1768 17.2374 13.4697 17.5303C13.7626 17.8232 14.2374 17.8232 14.5303 17.5303L19.5303 12.5303C19.6768 12.3839 19.75 12.1919 19.75 12C19.75 11.8977 19.7295 11.8002 19.6924 11.7113C19.6558 11.6235 19.6018 11.5411 19.5303 11.4697L14.5303 6.46967Z" />
      )}
    </svg>
  );
};

export default Arrow;

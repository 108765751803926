import { FC } from "react";
import { CommonIconProps } from "./types";

const Upload: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))"
}) => {
  return (
    <svg
      className="icon-upload"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4881 9.95188C11.4811 9.95836 11.4743 9.96498 11.4676 9.97171L9.30268 12.1367C9.00978 12.4296 9.00978 12.9044 9.30268 13.1973C9.59557 13.4902 10.0704 13.4902 10.3633 13.1973L11.25 12.3107V15.5C11.25 15.9142 11.5858 16.25 12 16.25C12.4142 16.25 12.75 15.9142 12.75 15.5V12.3107L13.6367 13.1973C13.9296 13.4902 14.4044 13.4902 14.6973 13.1973C14.9902 12.9044 14.9902 12.4296 14.6973 12.1367L12.5303 9.96967C12.3839 9.82322 12.1919 9.75 12 9.75C11.897 9.75 11.7988 9.77078 11.7094 9.80838C11.6297 9.84183 11.5546 9.88967 11.4881 9.95188Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.25C9.19057 4.25 6.79026 5.96724 5.77366 8.40427C3.1937 8.9661 1.25 11.2423 1.25 14C1.25 17.1752 3.82479 19.75 7 19.75H18C20.6232 19.75 22.75 17.6232 22.75 15C22.75 12.6196 20.9988 10.648 18.7145 10.3034C18.3659 6.90253 15.493 4.25 12 4.25ZM7.034 9.30967C7.73911 7.23814 9.69501 5.75 12 5.75C14.8998 5.75 17.25 8.10021 17.25 11C17.25 11.4142 17.5858 11.75 18 11.75C19.7948 11.75 21.25 13.2052 21.25 15C21.25 16.7948 19.7948 18.25 18 18.25H7C4.65321 18.25 2.75 16.3468 2.75 14C2.75 11.8555 4.3475 10.0948 6.42546 9.81111C6.70689 9.77268 6.94247 9.57856 7.034 9.30967Z"
        fill={fill}
      />
    </svg>
  );
};

export default Upload;

import { DoNotCare } from "../../types";
import {
  InputNumberProps,
  InputTextProps,
  InputTextTypes,
  InputDateRangeProps,
  InputDateSingleProps
} from ".";

export const isInputNumberProps = (
  props: DoNotCare
): props is InputNumberProps =>
  props.type === "number" &&
  (typeof props.value === "number" || props.value === null);

export const isInputTextProps = (props: DoNotCare): props is InputTextProps =>
  InputTextTypes.includes(props.type) &&
  ["undefined", "string"].includes(typeof props.value);

export const isInputDateProps = (
  props: DoNotCare
): props is InputDateRangeProps => props.type === "date";

export const isInputDateRangeProps = (
  props: DoNotCare
): props is InputDateRangeProps =>
  props.type === "date" && Array.isArray(props.value);

export const isInputDateSingleProps = (
  props: DoNotCare
): props is InputDateSingleProps =>
  props.type === "date" && !Array.isArray(props.value);

import { IToastProps } from "@blueprintjs/core";
import { Publisher } from "@madhive/mad-sdk";

export const GET_ALL_PUBLISHERS_PENDING = "GET_ALL_PUBLISHERS_PENDING";
export const GET_ALL_PUBLISHERS_FAILURE = "GET_ALL_PUBLISHERS_FAILURE";
export const GET_ALL_PUBLISHERS_SUCCESS = "GET_ALL_PUBLISHERS_SUCCESS";

export const SAVE_PUBLISHER_PENDING = "SAVE_PUBLISHER_PENDING";
export const SAVE_PUBLISHER_SUCCESS = "SAVE_PUBLISHER_SUCCESS";
export const SAVE_PUBLISHER_FAILURE = "SAVE_PUBLISHER_FAILURE";

export const ABORT_GET_ALL_PUBLISHERS_REQUEST =
  "ABORT_GET_ALL_PUBLISHERS_REQUEST";

export interface PublishersState {
  readonly byId: Record<string, Publisher>;
  readonly isAllLoading: boolean;
  readonly receivedAllAt: number | null;
  readonly error: string | null;
}

interface GetAllPublishersPendingAction {
  type: typeof GET_ALL_PUBLISHERS_PENDING;
}

interface GetAllPublishersSuccessAction {
  type: typeof GET_ALL_PUBLISHERS_SUCCESS;
  payload: Publisher[];
  meta: {
    timestamp: number;
  };
}

interface GetAllPublishersFailureAction {
  type: typeof GET_ALL_PUBLISHERS_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface SavePublisherPendingAction {
  type: typeof SAVE_PUBLISHER_PENDING;
}

interface SavePublisherSuccessAction {
  type: typeof SAVE_PUBLISHER_SUCCESS;
  payload: Publisher;
  meta: {
    timestamp: number;
  };
}

interface SavePublisherFailureAction {
  type: typeof SAVE_PUBLISHER_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface AbortGetAllPublishersRequestAction {
  type: typeof ABORT_GET_ALL_PUBLISHERS_REQUEST;
}

export type PublishersActionTypes =
  | GetAllPublishersPendingAction
  | GetAllPublishersSuccessAction
  | GetAllPublishersFailureAction
  | SavePublisherPendingAction
  | SavePublisherSuccessAction
  | SavePublisherFailureAction
  | AbortGetAllPublishersRequestAction;

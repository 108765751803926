import { lastValueFrom, Observable, of } from "rxjs";
import { FilterTypes } from "../handlers/filter";
export class SummaryInjector {
    constructor(sdk) {
        this.sdk = sdk;
    }
    inject(campaigns, fields) {
        if (campaigns.length === 0 || fields.length === 0) {
            return of(campaigns);
        }
        else if (fields.length === 1 && fields[0] === "pacing") {
            // If we only want pacing, we can make a single call to summaries to get it
            return this.hydrateSummariesHolistically(campaigns, fields);
        }
        else {
            return this.hydrateSummariesPerCampaign(campaigns, fields);
        }
    }
    /**
     * Summarizes all campaigns with a single request.
     * This is usable for any request that only requires "summary", due to a quirk in the API.
     * If other fields are needed, `getPerCampaignSummary` should be used.
     * @param campaigns: the campaigns to hydrate.
     * @param fields: what to get summaries of.
     */
    hydrateSummariesHolistically(campaigns, fields) {
        return new Observable((subscriber) => {
            lastValueFrom(this.sdk.summaries.find(this.getSummaryFilters(campaigns, fields)))
                .then((summary) => {
                const lookup = new Map();
                for (const current of summary.campaignSummaries || []) {
                    lookup.set(current.id, current);
                }
                subscriber.next(campaigns.map((campaign) => {
                    const pacing = lookup.get(campaign.id) || null;
                    campaign.summary = {
                        pacing,
                        frequency: null,
                        vcr: typeof pacing?.videoCompleteRate === "number" ? pacing.videoCompleteRate : null
                    };
                    this.hydrateChildren(campaign, pacing);
                    return campaign;
                }));
                subscriber.complete();
            })
                .catch((error) => {
                subscriber.error(error);
            });
        });
    }
    /**
     * Summarizes each campaign with individual requests.
     * This is necessary for any request that requires vcr/frequency, due to a quirk in the API.
     * The quirk is: if you send a request containing all the campaigns, it'll give you the vcr/frequency for the entire set - not per-campaign (we want per-campaign.)
     * @param campaigns: the campaigns to hydrate.
     * @param fields: what to get summaries of.
     */
    hydrateSummariesPerCampaign(campaigns, fields) {
        return new Observable((subscriber) => {
            this.hydrateByBatch(subscriber, campaigns, fields);
        });
    }
    /**
     * Due to another quirk in the API, we can accidentally DDOS the service by trying to send all these requests
     * per campaign at once, if the number of campaigns is large enough. So, we break them into batches.
     * Meaning:
     *   - we do the first `${size}` campaigns
     *     - we do not block on this, letting their updates get sent out to the subscriber as we go
     *   - once the batch is done, then do the next batch in the same fashion
     *   - repeat this until all campaigns have been hydrated
     * @param subscriber: a subscriber that can be notified of the updates that are being made to campaigns.
     * @param campaigns: the total array of campaigns to hydrate.
     * @param fields: which fields are being requested.
     * @param index: where to start the batch from. Defaults to 0.
     * @param completed: a tracker of how many campaigns' hydrations have been completed - both in total, and just for this batch. Defaults to 0 for both.
     */
    hydrateByBatch(subscriber, campaigns, fields, index = 0, completed = { total: 0, batch: 0 }) {
        // Instead of making one single call we need to make a call per campaign to get the vcr/freq information
        // if we don't batch these calls in some way, we can cause the API service to run out of resources
        // 50 is a semi-arbitrary size, but for some justification: it's the app's default page size, and is a "reasonable" number (not too big, not too small)
        const size = 50;
        const batch = campaigns.slice(index, index + size);
        completed.batch = 0;
        let errored = false;
        for (const campaign of batch) {
            if (errored) {
                break;
            }
            this.sdk.summaries.find(this.getSummaryFilters([campaign], fields)).subscribe({
                next: ({ campaignSummaries, frequency, videoCompletionRate }) => {
                    // We merge the summary onto the correct campaign
                    const summary = campaign.summary || {};
                    if (campaignSummaries) {
                        const pacing = campaignSummaries[0] || null;
                        summary.pacing = pacing;
                        this.hydrateChildren(campaign, pacing);
                    }
                    if (typeof frequency !== "undefined" || frequency === 0) {
                        summary.frequency = frequency;
                    }
                    if (typeof videoCompletionRate !== "undefined" || videoCompletionRate === 0) {
                        summary.vcr = videoCompletionRate;
                    }
                    campaign.summary = summary;
                    subscriber.next(campaigns);
                },
                error: (error) => {
                    errored = true;
                    subscriber.error(error);
                },
                complete: () => {
                    completed.batch += 1;
                    completed.total += 1;
                    // once we're done loading, we want to enforce that anything
                    // we didn't get is set to null
                    campaign.summary = {
                        pacing: campaign.summary?.pacing ?? null,
                        vcr: campaign.summary?.vcr ?? null,
                        frequency: campaign.summary?.frequency ?? null
                    };
                    subscriber.next(campaigns);
                    if (completed.total === campaigns.length) {
                        subscriber.complete();
                    }
                    else if (!errored && completed.batch === size) {
                        this.hydrateByBatch(subscriber, campaigns, fields, index + size, completed);
                    }
                }
            });
        }
    }
    /**
     * Hydrates the given campaign's line items.
     * @param campaign: the campaign whose descendants we should hydrate.
     * @param pacing: the pacing info to use for hydration.
     */
    hydrateChildren(campaign, pacing) {
        if (campaign.lineItems.length === 0) {
            return;
        }
        const lookup = new Map(pacing?.lineItems.map((lineItemSummary) => [lineItemSummary.id, lineItemSummary]));
        for (const lineItem of campaign.lineItems) {
            const pacing = lookup.get(lineItem.id);
            lineItem.summary = {
                pacing: pacing || null,
                vcr: typeof pacing?.videoCompleteRate === "number" ? pacing.videoCompleteRate : null
            };
        }
    }
    /**
     * There are some oddities with the current service that we need to account for.
     * 1. Even though we are giving a list of campaign IDs we still need to give a start
     *    end date which is usually used to limit the request (b/c of a slow query). Now
     *    we have IDs we shouldn't need this but it is required so we are going to "hard code"
     *    them to be in the past (2018) and in the future (01/01/(current-year + 2)). There is a new service being
     *    built that won't have this limitation.
     * 2. The request also needs parentId and owners set b/c they are required but are just the
     *    org id of the current user (which the service also has)
     * @param campaigns: campaigns to get summaries for.
     * @param fields: which summaries to request.
     * @return: a filter set for requesting campaign summaries.
     */
    getSummaryFilters(campaigns, fields) {
        const user = this.sdk.getCurrentUser();
        return {
            where: [
                {
                    field: "campaignIds",
                    type: FilterTypes.EQ,
                    value: campaigns.map((c) => c.id)
                },
                {
                    field: "start",
                    type: FilterTypes.EQ,
                    value: "2018-01-01"
                },
                {
                    field: "end",
                    type: FilterTypes.EQ,
                    value: `${new Date().getFullYear() + 2}-01-01`
                },
                {
                    field: "parentId",
                    type: FilterTypes.EQ,
                    value: user.primaryOrganizationId
                },
                {
                    field: "ownerIds",
                    type: FilterTypes.EQ,
                    value: [user.primaryOrganizationId]
                }
            ],
            fields: fields.map((field) => (field === "pacing" ? "campaignSummaries" : field))
        };
    }
}

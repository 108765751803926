import axios from "axios";
import { BehaviorSubject } from "rxjs";
import { signInWithCustomToken, signOut as firebaseSignout, setPersistence, inMemoryPersistence } from "firebase/auth";
import { PasswordResetMissingTempPasswordError, PasswordResetError, NoAuthorizedUser, PasswordSetError, UnauthenticatedError } from "../../errors";
import { BrowserCredentialStorage } from "./credential-storage";
import { FilterTypes } from "../handlers/filter";
/**
 * This is a special case collection that only handle auth.
 * Because of this it doesn't extend handler because there is no
 * really searching, deleting etc within auth.
 */
class Authentication {
    /**
     * Authentication needs to have a connection to firebase to
     * do all auth currently, and a reference to sdk.urls
     * @param sdk MadSDK
     */
    constructor(sdk) {
        this.sdk = sdk;
        this.madFire = sdk.madFire;
        this.credentials = sdk.config.credentialStorage || new BrowserCredentialStorage();
        this.currentAccount = new BehaviorSubject(null);
        this.addAuthorizationHeader();
    }
    /**
     * Triggers the callback immedaitely with the current user state, and any time it changes in the future
     * @param callback
     */
    onAuthStateChanged(callback) {
        return this.currentAccount.subscribe(callback);
    }
    /**
     * @param account: the account to fetch latest user info for.
     * @return: that same account, with the user info updated to latest.
     */
    // TODO: BASE-776, delete and deprecate this
    async populateWithLatestUserData(account) {
        const id = account.user.id;
        const latestUser = await this.sdk.users.find_once({
            where: [
                {
                    field: "id",
                    type: FilterTypes.EQ,
                    value: id
                }
            ]
        });
        if (!latestUser) {
            console.warn("Failed to populate account with latest user data.");
            return account;
        }
        return {
            ...account,
            user: latestUser
        };
    }
    /**
     * Pushes the new user info to all subscribers.
     * Does nothing if account is currently null.
     * @param user: fresh user information.
     */
    // TODO: BASE-776, delete and deprecate this
    refreshUserInfo(user) {
        const currentAccount = this.getCurrentAccount();
        if (currentAccount === null) {
            return;
        }
        const updatedAccount = {
            ...currentAccount,
            user
        };
        this.currentAccount.next(updatedAccount);
    }
    /**
     * This method checks if we have access to the account endpoint
     * @returns Promise<Acount>
     */
    async trySession(verify) {
        const ssoToken = this.credentials.cookie.get();
        if (ssoToken) {
            return this.loginWithSSOToken(ssoToken);
        }
        const madAuthToken = this.credentials.token.get();
        if (!madAuthToken) {
            throw new UnauthenticatedError();
        }
        try {
            const { data: account } = await axios.get(`${this.sdk.urls.burnsBaseUrl}/account`);
            if (verify && !verify(account.user)) {
                await this.signOut();
                throw "mad-sdk login verify failed";
            }
            await this.authFirebaseWithToken(account.firebaseCustomAuthToken);
            const accountWithFreshUserData = await this.populateWithLatestUserData(account);
            this.currentAccount.next(accountWithFreshUserData);
            return accountWithFreshUserData;
        }
        catch (e) {
            this.credentials.token.set(undefined);
            throw new UnauthenticatedError();
        }
    }
    async loginWithSSOToken(token) {
        try {
            const { data: account } = await axios.post(`${this.sdk.urls.burnsBaseUrl}/login`, {
                access_token: token
            });
            return this.initializeUserSession(account);
        }
        catch (e) {
            this.credentials.cookie.clear();
            throw new UnauthenticatedError();
        }
    }
    /**
     * Attempts to authenticate a user by email and password.
     * @param email email to auth with.
     * @param password password for auth.
     * @param verify: if provided, an extra function to verify the user once signed in.
     * @throws: if login fails, or verify is provided and returns false.
     */
    async auth(email, password, verify) {
        try {
            const { data: account } = await axios.post(`${this.sdk.urls.burnsBaseUrl}/login`, {
                email,
                password
            });
            if (verify && !verify(account.user)) {
                await this.signOut();
                throw "mad-sdk login verify failed";
            }
            return this.initializeUserSession(account);
        }
        catch (e) {
            console.warn("mad-sdk login failed", e);
            throw e;
        }
    }
    /**
     * Attempts to authenticate a local development session.
     * @param email the email address of the org to log in as.
     * @param token the gcloud auth token of the developer logging in.
     * @throws: if login fails.
     */
    async localAuth(email, token) {
        try {
            const { data: account } = await axios.get(`${this.sdk.urls.burnsBaseUrl}/account`, {
                headers: { Authorization: `Bearer ${token}` },
                params: { email }
            });
            return this.initializeUserSession(account);
        }
        catch (e) {
            console.warn("mad-sdk login failed", e);
            throw e;
        }
    }
    /**
     * Authenticates a user by using a Magic Link generated from the Sentinel app
     * @param token A JWT token generated by Sentinel service which is authorized to start a user session
     * @returns
     */
    async magicLinkAuth(token) {
        try {
            const { data: account } = await axios.post(`${this.sdk.urls.burnsBaseUrl}/public/sentinel/validate-magic-link`, {
                magicLink: token
            });
            return this.initializeUserSession(account, true);
        }
        catch (e) {
            console.warn("mad-sdk magic link login failed", e);
            throw e;
        }
    }
    /**
     * Initializes the session for the user account that was successfully logged in
     * @param account The user account returned on successful login
     * @param isSessionTemporary Indicates if the session should be persisted in storage
     * @returns
     */
    async initializeUserSession(account, isSessionTemporary = false) {
        if (isSessionTemporary) {
            const firebaseAuth = this.madFire.getAuth();
            await setPersistence(firebaseAuth, inMemoryPersistence);
            this.temporaryToken = account.madAuthToken;
        }
        else {
            this.credentials.token.set(account.madAuthToken);
        }
        await this.authFirebaseWithToken(account.firebaseCustomAuthToken);
        this.credentials.cookie.clear();
        const accountWithFreshUserData = await this.populateWithLatestUserData(account);
        this.currentAccount.next(accountWithFreshUserData);
        return accountWithFreshUserData;
    }
    /**
     * Allows authentication using a token instead of email/password
     * @param token Token to use for authentication
     */
    async authFirebaseWithToken(token) {
        const auth = this.madFire.getAuth();
        return signInWithCustomToken(auth, token);
    }
    /**
     * Signout the current user.
     */
    async signOut() {
        const auth = this.madFire.getAuth();
        this.sdk.caches.clear();
        await firebaseSignout(auth);
        this.credentials.token.clear();
        this.credentials.cookie.clear();
        this.currentAccount.next(null);
    }
    /**
     * Used to change a password for a user.
     * @param password: the new password.
     * @param email: optional. The email of the user to set the password for. If undefined, operates on the currently-authenticated user.
     */
    async setPassword(password, email) {
        const route = email ? "admin" : "account";
        return new Promise((resolve, reject) => {
            axios
                .post(`${this.sdk.urls.burnsBaseUrl}/${route}/update-password`, {
                password,
                email
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(async () => resolve())
                .catch((error) => {
                if (error.response?.status === 403 || error.response?.status === 401) {
                    reject(new NoAuthorizedUser());
                }
                reject(new PasswordSetError());
            });
        });
    }
    /**
     * Reset password for a given email.
     * @param email: email to reset the password for.
     * @return: the new temporary password.
     */
    async resetPassword(email) {
        const url = `${this.sdk.urls.burnsBaseUrl}/admin/reset-password`;
        return new Promise((resolve, reject) => {
            axios
                .post(url, {
                email
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(async (res) => {
                if (!res.data?.tempPW) {
                    reject(new PasswordResetMissingTempPasswordError());
                }
                resolve(res.data.tempPW);
            })
                .catch((error) => {
                if (error.response?.status === 403 || error.response?.status === 401) {
                    reject(new NoAuthorizedUser());
                }
                reject(new PasswordResetError());
            });
        });
    }
    /**
     * expose the current authenticated user.
     */
    getCurrentAccount() {
        return this.currentAccount.getValue();
    }
    addAuthorizationHeader() {
        axios.interceptors.request.use((config) => {
            return this.buildAuthorizationHeaderConfig(config);
        });
    }
    buildAuthorizationHeaderConfig(config) {
        let token;
        if (this.temporaryToken) {
            token = this.temporaryToken;
        }
        else {
            token = this.credentials.token.get();
        }
        if (token) {
            return {
                ...config,
                headers: {
                    ...config.headers,
                    Authorization: `Bearer ${token}`
                }
            };
        }
        return config;
    }
    /**
     * @return: the current token used for authentication, if auth'd. Else, undefined.
     */
    getAuthorizationToken() {
        return this.credentials.token.get();
    }
}
export default Authentication;

import {
  createContext,
  FC,
  useState,
  MutableRefObject,
  HTMLProps,
  forwardRef,
  useContext,
  useImperativeHandle,
  memo,
  ReactNode,
  PropsWithChildren
} from "react";
import { Card, Collapse, ICardProps, Icon, IconSize } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { Button, ButtonVariant, TextColor } from "madhive/components";
import classNames from "classnames";
import { css } from "@emotion/react";
import { noop } from "lib/utils/fp";

import type { DataAttributes } from "frontier/lib/components/types";
import { CollapseCardFn } from ".";
import "./SmithersCard.scss";

interface CardWrapperProps {
  elevation?: number;
  sharpCorners?: boolean;
  grow?: boolean;
  centerContent?: boolean;
  fillContainer?: boolean;
  fullWidth?: boolean;
  fullHeight?: boolean;
  hasShadow?: boolean;
}

interface SmithersCardContextValue {
  isExpanded: boolean;
  onToggleExpand: () => void;
}

export const SmithersCardContext = createContext<SmithersCardContextValue>({
  isExpanded: true,
  onToggleExpand: noop
});

const SmithersCardWrapper: FC<CardWrapperProps & ICardProps> = props => {
  const [isExpanded, setIsExpanded] = useState(true);

  const {
    sharpCorners = false,
    elevation,
    grow,
    centerContent,
    fillContainer = false,
    fullWidth = false,
    fullHeight = false,
    hasShadow = false,
    ...divProps
  } = props;

  return (
    <SmithersCardContext.Provider
      value={{
        isExpanded,
        onToggleExpand: () => setIsExpanded(prev => !prev)
      }}
    >
      <Card
        data-testid="smithers-card-wrapper"
        {...divProps}
        elevation={elevation}
        className={classNames(
          props.className,
          "SmithersCard",
          hasShadow ? "up-specificity-shadow" : "up-specificity",
          sharpCorners ? "SmithersCard--sharp-corners" : null,
          grow ? "SmithersCard--grow" : null,
          centerContent ? "SmithersCard--center-content" : null,
          fillContainer ? "SmithersCard--fill-container" : null,
          fullWidth ? "SmithersCard--full-width" : null,
          fullHeight ? "SmithersCard--full-height" : null
        )}
      >
        {props.children}
      </Card>
    </SmithersCardContext.Provider>
  );
};

type TitleProps = {
  centered?: boolean;
  padding?: boolean;
  className?: string;
  color?: TextColor;
  align?: "inherit" | "left" | "right" | "center" | "justify";
  children?: ReactNode | ReactNode[] | string | number | null | undefined;
} & DataAttributes;

const SmithersCardTitle: FC<TitleProps> = props => {
  const {
    centered,
    padding,
    className,
    color = TextColor.DEFAULT,
    children,
    align = "inherit",
    ...dataAttributes
  } = props;

  return (
    <h3
      data-testid="smithers-card-title"
      css={css`
        color: ${color};
        text-align: ${align};
      `}
      className={classNames(
        "SmithersCard__HeaderText",
        centered ? "Smithers__CenteredText" : null,
        className,
        padding ? "SmithersCard__HeaderText--padding" : null
      )}
      {...dataAttributes}
    >
      {children}
    </h3>
  );
};

interface TitleContainerProps {
  /** Default: true */
  allowFlexWrap?: boolean;
  border?: boolean;
  sticky?: boolean;
  subheading?: string;
  hasExpandControls?: boolean;
  expandRef?: MutableRefObject<CollapseCardFn | undefined>;
  lowPadding?: boolean;
}

const SmithersCardTitleContainer: FC<
  TitleContainerProps & HTMLProps<HTMLDivElement>
> = forwardRef((props, ref) => {
  const {
    expandRef,
    allowFlexWrap = false,
    border = true,
    sticky,
    hasExpandControls = false,
    lowPadding = false,
    className,
    subheading,
    children,
    ...divProps
  } = props;

  const { onToggleExpand, isExpanded } = useContext(SmithersCardContext);

  // Added for expanding or collapsing the card outside of this components
  useImperativeHandle(expandRef, () => ({
    isExpanded,
    cardToggle() {
      onToggleExpand();
    }
  }));

  return (
    <>
      {subheading ? (
        <div
          data-testid="smithers-card-title-container-subheading"
          className={classNames("SmithersCardHeader__SubtitleContainer")}
        >
          <h6 className="SmithersCardHeader__SubtitleText">{subheading}</h6>
        </div>
      ) : null}
      <div
        ref={ref}
        data-testid="smithers-card-title-container"
        {...divProps}
        className={classNames(
          "SmithersCardHeader__Container",
          allowFlexWrap ? "SmithersCardHeader__Container_FlexWrap" : null,
          border && isExpanded ? "SmithersCardHeader--border-bottom" : null,
          sticky ? "SmithersCardHeader__Container--sticky" : null,
          hasExpandControls
            ? "SmithersCardHeader__Container--low-padding-left"
            : null,
          className,
          lowPadding
            ? "SmithersCardHeader__Container--low-padding-vertical"
            : ""
        )}
      >
        {hasExpandControls && (
          <Button
            variant={ButtonVariant.ICON}
            data-testid="smithers-card-icon"
            onClick={onToggleExpand}
          >
            <Icon
              icon={
                isExpanded ? IconNames.CHEVRON_DOWN : IconNames.CHEVRON_RIGHT
              }
              iconSize={IconSize.LARGE}
              data-testid="smithers-card-icon-chevron"
            />
          </Button>
        )}
        {children}
      </div>
    </>
  );
});

export interface CardBodyProps {
  borderBottom?: boolean;
  grow?: boolean;
  centerContent?: boolean;
  fullWidth?: boolean;
  noPadding?: boolean;
  fullHeight?: boolean;
}

const SmithersCardBody: FC<
  PropsWithChildren<CardBodyProps & HTMLProps<HTMLDivElement>>
> = ({
  borderBottom,
  children,
  className,
  grow,
  centerContent,
  noPadding,
  fullHeight,
  ...divProps
}) => (
  <div
    data-testid="smithers-card-body"
    {...divProps}
    className={classNames(
      "SmithersCard__Body",
      borderBottom ? "SmithersCard__Body--border-bottom" : null,
      grow ? "SmithersCard__Body--grow" : null,
      centerContent ? "SmithersCard__Body--center-content" : null,
      noPadding ? "SmithersCard__Body--no--padding" : null,
      fullHeight ? "SmithersCard__Body--full-height" : null,
      className
    )}
  >
    {children}
  </div>
);

const SmithersCardCollapseContainer: FC<PropsWithChildren> = ({ children }) => {
  const { isExpanded } = useContext(SmithersCardContext);
  return <Collapse isOpen={isExpanded}>{children}</Collapse>;
};

class SmithersCard {
  public static Title = memo<TitleProps>(SmithersCardTitle);

  public static Body =
    memo<PropsWithChildren<CardBodyProps & HTMLProps<HTMLDivElement>>>(
      SmithersCardBody
    );

  public static CollapseContainer = memo<
    PropsWithChildren<TitleContainerProps & HTMLProps<HTMLDivElement>>
  >(SmithersCardCollapseContainer);

  public static TitleContainer = memo<
    TitleContainerProps & HTMLProps<HTMLDivElement>
  >(SmithersCardTitleContainer);

  public static Card = memo<CardWrapperProps & ICardProps>(SmithersCardWrapper);
}

export { SmithersCard };

import { RootState } from "rootReducer";
import { UserType } from "@madhive/mad-sdk";
import { ManageUsersPrimaryUserTypes } from "features/ManageUsers/constants";
import { DEFAULT_ORG_VIEWS } from "lib/constants/defaults";

export const selectCurrentUser = (state: RootState) =>
  state.auth.masqueradingUser || state.auth.actualUser;

export const selectIsMasquerading = (state: RootState) =>
  !!state.auth.masqueradingUser;

export const selectIsLoggedIn = (state: RootState) => !!state.auth.actualUser;

export const selectHasAuthBeenAttempted = (state: RootState) =>
  !!state.auth.loginRequestTimestamp;

export const selectCurrentUserViews = (state: RootState) => {
  const currentUser = selectCurrentUser(state);
  if (currentUser) {
    const primaryOrganization =
      state.organizations.byId[currentUser.primaryOrganizationId];
    if (primaryOrganization && primaryOrganization.views) {
      return currentUser.viewIds.filter(
        viewId => viewId in primaryOrganization.views!
      );
    }
  }
  return [];
};

export const selectCurrentUserSettings = (state: RootState) =>
  state.auth.masqueradingUser?.settings || state.auth.actualUser?.settings;

export const selectProductionViews = (state: RootState) => {
  const currentUser = selectCurrentUser(state);

  // If user exists
  if (currentUser) {
    const primaryOrganization =
      state.organizations.byId[currentUser.primaryOrganizationId];
    // If primary org exists given user's primary org id, return org views
    if (primaryOrganization && primaryOrganization.views) {
      return primaryOrganization.views;
    }
    // Return default org view if user exists but no primary org

    return DEFAULT_ORG_VIEWS;
  }
  // No user AND no org

  return {};
};

/* manageUsers typing */
export const selectUserType = (state: RootState) => {
  const currentUser = selectCurrentUser(state);
  if (currentUser) {
    return currentUser.type === UserType.ORGANIZATION
      ? currentUser.isAdmin
        ? ManageUsersPrimaryUserTypes.INTERNAL_ADMIN
        : ManageUsersPrimaryUserTypes.INTERNAL_STANDARD
      : ManageUsersPrimaryUserTypes.EXTERNAL;
  }
  return ManageUsersPrimaryUserTypes.EXTERNAL;
};

export const selectPrimaryOrganizationId = (state: RootState) =>
  state.auth.actualUser && state.auth.actualUser.primaryOrganizationId;

import { useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import Tooltip from "../Tooltip";
import { TruncatedProps } from "./types";
import { styles } from "./styles";

export const Truncated = ({
  text,
  lines = 1,
  tooltip = true
}: TruncatedProps) => {
  const overflowIndicator = useRef<HTMLSpanElement | null>(null);
  const [showTooltip, setShowTooltip] = useState(tooltip);

  useEffect(() => {
    if (!overflowIndicator.current || !tooltip) {
      return;
    }

    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.target === overflowIndicator.current) {
            setShowTooltip(!entry.intersectionRatio);
          }
        });
      },
      { root: overflowIndicator.current.parentElement }
    );

    observer.observe(overflowIndicator.current);

    return () => {
      if (overflowIndicator.current && observer) {
        observer.unobserve(overflowIndicator.current);
        observer.disconnect();
      }
    };
  }, [overflowIndicator.current, text]);

  return (
    <div
      className="kit-Truncated"
      css={[
        styles.ellipsis,
        css`
          -webkit-line-clamp: ${lines};
        `
      ]}
    >
      {text}
      <span css={styles.overflowIndicator} ref={overflowIndicator} />

      {/* Blueprint Popover adds a bunch of extra nested elements which causes problems with the styling
    so we render it with a fake element that overlays the real text. */}
      {showTooltip && (
        <div css={styles.tooltip}>
          <Tooltip
            content={text}
            position="top"
            {...(typeof tooltip === "object" ? tooltip : {})}
          >
            <span />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

export default Truncated;
export * from "./types";

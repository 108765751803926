import {
  PossibleQuickEditStatuses,
  RootCampaign,
  QuickEditLineItemPayload,
  LineItemId,
  EditsRecord,
  LineItemFormattedForAttachingCreatives
} from "campaign-types";
import { RawInstructionStatus } from "features/ManageCampaigns/constants";
import { getLatestDate, getTenMinutesFromNow, isDatePassed } from "./date";

// we should update the start date when setting live when startASAP is checked or start date is in the past
export const shouldUpdateStartDateIfSetLive = (
  isStartASAPEnabled: boolean,
  startASAP: boolean,
  startDate: Date | undefined,
  rawStatus: RawInstructionStatus,
  statusToSetOnBulkSave: RawInstructionStatus
) =>
  isStartASAPEnabled &&
  (startASAP || isDatePassed(startDate)) &&
  statusToSetOnBulkSave === RawInstructionStatus.DELIVERABLE &&
  rawStatus === RawInstructionStatus.DRAFT;

export const rawInstructionStatusToIsLive = (
  rawInstructionStatus: PossibleQuickEditStatuses
): boolean => {
  if (
    rawInstructionStatus === RawInstructionStatus.DRAFT ||
    rawInstructionStatus === RawInstructionStatus.PAUSED
  ) {
    return false;
  }

  return true;
};

export const toggleRawInstructionStatus = (
  status: RawInstructionStatus
): RawInstructionStatus.DELIVERABLE | RawInstructionStatus.PAUSED => {
  if (
    status === RawInstructionStatus.DRAFT ||
    status === RawInstructionStatus.PAUSED
  ) {
    return RawInstructionStatus.DELIVERABLE;
  }
  return RawInstructionStatus.PAUSED;
};

/**
 * When toggling a campaign's live status, we want to toggle live the campaign, all
 * set-live-able line items, and every line item's creative flight.
 */
export const calculateEditsAfterTogglingFullInstructionTreeStatus = (
  rootInstruction: RootCampaign,
  isStartASAPEnabled: boolean
): EditsRecord => {
  const statusToRead = rootInstruction.rawStatus;

  const toggledStatus = toggleRawInstructionStatus(statusToRead);

  const SHOULD_UPDATE_STATUS_FOR = [
    RawInstructionStatus.PAUSED,
    RawInstructionStatus.DELIVERABLE
  ];

  if (SHOULD_UPDATE_STATUS_FOR.includes(toggledStatus)) {
    /** We need to set this campaign, all of its line items, and every creative flight attached to new status. */

    // Should not update creative status if status is being toggled to paused
    const shouldUpdateCreativesStatus =
      toggledStatus !== RawInstructionStatus.PAUSED;

    const lineItemEdits = rootInstruction.lineItems
      .filter(lineItem => lineItem.userCanSetLive)
      .reduce<Record<LineItemId, QuickEditLineItemPayload>>((acc, cur) => {
        acc[cur.id] = {
          id: cur.id,
          status: toggledStatus,
          ...(shouldUpdateStartDateIfSetLive(
            isStartASAPEnabled,
            cur.startASAP,
            cur.startDate,
            cur.rawStatus,
            toggledStatus
          ) && {
            startDate: getTenMinutesFromNow()
          }),
          version: cur.version,
          creatives: cur.attachedCreatives.map(creativeFlight => ({
            ...creativeFlight,
            id: creativeFlight.id || "",
            status: shouldUpdateCreativesStatus
              ? toggledStatus
              : creativeFlight.status,
            ...(shouldUpdateStartDateIfSetLive(
              isStartASAPEnabled,
              creativeFlight.startASAP,
              creativeFlight.startDate,
              creativeFlight.status,
              toggledStatus
            ) && {
              startDate: getLatestDate([getTenMinutesFromNow(), cur.startDate])
            })
          }))
        };

        return acc;
      }, {});

    return {
      campaignEdits: {
        [rootInstruction.id]: {
          id: rootInstruction.id,
          status: toggledStatus,
          ...(shouldUpdateStartDateIfSetLive(
            isStartASAPEnabled,
            rootInstruction.startASAP,
            rootInstruction.startDate,
            rootInstruction.rawStatus,
            toggledStatus
          ) && {
            startDate: getTenMinutesFromNow()
          }),
          version: rootInstruction.version
        }
      },
      lineItemEdits
    };
  }

  return {
    campaignEdits: {},
    lineItemEdits: {}
  };
};

export const calculateEditsAfterTogglingLineItemStatus = (
  lineItem: LineItemFormattedForAttachingCreatives,
  parentCampaign: RootCampaign,
  isStartASAPEnabled: boolean
): EditsRecord => {
  const statusToRead = lineItem.rawStatus;

  const toggledStatus = toggleRawInstructionStatus(statusToRead);

  const shouldUpdateCreativesStatus =
    toggledStatus !== RawInstructionStatus.PAUSED;

  /**
   * We need this line item to be the new status. We also need to
   * ensure that its parent campaign is the new status
   */

  return {
    campaignEdits:
      toggledStatus === RawInstructionStatus.DELIVERABLE
        ? {
            [parentCampaign.id]: {
              id: parentCampaign.id,
              status: toggledStatus,
              version: parentCampaign.version
            }
          }
        : {},
    lineItemEdits: {
      [lineItem.id]: {
        id: lineItem.id,
        status: toggledStatus,
        ...(shouldUpdateStartDateIfSetLive(
          isStartASAPEnabled,
          lineItem.startASAP,
          lineItem.startDate,
          lineItem.rawStatus,
          toggledStatus
        ) && {
          startDate: getLatestDate([
            getTenMinutesFromNow(),
            parentCampaign.startDate
          ])
        }),
        version: lineItem.version,
        creatives: lineItem.attachedCreatives.map(creativeFlight => ({
          ...creativeFlight,
          id: creativeFlight.id || "",
          status: shouldUpdateCreativesStatus
            ? toggledStatus
            : creativeFlight.status,
          ...(shouldUpdateStartDateIfSetLive(
            isStartASAPEnabled,
            creativeFlight.startASAP,
            creativeFlight.startDate,
            creativeFlight.status,
            toggledStatus
          ) && {
            startDate: getLatestDate([
              getTenMinutesFromNow(),
              lineItem.startDate
            ])
          })
        }))
      }
    }
  };
};

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Copy: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-copy"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M7.116 4.116a1.25 1.25 0 0 1 .883-.366H8a.75.75 0 0 0 0-1.5h-.001A2.75 2.75 0 0 0 5.25 5a.75.75 0 0 0 1.5 0c0-.332.131-.65.366-.884ZM10.25 3a.75.75 0 0 1 .75-.75h1a.75.75 0 0 1 0 1.5h-1a.75.75 0 0 1-.75-.75ZM15 2.25a.75.75 0 0 0 0 1.5h1a.75.75 0 0 0 0-1.5h-1ZM18.25 3a.75.75 0 0 1 .75-.75h.001A2.75 2.75 0 0 1 21.75 5a.75.75 0 1 1-1.5 0 1.25 1.25 0 0 0-1.249-1.25H19a.75.75 0 0 1-.75-.75ZM21 11.25a.75.75 0 0 1 .75.75v1a.75.75 0 0 1-1.5 0v-1a.75.75 0 0 1 .75-.75ZM21.75 8a.75.75 0 0 0-1.5 0v1a.75.75 0 0 0 1.5 0V8ZM21.75 16a.75.75 0 0 0-1.5 0 1.25 1.25 0 0 1-1.249 1.25.751.751 0 0 0-.001 1.5A2.752 2.752 0 0 0 21.75 16ZM6.5 10.75a.75.75 0 0 0 0 1.5H9a.75.75 0 0 0 0-1.5H6.5ZM5.75 14.5a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5h-6a.75.75 0 0 1-.75-.75ZM6.5 16.75a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5h-6Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 10A2.75 2.75 0 0 1 5 7.25h9A2.75 2.75 0 0 1 16.75 10v9A2.75 2.75 0 0 1 14 21.75H5A2.75 2.75 0 0 1 2.25 19v-9ZM5 8.75c-.69 0-1.25.56-1.25 1.25v9c0 .69.56 1.25 1.25 1.25h9c.69 0 1.25-.56 1.25-1.25v-9c0-.69-.56-1.25-1.25-1.25H5Z"
      />
    </svg>
  );
};

export default Copy;

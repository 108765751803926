import { IToastProps } from "@blueprintjs/core";
import { PioProductPackage } from "@madhive/mad-sdk";

export const GET_PIO_PRODUCT_PACKAGES_SUCCESS =
  "GET_PIO_PRODUCT_PACKAGES_SUCCESS";
export const GET_PIO_PRODUCT_PACKAGES_PENDING =
  "GET_PIO_PRODUCT_PACKAGES_PENDING";
export const GET_PIO_PRODUCT_PACKAGES_FAILURE =
  "GET_PIO_PRODUCT_PACKAGES_FAILURE";

export interface PioProductPackagesState {
  readonly byId: Record<string, PioProductPackage>;
  readonly isAllLoading: boolean;
  readonly receivedAllAt: null | number;
}

interface GetPioProductPackagesSuccessAction {
  type: typeof GET_PIO_PRODUCT_PACKAGES_SUCCESS;
  payload: PioProductPackage[];
  meta: {
    timestamp: number;
  };
}

interface GetPioProductPackagesPendingAction {
  type: typeof GET_PIO_PRODUCT_PACKAGES_PENDING;
}

interface GetPioProductPackagesFailureAction {
  type: typeof GET_PIO_PRODUCT_PACKAGES_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

export type PioProductPackagesActionType =
  | GetPioProductPackagesSuccessAction
  | GetPioProductPackagesPendingAction
  | GetPioProductPackagesFailureAction;

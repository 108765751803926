import { PropsWithChildren } from "react";
import { css } from "@emotion/react";
import { Icons } from "frontier/lib/kit";
import { DisplaySize } from "frontier/lib/kit/types";

interface Props {
  className?: string;
  size?: DisplaySize;
  activeItem?: string;
  href?: string;
}

const style = {
  root: css`
    color: rgba(0, 0, 0, 0.54);
    display: inline-flex;
    min-width: 56px;
    flex-shrink: 0;
  `
};

const ListItemIcon = ({
  children,
  className,
  size,
  href,
  activeItem
}: PropsWithChildren<Props>) => {
  const isActive = href === activeItem;

  return (
    <div
      className={className}
      css={style.root}
      data-testid="smithers-list-item-icon"
    >
      <Icons.Display
        size={size}
        fill={
          isActive ? "var(--color-splash-primary)" : "var(--color-secondary)"
        }
      >
        {children}
      </Icons.Display>
    </div>
  );
};

export default ListItemIcon;

import { CancelTokenSource } from "axios";
import {
  ABORT_POLYGONS_REQUEST,
  CLEAR_POLYGON_ERROR,
  GET_POLYGONS_FAILURE,
  GET_POLYGONS_PENDING,
  GET_POLYGONS_SUCCESS,
  PolygonsActionTypes,
  PolygonState
} from "./types";

const deriveLoadingPolygonIds = (oldIds: string[], newIds: string[]) => {
  const newIdsSet = new Set(newIds);

  return oldIds.filter(id => !newIdsSet.has(id));
};

const addCancellationSource = (
  state: PolygonState,
  newSource: CancelTokenSource
) =>
  state.activeCancelTokenSources.find(source => source === newSource)
    ? state.activeCancelTokenSources
    : state.activeCancelTokenSources.concat(newSource);

const removeCancellationSource = (
  state: PolygonState,
  existingSource: CancelTokenSource
) => state.activeCancelTokenSources.filter(source => source !== existingSource);

export const polygonInitialState: PolygonState = {
  polygons: [],
  errorPolygonIds: [],
  loadingPolygonIds: [],
  idsForPolygonsWithNoData: [],
  activeCancelTokenSources: [],
  isLoading: false,
  error: { message: null }
};

export const polygonReducer = (
  state = polygonInitialState,
  action: PolygonsActionTypes
): PolygonState => {
  switch (action.type) {
    case GET_POLYGONS_PENDING:
      return {
        ...state,
        loadingPolygonIds: action.payload,
        activeCancelTokenSources: addCancellationSource(
          state,
          action.meta.cancellationSource
        ),
        isLoading: true
      };
    case GET_POLYGONS_SUCCESS:
      return {
        ...state,
        polygons: state.polygons.concat(action.payload.success),
        // No guaranatee that we actually have geographic coordinates for every geographic entity returned by NNY— store those no-match ids so we don't attempt to fetch them again.
        idsForPolygonsWithNoData: [
          ...new Set(
            state.idsForPolygonsWithNoData.concat(action.payload.failure)
          )
        ],
        loadingPolygonIds: deriveLoadingPolygonIds(
          state.loadingPolygonIds,
          action.payload.success
            .map(polygon => polygon.groupingId)
            .concat(action.payload.failure)
        ),
        activeCancelTokenSources: removeCancellationSource(
          state,
          action.meta.cancellationSource
        ),
        // if clearing this api call's cancellation source from the active sources array results in an empty array, we can be sure that no other calls are pending
        isLoading: !!removeCancellationSource(
          state,
          action.meta.cancellationSource
        ).length,
        error: { message: null }
      };
    case GET_POLYGONS_FAILURE:
      return {
        ...state,
        errorPolygonIds: action.payload,
        loadingPolygonIds: deriveLoadingPolygonIds(
          state.loadingPolygonIds,
          action.payload
        ),
        activeCancelTokenSources: removeCancellationSource(
          state,
          action.meta.cancellationSource
        ),
        isLoading: !!removeCancellationSource(
          state,
          action.meta.cancellationSource
        ).length,
        error: action.meta.error
      };
    case ABORT_POLYGONS_REQUEST:
      return {
        ...state,
        loadingPolygonIds: deriveLoadingPolygonIds(
          state.loadingPolygonIds,
          action.payload
        ),
        activeCancelTokenSources: removeCancellationSource(
          state,
          action.meta.cancellationSource
        ),
        isLoading: !!removeCancellationSource(
          state,
          action.meta.cancellationSource
        ).length
      };
    case CLEAR_POLYGON_ERROR:
      return {
        ...state,
        error: { message: null }
      };
    default:
      return state;
  }
};

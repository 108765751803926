import { FileError } from "../types";
import Item from "./Item";

type UploaderFileListProps = {
  /** A set of files or a map of files to their error messages. */
  files: Map<string, FileError> | Map<string, File>;
  onDelete: (file: File) => void;
  onReselect?: (file: File) => void;
  processing?: boolean;
};

const UploaderFileList = ({
  files,
  onDelete,
  onReselect,
  processing
}: UploaderFileListProps) => (
  <>
    {Array.from(files.keys()).map(fileName => {
      const fileOrError = files.get(fileName);
      if (!fileOrError) return null;
      return (
        <Item
          key={
            "file" in fileOrError
              ? `${fileOrError.file.name}-${fileOrError.file.lastModified}`
              : `${fileOrError.name}-${fileOrError.lastModified}`
          }
          file={"file" in fileOrError ? fileOrError.file : fileOrError}
          onDelete={onDelete}
          error={"error" in fileOrError ? fileOrError.error : undefined}
          onReselect={onReselect}
          processing={processing}
        />
      );
    })}
  </>
);

export default UploaderFileList;

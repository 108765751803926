import {
  AuthState,
  AuthActionTypes,
  LOGIN_FIREBASE_VIA_TOKEN_PENDING,
  LOGIN_USER_PENDING,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILURE,
  MASQUERADE_USER,
  MASK_OFF_USER
} from "./types";

export const initialState: AuthState = {
  actualUser: null,
  masqueradingUser: null,
  isLoading: false,
  initializationError: null,
  loginRequestTimestamp: null,
  loginSuccessTimestamp: null
};

export const authReducer = (
  state = initialState,
  action: AuthActionTypes
): AuthState => {
  switch (action.type) {
    /* LOGOUT_USER is handled in the rootReducer */
    case LOGIN_FIREBASE_VIA_TOKEN_PENDING:
      return {
        actualUser: null,
        masqueradingUser: null,
        isLoading: true,
        initializationError: null,
        loginRequestTimestamp: null,
        loginSuccessTimestamp: null
      };
    case LOGIN_USER_PENDING:
      return {
        actualUser: null,
        masqueradingUser: null,
        isLoading: true,
        initializationError: null,
        loginRequestTimestamp: action.meta.timestamp,
        loginSuccessTimestamp: null
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        actualUser: action.payload,
        isLoading: false,
        initializationError: null,
        loginSuccessTimestamp: action.meta.timestamp
      };
    case LOGIN_USER_FAILURE:
      return {
        ...state,
        isLoading: false,
        initializationError: action.meta.error
      };
    case MASQUERADE_USER:
      return {
        ...state,
        masqueradingUser: action.payload
      };
    case MASK_OFF_USER:
      return {
        ...state,
        masqueradingUser: null
      };
    default:
      return state;
  }
};

import {
  EditsRecord,
  LineItemId,
  QuickEditCampaignPayload,
  QuickEditLineItemPayload
} from "campaign-types";
import { RawInstructionStatus } from "features/ManageCampaigns/constants";
import { CampaignStatus } from "lib/constants/campaign";
import {
  CampaignsQuickEditsActionTypes,
  CampaignsQuickEditsState,
  DISCARD_QUICK_EDITS,
  QuickEditCampaign,
  QuickEditLineItem,
  QUICK_EDIT_CAMPAIGN,
  QUICK_EDIT_LINE_ITEM
} from "./types";

const DELIVERABLE_STATUSES = new Set([
  CampaignStatus.LIVE,
  CampaignStatus.LIVE_WARNING,
  CampaignStatus.WAITING
]);

const STATUSES_THAT_CAN_BE_CHANGED: Set<CampaignStatus> = new Set([
  CampaignStatus.LIVE,
  CampaignStatus.LIVE_WARNING,
  CampaignStatus.READY,
  CampaignStatus.WAITING,
  CampaignStatus.PAUSED
]);

const calculateLineItemParentEdit = (
  action: QuickEditLineItem,
  editsRecord: EditsRecord
): QuickEditCampaignPayload | null => {
  if (action.data.status === RawInstructionStatus.DELIVERABLE) {
    /**
     * If the user is setting a line item live, also ensure that it's parent is set live.
     */

    const maybeAlreadyEditedParent: QuickEditCampaignPayload | undefined =
      editsRecord.campaignEdits[action.originalInstructionParent.id];

    const statusOfParent =
      maybeAlreadyEditedParent && maybeAlreadyEditedParent.status
        ? maybeAlreadyEditedParent.status
        : action.originalInstructionParent.rawStatus;

    if (
      statusOfParent === RawInstructionStatus.PAUSED ||
      statusOfParent === RawInstructionStatus.DRAFT
    ) {
      return {
        id: action.originalInstructionParent.id,
        status: RawInstructionStatus.DELIVERABLE,
        version: action.originalInstructionParent.version
      };
    }
  }

  return null;
};

const getLineItemStatusFromEditsRecord = (
  editsRecord: EditsRecord,
  key: keyof EditsRecord,
  id: string
) => {
  const maybeQuickEditedInstruction = editsRecord[key][id];

  return maybeQuickEditedInstruction && maybeQuickEditedInstruction.status;
};

const calculateCampaignDescendantEdits = (
  action: QuickEditCampaign,
  editsRecord: EditsRecord
): QuickEditLineItemPayload[] => {
  const newStatus = action.data.status;
  if (action.data.status === RawInstructionStatus.DELIVERABLE) {
    /**
     * In the case that a user is quick-editing a campaign to be live, if there
     * is not at least one line item live, set all valid line items live;
     */

    const liveLineItems = action.originalInstruction.lineItems.filter(li => {
      /** If an edit has been made to this instruction previously, we want to read from that, instead */
      const maybeExistingEditedStatus = getLineItemStatusFromEditsRecord(
        editsRecord,
        "lineItemEdits",
        li.id
      );

      if (maybeExistingEditedStatus) {
        return maybeExistingEditedStatus === RawInstructionStatus.DELIVERABLE;
      }

      return (
        DELIVERABLE_STATUSES.has(li.status) ||
        maybeExistingEditedStatus === RawInstructionStatus.DELIVERABLE
      );
    });

    if (liveLineItems.length > 0) {
      return [];
    }

    const lineItemsToSetLive = action.originalInstruction.lineItems.filter(
      li => {
        /** If an edit has been made to this instruction previously, we want to read from that, instead */
        const maybeExistingEditedStatus = getLineItemStatusFromEditsRecord(
          editsRecord,
          "lineItemEdits",
          li.id
        );

        if (maybeExistingEditedStatus) {
          return maybeExistingEditedStatus !== RawInstructionStatus.DELIVERABLE;
        }

        return (
          STATUSES_THAT_CAN_BE_CHANGED.has(li.status) &&
          li.rawStatus !== RawInstructionStatus.DELIVERABLE
        );
      }
    );

    return lineItemsToSetLive.map((li): QuickEditLineItemPayload => {
      const maybeAlreadyEditedLineItem: QuickEditLineItemPayload | undefined =
        editsRecord.lineItemEdits[action.data.id];

      return {
        id: li.id,
        version: li.version,
        status: RawInstructionStatus.DELIVERABLE,
        bookedImpressions: maybeAlreadyEditedLineItem
          ? maybeAlreadyEditedLineItem.bookedImpressions
          : li.bookedImpressions,
        creatives: maybeAlreadyEditedLineItem
          ? maybeAlreadyEditedLineItem.creatives
          : li.attachedCreatives.map(creativeFlight => ({
              ...creativeFlight,
              id: creativeFlight.id || "",
              status: RawInstructionStatus.DELIVERABLE
            })),
        endDate: maybeAlreadyEditedLineItem
          ? maybeAlreadyEditedLineItem.endDate
          : li.endDate,
        startDate: maybeAlreadyEditedLineItem
          ? maybeAlreadyEditedLineItem.startDate
          : li.startDate
      };
    });
  }
  if (
    newStatus === RawInstructionStatus.PAUSED ||
    newStatus === RawInstructionStatus.DRAFT
  ) {
    /**
     * In the case that a user is quick-editing a campaign to be non-live, set
     * all descendants non-live;
     */

    const lineItemsToPause = action.originalInstruction.lineItems.filter(li => {
      /** If an edit has been made to this instruction previously, we want to read from that, instead */
      const maybeExistingEditedStatus = getLineItemStatusFromEditsRecord(
        editsRecord,
        "lineItemEdits",
        li.id
      );

      if (maybeExistingEditedStatus) {
        return maybeExistingEditedStatus === RawInstructionStatus.DELIVERABLE;
      }

      return (
        STATUSES_THAT_CAN_BE_CHANGED.has(li.status) &&
        li.rawStatus === RawInstructionStatus.DELIVERABLE
      );
    });

    const shoulUpdateCreativeStatus = newStatus !== RawInstructionStatus.PAUSED;

    return lineItemsToPause.map((li): QuickEditLineItemPayload => {
      const maybeAlreadyEditedLineItem: QuickEditLineItemPayload | undefined =
        editsRecord.lineItemEdits[action.data.id];

      return {
        id: li.id,
        version: li.version,
        status: newStatus,
        bookedImpressions: maybeAlreadyEditedLineItem
          ? maybeAlreadyEditedLineItem.bookedImpressions
          : li.bookedImpressions,
        creatives: maybeAlreadyEditedLineItem
          ? maybeAlreadyEditedLineItem.creatives
          : li.attachedCreatives.map(creativeFlight => ({
              ...creativeFlight,
              id: creativeFlight.id || "",
              status: shoulUpdateCreativeStatus
                ? newStatus
                : creativeFlight.status
            })),
        endDate: maybeAlreadyEditedLineItem
          ? maybeAlreadyEditedLineItem.endDate
          : li.endDate,
        startDate: maybeAlreadyEditedLineItem
          ? maybeAlreadyEditedLineItem.startDate
          : li.startDate
      };
    });
  }

  return [];
};

export const quickEditsInitialState: CampaignsQuickEditsState = {
  campaignEdits: {},
  lineItemEdits: {},
  persistedCampaigns: {},
  persistedLineItems: {}
};

export const campaignsQuickEditsReducer = (
  state = quickEditsInitialState,
  action: CampaignsQuickEditsActionTypes
): CampaignsQuickEditsState => {
  switch (action.type) {
    case QUICK_EDIT_CAMPAIGN: {
      const descendantsWeAlsoHaveToUpdateAsAResultOfThisEdit =
        calculateCampaignDescendantEdits(action, state);

      const newLineItemEdits =
        descendantsWeAlsoHaveToUpdateAsAResultOfThisEdit.length > 0
          ? descendantsWeAlsoHaveToUpdateAsAResultOfThisEdit.reduce<
              Record<LineItemId, QuickEditLineItemPayload>
            >((acc, cur) => {
              acc[cur.id] = {
                ...acc[cur.id],
                ...cur
              };
              return acc;
            }, state.lineItemEdits)
          : state.lineItemEdits;

      const maybeExistingCampaignEdit = state.campaignEdits[action.data.id];

      return {
        ...state,
        campaignEdits: {
          ...state.campaignEdits,
          [action.data.id]: {
            ...maybeExistingCampaignEdit,
            ...action.data
          }
        },
        lineItemEdits: newLineItemEdits
      };
    }
    case QUICK_EDIT_LINE_ITEM: {
      const parentWeMaybeHaveToUpdateAsAResultOfThisEdit =
        calculateLineItemParentEdit(action, state);

      const newCampaignEdits = parentWeMaybeHaveToUpdateAsAResultOfThisEdit
        ? {
            ...state.campaignEdits,
            [parentWeMaybeHaveToUpdateAsAResultOfThisEdit.id]:
              parentWeMaybeHaveToUpdateAsAResultOfThisEdit
          }
        : state.campaignEdits;

      const maybeAlreadyEditedLineItem = state.lineItemEdits[action.data.id];

      const persistedLineItem = action.originalInstructionParent.lineItems.find(
        li => li.id === action.data.id
      );

      return {
        ...state,
        lineItemEdits: {
          ...state.lineItemEdits,
          [action.data.id]: {
            ...maybeAlreadyEditedLineItem,
            ...action.data
          }
        },
        campaignEdits: newCampaignEdits,
        persistedLineItems: {
          ...state.persistedLineItems,
          [action.data.id]: persistedLineItem!
        }
      };
    }
    case DISCARD_QUICK_EDITS:
      return { ...quickEditsInitialState };
    default:
      return state;
  }
};

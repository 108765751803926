import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Record: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-record"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 7a1.5 1.5 0 0 0-1.5 1.5v7a1.5 1.5 0 0 0 1.5 1.5h7.875a1.5 1.5 0 0 0 1.5-1.5v-7a1.5 1.5 0 0 0-1.5-1.5H5.25Zm-3 1.5a3 3 0 0 1 3-3h7.875a3 3 0 0 1 3 3v7a3 3 0 0 1-3 3H5.25a3 3 0 0 1-3-3v-7Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.7 7.265c1.227-.989 3.05-.114 3.05 1.46v6.55c0 1.574-1.823 2.449-3.05 1.46l-3.795-3.054a.75.75 0 1 1 .94-1.168l3.795 3.054c.245.197.61.024.61-.292v-6.55a.374.374 0 0 0-.61-.292l-3.795 3.054a.75.75 0 1 1-.94-1.168L18.7 7.265Z"
      />
    </svg>
  );
};

export default Record;

import axios from "axios";
import { NotImplementedError } from "../../../errors";
import { Handler } from "../../handlers";
export class AdvertisersBulk extends Handler {
    constructor(sdk) {
        super(sdk, "advertisers-bulk");
    }
    /**
     * Updates the category for each creative that belongs to a given advertiser.
     * @param data.categoryId The ID of the category.
     * @param data.advertiserId The ID of the advertiser.
     */
    async saveItem(data) {
        await axios.post(`${this.sdk.urls.madhiveEncoderBaseUrl}/batchUpdateIABCategoryRtbIds`, {
            advertiser: data.advertiserId,
            iab_category_rtb_ids: data.categoryId ? [data.categoryId] : []
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        // This bulk edit will update an unknown amount of creatives and needs to clear the cache
        this.sdk.caches.clear("creatives");
    }
    async findItems() {
        throw new NotImplementedError("find");
    }
    async deleteItem() {
        throw new NotImplementedError("delete");
    }
}

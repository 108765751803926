import { IToastProps } from "@blueprintjs/core";
import { FootTrafficReport, FootTrafficReportResult } from "@madhive/mad-sdk";

export interface FootTrafficState {
  readonly byId: Record<string, FootTrafficReport>;
  readonly isLoading: boolean;
  readonly isLoaded: boolean;
  readonly isReportDetailsLoading: boolean;
  readonly isNewDimensionLoading: boolean;
  readonly resultsById: Record<string, FootTrafficReportResult | null> | null;
  readonly error:
    | ({
        message: string;
      } & IToastProps)
    | null;
}

export const GET_FOOT_TRAFFIC_REPORTS_PENDING =
  "GET_FOOT_TRAFFIC_REPORTS_PENDING";
export const GET_FOOT_TRAFFIC_REPORTS_SUCCESS =
  "GET_FOOT_TRAFFIC_REPORTS_SUCCESS";
export const GET_FOOT_TRAFFIC_REPORTS_FAILURE =
  "GET_FOOT_TRAFFIC_REPORTS_FAILURE";

export const GET_FOOT_TRAFFIC_REPORT_RESULTS_PENDING =
  "GET_FOOT_TRAFFIC_REPORT_RESULTS_PENDING";
export const GET_FOOT_TRAFFIC_REPORT_RESULTS_SUCCESS =
  "GET_FOOT_TRAFFIC_REPORT_RESULTS_SUCCESS";
export const GET_FOOT_TRAFFIC_REPORT_RESULTS_FAILURE =
  "GET_FOOT_TRAFFIC_REPORT_RESULTS_FAILURE";

export const ARCHIVE_FOOT_TRAFFIC_REPORT_PENDING =
  "ARCHIVE_FOOT_TRAFFIC_REPORT_PENDING";
export const ARCHIVE_FOOT_TRAFFIC_REPORT_SUCCESS =
  "ARCHIVE_FOOT_TRAFFIC_REPORT_SUCCESS";
export const ARCHIVE_FOOT_TRAFFIC_REPORT_FAILURE =
  "ARCHIVE_FOOT_TRAFFIC_REPORT_FAILURE";

export const UNARCHIVE_FOOT_TRAFFIC_REPORT_PENDING =
  "UNARCHIVE_FOOT_TRAFFIC_REPORT_PENDING";
export const UNARCHIVE_FOOT_TRAFFIC_REPORT_SUCCESS =
  "UNARCHIVE_FOOT_TRAFFIC_REPORT_SUCCESS";
export const UNARCHIVE_FOOT_TRAFFIC_REPORT_FAILURE =
  "UNARCHIVE_FOOT_TRAFFIC_REPORT_FAILURE";

export const CREATE_FOOT_TRAFFIC_REPORT_PENDING =
  "CREATE_FOOT_TRAFFIC_REPORT_PENDING";
export const CREATE_FOOT_TRAFFIC_REPORT_SUCCESS =
  "CREATE_FOOT_TRAFFIC_REPORT_SUCCESS";
export const CREATE_FOOT_TRAFFIC_REPORT_FAILURE =
  "CREATE_FOOT_TRAFFIC_REPORT_FAILURE";
interface GetFootTrafficReportsPendingAction {
  type: typeof GET_FOOT_TRAFFIC_REPORTS_PENDING;
}

interface GetFootTrafficReportsSuccessAction {
  type: typeof GET_FOOT_TRAFFIC_REPORTS_SUCCESS;
  payload: FootTrafficReport[];
}
interface GetFootTrafficReportsFailureAction {
  type: typeof GET_FOOT_TRAFFIC_REPORTS_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface CreateFootTrafficReportPendingAction {
  type: typeof CREATE_FOOT_TRAFFIC_REPORT_PENDING;
}

interface CreateFootTrafficReportSuccessAction {
  type: typeof CREATE_FOOT_TRAFFIC_REPORT_SUCCESS;
  payload: FootTrafficReport;
  meta: {
    success: {
      message: string;
    } & IToastProps;
  };
}
interface CreateFootTrafficReportFailureAction {
  type: typeof CREATE_FOOT_TRAFFIC_REPORT_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface GetFootTrafficReportDetailsPendingAction {
  type: typeof GET_FOOT_TRAFFIC_REPORT_RESULTS_PENDING;
  payload: boolean | undefined;
}

interface GetFootTrafficReportDetailsSuccessAction {
  type: typeof GET_FOOT_TRAFFIC_REPORT_RESULTS_SUCCESS;
  payload: any;
}
interface GetFootTrafficReportDetailsFailureAction {
  type: typeof GET_FOOT_TRAFFIC_REPORT_RESULTS_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface ArchiveFootTrafficReportPendingAction {
  type: typeof ARCHIVE_FOOT_TRAFFIC_REPORT_PENDING;
}
interface ArchiveFootTrafficReportFailureAction {
  type: typeof ARCHIVE_FOOT_TRAFFIC_REPORT_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface ArchiveFootTrafficReportSuccessAction {
  type: typeof ARCHIVE_FOOT_TRAFFIC_REPORT_SUCCESS;
  meta: {
    report: FootTrafficReport;
    success: {
      message: string;
    } & IToastProps;
  };
}

interface UnarchiveFootTrafficReportPendingAction {
  type: typeof UNARCHIVE_FOOT_TRAFFIC_REPORT_PENDING;
}
interface UnarchiveFootTrafficReportFailureAction {
  type: typeof UNARCHIVE_FOOT_TRAFFIC_REPORT_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface UnarchiveFootTrafficReportSuccessAction {
  type: typeof UNARCHIVE_FOOT_TRAFFIC_REPORT_SUCCESS;
  meta: {
    report: FootTrafficReport;
    success: {
      message: string;
    } & IToastProps;
  };
}

export type FootTrafficReportsActionTypes =
  | GetFootTrafficReportsPendingAction
  | GetFootTrafficReportsSuccessAction
  | GetFootTrafficReportsFailureAction
  | CreateFootTrafficReportPendingAction
  | CreateFootTrafficReportSuccessAction
  | CreateFootTrafficReportFailureAction
  | GetFootTrafficReportDetailsPendingAction
  | GetFootTrafficReportDetailsSuccessAction
  | GetFootTrafficReportDetailsFailureAction
  | ArchiveFootTrafficReportPendingAction
  | ArchiveFootTrafficReportSuccessAction
  | ArchiveFootTrafficReportFailureAction
  | UnarchiveFootTrafficReportSuccessAction
  | UnarchiveFootTrafficReportPendingAction
  | UnarchiveFootTrafficReportFailureAction;

import { FilterTypes } from "@madhive/mad-sdk";
import { useCollection } from "../../utils/collections";
import { useBehaviorSubject } from "../rxjs";
export const getSingularDataDefaults = () => {
    // pendingChanges is initialized as a pointer to deltas because there is no data to combine with
    // deltas yet. This is useful for a creation form (no initial id).
    const deltas = {};
    return {
        deltas,
        pendingChanges: deltas,
        isLoading: false,
        isLoaded: false,
        isSaving: false,
        isInitializing: true,
        isDeleted: false
    };
};
/**
 * Utils for singular data
 * @param collection collection name
 * @param id finds a particular id
 * @returns Observer and promise/observable handler to the collection
 */
export const useSingularMadHooksUtils = (collection, id) => {
    const handler = useCollection(collection);
    const filters = id
        ? {
            where: [{ field: handler.getIdentifierKey(), type: FilterTypes.EQ, value: id }]
        }
        : undefined;
    const observer = useBehaviorSubject(collection, { filters, key: `${handler.getIdentifierKey()}:${id}` }, getSingularDataDefaults());
    return { observer, handler };
};

import axios from "axios";
import { DeleteUserFailed, MadSDKAbortError, UserCreateFailed, UserSaveFailed, UsersRequestFailed } from "../../errors";
import { serviceUserToUser, userToPostgresUser } from "../../models/user";
import { AffiliationTypes } from "../../types";
import { convertTimeToUnixTimestamp } from "../../utils";
import { Handler } from "../handlers";
import { FilterTypes, FilterValueTypes, validateFilterStructure } from "../handlers/filter";
import Settings from "./settings";
/**
 * Class to handle the users collections filters and management
 */
class Users extends Handler {
    constructor(sdk) {
        super(sdk, "users");
        /**
         * Used to force services
         */
        this.updateServiceCache = async (user) => {
            return axios.get(`${this.sdk.urls.burnsBaseUrl}/users/cache/update?updateEmail=${user.email}`, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
        };
        /**
         * Used to update a user.
         *
         * @param user User to be updated.
         */
        this.update = async (user) => {
            const cancellationSource = axios.CancelToken.source();
            return new Promise((resolve, reject) => {
                axios
                    .post(`${this.sdk.urls.baseAPIUrl}/user`, {
                    ...this.fromUser(user),
                    last_login: convertTimeToUnixTimestamp(user.lastLoginDate),
                    created: convertTimeToUnixTimestamp(user.createdDate),
                    updated: convertTimeToUnixTimestamp(user.updated || null),
                    id: user.id
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    cancelToken: cancellationSource.token
                })
                    .then((res) => {
                    try {
                        const { data: userReceived } = res.data;
                        // The data returned from this API is incomplete so
                        // we need to fetch the users to get the update information
                        const filterNeeded = {
                            where: [{ field: "id", type: FilterTypes.EQ, value: userReceived.id }]
                        };
                        const key = this.keyFromFilter(filterNeeded);
                        this.cache.remove(key);
                        this.find_once(filterNeeded).then((foundUser) => {
                            // burns handles user account info, and it is potentially cached and not kept up to date with updates here
                            // so, we force the user account info to be updated alongside this update, if it's modifying the current account's user
                            // TODO: BASE-776, delete and deprecate this
                            if (foundUser) {
                                const currentAccount = this.sdk.authentication.getCurrentAccount();
                                if (currentAccount?.user.id === foundUser.id) {
                                    this.sdk.authentication.refreshUserInfo(foundUser);
                                }
                                resolve(foundUser);
                            }
                            else {
                                reject("Updated user wasn't found after updated");
                            }
                        });
                    }
                    catch (e) {
                        reject(new UserSaveFailed());
                    }
                })
                    .catch((error) => {
                    if (axios.isCancel(error)) {
                        reject(new MadSDKAbortError());
                    }
                    else {
                        reject(error);
                    }
                });
            });
        };
        /**
         * Used to create a user.
         *
         * @param user User data to create a new use from.
         */
        this.create = async (user) => {
            const cancellationSource = axios.CancelToken.source();
            return new Promise((resolve, reject) => {
                axios
                    .post(`${this.sdk.urls.baseAPIUrl}/user`, this.fromUser(user), {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    cancelToken: cancellationSource.token
                })
                    .then((res) => {
                    try {
                        const { data: userReceived } = res.data;
                        // We need to clear all cache for users b/c if the
                        // user requests all user this new one is gone
                        this.cache.clear();
                        // The data returned from this api isn't complete
                        // we need to fetch a full user to return
                        this.find_once({
                            where: [{ field: "id", type: FilterTypes.EQ, value: userReceived.id }]
                        }).then((foundUser) => {
                            resolve(foundUser);
                        });
                    }
                    catch (e) {
                        reject(new UserCreateFailed());
                    }
                })
                    .catch((error) => {
                    if (axios.isCancel(error)) {
                        reject(new MadSDKAbortError());
                    }
                    else {
                        reject(error);
                    }
                });
            });
        };
        /**
         * Deletes a user based on given ID.
         *
         * @param id ID of the user to be deleted.
         */
        this.deleteItem = async (id) => {
            const cancellationSource = axios.CancelToken.source();
            return axios
                .delete(`${this.sdk.urls.baseAPIUrl}/user/${id}`, {
                headers: {
                    "Content-Type": "application/json"
                },
                cancelToken: cancellationSource.token
            })
                .then((res) => {
                try {
                    // we need to clear cache
                    this.cache.clear();
                    return res.data;
                }
                catch (e) {
                    throw new DeleteUserFailed();
                }
            })
                .catch((error) => {
                if (axios.isCancel(error)) {
                    throw new MadSDKAbortError();
                }
                else {
                    throw error;
                }
            });
        };
        /**
         * Updated the given User with hydration of Org Type and Affiliations.
         * These all require extra data from different sources.
         *
         * @param user User to hydrate
         */
        this.hydrateUser = async (user) => {
            return new Promise((resolve) => {
                const promises = [
                    this.sdk.cryptography.getOrganizationTypeFromOrgKey(user.primaryOrganizationId || "")
                ];
                Promise.all(promises).then(([OrganizationType]) => {
                    if (OrganizationType) {
                        /* eslint-disable-next-line no-param-reassign */
                        user.userPrimaryOrganizationType = OrganizationType;
                    }
                    const affiliations = [];
                    for (const id of [user.teamId, user.agencyId, user.advertiserId]) {
                        id && affiliations.push({ id });
                    }
                    /* eslint-disable-next-line no-param-reassign */
                    user.affiliations = affiliations.length
                        ? affiliations
                        : [
                            {
                                id: AffiliationTypes.CORPORATE,
                                label: AffiliationTypes.CORPORATE
                            }
                        ];
                    resolve(user);
                });
            });
        };
        /**
         * Used to fetch all users from Postgres. Currently no filters are supported by the API
         *
         * @param cancellationToken
         */
        this.getPostgresUsers = async (cancellationToken) => {
            return axios
                .get(`${this.sdk.urls.baseAPIUrl}/users`, {
                headers: {
                    "Content-Type": "application/json"
                },
                cancelToken: cancellationToken.token
            })
                .then((res) => {
                try {
                    const { data: users } = res.data;
                    return users;
                }
                catch (e) {
                    throw new UsersRequestFailed();
                }
            })
                .catch((error) => {
                if (axios.isCancel(error)) {
                    throw new MadSDKAbortError();
                }
                else {
                    throw error;
                }
            });
        };
        this.toUser = (user) => {
            return serviceUserToUser(user);
        };
        this.fromUser = (user) => {
            return userToPostgresUser(user);
        };
        this.settings = new Settings(sdk);
    }
    /**
     * Handles finding specific users based on filters given.
     *
     * @param filters filters to use within the find, currently only "id", "eq" is supported
     */
    async findItems(filters) {
        const key = this.keyFromFilter(filters);
        if (this.cache.has(key)) {
            return this.cache.get(key);
        }
        validateFilterStructure(filters, [
            { filterType: FilterTypes.EQ, valueType: FilterValueTypes.STRING }
        ]);
        const idFilter = filters.where?.find((filter) => filter.field === "id")
            ?.value;
        const cancellationSource = axios.CancelToken.source();
        let pgUsers = await this.getPostgresUsers(cancellationSource);
        if (idFilter) {
            pgUsers = pgUsers.filter((pgUser) => pgUser.id === idFilter);
        }
        const users = [];
        for (const pgUser of pgUsers) {
            users.push(this.hydrateUser(this.toUser(pgUser)));
        }
        const usersPromise = Promise.all(users);
        this.cache.set(key, usersPromise);
        return usersPromise;
    }
    /**
     * Saves a users. If there is a user id given then it will update that user
     * otherwise it will create a new user.
     * @param data User to be saved.
     */
    async saveItem(data) {
        return new Promise((resolve, reject) => {
            if (data.id) {
                // update user
                this.update(data)
                    .then(async (user) => {
                    this.cache.clear();
                    await this.updateServiceCache(user);
                    resolve(user);
                })
                    .catch((error) => reject(error));
            }
            else {
                // create user
                this.create(data)
                    .then(async (user) => {
                    if (user) {
                        this.cache.clear();
                        await this.updateServiceCache(user);
                        resolve(user);
                    }
                    else {
                        reject("User wasn't found after creation.");
                    }
                })
                    .catch((error) => reject(error));
            }
        });
    }
}
export default Users;

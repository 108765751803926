import {
  Organization,
  User,
  UserOperationMode,
  ViewId,
  OrganizationType,
  KnownOrganizationNames,
  KnownOrganizationIds,
  UserType,
  DEFAULT_SERVICE_USER_SETTINGS
} from "@madhive/mad-sdk";
import { madSDK } from "lib/sdk";
import { PacingStatus } from "./pacing";

export const DEFAULT_DEV_USER: User = madSDK.users.toUser({
  email: "dev.premion@madhive.com",
  name: "MadHive Devs",
  views: [
    ViewId.DASHBOARD,
    ViewId.PACING,
    ViewId.TRACKING_PIXELS,
    ViewId.ADMIN_USERS,
    ViewId.MANAGE_PUBLISHER_GROUPS
  ] as ViewId[],
  type: UserType.ORGANIZATION,
  primary_organization_id: KnownOrganizationIds.PREMION,
  id: "",
  admin: true,
  developer: true,
  settings: {
    ...DEFAULT_SERVICE_USER_SETTINGS,
    dashboard: {
      ...DEFAULT_SERVICE_USER_SETTINGS.dashboard,
      dashboard_filter_settings: {
        ...DEFAULT_SERVICE_USER_SETTINGS.dashboard?.dashboard_filter_settings,
        station: {
          is_set: true,
          value: true
        }
      }
    },
    homepage: ViewId.DASHBOARD
  },
  advertiser_id: null,
  agency_id: null,
  team_id: null,
  legacy_advertiser_id: null,
  legacy_agency_id: null,
  legacy_user_id: null,
  legacy_team_id: null,
  last_login: null,
  created: null,
  abilities: [
    {
      resource: ViewId.DASHBOARD,
      operations: [UserOperationMode.READ, UserOperationMode.UPDATE]
    },
    {
      resource: ViewId.PACING,
      operations: [UserOperationMode.READ, UserOperationMode.UPDATE]
    },
    {
      resource: ViewId.TRACKING_PIXELS,
      operations: [UserOperationMode.READ, UserOperationMode.UPDATE]
    },
    {
      resource: ViewId.ADMIN_USERS,
      operations: [UserOperationMode.READ, UserOperationMode.UPDATE]
    },
    {
      resource: ViewId.MANAGE_PUBLISHER_GROUPS,
      operations: [UserOperationMode.READ, UserOperationMode.UPDATE]
    },
    {
      resource: ViewId.MANAGE_PUBLISHER_GROUPS,
      operations: [UserOperationMode.READ, UserOperationMode.UPDATE]
    },
    {
      resource: ViewId.CREATIVE_LIBRARY,
      operations: [UserOperationMode.READ, UserOperationMode.UPDATE]
    }
  ]
});

export const DEFAULT_PACING_COLORS: Readonly<Record<PacingStatus, string>> = {
  [PacingStatus.FUTURE]: "var(--semi-dark)",
  [PacingStatus.ON]: "#66bb6a",
  [PacingStatus.OVER]: "rgb(49, 162, 226)",
  [PacingStatus.UNDER]: "rgb(242, 153, 74)",
  [PacingStatus.WAITING]: "var(--semi-dark)",
  [PacingStatus.WAY_OVER]: "#BB6BD9",
  [PacingStatus.WAY_UNDER]: "rgb(229, 85, 85)",
  [PacingStatus.ACTIVE_WAITING]: "var(--semi-dark)"
};

export const DEFAULT_ORG_VIEWS = {
  [ViewId.ADMIN_USERS]: {
    production: true,
    title: "Manage Users"
  },
  [ViewId.MANAGE_CAMPAIGNS]: {
    production: true,
    title: "Campaign Management"
  },
  [ViewId.DASHBOARD]: {
    production: true,
    title: "Dashboard"
  },
  [ViewId.MANAGE_PUBLISHER_GROUPS]: {
    production: true,
    title: "Manage Publisher Groups"
  },
  [ViewId.PACING]: {
    production: true,
    title: "Pacing"
  },
  [ViewId.TRACKING_PIXELS]: {
    production: true,
    title: "Tracking Pixels"
  }
};

export const DEFAULT_ORG: Organization = {
  id: KnownOrganizationIds.PREMION,
  name: KnownOrganizationNames.PREMION,
  type: OrganizationType.UNKNOWN,
  views: DEFAULT_ORG_VIEWS
};

import { FC, PropsWithChildren } from "react";
import { css } from "@emotion/react";
import {
  Button,
  ButtonColor,
  ButtonVariant,
  Typography
} from "madhive/components";
import { createPortal } from "react-dom";
import Accordion from "../Accordion";
import JobStatusIcon, { JobStatus } from "../JobStatus";

export type ProcessingListItem = {
  id: number | string;
  text: string;
  status: JobStatus;
  button?: {
    label: string;
    callback?: () => void;
  };
};

interface Props {
  /** Title to appear at the top of the list */
  title: string;
  /** Data to render as items beneath the title, when list is in open state */
  items: ProcessingListItem[];
  /** Whether the user can close out of the list */
  dismissable?: boolean;
  /** The function called when user clicks the close button */
  setIsDismissed?: () => void;
  /** A css selector specifying the parent dom node of the list. Default behavior appends the list to the document body. */
  portalTo?: string;
}

const styles = {
  container: css`
    position: fixed;
    right: 2rem;
    bottom: 2rem;
    width: 480px;
    z-index: 10;
    filter: drop-shadow(0px 2px 10px rgba(186, 199, 213, 0.5));
  `,
  listItem: css`
    display: flex;
    justify-content: space-between;
    padding: var(--spacing-16) var(--spacing-24);
    border-top: 1px solid var(--gray-3);
  `,
  itemStatusContainer: css`
    display: flex;
  `,
  list: css`
    margin-block-start: 0px;
    margin-block-end: 0px;
    padding-inline-start: 0px;
  `,
  action: css`
    display: flex;

    button.bp4-button.primary.text {
      margin-right: var(--spacing-16);
      font-size: var(--font-size-12);
      height: 18px;
      min-height: 18px;
      padding: 0px;
    }
  `
};

const ProcessingList: FC<PropsWithChildren<Props>> = props => {
  const { title, items, setIsDismissed, dismissable, portalTo } = props;

  const portalElement =
    (portalTo && document.querySelector(portalTo)) || document.body;

  return createPortal(
    <div css={styles.container}>
      <Accordion
        title={title}
        onDismiss={dismissable ? setIsDismissed : undefined}
      >
        <ul css={styles.list}>
          {items.map(item => (
            <li css={styles.listItem} key={item.id}>
              <Typography ellipsize>{item.text}</Typography>
              <div css={styles.action}>
                {item.button && (
                  <Button
                    variant={ButtonVariant.TEXT}
                    color={ButtonColor.PRIMARY}
                    onClick={item.button.callback}
                  >
                    {item.button.label}
                  </Button>
                )}
                <JobStatusIcon status={item.status} />
              </div>
            </li>
          ))}
        </ul>
      </Accordion>
    </div>,
    portalElement
  );
};

export default ProcessingList;

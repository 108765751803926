import { useCallback, useMemo } from "react";
import { selectCurrentOrganization } from "appReducers/organizationsReducer";
import { BASE_APP_NAME } from "lib/constants/config";
import {
  compareOrgRules,
  RULE_WHITE_LABEL_ORGS_IDS
} from "lib/constants/orgRules";
import { CSVContent, downloadCSV as baseDownloadCSV } from "lib/utils/csv";
import { generatePdf as baseGeneratePdf } from "lib/utils/pdf";
import { useSelector } from "react-redux";

interface OrganizationHook {
  /**
   * get if the current organization is listed as white label
   * without "MadHive" labels
   */
  isWhiteLabel: boolean;

  /**
   * replace current downloadCSV in lib/utils
   * and add conditional to check white labeled
   * organizations and remove MadHive from exported
   * filename.
   * @param rows Array with the data to be exported
   * @param headings Array with the columns to be exported
   * @param fileNameSuffix Define the suffix of the filename to be exported, if not
   * defined, the current timestamp will be used >> new Date().toISOString()
   */
  downloadCSV: (
    rows: CSVContent,
    headings: string[],
    fileNameSuffix?: string
  ) => void;

  generatePdf: (
    fileNameSuffix: string,
    element: HTMLElement | null
  ) => Promise<void>;
}

/**
 * Get specific rules based on current organization
 */
export default function useOrganizationHook(): OrganizationHook {
  const currentOrganization = useSelector(selectCurrentOrganization);

  const isWhiteLabel = useMemo(() => {
    if (!currentOrganization) {
      return false;
    }
    return compareOrgRules(currentOrganization.id, RULE_WHITE_LABEL_ORGS_IDS);
  }, [currentOrganization]);

  const downloadCSV = useCallback(
    (rows: CSVContent, headings: string[], fileNameSuffix?: string) => {
      const prefix = isWhiteLabel ? "" : `${BASE_APP_NAME}-`;
      const suffix = fileNameSuffix || new Date().toISOString();
      baseDownloadCSV(rows, headings, prefix + suffix);
    },
    [isWhiteLabel]
  );

  const generatePdf = async (
    fileNameSuffix: string,
    element: HTMLElement | null
  ) => {
    const prefix = isWhiteLabel ? "" : `${BASE_APP_NAME}-`;
    await baseGeneratePdf(prefix + fileNameSuffix, element);
  };

  return { isWhiteLabel, downloadCSV, generatePdf };
}

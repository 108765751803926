import { CancelTokenSource } from "axios";
import {
  CampaignId,
  LineItemId,
  ManageCampaignsInstructionFilter
} from "campaign-types";
import { ManageCampaignTableColumn } from "features/ManageCampaigns/MainScreen/constants";
import { ManageCampaignTabId } from "features/ManageCampaigns/MainScreen/MainScreenHeader";

export const UPDATE_CAMPAIGNS_DASHBOARD = "UPDATE_CAMPAIGNS_DASHBOARD";
export const COPY_APPLIED_FILTERS_TO_SELECTED_FILTERS =
  "COPY_APPLIED_FILTERS_TO_SELECTED_FILTERS";
export const SET_CAMPAIGNS_SELECTABLE_VALUES_SUCCESS =
  "SET_CAMPAIGNS_SELECTABLE_VALUES_SUCCESS";
export const SET_CAMPAIGNS_SELECTABLE_VALUES_PENDING =
  "SET_CAMPAIGNS_SELECTABLE_VALUES_PENDING";
export const SET_CAMPAIGNS_SELECTABLE_VALUES_FAILURE =
  "SET_CAMPAIGNS_SELECTABLE_VALUES_FAILURE";

export interface CampaignIdAndName {
  id: string;
  name: string;
}

export interface CampaignsDashboardState {
  readonly searchTerm: string;
  readonly appliedFilters: ManageCampaignsInstructionFilter[];
  readonly selectedFilters: ManageCampaignsInstructionFilter[];
  readonly isSettingsDrawerOpen: boolean;
  readonly isFilterDialogOpen: boolean;
  readonly isDownloadDialogOpen: boolean;
  readonly isCreativeDeliveryLoading: boolean;
  readonly expandedCampaignIds: Set<CampaignId>;
  readonly isBulkAssignDrawerOpen: boolean;
  readonly expandedLineItemIds: Set<LineItemId>;
  readonly defaultColumns: ManageCampaignTableColumn[];
  readonly columnsForUser: ManageCampaignTableColumn[];
  readonly isBulkEditing: boolean;
  readonly selectedTabId: ManageCampaignTabId;
  readonly dates: { readonly startDate: Date; readonly endDate: Date };
  readonly availableDashboardDates: {
    readonly min: Date | undefined;
    readonly max: Date | undefined;
  };
  readonly isFilterByAdvertiserClicked: boolean;
  readonly campaignFilterSelectableValues: {
    readonly loading: boolean;
    readonly query: string;
    readonly cancellationSource: CancelTokenSource | null;
    readonly values: CampaignIdAndName[];
  };
}

interface UpdateCampaignsDashboardAction {
  type: typeof UPDATE_CAMPAIGNS_DASHBOARD;
  payload: Partial<CampaignsDashboardState>;
}

interface CopyAppliedFiltersDashboardAction {
  type: typeof COPY_APPLIED_FILTERS_TO_SELECTED_FILTERS;
}

interface SetCampaignsSelectableValuesSuccess {
  type: typeof SET_CAMPAIGNS_SELECTABLE_VALUES_SUCCESS;
  values: CampaignIdAndName[];
  query: string;
}

interface SetCampaignsSelectableValuesPending {
  type: typeof SET_CAMPAIGNS_SELECTABLE_VALUES_PENDING;
  cancellationSource: CancelTokenSource;
}

interface SetCampaignsSelectableValuesFailure {
  type: typeof SET_CAMPAIGNS_SELECTABLE_VALUES_FAILURE;
}

export type CampaignsDashboardActionTypes =
  | UpdateCampaignsDashboardAction
  | CopyAppliedFiltersDashboardAction
  | SetCampaignsSelectableValuesSuccess
  | SetCampaignsSelectableValuesPending
  | SetCampaignsSelectableValuesFailure;

export enum FullStoryCustomEvent {
  /** The value of this enums members will appear in our FullStory dashboard. */
  FATAL_CRASH = "FATAL_CRASH",
  POST_LOGIN_AUTH_USER_INITIALIZATION_ERROR = "POST_LOGIN_AUTH_USER_INITIALIZATION_ERROR",
  SSO_ATTEMPT = "SSO_ATTEMPT",
  SSO_SUCCESS = "SSO_SUCCESS",
  SSO_FAILURE = "SSO_FAILURE",
  FORECAST_FAILURE = "FORECAST_FAILURE",
  CAMPAIGN_CREATION_FAILURE = "CAMPAIGN_CREATION_FAILURE",
  CAMPAIGN_UPDATE_FAILURE = "CAMPAIGN_UPDATE_FAILURE",
  LINE_ITEM_CREATION_FAILURE = "LINE_ITEM_CREATION_FAILURE",
  FLIGHT_CREATION_FAILURE = "FLIGHT_CREATION_FAILURE",
  CAMPAIGN_DETAILS_GET_FAILURE = "CAMPAIGN_DETAILS_GET_FAILURE",
  AUDIENCE_FORECASTER_ZIP_RANGE_FINDER_OPEN = "AUDIENCE_FORECASTER_ZIP_RANGE_FINDER_OPEN",
  AUDIENCE_FORECASTER_ZIP_RANGE_FINDER_ADD_ZIPS = "AUDIENCE_FORECASTER_ZIP_RANGE_FINDER_ADD_ZIPS",
  DASHBOARD_CSV_REPORT_SUCCESS = "DASHBOARD_CSV_REPORT_SUCCESS",
  DASHBOARD_CSV_REPORT_FAILURE = "DASHBOARD_CSV_REPORT_FAILURE",
  GENERATE_PDF_SUCCESS = "GENERATE_PDF_SUCCESS",
  GENERATE_PDF_FAILURE = "GENERATE_PDF_FAILURE",
  PRODUCT_DETAILS_GET_FAILURE = "PRODUCT_DETAILS_GET_FAILURE",
  PRODUCT_GET_ALL_FAILURE = "PRODUCT_GET_ALL_FAILURE",
  PRODUCT_CREATION_FAILURE = "PRODUCT_CREATION_FAILURE",
  PRODUCT_UPDATE_FAILURE = "PRODUCT_UPDATE_FAILURE",
  DASHBOARD_GET_UNIQUES = "DASHBOARD_GET_UNIQUES",
  DASHBOARD_GET_ZIP = "DASHBOARD_GET_ZIP",
  DASHBOARD_GET_IMPRESSION = "DASHBOARD_GET_IMPRESSION",
  DASHBOARD_GET_CAMPAIGN_SUMMARIES = "DASHBOARD_GET_CAMPAIGN_SUMMARIES",
  DASHBOARD_GET_SUMMARIES = "DASHBOARD_GET_SUMMARIES",
  USER_CREATION_FAILURE = "USER_CREATION_FAILURE",
  USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE",
  LINEAR_MEASUREMENT_GET_REPORTS = "LINEAR_MEASUREMENT_GET_REPORTS",
  LINEAR_MEASUREMENT_DELETE_REPORT = "LINEAR_MEASUREMENT_DELETE_REPORT",
  LINEAR_MEASUREMENT_OTT_GET_REPORTS = "LINEAR_MEASUREMENT_OTT_GET_REPORTS",
  LINEAR_MEASUREMENT_OTT_DELETE_REPORT = "LINEAR_MEASUREMENT_OTT_DELETE_REPORT",
  LINEAR_MEASUREMENT_REPORT_CREATION_FAILURE = "LINEAR_MEASUREMENT_REPORT_CREATION_FAILURE",
  CUSTOM_REPORTING_CREATION = "CUSTOM_REPORTING_CREATION",
  CUSTOM_REPORTING_CREATION_FAILURE = "CUSTOM_REPORTING_CREATION_FAILURE",
  FOOT_TRAFFIC_REPORT_CREATION = "FOOT_TRAFFIC_REPORT_CREATION",
  FOOT_TRAFFIC_REPORT_CREATION_FAILURE = "FOOT_TRAFFIC_REPORT_CREATION_FAILURE",
  FOOT_TRAFFIC_REPORT_ARCHIVE_SUCCESS = "FOOT_TRAFFIC_REPORT_ARCHIVE_SUCCESS",
  FOOT_TRAFFIC_REPORT_ARCHIVE_FAILURE = "FOOT_TRAFFIC_REPORT_ARCHIVE_FAILURE"
}

import {
  DaypartMetrics,
  InventoryDimension,
  InventoryMetrics,
  PlanStatus
} from "features/OOH/Planning/generated-types";
import { AvailableOption } from "components/Reusable/SmithersMultiSelect/SmithersMultiSelect";
import { OohFootTraffic } from "ooh-types";
import { LogicalOperator } from "lib/constants/misc";
import { DaypartsArray } from "types";

export interface Venue {
  slug: string;
  id: string;
  label: string;
  labelWithParents: string;
  children?: Venue[];
}

export const GET_OOH_FOOTTRAFFIC_PENDING = "GET_OOH_FOOTTRAFFIC_PENDING";
export const GET_OOH_FOOTTRAFFIC_SUCCESS = "GET_OOH_FOOTTRAFFIC_SUCCESS";
export const GET_OOH_FOOTTRAFFIC_FAILURE = "GET_OOH_FOOTTRAFFIC_FAILURE";

export interface OohState {
  readonly isLoading: boolean;
  readonly error: string | null;

  readonly footTraffic: OohFootTraffic[] | undefined;
  readonly footTrafficIsLoading: boolean;
  readonly footTrafficError: string | null;

  readonly activeScenarioId: number | null;
  readonly constraints: {
    maxCPM: number | null;
    venues: Venue[];
    publishers: string[];
    poi: string[];
    poiRadius: number;
    bundles: string[];
    sizes: string[];

    venuesOperator: LogicalOperator;
    publishersOperator: LogicalOperator;
    poiOperator: LogicalOperator;
    bundlesOperator: LogicalOperator;
    sizesOperator: LogicalOperator;

    showDayparts: boolean;
    dayparts: DaypartsArray;
    daypartsOperator: LogicalOperator;
  };

  readonly listFilters: {
    selectedDateRange: {
      startDate: string | null;
      endDate: string | null;
    };
    audiences: AvailableOption[];
    statuses: Set<PlanStatus>;
    search: string;
  };

  readonly planFilters: {
    datatable: {
      dimension: InventoryDimension | undefined;
      metrics: InventoryMetrics[];
    };
    heatmap: {
      showAudienceConcentration: boolean;
      showInventory: boolean;
      poiCategories: AvailableOption[];
    };
    barchart: {
      dimension: InventoryDimension;
      metric: InventoryMetrics;
    };
    bubblechart: {
      metric: DaypartMetrics;
    };
    scatterplot: {
      dimension: InventoryDimension;
      x: InventoryMetrics;
      y: InventoryMetrics;
      z: InventoryMetrics;
    };
  };
}

interface GetOohFootTrafficPendingAction {
  type: typeof GET_OOH_FOOTTRAFFIC_PENDING;
}
interface GetOohFootTrafficSuccessAction {
  type: typeof GET_OOH_FOOTTRAFFIC_SUCCESS;
  payload: OohFootTraffic[];
}
interface GetOohFootTrafficFailureAction {
  type: typeof GET_OOH_FOOTTRAFFIC_FAILURE;
  meta: {
    error: {
      message: string;
    };
  };
}

// List Filters
export const SET_LIST_FILTER = "SET_LIST_FILTER";
interface SetListFilterValueAction {
  type: typeof SET_LIST_FILTER;
  prop: string;
  value: any;
}

// Plan Filters
export const SET_PLAN_CONSTRAINT = "SET_PLAN_CONSTRAINT";
interface SetPlanConstraintValueAction {
  type: typeof SET_PLAN_CONSTRAINT;
  prop: string;
  value: any;
}
export const SET_ACTIVE_SCENARIO = "SET_ACTIVE_SCENARIO";
interface SetActiveScenarioAction {
  type: typeof SET_ACTIVE_SCENARIO;
  activeScenarioId: number;
  constraints: any;
}
export const RESET_PLAN_CONSTRAINTS = "RESET_PLAN_CONSTRAINTS";
interface ResetPlanConstraintValuesAction {
  type: typeof RESET_PLAN_CONSTRAINTS;
}
export const SET_PLAN_FILTER = "SET_PLAN_FILTER";
interface SetPlanFilterValueAction {
  type: typeof SET_PLAN_FILTER;
  card: string;
  prop: string;
  value: any;
}

export type OohActionTypes =
  | GetOohFootTrafficPendingAction
  | GetOohFootTrafficSuccessAction
  | GetOohFootTrafficFailureAction
  | ResetPlanConstraintValuesAction
  | SetPlanConstraintValueAction
  | SetActiveScenarioAction
  | SetListFilterValueAction
  | SetPlanFilterValueAction;

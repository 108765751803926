import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Block: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-block"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25A9.75 9.75 0 0 0 2.25 12 9.75 9.75 0 0 0 12 21.75 9.75 9.75 0 0 0 21.75 12 9.75 9.75 0 0 0 12 2.25ZM3.75 12A8.25 8.25 0 0 1 12 3.75c2.008 0 3.849.717 5.28 1.91L5.66 17.28A8.217 8.217 0 0 1 3.75 12Zm2.97 6.34A8.217 8.217 0 0 0 12 20.25 8.25 8.25 0 0 0 20.25 12a8.217 8.217 0 0 0-1.91-5.28L6.72 18.34Z"
      />
    </svg>
  );
};

export default Block;

import { IToastProps } from "@blueprintjs/core";
import { CustomReport, ReportResult } from "@madhive/mad-sdk";

export const GET_REPORTS_PENDING = "GET_REPORTS_PENDING";
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const GET_REPORTS_FAILURE = "GET_REPORTS_FAILURE";

export const REFRESH_REPORTS = "REFRESH_REPORTS";

export const GET_REPORT_DETAILS_PENDING = "GET_REPORT_DETAILS_PENDING";
export const GET_REPORT_DETAILS_SUCCESS = "GET_REPORT_DETAILS_SUCCESS";
export const GET_REPORT_DETAILS_FAILURE = "GET_REPORT_DETAILS_FAILURE";

export const GET_REPORT_CSV_PENDING = "GET_REPORT_CSV_PENDING";
export const GET_REPORT_CSV_SUCCESS = "GET_REPORT_CSV_SUCCESS";
export const GET_REPORT_CSV_FAILURE = "GET_REPORT_CSV_FAILURE";

export const DELETE_REPORT_PENDING = "DELETE_REPORT_PENDING";
export const DELETE_REPORT_SUCCESS = "DELETE_REPORT_SUCCESS";
export const DELETE_REPORT_FAILURE = "DELETE_REPORT_FAILURE";

export const CREATE_REPORT_PENDING = "CREATE_REPORT_PENDING";
export const CREATE_REPORT_SUCCESS = "CREATE_REPORT_SUCCESS";
export const CREATE_REPORT_FAILURE = "CREATE_REPORT_FAILURE";

export const GET_LINEAR_REPORT_WHITE_LIST_PENDING =
  "GET_LINEAR_REPORT_WHITE_LIST_PENDING";
export const GET_LINEAR_REPORT_WHITE_LIST_SUCCESS =
  "GET_LINEAR_REPORT_WHITE_LIST_SUCCESS";
export const GET_LINEAR_REPORT_WHITE_LIST_FAILURE =
  "GET_LINEAR_REPORT_WHITE_LIST_FAILURE";

export const SET_CREATE_REPORT_TYPE = "SET_CREATE_REPORT_TYPE";
export const CLEAR_CREATE_REPORT_TYPE = "CLEAR_CREATE_REPORT_TYPE";
export const SET_REPORT_CSV_METADATA = "SET_REPORT_CSV_METADATA";
export const CLEAR_REPORT_CSV_METADATA = "CLEAR_REPORT_CSV_METADATA";
export const SET_REPORT_CSV_METADATA_AVAILABLE_DIMENSIONS =
  "SET_REPORT_CSV_METADATA_AVAILABLE_DIMENSIONS";

export interface CustomReportingState {
  readonly byId: Record<string, CustomReport>;
  readonly reportCSVMetadata: {
    report?: CustomReport;
    availableDimensions?: string[];
    reportHasOttData?: boolean;
    shouldShowLinearData?: boolean;
    shouldShowDisplayData?: boolean;
  };
  readonly isLoading: boolean;
  readonly isLoaded: boolean;
  readonly isReportDetailsLoading: boolean;
  readonly isNewDimensionLoading: boolean;
  readonly resultsById: Record<string, ReportResult | null> | null;
  // TODO @MaximeHeckel putting this error field for now, let's see if we really need it or not
  readonly error:
    | ({
        message: string;
      } & IToastProps)
    | null;
  readonly isWhiteListLoading: boolean;
  readonly whiteListError: boolean;
  readonly whitelistedUsers: string[] | null;
}

interface GetWhiteListPendingAction {
  type: typeof GET_LINEAR_REPORT_WHITE_LIST_PENDING;
}
interface GetWhiteListSuccessAction {
  type: typeof GET_LINEAR_REPORT_WHITE_LIST_SUCCESS;
  payload: string[];
}

interface GetWhiteListFailureAction {
  type: typeof GET_LINEAR_REPORT_WHITE_LIST_FAILURE;
}

interface GetReportsPendingAction {
  type: typeof GET_REPORTS_PENDING;
}

interface GetReportsSuccessAction {
  type: typeof GET_REPORTS_SUCCESS;
  payload: CustomReport[];
}
interface GetReportsFailureAction {
  type: typeof GET_REPORTS_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface CreateReportPendingAction {
  type: typeof CREATE_REPORT_PENDING;
}

interface CreateReportSuccessAction {
  type: typeof CREATE_REPORT_SUCCESS;
  meta: {
    success: {
      message: string;
    } & IToastProps;
  };
}
interface CreateReportFailureAction {
  type: typeof CREATE_REPORT_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface RefreshReportsAction {
  type: typeof REFRESH_REPORTS;
  payload: CustomReport[];
}

interface GetReportDetailsPendingAction {
  type: typeof GET_REPORT_DETAILS_PENDING;
  payload: boolean | undefined;
}

interface GetReportDetailsSuccessAction {
  type: typeof GET_REPORT_DETAILS_SUCCESS;
  payload: any;
}
interface GetReportDetailsFailureAction {
  type: typeof GET_REPORT_DETAILS_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}
interface GetReportCSVPendingAction {
  type: typeof GET_REPORT_CSV_PENDING;
}

interface GetReportCSVSuccessAction {
  type: typeof GET_REPORT_CSV_SUCCESS;
}
interface GetReportCSVFailureAction {
  type: typeof GET_REPORT_CSV_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface DeleteReportPendingAction {
  meta: {
    id: string;
  };
  type: typeof DELETE_REPORT_PENDING;
}
interface DeleteReportFailureAction {
  type: typeof DELETE_REPORT_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}
interface DeleteReportSuccessAction {
  type: typeof DELETE_REPORT_SUCCESS;
  meta: {
    id: string;
    success: {
      message: string;
    } & IToastProps;
  };
}

interface SetReportCSVMetadata {
  type: typeof SET_REPORT_CSV_METADATA;
  payload: {
    report?: CustomReport;
  };
}

interface SetReportCSVMetadataAvailableDimensions {
  type: typeof SET_REPORT_CSV_METADATA_AVAILABLE_DIMENSIONS;
  payload: {
    availableDimensions: string[];
    reportHasOttData: boolean;
    shouldShowLinearData: boolean;
    shouldShowDisplayData: boolean;
  };
}

interface ClearReportCSVMetadata {
  type: typeof CLEAR_REPORT_CSV_METADATA;
}

export type CustomReportsActionTypes =
  | GetReportsPendingAction
  | GetReportsSuccessAction
  | GetReportsFailureAction
  | CreateReportPendingAction
  | CreateReportSuccessAction
  | CreateReportFailureAction
  | RefreshReportsAction
  | GetReportDetailsPendingAction
  | GetReportDetailsSuccessAction
  | GetReportDetailsFailureAction
  | GetReportCSVPendingAction
  | GetReportCSVSuccessAction
  | GetReportCSVFailureAction
  | SetReportCSVMetadata
  | ClearReportCSVMetadata
  | DeleteReportPendingAction
  | DeleteReportSuccessAction
  | DeleteReportFailureAction
  | SetReportCSVMetadataAvailableDimensions
  | GetWhiteListPendingAction
  | GetWhiteListPendingAction
  | GetWhiteListSuccessAction
  | GetWhiteListFailureAction;

/**
 * @param sort: optional. Information on the field and direction of sorting, if any.
 * @return: a proper query string for the supplied sort.
 */
export const toQueryParams = (sort) => {
    if (sort && sort.field && sort.direction) {
        return `?sortBy=${sort.field}%20${sort.direction}`;
    }
    return "";
};

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Pointer: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-pointer"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2499 8.49998C10.2499 7.25777 11.2577 6.24998 12.4999 6.24998C13.7421 6.24998 14.7499 7.25777 14.7499 8.49998V12C14.7499 12.4142 14.4141 12.75 13.9999 12.75C13.5857 12.75 13.2499 12.4142 13.2499 12V8.49998C13.2499 8.0862 12.9137 7.74998 12.4999 7.74998C12.0861 7.74998 11.7499 8.0862 11.7499 8.49998C11.7499 8.9142 11.4141 9.24998 10.9999 9.24998C10.5857 9.24998 10.2499 8.9142 10.2499 8.49998Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4999 8.75002C15.0861 8.75002 14.7499 9.08623 14.7499 9.50002C14.7499 9.91423 14.4141 10.25 13.9999 10.25C13.5857 10.25 13.2499 9.91423 13.2499 9.50002C13.2499 8.2578 14.2577 7.25002 15.4999 7.25002C16.7421 7.25002 17.7499 8.2578 17.7499 9.50002V13C17.7499 13.4142 17.4141 13.75 16.9999 13.75C16.5857 13.75 16.2499 13.4142 16.2499 13V9.50002C16.2499 9.08623 15.9137 8.75002 15.4999 8.75002Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.2501 10.5C16.2501 9.25779 17.2578 8.25 18.5001 8.25C19.7423 8.25 20.7501 9.25779 20.7501 10.5V16C20.7501 19.7282 17.7283 22.75 14.0001 22.75H11.1671C7.82576 22.75 4.98748 20.3054 4.49137 17.0014L3.98837 13.6473C3.80808 12.446 4.20853 11.231 5.06746 10.3719C5.78547 9.65278 6.7595 9.25 7.77506 9.25H8.00006C8.41427 9.25 8.75006 9.58579 8.75006 10C8.75006 10.4142 8.41427 10.75 8.00006 10.75H7.77506C7.15671 10.75 6.56481 10.9952 6.12885 11.4319C5.60599 11.9547 5.36209 12.6941 5.47175 13.4247L5.97475 16.7786C6.3607 19.3486 8.5684 21.25 11.1671 21.25H14.0001C16.8998 21.25 19.2501 18.8998 19.2501 16V10.5C19.2501 10.0862 18.9138 9.75 18.5001 9.75C18.0863 9.75 17.7501 10.0862 17.7501 10.5C17.7501 10.9142 17.4143 11.25 17.0001 11.25C16.5858 11.25 16.2501 10.9142 16.2501 10.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49987 2.75002C9.08609 2.75002 8.74987 3.08623 8.74987 3.50002V12C8.74987 12.4142 8.41409 12.75 7.99987 12.75C7.58566 12.75 7.24987 12.4142 7.24987 12V3.50002C7.24987 2.2578 8.25766 1.25002 9.49987 1.25002C10.7421 1.25002 11.7499 2.2578 11.7499 3.50002V12C11.7499 12.4142 11.4141 12.75 10.9999 12.75C10.5857 12.75 10.2499 12.4142 10.2499 12V3.50002C10.2499 3.08623 9.91366 2.75002 9.49987 2.75002Z"
      />
    </svg>
  );
};

export default Pointer;

import {
  HHCountState,
  HHCountActionTypes,
  GET_HH_COUNT_PENDING,
  GET_HH_COUNT_SUCCESS,
  GET_HH_COUNT_FAILURE
} from "./types";

export const initialState: HHCountState = {
  byForecastId: {}
};

export const householdsCountReducer = (
  state = initialState,
  action: HHCountActionTypes
): HHCountState => {
  switch (action.type) {
    case GET_HH_COUNT_PENDING:
      return {
        byForecastId: {
          ...state.byForecastId,
          [action.meta.forecastId]: {
            count: null,
            isLoading: true,
            error: false
          }
        }
      };
    case GET_HH_COUNT_SUCCESS:
      return {
        byForecastId: {
          ...state.byForecastId,
          [action.meta.forecastId]: {
            // return of API is always an array length of 1
            count: action.payload[0].households,
            isLoading: false,
            error: false
          }
        }
      };
    case GET_HH_COUNT_FAILURE: {
      return {
        byForecastId: {
          ...state.byForecastId,
          [action.meta.forecastId]: {
            // return of API is always an array length of 1
            count: null,
            isLoading: false,
            error: true
          }
        }
      };
    }
    default:
      return state;
  }
};

import { ReactNode, FC } from "react";
import { css } from "@emotion/react";

interface Props {
  value: ReactNode;
}

const style = {
  root: css`
    flex: 1 1 auto;
    min-width: 0;
    margin-top: 4px;
    margin-bottom: 4px;
  `
};

export const ListItemText: FC<Props> = ({ value }) => (
  <div
    className="list-item-text"
    css={style.root}
    data-testid="smithers-list-item-text"
  >
    {value}
  </div>
);

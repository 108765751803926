import { NotImplementedError } from "../../errors";
import { Observable } from "rxjs";
import { ObservableHandler } from "../handlers";
import { parseIdFilter, filtersHasEmptyIdParam, parseInclusionFilter } from "../handlers/filter";
import axios from "axios";
import { SSPsFetchFailed } from "./errors";
class SSPs extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "ssp");
        this.url = `${this.sdk.urls.baseAPIUrl}/ssp`;
    }
    findItems(filters = {}) {
        return new Observable((subscriber) => {
            const idFilter = parseIdFilter(filters);
            const codeFilter = parseInclusionFilter(filters, "code", ["string"]);
            if (filtersHasEmptyIdParam(filters.where)) {
                subscriber.next([]);
                subscriber.complete();
            }
            else {
                this.getSSPs()
                    .then((data) => {
                    const filterFn = (ssp) => idFilter.size ? idFilter.has(ssp.id) : codeFilter.has(ssp.code);
                    const filteredData = idFilter.size || codeFilter.size ? data.filter(filterFn) : data;
                    subscriber.next(filteredData);
                    subscriber.complete();
                })
                    .catch((error) => {
                    subscriber.error(error);
                });
            }
        });
    }
    /**
     * @return: the array of gotten SSPs.
     */
    async getSSPs() {
        return this.cache.promise(this.url, () => axios
            .get(this.url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => res.data || [])
            .catch((error) => {
            throw new SSPsFetchFailed(error);
        }));
    }
    /**
     * @deprecated
     * SSP does not support make.
     */
    make() {
        return new Promise((_, reject) => {
            reject(new NotImplementedError("make"));
        });
    }
    saveItem() {
        return new Observable((subscriber) => {
            subscriber.error(new NotImplementedError("save"));
        });
    }
    async deleteItem() {
        return new Promise((_, reject) => {
            reject(new NotImplementedError("delete"));
        });
    }
}
export default SSPs;

export const randomString = (length) => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};
// TODO: MAD-2546 - unit tests!
/**
 * @param str: a string that may or may not be defined (or be defined, and empty).
 * @return: the string with .trim() called on it if it is defined, and its length is greater than 0. Else, the string/undefined unmodified.
 */
export const trim = (str) => {
    if (str && str.length > 0) {
        return str.trim();
    }
    return str;
};
export const escapeRegex = (string) => {
    // eslint-disable-next-line no-useless-escape
    return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&");
};
export const snakeToCamelCase = (str) => {
    if (!/[_-]/.test(str)) {
        return str;
    }
    return str
        .toLowerCase()
        .replace(/([-_][A-Za-z]?)/g, (match) => match.toUpperCase().replace("-", "").replace("_", ""));
};
export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter, index) => index === 0 ? letter.toLowerCase() : `_${letter.toLowerCase()}`);
/**
 * Compares both strings and returns true/false for match
 * @param {string} s1
 * @param {string} s2
 * @returns {boolean}
 */
export const equals = (s1, s2) => {
    return s1.localeCompare(s2) === 0;
};
/**
 * Compares both strings and returns true/false if s1 is greater then or equal to s2
 * @param {string} s1
 * @param {string} s2
 * @returns {boolean}
 */
export const gte = (s1, s2) => {
    return s1.localeCompare(s2) >= 0;
};
/**
 * Compares both strings and returns true/false if s1 is greater then s2
 * @param {string} s1
 * @param {string} s2
 * @returns {boolean}
 */
export const gt = (s1, s2) => {
    return s1.localeCompare(s2) > 0;
};
/**
 * Compares both strings and returns true/false if s1 is less then or equal to s2
 * @param {string} s1
 * @param {string} s2
 * @returns {boolean}
 */
export const lte = (s1, s2) => {
    return s1.localeCompare(s2) <= 0;
};
/**
 * Compares both strings and returns true/false if s1 is less then s2
 * @param {string} s1
 * @param {string} s2
 * @returns {boolean}
 */
export const lt = (s1, s2) => {
    return s1.localeCompare(s2) < 0;
};

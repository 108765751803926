import { Intent, IToastProps } from "@blueprintjs/core";
import { AppThunk } from "types";
import {
  SHOW_TOAST,
  HIDE_TOAST,
  CLEAR_TOASTS,
  ToastOptionsType
} from "./types";

export const show = (toastOptions: ToastOptionsType) => ({
  type: SHOW_TOAST,
  ...toastOptions,
  uid: toastOptions.uid || Date.now(),
  message: toastOptions.message
});

export const hide = (uid: number) => ({
  type: HIDE_TOAST,
  uid
});

export const clear = () => ({ type: CLEAR_TOASTS });

export type ToastActionProps = Omit<IToastProps, "message">;

export const showSuccess =
  (message: string, options?: ToastActionProps): AppThunk<void> =>
  dispatch => {
    dispatch(
      show({
        message,
        icon: "endorsed",
        intent: Intent.SUCCESS,
        ...(options || {})
      })
    );
  };

export const showError =
  (message: string, options?: ToastActionProps): AppThunk<void> =>
  dispatch => {
    dispatch(
      show({
        message,
        icon: "error",
        intent: Intent.DANGER,
        ...(options || {})
      })
    );
  };

export const showWarning =
  (message: string, options?: ToastActionProps): AppThunk<void> =>
  dispatch => {
    dispatch(
      show({
        message,
        icon: "warning-sign",
        intent: Intent.WARNING,
        ...(options || {})
      })
    );
  };

export const showInfo =
  (message: string, options?: ToastActionProps): AppThunk<void> =>
  dispatch => {
    dispatch(
      show({
        message,
        icon: "info-sign",
        intent: Intent.PRIMARY,
        ...(options || {})
      })
    );
  };

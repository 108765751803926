import {
  FeatureFlagsState,
  FeatureFlagsActionType,
  SET_FEATURE_FLAGS
} from "./types";

const initialState: FeatureFlagsState = {
  list: {}
};

export const featureFlagsReducer = (
  state = initialState,
  action: FeatureFlagsActionType
): FeatureFlagsState => {
  switch (action.type) {
    case SET_FEATURE_FLAGS: {
      return {
        ...state,
        list: { ...action.payload }
      };
    }
    default:
      return state;
  }
};

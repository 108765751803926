import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const FullCircle: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
    >
      <circle cx="4" cy="4" r="4" fill={fill} />
    </svg>
  );
};

export default FullCircle;

export const buildSort = (sort) => {
    let field = sort.field;
    switch (sort.field) {
        case "id":
            field = "id";
            break;
        case "name":
            field = "name";
            break;
        case "updatedAt":
            field = "updated_at";
            break;
        case "updatedBy":
            field = "updated_by";
            break;
        case "vastUrl":
            field = "vast_url";
            break;
        case "tag":
            field = "tag";
            break;
        case "advertiserId":
            field = "parent";
            break;
        case "advertiserName":
            field = "parent_name";
            break;
        case "isciCode":
            field = "isci_code";
            break;
        case "associatedLineItems":
            field = "associated_lineitems";
            break;
        case "associatedCampaigns":
            field = "associated_campaigns";
            break;
        case "category":
            field = "advertiser_categories";
            break;
        default:
            break;
    }
    return `${field} ${sort.direction}`;
};

import { PremionCampaignRevenueType } from "newnewyork";
import { CampaignStatus } from "@madhive/mad-sdk";

export enum InstructionType {
  CAMPAIGN = "Campaign",
  LINE_ITEM = "Line Item"
}

export { CampaignStatus };

export const CampaignStatusCode: Record<keyof typeof CampaignStatus, string> = {
  INVALID: "0",
  MISSING_LINE_ITEM: "1",
  MISSING_CREATIVE: "2",
  MISSING_WHITELIST: "3",
  READY: "4",
  COMPLETED: "5",
  WAITING: "6",
  NO_ACTIVE_CREATIVE: "7",
  LIVE_WARNING: "8",
  LIVE: "9",
  PAUSED: "10",
  CANCELLED: "11",
  ARCHIVED: "12",
  MISSING_GEO: "13",
  MISSING_PRODUCT: "14",
  MISSING_GOAL: "15",
  CANT_SET_LIVE: "16",
  MISSING_FREQ_CAP: "17",
  MISSING_DETAILS: "999",
  UNKNOWN: ""
};

export type ActionRequiredStatus =
  | CampaignStatus.MISSING_CREATIVE
  | CampaignStatus.MISSING_PRODUCT
  | CampaignStatus.MISSING_LINE_ITEM
  | CampaignStatus.MISSING_WHITELIST
  | CampaignStatus.MISSING_GEO
  | CampaignStatus.MISSING_GOAL
  | CampaignStatus.MISSING_PRODUCT
  | CampaignStatus.MISSING_FREQ_CAP;

export type LineItemActionRequiredInstructionStatus =
  | CampaignStatus.MISSING_CREATIVE
  | CampaignStatus.MISSING_WHITELIST
  | CampaignStatus.MISSING_GEO
  | CampaignStatus.MISSING_GOAL
  | CampaignStatus.MISSING_PRODUCT
  | CampaignStatus.MISSING_FREQ_CAP;

export type CampaignActionRequiredInstructionStatus =
  CampaignStatus.MISSING_LINE_ITEM;

export type UneditableCampaignStatus =
  | CampaignStatus.ARCHIVED
  | CampaignStatus.CANCELLED;

/**
 * Removing "COMPLETED" status from this set as parent campaigns that were once completed can be edited to
 * allow for additional "make good" line items.
 */
export const UNEDITABLE_CAMPAIGN_STATUSES = new Set([
  CampaignStatus.CANCELLED,
  CampaignStatus.ARCHIVED
]);

// needed PRE_LIVE campaign statuses minus WAITING
export const PRE_SET_LIVE_CAMPAIGN_STATUSES = new Set([
  CampaignStatus.READY,
  CampaignStatus.MISSING_WHITELIST,
  CampaignStatus.MISSING_LINE_ITEM,
  CampaignStatus.MISSING_GEO,
  CampaignStatus.MISSING_CREATIVE,
  CampaignStatus.MISSING_GOAL,
  CampaignStatus.MISSING_PRODUCT,
  CampaignStatus.MISSING_FREQ_CAP
]);

export const PRE_LIVE_CAMPAIGN_STATUSES = new Set([
  CampaignStatus.READY,
  CampaignStatus.WAITING,
  CampaignStatus.CANT_SET_LIVE,
  CampaignStatus.MISSING_WHITELIST,
  CampaignStatus.MISSING_LINE_ITEM,
  CampaignStatus.MISSING_GEO,
  CampaignStatus.MISSING_CREATIVE,
  CampaignStatus.MISSING_GOAL,
  CampaignStatus.MISSING_PRODUCT,
  CampaignStatus.MISSING_FREQ_CAP
]);

export const NON_READY_CAMPAIGN_STATUSES = new Set<CampaignStatus>([
  CampaignStatus.ARCHIVED,
  CampaignStatus.CANCELLED,
  CampaignStatus.COMPLETED,
  CampaignStatus.INVALID,
  CampaignStatus.MISSING_CREATIVE,
  CampaignStatus.MISSING_LINE_ITEM,
  CampaignStatus.MISSING_WHITELIST,
  CampaignStatus.MISSING_GEO,
  CampaignStatus.MISSING_GOAL,
  CampaignStatus.MISSING_PRODUCT,
  CampaignStatus.MISSING_FREQ_CAP
]);

/**
 * Only difference between this constant and the `UNEDITABLE_CAMPAIGN_STATUSES` constant is that completed
 * line items should not be editable.
 */
export const UNEDITABLE_LINE_ITEM_STATUSES = new Set([
  CampaignStatus.COMPLETED,
  CampaignStatus.CANCELLED,
  CampaignStatus.ARCHIVED
]);

export enum DEVICE_TYPES {
  TV = "tv",
  MOBILE = "mobile",
  TABLET = "tablet",
  DESKTOP = "desktop"
}

export const PREMION_REVENUE_TYPES: PremionCampaignRevenueType[] = [
  "Local",
  "Channel Partner",
  "Regional",
  "Promo",
  "Promo - Case Study",
  "National"
];

export enum AssetUploadTypes {
  FILE = "video/mp4",
  FOLDER = "folder"
}

export interface UploadedAssetGroup {
  timeStamp: Date;
  files: File[];
}

export enum BulkAssetProgressTypes {
  ERROR = "error",
  POSTED = "posted",
  VALIDATED = "validated",
  CLOUD_STORAGE = "cloudStorage",
  INITIATED = "initiated"
}

export enum BulkCreativeAssetType {
  DESKTOP = "desktop",
  CDN_VAST = "cdnVast"
}

export enum BulkCreativeUploaderTabs {
  CHOOSE_UPLOAD_TYPE = "chooseUploadType",
  UPLOAD_CREATIVES = "upload"
}

export const VALID_VIDEO_EXTENSION = "video/mp4";

export interface AssetGroupDetails {
  timestamp: Date;
  fileIdx: number;
  groupIdx: number;
}

export interface FlattenedAsset {
  file: File;
  innerIdx: number;
  groupIdx: number;
  timestamp: Date;
}

export interface BulkXlsRow {
  assetFileName: string;
  creativeId: string;
  creativeName: string;
  iabCategoryId?: string;
  iabSubcategoryId?: string;
  isciCode?: string;
  impressionPixel?: string;
  clickthroughUrl: string;
  videoStartPixel?: string;
  firstQuartilePixel?: string;
  midpointPixel?: string;
  thirdQuartilePixel?: string;
  videoCompletePixel?: string;
}

export interface BulkXlsColumnHeadings {
  assetFileName: number;
  creativeId: number;
  creativeName: number;
  iabCategoryId?: number;
  iabSubcategoryId?: number;
  isciCode: number;
  impressionPixel: number;
  clickthroughUrl: number;
  videoStartPixel: number;
  firstQuartilePixel: number;
  midpointPixel: number;
  thirdQuartilePixel: number;
  videoCompletePixel: number;
}

export interface XlsToUploadedAssetMap {
  assetFileName: string;
  assetGroupDetails?: AssetGroupDetails;
  creativeId?: string;
  isCreativeIdDuplicate: boolean;
  isXlsRowDuplicate: boolean;
  isAssetDuplicate: boolean;
  isAssetCorrupted: boolean;
}

export interface FileMappedToMetadata {
  file: File;
  metadata: BulkXlsRow;
}

export enum AssetRowError {
  CORRUPTED = "Corrupted File",
  DUPLICATE = "Duplicate File",
  DUPLICATE_ID = "Duplicate Creative Id",
  MISSING_FROM_XLS = "Asset is present in uploaded files but missing in spreadsheet",
  MISSING_FROM_UPLOADED_ASSETS = "Creative specified in template is missing from the spreadsheet"
}

export interface TemplateData {
  ID: string;
  Filename: string;
}

export enum LogicalOperator {
  INCLUDE = "include",
  EXCLUDE = "exclude"
}

export { DayOfTheWeek } from "@madhive/mad-sdk";

export const MOCK_ADOMAIN = "madhive.com";

export const AUDIENCE_ALREADY_EXISTS_HTTP_STATUS_CODE = 303;

export var NotificationStatuses;
(function (NotificationStatuses) {
    NotificationStatuses["IN_QUEUE"] = "IN_QUEUE";
    NotificationStatuses["SENT"] = "SENT";
    NotificationStatuses["ARCHIVED"] = "ARCHIVED";
})(NotificationStatuses || (NotificationStatuses = {}));
export var NotificationCategory;
(function (NotificationCategory) {
    NotificationCategory["CREATIVE_DELIVERY"] = "creative-delivery";
    NotificationCategory["DOWNLOAD"] = "download";
    NotificationCategory["ACTION"] = "action";
    NotificationCategory["RELEASE"] = "release";
    NotificationCategory["MAINTENANCE"] = "maintenance";
    NotificationCategory["WORKFLOW"] = "workflow";
    NotificationCategory["BULK_CREATIVE_UPLOAD"] = "creative-upload";
    NotificationCategory["BULK_CAMPAIGN_UPLOAD"] = "campaign upload";
    NotificationCategory["BULK_EDIT_LINE_ITEM"] = "bulk-edit-line-item";
})(NotificationCategory || (NotificationCategory = {}));

import { IToastProps } from "@blueprintjs/core";
import { CancelTokenSource } from "axios";
import { LineItemFormatted, LineItemId, ShallowLineItem } from "campaign-types";
import { CreateAndUpdateLineItemEndpointPostBody } from "newnewyork";

export type LineItemFetchMap = Record<
  LineItemId,
  {
    isLoading: boolean;
    data: LineItemFormatted | null;
  }
>;

export const ABORT_GET_ALL_LINE_ITEMS_REQUEST =
  "ABORT_GET_ALL_LINE_ITEMS_REQUEST";

export const ABORT_CREATE_LINE_ITEM_REQUEST = "ABORT_CREATE_LINE_ITEM_REQUEST";

export const ABORT_ARCHIVE_LINE_ITEM_REQUEST =
  "ABORT_ARCHIVE_LINE_ITEM_REQUEST";

export const ABORT_UPDATE_LINE_ITEM_REQUEST = "ABORT_UPDATE_LINE_ITEM_REQUEST";

export const CREATE_LINE_ITEM_PENDING = "CREATE_LINE_ITEM_PENDING";
export const CREATE_LINE_ITEM_SUCCESS = "CREATE_LINE_ITEM_SUCCESS";
export const CREATE_LINE_ITEM_FAILURE = "CREATE_LINE_ITEM_FAILURE";

export const UPDATE_LINE_ITEM_PENDING = "UPDATE_LINE_ITEM_PENDING";
export const UPDATE_LINE_ITEM_FAILURE = "UPDATE_LINE_ITEM_FAILURE";
export const UPDATE_LINE_ITEM_SUCCESS = "UPDATE_LINE_ITEM_SUCCESS";

export const DELETE_LINE_ITEM_PENDING = "DELETE_LINE_ITEM_PENDING";
export const DELETE_LINE_ITEM_FAILURE = "DELETE_LINE_ITEM_FAILURE";
export const DELETE_LINE_ITEM_SUCCESS = "DELETE_LINE_ITEM_SUCCESS";

export const GET_LINE_ITEM_DETAILS_PENDING = "GET_LINE_ITEM_DETAILS_PENDING";
export const GET_LINE_ITEM_DETAILS_FAILURE = "GET_LINE_ITEM_DETAILS_FAILURE";
export const GET_LINE_ITEM_DETAILS_SUCCESS = "GET_LINE_ITEM_DETAILS_SUCCESS";

export const SET_SELECTED_FOR_BULK_ACTION = "SET_SELECTED_FOR_BULK_ACTION";

export interface lineItemsState {
  readonly byId: Record<
    string,
    ShallowLineItem | CreateAndUpdateLineItemEndpointPostBody
  >;
  readonly loadCreateCancellationSource: CancelTokenSource | null;
  readonly loadArchiveCancellationSource: CancelTokenSource | null;
  readonly loadUpdateCancellationSource: CancelTokenSource | null;
  readonly receivedAllAt: number | null;
  readonly isSaving: boolean;
  readonly details: LineItemFetchMap;
  readonly selectedForBulkAction: ShallowLineItem[];
}

interface AbortGeLineItemsRequestAction {
  type: typeof ABORT_GET_ALL_LINE_ITEMS_REQUEST;
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

interface AbortCreateLineItemRequestAction {
  type: typeof ABORT_CREATE_LINE_ITEM_REQUEST;
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

interface AbortArchiveLineItemRequestAction {
  type: typeof ABORT_ARCHIVE_LINE_ITEM_REQUEST;
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

interface AbortUpdateLineItemRequestAction {
  type: typeof ABORT_UPDATE_LINE_ITEM_REQUEST;
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

interface CreateLineItemPendingAction {
  type: typeof CREATE_LINE_ITEM_PENDING;
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

interface CreateLineItemSuccessAction {
  type: typeof CREATE_LINE_ITEM_SUCCESS;
  payload: CreateAndUpdateLineItemEndpointPostBody;
  meta: {
    success: {
      message: string;
    } & IToastProps;
  };
}
interface CreateLineItemFailureAction {
  type: typeof CREATE_LINE_ITEM_FAILURE;
  meta: any;
}

interface UpdateLineItemPendingAction {
  type: typeof UPDATE_LINE_ITEM_PENDING;
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

interface UpdateLineItemSuccessAction {
  type: typeof UPDATE_LINE_ITEM_SUCCESS;
  payload: string;
}

interface UpdateLineItemFailureAction {
  type: typeof UPDATE_LINE_ITEM_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface DeleteLineItemPendingAction {
  type: typeof DELETE_LINE_ITEM_PENDING;
  meta: { cancellationSource: CancelTokenSource };
}

interface DeleteLineItemFailureAction {
  type: typeof DELETE_LINE_ITEM_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface DeleteLineItemSuccessAction {
  type: typeof DELETE_LINE_ITEM_SUCCESS;
  payload: string;
}

interface GetLineItemsDetailsPendingAction {
  type: typeof GET_LINE_ITEM_DETAILS_PENDING;
  meta: {
    lineItemFetchMap: LineItemFetchMap;
  };
}

interface GetLineItemsDetailsFailureAction {
  type: typeof GET_LINE_ITEM_DETAILS_FAILURE;
  payload: LineItemFetchMap;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface GetLineItemsDetailsSuccessAction {
  type: typeof GET_LINE_ITEM_DETAILS_SUCCESS;
  payload: LineItemFetchMap;
}

interface SetSelectedForBulkActionAction {
  type: typeof SET_SELECTED_FOR_BULK_ACTION;
  payload: ShallowLineItem[];
}

export type lineItemsActionTypes =
  | AbortCreateLineItemRequestAction
  | AbortArchiveLineItemRequestAction
  | AbortUpdateLineItemRequestAction
  | AbortGeLineItemsRequestAction
  | CreateLineItemPendingAction
  | UpdateLineItemPendingAction
  | DeleteLineItemPendingAction
  | DeleteLineItemFailureAction
  | DeleteLineItemSuccessAction
  | CreateLineItemSuccessAction
  | CreateLineItemFailureAction
  | UpdateLineItemSuccessAction
  | UpdateLineItemFailureAction
  | GetLineItemsDetailsPendingAction
  | GetLineItemsDetailsFailureAction
  | GetLineItemsDetailsSuccessAction
  | SetSelectedForBulkActionAction;

import { FC } from "react";
import { styles } from "./styles";
import { CalendarMonthProps } from "./types";
import { CalendarWeek } from "./Week";

export const CalendarMonth: FC<CalendarMonthProps> = ({
  month,
  min,
  max,
  selectDay
}) => {
  return (
    <div className="subKit-CalendarMonth" css={[styles.month.grid]}>
      {month.map((week, weekIndex) => (
        <CalendarWeek
          key={weekIndex}
          week={week}
          min={min}
          max={max}
          selectDay={selectDay}
        />
      ))}
    </div>
  );
};

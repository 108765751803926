export const serviceAgencyToAgency = (agency) => {
    return {
        id: agency.id,
        name: agency.name,
        whitelists: agency.whitelists,
        externalId: agency.external_id,
        status: agency.status
    };
};
export const agencyToServiceAgency = (agency) => {
    return {
        id: agency.id,
        name: agency.name.trim(),
        whitelists: agency.whitelists,
        external_id: agency.externalId,
        status: agency.status
    };
};

export const requiredOptions = (values) => {
    if (!Array.isArray(values) || values.length === 0) {
        return `This field is required`;
    }
    return;
};
export const requiredString = (value) => {
    return !value?.length ? "This field is required" : undefined;
};
// TODO: Moved this filter validation here for now.
export const filtersValidation = (filters) => {
    let filterErrors = {};
    for (const [filter, values] of Object.entries(filters)) {
        const isFilterUndefined = filter === "undefined";
        const isFilterValuesUndefined = !values.length;
        if (isFilterUndefined || isFilterValuesUndefined) {
            filterErrors = {
                ...filterErrors,
                [filter]: {
                    filterId: isFilterUndefined ? "This field is required" : "",
                    filterValues: isFilterValuesUndefined ? "This field is required" : ""
                }
            };
        }
    }
    return filterErrors;
};

import { Spinner } from "madhive/components";

import { css } from "@emotion/css";
import { ReactNode, FC, useMemo, isValidElement } from "react";
import Layout from "../Layout";
import PageHeader from "../SmithersPageHeader";
import SmithersCard from "../SmithersCard";

const boxShadow = css`
  box-shadow: var(--shadow-3) !important;
  border-radius: 0px !important;
`;

interface Props {
  title?: string | ReactNode;
  backButtonProps?: {
    to: string;
    onClick?: () => void;
  };
  fullWidth?: boolean;
  loadingElement?: JSX.Element;
  lowPadding?: boolean;
  fullContent?: boolean;
}

const SkeletonPage: FC<Props> = props => {
  const {
    title: header = "Loading...",
    fullWidth,
    backButtonProps,
    loadingElement,
    lowPadding = false,
    fullContent = false
  } = props;

  const SpinnerCard = useMemo(
    () => (
      <SmithersCard.Card grow centerContent sharpCorners={fullContent}>
        {loadingElement || <Spinner data-testid="smithers-skeleton-progress" />}
      </SmithersCard.Card>
    ),
    [fullContent, loadingElement]
  );

  return (
    <>
      <PageHeader.Wrapper
        data-testid="smithers-sekeleton-page-header"
        sticky
        fullWidth
        lowPadding={lowPadding}
        hasNoBottomMargin={fullContent}
        className={fullContent ? boxShadow : ""}
      >
        {backButtonProps ? (
          <PageHeader.BackButton
            to={backButtonProps.to}
            onClick={backButtonProps.onClick}
          />
        ) : null}

        {/* If `header` is a valid react node, render it without a wrapping element. */}
        {isValidElement(header) ? (
          header
        ) : (
          <PageHeader.Title data-testid="smithers-skeleton-title">
            {header as string}
          </PageHeader.Title>
        )}
        {/* Need to render this is we're rendering the back button in order to ensure correct spacing. */}
        {backButtonProps ? <PageHeader.ActionsContainer /> : null}
      </PageHeader.Wrapper>

      {fullContent ? (
        SpinnerCard
      ) : (
        <Layout fillPage fullWidth={fullWidth}>
          {SpinnerCard}
        </Layout>
      )}
    </>
  );
};

class SmithersSkeleton {
  public static Page = SkeletonPage;
}

export default SmithersSkeleton;

import {
  createContext,
  useState,
  useMemo,
  useCallback,
  FC,
  PropsWithChildren
} from "react";
import { LineItem } from "@madhive/mad-sdk";
import { ProcessingListItem } from "frontier/lib/components/ProcessingList";
import ProcessingPane, {
  ProcessingListContextValue
} from "frontier/lib/components/ProcessingPane";
import { downloadBlob } from "frontier/lib/utils/files";
import { madSDK } from "lib/sdk";
import { downloadCSV, Extension } from "lib/utils/csv";
import { generateErrorCsvRows } from "./utils";

type BulkEditLineItemsContextValue = ProcessingListContextValue & {
  bulkEdit: (edits: Partial<LineItem>[]) => Promise<void>;
};

export const BulkEditLineItemsContext =
  createContext<BulkEditLineItemsContextValue>({
    items: [],
    setItems: () => {},
    bulkEdit: async () => {}
  });

export const useBulkEditLineItems = () => {
  const [items, setItems] = useState<ProcessingListItem[]>([]);

  const itemsMemoized = useMemo(() => items, [items]);

  const bulkEdit = useCallback(async (edits: Partial<LineItem>[]) => {
    const jobId = new Date().valueOf();
    const jobName = `Bulk Edit ${edits.length} Line Items`;

    setItems(prev => [
      ...prev,
      {
        id: jobId,
        text: jobName,
        status: "loading"
      }
    ]);

    return madSDK.campaigns.lineItems.bulk
      .save(edits)
      .then(csv => {
        const filename = "Bulk Edit Line Items Report";
        setItems(prev => [
          ...prev.filter(job => job.id !== jobId),
          {
            id: jobId,
            text: jobName,
            status: "success"
          },
          {
            id: `${jobId}-csv`,
            text: `${filename}.csv`,
            status: "success",
            button: {
              label: "Download",
              callback: () =>
                downloadBlob(new Blob([csv]), `${filename}.${Extension.CSV}`)
            }
          }
        ]);
      })
      .catch(error => {
        const errorCsvRows = generateErrorCsvRows(
          Array.isArray(error) ? error : [],
          edits
        );

        setItems(prev => [
          ...prev.filter(job => job.id !== jobId),
          {
            id: jobId,
            text: `${jobName} - failed`,
            status: "error",
            button: errorCsvRows.length
              ? {
                  label: "download",
                  callback: () =>
                    downloadCSV(
                      errorCsvRows,
                      ["Line Item ID", "Error"],
                      "Bulk edit errors"
                    )
                }
              : {
                  label: "retry",
                  callback: () => {
                    setItems(previous =>
                      previous.filter(job => job.id !== jobId)
                    );
                    bulkEdit(edits);
                  }
                }
          }
        ]);
      });
  }, []);

  return {
    items: itemsMemoized,
    setItems,
    bulkEdit
  };
};

export const BulkEditLineItemsContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const value = useBulkEditLineItems();

  return (
    <BulkEditLineItemsContext.Provider value={value}>
      {children}
      <ProcessingPane
        title="Bulk Edit upload"
        items={value.items}
        setItems={value.setItems}
      />
    </BulkEditLineItemsContext.Provider>
  );
};

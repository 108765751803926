import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";

const X: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-circled-x"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M15.3603 8.63968C15.6532 8.93258 15.6532 9.40745 15.3603 9.70034L13.0607 12L15.3603 14.2997C15.6532 14.5926 15.6532 15.0674 15.3603 15.3603C15.0674 15.6532 14.5926 15.6532 14.2997 15.3603L12 13.0607L9.70033 15.3603C9.40743 15.6532 8.93256 15.6532 8.63967 15.3603C8.34677 15.0674 8.34677 14.5926 8.63967 14.2997L10.9393 12L8.63967 9.70034C8.34677 9.40745 8.34677 8.93258 8.63967 8.63968C8.93256 8.34679 9.40743 8.34679 9.70033 8.63968L12 10.9394L14.2997 8.63968C14.5926 8.34679 15.0674 8.34679 15.3603 8.63968Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25C6.61479 2.25 2.25 6.61479 2.25 12C2.25 17.3852 6.61479 21.75 12 21.75C17.3852 21.75 21.75 17.3852 21.75 12C21.75 6.61479 17.3852 2.25 12 2.25ZM3.75 12C3.75 7.44321 7.44321 3.75 12 3.75C16.5568 3.75 20.25 7.44321 20.25 12C20.25 16.5568 16.5568 20.25 12 20.25C7.44321 20.25 3.75 16.5568 3.75 12Z"
      />
    </svg>
  );
};

export default X;

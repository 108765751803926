import { css } from "@emotion/react";

export const loadingHiddenVisibility = css`
  visibility: hidden;
  display: flex;
`;

export const spinner = css`
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: calc(var(--spacing-8) * -1);
  margin-left: calc(var(--spacing-8) * -1);
`;

export const primaryContained = `
  background-color: var(--primary-color);
  border: none;
  padding: var(--spacing-8) var(--spacing-16);

  span.bp4-button-text {
    color: var(--brand-background);

    span.bp4-icon.bp4-icon:first-of-type:last-child {
      color: var(--brand-background);
    }
  }

  &:hover {
    background-color: var(--primary-hover);
  }

  &:focus {
    background-color: var(--primary-focus);
  }

  &.bp4-disabled,
  &.bp4-disabled:hover {
    background-color: var(--gray-2);
  }
`;

export const primaryOutlined = `
  background-color: var(--brand-background);
  border: 2px solid;
  border-color: var(--primary-color);
  padding: var(--spacing-8) var(--spacing-16);

  span.bp4-button-text {
    color: var(--primary-color);

    span.bp4-icon.bp4-icon:first-of-type:last-child {
      color: var(--primary-color);
    }
  }

  &:hover {
    background-color: var(--ghost-hover);
  }

  &:focus {
    background-color: var(--ghost-focus);
  }

  &.bp4-disabled,
  &.bp4-disabled:hover {
    background: transparent;
    border-color: var(--gray-2);
  }
`;

export const primaryText = `
  background-color: transparent;
  box-shadow: none;
  padding: var(--spacing-8) var(--spacing-0);

  span.bp4-button-text {
    color: var(--primary-color);
    span.bp4-icon.bp4-icon:first-of-type:last-child {
      color: var(--primary-color);
    }
  }
  
  &:hover {
    span.bp4-button-text {
      color: var(--primary-hover);
      span.bp4-icon.bp4-icon:first-of-type:last-child {
        color: var(--primary-hover);
      }
    }
  }

  &:focus {
    span.bp4-button-text {
      color: var(--primary-focus);
      span.bp4-icon.bp4-icon:first-of-type:last-child {
        color: var(--primary-focus);
      }
    }
  }
`;

export const danger = `
  background-color: var(--danger-color);
  border: none;
  padding: var(--spacing-8) var(--spacing-16);

  span.bp4-button-text {
    color: var(--brand-background);

    span.bp4-icon.bp4-icon:first-of-type:last-child {
      color: var(--brand-background);
    }
  }

  &:hover {
    background-color: var(--danger-hover);
  }

  &:focus {
    background-color: var(--danger-focus);
  }

  &.bp4-disabled,
  &.bp4-disabled:hover {
    background-color: var(--gray-2);
  }
`;

export const secondary = `
  background-color: var(--secondary-color);
  border: none;
  padding: var(--spacing-8) var(--spacing-16);

  span.bp4-button-text {
    color: var(--brand-background);

    span.bp4-icon.bp4-icon:first-of-type:last-child {
      color: var(--brand-background);
    }
  }

  &:hover {
    background-color: var(--secondary-hover);
  }

  &:focus {
    background-color: var(--secondary-focus);
  }

  &.bp4-disabled,
  &.bp4-disabled:hover {
    background-color: var(--gray-2);
  }
`;

export const icon = `
  background-color: transparent;
  padding: var(--spacing-8) var(--spacing-16);

  span.bp4-button-text {
    span.bp4-icon.bp4-icon:first-of-type:last-child {
      color: var(--gray-5);
    }
  }

  &:disabled {
    background-color: transparent;
  }

  &:hover,
  &:focus {
    .bp4-icon svg {
      fill: var(--primary-hover);
    }
  }
`;

import axios from "axios";
import { Observable } from "rxjs";
import { NotImplementedError, UnauthenticatedError } from "../../errors";
import { ObservableHandler } from "../handlers";
import { FilterTypes, FilterValueTypes, validateFilterStructure } from "../handlers/filter";
import { isSettings } from "../../models/user";
import { SettingsFetchFailed, SettingsSaveFailed, SettingsSaveMissingIdsError } from "./errors";
/**
 * Class to handle user settings.
 */
class UserSettings extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "user-settings");
        /**
         * @param settings: a map from user ID to settings to save, or a single settings representation.
         * @param ids: optional. A list of ids to assign the settings rep to. This is ignored if param settings is a map from ID to settings reps.
         * @return: an observable, containing a key-value map from user ID to the updated settings representations.
         * @errors: if the request structure is invalid, or there is an issue saving.
         */
        this.save = (settings, ids) => {
            return this.saveItem(settings, ids);
        };
        /**
         * @param filters: the filters to parse.
         * @return: all ID filters from the given filters, coalesced. Else, null.
         */
        this.getIdFilter = (filters) => {
            const idFilters = filters?.where?.filter((filter) => filter.field === "id") || null;
            if (!idFilters || idFilters.length === 0) {
                return null;
            }
            const ids = [];
            idFilters.forEach((filter) => {
                if (filter.type === FilterTypes.EQ || filter.type === FilterTypes.IN) {
                    if (typeof filter.value === "string") {
                        ids.push(filter.value);
                    }
                    else if (Array.isArray(filter.value) &&
                        filter.value.length > 0 &&
                        typeof filter.value[0] === "string") {
                        filter.value.forEach((id) => {
                            ids.push(String(id));
                        });
                    }
                }
            });
            return ids.length > 0 ? ids : null;
        };
        /**
         * @param ids: the IDs to filter on.
         * @return: determines if the IDs to filter on correspond only to the current user (i.e., there's one ID, and it's the current user's ID).
         */
        this.isForCurrentUserOnly = (ids) => {
            if (typeof ids === "undefined") {
                return false;
            }
            return ids.length === 1 && ids[0] === this.sdk.getCurrentUser()?.id;
        };
        this.url = (route, query) => `${this.sdk.urls.burnsBaseUrl}/${route}/settings${query || ""}`;
    }
    findItems(filters) {
        validateFilterStructure(filters, [
            {
                filterType: FilterTypes.EQ,
                valueType: FilterValueTypes.STRING
            },
            {
                filterType: FilterTypes.IN,
                valueType: FilterValueTypes.OBJECT
            }
        ]);
        return new Observable((subscriber) => {
            if (typeof this.sdk.getCurrentUser() === "undefined") {
                subscriber.error(new UnauthenticatedError());
                return;
            }
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            const ids = this.getIdFilter(filters) || [this.sdk.getCurrentUser().id];
            const { cached, missed } = this.getFromCache(ids);
            if (cached.size > 0) {
                subscriber.next(cached);
            }
            if (missed.length > 0) {
                this.fetch(missed)
                    .then((fetched) => {
                    subscriber.next(fetched);
                    subscriber.complete();
                })
                    .catch((error) => subscriber.error(error));
            }
            else {
                subscriber.complete();
            }
        });
    }
    saveItem(settings, ids) {
        return new Observable((subscriber) => {
            if (settings instanceof Map) {
                ids = Array.from(settings.keys());
            }
            if (typeof ids === "undefined" || ids.length === 0) {
                subscriber.error(new SettingsSaveMissingIdsError());
                return;
            }
            this.put(settings, ids)
                .then((saved) => {
                subscriber.next(saved);
                subscriber.complete();
            })
                .catch((error) => {
                subscriber.error(error);
            });
        });
    }
    /**
     * Make isn't implemented for users.
     */
    make() {
        throw new NotImplementedError("make");
    }
    /** @deprecated */
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    deleteItem(ids) {
        throw new NotImplementedError("delete");
    }
    /**
     * Queries the cache for the given set of IDs.
     * @param ids: the ids of the users to fetch settings for.
     * @return: a map from IDs to settings of cached values, and the array of misses.
     *  - cached is empty is there are no cached
     *  - missed is empty if there are no misses
     */
    getFromCache(ids) {
        const cached = new Map();
        const missed = [];
        ids.forEach((id) => {
            if (this.cache.has(id)) {
                cached.set(id, this.cache.get(id));
            }
            else {
                missed.push(id);
            }
        });
        return {
            cached,
            missed
        };
    }
    /**
     * Makes a network request to get the given IDs.
     * @param ids: the ids of the users to fetch settings for.
     * @return: a map of the IDs to the users' settings.
     */
    fetch(ids) {
        const route = this.isForCurrentUserOnly(ids) ? "account" : "admin/users";
        const query = route === "account" ? undefined : `?ids=${ids.join("&ids=")}`;
        return axios
            .get(this.url(route, query), {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => this.processResponse(ids, res.data))
            .catch((error) => {
            throw new SettingsFetchFailed(error);
        });
    }
    /**
     * @param settings: the settings to save. Either a single set of settings, or a map from user ID to the individual settings rep.
     * @param ids: the IDs of the users receiving settings updates. If settings is a map, this is the same as Array.from(settings.keys()).
     * @return: a map from user ID to settings, where the settings are the results of the save call.
     */
    put(settings, ids) {
        const route = this.isForCurrentUserOnly(ids) ? "account" : "admin/users";
        // remove the cache while we're updating - we'll re-set it while processing the response
        ids.forEach((id) => {
            this.cache.remove(id);
        });
        return axios
            .put(this.url(route), {
            settings,
            ids: settings instanceof Map ? undefined : ids
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => this.processResponse(ids, res.data))
            .catch((error) => {
            throw new SettingsSaveFailed(error);
        });
    }
    /**
     * Processes a request response, and updates the cache.
     * @param ids: the ids corresponding to the settings
     * @param data: a response for settings requests.
     * @return: the settings in a key-value map from user ID to settings.
     */
    processResponse(ids, data) {
        const parsed = new Map();
        if (isSettings(data)) {
            ids.forEach((id) => {
                parsed.set(id, data);
                this.cache.set(id, data);
            });
        }
        else {
            Object.entries(data).forEach(([id, settings]) => {
                parsed.set(id, settings);
                this.cache.set(id, settings);
            });
        }
        return parsed;
    }
}
export default UserSettings;

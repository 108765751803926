import { GeoTargets, GeoTargetsKeys } from "@madhive/mad-sdk";
import { GeographicEntityTypes } from "lib/utils/geography";
import { LogicalOperator } from "../../lib/constants/misc";

export interface LineItemGeoTargetingState extends LineitemGeoEntitiesState {
  targetingTranslatedToZipCodes: Set<string>;
  isLineItemTargetingMappedToState: boolean;
  currentCountry: string;
}

export type LineitemGeoEntitiesState = Record<
  GeographicEntityTypes,
  {
    entities: string[];
    selectType: LogicalOperator;
  }
>;

export const UPDATE_ENTITIES = "UPDATE_ENTITIES";
export const UPDATE_SELECT_TYPE = "UPDATE_SELECT_TYPE";
export const SET_IS_LINEITEM_TARGETING_MAPPED_TO_STATE =
  "SET_IS_LINEITEM_TARGETING_MAPPED_TO_STATE";
export const RESET_STATE = "RESET_STATE";

type GeoTargetsOverride = Pick<
  GeoTargets,
  Exclude<keyof GeoTargets, GeoTargetsKeys.DMA>
> & {
  [GeoTargetsKeys.DMA]: string[];
};

export interface GeoTargetingDetailsOverride {
  include: GeoTargetsOverride;
  exclude: GeoTargetsOverride;
}

interface ResetStateAction {
  type: typeof RESET_STATE;
}

interface UpdateEntitiesAction {
  type: typeof UPDATE_ENTITIES;
  payload: {
    entities: string[];
    targetingTranslatedToZips: Set<string>;
  };
  meta: GeographicEntityTypes;
}

interface UpdateSelectTypeAction {
  type: typeof UPDATE_SELECT_TYPE;
  payload: {
    selectType: LogicalOperator;
    targetingTranslatedToZips: Set<string>;
  };
  meta: GeographicEntityTypes;
}

interface SetIsUserEditingAction {
  type: typeof SET_IS_LINEITEM_TARGETING_MAPPED_TO_STATE;
  payload: {
    lineItemTargeting: LineitemGeoEntitiesState;
    targetingTranslatedToZips: Set<string>;
    currentCountry: string;
  };
}

export type LineItemGeoTargetingActionTypes =
  | ResetStateAction
  | UpdateEntitiesAction
  | UpdateSelectTypeAction
  | SetIsUserEditingAction;

import { Pixel } from "@madhive/mad-sdk";
import { createSelector } from "reselect";
import { RootState } from "rootReducer";

export const selectLastReceivedAllPixels = (state: RootState) =>
  state.trackingPixels.receivedAllAt;

export const selectUpdatingIds = (state: RootState) =>
  state.trackingPixels.updatingIds;

export const selectIsPixelSaving = (state: RootState) =>
  state.trackingPixels.isPixelSaving;

export const selectPixelsById = (state: RootState) => state.trackingPixels.byId;

export const selectPixelsAsArray = createSelector(
  selectPixelsById,
  (results: Record<string, Pixel>) => Object.values(results)
);

export const selectAvailablePixelsById = (state: RootState) =>
  state.trackingPixels.availableById;

export const selectAvailablePixelsAsArray = createSelector(
  selectAvailablePixelsById,
  (results: Record<string, Pixel>) => Object.values(results)
);

export const selectArchivedPixels = (state: RootState): Record<string, Pixel> =>
  state.trackingPixels.archivedById;

export const selectArchivedPixelsAsArray = createSelector(
  selectArchivedPixels,
  (results: Record<string, Pixel>) => Object.values(results)
);

export const selectPixelsError = (state: RootState) =>
  state.trackingPixels.pixelsError;

export const selectLastReceivedPixelHistory = (state: RootState) =>
  state.trackingPixels.receivedPixelHistoryAt;

export const selectPixelHistory = (state: RootState) =>
  state.trackingPixels.pixelHistoryById;

export const selectIsLoadingPixelsOrPixelHistory = (state: RootState) =>
  state.trackingPixels.isPixelsLoading ||
  state.trackingPixels.isPixelHistoryLoading;

export const selectIsAllPixelsLoading = (state: RootState) =>
  state.trackingPixels.isPixelsLoading;

export const selectIsPixelHistoryLoading = (state: RootState) =>
  state.trackingPixels.isPixelHistoryLoading;

import { requiredOptions } from "../../utils/validations";
export const emailsValidation = (values) => {
    return requiredOptions(values) || tooManyEmails(values) || areValidEmails(values);
};
const tooManyEmails = (values) => {
    const MAX_NUMBER_OF_EMAILS = 10;
    if (values.length > MAX_NUMBER_OF_EMAILS) {
        return `The maximum number of recipients is ${MAX_NUMBER_OF_EMAILS}`;
    }
    return;
};
const areValidEmails = (emails) => {
    for (const email of emails) {
        const isValidEmail = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
        if (!isValidEmail.test(email)) {
            return "One of the entries is not a valid email";
        }
    }
    return;
};

import { KnownOrganizationIds, User } from "@madhive/mad-sdk";

/**
 * Organizations that does not require Madhive logo or name in platform
 */
export const RULE_WHITE_LABEL_ORGS_IDS = Object.values(KnownOrganizationIds);
// TODO: bypass the organization rules and consider all orgs as whitelabel
// TODO: in future releases, remove all instances of Madhive and this hook
// TODO: to improve and reduce code

/**
 * Compare the user org id against a list of organization rules
 */
export function compareUserOrgRules(
  user: User,
  organizationList: KnownOrganizationIds[]
): boolean {
  if (!user) {
    return false;
  }
  return organizationList.includes(user.primaryOrganizationId);
}

/**
 * Compare the org id against a list of organization rules
 */
export function compareOrgRules(
  organizationId: string,
  organizationList: KnownOrganizationIds[]
): boolean {
  return organizationList.includes(organizationId as KnownOrganizationIds);
}

/* eslint-disable max-classes-per-file */
export * from "../modules/handlers/filter/errors";
export * from "../modules/advertisers/errors";
export * from "../modules/agencies/errors";
export * from "../modules/pixels/errors";
export * from "../modules/stations/errors";
export * from "../modules/products/errors";
export * from "../modules/teams/errors";
export class NoUserOrganizationError extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, NoUserOrganizationError);
        }
        this.name = "NoUserOrganizationError";
        this.message =
            "User is not assigned to known organization or the organization currently does not exist";
    }
}
export class MadSDKAbortError extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, MadSDKAbortError);
        }
        this.name = "AbortError";
        this.message = "MadSDK Aborted Request";
    }
}
export class UsersRequestFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UsersRequestFailed);
        }
        this.name = "UsersRequestFailed";
        this.message = "Failed to fetch users' auth metadata, please try again.";
    }
}
export class UserSaveFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UserSaveFailed);
        }
        this.name = "UserSaveFailed";
        this.message = "Failed to save user, please try again.";
    }
}
export class UserCreateFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UserCreateFailed);
        }
        this.name = "UserCreateFailed";
        this.message = "Failed to create user, please try again";
    }
}
export class PasswordResetMissingTempPasswordError extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PasswordResetMissingTempPasswordError);
        }
        this.name = "PasswordResetMissingTempPasswordError";
        this.message = "Missing newly created temporary password.";
    }
}
export class PasswordResetError extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PasswordResetError);
        }
        this.name = "PasswordResetError";
        this.message = "An error occurred during password reset. Please try again at a later time.";
    }
}
export class PasswordSetError extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PasswordSetError);
        }
        this.name = "PasswordSetError";
        this.message = "An error occurred setting password. Please try again at a later time.";
    }
}
export class DeleteUserFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, DeleteUserFailed);
        }
        this.name = "DeleteUserFailed";
        this.message = "Failed to delete user, please try again.";
    }
}
export class NoAuthorizedUser extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, NoAuthorizedUser);
        }
        this.name = "NoAuthorizedUser";
        this.message = "User must be authorized.";
    }
}
export class UnauthenticatedError extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnauthenticatedError);
        }
        this.name = "UnauthenticatedError";
        this.message = "Session is not authenticated.";
    }
}
export class NotImplementedError extends Error {
    constructor(method) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, NotImplementedError);
        }
        this.name = "NotImplementedError";
        this.message = `${method} hasn't been implemented yet`;
    }
}
export class PixelOwnerParseFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PixelOwnerParseFailed);
        }
        this.name = "PixelOwnerParseFailed";
        this.message = "Failed to parse pixels organizational data. Please try again at a later time.";
    }
}
export class PixelHistoryUnauthorizedFetchFailure extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PixelHistoryUnauthorizedFetchFailure);
        }
        this.name = "PixelHistoryUnauthorizedFetchFailure";
        this.message =
            "Failed to retrieve pixel history. User is not logged in. Please try again at a later time.";
    }
}
export class UnsuccessfulHermesResponse extends Error {
    constructor(responseCode) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnsuccessfulHermesResponse);
        }
        this.responseCode = responseCode;
        this.name = "UnsuccessfulHermesResponse";
        this.message = `Failed to interact with resource. Unsuccessful response code ${this.responseCode} received from Hermes.`;
    }
}
export class InvalidPixelHistoryData extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidPixelHistoryData);
        }
        this.name = "InvalidPixelHistoryData";
        this.message = "Pixel history data is not defined, or not of the expected type (object).";
    }
}
export class InvalidCreativeError extends Error {
    constructor(id, errors) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidCreativeError);
        }
        this.name = "InvalidCreativeError";
        this.message = `Creative ${id} has the following errors: ${errors.join(", ")}`;
    }
}
export class InvalidClickThrough extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidClickThrough);
        }
        this.name = "InvalidClickThrough";
        this.message = "Invalid Click Through URL, please update the click through url.";
    }
}
export class InvalidThirdPartyTracking extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidClickThrough);
        }
        this.name = "InvalidThirdPartyTracking";
        this.message = "Invalid Third Party Tracking, please update the click through url.";
    }
}
export class InvalidURL extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidURL);
        }
        this.name = "InvalidURL";
        this.message = "Invalid URL, please update the url.";
    }
}
export class NoMetadata extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, NoMetadata);
        }
        this.name = "NoMetadata";
        this.message = "No meta data";
    }
}
export class NoFileGiven extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, NoFileGiven);
        }
        this.name = "NoFileGiven";
        this.message = "No file given to upload";
    }
}
export class MintedKeyFailure extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, MintedKeyFailure);
        }
        this.name = "MintedKeyFailure";
        this.message = "Could not mint key";
    }
}
export class InvalidResultFromUpload extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidResultFromUpload);
        }
        this.name = "InvalidResultFromUpload";
        this.message = "No valid result from upload";
    }
}
export class DeleteCustomReportingDocFailure extends Error {
    constructor(reportId) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, DeleteCustomReportingDocFailure);
        }
        this.name = "DeleteCustomReportingDocFailure";
        this.reportId = reportId;
        this.message = `Failed to delete report. Report with key ${this.reportId} does not exist.`;
    }
}
export class FetchCustomReportNoResults extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FetchCustomReportNoResults);
        }
        this.name = "FetchCustomReportNoResults";
        this.message = "No report results could be found.";
    }
}
export class FetchCustomReportMissingReport extends Error {
    constructor(reportId) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FetchCustomReportMissingReport);
        }
        this.name = "FetchCustomReportMissingReport";
        this.reportId = reportId;
        this.message = `Failed to find report with ID ${this.reportId}`;
    }
}
export class FetchCustomReportResultFailure extends Error {
    constructor(reportId) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FetchCustomReportMissingReport);
        }
        this.name = "FetchCustomReportMissingReport";
        this.reportId = reportId;
        this.message = `Failed to fetch results of report with ID ${this.reportId}`;
    }
}
export class PublishersFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PublishersFetchFailed);
        }
        this.name = "PublishersFetchFailed";
        this.message = "Failed to fetch publishers";
    }
}
export class SummaryMissingDevices extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, SummaryMissingDevices);
        }
        this.name = "SummaryMissingDevices";
        this.message = "Summary Devices need at least 6 elements";
    }
}
export class SummaryCampaignFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, SummaryCampaignFetchFailed);
        }
        this.name = "SummaryCampaignFetchFailed";
        this.message = "Failed to get dashboard campaign summaries, please try again.";
    }
}
export class SummaryCreativeFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, SummaryCreativeFetchFailed);
        }
        this.name = "SummaryCreativeFetchFailed";
        this.message = "Failed to get dashboard creative summaries, please try again.";
    }
}
export class SummaryFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, SummaryFetchFailed);
        }
        this.name = "SummaryFetchFailed";
        this.message = "Failed to get summary, please try again.";
    }
}
export class SummaryDashboardFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, SummaryFetchFailed);
        }
        this.name = "SummaryFetchFailed";
        this.message = "Failed to get dashboard summaries, please try again.";
    }
}
export class UniqueImpressionFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UniqueImpressionFetchFailed);
        }
        this.name = "UniqueImpressionFetchFailed";
        this.message = "Failed to fetch unique impressions, please try again.";
    }
}
export var PublisherListErrorName;
(function (PublisherListErrorName) {
    PublisherListErrorName["PublisherListsFetchFailed"] = "PublisherListsFetchFailed";
    PublisherListErrorName["PublisherListCreationFailed"] = "PublisherListCreationFailed";
    PublisherListErrorName["PublisherListSameNameCreationFailure"] = "PublisherListSameNameCreationFailure";
    PublisherListErrorName["PublisherListUpdateFailed"] = "PublisherListUpdateFailed";
    PublisherListErrorName["PublisherListInvalidNameSaveFailure"] = "PublisherListInvalidNameSaveFailure";
    PublisherListErrorName["PublisherListInvalidDistributionCapSaveFailure"] = "PublisherListInvalidDistributionCapSaveFailure";
    PublisherListErrorName["PublisherListInvalidPublisherIdSaveFailure"] = "PublisherListInvalidPublisherIdSaveFailure";
    PublisherListErrorName["PublisherListUnknownPublisherIdSaveFailure"] = "PublisherListUnknownPublisherIdSaveFailure";
    PublisherListErrorName["PublisherListDuplicatePublisherSaveFailure"] = "PublisherListDuplicatePublisherSaveFailure";
    PublisherListErrorName["PublisherListInvalidCapSumSaveFailure"] = "PublisherListInvalidCapSumSaveFailure";
    PublisherListErrorName["PublisherListInvalidPublisherSaveFailure"] = "PublisherListInvalidPublisherSaveFailure";
    PublisherListErrorName["PublisherListInvalidServiceDefinition"] = "PublisherListInvalidServiceDefinition";
    PublisherListErrorName["PublisherListAuditFetchFailed"] = "PublisherListAuditFetchFailed";
})(PublisherListErrorName || (PublisherListErrorName = {}));
export class PublisherListsFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PublisherListsFetchFailed);
        }
        this.name = PublisherListErrorName.PublisherListsFetchFailed;
        this.message = "Failed to fetch publishers lists.";
    }
}
export class PublisherListCreationFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PublisherListCreationFailed);
        }
        this.name = PublisherListErrorName.PublisherListCreationFailed;
        this.message = "Failed to create publisher group. Please try again at a later time.";
    }
}
export class PublisherListSameNameCreationFailure extends Error {
    constructor(listName) {
        super();
        this.listName = listName;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PublisherListSameNameCreationFailure);
        }
        this.name = PublisherListErrorName.PublisherListSameNameCreationFailure;
        this.message = "Failed to create publisher group, this name already exists.";
    }
    static get apiErrorMessage() {
        return "name already exists";
    }
    static is(errors) {
        return errors.some((e) => e.includes(this.apiErrorMessage));
    }
}
export class PublisherListUpdateFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PublisherListUpdateFailed);
        }
        this.name = PublisherListErrorName.PublisherListUpdateFailed;
        this.message = "Failed to update publisher group. Please try again at a later time.";
    }
}
export class PublisherListInvalidNameSaveFailure extends Error {
    constructor(listName) {
        super();
        this.listName = listName;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PublisherListInvalidNameSaveFailure);
        }
        this.name = PublisherListErrorName.PublisherListInvalidNameSaveFailure;
        this.message = `"${this.listName}" is an invalid name for a publisher group. Please try again with a different name.`;
    }
    static get apiErrorMessage() {
        return "invalid name";
    }
    static is(errors) {
        return errors.some((e) => e.includes(this.apiErrorMessage));
    }
}
export class PublisherListInvalidDistributionCapSaveFailure extends Error {
    constructor(embeddedPublisherName, csvRowIndex) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PublisherListInvalidDistributionCapSaveFailure);
        }
        this.embeddedPublisherName = embeddedPublisherName;
        this.csvRowIndex = csvRowIndex;
        this.name = PublisherListErrorName.PublisherListInvalidDistributionCapSaveFailure;
        this.message = `Invalid cap value found for publisher${embeddedPublisherName.length > 0 ? `: ${embeddedPublisherName}` : ""}. See row ${csvRowIndex}. Please revise to a value between 0% and 100% inclusive.`;
    }
}
/**
 * An invalid ID indicates an ID that quite literally is not meant for publishers.
 */
export class PublisherListInvalidPublisherIdSaveFailure extends Error {
    constructor(publisherId, csvRowIndex) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PublisherListInvalidPublisherIdSaveFailure);
        }
        this.publisherId = publisherId;
        this.csvRowIndex = csvRowIndex;
        this.name = PublisherListErrorName.PublisherListInvalidPublisherIdSaveFailure;
        this.message = publisherId
            ? `Invalid publisher ID (${publisherId}) found. See row ${csvRowIndex}.`
            : `Invalid publisher ID found. See row ${csvRowIndex}.`;
    }
}
export class PublisherListUnknownPublisherIdSaveFailure extends Error {
    constructor(publisherId, csvRowIndex) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PublisherListUnknownPublisherIdSaveFailure);
        }
        this.publisherId = publisherId;
        this.csvRowIndex = csvRowIndex;
        this.name = PublisherListErrorName.PublisherListUnknownPublisherIdSaveFailure;
        this.message = `Provider ID for ${publisherId} not found in the main list. See row ${csvRowIndex}. Please double-check ID has not been changed.`;
    }
}
export class PublisherListDuplicatePublisherSaveFailure extends Error {
    constructor(publisherId, csvRowIndex) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PublisherListDuplicatePublisherSaveFailure);
        }
        this.publisherId = publisherId;
        this.csvRowIndex = csvRowIndex;
        this.name = PublisherListErrorName.PublisherListDuplicatePublisherSaveFailure;
        this.message = `Duplicate provider found: ${publisherId}. See row ${csvRowIndex}. Please remove and try again.`;
    }
}
export class PublisherListInvalidCapSumSaveFailure extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PublisherListInvalidCapSumSaveFailure);
        }
        this.name = PublisherListErrorName.PublisherListInvalidCapSumSaveFailure;
        this.message = "Cap values must sum to at least 100%. Please double-check CSV.";
    }
}
/**
 * A publisher associated with a list can be invalid, based on whatever criteria the API decides.
 * This is *not* the same as an invalid ID - an invalid ID indicates an ID that quite literally is not meant for publishers.
 */
export class PublisherListInvalidPublisherSaveFailure extends Error {
    constructor(publisherId) {
        super();
        this.publisherId = publisherId;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PublisherListInvalidPublisherSaveFailure);
        }
        this.name = PublisherListErrorName.PublisherListInvalidPublisherSaveFailure;
        this.message = `The publisher with ID "${this.publisherId || ""}" is no longer valid, but is present in your CSV. Please remove it or download the most up-to-date CSV.`;
    }
    static get apiErrorMessage() {
        return "is no longer valid. Please remove it or download the most up-to-date CSV";
    }
    static is(errors) {
        return errors.some((e) => e.includes(this.apiErrorMessage));
    }
    static parseInvalidPublisherId(errors) {
        const error = errors.find((e) => e.includes(this.apiErrorMessage));
        if (!error) {
            return undefined;
        }
        const publisherIdRegex = new RegExp('publisher with id "(.*)"', "g");
        const executedRegex = publisherIdRegex.exec(error);
        return ((executedRegex && executedRegex.length && executedRegex.length > 1 && executedRegex[1]) || // 1 will be the index of our only capture group
            undefined);
    }
}
export class PublisherListInvalidServiceDefinition extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PublisherListInvalidServiceDefinition);
        }
        this.name = PublisherListErrorName.PublisherListInvalidServiceDefinition;
        this.message =
            "Received an invalid publisher list definition. The definition is missing at least one of last_update, version, and updated_by.";
    }
}
export class PublisherListAuditFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PublisherListAuditFetchFailed);
        }
        this.name = PublisherListErrorName.PublisherListAuditFetchFailed;
        this.message =
            "Failed to get audit report for this publisher group. Please try again at a later time.";
    }
}
export class FreewheelProductsFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FreewheelProductsFetchFailed);
        }
        this.name = "FreewheelProductsFetchFailed";
        this.message = "Failed to fetch products from Freewheel";
    }
}
export class FreewheelProductsAccessDenial extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FreewheelProductsAccessDenial);
        }
        this.name = "FreewheelProductsAccessDenial";
        this.message = "Organization does not have access to Freewheel product data";
    }
}
export class InvalidFreewheelProductType extends Error {
    constructor(providedType) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidFreewheelProductType);
        }
        this.name = "InvalidFreewheelProductType";
        this.message = `${providedType} is not a valid Freewheel product type.`;
    }
}
export class CampaignFetchByIdFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CampaignFetchByIdFailed);
        }
        this.name = "CampaignFetchByIdFailed";
        this.message = "Failed to retrieve campaign by ID. Please try again at a later time.";
    }
}
export class CampaignsFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CampaignsFetchFailed);
        }
        this.name = "CampaignsFetchFailed";
        this.message = "Failed to retrieve campaigns. Please try again at a later time.";
    }
}
export class CampaignCreationFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CampaignCreationFailed);
        }
        this.name = "CampaignCreationFailed";
        this.message = "Failed to create campaign. Please try again at a later time.";
    }
}
export class CampaignUpdateFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CampaignUpdateFailed);
        }
        this.name = "CampaignUpdateFailed";
        this.message = "Failed to update campaign. Please try again at a later time.";
    }
}
export class CampaignDeleteFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CampaignDeleteFailed);
        }
        this.name = "CampaignDeleteFailed";
        this.message = "Failed to delete campaign. Please try again at a later time.";
    }
}
export class CampaignIdCreationFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CampaignIdCreationFailed);
        }
        this.name = "CampaignIdCreationFailed";
        this.message = "Failed to create an ID for the new campaign";
    }
}
export class LineItemFetchByIdFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, LineItemFetchByIdFailed);
        }
        this.name = "LineItemFetchByIdFailed";
        this.message = "Failed to retrieve line item by ID. Please try again at a later time.";
    }
}
export class LineItemsFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, LineItemsFetchFailed);
        }
        this.name = "LineItemsFetchFailed";
        this.message = "Failed to retrieve line item. Please try again at a later time.";
    }
}
export class LineItemCreationFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, LineItemCreationFailed);
        }
        this.name = "LineItemCreationFailed";
        this.message = "Failed to create line item. Please try again at a later time.";
    }
}
export class LineItemUpdateFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, LineItemUpdateFailed);
        }
        this.name = "LineItemUpdateFailed";
        this.message = "Failed to update line item. Please try again at a later time.";
    }
}
export class LineItemDeleteFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, LineItemDeleteFailed);
        }
        this.name = "LineItemDeleteFailed";
        this.message = "Failed to delete line item. Please try again at a later time.";
    }
}
export class LineItemIdCreationFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, LineItemIdCreationFailed);
        }
        this.name = "LineItemIdCreationFailed";
        this.message = "Failed to create an ID for the new line item";
    }
}
export class AdBookCampaignDataFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdBookCampaignDataFetchFailed);
        }
        this.name = "AdBookCampaignDataFetchFailed";
        this.message = "Failed to retrieve adbook campaigns. Please try again at a later time.";
    }
}
export class BulkAssignTemplateUploadFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, BulkAssignTemplateUploadFailed);
        }
        this.name = "BulkAssignTemplateUploadFailed";
        this.message = "Failed to upload bulk assign template. Please try again at a later time.";
    }
}
export class CampaignTemplateUploadFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CampaignTemplateUploadFailed);
        }
        this.name = "CampaignTemplateUploadFailed";
        this.message = "Failed to upload campaign template. Please try again at a later time.";
    }
}
export class CampaignTemplateFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CampaignTemplateFetchFailed);
        }
        this.name = "CampaignTemplateFetchFailed";
        this.message = "Failed to retrieve campaign template. Please try again at a later time.";
    }
}
export class BulkInstructionsUpdateFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, BulkInstructionsUpdateFailed);
        }
        this.name = "BulkInstructionsUpdateFailed";
        this.message = "Failed to update bulk instruction. Please try again at a later time.";
    }
}
export class BulkAssignTemplateFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, BulkAssignTemplateFetchFailed);
        }
        this.name = "BulkAssignTemplateFetchFailed";
        this.message = "Failed to retrieve bulk assign template. Please try again at a later time.";
    }
}
export class BulkAssignTemplateUpdateFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, BulkAssignTemplateUpdateFailed);
        }
        this.name = "BulkAssignTemplateUpdateFailed";
        this.message = "Failed to update bulk assign template. Please try again at a later time.";
    }
}
export class ReportingCreativesFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ReportingCreativesFetchFailed);
        }
        this.name = "ReportingCreativesFetchFailed";
        this.message = "Failed to retrieve reporting creatives. Please try again at a later time.";
    }
}
export class QAReportFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, QAReportFetchFailed);
        }
        this.name = "QAReportFetchFailed";
        this.message = "Failed to retrieve QA report. Please try again at a later time.";
    }
}
export class GetAllNetworksFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, GetAllNetworksFailed);
        }
        this.name = "GetAllNetworksFailed";
        this.message = "Failed to retrieve networks. Please try again at a later time.";
    }
}
export class GetHouseholdsCountError extends Error {
    constructor(error) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, GetHouseholdsCountError);
        }
        this.error = error;
        this.name = "GetHouseholdsCountError";
        this.message = `Failed to fetch household count for selected zip codes: ${this.error.message ? `: ${this.error.message}` : "."}`;
    }
}
export class GetPioProductPackagesError extends Error {
    constructor(error) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, GetPioProductPackagesError);
        }
        this.error = error;
        this.name = "GetPioProductPackagesError";
        this.message = `Failed to fetch Pio Product Packages. Please try again at a later time.`;
    }
}
export class AdvancedExportsQueryError extends Error {
    constructor(error, message) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvancedExportsQueryError);
        }
        this.error = error;
        this.name = "AdvancedExportsQueryError";
        this.message = `Failed to Export CSV Query. ${message || this.error.message}
    `;
    }
}
export class AttributionDashboardOptionsFailedFetchError extends Error {
    constructor(error) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvancedExportsQueryError);
        }
        this.error = error;
        this.name = "AttributionDashboardOptionsFailedFetchError";
        this.message = `Failed to fetch attribution dashboard advertisers and campaigns. ${this.error.message}`;
    }
}
export class AttributionDashboardOptionsFiltersError extends Error {
    constructor(missingFilters) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvancedExportsQueryError);
        }
        this.name = "AttributionDashboardOptionsFiltersError";
        this.message = `Failed to construct url query params for attribution dashboard; missing filters: ${missingFilters.join(", ")}`;
    }
}
export class AttributionDashboardFailedFetchError extends Error {
    constructor(error) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvancedExportsQueryError);
        }
        this.error = error;
        this.name = "AttributionDashboardFailedFetchError";
        this.message = `Failed to fetch attribution dashboard data. ${this.error.message}`;
    }
}
export class AttributionDashboardFiltersError extends Error {
    constructor(missingFilters) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvancedExportsQueryError);
        }
        this.name = "AttributionDashboardFiltersError";
        this.message = `Failed to construct url query params for attribution dashboard; missing filters: ${missingFilters.join(", ")}`;
    }
}
export class AudienceProvisionFetchFailedError extends Error {
    constructor(error) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AudienceProvisionFetchFailedError);
        }
        this.error = error;
        this.name = "AudienceProvisionFetchFailedError";
        this.message = `Failed to fetch audience provision data. ${this.error.message}`;
    }
}
export class AudienceProvisionSaveFailedError extends Error {
    constructor(error) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AudienceProvisionSaveFailedError);
        }
        this.error = error;
        this.name = "AudienceProvisionSaveFailedError";
        this.message = `Failed to save audience provision data. ${this.error.message}`;
    }
}

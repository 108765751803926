import { AttributionDashboardFiltersError } from "../../errors";
import { ATT_DASH_NECESSARY_FILTERS } from "../../models/attributionDashboard";
import { formatDate } from "../../utils";
import { FilterTypes, toQueryParam } from "../../modules/handlers/filter";
const makeQueryParamFilter = (wheres, field, paramName) => {
    const value = wheres.find((where) => where.field === field);
    if (!value) {
        return undefined;
    }
    return `"${paramName}": ${toQueryParam(value, { array: true })}`;
};
export const toQueryParams = (filter, isConversionBlacklistFilteringEnabled) => {
    let queryParams = "";
    const wheres = filter.where;
    if (!wheres) {
        return queryParams;
    }
    wheres.forEach((where) => {
        switch (where.field) {
            case "startDate": {
                queryParams = `${queryParams}&startDate=${toQueryParam({
                    ...where,
                    value: formatDate(where.value)
                })}`;
                break;
            }
            case "endDate": {
                queryParams = `${queryParams}&endDate=${toQueryParam({
                    ...where,
                    value: formatDate(where.value)
                })}`;
                break;
            }
            case "email": {
                queryParams = `${queryParams}&email=${toQueryParam(where)}`;
                break;
            }
            case "metrics": {
                where.value.forEach((metric) => {
                    queryParams = `${queryParams}&metrics=${encodeURIComponent(metric)}`;
                });
                break;
            }
            case "dimensions": {
                where.value.forEach((dimension) => {
                    queryParams = `${queryParams}&dimensions=${encodeURIComponent(dimension)}`;
                });
                break;
            }
        }
    });
    // check for query param for feature flag to enable ip filtering or not
    if (isConversionBlacklistFilteringEnabled) {
        queryParams = `${queryParams}&conversionsBlacklist=true`;
    }
    const advertiserIdFilter = makeQueryParamFilter(wheres, "advertiserIds", "advertiser_id");
    const campaignIdFilter = makeQueryParamFilter(wheres, "campaignIds", "campaign_id");
    if (advertiserIdFilter && campaignIdFilter) {
        const queryParamFilterField = `{${[advertiserIdFilter, campaignIdFilter]
            .filter(Boolean)
            .join(",")}}`;
        queryParams = `${queryParams}&filters=${queryParamFilterField}`;
    }
    return queryParams;
};
export const groupFiltersByDimension = (filter) => {
    const dimensions = filter.where?.find((where) => where.field === "dimensions")?.value;
    if (!dimensions) {
        return [];
    }
    const nonDimensionFilters = filter.where?.filter((where) => where.field !== "dimensions") || [];
    const filters = [];
    for (const dimension of dimensions) {
        const parsedDimensions = dimension.split("-").map((d) => d.trim());
        filters.push({
            where: [
                ...nonDimensionFilters,
                { field: "dimensions", type: FilterTypes.EQ, value: parsedDimensions }
            ]
        });
    }
    return filters;
};
export const validateFilterContent = (filters) => {
    if (!filters.where) {
        throw new AttributionDashboardFiltersError(ATT_DASH_NECESSARY_FILTERS);
    }
    const missingFilters = [];
    const filterFields = new Map();
    filters.where?.forEach((filter) => {
        if (Array.isArray(filter.value) ? filter.value.length : filter.value) {
            filterFields.set(filter.field, filter);
        }
    });
    ATT_DASH_NECESSARY_FILTERS.forEach((filterField) => {
        const match = filterFields.get(filterField);
        if (!match || !match.value || (Array.isArray(match.value) && !match.value.length)) {
            missingFilters.push(filterField);
        }
    });
    if (missingFilters.length) {
        throw new AttributionDashboardFiltersError(missingFilters);
    }
};

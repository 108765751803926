import { VITE_GOOGLE_MAPS_API_KEY } from "lib/viteEnvConfig";

const makeMapsApiAuthenticator = () => {
  const apiKey = VITE_GOOGLE_MAPS_API_KEY;
  const getScriptElement = () => document.querySelector(`#${apiKey}`);

  return {
    /**
     * Dynamically append google maps api import script to document. This
     * should only be called once a valid user session is confirmed, as it
     * exposes our google maps api key in html.
     * https://developers.google.com/maps/documentation/javascript/overview#Dynamic
     */
    auth: () =>
      new Promise<void>(resolve => {
        if (getScriptElement()) {
          resolve();
          return;
        }
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places,visualization&callback=initGoogleMapApis`;
        script.async = true;
        script.id = apiKey;

        // @ts-ignore
        window.initGoogleMapApis = () => {
          resolve();
        };

        document.head.appendChild(script);
      }),
    /**
     * Remove google maps api import script from document when user logs
     * out, which ensures the api key is not exposed to unauthenticated users
     */
    cleanup: () => {
      getScriptElement()?.remove();
    }
  };
};

export const mapsApiAuthenticator = makeMapsApiAuthenticator();

import { AdvertisersFetchFailed, NotImplementedError } from "../../index";
import { Handler } from "../handlers";
import axios from "axios";
class Advertisers extends Handler {
    constructor(sdk) {
        super(sdk, "reporting-advertisers", { atomize: true });
    }
    async findItems() {
        return this.cache.promise(this.advertisersUrl, () => axios
            .get(this.advertisersUrl, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data }) => data)
            .catch(() => {
            throw new AdvertisersFetchFailed();
        }));
    }
    get advertisersUrl() {
        return `${this.sdk.urls.madhiveReportingBaseUrl}/advertisers`;
    }
    /**
     * saveItem isn't implemented for reporting advertisers.
     */
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    saveItem(advertiser) {
        throw new NotImplementedError("save");
    }
    /**
     * deleteItem isn't implemented for reporting advertisers.
     */
    deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
}
export default Advertisers;

import { NotImplementedError } from "../errors";
import axios from "axios";
import { Handler } from "./handlers";
class Settings extends Handler {
    constructor(sdk) {
        super(sdk, "settings");
    }
    /**
     * Find the default settings. Currenlty only supports returning all the settings.
     * @return: a promise that resolves with all default settings.
     */
    async findItems() {
        const res = await axios.get(`${this.sdk.urls.burnsBaseUrl}/settings/default`);
        return [res.data];
    }
    /* eslint-disable-next-line class-methods-use-this, no-shadow, no-unused-vars */
    async saveItem() {
        throw new NotImplementedError("saveItem");
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async deleteItem() {
        throw new NotImplementedError("delete");
    }
}
export default Settings;

import { css } from "@emotion/css";
import styled from "@emotion/styled";

export const spinner = css`
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const path = css`
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.2s ease-in-out infinite;
  stroke-linecap: round;

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }
`;

export const Wrapper = styled("div")<{ width: number }>`
  position: relative;
  width: ${props => props.width}px;
  &:before {
    content: "";
    display: block;
    padding-top: 100%;
  }
`;

import { Icon, IconSize, Intent, Tooltip, Card } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import styled from "@emotion/styled";
import { Link, LinkProps } from "react-router-dom";
import { css } from "@emotion/react";
import {
  Button,
  ButtonColor,
  ButtonVariant,
  TextVariant,
  Typography as MadhiveTypography,
  TypographyProps as MadhiveTypographyProps
} from "madhive/components";
import { TitleVariant } from "madhive/components/Typography/types";
import { LogicalOperator } from "lib/constants/misc";
import { Chip } from "frontier/lib/components/Chip";
import { buildMediaQuery } from "lib/utils/styles";
import {
  FC,
  Fragment,
  ReactChild,
  ReactNode,
  HTMLProps,
  memo,
  CSSProperties
} from "react";
import Layout from "../Layout";

export interface AppliedHeaderFilter {
  id: string;
  label: string;
  operator?: LogicalOperator;
}

interface Props {
  sticky?: boolean;
  marginBottom?: boolean;
  appliedFilters?: AppliedHeaderFilter[];
  fullWidth?: boolean;
  onFilterClick?: (id: string, label: string) => void;
  onClearAll?: () => void;
  headerOptions?: HeaderOptions;
  hasBorder?: boolean;
  hasNoBottomMargin?: boolean;
  lowPadding?: boolean;
  hasShadow?: boolean;
  children?: ReactNode;
  "data-testid"?: string;
}

interface StyleProps {
  className?: string;
  style?: CSSProperties;
}

interface HeaderOptions {
  filtersCategoryLabel?: string;
  shouldHideFiltersCategoryLabel?: boolean;
  hasRoundedCorners?: boolean;
  headerBreakpointMediaQuery?: string;
}

const StyledTooltip = styled(Tooltip)`
  > div > span {
    display: flex;
    align-items: center;

    span {
      margin-left: var(--spacing-4);
    }
  }
`;

const GRAY_COLOR = "#f8f9fb";

const useClasses = (headerBreakpointMediaQuery: string) => ({
  headerContainer: css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-12) var(--spacing-0);
    margin-bottom: 0px !important; // there's a value from Layout.tsx which is used in a lot of places
    ${headerBreakpointMediaQuery} {
      flex-direction: column;
      align-items: flex-start;
      & > *:first-of-type {
        margin-bottom: var(--spacing-32);
      }
    }
  `,
  headerCard: css`
    background: #fff;
  `,
  marginBottom: css`
    margin-bottom: var(--spacing-32);
  `,
  actionsContainer: css`
    display: flex;
    justify-content: flex-end;
    flex: 1;
    flex-wrap: wrap;
    align-items: center;
    & > *:not(:last-child) {
      margin-right: var(--spacing-12);
    }
    & > *:last-child {
      margin-right: var(--spacing-0);
    }
  `,
  stickyHeader: css`
    position: sticky;
    top: 0px;
    z-index: 9;
  `,
  backButtonSvg: css`
    margin-right: var(--spacing-24);
    vertical-align: middle;
  `,
  ellipsizedHeader: css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `,
  headerText: css`
    max-width: 700px;
    align-self: center;
    font-size: var(--font-size-32);
    ${headerBreakpointMediaQuery} {
      align-self: normal;
    }
    ${buildMediaQuery.max("md")} {
      max-width: 300px;
    }
  `,
  appliedFiltersContainer: css`
    display: flex;
    border-top: 1px solid var(--border-light);
    border-bottom: 1px solid var(--border-light);
  `,
  appliedFiltersLabelContainer: css`
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    background: ${GRAY_COLOR};
    align-self: stretch;
    display: flex;
    align-items: center;
    justify-content: space-around;
    user-select: none;
    border-right: 1px solid var(--border-light);
    padding: var(--spacing-12) var(--spacing-24);
    margin-right: var(--spacing-4);
  `,
  appliedFiltersFlexContainer: css`
    display: flex;
    align-items: center;
  `,
  appliedFiltersFlexGrow: css`
    flex-grow: 1;
    display: flex;
    align-items: center;
    max-width: 100vw;
  `,
  border: css`
    border-bottom: 1px solid var(--border-light);
    border-radius: 0px;
  `,
  hasNoBottomMargin: css`
    margin-bottom: var(--spacing-0);
  `,
  lowPadding: css`
    padding: var(--spacing-12) var(--spacing-0);
  `,
  pageHeaderTitle: css`
    overflow: hidden;
    margin-right: var(--spacing-12);
  `,
  tooltipContent: css`
    max-width: 95vw;
    margin-bottom: 0px;
  `,
  shadow: css`
    position: relative;
    box-shadow: var(--shadow-3);
    border-bottom: none;
    margin-bottom: var(--spacing-0);
  `,
  chipListWrapper: css`
    display: flex;
    overflow: auto;
  `,
  chip: css`
    height: var(--spacing-32);
  `,
  deleteIcon: css`
    color: var(--white);
    cursor: pointer;
  `,
  card: css`
    padding: 0px;
    box-shadow: none;
  `
});

const PageHeaderWrapper: FC<Props & StyleProps> = ({
  children,
  sticky,
  marginBottom = true,
  hasBorder = false,
  hasNoBottomMargin = false,
  lowPadding = false,
  appliedFilters,
  onFilterClick,
  fullWidth = true,
  className,
  style,
  hasShadow = false,
  headerOptions = {
    shouldHideFiltersCategoryLabel: false,
    hasRoundedCorners: true
  },
  onClearAll,
  "data-testid": dataTestId
}) => {
  const classes = useClasses(
    headerOptions.headerBreakpointMediaQuery || buildMediaQuery.max("md")
  );

  /**
   * In the case where the consumer of this component wants to hide the box
   * containing the "FILTERS:" label, we want to make sure the applied filters
   * are in line with the rest of the page. If it's not being hidden, it should
   *  stretch to the edge of the page, as per designs. */
  const AppliedFiltersWrappingElement =
    headerOptions.shouldHideFiltersCategoryLabel ? Layout : Fragment;

  /** Avoid passing in unrecognized props to Fragment */
  const appliedFiltersWrappingElementProps =
    headerOptions.shouldHideFiltersCategoryLabel
      ? { fullWidth, style: { width: "100%" } }
      : {};

  return (
    <header
      css={sticky ? classes.stickyHeader : undefined}
      data-testid={dataTestId}
    >
      <Card
        className={className}
        css={css(
          classes.card,
          classes.headerCard,
          marginBottom ? classes.marginBottom : null,
          hasBorder ? classes.border : "",
          hasNoBottomMargin ? classes.hasNoBottomMargin : "",
          hasShadow ? classes.shadow : ""
        )}
        style={style}
      >
        <Layout fullWidth={fullWidth}>
          <div
            css={css(
              classes.headerContainer,
              lowPadding ? classes.lowPadding : ""
            )}
          >
            {children}
          </div>
        </Layout>
        {appliedFilters && appliedFilters.length ? (
          <div
            css={classes.appliedFiltersContainer}
            data-testid="page-header-applied-filters"
          >
            <AppliedFiltersWrappingElement
              {...appliedFiltersWrappingElementProps}
            >
              <div css={classes.appliedFiltersFlexContainer}>
                <div css={classes.appliedFiltersFlexGrow}>
                  {headerOptions.shouldHideFiltersCategoryLabel ? null : (
                    <div
                      css={classes.appliedFiltersLabelContainer}
                      data-testid="page-header-filter-label-container"
                    >
                      {headerOptions.filtersCategoryLabel || "Filters"}
                    </div>
                  )}

                  <div css={classes.chipListWrapper}>
                    {appliedFilters.map(filter => (
                      <Chip
                        key={filter.id}
                        data-testid={`filter-chip-${filter.label}`}
                        css={classes.chip}
                        label={filter.label}
                        intent={
                          filter.operator === LogicalOperator.EXCLUDE
                            ? Intent.DANGER
                            : Intent.SUCCESS
                        }
                        round={headerOptions.hasRoundedCorners}
                        onRemove={
                          onFilterClick &&
                          (() => onFilterClick(filter.id, filter.label))
                        }
                        removeIcon={
                          <Icon
                            icon={IconNames.DELETE}
                            iconSize={IconSize.LARGE}
                            data-testid={`filter-chip-icon-${filter.label}`}
                            css={classes.deleteIcon}
                          />
                        }
                      />
                    ))}
                  </div>
                </div>

                {!!onClearAll && (
                  <Button
                    color={ButtonColor.PRIMARY}
                    variant={ButtonVariant.TEXT}
                    onClick={onClearAll}
                    data-testid="page-header-clear-all-button"
                  >
                    Clear All
                  </Button>
                )}
              </div>
            </AppliedFiltersWrappingElement>
          </div>
        ) : null}
      </Card>
    </header>
  );
};

interface TitleProps {
  ellipsize?: boolean;
  headerBreakpointMediaQuery?: string;
  titleVariant?: TitleVariant;
  tooltip?: string;
  subtitle?: ReactChild;
  icon?: ReactNode;
}

const TOOLTIP_HOVER_OPEN_DELAY = 500;
const TOOLTIP_HOVER_CLOSE_DELAY = 0;

const PageHeaderTitle: FC<TitleProps & MadhiveTypographyProps> = ({
  children,
  titleVariant,
  tooltip,
  subtitle,
  icon,
  ...headingProps
}) => {
  const classes = useClasses(buildMediaQuery.max("xl"));
  const title = (
    <StyledTooltip
      content={<p css={classes.tooltipContent}>{children}</p>}
      hoverOpenDelay={TOOLTIP_HOVER_OPEN_DELAY}
      hoverCloseDelay={TOOLTIP_HOVER_CLOSE_DELAY}
      targetTagName="div"
    >
      <>
        <MadhiveTypography
          bold
          variant={
            titleVariant === TitleVariant.SUB
              ? TextVariant.H_TWO
              : TextVariant.H_ONE
          }
          {...headingProps}
          ellipsize
        >
          {children}
        </MadhiveTypography>
        {!!icon && icon}
      </>
    </StyledTooltip>
  );
  return (
    <div
      data-testid="page-header-title-container"
      css={classes.pageHeaderTitle}
    >
      {title}
      {subtitle}
    </div>
  );
};

const PageHeaderActionsContainer: FC<HTMLProps<HTMLDivElement>> = ({
  children,
  className,
  ...divProps
}) => {
  const classes = useClasses(buildMediaQuery.max("xl"));
  return (
    <div
      data-testid="page-header-actions-container"
      className={className}
      css={classes.actionsContainer}
      {...divProps}
    >
      {children}
    </div>
  );
};

const BackButton: FC<LinkProps> = props => {
  const classes = useClasses(buildMediaQuery.max("xl"));
  return (
    <Link
      to={props.to}
      onClick={props.onClick}
      data-testid="back-button"
      data-pdfignore
    >
      <svg
        width="26"
        height="26"
        viewBox="0 0 26 26"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        css={classes.backButtonSvg}
      >
        <path
          d="M25.0768 11.2307H6.35373L14.9537 2.63066L12.7691 0.461426L0.461426 12.7691L12.7691 25.0768L14.9383 22.9076L6.35373 14.3076H25.0768V11.2307Z"
          fill="var(--primary-color)"
        />
      </svg>
    </Link>
  );
};

export default class PageHeader {
  static Wrapper = memo(PageHeaderWrapper);

  static ActionsContainer = memo(PageHeaderActionsContainer);

  static BackButton = memo(BackButton);

  static Title = memo(PageHeaderTitle);
}

import { LookerBrowserSDK } from "@looker/sdk";
import { BrowserTransport, DefaultSettings } from "@looker/sdk-rtl";
import { EmbedHandler } from "./embed";
import { MadLookerSession } from "./session";
export class MadLooker {
    /**
     * @param sdk: an instance of the madSDK.
     * @param config: configuration otpions for MadLooker.
     */
    constructor(sdk, config = {}) {
        /**
         * Responds to Madhive account changes by properly modifying Looker's authentication session.
         * @param account: a Madhive account.
         */
        this.handleAuthStateChange = async (account) => {
            // if account is null, we've logged out - or if the IDs don't match, we've got a brand new account
            if (!account || this.userId !== account.user.id) {
                await this.session.logout();
            }
        };
        const settings = {
            ...DefaultSettings(),
            base_url: sdk.urls.lookerBaseUrl,
            ...(config.settings || {})
        };
        const transport = config.transport || new BrowserTransport(settings);
        this.session = new MadLookerSession(sdk, settings, transport);
        const initializer = config.initializer || LookerBrowserSDK;
        this.sdk = initializer.init40(settings, transport, this.session);
        sdk.authentication.onAuthStateChanged({
            next: this.handleAuthStateChange,
            error: () => this.handleAuthStateChange(null)
        });
        this.embed = new EmbedHandler(sdk, { useCookielessSession: config.useCookielessSession });
    }
}

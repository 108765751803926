export class CreativeSaveFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CreativeSaveFailed);
        }
        this.name = "CreativeSaveFailed";
        this.message = "Failed to save creative, please try again.";
    }
}

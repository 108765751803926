import { AffiliationTypes, DEFAULT_USER_SETTINGS, KnownOrganizationIds, ORGANIZATION_ID_TO_BRANDING_ID, ORGANIZATION_ID_TO_SMITHERS_ORG_NAME, PrimaryUserTypes, ViewId } from "../../types";
export * from "./settings";
export var UserOperationMode;
(function (UserOperationMode) {
    UserOperationMode["READ"] = "READ";
    UserOperationMode["UPDATE"] = "UPDATE";
})(UserOperationMode || (UserOperationMode = {}));
export const userToPostgresUser = (user) => {
    return {
        id: user.id || null,
        admin: user.isAdmin,
        developer: user.isDev,
        team_id: user.teamId,
        email: user.email,
        name: user.name,
        type: user.type,
        advertiser_id: user.advertiserId,
        agency_id: user.agencyId,
        views: user.viewIds,
        settings: userSettingsToServiceUserSettings(user.settings),
        parent: user.parent,
        owners: user.owners || [],
        updated: user.updated,
        last_login: user.lastLoginDate,
        created: user.createdDate,
        abilities: user.abilities
    };
};
const getPrimaryOrganizationId = (user) => {
    if (user.parent) {
        return user.parent;
    }
    return user.primary_organization_id
        ? user.primary_organization_id
        : KnownOrganizationIds.MADHIVE_DEFAULT;
};
const getBrandingId = (organizationId) => {
    return organizationId
        ? ORGANIZATION_ID_TO_BRANDING_ID[organizationId]
        : ORGANIZATION_ID_TO_BRANDING_ID[KnownOrganizationIds.MADHIVE_DEFAULT];
};
const getLastLoginDate = (user, authenticationMetaData) => {
    if (user.last_login) {
        return new Date(user.last_login);
    }
    return authenticationMetaData?.lastSignInTime
        ? new Date(authenticationMetaData.lastSignInTime)
        : null;
};
const getCreatedDate = (user, authenticationMetaData) => {
    if (user.created) {
        return new Date(user.created);
    }
    return authenticationMetaData?.creationTime
        ? new Date(authenticationMetaData.creationTime)
        : null;
};
const getAffiliationType = (user) => {
    if (user.team_id) {
        return AffiliationTypes.STATION;
    }
    if (user.agency_id) {
        return AffiliationTypes.AGENCY;
    }
    if (user.advertiser_id) {
        return AffiliationTypes.ADVERTISER;
    }
    return AffiliationTypes.CORPORATE;
};
const getHomepage = (user) => {
    if (user.settings && "homepage" in user.settings && user.settings.homepage) {
        return user.settings.homepage;
    }
    if (user.views) {
        return user.views[0];
    }
    return ViewId.DASHBOARD;
};
const getBaseAppName = (user) => {
    if (user.settings && "base_app_name" in user.settings && user.settings.base_app_name) {
        return user.settings.base_app_name;
    }
    return "MadHive";
};
const getIsFooter = (user) => {
    if (user.settings && "footer" in user.settings && user.settings.footer) {
        return user.settings.footer;
    }
    return false;
};
/**
 * @param user: the service rep of a user.
 * @return: their dashboard settings, if they exist. Else, undefined.
 */
// any because this function dealas with legacy settings code
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDashboard = (user) => {
    if (user.settings && "dashboard" in user.settings) {
        return user.settings.dashboard;
    }
    return undefined;
};
const getColumns = (user) => {
    if (user.settings && "columns" in user.settings) {
        return {
            manageCampaignsColumns: user.settings.columns?.manage_campaigns_columns
        };
    }
    return undefined;
};
const getUserType = (user) => {
    if (user.admin) {
        return PrimaryUserTypes.INTERNAL_ADMIN;
    }
    if (!user.agency_id && !user.advertiser_id) {
        return PrimaryUserTypes.INTERNAL_STANDARD;
    }
    return PrimaryUserTypes.EXTERNAL;
};
export const serviceUserSettingsToUserSettings = (settings, user) => {
    return {
        appSettings: {
            campaignsFrequencySettings: {
                dailyFreqCap: settings.app_settings?.campaigns_frequency_settings?.daily_freq_cap ||
                    DEFAULT_USER_SETTINGS.appSettings.campaignsFrequencySettings.dailyFreqCap,
                hasFreqCap: settings.app_settings?.campaigns_frequency_settings?.has_freq_cap ||
                    DEFAULT_USER_SETTINGS.appSettings.campaignsFrequencySettings.hasFreqCap,
                hourlyFreqCap: settings.app_settings?.campaigns_frequency_settings?.hourly_freq_cap ||
                    DEFAULT_USER_SETTINGS.appSettings.campaignsFrequencySettings.hourlyFreqCap
            }
        },
        assetSpecificationId: settings.asset_specification_id || DEFAULT_USER_SETTINGS.assetSpecificationId,
        publisher_validations: settings.publisher_validations,
        baseAppName: !user ? settings.base_app_name : getBaseAppName(user),
        customSegmentSelection: settings.custom_segment_selection,
        dashboard: {
            availableDates: {
                min: settings.dashboard?.available_dates?.min ||
                    DEFAULT_USER_SETTINGS.dashboard?.availableDates.min
            },
            dashboardFilterSettings: {
                advertiser: {
                    isSet: settings.dashboard?.dashboard_filter_settings?.advertiser?.is_set || false,
                    value: settings.dashboard?.dashboard_filter_settings?.advertiser?.value || false
                },
                agency: {
                    isSet: settings.dashboard?.dashboard_filter_settings?.agency?.is_set || false,
                    value: settings.dashboard?.dashboard_filter_settings?.agency?.value || false
                },
                campaign: {
                    isSet: settings.dashboard?.dashboard_filter_settings?.campaign?.is_set || false,
                    value: settings.dashboard?.dashboard_filter_settings?.campaign?.value || false
                },
                adbook: {
                    isSet: settings.dashboard?.dashboard_filter_settings?.adbook?.is_set || false,
                    value: settings.dashboard?.dashboard_filter_settings?.adbook?.value || false
                },
                product: {
                    isSet: settings.dashboard?.dashboard_filter_settings?.product?.is_set || false,
                    value: settings.dashboard?.dashboard_filter_settings?.product?.value || false
                },
                station: {
                    isSet: settings.dashboard?.dashboard_filter_settings?.station?.is_set || false,
                    value: settings.dashboard?.dashboard_filter_settings?.station?.value || false
                },
                team: {
                    isSet: settings.dashboard?.dashboard_filter_settings?.team?.is_set || false,
                    value: settings.dashboard?.dashboard_filter_settings?.team?.value || false
                }
            }
        },
        extMeta: {
            billingFieldName: settings.ext_meta?.billing_field_name || DEFAULT_USER_SETTINGS.extMeta.billingFieldName,
            externalIdFieldName: settings.ext_meta?.external_id_field_name ||
                DEFAULT_USER_SETTINGS.extMeta.externalIdFieldName,
            isOmsDisabled: settings.ext_meta?.is_oms_disabled || DEFAULT_USER_SETTINGS.extMeta.isOmsDisabled,
            omsIdFieldName: settings.ext_meta?.oms_id_field_name || DEFAULT_USER_SETTINGS.extMeta.omsIdFieldName,
            omsLabel: settings.ext_meta?.oms_label || DEFAULT_USER_SETTINGS.extMeta.omsLabel
        },
        footer: !user ? settings.footer : getIsFooter(user),
        homepage: settings.homepage,
        columns: {
            manageCampaignsColumns: settings.columns?.manage_campaigns_columns || []
        },
        staticAssets: settings.static_assets,
        theme: {
            hue: settings.theme?.hue,
            saturation: settings.theme?.saturation,
            lightness: settings.theme?.lightness,
            logoSize: settings.theme?.logo_size,
            toolbarColor: settings.theme?.toolbar_color
        },
        suits: settings.suits,
        components: settings.components,
        switches: settings.switches,
        values: settings.values
    };
};
export const userSettingsToServiceUserSettings = (userSettings) => {
    return {
        base_app_name: userSettings.baseAppName,
        footer: userSettings.footer,
        dashboard: {
            available_dates: userSettings.dashboard?.availableDates,
            dashboard_filter_settings: {
                advertiser: {
                    ...filterSettingsToServiceFilterSettings(userSettings.dashboard?.dashboardFilterSettings.advertiser)
                },
                agency: {
                    ...filterSettingsToServiceFilterSettings(userSettings.dashboard?.dashboardFilterSettings.agency)
                },
                station: {
                    ...filterSettingsToServiceFilterSettings(userSettings.dashboard?.dashboardFilterSettings.station)
                },
                campaign: {
                    ...filterSettingsToServiceFilterSettings(userSettings.dashboard?.dashboardFilterSettings.campaign)
                },
                product: {
                    ...filterSettingsToServiceFilterSettings(userSettings.dashboard?.dashboardFilterSettings.product)
                },
                adbook: {
                    ...filterSettingsToServiceFilterSettings(userSettings.dashboard?.dashboardFilterSettings.adbook)
                },
                team: {
                    ...filterSettingsToServiceFilterSettings(userSettings.dashboard?.dashboardFilterSettings.team)
                }
            }
        },
        app_settings: appSettingsToServiceAppSettings(userSettings.appSettings),
        columns: {
            manage_campaigns_columns: userSettings.columns?.manageCampaignsColumns || []
        },
        asset_specification_id: userSettings.assetSpecificationId,
        custom_segment_selection: userSettings.customSegmentSelection,
        ext_meta: extMetaSettingsToServiceExtMetaSettings(userSettings.extMeta),
        homepage: userSettings.homepage,
        static_assets: userSettings.staticAssets,
        theme: userSettings.theme,
        suits: userSettings.suits
    };
};
const appSettingsToServiceAppSettings = (appSettings) => {
    return {
        campaigns_frequency_settings: {
            daily_freq_cap: appSettings.campaignsFrequencySettings.dailyFreqCap,
            has_freq_cap: appSettings.campaignsFrequencySettings.hasFreqCap,
            hourly_freq_cap: appSettings.campaignsFrequencySettings.hourlyFreqCap
        }
    };
};
const extMetaSettingsToServiceExtMetaSettings = (extMetaSettings) => {
    return {
        billing_field_name: extMetaSettings.billingFieldName,
        external_id_field_name: extMetaSettings.externalIdFieldName,
        is_oms_disabled: extMetaSettings.isOmsDisabled,
        oms_id_field_name: extMetaSettings.omsIdFieldName,
        oms_label: extMetaSettings.omsLabel
    };
};
const filterSettingsToServiceFilterSettings = (filterSettings) => {
    if (!filterSettings) {
        return {
            is_set: false,
            value: false
        };
    }
    return {
        is_set: filterSettings.isSet,
        value: filterSettings.value
    };
};
export const serviceUserToUser = (user) => {
    const primaryOrganizationId = getPrimaryOrganizationId(user);
    const brandingId = getBrandingId(primaryOrganizationId);
    const lastLoginDate = getLastLoginDate(user, null);
    const createdDate = getCreatedDate(user, null);
    const id = String(user.id || user.user_id || "");
    return {
        id,
        brandingId,
        userId: id,
        name: user.name,
        email: user.email,
        type: user.type,
        isAdmin: user.admin,
        isDev: user.developer,
        viewIds: user.views || [],
        primaryOrganizationId,
        advertiserId: user.advertiser_id,
        agencyId: user.agency_id,
        teamId: user.team_id,
        legacyAdvertiserId: user.legacy_advertiser_id || null,
        legacyAgencyId: user.legacy_agency_id || null,
        legacyTeamId: user.legacy_team_id || null,
        legacyUserId: user.legacy_user_id || null,
        settings: user.settings
            ? serviceUserSettingsToUserSettings(user.settings, user)
            : DEFAULT_USER_SETTINGS,
        primaryOrganizationName: ORGANIZATION_ID_TO_SMITHERS_ORG_NAME[primaryOrganizationId],
        lastLoginDate,
        createdDate,
        affiliationType: getAffiliationType(user),
        homepage: getHomepage(user),
        dashboard: getDashboard(user),
        columns: getColumns(user),
        userType: getUserType(user),
        parent: user.parent,
        immediateParent: user.immediate_parent,
        updated: user.updated,
        owners: user.owners,
        affiliations: [],
        abilities: user.abilities
    };
};

import { FC } from "react";

const HappyFace: FC = () => (
  <svg
    width="80"
    height="80"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M40 80C62.0914 80 80 62.0914 80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80Z"
      fill="#F7B239"
    />
    <path
      d="M40.187 55.9949C34.0554 55.9949 28.2906 53.6196 23.955 49.3066C23.3741 48.7287 23.3741 47.7922 23.955 47.2145C24.536 46.6366 25.4775 46.6366 26.0584 47.2145C29.832 50.9686 34.8499 53.0363 40.1871 53.0363C45.5244 53.0363 50.5423 50.9687 54.3159 47.2145C54.6064 46.9256 54.987 46.781 55.3676 46.781C55.7482 46.781 56.1289 46.9254 56.4193 47.2145C57.0002 47.7923 57.0002 48.7288 56.4193 49.3066C52.0834 53.6196 46.3186 55.9949 40.187 55.9949Z"
      fill="#4D4D4D"
    />
    <path
      d="M27.7386 35.3955C27.7386 32.3773 25.2702 29.9219 22.2361 29.9219C19.202 29.9219 16.7336 32.3773 16.7336 35.3955C16.7336 38.4137 19.202 40.8692 22.2361 40.8692C25.2702 40.8692 27.7386 38.4137 27.7386 35.3955Z"
      fill="#4D4D4D"
    />
    <path
      d="M57.7607 29.9219C54.7266 29.9219 52.2582 32.3773 52.2582 35.3955C52.2582 38.4137 54.7266 40.8692 57.7607 40.8692C60.7947 40.8692 63.2631 38.4137 63.2631 35.3955C63.2631 32.3773 60.7947 29.9219 57.7607 29.9219Z"
      fill="#4D4D4D"
    />
  </svg>
);

export default HappyFace;

import { FC } from "react";
import { DoNotCare } from "../types";

export const withKitType = <KitType extends string, C extends FC<DoNotCare>>(
  type: KitType,
  WrappedComponent: C
) => {
  const Component = WrappedComponent;
  Component.defaultProps = {
    KIT_TYPE: type
  };
  Component.propTypes = {
    KIT_TYPE: (value: DoNotCare) =>
      value?.KIT_TYPE === type
        ? null
        : new Error(`Invalid KIT_TYPE, expected ${type}`)
  };
  return Component;
};

import { useContext, useEffect } from "react";
import { Intent } from "@blueprintjs/core";
import { ToasterContext } from "lib/context";

// PK: Though it won't catch all cases, this will detect if a user's browser goes offline
//     Firebase also gives us a check out of box: (e.g "Failed to get document because user is offline")
// eslint-disable-next-line import/prefer-default-export
export const useUserConnectivity = () => {
  const { addToast } = useContext(ToasterContext);
  useEffect(() => {
    window.addEventListener("offline", () => {
      addToast({
        intent: Intent.WARNING,
        message:
          "Your internet connection is offline, please check your network settings and try again."
      });
    });
  }, []);
};

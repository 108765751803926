import axios from "axios";
import { Observable } from "rxjs";
import { ObjType } from "../../index";
import { NotImplementedError, UnauthenticatedError } from "../../errors";
import { booleanExpressionToServiceBooleanExpression, recipeToServiceRecipeBase, serviceRecipeToRecipe } from "../../models/recipe";
import { FilterTypes, FilterValueTypes, parseIdFilter, validateFilterStructure } from "../../modules/handlers/filter";
import { get, set } from "idb-keyval";
import { ObservableHandler } from "../handlers/observable";
import { RecipeMatchFailed, RecipeSaveFailed, RecipeFetchFailed } from "./errors";
// if audiences structure changes, update this to invalidate the cache
const RECIPES_CACHE_VERSION = 1;
class Recipes extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "recipes", { atomize: true });
        this.loadRecipesCache = async () => {
            const userId = this.sdk.getCurrentUser()?.id;
            const cache = await get(`recipes-${userId}`);
            if (!cache || cache.version !== RECIPES_CACHE_VERSION) {
                return null;
            }
            return cache.data;
        };
        this.saveRecipesCache = async (recipes) => {
            const userId = this.sdk.getCurrentUser()?.id;
            await set(`recipes-${userId}`, {
                version: RECIPES_CACHE_VERSION,
                data: recipes
            });
        };
        this.url = {
            single: `${this.sdk.urls.baseAPIUrl}/recipe`,
            multiple: `${this.sdk.urls.baseAPIUrl}/recipes`,
            match: `${this.sdk.urls.baseAPIUrl}/recipebyexpr`
        };
    }
    findItems(filters) {
        return new Observable((subscriber) => {
            try {
                validateFilterStructure(filters, [
                    { filterType: FilterTypes.IN, valueType: FilterValueTypes.OBJECT },
                    { filterType: FilterTypes.EQ, valueType: FilterValueTypes.STRING }
                ]);
            }
            catch (error) {
                subscriber.error(error);
                return;
            }
            const idFilter = parseIdFilter(filters);
            const promise = idFilter.size === 1
                ? this.getRecipe(idFilter.values().next().value).then((recipe) => [recipe])
                : this.getRecipes(idFilter);
            promise
                .then((recipes) => {
                subscriber.next(recipes);
                subscriber.complete();
            })
                .catch((error) => {
                subscriber.error(error);
            });
        });
    }
    /**
     * Get multiple recipes.
     * @param ids: ids to filter as included. If empty, returns all recipes. Otherwise, only those with IDs in the set.
     * @return: the array of gotten recipes.
     */
    async getRecipes(ids) {
        const all = await this.cache.promise(this.url.multiple, () => axios
            .get(this.url.multiple, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => res?.data?.map((recipe) => this.toRecipe(recipe)) || [])
            .catch((error) => {
            throw new RecipeFetchFailed(error);
        }));
        if (ids.size === 0) {
            await this.saveRecipesCache(all);
            return all;
        }
        return all.filter((recipe) => ids.has(recipe.id));
    }
    /**
     * Get a single recipe.
     * @param id: the recipe's id.
     * @return: the recipe.
     */
    getRecipe(id) {
        return this.cache.promise(id, () => axios
            .get(`${this.url.single}/${id}`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
            return serviceRecipeToRecipe(res.data.data);
        })
            .catch((error) => {
            throw new RecipeFetchFailed(error);
        }));
    }
    /**
     * @deprecated
     * recipes does not support make.
     */
    make() {
        return new Promise((_, reject) => {
            reject(new NotImplementedError("make"));
        });
    }
    saveItem(toSave) {
        return new Observable((subscriber) => {
            this.normalizeRecipesForSaving(Array.isArray(toSave) ? toSave : [toSave]).then((normalized) => {
                this.saveRecipes(normalized)
                    .then((result) => {
                    if (result.length === 1) {
                        subscriber.next(result[0]);
                    }
                    else {
                        subscriber.next(result);
                    }
                    subscriber.complete();
                })
                    .catch((error) => {
                    subscriber.error(error);
                });
            });
        });
    }
    /**
     * Ensures all the recipes have a valid id, and properly set their parentage for saving.
     * @param recipes: the recipe to normalize.
     * @return: the normalized recipe set.
     */
    normalizeRecipesForSaving(recipes) {
        const promises = [];
        const user = this.sdk.getCurrentUser();
        if (typeof user === "undefined") {
            throw new UnauthenticatedError();
        }
        recipes.forEach((recipe) => {
            let normalized;
            // en and em dashes aren't supported, so replace with hyphen
            const name = recipe.name.replace(/\u2013|\u2014/g, "-");
            if (recipe.id) {
                normalized = Promise.resolve({
                    ...recipe,
                    id: recipe.id,
                    name
                });
            }
            else {
                // if no id, we're creating a recipe
                normalized = this.sdk.cryptography.mintKey(ObjType.SEG).then((id) => ({
                    ...recipe,
                    id,
                    name
                }));
            }
            promises.push(normalized);
        });
        return Promise.all(promises);
    }
    /**
     * POSTs the proper endpoint with the given recipe.
     * @param recipe: the recipe to save.
     * @return: the saved recipe.
     */
    saveRecipes(recipes) {
        return new Promise((resolve, reject) => {
            axios
                .post(this.url.multiple, recipes.map((recipe) => this.fromRecipe(recipe)), {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(({ data: res }) => {
                const saved = Array.isArray(res.data)
                    ? res.data.map((recipe) => this.toRecipe(recipe))
                    : [this.toRecipe(res.data)];
                resolve(saved);
            })
                .catch((error) => {
                reject(new RecipeSaveFailed(error));
            });
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async deleteItem(id) {
        return new Promise((_, reject) => {
            reject(new NotImplementedError("delete"));
        });
    }
    /**
     * Checks to see if a recipe with an equivalent expression already exists.
     * @param toMatch: a recipe or an expression to check.
     * @return: if an already-existing recipe matches the provided one, that recipe is returned. Else, null.
     */
    match(toMatch) {
        return new Observable((subscriber) => {
            const expression = "name" in toMatch && "type" in toMatch
                ? this.fromRecipe(toMatch).expr
                : booleanExpressionToServiceBooleanExpression(toMatch);
            axios
                .post(this.url.match, expression, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((result) => {
                if (result.data.data) {
                    subscriber.next(this.toRecipe(result.data.data));
                }
                else {
                    subscriber.next(null);
                }
            })
                .catch((error) => {
                subscriber.error(new RecipeMatchFailed(error));
            })
                .finally(() => {
                subscriber.complete();
            });
        });
    }
    /**
     * Converts from a service recipe to a client recipe.
     * @param recipe: the recipe to convert.
     * @return: the converted recipe.
     */
    toRecipe(recipe) {
        return serviceRecipeToRecipe(recipe);
    }
    /**
     * Converts from a client recipe to a service recipe.
     * @param recipe: the recipe to convert.
     * @return: the converted recipe.
     */
    fromRecipe(recipe) {
        return recipeToServiceRecipeBase(recipe);
    }
}
export default Recipes;

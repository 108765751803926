import { FirstPartyColumnHeaders } from "../../../types";
const FIRST_PARTY_DATA_CSV_COLUMNS = Object.values(FirstPartyColumnHeaders);
export const validators = {
    /**
     * This validation is a bit complicated b/c the data is really loaded
     * from a csv so we are going to validate specific column values
     * to match what is expected in each column of data.
     * validation is based on liveramp: https://developers.liveramp.com/retrieval-api/reference/batch-request-calls-match
     */
    data: (pii, data) => {
        // we are going to use the first row to be the values of the columns and determine which validator to run
        // based on that column name (this means bad column names can skip validation)
        if (data === undefined || data.length <= 1) {
            return "CSV must include more than just column headers.";
        }
        const errors = new Map();
        const columns = data[0];
        for (let rowIndex = 1; rowIndex < data.length; rowIndex++) {
            for (let colIndex = 0; colIndex < columns.length; colIndex++) {
                const columnData = {
                    columnName: columns[colIndex],
                    value: data[rowIndex][colIndex],
                    rowIndex,
                    colIndex
                };
                const error = validateColumn(columnData);
                error && errors?.set(`${rowIndex}-${colIndex}`, error);
            }
        }
        return errors.size > 0 ? JSON.stringify(Array.from(errors.entries())) : undefined;
    },
    /**
     * Validates a single row of data by validating row value combos as well as regex rules for each column in the row.
     * @param pii - the pii object to validate - unused in this case.
     * @param rowData - the row data to validate.
     * @returns either a string containing aggregated row errors or undefined if no error.
     */
    row: (pii, rowData) => {
        const { columnHeaders, rowIndex, row } = rowData;
        const colLength = columnHeaders.length;
        const errors = [];
        const values = {};
        // Build a map of column name to value for easier access.
        columnHeaders.map((columnName, colIndex) => {
            values[columnName] = row[colIndex];
        });
        // Destructure the minimum required columns for combo validation.
        const { email, phone, name, firstName, lastName, zipCode } = values;
        // Validate combinations of columns, according the following (LiveRamp) rules
        // https://developers.liveramp.com/rampid-api/reference/batch-request-calls-match#match-parameter-combinations
        const hasName = name || (firstName && lastName);
        const hasEmailOrPhone = email || phone;
        if (!hasEmailOrPhone && !hasName) {
            errors.push("Email or Phone required.");
        }
        else if (hasName) {
            if (!(email || phone || zipCode)) {
                errors.push("Email, Phone, or Zip Code required.");
            }
        }
        else if (!hasEmailOrPhone) {
            errors.push("Must include either name or the parsed name (firstName, lastName).");
        }
        // If there isn't a valid combination of columns, we don't need to validate the individual columns
        // so return early with the errors.
        if (errors.length > 0) {
            return `Row #${rowIndex}: ${errors.join(" ")}`;
        }
        // Validate each column individually (ie. regex, length, etc.)
        for (let colIndex = 0; colIndex < colLength; colIndex++) {
            const columnName = columnHeaders[colIndex];
            const columnData = {
                columnName,
                value: row[colIndex],
                colIndex
            };
            // At this point, we are only validating columns that have values. The logic above
            // should have already verified that the row has the required columns and that the
            // columns have values.
            const error = !!columnData.value && validateColumn(columnData);
            error && errors.push(error);
        }
        return errors.length > 0 ? `Row #${rowIndex}: ${errors.join(" ")}` : undefined;
    },
    /**
     * Name shouldn't be undefined or empty
     */
    name: (pii, value) => {
        if (value === undefined || value === "") {
            return "Name is required";
        }
        return;
    }
};
/**
 * Validates a single column of data by running a regex against the column's value depending on the column name.
 * @param columnData the column data (ie. columnName, value) to validate.
 * @returns either a string containing an error message or undefined if no error.
 */
const validateColumn = (columnData) => {
    const { columnName, value } = columnData;
    switch (columnName) {
        case FirstPartyColumnHeaders.STATE:
            if (!/^[A-Z]{2}$/.test(value)) {
                return "State needs to be an uppercase two-character state abbreviation.";
            }
            break;
        case FirstPartyColumnHeaders.ZIP_CODE:
            if (!/^\d{5}(?:-\d{4})?$/.test(value)) {
                return "Zip code needs to be 5 or 9 digits, hyphen required when 9 digits.";
            }
            break;
        case FirstPartyColumnHeaders.EMAIL:
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                return "Invalid email address.";
            }
            break;
        case FirstPartyColumnHeaders.PHONE:
            if (!/^\d{10}$/.test(value)) {
                return "Phone needs to be 10 digits with no special characters.";
            }
            break;
        case FirstPartyColumnHeaders.PRIMARY_NUMBER:
            if (!/^[0-9]+$/.test(value)) {
                return "Primary number must be a numeric value.";
            }
            break;
        case FirstPartyColumnHeaders.NAME:
            if (!/^\S+\s\S+.*$/.test(value)) {
                return "Name must be a full name with at least a first and last name.";
            }
            break;
        case FirstPartyColumnHeaders.STREET_ADDRESS:
            if (!/^\S+(?:\s+\S+){2}/.test(value)) {
                return "Street Address must be a complete street address (postal address without city, state, zip).";
            }
            break;
        default:
            if (FIRST_PARTY_DATA_CSV_COLUMNS.indexOf(columnName) === -1) {
                return `Unrecognized column name: ${columnName}`;
            }
            break;
    }
    return undefined;
};

import { StationGroupOrganizations, ORGS_WITH_OPTIONAL_STATION } from "../../types";
import { campaignStatusToServiceStatus, daypartToServiceDaypart, instructionToServiceInstruction, toDayPartsFromServiceDateParts, isLiveStatus, fromServiceOwners, toServiceOwners, CampaignStatus, ServiceCampaignStatus } from "./instruction";
import { serviceCampaignLineItemToCampaignLineItem } from "./lineitems";
import { metadataToExternalMetadata, serviceExternalMetaToExternalMeta } from "./meta";
/**
 * Builds a Campaign from a Service Campaign
 * @param serviceCampaign ServiceCampaign to be converted
 * @param Cryptography Configured Cryptography
 * @returns Campaign based on the ServiceCampaign given
 */
export const serviceCampaignToCampaign = async (serviceCampaign, crypto) => {
    let isDoubleVerified = false;
    let sumOfCreativesOnAllAssociatedLineItems = 0;
    let sumOfBookedImpressionsOnUnarchivedAssociatedLineItems = 0;
    let numberOfLiveLineItems = 0;
    const lineItems = await Promise.all(Object.values(serviceCampaign.descendants || []).map((descendant) => serviceCampaignLineItemToCampaignLineItem(descendant, crypto).then((lineItem) => {
        if (lineItem.meta?.isDoubleVerify) {
            isDoubleVerified = true;
        }
        sumOfCreativesOnAllAssociatedLineItems += lineItem.creativesCount;
        if (lineItem.status !== CampaignStatus.ARCHIVED && lineItem.bookedImpressions) {
            sumOfBookedImpressionsOnUnarchivedAssociatedLineItems += lineItem.bookedImpressions;
        }
        if (lineItem.status === CampaignStatus.LIVE) {
            numberOfLiveLineItems += 1;
        }
        return lineItem;
    })));
    const { advertiserId, stationId, agencyId, stationGroupId, ownersWithTypes } = await fromServiceOwners(serviceCampaign, crypto);
    const extMeta = serviceCampaign.ext_metas
        ? serviceExternalMetaToExternalMeta(serviceCampaign.ext_metas)
        : undefined;
    const startDate = serviceCampaign.start_date ? new Date(serviceCampaign.start_date) : undefined;
    const endDate = serviceCampaign.end_date ? new Date(serviceCampaign.end_date) : undefined;
    const status = CampaignStatus[ServiceCampaignStatus[serviceCampaign.derived_status]];
    const campaign = {
        id: serviceCampaign.id || "",
        bookedImpressions: serviceCampaign.booked,
        descendantsBookedImpressions: sumOfBookedImpressionsOnUnarchivedAssociatedLineItems,
        creativesCount: sumOfCreativesOnAllAssociatedLineItems,
        liveLineItemsCount: numberOfLiveLineItems,
        startDate,
        endDate,
        startAsap: serviceCampaign.start_asap || false,
        name: serviceCampaign.name,
        externalOrderManagementSystemId: serviceCampaign.oms_id,
        mediaType: serviceCampaign.media_type,
        frequencies: serviceCampaign.frequencies || [],
        budget: serviceCampaign.budget,
        externalId: serviceCampaign.external_id,
        instructionStatus: serviceCampaign.status,
        dayparts: toDayPartsFromServiceDateParts(serviceCampaign.dayparts),
        owners: serviceCampaign.owners || [],
        lastUpdated: serviceCampaign.updated ? new Date(serviceCampaign.updated) : undefined,
        version: serviceCampaign.version,
        parent: serviceCampaign.parent,
        parentName: serviceCampaign.parent_name,
        isDoubleVerify: isDoubleVerified,
        status,
        statusDetails: {
            code: serviceCampaign.status_details.code,
            ...(serviceCampaign.status_details.invalid_line_items
                ? { invalidLineItems: serviceCampaign.status_details.invalid_line_items }
                : {}),
            summary: CampaignStatus[ServiceCampaignStatus[serviceCampaign.status_details.summary]],
            canBe: serviceCampaign.status_details.canBe
        },
        lineItems: lineItems,
        advertiserId,
        stationId,
        agencyId,
        stationGroupId,
        organizationId: serviceCampaign.parent,
        ownersWithTypes,
        updatedBy: serviceCampaign.updated_by,
        targetingRules: serviceCampaign.targeting_rules,
        get isEditable() {
            return (status !== CampaignStatus.ARCHIVED &&
                !!startDate &&
                !!endDate &&
                startDate.valueOf() < endDate.valueOf());
        },
        meta: extMeta,
        isLive() {
            return isLiveStatus(this.status);
        }
    };
    return campaign;
};
export const campaignToServiceCampaign = (campaign) => {
    if (!ORGS_WITH_OPTIONAL_STATION.has(campaign.organizationId) &&
        StationGroupOrganizations.has(campaign.organizationId) &&
        !campaign.stationId) {
        throw new Error("Attempted to saved a campaign without providing a station ID");
    }
    const externalMetadata = metadataToExternalMetadata(campaign.meta);
    const base = instructionToServiceInstruction(campaign);
    return {
        ...base,
        advertiser_id: campaign.advertiserId,
        external_id: campaign.externalId ? campaign.externalId.trim() : "",
        booked: typeof campaign.bookedImpressions !== "undefined" &&
            typeof campaign.bookedImpressions !== "number"
            ? parseInt(campaign.bookedImpressions)
            : campaign.bookedImpressions,
        end_date: (campaign.endDate ? campaign.endDate : new Date()).toISOString(),
        start_date: (campaign.startDate ? campaign.startDate : new Date()).toISOString(),
        start_asap: campaign.startAsap || false,
        owners: toServiceOwners(campaign),
        status: campaignStatusToServiceStatus(campaign.status),
        dayparts: daypartToServiceDaypart(campaign.dayparts),
        ext_metas: externalMetadata,
        parent_name: campaign.parentName
    };
};
/**
 * @param item: anything.
 * @return: type narrowed. True if this is a campaign. Else, false.
 */
export const isCampaign = (item) => {
    return (typeof item === "object" &&
        item !== null &&
        "id" in item &&
        "lineItems" in item &&
        "descendantsBookedImpressions" in item &&
        "owners" in item);
};

import {
  GET_GEOGRAPHY_PENDING,
  GET_GEOGRAPHY_SUCCESS,
  GET_GEOGRAPHY_FAILURE,
  GeographyState,
  GeographyActionTypes
} from "./types";

export const geographyInitialState: GeographyState = {
  unfilteredGeoInfo: [],
  isLoading: false,
  isLoaded: false,
  error: null
};

export const geographyReducer = (
  state = geographyInitialState,
  action: GeographyActionTypes
): GeographyState => {
  switch (action.type) {
    case GET_GEOGRAPHY_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case GET_GEOGRAPHY_SUCCESS:
      return {
        ...state,
        unfilteredGeoInfo: action.payload,
        isLoading: false,
        isLoaded: true
      };
    case GET_GEOGRAPHY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.meta.error
      };
    default:
      return state;
  }
};

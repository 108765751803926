import {
  ScreenViewMode,
  VIEW_MODE_PARAM
} from "features/ManageCampaigns/constants";
import { ManageCampaignTabId } from "features/ManageCampaigns/MainScreen/MainScreenHeader";
import { readQueryString } from "lib/utils/qs";
import {
  COPY_APPLIED_FILTERS_TO_SELECTED_FILTERS,
  SET_CAMPAIGNS_SELECTABLE_VALUES_FAILURE,
  SET_CAMPAIGNS_SELECTABLE_VALUES_PENDING
} from ".";
import {
  CampaignsDashboardActionTypes,
  CampaignsDashboardState,
  SET_CAMPAIGNS_SELECTABLE_VALUES_SUCCESS,
  UPDATE_CAMPAIGNS_DASHBOARD
} from "./types";

// TODO init all user dependent fields
export const campaignsDashboardInitialState: CampaignsDashboardState = {
  searchTerm: "",
  appliedFilters: [],
  selectedFilters: [],
  isSettingsDrawerOpen: false,
  isFilterDialogOpen: false,
  isDownloadDialogOpen: false,
  isCreativeDeliveryLoading: false,
  expandedCampaignIds: new Set(),
  isBulkAssignDrawerOpen: false,
  expandedLineItemIds: new Set(),
  defaultColumns: [],
  columnsForUser: [],
  isBulkEditing:
    readQueryString(VIEW_MODE_PARAM, ScreenViewMode.READONLY) !==
    ScreenViewMode.READONLY,
  selectedTabId: ManageCampaignTabId.CAMPAIGNS,
  dates: {
    startDate: new Date(),
    endDate: new Date()
  },
  availableDashboardDates: { min: undefined, max: undefined },
  isFilterByAdvertiserClicked: false,
  campaignFilterSelectableValues: {
    loading: false,
    query: "",
    values: [],
    cancellationSource: null
  }
};

export const campaignsDashboardReducer = (
  state = campaignsDashboardInitialState,
  action: CampaignsDashboardActionTypes
): CampaignsDashboardState => {
  switch (action.type) {
    case UPDATE_CAMPAIGNS_DASHBOARD:
      return {
        ...state,
        ...action.payload
      };
    case COPY_APPLIED_FILTERS_TO_SELECTED_FILTERS:
      return {
        ...state,
        selectedFilters: state.appliedFilters
      };
    case SET_CAMPAIGNS_SELECTABLE_VALUES_PENDING:
      return {
        ...state,
        campaignFilterSelectableValues: {
          ...state.campaignFilterSelectableValues,
          loading: true,
          cancellationSource: action.cancellationSource
        }
      };
    case SET_CAMPAIGNS_SELECTABLE_VALUES_SUCCESS:
      return {
        ...state,
        campaignFilterSelectableValues: {
          cancellationSource: null,
          loading: false,
          values: action.values,
          query: action.query
        }
      };
    case SET_CAMPAIGNS_SELECTABLE_VALUES_FAILURE:
      return {
        ...state,
        campaignFilterSelectableValues: {
          cancellationSource: null,
          loading: false,
          values: [],
          query: ""
        }
      };
    default:
      return state;
  }
};

import { getStatusesByArchiveFilter } from "../../models/footTrafficAttribution";
import { formatDate } from "../../utils/dates";
import { getDetailedErrorMessage } from "../../utils/validation";
import axios from "axios";
import { MadSDKAbortError } from "../../errors";
import { ObservableHandler } from "../handlers";
import { ServiceStatus } from "../../types";
import { Observable, Subject } from "rxjs";
import FootTrafficPoller from "./poller";
import { ArchiveFootTrafficReportFailure, FootTrafficAttributionReportsFetchFailure, UnarchiveFootTrafficReportFailure } from "./errors";
import ReportResults from "./reportResults";
class FootTrafficAttribution extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "foot-traffic-attribution");
        this.reportResults = new ReportResults(this.sdk);
    }
    get reportsUrl() {
        return `${this.sdk.urls.madhiveReportingBaseUrl}/foot_traffic/reports`;
    }
    getFootTrafficReports(filters) {
        const resultKeys = filters.where?.find((filter) => filter.field === "resultKeys")?.value;
        const archiveFilter = getStatusesByArchiveFilter(filters.where?.find((filter) => filter.field === "archiveStatus")?.value);
        const cacheKey = `${this.reportsUrl}/${resultKeys ? resultKeys.join(",") : ""}/archive=${archiveFilter}`;
        return this.cache.promise(cacheKey, () => {
            const requestBody = {
                resultKeys,
                archive: archiveFilter
            };
            return axios
                .post(this.reportsUrl, requestBody, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(({ data }) => {
                const reports = data?.items || [];
                return Promise.resolve(reports);
            })
                .catch(() => {
                throw new FootTrafficAttributionReportsFetchFailure();
            });
        });
    }
    isFetchingReportsList(filters) {
        return !filters.where?.find((filter) => filter.field === "resultKeys")?.value;
    }
    initiatePoller(reports, subject) {
        this.poller?.stop();
        this.poller = new FootTrafficPoller(this.sdk, {
            data: reports,
            subject,
            clearCache: () => {
                this.cache.clear();
            }
        });
        this.poller.start();
    }
    findItems(filters) {
        const subject = new Subject();
        this.getFootTrafficReports(filters)
            .then((reports) => {
            subject.next(reports);
            if (this.isFetchingReportsList(filters)) {
                // we only initialize the poller when we are fetching the list of reports
                // whenever we filter by any specific report resultKeys there is no reason to initialize the poller
                this.initiatePoller(reports, subject);
            }
        })
            .catch((error) => subject.error(error));
        return subject;
    }
    async createReport(report) {
        const { data } = await axios.post(`${this.sdk.urls.madhiveReportingBaseUrl}/foot_traffic`, {
            ...report,
            endDate: formatDate(new Date(report.endDate)),
            startDate: formatDate(new Date(report.startDate))
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        return data.item;
    }
    /**
     * Creates or updates a foot traffic report
     * @param {FootTrafficParameters} data The payload sent by the FootTrafficCreateView
     */
    saveItem(data) {
        return new Observable((subscriber) => {
            let saveFn;
            /**
             * there is no report edit in foot traffic attribution
             *  if the id exists in the data passed into saveItem then we are updating the archive status of the report to unarchived
             */
            if ("id" in data && data.deletedAt) {
                saveFn = () => this.updateArchiveStatus(data.id, ServiceStatus.READY);
            }
            else {
                saveFn = () => this.createReport(data);
            }
            saveFn()
                .then((report) => {
                this.cache.clear();
                subscriber.next(report);
                subscriber.complete();
            })
                .catch((error) => {
                subscriber.error(this.getValidError(error));
            });
        });
    }
    /**
     * Updates the reports archive status to either archived or ready
     * @param reportId
     * @param serviceStatus
     * @returns
     */
    async updateArchiveStatus(reportId, serviceStatus) {
        const { data } = await axios.patch(`${this.sdk.urls.madhiveReportingBaseUrl}/custom_report/${reportId}`, {
            archive: serviceStatus
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        });
        const report = data?.report;
        if (!report) {
            if (serviceStatus === ServiceStatus.READY) {
                throw new ArchiveFootTrafficReportFailure(reportId);
            }
            else {
                throw new UnarchiveFootTrafficReportFailure(reportId);
            }
        }
        this.cache.clear();
        return report;
    }
    deleteItem(id) {
        try {
            return this.updateArchiveStatus(id, ServiceStatus.ARCHIVED);
        }
        catch (error) {
            throw this.getValidError(error);
        }
    }
    getValidError(error) {
        if (axios.isCancel(error)) {
            return new MadSDKAbortError();
        }
        return new Error(getDetailedErrorMessage(error, error.message));
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    make(defaults) {
        throw new Error("Method not implemented.");
    }
    // stops the poller if its instantiated
    complete() {
        this.poller?.stop();
    }
}
export default FootTrafficAttribution;

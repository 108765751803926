export const validators = {
    callLetters: (station, value) => {
        if (typeof value !== "string" || !value.trim().match(/^[A-Za-z0-9-]{3,7}$/)) {
            return "Call letters must be composed of 3-7 alphanumeric characters, without spaces.";
        }
        return undefined;
    },
    name: (station, value) => {
        if (typeof value !== "string" || !value.trim().match(/^[A-Za-z0-9\s-]{1,255}$/)) {
            return "Station names must be composed of 1-255 characters, using only alphanumeric characters and spaces.";
        }
        return undefined;
    },
    dmaId: (station, value) => {
        const { dmas, dmaId } = value;
        if (dmas && dmas.length === 0) {
            return "No DMA data available";
        }
        else if (dmaId && !dmas.find((dma) => dma.id === dmaId)) {
            return "DMA ID is unrecognized";
        }
        return undefined;
    }
};

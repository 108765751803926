import { OrganizationSettings } from "@madhive/mad-sdk";

export const GET_ORG_SETTINGS_PENDING = "GET_ORG_SETTINGS_PENDING";
export const GET_ORG_SETTINGS_SUCCESS = "GET_ORG_SETTINGS_SUCCESS";
export const GET_ORG_SETTINGS_FAILURE = "GET_ORG_SETTINGS_FAILURE";

export interface OrgSettingsState {
  readonly settings: OrganizationSettings | undefined;
  readonly isLoading: boolean;
  readonly isSaving: boolean;
  readonly error: string | null;
}

interface GetOrgSettingsPendingAction {
  type: typeof GET_ORG_SETTINGS_PENDING;
}

interface GetOrgSettingsSuccessAction {
  type: typeof GET_ORG_SETTINGS_SUCCESS;
  payload: OrganizationSettings;
}

interface GetOrgSettingsFailureAction {
  type: typeof GET_ORG_SETTINGS_FAILURE;
  meta: {
    error: string;
  };
}

export type OrgSettingsActionTypes =
  | GetOrgSettingsPendingAction
  | GetOrgSettingsSuccessAction
  | GetOrgSettingsFailureAction;

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const SearchList: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-search-list"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.002 8.74832C17.7667 8.74832 18.4779 8.51954 19.071 8.12669L20.4734 9.52909C20.7663 9.82198 21.2411 9.82198 21.534 9.52909C21.8269 9.23619 21.8269 8.76132 21.534 8.46843L20.1316 7.06603C20.5245 6.47296 20.7533 5.76173 20.7533 4.99707C20.7533 2.92531 19.0738 1.24582 17.002 1.24582C14.9303 1.24582 13.2508 2.92531 13.2508 4.99707C13.2508 7.06883 14.9303 8.74832 17.002 8.74832ZM17.002 2.74582C15.7587 2.74582 14.7508 3.75374 14.7508 4.99707C14.7508 6.2404 15.7587 7.24832 17.002 7.24832C17.6133 7.24832 18.1677 7.00468 18.5734 6.60921C18.5799 6.60219 18.5866 6.59527 18.5934 6.58844C18.6002 6.58162 18.6071 6.57495 18.6142 6.56844C19.0096 6.16277 19.2533 5.60837 19.2533 4.99707C19.2533 3.75374 18.2454 2.74582 17.002 2.74582Z"
      />
      <path d="M10.9995 2.24625H3.99663C3.0302 2.24659 2.24656 3.03023 2.24622 3.99666V5.99777C2.24656 6.9642 3.0302 7.74757 3.99663 7.74791H10.9995C11.4138 7.74791 11.7495 7.41213 11.7495 6.99791C11.7495 6.5837 11.4138 6.24791 10.9995 6.24791H3.9969C3.85847 6.24786 3.74627 6.13566 3.74622 5.99723V3.99683C3.74632 3.85845 3.85851 3.7463 3.9969 3.74625H10.9995C11.4138 3.74625 11.7495 3.41046 11.7495 2.99625C11.7495 2.58203 11.4138 2.24625 10.9995 2.24625Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.24715 10.7498H3.74653C2.9182 10.7501 2.24651 11.4218 2.24622 12.2501V13.751C2.24651 14.5793 2.9182 15.2507 3.74653 15.251H5.24742C6.07575 15.2507 6.74717 14.5791 6.74747 13.7507V12.2498C6.74717 11.4215 6.07548 10.7501 5.24715 10.7498ZM3.74622 13.7505V12.2504H5.24747L5.24688 13.751L3.74622 13.7505Z"
      />
      <path d="M8.47079 13.0004C8.47079 12.5862 8.80658 12.2504 9.22079 12.2504H21.0037C21.4179 12.2504 21.7537 12.5862 21.7537 13.0004C21.7537 13.4146 21.4179 13.7504 21.0037 13.7504H9.22079C8.80658 13.7504 8.47079 13.4146 8.47079 13.0004Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.24715 17.2525H3.74653C2.9182 17.2528 2.24651 17.9245 2.24622 18.7528V20.2537C2.24651 21.082 2.9182 21.7535 3.74653 21.7538H5.24742C6.07575 21.7535 6.74717 21.0818 6.74747 20.2534V18.7525C6.74717 17.9242 6.07548 17.2528 5.24715 17.2525ZM3.74622 20.2532V18.7531H5.24747L5.24688 20.2538L3.74622 20.2532Z"
      />
      <path d="M8.47079 19.5031C8.47079 19.0889 8.80658 18.7531 9.22079 18.7531H21.0037C21.4179 18.7531 21.7537 19.0889 21.7537 19.5031C21.7537 19.9173 21.4179 20.2531 21.0037 20.2531H9.22079C8.80658 20.2531 8.47079 19.9173 8.47079 19.5031Z" />
    </svg>
  );
};

export default SearchList;

import { getDetailedErrorMessage } from "../../utils";
export class UnfinishedReport extends Error {
    /** @param id: the id of the report. */
    constructor(id) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnfinishedReport);
        }
        this.name = "UnfinishedReport";
        this.message = `The report '${id}' has not yet finished generating. Please try again at a later time.`;
    }
}
export class InvalidCustomDateFilter extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidCustomDateFilter);
        }
        this.name = "InvalidCustomDateFilter";
        this.message =
            "A custom date range filter must be a tuple of the start and end date, respectively, each in Date object format.";
    }
}
export class InvalidQueryParameter extends Error {
    /** @param name: the name of the invalid parameter. */
    constructor(name) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidQueryParameter);
        }
        this.name = "InvalidQueryParameter";
        this.message = `Invalid values supplied to the parameter '${name}'.`;
    }
}
export class QuerySubmissionFailed extends Error {
    /**
     * @param caught: the caught error.
     * @param fallbackMessage: a message to show, if the caught error doesn't have any pertinent info.
     */
    constructor(caught, fallbackMessage = "Failed to submit query for statistics. Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, QuerySubmissionFailed);
        }
        this.name = "QuerySubmissionFailed";
        this.message = getDetailedErrorMessage(caught, fallbackMessage);
    }
}
export class UntrackableQuery extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UntrackableQuery);
        }
        this.name = "UntrackableQuery";
        this.message =
            "The service did not return the ID of the query submission. The result of this query is now untrackable.";
    }
}
export class ReportFetchFailed extends Error {
    /**
     * @param caught: the caught error.
     * @param fallbackMessage: a message to show, if the caught error doesn't have any pertinent info.
     */
    constructor(caught, fallbackMessage = "Failed to retrieve statistics report. Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ReportFetchFailed);
        }
        this.name = "ReportFetchFailed";
        this.message = getDetailedErrorMessage(caught, fallbackMessage);
    }
}
export class ParameterFetchFailed extends Error {
    /**
     * @param caught: the caught error.
     * @param fallbackMessage: a message to show, if the caught error doesn't have any pertinent info.
     */
    constructor(caught, type, fallbackMessage = `Failed to retrieve parameters for '${type}'. Please try again at a later time.`) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ParameterFetchFailed);
        }
        this.name = "ParameterFetchFailed";
        this.message = getDetailedErrorMessage(caught, fallbackMessage);
    }
}

/**
 * @param template: a client-side template rep.
 * @return: an equivalent service rep of a template.
 */
export const templateToServiceTemplate = (template) => ({
    data: template.rows
        ? template.rows.map((row) => ({
            ID: row.id,
            Filename: row.file
        }))
        : []
});

import { orgServiceStringConstants } from "./organization";
export const ValidOrgTypesForProvisioning = [
    orgServiceStringConstants.ADV,
    orgServiceStringConstants.AGENCY,
    orgServiceStringConstants.STATION_GROUP,
    orgServiceStringConstants.DSP
];
const serviceDataPackageToDataPackage = (serviceDataPackage) => {
    return {
        id: serviceDataPackage.id,
        name: serviceDataPackage.name
    };
};
const dataPackageToServiceDataPackage = (dataPackage) => {
    return {
        id: dataPackage.id,
        name: dataPackage.name
    };
};
const serviceDataPackageTierToDataPackageTier = (serviceDataPackageTier) => {
    return {
        id: serviceDataPackageTier.id,
        name: serviceDataPackageTier.name,
        dataPackageId: serviceDataPackageTier.data_package_id
    };
};
const dataPackageTierToServiceDataPackageTier = (dataPackageTier) => {
    return {
        id: dataPackageTier.id,
        name: dataPackageTier.name,
        data_package_id: dataPackageTier.dataPackageId
    };
};
export const serviceOrgListItemToProvision = (serviceOrgListItemData) => {
    return {
        orgId: serviceOrgListItemData.id,
        orgName: serviceOrgListItemData.name,
        createdAt: serviceOrgListItemData.audience_provision_created_at,
        updatedAt: serviceOrgListItemData.audience_provision_updated_at,
        // The following fields do not exist in org list items but are required for the AudienceProvision
        // model, so we set them to default values. This is required in order for us to use a
        // single model for both the org list and the org details API responses.
        availableDataPackages: [],
        availableDataPackageTiers: [],
        permissionedDataPackages: [],
        permissionedDataPackageTiers: [],
        firstPartyData: false
    };
};
export const serviceProvisionToProvision = (serviceAudienceProvision) => {
    return {
        orgId: serviceAudienceProvision.id,
        orgName: serviceAudienceProvision.name,
        createdAt: serviceAudienceProvision.created_at,
        updatedAt: serviceAudienceProvision.updated_at,
        availableDataPackages: serviceAudienceProvision.available_data_packages?.map((dataPackage) => serviceDataPackageToDataPackage(dataPackage)),
        availableDataPackageTiers: serviceAudienceProvision.available_data_package_tiers?.map((dataPackageTier) => serviceDataPackageTierToDataPackageTier(dataPackageTier)),
        permissionedDataPackages: serviceAudienceProvision.permissioned_data_packages?.map((dataPackage) => serviceDataPackageToDataPackage(dataPackage)),
        permissionedDataPackageTiers: serviceAudienceProvision.permissioned_data_package_tiers?.map((dataPackageTier) => serviceDataPackageTierToDataPackageTier(dataPackageTier)),
        firstPartyData: !!serviceAudienceProvision.first_party_data
    };
};
export const provisionToServiceProvision = (audienceProvision) => {
    return {
        id: audienceProvision.orgId,
        name: audienceProvision.orgName,
        created_at: audienceProvision.createdAt,
        updated_at: audienceProvision.updatedAt,
        available_data_packages: audienceProvision.availableDataPackages?.map((dataPackage) => dataPackageToServiceDataPackage(dataPackage)),
        available_data_package_tiers: audienceProvision.availableDataPackageTiers?.map((dataPackageTier) => dataPackageTierToServiceDataPackageTier(dataPackageTier)),
        permissioned_data_packages: audienceProvision.permissionedDataPackages?.map((dataPackage) => dataPackageToServiceDataPackage(dataPackage)),
        permissioned_data_package_tiers: audienceProvision.permissionedDataPackageTiers?.map((dataPackageTier) => dataPackageTierToServiceDataPackageTier(dataPackageTier)),
        first_party_data: !!audienceProvision.firstPartyData
    };
};

import { FC } from "react";
import { Link } from "react-router-dom";
import ListItem from "frontier/lib/components/List/ListItem";
import ListItemIcon from "frontier/lib/components/List/ListItemIcon";
import { ListItemText } from "frontier/lib/components/List/ListItemText";
import { style } from "./ViewSelectorDrawer";

interface ViewLinkProps {
  href: string;
  viewId: string;
  icon: JSX.Element;
  title: string;
  activeItem: string;
  onClick: () => void;
}

const ViewLink: FC<ViewLinkProps> = ({
  activeItem,
  href,
  viewId,
  title,
  icon,
  onClick
}) => {
  const isActive = href === activeItem;

  return (
    <Link to={href} key={viewId} css={style.linkText} onClick={onClick}>
      <ListItem button tabIndex={-1} css={style.listContainer(isActive)}>
        <ListItemIcon
          css={style.listItemIcon}
          data-testid={viewId}
          size="medium"
          href={href}
          activeItem={activeItem}
        >
          {icon}
        </ListItemIcon>
        <ListItemText value={title} />
      </ListItem>
    </Link>
  );
};

export default ViewLink;

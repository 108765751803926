import { useState, useMemo, useEffect, useRef } from "react";
import { InputTimeProps } from "../Picker/Time/types";

export const useInputTime = ({
  hour: incomingHour = 0,
  minute: incomingMinute = 0,
  second: incomingSecond = 0,
  onChange
}: Omit<InputTimeProps, "error">) => {
  const [time, setTime] = useState({
    hour: incomingHour,
    minute: incomingMinute,
    second: incomingSecond
  });

  const displayTime = useMemo(() => {
    let hour: number;
    if (time.hour > 12) {
      hour = time.hour - 12;
    } else if (time.hour === 0) {
      hour = 12;
    } else {
      hour = time.hour;
    }
    return {
      hour: String(hour).padStart(2, "0"),
      minute: String(time.minute).padStart(2, "0"),
      second: String(time.second).padStart(2, "0")
    };
  }, [time]);

  const hourRef = useRef<HTMLInputElement | null>(null);
  const minuteRef = useRef<HTMLInputElement | null>(null);
  const secondRef = useRef<HTMLInputElement | null>(null);
  const amPmRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    setTime({
      hour: Number.isNaN(incomingHour) ? 0 : incomingHour,
      minute: Number.isNaN(incomingMinute) ? 0 : incomingMinute,
      second: Number.isNaN(incomingSecond) ? 0 : incomingSecond
    });
  }, [incomingHour, incomingMinute, incomingSecond]);

  const amPm = useMemo(() => {
    if (time.hour >= 12) {
      return "PM";
    }
    return "AM";
  }, [time.hour]);

  const toggleAmPm = () => {
    let { hour } = time;
    hour = hour >= 12 ? hour - 12 : hour + 12;
    setTime({ ...time, hour });
    onChange({ ...time, hour });
  };

  const setHour = (value: number) => {
    let hour = (Number.isNaN(value) ? Number(displayTime.hour) : value) % 12;
    if (amPm === "PM") {
      hour += 12;
    }
    setTime(prev => ({ ...prev, hour }));
  };

  const setMinute = (value: number) => {
    setTime(prev => ({ ...prev, minute: value ? value % 60 : 0 }));
  };

  const setSecond = (value: number) => {
    setTime(prev => ({ ...prev, second: value ? value % 60 : 0 }));
  };

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLButtonElement>,
    refName: "hour" | "minute" | "second" | "amPm"
  ) => {
    if (["ArrowUp", "ArrowDown"].includes(e.key) && refName !== "amPm") {
      let changeValue = e.key === "ArrowUp" ? 1 : -1;
      if (e.shiftKey) {
        changeValue *= 10;
      }

      setTime(prev => {
        const current = prev[refName];
        let newValue = current + changeValue;
        if (newValue < 0) {
          newValue = 0;
        }
        if (refName === "hour" && newValue > 23) {
          newValue = 23;
        }
        if (["minute", "second"].includes(refName) && newValue > 59) {
          newValue = 59;
        }
        return { ...prev, [refName]: newValue };
      });
    }
    if (["ArrowRight", "ArrowLeft"].includes(e.key)) {
      switch (refName) {
        case "hour": {
          if (
            e.key === "ArrowRight" &&
            hourRef.current?.selectionStart ===
              e.currentTarget?.value?.length &&
            hourRef.current?.selectionEnd === e.currentTarget?.value?.length
          ) {
            minuteRef.current?.select();
          }
          break;
        }
        case "minute": {
          if (
            e.key === "ArrowRight" &&
            minuteRef.current?.selectionStart ===
              minuteRef.current?.value?.length &&
            minuteRef.current?.selectionEnd === minuteRef.current?.value?.length
          ) {
            secondRef.current?.focus();
          }
          if (
            e.key === "ArrowLeft" &&
            minuteRef.current?.selectionStart === 0 &&
            minuteRef.current?.selectionEnd === 0
          ) {
            hourRef.current?.focus();
          }
          break;
        }
        case "second": {
          if (
            e.key === "ArrowRight" &&
            secondRef.current?.selectionStart ===
              secondRef.current?.value?.length &&
            secondRef.current?.selectionEnd === secondRef.current?.value?.length
          ) {
            amPmRef.current?.focus();
          }
          if (
            e.key === "ArrowLeft" &&
            secondRef.current?.selectionEnd === 0 &&
            secondRef.current?.selectionStart === 0
          ) {
            minuteRef.current?.focus();
          }
          break;
        }
        case "amPm": {
          if (e.key === "ArrowLeft") {
            secondRef.current?.select();
          }
          break;
        }
        default:
          break;
      }
    }
  };

  const commitChange = () => onChange(time);

  return {
    displayTime,
    amPm,
    commitChange,
    hourRef,
    minuteRef,
    secondRef,
    amPmRef,
    onKeyDown,
    toggleAmPm,
    setHour,
    setMinute,
    setSecond
  };
};

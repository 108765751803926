import { Base } from "../../../handlers";
import { validators } from "./validators";
/**
 * Handler for lineItem.attachedCreatives to provide validation
 */
export class LineItemCreativeFlights extends Base {
    constructor(sdk) {
        super(sdk, "lineitems.creativeFlights");
        this.validators = validators(sdk);
    }
    /**
     * key to put the parent object on the child object when validating
     */
    getKeyToPutParentOn() {
        return "lineItem";
    }
    getDependantValidatorKeys(key) {
        switch (key) {
            case "startDate":
            case "endDate":
                return ["startDate", "endDate"];
            default:
                return [];
        }
    }
}

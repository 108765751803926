import { Routes } from "lib/constants/routes";
import { Link } from "react-router-dom";
import { InternalToolsIcon } from "components/Svg/ViewSelectorDrawerIcons";
import ListItem from "frontier/lib/components/List/ListItem";
import { ListItemText } from "frontier/lib/components/List/ListItemText";
import ListItemIcon from "frontier/lib/components/List/ListItemIcon";
import { Icons } from "frontier/lib/kit";
import { FC } from "react";
import { style } from "./ViewSelectorDrawer";

const InternalViewLink: FC<{
  to: Routes;
  text: string;
  onClick: () => void;
}> = ({ to, text, onClick }) => (
  <Link to={to} css={style.linkText} onClick={onClick}>
    <ListItem button tabIndex={-1} css={style.listContainer()}>
      <ListItemIcon css={style.listItemIcon}>
        <Icons.Display size="medium" fill="var(--color-secondary)">
          <InternalToolsIcon />
        </Icons.Display>
      </ListItemIcon>
      <ListItemText value={text} />
    </ListItem>
  </Link>
);

export default InternalViewLink;

import { css } from "@emotion/react";
import { styles as inputStyles } from "../../styles";

export const styles = {
  base: css`
    ${inputStyles.base}
    width: auto;
    input {
      width: 1.5rem;
      min-width: 1.5rem;
      text-align: center;
      -moz-appearance: textfield;
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      &:focus {
        outline: none;
      }
    }
    button {
      -webkit-appearance: none;
      border: none;
      cursor: pointer;
      outline: none;
      border-radius: var(--spacing-4);
      padding-top: 2px;
      padding-bottom: 2px;
      background-color: transparent;
      &:focus {
        background-color: var(--color-background-tertiary);
      }
    }
  `,
  withError: css`
    ${inputStyles.withError}
  `,
  colon: css`
    position: relative;
    top: -1px;
  `
};

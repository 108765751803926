import { FC, useImperativeHandle, useMemo, useState } from "react";
import DropdownItem from "../Item";
import { DropdownListProps } from "./types";
import { styles } from "./styles";

const DropdownList: FC<DropdownListProps> = ({
  cannotClear,
  items,
  title,
  selected = [],
  fixed,
  multiple,
  hideSelected,
  bordered,
  max,
  setFocusRef,
  lookupId,
  lookupLabel,
  lookupIcon,
  onItemFocus,
  onItemBlur,
  onSelectItem: incomingOnSelectItem,
  onListBlur
}) => {
  const [focusedIndex, setFocusedIndex] = useState<number | undefined>();

  const visibleItems = useMemo(() => {
    if (!hideSelected) {
      return items;
    }
    const visibleItems = [];
    for (let i = 0; i < items.length; i++) {
      if (!selected.includes(String(lookupId(items[i])))) {
        visibleItems.push(items[i]);
      }
    }
    return visibleItems;
  }, [items, selected, hideSelected]);

  const getNextFocusableIndex = (
    itemIndex: number,
    direction: "prev" | "next"
  ) => {
    const directionValue = direction === "prev" ? -1 : 1;
    let nextFocusableIndex = itemIndex + directionValue;
    for (
      let i = itemIndex + directionValue;
      i >= 0 && i < visibleItems.length;
      i += directionValue
    ) {
      const id = items[i] && lookupId(items[i]);
      if (!id || fixed?.has(id)) {
        nextFocusableIndex += directionValue;
        continue;
      }
      break;
    }

    if (nextFocusableIndex >= visibleItems.length) {
      nextFocusableIndex = Infinity;
    }
    return nextFocusableIndex;
  };

  const setNextFocusableIndex = (
    itemIndex: number | undefined,
    direction: "prev" | "next"
  ) => {
    if (itemIndex === undefined) {
      return setFocusedIndex(itemIndex);
    }
    if (itemIndex < 0) {
      return onListBlur?.("prev");
    }
    if (itemIndex >= visibleItems.length) {
      return onListBlur?.("next");
    }

    const id = visibleItems[itemIndex] && lookupId(visibleItems[itemIndex]);
    if (id && !fixed?.has(id)) {
      return setFocusedIndex(itemIndex);
    }

    const nextFocusableIndex = getNextFocusableIndex(itemIndex, direction);

    setFocusedIndex(nextFocusableIndex);

    if (nextFocusableIndex < 0 || nextFocusableIndex === Infinity) {
      onListBlur?.(direction);
    }
  };

  useImperativeHandle(setFocusRef, () => (index: number | undefined) => {
    setNextFocusableIndex(index, "next");
  });

  const onSelectItem = (id: string, itemIndex: number) => {
    incomingOnSelectItem(id);
    if (selected.includes(id)) {
      return setNextFocusableIndex(itemIndex - 1, "prev");
    }
    if (visibleItems.length === 1) {
      return onListBlur?.("next");
    }
    setNextFocusableIndex(itemIndex, "next");
  };

  const onItemKeyDown = (
    e: React.KeyboardEvent<HTMLElement>,
    itemIndex: number
  ) => {
    switch (e.key) {
      case "Tab": {
        e.preventDefault();
        if (e.shiftKey) {
          setNextFocusableIndex(itemIndex - 1, "prev");
          break;
        }
        setNextFocusableIndex(itemIndex + 1, "next");
        break;
      }
      case "ArrowUp": {
        setNextFocusableIndex(itemIndex - 1, "prev");
        break;
      }
      case "ArrowDown": {
        setNextFocusableIndex(itemIndex + 1, "next");
        break;
      }
      case "Delete":
      case "Backspace": {
        const id = items[itemIndex] && lookupId(items[itemIndex]);
        if (id && !fixed?.has(id) && !cannotClear && selected.includes(id)) {
          onSelectItem(id, itemIndex);
        }
        break;
      }
      default:
        break;
    }
  };

  const Items: JSX.Element[] = [];
  for (let i = 0; i < visibleItems.length; i++) {
    const id = !!visibleItems[i] && lookupId(visibleItems[i]);
    if (!id) {
      continue;
    }
    const isSelected = multiple ? selected.includes(id) : selected[0] === id;
    if (!isSelected || !hideSelected) {
      const clearable = multiple ? !fixed?.has(id) : !cannotClear;

      Items.push(
        <DropdownItem
          item={visibleItems[i]}
          isSelected={isSelected}
          focus={focusedIndex === i}
          onSelectItem={id => onSelectItem(id, i)}
          key={id}
          onKeyDown={e => onItemKeyDown(e, i)}
          lookupId={lookupId}
          lookupLabel={lookupLabel}
          lookupIcon={lookupIcon}
          onFocus={e => onItemFocus?.(e, visibleItems[i], i)}
          onBlur={e => {
            if (i === focusedIndex) {
              setFocusedIndex(undefined);
            }
            onItemBlur?.(e, visibleItems[i], i);
          }}
          disabled={!!max && selected?.length >= max}
          clearable={clearable}
        />
      );
    }
  }

  if (!Items.length) {
    return null;
  }

  return (
    <ul
      className="subKit-DropdownList"
      role="group"
      css={[styles.base, bordered && styles.bordered]}
      onClick={e => {
        e.stopPropagation();
        e.preventDefault();
      }}
    >
      {!!title && <legend css={[styles.title]}>{title}</legend>}
      {Items}
    </ul>
  );
};

export default DropdownList;
export * from "./types";

import { css } from "@emotion/react";
import { kitText } from "../../../styles";

export const styles = {
  base: css`
    margin: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
    padding: 0 var(--spacing-4) var(--spacing-4) var(--spacing-4);
  `,
  bordered: css`
    border-top: 1px solid var(--color-border-secondary);
    padding-top: var(--spacing-4);
  `,
  title: css`
    ${kitText.variant.body}
    ${kitText.style.bold}
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--color-tertiary);
    padding: var(--spacing-8) var(--spacing-12) var(--spacing-4)
      var(--spacing-8);
  `
};

import { FilterTypes } from "../../filter";
/**
 * FindOperator knows how to manipulate a set of filters and hydrated fields via a set of standard methods.
 * @template {FilterType}: the shape of allowed filters.
 * @template {SortType}: the shape of an allowed sort field.
 * @template {HydrateType}: the shape of allowed hydrated fields.
 */
export class Finder {
    /**
     * @param identifierKey: the key into the UniqueType which uniquely identifies the object.
     * @param _filters: any set of filters provided to the find.
     * @param _sort: any sorted field provided to the find.
     * @param _hydratedFields: any set of hydrated fields provided to the find.
     */
    constructor(identifierKey, _filters, _sort, _hydratedFields = new Set()) {
        this.identifierKey = identifierKey;
        this._filters = _filters;
        this._sort = _sort;
        this._hydratedFields = _hydratedFields;
    }
    /** The filters as they current exist. */
    get filters() {
        return { ...this._filters };
    }
    /** The sort as it currently exists. */
    get sortField() {
        return this._sort ? { ...this._sort } : undefined;
    }
    /** The hydrated fields as they currently exist. */
    get hydratedFields() {
        return Array.from(this._hydratedFields);
    }
    byId(id, options) {
        const idFilter = {
            field: this.identifierKey,
            type: FilterTypes.EQ,
            value: id
        };
        if (options?.override) {
            this._filters = {
                ...(this._filters || {}),
                where: [idFilter]
            };
            return;
        }
        const updated = { ...(this._filters || {}) };
        const wheres = this._filters?.where || [];
        updated.where = wheres.concat(idFilter);
        this._filters = updated;
    }
    filter(input, options) {
        if (options?.override) {
            this._filters = input || undefined;
            return;
        }
        const updated = { ...(this._filters || {}) };
        if (input?.where) {
            updated.where = this._filters?.where ? this._filters.where.concat(input.where) : input.where;
        }
        if (input?.paging) {
            updated.paging = {
                ...(updated?.paging || {}),
                ...input.paging
            };
        }
        if (input?.fields) {
            updated.fields = this._filters?.fields
                ? this._filters.fields.concat(input.fields)
                : input.fields;
        }
        this._filters = updated;
    }
    sort(input, options) {
        const { override = true } = options || {};
        // Since sort only takes one field, default behavior is to override.
        if (!override) {
            return;
        }
        this._sort = input || undefined;
    }
    hydrate(hydratedFields, options) {
        if (options?.override) {
            this._hydratedFields = new Set(hydratedFields);
            return;
        }
        for (const field of hydratedFields) {
            this._hydratedFields.add(field);
        }
    }
}

import { FIREBASE_PROJECT_ID_TO_API_KEY, FIREBASE_PROJECT_ID_TO_ANALYTICS_MEASUREMENT_ID, FIREBASE_PROJECT_ID_TO_APP_ID } from "../firebase/constants";
/**
 * Simple method to return the correct Firebase connection information
 * @param projectId Project ID to configure Firebase too.
 */
export const generateFirebaseConfig = (projectId) => {
    return {
        apiKey: FIREBASE_PROJECT_ID_TO_API_KEY[projectId],
        authDomain: `${projectId}.firebaseapp.com`,
        projectId,
        storageBucket: `${projectId}.appspot.com`,
        measurementId: FIREBASE_PROJECT_ID_TO_ANALYTICS_MEASUREMENT_ID[projectId],
        appId: FIREBASE_PROJECT_ID_TO_APP_ID[projectId],
        messagingSenderId: "1062407524656"
    };
};

import { ChangeEvent } from "react";
import { PUBLIC_URL } from "lib/viteEnvConfig";
import { parseCsv, readUploadedFileAsText } from "./csv";

export const buildPublicUrl = (filePath: string) => `${PUBLIC_URL}/${filePath}`;

export const geoCsvUpload = async (
  e: ChangeEvent<HTMLInputElement>,
  updateEntity: (value: string[]) => void,
  availableSet: Set<string>
) => {
  const filesList = e.target.files;

  if (!filesList || !filesList.length) {
    throw new Error("Could not read uploaded files list.");
  }

  const latestUploadedFile = filesList.item(filesList.length - 1);

  if (!latestUploadedFile) {
    throw new Error("Could not get latest uploaded file.");
  }

  const fileContents = await readUploadedFileAsText(latestUploadedFile);

  const parsedFileContents = parseCsv(fileContents);

  const csvHeadings = parsedFileContents[0];

  if (!csvHeadings) {
    throw new Error("File contained no rows when parsed as a CSV.");
  }

  const indexOfZipCodeColumn = csvHeadings.findIndex(heading =>
    // eslint-disable-next-line no-useless-escape
    /zipcode|zip\ code/i.test(heading.toString())
  );

  if (indexOfZipCodeColumn === -1) {
    // If there's no zip code column, assume the user just uploaded a file containing a list of comma separated zips.
    const zips = fileContents
      // eslint-disable-next-line no-useless-escape
      .split(/\s|\,|\./gi) // Splits on spaces, commas, and periods.
      .filter(
        // if the zipcode exists in NNY, let it through
        str => availableSet.has(str)
      ); // Handles empty strings

    updateEntity(zips);

    return;
  }

  const zipCodesToExtract = parsedFileContents
    .slice(1)
    .reduce<string[]>((acc, row) => {
      const zipCodeCell: string = row[indexOfZipCodeColumn].toString();

      /** Adds support for cells containing multiple zip codes. */
      const zipCodeCellSplit = zipCodeCell
        // eslint-disable-next-line no-useless-escape
        .split(/\s|\,|\./gi) // Splits on spaces, commas, and periods.
        .filter(str => str.length === 5); // Handles empty strings

      return acc.concat(zipCodeCellSplit);
    }, []);

  updateEntity(zipCodesToExtract);
};

import { FC, useEffect, useRef, useState } from "react";
import { ClassNames } from "@emotion/react";
import { Popover } from "@blueprintjs/core";
import { TooltipProps } from "./types";
import { styles } from "./styles";

const Tooltip: FC<TooltipProps> = ({
  children: target,
  content,
  usePortal,
  disabled,
  portalContainer,
  triggerAction = "hover",
  timeout = triggerAction === "hover" ? 250 : 0,
  transitionMs = 0,
  boundary = "viewport",
  position = "top",
  maxWidth = "275px",
  isOpen: incomingIsOpen,
  onClose: incomingOnClose,
  onOpen: incomingOnOpen
}) => {
  const [isOpen, setIsOpen] = useState(!!incomingIsOpen);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  /**
   * clears the the current timeout on timeoutRef — if it exists.
   */
  const clearRefTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  const onOpen = () => {
    clearRefTimeout();
    if (incomingOnOpen) {
      incomingOnOpen();
    }
    if (typeof incomingIsOpen === "undefined") {
      setIsOpen(true);
    }
  };

  const onClose = () => {
    clearRefTimeout();
    if (incomingOnClose) {
      incomingOnClose();
    }
    if (typeof incomingIsOpen === "undefined") {
      setIsOpen(false);
    }
  };

  /**
   * If timeout is defined, then we watch for mouse enter to clear the timeout,
   * since we want to keep the tooltip open when the user is moused over the target or tooltip.
   * If timeout is not defined, then we do nothing.
   */
  const onMouseEnter = () => {
    if (!timeout) {
      return;
    }
    clearRefTimeout();
  };

  /**
   * If timeout is defined, then we watch for mouse leave to set a timeout to close the tooltip when it completes.
   * If timeout is not defined, then we do nothing.
   */
  const onMouseLeave = () => {
    if (!timeout) {
      return;
    }

    clearRefTimeout();

    timeoutRef.current = setTimeout(() => {
      onClose();
    }, timeout);
  };

  /**
   * If the incomingIsOpen prop changes,
   * then we set the isOpen state to the new value,
   * and clear any current timeout.
   */
  useEffect(() => {
    if (incomingIsOpen === isOpen) {
      return;
    }
    clearRefTimeout();
    setIsOpen(!!incomingIsOpen);
  }, [incomingIsOpen]);

  /** clear timeout on component cleanup */
  useEffect(() => clearRefTimeout, []);

  if (!target) {
    return null;
  }

  return (
    <ClassNames>
      {({ css, cx }) => (
        <Popover
          disabled={disabled}
          isOpen={isOpen}
          portalContainer={portalContainer}
          usePortal={usePortal}
          position={position}
          lazy
          transitionDuration={transitionMs}
          modifiers={{
            offset: { offset: "0,-4", enabled: true },
            arrow: { enabled: true }
          }}
          content={
            <div
              role="tooltip"
              style={{ maxWidth }}
              css={[styles.tooltipWrapper]}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            >
              {content}
            </div>
          }
          target={target}
          targetProps={{
            style: {
              display: "inline-flex",
              cursor: triggerAction === "click" ? "pointer" : "default"
            },
            onMouseLeave,
            onClick: triggerAction === "click" ? onOpen : undefined,
            onMouseEnter: () => {
              onMouseEnter();
              if (triggerAction === "hover") {
                onOpen();
              }
            },
            onKeyUp: e => {
              if (e.key === "Enter" || e.key === " ") {
                onOpen();
              }
            }
          }}
          boundary={boundary}
          onOpening={onOpen}
          onClose={onClose}
          popoverClassName={cx(
            "kit-Tooltip",
            css`
              ${styles.popover}
            `
          )}
        />
      )}
    </ClassNames>
  );
};

export default Tooltip;
export * from "./types";

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Document: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-document"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M5.99766 2.24625C4.47842 2.24625 3.24683 3.47784 3.24683 4.99708V19.0029C3.24683 20.5222 4.47842 21.7537 5.99766 21.7537H7.99849C8.41271 21.7537 8.74849 21.418 8.74849 21.0037C8.74849 20.5895 8.41271 20.2537 7.99849 20.2537H5.99766C5.30684 20.2537 4.74683 19.6937 4.74683 19.0029V4.99708C4.74683 4.92452 4.753 4.85341 4.76486 4.78424C4.86591 4.1948 5.3794 3.74625 5.99766 3.74625H15.1735C15.505 3.74625 15.8231 3.87815 16.0577 4.11282L18.887 6.94208C19.1217 7.17668 19.2535 7.49477 19.2535 7.82626V12C19.2535 12.4142 19.5893 12.75 20.0035 12.75C20.4177 12.75 20.7535 12.4142 20.7535 12V7.82626C20.7535 7.09656 20.4633 6.39693 19.9475 5.88126L17.1184 3.05216C16.6027 2.53637 15.9032 2.24625 15.1735 2.24625H5.99766Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.9998 16.5022C10.5855 16.5022 10.2498 16.838 10.2498 17.2522V21.0038C10.2498 21.418 10.5855 21.7538 10.9998 21.7538H13.9993L14.001 21.7538H17.0022L17.0081 21.7537H20.0034C20.4177 21.7537 20.7534 21.418 20.7534 21.0037V15.2513C20.7534 14.8371 20.4177 14.5013 20.0034 14.5013H17.7522V13.0004C17.7522 12.5862 17.4164 12.2504 17.0022 12.2504H14.001C13.5868 12.2504 13.251 12.5862 13.251 13.0004V16.5022H10.9998ZM17.7522 20.2537V16.0013H19.2534V20.2537H17.7522ZM16.2522 15.2446V13.7504H14.751V17.2455L14.751 17.2522V20.2538H16.2522V15.2513L16.2522 15.2446ZM13.251 20.2538V18.0022H11.7498V20.2538H13.251Z"
      />
      <path d="M7.24854 7.99835C7.24854 7.58414 7.58432 7.24835 7.99854 7.24835H15.0015C15.4157 7.24835 15.7515 7.58414 15.7515 7.99835C15.7515 8.41257 15.4157 8.74835 15.0015 8.74835H7.99854C7.58432 8.74835 7.24854 8.41257 7.24854 7.99835Z" />
      <path d="M7.99854 10.7498C7.58432 10.7498 7.24854 11.0856 7.24854 11.4998C7.24854 11.914 7.58432 12.2498 7.99854 12.2498H10.4996C10.9138 12.2498 11.2496 11.914 11.2496 11.4998C11.2496 11.0856 10.9138 10.7498 10.4996 10.7498H7.99854Z" />
    </svg>
  );
};

export default Document;

import styled from "@emotion/styled";
import { css } from "@emotion/css";

export const SliderContainer = styled.div<{
  isDragging: boolean;
  disabled?: boolean;
}>`
  width: 100%;
  height: 40px;
  position: relative;
  user-select: none;
  cursor: ${props =>
    props.disabled ? "not-allowed" : props.isDragging ? "grabbing" : "grab"};
  opacity: ${props => (props.disabled ? ".5" : "1")};
`;

export const sliderTrack = css`
  height: 4px;
  border-radius: 2px;
  background-color: var(--gray-3);
`;

export const FilledTrack = styled.div<{ percent: number }>`
  width: ${props => props.percent}%;
  background-color: var(--primary-color);
  height: 100%;
  border-radius: 2px;
`;

export const sliderAxis = css`
  position: relative;
  justify-content: space-between;
`;

export const SliderHandle = styled.div<{ visuallyHidden: boolean }>`
  height: 12px;
  width: 12px;
  border-radius: 2px;
  background: ${props => (props.visuallyHidden ? "none" : "#f1f3f7")};
  border: ${props => (props.visuallyHidden ? "none" : "1px solid #b3bbcc")};
  margin-bottom: var(--spacing-8);
`;

export const SliderSlice = styled.div<{ percent: number; width: number }>`
  position: absolute;
  width: ${props => props.width}%;
  left: calc(${props => props.percent}% - ${props => props.width / 2}%);
  top: -8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;

  p {
    margin-bottom: unset;
  }
`;

export const AxisLabelContainer = styled.div<{ isSelected: boolean }>`
  background: ${props => (props.isSelected ? " var(--black)" : "none")};
  padding: 2px 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
`;

import { serviceUserToUser, userToPostgresUser } from "../user";
import { InvalidOrganizationType } from "./errors";
/** String constants used by BE services */
export const orgServiceStringConstants = {
    UNKNOWN: "UNKNOWN",
    HIVE: "HIVE",
    // In some cases on the BE, ADV is used as a shorthand for ADVERTISER (see _orgtype db function),
    // so we need to support both.
    ADV: "ADV",
    ADVERTISER: "ADVERTISER",
    AGENCY: "AGENCY",
    STATION_GROUP: "STATION_GROUP",
    STATION: "STATION",
    SSP: "SSP",
    PUBLISHER: "PUBLISHER",
    MANU: "MANU",
    DMP: "DMP",
    DSP: "DSP",
    TEAM: "TEAM"
};
/** all valid org types */
export var OrganizationType;
(function (OrganizationType) {
    /** @deprecated: UNKNOWN should never be used */
    OrganizationType[OrganizationType["UNKNOWN"] = 0] = "UNKNOWN";
    /** only MadHive has this type */
    OrganizationType[OrganizationType["HIVE"] = 1] = "HIVE";
    OrganizationType[OrganizationType["ADV"] = 2] = "ADV";
    OrganizationType[OrganizationType["ADVERTISER"] = 2] = "ADVERTISER";
    OrganizationType[OrganizationType["AGENCY"] = 3] = "AGENCY";
    OrganizationType[OrganizationType["STATION_GROUP"] = 4] = "STATION_GROUP";
    OrganizationType[OrganizationType["STATION"] = 5] = "STATION";
    OrganizationType[OrganizationType["SSP"] = 6] = "SSP";
    OrganizationType[OrganizationType["PUBLISHER"] = 7] = "PUBLISHER";
    OrganizationType[OrganizationType["MANU"] = 8] = "MANU";
    OrganizationType[OrganizationType["DMP"] = 9] = "DMP";
    OrganizationType[OrganizationType["DSP"] = 10] = "DSP";
    /** team is a carve out of its parent organization */
    OrganizationType[OrganizationType["TEAM"] = 11] = "TEAM";
})(OrganizationType || (OrganizationType = {}));
/**
 * @param type: the client org type.
 * @return: the service equivalent version.
 */
const OrganizationTypeToServiceOrganizationType = (type) => {
    switch (type) {
        case OrganizationType.ADVERTISER:
            return orgServiceStringConstants.ADVERTISER;
        case OrganizationType.AGENCY:
            return orgServiceStringConstants.AGENCY;
        case OrganizationType.DMP:
            return orgServiceStringConstants.DMP;
        case OrganizationType.DSP:
            return orgServiceStringConstants.DSP;
        case OrganizationType.HIVE:
            return orgServiceStringConstants.HIVE;
        case OrganizationType.MANU:
            return orgServiceStringConstants.MANU;
        case OrganizationType.PUBLISHER:
            return orgServiceStringConstants.PUBLISHER;
        case OrganizationType.SSP:
            return orgServiceStringConstants.SSP;
        case OrganizationType.STATION:
            return orgServiceStringConstants.STATION;
        case OrganizationType.STATION_GROUP:
            return orgServiceStringConstants.STATION_GROUP;
        case OrganizationType.TEAM:
            return orgServiceStringConstants.TEAM;
        default:
            return orgServiceStringConstants.UNKNOWN;
    }
};
/**
 * @param type: the service org type.
 * @return: the client equivalent version.
 */
const serviceOrganizationTypeToOrganizationType = (type) => {
    switch (type) {
        case "ADVERTISER":
            return OrganizationType.ADVERTISER;
        case "AGENCY":
            return OrganizationType.AGENCY;
        case "DMP":
            return OrganizationType.DMP;
        case "DSP":
            return OrganizationType.DSP;
        case "HIVE":
            return OrganizationType.HIVE;
        case "MANU":
            return OrganizationType.MANU;
        case "PUBLISHER":
            return OrganizationType.PUBLISHER;
        case "SSP":
            return OrganizationType.SSP;
        case "STATION":
            return OrganizationType.STATION;
        case "STATION_GROUP":
            return OrganizationType.STATION_GROUP;
        case "TEAM":
            return OrganizationType.TEAM;
        case "UNKNOWN":
            return OrganizationType.UNKNOWN;
        default:
            throw new InvalidOrganizationType(type);
    }
};
/**
 * @param org: any valid service org rep (either firestore or postgres).
 * @return: a valid representation of the organization for a client.
 */
export const serviceOrganizationToOrganization = (org) => ({
    id: org.id,
    name: org.name,
    type: serviceOrganizationTypeToOrganizationType(org.org_type),
    defaultPublisherGroup: org.default_publisher_group,
    defaultPublisherList: org.default_whitelist,
    parent: org.parent,
    publisherGroups: org.publisher_groups,
    publisherLists: org.whitelists,
    status: org.status,
    defaultUser: org.user ? serviceUserToUser(org.user) : undefined,
    views: org.views,
    settings: org.settings
});
/**
 * @param org: a client representation of an organization.
 * @return: a valid representation of the organization for MadHive services.
 */
export const organizationToServiceOrganizationBase = (org) => ({
    id: org.id,
    name: org.name,
    org_type: OrganizationTypeToServiceOrganizationType(org.type),
    default_publisher_group: org.defaultPublisherGroup,
    default_whitelist: org.defaultPublisherList,
    parent: org.parent,
    publisher_groups: org.publisherGroups,
    whitelists: org.publisherLists,
    status: org.status,
    user: org.defaultUser ? userToPostgresUser(org.defaultUser) : undefined,
    settings: org.settings
});

import { DateTime } from "luxon";

/**
 * Formats given date to "L/d/yy" or "LL/dd/yy, ttt" (based on time boolean passed in)
 * @param date Date | DateTime | string
 * @param time Should time be included in formatted string
 * @returns time === true ? "LL/dd/yy, ttt"
 * @returns time === false ? "L/d/yy"
 * @returns date === undefined "--"
 * @returns date === isInvalid "Invalid Date"
 */
export const formatDateFromAny = (
  date: Date | DateTime | string | undefined,
  time?: boolean
): string => {
  if (!date) {
    return "";
  }
  const dateTime =
    date instanceof DateTime ? date : DateTime.fromJSDate(new Date(date));
  if (dateTime.isValid) {
    return dateTime.toFormat(time ? "LL/dd/yy, h:mm:ss a ZZZZ" : "L/d/yy");
  }
  return "Invalid Date";
};

/**
 * helper function to convert Date or string (that represents a Date) to DateTime
 * @returns DateTime | undefined
 */
export const dateTimeFromAny = (value: Date | string): DateTime | undefined => {
  if (value instanceof Date) {
    return DateTime.fromJSDate(value);
  }

  if (!value || typeof value !== "string") {
    return undefined;
  }

  let dateString = value.trim().replace(/-/g, "/").toUpperCase();

  const dateStringParts = dateString.split(" ");
  /** correcting to AM/PM if ends in A or P */
  if (["A", "P"].includes(dateStringParts[2])) {
    dateStringParts[2] += "M";
    dateString = dateStringParts.join(" ");
  }

  return DateTime.fromJSDate(new Date(dateString));
};

/**
 * @param date1: DateTime to compare
 * @param date2: DateTime to compare
 * @returns true: when both dates are defined, and are the same day
 * @returns false: when either date is undefined, or dates are not the same day
 */
export const isSameDay = (
  date1: DateTime | undefined,
  date2: DateTime | undefined
) => {
  if (!date1 || !date2) {
    return false;
  }
  return (
    date1.hasSame(date2, "day") &&
    date1.hasSame(date2, "month") &&
    date1.hasSame(date2, "year")
  );
};

export const DAY_IN_MS = 1000 * 60 * 60 * 24;

// Proportional days between two date ranges -> 11/1 00:00 - 11/1 03:00 gives 0.125 day
export const getNumberOfProportionalDaysBetween = (d1: Date, d2: Date) => {
  const date1 = new Date(d1.getTime());
  const date2 = new Date(d2.getTime());

  return (date2.getTime() - date1.getTime()) / DAY_IN_MS;
};

export const addDaysToDate = (date: Date, numberOfDaysToAdd: number): Date => {
  const dateCopy = new Date(date);

  dateCopy.setDate(dateCopy.getDate() + numberOfDaysToAdd);

  return dateCopy;
};

import { Icon, IconSize } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useNotificationsData } from "components/NotificationsProvider";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "appReducers/authReducer";
import { markAllFirestoreNotificationsForUserAsRead } from "lib/utils/notifications";
import { css } from "@emotion/react";
import { Button, ButtonVariant } from "madhive/components";
import { Drawer } from "frontier/lib/components";
import { FC, useState } from "react";
import Badge from "../../frontier/lib/components/Badge";
import NotificationContainer from "./container";

interface Props {
  colorOverride: string | undefined;
}

const style = {
  drawer: css`
    padding: 20px;
  `,
  header: css`
    font-weight: 500;
    font-size: 28px;
    margin-top: 10px;
    margin-bottom: 25px;
  `
};

const Inbox: FC<Props> = props => {
  const user = useSelector(selectCurrentUser);
  const { colorOverride } = props;
  const { firestoreNotifications } = useNotificationsData();
  const [isOpen, setisOpenState] = useState<boolean>(false);

  const openNotificationsDrawer = () => {
    setisOpenState(true);
  };

  if (!user) {
    return null;
  }

  const closeNotificationsDrawer = () => {
    setisOpenState(false);
    if (firestoreNotifications.some(note => !note.read)) {
      markAllFirestoreNotificationsForUserAsRead(user);
    }
  };

  const unreadNotifications = firestoreNotifications.filter(el => !el.read);

  return (
    <>
      <Button
        aria-haspopup="true"
        aria-label="notifications"
        data-testid="notifications-button"
        variant={ButtonVariant.ICON}
        onClick={openNotificationsDrawer}
        disabled={!user}
        icon={
          <Badge
            badgeContent={unreadNotifications.length}
            color="danger"
            invisible={!user || unreadNotifications.length < 1}
          >
            <Icon
              color={colorOverride}
              icon={IconNames.NOTIFICATIONS}
              iconSize={IconSize.LARGE}
            />
          </Badge>
        }
      />

      {user && (
        <Drawer
          isOpen={isOpen}
          onClose={() => closeNotificationsDrawer()}
          position="right"
          size="650px"
          css={style.drawer}
        >
          <div data-testid="notifications-drawer">
            <div css={style.header}> Notifications</div>
            {firestoreNotifications.length === 0 ? (
              <p>No new notifications</p>
            ) : (
              firestoreNotifications.map(notification => (
                <NotificationContainer
                  notification={notification}
                  key={notification.id}
                />
              ))
            )}
          </div>
        </Drawer>
      )}
    </>
  );
};

export default Inbox;

/**
 * A PromiseBuilder provides a way to delay promise execution until .then, .catch, or .finally is called,
 * allowing a series of intermediate methods to be chained together, in a fluent interface.
 * @template {T}: the type of data returned from the promise.
 */
export class PromiseBuilder {
    /** @param executor: the function to execute for the promise's main logic. */
    constructor(executor) {
        this.executor = executor;
    }
    /**
     * The promise associated with this builder.
     * If it is not yet instantiated, instantiate it.
     */
    get promise() {
        if (!this.pending) {
            this.pending = new Promise(this.executor);
        }
        return this.pending;
    }
    then(onfulfilled, onrejected) {
        if (onfulfilled && onrejected) {
            return this.promise.then((value) => onfulfilled(value)).catch((error) => onrejected(error));
        }
        else if (onfulfilled) {
            return this.promise.then((value) => onfulfilled(value));
        }
        else if (onrejected) {
            // because we know we didn't get onfulfilled, TResult1 defaults to T, and this.promise returns T, this must be Promise<TResult1 | TResult2>
            return this.promise.catch((error) => onrejected(error));
        }
        // because we know we didn't get onfulfilled and onrejected,
        // TResult1 defaults to T, and this.promise returns T, this must be Promise<TResult1>
        return this.promise;
    }
    catch(onrejected) {
        return this.promise.catch(onrejected);
    }
    finally(onfinally) {
        return this.promise.finally(onfinally);
    }
    get [Symbol.toStringTag]() {
        return "PromiseBuilder";
    }
}

import { SVG } from "./containers";
import { Circle } from "./shapes";
import { Icon } from "./types";
import { parseColor } from "./utils";

export const Check = ({ circled, color, id, aria }: Icon) => {
  const colors = parseColor(color);

  return (
    <SVG viewBox="0 0 20 20" id={id} aria={aria}>
      <Circle color={colors.circle} visible={circled}>
        <path
          fill={colors.content}
          fillRule="nonzero"
          d="M8.167 13.5L6 11.333l.722-.722 1.445 1.445L13.222 7l.722.723z"
        />
      </Circle>
    </SVG>
  );
};

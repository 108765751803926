import { css } from "@emotion/react";
import { InputProps, InputType, InputValueType } from "./types";
import {
  isInputDateProps,
  isInputTextProps,
  isInputNumberProps,
  isInputDateSingleProps
} from "./types/guards";
import { InputText } from "./Text";
import { InputDate } from "./Date";
import FormField from "../FormField";
import ReadonlyFormField from "../FormField/Readonly";
import { styles } from "./styles";
import { InputNumber } from "./Number";

/**
 * Input text, number, and date
 */

const Input = <T extends InputType, Value extends InputValueType>({
  label,
  labelSuffix,
  labelSuffixAlign,
  hideLabel,
  error,
  offsetError,
  required,
  description,
  readonly,
  fill,
  minWidth = "none",
  placeholder,
  disabled,
  focusOnMount,
  readonlyParser,
  ...uniqueProps
}: InputProps<T, Value>) => {
  let formFieldError: string | undefined;

  if (error) {
    if (Array.isArray(error)) {
      formFieldError = [...new Set(error.filter(err => !!err))].join(", ");
    }
    if (typeof error === "string") {
      formFieldError = error;
    }
  }

  if (readonly) {
    let isTime = false;
    if (isInputDateProps(uniqueProps)) {
      isTime = uniqueProps.time || false;
    }
    return (
      <ReadonlyFormField
        parser={readonlyParser}
        fill={fill}
        label={label}
        hideLabel={hideLabel}
        value={uniqueProps.value}
        minWidth={minWidth}
        time={isTime}
        labelSuffix={labelSuffix}
        labelSuffixAlign={labelSuffixAlign}
      />
    );
  }

  return (
    <div
      className="kit-Input"
      css={css`
        ${fill ? styles.fill : "width: auto"};
        flex-grow: ${fill ? 1 : 0};
        margin: 0;
        min-width: ${minWidth};
      `}
    >
      <FormField
        fill={fill}
        label={label}
        labelSuffix={labelSuffix}
        labelSuffixAlign={labelSuffixAlign}
        hideLabel={hideLabel}
        error={formFieldError}
        offsetError={offsetError}
        required={required}
        description={description}
        fieldset={uniqueProps.type === "date"}
      >
        {isInputDateProps(uniqueProps) && (
          <InputDate
            label={label}
            error={error}
            required={required}
            description={description}
            readonly={readonly}
            fill={fill}
            minWidth={minWidth}
            placeholder={placeholder}
            disabled={disabled}
            focusOnMount={focusOnMount}
            type={uniqueProps.type}
            value={uniqueProps.value}
            onChange={uniqueProps.onChange}
            name={uniqueProps.name}
            id={uniqueProps.id}
            onFocus={uniqueProps.onFocus}
            onKeyUp={uniqueProps.onKeyUp}
            onBlur={uniqueProps.onBlur}
            onClick={uniqueProps.onClick}
            onInput={uniqueProps.onInput}
            onKeyDown={uniqueProps.onKeyDown}
            pickerPlacement={uniqueProps.pickerPlacement}
            presets={uniqueProps?.presets}
            noPicker={uniqueProps.noPicker}
            time={uniqueProps.time}
            min={uniqueProps.min}
            max={uniqueProps.max}
            flyoutProps={uniqueProps.flyoutProps}
            setEndToEndOfDay={uniqueProps.setEndToEndOfDay}
            isEndDate={
              isInputDateSingleProps(uniqueProps)
                ? uniqueProps.isEndDate
                : undefined
            }
            setError={uniqueProps.setError}
          />
        )}
        {isInputTextProps(uniqueProps) && (
          <InputText
            error={error}
            fill={fill}
            placeholder={placeholder}
            disabled={disabled}
            focusOnMount={focusOnMount}
            type={uniqueProps.type}
            value={uniqueProps.value}
            onChange={uniqueProps.onChange}
            name={uniqueProps.name}
            id={uniqueProps.id}
            prefix={uniqueProps.prefix}
            suffix={uniqueProps.suffix}
            onFocus={uniqueProps.onFocus}
            onKeyUp={uniqueProps.onKeyUp}
            onBlur={uniqueProps.onBlur}
            onClick={uniqueProps.onClick}
            onInput={uniqueProps.onInput}
            onKeyDown={uniqueProps.onKeyDown}
          />
        )}
        {isInputNumberProps(uniqueProps) && (
          <InputNumber
            error={error}
            fill={fill}
            placeholder={placeholder}
            disabled={disabled}
            focusOnMount={focusOnMount}
            type={uniqueProps.type}
            value={uniqueProps.value}
            onChange={uniqueProps.onChange}
            name={uniqueProps.name}
            id={uniqueProps.id}
            min={uniqueProps.min}
            max={uniqueProps.max}
            step={uniqueProps.step}
            precision={uniqueProps.precision}
            prefix={uniqueProps.prefix}
            suffix={uniqueProps.suffix}
            onFocus={uniqueProps.onFocus}
            onKeyUp={uniqueProps.onKeyUp}
            onBlur={uniqueProps.onBlur}
            onClick={uniqueProps.onClick}
            onInput={uniqueProps.onInput}
            onKeyDown={uniqueProps.onKeyDown}
          />
        )}
      </FormField>
    </div>
  );
};

export default Input;
export * from "./types";

import { PropsWithChildren } from "react";
import { DEFAULT_COLOR } from "../constants";

interface Props {
  color?: {
    /** Color of the circle's outline. */
    outline?: string;
    /** Color of the circle's interior. */
    fill?: string;
  };
  /** Thickness of the circle's outline. */
  thickness?: string;
  /** Whether or not the circle is visible. */
  visible?: boolean;
}

/** A Circle is a reusable shape that can have other shapes nested inside it. */
export const Circle = ({
  visible,
  color,
  children,
  thickness
}: PropsWithChildren<Props>) => (
  <>
    {visible && (
      <circle
        cx="50%"
        cy="50%"
        r="45%"
        stroke={color?.outline || DEFAULT_COLOR}
        fill={color?.fill}
        strokeWidth={thickness || "3%"}
      />
    )}
    {children}
  </>
);

import { ServeType } from "lib/constants/creatives";
import { determineHtmlTag } from "lib/utils/creatives";
import {
  GET_UPLOAD_ASSET_PENDING,
  GET_UPLOAD_ASSET_SUCCESS,
  GET_UPLOAD_ASSET_FAILURE,
  UploadAssetState,
  UploadAssetActionTypes,
  GET_RESET_ASSET_UPLOAD_STATE,
  GET_BYTES_TRANSFERRED_UPDATE,
  GET_INITIAL_FILE_INFO_UPDATE,
  SET_VALIDATED_THIRD_PARTY_TAG,
  SET_SERVE_TYPE,
  SET_RESET_UPLOADED_ASSET
} from "./types";

export const initialState: UploadAssetState = {
  isUploading: false,
  isSaving: false,
  /* boolean indicating if ever loaded -- useful for some loading screen logic */
  fatalUploadError: null,
  initialFileInfo: undefined,
  bytesTransferred: 0,
  uploadedAsset: undefined,
  validatedThirdPartyTag: undefined,
  serveType: undefined,
  isVast: false,
  isTag: false
};

export const assetUploadReducer = (
  state = initialState,
  action: UploadAssetActionTypes
): UploadAssetState => {
  switch (action.type) {
    case GET_UPLOAD_ASSET_PENDING:
      return {
        ...state,
        isUploading: true,
        fatalUploadError: null
      };
    case GET_UPLOAD_ASSET_SUCCESS: {
      const isTag = determineHtmlTag(
        action.payload?.validationDetails?.assetMetadata
      );
      return {
        ...state,
        isUploading: false,
        fatalUploadError: null,
        uploadedAsset: action.payload,
        bytesTransferred: 1,
        isTag,
        isVast: state.serveType === ServeType.THIRD_PARTY && !isTag
      };
    }
    case GET_UPLOAD_ASSET_FAILURE:
      return {
        ...state,
        isUploading: false,
        fatalUploadError: action.meta.error,
        initialFileInfo: undefined,
        bytesTransferred: 0,
        uploadedAsset: undefined
      };
    case GET_BYTES_TRANSFERRED_UPDATE:
      return {
        ...state,
        bytesTransferred: action.payload,
        isUploading: true
      };
    case GET_INITIAL_FILE_INFO_UPDATE:
      return {
        ...state,
        initialFileInfo: action.payload
      };
    case GET_RESET_ASSET_UPLOAD_STATE:
      return {
        ...state,
        ...initialState
      };
    case SET_VALIDATED_THIRD_PARTY_TAG:
      return {
        ...state,
        validatedThirdPartyTag: action.payload
      };
    case SET_SERVE_TYPE:
      return {
        ...state,
        serveType: action.payload
      };
    case SET_RESET_UPLOADED_ASSET:
      return {
        ...state,
        uploadedAsset: undefined
      };
    default:
      return state;
  }
};

export class TeamsFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, TeamsFetchFailed);
        }
        this.name = "TeamsFetchFailed";
        this.message = "Failed to retrieve teams. Please try again at a later time.";
    }
}

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Duplicate: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-duplicate"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M14.0008 7.25C14.415 7.25 14.7508 7.58579 14.7508 8V9.24918H16C16.4142 9.24918 16.75 9.58496 16.75 9.99918C16.75 10.4134 16.4142 10.7492 16 10.7492H14.7508V12C14.7508 12.4142 14.415 12.75 14.0008 12.75C13.5866 12.75 13.2508 12.4142 13.2508 12V10.7492H12C11.5858 10.7492 11.25 10.4134 11.25 9.99918C11.25 9.58496 11.5858 9.24918 12 9.24918H13.2508V8C13.2508 7.58579 13.5866 7.25 14.0008 7.25Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.25 6C6.25 3.92893 7.92893 2.25 10 2.25H18C20.0711 2.25 21.75 3.92893 21.75 6V14C21.75 16.0711 20.0711 17.75 18 17.75H17.75V18C17.75 20.0711 16.0711 21.75 14 21.75H6C3.92893 21.75 2.25 20.0711 2.25 18V10C2.25 7.92893 3.92893 6.25 6 6.25H6.25V6ZM16.25 18V17.75H10C7.92893 17.75 6.25 16.0711 6.25 14V7.75H6C4.75736 7.75 3.75 8.75736 3.75 10V18C3.75 19.2426 4.75736 20.25 6 20.25H14C15.2426 20.25 16.25 19.2426 16.25 18ZM7.75 14V6C7.75 4.75736 8.75736 3.75 10 3.75H18C19.2426 3.75 20.25 4.75736 20.25 6V14C20.25 15.2426 19.2426 16.25 18 16.25H10C8.75736 16.25 7.75 15.2426 7.75 14Z"
      />
    </svg>
  );
};

export default Duplicate;

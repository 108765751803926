import { FreewheelProduct } from "@madhive/mad-sdk";
import { createSelector } from "reselect";
import { RootState } from "rootReducer";

export const selectIsFreewheelProductsLoading = (state: RootState) =>
  state.freewheelProducts.isAllLoading;

export const selectIsFreewheelProductsLoaded = (state: RootState) =>
  !!state.freewheelProducts.receivedAllAt;

export const selectIsFreewheelProductsError = (state: RootState) =>
  !!state.freewheelProducts.error;

export const selectFreewheelProductsById = (state: RootState) =>
  state.freewheelProducts.byId;

export const selectFreewheelProductsAsList = createSelector(
  selectFreewheelProductsById,
  (results: Record<string, FreewheelProduct>) => Object.values(results)
);

export const selectLegacyAndFreewheelProductsById = (state: RootState) => ({
  current: state.freewheelProducts.byId,
  legacy: state.freewheelProducts.legacyById
});

export const selectCurrentAndLegacyFreewheelProductsAsList = createSelector(
  selectLegacyAndFreewheelProductsById,
  (results: ReturnType<typeof selectLegacyAndFreewheelProductsById>) =>
    Object.values(results.current).concat(Object.values(results.legacy))
);

export const selectCurrentAndLegacyFreewheelProductsAsSets = createSelector(
  selectLegacyAndFreewheelProductsById,
  (results: ReturnType<typeof selectLegacyAndFreewheelProductsById>) => ({
    current: new Set(Object.keys(results.current)),
    legacy: new Set(Object.keys(results.legacy))
  })
);

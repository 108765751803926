import { Account, KnownOrganizationNames } from "@madhive/mad-sdk";
import { setLoginSuccess } from "appReducers/authReducer";
import { setGetOrganizationSuccess } from "appReducers/organizationsReducer/actions";
import classNames from "classnames";
import NotificationsDataProvider from "components/NotificationsProvider";
import PersistentBottomBanners from "components/PersistentBottomBanners";
import usePostLoginRedirect from "hooks/useAuth/usePostLoginRedirect";
import useUserConnectivity from "hooks/useAuth/useUserConnectivity";
import { useFavicon } from "hooks/useFavicon";
import { HOSTNAME_TO_ORG_NAME } from "lib/constants/config";
import { Routes } from "lib/constants/routes";
import { madSDK } from "lib/sdk";
import { ensureUserCanLoginFromThisAddress, isLocalhost } from "lib/utils/env";
import { mapsApiAuthenticator } from "lib/utils/gmapsApi";
import { startSpan } from "lib/utils/sentry";
import { initializeStyles } from "lib/utils/styles";
import { Spinner } from "madhive/components";
import { FC, useState, useEffect, PropsWithChildren } from "react";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

// eslint-disable-next-line import/prefer-default-export
export const Auth: FC<PropsWithChildren> = props => {
  const dispatch = useDispatch();
  const [isCheckingSession, setIsCheckingSession] = useState(true);
  const navigate = useNavigate();

  // applies custom organization theme based on host name
  useEffect(() => {
    const { hostname } = window.location;
    const orgName = HOSTNAME_TO_ORG_NAME[hostname];
    if (orgName) {
      document.body.classList.add(orgName);
    }
  }, []);

  useEffect(() => {
    (async () => {
      const checkSessionSpan = startSpan("Check Session");
      try {
        // check to see if we're logged in
        await madSDK.authentication.trySession(
          ensureUserCanLoginFromThisAddress
        );
      } catch (e) {
        // User does not have a valid session
        setIsCheckingSession(false);

        if (
          window.location.pathname !== Routes.LOGIN &&
          window.location.pathname !== Routes.LOGIN_LOCAL
        ) {
          if (isLocalhost) {
            navigate(Routes.LOGIN_LOCAL, { replace: true });
          } else {
            navigate(Routes.LOGIN, { replace: true });
          }
        }
      }
      checkSessionSpan.ok();

      madSDK.authentication.currentAccount.subscribe(
        async (currentAccount: Account) => {
          if (!currentAccount) {
            mapsApiAuthenticator.cleanup();
            return;
          }
          await mapsApiAuthenticator.auth();
          // this function is run when the user has an active session
          // and it is in charge of loading all other necessary data
          // before marking the user as logged in
          const initSpan = startSpan("Initialize User");
          await madSDK.featureFlags.load();
          await dispatch(
            setGetOrganizationSuccess(currentAccount.organization)
          );
          await dispatch(setLoginSuccess(currentAccount.user));
          initSpan.ok();

          // below are a few more initialization actions

          document.body.className = "";
          document.body.classList.add(
            currentAccount.organization.name.toLowerCase().replace(/ /g, "-")
          );
          document.body.classList.add("default-theme");

          // reload stylesheet to get user specific information
          await initializeStyles();

          setIsCheckingSession(false);
        }
      );
    })();
  }, []);

  // User is logged in at this point

  usePostLoginRedirect();
  useUserConnectivity();

  const account = madSDK.authentication.getCurrentAccount();
  useFavicon(
    account
      ? (account.organization.name as KnownOrganizationNames)
      : KnownOrganizationNames.MADHIVE_DEFAULT
  );

  return (
    <>
      {isCheckingSession ? (
        <div
          className={classNames(
            "Smithers__FillContainer",
            "Smithers__VerticalFlex",
            "Smithers__Centered"
          )}
        >
          <Spinner />
        </div>
      ) : (
        <NotificationsDataProvider>
          {props.children}
          <PersistentBottomBanners />
        </NotificationsDataProvider>
      )}
    </>
  );
};

import { ReactElement, FC } from "react";
import { css } from "@emotion/react";
import { Spinner, SpinnerColor, SpinnerSize } from "madhive/components";
import { Check, Cross, Warning } from "../Icons";

export type JobStatus = "error" | "loading" | "success" | "warning";

interface Props {
  status: JobStatus;
}

const STATUS_TO_ICON: Record<JobStatus, ReactElement> = {
  error: (
    <Cross
      id="error-icon"
      circled
      color={{
        content: "var(--danger-color)",
        circle: { outline: "var(--danger-color)" }
      }}
    />
  ),
  loading: <Spinner size={SpinnerSize.SMALL} color={SpinnerColor.SECONDARY} />,
  success: (
    <Check
      id="success-icon"
      color={{
        content: "var(--save-color)",
        circle: { outline: "var(--save-color)" }
      }}
      circled
    />
  ),
  warning: (
    <Warning
      id="warning-icon"
      color={{
        content: "var(--warning-color)"
      }}
    />
  )
};

const styles = {
  iconWrapper: css`
    height: 18px;
    width: 18px;
  `
};

const JobStatusIcon: FC<Props> = props => {
  const { status } = props;

  return <div css={styles.iconWrapper}>{STATUS_TO_ICON[status]}</div>;
};

export default JobStatusIcon;

export var FilterErrorName;
(function (FilterErrorName) {
    FilterErrorName["FiltersRequiredError"] = "FiltersRequiredError";
    FilterErrorName["FilterInvalidError"] = "FilterInvalidError";
})(FilterErrorName || (FilterErrorName = {}));
export class FiltersRequiredError extends Error {
    constructor(requiredField) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FiltersRequiredError);
        }
        this.name = FilterErrorName.FiltersRequiredError;
        this.message = !requiredField
            ? "This find requires filters to work correctly."
            : `This find requires filtering on ${requiredField} to work correctly.`;
    }
}
export class FilterInvalidError extends Error {
    constructor(invalidFilter) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FiltersRequiredError);
        }
        this.invalidFilter = invalidFilter;
        this.name = FilterErrorName.FilterInvalidError;
        this.message = `This find does not accept filtering based on ${invalidFilter}.`;
    }
}

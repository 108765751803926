import { FC } from "react";
import { CommonIconProps, LeftRightDirection } from "./types";

type Props = CommonIconProps & {
  direction: LeftRightDirection;
};

const DrawerKnob: FC<Props> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)",
  direction = "right"
}) => {
  return (
    <svg
      className="icon-drawer-knob"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      {direction === "left" && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 2H19C19.55 2 20 1.55 20 1C20 0.45 19.55 0 19 0H8C7.45 0 7 0.45 7 1C7 1.55 7.45 2 8 2ZM4 2C3.72 2 3.47 2.11 3.29 2.29L0.29 5.29C0.11 5.47 0 5.72 0 6C0 6.28 0.11 6.53 0.29 6.71L3.29 9.71C3.47 9.89 3.72 10 4 10C4.55 10 5 9.55 5 9V3C5 2.45 4.55 2 4 2ZM19 10H8C7.45 10 7 10.45 7 11C7 11.55 7.45 12 8 12H19C19.55 12 20 11.55 20 11C20 10.45 19.55 10 19 10ZM19 5H8C7.45 5 7 5.45 7 6C7 6.55 7.45 7 8 7H19C19.55 7 20 6.55 20 6C20 5.45 19.55 5 19 5Z"
        />
      )}
      {direction === "right" && (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12 5H1C0.45 5 0 5.45 0 6C0 6.55 0.45 7 1 7H12C12.55 7 13 6.55 13 6C13 5.45 12.55 5 12 5ZM12 10H1C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12H12C12.55 12 13 11.55 13 11C13 10.45 12.55 10 12 10ZM12 0H1C0.45 0 0 0.45 0 1C0 1.55 0.45 2 1 2H12C12.55 2 13 1.55 13 1C13 0.45 12.55 0 12 0ZM19.71 5.29L16.71 2.29C16.53 2.11 16.28 2 16 2C15.45 2 15 2.45 15 3V9C15 9.55 15.45 10 16 10C16.28 10 16.53 9.89 16.71 9.71L19.71 6.71C19.89 6.53 20 6.28 20 6C20 5.72 19.89 5.47 19.71 5.29Z"
        />
      )}
    </svg>
  );
};

export default DrawerKnob;

import { DayOfTheWeek, Daypart, ClientDayparts } from "./types";
import { makeRange } from "./utils";

export const HOURS = [
  "12am",
  "1am",
  "2am",
  "3am",
  "4am",
  "5am",
  "6am",
  "7am",
  "8am",
  "9am",
  "10am",
  "11am",
  "12pm",
  "1pm",
  "2pm",
  "3pm",
  "4pm",
  "5pm",
  "6pm",
  "7pm",
  "8pm",
  "9pm",
  "10pm",
  "11pm"
] as const;

export const DAYS = [
  DayOfTheWeek.SUNDAY,
  DayOfTheWeek.MONDAY,
  DayOfTheWeek.TUESDAY,
  DayOfTheWeek.WEDNESDAY,
  DayOfTheWeek.THURSDAY,
  DayOfTheWeek.FRIDAY,
  DayOfTheWeek.SATURDAY
] as const;

export const EMPTY_DAYPARTS: Daypart[] = [
  {
    day: DayOfTheWeek.SUNDAY,
    hours: []
  },
  {
    day: DayOfTheWeek.MONDAY,
    hours: []
  },
  {
    day: DayOfTheWeek.TUESDAY,
    hours: []
  },
  {
    day: DayOfTheWeek.WEDNESDAY,
    hours: []
  },
  {
    day: DayOfTheWeek.THURSDAY,
    hours: []
  },
  {
    day: DayOfTheWeek.FRIDAY,
    hours: []
  },
  {
    day: DayOfTheWeek.SATURDAY,
    hours: []
  }
];

export const allHours = makeRange(24);

export const FILLED_DAYPARTS: Daypart[] = [
  {
    day: DayOfTheWeek.SUNDAY,
    hours: allHours
  },
  {
    day: DayOfTheWeek.MONDAY,
    hours: allHours
  },
  {
    day: DayOfTheWeek.TUESDAY,
    hours: allHours
  },
  {
    day: DayOfTheWeek.WEDNESDAY,
    hours: allHours
  },
  {
    day: DayOfTheWeek.THURSDAY,
    hours: allHours
  },
  {
    day: DayOfTheWeek.FRIDAY,
    hours: allHours
  },
  {
    day: DayOfTheWeek.SATURDAY,
    hours: allHours
  }
];

export const FILLED_CLIENT_DAYPARTS_HOURS = Array.from(
  { length: 24 },
  (_, index) => index
);

export const FILLED_CLIENT_DAYPARTS: ClientDayparts = {
  [DayOfTheWeek.SUNDAY]: FILLED_CLIENT_DAYPARTS_HOURS,
  [DayOfTheWeek.MONDAY]: FILLED_CLIENT_DAYPARTS_HOURS,
  [DayOfTheWeek.TUESDAY]: FILLED_CLIENT_DAYPARTS_HOURS,
  [DayOfTheWeek.WEDNESDAY]: FILLED_CLIENT_DAYPARTS_HOURS,
  [DayOfTheWeek.THURSDAY]: FILLED_CLIENT_DAYPARTS_HOURS,
  [DayOfTheWeek.FRIDAY]: FILLED_CLIENT_DAYPARTS_HOURS,
  [DayOfTheWeek.SATURDAY]: FILLED_CLIENT_DAYPARTS_HOURS
};

export const EMPTY_CLIENT_DAYPARTS: ClientDayparts = {
  [DayOfTheWeek.SUNDAY]: [],
  [DayOfTheWeek.MONDAY]: [],
  [DayOfTheWeek.TUESDAY]: [],
  [DayOfTheWeek.WEDNESDAY]: [],
  [DayOfTheWeek.THURSDAY]: [],
  [DayOfTheWeek.FRIDAY]: [],
  [DayOfTheWeek.SATURDAY]: []
};

import { NotImplementedError } from "../../errors";
import { ObservableHandler } from "../handlers";
import { FootTrafficDimension } from "../../models/footTrafficAttribution";
import { firstValueFrom, Observable } from "rxjs";
import { isTruthy } from "../../utils/fp";
import { FilterTypes } from "../handlers/filter";
class ReportResults extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "foot-traffic-report-results");
    }
    async fetchResults(files, dimensions) {
        if (!files)
            return;
        const results = {};
        const dimensionsSet = new Set(dimensions);
        // only fetch needed dimensions - filtering out based on passed in dimensions. If not dimensions are passed in, fetch everything
        const requestedFileUrls = dimensions && dimensions.length
            ? files.filter((file) => {
                if (dimensionsSet.has(file.dimension)) {
                    return file;
                }
                return;
            })
            : files;
        for (const file of requestedFileUrls) {
            // iterated over the json array before, but seems like we only get 1 download link for everything json[0]
            const [json] = file.json && file.json;
            const res = await this.fetchData(file.dimension, json);
            results[file.dimension] = res;
        }
        return results;
    }
    async fetchData(dimension, url) {
        try {
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const responseText = await response.text();
            /**
             * response is not a valid JSON object - data is stored as multi-line (line separated) objects
             *  so getting data in the string format and then looping over each line to get valid objects
             *  */
            const data = responseText
                .split("\n")
                .filter(isTruthy) // removing last line (empty string)
                .map((res) => {
                const data = JSON.parse(res);
                const result = {
                    ...data,
                    // due to diff BE formulas visits dimensions may have one of the values as a result
                    visits: data.visits || data.visits_count || data.visits_credits || 0,
                    visitors: data.visitors || data.visitors_count || data.visitors_credits || 0
                };
                // due to diff BE formulas visitors metric have been changed for address dimension
                // past reports has data.visitors data, but we do not want to display it anywhere for address dimensions since the formula used was not valid
                // reports previous to the addition of visitors_count metric being created will not show visitors values for address dimension
                // all other dimensions have also changed the visitors metrics, past reports have data.visitors and we want to continue to display the same value for them. New reports will have either visitors_count or visitors_credits value
                if (dimension === FootTrafficDimension.ADDRESS) {
                    result.visitors = data.visitors_count;
                }
                return result;
            }) || [];
            return data;
        }
        catch (error) {
            throw new Error("Could not fetch data.");
        }
    }
    findItems(filters) {
        // parse resultkey and dimensions filters
        const resultKey = filters.where?.find((filter) => filter.field === "resultKey")
            ?.value;
        const dimensions = filters.where?.find((filter) => filter.field === "dimensions")
            ?.value;
        return new Observable((subscriber) => {
            firstValueFrom(this.sdk.footTrafficAttribution.find_once({
                where: [
                    {
                        field: "resultKeys",
                        type: FilterTypes.EQ,
                        value: [resultKey]
                    }
                ]
            }))
                .then((report) => {
                if (!report) {
                    throw new Error("Could not find report.");
                }
                // files array
                const files = report?.reportFiles?.files;
                // unique url for foot traffic reports
                const cacheKey = `${this.sdk.urls.madhiveReportingBaseUrl}/foot_traffic/reportResults/${resultKey}?${dimensions.join(",")}`;
                this.cache
                    .promise(cacheKey, () => this.fetchResults(files, dimensions))
                    .then((reportResults) => {
                    subscriber.next(reportResults);
                    subscriber.complete();
                })
                    .catch((error) => {
                    subscriber.error(error);
                });
            })
                .catch((error) => {
                subscriber.error(error);
            });
        });
    }
    saveItem() {
        throw new NotImplementedError("saveItem");
    }
    deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
    make() {
        throw new Error("Method not implemented.");
    }
}
export default ReportResults;

import { CampaignStatus } from "../../../../types";
import { validateBookedImpressions, validateEndDate, validateFrequency, validateStartDate, validateDayparts, validateDeviceCaps } from "../../validators";
import { shouldValidateOnLineItem } from "../../validators/generic";
import { validateFlightEndDates, validateFlightStartDates, validateLineItemCreativeFlightEndDatesInRange, validateLineItemCreativeFlightStartDatesInRange } from "../creativeFlights/validators";
import { validateLineItemCreativeFlights } from "./attachedCreatives";
export * from "./status";
export const LINE_ITEM_FLIGHT_START_DATE_RANGE_ERROR = "Please check the start dates for the creatives.";
export const LINE_ITEM_FLIGHT_END_DATE_RANGE_ERROR = "Please check the end dates for the creatives.";
export function validators(sdk) {
    return {
        /**
         * Booked Impressions: Must be a number >= 0 && <= 10MM. Once a line item is created, its impressions cannot be decreased. Cannot have both impressions and budget set simultaneously
         */
        bookedImpressions: (lineItem, value) => {
            if (!shouldValidateOnLineItem(lineItem, "bookedImpressions")) {
                return undefined;
            }
            return validateBookedImpressions(lineItem, value);
        },
        /**
         * A line item must have a name
         */
        name: (lineItem, value) => {
            if (!shouldValidateOnLineItem(lineItem, "name")) {
                return undefined;
            }
            if (!value?.trim()?.length) {
                return "Please give the line item a name.";
            }
            return undefined;
        },
        dayparts: (lineItem, value) => {
            if (!shouldValidateOnLineItem(lineItem, "dayparts")) {
                return undefined;
            }
            return validateDayparts(value);
        },
        deviceCaps: (lineItem, value) => {
            if (!shouldValidateOnLineItem(lineItem, "deviceCaps")) {
                return undefined;
            }
            return validateDeviceCaps(value);
        },
        frequencies: (lineItem, value) => {
            if (!shouldValidateOnLineItem(lineItem, "frequencies")) {
                return undefined;
            }
            return validateFrequency(value);
        },
        /**
         * A live line item must have a product.
         */
        productId: (lineItem, value) => {
            if (!shouldValidateOnLineItem(lineItem, "productId")) {
                return undefined;
            }
            if (typeof value === "string" && value.trim().length) {
                return undefined;
            }
            return "Product is required.";
        },
        /**
         * A live line item must have a publisher group.
         */
        publisherGroupId: (lineItem, value) => {
            if (!shouldValidateOnLineItem(lineItem, "publisherGroupId")) {
                return undefined;
            }
            if (typeof value === "string" && value.trim().length) {
                return undefined;
            }
            return "Publisher group is required.";
        },
        /**
         * A line item requires a start date (that is before the end date), if it is not set to start ASAP.
         */
        startDate: (lineItem, value, current, updating) => {
            if (!shouldValidateOnLineItem(lineItem, "startDate")) {
                return undefined;
            }
            return validateStartDate(lineItem, value, updating);
        },
        /**
         * A line item requires an end date (that is after the start date) or BE throws an error.
         */
        endDate: (lineItem, value, current, updating) => {
            if (!shouldValidateOnLineItem(lineItem, "endDate")) {
                return undefined;
            }
            return validateEndDate(lineItem, value, updating);
        },
        /**
         * A line item requires one live creative flight to be set live or BE throws an error.
         */
        "attachedCreatives.status": (lineItem) => {
            if (!shouldValidateOnLineItem(lineItem, "attachedCreatives.status") ||
                !lineItem.attachedCreatives?.length) {
                return undefined;
            }
            if (lineItem.status === CampaignStatus.LIVE &&
                lineItem.attachedCreatives.every((creative) => creative.status !== 200)) {
                return "At least one creative flight needs to be set live before this line item can be set live.";
            }
            return undefined;
        },
        /**
         * A line item requires at least one creative flight to be set live
         */
        "attachedCreatives.empty": (lineItem) => {
            if (!shouldValidateOnLineItem(lineItem, "attachedCreatives.empty")) {
                return undefined;
            }
            if (!lineItem.attachedCreatives?.length) {
                return "There are no attached creatives on this line item.";
            }
            return undefined;
        },
        /**
         * LineItem.attachedCreatives are individually validated via `MadSDK.campaigns.lineItems.creativeFlights.validators`.
         * The keys prefixed with "attachedCreatives." below, loop through each creative flight on line item,
         * runs the validations for [key] in `attachedCreatives.[key]`, and returns the first error message found.
         */
        /**
         * Tracking pixels must be valid URLs and no duplicates or BE throws an error.
         */
        "attachedCreatives.trackingPixels": (lineItem) => {
            if (!shouldValidateOnLineItem(lineItem, "attachedCreatives.trackingPixels")) {
                return undefined;
            }
            return validateLineItemCreativeFlights("trackingPixels", lineItem, sdk);
        },
        /**
         * Each creative flight must have a weight or BE throws an error.
         */
        "attachedCreatives.weight": (lineItem) => {
            if (!shouldValidateOnLineItem(lineItem, "attachedCreatives.weight")) {
                return undefined;
            }
            return validateLineItemCreativeFlights("weight", lineItem, sdk)
                ? "Please enter a weight for all creatives. To pause a creative, use the ... button on the creative row."
                : undefined;
        },
        /**
         * If a creative flight has a click through URL, it must be a valid URL or BE throws an error.
         */
        "attachedCreatives.clickThroughUrl": (lineItem) => {
            if (!shouldValidateOnLineItem(lineItem, "attachedCreatives.clickThroughUrl")) {
                return undefined;
            }
            return validateLineItemCreativeFlights("clickThroughUrl", lineItem, sdk)
                ? "All clickthrough urls must be valid domains, have protocols (https/http) and be url safe."
                : undefined;
        },
        /**
         * All creative flights must have a start date defined, and it must come before the flight end date.
         */
        "attachedCreatives.startDate": (lineItem) => {
            if (!shouldValidateOnLineItem(lineItem, "attachedCreatives.startDate")) {
                return undefined;
            }
            return validateFlightStartDates(lineItem)
                ? LINE_ITEM_FLIGHT_START_DATE_RANGE_ERROR
                : undefined;
        },
        /**
         * All creative flights must have an end date defined, and it must come after the flight start date.
         */
        "attachedCreatives.endDate": (lineItem) => {
            if (!shouldValidateOnLineItem(lineItem, "attachedCreatives.endDate")) {
                return undefined;
            }
            return validateFlightEndDates(lineItem) ? LINE_ITEM_FLIGHT_END_DATE_RANGE_ERROR : undefined;
        },
        /**
         * All creative flights must start on or after the line item starts.
         */
        "attachedCreatives.startDatesInRange": (lineItem) => {
            if (!shouldValidateOnLineItem(lineItem, "attachedCreatives.startDatesInRange")) {
                return undefined;
            }
            return validateLineItemCreativeFlightStartDatesInRange(lineItem)
                ? LINE_ITEM_FLIGHT_START_DATE_RANGE_ERROR
                : undefined;
        },
        /**
         * All creative flights must end on or before the line item ends.
         */
        "attachedCreatives.endDatesInRange": (lineItem) => {
            if (!shouldValidateOnLineItem(lineItem, "attachedCreatives.endDatesInRange")) {
                return undefined;
            }
            return validateLineItemCreativeFlightEndDatesInRange(lineItem)
                ? LINE_ITEM_FLIGHT_END_DATE_RANGE_ERROR
                : undefined;
        }
    };
}

import { createSelector } from "reselect";
import { RootState } from "rootReducer";

export const selectAllPublishersById = (state: RootState) =>
  state.publishers.byId;

export const selectIsAllPublishersLoading = (state: RootState) =>
  state.publishers.isAllLoading;

export const selectReceivedPublishersAt = (state: RootState) =>
  state.publishers.receivedAllAt;

export const selectPublishersError = (state: RootState) =>
  state.publishers.error;

export const selectPublisherIdsToNames = createSelector(
  selectAllPublishersById,
  publishersById =>
    Object.values(publishersById).reduce<Record<string, string>>(
      (mapping, publisher) => {
        /* eslint-disable-next-line */
        mapping[publisher.id] = publisher.name;

        return mapping;
      },
      {}
    )
);

export const selectAllPublishers = createSelector(
  selectAllPublishersById,
  publishersById => Object.values(publishersById)
);

import { useEffect } from "react";
import { BASE_APP_NAME } from "lib/constants/config";
import useOrganizationHook from "./useOrganizationHook";

const useDocumentTitle = (title?: string) => {
  const { isWhiteLabel } = useOrganizationHook();

  useEffect(() => {
    // remove MadHive from page title for the profiles listed on RULE_WHITE_LABEL_ORGS_IDS
    if (isWhiteLabel) {
      document.title = title || BASE_APP_NAME;
    } else {
      document.title = title
        ? `${BASE_APP_NAME} - ${title}`
        : `${BASE_APP_NAME}`;
    }

    return () => {
      document.title = BASE_APP_NAME;
    };
  }, [title, isWhiteLabel]);
};

export default useDocumentTitle;

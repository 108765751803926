import { AssetDurationThresholds, KnownCreativeAssetSpecificationId } from "../../../../models/creatives/specifications";
import { objectLookup, toStartCase } from "../../../../utils";
import { convertBitsToKilobits, convertBitsToMegabits } from "../../../../utils/format";
export const isAssetDimensions = (value) => "height" in value &&
    "width" in value &&
    typeof value.height === "number" &&
    typeof value.width === "number";
/**
 * @param keyPath - that leads to specification value
 * @param orgSpecs - Map of specifications by org
 * @returns - Map of acceptable values for given keypath keyed by org name
 */
const acceptableSpecValuesByOrg = (keyPath, orgSpecs) => {
    const specValuesByOrg = new Map();
    orgSpecs.forEach((specification) => {
        const spec = objectLookup(specification, keyPath);
        specValuesByOrg.set(specification.name, !spec ? undefined : Array.isArray(spec) ? spec : [spec]);
    });
    return specValuesByOrg;
};
/**
 * Used to determine if accepted values need to be identified by org, or just listed
 * @param keyPath - that leads to specification value
 * @param orgSpecs - Map of specifications by org
 */
const allOrgsHaveSameSpecs = (keyPath, orgSpecs) => {
    const specByOrg = acceptableSpecValuesByOrg(keyPath, orgSpecs);
    const specs = specByOrg.values();
    let firstSpec = specs.next().value;
    if (Array.isArray(firstSpec)) {
        firstSpec = firstSpec.sort().join("");
    }
    for (const spec of specs) {
        if ((spec?.sort()?.join("") || spec) !== firstSpec) {
            return false;
        }
    }
    return true;
};
/**
 * Simplifies getting value & acceptable specification values
 * @param keyPath - that leads to specification value
 * @param data - {assetMetadata, orgSpecs}
 * @returns - all acceptable spec values and associated value on asset
 */
export const acceptedSpecValuesAndValue = (keyPath, data) => {
    const valueKeyPath = keyPath.split(".").pop() === "dimensions" ? "dimensions" : keyPath.replace(/\.min|\.max/g, "");
    return {
        acceptable: allAcceptableSpecs(keyPath, data.orgSpecs),
        value: objectLookup(data.assetMetadata, valueKeyPath)
    };
};
/**
 * Gets all the acceptable specs across orgs
 * @param keyPath - that leads to specification value
 * @param orgSpecs - Map of specifications by org
 */
export const allAcceptableSpecs = (keyPath, orgSpecs) => {
    const acceptableSpecValues = Array.from(acceptableSpecValuesByOrg(keyPath, orgSpecs).values());
    const results = new Set([]);
    acceptableSpecValues.forEach((specValues) => {
        if (typeof specValues === "undefined") {
            return;
        }
        if (Array.isArray(specValues)) {
            specValues.forEach((val) => results.add(val));
        }
        else {
            results.add(specValues);
        }
    });
    return Array.from(results);
};
/**
 * Get human readable values from spec
 * @param keyPath - that leads to specification value
 * @param acceptableSpecs - acceptable spec values across orgs
 * @returns - formatted values
 */
export const formatSpecValues = (keyPath, acceptableSpecs) => {
    if (typeof acceptableSpecs === "undefined" || !acceptableSpecs?.length) {
        return undefined;
    }
    if (["video.dimensions", "image.dimensions", "text.dimensions"].includes(keyPath)) {
        if (acceptableSpecs) {
            return acceptableSpecs
                .reduce((acc, spec) => {
                const { height, width } = spec || {};
                if (!height || !width) {
                    return acc;
                }
                const formatted = `${spec.width}x${spec.height}`;
                if (!acc.includes(formatted)) {
                    acc.push(formatted);
                }
                return acc;
            }, [])
                .join(", ");
        }
        return undefined;
    }
    else if (["duration"].includes(keyPath)) {
        return `${acceptableSpecs
            .map((duration) => `≤ ${duration + AssetDurationThresholds.UPPER}s`)
            .join(", ")}`;
    }
    else if (["audio.bitrate.min"].includes(keyPath)) {
        if (acceptableSpecs?.length) {
            return `${acceptableSpecs
                .map((val) => convertBitsToKilobits(val))
                .join("kbps, ")}kbps`;
        }
        return undefined;
    }
    else if (["video.bitrate.max", "video.bitrate.min"].includes(keyPath)) {
        if (acceptableSpecs?.length) {
            return `${acceptableSpecs
                .map((val) => convertBitsToMegabits(val))
                .join("mbps, ")}mbps`;
        }
        return undefined;
    }
    else if (["audio.codec", "video.codec"].includes(keyPath)) {
        return acceptableSpecs?.join(", ").toLocaleUpperCase();
    }
    else {
        return `${acceptableSpecs.join(", ")}`;
    }
};
/**
 * Generates failure message for property that failed specification
 * @param messagePrefix - primary part of error message that precedes list of acceptable values
 * @param keyPath - that leads to specification value
 * @param orgSpecs - Map of specifications by org
 * @returns spec error message — undefined means no spec error
 */
export const deriveSpecMessage = (messagePrefix, keyPath, orgSpecs) => {
    const orgSpecKeyPath = keyPath;
    const acceptableSpecs = allAcceptableSpecs(orgSpecKeyPath, orgSpecs);
    const allAcceptedSpecs = formatSpecValues(keyPath, acceptableSpecs);
    if (messagePrefix.includes("(s)")) {
        if (acceptableSpecs?.length < 2) {
            messagePrefix = messagePrefix.replace("(s)", "");
        }
        else {
            messagePrefix = messagePrefix.replace("(s)", "s");
        }
    }
    let suffix = "";
    switch (keyPath) {
        case "duration":
            suffix = "Duration must match exactly to 1/100th of a second";
            break;
    }
    let responseMessage = `${messagePrefix}${allAcceptedSpecs?.length ? ` ${allAcceptedSpecs}` : ""}`;
    if (orgSpecs?.size > 1 && !allOrgsHaveSameSpecs(keyPath, orgSpecs)) {
        const orgSpecificSpecs = [];
        orgSpecs.forEach((orgSpec) => {
            if (orgSpec.name === KnownCreativeAssetSpecificationId.BASIC) {
                return;
            }
            const specValue = objectLookup(orgSpec, keyPath);
            const orgAccepts = formatSpecValues(keyPath, Array.isArray(specValue) ? specValue : [specValue]);
            if (!orgAccepts?.length) {
                return;
            }
            orgSpecificSpecs.push(`${toStartCase(orgSpec.name)}: ${orgAccepts}`);
        });
        if (orgSpecificSpecs.length) {
            responseMessage = `${responseMessage} \n(${orgSpecificSpecs.join(" | ")})`;
        }
    }
    return `${responseMessage}${suffix?.length ? `. \n${suffix}` : ""}`.trim();
};
/**
 * Determines if SpecificationProblems has any errors
 * @param failures: failures to check
 * @returns: boolean
 */
export const hasSpecErrors = (problems) => {
    if (!problems) {
        return false;
    }
    return [
        typeof problems.duration === "string",
        typeof problems.image?.dimensions === "string",
        typeof problems.text?.dimensions === "string",
        typeof problems.video?.dimensions === "string",
        typeof problems.video?.aspectRatio === "string",
        typeof problems.video?.bitrate === "string",
        typeof problems.video?.codec === "string",
        typeof problems.audio?.bitrate === "string",
        typeof problems.audio?.codec === "string"
    ].includes(true);
};

import { toStartCase } from "../../utils";
/**
 * The members of this enum are the string values of the encoding service's 'name' field of the `Spec` object.
 * See: https://github.com/MadHive/taco/tree/develop/services/encoder
 * */
export var KnownCreativeAssetSpecificationId;
(function (KnownCreativeAssetSpecificationId) {
    KnownCreativeAssetSpecificationId["BASIC"] = "basic";
    KnownCreativeAssetSpecificationId["PREMION"] = "Premion";
    KnownCreativeAssetSpecificationId["HULU"] = "Hulu";
})(KnownCreativeAssetSpecificationId || (KnownCreativeAssetSpecificationId = {}));
export var AssetDurationThresholds;
(function (AssetDurationThresholds) {
    AssetDurationThresholds[AssetDurationThresholds["LOWER"] = 2] = "LOWER";
    AssetDurationThresholds[AssetDurationThresholds["UPPER"] = 0.1] = "UPPER";
})(AssetDurationThresholds || (AssetDurationThresholds = {}));
export var AssetStandardsStatus;
(function (AssetStandardsStatus) {
    AssetStandardsStatus["FAILED_DURATION"] = "Invalid Duration";
    AssetStandardsStatus["FAILED_NON_DURATION_SPEC"] = "Didn't Pass";
    AssetStandardsStatus["PASSED"] = "Passed";
})(AssetStandardsStatus || (AssetStandardsStatus = {}));
/**
 * formats array of dimension arrays, into array of dimension objects
 * @param dimensions array of dimensions arrays [width, height]
 * @returns array of dimension objects {width: number; height: number}
 */
const getDimensionsFromArray = (dimensions) => {
    return dimensions.reduce((acc, dimension) => {
        if (!dimension[0] || !dimension[1]) {
            return acc;
        }
        return [
            ...acc,
            {
                width: dimension[0],
                height: dimension[1]
            }
        ];
    }, []);
};
export const serviceSpecificationToSpecification = (specification) => {
    return {
        id: specification.id,
        name: specification.name,
        label: specification.name === KnownCreativeAssetSpecificationId.BASIC
            ? "Standards"
            : `${toStartCase(specification.name)} Standards`,
        duration: specification.durations,
        video: {
            aspectRatio: "16:9",
            dimensions: getDimensionsFromArray(specification.dimensions),
            codec: Array.isArray(specification.video_codecs) ? specification.video_codecs : [],
            bitrate: {
                min: specification.min_video_bitrate,
                max: specification.max_video_bitrate
            }
        },
        audio: {
            codec: Array.isArray(specification.audio_codecs) ? specification.audio_codecs : [],
            bitrate: {
                min: specification.min_audio_bitrate
            }
        },
        image: { dimensions: getDimensionsFromArray(specification.image.dimensions) },
        text: { dimensions: getDimensionsFromArray(specification.text.dimensions) }
    };
};

export const buildSort = (sort) => {
    let direction = sort.direction;
    if (direction === "ascending") {
        direction = "asc";
    }
    else if (direction === "descending") {
        direction = "desc";
    }
    let field = sort.field;
    switch (field) {
        case "price.cpm":
            field = "cpm";
            break;
        case "price.type":
            field = "cost_func";
            break;
        case "deal.type":
            field = "deal_type";
            break;
        case "updatedAt":
            field = "updated_at";
            break;
        case "createdAt":
            field = "created_at";
            break;
        case "audienceTargeting":
            field = "audience_targeting";
            break;
        case "geoTargeting":
            field = "geo_targeting";
            break;
    }
    return `${field} ${direction}`;
};
/**
 * @param sort: information on the field and direction of sorting, if any.
 * @return: a properly-formatted query string, based on the provided sorting info. Empty if there is no sort.
 */
export const sortToQueryParam = (sort) => {
    if (sort && sort.field && sort.direction) {
        return `&sortBy=${buildSort(sort)}`;
    }
    return "";
};

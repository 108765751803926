import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Archive: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-archive"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M8.25 11a.75.75 0 0 1 .75-.75h6a.75.75 0 0 1 0 1.5H9a.75.75 0 0 1-.75-.75Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.417 7.7A1.751 1.751 0 0 0 22.75 6V4A1.75 1.75 0 0 0 21 2.25H3A1.75 1.75 0 0 0 1.25 4v2c0 .823.568 1.513 1.333 1.7V19a2.75 2.75 0 0 0 2.75 2.75h13.334a2.75 2.75 0 0 0 2.75-2.75V7.7ZM3 3.75a.25.25 0 0 0-.25.25v2c0 .138.112.25.25.25h18a.25.25 0 0 0 .25-.25V4a.25.25 0 0 0-.25-.25H3Zm16.917 4H4.083V19c0 .69.56 1.25 1.25 1.25h13.334c.69 0 1.25-.56 1.25-1.25V7.75Z"
      />
    </svg>
  );
};

export default Archive;

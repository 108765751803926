import axios from "axios";
import { Handler } from "../handlers";
import { validateFilterStructure, FilterValueTypes, FilterTypes } from "../../modules/handlers/filter";
import { NotImplementedError, FiltersRequiredError, FilterErrorName, PublisherListAuditFetchFailed } from "../../errors";
import { serviceListToList } from "../../models/publisher";
import { parseNewNewYorkApiResponse } from "../../utils";
/**
 * Handles publisher list audit find.
 * Audits are version histories of a publisher list, and are thus just an array of Lists.
 */
class Audits extends Handler {
    constructor(sdk) {
        super(sdk, "audits");
        /**
         * @deprecated
         * find_once is not supported for audits.
         */
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.find_once = (filters) => {
            // find_once makes no real sense in the contex of audits
            // you want an audit based on a single publisher list id, which you already get from find(...)
            // there's no "once" when there's only one thing to get as a result to begin with
            throw new NotImplementedError("find_once");
        };
    }
    /**
     * Find an audit based on a publisher list id.
     *
     * @param filters: filter publisher audits from by a given publisher list id (e.g., {where: [field: "id", type: FilterTypes.EQ, value: "1"]}).
     *          Supports (and requires):
     *              {FilterTypes.EQ, "id"}
     */
    async findItems(filters) {
        validateFilterStructure(filters, [
            { filterType: FilterTypes.EQ, valueType: FilterValueTypes.STRING }
        ]);
        const publisherListId = filters.where?.find((filter) => filter.field === "id")?.value;
        if (!publisherListId) {
            throw new FiltersRequiredError("id");
        }
        const url = `${this.sdk.urls.baseAPIUrl}/publisher-group/${publisherListId}/audit`;
        const key = `${url}-${this.keyFromFilter(filters)}`;
        if (this.cache.has(key)) {
            return new Promise((resolve) => {
                this.cache.get(key).then((audit) => {
                    resolve(audit);
                });
            });
        }
        const req = new Promise((resolve, reject) => {
            axios
                .get(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((res) => {
                const { data: rawAuditData } = parseNewNewYorkApiResponse(res);
                resolve(rawAuditData.map(serviceListToList));
            })
                .catch((error) => {
                if (error.name === FilterErrorName.FilterInvalidError) {
                    reject(error);
                }
                else {
                    reject(new PublisherListAuditFetchFailed());
                }
            });
        });
        this.cache.set(key, req);
        return req;
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async saveItem(audit) {
        throw new NotImplementedError("save");
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}
export default Audits;

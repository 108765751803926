import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const X: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-x"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M7.61726 6.27748C7.24729 5.90751 6.64745 5.90751 6.27748 6.27748C5.90751 6.64745 5.90751 7.24729 6.27748 7.61726L10.6602 12L6.27748 16.3827C5.90751 16.7527 5.90751 17.3526 6.27748 17.7225C6.64745 18.0925 7.24729 18.0925 7.61726 17.7225L12 13.3398L16.3827 17.7225C16.7527 18.0925 17.3526 18.0925 17.7225 17.7225C18.0925 17.3526 18.0925 16.7527 17.7225 16.3827L13.3398 12L17.7225 7.61726C18.0925 7.24729 18.0925 6.64745 17.7225 6.27748C17.3526 5.90751 16.7527 5.90751 16.3827 6.27748L12 10.6602L7.61726 6.27748Z" />
    </svg>
  );
};

export default X;

import classNames from "classnames";
import "./ButtonGroup.scss";
import { FC, HTMLProps } from "react";

interface ButtonGroupProps {
  fullHeight?: boolean;
  connected?: boolean;
  centerAlign?: boolean;
  bottomAlign?: boolean;
  flexEnd?: boolean;
}

const ButtonGroup: FC<ButtonGroupProps & HTMLProps<HTMLDivElement>> = props => {
  const {
    fullHeight,
    connected,
    centerAlign,
    bottomAlign,
    flexEnd,
    ...divProps
  } = props;

  return (
    <div
      {...divProps}
      className={classNames(
        "SmithersButtonGroup__Container",
        connected ? "SmithersButtonGroup__Container--connected" : null,
        fullHeight ? "SmithersButtonGroup__Container--full-height" : null,
        centerAlign ? "SmithersButtonGroup__Container--centerAlign" : null,
        bottomAlign ? "SmithersButtonGroup__Container--bottom-align" : null,
        flexEnd ? "SmithersButtonGroup__Container--justify-flexend" : null,
        divProps.className
      )}
    >
      {props.children}
    </div>
  );
};

export default ButtonGroup;

import {
  GET_REPORTING_CAMPAIGNS_FAILURE,
  GET_REPORTING_CAMPAIGNS_PENDING,
  GET_REPORTING_CAMPAIGNS_SUCCESS
} from ".";
import {
  GET_REPORTING_ADVERTISERS_FAILURE,
  GET_REPORTING_ADVERTISERS_PENDING,
  GET_REPORTING_ADVERTISERS_SUCCESS,
  GET_REPORTING_CREATIVES_FAILURE,
  GET_REPORTING_CREATIVES_PENDING,
  GET_REPORTING_CREATIVES_SUCCESS,
  ReportingActionTypes,
  ReportingState
} from "./types";

export const initialState: ReportingState = {
  advertisersById: {},
  isAdvertisersLoading: false,
  isCampaignsLoading: false,
  isCreativesLoading: false,
  campaignsById: {},
  creatives: {},
  error: null
};

export const reportingReducer = (
  state = initialState,
  action: ReportingActionTypes
): ReportingState => {
  switch (action.type) {
    case GET_REPORTING_ADVERTISERS_PENDING:
      return {
        ...state,
        isAdvertisersLoading: true,
        error: null
      };

    case GET_REPORTING_ADVERTISERS_SUCCESS:
      return {
        ...state,
        advertisersById: action.payload,
        isAdvertisersLoading: false,
        error: null
      };

    case GET_REPORTING_ADVERTISERS_FAILURE:
      return {
        ...state,
        isAdvertisersLoading: false,
        error: action.meta.error
      };

    case GET_REPORTING_CAMPAIGNS_PENDING:
      return {
        ...state,
        isCampaignsLoading: true,
        error: null
      };

    case GET_REPORTING_CAMPAIGNS_SUCCESS:
      return {
        ...state,
        campaignsById: action.payload,
        isCampaignsLoading: false,
        error: null
      };

    case GET_REPORTING_CAMPAIGNS_FAILURE:
      return {
        ...state,
        isCampaignsLoading: false,
        error: action.meta.error
      };

    case GET_REPORTING_CREATIVES_PENDING:
      return {
        ...state,
        isCreativesLoading: true,
        error: null
      };

    case GET_REPORTING_CREATIVES_SUCCESS:
      return {
        ...state,
        creatives: action.payload,
        isCreativesLoading: false,
        error: null
      };

    case GET_REPORTING_CREATIVES_FAILURE:
      return {
        ...state,
        isCreativesLoading: false,
        error: action.meta.error
      };

    default:
      return state;
  }
};

import { useRef, useLayoutEffect } from "react";
import styled from "@emotion/styled";
import { Icon } from "@blueprintjs/core";
import SmithersSelect from "components/Reusable/SmithersSelect/SmithersSelect";
import { colors } from "./style";

interface PaginatorProps {
  page: number;
  setPage: (newPage: number) => void;
  totalRows: number;
  rowsPerPage: number;
  setRowPerPage: (newRowNum: number) => void;
  possibleNumberOfRows?: number[];
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: var(--spacing-16) var(--spacing-24);
  background-color: var(--white);
`;

const StyledIcon = styled(Icon as unknown as (props: any) => JSX.Element)<{
  disabled?: boolean;
}>`
  pointer-events: ${props => (props.disabled ? "none" : "")};
  opacity: ${props => (props.disabled ? 0.4 : 1)};
  cursor: pointer;
  margin: 0 0.25rem;
`;

const FlexRow = styled.div`
  display: flex;
  align-items: center;
`;

const NumberElement = styled.div`
  font-weight: var(--font-weight-bold);
  line-height: var(--spacing-16);
  font-size: var(--font-size-12);
  margin: 0 var(--spacing-8);
`;

const TextElement = styled.div<{ paddedLeft?: boolean }>`
  line-height: var(--spacing-16);
  font-size: var(--font-size-12);
  padding-left: ${props => (props.paddedLeft ? "var(--spacing-8)" : 0)};
`;

const Paginator = (props: PaginatorProps) => {
  const {
    page,
    setPage,
    totalRows,
    rowsPerPage,
    possibleNumberOfRows,
    setRowPerPage
  } = props;

  // lastPage is zero-indexed
  // 51 total rows / 50 rows perPage requires two actual pages
  // First page is 0
  // Last page is 1
  const lastPage = Math.floor(totalRows / rowsPerPage);

  useLayoutEffect(() => {
    if (page > lastPage) {
      setPage(lastPage);
    }
  }, [page, lastPage]);

  const id = useRef(Math.round(Math.random() * 1000));

  const upperPaginationBound =
    (page + 1) * rowsPerPage > totalRows ? totalRows : (page + 1) * rowsPerPage;

  return (
    <Wrapper>
      <div>
        {possibleNumberOfRows && (
          <FlexRow>
            <SmithersSelect
              id={`table-row-count-select-${id.current}`}
              options={possibleNumberOfRows.map((v: number) => ({
                id: v,
                label: v.toString()
              }))}
              selectedOptionId={rowsPerPage}
              onChange={setRowPerPage}
              isRightIconCaret
            />
            <TextElement paddedLeft>Per Page</TextElement>
          </FlexRow>
        )}
      </div>
      <FlexRow>
        <StyledIcon
          onClick={() => setPage(page - 1)}
          icon="arrow-left"
          color={colors.main}
          disabled={page === 0}
        />
        <NumberElement>
          {page * rowsPerPage + 1}-{upperPaginationBound}
        </NumberElement>
        <TextElement>of</TextElement>
        <NumberElement>{totalRows}</NumberElement>
        <StyledIcon
          onClick={() => setPage(page + 1)}
          icon="arrow-right"
          color={colors.main}
          disabled={page === lastPage}
        />
      </FlexRow>
    </Wrapper>
  );
};

export default Paginator;

export var StationField;
(function (StationField) {
    StationField["Id"] = "id";
    StationField["Name"] = "name";
    StationField["CallLetters"] = "callLetters";
    StationField["ExtId"] = "extId";
    StationField["DmaId"] = "dmaId";
    StationField["Parent"] = "parent";
})(StationField || (StationField = {}));
export const stationToServiceStation = (station) => {
    const dmaId = Number(station.dmaId);
    return {
        id: station.id,
        name: station.name,
        call_letters: station.callLetters,
        ext_id: station.extId,
        dma_id: Number.isNaN(dmaId) ? undefined : dmaId,
        parent: station.parent,
        status: station.status
    };
};
export const serviceStationToStation = (station) => {
    return {
        id: station.id,
        name: station.name,
        callLetters: station.call_letters,
        extId: station.ext_id,
        dmaId: String(station.dma_id),
        parent: station.parent,
        status: station.status
    };
};

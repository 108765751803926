import { CampaignStatus } from "../../models/campaigns/instruction";
import { StatusHandler } from "../handlers/status";
export class CampaignStatusManager extends StatusHandler {
    constructor(validate) {
        super();
        this.validate = validate;
    }
    set(campaign, status) {
        // Convert single Campaign to array if it isn't.
        const campaigns = Array.isArray(campaign) ? campaign : [campaign];
        const updatedCampaigns = [];
        campaigns.forEach((campaign) => {
            if (!this.validate(campaign, status)) {
                updatedCampaigns.push({
                    ...campaign,
                    status: status
                });
            }
        });
        // Return array if originally provided an array.
        return Array.isArray(campaign) ? updatedCampaigns : updatedCampaigns[0] || null;
    }
    toggleLive(campaign) {
        // Convert single Campaign to array if it isn't.
        const campaigns = Array.isArray(campaign) ? campaign : [campaign];
        const updatedCampaigns = [];
        // Each campaign is set individually in case they are not all the same status.
        campaigns.forEach((campaign) => {
            const updatedCampaign = this.set(campaign, campaign.isLive() ? CampaignStatus.PAUSED : CampaignStatus.LIVE);
            updatedCampaigns && updatedCampaigns.push(updatedCampaign);
        });
        // Return array if originally provided an array.
        return Array.isArray(campaign) ? updatedCampaigns : updatedCampaigns[0] || null;
    }
}

import { FilterInvalidError } from "../errors";
/**
 * Validates filters' FilterType/(typeof value) combination against expected. Throws if invalid, else does nothing.
 * @param filters: the filters to validate.
 * @param expected: indicates the acceptable FilterTypes and value types combos (e.g., [{EQ, string}, {IN, object}]).
 * @throws: FilterInvalidError if a filter in the provided filters has an invalid FilterType/(typeof value) combination.
 */
export const validateFilterStructure = (filters, expected) => {
    if (!filters || !filters.where || filters.where.length === 0) {
        return;
    }
    filters.where.forEach((filter) => {
        const filterType = filter.type;
        const valueType = typeof filter.value;
        if (!expected.some((expectedStructure) => expectedStructure.filterType === filterType && expectedStructure.valueType === valueType)) {
            throw new FilterInvalidError(`${filterType} and ${valueType}`);
        }
    });
};
/**
 * @param paging: paging filter info.
 * @return: true if it is validly structured. Else, false.
 */
export const isPagingFilterValid = (paging) => {
    if (typeof paging.token !== "undefined") {
        return typeof paging.offset !== "undefined";
    }
    return true;
};

/** inventory_packages can be added to many of our main data types — such as: insts, products, deals, etc. */
export var InventoryType;
(function (InventoryType) {
    InventoryType["PUBLISHER_GROUPS"] = "PUB_GROUP";
    InventoryType["PMP_DEALS_AND_SUPERTAGS"] = "DEALS";
    InventoryType["LINE_ITEM_PUB_GROUP"] = "LINE_ITEM_PUB_GROUP";
})(InventoryType || (InventoryType = {}));
// Common text to display for the InventoryType options
export var InventoryTypeText;
(function (InventoryTypeText) {
    InventoryTypeText["DEALS"] = "PMP Deals & SuperTags";
    InventoryTypeText["LINE_ITEM_PUB_GROUP"] = "Line Item Publisher Group";
    InventoryTypeText["PUB_GROUP"] = "Publisher Group";
})(InventoryTypeText || (InventoryTypeText = {}));
// TODO combine InventoryContentType and ContentType https://madhive.atlassian.net/browse/INPACK-1190
export var InventoryContentType;
(function (InventoryContentType) {
    InventoryContentType["GENRE"] = "Genre";
    InventoryContentType["LANGUAGE"] = "Language";
})(InventoryContentType || (InventoryContentType = {}));
/**
 * Convert a ServiceInventoryPackages to InventoryPackages.
 * @param inventory_packages The ServiceInventoryPackages to convert.
 * @returns The InventoryPackages.
 */
export const serviceToInventoryPackages = (inventory_packages) => (inventory_packages || []).map((inventory_package) => {
    let contentType;
    if (inventory_package.genres?.length) {
        contentType = InventoryContentType.GENRE;
    }
    else if (inventory_package.languages?.length) {
        contentType = InventoryContentType.LANGUAGE;
    }
    return {
        id: inventory_package?.id,
        wlistId: inventory_package?.wlist_id,
        inventoryIds: inventory_package.inventory_ids,
        genres: inventory_package?.genres,
        languages: inventory_package?.languages,
        volumeCapPct: inventory_package?.volume_cap_pct
            ? inventory_package?.volume_cap_pct / 100
            : undefined,
        maxEcpm: inventory_package?.max_ecpm,
        priority: inventory_package?.priority,
        name: inventory_package.name,
        contentType,
        inventoryType: inventory_package?.inventory_package_type,
        publisherIds: inventory_package?.publisher_ids
    };
});
/**
 * Convert an InventoryPackages to ServiceInventoryPackages.
 * @param inventoryPackages The InventoryPackages to convert.
 * @returns The ServiceInventoryPackages.
 */
export const inventoryPackagesToService = (inventoryPackages) => (inventoryPackages || []).map((inventoryPackage) => {
    return {
        id: inventoryPackage.id,
        name: inventoryPackage.name,
        inventory_package_type: inventoryPackage.inventoryType || InventoryType.PUBLISHER_GROUPS,
        wlist_id: inventoryPackage.wlistId,
        inventory_ids: inventoryPackage.inventoryIds,
        genres: inventoryPackage.genres,
        languages: inventoryPackage.languages,
        volume_cap_pct: inventoryPackage.volumeCapPct
            ? Math.round(inventoryPackage.volumeCapPct * 100)
            : undefined,
        max_ecpm: inventoryPackage.maxEcpm,
        priority: inventoryPackage.priority,
        publisher_ids: inventoryPackage.publisherIds
    };
});

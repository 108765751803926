export const downloadFile = (objectUrl: string, fileName?: string) => {
  const link = window.document.createElement("a");
  fileName && (link.download = fileName);
  link.href = objectUrl;
  link.style.visibility = "hidden";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const downloadBlob = (blob: Blob, fileName?: string) => {
  downloadFile(URL.createObjectURL(blob), fileName);
};

import { RemoteConfigProperties } from "@madhive/mad-sdk";
import { madSDK } from "lib/sdk";

/**
 * While a useFeatureFlag hook exists for React components, there is no equivalent for our TypeScript files.
 * This attempts to fill in that gap, providing a way to conditionally execute TS code based on the value of a feature flag.
 *
 * [EXAMPLE 1/3]
 * Await-ing the value of a feature flag:
 *
 * const isLegacyCampaign = await featureFlag(RemoteConfigProperties.LEGACY_CAMPAIGN);
 *
 * [EXAMPLE 2/3]
 * Executing a callback function if a feature flag is enabled:
 *
 * const isLegacyCampaign = await featureFlag.on(RemoteConfigProperties.LEGACY_CAMPAIGN, () => {});
 *
 * [EXAMPLE 3/3]
 * Executing a callback function if a feature flag is disabled:
 *
 * const isLegacyCampaign = await featureFlag.off(RemoteConfigProperties.LEGACY_CAMPAIGN, () => {});
 *
 * Since our TS config does not enable top-level await, the callback functions (via featureFlag.on. and featureFlag.off) are provided as a workaround.
 * @see: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-8.html#top-level-await
 */

export const featureFlag = async (key: RemoteConfigProperties) => {
  await madSDK.featureFlags.loaded();

  return madSDK.featureFlags.isFlagEnabled(key);
};

/**
 * Executes a callback function if the specified feature flag is enabled.
 *
 * @param key: The feature flag key.
 * @param cb: The callback function to invoke.
 */
featureFlag.on = async (key: RemoteConfigProperties, cb: () => void) => {
  await madSDK.featureFlags.loaded();

  if (madSDK.featureFlags.isFlagEnabled(key)) {
    cb();
  }
};

/**
 * Executes a callback function if the specified feature flag is disabled.
 *
 * @param key: The feature flag key.
 * @param cb: The callback function to invoke.
 */
featureFlag.off = async (key: RemoteConfigProperties, cb: () => void) => {
  await madSDK.featureFlags.loaded();

  if (!madSDK.featureFlags.isFlagEnabled(key)) {
    cb();
  }
};

/**
 * @param object: an object to get a value from.
 * @param path: a path within the object, leading to the value you want. It is dot-separated. Example: "user.meta.nickname".
 * @return: the value from the object at that path, or undefined if nothing exists within the object at that path.
 */
export const objectLookup = (object, path) => {
    if (!path || typeof object !== "object" || !object) {
        return undefined;
    }
    const depth = path.split(".");
    let result = object[depth[0]];
    if (result !== undefined) {
        for (let i = 1; i < depth.length; i++) {
            result = typeof result === "object" ? result[depth[i]] : undefined;
            if (result === undefined) {
                break;
            }
        }
    }
    return result;
};

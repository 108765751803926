import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";

const Question: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-circled-question"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M9.25 9.83301C9.25 8.31379 10.4808 7.08301 12 7.08301C13.5192 7.08301 14.75 8.31379 14.75 9.83301C14.75 11.0272 13.9717 11.6569 13.4302 12.0219L13.4293 12.0225C12.9717 12.33 12.75 12.5773 12.75 13V13.25C12.75 13.6642 12.4142 14 12 14C11.5858 14 11.25 13.6642 11.25 13.25V13C11.25 11.7891 12.0379 11.1504 12.5922 10.7778C13.0384 10.477 13.25 10.2387 13.25 9.83301C13.25 9.14222 12.6908 8.58301 12 8.58301C11.3092 8.58301 10.75 9.14222 10.75 9.83301C10.75 10.2472 10.4142 10.583 10 10.583C9.58579 10.583 9.25 10.2472 9.25 9.83301Z" />
      <path d="M11.999 15.25C11.4487 15.25 10.9975 15.6965 11 16.2527C11.0015 16.8037 11.4487 17.25 12 17.25C12.5522 17.25 13 16.8022 13 16.25C13 15.6958 12.5503 15.25 11.999 15.25Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 12C2.25 6.61479 6.61479 2.25 12 2.25C17.3852 2.25 21.75 6.61479 21.75 12C21.75 17.3852 17.3852 21.75 12 21.75C6.61479 21.75 2.25 17.3852 2.25 12ZM12 3.75C7.44321 3.75 3.75 7.44321 3.75 12C3.75 16.5568 7.44321 20.25 12 20.25C16.5568 20.25 20.25 16.5568 20.25 12C20.25 7.44321 16.5568 3.75 12 3.75Z"
      />
    </svg>
  );
};

export default Question;

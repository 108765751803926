import axios from "axios";
import { FilterTypes, FilterValueTypes, validateFilterStructure } from "../handlers/filter";
import { FreewheelProductsFetchFailed, FreewheelProductsAccessDenial, NotImplementedError, UnauthenticatedError, InvalidFreewheelProductType } from "../../errors";
import { serviceFreewheelProductToFreewheelProduct, FreewheelProductType, SupportedFilterField } from "../../models/freewheelProduct";
import { Handler } from "../handlers";
import { KnownOrganizationIds } from "../../types";
/**
 * Handles freewheel products collection find and management.
 */
class FreeWheelProducts extends Handler {
    constructor(sdk) {
        super(sdk, "free-wheel-products", { atomize: true });
        /**
         * @param user: the current user.
         * @param options: control which type of products to get. Supports a boolean indicating whether or not to get site-group-id-based products.
         * @return: an array of freewheel products, of the variant (i.e., site-group-id-based or non-site-group-id-based) requested.
         */
        this.getProducts = (user, type) => {
            const url = this.getPioProductsUrl(user, type);
            return this.cache.promise(url, () => axios
                .get(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => {
                const { data: serviceProducts } = response;
                return serviceProducts.map(serviceFreewheelProductToFreewheelProduct);
            })
                .catch(() => {
                throw new FreewheelProductsFetchFailed();
            }));
        };
        /**
         * @param filters: the filters provided to findItems.
         * @return: true if the filters indicate that site-group-id-based products should be included. Else, false.
         */
        this.getFreewheelProductTypesToFetch = (filters) => {
            const typesToFetch = filters.where?.find((filter) => filter.field === SupportedFilterField.TYPE)
                ?.value || null;
            if (!typesToFetch) {
                // PRODUCT_ID is the "current" version, so we default to that if no filters are specified
                return [FreewheelProductType.PRODUCT_ID];
            }
            // validate that the provided values are valid values from FreewheelProductType
            const validFreeWheelProductTypes = new Set(Object.values(FreewheelProductType));
            for (let i = 0; i < typesToFetch.length; ++i) {
                const providedType = typesToFetch[i];
                if (!validFreeWheelProductTypes.has(providedType)) {
                    throw new InvalidFreewheelProductType(providedType);
                }
            }
            return typesToFetch;
        };
        /**
         * @param user: the user object returned from this.sdk.getCurrentUser.
         * @throws: if the user's organization ID is not Premion's.
         */
        this.validateFreewheelAccess = (user) => {
            if (user.primaryOrganizationId !== KnownOrganizationIds.PREMION) {
                throw new FreewheelProductsAccessDenial();
            }
        };
        /**
         * @param user: the user doing the fetching.
         * @param type: the type of pio product url.
         * @return: the URL to query for PIO products.
         * @throws: InvalidFreewheelProductType if the provided type is invalid.
         */
        this.getPioProductsUrl = (user, type) => {
            const parentQueryParam = `?parent=${user.primaryOrganizationId}`;
            switch (type) {
                case FreewheelProductType.PRODUCT_ID:
                    // the "currenet" url, for product id-based products, is /pio_prods
                    return `${this.sdk.urls.baseHermesUrlStaticAssets}/pio_prods${parentQueryParam}`;
                case FreewheelProductType.SITE_GROUP_ID:
                    // the site-group-id-based, "legacy" url (for site-group-id-based products) is /pio_products
                    return `${this.sdk.urls.baseHermesUrlStaticAssets}/pio_products${parentQueryParam}`;
                default:
                    throw new InvalidFreewheelProductType(type);
            }
        };
    }
    async findItems(filters) {
        // validate our filters (if any) before we try any requests
        validateFilterStructure(filters, [
            { filterType: FilterTypes.IN, valueType: FilterValueTypes.OBJECT }
        ]);
        const user = this.sdk.getCurrentUser();
        if (!user) {
            throw new UnauthenticatedError();
        }
        this.validateFreewheelAccess(user);
        const typesToFetch = this.getFreewheelProductTypesToFetch(filters);
        const promises = [];
        for (let i = 0; i < typesToFetch.length; ++i) {
            const type = typesToFetch[i];
            promises.push(this.getProducts(user, type));
        }
        const products = await Promise.all(promises);
        return products.flat();
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async saveItem(product) {
        throw new NotImplementedError("save");
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}
export default FreeWheelProducts;

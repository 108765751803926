export class InvalidOrganizationType extends Error {
    constructor(type) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidOrganizationType);
        }
        this.name = "InvalidOrganizationType";
        this.message = `Failed to process organizations. Encountered an unrecognized organization type '${type}'.`;
    }
}

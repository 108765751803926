export { isTruthy } from "@madhive/mad-sdk";

export const sum = (nums: number[]) => nums.reduce((acc, cur) => acc + cur, 0);

export const getProp =
  <T>(key: keyof T) =>
  (obj: T) =>
    obj[key];

export const identity = <T>(x: T) => x;

// tslint:disable-next-line:no-empty
export const noop = (...args: any[]) => {}; // eslint-disable-line no-unused-vars

/**
 *
 * @param tupleArray
 */
export const unwrapTupleArray = <Key extends string | symbol | number, Val>(
  tupleArray: Array<[Key, Val]>
): Record<Key, Val> =>
  tupleArray.reduce<Record<Key, Val>>((acc, cur) => {
    const [key, value] = cur;
    acc[key] = value;
    return acc;
  }, {} as Record<Key, Val>);

export const getRandomArrayElement = <T = any>(arr: T[]) =>
  arr[Math.floor(Math.random() * arr.length)];

/**
 * Used to assert that a value is not null. Throws an error if the value is null.
 */
export const notNull = <T>(x: T | null | undefined, message?: string): T => {
  if (x === null || x === undefined) {
    throw new TypeError(message);
  }

  return x;
};

export const getAnyKeyValue = <K extends string | symbol | number, V>(
  x: Record<K, V>
): V | undefined => {
  const values = Object.values(x) as V[];

  return values[0] as V | undefined;
};

/** Use this function instead of lodash's `keyBy` since this is better at retaining type safety. */
export const keyObjectArrayBy = <T extends Record<string, any>>(
  arr: T[],
  key: keyof T
): Record<string | symbol | number, T> =>
  arr.reduce(
    (acc, cur) => {
      acc[cur[key]] = cur;
      return acc;
    },
    /**
     * `Object.create(null)` over `{}` so no false positives when the value of
     * `cur[key]` would be `.toString` for example, and avoiding dangerous behavior
     * if `cur[key]` would be `__prototype__` or `constructor`.
     * */
    Object.create(null)
  );

/** Accepts positive numbers. Decimal values will be rounded. */
export const allNumbersAreWithinThreshold = (
  weights: number[],
  differenceThreshold = 1
): boolean => {
  if (weights.length < 1) {
    return true;
  }

  const baseValue = Math.round(weights[0]);

  return weights.every(
    cur => Math.round(cur) - baseValue <= differenceThreshold
  );
};

export const deleteKeysWithUndefinedValues = <T extends Record<string, any>>(
  obj: T
): T =>
  Object.entries(obj).reduce<T>(
    (acc, [key, value]: [string | number, any]) => {
      if (value === undefined) {
        delete acc[key];
      }

      return acc;
    },
    { ...obj }
  );

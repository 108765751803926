export enum LoginEvents {
  RECORD_USER_SYSTEM_TIME = "record_user_system_time"
}

export enum ForecasterEvents {
  CLICKED_AUDIENCE_FORECASTER_ITEM = "clicked_audience_forecaster_item",
  CLICKED_GENERATE_AUDIENCE_FORECAST_BUTTON = "clicked_generate_audience_forecast_button",
  CLICKED_CREATE_AUDIENCE_FORECAST_BUTTON = "clicked_create_audience_forecast_button",
  CLICKED_DOWNLOAD_AUDIENCE_FORECAST = "clicked_download_audience_forecast",
  CLICKED_DELETE_AUDIENCE_FORECAST = "clicked_delete_audience_forecast",
  CLICKED_DUPLICATE_AUDIENCE_FORECAST = "clicked_duplicate_audience_forecast",
  CLICKED_AUDIENCE_FORECASTER_PRINT_PDF_SUCCESS = "clicked_audience_forecaster_print_pdf_success",
  CLICKED_AUDIENCE_FORECASTER_PRINT_PDF_FAILURE = "clicked_audience_forecaster_print_pdf_failure",

  CLICKED_AUDIENCE_FORECASTER_SAVE_BUTTON = "clicked_audience_forecaster_save_button",
  CHANGED_AUDIENCE_FORECASTER_DAILY_FREQUENCY = "changed_audience_forecaster_daily_frequency",
  CHANGED_AUDIENCE_FORECASTER_HOURLY_FREQUENCY = "changed_audience_forecaster_hourly_frequency",
  TOGGLED_AUDIENCE_FORECASTER_UNCAPPED_FREQUENCY = "toggled_audience_forecaster_uncapped_frequency",
  SELECTED_AUDIENCE_FORECASTER_CUSTOM_SEGMENTS_AGE = "selected_audience_forecaster_custom_segments_age",
  SELECTED_AUDIENCE_FORECASTER_CUSTOM_SEGMENTS_GENDER = "selected_audience_forecaster_custom_segments_gender",
  SELECTED_AUDIENCE_FORECASTER_CUSTOM_SEGMENTS_INCOME = "selected_audience_forecaster_custom_segments_income",
  SELECTED_AUDIENCE_FORECASTER_CUSTOM_SEGMENTS_EDUCATION = "selected_audience_forecaster_custom_segments_education",

  SELECTED_AUDIENCE_FORECASTER_DMA = "selected_audience_forecaster_dma",
  ADDED_AUDIENCE_FORECASTER_ZIPCODES = "added_audience_forecaster_zipcodes",
  CLICKED_AUDIENCE_FORECASTER_EXPORT_CSV = "clicked_audience_forecaster_export_csv",
  CLICKED_AUDIENCE_FORECASTER_ZIP_RANGE_FINDER_ADD_ZIPS_BUTTON = "clicked_audience_forecaster_add_to_audience_forecaster_button",
  SELECTED_AUDIENCE_FORECASTER_WHITELIST = "selected_audience_forecaster_whitelist",
  CLICKED_AUDIENCE_FORECASTER_UPLOAD_CSV = "clicked_audience_forecaster_upload_csv"
}
export enum CreativeLibraryEvents {
  CLICKED_CREATIVE_ITEM = "clicked_creative_item",
  CLICKED_ADD_CREATIVE_MAIN_VIEW = "clicked_add_creative_main_view",
  CLICKED_EDIT_CREATIVE_TABLE_VIEW = "clicked_edit_creative_table_view",

  CLICKED_UNARCHIVE_BUTTON = "clicked_unarchive_button",

  CLICKED_EDIT_CREATIVE_INDIVIDUAL_VIEW = "clicked_edit_creative_individual_view",
  CLICKED_CREATIVE_NAME_FIELD = "clicked_creative_name_field",
  CLICKED_CREATIVE_CLICK_THROUGH_FIELD = "clicked_creative_click_through_field",
  CLICKED_CREATIVE_ISCI_FIELD = "clicked_creative_isci_field",
  SELECTED_CREATIVE_ADVERTISER = "selected_creative_advertiser",
  CLICKED_CREATIVE_SUBMIT_DETAILS_BUTTON = "clicked_creative_submit_details",
  SELECTED_CREATIVE_TRACKING_PIXEL_TYPE = "selected_creative_tracking_pixel_type",
  CLICKED_CREATIVE_TRACKING_PIXEL_FIELD = "clicked_creative_tracking_pixel_field",
  CLICKED_CREATIVE_ADD_NEW_TRACKING_BUTTON = "clicked_creative_add_new_tracking_button",
  CLICKED_CREATIVE_EXPORT_CSV = "clicked_creative_export_csv",
  CLICKED_CREATIVE_SAVE_BUTTON = "clicked_creative_save_button",
  CLICKED_CREATIVE_CANCEL_UPLOAD = "clicked_creative_cancel_button",
  CLICKED_CREATIVE_HOSTED_BY_MADHIVE_BUTTON = "clicked_creative_hosted_by_madhive_button",
  CLICKED_CREATIVE_UPLOAD_FROM_DESKTOP_BUTTON = "clicked_creative_upload_from_desktop_button",
  CLICKED_CREATIVE_UPLOAD_FROM_CDN_BUTTON = "clicked_creative_upload_from_cdn_button",
  CLICKED_CREATIVE_CDN_FIELD = "clicked_creative_cdn_field",
  CLICKED_CREATIVE_SUBMIT_CDN = "clicked_creative_submit_cdn",
  CLICKED_CREATIVE_HOSTED_BY_THIRD_PARTY_BUTTON = "clicked_creative_hosted_by_third_party_button",
  CLICKED_CREATIVE_VAST_FIELD = "clicked_creative_vast_field",
  CLICKED_CREATIVE_VALIDATE_VAST_BUTTON = "clicked_creative_validate_vast_button",
  CLICKED_CREATIVE_OPEN_XML_BUTTON = "clicked_creative_open_xml_button",
  CLICKED_CREATIVE_OPEN_URL_BUTTON = "clicked_creative_open_url_button",
  CLICKED_CREATIVES_DOWNLOAD_CSV = "clicked_creatives_download_csv",
  FAILED_TO_OPEN_XML = "failed_to_open_xml",
  FAILED_TO_SAVE_CREATIVE = "failed_to_save_creative",
  CLICKED_MACROS_CHECKBOX = "clicked_macros_checkbox",
  CLICKED_CREATIVE_PROCEED_ANYWAY_BUTTON = "clicked_creative_proceed_anyway_button",
  CLICKED_CHOOSE_A_DIFFERENT_FILE = "clicked_choose_a_different_file",
  CLICKED_MADHIVE_STANDARDS_TAB = "clicked_madhive_standards_tab",
  CLICKED_PREMION_STANDARDS_TAB = "clicked_premion_standards_tab",
  SELECTED_CREATIVE_FILTER_ADVERTISER = "selected_creative_filter_advertiser",
  SELECTED_DURATION_OPERATOR = "selected_duration_operator",
  SELECTED_DURATION_SECONDS_OPERATOR = "selected_duration_seconds_operator",
  SELECTED_DURATION_SECONDS_SECONDARY_OPERATOR = "selected_duration_seconds_secondary_operator"
}

export enum ManageCampaignsEvents {
  /**
   * Campaign Events
   *
   */

  CLICK_CAMPAIGN_ITEM = "click_campaign_item",
  CLICKED_ARCHIVE_CAMPAIGN_CAMPAIGN_SCREEN = "clicked_archive_campaign_campaign_screen",
  CLICKED_ARCHIVE_CAMPAIGN_TABLE_VIEW = "clicked_archive_campaign_table_view",
  CLICKED_ASSOCIATED_CAMPAIGN = "clicked_associated_campaign",
  CLICKED_BREAD_CRUMB = "clicked_bread_crumb",
  CLICKED_CAMPAIGNS_TAB = "clicked_campaigns_tab_manage_campaigns_table",
  CLICKED_CANCEL_CAMPAIGN_SCREEN = "clicked_cancel_campaign_screen",
  CLICKED_CONFIRM_ADJUST_DATES = "clicked_confirm_adjust_dates",
  CLICKED_CONFIRM_MANAGE_PREV_CREATIVES = "clicked_confirm_prev_creatives",
  CLICKED_CONFIRM_SELECTION_DETAILS = "clicked_confirm_selection_details",
  CLICKED_DUPLICATE_CAMPAIGN_TABLE_VIEW = "clicked_duplicate_campaign_table_view",
  CLICKED_EDIT_CAMPAIGN_BRAND_FIELD = "clicked_edit_campaign_brand_field",
  CLICKED_EDIT_CAMPAIGN_CAMPAIGN_SCREEN = "clicked_edit_campaign_campaign_screen",
  CLICKED_EDIT_CAMPAIGN_CLIENT_CODE_FIELD = "clicked_edit_campaign_client_code_field",
  CLICKED_EDIT_CAMPAIGN_ESTIMATE_FIELD = "clicked_edit_campaign_estimate_field",
  CLICKED_EDIT_CAMPAIGN_ESTIMATE_CODE_FIELD = "clicked_edit_campaign_estimate_code_field",
  CLICKED_EDIT_CAMPAIGN_EXTERNAL_ID_FIELD = "clicked_edit_campaign_external_id_field",
  CLICKED_EDIT_CAMPAIGN_NAME_FIELD = "clicked_edit_campaign_name_field",
  CLICKED_EDIT_CAMPAIGN_OMS_FIELD = "clicked_edit_campaign_oms_field",
  CLICKED_EDIT_CAMPAIGN_PRODUCT_CODE_FIELD = "clicked_edit_campaign_product_code_field",
  CLICKED_EDIT_CAMPAIGN_RFP_DETAIL_NAME_FIELD = "clicked_edit_campaign_rfp_detail_name_field",
  CLICKED_EDIT_CAMPAIGN_RFP_NAME_FIELD = "clicked_edit_campaign_rfp_name_field",
  CLICKED_EDIT_CAMPAIGN_SALESFORCE_IO_ID_FIELD = "clicked_edit_campaign_salesforce_io_id_field",
  CLICKED_EDIT_CAMPAIGN_TABLE_VIEW = "clicked_edit_campaign_table_view",
  CLICKED_EXPORT_DATA_MANAGE_CAMPAIGNS = "clicked_export_data_manage_campaigns",
  CLICKED_EXPORT_QA_REPORT = "clicked_export_qa_report",
  CLICKED_EXPORT_TABLE_DATA = "clicked_export_table_data",
  CLICKED_NEW_CAMPAIGN_BRAND_FIELD = "clicked_new_campaign_brand_field",
  CLICKED_NEW_CAMPAIGN_CLIENT_CODE_FIELD = "clicked_new_campaign_client_code_field",
  CLICKED_NEW_CAMPAIGN_ESTIMATE_FIELD = "clicked_new_campaign_estimate_field",
  CLICKED_NEW_CAMPAIGN_ESTIMATE_CODE_FIELD = "clicked_new_campaign_estimate_code_field",
  CLICKED_NEW_CAMPAIGN_EXTERNAL_ID_FIELD = "clicked_new_campaign_external_id_field",
  CLICKED_NEW_CAMPAIGN_NAME_FIELD = "clicked_new_campaign_name_field",
  CLICKED_NEW_CAMPAIGN_OMS_FIELD = "clicked_new_campaign_oms_field",
  CLICKED_NEW_CAMPAIGN_PRODUCT_CODE_FIELD = "clicked_new_campaign_product_code_field",
  CLICKED_NEW_CAMPAIGN_RFP_DETAIL_NAME_FIELD = "clicked_new_campaign_rfp_detail_name_field",
  CLICKED_NEW_CAMPAIGN_RFP_NAME_FIELD = "clicked_new_campaign_rfp_name_field",
  CLICKED_NEW_CAMPAIGN_SALESFORCE_IO_ID_FIELD = "clicked_new_campaign_salesforce_io_id_field",
  CLICKED_REFETCH_DATA_CAMPAIGN_SCREEN = "clicked_refetch_data_campaign_screen",
  CLICKED_RESET_END_DATE = "clicked_campaign_reset_end_date",
  CLICKED_RESET_END_DATE_CAMPAIGN_SCREEN = "clicked_reset_end_date_campaign_screen",
  CLICKED_RESET_START_DATE_CAMPAIGN_SCREEN = "clicked_reset_start_date_campaign_screen",
  CLICKED_SAVE_CAMPAIGN_SCREEN = "clicked_save_campaign_screen",
  CREATED_NEW_CAMPAIGN_ADVERTISER = "created_new_campaign_advertiser",
  CREATED_NEW_CAMPAIGN_AGENCY = "created_new_campaign_agency",
  CREATED_NEW_CAMPAIGN_STATION = "created_new_campaign_station",
  SEARCHED_CAMPAIGN = "searched_campaign",
  SELECTED_CAMPAIGN_ADBOOK_MARKET = "selected_campaign_adbook_market",
  SELECTED_CAMPAIGN_ADVERTISER = "selected_campaign_advertiser",
  SELECTED_CAMPAIGN_AGENCY = "selected_campaign_agency",
  SELECTED_CAMPAIGN_END_DATE = "selected_campaign_end_date",
  SELECTED_CAMPAIGN_HOLDING_COMPANY = "selected_campaign_holding_company",
  SELECTED_CAMPAIGN_IAB_CATEGORY = "selected_campaign_iab_category",
  SELECTED_CAMPAIGN_REVENUE_TYPE = "selected_campaign_revenue_type",
  SELECTED_CAMPAIGN_START_DATE = "selected_campaign_start_date",
  SELECTED_CAMPAIGN_STATION = "selected_campaign_station",
  SELECTED_NEW_CAMPAIGN_ADBOOK_MARKET = "selected_new_campaign_adbook_market",
  SELECTED_NEW_CAMPAIGN_ADVERTISER = "selected_new_campaign_advertiser",
  SELECTED_NEW_CAMPAIGN_AGENCY = "selected_new_campaign_agency",
  SELECTED_NEW_CAMPAIGN_HOLDING_COMPANY = "selected_new_campaign_holding_company",
  SELECTED_NEW_CAMPAIGN_IAB_CATEGORY = "selected_new_campaign_iab_category",
  SELECTED_NEW_CAMPAIGN_REVENUE_TYPE = "selected_new_campaign_revenue_type",
  SELECTED_NEW_CAMPAIGN_STATION = "selected_new_campaign_station",
  SET_NEW_CAMPAIGN_END_DATE = "set_new_campaign_end_date",
  SET_NEW_CAMPAIGN_START_DATE = "set_new_campaign_start_date",
  TOGGLED_CAMPAIGN_SET_LIVE = "toggled_campaign_set_live",

  /**
   *
   * Line Items events
   */
  CLICKED_LINE_ITEMS_TAB = "clicked_line_items_tab_manage_campaigns_table",
  SEARCHED_LINE_ITEM = "searched_line_item",
  CLICKED_NEW_LINE_ITEM_NAME_FIELD = "clicked_new_line_item_name_field",
  CLICKED_NEW_LINE_ITEM_BOOKED_IMPS_FIELD = "clicked_new_line_item_booked_imp_field",
  CLICKED_EDIT_LINE_ITEM_BOOKED_IMPS_FIELD = "clicked_edit_line_item_booked_imps_field",
  CLICKED_NEW_LINE_ITEM_BUDGET_FIELD = "clicked_new_line_item_budget_field",
  CLICKED_EDIT_LINE_ITEM_BUDGET_FIELD = "clicked_edit_line_item_budget_field",
  CLICKED_NEW_LINE_ITEM_ECPM_FIELD = "clicked_new_line_item_ecpm_field",
  CLICKED_EDIT_LINE_ITEM_ECPM_FIELD = "clicked_edit_line_item_ecpm_field",
  CLICKED_NEW_LINE_ITEM_START_DATE_FIELD = "clicked_new_line_item_start_date_field",
  CLICKED_EDIT_LINE_ITEM_START_DATE_FIELD = "clicked_edit_line_item_start_date_field",
  CLICKED_NEW_LINE_ITEM_START_ASAP_FIELD = "clicked_new_line_item_start_asap_field",
  CLICKED_EDIT_LINE_ITEM_START_ASAP_FIELD = "clicked_edit_line_item_start_asap_field",
  CLICKED_NEW_LINE_ITEM_ASSOCIATED_SCENARIO_FIELD = "clicked_new_line_item_associated_scenario_field",
  CLICKED_EDIT_LINE_ITEM_ASSOCIATED_SCENARIO_FIELD = "clicked_edit_line_item_associated_scenario_field",
  CLICKED_NEW_LINE_ITEM_DSP_SELECT_FIELD = "clicked_new_line_item_dsp_select_field",
  CLICKED_EDIT_LINE_ITEM_DSP_SELECT_FIELD = "clicked_edit_line_item_dsp_select_field",
  CLICKED_NEW_LINE_ITEM_PACE_TO_FIELD = "clicked_new_line_item_pace_to_field",
  CLICKED_EDIT_LINE_ITEM_PACE_TO_FIELD = "clicked_edit_line_item_pace_to_field",
  CLICKED_NEW_LINE_ITEM_PACING_PER_FIELD = "clicked_new_line_item_pacing_per_field",
  CLICKED_EDIT_LINE_ITEM_PACING_PER_FIELD = "clicked_edit_line_item_pacing_per_field",
  CLICKED_NEW_LINE_ITEM_COPY_TPA_URL_BUTTON = "clicked_new_line_item_copy_tpa_url_button",
  CLICKED_EDIT_LINE_ITEM_COPY_TPA_URL_BUTTON = "clicked_edit_line_item_copy_tpa_url_button",
  CLICKED_NEW_LINE_ITEM_GENERATE_TPA_TOGGLE = "clicked_new_line_item_generate_tpa_toggle",
  CLICKED_EDIT_LINE_ITEM_GENERATE_TPA_TOGGLE = "clicked_edit_line_item_generate_tpa_toggle",
  CLICKED_NEW_LINE_ITEM_END_DATE_FIELD = "clicked_new_line_item_end_date_field",
  CLICKED_EDIT_LINE_ITEM_END_DATE_FIELD = "clicked_edit_line_item_end_date_field",
  CLICKED_NEW_LINE_ITEM_EXTERNAL_ID_FIELD = "clicked_new_line_item_external_id_field",
  CLICKED_EDIT_LINE_ITEM_EXTERNAL_ID_FIELD = "clicked_edit_line_item_external_id_field",
  CLICKED_NEW_LINE_ITEM_WIDE_ORBIT_LINE_NUMBER_FIELD = "clicked_new_line_item_wide_orbit_line_number_field",
  CLICKED_EDIT_LINE_ITEM_WIDE_ORBIT_LINE_NUMBER_FIELD = "clicked_edit_line_item_wide_orbit_line_number_field",
  CLICKED_NEW_LINE_ITEM_ACTUALIZED_IMPRESSIONS_FIELD = "clicked_new_line_item_actualized_impressions_field",
  CLICKED_EDIT_LINE_ITEM_ACTUALIZED_IMPRESSIONS_FIELD = "clicked_edit_line_item_actualized_impressions_field",
  CLICKED_NEW_LINE_ITEM_ASSOCIATED_CAMPAIGN = "clicked_new_line_item_associated_campaign",
  CLICKED_EDIT_LINE_ITEM_ASSOCIATED_CAMPAIGN = "clicked_new_line_item_associated_campaign",
  CLICKED_SAVE_LINE_ITEM = "clicked_save_line_item",
  CLICKED_DOWNLOAD_LINE_ITEMS = "clicked_download_line_Items",
  CLICKED_ADD_LINE_ITEM_TABLE_VIEW = "clicked_add_line_item_campaign_table_view",
  CLICKED_ADD_LINE_ITEM_CAMPAIGN_SCREEN = "clicked_add_line_item_campaign_screen",
  CLICKED_EDIT_LINE_ITEM_START_DATE = "edit_line_item_start_date",
  CLICKED_EDIT_LINE_ITEM_END_DATE = "edit_line_item_end_date",
  SELECTED_PRODUCT_LINE_ITEM_SCREEN = "selected_product_line_item_screen",
  SELECTED_LINE_ITEM_FREQUENCY_CAP = "edit_line_items_frequency_cap",
  SELECTED_LINE_ITEM_DAYPART = "selected_line_item_daypart",
  SELECTED_LINE_ITEM_DAYPART_OPERATOR = "selected_line_item_daypart_operator",
  SELECTED_EDIT_LINE_ITEM_START_DATE_CAMPAIGN_TABLE_VIEW = "selected_line_item_start_date_campaign_table_view",
  SELECTED_EDIT_LINE_ITEM_END_DATE_CAMPAIGN_TABLE_VIEW = "selected_line_item_end_date_campaign_table_view",
  CLICKED_LINE_ITEM_BOOKS_IMPS_CAMPAIGN_TABLE = "clicked_line_item_booked_imps_campaign_table_view",
  CLICKED_LINE_ITEM_BUDGET_CAMPAIGN_TABLE = "clicked_line_item_budget_campaign_table_view",
  CLICKED_LINE_ITEM = "clicked_line_item",
  CLICKED_LINE_ITEM_LINE_ITEM_TABLE = "clicked_line_item_line_item_table",
  CLICKED_ASSOCIATED_CAMPAIGN_LINE_ITEM_TABLE = "clicked_associated_campaign_line_item_table",
  CLICKED_EDIT_LINE_ITEM_TABLE = "clicked_edit_line_item_table",
  CLICKED_DUPLICATE_LINE_ITEM_TABLE = "clicked_duplicate_line_item_table",
  CLICKED_ARCHIVE_LINE_ITEM_TABLE = "clicked_archive_line_item_table",
  CLICKED_ARCHIVE_LINE_ITEM = "clicked_archive_line_Item",
  CLICKED_REFETCH_DATA_LINE_ITEM_SCREEN = "clicked_refetch_data_line_item_screen",
  CLICKED_EDIT_LINE_ITEM = "clicked_edit_line_Item",
  CLICKED_CANCEL_LINE_ITEM = "clicked_cancel_line_Item",
  CLICKED_ADD_LINE_ITEM = "clicked_add_line_item",
  CLICKED_EDIT_LINE_ITEM_NAME_FIELD = "clicked_edit_line_item_name_field",
  CLICKED_RESET_LINE_ITEM_START_DATE = "clicked_reset_line_item_start_date",
  CLICKED_RESET_LINE_ITEM_END_DATE = "clicked_reset_line_item_end_date",
  CLICKED_DAYPART_SCHEDULING_TOGGLE = "clicked_daypart_scheduling_toggle",
  SET_NEW_LINE_ITEM_START_DATE = "set_new_line_item_start_date",
  SET_NEW_LINE_ITEM_END_DATE = "set_new_line_item_end_date",
  SET_NEW_LINE_ITEM_DAYPART = "set_new_line_item_day_part",
  SET_NEW_LINE_ITEM_DAYPART_OPERATOR = "set_new_line_item_day_part_operator",
  SELECTED_NEW_LINE_ITEM_PRODUCT = "selected_new_line_item_product",
  SET_NEW_LINE_ITEM_FREQUENCY_CAP = "set_new_line_item_frequency_cap",
  TOGGLED_LINE_ITEM_SET_LIVE = "toggled_line_item_set_live",

  /**
   * Targetng Events
   *
   */

  CLICKED_SELECT_ZIPS = "clicked_select_zips",
  CLICKED_SELECT_DMAS = "clicked_select_dmas",
  CLICKED_SELECT_STATES = "clicked_select_states",
  CLICKED_SELECT_WHITELIST = "clicked_select_whitelist",
  CLICKED_CONNECTED_TV_PERCENT_FIELD = "clicked_connected_tv_percent_field",
  CLICKED_MOBILE_PERCENT_FIELD = "clicked_mobile_percent_field",
  CLICKED_DESKTOP_PERCENT = "clicked_desktop_percent",
  CLICKED_TABLET_PERCENT = "clicked_tablet_percent",
  SELECTED_DEMOGRAPHIC_SEGMENT = "selected_demographic_segment",
  SELECTED_CONSUMER_SEGMENT = "selected_consumer_segment",
  SELECTED_CUSTOM_SEGMENT = "selected_custom_segment",

  /**
   * Bulk Edit Events
   *
   */
  CLICKED_BULK_EDIT_TARGETING_BUTTON = "clicked_bulk_edit_targeting_button",
  CLICKED_BULK_ASSIGN_CREATIVES_BUTTON = "clicked_bulk_assign_creatives_button",
  CLICKED_BULK_ASSIGN_DOWNLOAD_TEMPLATE = "clicked_bulk_assign_download_template",
  CLICKED_BULK_ASSIGN_UPLOAD_TEMPLATE = "clicked_bulk_assign_upload_template",
  SELECTED_SWAP_CREATIVES = "selected_swap_creatives",
  SELECTED_ADD_TO_ROTATION = "selected_add_to_rotation",
  SELECTED_INHERIT_FROM_LINE_ITEMS = "selected_inherit_from_line_items",
  SELECTED_SCHEDULE_CREATIVES = "selected_schedule_creatives",
  CLICKED_SUBMIT_BULK_ASSIGN = "clicked_submit_bulk_assign",
  SUBMITTED_BULK_ASSIGN_CREATIVES = "submitted_bulk_assign_creatives",
  FAILED_TO_BULK_ASSIGN_CREATIVES = "failed_to_bulk_assign_creatives",
  CLICKED_FILTER_BY_ADVERTISER_DIALOG = "clicked_filter_by_advertiser_dialog",
  CLICKED_CANCEL_BULK_EDIT = "clicked_cancel_bulk_edit",
  CLICKED_SAVE_BULK_EDIT = "clicked_save_bulk_edit",
  CLICKED_BULK_EDIT_CAMPAIGNS_TABLE = "clicked_bulk_edit_campaigns_table",
  TOGGLED_CAMPAIGN_SET_LIVE_BULK_EDIT = "toggled_campaign_set_live_bulk_edit",

  /**
   * Creative Events
   *
   */
  CLICKED_EXPORT_CREATIVE_DELIVERY_REPORT = "clicked_export_creatives_delivery_report",
  CLICKED_ATTACH_CREATIVE_LINE_ITEM_TABLE = "clicked_attach_creative_line_item_table",
  CLICKED_UPLOAD_CREATIVE_CREATIVES_PANEL_DRAWER = "clicked_upload_creative_creatives_panel_drawer",
  CLICKED_ATTACH_CREATIVES_CREATIVES_PANEL_DRAWER = "clicked_attach_creatives_creatives_panel_drawer",
  CLICKED_ATTACH_CREATIVE_FROM_THE_LIBRARY_NO_CREATIVES = "clicked_attach_creative_from_the_library_no_creatives",
  CLICKED_UPLOAD_CREATIVE_CREATIVES_PANEL = "clicked_upload_creative_creatives_panel",
  CLICKED_ATTACH_CREATIVE_FROM_LIBRARY = "clicked_attach_creative_from_library",
  CLICKED_LINE_ITEM_CREATIVE_WEIGHT_FIELD = "clicked_line_item_creative_weight_field",
  CLICKED_LINE_ITEM_CREATIVE_CLICK_THRU_FIELD = "clicked_line_item_creative_click_thru_field",
  CLICKED_LINE_ITEM_CREATIVE_ADD_TRACKING_PIXELS = "clicked_line_item_creative_add_tracking_pixels",
  CLICKED_DUPLICATE_CREATIVE = "clicked_duplicate_creative",
  CLICKED_ARCHIVE_CREATIVE = "clicked_archive_creative",
  CLICKED_EDIT_CREATIVE_START_DATE = "clicked_edit_creative_start_date",
  CLICKED_EDIT_CREATIVE_END_DATE = "clicked_edit_creative_start_date",
  CLICKED_CANCEL_THIRD_PARTY_TRACKING_CREATIVES_PANEL_SCREEN = "clicked_cancel_third_party_tracking_creatives_panel_screen",
  CLICKED_SAVE_THIRD_PARTY_TRACKING_CREATIVES_PANEL_SCREEN = "clicked_save_third_party_tracking_creatives_panel_screen",
  CLICKED_ADD_NEW_TRACKING = "clicked_add_new_tracking",
  CLICKED_TRACKING_PIXELS_FIELD_LINE_ITEMS_SCREEN = "clicked_tracking_pixels_field_line_items_screen",
  CLICKED_ADD_FLIGHT = "clicked_add_flight",
  CLICKED_DELETE_GROUPED_CREATIVE = "clicked_delete_grouped_creative",

  /**
   * Failure events
   *
   */

  FAILED_TO_SAVE_BULK_EDIT = "failed_to_save_bulk_edit",
  FAILED_TO_ARCHIVE_LINE_ITEM_TABLE = "failed_to_archive_line_item_table",
  FAILED_TO_UNARCHIVE_LINE_ITEM_TABLE = "failed_to_unarchive_line_item_table",
  FAILED_TO_ARCHIVE_CAMPAIGN_TABLE_VIEW = "failed_to_archive_campaign_table_view",
  FAILED_TO_SAVE_LINE_ITEM = "failed_to_save_line_item",
  FAILED_TO_ARCHIVE_LINE_ITEM = "failed_to_archive_line_item",
  FAILED_TO_SAVE_CAMPAIGN_SCREEN = "failed_to_save_campaign_screen",
  FAILED_TO_ARCHIVE_CAMPAIGN_CAMPAIGN_SCREEN = "failed_to_archive_campaign_campaign_screen",
  FAILED_TO_SAVE_CAMPAIGN = "failed_to_save_campaign",
  FAILED_TO_CREATE_NEW_CAMPAIGN_AGENCY = "failed_to_create_new_campaign_agency",
  FAILED_TO_CREATE_NEW_CAMPAIGN_ADVERTISESR = "failed_to_create_new_campaign_advertiser",
  FAILED_TO_CREATE_NEW_CAMPAIGN_STATION = "failed_to_create_new_campaign_station"
}

export enum FilterEvents {
  SELECTED_ADBOOK_CLIENT = "selected_adbook_client",
  SELECTED_ADBOOK_MARKET = "selected_adbook_market",
  SELECTED_ADBOOK_STATUS = "selected_adbook_status",
  CLICKED_STATUS_FIELD = "clicked_status_filter",
  SELECTED_CLIENT_CODE = "selected_client_code",
  SELECTED_ESTIMATE_CODE = "selected_estimate_code",
  SELECTED_REVENUE_TYPE = "selected_revenue_type",
  SELECTED_RFP_DETAIL_NAME = "selected_rfp_detail_name",
  SELECTED_RFP_NAME = "selected_rfp_name",
  SELECTED_STATION = "selected_station",
  SELECTED_AGENCY = "selected_agency",
  SELECTED_ADVERTISER = "selected_advertiser",
  SELECTED_STATUS = "selected_status",
  SELECTED_CAMPAIGN_NAME = "selected_campaign_name",
  SELECTED_PRODUCT_CODE = "selected_product_code"
}

export enum ManageCampaignsFilter {
  NAME_FILTER_INPUT = "manage-campaigns-campaign-name-filter-input",
  STATION_FILTER_INPUT = "manage-campaigns-station-filter-input",
  AGENCY_FILTER_INPUT = "manage-campaigns-agency-filter-input",
  ADVERTISER_FILTER_INPUT = "campaign-management-advertiser-filter-input",
  ADBOOK_CLIENT_FILTER_INPUT = "manage-campaigns-adbook-client-filter-input",
  ADBOOK_MARKET_FILTER_INPUT = "manage-campaigns-adbook-market-filter-input",
  ADBOOK_STATUS_FILTER_INPUT = "manage-campaigns-adbook-status-filter-input",
  CLIENT_CODE_FILTER_INPUT = "manage-campaigns-client-code-filter-input",
  REVENUE_TYPE_FILTER_INPUT = "manage-campaigns-revenue-type-filter-input",
  RFP_DETAIL_NAME_INPUT = "manage-campaigns-rfp-details-name-filter-input",
  RFP_NAME_INPUT = "manage-campaigns-rfp-name-filter-input",
  PRODUCT_CODE_INPUT = "manage-campaigns-product-code-filter-input",
  ESTIMATE_CODE_INPUT = "manage-campaigns-estimate-code-filter-input"
}

export enum CreativeLibraryFilter {
  ADVERTISER_FILTER_INPUT = "creatives-advertiser-filter-input",
  DURATION_OPERATOR_INPUT = "duration-operator-filter-input",
  DURATION_SECONDS_OPERATOR_INPUT = "duration-seconds-filter-input",
  DURATION_SECONDS_OPERATOR_SECONDARY = "duration-seconds-filter-input-secondary"
}

export enum ManageCampaignsSegments {
  DEMOGRAPHIC_SEGMENT_INPUT = "demographic-segments-multiselect",
  CONSUMER_SEGMENT_INPUT = "consumer-segments-multiselect",
  CUSTOM_SEGMENT_INPUT = "custom-segments-multiselect"
}

export const SegmentIdToEvent = {
  [ManageCampaignsSegments.DEMOGRAPHIC_SEGMENT_INPUT]:
    ManageCampaignsEvents.SELECTED_DEMOGRAPHIC_SEGMENT,
  [ManageCampaignsSegments.CONSUMER_SEGMENT_INPUT]:
    ManageCampaignsEvents.SELECTED_CONSUMER_SEGMENT,
  [ManageCampaignsSegments.CUSTOM_SEGMENT_INPUT]:
    ManageCampaignsEvents.SELECTED_CUSTOM_SEGMENT
};

export const FilterIdToEvent = {
  [CreativeLibraryFilter.DURATION_OPERATOR_INPUT]:
    CreativeLibraryEvents.SELECTED_DURATION_OPERATOR,

  [CreativeLibraryFilter.DURATION_SECONDS_OPERATOR_INPUT]:
    CreativeLibraryEvents.SELECTED_DURATION_SECONDS_OPERATOR,

  [CreativeLibraryFilter.DURATION_SECONDS_OPERATOR_SECONDARY]:
    CreativeLibraryEvents.SELECTED_DURATION_SECONDS_SECONDARY_OPERATOR,

  [CreativeLibraryFilter.ADVERTISER_FILTER_INPUT]:
    CreativeLibraryEvents.SELECTED_CREATIVE_FILTER_ADVERTISER,
  [ManageCampaignsFilter.NAME_FILTER_INPUT]:
    FilterEvents.SELECTED_CAMPAIGN_NAME,
  [ManageCampaignsFilter.STATION_FILTER_INPUT]: FilterEvents.SELECTED_STATION,
  [ManageCampaignsFilter.AGENCY_FILTER_INPUT]: FilterEvents.SELECTED_AGENCY,
  [ManageCampaignsFilter.ADVERTISER_FILTER_INPUT]:
    FilterEvents.SELECTED_ADVERTISER,

  [ManageCampaignsFilter.ADBOOK_CLIENT_FILTER_INPUT]:
    FilterEvents.SELECTED_ADBOOK_CLIENT,
  [ManageCampaignsFilter.ADBOOK_MARKET_FILTER_INPUT]:
    FilterEvents.SELECTED_ADBOOK_MARKET,

  [ManageCampaignsFilter.ADBOOK_STATUS_FILTER_INPUT]:
    FilterEvents.SELECTED_ADBOOK_STATUS,

  [ManageCampaignsFilter.CLIENT_CODE_FILTER_INPUT]:
    FilterEvents.SELECTED_CLIENT_CODE,

  [ManageCampaignsFilter.REVENUE_TYPE_FILTER_INPUT]:
    FilterEvents.SELECTED_REVENUE_TYPE,

  [ManageCampaignsFilter.RFP_DETAIL_NAME_INPUT]:
    FilterEvents.SELECTED_RFP_DETAIL_NAME,
  [ManageCampaignsFilter.RFP_NAME_INPUT]: FilterEvents.SELECTED_RFP_NAME,

  [ManageCampaignsFilter.ESTIMATE_CODE_INPUT]:
    FilterEvents.SELECTED_ESTIMATE_CODE,

  [ManageCampaignsFilter.PRODUCT_CODE_INPUT]: FilterEvents.SELECTED_PRODUCT_CODE
};

export enum PacingEvents {
  CLICKED_EXPORT_TABLE_DATA = "clicked_export_table_data",
  CLICKED_EXPORT_QA_REPORT = "clicked_export_qa_report",
  CLICKED_EXPORT_CREATIVE_DELIVERY_REPORT = "clicked_export_creatives_delivery_report"
}

import { getDetailedErrorMessage } from "../../utils/validation";
export class ProductsFetchFailed extends Error {
    constructor(apiError, fallbackMessage = "Failed to retrieve products. Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ProductsFetchFailed);
        }
        this.name = "ProductsFetchFailed";
        this.message = getDetailedErrorMessage(apiError, fallbackMessage);
    }
}
export class ProductIdCreationFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ProductIdCreationFailed);
        }
        this.name = "ProductIdCreationFailed";
        this.message = "Failed to create an ID for the new product.";
    }
}
export class ProductSaveFailed extends Error {
    constructor(apiError, fallbackMessage = "Failed to save product. Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ProductSaveFailed);
        }
        this.name = "ProductSaveFailed";
        this.message = getDetailedErrorMessage(apiError, fallbackMessage);
    }
}
export class ProductDeletionFailed extends Error {
    constructor(apiError, fallbackMessage = "Failed to delete product. Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ProductDeletionFailed);
        }
        this.name = "ProductDeletionFailed";
        this.message = getDetailedErrorMessage(apiError, fallbackMessage);
    }
}

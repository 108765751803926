import { FC } from "react";
import { v4 as uuid } from "uuid";
import { CommonIconProps } from "../Icon/types";

const clipId = `kit-${uuid()}`;

const Clock: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, transparent)"
}) => {
  return (
    <svg
      className="icon-clock"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
    >
      <g clipPath={`url(#${clipId})`}>
        <path
          d="M23.5 12C23.5 18.3513 18.3513 23.5 12 23.5C5.64873 23.5 0.5 18.3513 0.5 12C0.5 5.64873 5.64873 0.5 12 0.5C18.3513 0.5 23.5 5.64873 23.5 12Z"
          stroke={stroke}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.8 11.672V7.2C12.8 6.76 12.44 6.4 12 6.4C11.56 6.4 11.2 6.76 11.2 7.2V12C11.2 12.224 11.288 12.424 11.432 12.568L13.832 14.968C13.976 15.112 14.176 15.2 14.4 15.2C14.84 15.2 15.2 14.84 15.2 14.4C15.2 14.176 15.112 13.976 14.968 13.832L12.8 11.672ZM12 4C7.584 4 4 7.584 4 12C4 16.416 7.584 20 12 20C16.416 20 20 16.416 20 12C20 7.584 16.416 4 12 4ZM12 18.4C8.464 18.4 5.6 15.536 5.6 12C5.6 8.464 8.464 5.6 12 5.6C15.536 5.6 18.4 8.464 18.4 12C18.4 15.536 15.536 18.4 12 18.4Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id={clipId}>
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Clock;

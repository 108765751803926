import classNames from "classnames";
import MadhiveFooterLogo from "static/branding/logos/madhive-new-footer";
import { FC } from "react";
import "./Footer.scss";
import { COPYRIGHT_TEXT } from "./Footer";

export const SimpleFooter: FC = () => (
  <footer className="SmithersFooter">
    <div
      className="SmithersFooter__Content"
      style={{ flexDirection: "column" }}
    >
      <p
        className={classNames(
          "SmithersFooter__Description",
          "SmithersFooter__Powered--By--Text"
        )}
      >
        Powered By
      </p>
      <MadhiveFooterLogo />
      <p className="SmithersFooter__Simple--CopyRight">{COPYRIGHT_TEXT}</p>
    </div>
  </footer>
);

export default SimpleFooter;

import axios from "axios";
import { Handler } from "../handlers";
import { NotImplementedError, MadSDKAbortError } from "../../errors";
import { serviceInscapeStatusToInscapeStatus, InscapeFilterFields } from "../../models/creatives/inscape";
import { parseNewNewYorkApiResponse } from "../../utils";
/**
 * This is a unique handler because it doesn't follow the standard
 * Type -> Service Type like most handlers. To make the interface the same
 * we still extend handler but need to override the types in findItem to
 * return 1 type and the filters to be based on a different type. To do this
 * we have to leave the Handler generics as "any"
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
class Inscape extends Handler {
    constructor(sdk) {
        super(sdk, "inscape");
        this.toInscapeStatus = (status) => {
            return serviceInscapeStatusToInscapeStatus(status);
        };
    }
    async findItems(filters) {
        const lookupId = filters?.where?.find((filter) => filter.field === InscapeFilterFields.CREATIVE_ID)?.value ||
            filters?.where?.find((filter) => filter.field === InscapeFilterFields.ORG_ID)?.value;
        if (!lookupId) {
            return [];
        }
        const url = `${this.sdk.urls.inscapeBaseUrl}/${lookupId}`;
        if (this.cache.has(url)) {
            const promise = this.cache.get(url);
            if (!promise) {
                return [];
            }
            return promise;
        }
        const req = axios
            .get(url, {
            headers: {
                Accept: "application/json"
            }
        })
            .then((res) => {
            const { data } = parseNewNewYorkApiResponse(res);
            return data.map((status) => this.toInscapeStatus(status));
        })
            .catch((error) => {
            if (axios.isCancel(error)) {
                throw new MadSDKAbortError();
            }
            else {
                throw error;
            }
        });
        this.cache.set(url, req);
        return req;
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any  */
    async saveItem() {
        throw new NotImplementedError("save");
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any  */
    async deleteItem() {
        throw new NotImplementedError("delete");
    }
}
export default Inscape;

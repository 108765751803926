import { CampaignStatus } from "../../../types";
/**
 * @param instruction: the campaign or line item to validate.
 * @param value: the value for booked impressions.
 * @return: an error message if there is an issue. Else, undefined.
 */
export const validateBookedImpressions = (instruction, value) => {
    const bookedImpressionsType = typeof value;
    const budgetType = typeof instruction?.budget;
    if (budgetType === "number" && instruction.budget > 0) {
        // if budget is set, we don't need/want booked impressions
        return undefined;
    }
    // if the line item is not live, and value does not exceed max, we don't need to validate
    if (instruction?.status !== CampaignStatus.LIVE && value <= 10000000) {
        return undefined;
    }
    if (bookedImpressionsType !== "number" || isNaN(value) || value <= 0 || value > 10000000) {
        return "Booked impressions must be a number between [0 - 10MM]";
    }
    return undefined;
};

import { Observable } from "rxjs";
import { ObservableHandler } from "../../handlers";
import { parseIdFilter } from "../../handlers/filter";
import { AdvancedExportsSchedulesCouldNotFindError } from "../errors";
import axios from "axios";
import { validators } from "./validators/validators";
export default class ReportsSchedules extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "advanced-exports-schedules");
        this.validators = validators;
    }
    get schedulesUrl() {
        return `${this.sdk.urls.advancedQueryToolBaseUrl}/v2/datafeeds/schedule`;
    }
    getSchedule(filters) {
        const idFilter = parseIdFilter(filters)?.values().next()?.value;
        if (!idFilter?.length) {
            throw new AdvancedExportsSchedulesCouldNotFindError();
        }
        const url = `${this.schedulesUrl}/${idFilter}`;
        return this.cache.promise(url, () => {
            return axios
                .get(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(({ data: res }) => {
                return Promise.resolve(res.data);
            });
        });
    }
    findItems(filters) {
        return new Observable((subscriber) => {
            this.getSchedule(filters)
                .then((schedule) => {
                subscriber.next(schedule);
                subscriber.complete();
            })
                .catch((error) => {
                subscriber.error(error);
            });
        });
    }
    buildSaveRequest(data) {
        const isUpdatingExistingScheduledReport = "id" in data && !!data.id?.length;
        if (isUpdatingExistingScheduledReport) {
            // when updating a report schedule we need to use a PATCH request
            return {
                request: axios.patch,
                url: `${this.schedulesUrl}/${data.id}`
            };
        }
        // when creating a new scheduled report we need to use a POST request
        return {
            request: axios.post,
            url: this.schedulesUrl
        };
    }
    saveItem(data) {
        return new Observable((subscriber) => {
            const validation = this.validate(data);
            if (validation.size) {
                subscriber.error(validation);
                return;
            }
            const { request, url } = this.buildSaveRequest(data);
            request(url, data, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(({ data: res }) => {
                // need to clear templates cache since we just updated a schedule that is directly associated to a template
                this.clearTemplatesCache();
                subscriber.next(res.data);
                subscriber.complete();
            })
                .catch((error) => {
                subscriber.error(error);
            });
        });
    }
    deleteItem(id) {
        return new Promise((resolve, reject) => {
            const url = `${this.schedulesUrl}/${id}`;
            axios
                .delete(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(({ data: res }) => {
                // need to clear templates cache since we just archived a schedule that is directly associated to a template
                this.clearTemplatesCache();
                resolve(res.data);
            })
                .catch((err) => {
                reject(err);
            });
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    make(defaults) {
        throw new Error("Method not implemented.");
    }
    clearTemplatesCache() {
        this.sdk.caches.clear("advanced-exports-templates");
    }
}

import { css } from "@emotion/react";

export const styles = {
  grid: css`
    display: grid;
    gap: var(--spacing-24);

    hr {
      border: 1px solid var(--color-border-secondary);
      border-width: 0 0 1px;
    }
  `,
  // This selects a container that does NOT contain ONLY direct children of the listed classes.
  hideEmptyCell: css`
    &:not(:has(> :not(.kit-GridRowBreak):not(.kit-GridHR))) {
      display: none;
    },
  `,
  fullRow: css`
    grid-column: 1/-1;
  `,
  etherealHr: css`
    height: 0;

    > hr {
      margin: 0;
      transform: translateY(-1px);
    }
  `
};

import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";

const DollarSign: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-circled-dollar-sign"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M12.75 6C12.75 5.58579 12.4142 5.25 12 5.25C11.5858 5.25 11.25 5.58579 11.25 6V6.75H11.107C9.52879 6.75 8.25 8.02879 8.25 9.607C8.25 10.917 9.141 12.0588 10.4116 12.3775L13.2236 13.0825C13.827 13.2338 14.25 13.776 14.25 14.398C14.25 15.1476 13.642 15.755 12.893 15.755H12.0871C12.0585 15.7517 12.0295 15.75 12 15.75C11.9705 15.75 11.9415 15.7517 11.9129 15.755H11.25C10.4212 15.755 9.75 15.0838 9.75 14.255H8.25C8.25 15.9122 9.59279 17.255 11.25 17.255V18C11.25 18.4142 11.5858 18.75 12 18.75C12.4142 18.75 12.75 18.4142 12.75 18V17.255H12.893C14.47 17.255 15.75 15.9764 15.75 14.398C15.75 13.088 14.859 11.9462 13.5884 11.6275L10.7764 10.9225C10.173 10.7712 9.75 10.229 9.75 9.607C9.75 8.85721 10.3572 8.25 11.107 8.25H12.75C13.5788 8.25 14.25 8.92121 14.25 9.75H15.75C15.75 8.09279 14.4072 6.75 12.75 6.75V6Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8943 18.8943C22.7019 15.0867 22.7019 8.91332 18.8943 5.10571C15.0867 1.2981 8.91338 1.2981 5.10577 5.10571C1.29816 8.9133 1.29816 15.0867 5.10577 18.8943C8.91336 22.7019 15.0867 22.7019 18.8943 18.8943ZM6.16643 6.16637C9.38826 2.94454 14.6119 2.94454 17.8337 6.16637C21.0552 9.38823 21.0554 14.6119 17.8337 17.8336C14.6118 21.0554 9.38824 21.0554 6.16643 17.8336C2.9446 14.6118 2.94461 9.38818 6.16643 6.16637Z"
      />
    </svg>
  );
};

export default DollarSign;

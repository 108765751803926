import { RootState } from "rootReducer";

export const selectOrgSettings = (state: RootState) =>
  state.orgSettings.settings;

export const selectIsOrgSettingsLoading = (state: RootState) =>
  state.orgSettings && state.orgSettings.isLoading
    ? state.orgSettings.isLoading
    : null;

export const selectOrgSettingsError = (state: RootState) =>
  state.orgSettings.error;

export const selectIsOrgSettingsLoadingForFirstTime = (state: RootState) =>
  state.orgSettings.settings === undefined && state.orgSettings.isLoading;

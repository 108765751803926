import { css } from "@emotion/react";
import { kitText } from "../../styles";

export const styles = {
  container: css`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--color-background-secondary);
    border: 1px dashed var(--color-border-primary);
    border-radius: var(--spacing-4);
    padding: var(--spacing-16);
    &:hover {
      cursor: pointer;
      background: var(--color-background-tertiary);
    }
    &:focus {
      outline: 0;
      border-color: var(--color-splash-primary);
      background-color: var(--color-splash-low-contrast);
    }
  `,
  containerError: css`
    border: 1px dashed var(--color-danger);
  `,
  clickable: css`
    &:hover {
      cursor: pointer;
      background: var(--color-background-tertiary);
    }
  `,
  dragging: css`
    background: var(--color-background-tertiary);
  `,
  contentWrapper: css`
    display: flex;
    flex-direction: column;
    gap: var(--spacing-8);
    align-self: stretch;
  `,
  instructionsWrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: var(--spacing-8) 0;
    gap: var(--spacing-4);
    align-self: stretch;
    font-size: var(--font-size-12);
    line-height: var(--spacing-16);
  `,
  instructions: css`
    display: flex;
    gap: var(--spacing-8);
    justify-content: space-between;
  `,
  clickableText: css`
    &:hover {
      cursor: pointer;
    }
    font-weight: var(--font-weight-bold);
    color: var(--color-splash-primary);
  `,
  acceptedFileTypes: css`
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    color: var(--color-secondary);
    font-size: 10px;
  `,
  fileRow: css`
    ${kitText.variant.body}
    display: flex;
    padding: var(--spacing-4) 0;
    gap: var(--spacing-8);
    justify-content: space-between;
  `,
  leftContent: css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    gap: var(--spacing-8);
  `,
  rightContent: css`
    display: flex;
    gap: var(--spacing-8);
  `,
  fileName: css`
    display: block;
    overflow-wrap: anywhere;
  `,
  fileError: css`
    color: var(--color-danger);
    font-size: 10px;
  `,
  uploaderError: css`
    color: var(--color-danger);
    font-size: 10px;
    display: flex;
    justify-content: center;
  `
};

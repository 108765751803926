import { NotImplementedError } from "../../errors";
import { Handler } from "../handlers";
import axios from "axios";
import { OrganizationImageNoFileSaveFailure } from "./errors";
export default class OrganizationImages extends Handler {
    constructor(sdk) {
        super(sdk, "organization-images");
    }
    async saveItem(image) {
        if (image.file === undefined) {
            throw new OrganizationImageNoFileSaveFailure();
        }
        return new Promise((resolve) => {
            const formData = new FormData();
            formData.append("file", image.file);
            formData.append("org", image.orgId);
            const config = {
                headers: {
                    "content-type": "multipart/form-data"
                }
            };
            axios
                .post(`${this.sdk.urls.burnsBaseUrl}/images/upload`, formData, config)
                .then((storedValue) => {
                resolve({
                    ...image,
                    path: storedValue.data.name
                });
            });
        });
    }
    async findItems() {
        throw new NotImplementedError("save");
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}

import { ServiceMediaTypeLegacy } from "../creatives/legacy";
import { divide, sum } from "../../utils";
export var SummaryDateFilterType;
(function (SummaryDateFilterType) {
    SummaryDateFilterType["RUNS_BETWEEN"] = "RUNS_BETWEEN";
    SummaryDateFilterType["STARTS_ON"] = "STARTS_ON";
    SummaryDateFilterType["ENDS_ON"] = "ENDS_ON";
    SummaryDateFilterType["STARTS_BETWEEN"] = "STARTS_BETWEEN";
    SummaryDateFilterType["ENDS_BETWEEN"] = "ENDS_BETWEEN";
})(SummaryDateFilterType || (SummaryDateFilterType = {}));
var DEVICES;
(function (DEVICES) {
    DEVICES["DESKTOP"] = "DESKTOP";
    DEVICES["MOBILE"] = "MOBILE";
    DEVICES["CONNECTED_TV"] = "CONNECTED TV";
    DEVICES["TABLET"] = "TABLET";
    DEVICES["ROBOT"] = "ROBOT";
    DEVICES["UNKNOWN"] = "UNKNOWN";
})(DEVICES || (DEVICES = {}));
var DAY_PARTS;
(function (DAY_PARTS) {
    DAY_PARTS["PART_MID_TO_2AM"] = "MID TO 2AM";
    DAY_PARTS["PART_2AM_TO_6AM"] = "2AM TO 6AM";
    DAY_PARTS["PART_6AM_TO_9AM"] = "6AM TO 9AM";
    DAY_PARTS["PART_9AM_TO_4PM"] = "9AM TO 4PM";
    DAY_PARTS["PART_4PM_TO_7PM"] = "4PM TO 7PM";
    DAY_PARTS["PART_7PM_TO_MID"] = "7PM TO MID";
})(DAY_PARTS || (DAY_PARTS = {}));
export var SUMMARY_FIELDS;
(function (SUMMARY_FIELDS) {
    // eslint-disable-next-line no-shadow
    SUMMARY_FIELDS["DAY_PARTS"] = "dayParts";
    // eslint-disable-next-line no-shadow
    SUMMARY_FIELDS["DEVICES"] = "devices";
    SUMMARY_FIELDS["QUARTILES"] = "quartiles";
    SUMMARY_FIELDS["FREQUENCY"] = "frequency";
    SUMMARY_FIELDS["VIDEO_COMPLETION_RATE"] = "videoCompletionRate";
    SUMMARY_FIELDS["UNIQUES"] = "uniques";
    SUMMARY_FIELDS["IMPRESSIONS_BY_DAY"] = "impressionsByDay";
    SUMMARY_FIELDS["GEO_SUMMARIES"] = "geoSummaries";
    SUMMARY_FIELDS["CAMPAIGN_SUMMARIES"] = "campaignSummaries";
    SUMMARY_FIELDS["CREATIVE_SUMMARIES"] = "creativeSummaries";
})(SUMMARY_FIELDS || (SUMMARY_FIELDS = {}));
export const EMPTY_QUARTILES = {
    impressions: 0,
    start: 0,
    first: 0,
    midpoint: 0,
    third: 0,
    complete: 0
};
export const DEFAULT_QUARTILES = {
    video: EMPTY_QUARTILES,
    audio: EMPTY_QUARTILES,
    display: EMPTY_QUARTILES
};
export const buildTrafficSources = (publisherSummaries, totalImpressions) => {
    if (publisherSummaries) {
        return publisherSummaries.map((publisher) => {
            const publisherTotalImpressions = publisher.q[0] ? publisher.q[0] : 0;
            return {
                id: publisher.id,
                name: publisher.name || "Missing Data",
                totalImpressions: publisher.q[0] ? publisher.q[0] : 0,
                distribution: publisherTotalImpressions > 0 ? (publisherTotalImpressions / totalImpressions) * 100 : 0
            };
        });
    }
    return [];
};
export const sumAllDayParts = (dayParts) => {
    const sumDayParts = {};
    // take array of dayParts objects and sums by common day part
    for (const d of dayParts) {
        for (const p in d.day_parts) {
            sumDayParts[p] = sumDayParts[p] + d.day_parts[p] || d.day_parts[p];
        }
    }
    // day_parts keys are returned in the following format: "hour_0",...,"hour_24" in an unordered list
    // turn sumDayParts object into an ordered array
    const dayPartsOrderedArr = Object.keys(sumDayParts)
        .sort(function (a, b) {
        return +a.slice(5) - +b.slice(5);
    })
        .map((dp) => sumDayParts[dp]);
    return dayPartsOrderedArr;
};
export const buildDayParts = (dayParts, totalImpressions) => {
    const dayPartSummaries = [];
    let dayPartTotal = dayParts ? dayParts.slice(0, 2).reduce(sum, 0) : 0;
    dayPartSummaries.push({
        label: DAY_PARTS.PART_MID_TO_2AM,
        id: DAY_PARTS.PART_MID_TO_2AM,
        value: dayPartTotal,
        pct: divide(dayPartTotal, totalImpressions)
    });
    dayPartTotal = dayParts ? dayParts.slice(2, 6).reduce(sum, 0) : 0;
    dayPartSummaries.push({
        label: DAY_PARTS.PART_2AM_TO_6AM,
        id: DAY_PARTS.PART_2AM_TO_6AM,
        value: dayPartTotal,
        pct: divide(dayPartTotal, totalImpressions)
    });
    dayPartTotal = dayParts ? dayParts.slice(6, 9).reduce(sum, 0) : 0;
    dayPartSummaries.push({
        label: DAY_PARTS.PART_6AM_TO_9AM,
        id: DAY_PARTS.PART_6AM_TO_9AM,
        value: dayPartTotal,
        pct: divide(dayPartTotal, totalImpressions)
    });
    dayPartTotal = dayParts ? dayParts.slice(9, 16).reduce(sum, 0) : 0;
    dayPartSummaries.push({
        label: DAY_PARTS.PART_9AM_TO_4PM,
        id: DAY_PARTS.PART_9AM_TO_4PM,
        value: dayPartTotal,
        pct: divide(dayPartTotal, totalImpressions)
    });
    dayPartTotal = dayParts ? dayParts.slice(16, 19).reduce(sum, 0) : 0;
    dayPartSummaries.push({
        label: DAY_PARTS.PART_4PM_TO_7PM,
        id: DAY_PARTS.PART_4PM_TO_7PM,
        value: dayPartTotal,
        pct: divide(dayPartTotal, totalImpressions)
    });
    dayPartTotal = dayParts ? dayParts.slice(19).reduce(sum, 0) : 0;
    dayPartSummaries.push({
        label: DAY_PARTS.PART_7PM_TO_MID,
        id: DAY_PARTS.PART_7PM_TO_MID,
        value: dayPartTotal,
        pct: divide(dayPartTotal, totalImpressions)
    });
    return dayPartSummaries;
};
// take array of device objects and sums by common device part
const sumDevices = (devicesArr) => {
    const devices = {
        desktop: 0,
        mobile: 0,
        robot: 0,
        tablet: 0,
        tv: 0,
        unknown: 0
    };
    if (devicesArr) {
        for (const d of devicesArr) {
            for (const p in d.devices) {
                devices[p] = devices[p] + d.devices[p] || d.devices[p];
            }
        }
    }
    return devices;
};
export const buildDevices = (devicesArr, totalImpressions) => {
    const devices = sumDevices(devicesArr);
    return [
        {
            label: DEVICES.CONNECTED_TV,
            id: DEVICES.CONNECTED_TV,
            value: devices.tv + devices.unknown,
            pct: divide(devices.tv + devices.unknown, totalImpressions)
        },
        {
            label: DEVICES.MOBILE,
            id: DEVICES.MOBILE,
            value: devices.mobile,
            pct: divide(devices.mobile, totalImpressions)
        },
        {
            label: DEVICES.DESKTOP,
            id: DEVICES.DESKTOP,
            value: devices.desktop,
            pct: divide(devices.desktop, totalImpressions)
        },
        {
            label: DEVICES.TABLET,
            id: DEVICES.TABLET,
            value: devices.tablet,
            pct: divide(devices.tablet, totalImpressions)
        },
        {
            label: DEVICES.UNKNOWN,
            id: DEVICES.UNKNOWN,
            value: devices.unknown + devices.robot,
            pct: divide(devices.unknown + devices.robot, totalImpressions)
        }
    ];
};
export const buildQuartiles = (quartilesResponse) => {
    return quartilesResponse.reduce((acc, { media_type, quartiles }) => {
        acc[media_type.toLowerCase()] = {
            impressions: quartiles.impressions,
            start: quartiles.ST,
            first: quartiles.Q1,
            midpoint: quartiles.Q2,
            third: quartiles.Q3,
            complete: quartiles.Q4
        };
        return acc;
    }, { ...DEFAULT_QUARTILES });
};
export const buildCompletionRates = (serviceCompletionRates) => {
    // not all completion rates are returned on the response
    const completionRatesByMediaType = serviceCompletionRates.reduce((acc, curr) => {
        acc[curr.media_type] = curr.completion_rate;
        return acc;
    }, {
        [ServiceMediaTypeLegacy.VIDEO]: 0,
        [ServiceMediaTypeLegacy.DISPLAY]: 0,
        [ServiceMediaTypeLegacy.AUDIO]: 0
    });
    return {
        videoCompletionRate: completionRatesByMediaType[ServiceMediaTypeLegacy.VIDEO],
        clickThroughRate: completionRatesByMediaType[ServiceMediaTypeLegacy.DISPLAY],
        listenThroughRate: completionRatesByMediaType[ServiceMediaTypeLegacy.AUDIO]
    };
};
export const getTotalImpressions = (filters, quartiles) => {
    // calculate total impressions from media type quartiles impressions,
    // when no media types are selected all media types are returned
    const mediaTypeFilters = filters.media_type?.length
        ? filters.media_type
        : ["video", "audio", "display"];
    return mediaTypeFilters.reduce((acc, curr) => {
        return acc + quartiles[curr].impressions;
    }, 0);
};
export const summaryFiltersToServiceSummaryFilters = (filters) => {
    return {
        parent_id: filters.parentId,
        owner_ids: filters.ownerIds,
        advertiser_ids: filters.advertiserIds,
        start: filters.start,
        end: filters.end,
        timezone: filters.timezone,
        products: filters.products,
        adbook_package_id: filters.adbookPackageId,
        campaign_ids: filters.campaignIds,
        line_item_ids: filters.lineItemIds,
        date_filter_type: filters.dateFilterType,
        brands: filters.brands,
        estimates: filters.estimates,
        media_type: filters.mediaType,
        dmas: filters.dmas,
        stations: filters.stations,
        agencies: filters.agencies,
        iab_adv_categories: filters.iabAdvertiserCategories,
        iab_creative_categories: filters.iabCreativeCategories,
        product_codes: filters.campaignFieldProductCodes,
        client_estimate_codes: filters.campaignFieldClientEstimateCodes
    };
};

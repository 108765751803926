/**
 * NOTICE OF DEPRECATION: Do not add anything to this file. This file will be
 * deleted in the future in favor of colocating context creation with the
 * relevant code and the pattern outlined in the style guide of not providing
 * useless default values to context providers. */

import { createContext } from "react";
import { MadhiveToastProps } from "components/Toaster/Toaster";
import { noop } from "./utils/fp";

export const ToasterContext = createContext<{
  addToast: (toast: MadhiveToastProps, key?: string) => void;
}>({
  addToast: noop
});

import { PropsWithChildren } from "react";

interface Props {
  /** Width. Defaults to 100%. */
  width?: string;
  /** Height. Defaults to 100%. */
  height?: string;
  viewBox: string;
  id?: string;
  aria?: {
    labelledBy?: string;
    label?: string;
  };
}

/** SVG wrapper that handles defaulting width/height, and grouping content. */
export const SVG = ({
  id,
  width,
  height,
  viewBox,
  children,
  aria
}: PropsWithChildren<Props>) => (
  <svg
    id={id}
    width={width || "100%"}
    height={height || "100%"}
    viewBox={viewBox}
    fill="none"
    stroke="none"
    role="img"
    aria-labelledby={aria?.labelledBy}
    aria-label={aria?.label}
  >
    {children}
  </svg>
);

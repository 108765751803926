import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const FilterList: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-filter-list"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 5.509A1.51 1.51 0 0 1 3.509 4h13.796a1.51 1.51 0 0 1 1.509 1.509v2.183c0 .449-.2.873-.543 1.16l-5.493 4.576v3.62c0 .571-.323 1.094-.835 1.349L9.595 19.57a1.077 1.077 0 0 1-1.56-.964v-5.179L2.544 8.851A1.51 1.51 0 0 1 2 7.692V5.51Zm1.509-.216a.216.216 0 0 0-.216.216v1.94H17.52v-1.94a.216.216 0 0 0-.215-.216H3.509Zm5.587 7.335L4.433 8.742H16.38l-4.664 3.886a.647.647 0 0 0-.232.497v3.922a.215.215 0 0 1-.12.193L9.33 18.258v-5.133a.647.647 0 0 0-.233-.497Z"
      />
      <path d="M15.31 15.143c-.426 0-.774-.314-.774-.697 0-.383.348-.696.774-.696h5.416c.426 0 .774.313.774.696 0 .383-.348.697-.774.697H15.31ZM15.31 19.321h5.416c.426 0 .774.314.774.697 0 .383-.348.696-.774.696H15.31c-.426 0-.774-.313-.774-.696 0-.383.348-.697.774-.697ZM20.726 16.536H15.31c-.426 0-.774.313-.774.696 0 .383.348.697.774.697h5.416c.426 0 .774-.314.774-.697 0-.383-.348-.696-.774-.696Z" />
    </svg>
  );
};

export default FilterList;

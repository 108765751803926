import { getDetailedErrorMessage } from "../../utils/validation";
export class DealsFetchFailed extends Error {
    /**
     * @param apiError: the error received from the API.
     * @param fallbackMessage: the message to use for the error, if the API error does not contain anything relevant.
     */
    constructor(apiError, fallbackMessage = "Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, DealsFetchFailed);
        }
        this.name = "DealsFetchFailed";
        this.message = `Failed to fetch deal(s). ${getDetailedErrorMessage(apiError, fallbackMessage)}`;
    }
}
export class DealSaveFailed extends Error {
    /**
     * @param apiError: the error received from the API.
     * @param fallbackMessage: the message to use for the error, if the API error does not contain anything relevant.
     */
    constructor(apiError, fallbackMessage = "Failed to save deal. Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, DealSaveFailed);
        }
        this.name = "DealSaveFailed";
        this.message = `Failed to save deal. ${getDetailedErrorMessage(apiError, fallbackMessage)}`;
    }
}

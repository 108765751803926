import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const List: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-list"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8839 4.11612C5.39574 3.62796 4.60428 3.62796 4.11613 4.11612C3.62797 4.60427 3.62797 5.39573 4.11613 5.88389L3.5858 6.41422L4.11613 5.88389C4.60428 6.37204 5.39574 6.37204 5.8839 5.88389C6.37205 5.39573 6.37205 4.60427 5.8839 4.11612ZM6.94456 3.05546L6.41423 3.58579L6.94456 3.05546C8.0185 4.1294 8.0185 5.8706 6.94456 6.94455C5.87061 8.01849 4.12941 8.01849 3.05547 6.94455L3.52857 6.47145L3.05547 6.94455C1.98152 5.8706 1.98152 4.1294 3.05547 3.05546L3.05547 3.05546C4.12941 1.98151 5.87061 1.98151 6.94456 3.05546ZM10.25 5C10.25 4.58579 10.5858 4.25 11 4.25H21C21.4142 4.25 21.75 4.58579 21.75 5C21.75 5.41421 21.4142 5.75 21 5.75H11C10.5858 5.75 10.25 5.41421 10.25 5ZM5.88389 11.1161C5.39573 10.628 4.60427 10.628 4.11612 11.1161C3.62796 11.6043 3.62796 12.3957 4.11612 12.8839C4.60427 13.3721 5.39573 13.3721 5.88389 12.8839C6.37204 12.3957 6.37204 11.6043 5.88389 11.1161ZM3.05546 10.0555C4.1294 8.98153 5.8706 8.98153 6.94455 10.0555C8.01849 11.1294 8.01849 12.8706 6.94455 13.9446C5.8706 15.0185 4.1294 15.0185 3.05546 13.9446L3.5802 13.4198L3.05546 13.9446C1.98151 12.8706 1.98151 11.1294 3.05546 10.0555ZM10.25 12C10.25 11.5858 10.5858 11.25 11 11.25H21C21.4142 11.25 21.75 11.5858 21.75 12C21.75 12.4142 21.4142 12.75 21 12.75H11C10.5858 12.75 10.25 12.4142 10.25 12ZM5.8839 18.1161C5.39574 17.628 4.60428 17.628 4.11613 18.1161C3.62797 18.6043 3.62797 19.3957 4.11613 19.8839C4.60428 20.3721 5.39574 20.3721 5.8839 19.8839C6.37205 19.3957 6.37205 18.6043 5.8839 18.1161ZM3.05547 17.0555C4.12941 15.9815 5.87061 15.9815 6.94456 17.0555C8.0185 18.1294 8.0185 19.8706 6.94456 20.9446C5.87061 22.0185 4.12941 22.0185 3.05547 20.9446C1.98152 19.8706 1.98152 18.1294 3.05547 17.0555L3.5858 17.5858L3.05547 17.0555ZM10.25 19C10.25 18.5858 10.5858 18.25 11 18.25H21C21.4142 18.25 21.75 18.5858 21.75 19C21.75 19.4142 21.4142 19.75 21 19.75H11C10.5858 19.75 10.25 19.4142 10.25 19Z"
      />
    </svg>
  );
};

export default List;

import { DateTime } from "luxon";
import { useCallback, useEffect, useState } from "react";
import { DateErrorMessages } from "../constants";
import { dateTimeFromAny, formatDateFromAny, isSameDay } from "../utils";
import { UseInputDateSingle, UseInputDateSingleArguments } from "./types";
import { useDateTimeMinMax } from "./useDateTimeMinMax";

export const useInputDateSingle = ({
  value,
  time,
  min: incomingMin,
  max: incomingMax,
  isEndDate,
  error,
  setError,
  onChange
}: UseInputDateSingleArguments): UseInputDateSingle => {
  const { min, max } = useDateTimeMinMax({
    min: incomingMin,
    max: incomingMax,
    time,
    setEndToEndOfDay: isEndDate
  });

  const [rawValue, setRawValue] = useState<UseInputDateSingle["rawValue"]>(
    formatDateFromAny(value, time)
  );

  const dateTimeFromRawValue = useCallback(
    (rawValue: string | undefined): DateTime | undefined => {
      if (!rawValue) {
        return undefined;
      }
      let dateTime = dateTimeFromAny(rawValue);

      if (!time) {
        dateTime = isEndDate
          ? dateTime?.endOf("day").startOf("second")
          : dateTime?.startOf("day");
      }
      if (!dateTime?.isValid) {
        return dateTime;
      }
      if (isEndDate) {
        const { hour, minute, second } = dateTime;
        if (hour === 0 && minute === 0 && second === 0) {
          dateTime = dateTime?.endOf("day").startOf("second");
        }
      }
      if (max && dateTime > max && isSameDay(dateTime, max)) {
        dateTime = dateTime?.set({
          hour: max.hour,
          minute: max.minute,
          second: max.second,
          day: max.day
        });
      } else if (min && dateTime < min && isSameDay(dateTime, min)) {
        dateTime = dateTime?.set({
          hour: min.hour,
          minute: min.minute,
          second: min.second,
          day: min.day
        });
      }
      return dateTime;
    },
    [time, isEndDate]
  );

  const errorFromRawValue = useCallback(
    (rawValue: UseInputDateSingle["rawValue"]) => {
      if (!rawValue) {
        return undefined;
      }

      let newError: string | undefined;

      const newDateTime = dateTimeFromRawValue(rawValue);

      if (!newDateTime?.isValid) {
        newError = DateErrorMessages.INVALID;
      } else if (min || max) {
        const isPastMax = max && max < newDateTime;
        const isBeforeMin = min && min > newDateTime;

        if (isPastMax || isBeforeMin) {
          newError = `Date must come ${
            isPastMax ? "before" : "after"
          } ${formatDateFromAny(isPastMax ? max! : min!, time)}`;
        }
      }

      return newError;
    },
    [min, max, dateTimeFromRawValue, time]
  );

  const handleSetRawValue = useCallback(
    (rawValue: UseInputDateSingle["rawValue"]) => {
      const newDate = dateTimeFromRawValue(rawValue);
      setRawValue(rawValue);
      /**
       * we are not checking for error when rawValue is set,
       * this gets handled in commitChange.
       * But, if error is set, then we want to clear it out when user inputs new value.
       */
      if (error) {
        if (!rawValue || !newDate || (!min && !max)) {
          setError?.(undefined);
        } else {
          const isPastMax = max && max < newDate;
          const isBeforeMin = min && min > newDate;
          if (!isPastMax && !isBeforeMin && !error) {
            setError?.(undefined);
          }
        }
      }
    },
    [error, min, max, setError, setRawValue, dateTimeFromRawValue]
  );

  useEffect(() => {
    const newError = errorFromRawValue(rawValue);
    if (newError && newError !== error) {
      setError?.(newError);
    }
  }, [min, max]);

  const commitChange = useCallback(() => {
    // happens when input is cleared
    if (!rawValue) {
      if (error) {
        setError?.(undefined);
      }
      // if current value is already undefined, we don't want to call onChange
      if (value) {
        onChange(undefined);
      }
      return;
    }

    const newError = errorFromRawValue(rawValue);
    if (newError && newError !== error) {
      setError?.(newError);
    }

    let newValue = rawValue;
    const newDateTime = dateTimeFromRawValue(rawValue);
    if (newDateTime?.isValid) {
      if (min && newDateTime < min) {
        newDateTime.set(min.toObject());
      }
      if (max && newDateTime > max) {
        newDateTime.set(max.toObject());
      }
      newValue = formatDateFromAny(newDateTime, time);
    }

    if (newValue !== rawValue) {
      setRawValue(newValue);
    }

    if (
      !value ||
      (!newDateTime?.isValid && rawValue !== "Invalid Date") ||
      newDateTime?.toMillis() !== value?.getTime()
    ) {
      onChange(
        newDateTime?.isValid
          ? newDateTime?.toJSDate()
          : new Date("Invalid Date")
      );
    }
  }, [
    error,
    rawValue,
    errorFromRawValue,
    setError,
    setRawValue,
    dateTimeFromRawValue,
    onChange
  ]);

  useEffect(() => {
    if (!value) {
      handleSetRawValue("");
      return;
    }
    if (
      value &&
      /**
       * this prevents input from setting back to empty state,
       * when an invalid date is sent back down through value
       */
      value.toString() !== "Invalid Date"
    ) {
      handleSetRawValue(formatDateFromAny(value, time));
    }
  }, [value, time]);

  return {
    rawValue,
    setRawValue: handleSetRawValue,
    commitChange
  };
};

import { adbookReducer } from "appReducers/adbookReducer/reducer";
import advertisersReducer from "appReducers/advertisersReducer";
import agenciesReducer from "appReducers/agenciesReducer";
import assetUploadReducer from "appReducers/assetUploadReducer";
import audiencesReducer from "appReducers/audiencesReducer";
import authReducer, { LOGOUT_USER } from "appReducers/authReducer";
import campaignsReducer from "appReducers/campaignsReducer";
import CustomReports from "appReducers/customReportsReducer";
import { dashboardReducer } from "appReducers/dashboardReducer/reducer";
import DeprecatedSegmentsReducer from "appReducers/deprecatedSegmentsReducer";
import dmpsReducer from "appReducers/dmpReducer";
import dmpSegmentsReducer from "appReducers/dmpSegmentsReducer";
import featureFlagsReducer from "appReducers/featureFlagsReducer";
import { footTrafficAttributionReducer } from "appReducers/footTrafficAttributionReducer/reducer";
import freewheelProductsReducer from "appReducers/freewheelProductsReducer";
import geographyReducer from "appReducers/geographyReducer";
import householdsCountReducer from "appReducers/householdsCountReducer";
import lineItemGeoTargetingReducer from "appReducers/lineItemGeoTargetingReducer";
import { lineItemsReducer } from "appReducers/lineItemsReducer/reducer";
import oohReducer from "appReducers/oohReducer";
import organizationsReducer from "appReducers/organizationsReducer";
import orgSettingsReducer from "appReducers/orgSettingsReducer";
import pioProductPackagesReducer from "appReducers/pioProductPackagesReducer";
import pixelReportReducer from "appReducers/pixelReportReducer";
import plannerReducer from "appReducers/plannerReducer";
import { polygonReducer } from "appReducers/polygonReducer/reducer";
import { productsReducer } from "appReducers/productsReducer";
import PublisherListsReducer from "appReducers/publisherListsReducer";
import PublishersReducer from "appReducers/publishersReducer";
import reportingReducer from "appReducers/reportingReducer";
import stationsReducer from "appReducers/stationsReducer";
import teamsReducer from "appReducers/teamsReducer";
import toasterReducer from "appReducers/toasterReducer";
import usersReducer from "appReducers/usersReducer";
import { ManageAgenciesReducerType } from "features/ManageAgencies/reducer";
import { ManageAudiencesReducerType } from "features/ManageAudiences/reducer";
import { AnyAction, combineReducers } from "redux";
import trackingPixelsReducer from "./appReducers/trackingPixelsReducer";
import configureStore from "./configureStore";

export enum ReducerKey {
  AUTH = "auth",
  ADVERTISERS = "advertisers",
  USERS = "users",
  ORGANIZATIONS = "organizations",
  AGENCIES = "agencies",
  STATIONS = "stations",
  FEATURE_FLAGS = "featureFlags",
  GEOGRAPHY = "geography",
  LINE_ITEM_GEO_TARGETING = "lineItemGeoTargeting",
  OOH = "ooh",
  ORG_SETTINGS = "orgSettings",
  TOASTS = "toasts",
  TEAMS = "teams",
  POLYGONS = "polygons",
  PUBLISHERS = "publishers",
  PUBLISHER_LISTS = "publisherLists",
  MANAGE_AGENCIES = "manageAgencies",
  DEPRECATED_SEGMENTS = "deprecatedSegments",
  DASHBOARD = "dashboard",
  CUSTOM_REPORTS = "customReports",
  AUDIENCES = "audiences",
  DMP_SEGMENTS = "dmpSegments",
  DMPS = "dmps",
  // PK: This is for shared state in Manage Audiences
  MANAGE_AUDIENCES = "manageAudiences",
  PRODUCTS = "products",
  ADBOOK_CAMPAIGNS = "adbookCampaigns",
  ASSET_UPLOAD = "assetUpload",
  CAMPAIGNS = "campaigns",
  TRACKING_PIXELS = "trackingPixels",
  PIXEL_REPORT = "pixelReport",
  LINE_ITEMS = "lineItems",
  FREEWHEEL_PRODUCTS = "freewheelProducts",
  CROSS_PLATFORM_PLANS = "crossPlatformPlans",
  HOUSEHOLDS_COUNT = "householdsCount",
  PIO_PRODUCT_PACKAGES = "pioProductPackages",
  PLANNER = "planner",
  FOOT_TRAFFIC_ATTRIBUTION = "footTrafficAttribution",
  REPORTING = "reporting"
}

const staticReducerMapObject = Object.freeze({
  [ReducerKey.AUTH]: authReducer,
  [ReducerKey.ADVERTISERS]: advertisersReducer,
  [ReducerKey.USERS]: usersReducer,
  [ReducerKey.ORGANIZATIONS]: organizationsReducer,
  [ReducerKey.AGENCIES]: agenciesReducer,
  [ReducerKey.STATIONS]: stationsReducer,
  [ReducerKey.FEATURE_FLAGS]: featureFlagsReducer,
  [ReducerKey.GEOGRAPHY]: geographyReducer,
  [ReducerKey.LINE_ITEM_GEO_TARGETING]: lineItemGeoTargetingReducer,
  [ReducerKey.OOH]: oohReducer,
  [ReducerKey.ORG_SETTINGS]: orgSettingsReducer,
  [ReducerKey.TOASTS]: toasterReducer,
  [ReducerKey.TEAMS]: teamsReducer,
  [ReducerKey.POLYGONS]: polygonReducer,
  [ReducerKey.PUBLISHERS]: PublishersReducer,
  [ReducerKey.PUBLISHER_LISTS]: PublisherListsReducer,
  [ReducerKey.DEPRECATED_SEGMENTS]: DeprecatedSegmentsReducer,
  [ReducerKey.DASHBOARD]: dashboardReducer,
  [ReducerKey.CUSTOM_REPORTS]: CustomReports,
  [ReducerKey.AUDIENCES]: audiencesReducer,
  [ReducerKey.DMP_SEGMENTS]: dmpSegmentsReducer,
  [ReducerKey.DMPS]: dmpsReducer,
  [ReducerKey.PRODUCTS]: productsReducer,
  [ReducerKey.ADBOOK_CAMPAIGNS]: adbookReducer,
  [ReducerKey.ASSET_UPLOAD]: assetUploadReducer,
  [ReducerKey.CAMPAIGNS]: campaignsReducer,
  [ReducerKey.TRACKING_PIXELS]: trackingPixelsReducer,
  [ReducerKey.PIXEL_REPORT]: pixelReportReducer,
  [ReducerKey.LINE_ITEMS]: lineItemsReducer,
  [ReducerKey.FREEWHEEL_PRODUCTS]: freewheelProductsReducer,
  [ReducerKey.HOUSEHOLDS_COUNT]: householdsCountReducer,
  [ReducerKey.PIO_PRODUCT_PACKAGES]: pioProductPackagesReducer,
  [ReducerKey.PLANNER]: plannerReducer,
  [ReducerKey.FOOT_TRAFFIC_ATTRIBUTION]: footTrafficAttributionReducer,
  [ReducerKey.REPORTING]: reportingReducer
});

/**
 * Necessary for RootState to maintain typing of dynamically loaded reducers--
 * only import code split reducer typing into the main bundle (the build process strips them).
 * Non-type imports will result in the chunk being duped in the main bundle.
 */
type DynamicReducerMapObjectType = {
  [ReducerKey.MANAGE_AGENCIES]: ManageAgenciesReducerType;
  [ReducerKey.MANAGE_AUDIENCES]: ManageAudiencesReducerType;
};

export const getRootReducer = (
  dynamicReducerMapObject?: Partial<DynamicReducerMapObjectType>
) => {
  const appReducer = combineReducers({
    ...staticReducerMapObject,
    ...dynamicReducerMapObject
  });

  /* combineReducers type inference does not pick up optional reducers */
  type DynamicState = ReturnType<typeof appReducer> &
    Partial<{
      [K in keyof DynamicReducerMapObjectType]: ReturnType<
        DynamicReducerMapObjectType[K]
      >;
    }>;

  return (state: DynamicState, action: AnyAction): DynamicState => {
    /*
     * LOGOUT_USER should reset the entire store to its initial state.
     * Please implement any logout side effects within its dispatcher.
     */
    if (action.type === LOGOUT_USER) {
      return appReducer(
        configureStore(undefined).getState(),
        action
      ) as DynamicState;
    }

    return appReducer(state, action) as DynamicState;
  };
};

export type RootState = ReturnType<ReturnType<typeof getRootReducer>>;

import { formatDate, toStartCase } from "../../utils";
export * from "./schedules";
export * from "./templates";
// TO-DO: change name of this enum to be specific to advanced exports
export var Status;
(function (Status) {
    Status["IN_PROGRESS"] = "IN_PROGRESS";
    Status["DONE"] = "DONE";
    Status["ERROR"] = "ERROR";
})(Status || (Status = {}));
export const buildNewReportExport = (id, name) => {
    return {
        id,
        name,
        createdAt: new Date(),
        status: Status.IN_PROGRESS
    };
};
export const serviceReportJobResultToReportJobResult = (serviceResult) => {
    const transformedData = {
        results: serviceResult.results
            ? serviceResult.results.map((result) => ({
                expiresAt: new Date(result.expires_at),
                url: result.url
            }))
            : [],
        status: serviceResult.status
    };
    return transformedData;
};
export const formatOptionLabel = (optionId) => {
    return toStartCase(optionId);
};
const countUppercase = (str) => str.replace(/[^A-Z]/g, "").length;
export const formatAndDedupOptions = (options) => {
    const seen = new Map();
    options.forEach((option) => {
        const caseInsensitive = option.toLowerCase();
        if (!seen.has(caseInsensitive)) {
            seen.set(caseInsensitive, { label: formatOptionLabel(option), id: option });
        }
        else {
            // Duplicate options should not occur. The only time it's happened
            // has been due to a formatting error in OMS ingestion.
            // Given two strings that are identical not accounting for case,
            // we're guessing that a camelCase option is the correct one.
            const correctLabel = countUppercase(seen.get(caseInsensitive)?.id || "") > countUppercase(option)
                ? seen.get(caseInsensitive)?.label || ""
                : formatOptionLabel(option);
            seen.set(caseInsensitive, { label: correctLabel, id: option });
        }
    });
    return Array.from(seen.values()).sort((a, b) => a.label.localeCompare(b.label));
};
export const queryParametersToReportJobRequest = (queryParameters) => {
    return {
        start_date: formatDate(queryParameters.startDate),
        end_date: formatDate(queryParameters.endDate),
        timezone: "America/New_York",
        dimensions: queryParameters.dimensions,
        metrics: queryParameters.metrics,
        filters: queryParameters.filters
    };
};

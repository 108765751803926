import { EMPTY_WHERE } from "./listUtils";
import { useLoadListData } from "./useLoadListData";
/**
 * Hook to retrieve a list of objects from MadSDK using Observer pattern from MadSDK
 * @param collection name used to key into MadSDK. Should match the Type you want it to return.
 * @param id the id of the object
 * @param options
 * @returns {ListData<HandlerObject<Key>>} for your object
 * @template T
 */
export const useMadSDKList = (collection, options = { filters: EMPTY_WHERE }) => {
    const listData = useLoadListData(collection, options);
    // list data may be filled in with additional utils in the future
    return listData;
};

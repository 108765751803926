// TODO: View/Route and their combo within Settings are in-progress, and subject to change
/**
 * @param r: a route, or a nested route.
 * @return: true if r is just route info (i.e., nothing is nested below it). False otherwise.
 */
export const isRoute = (r) => typeof r.columns !== "undefined";
/**
 * @param obj: the object to check.
 * @return: ture if the object implements the Settings interface. Else, false.
 */
// explicitly any because we're type narrowing any object
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isSettings = (obj) => {
    if (typeof obj?.views !== "object" || typeof obj?.route !== "object") {
        return false;
    }
    for (const viewId in obj.views) {
        const configs = obj.views[viewId];
        for (let j = 0; j < configs.length; ++j) {
            const config = configs[j];
            if (typeof config.id !== "string" ||
                typeof config.label !== "string" ||
                !Array.isArray(config.columns) ||
                typeof config.edit !== "boolean") {
                return false;
            }
        }
    }
    return true;
};

import { Provider as ReduxProvider } from "react-redux";
import configureStore from "configureStore";
import { BulkAssetUploadDataProvider } from "features/CreativeLibrary/BulkCreativeUploader/BulkCreativeUploaderDataProvider";
import { BrowserRouter } from "react-router-dom";
import { lookup } from "lib/redux";
import { BulkEditLineItemsContextProvider } from "frontier/lib/instruction/campaign/lineItem/bulkEdit/BulkEditContext";
import { ActivationContextProvider } from "frontier/lib/plan/activation/ActivationContext";
import { FC } from "react";
import Auth from "./Auth";
import CrashScreen from "./CrashScreen";
import { Footer } from "./Footer/Footer";
import SmithersRouter from "./Router/SmithersRouter";
import ToasterProvider from "./Toaster/Toaster";
import SmithersToolbar from "./Toolbar/SmithersToolbar";
import { ApplicationSettingsProvider } from "./ApplicationSettingsProvider";
import OrganizationSettings from "./OrganizationSettings";

const store = configureStore();
lookup.store = store;

const Smithers: FC = () => (
  <ReduxProvider store={store}>
    <BrowserRouter>
      <ToasterProvider>
        <Auth>
          <OrganizationSettings>
            <ApplicationSettingsProvider>
              <BulkEditLineItemsContextProvider>
                <ActivationContextProvider>
                  <BulkAssetUploadDataProvider>
                    <SmithersToolbar />
                    <CrashScreen>
                      <SmithersRouter />
                    </CrashScreen>
                  </BulkAssetUploadDataProvider>
                </ActivationContextProvider>
              </BulkEditLineItemsContextProvider>
              <Footer />
            </ApplicationSettingsProvider>
          </OrganizationSettings>
        </Auth>
      </ToasterProvider>
    </BrowserRouter>
  </ReduxProvider>
);

export default Smithers;

import { FilterTypes } from "../../../handlers/filter";
import { State } from "../../../../types";
import { stateToServiceStatus } from "../../../../utils";
/**
 * @param filters: map of filters, keys being the filter name, values being the applied values.
 * @return: filters parsed into valid SDK-style filter objects.
 */
export const toFilterObjects = (filters) => {
    const parsedFilters = { where: [] };
    if (filters.has("modified")) {
        const date = new Date(filters.get("modified")[0]);
        date.setHours(0, 0, 0, 0);
        parsedFilters.where.push({
            field: "lastUpdated",
            type: FilterTypes.GT,
            value: date.toISOString()
        });
    }
    if (filters.has("name")) {
        parsedFilters.where.push({
            field: "name",
            type: FilterTypes.EQ,
            value: filters.get("name")
        });
    }
    if (filters.has("state")) {
        // convert given instruction statuses to their numeric values
        const value = [];
        filters.get("state").forEach((state) => {
            const parsedState = State[state.toUpperCase()];
            if (typeof parsedState !== "undefined") {
                value.push(stateToServiceStatus(parsedState));
            }
        });
        parsedFilters.where.push({
            field: "state",
            type: FilterTypes.IN,
            value
        });
    }
    if (filters.has("advertiserId")) {
        parsedFilters.where.push({
            field: "advertiserId",
            type: FilterTypes.EQ,
            value: filters.get("advertiserId")
        });
    }
    if (filters.has("id")) {
        parsedFilters.where.push({
            field: "id",
            type: FilterTypes.EQ,
            value: filters.get("id")
        });
    }
    if (filters.has("search")) {
        parsedFilters.where.push({
            field: "search",
            type: FilterTypes.EQ,
            value: filters.get("search")
        });
    }
    if (filters.has("mediaTypes")) {
        parsedFilters.where.push({
            field: "mediaTypes",
            type: FilterTypes.EQ,
            value: filters.get("mediaTypes")
        });
    }
    return parsedFilters;
};

import {
  appendOwnerIdsToShallowCampaign,
  archiveCampaign as archive,
  createCampaign as create,
  duplicateCampaign as duplicate,
  getBulkAssignTemplate,
  getCampaignDetails,
  getCampaignTemplate,
  getRawRootCampaigns,
  getRawRootLineItems,
  transformRawCampaignDetails,
  transformRawRootCampaign,
  updateBulkInstructions,
  updateCampaign as update,
  updateInstructionsWithBulkAssignCreatives,
  uploadBulkAssignTemplate,
  uploadCampaignTemplate as uploadCampaignTemplateApi,
  unArchiveCampaign
} from "api/manageCampaigns";
import {
  lineItemDetailToBeFetched,
  lineItemUpdate
} from "appReducers/lineItemsReducer/actions";
import { getAllProducts } from "appReducers/productsReducer";
import { showError, showSuccess } from "appReducers/toasterReducer/actions";
import * as FullStory from "@fullstory/browser";
import axios, { CancelTokenSource } from "axios";
import {
  CampaignFilterFields,
  CampaignId,
  DraftCampaign,
  LineItemId,
  QuickEditCampaignPayload,
  QuickEditLineItemPayload,
  RootCampaign,
  ShallowLineItem,
  ManageCampaignsInstructionFilter,
  LineItemFormatted
} from "campaign-types";
import { FullStoryCustomEvent } from "lib/constants/fullStory";
import {
  deriveErrorResponse,
  isErrorResponseValid,
  isHttpStatusForbidden
} from "lib/utils/api";
import { isTruthy } from "lib/utils/fp";
import {
  CampaignStatus,
  ManageCampaignColumn,
  ObjType,
  PagingInfoResponse,
  RemoteConfigProperties,
  ServiceStatus,
  User
} from "@madhive/mad-sdk";
import { CampaignIdEndpointGetResponse } from "newnewyork";
import { madSDK } from "lib/sdk";
import { AppThunk, SmithersNotificationRaw } from "types";
import {
  manageCampaignColumnIdToCampaignBackendFilterName,
  InstructionFilterCategory,
  SORT_BY_ABBREV
} from "features/ManageCampaigns/constants";
import transformRawCampaignDescendant from "api/campaigns/transformRawCampaignDescendant";
import {
  MESSAGE_GETTER_DICTIONARY,
  BACKEND_ERROR_IDENTIFIERS
} from "./constants";
import {
  CampaignsDashboardState,
  selectCampaignsDashboard,
  selectSearchTerm
} from "./dashboard";
import {
  setSearchTerm,
  updateCampaignsDashboard,
  setAppliedFilters
} from "./dashboard/actions";
import {
  selectIsCampaignUpdating,
  selectLoadAllCampaignsCancellationSource,
  selectLoadSingleCampaignCancellationSource,
  selectCampaignById
} from "./selectors";
import {
  ABORT_GET_CAMPAIGN_REQUEST,
  CampaignsActionTypes,
  CREATE_CAMPAIGN_PENDING,
  DELETE_CAMPAIGN_FAILURE,
  DELETE_CAMPAIGN_PENDING,
  DELETE_CAMPAIGN_SUCCESS,
  GET_ALL_CAMPAIGNS_FAILURE,
  GET_ALL_CAMPAIGNS_PENDING,
  GET_ALL_CAMPAIGNS_SUCCESS,
  GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_FAILURE,
  GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_PENDING,
  GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_SUCCESS,
  GET_BULK_ASSIGN_TEMPLATE_UPLOAD_FAILURE,
  GET_BULK_ASSIGN_TEMPLATE_UPLOAD_PENDING,
  GET_BULK_ASSIGN_TEMPLATE_UPLOAD_SUCCESS,
  GET_CAMPAIGN_FAILURE,
  GET_CAMPAIGN_PENDING,
  GET_CAMPAIGN_SUCCESS,
  GET_CAMPAIGN_TEMPLATE_DOWNLOAD_FAILURE,
  GET_CAMPAIGN_TEMPLATE_DOWNLOAD_PENDING,
  GET_CAMPAIGN_TEMPLATE_DOWNLOAD_SUCCESS,
  GET_CAMPAIGN_TEMPLATE_UPLOAD_FAILURE,
  GET_CAMPAIGN_TEMPLATE_UPLOAD_PENDING,
  GET_CAMPAIGN_TEMPLATE_UPLOAD_SUCCESS,
  SAVE_CAMPAIGN_FAILURE,
  SAVE_CAMPAIGN_SUCCESS,
  UPDATE_CAMPAIGN_PENDING
} from "./types";
import {
  DEFAULT_CAMPAIGN_FILTER_FIELDS,
  deriveCampaignFilterMap,
  determineCampaignFilterFromUrl,
  getCampaignEditsWithUpdatedStartDatesIfNecessary,
  getLineItemAndCreativesWithUpdatedDataIfNecessary,
  compareOverlappingCampaigns
} from "./utils";
import {
  GET_ALL_LINEITEMS_FAILURE,
  GET_ALL_LINEITEMS_PENDING,
  GET_ALL_LINEITEMS_SUCCESS,
  PagingChanges,
  selectLineItemsCancellationSource,
  selectSortBy,
  selectPaging,
  SortInfo,
  selectLineItemsPaging,
  CLEAR_CAMPAIGN_SORTBY,
  CLEAR_LINEITEM_SORTBY,
  selectAllCampaignsRecord,
  selectAllLineItemsRecord
} from ".";

interface setGetAllCampaignsPendingParameters {
  cancellationSource: CancelTokenSource;
  pagingChanges?: PagingChanges;
  sortInfo?: SortInfo;
  isPreviousOrNextPage?: boolean;
}

interface SetGetAllLineItemsPendingParameters {
  cancellationSource: CancelTokenSource;
  pagingChanges?: PagingChanges;
  sortInfo?: SortInfo;
  isPreviousOrNextPage?: boolean;
}

interface SetGetAllLineItemsSuccessParameters {
  lineItems: ShallowLineItem[];
  campaigns: RootCampaign[];
  pagingInfo?: PagingInfoResponse;
}

export interface GetAllCampaignsOptions {
  shouldShowToast: boolean;
  dates: {
    startDate: string;
    endDate: string;
  };
  pagingChanges?: PagingChanges;
  filterFields: CampaignFilterFields;
  sortInfo?: SortInfo;
  search?: string;
  isPaginationEnabled?: boolean;
  isPreviousOrNextPage?: boolean;
  isExportAll?: boolean;
  filters?: any;
  statuses: number[];
}

type InstructionFilterCategoryRequestKeys =
  | "stations"
  | "agencies"
  | "advertisers"
  | "advertiser_categories"
  | "iab_category_rtb_ids"
  | "campaign_names"
  | "media_types"
  | "updateUsers"
  | "campaign_statuses"
  | "rfp_client_codes"
  | "client_estimates"
  | "revenue_types"
  | "rfp_detail_names"
  | "rfp_names"
  | "adbook_clients"
  | "adbook_markets"
  | "adbook_package_names"
  | "adbook_statuses"
  | "estimate_codes"
  | "product_codes";

const filterCategoryToProps = (): Record<
  InstructionFilterCategory,
  InstructionFilterCategoryRequestKeys
> => {
  return {
    [InstructionFilterCategory.STATION]: "stations",
    [InstructionFilterCategory.AGENCY]: "agencies",
    [InstructionFilterCategory.ADVERTISER]: "advertisers",
    [InstructionFilterCategory.CATEGORY]: "iab_category_rtb_ids",
    [InstructionFilterCategory.UPDATED_BY]: "updateUsers",
    [InstructionFilterCategory.CAMPAIGN_NAME]: "campaign_names",
    [InstructionFilterCategory.MEDIA_TYPE]: "media_types",
    [InstructionFilterCategory.INSTRUCTION_STATUS]: "campaign_statuses",
    [InstructionFilterCategory.ESTIMATE_CODE]: "client_estimates",
    [InstructionFilterCategory.PRODUCT_CODE]: "product_codes",
    [InstructionFilterCategory.PREMION_CLIENT_CODE]: "rfp_client_codes",
    [InstructionFilterCategory.PREMION_CLIENT_ESTIMATE_CODE]:
      "client_estimates",
    [InstructionFilterCategory.PREMION_REVENUE_TYPE]: "revenue_types",
    [InstructionFilterCategory.PREMION_RFP_DETAIL_NAME]: "rfp_detail_names",
    [InstructionFilterCategory.PREMION_RFP_NAME]: "rfp_names",
    [InstructionFilterCategory.SCRIPPS_ADBOOK_CLIENT]: "adbook_clients",
    [InstructionFilterCategory.SCRIPPS_ADBOOK_MARKET]: "adbook_markets",
    [InstructionFilterCategory.SCRIPPS_ADBOOK_PACKAGE_NAME]:
      "adbook_package_names",
    [InstructionFilterCategory.SCRIPPS_ADBOOK_STATUS]: "adbook_statuses"
  };
};

const EXTENSION_CATEGORIES = new Set<string>([
  InstructionFilterCategory.ESTIMATE_CODE,
  InstructionFilterCategory.PRODUCT_CODE,
  InstructionFilterCategory.PREMION_CLIENT_CODE,
  InstructionFilterCategory.PREMION_CLIENT_ESTIMATE_CODE,
  InstructionFilterCategory.PREMION_REVENUE_TYPE,
  InstructionFilterCategory.PREMION_RFP_DETAIL_NAME,
  InstructionFilterCategory.PREMION_RFP_NAME,
  InstructionFilterCategory.SCRIPPS_ADBOOK_CLIENT,
  InstructionFilterCategory.SCRIPPS_ADBOOK_MARKET,
  InstructionFilterCategory.SCRIPPS_ADBOOK_PACKAGE_NAME,
  InstructionFilterCategory.SCRIPPS_ADBOOK_STATUS
]);

type UrlFilterParams = {
  [key in InstructionFilterCategoryRequestKeys]?: any;
} & {
  // eslint-disable-next-line camelcase
  ext_metas?: any;
};

interface BulkUpdateDataProviderRequest {
  campaignEdits?: Record<CampaignId, QuickEditCampaignPayload>;
  lineItemEdits?: Record<LineItemId, QuickEditLineItemPayload>;
}

interface BulkUpdateDataProviderRequestOptions {
  shouldShowToast: boolean;
}

const setGetAllCampaignsPending = ({
  cancellationSource,
  pagingChanges,
  sortInfo,
  isPreviousOrNextPage
}: setGetAllCampaignsPendingParameters): CampaignsActionTypes => ({
  type: GET_ALL_CAMPAIGNS_PENDING,
  meta: {
    cancellationSource,
    pagingChanges,
    sortInfo,
    isPreviousOrNextPage
  }
});

const setGetAllCampaignsFailure = (): CampaignsActionTypes => ({
  type: GET_ALL_CAMPAIGNS_FAILURE
});

const setGetAllCampaignsSuccess = (
  campaigns: RootCampaign[],
  pagingInfo?: PagingInfoResponse
): CampaignsActionTypes => ({
  type: GET_ALL_CAMPAIGNS_SUCCESS,
  payload: campaigns,
  meta: {
    timestamp: Date.now(),
    pagingInfoResponse: pagingInfo
  }
});

const setGetAllLineItemsPending = ({
  cancellationSource,
  pagingChanges,
  sortInfo,
  isPreviousOrNextPage
}: SetGetAllLineItemsPendingParameters): CampaignsActionTypes => ({
  type: GET_ALL_LINEITEMS_PENDING,
  meta: {
    cancellationSource,
    pagingChanges,
    sortInfo,
    isPreviousOrNextPage
  }
});

const setGetAllLineItemsFailure = (): CampaignsActionTypes => ({
  type: GET_ALL_LINEITEMS_FAILURE
});

const setGetAllLineItemsSuccess = ({
  lineItems,
  pagingInfo,
  campaigns
}: SetGetAllLineItemsSuccessParameters): CampaignsActionTypes => ({
  type: GET_ALL_LINEITEMS_SUCCESS,
  payload: lineItems,
  meta: {
    timestamp: Date.now(),
    pagingInfoResponse: pagingInfo,
    campaigns
  }
});

const setGetCampaignPending = (
  campaignId: string,
  cancellationSource: CancelTokenSource
): CampaignsActionTypes => ({
  type: GET_CAMPAIGN_PENDING,
  meta: {
    campaignId,
    cancellationSource
  }
});

const setGetCampaignFailure = (
  campaignId: string,
  error?: Error
): CampaignsActionTypes => ({
  type: GET_CAMPAIGN_FAILURE,
  meta: {
    campaignId,
    error: {
      message:
        (error && error.message) ||
        MESSAGE_GETTER_DICTIONARY.GET_ONE_FAILURE(campaignId)
    }
  }
});

const setGetCampaignSuccess = (
  campaign: RootCampaign
): CampaignsActionTypes => ({
  type: GET_CAMPAIGN_SUCCESS,
  payload: campaign,
  meta: {
    timestamp: Date.now()
  }
});

const setAbortGetCampaignRequest = (
  campaignId: string
): CampaignsActionTypes => ({
  type: ABORT_GET_CAMPAIGN_REQUEST,
  meta: {
    campaignId
  }
});

const setCreateCampaignPending = (): CampaignsActionTypes => ({
  type: CREATE_CAMPAIGN_PENDING
});

const setUpdateCampaignPending = (
  campaign: RootCampaign
): CampaignsActionTypes => ({
  type: UPDATE_CAMPAIGN_PENDING,
  meta: {
    campaign
  }
});

const setSaveCampaignSuccess = (
  campaign: RootCampaign,
  message: string
): CampaignsActionTypes => ({
  type: SAVE_CAMPAIGN_SUCCESS,
  payload: { campaign },
  meta: {
    success: {
      message
    }
  }
});

const setSaveCampaignFailure = (error?: Error): CampaignsActionTypes => ({
  type: SAVE_CAMPAIGN_FAILURE,
  meta: {
    error: {
      message:
        (error && error.message) || MESSAGE_GETTER_DICTIONARY.SAVE_FAILURE()
    }
  }
});

const setDeleteCampaignPending = (): CampaignsActionTypes => ({
  type: DELETE_CAMPAIGN_PENDING
});

const setCampaignDeleteFailure = (error?: Error): CampaignsActionTypes => ({
  type: DELETE_CAMPAIGN_FAILURE,
  meta: {
    error: {
      message:
        (error && error.message) || MESSAGE_GETTER_DICTIONARY.ARCHIVE_FAILURE()
    }
  }
});

export const setDeleteCampaignSuccess = (
  campaignId: string
): CampaignsActionTypes => ({
  type: DELETE_CAMPAIGN_SUCCESS,
  payload: { campaignId },
  meta: {
    success: {
      message: MESSAGE_GETTER_DICTIONARY.ARCHIVE_SUCCESS()
    }
  }
});

const setBulkTemplateDownloadPending = (): CampaignsActionTypes => ({
  type: GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_PENDING
});

const setBulkTemplateDownloadFailure = (
  error?: Error
): CampaignsActionTypes => ({
  type: GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_FAILURE,
  meta: {
    error: {
      message:
        (error && error.message) ||
        MESSAGE_GETTER_DICTIONARY.TEMPLATE_DOWNLOAD_FAILURE()
    }
  }
});

const setBulkTemplateDownloadSuccess = (): CampaignsActionTypes => ({
  type: GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_SUCCESS,
  meta: {
    success: {
      message: MESSAGE_GETTER_DICTIONARY.TEMPLATE_DOWNLOAD_SUCCESS()
    }
  }
});

// eslint-disable-next-line no-unused-vars
const setBulkTemplateUploadPending = (): CampaignsActionTypes => ({
  type: GET_BULK_ASSIGN_TEMPLATE_UPLOAD_PENDING
});

// eslint-disable-next-line no-unused-vars
const setBulkTemplateUploadFailure = (error?: Error): CampaignsActionTypes => ({
  type: GET_BULK_ASSIGN_TEMPLATE_UPLOAD_FAILURE,
  meta: {
    error: {
      message:
        (error && error.message) ||
        MESSAGE_GETTER_DICTIONARY.TEMPLATE_UPLOAD_FAILURE()
    }
  }
});

// eslint-disable-next-line no-unused-vars
const setBulkTemplateUploadSuccess = (): CampaignsActionTypes => ({
  type: GET_BULK_ASSIGN_TEMPLATE_UPLOAD_SUCCESS,
  meta: {
    success: {
      message: MESSAGE_GETTER_DICTIONARY.TEMPLATE_UPLOAD_SUCCESS()
    }
  }
});
const setCampaignTemplateDownloadPending = (): CampaignsActionTypes => ({
  type: GET_CAMPAIGN_TEMPLATE_DOWNLOAD_PENDING
});

const setCampaignTemplateDownloadFailure = (
  error?: Error
): CampaignsActionTypes => ({
  type: GET_CAMPAIGN_TEMPLATE_DOWNLOAD_FAILURE,
  meta: {
    error: {
      message:
        (error && error.message) ||
        MESSAGE_GETTER_DICTIONARY.TEMPLATE_DOWNLOAD_FAILURE()
    }
  }
});

const setCampaignTemplateDownloadSuccess = (
  template: ArrayBuffer
): CampaignsActionTypes => ({
  type: GET_CAMPAIGN_TEMPLATE_DOWNLOAD_SUCCESS,
  payload: template,
  meta: {
    success: {
      message: MESSAGE_GETTER_DICTIONARY.TEMPLATE_DOWNLOAD_SUCCESS()
    }
  }
});

const setCampaignTemplateUploadPending = (): CampaignsActionTypes => ({
  type: GET_CAMPAIGN_TEMPLATE_UPLOAD_PENDING
});

const setCampaignTemplateUploadFailure = (
  error?: Error
): CampaignsActionTypes => ({
  type: GET_CAMPAIGN_TEMPLATE_UPLOAD_FAILURE,
  meta: {
    error: {
      message:
        (error && error.message) ||
        MESSAGE_GETTER_DICTIONARY.TEMPLATE_UPLOAD_FAILURE()
    }
  }
});

const setCampaignTemplateUploadSuccess = (): CampaignsActionTypes => ({
  type: GET_CAMPAIGN_TEMPLATE_UPLOAD_SUCCESS,
  meta: {
    success: {
      message: MESSAGE_GETTER_DICTIONARY.TEMPLATE_UPLOAD_SUCCESS()
    }
  }
});

const clearCampaignSortBy = (): CampaignsActionTypes => ({
  type: CLEAR_CAMPAIGN_SORTBY
});

const clearLineItemsSortBy = (): CampaignsActionTypes => ({
  type: CLEAR_LINEITEM_SORTBY
});

export const abortGetAllCampaigns =
  (): AppThunk<void> => (dispatch, getState) => {
    const prevCancelSource = selectLoadAllCampaignsCancellationSource(
      getState()
    );

    if (prevCancelSource) {
      prevCancelSource.cancel();
    }
  };

export const abortGetCampaign =
  (id: string): AppThunk<void> =>
  (dispatch, getState) => {
    const prevCancelSource = selectLoadSingleCampaignCancellationSource(
      getState()
    );

    if (prevCancelSource[id]) {
      prevCancelSource[id].cancel();
    }
    dispatch(setAbortGetCampaignRequest(id));
  };

export const createUrlParamsWithAppliedFilters = (
  appliedFilters: ManageCampaignsInstructionFilter[]
) => {
  const params: UrlFilterParams = {};

  appliedFilters.forEach(appliedFilter => {
    const key = filterCategoryToProps()[appliedFilter.category];
    if (EXTENSION_CATEGORIES.has(appliedFilter.category)) {
      if (!params.ext_metas) {
        params.ext_metas = {};
      }
      if (!params.ext_metas[key]) {
        params.ext_metas[key] = [];
      }
      params.ext_metas[key].push(appliedFilter.value);
    } else {
      if (!params[key]) {
        params[key] = [];
      }
      if (key === "campaign_statuses") {
        params[key]?.push(+appliedFilter.value);
      } else {
        params[key]?.push(appliedFilter.value);
      }
    }
  });

  Object.entries(params).forEach(([key, value]) => {
    if (
      key === "campaign_statuses" ||
      key === "iab_category_rtb_ids" ||
      key === "media_types"
    ) {
      params[key] = value.join(",");
    } else params[key] = JSON.stringify(value);
  });

  return params;
};

const getFilterName = (columnName: ManageCampaignColumn) =>
  manageCampaignColumnIdToCampaignBackendFilterName()[columnName] || columnName;

export const getAllCampaigns =
  ({
    shouldShowToast = false,
    dates,
    pagingChanges,
    sortInfo,
    search,
    isExportAll,
    isPaginationEnabled = false,
    isPreviousOrNextPage,
    filterFields = DEFAULT_CAMPAIGN_FILTER_FIELDS,
    statuses = []
  }: Partial<GetAllCampaignsOptions> = {}): AppThunk<Promise<RootCampaign[]>> =>
  (dispatch, getState) => {
    const { selectedFilters, dates: dashboardDates } = selectCampaignsDashboard(
      getState()
    );
    const datesToSend = dates || {
      startDate: dashboardDates.startDate.toISOString(),
      endDate: dashboardDates.endDate.toISOString()
    };
    const filters = createUrlParamsWithAppliedFilters(selectedFilters);

    const prevCancellationSource = selectLoadAllCampaignsCancellationSource(
      getState()
    );
    if (prevCancellationSource) {
      prevCancellationSource.cancel();
    }

    const cancellationSource = axios.CancelToken.source();
    if (!isExportAll) {
      dispatch(
        setGetAllCampaignsPending({
          cancellationSource,
          pagingChanges,
          sortInfo,
          isPreviousOrNextPage: isPaginationEnabled && isPreviousOrNextPage
        })
      );
    }
    if (search) {
      dispatch(clearCampaignSortBy());
    }
    const paging = selectPaging(getState());
    const pageSize =
      pagingChanges?.newPageSize?.toString() || paging.pageSize.toString();
    const pageToken = paging.currentPageToken.toString();
    const sortBy = sortInfo || selectSortBy(getState());
    const currentSearch =
      search !== undefined ? search : selectSearchTerm(getState());

    const includesSearch = madSDK.featureFlags.isFlagEnabled(
      RemoteConfigProperties.INCLUDES_SEARCH
    );

    return getRawRootCampaigns(
      cancellationSource,
      {
        runsBetween: `${datesToSend?.startDate}|${datesToSend?.endDate}`,
        statuses: statuses ? statuses.join(",") : "",
        // only send page_size when not export all scenario and pagination is enabled
        ...(!isExportAll &&
          isPaginationEnabled &&
          pageSize && { page_size: pageSize }),
        // only send page_token and offset when not export all scenario, pagination is enabled and is ongoing pagination (user clicked next or previous)
        ...(!isExportAll &&
          isPaginationEnabled &&
          isPreviousOrNextPage &&
          pageToken && {
            page_token: pageToken,
            offset: pagingChanges?.offset?.toString() || "0"
          }),
        ...(sortBy && {
          sortBy: `${getFilterName(sortBy.fieldId)} ${
            SORT_BY_ABBREV[sortBy.order]
          }`
        }),
        ...(currentSearch && { search: currentSearch }),
        ...(includesSearch && { fuzziness: "1" }),
        ...filters
      },
      // For filters where fields=id,name etc
      deriveCampaignFilterMap(filterFields)
    ).then(
      async ({ campaigns, pagingInfo }) => {
        const campaignsWithoutIds = campaigns
          ? campaigns.map(transformRawRootCampaign)
          : [];
        const formattedCampaigns = await Promise.all(
          campaignsWithoutIds.map(async (el): Promise<RootCampaign> => {
            const { advertiserId, agencyId, ownerIdsWithTypes, stationIds } =
              await appendOwnerIdsToShallowCampaign(
                el.ownerIdsMadhiveEncrypted,
                el.parent
              );
            return {
              ...el,
              advertiserId,
              agencyId,
              ownerIdsWithTypes,
              organizationId: el.parent,
              stationId: stationIds.length ? stationIds[0] : ""
            };
          })
        );
        if (!isExportAll) {
          dispatch(setGetAllCampaignsSuccess(formattedCampaigns, pagingInfo));
          dispatch(setSearchTerm(currentSearch));
          dispatch(setAppliedFilters(selectedFilters));
        }
        return formattedCampaigns;
      },
      error => {
        if (!axios.isCancel(error) && error.name !== "AbortError") {
          if (shouldShowToast && !isExportAll) {
            dispatch(
              showError(
                (error && error.message) ||
                  MESSAGE_GETTER_DICTIONARY.GET_ALL_FAILURE()
              )
            );
          }
          if (!isExportAll) {
            dispatch(setGetAllCampaignsFailure());
          }
          throw error;
        } else {
          console.log("Campaigns endpoint request aborted");
        }
        return [];
      }
    );
  };

export const getTransformedCampaigns = (
  cancellationSource: CancelTokenSource,
  params: Record<string, string>
) =>
  getRawRootCampaigns(cancellationSource, params).then(
    async ({ campaigns }) => {
      const campaignsWithoutIds = campaigns
        ? campaigns.map(transformRawRootCampaign)
        : [];
      const formattedCampaigns = await Promise.all(
        campaignsWithoutIds.map(async (el): Promise<RootCampaign> => {
          const { advertiserId, agencyId, ownerIdsWithTypes, stationIds } =
            await appendOwnerIdsToShallowCampaign(
              el.ownerIdsMadhiveEncrypted,
              el.parent
            );
          return {
            ...el,
            advertiserId,
            agencyId,
            ownerIdsWithTypes,
            organizationId: el.parent,
            stationId: stationIds.length ? stationIds[0] : ""
          };
        })
      );
      return formattedCampaigns;
    }
  );

export const getAllLineItems =
  ({
    shouldShowToast = false,
    dates,
    pagingChanges,
    sortInfo,
    search,
    isExportAll,
    isPreviousOrNextPage,
    filterFields = DEFAULT_CAMPAIGN_FILTER_FIELDS,
    statuses = []
  }: Partial<GetAllCampaignsOptions> = {}): AppThunk<
    Promise<ShallowLineItem[]>
  > =>
  (dispatch, getState) => {
    const prevCancellationSource = selectLineItemsCancellationSource(
      getState()
    );
    const { selectedFilters, dates: dashboardDates } = selectCampaignsDashboard(
      getState()
    );
    const filters = createUrlParamsWithAppliedFilters(selectedFilters);
    const datesToSend = dates || {
      startDate: dashboardDates.startDate.toISOString(),
      endDate: dashboardDates.endDate.toISOString()
    };
    if (prevCancellationSource) {
      prevCancellationSource.cancel();
    }
    const cancellationSource = axios.CancelToken.source();
    if (!isExportAll) {
      dispatch(
        setGetAllLineItemsPending({
          cancellationSource,
          pagingChanges,
          sortInfo,
          isPreviousOrNextPage
        })
      );
    }
    if (search) {
      dispatch(clearLineItemsSortBy());
    }
    const lineItemsPaging = selectLineItemsPaging(getState());
    const pageSize =
      pagingChanges?.newPageSize?.toString() ||
      lineItemsPaging.pageSize.toString();
    const pageToken = lineItemsPaging.currentPageToken.toString();
    const sortBy = sortInfo || selectSortBy(getState());
    const currentSearch =
      search !== undefined ? search : selectSearchTerm(getState());
    const includesSearch = madSDK.featureFlags.isFlagEnabled(
      RemoteConfigProperties.INCLUDES_SEARCH
    );

    return getRawRootLineItems(
      cancellationSource,
      {
        runsBetween: `${datesToSend?.startDate}|${datesToSend?.endDate}`,
        statuses: statuses ? statuses.join(",") : "",

        ...(!isExportAll && pageSize && { page_size: pageSize }),
        ...(!isExportAll &&
          isPreviousOrNextPage &&
          pageToken && {
            page_token: pageToken,
            offset: pagingChanges?.offset?.toString() || "0"
          }),
        ...(sortBy && {
          sortBy: `${getFilterName(sortBy.fieldId)} ${
            SORT_BY_ABBREV[sortBy.order]
          }`
        }),
        ...(currentSearch && { search: currentSearch }),
        ...(includesSearch && { fuzziness: "1" }),
        ...filters
      },
      // For filters where fields=id,name etc
      deriveCampaignFilterMap(filterFields)
    ).then(
      async ({ lineItems, pagingInfo }) => {
        const transformedLineItems = lineItems
          ? lineItems.map(lineItem =>
              transformRawCampaignDescendant(
                lineItem,
                lineItem.parent,
                lineItem.parent_name
              )
            )
          : [];
        if (!isExportAll) {
          try {
            const campaigns =
              transformedLineItems.length > 0
                ? await getTransformedCampaigns(cancellationSource, {
                    ids: JSON.stringify(
                      transformedLineItems
                        .map(lineItem => lineItem.parentCampaignId)
                        .filter(isTruthy)
                    )
                  })
                : [];
            dispatch(
              setGetAllLineItemsSuccess({
                lineItems: transformedLineItems,
                pagingInfo,
                campaigns
              })
            );
          } catch (error) {
            if (axios.isCancel(error) || error.name === "AbortError") {
              console.log("Campaigns endpoint request aborted");
            } else {
              throw error;
            }
          }
        }
        return transformedLineItems;
      },
      error => {
        if (!axios.isCancel(error) && error.name !== "AbortError") {
          if (shouldShowToast && !isExportAll) {
            dispatch(
              showError(
                (error && error.message) ||
                  MESSAGE_GETTER_DICTIONARY.GET_ALL_FAILURE()
              )
            );
          }
          if (!isExportAll) {
            dispatch(setGetAllLineItemsFailure());
          }
          throw error;
        } else {
          console.log("Line item endpoint request aborted");
        }
        return [];
      }
    );
  };

export const getAllCampaignsByUrlFilters =
  (
    isPaginationEnabled: boolean = false,
    filterStatuses?: number[]
  ): AppThunk<Promise<RootCampaign[]>> =>
  dispatch =>
    dispatch(
      getAllCampaigns({
        shouldShowToast: true,
        isPaginationEnabled,
        filterFields: determineCampaignFilterFromUrl(),
        statuses: filterStatuses
      })
    );

export const getAllLineItemsByUrlFilters =
  (filterStatuses?: number[]): AppThunk<Promise<ShallowLineItem[]>> =>
  dispatch =>
    dispatch(
      getAllLineItems({
        shouldShowToast: true,
        filterFields: determineCampaignFilterFromUrl(),
        statuses: filterStatuses
      })
    );

const parseCampaignFromGetCampaign = async (
  campaign: CampaignIdEndpointGetResponse
) => {
  const formattedDetails = transformRawCampaignDetails(campaign);
  const formattedRootCampaign = transformRawRootCampaign(campaign);

  const { advertiserId, agencyId, ownerIdsWithTypes, stationIds } =
    await appendOwnerIdsToShallowCampaign(
      formattedRootCampaign.ownerIdsMadhiveEncrypted,
      formattedRootCampaign.parent
    );

  return {
    ...formattedRootCampaign,
    advertiserId,
    agencyId,
    ownerIdsWithTypes,
    stationId: stationIds[0] || "",
    organizationId: formattedRootCampaign.parent,
    details: {
      data: formattedDetails,
      error: false,
      isLoading: false
    }
  };
};

export const getCampaign =
  (id: string): AppThunk<Promise<RootCampaign>> =>
  async (dispatch, getState) => {
    const prevCancelSource = selectLoadSingleCampaignCancellationSource(
      getState()
    );

    if (prevCancelSource[id]) {
      prevCancelSource[id].cancel();
    }
    const cancellationSource = axios.CancelToken.source();

    dispatch(setGetCampaignPending(id, cancellationSource));

    return getCampaignDetails(id, cancellationSource, {
      includeArchived: true
    }).then(
      async campaign => {
        const newRootCampaign = await parseCampaignFromGetCampaign(campaign);

        dispatch(setGetCampaignSuccess(newRootCampaign));
        return newRootCampaign;
      },
      error => {
        if (axios.isCancel(error)) {
          dispatch(setAbortGetCampaignRequest(id));
        } else if (error.isCampaignArchived) {
          dispatch(setGetCampaignFailure(error));
        } else {
          dispatch(setGetCampaignFailure(id, error));
          FullStory.event(FullStoryCustomEvent.CAMPAIGN_DETAILS_GET_FAILURE, {
            error
          });
        }
        throw error;
      }
    );
  };

export const fetchCampaignWithSuccess =
  (campaignId: string, message: string): AppThunk<Promise<RootCampaign>> =>
  async dispatch => {
    const formattedCampaign = await dispatch(getCampaign(campaignId));
    dispatch(setSaveCampaignSuccess(formattedCampaign, message));
    return formattedCampaign;
  };

export const createCampaign =
  (
    draftCampaign: DraftCampaign,
    parentCampaignId?: string
  ): AppThunk<Promise<RootCampaign>> =>
  async (dispatch, getState) => {
    if (selectIsCampaignUpdating(getState())) {
      const currentlyUpdatingError = new Error(
        MESSAGE_GETTER_DICTIONARY.CURRENTLY_UPDATING()
      );

      dispatch(setSaveCampaignFailure(currentlyUpdatingError));
      throw currentlyUpdatingError;
    }

    dispatch(setCreateCampaignPending());

    const key = await madSDK.cryptography.mintKey(ObjType.INST);

    if (!key) {
      dispatch(setSaveCampaignFailure(new Error("Failed to mint inst key.")));

      throw {
        type: "Failed to mint inst key."
      };
    }

    const cancellationSource = axios.CancelToken.source();

    const createFn = parentCampaignId
      ? duplicate(draftCampaign, parentCampaignId, key, cancellationSource)
      : create(draftCampaign, key, cancellationSource);

    return createFn.then(
      ({ id }) =>
        dispatch(
          fetchCampaignWithSuccess(
            id,
            MESSAGE_GETTER_DICTIONARY.CREATE_SUCCESS()
          )
        ),
      error => {
        if (isHttpStatusForbidden(error)) {
          const errorMsg = new Error(
            MESSAGE_GETTER_DICTIONARY.FORBIDDEN_FROM_UPDATING_FAILURE()
          );
          dispatch(setSaveCampaignFailure(errorMsg));
        } else if (error && error.name === "REFRESH_AFTER_CREATION_FAILURE") {
          const errorMsg = new Error(
            MESSAGE_GETTER_DICTIONARY.LATEST_DATA_UNABLE_TO_BE_FETCHED_FAILURE()
          );
          dispatch(setSaveCampaignFailure(errorMsg));
        } else {
          const errorMsg = new Error(
            `Failed to create a campaign with these settings. Make sure all fields are valid and try again. ${
              isErrorResponseValid(error)
                ? `${deriveErrorResponse(error).filter(isTruthy).join(",")}.`
                : ""
            }`
          );
          dispatch(setSaveCampaignFailure(errorMsg));
        }
        dispatch(setSaveCampaignFailure(error));
        FullStory.event(FullStoryCustomEvent.CAMPAIGN_CREATION_FAILURE, error);
        throw error;
      }
    );
  };

export const requestCampaignCreation =
  (
    campaign: DraftCampaign,
    duplicatedCampaignId?: string
  ): AppThunk<Promise<RootCampaign>> =>
  dispatch =>
    dispatch(createCampaign(campaign, duplicatedCampaignId));

export const requestCampaignUpdate =
  (
    newCampaign: RootCampaign,
    options: {
      isRecursive: boolean;
    }
  ): AppThunk<Promise<void>> =>
  async (dispatch, getState) => {
    const { isRecursive } = options;
    if (!isRecursive && selectIsCampaignUpdating(getState())) {
      const currentlyUpdatingError = new Error(
        MESSAGE_GETTER_DICTIONARY.CURRENTLY_UPDATING()
      );

      dispatch(setSaveCampaignFailure(currentlyUpdatingError));
      throw currentlyUpdatingError;
    }

    if (!isRecursive) dispatch(setUpdateCampaignPending(newCampaign));

    return update(newCampaign).then(
      async () => {
        await dispatch(
          fetchCampaignWithSuccess(
            newCampaign.id,
            MESSAGE_GETTER_DICTIONARY.UPDATE_SUCCESS()
          )
        );
      },
      error => {
        if (isHttpStatusForbidden(error)) {
          const errorMsg = new Error(
            MESSAGE_GETTER_DICTIONARY.FORBIDDEN_FROM_UPDATING_FAILURE()
          );
          dispatch(setSaveCampaignFailure(errorMsg));
        } else {
          const derivedErrorMessage =
            deriveErrorResponse(error).filter(isTruthy);
          const errorMsg = new Error(
            `Failed to update campaign with ID: ${newCampaign.id}. ${
              isErrorResponseValid(error)
                ? `${derivedErrorMessage.join(",")}.`
                : ""
            }`
          );
          /**
           * Here the user is trying to update a campaign with stale data. We will attempt once to refresh the data without him knowing
           * If we cannot, we will proceed without letting him know we tried
           */
          if (
            derivedErrorMessage.includes(
              BACKEND_ERROR_IDENTIFIERS.OUT_OF_SYNC
            ) &&
            !isRecursive
          ) {
            const cancellationSource = axios.CancelToken.source();
            getCampaignDetails(newCampaign.id, cancellationSource)
              .then(async campaign => {
                const serverCampaign = await parseCampaignFromGetCampaign(
                  campaign
                );
                // if it so happens that we got here, but we do have same version keys, there was an error in the backend
                if (serverCampaign.version <= newCampaign.version) {
                  dispatch(
                    setSaveCampaignFailure(
                      new Error(MESSAGE_GETTER_DICTIONARY.SAVE_FAILURE())
                    )
                  );
                  FullStory.event(
                    FullStoryCustomEvent.CAMPAIGN_UPDATE_FAILURE,
                    error
                  );
                  throw error;
                } else {
                  const oldCampaign = selectCampaignById(
                    getState(),
                    newCampaign.id
                  );
                  const { noOverlap, currentUpdatedKeys } =
                    compareOverlappingCampaigns(
                      oldCampaign,
                      newCampaign,
                      serverCampaign
                    );

                  if (noOverlap) {
                    const mergedCampaign = { ...serverCampaign };
                    currentUpdatedKeys.forEach(
                      key => (mergedCampaign[key] = newCampaign[key])
                    );
                    dispatch(requestCampaignUpdate(mergedCampaign, options));
                  } else {
                    // maybe we have some more options here, but updating only some properties might cause confusion with the user
                    dispatch(setSaveCampaignFailure(errorMsg));
                    FullStory.event(
                      FullStoryCustomEvent.CAMPAIGN_UPDATE_FAILURE,
                      error
                    );
                    throw error;
                  }
                }
              })
              .catch(() => {
                dispatch(setSaveCampaignFailure(errorMsg));
                FullStory.event(
                  FullStoryCustomEvent.CAMPAIGN_UPDATE_FAILURE,
                  error
                );
                // we throw the original error here
                throw error;
              });
          } else {
            dispatch(setSaveCampaignFailure(errorMsg));
            FullStory.event(
              FullStoryCustomEvent.CAMPAIGN_UPDATE_FAILURE,
              error
            );
            throw error;
          }
        }
      }
    );
  };

export const deleteCampaign =
  (
    campaignId: string,
    execBeforeStoreUpdate?: () => void
  ): AppThunk<Promise<string>> =>
  async (dispatch, getState) => {
    if (selectIsCampaignUpdating(getState())) {
      const alreadyUpdatingError = new Error(
        MESSAGE_GETTER_DICTIONARY.CURRENTLY_UPDATING()
      );
      dispatch(setSaveCampaignFailure(alreadyUpdatingError));

      throw alreadyUpdatingError;
    }

    dispatch(setDeleteCampaignPending());
    const cancellationSource = axios.CancelToken.source();
    return archive(campaignId, cancellationSource).then(
      () => {
        if (execBeforeStoreUpdate) {
          execBeforeStoreUpdate();
        }

        dispatch(setDeleteCampaignSuccess(campaignId));
        return campaignId;
      },
      error => {
        dispatch(setCampaignDeleteFailure(error));
        throw error;
      }
    );
  };

export const downloadBulkAssignTemplate =
  (lineItems: ShallowLineItem[]): AppThunk<Promise<ArrayBuffer>> =>
  async dispatch => {
    dispatch(setBulkTemplateDownloadPending());
    return await getBulkAssignTemplate(lineItems).then(
      (res: ArrayBuffer) => {
        dispatch(setBulkTemplateDownloadSuccess());
        return res;
      },
      error => {
        dispatch(setBulkTemplateDownloadFailure(error));
        throw error;
      }
    );
  };

export const downloadCampaignTemplate =
  (): AppThunk<Promise<ArrayBuffer>> => async dispatch => {
    dispatch(setCampaignTemplateDownloadPending());
    return await getCampaignTemplate().then(
      (res: ArrayBuffer) => {
        dispatch(setCampaignTemplateDownloadSuccess(res));
        return res;
      },
      error => {
        dispatch(setCampaignTemplateDownloadFailure(error));
        throw error;
      }
    );
  };

export const uploadCampaignTemplate =
  (blob: Blob): AppThunk<Promise<boolean>> =>
  async dispatch => {
    try {
      dispatch(setCampaignTemplateUploadPending());
      await uploadCampaignTemplateApi(blob);
      dispatch(setCampaignTemplateUploadSuccess());
      return true;
    } catch (err) {
      const formattedError = new Error(
        `Failed to create campaign(s) from template. ${
          isErrorResponseValid(err)
            ? `${deriveErrorResponse(err).filter(isTruthy).join(",")}.`
            : ""
        }`
      );

      dispatch(setCampaignTemplateUploadFailure(formattedError));

      throw formattedError;
    }
  };

export const createNotification =
  (
    notification: SmithersNotificationRaw,
    uploadUser: User,
    date: Date
  ): AppThunk<Promise<string | void> | undefined> =>
  async (dispatch, _, { createNewFirestoreNotification }) => {
    try {
      const notificationID = await createNewFirestoreNotification(
        notification,
        uploadUser,
        date
      );
      if (!notificationID) {
        throw new Error("Unable to create new firestore notification");
      }

      return notificationID;
    } catch (e) {
      dispatch(showError(e.message));
    }
  };

export const requestBulkCreativeAssignUpdate =
  (lineItemData: ShallowLineItem[]): AppThunk<Promise<void>> =>
  async dispatch => {
    try {
      if (!lineItemData.length) {
        return;
      }

      const cancellationSource = axios.CancelToken.source();

      await updateInstructionsWithBulkAssignCreatives(
        lineItemData,
        cancellationSource
      );

      try {
        const products = await dispatch(getAllProducts());
        const seenCampaigns = new Set<string>();
        const { lineItemIds, campaignIds } = lineItemData.reduce(
          (acc, cur) => {
            acc.lineItemIds.push(cur.id);
            if (!seenCampaigns.has(cur.parentCampaignId)) {
              acc.campaignIds.push(cur.parentCampaignId);
            }
            seenCampaigns.add(cur.parentCampaignId);
            return acc;
          },
          { lineItemIds: [] as string[], campaignIds: [] as string[] }
        );
        const promises = [
          ...lineItemIds.map(id =>
            dispatch(lineItemDetailToBeFetched(id, products, true, true))
          ),
          ...campaignIds.map(id => dispatch(getCampaign(id)))
        ] as Array<Promise<any>>;

        // Need to run these async fns in order, need to get all campaigns first, then its line items, then possibly line item details/flight details
        await Promise.all(promises);
        dispatch(showSuccess(MESSAGE_GETTER_DICTIONARY.EDITS_SAVE_SUCCESS()));
      } catch (e) {
        throw {
          type: "FAILED_TO_REFRESH"
        };
      }
    } catch (e) {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else if (e && e.type && e.type === "FAILED_TO_REFRESH") {
        dispatch(
          showError(MESSAGE_GETTER_DICTIONARY.REFRESH_DATA_FAILURE(), {
            timeout: 2000
          })
        );
      } else {
        if (e.type !== "FAILED_TO_REFRESH") {
          dispatch(
            showError(MESSAGE_GETTER_DICTIONARY.BULK_UPDATE_FAILURE(e), {
              timeout: 3000
            })
          );
        }
        /** Make sure we bubble up the error so that any component that calls this function can handle it. */
        throw e;
      }
    }
  };

export const requestBulkAssignTemplateUpload =
  (blob: Blob): AppThunk<Promise<any>> =>
  async dispatch => {
    try {
      const uploadBulkTemplateResponse = await uploadBulkAssignTemplate(blob);
      dispatch(
        showSuccess(MESSAGE_GETTER_DICTIONARY.UPLOADED_TEMPLATE_SUCCESS())
      );
      try {
        dispatch(
          showSuccess(
            MESSAGE_GETTER_DICTIONARY.UPLOADED_TEMPLATE_SUCCESS_DATA_FETCHED_SUCCESS()
          )
        );
      } catch (e) {
        dispatch(
          showError(
            MESSAGE_GETTER_DICTIONARY.UPLOADED_TEMPLATE_SUCCESS_DATA_FETCHED_FAILURE()
          )
        );
      }
      return uploadBulkTemplateResponse;
    } catch (bulkTemplateUploadError) {
      const isValidError = isErrorResponseValid(bulkTemplateUploadError);
      if (isValidError) {
        dispatch(
          showError(
            MESSAGE_GETTER_DICTIONARY.UPLOAD_TEMPLATE_FAILURE(
              bulkTemplateUploadError
            ),
            { timeout: 3000 }
          )
        );
      } else {
        dispatch(showError(`Failed to upload template`, { timeout: 3000 }));
      }
      throw bulkTemplateUploadError;
    }
  };
export const requestBulkDataUpdate =
  (
    { campaignEdits, lineItemEdits }: BulkUpdateDataProviderRequest,
    isStartASAPEnabled: boolean,
    options: BulkUpdateDataProviderRequestOptions = { shouldShowToast: true }
  ): AppThunk<Promise<any>> =>
  async (dispatch, getState) => {
    const allCampaigns = selectAllCampaignsRecord(getState());
    const allLineItems = selectAllLineItemsRecord(getState());
    try {
      const campaignEditsArray =
        getCampaignEditsWithUpdatedStartDatesIfNecessary(
          campaignEdits,
          allCampaigns,
          isStartASAPEnabled
        );
      const lineItemEditsArray =
        getLineItemAndCreativesWithUpdatedDataIfNecessary(
          lineItemEdits,
          allLineItems,
          isStartASAPEnabled
        );

      if (!campaignEditsArray.length && !lineItemEditsArray.length) {
        return;
      }

      const bulkData = [...campaignEditsArray, ...lineItemEditsArray];

      const cancellationSource = axios.CancelToken.source();

      const bulkUpdateResponse = await updateBulkInstructions(
        bulkData,
        cancellationSource
      );

      const lineItemIds = Object.values(lineItemEdits || {}).map(
        lineItem => lineItem.id
      );
      let campaignIds = new Set(
        Object.values(campaignEdits || {}).map(campaign => campaign.id)
      );
      let lineItemSuccessData;

      if (lineItemIds.length) {
        const { lineItems: refreshedLineItems, pagingInfo } =
          await getRawRootLineItems(cancellationSource, {
            ids: JSON.stringify(lineItemIds)
          });

        lineItemSuccessData = {
          lineItems: refreshedLineItems.map(lineItem =>
            transformRawCampaignDescendant(
              lineItem,
              lineItem.parent,
              lineItem.parent_name
            )
          ),
          pagingInfo
        };

        // Make sure each line item's parent campaign is refreshed because the edits could be only line items.
        campaignIds = new Set([
          ...campaignIds,
          ...refreshedLineItems.map(lineItem => lineItem.parent)
        ]);
      }

      const formattedRefreshedCampaigns = await getTransformedCampaigns(
        cancellationSource,
        {
          ids: JSON.stringify(Array.from(campaignIds))
        }
      );

      if (lineItemSuccessData) {
        dispatch(
          setGetAllLineItemsSuccess({
            lineItems: lineItemSuccessData.lineItems,
            pagingInfo: lineItemSuccessData.pagingInfo,
            campaigns: formattedRefreshedCampaigns
          })
        );
      } else {
        dispatch(setGetAllCampaignsSuccess(formattedRefreshedCampaigns));
      }

      if (options.shouldShowToast) {
        dispatch(showSuccess(MESSAGE_GETTER_DICTIONARY.EDITS_SAVE_SUCCESS()));
      }
      return bulkUpdateResponse;
    } catch (e) {
      if (axios.isCancel(e)) {
        throw { name: "AbortError" };
      } else if (e && e.type && e.type === "FAILED_TO_REFRESH") {
        dispatch(
          showError(MESSAGE_GETTER_DICTIONARY.REFRESH_DATA_FAILURE(), {
            timeout: 2000
          })
        );

        return Promise.resolve([]);
      } else {
        console.error(e);
        if (e.type !== "FAILED_TO_REFRESH") {
          dispatch(
            showError(MESSAGE_GETTER_DICTIONARY.BULK_UPDATE_FAILURE(e), {
              timeout: 3000
            })
          );
        }
        /** Make sure we bubble up the error so that any component that calls this function can handle it. */
        throw e;
      }
    }
  };

export const initCampaignsDashboard =
  (
    defaultColumns: CampaignsDashboardState["defaultColumns"],
    columnsForUser: CampaignsDashboardState["columnsForUser"],
    dates: CampaignsDashboardState["dates"],
    availableDashboardDates: CampaignsDashboardState["availableDashboardDates"]
  ): AppThunk<void> =>
  dispatch => {
    dispatch(
      updateCampaignsDashboard({
        defaultColumns,
        columnsForUser,
        dates,
        availableDashboardDates
      })
    );
  };

export const requestCampaignStatusUpdate =
  (
    campaignId: string,
    updateStatusTo: ServiceStatus
  ): AppThunk<Promise<void>> =>
  async dispatch => {
    if (updateStatusTo === ServiceStatus.ARCHIVED) {
      // if request is to archive, use deleteCampaign to
      // archive campaign and all the related line items
      await dispatch(deleteCampaign(campaignId));
    } else {
      // if request is to unarchive, use campaign update
      // and line item update to change status to DRAFT
      const campaign = await dispatch(getCampaign(campaignId));

      if (campaign.status === CampaignStatus.ARCHIVED) {
        try {
          await unArchiveCampaign(campaign);
        } catch (err) {
          dispatch(
            setSaveCampaignFailure(
              new Error(
                `${MESSAGE_GETTER_DICTIONARY.SAVE_FAILURE()}${
                  isErrorResponseValid(err)
                    ? // Accounts for multiple errors when saving
                      `${deriveErrorResponse(err).filter(isTruthy).join(",")}.`
                    : // Fallback in the event there is no error message
                      ""
                }`
              )
            )
          );
          return;
        }
      } else {
        const editModeVersionUpdated: RootCampaign = {
          ...campaign,
          rawStatus: updateStatusTo
        };

        // update campaign
        await update(editModeVersionUpdated);

        // fetch the latest product
        const allProducts = await dispatch(getAllProducts());

        // update all line items
        await Promise.all(
          campaign.lineItems.map(async lineItem => {
            // Get the latest data for update
            const freshLineItemData = await dispatch(
              lineItemDetailToBeFetched(lineItem.id, allProducts, true, true)
            ).catch(e => {
              console.error("Error on get fresh line item data", e);
            });

            if (freshLineItemData) {
              const lineItemDataToUpdate: LineItemFormatted = {
                ...freshLineItemData,
                rawStatus: updateStatusTo
              };

              await dispatch(
                lineItemUpdate(lineItemDataToUpdate, {
                  ignorePreviousRequest: true
                })
              ).catch(e => {
                console.error("Error on line item update", e);
              });
            }
          })
        );
      }

      dispatch(showSuccess(MESSAGE_GETTER_DICTIONARY.UPDATE_SUCCESS()));
    }
  };

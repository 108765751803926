import { isEqual, intersection } from "lodash";
import { CampaignFilterFieldsInProto } from "api/manageCampaigns";
import {
  CampaignFilterFields,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  LineItemCreative,
  LineItemCreativeMaybeWithoutId,
  QuickEditCampaignPayload,
  QuickEditLineItemPayload,
  RootCampaign,
  ShallowLineItem
} from "campaign-types";
import { ViewId } from "@madhive/mad-sdk";
import { shouldUpdateStartDateIfSetLive } from "lib/utils/instructions";
import { RawInstructionStatus } from "features/ManageCampaigns/constants";
import {
  getTenMinutesFromNow,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  getEarliestDate,
  getLatestDate,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, no-unused-vars
  isDatePassed
} from "lib/utils/date";

// Campaign GET url parameters
// PK: Modify this to set campaign range for GET /campaigns
export const LOOKOUT_WINDOW = 60;
// Geo data can be expensive, GET campaigns without geo since not necessary in table view
export const WITHOUT_GEO = true;

export const defaultStartDateForCampaigns = new Date(
  Date.now() - LOOKOUT_WINDOW * 24 * 60 * 60 * 1000
).toISOString();

export const defaultEndDateForCampaigns = new Date(
  Date.now() + LOOKOUT_WINDOW * 24 * 60 * 60 * 1000
).toISOString();

export const DEFAULT_CAMPAIGN_FILTER_FIELDS = {
  name: false,
  owners: false
};

// PK TODO: string as the key for now since we don't want to bring in all views
const MAP_OF_CAMPAIGN_FILTERS_PER_VIEW: Record<string, CampaignFilterFields> = {
  [ViewId.CUSTOM_REPORTING]: {
    name: true,
    owners: true,
    start: true,
    end: true
  },
  [ViewId.TRACKING_PIXELS]: {
    name: true,
    owners: true,
    start: true,
    end: true
  }
};

export const determineCampaignFilterFromUrl = (): CampaignFilterFields => {
  // PK TODO: Create a map of views that don't need all campaign data
  // PK NB: Once we move to redux we can conditionally call requests with filters, but
  // this should suffice for now
  if (window.location.pathname.includes(ViewId.CUSTOM_REPORTING)) {
    return MAP_OF_CAMPAIGN_FILTERS_PER_VIEW[ViewId.CUSTOM_REPORTING];
  }
  if (window.location.pathname.includes(ViewId.TRACKING_PIXELS)) {
    return MAP_OF_CAMPAIGN_FILTERS_PER_VIEW[ViewId.TRACKING_PIXELS];
  }
  return DEFAULT_CAMPAIGN_FILTER_FIELDS;
};

export const deriveCampaignFilterMap = (filter: CampaignFilterFields) =>
  // PK TODO: Combine params above with filters here once we move away from proto definitions for filters
  ({
    name: filter.name
      ? CampaignFilterFieldsInProto.NAME
      : CampaignFilterFieldsInProto.NULL,
    owners: filter.owners
      ? CampaignFilterFieldsInProto.OWNERS
      : CampaignFilterFieldsInProto.NULL,
    start: filter.start
      ? CampaignFilterFieldsInProto.START
      : CampaignFilterFieldsInProto.NULL,
    end: filter.end
      ? CampaignFilterFieldsInProto.END
      : CampaignFilterFieldsInProto.NULL
  });

export const getCampaignEditsWithUpdatedStartDatesIfNecessary = (
  campaignEdits: Record<string, QuickEditCampaignPayload> | undefined,
  allCampaigns: { [x: string]: RootCampaign },
  isStartASAPEnabled: boolean
) =>
  Object.values(campaignEdits || {}).map(campaign => {
    const original = allCampaigns[campaign.id];
    return shouldUpdateStartDateIfSetLive(
      isStartASAPEnabled,
      campaign.startASAP || original?.startASAP || false,
      campaign.startDate || original?.startDate || new Date(Date.now() + 1000),
      original?.rawStatus || RawInstructionStatus.DRAFT,
      campaign.status || RawInstructionStatus.DRAFT
    )
      ? { ...campaign, startDate: getTenMinutesFromNow() }
      : campaign;
  });

export const getLineItemAndCreativesWithUpdatedDataIfNecessary = (
  lineItemEdits: Record<string, QuickEditLineItemPayload> | undefined,
  allLineItems: { [x: string]: ShallowLineItem },
  isStartASAPEnabled: boolean
) =>
  Object.values(lineItemEdits || {}).map(lineItem => {
    const original = allLineItems[lineItem.id];
    const shouldUpdateStartDate = shouldUpdateStartDateIfSetLive(
      isStartASAPEnabled,
      lineItem.startASAP || original.startASAP || false,
      lineItem.startDate || original.startDate,
      original.rawStatus || RawInstructionStatus.DRAFT,
      lineItem.status || RawInstructionStatus.DRAFT
    );
    const lineItemStartDate = shouldUpdateStartDate
      ? getTenMinutesFromNow()
      : lineItem.startDate;
    const shouldUpdateCreatives =
      lineItem.startDate?.valueOf() !== original.startDate?.valueOf() ||
      lineItem.endDate?.valueOf() !== original.endDate?.valueOf();
    if (!shouldUpdateCreatives)
      return {
        ...lineItem,
        startDate: lineItemStartDate
      };
    return {
      ...lineItem,
      startDate: lineItemStartDate,
      creatives: original.attachedCreatives?.map(creative => {
        const changedCreative: LineItemCreativeMaybeWithoutId | undefined =
          lineItem.creatives?.find(c => c.id === creative.id);
        const startDate = getLatestDate([
          creative.startDate,
          lineItemStartDate
        ]);
        const endDate =
          lineItem.endDate &&
          creative.endDate &&
          creative.endDate.valueOf() > lineItem.endDate.valueOf()
            ? lineItem.endDate
            : creative.endDate;
        return {
          ...creative,
          ...(changedCreative || {}),
          startDate,
          endDate
        };
      })
    };
  });

/**
 * This function is used to help resolve the situation when we have a higher version of the campaign on the server
 * Usecase: the user has loaded the page with campaign version 1, but while he's working another user updated the campaign to version 2
 * now our user wants to update with his changes
 * We will only allow this if there are no conflicting properties
 */
export const compareOverlappingCampaigns = (
  oldCampaign: RootCampaign,
  newCampaign: RootCampaign,
  serverCampaign: RootCampaign
) => {
  const campaignKeysToSkip = [
    "version",
    "details",
    "dateLastFetched",
    "lastUpdated",
    "updatedBy",
    "lineItems"
  ];
  // oldCampaign is the campaign this user loaded when he started working
  // newCampaign is the data he hopes to save
  // serverCampaign is sent from the server after the save attempt, and it has a higher version
  const foreignUpdatedKeys = Object.keys(oldCampaign).filter(
    key =>
      !campaignKeysToSkip.includes(key) &&
      !isEqual(oldCampaign[key], serverCampaign[key])
  );
  const currentUpdatedKeys = Object.keys(oldCampaign).filter(
    key =>
      !campaignKeysToSkip.includes(key) &&
      !isEqual(oldCampaign[key], newCampaign[key])
  );
  // if the keys do not overlap, we can safely just update the serverCampaign with the new values and load
  const overlappingKeys = intersection(foreignUpdatedKeys, currentUpdatedKeys);
  return { noOverlap: overlappingKeys.length < 1, currentUpdatedKeys };
};

import { NotImplementedError } from "../../errors";
import { Handler } from "../handlers";
import Advertisers from "./advertisers";
import Campaigns from "./campaigns";
import Creatives from "./creatives";
/**
 * Reporting module responsible for all generic reports related functions
 */
class Reporting extends Handler {
    constructor(sdk) {
        super(sdk, "reporting");
        this.advertisers = new Advertisers(sdk);
        this.campaigns = new Campaigns(sdk);
        this.creatives = new Creatives(sdk);
    }
    /**
     * findItems isn't implemented for reporting wrapper.
     */
    async findItems() {
        throw new NotImplementedError("find");
    }
    /**
     * saveItem isn't implemented for reporting wrapper.
     */
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    saveItem(never) {
        throw new NotImplementedError("save");
    }
    /**
     * deleteItem isn't implemented for reporting wrapper.
     */
    deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
}
export default Reporting;

import { RootCampaign, ShallowLineItem } from "campaign-types";
import { createSelector } from "reselect";
import { RootState } from "rootReducer";

export const selectLastReceivedAllCampaigns = (state: RootState) =>
  state.campaigns.root.receivedAllAt;

export const selectLoadAllCampaignsCancellationSource = (state: RootState) =>
  state.campaigns.root.loadAllCancellationSource;

export const selectLoadSingleCampaignCancellationSource = (state: RootState) =>
  state.campaigns.root.loadSingleCancellationSource;

export const selectLineItemsCancellationSource = (state: RootState) =>
  state.campaigns.root.lineItems.cancellationSource;

export const selectLineItemsPaging = (state: RootState) =>
  state.campaigns.root.lineItems.paging;

export const selectLineItemsSortBy = (state: RootState) =>
  state.campaigns.root.lineItems.sortBy;

export const selectIsCampaignUpdating = (state: RootState) =>
  state.campaigns.root.isSaving;

export const selectCampaignsById = (state: RootState) =>
  state.campaigns.root.byId;

export const selectAllLineItemsRecord = (state: RootState) => {
  const lineItemsFromCampaignsById: Record<string, ShallowLineItem> =
    Object.values(state.campaigns.root.byId)
      .flatMap(campaign => campaign.lineItems)
      .reduce((obj, item) => {
        // eslint-disable-next-line no-param-reassign
        obj[item.id] = item;
        return obj;
      }, {});
  return {
    ...lineItemsFromCampaignsById,
    ...state.campaigns.root.lineItems.byId
  };
};

export const selectAllCampaignsRecord = (state: RootState) => ({
  ...state.campaigns.root.byId,
  ...state.campaigns.root.lineItems.campaignsById
});

export const selectPaging = (state: RootState) => state.campaigns.root.paging;

export const selectSortBy = (state: RootState) => state.campaigns.root.sortBy;

export const selectDates = (state: RootState) =>
  state.campaigns.dashboard.dates;

export const selectCampaignsAsArray = createSelector(
  selectCampaignsById,
  (cmps: Record<string, RootCampaign>) => Object.values(cmps)
);

// adapter to keep the app kind of working while migrating other pieces
export const selectShallowCampaigns = ({ campaigns }: RootState) => ({
  isLoading: !!campaigns.root.loadAllCancellationSource,
  data: Object.values(campaigns.root.byId),
  firstFetchFinished: !!campaigns.root.receivedAllAt,
  isRefreshing: !!(
    campaigns.root.loadAllCancellationSource && campaigns.root.receivedAllAt
  )
});

export const selectLineItems = (state: RootState) => ({
  data: Object.values(state.campaigns.root.lineItems.byId),
  isLoading: !!state.campaigns.root.lineItems.cancellationSource
});

export const selectCampaignById = (state: RootState, id: string) =>
  state.campaigns.root.byId[id];

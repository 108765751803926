import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const StopWatch: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-stop-watch"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.25012 2C9.25012 1.58579 9.58591 1.25 10.0001 1.25H14.0001C14.4143 1.25 14.7501 1.58579 14.7501 2C14.7501 2.41421 14.4143 2.75 14.0001 2.75H10.0001C9.58591 2.75 9.25012 2.41421 9.25012 2Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.1266 7.87349C14.2953 5.04219 9.70489 5.04219 6.87359 7.87349C4.0423 10.7048 4.0423 15.2952 6.87359 18.1265C9.70489 20.9578 14.2953 20.9578 17.1266 18.1265C19.9579 15.2952 19.9579 10.7048 17.1266 7.87349ZM18.1873 6.81283C14.7702 3.39574 9.23002 3.39574 5.81293 6.81283C2.39585 10.2299 2.39585 15.7701 5.81293 19.1872C9.23002 22.6043 14.7702 22.6043 18.1873 19.1872C21.6044 15.7701 21.6044 10.2299 18.1873 6.81283Z"
      />
      <path d="M16.5293 16.3745C16.5293 17.0123 16.0123 17.5293 15.3745 17.5293C14.7368 17.5293 14.2198 17.0123 14.2198 16.3745C14.2198 15.7368 14.7368 15.2197 15.3745 15.2197C16.0123 15.2197 16.5293 15.7368 16.5293 16.3745Z" />
      <path d="M13.1656 17.7452C13.1656 18.383 12.6486 18.9 12.0109 18.9C11.3731 18.9 10.8561 18.383 10.8561 17.7452C10.8561 17.1075 11.3731 16.5905 12.0109 16.5905C12.6486 16.5905 13.1656 17.1075 13.1656 17.7452Z" />
      <path d="M9.78113 16.3524C9.78113 16.9901 9.26413 17.5071 8.62637 17.5071C7.98862 17.5071 7.47162 16.9901 7.47162 16.3524C7.47162 15.7146 7.98862 15.1976 8.62637 15.1976C9.26413 15.1976 9.78113 15.7146 9.78113 16.3524Z" />
      <path d="M8.39896 13.0108C8.39896 13.6485 7.88196 14.1655 7.2442 14.1655C6.60645 14.1655 6.08945 13.6485 6.08945 13.0108C6.08945 12.373 6.60645 11.856 7.2442 11.856C7.88196 11.856 8.39896 12.373 8.39896 13.0108Z" />
      <path d="M9.80347 9.62636C9.80347 10.2641 9.28647 10.7811 8.64871 10.7811C8.01096 10.7811 7.49396 10.2641 7.49396 9.62636C7.49396 8.9886 8.01096 8.4716 8.64871 8.4716C9.28647 8.4716 9.80347 8.9886 9.80347 9.62636Z" />
      <path d="M13.1665 8.23322C13.1665 8.87098 12.6495 9.38798 12.0117 9.38798C11.374 9.38798 10.857 8.87098 10.857 8.23322C10.857 7.59547 11.374 7.07847 12.0117 7.07847C12.6495 7.07847 13.1665 7.59547 13.1665 8.23322Z" />
      <path d="M16.5071 9.63706C16.5071 10.2748 15.9901 10.7918 15.3524 10.7918C14.7146 10.7918 14.1976 10.2748 14.1976 9.63706C14.1976 8.9993 14.7146 8.4823 15.3524 8.4823C15.9901 8.4823 16.5071 8.9993 16.5071 9.63706Z" />
      <path d="M17.9109 12.9893C17.9109 13.627 17.3939 14.144 16.7562 14.144C16.1184 14.144 15.6014 13.627 15.6014 12.9893C15.6014 12.3515 16.1184 11.8345 16.7562 11.8345C17.3939 11.8345 17.9109 12.3515 17.9109 12.9893Z" />
    </svg>
  );
};

export default StopWatch;

import MadhiveFooterLogo from "static/branding/logos/madhive-new-footer";
import { FC } from "react";
import Layout from "../Reusable/Layout";
import "./Footer.scss";
import { DEFAULT_DISCLAIMER_TEXT } from "./constants";
import { COPYRIGHT_TEXT } from "./Footer";

export interface Props {
  disclaimer?: string;
}

export const FooterWithDisclaimer: FC<Props> = ({ disclaimer }: Props) => {
  const disclaimerText = disclaimer || DEFAULT_DISCLAIMER_TEXT;

  return (
    <footer className="SmithersFooter">
      <Layout>
        <div className="SmithersFooter__Content">
          <div className="SmithersFooter__Logo--Container">
            <span className="SmithersFooter__Description">Powered By</span>
            <MadhiveFooterLogo />
          </div>
          <div className="SmithersFooter__Disclaimer">
            <p>{disclaimerText}</p>
            <div className="SmithersFooter__Disclaimer--CopyRight">
              {COPYRIGHT_TEXT}
            </div>
          </div>
        </div>
      </Layout>
    </footer>
  );
};

export default FooterWithDisclaimer;

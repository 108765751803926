/**
 * This is a base handler class to share methods between Promise/Observable.
 * Because of legacy code the class "Handler" exported via the index.ts file
 * is really promise.ts which is built on this class.
 */
import Cache from "../../cache/index";
import { Validation } from "../../validation";
import { objectLookup } from "../../utils";
export class Base {
    /**
     * Handlers need access to MadSDK
     * @param sdk: Instance of MadSDK to use for lookups
     * @param id: is handler's ID
     * @param {CacheConfig} cachingConfig
     */
    constructor(sdk, id, cachingConfig) {
        this.sdk = sdk;
        this._id = id;
        this.cache = new Cache(this.sdk, this._id, cachingConfig);
    }
    /**
     * @param item: an item to be validated.
     * @param key: a key or an array of keys to validate.
     * @param value: optional. A value to be validated using the given key validator (to check if it can be used on that key).
     * @return: if multiple keys, a validation map containing the key-value pairings of errors (if any). Else, an error message related to the single key, if there is an error. Else, undefined.
     */
    validate(item, key, value) {
        let results = undefined;
        if (typeof this.validators === "undefined") {
            return results;
        }
        const validation = new Validation();
        const promises = [];
        const keys = new Set(typeof key === "string"
            ? [key]
            : Array.isArray(key)
                ? key.map((k) => k?.toString() || k)
                : Object.keys(this.validators));
        // if we are not validating a specific key, we want to include all dependant keys
        if (typeof key !== "string") {
            keys.forEach((key) => {
                const dependantKeys = this.getDependantValidatorKeys(key);
                dependantKeys.forEach((dependant) => {
                    keys.add(dependant);
                });
            });
        }
        for (const validatorKey of keys) {
            if (typeof validatorKey !== "string") {
                continue;
            }
            const currentValue = objectLookup(item, validatorKey);
            const validator = this.validators[validatorKey];
            if (typeof validator !== "function") {
                continue;
            }
            const updating = typeof value !== "undefined";
            const checking = updating ? value : currentValue;
            const result = validator(item, checking, currentValue, updating);
            if (result instanceof Promise) {
                promises.push(result.then((res) => {
                    if (res?.length) {
                        validation.set(validatorKey, res);
                    }
                }));
            }
            else if (result?.length) {
                validation.set(validatorKey, result);
            }
        }
        if (promises?.length) {
            results = Promise.all(promises).then(() => typeof key === "string" ? validation.get(key) : validation);
        }
        else {
            results = typeof key === "string" ? validation.get(key) : validation;
        }
        return results;
    }
    get id() {
        return this._id;
    }
    get reconcileCache() {
        return this.cache.reconcile.bind(this.cache);
    }
    /** @return: the key that unique identifies an ItemType object. Defaults to "id". */
    getIdentifierKey() {
        return "id";
    }
    /**
     * @param item: some item.
     * @return: the unique identifier value.
     */
    getIdentifier(item) {
        return item[this.getIdentifierKey()];
    }
    /**
     * Given a validator key, return the dependant validator keys that should also be ran.
     * @param key - the key to get dependant keys for.
     * @returns - an array of dependant keys.
     */
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    getDependantValidatorKeys(key) {
        return [];
    }
}

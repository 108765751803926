import { RefObject, useEffect } from "react";

export const useFocusOnMount = (
  ref: RefObject<HTMLElement>,
  focusOnMount: boolean
): void => {
  useEffect(() => {
    if (focusOnMount && ref.current) {
      ref.current.focus();
    }
  }, []);
};

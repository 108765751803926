import { Radio as BlueprintRadio } from "@blueprintjs/core";
import { RadioProps } from "./types";
import { styles } from "./styles";

/**
 * `Radio` renders a single radio button.
 * It is typically used as a child of a `RadioGroup` component, but it can also be used standalone.
 */
const Radio: React.FC<RadioProps> = ({
  label,
  name,
  value,
  disabled,
  onChange,
  checked = false
}) => {
  return (
    <BlueprintRadio
      name={name}
      value={value}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      css={[styles.input]}
    >
      {label}
    </BlueprintRadio>
  );
};

export default Radio;
export * from "./types";

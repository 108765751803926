import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";

const Info: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-circled-info"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M10.25 12C10.25 11.5858 10.5858 11.25 11 11.25H12C12.4142 11.25 12.75 11.5858 12.75 12V17C12.75 17.4142 12.4142 17.75 12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V12.75H11C10.5858 12.75 10.25 12.4142 10.25 12Z" />
      <path d="M11.749 7.25C11.1987 7.25 10.7474 7.69648 10.75 8.25272C10.7515 8.80368 11.1987 9.25 11.75 9.25C12.3022 9.25 12.75 8.80221 12.75 8.25C12.75 7.69584 12.3003 7.25 11.749 7.25Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 12C2.25 6.61479 6.61479 2.25 12 2.25C17.3852 2.25 21.75 6.61479 21.75 12C21.75 17.3852 17.3852 21.75 12 21.75C6.61479 21.75 2.25 17.3852 2.25 12ZM12 3.75C7.44321 3.75 3.75 7.44321 3.75 12C3.75 16.5568 7.44321 20.25 12 20.25C16.5568 20.25 20.25 16.5568 20.25 12C20.25 7.44321 16.5568 3.75 12 3.75Z"
      />
    </svg>
  );
};

export default Info;

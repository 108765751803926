export const validators = {
    name: (agency, value) => {
        if (typeof value !== "string" || value.length === 0) {
            return "Name is required";
        }
        else if (value.length < 2) {
            return "Name must be at least two characters in length";
        }
        return;
    }
};

import { CancelTokenSource } from "axios";
import { IToastProps } from "@blueprintjs/core";
import { PixelReportFormatted } from "pixel-types";

export const GET_PIXEL_REPORT_PENDING = "GET_PIXEL_REPORT_PENDING";
export const GET_PIXEL_REPORT_FAILURE = "GET_PIXEL_REPORT_FAILURE";
export const GET_PIXEL_REPORT_SUCCESS = "GET_PIXEL_REPORT_SUCCESS";
export const ABORT_GET_PIXEL_REPORT_REQUEST = "ABORT_GET_PIXEL_REPORT_REQUEST";

export interface PixelReportState {
  reportData: PixelReportFormatted | null;
  cancellationSource: CancelTokenSource | null;
  lastReceivedReportAt: number | null;
  error: string | null;
}

interface GetPixelReportPendingAction {
  type: typeof GET_PIXEL_REPORT_PENDING;
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

interface GetPixelReportFailureAction {
  type: typeof GET_PIXEL_REPORT_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface GetPixelReportSuccessAction {
  type: typeof GET_PIXEL_REPORT_SUCCESS;
  payload: PixelReportFormatted;
  meta: {
    timestamp: number;
  };
}

interface AbortGetPixelReportRequestAction {
  type: typeof ABORT_GET_PIXEL_REPORT_REQUEST;
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

export type PixelReportActionTypes =
  | GetPixelReportPendingAction
  | GetPixelReportFailureAction
  | GetPixelReportSuccessAction
  | AbortGetPixelReportRequestAction;

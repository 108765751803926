import { RootState } from "rootReducer";
import { createSelector } from "reselect";
import {
  formatGeographyForGeoMultiSelect,
  formatGeoAsRegionToZipCodes,
  formatZipToAllGeoCodes,
  KNOWN_COUNTRY_CODES,
  formatGeographyForGeoSelectionComponent
} from "lib/utils/geography";
import { ZipMappedToAllGeos } from "./types";

export const selectIsGeographyLoading = (state: RootState) =>
  state.geography.isLoading;

export const selectIsGeographyLoaded = (state: RootState) =>
  state.geography.isLoaded;

export const selectGeographyError = (state: RootState) => state.geography.error;

export const selectUnfilteredGeoData = (state: RootState) =>
  state.geography.unfilteredGeoInfo;

export const selectShouldFetchGeographicData = (state: RootState) =>
  !state.geography.isLoaded &&
  !state.geography.isLoading &&
  !state.geography.error;

export const selectGeoDataFormatted = createSelector(
  selectUnfilteredGeoData,
  (results: ZipMappedToAllGeos[]) => formatGeographyForGeoMultiSelect(results)
);

export const selectGeoDataForMultiSelect = (
  includedCountryCodes: string[],
  countriesInaccessibleToUserSelection: string[] = []
) =>
  createSelector(selectUnfilteredGeoData, (results: ZipMappedToAllGeos[]) =>
    formatGeographyForGeoMultiSelect(
      results,
      includedCountryCodes,
      countriesInaccessibleToUserSelection
    )
  );

export const selectGeoDataFormattedByCountryCodes = (
  includedCountryCodes: string[]
) =>
  createSelector(selectUnfilteredGeoData, (results: ZipMappedToAllGeos[]) =>
    formatGeographyForGeoMultiSelect(
      results,
      includedCountryCodes,
      Object.keys(KNOWN_COUNTRY_CODES).filter(
        code => !includedCountryCodes.includes(code)
      )
    )
  );

export const selectGeoDataFormattedForGeoSelection = createSelector(
  selectUnfilteredGeoData,
  (results: ZipMappedToAllGeos[]) =>
    formatGeographyForGeoSelectionComponent(results)
);

export const selectGeoTypeToZipMappings = createSelector(
  selectUnfilteredGeoData,
  (results: ZipMappedToAllGeos[]) => formatGeoAsRegionToZipCodes(results)
);

export const selectGeoTypeToZipMappingsForIndividualCountry = (
  individualCountry: string
) =>
  createSelector(
    selectUnfilteredGeoData,
    (results: ZipMappedToAllGeos[]) =>
      formatGeoAsRegionToZipCodes(results)[individualCountry]
  );

export const selectZipToAllGeoCodes = createSelector(
  selectUnfilteredGeoData,
  (results: ZipMappedToAllGeos[]) => formatZipToAllGeoCodes(results)
);

export const selectUSOnlyGeoDataFormatted = createSelector(
  selectUnfilteredGeoData,
  (results: ZipMappedToAllGeos[]) =>
    formatGeographyForGeoMultiSelect(results, [KNOWN_COUNTRY_CODES.US])
);

import axios from "axios";
import { Handler } from "../modules/handlers";
import { GetAllNetworksFailed, NotImplementedError } from "../errors";
// TODO: can this be combined with other reporting modules under some parent module (e.g., reporting.linearNetworks)?
const CACHE_KEY = "linear_networks";
class LinearNetworks extends Handler {
    constructor(sdk) {
        super(sdk, "linear-networks");
    }
    findItems() {
        if (this.cache.has(CACHE_KEY)) {
            return this.cache.get(CACHE_KEY);
        }
        const request = axios
            .get(`${this.sdk.urls.madhiveReportingBaseUrl}/linear_networks`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => response.data)
            .catch(() => {
            throw new GetAllNetworksFailed();
        });
        this.cache.set(CACHE_KEY, request);
        return request;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveItem(data) {
        throw new NotImplementedError("save");
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}
export default LinearNetworks;

import { GeoTargetingDetails, GeoTargets } from "campaign-types";
import { LogicalOperator } from "lib/constants/misc";
import {
  emptyGeographyObject,
  emptyNnyGeographyObject,
  GeographicEntityTypes,
  GeoTargetsKeys,
  GEO_LINEITEM_KEY_TO_GEO_ENTITY_KEY,
  KNOWN_COUNTRY_CODES
} from "lib/utils/geography";
import {
  isExclusivelyDmasAndOrDistricts,
  isGeoTargetEmpty
} from "lib/utils/lineItemGeography";
import { LineitemGeoEntitiesState, LineItemGeoTargetingState } from "./types";

export const makeInitialLineitemGeoEntitiesState =
  (): LineitemGeoEntitiesState => ({
    ...emptyGeographyObject({
      entities: [],
      selectType: LogicalOperator.INCLUDE
    }),
    [GeographicEntityTypes.COUNTRY]: {
      /**
       * Default country for empty targeting state is US. Following product rule that
       * targeting of any type should start in a state that is as broad as possible.
       */
      entities: [],
      selectType: LogicalOperator.INCLUDE
    }
  });

const parseStringIfDma = (entities: Array<string | number>): string[] =>
  entities.map((el: string | number) =>
    typeof el === "number" ? el.toString() : el
  );

export const isGeoTargetsEmpty = (
  lineItemTargeting: GeoTargetingDetails,
  selectType: LogicalOperator
) =>
  Object.values(GeoTargetsKeys).every(
    key => !lineItemTargeting[selectType][key].length
  );

export const isGeographicEntityTypesEmpty = (
  lineItemTargeting: LineItemGeoTargetingState,
  selectType: LogicalOperator
) =>
  Object.values(GeographicEntityTypes).every(
    key =>
      lineItemTargeting[key].selectType !== selectType ||
      !lineItemTargeting[key].entities.length
  );

export const isIncludeTargetingCountryOnly = (
  lineItemTargeting: GeoTargetingDetails
) =>
  Object.values(GeoTargetsKeys).every(key =>
    key === GeoTargetsKeys.COUNTRY
      ? !!lineItemTargeting.include[key].length
      : !lineItemTargeting.include[key].length
  );

export const formatInitialLineItemGeoTargeting = (
  lineItemTargeting: GeoTargetingDetails
): LineitemGeoEntitiesState => {
  const defaultTargeting = makeInitialLineitemGeoEntitiesState();

  if (isGeoTargetsEmpty(lineItemTargeting, LogicalOperator.INCLUDE)) {
    return defaultTargeting;
  }

  Object.values(GeoTargetsKeys).forEach(geoType => {
    switch (geoType) {
      case GeoTargetsKeys.COUNTRY:
        defaultTargeting[GeographicEntityTypes.COUNTRY] = {
          entities: isIncludeTargetingCountryOnly(lineItemTargeting)
            ? [lineItemTargeting.include.country]
            : [],
          selectType: LogicalOperator.INCLUDE
        };
        break;
      default:
        defaultTargeting[GEO_LINEITEM_KEY_TO_GEO_ENTITY_KEY[geoType]] = {
          entities: lineItemTargeting.include[geoType].length
            ? parseStringIfDma(lineItemTargeting.include[geoType])
            : parseStringIfDma(lineItemTargeting.exclude[geoType]),
          selectType: lineItemTargeting.exclude[geoType].length
            ? LogicalOperator.EXCLUDE
            : LogicalOperator.INCLUDE
        };
        break;
    }
  });

  return defaultTargeting;
};

// Clear country include if any other geographic entity type is included
const shouldCountryIncludeBeCleared = (nextState: LineItemGeoTargetingState) =>
  Object.values(GeographicEntityTypes).some(
    geoType =>
      geoType !== GeographicEntityTypes.COUNTRY &&
      nextState[geoType].selectType === LogicalOperator.INCLUDE &&
      !!nextState[geoType].entities.length
  );

export const preventEmptyGeoTargeting = (
  nextState: LineItemGeoTargetingState
) => {
  if (
    isGeographicEntityTypesEmpty(nextState, LogicalOperator.INCLUDE) &&
    isGeographicEntityTypesEmpty(nextState, LogicalOperator.EXCLUDE)
  ) {
    return {
      ...nextState,
      [GeographicEntityTypes.COUNTRY]: {
        entities: [nextState.currentCountry],
        selectType: LogicalOperator.INCLUDE
      }
    };
  }

  return nextState;
};

export const clearCountryIncludeInNextState = (
  nextState: LineItemGeoTargetingState
): LineItemGeoTargetingState =>
  preventEmptyGeoTargeting(
    shouldCountryIncludeBeCleared(nextState)
      ? {
          ...nextState,
          [GeographicEntityTypes.COUNTRY]: {
            entities: [],
            selectType: LogicalOperator.INCLUDE
          }
        }
      : nextState
  );

export const getEntityTypesByLogicalOperator = (
  currentState: LineItemGeoTargetingState
) => {
  const includedEntityTypes: GeographicEntityTypes[] = [
    GeographicEntityTypes.COUNTRY
  ];
  const excludedEntityTypes: GeographicEntityTypes[] = [];

  Object.values(GeographicEntityTypes).forEach(geoType => {
    currentState[geoType].selectType === LogicalOperator.INCLUDE
      ? includedEntityTypes.push(geoType)
      : excludedEntityTypes.push(geoType);
  });

  return { includedEntityTypes, excludedEntityTypes };
};

// Clear all other included geographic entities when user includes country
export const clearOtherIncludesOnCountryInclude = (
  country: string[] | undefined,
  currentState: LineItemGeoTargetingState
): LineItemGeoTargetingState => {
  if (country) {
    if (currentState.currentCountry !== country[0]) {
      /**
       * TB: if user has selected a new country when a country is already
       * selected (e.g. US -> CA), we must remove all current exclusions
       * because they do not overlap with the new country, and will
       * confuse the backend.
       */
      return {
        ...currentState,
        ...emptyGeographyObject({
          entities: [] as string[],
          selectType: LogicalOperator.INCLUDE
        }),
        [GeographicEntityTypes.COUNTRY]: {
          entities: country,
          selectType: LogicalOperator.INCLUDE
        },
        currentCountry: country[0]
      };
    }

    return {
      ...currentState,
      ...Object.values(GeographicEntityTypes).reduce(
        (acc, geoType) => {
          if (geoType === GeographicEntityTypes.COUNTRY) {
            acc[geoType] = {
              entities: country,
              selectType: LogicalOperator.INCLUDE
            };
          } else {
            currentState[geoType].selectType === LogicalOperator.INCLUDE
              ? (acc[geoType].entities = [])
              : (acc[geoType] = currentState[geoType]);
          }
          return acc;
        },
        emptyGeographyObject({
          entities: [] as string[],
          selectType: LogicalOperator.INCLUDE
        })
      )
    };
  }

  return currentState;
};

export const simulateNextStateOfReducer = (
  currentState: LineItemGeoTargetingState,
  entityType: GeographicEntityTypes,
  entity?: string[],
  selectType?: LogicalOperator
): LineItemGeoTargetingState => {
  switch (entityType) {
    case GeographicEntityTypes.COUNTRY:
      return clearOtherIncludesOnCountryInclude(entity, currentState);
    default:
      return clearCountryIncludeInNextState({
        ...currentState,
        [entityType]: {
          entities: entity || currentState[entityType].entities,
          selectType: selectType || currentState[entityType].selectType
        }
      });
  }
};

export const deriveLineItemCountry = (
  lineItemTargeting: GeoTargetingDetails
) => {
  if (isGeoTargetsEmpty(lineItemTargeting, LogicalOperator.INCLUDE)) {
    return "";
  }

  if (lineItemTargeting.include.country) {
    return lineItemTargeting.include.country;
  }

  /**
   * TB: In the case that no country is defined on the lineitem's targeting, there is
   * currently only one possibility: that the lineitem only has DMAs or districts
   * defined on it, which means (via backend logic) that it must be targeted within
   * the US.
   */
  return KNOWN_COUNTRY_CODES.US;
};

const makeNNYGeoInclude = (targets: GeoTargets): GeoTargets => ({
  ...targets,
  country: isExclusivelyDmasAndOrDistricts(targets) ? "" : targets.country
});

const makeNNYGeoExclude = (targets: GeoTargets): GeoTargets => ({
  ...targets,
  country:
    isExclusivelyDmasAndOrDistricts(targets) ||
    isGeoTargetEmpty({ ...targets, country: "" })
      ? ""
      : targets.country
});

export const formatLineItemGeoTargetingForNNY = (
  currentState: LineItemGeoTargetingState
): GeoTargetingDetails => {
  const emptyNnyTargeting = {
    [LogicalOperator.INCLUDE]: emptyNnyGeographyObject(),
    [LogicalOperator.EXCLUDE]: emptyNnyGeographyObject()
  };

  Object.values(GeoTargetsKeys).forEach(targetKey => {
    const geographyKey = GEO_LINEITEM_KEY_TO_GEO_ENTITY_KEY[targetKey];

    Object.values(LogicalOperator).forEach(operator => {
      if (currentState[geographyKey].selectType === operator) {
        if (targetKey === GeoTargetsKeys.COUNTRY) {
          emptyNnyTargeting.include[targetKey] = currentState.currentCountry;
        } else if (targetKey === GeoTargetsKeys.DMA) {
          emptyNnyTargeting[operator][targetKey] = currentState[
            geographyKey
          ].entities.map(ent => parseInt(ent, 10));
        } else {
          emptyNnyTargeting[operator][targetKey] =
            currentState[geographyKey].entities;
        }
      }
    });
  });

  // we must have a country defined, but we removed in the next step if it's not needed
  emptyNnyTargeting.exclude.country = currentState.currentCountry;

  return {
    [LogicalOperator.INCLUDE]: makeNNYGeoInclude(emptyNnyTargeting.include),
    [LogicalOperator.EXCLUDE]: makeNNYGeoExclude(emptyNnyTargeting.exclude)
  };
};

import { ViewId, User, UserAbility, UserOperationMode } from "@madhive/mad-sdk";

export const getUserPermissions = (
  viewId: ViewId,
  abilities: UserAbility[]
) => {
  const userAbilitiesForViewId =
    viewId === ViewId.FIRST_PARTY_DATA_ONBOARDING
      ? abilities.find(item => item.resource === "pii")
      : abilities.find(item => item.resource === viewId);

  const canView = !!userAbilitiesForViewId?.operations.includes(
    UserOperationMode.READ
  );

  const canManage = !!userAbilitiesForViewId?.operations.includes(
    UserOperationMode.UPDATE
  );

  const readOnly = canView && !canManage;

  return { canView, canManage, readOnly };
};

export const isUserAbleToViewAgainstViewId = (viewId: ViewId, user: User) => {
  if (user.isDev) {
    return true;
  }

  if (!user.abilities) {
    return user.viewIds.includes(viewId);
  }

  const { canManage, canView } = getUserPermissions(viewId, user.abilities);

  return canView || canManage;
};

import axios from "axios";
import { Handler } from "../../handlers";
import { serviceSpecificationToSpecificationLegacy } from "../../../models/creatives/legacy/specifications";
import { NotImplementedError } from "../../../errors";
class Specifications extends Handler {
    constructor(sdk) {
        super(sdk, "specifications");
        this.toSpecification = (serviceSpecification) => {
            return serviceSpecificationToSpecificationLegacy(serviceSpecification);
        };
    }
    async findItems() {
        const url = `${this.sdk.urls.madhiveEncoderBaseUrl}/specs`;
        if (this.cache.has(url)) {
            return new Promise((resolve) => {
                this.cache.get(url).then((specifications) => {
                    resolve(specifications);
                });
            });
        }
        return new Promise((resolve, reject) => {
            axios
                .get(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((res) => {
                if (res.data?.data) {
                    resolve(res.data.data.map((spec) => this.toSpecification(spec)));
                }
                else {
                    throw new Error();
                }
            })
                .catch((error) => {
                reject(error);
            });
        });
    }
    /* eslint-disable-next-line class-methods-use-this, no-shadow */
    async saveItem() {
        throw new NotImplementedError("Save");
    }
    /* eslint-disable-next-line class-methods-use-this */
    async deleteItem() {
        throw new NotImplementedError("Delete");
    }
}
export default Specifications;

import { useEffect } from "react";
export const useMadHooksCacheSubscription = (observer, next, dependencies) => {
    // The useEffect cleanup from the previous state happens before the useEffect itself, which can
    // result in the cache cleanup seeing the current observer with no subscribers and removing it.
    // This promise makes sure that the unsibscribe happens after a new subscribe to avoid that.
    let placeholderSubscription;
    if (!observer.observed) {
        placeholderSubscription = observer.subscribe();
    }
    useEffect(() => {
        const madHooksCacheSubscription = observer.subscribe({ next });
        placeholderSubscription && placeholderSubscription.unsubscribe();
        return () => {
            madHooksCacheSubscription?.unsubscribe();
        };
    }, [...(dependencies || [])]);
};

export const inputChecked = `
  background-color: var(--primary-color);
  border: none;
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%228%22%20viewBox%3D%220%200%2010%208%22%20fill%3D%22orange%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M1.6637%204.4955C1.40091%204.20364%200.951276%204.18008%200.659417%204.44287C0.367557%204.70566%200.343993%205.15529%200.606784%205.44715L2.33176%207.36294C2.62058%207.6837%203.13057%207.71997%203.46218%207.42139L9.34058%202.12846C9.63243%201.86567%209.656%201.41604%209.39321%201.12418C9.13041%200.832317%208.68078%200.808753%208.38892%201.07154L2.97222%205.94876L1.6637%204.4955Z%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
`;

export const inputIndeterminate = `
  border: none;
  background-color: var(--primary-color);
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%222%22%20viewBox%3D%220%200%2010%202%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Crect%20x%3D%220.555557%22%20y%3D%220.111115%22%20width%3D%229.15556%22%20height%3D%221.77778%22%20rx%3D%220.888889%22%20fill%3D%22white%22%2F%3E%0A%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-position: center;
`;

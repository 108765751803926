import { useState, useEffect } from "react";
import { useDebouncedValue } from "../useDebouncedValue";
import { DoNotCare } from "../../types";
import { UseSearcherArgs } from "./types";
import { includesSearch } from "./utils";

export const useSearcher = <T extends { [key: string]: DoNotCare } | string>({
  items,
  debounceMs = 0,
  isLoading: incomingLoading,
  disabled = false,
  keys,
  onAsyncQuery
}: UseSearcherArgs<T>) => {
  const [isMounted, setIsMounted] = useState(false);

  const [isLoading, setIsLoading] = useState(() => incomingLoading || false);
  useEffect(() => setIsLoading(!!incomingLoading), [incomingLoading]);

  /** non-debounced query — used for input state */
  const [rawQuery, setRawQuery] = useState("");
  const debouncedQuery = useDebouncedValue(rawQuery, debounceMs);
  /** if debounceMs is greater than 0, then we use the debouncedQuery — otherwise we use rawQuery so update happens instantly */
  const query = debounceMs ? debouncedQuery : rawQuery;

  const [filteredItems, setFilteredItems] = useState(items);

  useEffect(() => {
    setFilteredItems(items);
  }, [items]);

  useEffect(() => {
    if (disabled) {
      setFilteredItems(items);
    }
  }, [disabled]);

  useEffect(() => {
    if (disabled || !isMounted) {
      return;
    }
    if (!rawQuery && !onAsyncQuery) {
      setFilteredItems(items);
    }
    setIsLoading(!!rawQuery);
  }, [rawQuery]);

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      return;
    }
    if (disabled) {
      return;
    }
    if (onAsyncQuery) {
      try {
        onAsyncQuery(query);
      } finally {
        if (typeof incomingLoading === "undefined") {
          setIsLoading(false);
        } else {
          setIsLoading(incomingLoading);
        }
      }
    } else {
      if (query) {
        setFilteredItems(includesSearch(items, keys, query));
      }
      setIsLoading(false);
    }
  }, [query]);

  return { filteredItems, isLoading, setQuery: setRawQuery, query: rawQuery };
};

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const BagWithMinus: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-bag-with-minus"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5 3.121a2.751 2.751 0 0 0-2.75 2.751h5.502a2.752 2.752 0 0 0-2.751-2.751Zm4.252 2.752a4.252 4.252 0 0 0-8.503 0H8a2.75 2.75 0 0 0-2.752 2.75V11a.75.75 0 0 0 1.5 0V8.624c0-.691.56-1.251 1.251-1.251h11.005c.69 0 1.25.56 1.25 1.25v10.38c0 .69-.56 1.25-1.25 1.25H13a.75.75 0 0 0 0 1.5h6.003a2.75 2.75 0 0 0 2.75-2.75V8.623a2.75 2.75 0 0 0-2.75-2.75h-1.25ZM6.998 14.376a3.252 3.252 0 1 0 0 6.503 3.252 3.252 0 0 0 0-6.503Zm-4.752 3.251a4.752 4.752 0 1 1 9.504 0 4.752 4.752 0 0 1-9.504 0Zm2.501 0a.75.75 0 0 1 .75-.75H8.5a.75.75 0 0 1 0 1.5H5.497a.75.75 0 0 1-.75-.75Z"
      />
    </svg>
  );
};

export default BagWithMinus;

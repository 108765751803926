import { css } from "@emotion/react";

export const shared = {
  prefixAndSuffix: css`
    .bp4-input-action,
    .kit-input-suffix,
    .bp4-input-left-container,
    .kit-input-prefix,
    .date-input-icon {
      height: var(--input-height);
      display: flex;
      align-items: center;
      box-sizing: content-box;
      position: relative;
    }
  `,
  disabled: css`
    --icon-fill-color: var(--color-tertiary);
    background-color: var(--color-background-tertiary);
    box-shadow: none;
    color: var(--color-tertiary);
    cursor: not-allowed;
    border: var(--input-border-width, 1px) solid var(--color-border-secondary);
    input:disabled {
      background-color: var(--color-background-tertiary);
      box-shadow: none;
      color: var(--color-tertiary);
      cursor: not-allowed;
    }
  `,
  withError: css`
    border-color: var(--color-danger);
    &:focus-within {
      border-color: var(--color-border-danger);
    }
  `,
  inputBase: css`
    --input-height: var(--spacing-32);
    --icon-fill-color: var(--icon-fill, var(--color-secondary));
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    border: var(--input-border-width, 1px) solid var(--color-border-primary);
    border-radius: var(--border-radii-base);
    box-sizing: border-box;
    width: 100%;
    background-color: var(--color-background-primary);
    padding: 0 var(--spacing-8) !important;
    &:focus-within {
      border-color: var(--input-focus-color);
    }
    .bp4-input-group {
      display: flex;
      align-items: center;
      width: 100%;
    }
    input {
      border: none;
      font-family: var(--font-family-body);
      font-size: var(--font-size-body);
      height: var(--input-height);
      margin-bottom: 0;
      border-radius: var(--border-radii-base);
      box-shadow: none !important;
      width: 100%;
      box-sizing: border-box;
      padding: 0;
      &:focus {
        outline: none;
      }
    }
  `
};
export const styles = {
  fill: css`
    width: 100%;
    .bp4-popover-target {
      width: 100%;
    }
  `,
  inputBase: css`
    ${shared.inputBase}
    --input-focus-color: var(--color-splash-primary);
  `,
  disabled: css`
    ${shared.disabled}
  `,
  withError: css`
    ${shared.withError}
  `,
  withPrefix: css`
    ${shared.prefixAndSuffix}
    padding-left: 0 !important;
    .bp4-input-left-container,
    .kit-input-prefix {
      width: 1.5rem !important;
      padding: 0 var(--spacing-4) 0 var(--spacing-8) !important;
    }
    input {
      padding-left: var(--spacing-4) !important;
    }
  `,
  withSuffix: css`
    ${shared.prefixAndSuffix}
    padding-right: 0 !important;
    .bp4-input-action,
    .kit-input-suffix {
      width: 1.5rem !important;
      padding: 0 var(--spacing-8) 0 var(--spacing-4) !important;
    }
    input {
      padding-right: var(--spacing-4) !important;
    }
  `
};

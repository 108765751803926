import { FilterTypes as FilterFieldTypes } from "../filter";
/**
 * This is an abstract base class for support filters
 */
class Filters {
    constructor(defaultFilters) {
        // Key indexed filters.
        this.currentFilters = new Map();
        this.currentFields = [];
        /**
         * Simple method to call handleFilterUpdate if it exists.
         */
        this.updateHandlers = () => {
            if (this.handleFilterUpdate) {
                this.handleFilterUpdate();
            }
        };
        if (defaultFilters?.fields) {
            this.setFields(defaultFilters.fields);
        }
        if (defaultFilters?.where && Array.isArray(defaultFilters.where)) {
            defaultFilters.where.forEach((f) => this.setFilter(f));
        }
        this.filters = {
            add: this.add.bind(this),
            remove: this.remove.bind(this),
            clear: this.clear.bind(this),
            set: this.set.bind(this),
            get: this.getFilters.bind(this),
            fields: {
                get: this.getFields.bind(this),
                add: this.addField.bind(this),
                clear: this.clearFields.bind(this),
                set: this.setFields.bind(this)
            }
        };
    }
    getFields() {
        return [...this.currentFields];
    }
    /**
     * Used to add a single fields to the list of fields.
     * @param field Field to be added.
     */
    addField(field) {
        this.currentFields.push(field);
        this.updateHandlers();
    }
    /**
     * Clear out the current set of fields.
     */
    clearFields() {
        this.currentFields = [];
        this.updateHandlers();
    }
    /**
     * Used to set the fields to the given list
     * @param fields Array of fields to be added
     */
    setFields(fields) {
        this.currentFields = fields;
        this.updateHandlers();
    }
    /**
     * Add a filter by string or FilterField.
     * @param filter either a string filter or FilterField.
     * string filter:
     *   status:completed
     * FilterField:
     *   {
     *     field: "status",
     *     type: "eq",
     *     value: "completed"
     *   }
     */
    add(filter) {
        const filterField = typeof filter === "string" ? this.convertStringToFilter(filter) : filter;
        if (!filterField.field.toString().length) {
            return;
        }
        this.addFilter(filterField);
        this.updateHandlers();
    }
    /**
     * Add a FilterField filter (without updateHandlers).
     * @param filter The filter to add.
     * FilterField:
     *   {
     *     field: "status",
     *     type: "eq",
     *     value: "completed"
     *   }
     */
    addFilter(filter) {
        const currentFilter = this.currentFilters.get(filter.field);
        if (!currentFilter) {
            this.currentFilters.set(filter.field, filter);
        }
        else {
            currentFilter.value = this.combineFilters(currentFilter, filter).value;
        }
    }
    /**
     * Update or add filter/filters.
     * @param filter/filters String/Filter/FilterField to update or add.
     * String Example: status:complete status:running
     */
    set(filters) {
        let filterFields = [];
        if (typeof filters === "string") {
            filterFields = this.convertStringToFilters(filters);
        }
        else if ("where" in filters && Array.isArray(filters.where)) {
            filterFields = filters.where.map((fields) => ({ ...fields }));
        }
        else if ("value" in filters) {
            filterFields = [filters];
        }
        filterFields.forEach((f) => this.setFilter(f));
        this.updateHandlers();
    }
    /**
     * Update or add a filter (without updateHandlers).
     * @param filter to update or add.
     */
    setFilter(filter) {
        if (!filter.value?.toString().length) {
            this.removeFilter(filter);
        }
        else {
            this.currentFilters.set(filter.field, filter);
        }
    }
    /**
     * Remove specified string or FieldFilter filter from filters.
     * @param filter The filter, either a string or FilterField.
     * If it is a string it can be in two formats, either just a
     * filter eg `status` or a specific filter `status:completed`.
     * If it is a FilterField that field is removed.
     */
    remove(filter) {
        const filterField = typeof filter === "string" ? this.convertStringToFilter(filter) : filter;
        if (this.removeFilter(filterField)) {
            this.updateHandlers();
        }
    }
    /**
     * Remove specified FieldFilter filter from filters (without updateHandlers).
     * @param filter FieldFilter filter.
     * @returns true if a filter was removed, otherwise false.
     */
    removeFilter(filter) {
        const currentFilter = this.currentFilters.get(filter.field);
        if (!currentFilter) {
            return false;
        }
        // If no value is provided, all values for matched key are removed.
        if (filter.value === undefined || !filter.value.toString().length) {
            this.currentFilters.delete(filter.field);
            return true;
        }
        // When both current filter and filter to remove only have one value, we just compare them.
        if (!Array.isArray(currentFilter.value)) {
            return currentFilter.value === filter.value && this.currentFilters.delete(filter.field);
        }
        // When current filter is an array, we look for the specified value(s) to remove.
        let deleted = 0;
        for (const val of Array.isArray(filter.value) ? filter.value : [filter.value]) {
            for (let i = 0; i < currentFilter.value.length; i++) {
                if (currentFilter.value[i] === val) {
                    currentFilter.value.splice(i, 1);
                    deleted++;
                    continue;
                }
            }
        }
        // If all values were removed, remove the key field as well.
        if (!currentFilter.value.length) {
            this.currentFilters.delete(filter.field);
            return true;
        }
        // At least one value was removed.
        return !!deleted;
    }
    /**
     * Clears all filters.
     */
    clear() {
        this.currentFilters = new Map();
        this.updateHandlers();
    }
    /**
     * Get the current filters as a Filter or string.
     * @param type What format should the filter be returned as.
     *
     * @returns string: "status:completed name:bob" or filter: Filter
     */
    getFilters(type) {
        if (type === "string") {
            const stringFilters = [];
            this.currentFilters.forEach((f) => stringFilters.push(this.convertFilterToString(f)));
            return stringFilters.join(" ");
        }
        return this.currentFilters.size
            ? { where: Array.from(this.currentFilters.values()) }
            : undefined;
    }
    /**
     * Combine the values of two filters.
     * @param destination the filter to add the values to.
     * @param source the filter to add the values from.
     * FilterField:
     *   {
     *     field: "status",
     *     type: "eq",
     *     value: "completed"
     *   }
     */
    combineFilters(destination, source) {
        // Because the arrays of values in filters can be of different types, we do not care what the types are
        // when combined since they cannot be discerned between at run-time.
        return {
            field: destination.field,
            type: destination.type,
            value: [
                ...(Array.isArray(destination.value) ? destination.value : [destination.value]),
                ...(Array.isArray(source.value) ? source.value : [source.value])
            ]
        };
    }
    /**
     * Converts a string filter to FilterField filter.
     * @param filter String filter in the format param:value.
     */
    convertStringToFilter(filter) {
        const [field, value] = filter.split(":");
        return { field: field, type: FilterFieldTypes.EQ, value: value };
    }
    /**
     * Converts a string filter with multiple filters to an array of FilterField filters.
     * @param filter String filter in the format "param:value param:value2 param2:value".
     */
    convertStringToFilters(filters) {
        const setFilters = new Map();
        filters.split(" ").forEach((stringFilter) => {
            const filter = this.convertStringToFilter(stringFilter);
            const setFilter = setFilters.get(filter.field);
            setFilters.set(filter.field, setFilter ? this.combineFilters(setFilter, filter) : filter);
        });
        return Array.from(setFilters.values());
    }
    /**
     * Converts a FilterField filter to a string.
     * @param filter FieldFilter.
     */
    convertFilterToString(filter) {
        return Array.isArray(filter.value)
            ? `${String(filter.field)}:${filter.value.join(` ${String(filter.field)}:`)}`
            : `${String(filter.field)}:${filter.value}`;
    }
}
export default Filters;

import { useMemo } from "react";
import styled from "@emotion/styled";
import Row from "./row";
import Cell from "./cell";
import { useRowContext } from "./table";
import { TableColumn } from "./types";

interface TableContentProps {
  rowHeight: number;
  parentHeight: number;
  rowCount: number;
  openSubRowCount: number;
  scrollIndex: number;
  indexAccountingForPage: number;
  subRowKey?: string;
  renderDependencies?: any[];
}

const ContentWrapper = styled.div<{ height: number }>`
  min-height: ${props => props.height}px;
  max-height: ${props => props.height}px;
  height: ${props => props.height}px;
`;

// not sure yet how to handle this since data will most likely be parsed via renderSubRow...
// waiting on PR review inputs, but likely to remove this logic
export const renderRowCells = (row: any, rowIndex: number) =>
  row.cells.map((column: TableColumn, columnIndex: number) => (
    <Cell columnIndex={columnIndex} key={columnIndex} rowIndex={rowIndex} />
  ));

const TableContent = (props: TableContentProps) => {
  const {
    rowCount,
    openSubRowCount,
    rowHeight,
    parentHeight,
    scrollIndex,
    indexAccountingForPage,
    subRowKey
  } = props;

  const { data, openRows, rowHeights } = useRowContext();

  // const totalRowHeight =

  const minVisibleRows = Math.round(parentHeight / rowHeight) + 1; // let's just disregard open rows here, makes no significant difference

  const rows = useMemo(() => [...Array(rowCount)], [rowCount]);

  /**
   * compositeIndices keep track of a row's index taking into account parent
   * and child rows
   */
  const { compositeIndices } = useMemo(
    () =>
      rows.reduce<{
        compositeIndices: number[];
        rowSum: number;
      }>(
        (acc, el, idx) => {
          acc.compositeIndices.push(acc.rowSum);

          acc.rowSum += 1;

          if (openRows.has(idx) && subRowKey) {
            acc.rowSum += data[idx][subRowKey].length;
          }

          return acc;
        },
        {
          compositeIndices: [] as number[],
          rowSum: 0
        }
      ),
    [rows, openRows, data]
  );

  const wrapperHeight = useMemo(() => {
    if (!rowHeights) {
      return rowCount * rowHeight + openSubRowCount * rowHeight;
    }

    // Currently not supporting the use-case for a table with subrows and
    // dynamic row height. This will probably need to be revisited.
    // eslint-disable-next-line no-param-reassign
    return rowHeights.reduce((acc, el) => (acc += el), 0);
  }, [rowCount, rowHeight, openSubRowCount, rowHeight, rowHeights]);

  return (
    <ContentWrapper height={wrapperHeight}>
      {compositeIndices.map(
        (currPageCompositeIndex: number, currPageOuterRowIndex: number) => (
          <Row
            key={currPageCompositeIndex}
            dataIndex={currPageOuterRowIndex + indexAccountingForPage}
            currPageOuterRowIndex={currPageOuterRowIndex}
            currPageCompositeIndex={currPageCompositeIndex}
            scrollIndex={scrollIndex}
            minVisibleRows={minVisibleRows}
            renderDependencies={props.renderDependencies}
          />
        )
      )}
    </ContentWrapper>
  );
};

export default TableContent;

import axios from "axios";
import { DashboardReports } from "./dashboard";
export default class Reports {
    /**
     * Handlers need access to MadSDK
     * @param sdk Instance of MadSDK to use for lookups
     */
    constructor(sdk) {
        this.sdk = sdk;
        this.qa = {
            get: this.getQAReport.bind(this)
        };
        this.creatives = {
            delivery: {
                create: this.createDeliveryReport.bind(this)
            }
        };
        this.dashboard = new DashboardReports(this.sdk);
    }
    // TODO: the following should have a type applied, not any
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    createDeliveryReport(start, end) {
        // async promise executor needed in this case
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (resolve, reject) => {
            const user = this.sdk.getCurrentUser();
            if (!user) {
                reject("No Active User");
            }
            else {
                const data = {
                    product_id: "",
                    adbook_package_id: "",
                    campaign_ids: [],
                    old_campaign_ids: [],
                    start: start.toISOString(),
                    end: end.toISOString(),
                    parent_id: user.primaryOrganizationId,
                    owner_ids: [
                        user.primaryOrganizationId,
                        ...(user.advertiserId ? [user.advertiserId] : []),
                        ...(user.agencyId ? [user.agencyId] : [])
                    ]
                };
                const now = new Date();
                const id = await this.sdk.madFire
                    .addDoc("notifications", {
                    authorId: user.email,
                    recipientId: user.email,
                    status: "IN_QUEUE",
                    title: "Creative Delivery Export Complete",
                    actions: {
                        0: {
                            buttonText: "Download Report"
                        }
                    },
                    category: "creative-delivery",
                    content: "Creative Delivery report has been exported",
                    read: false,
                    createdDate: now,
                    lastUpdatedDate: now,
                    meta: {
                        submittedDate: now
                    }
                })
                    .then((docRef) => docRef.id)
                    .catch((err) => {
                    reject(err);
                });
                axios
                    .post(`${this.sdk.urls.baseHermesUrl}/dashboard/creative/${id}`, data, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .then((res) => {
                    resolve(res.data);
                })
                    .catch((error) => {
                    reject(error);
                });
            }
        });
    }
    /**
     * Method to fetch the qa report data.
     */
    getQAReport() {
        return new Promise((resolve, reject) => {
            axios
                .get(`${this.sdk.urls.baseAPIUrl}/qaexport`, {
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/json"
                }
            })
                .then((data) => {
                const blob = new Blob([data.data], {
                    type: "application/xml"
                });
                resolve(blob);
            })
                .catch((error) => {
                reject(error);
            });
        });
    }
}

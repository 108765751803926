import { RefObject, useState, useEffect } from "react";
import ResizeObserver from "resize-observer-polyfill";

export interface ResizeObserverEntry {
  target: Element;
  contentRect: DOMRectReadOnly;
}
export type ObserverCallback = (entry: DOMRectReadOnly) => void;

export const useResizeObserver = (
  ref: RefObject<Element>,
  callback: ObserverCallback
) => {
  const [currentHeight, setCurrentHeight] = useState<number>(0);
  const [currentWidth, setCurrentWidth] = useState<number>(0);
  const [currentRect, setCurrentRect] = useState<DOMRectReadOnly>();

  const handleResize = (entries: ResizeObserverEntry[]) => {
    const [entry] = entries;
    setCurrentRect(entry.contentRect);
  };

  useEffect(() => {
    if (callback && currentRect) {
      // We flyout windows we are redrawing b/c of scrollbar removal
      // let's only redraw if distance is > 15 pixels
      if (
        Math.abs(currentRect.height - currentHeight) > 15 ||
        Math.abs(currentRect.width - currentWidth) > 15
      ) {
        callback(currentRect);
        setCurrentHeight(currentRect.height);
        setCurrentWidth(currentRect.width);
      }
    }
  }, [currentRect]);

  const [resizeObs] = useState(() => new ResizeObserver(handleResize));

  useEffect(() => {
    if (!resizeObs) return;

    if (ref.current) {
      resizeObs.observe(ref.current);
    }

    return () => resizeObs.disconnect();
  }, [ref, resizeObs]);
};

import {
  CampaignId,
  QuickEditCampaignPayload,
  LineItemId,
  QuickEditLineItemPayload,
  RootCampaign,
  ShallowLineItem
} from "campaign-types";

export type CampaignEditRecords = Record<CampaignId, QuickEditCampaignPayload>;
export type LineItemEditRecords = Record<LineItemId, QuickEditLineItemPayload>;

export interface CampaignsQuickEditsState {
  campaignEdits: CampaignEditRecords;
  lineItemEdits: LineItemEditRecords;
  persistedCampaigns: Record<string, RootCampaign>;
  persistedLineItems: Record<string, ShallowLineItem>;
}

export const QUICK_EDIT_CAMPAIGN = "QUICK_EDIT_CAMPAIGN";

export const QUICK_EDIT_LINE_ITEM = "QUICK_EDIT_LINE_ITEM";

export const DISCARD_QUICK_EDITS = "DISCARD_QUICK_EDITS";

export type PartialRootCampaign = Pick<
  RootCampaign,
  | "id"
  | "lineItems"
  | "rawStatus"
  | "status"
  | "version"
  | "startDate"
  | "startASAP"
>;

export interface QuickEditCampaign {
  type: typeof QUICK_EDIT_CAMPAIGN;
  data: QuickEditCampaignPayload;
  originalInstruction: PartialRootCampaign;
}

export interface QuickEditLineItem {
  type: typeof QUICK_EDIT_LINE_ITEM;
  data: QuickEditLineItemPayload;
  originalInstructionParent: PartialRootCampaign;
}

export interface DiscardQuickEdits {
  type: typeof DISCARD_QUICK_EDITS;
}

export type CampaignsQuickEditsActionTypes =
  | QuickEditCampaign
  | QuickEditLineItem
  | DiscardQuickEdits;

export * from "./errors";
/** Proxies a map, so that [] accessors will yield the equivalent result of .get/.set. */
class MapProxy {
    get(target, key) {
        if (key in target) {
            if (typeof target[key] === "function") {
                return target[key].bind(target);
            }
            return target[key];
        }
        if (typeof target.get === "function") {
            return target.get(key);
        }
        return undefined;
    }
    set(target, key, value) {
        if (typeof target.set === "function") {
            target.set(key, value);
        }
        else {
            target[key] = value;
        }
        return true;
    }
}
/**
 * Extension of the native Map.
 * Provides easier error message getting.
 * Ensure accessing/changing keys/values can be done via .get/.set, or via brackets.
 */
export class Validation extends Map {
    constructor(iterable) {
        super(iterable);
        return new Proxy(this, new MapProxy());
    }
    /**
     * @param key: optional. Get the message related to a specific key.
     * @return: the message associated to the given key, or a general message indicating how many errors there are, along with one arbitrary error message.
     *          If there are no errors, or no errors associated with the given key, returns undefined.
     */
    message(key) {
        if (key) {
            return this.text(key);
        }
        const text = this.text(this.arbitraryKey);
        return this.size <= 1
            ? text
            : `${text}, and ${this.size - 1} other error${this.size > 2 ? "s" : ""}.`;
    }
    /**
     * @param key: a key to the map.
     * @return: the value of that key, if one exists. Else, undefined.
     */
    text(key) {
        if (typeof key === "undefined") {
            return undefined;
        }
        const value = this.get(key);
        if (typeof value === "undefined") {
            return undefined;
        }
        return typeof value === "string" ? value : `Invalid value for ${key}`;
    }
    /** @return: an arbitrary value from the map. Undefined, if there are no values. */
    get arbitraryKey() {
        const iterator = this.keys().next();
        if (iterator.done) {
            return undefined;
        }
        return iterator.value;
    }
}

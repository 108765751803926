import axios from "axios";
import { Templator } from "../handlers";
import { TemplateCreationFailed } from "../handlers/errors";
class Templates extends Templator {
    constructor(madSDK) {
        super(madSDK);
        this.download = (type) => {
            return this.create({ type });
        };
        this.createTemplate = async (template) => {
            const key = this.createCacheKey(template);
            let result;
            if (!this.cache.has(key)) {
                try {
                    if (template.type === "bulk") {
                        result = await this.fetchBulkTemplate();
                    }
                    else {
                        throw new Error(`Unsupported template type '${template.type}'`);
                    }
                }
                catch (error) {
                    throw new TemplateCreationFailed(error);
                }
                if (result) {
                    await this.cache.set(key, result);
                }
            }
            return Promise.resolve(this.cache.get(key));
        };
        this.createCacheKey = (template) => {
            const rowHashes = [];
            if (template.fileContent) {
                const contentString = typeof template.fileContent !== "string"
                    ? String.fromCharCode.apply(null, new Uint16Array(template.fileContent))
                    : template.fileContent;
                rowHashes.push(contentString);
            }
            return `type-${template.type}-${template.fileContent ? `file-content-${rowHashes.join("-")}` : "download"}`;
        };
        this.fetchBulkTemplate = async () => {
            const route = "/template/campaignUpload";
            const res = await axios.get(`${this.baseUrl}/${route}`, {
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/json"
                }
            });
            return res.data;
        };
        this.baseUrl = this.sdk.urls.baseAPIUrl;
    }
}
export default Templates;

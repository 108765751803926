export class InvalidBuilderSettings extends Error {
    constructor(...incorrect) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidBuilderSettings);
        }
        this.name = "InvalidBuilderSettings";
        this.message = `The provided configuration for the EmbedBuilder is invalid. The following are incorrect: ${incorrect.join(", ")}.`;
    }
}

/**
 * DEFINITIONS
 * A Recipe (service or otherwise) represents a set of segments, combined using boolean expressions to obtain some demographic to target. A Recipe is the same thing as an audience.
 * Example: recipeForPeople40To60WhoOwnDogs = AND(People ages 40-60, dog owners) <-- each argument to that AND is a segment
 * Recipes can be either STANDARD or CUSTOM.
 *    STANDARD recipes are created by MadHive, and provide users with a bundle of logically-combined segments to use in their own recipes. Users cannot edit these, or create their own.
 *    CUSTOM recipes are created by users, and can contain any combination of segments and STANDARD recipes.
 */
export var BooleanOperation;
(function (BooleanOperation) {
    BooleanOperation["AND"] = "AND";
    BooleanOperation["OR"] = "OR";
    BooleanOperation["NOT"] = "NOT";
    BooleanOperation["IDENTITY"] = "IDENTITY"; // expresses just a segment as the entire expression
})(BooleanOperation || (BooleanOperation = {}));
export const booleanExpressionLegacyToBooleanExpression = (ex) => {
    const children = [];
    for (const child of ex.children || []) {
        children.push(booleanExpressionLegacyToBooleanExpression(child));
    }
    return {
        operation: ex.Op,
        ids: ex.ids,
        children
    };
};
export var RecipeType;
(function (RecipeType) {
    RecipeType["STANDARD"] = "STANDARD";
    RecipeType["CUSTOM"] = "CUSTOM";
    RecipeType["PREFERRED"] = "PREFERRED";
    RecipeType["UNKNOWN"] = "UNKNOWN";
})(RecipeType || (RecipeType = {}));
/**
 * @param expr: a boolean expression obtained from the frontend/adhering to BooleanExpression.
 * @return: an equivalent object, formatted to the ServiceBooleanExpression interface.
 */
export const booleanExpressionToServiceBooleanExpression = (expr) => {
    // An expression node with one child that does not use the 'NOT' operator is superfluous
    if (!expr.ids.length && expr.children.length === 1 && expr.operation !== BooleanOperation.NOT) {
        return booleanExpressionToServiceBooleanExpression(expr.children[0]);
    }
    const isIdentityNode = expr.operation !== BooleanOperation.NOT && !expr.children.length && expr.ids.length === 1;
    return {
        // [and, or, not] are meaningless as operators if isIdentityNode is true
        operation: isIdentityNode ? BooleanOperation.IDENTITY : expr.operation,
        id: expr.ids,
        children: expr.children.map((childExpr) => booleanExpressionToServiceBooleanExpression(childExpr))
    };
};
/**
 * @param expr: a boolean expression obtained from the backend/adhering to ServiceBooleanExpression.
 * @return: an equivalent object, formatted to the BooleanExpression interface.
 */
export const serviceBooleanExpressionToBooleanExpression = (expr) => {
    return {
        operation: expr.operation,
        ids: expr.id,
        children: expr.children.map((childExpr) => serviceBooleanExpressionToBooleanExpression(childExpr))
    };
};
/**
 * @param recipe: a recipe obtained from the frontend/adhering to Recipe.
 * @return: an equivalent object, formatted to the ServiceRecipeBase interface.
 */
export const recipeToServiceRecipeBase = (recipe) => {
    return {
        id: recipe.id,
        name: recipe.name,
        type: recipe.type,
        parent: recipe.parent,
        category: recipe.category,
        cpm: recipe.cpm?.max,
        min_cpm: recipe.cpm?.min,
        household_reach: recipe.householdReach,
        description: recipe.description,
        expr: booleanExpressionToServiceBooleanExpression(recipe.expression),
        status: recipe.status
    };
};
/**
 * @param recipe: a recipe obtained from the frontend/adhering to Recipe.
 * @return: an equivalent object, formatted to the ServiceRecipe interface.
 */
export const recipeToServiceRecipe = (recipe) => {
    return {
        id: recipe.id,
        name: recipe.name,
        type: recipe.type,
        parent: recipe.parent,
        category: recipe.category,
        cpm: recipe.cpm?.max,
        min_cpm: recipe.cpm?.min,
        description: recipe.description,
        household_reach: recipe.householdReach,
        expr: booleanExpressionToServiceBooleanExpression(recipe.expression),
        status: recipe.status,
        created_at: recipe.createdOn.getTime(),
        created_by: recipe.createdBy
    };
};
/**
 * @param recipe: a recipe obtained from the frontend/adhering to Recipe.
 * @return: an equivalent object, formatted to the ServiceRecipe interface.
 */
export const serviceRecipeToRecipe = (recipe) => {
    return {
        id: recipe.id,
        name: recipe.name,
        type: recipe.type,
        parent: recipe.parent,
        category: recipe.category,
        cpm: {
            min: recipe.min_cpm,
            max: recipe.cpm
        },
        get price() {
            // cpm === cost per mile === cost per 1,000 impressions
            // so, for the client's interpretation of price, we divide it
            return {
                min: recipe.min_cpm / 100,
                max: recipe.cpm / 100
            };
        },
        description: recipe.description,
        householdReach: recipe.household_reach,
        expression: serviceBooleanExpressionToBooleanExpression(recipe.expr),
        status: recipe.status,
        createdOn: new Date(recipe.created_at * 1000),
        createdBy: recipe.created_by
    };
};

import { ProductionViews } from "types";
import { startCase } from "lodash";

export const viewDisplayName = (
  viewId: string,
  orgProductionViews: ProductionViews,
  version?: string
) => {
  // Default to startCase of viewId
  let displayName = startCase(viewId);

  // If viewId is in orgProductionViews, use the title
  if (orgProductionViews[viewId])
    displayName = orgProductionViews[viewId].title;

  // If version is provided, append it to the displayName
  if (version) {
    displayName = `${displayName} (${version.toUpperCase()})`;
  }

  return displayName;
};

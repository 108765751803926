import { useRef, useEffect } from "react";
import { OrganizationName } from "types";
import { KnownOrganizationNames } from "@madhive/mad-sdk";
import {
  HOSTNAME_TO_FAV_ICON,
  ORGANIZATION_NAME_TO_FAV_ICON
} from "lib/constants/config";

export const useFavicon = (org: OrganizationName) => {
  const refToFavIconLink = useRef<HTMLLinkElement | null>(null);

  useEffect(() => {
    const { hostname } = window.location;
    const favIconPublicURL =
      HOSTNAME_TO_FAV_ICON[hostname] ||
      ORGANIZATION_NAME_TO_FAV_ICON[org] ||
      ORGANIZATION_NAME_TO_FAV_ICON[KnownOrganizationNames.MADHIVE_DEFAULT];

    if (refToFavIconLink.current) {
      document.head.removeChild(refToFavIconLink.current);
    }

    const favIconLink = document.createElement("link");
    favIconLink.rel = "shortcut icon";
    favIconLink.href = favIconPublicURL;
    document.head.appendChild(favIconLink);
    refToFavIconLink.current = favIconLink;
  }, [org]);
};

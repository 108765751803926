import { deriveErrorResponse } from "../../utils/validation";
export class PlansFailedFetch extends Error {
    constructor(baseError) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PlansFailedFetch);
        }
        const apiErrorMessages = deriveErrorResponse(baseError);
        const detailMessage = (apiErrorMessages.length > 0 && `Details: ${apiErrorMessages.join(",")}`) || "";
        this.name = "PlansFetchFailed";
        this.message = `Failed to fetch plans. ${detailMessage}`;
    }
}
export class ExpiredScenario extends Error {
    constructor() {
        super();
        this.name = "ExpiredScenario";
        this.message = `Failed to fetch forecast results for scenario: scenario is expired. Please refresh its parent plan.`;
    }
}
export class CorruptedScenarioData extends Error {
    constructor() {
        super();
        this.name = "CorruptedScenarioData";
        this.message = `Failed to fetch forecast results for scenario: its status is ready, but its data is incorrectly formatted.`;
    }
}
export class ScenarioFetchFailed extends Error {
    constructor() {
        super();
        this.name = "ScenarioFetchFailed";
        this.message =
            "Failed to fetch forecast results for scenario: its status is ready, but its data is incorrectly formatted.";
    }
}
export class PlanSaveFailed extends Error {
    constructor() {
        super();
        this.name = "PlanSaveFailed";
        this.message = "Failed to save plan.";
    }
}
export class BadScenarioSavePayload extends Error {
    constructor() {
        super();
        this.name = "BadScenarioSavePayload";
        this.message = "Missing id of parent plan in scenario save payload.";
    }
}
export class ScenarioSaveFailure extends Error {
    constructor() {
        super();
        this.name = "ScenarioSaveFailure";
        this.message = "Failed to save scenario.";
    }
}

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const PaceToImpression: FC<CommonIconProps> = ({
  fill = "#5C6370",
  stroke = "#5C6370"
}) => {
  return (
    <svg width="100%" height="100%" viewBox="0 0 16 16" fill="none">
      <circle
        cx="8"
        cy="8"
        r="7.36842"
        stroke={stroke}
        strokeWidth="1.26316"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.33307 5.46316C5.33307 5.05177 5.66658 4.71827 6.07797 4.71828C6.31852 4.71829 6.54425 4.83446 6.68407 5.03021L8.71769 7.87724L10.7513 5.03019C10.8911 4.83444 11.1169 4.71826 11.3574 4.71826C11.7688 4.71826 12.1023 5.05175 12.1023 5.46314V11.0362H10.7485V7.58575L9.09136 10.0331C8.91237 10.2974 8.523 10.2974 8.34401 10.0331L6.68692 7.58575V11.0362H5.33307V5.46316ZM3.07666 11.0362V4.71826H4.43051V11.0362H3.07666Z"
        fill={fill}
      />
    </svg>
  );
};

export default PaceToImpression;

import { UnauthenticatedError } from "../../../errors";
// We don't want to store too many exported reports in localStorage
export const MAX_NUMBER_OF_SAVED_REPORTS = 10;
export default class ExportedReportStorage {
    constructor(sdk) {
        this.sdk = sdk;
    }
    async getStorageKey() {
        if (!this.key) {
            const user = this.sdk.getCurrentUser();
            if (!user) {
                return Promise.reject(new UnauthenticatedError());
            }
            this.key = `report_exports_${user.id}`;
        }
        return Promise.resolve(this.key);
    }
    async getExports() {
        try {
            const storageKey = await this.getStorageKey();
            const item = window.localStorage.getItem(storageKey);
            if (item) {
                const exports = JSON.parse(item);
                const sortedExports = exports
                    .map((report) => ({
                    ...report,
                    createdAt: new Date(report.createdAt)
                }))
                    // sort by createdAt desc
                    .sort((a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
                return Promise.resolve(sortedExports);
            }
        }
        catch (err) {
            console.error("Error retrieving exports from local storage", err);
        }
        return Promise.resolve([]);
    }
    async saveExport(reportExport) {
        const reportExports = await this.getExports();
        // add report to first array position
        reportExports.unshift(reportExport);
        const reportsToSave = reportExports.length > MAX_NUMBER_OF_SAVED_REPORTS
            ? reportExports.slice(0, MAX_NUMBER_OF_SAVED_REPORTS)
            : reportExports;
        await this.saveAllReportExports(reportsToSave);
        return Promise.resolve();
    }
    async clearExports() {
        const storageKey = await this.getStorageKey();
        window.localStorage.removeItem(storageKey);
        return Promise.resolve();
    }
    async saveAllReportExports(reportExports) {
        const storageKey = await this.getStorageKey();
        window.localStorage.setItem(storageKey, JSON.stringify(reportExports));
    }
    async updateExportStatus(id, status, results = []) {
        const reportExports = await this.getExports();
        const reportIndex = reportExports.findIndex((report) => report.id === id);
        const report = reportExports[reportIndex];
        report.results = results;
        report.status = status;
        await this.saveAllReportExports(reportExports);
        return report;
    }
}

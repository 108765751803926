import axios from "axios";
import { NoAuthorizedUser, UnauthenticatedError } from "../../errors";
import { Handler } from "../handlers";
import { serviceAgencyToAgency, agencyToServiceAgency } from "../../models/agency";
import { isNotAdvertiser } from "../../utils";
import { OrganizationType } from "../../types";
import { validateFilterStructure, FilterValueTypes, FilterTypes, parseIdFilter } from "../handlers/filter";
import { AgenciesFetchFailed, AgencyDeleteFailed, AgencyIdCreationFailed, AgencyCreationFailed, AgencyUpdateFailed, AgencyExistsUpdateFailure, AgencyExistsCreationFailure, AgencyInvalidNameCreationFailure } from "./errors";
import { toQueryParams } from "./utils";
import { validators } from "./validators";
class Agencies extends Handler {
    constructor(sdk) {
        super(sdk, "agencies", { atomize: true });
        this.validators = validators;
    }
    async findItems(filters, sort = { field: "name", direction: "asc" }) {
        validateFilterStructure(filters, [
            { filterType: FilterTypes.EQ, valueType: FilterValueTypes.STRING },
            { filterType: FilterTypes.EQ, valueType: FilterValueTypes.NUMBER },
            { filterType: FilterTypes.IN, valueType: FilterValueTypes.OBJECT }
        ]);
        const idFilter = parseIdFilter(filters);
        const promise = idFilter.size === 1
            ? this.getAgency(idFilter.values().next().value).then((agency) => [agency])
            : this.getAgencies(filters, sort);
        return promise.catch(() => {
            throw new AgenciesFetchFailed();
        });
    }
    /**
     * @param filters: filters to narrow down the set of agencies.
     * @return: the set of agencies matching the filters (if any). If no filters, all available agencies.
     */
    getAgencies(filters, sort) {
        // If no status filter is specified, default to active Agencies.
        const activeStatusFilter = {
            field: "status",
            type: FilterTypes.EQ,
            value: "200"
        };
        if (!filters) {
            filters = { where: [activeStatusFilter] };
        }
        else if (filters.where &&
            !filters.where?.some((where) => where.field === "status" || where.field === "id")) {
            filters.where = [...filters.where, activeStatusFilter];
        }
        const url = `${this.sdk.urls.baseAPIUrl}/agencies${toQueryParams(filters, sort)}`;
        return this.cache.promise(url, () => axios
            .get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => {
            return res.data.map((agency) => this.toAgency(agency));
        }));
    }
    /**
     * @param id: an agency id.
     * @return: the agency with that id.
     */
    getAgency(id) {
        return this.cache.promise(id, () => axios
            .get(`${this.sdk.urls.baseAPIUrl}/agency/${id}`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => {
            return this.toAgency(res.data);
        }));
    }
    saveItem(agency) {
        const user = this.sdk.getCurrentUser();
        if (!user) {
            throw new UnauthenticatedError();
        }
        else if (!isNotAdvertiser(user)) {
            throw new NoAuthorizedUser();
        }
        return this.normalize(agency).then((normalized) => axios
            .post(`${this.sdk.urls.baseAPIUrl}/agency`, this.fromAgency(normalized), {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => {
            return this.toAgency(res.data);
        })
            .catch((error) => {
            throw (this.getSpecificAgenciesError(error) ||
                (!agency.id ? new AgencyCreationFailed() : new AgencyUpdateFailed()));
        }));
    }
    /**
     * @param agency: an agency or a base agency.
     * @return: the input normalized into a fully-fledged Agency.
     */
    normalize(agency) {
        if (agency.id) {
            return Promise.resolve(agency);
        }
        return this.sdk.cryptography
            .mintOrgKey(OrganizationType.AGENCY)
            .then((id) => ({ ...agency, id }))
            .catch(() => {
            throw new AgencyIdCreationFailed();
        });
    }
    deleteItem(id) {
        return axios
            .delete(`${this.sdk.urls.baseAPIUrl}/agency/${id}`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => {
            return this.toAgency(res.data);
        })
            .catch(() => {
            throw new AgencyDeleteFailed();
        });
    }
    /**
     * @param error: an error from the API.
     * @param agency: optional. The agency associated with that error, if any.
     * @return: a specific error to throw, or null, if none can be determined.
     */
    getSpecificAgenciesError(error, agency) {
        const message = error.response?.data?.errors;
        if (agency && message) {
            if (AgencyExistsCreationFailure.is(message)) {
                return new AgencyExistsCreationFailure(agency.externalId);
            }
            else if (AgencyExistsUpdateFailure.is(message)) {
                return new AgencyExistsUpdateFailure(agency.externalId);
            }
            else if (AgencyInvalidNameCreationFailure.is(message)) {
                return new AgencyInvalidNameCreationFailure(agency.name);
            }
        }
        return null;
    }
    toAgency(agency) {
        return serviceAgencyToAgency(agency);
    }
    fromAgency(agency) {
        return agencyToServiceAgency(agency);
    }
}
export default Agencies;

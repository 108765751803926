import { useRef, useEffect } from "react";
import classNames from "classnames";
import styled from "@emotion/styled";
import { CheckboxProps } from "./types";
import { inputChecked, inputIndeterminate } from "./style";

export const BaseComponent = (props: CheckboxProps) => {
  const { children, className, indeterminate, ...checkboxProps } = props;

  /**
   * For the sake of consistent behavior, indeterminate state
   * takes precedence over checked state
   */
  const isChecked = indeterminate ? false : checkboxProps.checked;

  const checkboxId = props.id || "checkbox-input";

  const labelId = `${checkboxId}-label`;

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.indeterminate = !!indeterminate;
  }, [indeterminate, inputRef.current]);

  return (
    <div
      className={classNames(
        className,
        "Checkbox",
        props.disabled && "Checkbox--disabled"
      )}
    >
      <input
        {...checkboxProps}
        id={checkboxId}
        aria-labelledby={labelId}
        checked={isChecked}
        type="checkbox"
        className="Checkbox-Input"
        ref={inputRef}
        aria-checked={indeterminate ? "mixed" : !!checkboxProps.checked}
      />
      {children && (
        <div
          id={labelId}
          onClick={ev => {
            if (props.disabled) return;

            if (!inputRef.current) return;

            // eslint-disable-next-line no-param-reassign
            ev.target = inputRef.current;

            if (checkboxProps.onChange) {
              checkboxProps.onChange(ev as any);
            }
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export const Checkbox = styled(BaseComponent)<CheckboxProps>`
  display: flex;
  align-items: ${props => (props.align ? props.align : "center")};
  cursor: ${props => (props.disabled ? "default" : "pointer")};

  .Checkbox {
    &-Input {
      cursor: ${props => (props.disabled ? "default" : "pointer")};
      appearance: none;
      height: 16px;
      width: 16px;
      min-width: 16px;
      max-width: 16px;
      border: 1px solid var(--gray-4);
      margin-right: ${props =>
        props.children ? "var(--spacing-8)" : "var(--spacing-0)"};
      border-radius: 3px;
    }

    &-Input:checked {
      ${inputChecked}
    }

    &-Input:indeterminate {
      ${inputIndeterminate}
    }

    &-Input:disabled {
      background-color: var(--gray-3);
    }

    &-Input:focus {
      border: 1px solid var(--gray-5);
    }

    &-Input:hover {
      &:checked {
        border: none;
        background-color: ${props =>
          props.disabled ? "var(--gray-3);" : "var(--primary-focus)"};
      }
    }
  }

  &.Checkbox--disabled {
    color: var(--gray-4);
  }
`;

import { ReactText } from "react";
/* eslint-disable no-plusplus */
import JSZip from "jszip";
import { BASE_APP_NAME } from "lib/constants/config";
import {
  compareOrgRules,
  RULE_WHITE_LABEL_ORGS_IDS
} from "lib/constants/orgRules";
import { stringify } from "@madhive/mad-sdk";
import { downloadBlob, downloadFile } from "frontier/lib/utils/files";

export type CSVContent = ReactText[][];

export type CSV = {
  rows: CSVContent;
  headings: string[];
  customFileName: string;
};

export type CSVOptions = {
  fallback?: string;
  escapeDoubleQuotes?: boolean;
};

export const generateCSVBlob = (csv: CSV, options?: CSVOptions) => {
  const { headings, rows } = csv;
  const headingRow = `${headings.join(",")}\n`;
  const dataRows = rows.reduce((acc, row) => {
    row.forEach((cell, cellIndex) => {
      // eslint-disable-next-line no-param-reassign
      acc += `${stringify(cell, options)}${
        cellIndex < row.length - 1 ? "," : ""
      }`;
    });

    return `${acc}\n`;
  }, "");

  const blob = new Blob([headingRow + dataRows], {
    type: "text/csv;charset=utf-8;"
  });

  return blob;
};

export enum Extension {
  ZIP = "zip",
  CSV = "csv"
}

export const downloadContent = (
  content: any,
  extension: Extension,
  fileName?: string
) => {
  const url = URL.createObjectURL(content);
  const link = document.createElement("a");
  link.setAttribute("href", url);
  link.setAttribute("download", `${fileName || "export"}.${extension}`);
  link.style.visibility = "hidden";

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const bulkDownloadCSV = (csvs: CSV[], zipName?: string) => {
  const zip = new JSZip();
  const blobMap = csvs.map(csv => generateCSVBlob(csv));

  for (let file = 0; file < csvs.length; file++) {
    // Zip file with the file name.
    zip.file(
      `${csvs[file].customFileName.replaceAll(":", "")}.csv`,
      blobMap[file]
    );
  }
  zip
    .generateAsync({ type: "blob" })
    .then((content: any) => {
      // Create the URL before downloading
      const url = URL.createObjectURL(content);
      downloadFile(url, `${zipName}.${Extension.ZIP}`);
    })
    .catch(err => {
      console.error(err);
    });
};

export interface CSVUrl {
  url: string;
  fileName: string;
}

export const bulkDownloadCSVByURL = async ({
  csvUrls,
  zipName,
  showError
}: {
  csvUrls: CSVUrl[];
  zipName?: string;
  showError?: (message: string) => void;
}) => {
  try {
    const downloadedFiles = await Promise.all(
      /**
       * using fetch here instead of axios, because axios has an interceptor that adds authorization headers to all requests
       * we don't want to add auth headers for these are made to google storage buckets and the header is not needed.
       * instead of refactoring and adding complexity to the axios interceptor decided to use fetch for this use case.
       */
      csvUrls.map(({ url, fileName }: CSVUrl) =>
        fetch(url, {
          method: "GET"
        })
          .then((response: Response) => response.blob())
          .then((blob: Blob) => ({
            url,
            data: blob,
            fileName
          }))
      )
    );

    if (csvUrls.length === 1) {
      // don't need to zip files if only one csv is to be downloaded
      const [csv] = downloadedFiles;

      downloadBlob(csv.data, `${csv.fileName}.${Extension.CSV}`);
    } else {
      // zip all downloaded csv files
      const zip = new JSZip();
      downloadedFiles.forEach(({ data, fileName = "custom-report" }) => {
        zip.file(`${fileName}.csv`, data);
      });

      zip
        .generateAsync({ type: "blob" })
        .then((content: any) => {
          downloadFile(content, `${zipName}.${Extension.ZIP}`);
        })
        .catch(err => {
          console.error(err);
        });
    }
  } catch (err) {
    console.error(err);

    if (showError) {
      showError(`There was an error while downloading CSVs.`);
    }
  }
};

export const downloadCSV = (
  rows: CSVContent,
  headings: string[],
  customFileName = "",
  options?: CSVOptions
) => {
  const csv = {
    rows,
    headings,
    customFileName
  };

  const blob = generateCSVBlob(csv, options);
  const now = new Date().toISOString();
  const fileName = customFileName || `${BASE_APP_NAME}-${now}`;
  downloadBlob(blob, `${fileName}.${Extension.CSV}`);
};

/**
 * Method to support downloadCSV for MediaMath or another
 * profiles with white-label concept
 * @param rows
 * @param headings
 * @param organizationId ID of organization
 * @param customFileName if defined, bypass organization
 */
export const downloadCSVByWhiteLabelOrg = (
  rows: CSVContent,
  headings: string[],
  organizationId?: string,
  customFileName?: string
) => {
  const finalCustomFileName =
    customFileName ||
    (organizationId &&
    compareOrgRules(organizationId, RULE_WHITE_LABEL_ORGS_IDS)
      ? new Date().toISOString()
      : undefined);

  downloadCSV(rows, headings, finalCustomFileName);
};

export const readUploadedFileAsText = (inputFile: File): Promise<string> => {
  const temporaryFileReader = new FileReader();

  return new Promise((resolve, reject) => {
    temporaryFileReader.onerror = () => {
      temporaryFileReader.abort();
      reject(new DOMException("Problem parsing input file."));
    };

    temporaryFileReader.onload = () => {
      resolve(temporaryFileReader.result as string);
    };
    temporaryFileReader.readAsText(inputFile);
  });
};

/**
 * Credit to: https://stackoverflow.com/a/14991797
 *
 * @param {string} csv
 * @param {Function} reviver
 */
export const parseCsv = (
  csv: string,
  reviver = (r: number, c: number, v: string) => v
) => {
  const chars = csv.split("");
  const table: Array<Array<string | number>> = [];
  const cc = chars.length;

  let c = 0;
  let start;
  let end;
  let row: Array<string | number>;

  while (c < cc) {
    table.push((row = []));
    while (c < cc && chars[c] !== "\r" && chars[c] !== "\n") {
      start = c;
      end = c;
      if (chars[c] === '"') {
        start = ++c;
        end = ++c;
        while (c < cc) {
          if (chars[c] === '"') {
            if (chars[c + 1] !== '"') {
              break;
            } else {
              chars[++c] = "";
            } // unescape ""
          }
          end = ++c;
        }
        if (chars[c] === '"') {
          ++c;
        }
        while (
          c < cc &&
          chars[c] !== "\r" &&
          chars[c] !== "\n" &&
          chars[c] !== ","
        ) {
          ++c;
        }
      } else {
        while (
          c < cc &&
          chars[c] !== "\r" &&
          chars[c] !== "\n" &&
          chars[c] !== ","
        ) {
          end = ++c;
        }
      }
      row.push(
        reviver(table.length - 1, row.length, chars.slice(start, end).join(""))
      );
      if (chars[c] === ",") {
        ++c;
      }
    }
    if (chars[c] === "\r") {
      ++c;
    }
    if (chars[c] === "\n") {
      ++c;
    }
  }
  return table;
};

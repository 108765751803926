import {
  KeyboardEventHandler,
  MouseEventHandler,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  KeyboardEvent
} from "react";
import { css } from "@emotion/react";
import { styles } from "./styles";
import { useFocusOnMount, useSizingProp, useResizeObserver } from "../../hooks";
import { DoNotCare } from "../../types";
import {
  isPropsMultiSelect,
  isPropsSingleSelect,
  SelectTargetProps
} from "./types";
import Chips, { ChipsSetFocusRef } from "../Chips";
import { EMPTY_SELECT_TEXT, findLengthOfLongestOption } from "./utils";
import SelectClearButton from "./ClearButton";
import * as Icons from "../Icons";

const SelectTarget = <
  Selected extends string | undefined | string[],
  Item extends string | Record<string, DoNotCare>
>({
  selected,
  options,
  focusOnMount,
  selectedIds,
  itemsMap,
  onSelect,
  lookupLabel,
  lookupIntent,
  lookupIcon,
  selectedSeparator,
  label,
  fixed,
  readonly,
  fill,
  fillToWidestOption,
  disabled,
  error,
  cannotClear,
  width: incomingWidth,
  minWidth: incomingMinWidth,
  isOpen,
  onFocus,
  onBlur,
  onClick,
  setTargetWidth,
  setFocusRef
}: SelectTargetProps<Selected, Item>) => {
  const targetRef = useRef<HTMLDivElement>(null);

  useFocusOnMount(targetRef, !!focusOnMount);

  const rawWidth = useSizingProp(incomingWidth, "auto");
  const minWidth = useSizingProp(incomingMinWidth, "auto");

  const selectProps = {
    selected,
    onSelect
  };

  let setFocusChipRef: ChipsSetFocusRef | null = null;

  useImperativeHandle(setFocusRef, () => ({
    chipIndex: setFocusChipRef,
    target: () => targetRef.current?.focus()
  }));

  const onTargetClick: MouseEventHandler<HTMLElement> = e => {
    e.stopPropagation();
    e.preventDefault();
    if (disabled) {
      return;
    }
    onClick?.(e);
  };

  const onChipsBlur = (direction: "next" | "prev") => {
    switch (direction) {
      case "next":
        onBlur(isOpen ? "next" : undefined);
        break;
      case "prev":
        onBlur("prev");
        break;
      default:
        break;
    }
  };

  const onTargetKeyDown: KeyboardEventHandler<HTMLElement> = e => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    switch (e.key) {
      case "ArrowUp":
        onBlur("prev");
        break;
      case "ArrowDown": {
        onBlur("next");
        break;
      }
      case "Tab": {
        if (e.shiftKey) {
          onBlur("prev");
        } else {
          onBlur(isOpen ? "next" : undefined);
        }
        break;
      }
      case " ":
      case "Enter":
        onBlur(isOpen ? "prev" : "next");
        break;
      case "Escape":
        e.preventDefault();
        e.stopPropagation();
        onBlur("prev");
        break;
      case "Backspace":
      case "Delete": {
        if (cannotClear || disabled) {
          break;
        }
        if (isPropsSingleSelect(selectProps)) {
          selectProps.onSelect?.(undefined);
        } else if (isPropsMultiSelect(selectProps)) {
          selectProps.onSelect?.([...(fixed?.values() || [])]);
        }
        onBlur("next");
        break;
      }
      default:
        break;
    }
  };

  const width = useMemo(
    () => {
      if (fill) {
        return "100%";
      }
      if (fillToWidestOption) {
        const longestOption = findLengthOfLongestOption(options, lookupLabel);
        if (!longestOption) {
          return "fit-content";
        }
        return `calc(${longestOption}ch + var(--spacing-16))`;
      }
      if (rawWidth) {
        return rawWidth;
      }
      return undefined;
    },
    /**
     * Options is deliberately left out of the dependencies array
     * Reason is that on isLoading new options the size of the target might shift and create a negative user experience.
     * The first set of options will define the size of the target and the size will not change on new options.
     * TODO: to be verified with the Searchable logic in situation when isLoading comes first
     */
    [rawWidth, fill, fillToWidestOption, readonly]
  );

  useResizeObserver(targetRef, () =>
    setTargetWidth(targetRef.current?.offsetWidth || 100)
  );

  useEffect(() => {
    setTargetWidth(targetRef.current?.offsetWidth || 100);
  }, [width, readonly]);

  const clearable = selectedIds.length > (fixed?.size || 0);

  const ToggleIcon = (
    <div
      className="kit-select-target-toggle-icon"
      css={[
        styles.toggleIcon,
        styles.iconSizing,
        css`
          transform: rotate(${isOpen ? "180deg" : "0deg"});
        `
      ]}
    >
      <Icons.Chevron direction="down" />
    </div>
  );

  const lookupLabelFromId = (id: string | undefined) => {
    const foundItem = !!id && itemsMap?.get(id);
    if (foundItem) {
      return foundItem ? lookupLabel(foundItem) : undefined;
    }
    return undefined;
  };

  const lookupIconFromId = (id: string | undefined) => {
    const foundItem = !!id && itemsMap?.get(id);
    if (foundItem) {
      return foundItem ? lookupIcon?.(foundItem) : undefined;
    }
    return undefined;
  };

  const lookupReadonly = (id: string | undefined) => {
    return id ? !!fixed?.has(id) : false;
  };

  const lookupIntentFromId = (id: string | undefined) => {
    const foundItem = !!id && itemsMap?.get(id);
    if (foundItem) {
      return foundItem ? lookupIntent?.(foundItem) : undefined;
    }
    return undefined;
  };

  const onSelectedChipKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    switch (e.key) {
      case "ArrowUp":
        onChipsBlur("prev");
        break;
      case "ArrowDown":
      case "Enter":
      case " ": {
        e.preventDefault();
        e.stopPropagation();
        onChipsBlur("next");
        break;
      }
      default:
        break;
    }
  };

  return (
    <div
      aria-label={label}
      ref={targetRef}
      css={[
        styles.target,
        !!selectedIds.length && styles.hasSelection,
        !!error && styles.hasError,
        !!disabled && styles.isDisabled,
        css`
          width: ${width};
          min-width: ${minWidth};
        `
      ]}
      className="subKit-SelectTarget"
      role="button"
      aria-expanded={isOpen}
      aria-disabled={disabled}
      tabIndex={disabled ? -1 : 0}
      onClick={onTargetClick}
      onFocus={onFocus}
      onKeyDown={onTargetKeyDown}
    >
      {isPropsMultiSelect(selectProps) && (
        <div
          className="kit-select-multi-target"
          css={css`
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: ${selected?.length ? "flex-start" : "center"};
          `}
        >
          <Chips
            items={selectedIds}
            setFocusRef={ref => (setFocusChipRef = ref)}
            onChipsBlur={onChipsBlur}
            onChange={selectProps.onSelect}
            lookupId={id => id}
            lookupLabel={lookupLabelFromId}
            lookupIcon={lookupIconFromId}
            lookupIntent={lookupIntentFromId}
            lookupReadonly={lookupReadonly}
            onClick={e => e.stopPropagation()}
            disabled={disabled}
            onKeyDown={onSelectedChipKeyDown}
            emptyContent={
              <span css={styles.targetText}>{EMPTY_SELECT_TEXT}</span>
            }
            separator={selectedSeparator}
          />
          {clearable ? (
            <SelectClearButton onClick={() => selectProps.onSelect([])} />
          ) : (
            ToggleIcon
          )}
        </div>
      )}
      {isPropsSingleSelect(selectProps) && (
        <div
          className="kit-select-single-target"
          css={css`
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <span css={styles.targetText}>
            {lookupLabelFromId(selectProps.selected) || EMPTY_SELECT_TEXT}
          </span>
          {!cannotClear && !!selectProps.selected ? (
            <SelectClearButton
              onClick={() => selectProps.onSelect?.(undefined)}
            />
          ) : (
            ToggleIcon
          )}
        </div>
      )}
    </div>
  );
};

export default SelectTarget;

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

type EyeIconVariants = "show" | "hidden";

type Props = CommonIconProps & {
  variant: EyeIconVariants;
};

const Eye: FC<Props> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)",
  variant = "show"
}) => {
  return (
    <svg
      className={`icon-eye-${variant}`}
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      {variant === "show" && (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.37365 9.3736C10.8242 7.92308 13.1759 7.92308 14.6265 9.3736C16.077 10.8241 16.077 13.1759 14.6265 14.6264C13.1759 16.0769 10.8242 16.0769 9.37365 14.6264C7.92312 13.1759 7.92312 10.8241 9.37365 9.3736ZM10.3838 10.3838C11.2764 9.49115 12.7237 9.49115 13.6163 10.3838C14.5089 11.2764 14.5089 12.7236 13.6163 13.6162C12.7237 14.5089 11.2764 14.5089 10.3838 13.6162C9.4912 12.7236 9.4912 11.2764 10.3838 10.3838Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0001 4.25C10.0367 4.25 8.13192 5.09876 6.49933 6.36043C4.86263 7.62528 3.4499 9.34151 2.45834 11.1753C2.18143 11.6885 2.18109 12.3098 2.45801 12.8231C3.44956 14.6574 4.86259 16.3744 6.4993 17.6394C8.13191 18.9012 10.0367 19.75 12.0001 19.75C13.9635 19.75 15.8682 18.9012 17.5008 17.6396C19.1375 16.3747 20.5502 14.6585 21.5418 12.8247C21.8187 12.3115 21.8191 11.6902 21.5421 11.1769C20.5506 9.34261 19.1376 7.62557 17.5008 6.36058C15.8682 5.09876 13.9635 4.25 12.0001 4.25ZM3.77804 11.8883C4.67846 10.2232 5.95914 8.67362 7.41656 7.54732C8.87822 6.41774 10.4685 5.75 12.0001 5.75C13.5317 5.75 15.1219 6.41774 16.5836 7.54742C18.041 8.67389 19.3218 10.2238 20.2222 11.8895C20.259 11.9579 20.259 12.0428 20.2223 12.1113C19.3219 13.7765 18.0411 15.3263 16.5836 16.4527C15.1219 17.5823 13.5317 18.25 12.0001 18.25C10.4685 18.25 8.87823 17.5823 7.41659 16.4526C5.95915 15.3261 4.67846 13.7763 3.77802 12.1107C3.74108 12.042 3.74109 11.9569 3.77804 11.8883Z"
          />
        </>
      )}
      {variant === "hidden" && (
        <>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.0001 4.25C10.0367 4.25 8.13189 5.09876 6.49928 6.36058C4.86257 7.62557 3.44954 9.34261 2.45799 11.1769C2.18107 11.6902 2.18145 12.3115 2.45836 12.8248C3.31491 14.4084 4.48514 15.9039 5.84211 17.0973L4.46973 18.4697C4.17683 18.7626 4.17683 19.2374 4.46973 19.5303C4.76262 19.8232 5.23749 19.8232 5.53039 19.5303L7.46089 17.5998C7.47772 17.5845 7.49397 17.5682 7.50957 17.5511L17.5659 7.49485C17.5712 7.48971 17.5764 7.48447 17.5816 7.47914L19.5304 5.53033C19.8233 5.23744 19.8233 4.76256 19.5304 4.46967C19.2375 4.17678 18.7626 4.17678 18.4697 4.46967L16.9683 5.97105C15.4606 4.92705 13.7533 4.25 12.0001 4.25ZM15.8878 7.05155C14.6254 6.22501 13.2916 5.75 12.0001 5.75C10.4684 5.75 8.87821 6.41774 7.41657 7.54742C5.95903 8.67393 4.67827 10.2239 3.77782 11.8897C3.74079 11.9583 3.74103 12.0429 3.77797 12.1116C4.57176 13.5791 5.66054 14.9565 6.90521 16.0342L8.76336 14.176C7.74143 12.6595 7.90122 10.5832 9.24272 9.24166C10.5842 7.90016 12.6605 7.74037 14.1771 8.7623L15.8878 7.05155ZM10.3034 10.3023C11.0553 9.5504 12.1826 9.40181 13.0829 9.85654L9.85761 13.0818C9.40287 12.1815 9.55146 11.0542 10.3034 10.3023Z"
          />
          <path d="M19.6493 8.43387C19.3803 8.11891 18.9069 8.08167 18.5919 8.35069C18.277 8.61971 18.2397 9.09312 18.5088 9.40808C19.1679 10.1798 19.7511 11.0183 20.2221 11.8893C20.2591 11.958 20.2594 12.0426 20.2223 12.1112C19.3219 13.7765 18.0411 15.3263 16.5836 16.4527C15.1219 17.5822 13.5317 18.25 12 18.25C11.2641 18.25 10.513 18.0944 9.76677 17.8055C9.38048 17.656 8.94613 17.848 8.79661 18.2343C8.6471 18.6205 8.83904 19.0549 9.22533 19.2044C10.1171 19.5496 11.052 19.75 12 19.75C13.9634 19.75 15.8682 18.9012 17.5008 17.6395C19.1375 16.3747 20.5502 14.6585 21.5418 12.8247C21.8187 12.3114 21.8187 11.6895 21.5418 11.1762C21.0168 10.2054 20.3721 9.28003 19.6493 8.43387Z" />
        </>
      )}
    </svg>
  );
};
export default Eye;

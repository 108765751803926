import { isValidUrlWithRequiredProtocol } from "../../../utils";
import { Validation } from "../../../validation";
export const validateTrackingPixelUrl = (url, currentUrls) => {
    if (!url) {
        return undefined;
    }
    if (!isValidUrlWithRequiredProtocol(url)) {
        return "Tracking pixels must be a valid URL";
    }
    let seen = 0;
    for (const currentUrl of currentUrls || []) {
        if (currentUrl === url) {
            seen += 1;
        }
        if (seen > 1) {
            return "Duplicate tracking pixel URLs are not allowed.";
        }
    }
    return undefined;
};
/**
 * Get a validation object for tracking pixels.
 * Validation is keyed by errored URL.
 * @param trackingPixels - array of tracking pixel objects
 */
export const validateTrackingPixels = (trackingPixels, urls, returnAfterFirstError = false) => {
    const validation = new Validation();
    if (!trackingPixels?.length && !urls?.length) {
        return validation;
    }
    const allUrls = trackingPixels?.flatMap(({ urls = [] }) => urls?.filter(Boolean)) || [];
    const urlsToValidate = new Set(Array.isArray(urls) ? urls : allUrls);
    for (const url of urlsToValidate) {
        const error = validateTrackingPixelUrl(url, allUrls);
        if (error) {
            validation.set(url, error);
            if (returnAfterFirstError) {
                return validation;
            }
        }
    }
    return validation;
};

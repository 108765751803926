import { FC } from "react";
import { styles } from "./styles";
import { InputTimeProps } from "./types";
import { useInputTime } from "../../hooks/useInputTime";

const InputTime: FC<InputTimeProps> = ({
  hour,
  minute,
  second,
  onChange,
  error
}) => {
  const {
    displayTime,
    amPm,
    hourRef,
    minuteRef,
    secondRef,
    amPmRef,
    setHour,
    setMinute,
    setSecond,
    toggleAmPm,
    onKeyDown,
    commitChange
  } = useInputTime({ hour, minute, second, onChange });

  return (
    <div
      css={[styles.base, error && styles.withError]}
      className="kit-InputTime"
      data-testid="kit-InputTime"
    >
      <input
        data-testid="input-time-hour"
        onFocus={e => e.target?.select()}
        ref={hourRef}
        value={displayTime.hour}
        onBlur={commitChange}
        onChange={e => setHour(Number(e.target?.value))}
        onKeyDown={e => onKeyDown(e, "hour")}
        onClick={e => e.stopPropagation()}
      />
      <span css={styles.colon}>:</span>
      <input
        data-testid="input-time-minute"
        ref={minuteRef}
        onFocus={e => e.target?.select()}
        value={displayTime.minute}
        onBlur={commitChange}
        onChange={e => setMinute(Number(e.target?.value))}
        onClick={e => e.stopPropagation()}
        onKeyDown={e => onKeyDown(e, "minute")}
      />
      <span css={styles.colon}>:</span>
      <input
        data-testid="input-time-second"
        ref={secondRef}
        onFocus={e => e.target?.select()}
        onBlur={commitChange}
        onChange={e => setSecond(Number(e.target?.value))}
        onClick={e => e.stopPropagation()}
        onKeyDown={e => onKeyDown(e, "second")}
        value={displayTime.second}
      />
      <button
        data-testid="input-time-am-pm"
        type="button"
        ref={amPmRef}
        onClick={e => {
          toggleAmPm();
          e.stopPropagation();
        }}
        onKeyDown={e => onKeyDown(e, "amPm")}
      >
        {amPm}
      </button>
    </div>
  );
};
export default InputTime;

import { IToastProps } from "@blueprintjs/core";

export const SHOW_TOAST = "SHOW_TOAST";
export const HIDE_TOAST = "HIDE_TOAST";
export const CLEAR_TOASTS = "CLEAR_TOASTS";
export interface ToastOptionsType extends IToastProps {
  uid?: number;
}

interface ShowToastAction extends ToastOptionsType {
  type: typeof SHOW_TOAST;
}

interface HideToastAction {
  type: typeof HIDE_TOAST;
  uid: number;
}

interface ClearToastAction {
  type: typeof CLEAR_TOASTS;
}

export interface ToastActionWithPayload<
  AT extends string,
  TT extends ToastType,
  P
> extends ToastAction<AT, TT> {
  payload: P;
}

export enum ToastType {
  error = "error",
  success = "success",
  warning = "warning",
  info = "info"
}

export type ToastMessageBody = {
  message: string;
} & IToastProps;

export type ToastMetaBody<T extends ToastType> = {
  [P in T]: ToastMessageBody;
};

export interface ToastAction<AT extends string, TT extends ToastType> {
  type: AT;
  meta: ToastMetaBody<TT>;
}

export type ToastActionTypes =
  | ShowToastAction
  | HideToastAction
  | ClearToastAction;

import { ARCHIVE_FILTER_TO_URL_PARAM, serviceToClientPlan } from "../../models/plans/index";
import { convertTimeToUnixTimestamp } from "../../utils/index";
import axios from "axios";
import { Poller } from "../handlers/poller";
export const POLL_INTERVAL = 7000;
const getMostRecentPlanUpdate = (pollResult) => {
    return pollResult.reduce((newest, plan) => {
        const planLastUpdated = plan.lastUpdated.getTime();
        return planLastUpdated > (newest || 0) ? planLastUpdated : newest;
    }, 0);
};
class PlanPoller extends Poller {
    constructor(sdk, appliedFilters, config) {
        super(sdk, {
            data: config?.data,
            interval: config?.interval || POLL_INTERVAL
        });
        this.appliedFilters = appliedFilters;
        /**
         * starting with a null mostRecentUpdate timestamp ensures that when
         * polling plans begins, the first api call will request all plans, not
         * just plans after a given time.
         */
        this.mostRecentPlanUpdate = null;
    }
    async fetchData() {
        const { archiveStatus, allUsers, sinceDate } = this.appliedFilters;
        // If sinceDate is not provided, get all plans since unix OS inception.
        const getPlansSince = sinceDate ? sinceDate : 0;
        const plansUpdatedSinceLastPoll = await axios.get(`${this.sdk.urls.basePlannerServiceUrl}/plans?min_last_updated_at=${this.mostRecentPlanUpdate
            ? convertTimeToUnixTimestamp(this.mostRecentPlanUpdate) + 1
            : getPlansSince}${archiveStatus ? `&statuses=${ARCHIVE_FILTER_TO_URL_PARAM[archiveStatus]}` : ""}${allUsers ? `&all_users=${allUsers}` : ""}`);
        if (plansUpdatedSinceLastPoll.data.data === null) {
            return [];
        }
        return plansUpdatedSinceLastPoll.data.data.map(serviceToClientPlan);
    }
    async requestPlan(planId) {
        if (!planId)
            throw new Error("Plan does not exist");
        return axios
            .get(`${this.sdk.urls.basePlannerServiceUrl}/plan/${planId}`)
            .then((res) => res.data);
    }
    shouldEmitPlanUpdate(update) {
        if (!this.data?.size)
            return true;
        if (!update.length)
            return false;
        if (this.appliedFilters.currentPlanId) {
            const currentPlanInState = this.data.get(this.appliedFilters.currentPlanId);
            if (!currentPlanInState)
                return true;
            return currentPlanInState.lastUpdated < update[0].lastUpdated;
        }
        return true;
    }
    // overrides Poller's handlePolling method because of the way plans polling is built
    async handlePolling() {
        if (this.appliedFilters.currentPlanId) {
            const currentPlan = await this.requestPlan(this.appliedFilters.currentPlanId).then((res) => serviceToClientPlan(res));
            const plans = [currentPlan];
            if (this.shouldEmitPlanUpdate(plans)) {
                this.updateSubscribers(plans);
                this.subject.next(plans);
            }
        }
        else {
            const mostRecentPlans = await this.fetchData();
            const newestTimestamp = getMostRecentPlanUpdate(mostRecentPlans);
            if (newestTimestamp) {
                this.mostRecentPlanUpdate = newestTimestamp;
            }
            if (this.shouldEmitPlanUpdate(mostRecentPlans)) {
                this.updateSubscribers(mostRecentPlans);
            }
        }
    }
    /**
     * Forces the poll to revert to its original state without stopping. It
     * will continue to update its subscribers. Filters are not reset.
     */
    restartPoll() {
        this.reset();
        this.mostRecentPlanUpdate = null;
        return this.poll();
    }
}
export default PlanPoller;

import { FC, PropsWithChildren, useCallback, useContext } from "react";
import {
  ViewId,
  UserType,
  OrganizationType,
  KnownOrganizationIds,
  OrganizationsWithStationsByException,
  User,
  StationGroupOrganizations
} from "@madhive/mad-sdk";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentUser, logoutUser } from "appReducers/authReducer";
import { ToasterContext } from "lib/context";
import { Routes } from "lib/constants/routes";
import { Intent } from "@blueprintjs/core";
import { madSDK } from "lib/sdk";
import { isLocalhost } from "lib/utils/env";
import { Navigate, NavigateFunction, useNavigate } from "react-router-dom";
import { isUserAbleToViewAgainstViewId } from "components/Auth/utils";
import DocumentTitler from "./DocumentTitler";

/**
 * This file abstracts the <AllowedOnly userType={UserType.ADMIN}>, <DevOnly>, <UserOnly> components.
 * The usage is the exact same.
 */

interface BaseProp {
  shouldRedirect?: boolean;
}
interface PropsInclude extends BaseProp {
  include: KnownOrganizationIds[];
  exclude?: never;
  orgType?: never;
  userType?: never;
}

interface PropsExclude extends BaseProp {
  exclude: KnownOrganizationIds[];
  include?: never;
  orgType?: never;
  userType?: never;
}
interface PropsUserType extends BaseProp {
  userType: UserType;
  exclude?: never;
  include?: never;
  orgType?: never;
}

interface PropsOrganizationType extends BaseProp {
  orgType: OrganizationType;
  exclude?: never;
  include?: never;
  userType?: never;
}

type PropRules =
  | PropsInclude
  | PropsExclude
  | PropsUserType
  | PropsOrganizationType;

export const AllowedOnly: FC<PropsWithChildren<PropRules>> = props => {
  const { children, include, exclude, shouldRedirect, userType, orgType } =
    props;

  const user = useSelector(selectCurrentUser);

  const navigate = useNavigate();

  const passthrough = (): boolean => {
    const { isDev, isAdmin, primaryOrganizationId } = user!;

    if (include && !include.includes(primaryOrganizationId)) {
      return false;
    }

    if (exclude && exclude.includes(primaryOrganizationId)) {
      return false;
    }

    if (
      orgType &&
      (orgType === OrganizationType.STATION_GROUP ||
        OrganizationsWithStationsByException.includes(primaryOrganizationId))
    ) {
      return StationGroupOrganizations.has(primaryOrganizationId);
    }

    if (userType && userType === UserType.DEV) {
      return isDev;
    }

    if (userType && userType === UserType.ADMIN) {
      return isAdmin;
    }

    if (userType && userType === UserType.ROOT) {
      return madSDK.isRootUser();
    }

    return true;
  };

  if (!passthrough()) {
    if (shouldRedirect) {
      if (isLocalhost) {
        navigate(Routes.LOGIN_LOCAL, { replace: true });
      } else {
        navigate(Routes.LOGIN, { replace: true });
      }
    }

    return null;
  }

  return <>{children}</>;
};

interface AuthorizedOnlyProps {
  viewId: ViewId | ViewId[];
  /** True by default */
  redirectIfNotAuthed?: boolean;
}

const attemptToRedirect = (
  user: User,
  onCriticalFailure: () => void,
  navigate: NavigateFunction
) => {
  if (!user.homepage) {
    // This means that we have no good place to redirect the user to. Logging them out is our best bet.
    onCriticalFailure();
  }

  navigate(`/${user.homepage}`, { replace: true });
};

export const AuthorizedOnly: FC<
  PropsWithChildren<AuthorizedOnlyProps>
> = props => {
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onLogout = useCallback(
    () => dispatch(logoutUser(navigate)),
    [dispatch]
  );
  const { addToast } = useContext(ToasterContext);

  const { redirectIfNotAuthed = true, viewId } = props;

  if (!user) {
    if (redirectIfNotAuthed) {
      return <Navigate to={Routes.LOGIN} replace />;
    }
    return null;
  }

  /**
   * For views that accept multiple view IDs, they'll pass in an array.
   */
  if (Array.isArray(viewId)) {
    const setOfAvailableViews = new Set(user.viewIds);

    if (
      viewId.some(acceptableViewId =>
        setOfAvailableViews.has(acceptableViewId)
      ) ||
      user.isDev
    ) {
      return <DocumentTitler {...props}>{props.children}</DocumentTitler>;
    }
    if (redirectIfNotAuthed) {
      attemptToRedirect(
        user,
        () => {
          addToast({
            intent: Intent.DANGER,
            message:
              "Not authorized and no available redirect. You've been logged out."
          });
          onLogout();
        },
        navigate
      );
    }
    return null;
  }

  if (isUserAbleToViewAgainstViewId(viewId, user)) {
    return <DocumentTitler {...props}>{props.children}</DocumentTitler>;
  }
  if (redirectIfNotAuthed) {
    attemptToRedirect(user, onLogout, navigate);
  }
  return null;
};

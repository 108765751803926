import { KeyboardEvent, FocusEvent, MouseEvent, FormEvent } from "react";
import { DateTime } from "luxon";
import { IconNames } from "../../Icons/types";
import { FormFieldProps, ReadonlyFormFieldProps } from "../../FormField/types";
import { UniqueInputDateProps } from "../Date/types";

export * from "./guards";
export * from "../Date/types";
export * from "../Date/Picker/types";
export * from "../Date/hooks/types";

export const InputTextTypes = ["text", "password", "email"] as const;

type InputTextType = (typeof InputTextTypes)[number];

export type InputType = InputTextType | "number" | "date";

export type DateRange = Array<Date | undefined>;
export type DateTimeRange = Array<DateTime | undefined>;

export type InputValueType =
  | (string | undefined) // text, password, email
  | (number | null) // number
  | (Date | undefined) // date single
  | DateRange; // date range

export type InputOnChangeHandler<
  T extends InputType,
  Value extends InputValueType
> = T extends "date"
  ? Value extends DateRange
    ? (value: DateRange) => void
    : (value: Date | undefined) => void
  : T extends "number"
  ? (value: number | null) => void
  : (value: string) => void;

/** Props unique to <Input type={"text" | "password" | "email"} /> */
type UniqueInputTextProps = {
  /** Text input type */
  type: "text" | "password" | "email";
  /**
   * Value of the input
   * @type string — valid string
   * @type undefined — coerced to empty string
   */
  value: string | undefined;
  /** @param value: string */
  onChange: (value: string) => void;
  /**
   * Icon slot at start of Input
   */
  prefix?: IconNames | JSX.Element;
  /**
   * Icon slot at end of Input
   */
  suffix?: IconNames | JSX.Element;
};

/** Props unique to <Input type="number" /> */
type UniqueInputNumberProps = {
  /** Number input type */
  type: "number";
  /**
   * Value of the input
   * @type number — valid number
   * @type null — empty input
   */
  value: number | null;
  /**
   * @param value: number — when valid number
   * @param value: null — when invalid number
   */
  onChange: (value: number | null) => void;
  /** What increments to use for increase/decrease. */
  step?: number;
  /** The min value for the Input. */
  min?: number;
  /** The max value for the Input. */
  max?: number;
  /** How many places past the decimal the input should allow */
  precision?: number;
  /**
   * Icon slot at start of Input
   */
  prefix?: IconNames | JSX.Element;
  /**
   * Icon slot at end of Input
   */
  suffix?: IconNames | JSX.Element;
};

type SharedInputProps<T extends InputType, Value extends InputValueType> = Pick<
  FormFieldProps,
  | "description"
  | "hideLabel"
  | "required"
  | "labelSuffix"
  | "labelSuffixAlign"
  | "offsetError"
> & {
  /**
   * What label to display on Input
   */
  label?: string;
  /*
   * When string || true, Input visually indicates there is an error
   * When string, Input displays error message
   * @default false
   */
  error?: string | boolean | undefined;
  /**
   * Placeholder to be shown on the Input
   */
  placeholder?: string;
  /**
   * Input should be disabled
   * @default false
   */
  disabled?: boolean;
  /**
   * Focus on the Input when mounted
   * @default false
   */
  focusOnMount?: boolean;
  /**
   * Whether the Input should fill the width of it's space
   * @default false
   */
  fill?: boolean;
  /**
   * The minimum width of the Input
   * @default "none"
   */
  minWidth?: string;
  /**
   * Should render plain text value instead of Input
   * @default false
   */
  readonly?: boolean;
  /**
   * Id for the Input(s)
   * @default `kit-${uuid()}`;
   * if date range
   * @default Array<`kit-${uuid()}`> - length: 2
   */
  id?: T extends "date"
    ? Value extends DateRange
      ? Array<string | undefined>
      : string | undefined
    : string | undefined;
  /**
   * Name to use on the Input field
   */
  name?: T extends "date"
    ? Value extends DateRange
      ? Array<string | undefined>
      : string | undefined
    : string | undefined;
  /**
   * Parser to use when rendering readonly value
   * @default (value) => value
   */
  readonlyParser?: ReadonlyFormFieldProps["parser"];
  /**
   * onKeyUp triggers on every keyup event triggered on Input
   */
  onKeyUp?: (
    event: KeyboardEvent<HTMLInputElement>,
    startEnd?: T extends "date"
      ? Value extends DateRange
        ? "start" | "end" | undefined
        : never
      : never
  ) => void;
  /**
   * onKeyDown triggers on every keydown event triggered on Input
   */
  onKeyDown?: (
    event: KeyboardEvent<HTMLInputElement>,
    startEnd?: T extends "date"
      ? Value extends DateRange
        ? "start" | "end" | undefined
        : never
      : never
  ) => void;
  /**
   * onBlur triggers on every blur event triggered on Input
   */
  onBlur?: (
    event: FocusEvent<HTMLInputElement>,
    startEnd?: T extends "date"
      ? Value extends DateRange
        ? "start" | "end" | undefined
        : never
      : never
  ) => void;
  /**
   * onFocus triggers on every focus event triggered on Input
   */
  onFocus?: (
    event: FocusEvent<HTMLInputElement>,
    startEnd?: T extends "date"
      ? Value extends DateRange
        ? "start" | "end" | undefined
        : never
      : never
  ) => void;
  /**
   * onClick triggers on every click event triggered on Input
   */
  onClick?: (
    event: MouseEvent<HTMLElement>,
    startEnd?: T extends "date"
      ? Value extends DateRange
        ? "start" | "end" | undefined
        : never
      : never
  ) => void;
  /**
   * onInput triggers on every input event triggered on Input
   */
  onInput?: (
    event: FormEvent<HTMLInputElement>,
    startEnd?: T extends "date"
      ? Value extends DateRange
        ? "start" | "end" | undefined
        : never
      : never
  ) => void;
};

export type InputTextProps = UniqueInputTextProps &
  SharedInputProps<"text" | "password" | "email", string | undefined>;

export type InputNumberProps = UniqueInputNumberProps &
  SharedInputProps<"number", number | null>;

export type InputDateRangeProps = UniqueInputDateProps<DateRange> &
  SharedInputProps<"date", DateRange>;

export type InputDateSingleProps = UniqueInputDateProps<Date | undefined> &
  SharedInputProps<"date", Date | undefined>;

export type InputDateProps<
  Value extends (Date | undefined) | DateRange = undefined
> = Value extends DateRange ? InputDateRangeProps : InputDateSingleProps;

export type InputProps<T extends InputType, Value extends InputValueType> = {
  type: T;
  value: Value;
  onChange: InputOnChangeHandler<T, Value>;
} & (T extends "date"
  ? Value extends Date | DateRange | undefined
    ? InputDateProps<Value>
    : never
  : T extends "text" | "password" | "email"
  ? InputTextProps
  : T extends "number"
  ? InputNumberProps
  : never);

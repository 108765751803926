import { ReactElement, ComponentType } from "react";

export function isElementOfType<P>(
  element: any,
  componentType: ComponentType<P>
): element is ReactElement<P> {
  return (
    element != null &&
    element.type != null &&
    element.type.displayName != null &&
    element.type.displayName === componentType.displayName
  );
}

import { useMemo } from "react";
import { useSelector } from "react-redux";
import { featureFlags } from "appReducers/featureFlagsReducer";
import { madSDK } from "lib/sdk";
import { RemoteConfigProperties } from "@madhive/mad-sdk";

export { RemoteConfigProperties };
/**
 * Helper Hook to read whether a feature flag is enabled or not.
 * This hook is meant to be used to show or hide elements on the UI, enable or disable features or routes.
 * @param {RemoteConfigProperties} key The key of the feature flag in firestore, should always reference enum
 * @returns {boolean} True if the feature flag associated with the key is enabled, false if not
 */
export const useFeatureFlag = (
  key: RemoteConfigProperties | RemoteConfigProperties[]
) => {
  const allFlags = useSelector(featureFlags);

  if (!Array.isArray(key)) {
    if (madSDK.featureFlags.useSDKFeatureFlags)
      return madSDK.featureFlags.isFlagEnabled(key);

    return allFlags[key] || false;
  }

  if (madSDK.featureFlags.useSDKFeatureFlags) {
    return key.every(flag => madSDK.featureFlags.isFlagEnabled(flag));
  }

  return key.every(flag => allFlags[flag] || false);
};

export const useFeatureFlags = (keys: RemoteConfigProperties[]) => {
  const allFlags = useSelector(featureFlags);

  const flagsMap = useMemo(() => {
    if (madSDK.featureFlags.useSDKFeatureFlags) {
      return Object.fromEntries(
        keys.map(key => [key, madSDK.featureFlags.isFlagEnabled(key)])
      );
    }

    return Object.fromEntries(keys.map(key => [key, allFlags[key] || false]));
  }, [allFlags, keys]) as Record<RemoteConfigProperties, boolean>;

  return flagsMap;
};

import { css } from "@emotion/react";

export const styles = {
  display: css`
    position: relative;
    flex-shrink: 0;
    display: inline-flex;
  `,
  extraSmall: css`
    width: 0.75rem;
    height: 0.75rem;
  `,
  small: css`
    width: 1rem;
    height: 1rem;
  `,
  medium: css`
    width: 1.5rem;
    height: 1.5rem;
  `,
  large: css`
    width: 2rem;
    height: 2rem;
  `,
  extraLarge: css`
    width: 2.5rem;
    height: 2.5rem;
  `
};

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const VideoSlate: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-video-slate"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0176 2.25C18.0061 2.24973 17.9946 2.24974 17.9831 2.25H13.0174C13.0058 2.24973 12.9943 2.24974 12.9829 2.25H8.01747C8.00596 2.24973 7.99446 2.24974 7.98299 2.25H4.00008C3.27062 2.2498 2.57098 2.53949 2.05517 3.0553C1.53937 3.5711 1.24968 4.27075 1.24988 5.00021L1.24988 8L1.24988 18.9998C1.24968 19.7293 1.53937 20.4289 2.05517 20.9447C2.57098 21.4605 3.27062 21.7502 4.00008 21.75H19.9992C20.7286 21.7502 21.4288 21.4605 21.9446 20.9447C22.4604 20.4289 22.7501 19.7293 22.7499 18.9998V5.00021C22.7501 4.27075 22.4604 3.5711 21.9446 3.0553C21.4288 2.53949 20.7291 2.2498 19.9997 2.25H18.0176ZM21.2499 7.25V4.99979C21.25 4.6683 21.1183 4.35036 20.8839 4.11596C20.6495 3.88156 20.3316 3.74991 20.0001 3.75H18.5079L17.1079 7.25H21.2499ZM16.0171 8.75H21.2499V19.0002C21.25 19.3317 21.1183 19.6496 20.8839 19.884C20.6495 20.1184 20.3316 20.2501 20.0001 20.25L19.9999 21C19.9999 20.25 20.0001 20.25 20.0001 20.25H3.99967C3.66818 20.2501 3.35023 20.1184 3.11583 19.884C2.88143 19.6496 2.74979 19.3317 2.74988 19.0002V8.75H5.98251C5.99404 8.75027 6.00555 8.75027 6.01703 8.75H10.9824C10.9939 8.75027 11.0054 8.75027 11.0169 8.75H15.9826C15.9942 8.75027 16.0057 8.75027 16.0171 8.75ZM15.4923 7.25L16.8923 3.75H13.5077L12.1077 7.25H15.4923ZM10.4921 7.25L11.8921 3.75H8.50777L7.10777 7.25H10.4921ZM5.49223 7.25L6.89222 3.75H3.99967C3.66818 3.74991 3.35023 3.88155 3.11583 4.11596C2.88143 4.35036 2.74979 4.6683 2.74988 4.99979V7.25H5.49223Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8724 12.8242V16.1759L13.7724 14.5L10.8724 12.8242ZM10.0729 11.4383C10.5063 11.1883 11.0401 11.1885 11.4732 11.4389L14.6729 13.2879C15.1058 13.538 15.3724 14 15.3724 14.5C15.3724 15 15.1058 15.462 14.6729 15.7121L11.4732 17.5611C11.0401 17.8115 10.5063 17.8117 10.0729 17.5617C9.63958 17.3117 9.37254 16.8495 9.37244 16.3492V12.6508C9.37255 12.1505 9.63958 11.6883 10.0729 11.4383Z"
      />
    </svg>
  );
};

export default VideoSlate;

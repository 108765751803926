import { FC, KeyboardEvent, useEffect, useMemo, useRef } from "react";
import { css } from "@emotion/react";
import { DoNotCare } from "../types";
import { getIconFromStringOrElement } from "../Icons/utils";
import { styles } from "./styles";
import { ChipProps } from "./types";
import { useSizingProp } from "../../hooks/props/useSizingProp";
import Icon from "../Icon";

/**
 * `Chip` components are small boxes containing text. Optionally, they can contain an icon, and be interactive.
 */
const Chip: FC<ChipProps> = ({
  children,
  isRemovable = false,
  readonly,
  prefix,
  disabled = false,
  minWidth: incomingMinWidth,
  maxWidth: incomingMaxWidth,
  focus,
  intent = "info",
  lookupIntent,
  onRemove = () => {},
  onBlur,
  onFocus,
  onKeyUp,
  onKeyDown,
  onClick
}) => {
  const isInteractive = !readonly && !disabled;

  const minWidth = useSizingProp(incomingMinWidth, "var(--chip-min-width)");
  const maxWidth = useSizingProp(
    incomingMaxWidth,
    "var(--chip-max-width, fit-content)"
  );
  const resolvedIntent = lookupIntent || intent;

  const PrefixIcon = useMemo(
    () =>
      getIconFromStringOrElement(prefix, {
        size: "extra-small",
        ...(resolvedIntent === "error"
          ? { fill: "var(--color-danger)", stroke: "var(--color-danger)" }
          : {})
      }),
    [prefix]
  );

  const chipRef = useRef<HTMLElement>(null);

  const handleOnKeyUp = (e: KeyboardEvent<HTMLElement>) => {
    if (disabled || readonly) {
      return;
    }
    onKeyUp?.(e);
    if (chipRef.current && ["Enter", " "].includes(e.key)) {
      chipRef.current?.click();
    }
  };

  const noop = (e: DoNotCare) => {
    e?.stopPropagation();
    e?.preventDefault();
  };

  useEffect(() => {
    if (
      !chipRef.current ||
      disabled ||
      !focus ||
      document.activeElement === chipRef.current
    ) {
      return;
    }
    chipRef.current?.focus();
  }, [focus]);

  return (
    <span
      className="kit-Chip"
      ref={chipRef}
      role="button"
      aria-disabled={disabled}
      tabIndex={isInteractive ? 0 : -1}
      onKeyUp={isInteractive ? handleOnKeyUp : noop}
      onKeyDown={isInteractive ? onKeyDown : noop}
      onClick={isInteractive ? onClick : noop}
      onFocus={isInteractive ? onFocus : noop}
      onBlur={isInteractive ? onBlur : noop}
      css={[
        styles.wrapper,
        disabled && styles.isDisabled,
        isInteractive && styles.isInteractive,
        resolvedIntent === "error" && styles.intent.error,
        css`
          min-width: ${minWidth};
          max-width: ${maxWidth};
        `
      ]}
    >
      {!!prefix && PrefixIcon}
      <span css={styles.label}>{children}</span>
      {isRemovable && !readonly && !disabled && (
        <button
          css={styles.removeButton}
          tabIndex={-1}
          aria-label="remove"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onRemove();
          }}
          onFocus={noop}
          onBlur={noop}
          disabled={disabled}
          type="button"
        >
          <Icon name="X" size="extra-small" />
        </button>
      )}
    </span>
  );
};

export default Chip;
export * from "./types";

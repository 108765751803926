/*
 * GENERATED BY `/services/talon/codegen.js` FOR `/smithers/src/features/OOH/Planning`
 */
/* eslint-disable */
import { gql } from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** Inventory Export Cell Content */
  InventoryExportCell: any;
};

export type Query = {
  __typename?: 'Query';
  scenario?: Maybe<Scenario>;
  findFirstScenario?: Maybe<Scenario>;
  scenarios: Array<Scenario>;
  aggregateScenario: AggregateScenario;
  inventoryVenues: Array<InventoryVenue>;
  aggregatePlanInventory: AggregatePlanInventory;
  /** View list of all the inventory points of interest, optionally filtered by zip code */
  poiCategories: Array<Scalars['String']>;
  /** View points of interest for a selected geographic region, optionally filtered by categories */
  poiLocations: Array<PoiLocation>;
  plan?: Maybe<Plan>;
  plans: Array<Plan>;
  segments: Array<Segment>;
};


export type QueryScenarioArgs = {
  where: ScenarioWhereUniqueInput;
};


export type QueryFindFirstScenarioArgs = {
  where?: Maybe<ScenarioWhereInput>;
  orderBy?: Maybe<Array<ScenarioOrderByInput>>;
  cursor?: Maybe<ScenarioWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ScenarioScalarFieldEnum>>;
};


export type QueryScenariosArgs = {
  where?: Maybe<ScenarioWhereInput>;
  orderBy?: Maybe<Array<ScenarioOrderByInput>>;
  cursor?: Maybe<ScenarioWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ScenarioScalarFieldEnum>>;
};


export type QueryAggregateScenarioArgs = {
  where?: Maybe<ScenarioWhereInput>;
  orderBy?: Maybe<Array<ScenarioOrderByInput>>;
  cursor?: Maybe<ScenarioWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryInventoryVenuesArgs = {
  where?: Maybe<InventoryVenueWhereInput>;
  orderBy?: Maybe<Array<InventoryVenueOrderByInput>>;
  cursor?: Maybe<InventoryVenueWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<InventoryVenueScalarFieldEnum>>;
};


export type QueryAggregatePlanInventoryArgs = {
  where?: Maybe<PlanInventoryWhereInput>;
  orderBy?: Maybe<Array<PlanInventoryOrderByInput>>;
  cursor?: Maybe<PlanInventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryPoiLocationsArgs = {
  categories: Array<Scalars['String']>;
  west: Scalars['Float'];
  east: Scalars['Float'];
  south: Scalars['Float'];
  north: Scalars['Float'];
};


export type QueryPlanArgs = {
  where: PlanWhereUniqueInput;
};


export type QueryPlansArgs = {
  where?: Maybe<PlanWhereInput>;
  orderBy?: Maybe<Array<PlanOrderByInput>>;
  cursor?: Maybe<PlanWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PlanScalarFieldEnum>>;
};


export type QuerySegmentsArgs = {
  where?: Maybe<SegmentWhereInput>;
  orderBy?: Maybe<Array<SegmentOrderByInput>>;
  cursor?: Maybe<SegmentWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<SegmentScalarFieldEnum>>;
};

export type Scenario = {
  __typename?: 'Scenario';
  id: Scalars['Int'];
  title: Scalars['String'];
  status: ScenarioStatus;
  planId: Scalars['Int'];
  maxCPM?: Maybe<Scalars['Float']>;
  publishers: Array<Scalars['String']>;
  poiCategories: Array<Scalars['String']>;
  poiRadius?: Maybe<Scalars['Float']>;
  bundles: Array<Scalars['String']>;
  sizes: Array<Scalars['String']>;
  daypartId: Scalars['Int'];
  venuesIsExclude: Scalars['Boolean'];
  publishersIsExclude: Scalars['Boolean'];
  poiIsExclude: Scalars['Boolean'];
  bundlesIsExclude: Scalars['Boolean'];
  sizesIsExclude: Scalars['Boolean'];
  daypartsIsExclude: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  plan: Plan;
  venues: Array<InventoryVenue>;
  daypart: ScenarioDaypart;
};


export type ScenarioVenuesArgs = {
  where?: Maybe<InventoryVenueWhereInput>;
  orderBy?: Maybe<Array<InventoryVenueOrderByInput>>;
  cursor?: Maybe<InventoryVenueWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<InventoryVenueScalarFieldEnum>>;
};

export enum ScenarioStatus {
  Ready = 'READY',
  Archived = 'ARCHIVED'
}


export type Plan = {
  __typename?: 'Plan';
  id: Scalars['Int'];
  ownerEmail?: Maybe<Scalars['String']>;
  orgId: Scalars['String'];
  title: Scalars['String'];
  status: PlanStatus;
  segmentId: Scalars['String'];
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  geoType: GeoType;
  geoEntities: Array<Scalars['String']>;
  geoBounds: Scalars['JSON'];
  createdAt: Scalars['DateTime'];
  archivedAt?: Maybe<Scalars['DateTime']>;
  org: Organization;
  segment: Segment;
  scenarios: Array<Scenario>;
  inventory: Array<PlanInventory>;
  jobs: Array<BigQueryJob>;
  footraffic: Array<FootTraffic>;
  inventoryLocations: Array<InventoryLocation>;
  footrafficLocations: Array<FootTrafficPoint>;
  inventoryExport: InventoryExport;
  metadata: PlanMetadata;
  inventoryDaypartMetrics: Array<InventoryDaypartAggregate>;
  /** Summary metrics for a specific plan */
  summaryMetrics: PlanSummaryMetrics;
  /** Metrics for a plan inventory grouped by a selected dimension */
  inventoryMetrics: Array<PlanInventoryMetrics>;
};


export type PlanScenariosArgs = {
  where?: Maybe<ScenarioWhereInput>;
  orderBy?: Maybe<Array<ScenarioOrderByInput>>;
  cursor?: Maybe<ScenarioWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<ScenarioScalarFieldEnum>>;
};


export type PlanInventoryArgs = {
  where?: Maybe<PlanInventoryWhereInput>;
  orderBy?: Maybe<Array<PlanInventoryOrderByInput>>;
  cursor?: Maybe<PlanInventoryWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<PlanInventoryScalarFieldEnum>>;
};


export type PlanJobsArgs = {
  where?: Maybe<BigQueryJobWhereInput>;
  orderBy?: Maybe<Array<BigQueryJobOrderByInput>>;
  cursor?: Maybe<BigQueryJobWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<BigQueryJobScalarFieldEnum>>;
};


export type PlanFootrafficArgs = {
  where?: Maybe<FootTrafficWhereInput>;
  orderBy?: Maybe<Array<FootTrafficOrderByInput>>;
  cursor?: Maybe<FootTrafficWhereUniqueInput>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  distinct?: Maybe<Array<FootTrafficScalarFieldEnum>>;
};


export type PlanInventoryLocationsArgs = {
  north: Scalars['Float'];
  south: Scalars['Float'];
  east: Scalars['Float'];
  west: Scalars['Float'];
};


export type PlanFootrafficLocationsArgs = {
  north: Scalars['Float'];
  south: Scalars['Float'];
  east: Scalars['Float'];
  west: Scalars['Float'];
};


export type PlanInventoryDaypartMetricsArgs = {
  metric: DaypartMetrics;
};


export type PlanInventoryMetricsArgs = {
  dimension?: Maybe<InventoryDimension>;
  sortBy?: Maybe<InventoryMetrics>;
  sortDirection?: Maybe<SortOrder>;
};

export enum PlanStatus {
  Processing = 'PROCESSING',
  Ready = 'READY',
  Archived = 'ARCHIVED',
  Error = 'ERROR'
}

export enum GeoType {
  Country = 'country',
  State = 'state',
  District = 'district',
  Dma = 'dma',
  ZipCode = 'zipCode'
}


export type Organization = {
  __typename?: 'Organization';
  id: Scalars['String'];
};

export type Segment = {
  __typename?: 'Segment';
  id: Scalars['String'];
  name: Scalars['String'];
  category: Scalars['String'];
  status: SegmentStatus;
  orgId: Scalars['String'];
};

export enum SegmentStatus {
  Ready = 'READY',
  Archived = 'ARCHIVED',
  Draft = 'DRAFT'
}

export type ScenarioWhereInput = {
  AND?: Maybe<Array<ScenarioWhereInput>>;
  OR?: Maybe<Array<ScenarioWhereInput>>;
  NOT?: Maybe<Array<ScenarioWhereInput>>;
  id?: Maybe<IntFilter>;
  title?: Maybe<StringFilter>;
  status?: Maybe<EnumScenarioStatusFilter>;
  plan?: Maybe<PlanRelationFilter>;
  planId?: Maybe<IntFilter>;
  maxCPM?: Maybe<FloatNullableFilter>;
  venues?: Maybe<InventoryVenueListRelationFilter>;
  publishers?: Maybe<StringNullableListFilter>;
  poiCategories?: Maybe<StringNullableListFilter>;
  poiRadius?: Maybe<FloatNullableFilter>;
  bundles?: Maybe<StringNullableListFilter>;
  sizes?: Maybe<StringNullableListFilter>;
  daypart?: Maybe<ScenarioDaypartRelationFilter>;
  daypartId?: Maybe<IntFilter>;
  venuesIsExclude?: Maybe<BoolFilter>;
  publishersIsExclude?: Maybe<BoolFilter>;
  poiIsExclude?: Maybe<BoolFilter>;
  bundlesIsExclude?: Maybe<BoolFilter>;
  sizesIsExclude?: Maybe<BoolFilter>;
  daypartsIsExclude?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type EnumScenarioStatusFilter = {
  equals?: Maybe<ScenarioStatus>;
  in?: Maybe<Array<ScenarioStatus>>;
  notIn?: Maybe<Array<ScenarioStatus>>;
  not?: Maybe<NestedEnumScenarioStatusFilter>;
};

export type NestedEnumScenarioStatusFilter = {
  equals?: Maybe<ScenarioStatus>;
  in?: Maybe<Array<ScenarioStatus>>;
  notIn?: Maybe<Array<ScenarioStatus>>;
  not?: Maybe<NestedEnumScenarioStatusFilter>;
};

export type PlanRelationFilter = {
  is?: Maybe<PlanWhereInput>;
  isNot?: Maybe<PlanWhereInput>;
};

export type PlanWhereInput = {
  AND?: Maybe<Array<PlanWhereInput>>;
  OR?: Maybe<Array<PlanWhereInput>>;
  NOT?: Maybe<Array<PlanWhereInput>>;
  id?: Maybe<IntFilter>;
  ownerEmail?: Maybe<StringNullableFilter>;
  org?: Maybe<OrganizationRelationFilter>;
  orgId?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  status?: Maybe<EnumPlanStatusFilter>;
  segment?: Maybe<SegmentRelationFilter>;
  segmentId?: Maybe<StringFilter>;
  startDate?: Maybe<DateTimeFilter>;
  endDate?: Maybe<DateTimeFilter>;
  geoType?: Maybe<EnumGeoTypeFilter>;
  geoEntities?: Maybe<StringNullableListFilter>;
  geoBounds?: Maybe<JsonFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  archivedAt?: Maybe<DateTimeNullableFilter>;
  scenarios?: Maybe<ScenarioListRelationFilter>;
  inventory?: Maybe<PlanInventoryListRelationFilter>;
  jobs?: Maybe<BigQueryJobListRelationFilter>;
  footraffic?: Maybe<FootTrafficListRelationFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type OrganizationRelationFilter = {
  is?: Maybe<OrganizationWhereInput>;
  isNot?: Maybe<OrganizationWhereInput>;
};

export type OrganizationWhereInput = {
  AND?: Maybe<Array<OrganizationWhereInput>>;
  OR?: Maybe<Array<OrganizationWhereInput>>;
  NOT?: Maybe<Array<OrganizationWhereInput>>;
  id?: Maybe<StringFilter>;
  plans?: Maybe<PlanListRelationFilter>;
  users?: Maybe<UserListRelationFilter>;
  segments?: Maybe<SegmentListRelationFilter>;
};

export type PlanListRelationFilter = {
  every?: Maybe<PlanWhereInput>;
  some?: Maybe<PlanWhereInput>;
  none?: Maybe<PlanWhereInput>;
};

export type UserListRelationFilter = {
  every?: Maybe<UserWhereInput>;
  some?: Maybe<UserWhereInput>;
  none?: Maybe<UserWhereInput>;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  id?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  primaryOrgId?: Maybe<StringFilter>;
  primaryOrg?: Maybe<OrganizationRelationFilter>;
};

export type SegmentListRelationFilter = {
  every?: Maybe<SegmentWhereInput>;
  some?: Maybe<SegmentWhereInput>;
  none?: Maybe<SegmentWhereInput>;
};

export type SegmentWhereInput = {
  AND?: Maybe<Array<SegmentWhereInput>>;
  OR?: Maybe<Array<SegmentWhereInput>>;
  NOT?: Maybe<Array<SegmentWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  category?: Maybe<StringFilter>;
  status?: Maybe<EnumSegmentStatusFilter>;
  orgId?: Maybe<StringFilter>;
  org?: Maybe<OrganizationRelationFilter>;
  plans?: Maybe<PlanListRelationFilter>;
};

export type EnumSegmentStatusFilter = {
  equals?: Maybe<SegmentStatus>;
  in?: Maybe<Array<SegmentStatus>>;
  notIn?: Maybe<Array<SegmentStatus>>;
  not?: Maybe<NestedEnumSegmentStatusFilter>;
};

export type NestedEnumSegmentStatusFilter = {
  equals?: Maybe<SegmentStatus>;
  in?: Maybe<Array<SegmentStatus>>;
  notIn?: Maybe<Array<SegmentStatus>>;
  not?: Maybe<NestedEnumSegmentStatusFilter>;
};

export type EnumPlanStatusFilter = {
  equals?: Maybe<PlanStatus>;
  in?: Maybe<Array<PlanStatus>>;
  notIn?: Maybe<Array<PlanStatus>>;
  not?: Maybe<NestedEnumPlanStatusFilter>;
};

export type NestedEnumPlanStatusFilter = {
  equals?: Maybe<PlanStatus>;
  in?: Maybe<Array<PlanStatus>>;
  notIn?: Maybe<Array<PlanStatus>>;
  not?: Maybe<NestedEnumPlanStatusFilter>;
};

export type SegmentRelationFilter = {
  is?: Maybe<SegmentWhereInput>;
  isNot?: Maybe<SegmentWhereInput>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type EnumGeoTypeFilter = {
  equals?: Maybe<GeoType>;
  in?: Maybe<Array<GeoType>>;
  notIn?: Maybe<Array<GeoType>>;
  not?: Maybe<NestedEnumGeoTypeFilter>;
};

export type NestedEnumGeoTypeFilter = {
  equals?: Maybe<GeoType>;
  in?: Maybe<Array<GeoType>>;
  notIn?: Maybe<Array<GeoType>>;
  not?: Maybe<NestedEnumGeoTypeFilter>;
};

export type StringNullableListFilter = {
  equals?: Maybe<Array<Scalars['String']>>;
  has?: Maybe<Scalars['String']>;
  hasEvery?: Maybe<Array<Scalars['String']>>;
  hasSome?: Maybe<Array<Scalars['String']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type JsonFilter = {
  equals?: Maybe<Scalars['JSON']>;
  not?: Maybe<Scalars['JSON']>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type ScenarioListRelationFilter = {
  every?: Maybe<ScenarioWhereInput>;
  some?: Maybe<ScenarioWhereInput>;
  none?: Maybe<ScenarioWhereInput>;
};

export type PlanInventoryListRelationFilter = {
  every?: Maybe<PlanInventoryWhereInput>;
  some?: Maybe<PlanInventoryWhereInput>;
  none?: Maybe<PlanInventoryWhereInput>;
};

export type PlanInventoryWhereInput = {
  AND?: Maybe<Array<PlanInventoryWhereInput>>;
  OR?: Maybe<Array<PlanInventoryWhereInput>>;
  NOT?: Maybe<Array<PlanInventoryWhereInput>>;
  id?: Maybe<IntFilter>;
  adUnitId?: Maybe<StringFilter>;
  lat?: Maybe<FloatFilter>;
  lon?: Maybe<FloatFilter>;
  state?: Maybe<StringFilter>;
  city?: Maybe<StringFilter>;
  zipCode?: Maybe<StringFilter>;
  plan?: Maybe<PlanRelationFilter>;
  planId?: Maybe<IntFilter>;
  publisher?: Maybe<StringFilter>;
  bundle?: Maybe<StringNullableFilter>;
  impressionsPerHour?: Maybe<IntNullableFilter>;
  orientation?: Maybe<EnumAdUnitOrientationNullableListFilter>;
  venue?: Maybe<InventoryVenueRelationFilter>;
  venueId?: Maybe<StringNullableFilter>;
  bigQueryJob?: Maybe<BigQueryJobRelationFilter>;
  bigQueryJobId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type EnumAdUnitOrientationNullableListFilter = {
  equals?: Maybe<Array<AdUnitOrientation>>;
  has?: Maybe<AdUnitOrientation>;
  hasEvery?: Maybe<Array<AdUnitOrientation>>;
  hasSome?: Maybe<Array<AdUnitOrientation>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export enum AdUnitOrientation {
  Horizontal = 'HORIZONTAL',
  Vertical = 'VERTICAL',
  Square = 'SQUARE'
}

export type InventoryVenueRelationFilter = {
  is?: Maybe<InventoryVenueWhereInput>;
  isNot?: Maybe<InventoryVenueWhereInput>;
};

export type InventoryVenueWhereInput = {
  AND?: Maybe<Array<InventoryVenueWhereInput>>;
  OR?: Maybe<Array<InventoryVenueWhereInput>>;
  NOT?: Maybe<Array<InventoryVenueWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  doohId?: Maybe<StringNullableFilter>;
  stringList?: Maybe<StringNullableFilter>;
  PlanInventory?: Maybe<PlanInventoryListRelationFilter>;
  Scenario?: Maybe<ScenarioRelationFilter>;
  scenarioId?: Maybe<IntNullableFilter>;
};

export type ScenarioRelationFilter = {
  is?: Maybe<ScenarioWhereInput>;
  isNot?: Maybe<ScenarioWhereInput>;
};

export type BigQueryJobRelationFilter = {
  is?: Maybe<BigQueryJobWhereInput>;
  isNot?: Maybe<BigQueryJobWhereInput>;
};

export type BigQueryJobWhereInput = {
  AND?: Maybe<Array<BigQueryJobWhereInput>>;
  OR?: Maybe<Array<BigQueryJobWhereInput>>;
  NOT?: Maybe<Array<BigQueryJobWhereInput>>;
  id?: Maybe<StringFilter>;
  status?: Maybe<EnumBigQueryJobStateFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  startedAt?: Maybe<DateTimeNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  processedAt?: Maybe<DateTimeNullableFilter>;
  processedBy?: Maybe<EnumJobProcessorNullableFilter>;
  totalBytesProcessed?: Maybe<StringNullableFilter>;
  totalBytesBilled?: Maybe<StringNullableFilter>;
  totalMiliseconds?: Maybe<StringNullableFilter>;
  errorMessage?: Maybe<JsonNullableFilter>;
  jobMetadata?: Maybe<JsonNullableFilter>;
  plan?: Maybe<PlanRelationFilter>;
  planId?: Maybe<IntFilter>;
  PlanInventory?: Maybe<PlanInventoryListRelationFilter>;
  jobType?: Maybe<EnumBigQueryJobTypeFilter>;
  InventoryDaypartMetric?: Maybe<InventoryDaypartMetricListRelationFilter>;
};

export type EnumBigQueryJobStateFilter = {
  equals?: Maybe<BigQueryJobState>;
  in?: Maybe<Array<BigQueryJobState>>;
  notIn?: Maybe<Array<BigQueryJobState>>;
  not?: Maybe<NestedEnumBigQueryJobStateFilter>;
};

export enum BigQueryJobState {
  Done = 'DONE',
  Pending = 'PENDING',
  Error = 'ERROR'
}

export type NestedEnumBigQueryJobStateFilter = {
  equals?: Maybe<BigQueryJobState>;
  in?: Maybe<Array<BigQueryJobState>>;
  notIn?: Maybe<Array<BigQueryJobState>>;
  not?: Maybe<NestedEnumBigQueryJobStateFilter>;
};

export type EnumJobProcessorNullableFilter = {
  equals?: Maybe<JobProcessor>;
  in?: Maybe<Array<JobProcessor>>;
  notIn?: Maybe<Array<JobProcessor>>;
  not?: Maybe<NestedEnumJobProcessorNullableFilter>;
};

export enum JobProcessor {
  PushNotification = 'PUSH_NOTIFICATION',
  PoolingCron = 'POOLING_CRON'
}

export type NestedEnumJobProcessorNullableFilter = {
  equals?: Maybe<JobProcessor>;
  in?: Maybe<Array<JobProcessor>>;
  notIn?: Maybe<Array<JobProcessor>>;
  not?: Maybe<NestedEnumJobProcessorNullableFilter>;
};

export type JsonNullableFilter = {
  equals?: Maybe<Scalars['JSON']>;
  not?: Maybe<Scalars['JSON']>;
};

export type EnumBigQueryJobTypeFilter = {
  equals?: Maybe<BigQueryJobType>;
  in?: Maybe<Array<BigQueryJobType>>;
  notIn?: Maybe<Array<BigQueryJobType>>;
  not?: Maybe<NestedEnumBigQueryJobTypeFilter>;
};

export enum BigQueryJobType {
  InventoryRetrieval = 'INVENTORY_RETRIEVAL',
  InventoryMetricsCalculation = 'INVENTORY_METRICS_CALCULATION',
  FoottrafficCalculation = 'FOOTTRAFFIC_CALCULATION'
}

export type NestedEnumBigQueryJobTypeFilter = {
  equals?: Maybe<BigQueryJobType>;
  in?: Maybe<Array<BigQueryJobType>>;
  notIn?: Maybe<Array<BigQueryJobType>>;
  not?: Maybe<NestedEnumBigQueryJobTypeFilter>;
};

export type InventoryDaypartMetricListRelationFilter = {
  every?: Maybe<InventoryDaypartMetricWhereInput>;
  some?: Maybe<InventoryDaypartMetricWhereInput>;
  none?: Maybe<InventoryDaypartMetricWhereInput>;
};

export type InventoryDaypartMetricWhereInput = {
  AND?: Maybe<Array<InventoryDaypartMetricWhereInput>>;
  OR?: Maybe<Array<InventoryDaypartMetricWhereInput>>;
  NOT?: Maybe<Array<InventoryDaypartMetricWhereInput>>;
  adUnitId?: Maybe<StringFilter>;
  date?: Maybe<DateTimeFilter>;
  impressionsPerHour?: Maybe<IntNullableListFilter>;
  totalImpressions?: Maybe<IntFilter>;
  bigQueryJob?: Maybe<BigQueryJobRelationFilter>;
  bigQueryJobId?: Maybe<StringFilter>;
  segments?: Maybe<InventorySegmentDaypartMetricListRelationFilter>;
};

export type IntNullableListFilter = {
  equals?: Maybe<Array<Scalars['Int']>>;
  has?: Maybe<Scalars['Int']>;
  hasEvery?: Maybe<Array<Scalars['Int']>>;
  hasSome?: Maybe<Array<Scalars['Int']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type InventorySegmentDaypartMetricListRelationFilter = {
  every?: Maybe<InventorySegmentDaypartMetricWhereInput>;
  some?: Maybe<InventorySegmentDaypartMetricWhereInput>;
  none?: Maybe<InventorySegmentDaypartMetricWhereInput>;
};

export type InventorySegmentDaypartMetricWhereInput = {
  AND?: Maybe<Array<InventorySegmentDaypartMetricWhereInput>>;
  OR?: Maybe<Array<InventorySegmentDaypartMetricWhereInput>>;
  NOT?: Maybe<Array<InventorySegmentDaypartMetricWhereInput>>;
  adUnitId?: Maybe<StringFilter>;
  date?: Maybe<DateTimeFilter>;
  impressionsPerHour?: Maybe<IntNullableListFilter>;
  totalImpressions?: Maybe<IntFilter>;
  daypartImpression?: Maybe<InventoryDaypartMetricRelationFilter>;
  segmentId?: Maybe<StringFilter>;
};

export type InventoryDaypartMetricRelationFilter = {
  is?: Maybe<InventoryDaypartMetricWhereInput>;
  isNot?: Maybe<InventoryDaypartMetricWhereInput>;
};

export type BigQueryJobListRelationFilter = {
  every?: Maybe<BigQueryJobWhereInput>;
  some?: Maybe<BigQueryJobWhereInput>;
  none?: Maybe<BigQueryJobWhereInput>;
};

export type FootTrafficListRelationFilter = {
  every?: Maybe<FootTrafficWhereInput>;
  some?: Maybe<FootTrafficWhereInput>;
  none?: Maybe<FootTrafficWhereInput>;
};

export type FootTrafficWhereInput = {
  AND?: Maybe<Array<FootTrafficWhereInput>>;
  OR?: Maybe<Array<FootTrafficWhereInput>>;
  NOT?: Maybe<Array<FootTrafficWhereInput>>;
  id?: Maybe<IntFilter>;
  planId?: Maybe<IntFilter>;
  plan?: Maybe<PlanRelationFilter>;
  lat?: Maybe<FloatFilter>;
  lon?: Maybe<FloatFilter>;
  count?: Maybe<IntFilter>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type InventoryVenueListRelationFilter = {
  every?: Maybe<InventoryVenueWhereInput>;
  some?: Maybe<InventoryVenueWhereInput>;
  none?: Maybe<InventoryVenueWhereInput>;
};

export type ScenarioDaypartRelationFilter = {
  is?: Maybe<ScenarioDaypartWhereInput>;
  isNot?: Maybe<ScenarioDaypartWhereInput>;
};

export type ScenarioDaypartWhereInput = {
  AND?: Maybe<Array<ScenarioDaypartWhereInput>>;
  OR?: Maybe<Array<ScenarioDaypartWhereInput>>;
  NOT?: Maybe<Array<ScenarioDaypartWhereInput>>;
  id?: Maybe<IntFilter>;
  scenario?: Maybe<ScenarioRelationFilter>;
  sunday?: Maybe<IntNullableListFilter>;
  monday?: Maybe<IntNullableListFilter>;
  tuesday?: Maybe<IntNullableListFilter>;
  wednesday?: Maybe<IntNullableListFilter>;
  thursday?: Maybe<IntNullableListFilter>;
  friday?: Maybe<IntNullableListFilter>;
  saturday?: Maybe<IntNullableListFilter>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type ScenarioOrderByInput = {
  id?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  plan?: Maybe<PlanOrderByInput>;
  planId?: Maybe<SortOrder>;
  maxCPM?: Maybe<SortOrder>;
  publishers?: Maybe<SortOrder>;
  poiCategories?: Maybe<SortOrder>;
  poiRadius?: Maybe<SortOrder>;
  bundles?: Maybe<SortOrder>;
  sizes?: Maybe<SortOrder>;
  daypart?: Maybe<ScenarioDaypartOrderByInput>;
  daypartId?: Maybe<SortOrder>;
  venuesIsExclude?: Maybe<SortOrder>;
  publishersIsExclude?: Maybe<SortOrder>;
  poiIsExclude?: Maybe<SortOrder>;
  bundlesIsExclude?: Maybe<SortOrder>;
  sizesIsExclude?: Maybe<SortOrder>;
  daypartsIsExclude?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type PlanOrderByInput = {
  id?: Maybe<SortOrder>;
  ownerEmail?: Maybe<SortOrder>;
  org?: Maybe<OrganizationOrderByInput>;
  orgId?: Maybe<SortOrder>;
  title?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  segment?: Maybe<SegmentOrderByInput>;
  segmentId?: Maybe<SortOrder>;
  startDate?: Maybe<SortOrder>;
  endDate?: Maybe<SortOrder>;
  geoType?: Maybe<SortOrder>;
  geoEntities?: Maybe<SortOrder>;
  geoBounds?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  archivedAt?: Maybe<SortOrder>;
};

export type OrganizationOrderByInput = {
  id?: Maybe<SortOrder>;
};

export type SegmentOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  category?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  orgId?: Maybe<SortOrder>;
  org?: Maybe<OrganizationOrderByInput>;
};

export type ScenarioDaypartOrderByInput = {
  id?: Maybe<SortOrder>;
  sunday?: Maybe<SortOrder>;
  monday?: Maybe<SortOrder>;
  tuesday?: Maybe<SortOrder>;
  wednesday?: Maybe<SortOrder>;
  thursday?: Maybe<SortOrder>;
  friday?: Maybe<SortOrder>;
  saturday?: Maybe<SortOrder>;
};

export type ScenarioWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export enum ScenarioScalarFieldEnum {
  Id = 'id',
  Title = 'title',
  Status = 'status',
  PlanId = 'planId',
  MaxCpm = 'maxCPM',
  Publishers = 'publishers',
  PoiCategories = 'poiCategories',
  PoiRadius = 'poiRadius',
  Bundles = 'bundles',
  Sizes = 'sizes',
  DaypartId = 'daypartId',
  VenuesIsExclude = 'venuesIsExclude',
  PublishersIsExclude = 'publishersIsExclude',
  PoiIsExclude = 'poiIsExclude',
  BundlesIsExclude = 'bundlesIsExclude',
  SizesIsExclude = 'sizesIsExclude',
  DaypartsIsExclude = 'daypartsIsExclude',
  CreatedAt = 'createdAt'
}

export type PlanInventory = {
  __typename?: 'PlanInventory';
  id: Scalars['Int'];
  adUnitId: Scalars['String'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  state: Scalars['String'];
  city: Scalars['String'];
  zipCode: Scalars['String'];
  planId: Scalars['Int'];
  publisher: Scalars['String'];
  bundle?: Maybe<Scalars['String']>;
  impressionsPerHour?: Maybe<Scalars['Int']>;
  orientation: Array<AdUnitOrientation>;
  venueId?: Maybe<Scalars['String']>;
  bigQueryJobId: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type PlanInventoryOrderByInput = {
  id?: Maybe<SortOrder>;
  adUnitId?: Maybe<SortOrder>;
  lat?: Maybe<SortOrder>;
  lon?: Maybe<SortOrder>;
  state?: Maybe<SortOrder>;
  city?: Maybe<SortOrder>;
  zipCode?: Maybe<SortOrder>;
  plan?: Maybe<PlanOrderByInput>;
  planId?: Maybe<SortOrder>;
  publisher?: Maybe<SortOrder>;
  bundle?: Maybe<SortOrder>;
  impressionsPerHour?: Maybe<SortOrder>;
  orientation?: Maybe<SortOrder>;
  venue?: Maybe<InventoryVenueOrderByInput>;
  venueId?: Maybe<SortOrder>;
  bigQueryJob?: Maybe<BigQueryJobOrderByInput>;
  bigQueryJobId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
};

export type InventoryVenueOrderByInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  doohId?: Maybe<SortOrder>;
  stringList?: Maybe<SortOrder>;
  Scenario?: Maybe<ScenarioOrderByInput>;
  scenarioId?: Maybe<SortOrder>;
};

export type BigQueryJobOrderByInput = {
  id?: Maybe<SortOrder>;
  status?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  startedAt?: Maybe<SortOrder>;
  completedAt?: Maybe<SortOrder>;
  processedAt?: Maybe<SortOrder>;
  processedBy?: Maybe<SortOrder>;
  totalBytesProcessed?: Maybe<SortOrder>;
  totalBytesBilled?: Maybe<SortOrder>;
  totalMiliseconds?: Maybe<SortOrder>;
  errorMessage?: Maybe<SortOrder>;
  jobMetadata?: Maybe<SortOrder>;
  plan?: Maybe<PlanOrderByInput>;
  planId?: Maybe<SortOrder>;
  jobType?: Maybe<SortOrder>;
};

export type PlanInventoryWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export enum PlanInventoryScalarFieldEnum {
  Id = 'id',
  AdUnitId = 'adUnitId',
  Lat = 'lat',
  Lon = 'lon',
  State = 'state',
  City = 'city',
  ZipCode = 'zipCode',
  PlanId = 'planId',
  Publisher = 'publisher',
  Bundle = 'bundle',
  ImpressionsPerHour = 'impressionsPerHour',
  Orientation = 'orientation',
  VenueId = 'venueId',
  BigQueryJobId = 'bigQueryJobId',
  CreatedAt = 'createdAt'
}

export type BigQueryJob = {
  __typename?: 'BigQueryJob';
  id: Scalars['String'];
  status: BigQueryJobState;
  createdAt: Scalars['DateTime'];
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedBy?: Maybe<JobProcessor>;
  totalBytesProcessed?: Maybe<Scalars['String']>;
  totalBytesBilled?: Maybe<Scalars['String']>;
  totalMiliseconds?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['JSON']>;
  jobMetadata?: Maybe<Scalars['JSON']>;
  planId: Scalars['Int'];
  jobType: BigQueryJobType;
};

export type BigQueryJobWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum BigQueryJobScalarFieldEnum {
  Id = 'id',
  Status = 'status',
  CreatedAt = 'createdAt',
  StartedAt = 'startedAt',
  CompletedAt = 'completedAt',
  ProcessedAt = 'processedAt',
  ProcessedBy = 'processedBy',
  TotalBytesProcessed = 'totalBytesProcessed',
  TotalBytesBilled = 'totalBytesBilled',
  TotalMiliseconds = 'totalMiliseconds',
  ErrorMessage = 'errorMessage',
  JobMetadata = 'jobMetadata',
  PlanId = 'planId',
  JobType = 'jobType'
}

export type FootTraffic = {
  __typename?: 'FootTraffic';
  id: Scalars['Int'];
  planId: Scalars['Int'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  count: Scalars['Int'];
};

export type FootTrafficOrderByInput = {
  id?: Maybe<SortOrder>;
  planId?: Maybe<SortOrder>;
  plan?: Maybe<PlanOrderByInput>;
  lat?: Maybe<SortOrder>;
  lon?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type FootTrafficWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export enum FootTrafficScalarFieldEnum {
  Id = 'id',
  PlanId = 'planId',
  Lat = 'lat',
  Lon = 'lon',
  Count = 'count'
}

export type InventoryLocation = {
  __typename?: 'InventoryLocation';
  id: Scalars['ID'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  isCluster: Scalars['Boolean'];
};

export type FootTrafficPoint = {
  __typename?: 'FootTrafficPoint';
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  count: Scalars['Float'];
};

export type InventoryExport = {
  __typename?: 'InventoryExport';
  headings: Array<Scalars['String']>;
  data: Array<Array<Scalars['InventoryExportCell']>>;
};


export type PlanMetadata = {
  __typename?: 'PlanMetadata';
  /** List of all unique publishers in the plan inventory */
  publishers: Array<Scalars['String']>;
  /** List of all the unique bundles on the plan inventory */
  bundles: Array<Scalars['String']>;
  /** List of all the unique sizes in the plan inventory in the form: 100x300 */
  sizes: Array<Scalars['String']>;
};

export type InventoryDaypartAggregate = {
  __typename?: 'InventoryDaypartAggregate';
  dow: Scalars['Float'];
  hour: Scalars['Float'];
  metric: Scalars['Float'];
};

/** All the available metrics for filtering daypart calculations. */
export enum DaypartMetrics {
  Impressions = 'IMPRESSIONS',
  Reach = 'REACH',
  Frequency = 'FREQUENCY'
}

export type PlanSummaryMetrics = {
  __typename?: 'PlanSummaryMetrics';
  /** The total ad units within the selected geo region for a plan. */
  totalAdUnits: Scalars['Int'];
  /** The total amount of impressions the group of ad units has received within the selected date range for a plan. */
  totalImpressions: Scalars['Int'];
  /** The total amount of impressions for a plan's target audience within the group of ad units. */
  audienceImpressions: Scalars['Int'];
  /** The percetage of impressions that belong to the plan's target audience in comparison to the total impressions. */
  audienceComposition: Scalars['Float'];
  /** The average biding pricing taken from the Place Exchange bidfloor offerings. */
  cpm: Scalars['Int'];
};

export type PlanInventoryMetrics = {
  __typename?: 'PlanInventoryMetrics';
  /** The total ad units within the selected geo region for a plan. */
  totalAdUnits: Scalars['Int'];
  /** The total amount of impressions the group of ad units has received within the selected date range for a plan. */
  totalImpressions: Scalars['Int'];
  /** The total amount of impressions for a plan's target audience within the group of ad units. */
  audienceImpressions: Scalars['Int'];
  /** The percetage of impressions that belong to the plan's target audience in comparison to the total impressions. */
  audienceComposition: Scalars['Float'];
  /** The average biding pricing taken from the Place Exchange bidfloor offerings. */
  cpm: Scalars['Int'];
  /** The value of the selected dimension on which the metrics where grouped by. */
  dimension: Scalars['String'];
  /** The plan's target audience composition divided by the composition of the grouped set of inventory with an scpecific characteristic. */
  audienceIndex: Scalars['Int'];
};

export enum InventoryDimension {
  Venue = 'venue',
  Publisher = 'publisher',
  Bundle = 'bundle',
  Orientation = 'orientation'
}

/** All the available metrics for a piece of inventory */
export enum InventoryMetrics {
  Dimension = 'DIMENSION',
  TotalAdUnits = 'TOTAL_AD_UNITS',
  TotalImpressions = 'TOTAL_IMPRESSIONS',
  AudienceImpressions = 'AUDIENCE_IMPRESSIONS',
  AudienceComposition = 'AUDIENCE_COMPOSITION',
  AudienceIndex = 'AUDIENCE_INDEX',
  Cpm = 'CPM'
}

export type InventoryVenue = {
  __typename?: 'InventoryVenue';
  id: Scalars['String'];
  name: Scalars['String'];
  doohId?: Maybe<Scalars['String']>;
  stringList?: Maybe<Scalars['String']>;
  scenarioId?: Maybe<Scalars['Int']>;
};

export type InventoryVenueWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum InventoryVenueScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  DoohId = 'doohId',
  StringList = 'stringList',
  ScenarioId = 'scenarioId'
}

export type ScenarioDaypart = {
  __typename?: 'ScenarioDaypart';
  id: Scalars['Int'];
  sunday: Array<Scalars['Int']>;
  monday: Array<Scalars['Int']>;
  tuesday: Array<Scalars['Int']>;
  wednesday: Array<Scalars['Int']>;
  thursday: Array<Scalars['Int']>;
  friday: Array<Scalars['Int']>;
  saturday: Array<Scalars['Int']>;
};

export type AggregateScenario = {
  __typename?: 'AggregateScenario';
  count?: Maybe<ScenarioCountAggregate>;
  avg?: Maybe<ScenarioAvgAggregate>;
  sum?: Maybe<ScenarioSumAggregate>;
  min?: Maybe<ScenarioMinAggregate>;
  max?: Maybe<ScenarioMaxAggregate>;
};

export type ScenarioCountAggregate = {
  __typename?: 'ScenarioCountAggregate';
  id: Scalars['Int'];
  title?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['Int']>;
  planId: Scalars['Int'];
  maxCPM?: Maybe<Scalars['Int']>;
  publishers?: Maybe<Scalars['Int']>;
  poiCategories?: Maybe<Scalars['Int']>;
  poiRadius?: Maybe<Scalars['Int']>;
  bundles?: Maybe<Scalars['Int']>;
  sizes?: Maybe<Scalars['Int']>;
  daypartId: Scalars['Int'];
  venuesIsExclude?: Maybe<Scalars['Int']>;
  publishersIsExclude?: Maybe<Scalars['Int']>;
  poiIsExclude?: Maybe<Scalars['Int']>;
  bundlesIsExclude?: Maybe<Scalars['Int']>;
  sizesIsExclude?: Maybe<Scalars['Int']>;
  daypartsIsExclude?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  _all: Scalars['Int'];
};

export type ScenarioAvgAggregate = {
  __typename?: 'ScenarioAvgAggregate';
  id: Scalars['Float'];
  planId: Scalars['Float'];
  maxCPM?: Maybe<Scalars['Float']>;
  poiRadius?: Maybe<Scalars['Float']>;
  daypartId: Scalars['Float'];
};

export type ScenarioSumAggregate = {
  __typename?: 'ScenarioSumAggregate';
  id: Scalars['Int'];
  planId: Scalars['Int'];
  maxCPM?: Maybe<Scalars['Float']>;
  poiRadius?: Maybe<Scalars['Float']>;
  daypartId: Scalars['Int'];
};

export type ScenarioMinAggregate = {
  __typename?: 'ScenarioMinAggregate';
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  status?: Maybe<ScenarioStatus>;
  planId: Scalars['Int'];
  maxCPM?: Maybe<Scalars['Float']>;
  poiRadius?: Maybe<Scalars['Float']>;
  daypartId: Scalars['Int'];
  venuesIsExclude?: Maybe<Scalars['Boolean']>;
  publishersIsExclude?: Maybe<Scalars['Boolean']>;
  poiIsExclude?: Maybe<Scalars['Boolean']>;
  bundlesIsExclude?: Maybe<Scalars['Boolean']>;
  sizesIsExclude?: Maybe<Scalars['Boolean']>;
  daypartsIsExclude?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type ScenarioMaxAggregate = {
  __typename?: 'ScenarioMaxAggregate';
  id: Scalars['Int'];
  title?: Maybe<Scalars['String']>;
  status?: Maybe<ScenarioStatus>;
  planId: Scalars['Int'];
  maxCPM?: Maybe<Scalars['Float']>;
  poiRadius?: Maybe<Scalars['Float']>;
  daypartId: Scalars['Int'];
  venuesIsExclude?: Maybe<Scalars['Boolean']>;
  publishersIsExclude?: Maybe<Scalars['Boolean']>;
  poiIsExclude?: Maybe<Scalars['Boolean']>;
  bundlesIsExclude?: Maybe<Scalars['Boolean']>;
  sizesIsExclude?: Maybe<Scalars['Boolean']>;
  daypartsIsExclude?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type AggregatePlanInventory = {
  __typename?: 'AggregatePlanInventory';
  count?: Maybe<PlanInventoryCountAggregate>;
  avg?: Maybe<PlanInventoryAvgAggregate>;
  sum?: Maybe<PlanInventorySumAggregate>;
  min?: Maybe<PlanInventoryMinAggregate>;
  max?: Maybe<PlanInventoryMaxAggregate>;
};

export type PlanInventoryCountAggregate = {
  __typename?: 'PlanInventoryCountAggregate';
  id: Scalars['Int'];
  adUnitId?: Maybe<Scalars['Int']>;
  lat: Scalars['Int'];
  lon: Scalars['Int'];
  state?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['Int']>;
  zipCode?: Maybe<Scalars['Int']>;
  planId: Scalars['Int'];
  publisher?: Maybe<Scalars['Int']>;
  bundle?: Maybe<Scalars['Int']>;
  impressionsPerHour?: Maybe<Scalars['Int']>;
  orientation?: Maybe<Scalars['Int']>;
  venueId?: Maybe<Scalars['Int']>;
  bigQueryJobId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Int']>;
  _all: Scalars['Int'];
};

export type PlanInventoryAvgAggregate = {
  __typename?: 'PlanInventoryAvgAggregate';
  id: Scalars['Float'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  planId: Scalars['Float'];
  impressionsPerHour?: Maybe<Scalars['Float']>;
};

export type PlanInventorySumAggregate = {
  __typename?: 'PlanInventorySumAggregate';
  id: Scalars['Int'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  planId: Scalars['Int'];
  impressionsPerHour?: Maybe<Scalars['Int']>;
};

export type PlanInventoryMinAggregate = {
  __typename?: 'PlanInventoryMinAggregate';
  id: Scalars['Int'];
  adUnitId?: Maybe<Scalars['String']>;
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  planId: Scalars['Int'];
  publisher?: Maybe<Scalars['String']>;
  bundle?: Maybe<Scalars['String']>;
  impressionsPerHour?: Maybe<Scalars['Int']>;
  venueId?: Maybe<Scalars['String']>;
  bigQueryJobId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PlanInventoryMaxAggregate = {
  __typename?: 'PlanInventoryMaxAggregate';
  id: Scalars['Int'];
  adUnitId?: Maybe<Scalars['String']>;
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  state?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  planId: Scalars['Int'];
  publisher?: Maybe<Scalars['String']>;
  bundle?: Maybe<Scalars['String']>;
  impressionsPerHour?: Maybe<Scalars['Int']>;
  venueId?: Maybe<Scalars['String']>;
  bigQueryJobId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type PoiLocation = {
  __typename?: 'PoiLocation';
  id: Scalars['ID'];
  lat: Scalars['Float'];
  lng: Scalars['Float'];
  isCluster: Scalars['Boolean'];
};

export type PlanWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export enum PlanScalarFieldEnum {
  Id = 'id',
  OwnerEmail = 'ownerEmail',
  OrgId = 'orgId',
  Title = 'title',
  Status = 'status',
  SegmentId = 'segmentId',
  StartDate = 'startDate',
  EndDate = 'endDate',
  GeoType = 'geoType',
  GeoEntities = 'geoEntities',
  GeoBounds = 'geoBounds',
  CreatedAt = 'createdAt',
  ArchivedAt = 'archivedAt'
}

export type SegmentWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum SegmentScalarFieldEnum {
  Id = 'id',
  Name = 'name',
  Category = 'category',
  Status = 'status',
  OrgId = 'orgId'
}

export type Mutation = {
  __typename?: 'Mutation';
  createScenario: Scenario;
  deleteScenario?: Maybe<Scenario>;
  updateScenario?: Maybe<Scenario>;
  deleteManyScenario: AffectedRowsOutput;
  updateManyScenario: AffectedRowsOutput;
  upsertScenario: Scenario;
  createPlan: Plan;
  deletePlan?: Maybe<Plan>;
  updatePlan?: Maybe<Plan>;
};


export type MutationCreateScenarioArgs = {
  data: ScenarioCreateInput;
};


export type MutationDeleteScenarioArgs = {
  where: ScenarioWhereUniqueInput;
};


export type MutationUpdateScenarioArgs = {
  data: ScenarioUpdateInput;
  where: ScenarioWhereUniqueInput;
};


export type MutationDeleteManyScenarioArgs = {
  where?: Maybe<ScenarioWhereInput>;
};


export type MutationUpdateManyScenarioArgs = {
  data: ScenarioUpdateManyMutationInput;
  where?: Maybe<ScenarioWhereInput>;
};


export type MutationUpsertScenarioArgs = {
  where: ScenarioWhereUniqueInput;
  create: ScenarioCreateInput;
  update: ScenarioUpdateInput;
};


export type MutationCreatePlanArgs = {
  data: PlanCreateInput;
};


export type MutationDeletePlanArgs = {
  where: PlanWhereUniqueInput;
};


export type MutationUpdatePlanArgs = {
  data: PlanUpdateInput;
  where: PlanWhereUniqueInput;
};

export type ScenarioCreateInput = {
  title: Scalars['String'];
  status?: Maybe<ScenarioStatus>;
  maxCPM?: Maybe<Scalars['Float']>;
  poiRadius?: Maybe<Scalars['Float']>;
  venuesIsExclude?: Maybe<Scalars['Boolean']>;
  publishersIsExclude?: Maybe<Scalars['Boolean']>;
  poiIsExclude?: Maybe<Scalars['Boolean']>;
  bundlesIsExclude?: Maybe<Scalars['Boolean']>;
  sizesIsExclude?: Maybe<Scalars['Boolean']>;
  daypartsIsExclude?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  publishers?: Maybe<ScenarioCreatepublishersInput>;
  poiCategories?: Maybe<ScenarioCreatepoiCategoriesInput>;
  bundles?: Maybe<ScenarioCreatebundlesInput>;
  sizes?: Maybe<ScenarioCreatesizesInput>;
  plan: PlanCreateNestedOneWithoutScenariosInput;
  venues?: Maybe<InventoryVenueCreateNestedManyWithoutScenarioInput>;
  daypart: ScenarioDaypartCreateNestedOneWithoutScenarioInput;
};

export type ScenarioCreatepublishersInput = {
  set: Array<Scalars['String']>;
};

export type ScenarioCreatepoiCategoriesInput = {
  set: Array<Scalars['String']>;
};

export type ScenarioCreatebundlesInput = {
  set: Array<Scalars['String']>;
};

export type ScenarioCreatesizesInput = {
  set: Array<Scalars['String']>;
};

export type PlanCreateNestedOneWithoutScenariosInput = {
  create?: Maybe<PlanCreateWithoutScenariosInput>;
  connectOrCreate?: Maybe<PlanCreateOrConnectWithoutscenariosInput>;
  connect?: Maybe<PlanWhereUniqueInput>;
};

export type PlanCreateWithoutScenariosInput = {
  ownerEmail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  status?: Maybe<PlanStatus>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  geoType: GeoType;
  geoBounds?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  geoEntities?: Maybe<PlanCreategeoEntitiesInput>;
  org: OrganizationCreateNestedOneWithoutPlansInput;
  segment: SegmentCreateNestedOneWithoutPlansInput;
  inventory?: Maybe<PlanInventoryCreateNestedManyWithoutPlanInput>;
  jobs?: Maybe<BigQueryJobCreateNestedManyWithoutPlanInput>;
  footraffic?: Maybe<FootTrafficCreateNestedManyWithoutPlanInput>;
};

export type PlanCreategeoEntitiesInput = {
  set: Array<Scalars['String']>;
};

export type OrganizationCreateNestedOneWithoutPlansInput = {
  create?: Maybe<OrganizationCreateWithoutPlansInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutplansInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateWithoutPlansInput = {
  id: Scalars['String'];
  users?: Maybe<UserCreateNestedManyWithoutPrimaryOrgInput>;
  segments?: Maybe<SegmentCreateNestedManyWithoutOrgInput>;
};

export type UserCreateNestedManyWithoutPrimaryOrgInput = {
  create?: Maybe<Array<UserCreateWithoutPrimaryOrgInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutprimaryOrgInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateWithoutPrimaryOrgInput = {
  id: Scalars['String'];
  email: Scalars['String'];
};

export type UserCreateOrConnectWithoutprimaryOrgInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutPrimaryOrgInput;
};

export type UserWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type SegmentCreateNestedManyWithoutOrgInput = {
  create?: Maybe<Array<SegmentCreateWithoutOrgInput>>;
  connectOrCreate?: Maybe<Array<SegmentCreateOrConnectWithoutorgInput>>;
  connect?: Maybe<Array<SegmentWhereUniqueInput>>;
};

export type SegmentCreateWithoutOrgInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  category: Scalars['String'];
  status: SegmentStatus;
  plans?: Maybe<PlanCreateNestedManyWithoutSegmentInput>;
};

export type PlanCreateNestedManyWithoutSegmentInput = {
  create?: Maybe<Array<PlanCreateWithoutSegmentInput>>;
  connectOrCreate?: Maybe<Array<PlanCreateOrConnectWithoutsegmentInput>>;
  connect?: Maybe<Array<PlanWhereUniqueInput>>;
};

export type PlanCreateWithoutSegmentInput = {
  ownerEmail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  status?: Maybe<PlanStatus>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  geoType: GeoType;
  geoBounds?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  geoEntities?: Maybe<PlanCreategeoEntitiesInput>;
  org: OrganizationCreateNestedOneWithoutPlansInput;
  scenarios?: Maybe<ScenarioCreateNestedManyWithoutPlanInput>;
  inventory?: Maybe<PlanInventoryCreateNestedManyWithoutPlanInput>;
  jobs?: Maybe<BigQueryJobCreateNestedManyWithoutPlanInput>;
  footraffic?: Maybe<FootTrafficCreateNestedManyWithoutPlanInput>;
};

export type ScenarioCreateNestedManyWithoutPlanInput = {
  create?: Maybe<Array<ScenarioCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<ScenarioCreateOrConnectWithoutplanInput>>;
  connect?: Maybe<Array<ScenarioWhereUniqueInput>>;
};

export type ScenarioCreateWithoutPlanInput = {
  title: Scalars['String'];
  status?: Maybe<ScenarioStatus>;
  maxCPM?: Maybe<Scalars['Float']>;
  poiRadius?: Maybe<Scalars['Float']>;
  venuesIsExclude?: Maybe<Scalars['Boolean']>;
  publishersIsExclude?: Maybe<Scalars['Boolean']>;
  poiIsExclude?: Maybe<Scalars['Boolean']>;
  bundlesIsExclude?: Maybe<Scalars['Boolean']>;
  sizesIsExclude?: Maybe<Scalars['Boolean']>;
  daypartsIsExclude?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  publishers?: Maybe<ScenarioCreatepublishersInput>;
  poiCategories?: Maybe<ScenarioCreatepoiCategoriesInput>;
  bundles?: Maybe<ScenarioCreatebundlesInput>;
  sizes?: Maybe<ScenarioCreatesizesInput>;
  venues?: Maybe<InventoryVenueCreateNestedManyWithoutScenarioInput>;
  daypart: ScenarioDaypartCreateNestedOneWithoutScenarioInput;
};

export type InventoryVenueCreateNestedManyWithoutScenarioInput = {
  create?: Maybe<Array<InventoryVenueCreateWithoutScenarioInput>>;
  connectOrCreate?: Maybe<Array<InventoryVenueCreateOrConnectWithoutScenarioInput>>;
  connect?: Maybe<Array<InventoryVenueWhereUniqueInput>>;
};

export type InventoryVenueCreateWithoutScenarioInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  doohId?: Maybe<Scalars['String']>;
  stringList?: Maybe<Scalars['String']>;
  PlanInventory?: Maybe<PlanInventoryCreateNestedManyWithoutVenueInput>;
};

export type PlanInventoryCreateNestedManyWithoutVenueInput = {
  create?: Maybe<Array<PlanInventoryCreateWithoutVenueInput>>;
  connectOrCreate?: Maybe<Array<PlanInventoryCreateOrConnectWithoutvenueInput>>;
  connect?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
};

export type PlanInventoryCreateWithoutVenueInput = {
  adUnitId: Scalars['String'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  state: Scalars['String'];
  city: Scalars['String'];
  zipCode: Scalars['String'];
  publisher: Scalars['String'];
  bundle?: Maybe<Scalars['String']>;
  impressionsPerHour?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  orientation?: Maybe<PlanInventoryCreateorientationInput>;
  plan: PlanCreateNestedOneWithoutInventoryInput;
  bigQueryJob: BigQueryJobCreateNestedOneWithoutPlanInventoryInput;
};

export type PlanInventoryCreateorientationInput = {
  set: Array<AdUnitOrientation>;
};

export type PlanCreateNestedOneWithoutInventoryInput = {
  create?: Maybe<PlanCreateWithoutInventoryInput>;
  connectOrCreate?: Maybe<PlanCreateOrConnectWithoutinventoryInput>;
  connect?: Maybe<PlanWhereUniqueInput>;
};

export type PlanCreateWithoutInventoryInput = {
  ownerEmail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  status?: Maybe<PlanStatus>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  geoType: GeoType;
  geoBounds?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  geoEntities?: Maybe<PlanCreategeoEntitiesInput>;
  org: OrganizationCreateNestedOneWithoutPlansInput;
  segment: SegmentCreateNestedOneWithoutPlansInput;
  scenarios?: Maybe<ScenarioCreateNestedManyWithoutPlanInput>;
  jobs?: Maybe<BigQueryJobCreateNestedManyWithoutPlanInput>;
  footraffic?: Maybe<FootTrafficCreateNestedManyWithoutPlanInput>;
};

export type SegmentCreateNestedOneWithoutPlansInput = {
  create?: Maybe<SegmentCreateWithoutPlansInput>;
  connectOrCreate?: Maybe<SegmentCreateOrConnectWithoutplansInput>;
  connect?: Maybe<SegmentWhereUniqueInput>;
};

export type SegmentCreateWithoutPlansInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  category: Scalars['String'];
  status: SegmentStatus;
  org: OrganizationCreateNestedOneWithoutSegmentsInput;
};

export type OrganizationCreateNestedOneWithoutSegmentsInput = {
  create?: Maybe<OrganizationCreateWithoutSegmentsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutsegmentsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateWithoutSegmentsInput = {
  id: Scalars['String'];
  plans?: Maybe<PlanCreateNestedManyWithoutOrgInput>;
  users?: Maybe<UserCreateNestedManyWithoutPrimaryOrgInput>;
};

export type PlanCreateNestedManyWithoutOrgInput = {
  create?: Maybe<Array<PlanCreateWithoutOrgInput>>;
  connectOrCreate?: Maybe<Array<PlanCreateOrConnectWithoutorgInput>>;
  connect?: Maybe<Array<PlanWhereUniqueInput>>;
};

export type PlanCreateWithoutOrgInput = {
  ownerEmail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  status?: Maybe<PlanStatus>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  geoType: GeoType;
  geoBounds?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  geoEntities?: Maybe<PlanCreategeoEntitiesInput>;
  segment: SegmentCreateNestedOneWithoutPlansInput;
  scenarios?: Maybe<ScenarioCreateNestedManyWithoutPlanInput>;
  inventory?: Maybe<PlanInventoryCreateNestedManyWithoutPlanInput>;
  jobs?: Maybe<BigQueryJobCreateNestedManyWithoutPlanInput>;
  footraffic?: Maybe<FootTrafficCreateNestedManyWithoutPlanInput>;
};

export type PlanInventoryCreateNestedManyWithoutPlanInput = {
  create?: Maybe<Array<PlanInventoryCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<PlanInventoryCreateOrConnectWithoutplanInput>>;
  connect?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
};

export type PlanInventoryCreateWithoutPlanInput = {
  adUnitId: Scalars['String'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  state: Scalars['String'];
  city: Scalars['String'];
  zipCode: Scalars['String'];
  publisher: Scalars['String'];
  bundle?: Maybe<Scalars['String']>;
  impressionsPerHour?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  orientation?: Maybe<PlanInventoryCreateorientationInput>;
  venue?: Maybe<InventoryVenueCreateNestedOneWithoutPlanInventoryInput>;
  bigQueryJob: BigQueryJobCreateNestedOneWithoutPlanInventoryInput;
};

export type InventoryVenueCreateNestedOneWithoutPlanInventoryInput = {
  create?: Maybe<InventoryVenueCreateWithoutPlanInventoryInput>;
  connectOrCreate?: Maybe<InventoryVenueCreateOrConnectWithoutPlanInventoryInput>;
  connect?: Maybe<InventoryVenueWhereUniqueInput>;
};

export type InventoryVenueCreateWithoutPlanInventoryInput = {
  id: Scalars['String'];
  name: Scalars['String'];
  doohId?: Maybe<Scalars['String']>;
  stringList?: Maybe<Scalars['String']>;
  Scenario?: Maybe<ScenarioCreateNestedOneWithoutVenuesInput>;
};

export type ScenarioCreateNestedOneWithoutVenuesInput = {
  create?: Maybe<ScenarioCreateWithoutVenuesInput>;
  connectOrCreate?: Maybe<ScenarioCreateOrConnectWithoutvenuesInput>;
  connect?: Maybe<ScenarioWhereUniqueInput>;
};

export type ScenarioCreateWithoutVenuesInput = {
  title: Scalars['String'];
  status?: Maybe<ScenarioStatus>;
  maxCPM?: Maybe<Scalars['Float']>;
  poiRadius?: Maybe<Scalars['Float']>;
  venuesIsExclude?: Maybe<Scalars['Boolean']>;
  publishersIsExclude?: Maybe<Scalars['Boolean']>;
  poiIsExclude?: Maybe<Scalars['Boolean']>;
  bundlesIsExclude?: Maybe<Scalars['Boolean']>;
  sizesIsExclude?: Maybe<Scalars['Boolean']>;
  daypartsIsExclude?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  publishers?: Maybe<ScenarioCreatepublishersInput>;
  poiCategories?: Maybe<ScenarioCreatepoiCategoriesInput>;
  bundles?: Maybe<ScenarioCreatebundlesInput>;
  sizes?: Maybe<ScenarioCreatesizesInput>;
  plan: PlanCreateNestedOneWithoutScenariosInput;
  daypart: ScenarioDaypartCreateNestedOneWithoutScenarioInput;
};

export type ScenarioDaypartCreateNestedOneWithoutScenarioInput = {
  create?: Maybe<ScenarioDaypartCreateWithoutScenarioInput>;
  connectOrCreate?: Maybe<ScenarioDaypartCreateOrConnectWithoutscenarioInput>;
  connect?: Maybe<ScenarioDaypartWhereUniqueInput>;
};

export type ScenarioDaypartCreateWithoutScenarioInput = {
  sunday?: Maybe<ScenarioDaypartCreatesundayInput>;
  monday?: Maybe<ScenarioDaypartCreatemondayInput>;
  tuesday?: Maybe<ScenarioDaypartCreatetuesdayInput>;
  wednesday?: Maybe<ScenarioDaypartCreatewednesdayInput>;
  thursday?: Maybe<ScenarioDaypartCreatethursdayInput>;
  friday?: Maybe<ScenarioDaypartCreatefridayInput>;
  saturday?: Maybe<ScenarioDaypartCreatesaturdayInput>;
};

export type ScenarioDaypartCreatesundayInput = {
  set: Array<Scalars['Int']>;
};

export type ScenarioDaypartCreatemondayInput = {
  set: Array<Scalars['Int']>;
};

export type ScenarioDaypartCreatetuesdayInput = {
  set: Array<Scalars['Int']>;
};

export type ScenarioDaypartCreatewednesdayInput = {
  set: Array<Scalars['Int']>;
};

export type ScenarioDaypartCreatethursdayInput = {
  set: Array<Scalars['Int']>;
};

export type ScenarioDaypartCreatefridayInput = {
  set: Array<Scalars['Int']>;
};

export type ScenarioDaypartCreatesaturdayInput = {
  set: Array<Scalars['Int']>;
};

export type ScenarioDaypartCreateOrConnectWithoutscenarioInput = {
  where: ScenarioDaypartWhereUniqueInput;
  create: ScenarioDaypartCreateWithoutScenarioInput;
};

export type ScenarioDaypartWhereUniqueInput = {
  id?: Maybe<Scalars['Int']>;
};

export type ScenarioCreateOrConnectWithoutvenuesInput = {
  where: ScenarioWhereUniqueInput;
  create: ScenarioCreateWithoutVenuesInput;
};

export type InventoryVenueCreateOrConnectWithoutPlanInventoryInput = {
  where: InventoryVenueWhereUniqueInput;
  create: InventoryVenueCreateWithoutPlanInventoryInput;
};

export type BigQueryJobCreateNestedOneWithoutPlanInventoryInput = {
  create?: Maybe<BigQueryJobCreateWithoutPlanInventoryInput>;
  connectOrCreate?: Maybe<BigQueryJobCreateOrConnectWithoutPlanInventoryInput>;
  connect?: Maybe<BigQueryJobWhereUniqueInput>;
};

export type BigQueryJobCreateWithoutPlanInventoryInput = {
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BigQueryJobState>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedBy?: Maybe<JobProcessor>;
  totalBytesProcessed?: Maybe<Scalars['String']>;
  totalBytesBilled?: Maybe<Scalars['String']>;
  totalMiliseconds?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['JSON']>;
  jobMetadata?: Maybe<Scalars['JSON']>;
  jobType: BigQueryJobType;
  plan: PlanCreateNestedOneWithoutJobsInput;
  InventoryDaypartMetric?: Maybe<InventoryDaypartMetricCreateNestedManyWithoutBigQueryJobInput>;
};

export type PlanCreateNestedOneWithoutJobsInput = {
  create?: Maybe<PlanCreateWithoutJobsInput>;
  connectOrCreate?: Maybe<PlanCreateOrConnectWithoutjobsInput>;
  connect?: Maybe<PlanWhereUniqueInput>;
};

export type PlanCreateWithoutJobsInput = {
  ownerEmail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  status?: Maybe<PlanStatus>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  geoType: GeoType;
  geoBounds?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  geoEntities?: Maybe<PlanCreategeoEntitiesInput>;
  org: OrganizationCreateNestedOneWithoutPlansInput;
  segment: SegmentCreateNestedOneWithoutPlansInput;
  scenarios?: Maybe<ScenarioCreateNestedManyWithoutPlanInput>;
  inventory?: Maybe<PlanInventoryCreateNestedManyWithoutPlanInput>;
  footraffic?: Maybe<FootTrafficCreateNestedManyWithoutPlanInput>;
};

export type FootTrafficCreateNestedManyWithoutPlanInput = {
  create?: Maybe<Array<FootTrafficCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<FootTrafficCreateOrConnectWithoutplanInput>>;
  connect?: Maybe<Array<FootTrafficWhereUniqueInput>>;
};

export type FootTrafficCreateWithoutPlanInput = {
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  count: Scalars['Int'];
};

export type FootTrafficCreateOrConnectWithoutplanInput = {
  where: FootTrafficWhereUniqueInput;
  create: FootTrafficCreateWithoutPlanInput;
};

export type PlanCreateOrConnectWithoutjobsInput = {
  where: PlanWhereUniqueInput;
  create: PlanCreateWithoutJobsInput;
};

export type InventoryDaypartMetricCreateNestedManyWithoutBigQueryJobInput = {
  create?: Maybe<Array<InventoryDaypartMetricCreateWithoutBigQueryJobInput>>;
  connectOrCreate?: Maybe<Array<InventoryDaypartMetricCreateOrConnectWithoutbigQueryJobInput>>;
  connect?: Maybe<Array<InventoryDaypartMetricWhereUniqueInput>>;
};

export type InventoryDaypartMetricCreateWithoutBigQueryJobInput = {
  adUnitId: Scalars['String'];
  date: Scalars['DateTime'];
  totalImpressions: Scalars['Int'];
  impressionsPerHour?: Maybe<InventoryDaypartMetricCreateimpressionsPerHourInput>;
  segments?: Maybe<InventorySegmentDaypartMetricCreateNestedManyWithoutDaypartImpressionInput>;
};

export type InventoryDaypartMetricCreateimpressionsPerHourInput = {
  set: Array<Scalars['Int']>;
};

export type InventorySegmentDaypartMetricCreateNestedManyWithoutDaypartImpressionInput = {
  create?: Maybe<Array<InventorySegmentDaypartMetricCreateWithoutDaypartImpressionInput>>;
  connectOrCreate?: Maybe<Array<InventorySegmentDaypartMetricCreateOrConnectWithoutdaypartImpressionInput>>;
  connect?: Maybe<Array<InventorySegmentDaypartMetricWhereUniqueInput>>;
};

export type InventorySegmentDaypartMetricCreateWithoutDaypartImpressionInput = {
  totalImpressions: Scalars['Int'];
  segmentId: Scalars['String'];
  impressionsPerHour?: Maybe<InventorySegmentDaypartMetricCreateimpressionsPerHourInput>;
};

export type InventorySegmentDaypartMetricCreateimpressionsPerHourInput = {
  set: Array<Scalars['Int']>;
};

export type InventorySegmentDaypartMetricCreateOrConnectWithoutdaypartImpressionInput = {
  where: InventorySegmentDaypartMetricWhereUniqueInput;
  create: InventorySegmentDaypartMetricCreateWithoutDaypartImpressionInput;
};

export type InventorySegmentDaypartMetricWhereUniqueInput = {
  adUnitId_date_segmentId?: Maybe<InventorySegmentDaypartMetricAdUnitIdDateSegmentIdCompoundUniqueInput>;
};

export type InventorySegmentDaypartMetricAdUnitIdDateSegmentIdCompoundUniqueInput = {
  adUnitId: Scalars['String'];
  date: Scalars['DateTime'];
  segmentId: Scalars['String'];
};

export type InventoryDaypartMetricCreateOrConnectWithoutbigQueryJobInput = {
  where: InventoryDaypartMetricWhereUniqueInput;
  create: InventoryDaypartMetricCreateWithoutBigQueryJobInput;
};

export type InventoryDaypartMetricWhereUniqueInput = {
  adUnitId_date?: Maybe<InventoryDaypartMetricAdUnitIdDateCompoundUniqueInput>;
};

export type InventoryDaypartMetricAdUnitIdDateCompoundUniqueInput = {
  adUnitId: Scalars['String'];
  date: Scalars['DateTime'];
};

export type BigQueryJobCreateOrConnectWithoutPlanInventoryInput = {
  where: BigQueryJobWhereUniqueInput;
  create: BigQueryJobCreateWithoutPlanInventoryInput;
};

export type PlanInventoryCreateOrConnectWithoutplanInput = {
  where: PlanInventoryWhereUniqueInput;
  create: PlanInventoryCreateWithoutPlanInput;
};

export type BigQueryJobCreateNestedManyWithoutPlanInput = {
  create?: Maybe<Array<BigQueryJobCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<BigQueryJobCreateOrConnectWithoutplanInput>>;
  connect?: Maybe<Array<BigQueryJobWhereUniqueInput>>;
};

export type BigQueryJobCreateWithoutPlanInput = {
  id?: Maybe<Scalars['String']>;
  status?: Maybe<BigQueryJobState>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startedAt?: Maybe<Scalars['DateTime']>;
  completedAt?: Maybe<Scalars['DateTime']>;
  processedAt?: Maybe<Scalars['DateTime']>;
  processedBy?: Maybe<JobProcessor>;
  totalBytesProcessed?: Maybe<Scalars['String']>;
  totalBytesBilled?: Maybe<Scalars['String']>;
  totalMiliseconds?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['JSON']>;
  jobMetadata?: Maybe<Scalars['JSON']>;
  jobType: BigQueryJobType;
  PlanInventory?: Maybe<PlanInventoryCreateNestedManyWithoutBigQueryJobInput>;
  InventoryDaypartMetric?: Maybe<InventoryDaypartMetricCreateNestedManyWithoutBigQueryJobInput>;
};

export type PlanInventoryCreateNestedManyWithoutBigQueryJobInput = {
  create?: Maybe<Array<PlanInventoryCreateWithoutBigQueryJobInput>>;
  connectOrCreate?: Maybe<Array<PlanInventoryCreateOrConnectWithoutbigQueryJobInput>>;
  connect?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
};

export type PlanInventoryCreateWithoutBigQueryJobInput = {
  adUnitId: Scalars['String'];
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  state: Scalars['String'];
  city: Scalars['String'];
  zipCode: Scalars['String'];
  publisher: Scalars['String'];
  bundle?: Maybe<Scalars['String']>;
  impressionsPerHour?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  orientation?: Maybe<PlanInventoryCreateorientationInput>;
  plan: PlanCreateNestedOneWithoutInventoryInput;
  venue?: Maybe<InventoryVenueCreateNestedOneWithoutPlanInventoryInput>;
};

export type PlanInventoryCreateOrConnectWithoutbigQueryJobInput = {
  where: PlanInventoryWhereUniqueInput;
  create: PlanInventoryCreateWithoutBigQueryJobInput;
};

export type BigQueryJobCreateOrConnectWithoutplanInput = {
  where: BigQueryJobWhereUniqueInput;
  create: BigQueryJobCreateWithoutPlanInput;
};

export type PlanCreateOrConnectWithoutorgInput = {
  where: PlanWhereUniqueInput;
  create: PlanCreateWithoutOrgInput;
};

export type OrganizationCreateOrConnectWithoutsegmentsInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutSegmentsInput;
};

export type OrganizationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type SegmentCreateOrConnectWithoutplansInput = {
  where: SegmentWhereUniqueInput;
  create: SegmentCreateWithoutPlansInput;
};

export type PlanCreateOrConnectWithoutinventoryInput = {
  where: PlanWhereUniqueInput;
  create: PlanCreateWithoutInventoryInput;
};

export type PlanInventoryCreateOrConnectWithoutvenueInput = {
  where: PlanInventoryWhereUniqueInput;
  create: PlanInventoryCreateWithoutVenueInput;
};

export type InventoryVenueCreateOrConnectWithoutScenarioInput = {
  where: InventoryVenueWhereUniqueInput;
  create: InventoryVenueCreateWithoutScenarioInput;
};

export type ScenarioCreateOrConnectWithoutplanInput = {
  where: ScenarioWhereUniqueInput;
  create: ScenarioCreateWithoutPlanInput;
};

export type PlanCreateOrConnectWithoutsegmentInput = {
  where: PlanWhereUniqueInput;
  create: PlanCreateWithoutSegmentInput;
};

export type SegmentCreateOrConnectWithoutorgInput = {
  where: SegmentWhereUniqueInput;
  create: SegmentCreateWithoutOrgInput;
};

export type OrganizationCreateOrConnectWithoutplansInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutPlansInput;
};

export type PlanCreateOrConnectWithoutscenariosInput = {
  where: PlanWhereUniqueInput;
  create: PlanCreateWithoutScenariosInput;
};

export type ScenarioUpdateInput = {
  title?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumScenarioStatusFieldUpdateOperationsInput>;
  maxCPM?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  poiRadius?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  venuesIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  publishersIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  poiIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  bundlesIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  sizesIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  daypartsIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  publishers?: Maybe<ScenarioUpdatepublishersInput>;
  poiCategories?: Maybe<ScenarioUpdatepoiCategoriesInput>;
  bundles?: Maybe<ScenarioUpdatebundlesInput>;
  sizes?: Maybe<ScenarioUpdatesizesInput>;
  plan?: Maybe<PlanUpdateOneRequiredWithoutScenariosInput>;
  venues?: Maybe<InventoryVenueUpdateManyWithoutScenarioInput>;
  daypart?: Maybe<ScenarioDaypartUpdateOneRequiredWithoutScenarioInput>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type EnumScenarioStatusFieldUpdateOperationsInput = {
  set?: Maybe<ScenarioStatus>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type ScenarioUpdatepublishersInput = {
  set: Array<Scalars['String']>;
};

export type ScenarioUpdatepoiCategoriesInput = {
  set: Array<Scalars['String']>;
};

export type ScenarioUpdatebundlesInput = {
  set: Array<Scalars['String']>;
};

export type ScenarioUpdatesizesInput = {
  set: Array<Scalars['String']>;
};

export type PlanUpdateOneRequiredWithoutScenariosInput = {
  create?: Maybe<PlanCreateWithoutScenariosInput>;
  connectOrCreate?: Maybe<PlanCreateOrConnectWithoutscenariosInput>;
  upsert?: Maybe<PlanUpsertWithoutScenariosInput>;
  connect?: Maybe<PlanWhereUniqueInput>;
  update?: Maybe<PlanUpdateWithoutScenariosInput>;
};

export type PlanUpsertWithoutScenariosInput = {
  update: PlanUpdateWithoutScenariosInput;
  create: PlanCreateWithoutScenariosInput;
};

export type PlanUpdateWithoutScenariosInput = {
  ownerEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumPlanStatusFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  geoType?: Maybe<EnumGeoTypeFieldUpdateOperationsInput>;
  geoBounds?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  archivedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  geoEntities?: Maybe<PlanUpdategeoEntitiesInput>;
  org?: Maybe<OrganizationUpdateOneRequiredWithoutPlansInput>;
  segment?: Maybe<SegmentUpdateOneRequiredWithoutPlansInput>;
  inventory?: Maybe<PlanInventoryUpdateManyWithoutPlanInput>;
  jobs?: Maybe<BigQueryJobUpdateManyWithoutPlanInput>;
  footraffic?: Maybe<FootTrafficUpdateManyWithoutPlanInput>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type EnumPlanStatusFieldUpdateOperationsInput = {
  set?: Maybe<PlanStatus>;
};

export type EnumGeoTypeFieldUpdateOperationsInput = {
  set?: Maybe<GeoType>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type PlanUpdategeoEntitiesInput = {
  set: Array<Scalars['String']>;
};

export type OrganizationUpdateOneRequiredWithoutPlansInput = {
  create?: Maybe<OrganizationCreateWithoutPlansInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutplansInput>;
  upsert?: Maybe<OrganizationUpsertWithoutPlansInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUpdateWithoutPlansInput>;
};

export type OrganizationUpsertWithoutPlansInput = {
  update: OrganizationUpdateWithoutPlansInput;
  create: OrganizationCreateWithoutPlansInput;
};

export type OrganizationUpdateWithoutPlansInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  users?: Maybe<UserUpdateManyWithoutPrimaryOrgInput>;
  segments?: Maybe<SegmentUpdateManyWithoutOrgInput>;
};

export type UserUpdateManyWithoutPrimaryOrgInput = {
  create?: Maybe<Array<UserCreateWithoutPrimaryOrgInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutprimaryOrgInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutPrimaryOrgInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutPrimaryOrgInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutPrimaryOrgInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpsertWithWhereUniqueWithoutPrimaryOrgInput = {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutPrimaryOrgInput;
  create: UserCreateWithoutPrimaryOrgInput;
};

export type UserUpdateWithoutPrimaryOrgInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateWithWhereUniqueWithoutPrimaryOrgInput = {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutPrimaryOrgInput;
};

export type UserUpdateManyWithWhereWithoutPrimaryOrgInput = {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
};

export type UserScalarWhereInput = {
  AND?: Maybe<Array<UserScalarWhereInput>>;
  OR?: Maybe<Array<UserScalarWhereInput>>;
  NOT?: Maybe<Array<UserScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  email?: Maybe<StringFilter>;
  primaryOrgId?: Maybe<StringFilter>;
};

export type UserUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
};

export type SegmentUpdateManyWithoutOrgInput = {
  create?: Maybe<Array<SegmentCreateWithoutOrgInput>>;
  connectOrCreate?: Maybe<Array<SegmentCreateOrConnectWithoutorgInput>>;
  upsert?: Maybe<Array<SegmentUpsertWithWhereUniqueWithoutOrgInput>>;
  connect?: Maybe<Array<SegmentWhereUniqueInput>>;
  set?: Maybe<Array<SegmentWhereUniqueInput>>;
  disconnect?: Maybe<Array<SegmentWhereUniqueInput>>;
  delete?: Maybe<Array<SegmentWhereUniqueInput>>;
  update?: Maybe<Array<SegmentUpdateWithWhereUniqueWithoutOrgInput>>;
  updateMany?: Maybe<Array<SegmentUpdateManyWithWhereWithoutOrgInput>>;
  deleteMany?: Maybe<Array<SegmentScalarWhereInput>>;
};

export type SegmentUpsertWithWhereUniqueWithoutOrgInput = {
  where: SegmentWhereUniqueInput;
  update: SegmentUpdateWithoutOrgInput;
  create: SegmentCreateWithoutOrgInput;
};

export type SegmentUpdateWithoutOrgInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  category?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumSegmentStatusFieldUpdateOperationsInput>;
  plans?: Maybe<PlanUpdateManyWithoutSegmentInput>;
};

export type EnumSegmentStatusFieldUpdateOperationsInput = {
  set?: Maybe<SegmentStatus>;
};

export type PlanUpdateManyWithoutSegmentInput = {
  create?: Maybe<Array<PlanCreateWithoutSegmentInput>>;
  connectOrCreate?: Maybe<Array<PlanCreateOrConnectWithoutsegmentInput>>;
  upsert?: Maybe<Array<PlanUpsertWithWhereUniqueWithoutSegmentInput>>;
  connect?: Maybe<Array<PlanWhereUniqueInput>>;
  set?: Maybe<Array<PlanWhereUniqueInput>>;
  disconnect?: Maybe<Array<PlanWhereUniqueInput>>;
  delete?: Maybe<Array<PlanWhereUniqueInput>>;
  update?: Maybe<Array<PlanUpdateWithWhereUniqueWithoutSegmentInput>>;
  updateMany?: Maybe<Array<PlanUpdateManyWithWhereWithoutSegmentInput>>;
  deleteMany?: Maybe<Array<PlanScalarWhereInput>>;
};

export type PlanUpsertWithWhereUniqueWithoutSegmentInput = {
  where: PlanWhereUniqueInput;
  update: PlanUpdateWithoutSegmentInput;
  create: PlanCreateWithoutSegmentInput;
};

export type PlanUpdateWithoutSegmentInput = {
  ownerEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumPlanStatusFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  geoType?: Maybe<EnumGeoTypeFieldUpdateOperationsInput>;
  geoBounds?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  archivedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  geoEntities?: Maybe<PlanUpdategeoEntitiesInput>;
  org?: Maybe<OrganizationUpdateOneRequiredWithoutPlansInput>;
  scenarios?: Maybe<ScenarioUpdateManyWithoutPlanInput>;
  inventory?: Maybe<PlanInventoryUpdateManyWithoutPlanInput>;
  jobs?: Maybe<BigQueryJobUpdateManyWithoutPlanInput>;
  footraffic?: Maybe<FootTrafficUpdateManyWithoutPlanInput>;
};

export type ScenarioUpdateManyWithoutPlanInput = {
  create?: Maybe<Array<ScenarioCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<ScenarioCreateOrConnectWithoutplanInput>>;
  upsert?: Maybe<Array<ScenarioUpsertWithWhereUniqueWithoutPlanInput>>;
  connect?: Maybe<Array<ScenarioWhereUniqueInput>>;
  set?: Maybe<Array<ScenarioWhereUniqueInput>>;
  disconnect?: Maybe<Array<ScenarioWhereUniqueInput>>;
  delete?: Maybe<Array<ScenarioWhereUniqueInput>>;
  update?: Maybe<Array<ScenarioUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<ScenarioUpdateManyWithWhereWithoutPlanInput>>;
  deleteMany?: Maybe<Array<ScenarioScalarWhereInput>>;
};

export type ScenarioUpsertWithWhereUniqueWithoutPlanInput = {
  where: ScenarioWhereUniqueInput;
  update: ScenarioUpdateWithoutPlanInput;
  create: ScenarioCreateWithoutPlanInput;
};

export type ScenarioUpdateWithoutPlanInput = {
  title?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumScenarioStatusFieldUpdateOperationsInput>;
  maxCPM?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  poiRadius?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  venuesIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  publishersIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  poiIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  bundlesIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  sizesIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  daypartsIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  publishers?: Maybe<ScenarioUpdatepublishersInput>;
  poiCategories?: Maybe<ScenarioUpdatepoiCategoriesInput>;
  bundles?: Maybe<ScenarioUpdatebundlesInput>;
  sizes?: Maybe<ScenarioUpdatesizesInput>;
  venues?: Maybe<InventoryVenueUpdateManyWithoutScenarioInput>;
  daypart?: Maybe<ScenarioDaypartUpdateOneRequiredWithoutScenarioInput>;
};

export type InventoryVenueUpdateManyWithoutScenarioInput = {
  create?: Maybe<Array<InventoryVenueCreateWithoutScenarioInput>>;
  connectOrCreate?: Maybe<Array<InventoryVenueCreateOrConnectWithoutScenarioInput>>;
  upsert?: Maybe<Array<InventoryVenueUpsertWithWhereUniqueWithoutScenarioInput>>;
  connect?: Maybe<Array<InventoryVenueWhereUniqueInput>>;
  set?: Maybe<Array<InventoryVenueWhereUniqueInput>>;
  disconnect?: Maybe<Array<InventoryVenueWhereUniqueInput>>;
  delete?: Maybe<Array<InventoryVenueWhereUniqueInput>>;
  update?: Maybe<Array<InventoryVenueUpdateWithWhereUniqueWithoutScenarioInput>>;
  updateMany?: Maybe<Array<InventoryVenueUpdateManyWithWhereWithoutScenarioInput>>;
  deleteMany?: Maybe<Array<InventoryVenueScalarWhereInput>>;
};

export type InventoryVenueUpsertWithWhereUniqueWithoutScenarioInput = {
  where: InventoryVenueWhereUniqueInput;
  update: InventoryVenueUpdateWithoutScenarioInput;
  create: InventoryVenueCreateWithoutScenarioInput;
};

export type InventoryVenueUpdateWithoutScenarioInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  doohId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stringList?: Maybe<NullableStringFieldUpdateOperationsInput>;
  PlanInventory?: Maybe<PlanInventoryUpdateManyWithoutVenueInput>;
};

export type PlanInventoryUpdateManyWithoutVenueInput = {
  create?: Maybe<Array<PlanInventoryCreateWithoutVenueInput>>;
  connectOrCreate?: Maybe<Array<PlanInventoryCreateOrConnectWithoutvenueInput>>;
  upsert?: Maybe<Array<PlanInventoryUpsertWithWhereUniqueWithoutVenueInput>>;
  connect?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
  set?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
  delete?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
  update?: Maybe<Array<PlanInventoryUpdateWithWhereUniqueWithoutVenueInput>>;
  updateMany?: Maybe<Array<PlanInventoryUpdateManyWithWhereWithoutVenueInput>>;
  deleteMany?: Maybe<Array<PlanInventoryScalarWhereInput>>;
};

export type PlanInventoryUpsertWithWhereUniqueWithoutVenueInput = {
  where: PlanInventoryWhereUniqueInput;
  update: PlanInventoryUpdateWithoutVenueInput;
  create: PlanInventoryCreateWithoutVenueInput;
};

export type PlanInventoryUpdateWithoutVenueInput = {
  adUnitId?: Maybe<StringFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lon?: Maybe<FloatFieldUpdateOperationsInput>;
  state?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  zipCode?: Maybe<StringFieldUpdateOperationsInput>;
  publisher?: Maybe<StringFieldUpdateOperationsInput>;
  bundle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  impressionsPerHour?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  orientation?: Maybe<PlanInventoryUpdateorientationInput>;
  plan?: Maybe<PlanUpdateOneRequiredWithoutInventoryInput>;
  bigQueryJob?: Maybe<BigQueryJobUpdateOneRequiredWithoutPlanInventoryInput>;
};

export type FloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type PlanInventoryUpdateorientationInput = {
  set: Array<AdUnitOrientation>;
};

export type PlanUpdateOneRequiredWithoutInventoryInput = {
  create?: Maybe<PlanCreateWithoutInventoryInput>;
  connectOrCreate?: Maybe<PlanCreateOrConnectWithoutinventoryInput>;
  upsert?: Maybe<PlanUpsertWithoutInventoryInput>;
  connect?: Maybe<PlanWhereUniqueInput>;
  update?: Maybe<PlanUpdateWithoutInventoryInput>;
};

export type PlanUpsertWithoutInventoryInput = {
  update: PlanUpdateWithoutInventoryInput;
  create: PlanCreateWithoutInventoryInput;
};

export type PlanUpdateWithoutInventoryInput = {
  ownerEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumPlanStatusFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  geoType?: Maybe<EnumGeoTypeFieldUpdateOperationsInput>;
  geoBounds?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  archivedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  geoEntities?: Maybe<PlanUpdategeoEntitiesInput>;
  org?: Maybe<OrganizationUpdateOneRequiredWithoutPlansInput>;
  segment?: Maybe<SegmentUpdateOneRequiredWithoutPlansInput>;
  scenarios?: Maybe<ScenarioUpdateManyWithoutPlanInput>;
  jobs?: Maybe<BigQueryJobUpdateManyWithoutPlanInput>;
  footraffic?: Maybe<FootTrafficUpdateManyWithoutPlanInput>;
};

export type SegmentUpdateOneRequiredWithoutPlansInput = {
  create?: Maybe<SegmentCreateWithoutPlansInput>;
  connectOrCreate?: Maybe<SegmentCreateOrConnectWithoutplansInput>;
  upsert?: Maybe<SegmentUpsertWithoutPlansInput>;
  connect?: Maybe<SegmentWhereUniqueInput>;
  update?: Maybe<SegmentUpdateWithoutPlansInput>;
};

export type SegmentUpsertWithoutPlansInput = {
  update: SegmentUpdateWithoutPlansInput;
  create: SegmentCreateWithoutPlansInput;
};

export type SegmentUpdateWithoutPlansInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  category?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumSegmentStatusFieldUpdateOperationsInput>;
  org?: Maybe<OrganizationUpdateOneRequiredWithoutSegmentsInput>;
};

export type OrganizationUpdateOneRequiredWithoutSegmentsInput = {
  create?: Maybe<OrganizationCreateWithoutSegmentsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutsegmentsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutSegmentsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUpdateWithoutSegmentsInput>;
};

export type OrganizationUpsertWithoutSegmentsInput = {
  update: OrganizationUpdateWithoutSegmentsInput;
  create: OrganizationCreateWithoutSegmentsInput;
};

export type OrganizationUpdateWithoutSegmentsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  plans?: Maybe<PlanUpdateManyWithoutOrgInput>;
  users?: Maybe<UserUpdateManyWithoutPrimaryOrgInput>;
};

export type PlanUpdateManyWithoutOrgInput = {
  create?: Maybe<Array<PlanCreateWithoutOrgInput>>;
  connectOrCreate?: Maybe<Array<PlanCreateOrConnectWithoutorgInput>>;
  upsert?: Maybe<Array<PlanUpsertWithWhereUniqueWithoutOrgInput>>;
  connect?: Maybe<Array<PlanWhereUniqueInput>>;
  set?: Maybe<Array<PlanWhereUniqueInput>>;
  disconnect?: Maybe<Array<PlanWhereUniqueInput>>;
  delete?: Maybe<Array<PlanWhereUniqueInput>>;
  update?: Maybe<Array<PlanUpdateWithWhereUniqueWithoutOrgInput>>;
  updateMany?: Maybe<Array<PlanUpdateManyWithWhereWithoutOrgInput>>;
  deleteMany?: Maybe<Array<PlanScalarWhereInput>>;
};

export type PlanUpsertWithWhereUniqueWithoutOrgInput = {
  where: PlanWhereUniqueInput;
  update: PlanUpdateWithoutOrgInput;
  create: PlanCreateWithoutOrgInput;
};

export type PlanUpdateWithoutOrgInput = {
  ownerEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumPlanStatusFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  geoType?: Maybe<EnumGeoTypeFieldUpdateOperationsInput>;
  geoBounds?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  archivedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  geoEntities?: Maybe<PlanUpdategeoEntitiesInput>;
  segment?: Maybe<SegmentUpdateOneRequiredWithoutPlansInput>;
  scenarios?: Maybe<ScenarioUpdateManyWithoutPlanInput>;
  inventory?: Maybe<PlanInventoryUpdateManyWithoutPlanInput>;
  jobs?: Maybe<BigQueryJobUpdateManyWithoutPlanInput>;
  footraffic?: Maybe<FootTrafficUpdateManyWithoutPlanInput>;
};

export type PlanInventoryUpdateManyWithoutPlanInput = {
  create?: Maybe<Array<PlanInventoryCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<PlanInventoryCreateOrConnectWithoutplanInput>>;
  upsert?: Maybe<Array<PlanInventoryUpsertWithWhereUniqueWithoutPlanInput>>;
  connect?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
  set?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
  delete?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
  update?: Maybe<Array<PlanInventoryUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<PlanInventoryUpdateManyWithWhereWithoutPlanInput>>;
  deleteMany?: Maybe<Array<PlanInventoryScalarWhereInput>>;
};

export type PlanInventoryUpsertWithWhereUniqueWithoutPlanInput = {
  where: PlanInventoryWhereUniqueInput;
  update: PlanInventoryUpdateWithoutPlanInput;
  create: PlanInventoryCreateWithoutPlanInput;
};

export type PlanInventoryUpdateWithoutPlanInput = {
  adUnitId?: Maybe<StringFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lon?: Maybe<FloatFieldUpdateOperationsInput>;
  state?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  zipCode?: Maybe<StringFieldUpdateOperationsInput>;
  publisher?: Maybe<StringFieldUpdateOperationsInput>;
  bundle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  impressionsPerHour?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  orientation?: Maybe<PlanInventoryUpdateorientationInput>;
  venue?: Maybe<InventoryVenueUpdateOneWithoutPlanInventoryInput>;
  bigQueryJob?: Maybe<BigQueryJobUpdateOneRequiredWithoutPlanInventoryInput>;
};

export type InventoryVenueUpdateOneWithoutPlanInventoryInput = {
  create?: Maybe<InventoryVenueCreateWithoutPlanInventoryInput>;
  connectOrCreate?: Maybe<InventoryVenueCreateOrConnectWithoutPlanInventoryInput>;
  upsert?: Maybe<InventoryVenueUpsertWithoutPlanInventoryInput>;
  connect?: Maybe<InventoryVenueWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<InventoryVenueUpdateWithoutPlanInventoryInput>;
};

export type InventoryVenueUpsertWithoutPlanInventoryInput = {
  update: InventoryVenueUpdateWithoutPlanInventoryInput;
  create: InventoryVenueCreateWithoutPlanInventoryInput;
};

export type InventoryVenueUpdateWithoutPlanInventoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  doohId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stringList?: Maybe<NullableStringFieldUpdateOperationsInput>;
  Scenario?: Maybe<ScenarioUpdateOneWithoutVenuesInput>;
};

export type ScenarioUpdateOneWithoutVenuesInput = {
  create?: Maybe<ScenarioCreateWithoutVenuesInput>;
  connectOrCreate?: Maybe<ScenarioCreateOrConnectWithoutvenuesInput>;
  upsert?: Maybe<ScenarioUpsertWithoutVenuesInput>;
  connect?: Maybe<ScenarioWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<ScenarioUpdateWithoutVenuesInput>;
};

export type ScenarioUpsertWithoutVenuesInput = {
  update: ScenarioUpdateWithoutVenuesInput;
  create: ScenarioCreateWithoutVenuesInput;
};

export type ScenarioUpdateWithoutVenuesInput = {
  title?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumScenarioStatusFieldUpdateOperationsInput>;
  maxCPM?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  poiRadius?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  venuesIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  publishersIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  poiIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  bundlesIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  sizesIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  daypartsIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  publishers?: Maybe<ScenarioUpdatepublishersInput>;
  poiCategories?: Maybe<ScenarioUpdatepoiCategoriesInput>;
  bundles?: Maybe<ScenarioUpdatebundlesInput>;
  sizes?: Maybe<ScenarioUpdatesizesInput>;
  plan?: Maybe<PlanUpdateOneRequiredWithoutScenariosInput>;
  daypart?: Maybe<ScenarioDaypartUpdateOneRequiredWithoutScenarioInput>;
};

export type ScenarioDaypartUpdateOneRequiredWithoutScenarioInput = {
  create?: Maybe<ScenarioDaypartCreateWithoutScenarioInput>;
  connectOrCreate?: Maybe<ScenarioDaypartCreateOrConnectWithoutscenarioInput>;
  upsert?: Maybe<ScenarioDaypartUpsertWithoutScenarioInput>;
  connect?: Maybe<ScenarioDaypartWhereUniqueInput>;
  update?: Maybe<ScenarioDaypartUpdateWithoutScenarioInput>;
};

export type ScenarioDaypartUpsertWithoutScenarioInput = {
  update: ScenarioDaypartUpdateWithoutScenarioInput;
  create: ScenarioDaypartCreateWithoutScenarioInput;
};

export type ScenarioDaypartUpdateWithoutScenarioInput = {
  sunday?: Maybe<ScenarioDaypartUpdatesundayInput>;
  monday?: Maybe<ScenarioDaypartUpdatemondayInput>;
  tuesday?: Maybe<ScenarioDaypartUpdatetuesdayInput>;
  wednesday?: Maybe<ScenarioDaypartUpdatewednesdayInput>;
  thursday?: Maybe<ScenarioDaypartUpdatethursdayInput>;
  friday?: Maybe<ScenarioDaypartUpdatefridayInput>;
  saturday?: Maybe<ScenarioDaypartUpdatesaturdayInput>;
};

export type ScenarioDaypartUpdatesundayInput = {
  set: Array<Scalars['Int']>;
};

export type ScenarioDaypartUpdatemondayInput = {
  set: Array<Scalars['Int']>;
};

export type ScenarioDaypartUpdatetuesdayInput = {
  set: Array<Scalars['Int']>;
};

export type ScenarioDaypartUpdatewednesdayInput = {
  set: Array<Scalars['Int']>;
};

export type ScenarioDaypartUpdatethursdayInput = {
  set: Array<Scalars['Int']>;
};

export type ScenarioDaypartUpdatefridayInput = {
  set: Array<Scalars['Int']>;
};

export type ScenarioDaypartUpdatesaturdayInput = {
  set: Array<Scalars['Int']>;
};

export type BigQueryJobUpdateOneRequiredWithoutPlanInventoryInput = {
  create?: Maybe<BigQueryJobCreateWithoutPlanInventoryInput>;
  connectOrCreate?: Maybe<BigQueryJobCreateOrConnectWithoutPlanInventoryInput>;
  upsert?: Maybe<BigQueryJobUpsertWithoutPlanInventoryInput>;
  connect?: Maybe<BigQueryJobWhereUniqueInput>;
  update?: Maybe<BigQueryJobUpdateWithoutPlanInventoryInput>;
};

export type BigQueryJobUpsertWithoutPlanInventoryInput = {
  update: BigQueryJobUpdateWithoutPlanInventoryInput;
  create: BigQueryJobCreateWithoutPlanInventoryInput;
};

export type BigQueryJobUpdateWithoutPlanInventoryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumBigQueryJobStateFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  startedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  processedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  processedBy?: Maybe<NullableEnumJobProcessorFieldUpdateOperationsInput>;
  totalBytesProcessed?: Maybe<NullableStringFieldUpdateOperationsInput>;
  totalBytesBilled?: Maybe<NullableStringFieldUpdateOperationsInput>;
  totalMiliseconds?: Maybe<NullableStringFieldUpdateOperationsInput>;
  errorMessage?: Maybe<Scalars['JSON']>;
  jobMetadata?: Maybe<Scalars['JSON']>;
  jobType?: Maybe<EnumBigQueryJobTypeFieldUpdateOperationsInput>;
  plan?: Maybe<PlanUpdateOneRequiredWithoutJobsInput>;
  InventoryDaypartMetric?: Maybe<InventoryDaypartMetricUpdateManyWithoutBigQueryJobInput>;
};

export type EnumBigQueryJobStateFieldUpdateOperationsInput = {
  set?: Maybe<BigQueryJobState>;
};

export type NullableEnumJobProcessorFieldUpdateOperationsInput = {
  set?: Maybe<JobProcessor>;
};

export type EnumBigQueryJobTypeFieldUpdateOperationsInput = {
  set?: Maybe<BigQueryJobType>;
};

export type PlanUpdateOneRequiredWithoutJobsInput = {
  create?: Maybe<PlanCreateWithoutJobsInput>;
  connectOrCreate?: Maybe<PlanCreateOrConnectWithoutjobsInput>;
  upsert?: Maybe<PlanUpsertWithoutJobsInput>;
  connect?: Maybe<PlanWhereUniqueInput>;
  update?: Maybe<PlanUpdateWithoutJobsInput>;
};

export type PlanUpsertWithoutJobsInput = {
  update: PlanUpdateWithoutJobsInput;
  create: PlanCreateWithoutJobsInput;
};

export type PlanUpdateWithoutJobsInput = {
  ownerEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumPlanStatusFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  geoType?: Maybe<EnumGeoTypeFieldUpdateOperationsInput>;
  geoBounds?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  archivedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  geoEntities?: Maybe<PlanUpdategeoEntitiesInput>;
  org?: Maybe<OrganizationUpdateOneRequiredWithoutPlansInput>;
  segment?: Maybe<SegmentUpdateOneRequiredWithoutPlansInput>;
  scenarios?: Maybe<ScenarioUpdateManyWithoutPlanInput>;
  inventory?: Maybe<PlanInventoryUpdateManyWithoutPlanInput>;
  footraffic?: Maybe<FootTrafficUpdateManyWithoutPlanInput>;
};

export type FootTrafficUpdateManyWithoutPlanInput = {
  create?: Maybe<Array<FootTrafficCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<FootTrafficCreateOrConnectWithoutplanInput>>;
  upsert?: Maybe<Array<FootTrafficUpsertWithWhereUniqueWithoutPlanInput>>;
  connect?: Maybe<Array<FootTrafficWhereUniqueInput>>;
  set?: Maybe<Array<FootTrafficWhereUniqueInput>>;
  disconnect?: Maybe<Array<FootTrafficWhereUniqueInput>>;
  delete?: Maybe<Array<FootTrafficWhereUniqueInput>>;
  update?: Maybe<Array<FootTrafficUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<FootTrafficUpdateManyWithWhereWithoutPlanInput>>;
  deleteMany?: Maybe<Array<FootTrafficScalarWhereInput>>;
};

export type FootTrafficUpsertWithWhereUniqueWithoutPlanInput = {
  where: FootTrafficWhereUniqueInput;
  update: FootTrafficUpdateWithoutPlanInput;
  create: FootTrafficCreateWithoutPlanInput;
};

export type FootTrafficUpdateWithoutPlanInput = {
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lon?: Maybe<FloatFieldUpdateOperationsInput>;
  count?: Maybe<IntFieldUpdateOperationsInput>;
};

export type IntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type FootTrafficUpdateWithWhereUniqueWithoutPlanInput = {
  where: FootTrafficWhereUniqueInput;
  data: FootTrafficUpdateWithoutPlanInput;
};

export type FootTrafficUpdateManyWithWhereWithoutPlanInput = {
  where: FootTrafficScalarWhereInput;
  data: FootTrafficUpdateManyMutationInput;
};

export type FootTrafficScalarWhereInput = {
  AND?: Maybe<Array<FootTrafficScalarWhereInput>>;
  OR?: Maybe<Array<FootTrafficScalarWhereInput>>;
  NOT?: Maybe<Array<FootTrafficScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  planId?: Maybe<IntFilter>;
  lat?: Maybe<FloatFilter>;
  lon?: Maybe<FloatFilter>;
  count?: Maybe<IntFilter>;
};

export type FootTrafficUpdateManyMutationInput = {
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lon?: Maybe<FloatFieldUpdateOperationsInput>;
  count?: Maybe<IntFieldUpdateOperationsInput>;
};

export type InventoryDaypartMetricUpdateManyWithoutBigQueryJobInput = {
  create?: Maybe<Array<InventoryDaypartMetricCreateWithoutBigQueryJobInput>>;
  connectOrCreate?: Maybe<Array<InventoryDaypartMetricCreateOrConnectWithoutbigQueryJobInput>>;
  upsert?: Maybe<Array<InventoryDaypartMetricUpsertWithWhereUniqueWithoutBigQueryJobInput>>;
  connect?: Maybe<Array<InventoryDaypartMetricWhereUniqueInput>>;
  set?: Maybe<Array<InventoryDaypartMetricWhereUniqueInput>>;
  disconnect?: Maybe<Array<InventoryDaypartMetricWhereUniqueInput>>;
  delete?: Maybe<Array<InventoryDaypartMetricWhereUniqueInput>>;
  update?: Maybe<Array<InventoryDaypartMetricUpdateWithWhereUniqueWithoutBigQueryJobInput>>;
  updateMany?: Maybe<Array<InventoryDaypartMetricUpdateManyWithWhereWithoutBigQueryJobInput>>;
  deleteMany?: Maybe<Array<InventoryDaypartMetricScalarWhereInput>>;
};

export type InventoryDaypartMetricUpsertWithWhereUniqueWithoutBigQueryJobInput = {
  where: InventoryDaypartMetricWhereUniqueInput;
  update: InventoryDaypartMetricUpdateWithoutBigQueryJobInput;
  create: InventoryDaypartMetricCreateWithoutBigQueryJobInput;
};

export type InventoryDaypartMetricUpdateWithoutBigQueryJobInput = {
  adUnitId?: Maybe<StringFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  totalImpressions?: Maybe<IntFieldUpdateOperationsInput>;
  impressionsPerHour?: Maybe<InventoryDaypartMetricUpdateimpressionsPerHourInput>;
  segments?: Maybe<InventorySegmentDaypartMetricUpdateManyWithoutDaypartImpressionInput>;
};

export type InventoryDaypartMetricUpdateimpressionsPerHourInput = {
  set: Array<Scalars['Int']>;
};

export type InventorySegmentDaypartMetricUpdateManyWithoutDaypartImpressionInput = {
  create?: Maybe<Array<InventorySegmentDaypartMetricCreateWithoutDaypartImpressionInput>>;
  connectOrCreate?: Maybe<Array<InventorySegmentDaypartMetricCreateOrConnectWithoutdaypartImpressionInput>>;
  upsert?: Maybe<Array<InventorySegmentDaypartMetricUpsertWithWhereUniqueWithoutDaypartImpressionInput>>;
  connect?: Maybe<Array<InventorySegmentDaypartMetricWhereUniqueInput>>;
  set?: Maybe<Array<InventorySegmentDaypartMetricWhereUniqueInput>>;
  disconnect?: Maybe<Array<InventorySegmentDaypartMetricWhereUniqueInput>>;
  delete?: Maybe<Array<InventorySegmentDaypartMetricWhereUniqueInput>>;
  update?: Maybe<Array<InventorySegmentDaypartMetricUpdateWithWhereUniqueWithoutDaypartImpressionInput>>;
  updateMany?: Maybe<Array<InventorySegmentDaypartMetricUpdateManyWithWhereWithoutDaypartImpressionInput>>;
  deleteMany?: Maybe<Array<InventorySegmentDaypartMetricScalarWhereInput>>;
};

export type InventorySegmentDaypartMetricUpsertWithWhereUniqueWithoutDaypartImpressionInput = {
  where: InventorySegmentDaypartMetricWhereUniqueInput;
  update: InventorySegmentDaypartMetricUpdateWithoutDaypartImpressionInput;
  create: InventorySegmentDaypartMetricCreateWithoutDaypartImpressionInput;
};

export type InventorySegmentDaypartMetricUpdateWithoutDaypartImpressionInput = {
  totalImpressions?: Maybe<IntFieldUpdateOperationsInput>;
  segmentId?: Maybe<StringFieldUpdateOperationsInput>;
  impressionsPerHour?: Maybe<InventorySegmentDaypartMetricUpdateimpressionsPerHourInput>;
};

export type InventorySegmentDaypartMetricUpdateimpressionsPerHourInput = {
  set: Array<Scalars['Int']>;
};

export type InventorySegmentDaypartMetricUpdateWithWhereUniqueWithoutDaypartImpressionInput = {
  where: InventorySegmentDaypartMetricWhereUniqueInput;
  data: InventorySegmentDaypartMetricUpdateWithoutDaypartImpressionInput;
};

export type InventorySegmentDaypartMetricUpdateManyWithWhereWithoutDaypartImpressionInput = {
  where: InventorySegmentDaypartMetricScalarWhereInput;
  data: InventorySegmentDaypartMetricUpdateManyMutationInput;
};

export type InventorySegmentDaypartMetricScalarWhereInput = {
  AND?: Maybe<Array<InventorySegmentDaypartMetricScalarWhereInput>>;
  OR?: Maybe<Array<InventorySegmentDaypartMetricScalarWhereInput>>;
  NOT?: Maybe<Array<InventorySegmentDaypartMetricScalarWhereInput>>;
  adUnitId?: Maybe<StringFilter>;
  date?: Maybe<DateTimeFilter>;
  impressionsPerHour?: Maybe<IntNullableListFilter>;
  totalImpressions?: Maybe<IntFilter>;
  segmentId?: Maybe<StringFilter>;
};

export type InventorySegmentDaypartMetricUpdateManyMutationInput = {
  totalImpressions?: Maybe<IntFieldUpdateOperationsInput>;
  segmentId?: Maybe<StringFieldUpdateOperationsInput>;
  impressionsPerHour?: Maybe<InventorySegmentDaypartMetricUpdateimpressionsPerHourInput>;
};

export type InventoryDaypartMetricUpdateWithWhereUniqueWithoutBigQueryJobInput = {
  where: InventoryDaypartMetricWhereUniqueInput;
  data: InventoryDaypartMetricUpdateWithoutBigQueryJobInput;
};

export type InventoryDaypartMetricUpdateManyWithWhereWithoutBigQueryJobInput = {
  where: InventoryDaypartMetricScalarWhereInput;
  data: InventoryDaypartMetricUpdateManyMutationInput;
};

export type InventoryDaypartMetricScalarWhereInput = {
  AND?: Maybe<Array<InventoryDaypartMetricScalarWhereInput>>;
  OR?: Maybe<Array<InventoryDaypartMetricScalarWhereInput>>;
  NOT?: Maybe<Array<InventoryDaypartMetricScalarWhereInput>>;
  adUnitId?: Maybe<StringFilter>;
  date?: Maybe<DateTimeFilter>;
  impressionsPerHour?: Maybe<IntNullableListFilter>;
  totalImpressions?: Maybe<IntFilter>;
  bigQueryJobId?: Maybe<StringFilter>;
};

export type InventoryDaypartMetricUpdateManyMutationInput = {
  adUnitId?: Maybe<StringFieldUpdateOperationsInput>;
  date?: Maybe<DateTimeFieldUpdateOperationsInput>;
  totalImpressions?: Maybe<IntFieldUpdateOperationsInput>;
  impressionsPerHour?: Maybe<InventoryDaypartMetricUpdateimpressionsPerHourInput>;
};

export type PlanInventoryUpdateWithWhereUniqueWithoutPlanInput = {
  where: PlanInventoryWhereUniqueInput;
  data: PlanInventoryUpdateWithoutPlanInput;
};

export type PlanInventoryUpdateManyWithWhereWithoutPlanInput = {
  where: PlanInventoryScalarWhereInput;
  data: PlanInventoryUpdateManyMutationInput;
};

export type PlanInventoryScalarWhereInput = {
  AND?: Maybe<Array<PlanInventoryScalarWhereInput>>;
  OR?: Maybe<Array<PlanInventoryScalarWhereInput>>;
  NOT?: Maybe<Array<PlanInventoryScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  adUnitId?: Maybe<StringFilter>;
  lat?: Maybe<FloatFilter>;
  lon?: Maybe<FloatFilter>;
  state?: Maybe<StringFilter>;
  city?: Maybe<StringFilter>;
  zipCode?: Maybe<StringFilter>;
  planId?: Maybe<IntFilter>;
  publisher?: Maybe<StringFilter>;
  bundle?: Maybe<StringNullableFilter>;
  impressionsPerHour?: Maybe<IntNullableFilter>;
  orientation?: Maybe<EnumAdUnitOrientationNullableListFilter>;
  venueId?: Maybe<StringNullableFilter>;
  bigQueryJobId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type PlanInventoryUpdateManyMutationInput = {
  adUnitId?: Maybe<StringFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lon?: Maybe<FloatFieldUpdateOperationsInput>;
  state?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  zipCode?: Maybe<StringFieldUpdateOperationsInput>;
  publisher?: Maybe<StringFieldUpdateOperationsInput>;
  bundle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  impressionsPerHour?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  orientation?: Maybe<PlanInventoryUpdateorientationInput>;
};

export type BigQueryJobUpdateManyWithoutPlanInput = {
  create?: Maybe<Array<BigQueryJobCreateWithoutPlanInput>>;
  connectOrCreate?: Maybe<Array<BigQueryJobCreateOrConnectWithoutplanInput>>;
  upsert?: Maybe<Array<BigQueryJobUpsertWithWhereUniqueWithoutPlanInput>>;
  connect?: Maybe<Array<BigQueryJobWhereUniqueInput>>;
  set?: Maybe<Array<BigQueryJobWhereUniqueInput>>;
  disconnect?: Maybe<Array<BigQueryJobWhereUniqueInput>>;
  delete?: Maybe<Array<BigQueryJobWhereUniqueInput>>;
  update?: Maybe<Array<BigQueryJobUpdateWithWhereUniqueWithoutPlanInput>>;
  updateMany?: Maybe<Array<BigQueryJobUpdateManyWithWhereWithoutPlanInput>>;
  deleteMany?: Maybe<Array<BigQueryJobScalarWhereInput>>;
};

export type BigQueryJobUpsertWithWhereUniqueWithoutPlanInput = {
  where: BigQueryJobWhereUniqueInput;
  update: BigQueryJobUpdateWithoutPlanInput;
  create: BigQueryJobCreateWithoutPlanInput;
};

export type BigQueryJobUpdateWithoutPlanInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumBigQueryJobStateFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  startedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  processedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  processedBy?: Maybe<NullableEnumJobProcessorFieldUpdateOperationsInput>;
  totalBytesProcessed?: Maybe<NullableStringFieldUpdateOperationsInput>;
  totalBytesBilled?: Maybe<NullableStringFieldUpdateOperationsInput>;
  totalMiliseconds?: Maybe<NullableStringFieldUpdateOperationsInput>;
  errorMessage?: Maybe<Scalars['JSON']>;
  jobMetadata?: Maybe<Scalars['JSON']>;
  jobType?: Maybe<EnumBigQueryJobTypeFieldUpdateOperationsInput>;
  PlanInventory?: Maybe<PlanInventoryUpdateManyWithoutBigQueryJobInput>;
  InventoryDaypartMetric?: Maybe<InventoryDaypartMetricUpdateManyWithoutBigQueryJobInput>;
};

export type PlanInventoryUpdateManyWithoutBigQueryJobInput = {
  create?: Maybe<Array<PlanInventoryCreateWithoutBigQueryJobInput>>;
  connectOrCreate?: Maybe<Array<PlanInventoryCreateOrConnectWithoutbigQueryJobInput>>;
  upsert?: Maybe<Array<PlanInventoryUpsertWithWhereUniqueWithoutBigQueryJobInput>>;
  connect?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
  set?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
  disconnect?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
  delete?: Maybe<Array<PlanInventoryWhereUniqueInput>>;
  update?: Maybe<Array<PlanInventoryUpdateWithWhereUniqueWithoutBigQueryJobInput>>;
  updateMany?: Maybe<Array<PlanInventoryUpdateManyWithWhereWithoutBigQueryJobInput>>;
  deleteMany?: Maybe<Array<PlanInventoryScalarWhereInput>>;
};

export type PlanInventoryUpsertWithWhereUniqueWithoutBigQueryJobInput = {
  where: PlanInventoryWhereUniqueInput;
  update: PlanInventoryUpdateWithoutBigQueryJobInput;
  create: PlanInventoryCreateWithoutBigQueryJobInput;
};

export type PlanInventoryUpdateWithoutBigQueryJobInput = {
  adUnitId?: Maybe<StringFieldUpdateOperationsInput>;
  lat?: Maybe<FloatFieldUpdateOperationsInput>;
  lon?: Maybe<FloatFieldUpdateOperationsInput>;
  state?: Maybe<StringFieldUpdateOperationsInput>;
  city?: Maybe<StringFieldUpdateOperationsInput>;
  zipCode?: Maybe<StringFieldUpdateOperationsInput>;
  publisher?: Maybe<StringFieldUpdateOperationsInput>;
  bundle?: Maybe<NullableStringFieldUpdateOperationsInput>;
  impressionsPerHour?: Maybe<NullableIntFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  orientation?: Maybe<PlanInventoryUpdateorientationInput>;
  plan?: Maybe<PlanUpdateOneRequiredWithoutInventoryInput>;
  venue?: Maybe<InventoryVenueUpdateOneWithoutPlanInventoryInput>;
};

export type PlanInventoryUpdateWithWhereUniqueWithoutBigQueryJobInput = {
  where: PlanInventoryWhereUniqueInput;
  data: PlanInventoryUpdateWithoutBigQueryJobInput;
};

export type PlanInventoryUpdateManyWithWhereWithoutBigQueryJobInput = {
  where: PlanInventoryScalarWhereInput;
  data: PlanInventoryUpdateManyMutationInput;
};

export type BigQueryJobUpdateWithWhereUniqueWithoutPlanInput = {
  where: BigQueryJobWhereUniqueInput;
  data: BigQueryJobUpdateWithoutPlanInput;
};

export type BigQueryJobUpdateManyWithWhereWithoutPlanInput = {
  where: BigQueryJobScalarWhereInput;
  data: BigQueryJobUpdateManyMutationInput;
};

export type BigQueryJobScalarWhereInput = {
  AND?: Maybe<Array<BigQueryJobScalarWhereInput>>;
  OR?: Maybe<Array<BigQueryJobScalarWhereInput>>;
  NOT?: Maybe<Array<BigQueryJobScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  status?: Maybe<EnumBigQueryJobStateFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  startedAt?: Maybe<DateTimeNullableFilter>;
  completedAt?: Maybe<DateTimeNullableFilter>;
  processedAt?: Maybe<DateTimeNullableFilter>;
  processedBy?: Maybe<EnumJobProcessorNullableFilter>;
  totalBytesProcessed?: Maybe<StringNullableFilter>;
  totalBytesBilled?: Maybe<StringNullableFilter>;
  totalMiliseconds?: Maybe<StringNullableFilter>;
  errorMessage?: Maybe<JsonNullableFilter>;
  jobMetadata?: Maybe<JsonNullableFilter>;
  planId?: Maybe<IntFilter>;
  jobType?: Maybe<EnumBigQueryJobTypeFilter>;
};

export type BigQueryJobUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumBigQueryJobStateFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  startedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  completedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  processedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  processedBy?: Maybe<NullableEnumJobProcessorFieldUpdateOperationsInput>;
  totalBytesProcessed?: Maybe<NullableStringFieldUpdateOperationsInput>;
  totalBytesBilled?: Maybe<NullableStringFieldUpdateOperationsInput>;
  totalMiliseconds?: Maybe<NullableStringFieldUpdateOperationsInput>;
  errorMessage?: Maybe<Scalars['JSON']>;
  jobMetadata?: Maybe<Scalars['JSON']>;
  jobType?: Maybe<EnumBigQueryJobTypeFieldUpdateOperationsInput>;
};

export type PlanUpdateWithWhereUniqueWithoutOrgInput = {
  where: PlanWhereUniqueInput;
  data: PlanUpdateWithoutOrgInput;
};

export type PlanUpdateManyWithWhereWithoutOrgInput = {
  where: PlanScalarWhereInput;
  data: PlanUpdateManyMutationInput;
};

export type PlanScalarWhereInput = {
  AND?: Maybe<Array<PlanScalarWhereInput>>;
  OR?: Maybe<Array<PlanScalarWhereInput>>;
  NOT?: Maybe<Array<PlanScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  ownerEmail?: Maybe<StringNullableFilter>;
  orgId?: Maybe<StringFilter>;
  title?: Maybe<StringFilter>;
  status?: Maybe<EnumPlanStatusFilter>;
  segmentId?: Maybe<StringFilter>;
  startDate?: Maybe<DateTimeFilter>;
  endDate?: Maybe<DateTimeFilter>;
  geoType?: Maybe<EnumGeoTypeFilter>;
  geoEntities?: Maybe<StringNullableListFilter>;
  geoBounds?: Maybe<JsonFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  archivedAt?: Maybe<DateTimeNullableFilter>;
};

export type PlanUpdateManyMutationInput = {
  ownerEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumPlanStatusFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  geoType?: Maybe<EnumGeoTypeFieldUpdateOperationsInput>;
  geoBounds?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  archivedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  geoEntities?: Maybe<PlanUpdategeoEntitiesInput>;
};

export type PlanInventoryUpdateWithWhereUniqueWithoutVenueInput = {
  where: PlanInventoryWhereUniqueInput;
  data: PlanInventoryUpdateWithoutVenueInput;
};

export type PlanInventoryUpdateManyWithWhereWithoutVenueInput = {
  where: PlanInventoryScalarWhereInput;
  data: PlanInventoryUpdateManyMutationInput;
};

export type InventoryVenueUpdateWithWhereUniqueWithoutScenarioInput = {
  where: InventoryVenueWhereUniqueInput;
  data: InventoryVenueUpdateWithoutScenarioInput;
};

export type InventoryVenueUpdateManyWithWhereWithoutScenarioInput = {
  where: InventoryVenueScalarWhereInput;
  data: InventoryVenueUpdateManyMutationInput;
};

export type InventoryVenueScalarWhereInput = {
  AND?: Maybe<Array<InventoryVenueScalarWhereInput>>;
  OR?: Maybe<Array<InventoryVenueScalarWhereInput>>;
  NOT?: Maybe<Array<InventoryVenueScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  doohId?: Maybe<StringNullableFilter>;
  stringList?: Maybe<StringNullableFilter>;
  scenarioId?: Maybe<IntNullableFilter>;
};

export type InventoryVenueUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  doohId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  stringList?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type ScenarioUpdateWithWhereUniqueWithoutPlanInput = {
  where: ScenarioWhereUniqueInput;
  data: ScenarioUpdateWithoutPlanInput;
};

export type ScenarioUpdateManyWithWhereWithoutPlanInput = {
  where: ScenarioScalarWhereInput;
  data: ScenarioUpdateManyMutationInput;
};

export type ScenarioScalarWhereInput = {
  AND?: Maybe<Array<ScenarioScalarWhereInput>>;
  OR?: Maybe<Array<ScenarioScalarWhereInput>>;
  NOT?: Maybe<Array<ScenarioScalarWhereInput>>;
  id?: Maybe<IntFilter>;
  title?: Maybe<StringFilter>;
  status?: Maybe<EnumScenarioStatusFilter>;
  planId?: Maybe<IntFilter>;
  maxCPM?: Maybe<FloatNullableFilter>;
  publishers?: Maybe<StringNullableListFilter>;
  poiCategories?: Maybe<StringNullableListFilter>;
  poiRadius?: Maybe<FloatNullableFilter>;
  bundles?: Maybe<StringNullableListFilter>;
  sizes?: Maybe<StringNullableListFilter>;
  daypartId?: Maybe<IntFilter>;
  venuesIsExclude?: Maybe<BoolFilter>;
  publishersIsExclude?: Maybe<BoolFilter>;
  poiIsExclude?: Maybe<BoolFilter>;
  bundlesIsExclude?: Maybe<BoolFilter>;
  sizesIsExclude?: Maybe<BoolFilter>;
  daypartsIsExclude?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
};

export type ScenarioUpdateManyMutationInput = {
  title?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumScenarioStatusFieldUpdateOperationsInput>;
  maxCPM?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  poiRadius?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  venuesIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  publishersIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  poiIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  bundlesIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  sizesIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  daypartsIsExclude?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  publishers?: Maybe<ScenarioUpdatepublishersInput>;
  poiCategories?: Maybe<ScenarioUpdatepoiCategoriesInput>;
  bundles?: Maybe<ScenarioUpdatebundlesInput>;
  sizes?: Maybe<ScenarioUpdatesizesInput>;
};

export type PlanUpdateWithWhereUniqueWithoutSegmentInput = {
  where: PlanWhereUniqueInput;
  data: PlanUpdateWithoutSegmentInput;
};

export type PlanUpdateManyWithWhereWithoutSegmentInput = {
  where: PlanScalarWhereInput;
  data: PlanUpdateManyMutationInput;
};

export type SegmentUpdateWithWhereUniqueWithoutOrgInput = {
  where: SegmentWhereUniqueInput;
  data: SegmentUpdateWithoutOrgInput;
};

export type SegmentUpdateManyWithWhereWithoutOrgInput = {
  where: SegmentScalarWhereInput;
  data: SegmentUpdateManyMutationInput;
};

export type SegmentScalarWhereInput = {
  AND?: Maybe<Array<SegmentScalarWhereInput>>;
  OR?: Maybe<Array<SegmentScalarWhereInput>>;
  NOT?: Maybe<Array<SegmentScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  category?: Maybe<StringFilter>;
  status?: Maybe<EnumSegmentStatusFilter>;
  orgId?: Maybe<StringFilter>;
};

export type SegmentUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  category?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumSegmentStatusFieldUpdateOperationsInput>;
};

export type AffectedRowsOutput = {
  __typename?: 'AffectedRowsOutput';
  count: Scalars['Int'];
};

export type PlanCreateInput = {
  ownerEmail?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  status?: Maybe<PlanStatus>;
  startDate: Scalars['DateTime'];
  endDate: Scalars['DateTime'];
  geoType: GeoType;
  geoBounds?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  archivedAt?: Maybe<Scalars['DateTime']>;
  geoEntities?: Maybe<PlanCreategeoEntitiesInput>;
  org: OrganizationCreateNestedOneWithoutPlansInput;
  segment: SegmentCreateNestedOneWithoutPlansInput;
  scenarios?: Maybe<ScenarioCreateNestedManyWithoutPlanInput>;
  inventory?: Maybe<PlanInventoryCreateNestedManyWithoutPlanInput>;
  jobs?: Maybe<BigQueryJobCreateNestedManyWithoutPlanInput>;
  footraffic?: Maybe<FootTrafficCreateNestedManyWithoutPlanInput>;
};

export type PlanUpdateInput = {
  ownerEmail?: Maybe<NullableStringFieldUpdateOperationsInput>;
  title?: Maybe<StringFieldUpdateOperationsInput>;
  status?: Maybe<EnumPlanStatusFieldUpdateOperationsInput>;
  startDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  endDate?: Maybe<DateTimeFieldUpdateOperationsInput>;
  geoType?: Maybe<EnumGeoTypeFieldUpdateOperationsInput>;
  geoBounds?: Maybe<Scalars['JSON']>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  archivedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  geoEntities?: Maybe<PlanUpdategeoEntitiesInput>;
  org?: Maybe<OrganizationUpdateOneRequiredWithoutPlansInput>;
  segment?: Maybe<SegmentUpdateOneRequiredWithoutPlansInput>;
  scenarios?: Maybe<ScenarioUpdateManyWithoutPlanInput>;
  inventory?: Maybe<PlanInventoryUpdateManyWithoutPlanInput>;
  jobs?: Maybe<BigQueryJobUpdateManyWithoutPlanInput>;
  footraffic?: Maybe<FootTrafficUpdateManyWithoutPlanInput>;
};

import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";

const Play: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-circled-play"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C7.44321 3.75 3.75 7.44321 3.75 12C3.75 16.5568 7.44321 20.25 12 20.25C16.5568 20.25 20.25 16.5568 20.25 12C20.25 7.44321 16.5568 3.75 12 3.75ZM2.25 12C2.25 6.61479 6.61479 2.25 12 2.25C17.3852 2.25 21.75 6.61479 21.75 12C21.75 17.3852 17.3852 21.75 12 21.75C6.61479 21.75 2.25 17.3852 2.25 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.559 9.70401V14.295L14.4411 11.9995L10.559 9.70401ZM11.3228 8.41247C10.3219 7.82019 9.05899 8.54328 9.05899 9.70401V14.295C9.05899 15.4557 10.3219 16.1788 11.3228 15.5865C11.3227 15.5866 11.3229 15.5865 11.3228 15.5865L15.2044 13.2908C16.1867 12.7106 16.1869 11.2889 15.2048 10.7085C15.2049 10.7085 15.2047 10.7084 15.2048 10.7085L11.3228 8.41247C11.3229 8.41252 11.3227 8.41241 11.3228 8.41247Z"
      />
    </svg>
  );
};

export default Play;

import { RemoteConfigProperties } from "@madhive/mad-sdk";
import { madSDK } from "lib/sdk";

export const reconcileMadSdkCampaignCache = (campaigns: any) => {
  if (
    !madSDK.featureFlags.isFlagEnabled(RemoteConfigProperties.LEGACY_CAMPAIGN)
  ) {
    madSDK.campaigns.reconcileCache("delete", campaigns);
  }
};

export const reconcileMadSdkLineItemCache = (lineItems: any) => {
  if (
    !madSDK.featureFlags.isFlagEnabled(RemoteConfigProperties.LEGACY_LINE_ITEM)
  ) {
    madSDK.campaigns.lineItems.reconcileCache("delete", lineItems);
  }
};

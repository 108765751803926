import { BadCacheKeyFormat } from "./errors";
/**
 * This is the global cache that all instances of Cache reach into,
 * to get/set/remove/clear/has items.
 * Only one instance of this class is exported from root sdk.
 */
class Caches {
    constructor() {
        this.data = new Map();
    }
    /**
     * @param key: unique cache key - prefixed with namespace and category, like namespace::category::identifier.
     * @return: true if an item with that key was found and removed. Else, false.
     */
    remove(key) {
        try {
            const { namespace, category, identifier } = this.parseKey(key);
            return !!this.data.get(namespace)?.get(category)?.delete(identifier);
        }
        catch {
            return false;
        }
    }
    /**
     * @param key: unique cache key - prefixed with namespace and category, like namespace::category::identifier.
     * @return: cached item if it exists - else undefined.
     */
    get(key) {
        try {
            const { namespace, category, identifier } = this.parseKey(key);
            return this.data?.get(namespace)?.get(category)?.get(identifier);
        }
        catch {
            return undefined;
        }
    }
    /**
     * @param key: unique cache key - prefixed with namespace and category, like namespace::category::identifier.
     * @returns: true if the cached item exist, else false.
     */
    has(key) {
        try {
            const { namespace, category, identifier } = this.parseKey(key);
            return !!this.data?.get(namespace)?.get(category)?.has(identifier);
        }
        catch {
            return false;
        }
    }
    /**
     *
     * @param key: unique cache key - prefixed with namespace and category, like namespace::category::identifier.
     * @param value: the item to cache.
     * @throws: BadCacheKeyFormat if key is invalidly formatted.
     */
    set(key, value) {
        const { namespace, category, identifier } = this.parseKey(key);
        if (!this.data.has(namespace)) {
            this.data.set(namespace, new Map([
                ["resources", new Map()],
                ["atoms", new Map()]
            ]));
        }
        this.data.get(namespace)?.get(category)?.set(identifier, value);
    }
    /**
     * @param namespace: optional. Namespace of cache to clear. If none provided, will clear all caches.
     * @param category: optional. Category of values to clear. If none provided, will clear all categories of values in the provided `namespace`.
     */
    clear(namespace, category) {
        if (!namespace) {
            this.data.clear();
        }
        else if (this.data.has(namespace)) {
            if (category) {
                const cache = this.data.get(namespace)?.get(category);
                cache?.clear();
            }
            else {
                this.data.delete(namespace);
            }
        }
    }
    /**
     * @param namespace: the namespace of the cache to get entries for.
     * @param category: optional. The sorts of items to iterate over. Defaults to both atoms and resources.
     * @return: an iterable iterator of all [key, value] pairs in the cache
     */
    *entries(namespace, category) {
        const namespaceCache = this.data.get(namespace);
        if (!namespaceCache) {
            return;
        }
        const lookups = [];
        if (category) {
            const valueCache = namespaceCache.get(category);
            valueCache && lookups.push(valueCache);
        }
        else {
            for (const [, valueCache] of namespaceCache) {
                lookups.push(valueCache);
            }
        }
        for (const lookup of lookups) {
            for (const entry of lookup) {
                yield entry;
            }
        }
    }
    /**
     * @param namespace: the namespace of the cache to get entries for.
     * @param category: optional. The sorts of items to iterate over. Defaults to both atoms and resources.
     * @return: an iterable of all keys in the cache.
     */
    *keys(namespace, category) {
        for (const [key] of this.entries(namespace, category)) {
            yield key;
        }
    }
    /**
     * @param namespace: the namespace of the cache to get entries for.
     * @param category: optional. The sorts of items to iterate over. Defaults to both atoms and resources.
     * @return: an iterable of all values in the cache.
     */
    *values(namespace, category) {
        for (const [, value] of this.entries(namespace, category)) {
            yield value;
        }
    }
    /**
     * @param key: a structured key.
     * @return: the pieces of the key, broken down by namespace, category, identifier.
     * @throws: BadCacheKeyFormat if the key is not validly formatted.
     */
    parseKey(key) {
        const split = key.split("::");
        if (split.length !== 3 || !["atoms", "resources"].includes(split[1])) {
            throw new BadCacheKeyFormat(key);
        }
        const [namespace, category, identifier] = split;
        return { namespace, category, identifier };
    }
    get namespaces() {
        return new Set(this.data.keys());
    }
}
export default Caches;

import styled from "@emotion/styled";

import { Icon } from "@blueprintjs/core";

import { Icons, Tooltip } from "frontier/lib/kit";

import { colors, StickyCheckbox } from "./style";

import { SortDirection, TableColumn } from "./types";

interface HeaderCellProps {
  column: TableColumn;
  columnIndex: number;
  rowIndex: number;
  isResizing: boolean;
  setResizing: (num: number) => void;
  isSortColumn: boolean;
  sortDirection: SortDirection;
  setSortColumn: (columnIndex: number) => void;
  fixable: boolean;
  setStickyColumn: (columnIndex: number) => void;
  setColumnWidth: (columnIndex: number, width: number) => void;
  isSticky: boolean;
  setOpenRows: (rows: Set<number>) => void;
}

const HeaderCellWrapper = styled.div<{ clickable: boolean }>`
  cursor: pointer;
  pointer-events: ${props => (props.clickable ? "" : "none")};
`;

const LocalWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
`;

const Resizer = styled.div`
  width: 4px;
  background: transparent;
  position: absolute;
  right: -4px;
  top: 0;
  bottom: 0;
  cursor: col-resize;
`;

const TitleWrapper = styled.div<{ isSortColumn: boolean }>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-width: 100%;
  min-width: 0;
  white-space: nowrap;
  font-weight: var(--font-weight-bold);
  line-height: var(--spacing-16);
  font-size: var(--font-size-12);
  color: ${props => (props.isSortColumn ? "var(--primary-color)" : "")};
  user-select: none;

  .bp4-popover-wrapper {
    display: flex;
    margin-left: var(--spacing-8);
  }
`;

const OptionWrapper = styled.div`
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  > * {
    cursor: pointer;
  }
  margin-left: var(--spacing-4);
`;

const StyledIcon = styled(Icon as unknown as (props: any) => JSX.Element)<{
  reversed: boolean;
}>`
  transition: transform 250ms ease-in-out;
  transform: rotate(${props => (props.reversed ? 180 : 0)}deg);
`;

const HeaderCell = (props: HeaderCellProps) => {
  const {
    columnIndex,
    column: { title, description, sortable, sortFunction },
    isSortColumn,
    sortDirection,
    setResizing,
    isResizing,
    setOpenRows
  } = props;

  const handleSticky = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    props.setStickyColumn(columnIndex);
  };

  const handleSort = (e: { stopPropagation: () => void }) => {
    if (isResizing) return;

    if (setOpenRows) {
      /**
       * Since a row's open state is determined by its index, sorting will
       * open new rows and close open rows if indices of data change.
       * Unless we reset open state.
       */
      setOpenRows(new Set());
    }

    e.stopPropagation();
    props.setSortColumn(columnIndex);
  };

  const handleMouseDown = () => setResizing(columnIndex);

  const canSort = Boolean(sortable || sortFunction);

  const renderTooltip = (description: string) => (
    <Tooltip position="auto" content={description} triggerAction="hover">
      <Icons.Display size="small">
        <Icons.Circled.Info fill="var(--color-secondary)" />
      </Icons.Display>
    </Tooltip>
  );

  return (
    <HeaderCellWrapper clickable={canSort} onClick={handleSort}>
      <LocalWrapper>
        <TitleWrapper isSortColumn={isSortColumn}>
          {title}
          {description && renderTooltip(description)}
        </TitleWrapper>
        <OptionWrapper>
          {isSortColumn && canSort && (
            <StyledIcon
              icon="caret-down"
              color={colors.black}
              reversed={
                isSortColumn && sortDirection === SortDirection.ASCENDING
              }
            />
          )}
          {props.fixable && (
            <StickyCheckbox
              onClick={handleSticky}
              checked={props.isSticky}
              inverted
            />
          )}
        </OptionWrapper>
        <Resizer onMouseDown={handleMouseDown} />
      </LocalWrapper>
    </HeaderCellWrapper>
  );
};

export default HeaderCell;

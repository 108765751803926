import { findFrequencyValue, checkFrequencyProportions, checkFrequencyLimits } from "../../../utils/frequency";
/**
 * @param value: the frequency array associated with the frequencies key.
 * @return: an error message, if there is an error. Else, undefined.
 */
export const validateFrequency = (value) => {
    if (!value || !value.length)
        return;
    const dayCap = findFrequencyValue(value, "DAY");
    if (dayCap === undefined) {
        return "Daily Frequency Cap must be defined.";
    }
    if (Number(dayCap) === 0) {
        return "Frequency cap must be set to a value greater than 0.";
    }
    const proportionErrors = checkFrequencyProportions(value);
    if (proportionErrors) {
        return proportionErrors;
    }
    const limitErrors = Object.values(checkFrequencyLimits(value) || {}).join(", ");
    if (limitErrors) {
        return limitErrors;
    }
    return;
};

import { useEffect, useState } from "react";
import FormField from "../../FormField";
import { DoNotCare } from "../../../types";
import Radio from "..";
import { RadioGroupProps } from "./types";
import { styles } from "./styles";
import ReadonlyFormField from "../../FormField/Readonly";

/**
 * `RadioGroup` renders a group of radio buttons, and allows users to select one option from a set.
 * It manages the selected state of its child radio buttons, ensuring that only one radio button in the group can be selected at a time.
 */
export const RadioGroup = <
  Option extends DoNotCare,
  Selected extends DoNotCare
>({
  disabled = false,
  fill = false,
  stacked = false,
  label,
  description,
  hideLabel,
  error,
  offsetError,
  required,
  labelSuffix,
  labelSuffixAlign,
  readonly,
  readonlyParser,
  options,
  selectedValue,
  lookupLabel,
  lookupValue,
  onChange
}: RadioGroupProps<Option, Selected>) => {
  const [groupValue, setGroupValue] = useState<Selected>(selectedValue);

  useEffect(() => {
    setGroupValue(selectedValue);
  }, [selectedValue]);

  const lookupCleanValue = (option: Option) => {
    const value = lookupValue(option);
    if (
      value === undefined ||
      (typeof value !== "string" && typeof value !== "number")
    ) {
      return undefined;
    }
    return value;
  };

  const handleOnChange = (option: Option) => {
    const value = lookupCleanValue(option);
    if (value === groupValue || value === undefined) {
      return;
    }
    setGroupValue(value);
    if (onChange) {
      onChange(value);
    }
  };

  if (readonly) {
    return (
      <ReadonlyFormField
        parser={readonlyParser || lookupLabel}
        fill={fill}
        label={label}
        hideLabel={hideLabel}
        value={selectedValue}
        description={description}
        labelSuffix={labelSuffix}
        labelSuffixAlign={labelSuffixAlign}
      />
    );
  }

  return (
    <FormField
      fieldset
      label={label}
      error={error}
      offsetError={offsetError}
      fill={fill}
      required={required}
      description={description}
      hideLabel={hideLabel}
      labelSuffix={labelSuffix}
      labelSuffixAlign={labelSuffixAlign}
    >
      <div
        data-testid="kit-RadioGroup"
        className="radio-group"
        css={[
          styles.base,
          fill && styles.fill,
          stacked && styles.stacked,
          label && styles.withLabel
        ]}
      >
        {options.map((option: Option) => (
          <Radio
            key={lookupCleanValue(option)}
            disabled={disabled}
            value={lookupCleanValue(option) || ""}
            label={lookupLabel(option)}
            onChange={() => handleOnChange(option)}
            checked={groupValue === lookupValue(option)}
          />
        ))}
      </div>
    </FormField>
  );
};

export default RadioGroup;
export * from "./types";

import { Icon, IconSize } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { useSelector, shallowEqual } from "react-redux";
import { selectCurrentUser } from "appReducers/authReducer";
import { selectCurrentOrganization } from "appReducers/organizationsReducer";

import { css } from "@emotion/react";
import { Button, ButtonVariant } from "madhive/components";
import { madSDK } from "lib/sdk";
import { ORG_ID_TO_HOSTNAMES } from "lib/constants/organizations";
import { FC, useState, memo } from "react";
import ViewSelectorDrawer from "../ViewSelectorDrawer";
import { Inbox as InboxNotifications } from "../Notifications";
import ProfileButton from "./ProfileButton";

const drawerWidth = 300;

const SmithersToolbar: FC = () => {
  const user = useSelector(selectCurrentUser, shallowEqual);
  const currentOrganization = useSelector(
    selectCurrentOrganization,
    shallowEqual
  );

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  if (!user || !currentOrganization) {
    return null;
  }

  // Since the Org the user is part of can be different then the domain
  // we are currently on we want to override the domain on logo look up
  // once all orgs have been updated to use the new setttings this
  // override can be removed. (we pull the last domain b/c current all orgs
  // have a list of madhive specific domains before their custom domains)
  const domain =
    ORG_ID_TO_HOSTNAMES[user.primaryOrganizationId]?.[
      ORG_ID_TO_HOSTNAMES[user.primaryOrganizationId]?.length - 1
    ];

  /**
   * The toolbar background is a brand-specific color that may or may not be the
   * same as the brand's primary-main color. In order to consistently get icons
   * that contrast against the toolbar background color while still using the
   * brand's color palette, we need to map the brand ID to a brand-specific
   * custom color.
   */
  // PK TODO (settings): Need to backfill toolbar_color in postgres
  const iconColorOverride =
    user.settings.theme?.toolbarColor || "var(--brand-logo-color)";
  const logoSizeOverride =
    user.settings.theme?.logoSize && `${user.settings.theme.logoSize}px`;

  const styles = {
    appBar: css`
      position: relative;
    `,
    logoWrapper: css`
      display: flex;
      justify-content: center;
      position: absolute;
      inset: 0;
      overflow: hidden;
      padding: var(--spacing-12);
      ${logoSizeOverride && "align-items: center;"}
      pointer-events: none;
    `,
    contentWrapper: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: var(--header-color);
      border-bottom: 1px solid var(--header-nav-border);
      height: var(--spacing-64);
      padding: 0 var(--spacing-16);

      & div:only-child {
        margin-left: auto;
      }

      .bp4-button.icon {
        display: inline-block;
        height: auto;
      }
    `
  };

  return (
    <>
      <div css={styles.appBar}>
        <div css={styles.logoWrapper}>
          <img
            className="toolbar-logo"
            src={`${madSDK.urls.burnsBaseUrl}/public/images/app-logo.svg?org=${user.primaryOrganizationId}&domain=${domain}`}
            alt=""
            css={
              logoSizeOverride
                ? css`
                    width: ${logoSizeOverride};
                  `
                : null
            }
          />
        </div>
        <div css={styles.contentWrapper}>
          {user.viewIds.length > 1 && (
            <Button
              variant={ButtonVariant.ICON}
              icon={
                <Icon
                  icon={IconNames.MENU}
                  iconSize={IconSize.LARGE}
                  color={iconColorOverride}
                />
              }
              onClick={() => setDrawerOpen(true)}
              data-testid="nav-bar-toggle"
              aria-label="main menu"
              aria-haspopup="true"
              data-pdfignore
            />
          )}
          <div data-pdfignore>
            <InboxNotifications colorOverride={iconColorOverride} />
            <ProfileButton disabled={!user} colorOverride={iconColorOverride} />
          </div>
        </div>
      </div>
      <ViewSelectorDrawer
        width={drawerWidth}
        isOpen={drawerOpen}
        onDrawerClose={() => setDrawerOpen(false)}
        views={user.viewIds}
      />
    </>
  );
};

export default memo(SmithersToolbar);

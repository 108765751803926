import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Ellipsis: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-ellipsis"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.2527 12C19.2527 11.3095 18.693 10.7498 18.0025 10.7498C17.312 10.7498 16.7523 11.3095 16.7523 12C16.7523 12.6905 17.312 13.2502 18.0025 13.2502C18.693 13.2502 19.2527 12.6905 19.2527 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2502 12C13.2502 11.3095 12.6905 10.7498 12 10.7498C11.3095 10.7498 10.7498 11.3095 10.7498 12C10.7498 12.6905 11.3095 13.2502 12 13.2502C12.6905 13.2502 13.2502 12.6905 13.2502 12Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.24771 12C7.24771 11.3095 6.68797 10.7498 5.9975 10.7498C5.30703 10.7498 4.74729 11.3095 4.74729 12C4.74729 12.6905 5.30703 13.2502 5.9975 13.2502C6.68797 13.2502 7.24771 12.6905 7.24771 12Z"
      />
    </svg>
  );
};

export default Ellipsis;

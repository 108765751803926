import { MouseEventHandler, ReactNode, memo, useMemo } from "react";
import { Icon, Popover } from "@blueprintjs/core";
import styled from "@emotion/styled";
import classNames from "classnames";
import { isEqual } from "lodash";
import { Button, ButtonVariant } from "madhive/components";
import { Checkbox } from "../Checkbox";
import Cell, { CellWrapperStyle } from "./cell";
import {
  colors,
  DEFAULT_ROW_HEIGHT,
  leftShadow,
  StickyCheckbox,
  zIndex
} from "./style";
import { ColumnContextBase, useRowContext } from "./table";
import { renderRowCells } from "./tableContent";
import { ToggleSelected } from "./types";
import { getIsUnchangedRenderDependencies } from "./utils";

interface HeaderRowProps {
  header: true;
  onMouseDown?: MouseEventHandler<HTMLDivElement>;
  onMouseMove?: MouseEventHandler<HTMLDivElement>;
  onMouseUp?: MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: MouseEventHandler<HTMLDivElement>;
  toggleSelected?: ToggleSelected;
  currPageOuterRowIndex?: never;
  currPageCompositeIndex?: never;
  dataIndex?: never;
  scrollIndex?: never;
  minVisibleRows?: never;
}

interface BodyRowProps {
  currPageOuterRowIndex: number;
  currPageCompositeIndex: number;
  dataIndex: number;
  scrollIndex: number;
  minVisibleRows: number;
  header?: never;
  onMouseDown?: never;
  onMouseMove?: never;
  onMouseUp?: never;
  onMouseLeave?: never;
  toggleSelected?: never;
}

interface CommonRowProps {
  renderDependencies?: any[];
  children?: ReactNode;
}

type RowProps =
  | (CommonRowProps & HeaderRowProps)
  | (CommonRowProps & BodyRowProps);

interface PlainRowUniqueProps {
  subRowIndex?: number;
  item: any;
  top: number;
  isOpen: boolean;
  isSticky: boolean;
}

type PlainRowBaseProps = PlainRowUniqueProps &
  Omit<ColumnContextBase, "data" | "openRows" | "stickyRows" | "page">;

type PlainRowProps = PlainRowBaseProps & Omit<RowProps, "dataIndex">;

const ROW_COUNT_OUTSIDE_VIEWBOX = 3;

const RowWrapper = styled.div<{
  header?: boolean;
  height?: number;
  top: number;
  isSubrow: boolean;
  clickable: boolean;
}>`
  display: flex;
  position: ${props => (props.top > -2 ? "sticky" : "static")};
  top: ${props => props.top || 0}px;
  z-index: ${zIndex.rowSticky};
  height: ${props => (props.height ? `${props.height}px` : "auto")};
  background-color: ${colors.white};
  transition: all 200ms ease-in-out;
  cursor: ${props => (props.clickable ? "pointer" : "auto")};

  :not(.expanded-height-mono-row) > :not(.not-cell) {
    ${CellWrapperStyle}
  }
  > :nth-of-type(2) {
    padding-left: ${props =>
      props.isSubrow ? "calc(var(--spacing-8) * 3)" : "var(--spacing-8)"};
  }

  @keyframes expand {
    from {
      max-height: 1px;
    }
    to {
      max-height: 300px;
    }
  }
`;

const BulkWrapper = styled.div<{
  header?: boolean;
  height: number;
  itemCount: number;
}>`
  position: sticky;
  left: 0;
  vertical-align: top;
  z-index: ${props => (props.header ? zIndex.headerBulk : zIndex.cellBulk)};
  height: ${props => props.height}px;
  border-bottom: 1px solid var(--gray-3);
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  background-color: var(--white);
  flex: 0 0
    calc(
      var(--spacing-16) *
        ${props => props.itemCount * 1.5 + (props.itemCount ? 1.5 : 0)}
    );
  padding-left: var(--spacing-16);
`;

const VerticalOptions = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`;

const OpenerIcon = styled(Icon as unknown as (props: any) => JSX.Element)<{
  open: boolean;
  inactive: boolean;
}>`
  transition: transform 250ms ease-in-out;
  transform: rotate(${props => (props.open ? 90 : 0)}deg);
  cursor: pointer;
  pointer-events: ${props => (props.inactive ? "none" : "")};
`;

const RightMenuWrapper = styled.div<{ height: number }>`
  vertical-align: top;
  position: sticky;
  right: 0;
  height: ${props => props.height}px;
  flex: 0 0 calc(var(--spacing-16) * 4);
  z-index: ${zIndex.rightMenuContentRenderFn};
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid var(--gray-3);

  background: ${colors.white};
  box-shadow: ${leftShadow};
`;

export const FakeRow = styled.div<{ height: number }>`
  width: 100%;
  height: ${props => props.height}px;
  display: flex;
  align-items: center;
  > span {
    flex-grow: 1;
  }
`;

// fallback if nothing is passed to the row
const createColumns = (count: number, rowIndex: number) =>
  [...Array(count)].map((a, columnIndex: number) => (
    <Cell key={columnIndex} rowIndex={rowIndex} columnIndex={columnIndex} />
  ));

const PlainRow = memo(
  (props: PlainRowProps) => {
    const {
      onMouseDown,
      onMouseMove,
      onMouseUp,
      onMouseLeave,
      currPageCompositeIndex,
      subRowIndex,
      rowHeight = DEFAULT_ROW_HEIGHT,
      item,
      header,
      children,
      top,
      toggleStickyRow,
      toggleSubrows,
      isOpen,
      renderRow,
      renderSubrow,
      canFixRows,
      columnCount,
      selectable,
      itemKey,
      toggleSelected,
      selectedIds,
      disableSubRowSelection,
      rightMenuContentRenderFn,
      hasSubrows,
      subRowKey,
      scrollIndex,
      minVisibleRows,
      isSticky,
      lengthOfDataPlusSubRows,
      currPageOuterRowIndex,
      rowClassname,
      onRowClick
    } = props;

    const getIsHidden = () => {
      if (header) return false;
      if (
        scrollIndex === undefined ||
        minVisibleRows === undefined ||
        currPageCompositeIndex === undefined
      )
        return false;

      const lowerBound = scrollIndex - ROW_COUNT_OUTSIDE_VIEWBOX;
      const upperBound =
        scrollIndex + ROW_COUNT_OUTSIDE_VIEWBOX + minVisibleRows;

      const isInViewport =
        currPageCompositeIndex > lowerBound &&
        currPageCompositeIndex < upperBound;

      return !isInViewport && !isSticky;
    };

    const isHidden = getIsHidden();

    const isSubrow = subRowIndex !== undefined;

    const handleSticky = () => {
      if (!currPageCompositeIndex) return;
      toggleStickyRow(currPageCompositeIndex);
    };

    const handleToggleSubrows = () => {
      if (currPageOuterRowIndex === undefined) return;
      if (isSubrow) return;
      toggleSubrows(currPageOuterRowIndex);
    };

    const getIsSelected = () => {
      if (!itemKey || !selectedIds) return false;

      const selectedId: string | undefined = item && item[itemKey];

      return Boolean(selectedId && selectedIds[selectedId]);
    };

    const getRowClassname = () => item && rowClassname && rowClassname(item);

    const getHeaderSelectionState = () => {
      if (!selectable || !header || !selectedIds) return false;

      const selectedVals = Object.values(selectedIds);

      const isAnySelectedDataTruthy = selectedVals.find(Boolean);

      return isAnySelectedDataTruthy;
    };

    const isHeaderCheckboxIndeterminate = getHeaderSelectionState();

    const isSelected = getIsSelected();

    const handleSelection = () => {
      if (selectable && toggleSelected) {
        toggleSelected(item, header);
      }
    };

    const MenuContent = rightMenuContentRenderFn
      ? rightMenuContentRenderFn(item)
      : null;

    const subRowRenderFn = () => (
      <>
        {isOpen &&
          subRowKey &&
          !header &&
          item[subRowKey].map((subrow: any, innerIndex: number) => (
            <PlainRow
              currPageCompositeIndex={
                (currPageCompositeIndex as number) + 1 + innerIndex
              }
              subRowIndex={innerIndex}
              key={innerIndex}
              rowHeight={rowHeight}
              top={-2}
              toggleSelected={toggleSelected}
              selectable={selectable}
              selectedIds={selectedIds}
              itemKey={itemKey}
              disableSubRowSelection={disableSubRowSelection}
              hasSubrows={hasSubrows}
              subRowKey={subRowKey}
              rightMenuContentRenderFn={rightMenuContentRenderFn}
              scrollIndex={scrollIndex}
              minVisibleRows={minVisibleRows}
              isSticky={isSticky}
              item={subrow}
              isOpen={false}
              lengthOfDataPlusSubRows={lengthOfDataPlusSubRows}
              canFixRows={canFixRows}
              toggleStickyRow={toggleStickyRow}
              toggleSubrows={toggleSubrows}
              columnCount={columnCount}
              rowClassname={rowClassname}
              onRowClick={onRowClick}
            >
              {renderSubrow
                ? renderSubrow(subrow)
                : renderRowCells(subrow, innerIndex)}
            </PlainRow>
          ))}
      </>
    );

    if (isHidden) {
      return (
        <>
          <FakeRow height={rowHeight} />
          {subRowRenderFn()}
        </>
      );
    }

    if (item && item.height) {
      return (
        <>
          <RowWrapper
            onMouseDown={onMouseDown}
            onMouseMove={onMouseMove}
            onMouseUp={onMouseUp}
            onMouseLeave={onMouseLeave}
            className={classNames(
              "table-row",
              header && "header-row",
              "expanded-height-mono-row",
              getRowClassname()
            )}
            header={header}
            // The 'height' property on an element is used to calculate the height
            // of the table container, but the row itself should simply be set to
            // 'auto' in this case so that its height can dynamically adjust to
            // content rendered within it.
            height={undefined}
            top={top || top === 0 ? top : -2}
            isSubrow={isSubrow}
            clickable={!header && !!onRowClick}
            onClick={() => onRowClick?.(item)}
          >
            {!header && renderRow
              ? renderRow(item)
              : children ||
                createColumns(columnCount, currPageCompositeIndex as number)}
          </RowWrapper>
          {subRowRenderFn()}
        </>
      );
    }

    return (
      <>
        <RowWrapper
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseUp={onMouseUp}
          onMouseLeave={onMouseLeave}
          className={classNames(
            "table-row",
            header && "header-row",
            getRowClassname()
          )}
          header={header}
          height={rowHeight}
          top={top || top === 0 ? top : -2}
          isSubrow={isSubrow}
          key={currPageCompositeIndex}
          clickable={!header && !!onRowClick}
          onClick={() => onRowClick?.(item)}
        >
          <BulkWrapper
            header={header}
            height={rowHeight}
            className={classNames("not-cell", getRowClassname())}
            itemCount={[selectable, hasSubrows].filter(i => i).length}
          >
            {selectable && !(isSubrow && props.disableSubRowSelection) && (
              <Checkbox
                checked={isSelected}
                onChange={handleSelection}
                indeterminate={isHeaderCheckboxIndeterminate}
              />
            )}
            {hasSubrows && !isSubrow && subRowKey && (
              <VerticalOptions>
                {!header && canFixRows && !isSubrow && (
                  <StickyCheckbox
                    onClick={handleSticky}
                    checked={top || top === 0 ? top > -2 : false}
                    inverted={header}
                  />
                )}
                <OpenerIcon
                  inactive={header || !item[subRowKey]}
                  icon="caret-right"
                  color={
                    header || !item[subRowKey] ? colors.white : colors.black
                  }
                  open={isOpen}
                  onClick={handleToggleSubrows}
                />
              </VerticalOptions>
            )}
          </BulkWrapper>
          {!header && renderRow
            ? renderRow(item)
            : children ||
              createColumns(columnCount, currPageCompositeIndex as number)}
          {!!MenuContent && (
            <RightMenuWrapper height={rowHeight} className="not-cell">
              {!header && (
                <Popover content={MenuContent}>
                  <Button
                    icon={<Icon icon="more" />}
                    variant={ButtonVariant.ICON}
                  />
                </Popover>
              )}
            </RightMenuWrapper>
          )}
        </RowWrapper>
        {subRowRenderFn()}
      </>
    );
  },
  (prev, next) => {
    /**
     * If you're encountering problems with row rendering, look at this
     * equality function— chances are, the row isn't rerendering because the
     * change isn't explicitly stipulated as a condition for re-render below.
     */

    if (prev.header) return false;
    return (
      getIsUnchangedRenderDependencies(
        prev.renderDependencies,
        next.renderDependencies
      ) &&
      prev.top === next.top &&
      prev.isOpen === next.isOpen &&
      isEqual(prev.item, next.item) &&
      prev.columnCount === next.columnCount &&
      prev.scrollIndex === next.scrollIndex &&
      prev.currPageCompositeIndex === next.currPageCompositeIndex &&
      prev.minVisibleRows === next.minVisibleRows &&
      prev.selectedIds === next.selectedIds
    );
  }
);

const Row = memo((props: RowProps) => {
  const {
    currPageCompositeIndex,
    currPageOuterRowIndex,
    dataIndex,
    scrollIndex,
    minVisibleRows,
    header,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onMouseLeave,
    renderDependencies
  } = props;

  const {
    rowHeight,
    rowHeights,
    canFixRows,
    stickyRows,
    toggleStickyRow,
    openRows,
    toggleSubrows,
    toggleSelected,
    selectedIds,
    disableSubRowSelection,
    data,
    renderRow,
    renderSubrow,
    columnCount,
    selectable,
    hasSubrows,
    subRowKey,
    rightMenuContentRenderFn,
    itemKey,
    rowClassname,
    onRowClick,
    lengthOfDataPlusSubRows
  } = useRowContext();

  const derivedRowHeight =
    rowHeights && dataIndex ? rowHeights[dataIndex] : rowHeight;

  const isSticky =
    header || stickyRows.includes(currPageCompositeIndex as number);

  const { top } = useMemo(() => {
    // const hiddenLocal = !header && !inViewport && !isSticky;
    // topLocal is just top named so to avoid lint issues
    // header and sticky rows have top values, else it's -2
    // top has 0, sticky top is determined by previous rows to make sure there's no overlapping
    // so far subrows are part of row, change this?
    let topLocal = -2;
    if (header) {
      topLocal = 0;
    } else if (isSticky) {
      topLocal =
        (1 + stickyRows.indexOf(currPageCompositeIndex as number)) *
        derivedRowHeight;
    }
    return { top: topLocal };
  }, [stickyRows, currPageCompositeIndex]);

  const item = header && dataIndex ? null : data[dataIndex as number];

  const isOpen = useMemo(() => {
    if (header) return false;
    return openRows.has(currPageOuterRowIndex as number);
  }, [openRows, currPageOuterRowIndex]);

  if (!header && !item) {
    return <FakeRow height={derivedRowHeight} />;
  }

  return (
    <PlainRow
      header={header}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
      isOpen={isOpen}
      item={item}
      top={top}
      hasSubrows={hasSubrows}
      subRowKey={subRowKey}
      selectable={selectable}
      toggleSelected={toggleSelected}
      itemKey={itemKey}
      selectedIds={selectedIds}
      disableSubRowSelection={disableSubRowSelection}
      canFixRows={canFixRows}
      toggleStickyRow={toggleStickyRow}
      toggleSubrows={toggleSubrows}
      renderRow={renderRow}
      renderSubrow={renderSubrow}
      columnCount={columnCount}
      rightMenuContentRenderFn={rightMenuContentRenderFn}
      currPageCompositeIndex={currPageCompositeIndex}
      currPageOuterRowIndex={currPageOuterRowIndex}
      scrollIndex={scrollIndex}
      minVisibleRows={minVisibleRows}
      isSticky={isSticky}
      lengthOfDataPlusSubRows={lengthOfDataPlusSubRows}
      rowHeight={derivedRowHeight}
      rowClassname={rowClassname}
      onRowClick={onRowClick}
      renderDependencies={renderDependencies}
    >
      {props.children}
    </PlainRow>
  );
});

export default Row;

import Paging from "./paging";
/**
 * This Sorting class handles the sorting logic for a Stream
 */
class Sorting extends Paging {
    constructor(defaultFilters, defaultSort) {
        super(defaultFilters);
        this.sorting = {
            sort: this.sort.bind(this)
        };
        if (defaultSort) {
            this.field = defaultSort.field;
            this.direction = defaultSort.direction;
        }
    }
    /**
     * Set the current field
     */
    set field(column) {
        this._field = column;
    }
    /**
     * Get the current set field.
     */
    get field() {
        return this._field;
    }
    /**
     * Set the direction
     */
    set direction(direction) {
        this._direction = this.getDirection(direction);
    }
    /**
     * Get the direction of current sort.
     */
    get direction() {
        return this._direction;
    }
    /**
     * Set the current sort on this item.
     * @param field field to be sorted on
     * @param direction direction of the sort.
     */
    sort(field, direction) {
        const dir = this.getDirection(direction);
        if (dir) {
            this.field = field;
            this.direction = dir;
        }
        else {
            if (this.direction === "asc") {
                this.direction = "desc";
            }
            else {
                this.direction = "asc";
            }
        }
        this.handleSortingUpdate();
    }
    /**
     * Get the current sort.
     * @returns Sort<T> using the current fields.
     */
    getSort() {
        return {
            field: this._field,
            direction: this._direction
        };
    }
    /**
     * Services really only support "asc", "desc" but we want
     * to support descending and ascending also so this coverts
     * to the service standard.
     * @param direction SortDirection to be simplified.
     * @returns either "asc" or "desc"
     */
    getDirection(direction) {
        let dir = direction;
        if (dir === "ascending") {
            dir = "asc";
        }
        else if (dir === "descending") {
            dir = "desc";
        }
        return dir;
    }
}
export default Sorting;

import { now } from "./utils";
/**
 * Enables efficient management of lifespan-limited keys
 * based on their entry time, and time to live.
 */
export class Reaper {
    /**
     * @param sdk: an instance of the SDK for cache lookups.
     * @param ttl: the configured time-to-live in seconds.
     */
    constructor(sdk, ttl) {
        this.sdk = sdk;
        this.ttl = ttl;
        this.current = {
            id: 0,
            expiry: now() + this.ttl,
            keys: new Set()
        };
        this.next = {
            id: 1,
            expiry: now() + this.ttl * 2,
            keys: new Set()
        };
    }
    /**
     * Clears any keys the reaper may be tracking for removal.
     * @param category: optional. If provided, only keys of this category are cleared.
     */
    clear(category) {
        if (!category) {
            this.current.keys.clear();
            this.next.keys.clear();
            return;
        }
        for (const keyset of [this.current.keys, this.next.keys]) {
            for (const key of keyset) {
                const [, keyCategory] = key.split("::");
                if (keyCategory === category) {
                    this.remove(key);
                }
            }
        }
    }
    /** Removes the given key from any tracking set. */
    remove(key) {
        this.current.keys.delete(key);
        this.next.keys.delete(key);
    }
    /**
     * Ensures the reaper will track and remove the cached values for the given keys when their ttl expires.
     * @param keys: the set of keys to track.
     */
    track(keys) {
        const slot = this.slot();
        for (const key of keys) {
            if (this.current.id !== slot) {
                this.current.keys.delete(key);
                this.next.keys.add(key);
            }
            else {
                this.current.keys.add(key);
            }
        }
    }
    /** Removes keys that are the next-up to be expired, based on their ttl. */
    trim() {
        const keys = this.current.keys;
        for (const key of keys) {
            this.sdk.caches.remove(key);
        }
        this.cycle();
    }
    /**
     * Makes the current bucket of keys to-be-removed equal to the next bucket.
     * Makes the next bucket be a fresh set.
     */
    cycle() {
        this.current = this.next;
        this.next = {
            id: this.current.id === 0 ? 1 : 0,
            expiry: now() + this.ttl,
            keys: new Set()
        };
    }
    /**
     * @param base: optional. The timestamp to use as the base time. Defaults to the time of calling.
     * @return: the ID of the particular trim bucket to be placed into, based on the current trim and the current time.
     */
    slot(base) {
        /*
        This diagram gives a general picture:
    
           |--------------------|
          |--------------------|                 |--------------------|
        |--------------------|
        |--------------------|--------------------|--------------------|
        t0                 t0 + ttl            t0 + 2ttl           t0 + 3ttl
    
        A new addition will always be slotted to the next bucket, unless its ttl perfectly lines up with the start of this bucket.
        */
        const expiry = typeof base === "number" ? base + this.ttl : now() + this.ttl;
        if (expiry > this.current.expiry) {
            return this.next.id;
        }
        return this.current.id;
    }
}

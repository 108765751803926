import { useMadSDKList, useMadSDKSingular } from "../../../hooks/madSDK";
import { FilterTypes, ServiceStatus } from "@madhive/mad-sdk";
/**
 * Hook that returns an Advertiser
 * @param id id of the campaign
 * @param options
 * @returns {SingularData<Advertiser>}
 */
export const useAdvertiser = (id, options) => useMadSDKSingular("advertisers", id, options);
/**
 * Hook that returns a list of all advertisers
 * @param options
 * @returns {ListData<Advertiser>}
 */
export const useAdvertisers = (options) => useMadSDKList("advertisers", options);
/**
 * Hook that returns an array of advertisers matching the provided ids
 * @param ids List of ids to search for
 * @returns {ListData<Advertiser>}
 */
export const useAdvertisersByIds = (ids) => useAdvertisers({
    filters: { where: ids.map((id) => ({ field: "id", type: FilterTypes.EQ, value: id })) }
});
/**
 * Hook that returns an array of active (non-archived) Advertisers
 * @param options
 * @returns {ListData<Advertiser>}
 */
export const useActiveAdvertisers = (options) => {
    const advertisers = useAdvertisers(options);
    return {
        ...advertisers,
        data: advertisers.data?.filter((advertiser) => advertiser.status !== ServiceStatus.ARCHIVED)
    };
};

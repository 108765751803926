import { init, configureScope, getCurrentHub } from "@sentry/browser";
import { Integrations, SpanStatus } from "@sentry/tracing";
import SentryFullStory from "@sentry/fullstory";
import axios from "axios";
import { SENTRY_DSN, __DEV__ } from "lib/constants/config";
import { madSDK } from "lib/sdk";
import { REACT_APP_GIT_COMMIT } from "lib/viteEnvConfig";
import { Account } from "@madhive/mad-sdk";

// eslint-disable-next-line import/prefer-default-export
export function initializeSentry() {
  if (__DEV__) return;
  if (
    document.location.host === "127.0.0.1" ||
    document.location.host === "localhost"
  )
    return;

  const projectId = madSDK.config.googleEndpointProjectId;
  const gitCommit = REACT_APP_GIT_COMMIT;
  console.log("Sentry release", gitCommit);
  init({
    dsn: SENTRY_DSN,
    release: gitCommit,
    environment: projectId,
    ignoreErrors: [
      "ResizeObserver loop",
      "this error is OK",
      "Request aborted",
      "Network Error"
    ],
    beforeSend: (event, hint) => {
      const error = hint?.originalException as Error;

      if (axios.isCancel(error)) {
        return null;
      }

      return event;
    },
    integrations: [
      new Integrations.BrowserTracing({
        beforeNavigate: context => ({
          ...context,
          // Urls have IDs within them the follow replaces those ids
          // with <id> to group url errors better.
          name: window.location.pathname.replace(/[a-z0-9A-Z]{28,31}/g, "<id>")
        })
      }),
      new SentryFullStory("madhive")
    ],
    tracesSampleRate: 0.1
  });
  madSDK.authentication.currentAccount.subscribe((account: Account) => {
    configureScope(scope => {
      if (account?.user) {
        scope.setUser({
          email: account.user.email
        });
      } else {
        scope.setUser(null);
      }
    });
  });
}

export function startSpan(op: string, description?: string) {
  const transaction = getCurrentHub().getScope()?.getTransaction();
  const span = transaction?.startChild({
    op,
    description
  });

  return {
    ok: () => {
      span?.setStatus(SpanStatus.Ok);
      span?.finish();
    },
    error: () => {
      span?.setStatus(SpanStatus.UnknownError);
      span?.finish();
    }
  };
}

import { ArchiveFilter } from "@madhive/mad-sdk";
import { RadioGroup } from "frontier/lib/kit";
import { FC } from "react";

export const ArchiveStatus = [
  {
    label: "Show Active Items Only",
    value: ArchiveFilter.ACTIVE
  },
  {
    label: "Show Archived Items Only",
    value: ArchiveFilter.ARCHIVED
  },
  {
    label: "Show Both Active and Archived Items",
    value: ArchiveFilter.BOTH
  }
];

export const ArchiveStatusSelector: FC<{
  onSelect: (value: string | null) => void;
  selected: string | null;
}> = ({ onSelect, selected }) => {
  return (
    <RadioGroup
      options={ArchiveStatus}
      selectedValue={selected}
      onChange={val => {
        console.log("VAL", val);
        onSelect(val);
      }}
      lookupLabel={i => i?.label}
      lookupValue={i => i?.value}
      stacked
    />
  );
};

import { IToastProps } from "@blueprintjs/core";
import {
  ClientPlanHydrated,
  ClientScenarioResultsHydrated
} from "@madhive/mad-sdk";

export const REFRESH_PLAN_METADATA_FAILURE = "REFRESH_PLAN_METADATA_FAILURE";
export const REFRESH_PLAN_METADATA_SUCCESS = "REFRESH_PLAN_METADATA_SUCCESS";
export const GET_PLAN_METADATA_DATA_PENDING = "GET_PLAN_METADATA_DATA_PENDING";

export const REFRESH_SINGLE_PLAN_SUCCESS = "REFRESH_SINGLE_PLAN_SUCCESS";

export const GET_SCENARIO_RESULTS_SUCCESS = "GET_SCENARIO_RESULTS_SUCCESS";

export interface PlannerState {
  readonly planMetadata: ClientPlanHydrated[];
  readonly receivedPlanMetadataAt: number | null;
  readonly isPlanMetadataLoadingFirstFetch: boolean;
  readonly isPlanMetadataError: boolean;
  readonly scenarioResults: Record<string, ClientScenarioResultsHydrated>;
}

interface GetAllPlanMetadataPendingAction {
  type: typeof GET_PLAN_METADATA_DATA_PENDING;
}

export interface GetAllPlanMetadataSuccessAction {
  type: typeof REFRESH_PLAN_METADATA_SUCCESS;
  payload: ClientPlanHydrated[];
  meta: {
    timestamp: number;
  };
}

export interface GetSinglePlanMetadataSuccessAction {
  type: typeof REFRESH_SINGLE_PLAN_SUCCESS;
  payload: ClientPlanHydrated;
  meta: {
    timestamp: number;
  };
}

interface GetAllPlanMetadataFailureAction {
  type: typeof REFRESH_PLAN_METADATA_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface GetScenarioResultSuccessAction {
  type: typeof GET_SCENARIO_RESULTS_SUCCESS;
  payload: ClientScenarioResultsHydrated[];
  meta: {
    planId: string;
  };
}

export type PlannerActionTypes =
  | GetAllPlanMetadataSuccessAction
  | GetAllPlanMetadataFailureAction
  | GetAllPlanMetadataPendingAction
  | GetSinglePlanMetadataSuccessAction
  | GetScenarioResultSuccessAction;

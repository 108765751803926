import { FC } from "react";
import { CalendarWeekProps } from "./types";
import { CalendarDay } from "./Day";
import { styles } from "./styles";

export const CalendarWeek: FC<CalendarWeekProps> = ({ week, selectDay }) => {
  return (
    <div className="subKit-CalendarWeek" css={styles.week}>
      {week.map(
        (
          { date, isSelected, isInRange, isDisabled, isEmpty, error },
          dayIndex
        ) => (
          <CalendarDay
            key={dayIndex}
            isSelected={isSelected}
            selectDay={selectDay}
            date={date}
            isInRange={isInRange}
            isDisabled={isDisabled}
            isEmpty={isEmpty}
            error={error}
          />
        )
      )}
    </div>
  );
};

export enum LogicalOperator {
  INCLUDE = "include",
  EXCLUDE = "exclude"
}

export enum DAYPART_DRAG_MODE {
  INACTIVE = 0,
  ADDING = 1,
  REMOVING = 2
}

export enum DayOfTheWeek {
  SUNDAY = "Sunday",
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday"
}

export type Daypart = {
  day: DayOfTheWeek;
  hours: number[];
};

export type ClientDayparts = Record<DayOfTheWeek, number[]>;

export type DayparterProps = {
  /** Array of dayparts that represents current selection */
  dayparts: Daypart[] | undefined;
  /**
   * Whether the hours on provided dayparts represent hours that should be included or excluded
   * - if true, the hours on provided dayparts represent hours that should be excluded
   * @default false
   */
  daypartsIsExclusive?: boolean;
  /**
   * handler called when dayparts are changed
   * @param dayparts - new dayparts
   * @param currentOperator - current logical operator
   */
  onChange: (dayparts: Daypart[], currentOperator: LogicalOperator) => void;
  /**
   * current logical operator
   * @default LogicalOperator.INCLUDE
   */
  operator?: LogicalOperator;
  /**
   * called when the logical operator (include | exclude) changes
   * if undefined, operator radio buttons will not appear
   * @param operator - new logical operator
   * @param prevOperator - previous logical operator
   */
  onOperatorChange?:
    | ((operator: LogicalOperator, prevOperator: LogicalOperator) => void)
    | undefined
    | false;
  /**
   * whether the daypart selected hours should be inverted when the logical operator changes
   */
  invertOnOperatorChange?: boolean;
  /** whether component is in readonly mode or not */
  readonly?: boolean;
  /**
   * aria-label for Daryparter fieldset group
   * @default "Dayparts"
   */
  groupLabel?: string;
  /**
   * whether Operator radio options are shown or not
   * (`onOperatorChange` implies this is true)
   * @default false
   */
  showOperatorOptions?: boolean;
  /**
   * the message to display to the user whenever an error is detected
   */
  error?: string;
};

export type DaypartProps = Pick<
  DayparterProps,
  "daypartsIsExclusive" | "operator" | "readonly"
> & {
  /** day's index */
  day: DayOfTheWeek | -1;
  /** hour's index */
  hour: number;
  /** called when daypart is moused down */
  onMouseDown: () => void;
  /** called when enters daypart */
  onMouseEnter: () => void;
  /** whether the hour is present in the daypart's hours set */
  dayHoursHasHour?: boolean;
};

import { FC } from "react";
import {
  Popover,
  IPopoverSharedProps,
  IOverlayLifecycleProps
} from "@blueprintjs/core";
import { ClassNames } from "@emotion/react";
import { FlyoutProps } from "frontier/lib/kit/types";

type SmithersFlyoutProps = Omit<FlyoutProps, "keepOpen" | "onOpen"> & {
  onClosed?: IOverlayLifecycleProps["onClosed"];
  onClosing?: IOverlayLifecycleProps["onClosing"];
  onOpened?: IOverlayLifecycleProps["onOpened"];
  onOpening?: IOverlayLifecycleProps["onOpening"];
};

export const Flyout: FC<SmithersFlyoutProps> = props => {
  const {
    isOpen,
    target,
    offset = 0,
    children,
    arrow = false,
    boundary = "viewport",
    hasBackdrop = true,
    portalContainer,
    usePortal,
    position,
    lazy,
    dismissAncestors: captureDismiss,
    onClose,
    onClosed,
    onClosing: propsOnClosing,
    onInteraction,
    onOpened,
    onOpening: propsOnOpening
  } = props;
  const modifiers: IPopoverSharedProps["modifiers"] = {
    offset: { offset, enabled: typeof offset !== "undefined" },
    arrow: { enabled: arrow }
  };

  /**
   * need to account for scroll bars when hasBackdrop === true and document.body has overflow,
   * by adding padding to body
   */
  const onOpening: IOverlayLifecycleProps["onOpening"] = hasBackdrop
    ? node => {
        const body = document.body;
        if (
          body.scrollHeight > body.clientHeight ||
          body.scrollWidth > body.clientWidth
        ) {
          body.style.paddingRight = "15px";
        }
        if (typeof propsOnOpening === "function") {
          propsOnOpening(node);
        }
      }
    : propsOnOpening;

  /**
   * need remove padding on document.body if added onOpening
   */
  const onClosing: IOverlayLifecycleProps["onClosing"] = hasBackdrop
    ? node => {
        const body = document.body;
        if (body.style.paddingRight) {
          body.style.removeProperty("padding-right");
        }
        if (typeof propsOnClosing === "function") {
          propsOnClosing(node);
        }
      }
    : propsOnClosing;

  return (
    <ClassNames>
      {({ css, cx }) => (
        <Popover
          isOpen={isOpen}
          portalContainer={portalContainer}
          usePortal={usePortal}
          position={position}
          lazy={lazy}
          captureDismiss={captureDismiss}
          transitionDuration={150}
          modifiers={modifiers}
          content={children}
          target={target}
          boundary={boundary}
          hasBackdrop={hasBackdrop}
          onOpening={onOpening}
          onClosing={onClosing}
          onClose={onClose}
          onOpened={onOpened}
          onClosed={onClosed}
          onInteraction={onInteraction}
          popoverClassName={cx(
            "smithers-Flyout",
            css`
              box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
                0px 8px 10px 1px rgba(0, 0, 0, 0.14),
                0px 3px 14px 2px rgba(0, 0, 0, 0.12);
              border-radius: 4px;
            `
          )}
        >
          {undefined}
        </Popover>
      )}
    </ClassNames>
  );
};

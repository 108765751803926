import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";

const Exclamation: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-circled-exclamation"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M12 6.75C12.4142 6.75 12.75 7.08579 12.75 7.5V12.5C12.75 12.9142 12.4142 13.25 12 13.25C11.5858 13.25 11.25 12.9142 11.25 12.5V7.5C11.25 7.08579 11.5858 6.75 12 6.75Z" />
      <path d="M11.999 15.25C11.4487 15.25 10.9975 15.6965 11 16.2527C11.0015 16.8037 11.4487 17.25 12 17.25C12.5522 17.25 13 16.8022 13 16.25C13 15.6958 12.5503 15.25 11.999 15.25Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21.75C6.61479 21.75 2.25 17.3852 2.25 12C2.25 6.61479 6.61479 2.25 12 2.25C17.3852 2.25 21.75 6.61479 21.75 12C21.75 17.3852 17.3852 21.75 12 21.75ZM12 3.75C7.44321 3.75 3.75 7.44321 3.75 12C3.75 16.5568 7.44321 20.25 12 20.25C16.5568 20.25 20.25 16.5568 20.25 12C20.25 7.44321 16.5568 3.75 12 3.75Z"
      />
    </svg>
  );
};

export default Exclamation;

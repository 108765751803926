import { FC, SVGProps } from "react";

export const DefaultDownloadIcon: FC<SVGProps<number | string>> = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="download-icon"
  >
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 1C4.224 1 4 1.2245 4 1.5V28.5C4 28.7755 4.224 29 4.5 29H25.5C25.776 29 26 28.7755 26 28.5V5.707L21.293 1H4.5ZM25.5 30H4.5C3.673 30 3 29.327 3 28.5V1.5C3 0.673 3.673 0 4.5 0H21.5C21.633 0 21.76 0.0525 21.8535 0.1465L26.8535 5.1465C26.9475 5.24 27 5.3675 27 5.5V28.5C27 29.327 26.327 30 25.5 30Z"
        fill="#60656C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22 4.99993H25.293L22 1.70693V4.99993ZM26.5 5.99993H21.5C21.2235 5.99993 21 5.77593 21 5.49993V0.499933C21 0.297933 21.1215 0.115433 21.3085 0.0379335C21.496 -0.0390665 21.7105 0.00343345 21.8535 0.146433L26.8535 5.14643C26.9965 5.28943 27.0395 5.50443 26.962 5.69143C26.885 5.87793 26.702 5.99993 26.5 5.99993Z"
        fill="#60656C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 24.5001H9.5C9.2235 24.5001 9 24.2761 9 24.0001C9 23.7241 9.2235 23.5001 9.5 23.5001H20.5C20.7765 23.5001 21 23.7241 21 24.0001C21 24.2761 20.7765 24.5001 20.5 24.5001Z"
        fill="#60656C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 20.5001C14.7235 20.5001 14.5 20.2761 14.5 20.0001V6.00006C14.5 5.72406 14.7235 5.50006 15 5.50006C15.2765 5.50006 15.5 5.72406 15.5 6.00006V20.0001C15.5 20.2761 15.2765 20.5001 15 20.5001Z"
        fill="#60656C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0001 20.5001C14.8721 20.5001 14.7441 20.4511 14.6466 20.3536L10.1466 15.8536C9.95113 15.6581 9.95113 15.3421 10.1466 15.1466C10.3421 14.9511 10.6581 14.9511 10.8536 15.1466L15.0001 19.2931L19.1466 15.1466C19.3421 14.9511 19.6581 14.9511 19.8536 15.1466C20.0491 15.3421 20.0491 15.6581 19.8536 15.8536L15.3536 20.3536C15.2561 20.4511 15.1281 20.5001 15.0001 20.5001Z"
        fill="#60656C"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import { css } from "@emotion/react";
import { kitText } from "../../styles";

export const styles = {
  base: css`
    fieldset {
      padding: 0;
      margin: 0;
    }
  `,
  label: css`
    margin-bottom: var(--spacing-4);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    white-space: nowrap;
    overflow: hidden;
  `,
  labelTruncate: css`
    white-space: unset;
    word-break: break-all;
    overflow: hidden;
  `,
  requiredAsterisk: css`
    ${kitText.color.error}
    margin-left: 0.125rem;
  `,
  description: css`
    margin-top: var(--spacing-8);
    width: 100%;
    ${kitText.variant.body}

    // this prevents applying an excessive amount of bottom margin, when the description is the only child
    // ... and there are no other elements between it and the bottom border of its container
    & + * {
      margin-top: var(--spacing-16);
    }
  `,
  error: css`
    margin-top: var(--spacing-4);
  `,
  fill: css`
    width: 100%;
  `,
  labelSuffix: css`
    ${kitText.variant.body}
    margin-left: 0 0 var(--spacing-4) auto;
    width: 100%;
    padding-left: var(--spacing-4);
    display: flex;
    gap: var(--spacing-4);
    --icon-fill-color: var(--color-secondary);
    .bp4-popover-wrapper {
      // needed to prevent misalignment of the target element
      line-height: 0;
    }
  `,
  hidden: css`
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  `
};

import { FilterTypes, FilterValueTypes } from "../../handlers/filter";
import { AttributionDashboardOptionsFailedFetchError, NotImplementedError, UnauthenticatedError } from "../../../errors";
import axios from "axios";
import { Handler } from "../../handlers";
import { ApiVersion } from "../types";
import { buildUrl } from "../utils";
import { validateFilterStructure } from "../../handlers/filter";
import { toQueryParams, validateFilterContent } from "./query";
export class AttributionPreFilters extends Handler {
    constructor(sdk) {
        super(sdk, "attribution-dashboard-pre-filters");
    }
    async findItems(filters) {
        const email = this.sdk.getCurrentUser()?.email;
        if (!email) {
            throw new UnauthenticatedError();
        }
        validateFilterStructure(filters, [
            { filterType: FilterTypes.EQ, valueType: FilterValueTypes.STRING },
            { filterType: FilterTypes.EQ, valueType: FilterValueTypes.OBJECT },
            { filterType: FilterTypes.IN, valueType: FilterValueTypes.OBJECT }
        ]);
        validateFilterContent(filters);
        const url = buildUrl(this.sdk, ApiVersion.V2, `attribution-dashboard/pre-filters?${toQueryParams(
        // email filter currently necessary for server side authorization
        {
            ...filters,
            // we can rely on filters.where to be defined since validateFilterContent will throw an error if it's not
            where: [...filters.where, { field: "email", type: FilterTypes.EQ, value: email }]
        })}`);
        return this.cache.promise(url, () => axios
            .get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
            return [response.data.attribution_dashboard_prefilters];
        })
            .catch((error) => {
            throw new AttributionDashboardOptionsFailedFetchError(error);
        }));
    }
    async saveItem() {
        throw new NotImplementedError("saveItem");
    }
    async deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
}

import { parsePagingQuery } from "../../handlers/filter";
import { AdvancedExportStatuses } from "../../../types";
export const buildSort = (sort) => {
    let direction = sort.direction;
    if (direction === "ascending") {
        direction = "asc";
    }
    else if (direction === "descending") {
        direction = "desc";
    }
    return `${sort.field} ${direction}`;
};
/**
 * @param sort: information on the field and direction of sorting, if any.
 * @return: a properly-formatted query string, based on the provided sorting info. Empty if there is no sort.
 */
const sortToQueryParam = (sort) => {
    let params = "";
    if (sort && sort.field && sort.direction) {
        params = `${params}&sortBy=${buildSort(sort)}`;
    }
    return params;
};
const wheresToQueryParams = (wheres) => {
    let params = "";
    if (wheres) {
        wheres.forEach((filter) => {
            switch (filter.field) {
                case "search": {
                    params = `${params}&search=${filter.value}`;
                    break;
                }
                case "status": {
                    params = `${params}&status=${filter.value}`;
                    break;
                }
            }
        });
    }
    return params;
};
/**
 * @param filters: array of SDK-style isntruction filters.
 * @param sort: optional. Information on the field and direction of sorting, if any.
 * @return: a proper query string for the supplied filters.
 */
export const toQueryParams = (filters, sort) => {
    const params = `${wheresToQueryParams(filters.where)}${parsePagingQuery(filters.paging)}${sortToQueryParam(sort)}`;
    return params.length ? `?${params}` : "";
};
/**
 * @param filters: array of SDK-style isntruction filters.
 * @return: body to send with http post request
 */
export const buildBody = (filters) => {
    const archiveStatus = filters.where?.find((filter) => filter.field === "status")?.value || "";
    if (archiveStatus === "both") {
        return {
            statuses: [
                AdvancedExportStatuses.ARCHIVED,
                AdvancedExportStatuses.CANCELLED,
                AdvancedExportStatuses.DRAFT,
                AdvancedExportStatuses.PAUSED,
                AdvancedExportStatuses.READY
            ]
        };
    }
    else if (archiveStatus === "archived") {
        return {
            statuses: [AdvancedExportStatuses.ARCHIVED]
        };
    }
    else {
        return {
            statuses: [AdvancedExportStatuses.READY]
        };
    }
};

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Refresh: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-refresh"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M17.127 6.873a7.25 7.25 0 0 0-10.254 0 7.219 7.219 0 0 0-2.12 5.009.75.75 0 0 1-1.499-.024 8.719 8.719 0 0 1 2.559-6.045 8.75 8.75 0 0 1 12.925.605V4.64a.75.75 0 0 1 1.5 0v3.536a.75.75 0 0 1-.75.75h-3.535a.75.75 0 0 1 0-1.5h1.67a7.103 7.103 0 0 0-.496-.552ZM19.995 11.282a.75.75 0 0 1 .747.753 8.715 8.715 0 0 1-2.555 6.152 8.75 8.75 0 0 1-12.925-.605v1.779a.75.75 0 0 1-1.5 0v-3.536a.75.75 0 0 1 .75-.75h3.535a.75.75 0 0 1 0 1.5h-1.67a7.25 7.25 0 0 0 10.75.552 7.216 7.216 0 0 0 2.115-5.098.75.75 0 0 1 .753-.747Z" />
    </svg>
  );
};

export default Refresh;

import {
  GeographicEntityTypes,
  GeographyTargetingDetails,
  GeographyTargets,
  LineItem,
  satoshisToDollars
} from "@madhive/mad-sdk";
import { IndividualRegion } from "appReducers/geographyReducer";
import { ShallowLineItem } from "campaign-types";
import { GeoSelectionProps } from "components/Reusable/GeoSelection/GeoSelection";
import { getPacingStrategy } from "frontier/lib/campaigns/lineitems/utils/targeting";
import { PaceStrategy } from "types";
import { BulkEditFields, ROW_ID_DELIMITER } from "./types";

/** Key name conversions for geo data. */
const geosToLineItemKeys: Record<
  GeographicEntityTypes,
  keyof GeographyTargets
> = {
  country: "country",
  state: "regions",
  dma: "dmas",
  district: "districts",
  zipCode: "postalCodes"
};

export const verifyGeoRulesForBackend = (
  bulkEditChanges: Partial<LineItem>
) => {
  // If regions or postalCodes is excluded, a country is require in the exclude object.
  // Otherwise errors with: "Must include either DMA, country with postals or regions, or congressional districts"
  // We always need a country in the include object, even selecting only excluded fields.
  // Otherwise errors with: "Cannot have geo exclusion without positive geo targeting".
  const geoTargeting = bulkEditChanges?.geoTargeting;
  if (geoTargeting) {
    if (
      !geoTargeting.exclude.country &&
      (geoTargeting.exclude.regions?.length > 0 ||
        geoTargeting.exclude.postalCodes?.length > 0)
    ) {
      geoTargeting.exclude.country = geoTargeting.include.country;
    }
  }
};

/** Translates the geo data shape from GeographicSelection for BulkEditLineItems.
 * Using promise to complete the translation before any action.
 * @param selectedGeos data for display in GeoSelectionV2 component
 * @return Converted geo data shape for LineItem
 */
export const translateGeoToLineItem = (
  selectedGeos: GeoSelectionProps
): GeographyTargetingDetails => {
  return Object.entries(selectedGeos).reduce(
    (accumulator, [key, geographicSelection]) => {
      const operation: "include" | "exclude" =
        geographicSelection.logicalOperator || "include";
      const keyNameTranslation =
        geosToLineItemKeys[key as GeographicEntityTypes];
      const geoSelectionValues = geographicSelection.values;
      let newValue: string | number[] | string[];

      if (key === "country") {
        newValue = geographicSelection.id as string;
      } else if (key === "dma") {
        newValue = geoSelectionValues?.map((value: IndividualRegion) =>
          parseInt(value.id, 10)
        ) as number[];
      } else {
        newValue = geoSelectionValues?.map(
          (value: IndividualRegion) => value.id
        ) as string[];
      }

      return {
        ...accumulator,
        [operation]: {
          ...accumulator[operation],
          [keyNameTranslation]: newValue
        }
      };
    },
    {
      include: {
        country: "",
        regions: [],
        districts: [],
        dmas: [],
        postalCodes: []
      },
      exclude: {
        country: "",
        regions: [],
        districts: [],
        dmas: [],
        postalCodes: []
      }
    } as GeographyTargetingDetails
  );
};

export const increaseNumberByPercent = (number: number, percent: number) =>
  Math.round(number + (percent / 100) * number);

export const createBulkEdits = (
  bulkEditChanges: Partial<LineItem>,
  selectedChangeIds: Set<string>,
  lineItems: ShallowLineItem[]
) => {
  const bulkEdits: Record<string, Partial<LineItem>> = {};
  selectedChangeIds.forEach(selectedChangeId => {
    const selectedChange = selectedChangeId.split(ROW_ID_DELIMITER);
    const lineItemId = selectedChange[0];
    const fieldKey = selectedChange[1] as keyof LineItem;

    if (!bulkEdits[lineItemId]) {
      bulkEdits[lineItemId] = {
        id: lineItemId,
        /**
         * We need to include parent id in edits so that when a firestore
         * notification is created, we can display text that shows how
         * campaigns are affected
         * */
        parent: lineItems.find(({ id }) => id === lineItemId)?.parent
      };
    }

    const matchingLineItem = lineItems.find(item => item.id === lineItemId);
    let fieldValue;

    switch (fieldKey) {
      case "bookedImpressions":
        fieldValue = increaseNumberByPercent(
          matchingLineItem?.bookedImpressions || 0,
          bulkEditChanges?.[fieldKey] || 0
        );

        break;

      case "budget":
        fieldValue = satoshisToDollars(
          increaseNumberByPercent(
            matchingLineItem?.budget || 0,
            bulkEditChanges?.[fieldKey] || 0
          )
        );

        break;

      default:
        fieldValue = bulkEditChanges[fieldKey];
    }

    // TODO: Figure out the best way to add some type safety here
    bulkEdits[lineItemId][fieldKey] = fieldValue as any;
  });

  return Object.values(bulkEdits);
};

export const generateErrorCsvRows = (
  errors: string[],
  lineItems: Array<Partial<LineItem>>
): string[][] => {
  const rows: string[][] = [];

  lineItems.forEach(({ id }) => {
    if (id) {
      const liErrors: string[] = [];

      errors.forEach(err => id && err.includes(id) && liErrors.push(err));

      liErrors.length && rows.push([id, liErrors.join(", ")]);
    }
  });

  return rows;
};

export const numberComparator = (a: number, b: number) => a - b;

/**
 * Returns the fields that can be bulk edited from a given set of line items.
 * @returns a promise that resolves to an object, where each key/value pair represents an editable field name/label.
 */
export const getBulkEditFieldsFrom = (
  lineItems: ShallowLineItem[]
): BulkEditFields => {
  const bulkEditFields = {
    bookedImpressions: "Increase Impressions",
    budget: "Increase Budget",
    publisherGroupId: "Publisher Targeting",
    frequencies: "Frequency Cap",
    deviceCaps: "Device Distribution",
    dayparts: "Daypart Scheduling",
    segments: "Audience",
    geoTargeting: "Geographic Selection"
  } as Partial<Record<keyof LineItem, string>>;

  const hasBudgetLineItems = lineItems.some(lineItem => {
    // TODO: After CAMP-90 is completed - Replace with isPacingToBudgetLegacy()
    return (
      getPacingStrategy(lineItem as Pick<LineItem, "budget">) ===
      PaceStrategy.BUDGET
    );
  });

  const hasImpressionLineItems = lineItems.some(lineItem => {
    // TODO: After CAMP-90 is completed - Replace with isPacingToImpressionsLegacy()
    return (
      getPacingStrategy(lineItem as Pick<LineItem, "budget">) ===
      PaceStrategy.IMPRESSIONS
    );
  });

  // n.b. we delete instead of adding in order to preserve the order of the fields
  if (!hasBudgetLineItems) {
    delete bulkEditFields.budget;
  }

  if (!hasImpressionLineItems) {
    delete bulkEditFields.bookedImpressions;
  }

  return bulkEditFields;
};

export class SessionAcquireFailure extends Error {
    /** @param message: the error message, if any. */
    constructor(message) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, SessionAcquireFailure);
        }
        this.name = "SessionAcquireFailure";
        this.message = `Looker failed to acquire an embed session.${message ? ` Message: ${message}.` : ""}`;
    }
}
export class SessionRegenerationFailure extends Error {
    /** @param message: the error message, if any. */
    constructor(message) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, SessionRegenerationFailure);
        }
        this.name = "SessionRegenerationFailure";
        this.message = `Looker failed to regenerate tokens for an embed session.${message ? ` Message: ${message}.` : ""}`;
    }
}

import { FC, SVGProps } from "react";

export const DefaultReleaseIcon: FC<SVGProps<number | string>> = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="release-icon"
  >
    <path
      d="M10.1467 9.35348C10.2404 9.44725 10.3676 9.49995 10.5002 9.49998H10.5357C10.6805 9.48938 10.8135 9.41639 10.9002 9.29998L13.9002 5.29998C14.0074 5.15707 14.0302 4.96784 13.96 4.80357C13.8898 4.63929 13.7374 4.52493 13.56 4.50357C13.3827 4.4822 13.2074 4.55707 13.1002 4.69998L10.4462 8.23898L9.8537 7.64648C9.65751 7.45699 9.34566 7.4597 9.15279 7.65257C8.95993 7.84544 8.95722 8.15729 9.1467 8.35348L10.1467 9.35348Z"
      fill="#60656C"
    />
    <path
      d="M29.5 24H25V3.5C24.9978 1.56792 23.4321 0.00220432 21.5 0H3.5C1.56792 0.00220432 0.00220432 1.56792 0 3.5V10.5C0 10.7761 0.223858 11 0.5 11H6V26.5C6.0022 28.4321 7.56792 29.9978 9.5 30H26.5C28.4321 29.9978 29.9978 28.4321 30 26.5V24.5C30 24.2239 29.7761 24 29.5 24ZM1 10V3.5C1 2.11929 2.11929 1 3.5 1C4.88071 1 6 2.11929 6 3.5V10H1ZM7 26.5V3.5C7.00012 2.55844 6.61964 1.65681 5.945 1H21.5C22.88 1.00165 23.9983 2.11997 24 3.5V24H12.5C12.2239 24 12 24.2239 12 24.5V26.5C12 27.8807 10.8807 29 9.5 29C8.11929 29 7 27.8807 7 26.5ZM29 26.5C28.9983 27.88 27.88 28.9983 26.5 29H11.945C12.6196 28.3432 13.0001 27.4416 13 26.5V25H29V26.5Z"
      fill="#60656C"
    />
    <path
      d="M15.5 8.00006H21.5C21.7761 8.00006 22 7.7762 22 7.50006C22 7.22392 21.7761 7.00006 21.5 7.00006H15.5C15.2239 7.00006 15 7.22392 15 7.50006C15 7.7762 15.2239 8.00006 15.5 8.00006Z"
      fill="#60656C"
    />
    <path
      d="M10.1467 15.3534C10.2404 15.4472 10.3676 15.4999 10.5002 15.4999H10.5357C10.6805 15.4893 10.8135 15.4164 10.9002 15.2999L13.9002 11.2999C14.0659 11.079 14.0211 10.7656 13.8002 10.5999C13.5793 10.4343 13.2659 10.479 13.1002 10.6999L10.4462 14.2389L9.8537 13.6464C9.65751 13.457 9.34566 13.4597 9.15279 13.6525C8.95993 13.8454 8.95722 14.1573 9.1467 14.3534L10.1467 15.3534Z"
      fill="#60656C"
    />
    <path
      d="M15.5 14.0001H21.5C21.7761 14.0001 22 13.7762 22 13.5001C22 13.2239 21.7761 13.0001 21.5 13.0001H15.5C15.2239 13.0001 15 13.2239 15 13.5001C15 13.7762 15.2239 14.0001 15.5 14.0001Z"
      fill="#60656C"
    />
    <path
      d="M10.5002 21.4999H10.5357C10.6805 21.4893 10.8135 21.4164 10.9002 21.2999L13.9002 17.2999C14.0659 17.079 14.0211 16.7656 13.8002 16.5999C13.5793 16.4343 13.2659 16.479 13.1002 16.6999L10.4462 20.2389L9.8537 19.6464C9.65751 19.457 9.34566 19.4597 9.15279 19.6525C8.95993 19.8454 8.95722 20.1572 9.1467 20.3534L10.1467 21.3534C10.2404 21.4472 10.3676 21.4999 10.5002 21.4999Z"
      fill="#60656C"
    />
    <path
      d="M15.5 20.0001H21.5C21.7761 20.0001 22 19.7762 22 19.5001C22 19.2239 21.7761 19.0001 21.5 19.0001H15.5C15.2239 19.0001 15 19.2239 15 19.5001C15 19.7762 15.2239 20.0001 15.5 20.0001Z"
      fill="#60656C"
    />
  </svg>
);

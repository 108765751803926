import {
  PublishersState,
  PublishersActionTypes,
  GET_ALL_PUBLISHERS_PENDING,
  GET_ALL_PUBLISHERS_FAILURE,
  GET_ALL_PUBLISHERS_SUCCESS,
  ABORT_GET_ALL_PUBLISHERS_REQUEST
} from "./publishersTypes";

export const initialState: PublishersState = {
  byId: {},
  isAllLoading: false,
  receivedAllAt: null,
  error: null
};

export const publishersReducer = (
  state = initialState,
  action: PublishersActionTypes
): PublishersState => {
  switch (action.type) {
    case GET_ALL_PUBLISHERS_PENDING:
      return {
        ...state,
        isAllLoading: true,
        error: null
      };
    case GET_ALL_PUBLISHERS_FAILURE:
      return {
        ...state,
        isAllLoading: false,
        error: action.meta.error.message
      };
    case GET_ALL_PUBLISHERS_SUCCESS: {
      const publishers = action.payload;
      return {
        ...state,
        byId: publishers.reduce((byId, publisher) => {
          // eslint-disable-next-line no-param-reassign
          byId[publisher.id] = publisher;
          return byId;
        }, {}),
        isAllLoading: false,
        receivedAllAt: action.meta.timestamp,
        error: null
      };
    }
    case ABORT_GET_ALL_PUBLISHERS_REQUEST:
      return {
        ...state,
        isAllLoading: false
      };
    default:
      return state;
  }
};

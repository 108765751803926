import { useEffect } from "react";
import { BehaviorSubject, Observable, isObservable as rxjsIsObservable } from "rxjs";
import { isDev } from "../../utils";
import { getHandler } from "../../utils/collections";
let interval;
const behaviorSubjectMap = new Map();
// TODO betterify the stringify with consistency e.g., {a,b} !== {b,a} but this works for now purposes
export const createKey = (options) => `${JSON.stringify(options.filters)}::${JSON.stringify(options.sort)}`;
export const getObservableFromData = (collection, options, value) => {
    if (isDev && !interval) {
        // @ts-ignore
        global.madHooksStore = behaviorSubjectMap;
    }
    const key = options.key || createKey(options);
    if (!behaviorSubjectMap.has(collection)) {
        behaviorSubjectMap.set(collection, new Map([[key, new BehaviorSubject(value)]]));
    }
    if (!behaviorSubjectMap.get(collection)?.has(key)) {
        behaviorSubjectMap.get(collection)?.set(key, new BehaviorSubject(value));
    }
    return {
        observable: behaviorSubjectMap.get(collection)?.get(key),
        key
    };
};
/**
 * Checks if there are no more listeners
 * @param collection Collection where the data is
 * @param options Optional 'where'/'pagination' filters and sort to apply to madSDK
 */
export const cleanupSubscribers = (collection, options) => {
    const key = options.key || createKey(options);
    const observable = behaviorSubjectMap.get(collection)?.get(key);
    if (observable && !observable.observed) {
        behaviorSubjectMap.get(collection)?.delete(key);
    }
};
/**
 * Checks if observable should be pruned from the collection
 * @param observable the observable to check
 * @param key the behaviorSubjectMap key
 * @param options modify the logic that determines if an observable should be pruned
 * @param options.singularCleanup -
 * - if singularCleanup is defined: only unobserved singular keys found in singularCleanup are removed
 * - if singularCleanup is undefined: all unobserved singular keys in behaviorSubjectMap are removed
 * @param options.identifierKey the identifier key to use for the collection — default: "id"
 */
const shouldPruneFromCollection = (observable, key, singularCleanup = undefined, identifierKey = "id") => {
    /** if observable is still being observed; then don't prune */
    if (observable.observed) {
        return false;
    }
    if (
    // singular keys to cleanup are provided
    singularCleanup?.size &&
        // and key is for a singular item
        key?.startsWith(`${identifierKey}:`) &&
        // and key is not in singularCleanup
        !singularCleanup.has(key)) {
        return false;
    }
    return true;
};
/**
 * Checks if collection has any observed data — if not, removes collection from the behaviorSubjectMap
 * @param collection Collection where the data is
 * @param {Set<string>} singularCleanup optional
 * - if singularCleanup is defined: only unobserved singular keys found in singularCleanup are removed
 * - if singularCleanup is undefined: all unobserved singular keys in behaviorSubjectMap are removed
 */
export const cleanupCollection = (collection, singularCleanup) => {
    const collectionMap = behaviorSubjectMap.get(collection);
    if (!collectionMap) {
        return;
    }
    const identifierKey = getHandler(collection).getIdentifierKey();
    for (const [key, observable] of collectionMap) {
        if (shouldPruneFromCollection(observable, key, singularCleanup, identifierKey)) {
            behaviorSubjectMap.get(collection)?.delete(key);
        }
    }
};
/**
 * Gets a handle into an observable for the data
 * @param collection Collection where the data is
 * @param options Optional 'where'/'pagination' filters and sort to apply to madSDK
 * @param initial Initial value to set in the cache
 * @returns
 */
export const useBehaviorSubject = (collection, options, initial) => {
    const { observable } = getObservableFromData(collection, options, initial);
    useEffect(() => () => {
        cleanupSubscribers(collection, options);
    }, []);
    return observable;
};
/**
 * Dangerously blows away the cache. Primarily used for unit testing and logout.
 */
export const resetRxjsCache = () => {
    behaviorSubjectMap.clear();
};
/**
 * Checks for any subscribers, used to test that unsubscribing is happening correctly on unmount
 * @returns boolean true if there are subscribers, false if not
 */
export const hasAnySubscribers = () => {
    for (const [, collectionMap] of behaviorSubjectMap) {
        for (const [, subject] of collectionMap)
            if (subject.observed) {
                return true;
            }
    }
    return false;
};
/**
 * Test util for getting the number of entries under a given mad-sdk collection cache key
 * */
export const getNumCacheEntries = (collection) => behaviorSubjectMap.get(collection)?.size || 0;
/**
 * Invalidates a collection so that the list data can be refreshed from the api
 */
export const invalidateCollection = (collection) => {
    const collectionMap = behaviorSubjectMap.get(collection);
    if (!collectionMap) {
        return;
    }
    for (const [key, observable] of collectionMap) {
        if (!observable.observed) {
            collectionMap.delete(key);
        }
        else {
            observable.next({ ...observable.value, isStale: true, isInitializing: true });
        }
    }
};
/**
 * This is a small wrapper of RxJS' exported `isObservable`, defaulting the generic to `DoNotCare`, and allowing the caller to control it, if need be.
 * This makes type narrowing actually work in alternative paths (for instance, in `if (isObservable(thing)) { } else { }`, the `else` branch will type narrow properly).
 * @param obj: anything.
 * @return: type narrowed. True if it is an observable. Else, false.
 * @template {T}: the type the Observable deals with. Defaults to `DoNotCare`.
 */
export const isObservable = (obj) => rxjsIsObservable(obj);
/**
 * Convert a Promise (or Observable) into an Observable.
 * @param promiseOrObservable: A Promise or an Observable.
 * @return: Observable. If an Observable is provided, it is returned.
 */
export const toObservable = (promiseOrObservable) => {
    if (isObservable(promiseOrObservable)) {
        return promiseOrObservable;
    }
    return new Observable((subscriber) => {
        promiseOrObservable
            .then((data) => {
            subscriber.next(data);
            subscriber.complete();
        })
            .catch((error) => subscriber.error(error));
    });
};

import { CommonIconProps } from "../Icon/types";

export default function ExternalLink({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}: CommonIconProps) {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.4578 2.40586C21.4969 2.43602 21.5328 2.46983 21.5649 2.50668C21.6802 2.63854 21.75 2.81112 21.75 3V10C21.75 10.4142 21.4142 10.75 21 10.75C20.5858 10.75 20.25 10.4142 20.25 10V4.81066L12.5303 12.5303C12.2374 12.8232 11.7626 12.8232 11.4697 12.5303C11.1768 12.2374 11.1768 11.7626 11.4697 11.4697L19.1893 3.75H14C13.5858 3.75 13.25 3.41421 13.25 3C13.25 2.58579 13.5858 2.25 14 2.25H21C21.0186 2.25 21.037 2.25068 21.0552 2.252C21.1336 2.25778 21.2118 2.27595 21.287 2.30709C21.349 2.33277 21.4062 2.36615 21.4578 2.40586ZM21.4578 2.40586C21.3448 2.31867 21.2062 2.263 21.0552 2.252Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 5C2.25 3.48079 3.48079 2.25 5 2.25H9C9.41421 2.25 9.75 2.58579 9.75 3C9.75 3.41421 9.41421 3.75 9 3.75H5C4.30921 3.75 3.75 4.30921 3.75 5V19C3.75 19.6908 4.30921 20.25 5 20.25H19C19.6908 20.25 20.25 19.6908 20.25 19V15C20.25 14.5858 20.5858 14.25 21 14.25C21.4142 14.25 21.75 14.5858 21.75 15V19C21.75 20.5192 20.5192 21.75 19 21.75H5C3.48079 21.75 2.25 20.5192 2.25 19V5Z"
      />
    </svg>
  );
}

import { useEffect } from "react";
import { ThunkDispatchWithReturnValue } from "types";
import axios from "axios";
import {
  getAllAdvertisers,
  getRunningAdvertisers,
  GetRunningAdvertiserProps,
  selectLastReceivedAdvertisers,
  selectAreAllAdvertisersLoading,
  selectAreRunningAdvertisersLoading
} from "appReducers/advertisersReducer";
import { selectCurrentUser } from "appReducers/authReducer/selectors";
import { useDispatch, useSelector } from "react-redux";

const useLoadAdvertisers = () => {
  const dispatch = useDispatch<ThunkDispatchWithReturnValue>();
  const user = useSelector(selectCurrentUser);
  const areAllAdvertisersLoading = useSelector(selectAreAllAdvertisersLoading);

  const wasAdvertisersGetSuccessful = useSelector(
    selectLastReceivedAdvertisers
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    const cancellationSource = axios.CancelToken.source();

    const loadAllAdvertisers = async () => {
      try {
        await dispatch(getAllAdvertisers());
      } catch (error) {
        console.error(error);
      }
    };

    if (!areAllAdvertisersLoading && !wasAdvertisersGetSuccessful) {
      loadAllAdvertisers();
    }

    return cancellationSource.cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useLoadAdvertisers;

export const useLoadRunningAdvertisers = ({
  runsBetween,
  campaignStatuses
}: GetRunningAdvertiserProps) => {
  const dispatch = useDispatch<ThunkDispatchWithReturnValue>();
  const user = useSelector(selectCurrentUser);
  const areRunningAdvertisersLoading = useSelector(
    selectAreRunningAdvertisersLoading
  );

  useEffect(() => {
    if (!user) {
      return;
    }

    const cancellationSource = axios.CancelToken.source();

    const loadRunningAdvertisers = async () => {
      try {
        await dispatch(
          getRunningAdvertisers({ runsBetween, campaignStatuses })
        );
      } catch (error) {
        console.error(error);
      }
    };
    if ((runsBetween || campaignStatuses) && !areRunningAdvertisersLoading) {
      loadRunningAdvertisers();
    }

    return cancellationSource.cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [runsBetween?.[0], runsBetween?.[1], campaignStatuses]);
};

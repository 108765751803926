import { css } from "@emotion/react";

export const styles = {
  wrapper: css`
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  `,
  isDisabled: css`
    color: var(--color-secondary);
  `
};

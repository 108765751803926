import { FC, useMemo, useRef } from "react";
import { InputGroup } from "@blueprintjs/core";
import { v4 as uuid } from "uuid";
import { useFocusOnMount } from "../../../hooks/useFocusOnMount";
import { InputDateSingleProps } from "../types";
import { useInputDateSingle } from "./hooks/useInputDateSingle";
import { DatePlaceholders } from "./constants";

/**
 * `<Input type="date" value={Date | undefined} />` allows user to input single date.
 */
export const InputDateSingle: FC<InputDateSingleProps> = ({
  value,
  error,
  id = `kit-${uuid()}`,
  disabled = false,
  focusOnMount = false,
  time = false,
  placeholder: incomingPlaceholder,
  pickerPlacement = "start",
  picker,
  name,
  min,
  max,
  isEndDate,
  onChange,
  onKeyDown,
  onKeyUp,
  onFocus,
  onBlur,
  setError,
  onClick,
  onInput
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { rawValue, setRawValue, commitChange } = useInputDateSingle({
    time,
    min,
    max,
    onChange,
    value,
    error,
    setError,
    isEndDate
  });

  useFocusOnMount(inputRef, focusOnMount);

  const placeholder = useMemo(() => {
    if (incomingPlaceholder) {
      return incomingPlaceholder;
    }
    return time ? DatePlaceholders.WITH_TIME : DatePlaceholders.SIMPLE;
  }, [time, incomingPlaceholder]);

  return (
    <InputGroup
      tabIndex={0}
      data-testid="single-date-input"
      inputRef={inputRef}
      value={rawValue}
      disabled={disabled}
      type="text"
      id={id}
      name={name}
      placeholder={placeholder}
      leftElement={pickerPlacement === "start" ? picker : undefined}
      rightElement={pickerPlacement === "end" ? picker : undefined}
      onChange={e => setRawValue(e.target.value)}
      onKeyDown={onKeyDown}
      onKeyUp={onKeyUp}
      onFocus={onFocus}
      onClick={onClick}
      onInput={onInput}
      onBlur={e => {
        commitChange();
        onBlur?.(e);
      }}
    />
  );
};

export type QueryParamRecord = Record<string, string>;

/**
 *
 * @param params A Record representing the query parameters.
 * @returns The query string built from the provided params.
 */
export const convertRecordToQueryString = <T>(
  params: Record<string, T>
): string => {
  const cleanParams: QueryParamRecord = {};

  for (const param of Object.keys(params)) {
    if (params[param] === undefined) {
      continue;
    }
    cleanParams[param] = String(params[param]);
  }

  return new URLSearchParams(cleanParams).toString();
};

/**
 *
 * @param queryString The query string without any fragments.
 * @returns A QueryParamRecord derived from the provided query string.
 */
export const convertQueryStringToRecord = (
  queryString: string
): QueryParamRecord => {
  const params = new URLSearchParams(queryString);

  return Object.fromEntries(params.entries());
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const readQueryString = <T extends {}>(
  param: string,
  fallback: T
): T => {
  const raw = convertQueryStringToRecord(window.location.search)[param];

  if (!raw || Array.isArray(raw)) {
    return fallback;
  }

  try {
    return JSON.parse(JSON.stringify(raw)) as T as T;
  } catch (e) {
    console.warn("Failed to read query string. param:", param);
    return fallback;
  }
};

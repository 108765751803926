import MadSDK from "@madhive/mad-sdk";
import { ENVIRONMENT } from "./env";
const madSDK = new MadSDK({
    firebaseConfig: {
        projectId: ENVIRONMENT.PROJECT_ID,
        storageId: ENVIRONMENT.STORAGE_ID,
        minimumFetchIntervalMillis: ENVIRONMENT.MIN_FETCH_INTERVAL,
        debug: ENVIRONMENT.DEBUG,
        useRemoteConfig: false
    },
    googleEndpointProjectId: ENVIRONMENT.PROJECT_ID,
    baseCIUrl: ENVIRONMENT.BASE_CI_URL
});
Object.freeze(madSDK);
export { madSDK };

export const SET_FEATURE_FLAGS = "SET_FEATURE_FLAGS";

interface setFeatureFlagsAction {
  type: typeof SET_FEATURE_FLAGS;
  payload: Record<string, boolean>;
}

export interface FeatureFlagsState {
  readonly list: Record<string, boolean>;
}

export type FeatureFlagsActionType = setFeatureFlagsAction;

export class InvalidPage extends Error {
    constructor(pagingFor) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidPage);
        }
        const explanation = "Pages must contain a numeric size. If token is defined, offset must be defined as well.";
        this.name = "InvalidPage";
        this.message = pagingFor ? `Page for ${pagingFor} is invalid. ${explanation}` : explanation;
    }
}

// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
export const __DEV__ = import.meta.env.VITE_NODE_ENV === "development";

// @ts-ignore
// eslint-disable-next-line no-underscore-dangle
export const __PROD__ = import.meta.env.VITE_NODE_ENV === "production";

// @ts-ignore
export const DATE_OF_CURRENT_RELEASE_ENV = import.meta.env
  .VITE_DATE_OF_CURRENT_RELEASE;

// @ts-ignore
export const CI = import.meta.env.VITE_CI;

// @ts-ignore
export const REACT_APP_CI_BASE_URL = import.meta.env.VITE_REACT_APP_CI_BASE_URL;

// @ts-ignore
export const NODE_ENV = import.meta.env.VITE_NODE_ENV;

// @ts-ignore
export const PUBLIC_URL = import.meta.env.VITE_PUBLIC_URL;

// @ts-ignore
export const REACT_APP_GIT_COMMIT = import.meta.env.VITE_REACT_APP_GIT_COMMIT;

// @ts-ignore
export const { VITE_GOOGLE_MAPS_API_KEY } = import.meta.env;

// @ts-ignore
export const { VITE_SMITHERS_VERSION } = import.meta.env;

// @ts-ignore
export const { VITE_GCP_TOKEN } = import.meta.env;

import { downloadBlob } from "frontier/lib/utils/files";
import html2Canvas from "html2canvas";
import jsPDF from "jspdf";
import JSZip from "jszip";
import { __DEV__ } from "lib/constants/config";

// List of properties updateChildStyle needs to set inline to preserve SVGs
const PROPERTIES = [
  "fill",
  "color",
  "font-size",
  "stroke",
  "font",
  "display",
  "transform"
];

/* eslint-disable no-param-reassign */
/**
 * This functions recursively updates every child of an svg element
 * to have inline styles based on their computed (css) style.
 * It also sets the width and the height property of svg elements to
 * their actual dimensions as heght and width propeties might be inherited
 * through css and thus be skipped by html2canvas
 * @param  {SVGSVGElement|HTMLElement} node
 */
export const updateChildStyle = (node: SVGSVGElement | HTMLElement) => {
  if (!node.style) {
    return;
  }

  /**
   * getComputedStyle is a window method that returns an object containing
   * the values of all CSS properties of an element
   */
  const currentStyles = getComputedStyle(node);

  /**
   *  Get all porperties that we care about from the current style
   * and apply it as inline style
   */
  PROPERTIES.forEach(property => {
    node.style[property] = currentStyles[property];
  });

  /**
   * Set height and width attributes inline
   */
  if (
    node.getAttribute("width") === null ||
    node.getAttribute("height") === null
  ) {
    node.setAttribute("width", `${node.getBoundingClientRect().width}`);
    node.setAttribute("height", `${node.getBoundingClientRect().height}`);
    node.style.width = "";
    node.style.height = "";
  }

  /**
   * Set font in inline style to ensure that while generating the canvas
   * the UI will at least fallback to sans-serif
   */
  node.style.fontFamily = "RedHatText, sans-serif";

  /**
   * Call recursively updateChildStyle to each children of the current element
   */
  Array.prototype.slice
    .call(node.childNodes)
    .forEach((child: SVGSVGElement) => {
      updateChildStyle(child);
    });
};

/**
 * This function will set the computed styles (css) of each svg element
 * present within the target element as inline style for enable htlm2canvas
 * to capture fully styled svgs (which is not supported when style is solely
 * injected via css class).
 * @param  {HTMLElement} element
 */
const setSVGInlineStyles = (element: HTMLElement) => {
  /**
   * @MaximeHeckel 23/01/2020: Note for Array.prototype.slice.call
   * Applying Array.prototype.slice.call on a list of nodes lets us
   * use Array method on a NodeList type element (NodeList is not an instance of Array).
   * Array.prototype.slice.call(NodeList) will return that NodeList
   * as an new array.
   */
  const svgElements = Array.prototype.slice.call(
    element.getElementsByTagName("svg")
  );

  svgElements.forEach((item: SVGSVGElement) => {
    updateChildStyle(item);
  });
};

/**
 * Returns true if the element has a data-pdfignore attribute
 * Returns false otherwise
 * @param  {HTMLElement} element
 * @returns boolean
 */
const ignoreElements = (element: HTMLElement): boolean =>
  element && element.dataset.pdfignore === "true";

const generateDoc = async (element: HTMLElement | null): Promise<jsPDF> => {
  if (!element) {
    console.error("element is null");
    return Promise.reject("Error while generating pdf.");
  }

  /**
   *  The following code is a fix for this particular google map issue with html2canvas:
   *  https://github.com/niklasvh/html2canvas/issues/1655
   */
  const gmapNode = <HTMLElement>(
    document.querySelector(
      ".gm-style>div:first-of-type>div:first-of-type>div:last-child>div"
    )
  );

  let transform = null;
  let mapLeft;
  let mapTop;

  /**
   *  If a gmapNode is present in the view, reapply the transform, left and top css property
   *  to avoid undesirable offsets
   */
  if (gmapNode) {
    transform = gmapNode.style.transform;
    const comp = transform && transform.split(","); // split up the transform matrix
    mapLeft = comp && parseFloat(comp[4]); // get left value
    mapTop = comp && parseFloat(comp[5]); // get top value

    gmapNode.style.transform = "none";
    gmapNode.style.left = `${mapLeft}px`;
    gmapNode.style.top = `${mapTop}px`;
  }

  /**
   * The following is a workaround to get back any svg styles and have it be rendered
   * when html2canvas takes a screenshot of the target element.
   */
  setSVGInlineStyles(element);

  // '.gm-style>div:first>div:first>div:last>div'

  /**
   * The following is a workaround to make sure we can get a full screenshot
   * of components with the overflow property set to auto (like the Drawer component).
   * These styles are reset later on once the pdf generation is done.
   * We also want to disable any boxShadow as they can leave artifacts on the pdf.
   */
  element.style.height = "auto";
  element.style.overflow = "show";
  element.style.boxShadow = "none";

  /**
   * Workaround for removing box-shadow styling from smithers card and adding a border.
   * box-shadow css property is not supported by html2canvas https://github.com/niklasvh/html2canvas/pull/1086
   */
  element
    .querySelectorAll(".SmithersCard.up-specificity-shadow")
    .forEach((el: HTMLElement) => {
      el.style.boxShadow = "none";
      el.style.border = "2px solid var(--gray-2)";
    });

  /**
   * Takes a screenshot of the target element
   */
  const canvas = await html2Canvas(element, {
    backgroundColor: "rgb(241, 243, 247)",
    /**
     *  set to 1 to make sure we do not use the device pixel ratio as default (it's set to 2 for retina
     * devices and html2canvas might simply stop rendering the page after a certain amount of pixel is
     * processed) => https://developer.mozilla.org/en-US/docs/Web/API/Window/devicePixelRatio
     */
    scale: 1,
    logging: __DEV__,
    /**
     * set to true to fetch the google maps images, without this the map won't be visible on
     * the printed pdf.
     */
    useCORS: true,
    /**
     * set to the ignoreElements utility function above to ignore any element that has the
     * data-pdfignore data attribute
     */
    ignoreElements,
    allowTaint: true,
    /**
     * Note @MaximeHeckel: This option is needed to allow exporting pdf while the user has scrolled the page.
     * Without it, we would cut or even mis-capture the targeted element
     * We never had this use case before (export pdf button where only accessible while being scrolled ) to the top
     *
     * More informations here: https://github.com/niklasvh/html2canvas/issues/1878
     * In case this causes issue
     */
    scrollY: window.pageYOffset * -1
  });

  // document.body.scrollTop = currentScroll;
  // document.documentElement.scrollTop = currentScroll;
  /**
   * We want the pdf to be A4 format
   * A4 =  210 mm x  297 mm =  595 pt x  842 pt
   */
  const imageWidth = 595;
  const pageHeight = 842;

  /**
   * Get image height from the imageWidth (constant => A4) the canvas height and the canvas width
   * imageHeight = ?   canvas.height
   *                 X
   * imageWidth = 595   canvas.width
   */
  const imageHeight = (canvas.height * imageWidth) / canvas.width;

  let heightLeft = imageHeight;
  let position = 0;

  /**
   * Generate the pdf from the canvas
   */
  /* eslint-disable-next-line new-cap */
  const doc = new jsPDF({
    unit: "pt"
  });

  const image = canvas.toDataURL("image/jpeg");

  // Print the first page of the pdf
  doc.addImage(image, "JPEG", 0, position, imageWidth, imageHeight);

  heightLeft -= pageHeight;

  // Loop to print the rest of the pages
  while (heightLeft >= 0) {
    position = heightLeft - imageHeight;
    doc.addPage();
    doc.addImage(image, "JPEG", 0, position, imageWidth, imageHeight);
    heightLeft -= pageHeight;
  }

  /**
   * Reset the height, boxShadow and overflow inline styles set prior to the html2canvas screenshot
   */
  element.style.height = "";
  element.style.overflow = "";
  element.style.boxShadow = "";
  /**
   * Reset SmithersCard box-shadow inline style override that is set prior to the html2canvas screenshot
   */
  element
    .querySelectorAll(".SmithersCard.up-specificity-shadow")
    .forEach((el: HTMLElement) => {
      el.style.boxShadow = "";
      el.style.borderBottom = "";
    });

  /**
   *  If a gmapNode was previously altered, reset the style to the original css properties
   */
  if (gmapNode && transform) {
    gmapNode.style.transform = transform;
    gmapNode.style.left = "0px";
    gmapNode.style.top = "0px";
  }

  return doc;
};

export type PDF = {
  name: string;
  element: HTMLElement;
};

/**
 *
 * @param {string} zipName
 * @param {} pdfs
 */
export const bulkGeneratePdf = async (
  pdfs: PDF[],
  zipName?: string
): Promise<void> => {
  const getDocs = async () =>
    Promise.all(pdfs.map(pdf => generateDoc(pdf.element)));

  const docs = await getDocs();

  const zip = new JSZip();

  for (let file = 0; file < pdfs.length; file++) {
    // Zip file with the file name.
    zip.file(`${pdfs[file].name}.pdf`, docs[file].output("blob"));
  }

  zip.generateAsync({ type: "blob" }).then((content: Blob) => {
    downloadBlob(content, `${zipName || "export"}.zip`);
  });
};

/**
 * Generates the pdf of a given target element and downloads it
 * @param  {string} name
 * @param  {HTMLElement} element
 * @returns Promise
 */
export const generatePdf = async (
  name: string,
  element: HTMLElement | null
): Promise<void | string> => {
  const doc = await generateDoc(element);

  /**
   * Saves / downloads the pdf
   */
  doc.save(`${name}.pdf`);
  return Promise.resolve();
};
/* eslint-enable no-param-reassign */

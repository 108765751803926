import { ATT_DASH_PRE_FILTERS_NECESSARY_FILTERS } from "../../../models/attributionDashboard/preFilters";
import { toQueryParam } from "../../handlers/filter";
import { AttributionDashboardFiltersError, AttributionDashboardOptionsFiltersError } from "../../../types";
import { formatDate } from "../../../utils";
export const toQueryParams = (filter) => {
    let queryParams = "";
    const wheres = filter.where;
    if (!wheres) {
        return queryParams;
    }
    wheres.forEach((where) => {
        switch (where.field) {
            case "startDate": {
                queryParams = `${queryParams}&startDate=${toQueryParam({
                    ...where,
                    value: formatDate(where.value)
                })}`;
                break;
            }
            case "endDate": {
                queryParams = `${queryParams}&endDate=${toQueryParam({
                    ...where,
                    value: formatDate(where.value)
                })}`;
                break;
            }
            case "email": {
                queryParams = `${queryParams}&email=${toQueryParam(where)}`;
                break;
            }
        }
    });
    return queryParams;
};
export const validateFilterContent = (filters) => {
    if (!filters.where) {
        throw new AttributionDashboardOptionsFiltersError(ATT_DASH_PRE_FILTERS_NECESSARY_FILTERS);
    }
    const missingFilters = [];
    const filterFields = new Map();
    filters.where?.forEach((filter) => {
        if (Array.isArray(filter.value) ? filter.value.length : filter.value) {
            filterFields.set(filter.field, filter);
        }
    });
    ATT_DASH_PRE_FILTERS_NECESSARY_FILTERS.forEach((filterField) => {
        const match = filterFields.get(filterField);
        if (!match || !match.value || (Array.isArray(match.value) && !match.value.length)) {
            missingFilters.push(filterField);
        }
    });
    if (missingFilters.length) {
        throw new AttributionDashboardFiltersError(missingFilters);
    }
};

import React from "react";
import { spinner, path, Wrapper } from "./style";
import { SpinnerColor, SpinnerProps, SpinnerSize } from "./types";

const SpinnerSizeToPx = {
  [SpinnerSize.TINY]: 12,
  [SpinnerSize.SMALL]: 16,
  [SpinnerSize.MEDIUM]: 32
};

const Spinner = (props: SpinnerProps) => {
  const {
    size = SpinnerSize.MEDIUM,
    color = SpinnerColor.PRIMARY,
    "data-testid": dataTestId
  } = props;

  return (
    <Wrapper data-testid={dataTestId} width={SpinnerSizeToPx[size]}>
      <svg className={spinner} viewBox="16 16 32 32">
        <circle
          className={path}
          cx="32"
          cy="32"
          r="14"
          fill="none"
          stroke={color}
          strokeWidth="3"
          strokeMiterlimit="10"
        />
      </svg>
    </Wrapper>
  );
};

export { Spinner };

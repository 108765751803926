export const buildSort = (sort) => {
    let field = sort.field;
    switch (sort.field) {
        case "setLive":
            field = "set_live";
            break;
        case "vcrTarget":
            field = "vcr_target";
            break;
        case "externalId":
            field = "external_id";
            break;
        case "omsId":
            field = "oms_id";
            break;
        case "updatedBy":
            field = "updated_by";
            break;
        case "updatedAt":
            field = "updated_at";
            break;
        case "status":
            field = "derived_status";
            break;
        case "lineItemsCount":
            field = "line_items_count";
            break;
        case "creativeFlightsCount":
            field = "creative_flights_count";
            break;
        case "bookedImpressions":
            field = "booked_impressions_sum";
            break;
        default:
            break;
    }
    return `${field} ${sort.direction}`;
};

import axios from "axios";
import { Handler } from "../handlers";
import { FilterTypes, FilterValueTypes, parseIdFilter, validateFilterStructure } from "../handlers/filter";
import { NotImplementedError, UnsuccessfulHermesResponse, InvalidPixelHistoryData, FiltersRequiredError, FilterErrorName } from "../../errors";
import { PixelHistoryFetchFailed } from "./errors";
/**
 * Handles history collections find and management.
 * Similar to modules/creatives/inscape, this also breaks from the standard Handler mold,
 * because it needs mandatory filtering based on a set of pixel ids, instead of ordinary
 * filtering based on the collection's given type.
 * tl;dr:
 *    it needs to implement a consistent collections interface
 *    it needs to filter based on a set of pixel ids, but take in and return models/pixel/history types
 *    so the handler is extended using any types, instead of History
 */
class Histories extends Handler {
    constructor(sdk) {
        super(sdk, "histories", { atomize: true });
        this.validateBeaconHistoryResponse = (rawAxiosResponse) => {
            if (rawAxiosResponse.status < 200 || rawAxiosResponse.status > 299) {
                throw new UnsuccessfulHermesResponse(rawAxiosResponse.status);
            }
            if (!rawAxiosResponse.data || typeof rawAxiosResponse.data !== "object") {
                throw new InvalidPixelHistoryData();
            }
        };
    }
    /**
     * Find pixel history based on a set of pixelIds.
     *
     * @param filters: filter histories from a set of pixelIds (e.g., {where: [field: "pixelIds", type: FilterTypes.IN, value: ["1", "2", "3"]]})
     */
    async findItems(filters) {
        validateFilterStructure(filters, [
            { filterType: FilterTypes.IN, valueType: FilterValueTypes.OBJECT }
        ]);
        const filterIds = Array.from(parseIdFilter(filters));
        if (filterIds.length === 0) {
            throw new FiltersRequiredError("id");
        }
        const url = `${this.sdk.urls.baseHermesUrl}/beacon_hist`;
        const histories = await this.cache.promise(`${url}?ids=${Array.from(filterIds.values()).join(",")}`, () => axios
            .post(url, {
            ids: filterIds
        }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
            this.validateBeaconHistoryResponse(res);
            return res.data;
        })
            .catch((error) => {
            if (error.name === FilterErrorName.FilterInvalidError) {
                throw error;
            }
            else {
                throw new PixelHistoryFetchFailed();
            }
        }));
        return histories;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async saveItem(history) {
        throw new NotImplementedError("save");
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}
export default Histories;

const NOTIFICATION_COLLECTION = "notifications";
export default class Notifications {
    constructor(sdk) {
        this.sdk = sdk;
    }
    async createNotification(notification) {
        const now = new Date();
        const formattedNotification = {
            ...notification,
            read: false,
            createdDate: now,
            lastUpdatedDate: now
        };
        const notificationId = await this.sdk.madFire
            .addDoc(NOTIFICATION_COLLECTION, formattedNotification)
            .then((docRef) => {
            return docRef.id;
        })
            .catch((err) => {
            return Promise.reject(err);
        });
        return Promise.resolve(notificationId);
    }
    async getNotification(id) {
        return this.sdk.madFire
            .getDocument(NOTIFICATION_COLLECTION, id)
            .then((snapshot) => snapshot.data())
            .catch((err) => {
            return Promise.reject(err);
        });
    }
    async updateNotification(id, update) {
        this.sdk.madFire.updateDoc(NOTIFICATION_COLLECTION, id, update).catch((err) => {
            return Promise.reject(err);
        });
    }
}

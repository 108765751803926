import { DealCategoryLabel, DealCategory } from "@madhive/mad-sdk";
import {
  CampaignStatus,
  LineItemActionRequiredInstructionStatus
} from "lib/constants/campaign";
import { LineItemTab, TargetingSubTab } from "../constants";

export const LINE_ITEM_TAB_TO_UI_ERROR_LABEL: Record<LineItemTab, string> = {
  [LineItemTab.OVERVIEW]: "Overview Tab",
  [LineItemTab.TARGETING]: "Targeting Tab",
  [LineItemTab.CREATIVES]: "Creative Scheduling",
  [LineItemTab.WEIGHTING]: "Weighting Tab",
  [LineItemTab.FLIGHTS]: "Flights Tab"
};

export const HOLDING_COMPANY = [
  { id: "interpublic", name: "Interpublic" },
  { id: "dentsu", name: "Dentsu" },
  { id: "publicis", name: "Publicis" },
  { id: "omnicom", name: "OmniCom" },
  { id: "wpp", name: "WPP" },
  { id: "none", name: "No HoldCo" }
];

export const ECPM_LABEL_LEGACY = "eCPM";
export const ECPM_LABEL = "Max eCPM";
export const BUDGET_LABEL = "Budget";
export const IMPRESSIONS_LABEL = "Impressions";
export const LINE_ITEM_LIFETIME_LEGACY = "Line Item Lifetime";
export const LINE_ITEM_LIFETIME = "Lifetime";
export const LINE_ITEM_DAILY = "Day";

const LIST_OF_STATES_WITH_ABBREVIATIONS = [
  {
    name: "Alabama",
    abbreviation: "AL"
  },
  {
    name: "Alaska",
    abbreviation: "AK"
  },
  {
    name: "American Samoa",
    abbreviation: "AS"
  },
  {
    name: "Arizona",
    abbreviation: "AZ"
  },
  {
    name: "Arkansas",
    abbreviation: "AR"
  },
  {
    name: "California",
    abbreviation: "CA"
  },
  {
    name: "Colorado",
    abbreviation: "CO"
  },
  {
    name: "Connecticut",
    abbreviation: "CT"
  },
  {
    name: "Delaware",
    abbreviation: "DE"
  },
  {
    name: "District Of Columbia",
    abbreviation: "DC"
  },
  // Might not need this, isn't a part of census_tracts_STATE/TERRITORYNAME
  {
    name: "Federated States Of Micronesia",
    abbreviation: "FM"
  },
  {
    name: "Florida",
    abbreviation: "FL"
  },
  {
    name: "Georgia",
    abbreviation: "GA"
  },
  {
    name: "Guam",
    abbreviation: "GU"
  },
  {
    name: "Hawaii",
    abbreviation: "HI"
  },
  {
    name: "Idaho",
    abbreviation: "ID"
  },
  {
    name: "Illinois",
    abbreviation: "IL"
  },
  {
    name: "Indiana",
    abbreviation: "IN"
  },
  {
    name: "Iowa",
    abbreviation: "IA"
  },
  {
    name: "Kansas",
    abbreviation: "KS"
  },
  {
    name: "Kentucky",
    abbreviation: "KY"
  },
  {
    name: "Louisiana",
    abbreviation: "LA"
  },
  {
    name: "Maine",
    abbreviation: "ME"
  },
  // Might not need this, isn't a part of census_tracts_STATE/TERRITORYNAME
  {
    name: "Marshall Islands",
    abbreviation: "MH"
  },
  {
    name: "Maryland",
    abbreviation: "MD"
  },
  {
    name: "Massachusetts",
    abbreviation: "MA"
  },
  {
    name: "Michigan",
    abbreviation: "MI"
  },
  {
    name: "Minnesota",
    abbreviation: "MN"
  },
  {
    name: "Mississippi",
    abbreviation: "MS"
  },
  {
    name: "Missouri",
    abbreviation: "MO"
  },
  {
    name: "Montana",
    abbreviation: "MT"
  },
  {
    name: "Nebraska",
    abbreviation: "NE"
  },
  {
    name: "Nevada",
    abbreviation: "NV"
  },
  {
    name: "New Hampshire",
    abbreviation: "NH"
  },
  {
    name: "New Jersey",
    abbreviation: "NJ"
  },
  {
    name: "New Mexico",
    abbreviation: "NM"
  },
  {
    name: "New York",
    abbreviation: "NY"
  },
  {
    name: "North Carolina",
    abbreviation: "NC"
  },
  {
    name: "North Dakota",
    abbreviation: "ND"
  },
  {
    name: "Northern Mariana Islands",
    abbreviation: "MP"
  },
  {
    name: "Ohio",
    abbreviation: "OH"
  },
  {
    name: "Oklahoma",
    abbreviation: "OK"
  },
  {
    name: "Oregon",
    abbreviation: "OR"
  },

  // Might not need this, isn't a part of census_tracts_STATE/TERRITORYNAME
  {
    name: "Palau",
    abbreviation: "PW"
  },
  {
    name: "Pennsylvania",
    abbreviation: "PA"
  },
  {
    name: "Puerto Rico",
    abbreviation: "PR"
  },
  {
    name: "Rhode Island",
    abbreviation: "RI"
  },
  {
    name: "South Carolina",
    abbreviation: "SC"
  },
  {
    name: "South Dakota",
    abbreviation: "SD"
  },
  {
    name: "Tennessee",
    abbreviation: "TN"
  },
  {
    name: "Texas",
    abbreviation: "TX"
  },
  {
    name: "Utah",
    abbreviation: "UT"
  },
  {
    name: "Vermont",
    abbreviation: "VT"
  },
  {
    name: "Virgin Islands",
    abbreviation: "VI"
  },
  {
    name: "Virginia",
    abbreviation: "VA"
  },
  {
    name: "Washington",
    abbreviation: "WA"
  },
  {
    name: "West Virginia",
    abbreviation: "WV"
  },
  {
    name: "Wisconsin",
    abbreviation: "WI"
  },
  {
    name: "Wyoming",
    abbreviation: "WY"
  }
];

export const LIST_OF_STATES_WITH_ABBREVIATIONS_MAP =
  LIST_OF_STATES_WITH_ABBREVIATIONS.reduce(
    (acc, cur) => ({
      ...acc,
      [cur.abbreviation]: cur.name
    }),
    {}
  );

export const ACTION_REQUIRED_STATUS_TO_LINE_ITEM_TABS: Readonly<
  Record<
    LineItemActionRequiredInstructionStatus,
    {
      tab: LineItemTab;
      subTab: TargetingSubTab | null;
    }
  >
> = {
  [CampaignStatus.MISSING_CREATIVE]: {
    tab: LineItemTab.CREATIVES,
    subTab: null
  },
  [CampaignStatus.MISSING_WHITELIST]: {
    tab: LineItemTab.TARGETING,
    subTab: TargetingSubTab.INVENTORY
  },
  [CampaignStatus.MISSING_GEO]: {
    tab: LineItemTab.TARGETING,
    subTab: null
  },
  [CampaignStatus.MISSING_GOAL]: {
    tab: LineItemTab.OVERVIEW,
    subTab: null
  },
  [CampaignStatus.MISSING_PRODUCT]: {
    tab: LineItemTab.TARGETING,
    subTab: null
  },
  [CampaignStatus.MISSING_FREQ_CAP]: {
    tab: LineItemTab.TARGETING,
    subTab: null
  }
};

export const defaultDeviceType = { desktop: 0, mobile: 0, tablet: 0, tv: 100 };

export const DSP_OPTIONS = [
  { id: "0LDY6rsTok3wcPtS29QFr97Mtpw7", label: "Madhive" },
  { id: "NaYohTJvHtJVMSHZW0CIhepYt5Xi", label: "Google Ad Manager ( GAM )" },
  { id: "3SLsQgGodlAmVrUmZYoQ1GcZoMDj", label: "Xandr" }
];

export const dealCategoryOptions = [
  {
    id: DealCategory.NON_PG_DEAL,
    name: DealCategoryLabel[DealCategory.NON_PG_DEAL]
  }
];

import type { Event, IListener, IUser } from "@madhive/mad-tracker";
import { User as MadSDKUser } from "@madhive/mad-sdk";

import { madSDK } from "lib/sdk";

export class FirebaseAnayltics implements IListener<MadSDKUser> {
  user = {
    set: (user: IUser<MadSDKUser>) => {
      if (user.traits) {
        const {
          primaryOrganizationId,
          primaryOrganizationName,
          teamId,
          name,
          email
        } = user.traits;

        madSDK.madFire.setUserProperties({
          organization_id: primaryOrganizationId,
          organization_name: primaryOrganizationName,
          team_id: teamId,
          name,
          email,
          user_id: user.id
        });
      }

      // this needs to come after setting the properties, to be sure ID is properly set
      madSDK.madFire.setUserId(user.id);
    },
    reset: () => {
      madSDK.madFire.setUserId(null);
      madSDK.madFire.setUserProperties({});
    }
  };

  track = ({
    event,
    properties
  }: {
    event: Event;
    properties?: Record<string, string>;
  }) => {
    madSDK.madFire.logEvent(event, properties || {});
  };
}

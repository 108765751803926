import { ROOT_USER_ID } from "./types";
import MadFire from "./firebase";
import URLS from "./urls";
import { Authentication, Users, Organizations, Stations, Agencies, Advertisers, Categories, Creatives, LegacyCreatives, Teams, DataManagementPlatforms, Pixels, Products, Publishers, CustomReports, Summaries, FeatureFlags, Recipes, Campaigns, LinearNetworks, HouseholdsCount, PioProductPackages, Plans, LegacyScenarios, AdvancedExports, Reports, Geo, Reporting, PIIHandler as PII, Notifications, AttributionDashboard, AudienceProvisionsHandler as AudienceProvisions } from "./modules";
import { Cryptography } from "./cryptography";
import FootTrafficAttribution from "./modules/footTrafficAttribution";
import ZipcodesInRadius from "./modules/zipcodesInRadius";
import Caches from "./cache/global";
import Settings from "./modules/settings";
import Deals from "./modules/deals";
import SSPs from "./modules/ssps";
import { Security } from "./plugins/security";
import { DefaultSecurity } from "./plugins/security/default";
import { Properties } from "./properties";
import Application from "./modules/application";
import { Statistics } from "./modules/statistics";
import { MadLooker } from "./modules/looker";
import ContentObjects from "./modules/contentObjects";
/**
 * MadSDK is a singleton class that builds connections to firebase, setup
 * a set of collections, intialize classes, and is the single entry point
 * into gather data from MadHive API and backends.
 *
 * @constructor config: {
 *    googleEndpointProjectId - Project ID
 *    firebaseConfig: {
 *      projectId: Project ID
 *      storageId: Storage ID
 *      minimumFetchIntervalMillis: Time out for firebase requests
 *      debug: Outputs extra data and changes the connection type
 *    }
 * }
 *
 */
class MadSDK {
    /**
     * Construction of the MadHive Instance
     * @param config Used to instantion of all collections and connections to firebase
     */
    constructor(config) {
        if (!MadSDK.instance) {
            this.madConfig = config;
            this.googleEndpointProjectId = config.googleEndpointProjectId;
            if (config.firebaseConfig) {
                // If firebase config is given instantiate MadFire.
                this.madFire = new MadFire(config.firebaseConfig);
            }
            // instantiate URLs first to ensure they're available to child collections that may need them at construction time
            this.urls = new URLS(this, config);
            // instantiate auth second, to ensure it's available to child collections that may need it at construction time
            this.authentication = new Authentication(this);
            /**
             * Next is the creation of all the supported collections.
             * Most of these are just Handlers and take a instance of MadSDK
             * The reason it was done this way instead of a "plugin" style was
             * to allow sdk.<collection>. vs sdk[<collection>].
             */
            this.caches = new Caches();
            this.looker = new MadLooker(this, config.lookerConfig);
            this.users = new Users(this);
            this.organizations = new Organizations(this);
            this.stations = new Stations(this);
            this.agencies = new Agencies(this);
            this.advertisers = new Advertisers(this);
            this.categories = new Categories(this);
            this.creatives = new Creatives(this);
            this.teams = new Teams(this);
            this.pixels = new Pixels(this);
            this.products = new Products(this);
            this.publishers = new Publishers(this);
            this.deals = new Deals(this);
            this.ssps = new SSPs(this);
            this.pioProductPackages = new PioProductPackages(this);
            this.linearNetworks = new LinearNetworks(this);
            this.customReports = new CustomReports(this, this.madFire);
            this.summaries = new Summaries(this);
            this.statistics = new Statistics(this);
            this.recipes = new Recipes(this);
            this.featureFlags = new FeatureFlags(this);
            this.campaigns = new Campaigns(this);
            this.householdsCount = new HouseholdsCount(this);
            this.cryptography = new Cryptography(config.crypto || window.crypto);
            this.dmps = new DataManagementPlatforms(this);
            this.plans = new Plans(this);
            this.advancedExports = new AdvancedExports(this);
            this.footTrafficAttribution = new FootTrafficAttribution(this); // maybe madFire as a second arg
            this.reports = new Reports(this);
            this.attributionDashboard = new AttributionDashboard(this);
            this.geo = new Geo(this);
            this.zipcodesInRadius = new ZipcodesInRadius(this);
            this.reporting = new Reporting(this);
            this.pii = new PII(this);
            this.contentObjects = new ContentObjects(this);
            this.settings = new Settings(this);
            this.legacy = {
                creatives: new LegacyCreatives(this),
                plans: { scenarios: new LegacyScenarios(this) }
            };
            this.notifications = new Notifications(this);
            this.properties = new Properties(this);
            this.app = new Application(this);
            this.audienceProvisions = new AudienceProvisions(this);
            MadSDK.instance = this;
        }
        return MadSDK.instance;
    }
    // Simple getter for the configuration of this MadSDK instance.
    get config() {
        return this.madConfig;
    }
    get security() {
        if (!this.securityPlugin) {
            this.securityPlugin = new DefaultSecurity(this);
        }
        return this.securityPlugin;
    }
    use(plugin) {
        if (plugin.prototype instanceof Security) {
            this.securityPlugin = new plugin(this);
        }
    }
    /**
     * Returns the currently authenticated user
     */
    getCurrentUser() {
        return this.authentication.getCurrentAccount()?.user;
    }
    /**
     * The current user is logged into dev@madhive.com,
     * which is a special account that has access to everything.
     */
    isRootUser() {
        return this.getCurrentUser()?.id === ROOT_USER_ID;
    }
    /**
     *  --- TEMPORY EXPODED FIREBASE FEATURES ---
     * The following are just public api to get to the basic firebase
     * connections. These should be slowly removed and only collection
     * interfaces should be used after full migration.
     */
    /**
     * @deprecated Use collections
     */
    get firebase() {
        if (this.madFire) {
            return this.madFire.firebase;
        }
        throw new Error("No Firebase connection established.");
    }
    /**
     * @deprecated Use collections
     */
    get fireStorage() {
        if (this.madFire) {
            return this.madFire.fireStorage;
        }
        throw new Error("No Firebase Mad Data Storage connection established.");
    }
    /**
     * @deprecated Use collections
     */
    get firestore() {
        if (this.madFire) {
            return this.madFire.firestore;
        }
        throw new Error("No Firebase remote config connection established.");
    }
    /**
     * @deprecated Use collections
     */
    get storage() {
        if (this.madFire) {
            return this.madFire.storage;
        }
        throw new Error("No Firebase Storage Created");
    }
}
export default MadSDK;
export * from "./utils";
export * from "./types";
export { NodeCredentialStorage, BrowserCredentialStorage } from "./modules/authentication/credential-storage";

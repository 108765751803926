import { ReactElement } from "react";
import {
  LookupMethod,
  DoNotCare,
  isIdType,
  IdType,
  IdTypeFromData
} from "./types";

export * from "./Input/Date/utils";
export { makeTable } from "./Table/makeTable";

/**
 * @param option: an option to examine.
 * @return
 *   - if an object, a JSON stringified version of the object
 *   - if an array, join the values separating with a comma
 *   - if undefined, the empty string
 *   - else, option.toString
 */
export const defaultLookup: LookupMethod<string> = (
  option?: DoNotCare
): string => {
  if (typeof option === "undefined") {
    return "";
  }

  if (Array.isArray(option)) {
    return option.join(", ");
  }

  return typeof option === "object"
    ? JSON.stringify(option)
    : option.toString();
};

/**
 * @param min: the minimum value.
 * @param value: the value to constrain.
 * @param max: the maximum value.
 * @return: the value, constrained to the inclusive range of [min, max].
 */
export const constrain = (min: number, value: number, max: number) => {
  return Math.max(Math.min(Number(value), max), min);
};

export const defaultLookupId = <
  Data extends Record<string, any>,
  Returns extends IdType = IdTypeFromData<Data>,
  Keys extends string = "id" | "key"
>(
  data: Data,
  keys = ["id", "key"] as Keys[]
) => {
  let res: unknown;
  if (isIdType(data)) {
    res = data;
  }
  if (!res && data && typeof data === "object") {
    for (const key of keys) {
      if (key in data && isIdType(data[key as keyof Data])) {
        res = data[key as keyof Data];
      }
    }
  }
  if (!res) {
    res = JSON.stringify(data);
  }
  return res as Returns;
};

export const lookupChildrenByKitType = <KitType extends string>(
  componentType: KitType,
  children: DoNotCare
) =>
  (Array.isArray(children) ? children : [children]).filter(
    child => child && child?.props?.KIT_TYPE === componentType
  ) as Array<ReactElement<DoNotCare & { KIT_TYPE: KitType }>>;

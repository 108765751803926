import { DoNotCare } from "@madhive/mad-sdk";
import { v4 as uuid } from "uuid";

import { isLocalhost } from "lib/utils/env";

/**
 * An abstract class that provides a common interface for injecting tracker installation scripts into the DOM,
 * and interacting with the resulting interface.
 * @template {T}: the shape of the injected tracker (e.g., heap's functions). Defaults to `DoNotCare`.
 */
export abstract class Injector<T = DoNotCare> {
  private scriptId: string;

  constructor() {
    this.scriptId = `mad${uuid()}}`;
    this.inject();
  }

  /** The instance of the injected tracker. */
  protected abstract get instance(): T | undefined;

  /** The project ID associated with the injected tracker. */
  protected abstract get projectId(): string | undefined;

  /** The source of the script to inject. */
  protected abstract source: string;

  /**
   * Idempotently injects a script element as defined by the inheritor.
   * @param options: optional. Configurations on how/when the injection is done.
   * @param options.localhost: if true, the injection will be allowed on localhost environments. Defaults to `false`.
   */
  protected inject(options?: { localhost?: boolean }) {
    const injectable = options?.localhost ? true : !isLocalhost;

    if (!document || !injectable || !this.projectId) {
      return;
    }

    // the script is already injected
    if (document.getElementById(this.scriptId)) {
      return;
    }

    const script = document.createElement("script");

    script.innerHTML = this.source;
    script.id = this.scriptId;
    script.async = true;

    document.body.appendChild(script);
  }
}

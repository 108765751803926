import { isTruthy } from "./fp";
/**
 * @param e: the error to check.
 * @returns: true if the error contains a data object, marked true for visible_error, with a non-empty array of errors. Undefined if AxiosError response and response.data are not both defined objects. Else, false.
 */
export const isErrorResponseValid = (e) => {
    return !!(e.response &&
        e.response.data &&
        typeof e.response.data === "object" &&
        e.response.data.visible_error &&
        Array.isArray(e.response.data.errors) &&
        e.response.data.errors.length > 0);
};
// TODO: Should we create an error util file instead?
/**
 * Error return data type can be any here, because we don't care about it, but we're forced to define it
 * @param e: the error to check.
 * @return: array of string of errors from backend, usually user-friendly
 */
export const deriveErrorResponse = (e) => {
    return e?.response?.data?.errors && Array.isArray(e.response.data.errors)
        ? e.response.data.errors
        : [];
};
/**
 * @param apiError: the error to derive details from.
 * @param fallbackMessage: message to use if no detail error from api was found
 * @return: string message with error details
 */
export const getDetailedErrorMessage = (apiError, fallbackMessage) => {
    const apiErrorMessages = deriveErrorResponse(apiError);
    return apiErrorMessages.length > 0
        ? `Details: ${apiErrorMessages.filter(isTruthy).join(",")}`
        : fallbackMessage;
};

export class AgenciesFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AgenciesFetchFailed);
        }
        this.name = "AgenciesFetchFailed";
        this.message = "Failed to retrieve agencies. Please try again at a later time.";
    }
}
export class AgencyIdCreationFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AgencyIdCreationFailed);
        }
        this.name = "AgencyIdCreationFailed";
        this.message = "Failed to create an ID for the new agency.";
    }
}
export class AgencyCreationFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AgencyCreationFailed);
        }
        this.name = "AgencyCreationFailed";
        this.message = "Failed to create agency. Please try again at a later time.";
    }
}
export class AgencyUpdateFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AgencyUpdateFailed);
        }
        this.name = "AgencyUpdateFailed";
        this.message = "Failed to update agency. Please try again at a later time.";
    }
}
export class AgencyExistsUpdateFailure extends Error {
    constructor(externalId) {
        super();
        this.externalId = externalId;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AgencyExistsUpdateFailure);
        }
        this.name = "AgencyExistsUpdateFailure";
        this.message = `Failed to update agency. An agency with this External ID (${this.externalId}) already exists.`;
    }
    static is(errors) {
        return errors.some((error) => error.includes("with this external ID"));
    }
}
export class AgencyDeleteFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AgencyDeleteFailed);
        }
        this.name = "AgencyDeleteFailed";
        this.message = "Failed to delete agency. Please try again at a later time.";
    }
}
export class AgencyExistsCreationFailure extends Error {
    constructor(externalId) {
        super();
        this.externalId = externalId;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AgencyExistsCreationFailure);
        }
        this.name = "AgencyExistsCreationFailure";
        this.message = `Failed to create agency. An agency with this External ID (${this.externalId}) already exists.`;
    }
    static is(errors) {
        return errors.some((error) => error.includes("with this external ID"));
    }
}
export class AgencyInvalidNameCreationFailure extends Error {
    constructor(agencyName) {
        super();
        this.agencyName = agencyName;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AgencyInvalidNameCreationFailure);
        }
        this.name = "AgencyInvalidNameCreationFailure";
        this.message = `"${this.agencyName}" is an invalid name for an Agency. Please try again with a different name.`;
    }
    static is(errors) {
        return errors.some((error) => error.includes("invalid name"));
    }
}

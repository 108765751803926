// moved over from Wingsuit

/** groups of extensions that are the same file type */
export const extensionMatches: string[][] = [
  ["jpg", "jpeg"],
  ["tif", "tiff"],
  ["xls", "xlsx"]
];

/**
 * Checks for all the potential extensions a file could have,
 * base on the provided extension. EX: "jpg" could also be "jpeg"
 * if there is no match, will still return
 * sanitized (lowercase + no ".") version of provided ext
 * @param ext - file extension to check for matches
 * @returns - returns array of matches including ext provided
 */
export const getAllPotentialExtensionFromExtension = (
  ext: string
): string[] => {
  const sanitizedExtension = ext.toLocaleLowerCase().replace(".", "");

  const extensionMap: Map<string, string[]> = extensionMatches.reduce(
    (acc, group): Map<string, string[]> => {
      group.forEach(str => acc.set(str, group));
      return acc;
    },
    new Map()
  );

  return extensionMap.get(sanitizedExtension) || [sanitizedExtension];
};

/**
 * Checks if fileName has accepted extension
 * @param file
 * @param accepted
 * @returns - true/false if is accepted
 */
export const hasAcceptedExtension = (
  file: File,
  accepted: string[]
): boolean => {
  const fileExt = `${file?.name?.toLowerCase()?.split(".").pop()}`;
  let isAccepted = false;
  for (let i = 0; i < accepted.length && isAccepted === false; i++) {
    const potentialMatches = getAllPotentialExtensionFromExtension(accepted[i]);
    isAccepted = potentialMatches.includes(fileExt);
  }
  return isAccepted;
};

/**
 * Checks if file has accepted type
 * @param file
 * @param accepted
 */
export const hasAcceptedType = (file: File, accepted: string[]): boolean => {
  let isAccepted = false;
  for (let i = 0; i < accepted.length && isAccepted === false; i++) {
    const reg = new RegExp(accepted[i]);
    if (file.type === accepted[i] || file.type.search(reg) === 0) {
      isAccepted = true;
    }
  }
  return isAccepted;
};

/**
 * Validates files' extensions and types based on accept
 * @param {File[]} files
 * @param {extensions: string[]; types: string[];} accepted
 * @returns {valid: File[]; skipped: File[]}
 */
export const validateAcceptedFile = (
  file: File,
  accepted: { extensions: string[]; types: string[] }
): boolean => {
  if (!accepted?.extensions?.length && !accepted?.types?.length) {
    return true;
  }
  return (
    hasAcceptedExtension(file, accepted.extensions) ||
    hasAcceptedType(file, accepted.types)
  );
};

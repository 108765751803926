import { Finder } from "../operators";
import { PromiseBuilder } from "./base";
/**
 * FindPromiseBuilder knows how to create a fluent promise specifically for find calls,
 * allowing additional changes to filtering, etc. via method chaining, before executing a promise.
 * @template {ReturnType}: the type of object returned from the promise.
 * @template {FilterType}: the shape of allowed filters.
 * @template {SortType}: the shape of allowed sort field.
 * @template {HydrateType}: the shape of allowed hydrated fields.
 */
export class FindPromiseBuilder extends PromiseBuilder {
    /**
     * @param identifierKey: the key into the UniqueType which uniquely identifies the object.
     * @param filters: any set of filters provided to the find.
     * @param hydratedFields: any set of hydrated fields provided to the find.
     * @param execute: what to actually do in order to make a `find` happen.
     */
    constructor(config) {
        const { identifierKey, filters = {}, sort, hydratedFields = [], execute } = config;
        super((resolve, reject) => {
            execute({
                filters: this.finder.filters,
                hydratedFields: this.finder.hydratedFields,
                sort: this.finder.sortField
            })
                .then((value) => resolve(value))
                .catch((error) => reject(error));
        });
        this.finder = new Finder(identifierKey, filters, sort, new Set(hydratedFields));
    }
    byId(id, options) {
        this.finder.byId(id, options);
        return this;
    }
    filter(input, options) {
        this.finder.filter(input, options);
        return this;
    }
    sort(input, options) {
        this.finder.sort(input, options);
        return this;
    }
    hydrate(hydratedFields, options) {
        this.finder.hydrate(hydratedFields, options);
        return this;
    }
}

import { deriveErrorResponse, getDetailedErrorMessage, isErrorResponseValid } from "../../utils/validation";
import { isTruthy } from "../../utils/fp";
/** Handles error formatting for Templator.create */
export class TemplateCreationFailed extends Error {
    constructor(error, fallbackMessage = "Failed to create template. Please try again later.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, TemplateCreationFailed);
        }
        this.name = "TemplateCreationFailed";
        this.message = getDetailedErrorMessage(error, fallbackMessage);
    }
}
/** Handles error formatting for Uploader.save */
export class UploadSaveFailed extends Error {
    constructor(error, prependMessage = "Failed to create assets from file.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, TemplateCreationFailed);
        }
        this.name = "UploadSaveFailed";
        this.message = `${prependMessage}${isErrorResponseValid(error)
            ? ` ${deriveErrorResponse(error).filter(isTruthy).join(",")}.`
            : ""}`;
    }
}

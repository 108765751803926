import { css } from "@emotion/react";

export const styles = {
  ellipsis: css`
    position: relative;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
  tooltip: css`
    position: absolute;
    inset: 0;

    > .bp4-popover-wrapper,
    > .bp4-popover-wrapper > span {
      position: absolute;
      inset: 0;
    }
  `,
  overflowIndicator: css`
    display: inline-block;
    line-height: 1;
  `
};

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Download: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))"
}) => {
  return (
    <svg
      className="icon-download"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 9.75C12.4142 9.75 12.75 10.0858 12.75 10.5V13.6894L13.6367 12.8027C13.9296 12.5098 14.4044 12.5098 14.6973 12.8027C14.9902 13.0956 14.9902 13.5704 14.6973 13.8633L12.5303 16.0303C12.455 16.1057 12.3676 16.1616 12.2744 16.1982C12.199 16.2279 12.1177 16.2457 12.0326 16.2493C12.0117 16.2502 11.9907 16.2502 11.9698 16.2494C11.8815 16.2459 11.7971 16.2271 11.7193 16.1957C11.6284 16.1591 11.5433 16.104 11.4697 16.0303L9.30268 13.8633C9.00978 13.5704 9.00978 13.0956 9.30268 12.8027C9.59557 12.5098 10.0704 12.5098 10.3633 12.8027L11.25 13.6893V10.5C11.25 10.0858 11.5858 9.75 12 9.75Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4.25C9.19057 4.25 6.79026 5.96724 5.77366 8.40427C3.1937 8.9661 1.25 11.2423 1.25 14C1.25 17.1752 3.82479 19.75 7 19.75H18C20.6232 19.75 22.75 17.6232 22.75 15C22.75 12.6196 20.9988 10.648 18.7145 10.3034C18.3659 6.90253 15.493 4.25 12 4.25ZM7.034 9.30967C7.73911 7.23814 9.69501 5.75 12 5.75C14.8998 5.75 17.25 8.10021 17.25 11C17.25 11.4142 17.5858 11.75 18 11.75C19.7948 11.75 21.25 13.2052 21.25 15C21.25 16.7948 19.7948 18.25 18 18.25H7C4.65321 18.25 2.75 16.3468 2.75 14C2.75 11.8555 4.3475 10.0948 6.42546 9.81111C6.70689 9.77268 6.94247 9.57856 7.034 9.30967Z"
        fill={fill}
      />
    </svg>
  );
};

export default Download;

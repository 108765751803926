export class InvalidCacheSpecifier extends Error {
    constructor(input) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CacheKeyError);
        }
        this.name = "InvalidCacheSpecifier";
        this.message = `Cache specifiers (i.e., keys, namespaces) cannot contain "::". "${input}" is invalid.`;
    }
}
export class BadCacheKeyFormat extends Error {
    constructor(key) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, BadCacheKeyFormat);
        }
        this.name = "BadCacheKeyFormat";
        this.message = `Provided key (${key}) does not adhere to "namespace::category::identifier" format required of keys.`;
    }
}
export class CacheKeyError extends Error {
    constructor(key) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CacheKeyError);
        }
        this.name = "CacheKeyError";
        this.message = `Failed to find or fetch information for key ${key}.`;
    }
}
export class BadItemKey extends Error {
    constructor(keypath, details) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, BadItemKey);
        }
        this.name = "BadItemKey";
        this.message = `${details?.default ? "Default" : "Given"} keypath into item (${keypath}) does not yield a defined value. Cache cannot process any items.`;
    }
}

import { Subject } from "rxjs";
export const DEFAULT_POLL_INTERVAL = 15000;
export class Poller {
    constructor(sdk, config) {
        // the data that will need to be polled
        this.data = new Map();
        this.sdk = sdk;
        this.subject = config?.subject || new Subject();
        this.interval = config?.interval || DEFAULT_POLL_INTERVAL;
        // defaults to false - the poller will not stop until the unsubscribe method is called directly
        this.unsubscribeUponDataCompletion = config?.unsubscribeUponDataCompletion || false;
        // populate the data map
        this.setData(config?.data);
    }
    setData(data) {
        data?.forEach((d) => {
            this.data.set(d.id, d);
        });
    }
    /**
     * starts the polling
     * @returns the subject that will be returned
     */
    start() {
        return this.poll();
    }
    /**
     * creates an interval to poll the data that needs to be polled
     * @returns the subject that will be returned
     */
    poll() {
        // poll once immediately
        this.handlePolling();
        this.intervalId = setInterval(() => {
            this.handlePolling();
        }, this.interval);
        return this.subject;
    }
    handlePolling() {
        return new Promise((resolve) => {
            if (this.data.size) {
                this.fetchData().then((refetchedData) => {
                    if (refetchedData.length) {
                        this.subject.next(refetchedData);
                    }
                });
            }
            else if (this.unsubscribeUponDataCompletion) {
                // unsubscribe if there are no more data to poll from
                this.stop();
            }
            resolve();
        });
    }
    /**
     * resets the interval and the data map
     */
    reset() {
        this.intervalId && clearInterval(this.intervalId);
        this.intervalId = null;
        this.data.clear();
    }
    /**
     * stops the poller and completes the subject
     */
    stop() {
        this.reset();
        this.subject?.complete();
    }
    /**
     * Exposes the ability to manually update poll subscribers.
     * @param next Array of data that subscribers should be updated with, or a
     * function that offers access to the poll's current state, which returns
     * an array of data that subscribers should be updated with.
     * @returns void
     */
    updateSubscribers(next) {
        const data = Array.isArray(next) ? next : next(Array.from(this.data.values()));
        this.setData(data);
        this.subject.next(Array.from(this.data.values()) || []);
    }
    isPolling() {
        return !!this.intervalId && !this.subject?.closed;
    }
}

import { FrequencyCapSettings, AppSettings } from "@madhive/mad-sdk";
import { selectCurrentUser } from "appReducers/authReducer";
import { DEFAULT_APPLICATION_SETTINGS } from "lib/constants/settings";
import {
  createContext,
  useContext,
  FC,
  useMemo,
  PropsWithChildren
} from "react";
import { useSelector } from "react-redux";
import { ApplicationSettings } from "types/ui/application-settings";

const appSettingToFrequencyCapSettings = (
  setting: AppSettings["campaignsFrequencySettings"]
): FrequencyCapSettings => ({
  dailyFrequencyCap: setting.dailyFreqCap,
  hourlyFrequencyCap: setting.hourlyFreqCap,
  hasFrequencyCap: setting.hasFreqCap
});

const ApplicationSettingsContext = createContext<
  ApplicationSettings | undefined
>(undefined);

export const useApplicationSettings = () => {
  const contextValue = useContext(ApplicationSettingsContext);

  if (!contextValue) {
    throw new Error(
      "You attempted to use OrgSettingsContext in a component without rendering its provider above it in the component tree."
    );
  }

  return contextValue;
};

export const ApplicationSettingsProvider: FC<PropsWithChildren> = props => {
  const user = useSelector(selectCurrentUser);

  const applicationSettingsValue: ApplicationSettings = useMemo(() => {
    if (!user) {
      return DEFAULT_APPLICATION_SETTINGS;
    }
    const {
      settings: {
        appSettings: { campaignsFrequencySettings }
      }
    } = user;
    return {
      manageCampaignsDefaultFrequencySettings: appSettingToFrequencyCapSettings(
        campaignsFrequencySettings
      )
    };
  }, [user]);

  return (
    <ApplicationSettingsContext.Provider value={applicationSettingsValue}>
      {props.children}
    </ApplicationSettingsContext.Provider>
  );
};

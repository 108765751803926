import { NotificationCategory, NotificationStatuses } from "../../models/notifications";
import axios from "axios";
import { MadSDKAbortError } from "../../errors";
import { getDetailedErrorMessage } from "../../utils/validation";
var ReportType;
(function (ReportType) {
    ReportType["BY_LINE_ITEM_ONLY"] = "By Line Item Only";
    ReportType["BY_LINE_ITEM_AND_DAY"] = "By Line Item and Day";
})(ReportType || (ReportType = {}));
const REPORT_URL = {
    [ReportType.BY_LINE_ITEM_ONLY]: "/dashboard/lineitem",
    [ReportType.BY_LINE_ITEM_AND_DAY]: "/dashboard/day"
};
export class DashboardReports {
    constructor(sdk) {
        this.sdk = sdk;
    }
    async byLineItemOnly(filters) {
        return this.generateReport(ReportType.BY_LINE_ITEM_ONLY, filters);
    }
    async byLineItemAndDay(filters) {
        return this.generateReport(ReportType.BY_LINE_ITEM_AND_DAY, filters);
    }
    async generateReport(reportType, filters) {
        const user = this.sdk.getCurrentUser();
        if (!user) {
            return Promise.reject("No Active User");
        }
        try {
            /**
             * Create notification doc for dashboard CSV query and pass notification ID to Hermes /dashboard endpoint.
             * This notification will by default be set to `status` = `IN_QUEUE` so as not to trigger an inbox message.
             * When the dashboard CSV is complete, Hermes will update the same notification with status = `SENT`
             */
            const now = new Date();
            /**
             * formats date into the following format
             * MM/dd/yyyy HH:mm a
             * 01/01/2023 00:00 AM
             * */
            const dateSubmitted = new Intl.DateTimeFormat("en-US", {
                month: "2-digit",
                day: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                hourCycle: "h11",
                formatMatcher: "best fit"
            })
                .format(now)
                .replace(",", "");
            // TODO: refactor smithers notifications to consolidate types and module in mad-sdk?
            const notification = {
                title: "Dashboard CSV Export Complete",
                content: `Date Submitted: ${dateSubmitted}, Filter Start Date: ${filters.start}, Filter End Date: ${filters.end}, Report Type: ${reportType}`,
                status: NotificationStatuses.IN_QUEUE,
                category: NotificationCategory.DOWNLOAD,
                recipientId: user.email,
                authorId: user.email,
                actions: {
                    0: { buttonText: "Download Report" }
                },
                meta: {
                    submittedDate: now,
                    filterStartDate: new Date(filters.start),
                    filterEndDate: new Date(filters.end),
                    reportType: reportType
                }
            };
            const notificationId = await this.sdk.notifications.createNotification(notification);
            const cancelTokenSource = axios.CancelToken.source();
            const { data } = await axios.post(`${this.sdk.urls.baseHermesUrl}${REPORT_URL[reportType]}/${notificationId}`, this.buildQueryParams(filters), {
                headers: {
                    "Content-Type": "application/json"
                },
                cancelToken: cancelTokenSource.token
            });
            return Promise.resolve(data);
        }
        catch (error) {
            if (axios.isCancel(error)) {
                return Promise.reject(new MadSDKAbortError());
            }
            return Promise.reject(new Error(getDetailedErrorMessage(error, error.message)));
        }
    }
    buildQueryParams(filters) {
        return {
            parent_id: filters.parentId,
            owner_ids: filters.ownerIds,
            advertiser_ids: filters.advertiserIds,
            start: filters.start,
            end: filters.end,
            timezone: filters.timezone,
            products: filters.products,
            adbook_package_id: filters.adbookPackageId,
            campaign_ids: filters.campaignIds,
            line_item_ids: filters.lineItemIds,
            date_filter_type: filters.dateFilterType,
            brands: filters.brands,
            estimates: filters.estimates,
            media_type: filters.mediaType,
            dmas: filters.dmas,
            stations: filters.stations,
            agencies: filters.agencies,
            iab_adv_categories: filters.iabAdvertiserCategories,
            iab_creative_categories: filters.iabCreativeCategories
        };
    }
}

import { IToastProps } from "@blueprintjs/core";
import { CancelTokenSource } from "axios";

export interface DashboardReportsState {
  isProcessingRequest: boolean;
  cancellationSource?: CancelTokenSource;
  error:
    | ({
        message: string;
      } & IToastProps)
    | null;
}

export const PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_PENDING =
  "PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_PENDING";

export const PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_SUCCESS =
  "PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_SUCCESS";

export const PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_FAILURE =
  "PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_FAILURE";

export const PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_PENDING =
  "PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_PENDING";

export const PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_SUCCESS =
  "PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_SUCCESS";

export const PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_FAILURE =
  "PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_FAILURE";

interface ProcessLineItemOnlyReportRequestPendingAction {
  type: typeof PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_PENDING;
}

interface ProcessLineItemOnlyReportRequestSuccessAction {
  type: typeof PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_SUCCESS;
}

interface ProcessLineItemOnlyReportRequestFailureAction {
  type: typeof PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface ProcessLineItemAndDayReportRequestPendingAction {
  type: typeof PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_PENDING;
}

interface ProcessLineItemAndDayReportRequestSuccessAction {
  type: typeof PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_SUCCESS;
}

interface ProcessLineItemAndDayReportRequestFailureAction {
  type: typeof PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

export type DashboardReportActions =
  | ProcessLineItemOnlyReportRequestPendingAction
  | ProcessLineItemOnlyReportRequestSuccessAction
  | ProcessLineItemOnlyReportRequestFailureAction
  | ProcessLineItemAndDayReportRequestPendingAction
  | ProcessLineItemAndDayReportRequestSuccessAction
  | ProcessLineItemAndDayReportRequestFailureAction;

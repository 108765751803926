import { MadTracker } from "@madhive/mad-tracker";
import { User as MadSDKUser } from "@madhive/mad-sdk";

import {
  DATE_OF_CURRENT_RELEASE,
  __DEV__,
  __PROD__
} from "lib/constants/config";
import { REACT_APP_GIT_COMMIT } from "lib/viteEnvConfig";

import {
  FirebaseAnayltics,
  FullStory,
  Heap,
  Segment,
  Sprig
} from "./listeners";

const madTracker = new MadTracker<
  MadSDKUser,
  {
    [key: string]: string;
  }
>();

if (__PROD__) {
  madTracker.listeners.register("fullstory", new FullStory());
  madTracker.listeners.register("firebase-analytics", new FirebaseAnayltics());
}

if (__DEV__) {
  madTracker.listeners.register("segment", new Segment());
}

// Heap is registered as a new listener so we can now track events.
madTracker.listeners.register("heap", new Heap());
madTracker.listeners.register("sprig", new Sprig());

madTracker.core = {
  release_date: DATE_OF_CURRENT_RELEASE.toISOString(),
  release_commit: `${REACT_APP_GIT_COMMIT}`
};

export { madTracker };

import { useState, useEffect } from "react";

/**
 * Hook to delay changes to a value until the last change has occurred at least
 * `delayInMilliseconds` milliseconds ago.
 *
 * Usage:
 *
 * ```
 * const [userInput, setUserInput] = useState("")
 *
 * const debouncedUserInput = useDebounce(userInput, 1000);
 *
 * // As the user types into e.g. a text field, `userInput` will be updated as they type,
 * // But the value of `debouncedUserInput` will only change after they have stopped
 * // typing for at least 1000 milliseconds
 * ```
 * */
export const useDebouncedValue = <T>(value: T, delayInMilliseconds: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = window.setTimeout(() => {
      setDebouncedValue(value);
    }, delayInMilliseconds);

    return () => {
      window.clearTimeout(handler);
    };
  }, [value, delayInMilliseconds]);

  return debouncedValue;
};

import { selectCurrentUser } from "appReducers/authReducer";
import { keyBy } from "lodash";
import axios, { AxiosError } from "axios";
import { selectCurrentOrganization } from "appReducers/organizationsReducer";
import { MOCK_ADOMAIN } from "lib/constants/misc";
import {
  Advertiser,
  AdvertiserFilters,
  Filter,
  FilterTypes,
  NoAuthorizedUser,
  OrganizationType,
  ServiceStatus
} from "@madhive/mad-sdk";
import { AppThunk } from "types";
import { isErrorResponseValid, deriveErrorResponse } from "lib/utils/api";
import { madSDK } from "lib/sdk";
import { BaseAppNames } from "lib/constants/config";
import {
  ABORT_ADVERTISER_REQUEST,
  GET_AVAILABLE_ADVERTISERS_FAILURE,
  GET_AVAILABLE_ADVERTISERS_PENDING,
  GET_AVAILABLE_ADVERTISERS_SUCCESS,
  GET_RUNNING_ADVERTISERS_FAILURE,
  GET_RUNNING_ADVERTISERS_PENDING,
  GET_RUNNING_ADVERTISERS_SUCCESS,
  CREATE_ADVERTISER_SUCCESS,
  UPDATE_ADVERTISER_PENDING,
  UPDATE_ADVERTISER_SUCCESS,
  UPDATE_ADVERTISER_FAILURE,
  AdvertisersActionTypes
} from "./types";

const setGetAvailableAdvertisersPending = () => ({
  type: GET_AVAILABLE_ADVERTISERS_PENDING
});

const setGetAvailableAdvertisersSuccess = (
  advertisers: Record<string, Advertiser>
): AdvertisersActionTypes => ({
  type: GET_AVAILABLE_ADVERTISERS_SUCCESS,
  payload: advertisers,
  meta: {
    timestamp: Date.now()
  }
});

const setGetAvailableAdvertisersFailure = (
  serverError?: string
): AdvertisersActionTypes => ({
  type: GET_AVAILABLE_ADVERTISERS_FAILURE,
  meta: {
    error: {
      message:
        serverError ||
        "There was an error retrieving the list of available advertisers."
    }
  }
});

const setGetRunningAdvertisersPending = () => ({
  type: GET_RUNNING_ADVERTISERS_PENDING
});

const setGetRunningAdvertisersSuccess = (
  advertisers: Record<string, Advertiser>
): AdvertisersActionTypes => ({
  type: GET_RUNNING_ADVERTISERS_SUCCESS,
  payload: advertisers,
  meta: {
    timestamp: Date.now()
  }
});

const setGetRunningAdvertisersFailure = (
  serverError?: string
): AdvertisersActionTypes => ({
  type: GET_RUNNING_ADVERTISERS_FAILURE,
  meta: {
    error: {
      message:
        serverError ||
        "There was an error retrieving the list of running advertisers."
    }
  }
});

export const setCreateAdvertiserSuccess = (
  advertiser: Advertiser,
  requestId: string
): AdvertisersActionTypes => ({
  type: CREATE_ADVERTISER_SUCCESS,
  payload: advertiser,
  meta: {
    requestId,
    success: {
      message: `${advertiser.name} successfully created.`
    }
  }
});

const setUpdateAdvertiserPending = (
  advertiser: Advertiser
): AdvertisersActionTypes => ({
  type: UPDATE_ADVERTISER_PENDING,
  meta: { advertiser }
});

export const setUpdateAdvertiserSuccess = (
  advertiser: Advertiser
): AdvertisersActionTypes => ({
  type: UPDATE_ADVERTISER_SUCCESS,
  payload: advertiser,
  meta: {
    success: {
      message: `${advertiser.name} successfully updated.`
    }
  }
});

const setUpdateAdvertiserFailure = (
  id: string,
  serverError?: string
): AdvertisersActionTypes => ({
  type: UPDATE_ADVERTISER_FAILURE,
  meta: {
    id,
    error: {
      message: serverError || "Failed to save advertiser",
      timeout: 3000
    }
  }
});

const setAbortAdvertiserRequest = (): AdvertisersActionTypes => ({
  type: ABORT_ADVERTISER_REQUEST
});

export const getAllAdvertisers =
  (): AppThunk<Promise<Record<string, Advertiser>>> =>
  async (dispatch, getState) => {
    const currentOrganization = selectCurrentOrganization(getState());
    const currentUser = selectCurrentUser(getState());

    dispatch(setGetAvailableAdvertisersPending());

    if (
      currentOrganization &&
      currentOrganization.type === OrganizationType.ADVERTISER
    ) {
      const advertisers: Record<string, Advertiser> = {
        [currentOrganization.id]: {
          //* * if primary org type is advertiser, adomain will never be exposed to the user */
          adomain: MOCK_ADOMAIN,
          id: currentOrganization.id,
          name:
            (currentUser && currentUser.settings.baseAppName) ||
            BaseAppNames.MADHIVE,
          externalId: ""
        }
      };

      dispatch(setGetAvailableAdvertisersSuccess(advertisers));

      return advertisers;
    }

    return madSDK.advertisers.find().then(
      advertisers => {
        const advertisersById: Record<string, Advertiser> = keyBy(
          advertisers,
          "id"
        );

        dispatch(setGetAvailableAdvertisersSuccess(advertisersById));

        return advertisersById;
      },
      error => {
        if (axios.isCancel(error)) {
          dispatch(setAbortAdvertiserRequest());
        } else if (isErrorResponseValid(error)) {
          dispatch(
            setGetAvailableAdvertisersFailure(
              deriveErrorResponse(error).filter(Boolean).join(",")
            )
          );
        } else {
          dispatch(setGetAvailableAdvertisersFailure());
        }
        throw error;
      }
    );
  };

export type GetRunningAdvertiserProps = {
  runsBetween?: [Date, Date];
  campaignStatuses?: ServiceStatus[];
};

export const getRunningAdvertisers =
  ({
    runsBetween,
    campaignStatuses
  }: GetRunningAdvertiserProps): AppThunk<
    Promise<Record<string, Advertiser>>
  > =>
  async (dispatch, getState) => {
    const currentOrganization = selectCurrentOrganization(getState());
    const currentUser = selectCurrentUser(getState());

    dispatch(setGetRunningAdvertisersPending());

    if (
      currentOrganization &&
      currentOrganization.type === OrganizationType.ADVERTISER
    ) {
      const advertisers: Record<string, Advertiser> = {
        [currentOrganization.id]: {
          //* * if primary org type is advertiser, adomain will never be exposed to the user */
          adomain: MOCK_ADOMAIN,
          id: currentOrganization.id,
          name:
            (currentUser && currentUser.settings.baseAppName) ||
            BaseAppNames.MADHIVE,
          externalId: ""
        }
      };

      dispatch(setGetRunningAdvertisersSuccess(advertisers));

      return advertisers;
    }

    const filters: Filter<AdvertiserFilters> = { where: [] };
    if (runsBetween) {
      filters.where!.push({
        field: "runsBetween",
        type: FilterTypes.EQ,
        value: runsBetween
      });
    }
    if (campaignStatuses) {
      filters.where!.push({
        field: "campaignStatuses",
        type: FilterTypes.EQ,
        value: campaignStatuses
      });
    }

    return madSDK.advertisers.find(filters).then(
      (advertisers: Advertiser[]) => {
        const advertisersById: Record<string, Advertiser> = keyBy(
          advertisers,
          "id"
        );

        dispatch(setGetRunningAdvertisersSuccess(advertisersById));

        return advertisersById;
      },
      (error: AxiosError) => {
        if (axios.isCancel(error)) {
          dispatch(setAbortAdvertiserRequest());
        } else if (isErrorResponseValid(error)) {
          dispatch(
            setGetRunningAdvertisersFailure(
              deriveErrorResponse(error).filter(Boolean).join(",")
            )
          );
        } else {
          dispatch(setGetRunningAdvertisersFailure());
        }
        throw error;
      }
    );
  };

export const updateAdvertiser =
  (advertiser: Advertiser): AppThunk<Promise<Advertiser>> =>
  async dispatch => {
    const advertiserFormatted: Advertiser = {
      ...advertiser,
      name: advertiser.name.trim()
    };

    dispatch(setUpdateAdvertiserPending(advertiserFormatted));

    return madSDK.advertisers
      .save(advertiserFormatted)
      .then(newAdvertiser => {
        dispatch(setUpdateAdvertiserSuccess(newAdvertiser));
        return newAdvertiser;
      })
      .catch(error => {
        if (error instanceof NoAuthorizedUser) {
          dispatch(
            setUpdateAdvertiserFailure(
              advertiserFormatted.id || "",
              "User is not logged in"
            )
          );
        } else {
          dispatch(
            setUpdateAdvertiserFailure(advertiserFormatted.id || "", error)
          );
        }
        throw error;
      });
  };

import { User } from "@madhive/mad-sdk";

/**
 * Helper function to get an item from localStorage for a given key
 * @param  {string} localStorageKey
 * @returns string
 */
export const getLocalStorageItem = (localStorageKey: string): string | null =>
  localStorage.getItem(localStorageKey);

/**
 * Helper function to set an item with a given key in localStorage
 * @param  {string} localStorageKey
 * @param  {string} item
 * @returns void
 */
export const setLocalStorageItem = (
  localStorageKey: string,
  item: string
): void => {
  localStorage.setItem(localStorageKey, item);
};

/**
 * Base procedure to get parsed JSON customization stored in localStorage for a given key
 * @param  {string} localStorageKey
 * @returns Record<string, string[]> | null
 *
 * e.g. {"scripps":["name","madhiveid","last_updated"]}
 */
export const getCustomizedColumnIds = (
  localStorageKey: string
): Record<string, string[]> | null => {
  const customizedColumnIdsJSON = getLocalStorageItem(localStorageKey);
  return customizedColumnIdsJSON ? JSON.parse(customizedColumnIdsJSON) : null;
};

/**
 * Given a set of column ids, this returns an array them in the default (source code) order
 * @param  {Set<string>} manageCampaignsColumnIdSet
 * @param  {Map<any, (User|null) => string>} columnMap
 */
export const buildDefaultOrderedColumnIds = (
  manageCampaignsColumnIdSet: Set<string>,
  columnMap: Map<any, (user: User | null) => string>
) =>
  [...columnMap.keys()].reduce((orderedColumnIds, currentColumnId) => {
    if (manageCampaignsColumnIdSet.has(currentColumnId)) {
      return [...orderedColumnIds, currentColumnId];
    }
    return [...orderedColumnIds];
  }, []);

/**
 * Build new column customization with any other current localStorage customizations expressed in JSON
 * @param  {Record<string, string[]>|null} currentCustomization
 * @param  {string[]} customColumnIdsToSet
 * @param  {User} user
 * @returns string
 */
export const buildCompleteUserColumnCustomization = (
  currentCustomization: Record<string, string[]> | null,
  customColumnIdsToSet: string[],
  user: User
): string =>
  JSON.stringify({
    ...currentCustomization, // Current set of customized columns
    [user.primaryOrganizationName]: [...customColumnIdsToSet] // New set of customized columns
  });

/**
 * This functions returns true if the current set of customColumnIds are accepted (i.e. are present
 * in the default set of columns)
 * @param  {string[]} customColumnIds
 * @param  {Set<string>} acceptedColumnIdSet
 * @returns boolean
 */
export const validateCustomColumnIdsIsSubsetOfKnownColumnIds = (
  customColumnIds: string[],
  acceptedColumnIdSet: Set<string>
): boolean => customColumnIds.every(id => acceptedColumnIdSet.has(id));

/**
 * Backend team is working on some breaking changes to this api
 * We will eventually need to migrate all requests to api v2 which will have some requests/response data structure changes
 */
export var ApiVersion;
(function (ApiVersion) {
    ApiVersion["V1"] = "v1";
    ApiVersion["V2"] = "v2";
})(ApiVersion || (ApiVersion = {}));
export var StatusCode;
(function (StatusCode) {
    StatusCode[StatusCode["SUCCESS"] = 200] = "SUCCESS";
    StatusCode[StatusCode["IN_PROGRESS"] = 202] = "IN_PROGRESS";
    StatusCode[StatusCode["NO_CONTENT"] = 204] = "NO_CONTENT";
})(StatusCode || (StatusCode = {}));

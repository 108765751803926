import { CampaignStatus, isLiveStatus } from "../../../../models/campaigns/instruction";
import { ServiceStatus } from "../../../../types";
const MISSING_CREATIVE_MESSAGE = "No creative flights associated with this line item.";
const NO_ACTIVE_CREATIVE_MESSAGE = "No creative flights associated with this line item are active.";
/**
 * @param lineItem: a line item.
 * @param value: the status value to validate.
 * @param current: the current status value of the line item.
 * @param updating: true if this validation is for an update to the line item, else false (i.e., an as-is validation).
 * @return: a validation error message, if it is not valid. Else, undefined.
 */
export const validateStatus = (lineItem, value, current, updating) => {
    if (!Object.values(CampaignStatus).includes(value)) {
        return "Status should be a valid campaign status.";
    }
    try {
        switch (value) {
            case CampaignStatus.LIVE:
                return validateLiveStatus(lineItem, current, updating);
            case CampaignStatus.PAUSED:
                return validatePausedStatus(lineItem, current, updating);
            case CampaignStatus.ARCHIVED:
                return validateArchivedStatus(lineItem, current, updating);
            case CampaignStatus.READY:
                return validateReadyStatus(lineItem, current, updating);
        }
    }
    catch {
        return "Line item has an invalid structure.";
    }
    return;
};
const validateLiveStatus = (lineItem, current, updating) => {
    const now = new Date();
    if (updating) {
        switch (current) {
            case CampaignStatus.CANCELLED:
                return "Canceled line items cannot be live.";
            case CampaignStatus.ARCHIVED:
                return "Archived line items can't be live.";
            case CampaignStatus.MISSING_CREATIVE:
                return MISSING_CREATIVE_MESSAGE;
            case CampaignStatus.NO_ACTIVE_CREATIVE:
                return NO_ACTIVE_CREATIVE_MESSAGE;
        }
        if (!(lineItem.startDate instanceof Date) ||
            (lineItem.startDate < now &&
                !lineItem.startAsap &&
                lineItem.instructionStatus != ServiceStatus.PAUSED)) {
            return "Cannot be live. Start date is in the past.";
        }
    }
    if (!(lineItem.endDate instanceof Date) || lineItem.endDate < now) {
        return "Cannot be live. End date is in the past.";
    }
    if (!lineItem.attachedCreatives?.length) {
        return MISSING_CREATIVE_MESSAGE;
    }
    if (!lineItem.attachedCreatives.some((creative) => creative.status === ServiceStatus.READY && creative.endDate > now)) {
        return NO_ACTIVE_CREATIVE_MESSAGE;
    }
    return;
};
const validatePausedStatus = (lineItem, current, updating) => {
    if (updating) {
        if (current === CampaignStatus.PAUSED) {
            return "A line item which is already paused cannot be paused again.";
        }
        if (!isLiveStatus(current)) {
            return "Inactive line items can't be paused.";
        }
    }
    return;
};
const validateArchivedStatus = (lineItem, current, updating) => {
    if (updating) {
        switch (current) {
            case CampaignStatus.INVALID:
                return "Invalid line items can't be archived.";
            case CampaignStatus.COMPLETED:
                return "Completed line items can't be archived.";
            case CampaignStatus.PAUSED:
                return "A line item which was previously active cannot be archived.";
            case CampaignStatus.ARCHIVED:
                return "This line item is already archived. An archived line item cannot be archived again.";
        }
    }
    const { endDate, startDate } = lineItem;
    const now = new Date();
    let past = true;
    let future = true;
    if (!startDate && endDate) {
        past = endDate < now;
        future = endDate > now;
    }
    else if (startDate && !endDate) {
        past = startDate < now;
        future = startDate > now;
    }
    else if (startDate && endDate) {
        past = startDate < now && endDate < now;
        future = startDate > now && endDate > now;
    }
    return past || future ? undefined : "Live line items can't be archived.";
};
const validateReadyStatus = (lineItem, current, updating) => {
    if (updating && current !== CampaignStatus.ARCHIVED) {
        return "This line item is not archived. It cannot be unarchived.";
    }
    return;
};

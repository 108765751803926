import { FC, SVGProps } from "react";

export const DefaultWorkflowIcon: FC<SVGProps<number | string>> = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="workflow-icon"
  >
    <g clipPath="url(#clip0)">
      <path
        d="M28.0001 5.49994H21.9501C21.7286 4.42384 20.8305 3.61844 19.7368 3.51503C18.643 3.41161 17.6099 4.03442 17.1906 5.04994L8.71511 2.11294C8.21354 1.93625 7.66218 1.96707 7.18342 2.19857C6.70466 2.43006 6.3381 2.84308 6.16511 3.34594L0.112112 20.8054C-0.0635469 21.3089 -0.030857 21.8617 0.202919 22.341C0.436695 22.8203 0.852196 23.1864 1.35711 23.3579L9.00011 25.9819V25.9999C9.00011 27.1045 9.89554 27.9999 11.0001 27.9999H22.0001C22.0657 27.9996 22.1306 27.9866 22.1911 27.9614C22.2518 27.9365 22.3071 27.8998 22.3536 27.8534L29.8536 20.3534C29.8999 20.3069 29.9366 20.2517 29.9616 20.1909C29.9867 20.1304 29.9998 20.0655 30.0001 19.9999V7.49994C30.0001 6.39538 29.1047 5.49994 28.0001 5.49994ZM19.5001 4.49994C20.3285 4.49994 21.0001 5.17152 21.0001 5.99994C21.0001 6.82837 20.3285 7.49994 19.5001 7.49994C18.6717 7.49994 18.0001 6.82837 18.0001 5.99994C18.0001 5.17152 18.6717 4.49994 19.5001 4.49994ZM1.67861 22.4109C1.42607 22.3251 1.21829 22.1419 1.10149 21.9021C0.984682 21.6623 0.968526 21.3857 1.05661 21.1339L7.11261 3.67244C7.1987 3.42122 7.38168 3.21487 7.6208 3.09935C7.85992 2.98382 8.13531 2.96874 8.38561 3.05744L15.4341 5.49994H11.0001C9.89554 5.49994 9.00011 6.39538 9.00011 7.49994V24.9249L1.67861 22.4109ZM10.0001 25.9999V7.49994C10.0001 6.94766 10.4478 6.49994 11.0001 6.49994H17.0501C17.2867 7.66511 18.3112 8.50245 19.5001 8.50245C20.6891 8.50245 21.7135 7.66511 21.9501 6.49994H28.0001C28.5524 6.49994 29.0001 6.94766 29.0001 7.49994V19.4999H23.5001C22.3955 19.4999 21.5001 20.3954 21.5001 21.4999V26.9999H11.0001C10.4478 26.9999 10.0001 26.5522 10.0001 25.9999ZM28.2931 20.4999L22.5001 26.2929V21.4999C22.5001 20.9477 22.9478 20.4999 23.5001 20.4999H28.2931Z"
        fill="#60656C"
      />
      <path
        d="M12.5 10.9999H16C16.2761 10.9999 16.5 10.776 16.5 10.4999C16.5 10.2237 16.2761 9.99988 16 9.99988H12.5C12.2239 9.99988 12 10.2237 12 10.4999C12 10.776 12.2239 10.9999 12.5 10.9999Z"
        fill="#60656C"
      />
      <path
        d="M12.5 13.4999H25C25.2761 13.4999 25.5 13.2761 25.5 12.9999C25.5 12.7238 25.2761 12.4999 25 12.4999H12.5C12.2239 12.4999 12 12.7238 12 12.9999C12 13.2761 12.2239 13.4999 12.5 13.4999Z"
        fill="#60656C"
      />
      <path
        d="M12.5 16H25C25.2761 16 25.5 15.7761 25.5 15.5C25.5 15.2239 25.2761 15 25 15H12.5C12.2239 15 12 15.2239 12 15.5C12 15.7761 12.2239 16 12.5 16Z"
        fill="#60656C"
      />
      <path
        d="M12.5 18.4999H25C25.2761 18.4999 25.5 18.276 25.5 17.9999C25.5 17.7237 25.2761 17.4999 25 17.4999H12.5C12.2239 17.4999 12 17.7237 12 17.9999C12 18.276 12.2239 18.4999 12.5 18.4999Z"
        fill="#60656C"
      />
      <path
        d="M14.5 21.9999H12.5C12.2239 21.9999 12 22.2237 12 22.4999C12 22.776 12.2239 22.9999 12.5 22.9999H14.5C14.7761 22.9999 15 22.776 15 22.4999C15 22.2237 14.7761 21.9999 14.5 21.9999Z"
        fill="#60656C"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

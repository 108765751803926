export const MESSAGE_GETTER_DICTIONARY = {
  CREATE_SUCCESS: () => "This line item was successfully created.",
  CREATE_SUCCESS_FETCH_FAILURE: () =>
    "The line item was successfully created but the latest data could not be fetched. Please refresh to see the most up-to-date data.",
  CREATE_KEY_FAILURE: () =>
    "Failed to create line item because an ID could not be generated.",
  CREATE_MINT_ERROR: () => "Failed to mint inst key",
  CREATE_FAILURE: () => "Failed to create line item",
  CREATE_SETTINGS_FAILURE: () =>
    "Failed to create a line item with these settings. Make sure all fields are valid and try again.",
  ARCHIVE_SUCCESS: () => "This line item has been successfully archived.",
  ARCHIVE_FAILURE: () => "This line item could not be archived.",
  ARCHIVE_FETCH_FAILURE: () =>
    "This line item has been successfully archived but the latest data could not be fetched. It's recommended to refresh the page to ensure you're seeing the latest data.",
  CURRENTLY_UPDATING: () =>
    "Line Item is currently being updated, please try again later.",
  FORBIDDEN_FROM_UPDATING_FAILURE: () =>
    "User is not permitted to perform this action.",
  LATEST_DATA_UNABLE_TO_BE_FETCHED_FAILURE: () =>
    "The line item was successfully created but the latest data could not be fetched. Please refresh to see the most up-to-date data.",
  UPDATE_SUCCESS: () =>
    "Your changes to this line item have been saved. Please wait while the most up to date data is retrieved...",
  UPDATE_DETAIL_SUCCESS: () =>
    "Your changes to this line item have been saved.",
  UPDATE_REFRESH: () =>
    "Your changes have been saved but the latest data for this line item could not be retrieved. It's recommended you refresh in order to see the most up-to-date information.",
  UPDATE_FAILURE: () => "Failed to save your changes to this line item."
};

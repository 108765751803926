import { FilterTypes, parsePagingQuery, toQueryParam } from "../../handlers/filter";
import { buildSort } from "./sorting";
/**
 * @param wheres: the `where` portion in an SDK filter object.
 * @return: a properly-formatted query parameter string, based on the filters. If there are none, the empty string.
 */
const wheresToQueryParams = (wheres) => {
    let params = "";
    if (wheres) {
        wheres.forEach((filter) => {
            /**
             * Below, we parse filters into the campaignmanager API equivalent.
             * Some quirks:
             *  - some fields expect a comma-separated list, plain (e.g., foo=1,2,3).
             *  - some fields expect a single value (e.g., foo=baz)
             *  - some fields expect a JSON-y array (e.g., foo=["bar", "baz"])
             *  - some fields expect a pipe-separated value (e.g., foo=bar|baz)
             *  - some fields expect an encoded JSON blob (e.g., foo=%7B%22key%22%3A%22value%22%7D=) (blob was { key: "value" })
             * Each case below handles this as appropriate for the given field.
             */
            switch (filter.field) {
                case "startDate": {
                    const value = toQueryParam(filter);
                    if (filter.type === FilterTypes.GT) {
                        params = `${params}&startsAfter=${value}`;
                    }
                    else if (filter.type === FilterTypes.LT) {
                        params = `${params}&startsBefore=${value}`;
                    }
                    break;
                }
                case "endDate": {
                    const value = toQueryParam(filter);
                    if (filter.type === FilterTypes.LT) {
                        params = `${params}&endsBefore=${value}`;
                    }
                    else if (filter.type === FilterTypes.GT) {
                        params = `${params}&endsAfter=${value}`;
                    }
                    break;
                }
                case "timeframe": {
                    const value = toQueryParam(filter, { join: "|" });
                    if (filter.type === FilterTypes.BETWEEN) {
                        params = `${params}&runsBetween=${value}`;
                    }
                    break;
                }
                case "lastUpdated": {
                    const value = toQueryParam(filter);
                    params = `${params}&lastModified=${value}`;
                    break;
                }
                case "state": {
                    const value = toQueryParam(filter);
                    params = `${params}&statuses=${value}`;
                    break;
                }
                case "status": {
                    const value = toQueryParam(filter);
                    params = `${params}&campaign_statuses=${value}`;
                    break;
                }
                case "mediaType": {
                    const value = toQueryParam(filter);
                    params = `${params}&media_types=${value}`;
                    break;
                }
                case "category": {
                    const value = toQueryParam(filter);
                    params = `${params}&advertiser_categories=${value}`;
                    break;
                }
                case "updatedBy": {
                    const value = toQueryParam(filter, { array: true });
                    params = `${params}&updateUsers=${value}`;
                    break;
                }
                case "station": {
                    const value = toQueryParam(filter, { array: true });
                    params = `${params}&stations=${value}`;
                    break;
                }
                case "agency": {
                    const value = toQueryParam(filter, { array: true });
                    params = `${params}&agencies=${value}`;
                    break;
                }
                case "advertiser": {
                    const value = toQueryParam(filter, { array: true });
                    params = `${params}&advertisers=${value}`;
                    break;
                }
                case "name": {
                    const value = toQueryParam(filter, { array: true });
                    params = `${params}&campaign_names=${value}`;
                    break;
                }
                case "id": {
                    const value = toQueryParam(filter, { array: true });
                    params = `${params}&ids=${value}`;
                    break;
                }
                case "meta": {
                    // the caller should have provided meta as a stringified JSON blob
                    if (typeof filter.value === "string") {
                        const value = toQueryParam(filter);
                        params = `${params}&ext_metas=${value}`;
                    }
                    break;
                }
                case "search": {
                    params = `${params}&search=${filter.value}`;
                    break;
                }
            }
        });
    }
    return params;
};
/**
 * @param fields: the `fields` portion in an SDK filter object.
 * @return: a properly-formatted query parameter string, based on the fields. If there are none, the empty string.
 */
const fieldsToQueryParams = (fields) => {
    let params = "";
    if (fields) {
        // we exclude these, because the fields API is also pulling
        // double-duty to trigger pacing hydration
        const excluded = new Set(["pacing", "videoCompletionRate", "frequency"]);
        let mod = "";
        for (const field of fields) {
            if (!excluded.has(field)) {
                params = `${params}${mod}${field}`;
                mod = ",";
            }
        }
    }
    return params.length > 0 ? `&fields=${params}` : "";
};
/**
 * @param sort: information on the field and direction of sorting, if any.
 * @return: a properly-formatted query string, based on the provided sorting info. Empty if there is no sort.
 */
const sortToQueryParam = (sort) => {
    let params = "";
    if (sort && sort.field && sort.direction) {
        params = `${params}&sortBy=${buildSort(sort)}`;
    }
    return params;
};
/**
 * @param filters: array of SDK-style isntruction filters.
 * @param sort: optional. Information on the field and direction of sorting, if any.
 * @return: a proper query string for the supplied filters.
 */
export const toQueryParams = (filters, sort) => {
    const params = `${fieldsToQueryParams(filters.fields)}${wheresToQueryParams(filters.where)}${parsePagingQuery(filters.paging)}${sortToQueryParam(sort)}`;
    return params.length ? `?${params}` : "";
};

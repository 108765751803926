import { objectLookup } from "@madhive/mad-sdk";
import { useMemo } from "react";
import { madSDK } from "../sdk";
class InvalidCollection extends Error {
    /**
     * @param collection: the offending collection name.
     */
    constructor(collection) {
        super();
        if (Error?.captureStackTrace) {
            Error.captureStackTrace(this, InvalidCollection);
        }
        this.name = "InvalidCollection";
        this.message = `No SDK collection with the name '${collection}' exists.`;
    }
}
export const getHandler = (collection) => {
    const handler = objectLookup(madSDK, collection);
    if (!handler) {
        throw new InvalidCollection(`${collection}`);
    }
    return handler;
};
export const useCollection = (collection) => useMemo(() => getHandler(collection), [collection]);

import { ClientScenarioResultsHydrated, PlanStatus } from "@madhive/mad-sdk";
import { keyBy } from "lodash";
import {
  GET_PLAN_METADATA_DATA_PENDING,
  GET_SCENARIO_RESULTS_SUCCESS,
  PlannerActionTypes,
  PlannerState,
  REFRESH_PLAN_METADATA_FAILURE,
  REFRESH_PLAN_METADATA_SUCCESS,
  REFRESH_SINGLE_PLAN_SUCCESS
} from "./types";

export const initialState: PlannerState = {
  planMetadata: [],
  receivedPlanMetadataAt: null,
  isPlanMetadataLoadingFirstFetch: false,
  isPlanMetadataError: false,
  scenarioResults: {}
};

const getCurrentPlanIdOfScenarioResults = (
  scenarioResultsState: Record<string, ClientScenarioResultsHydrated>
) =>
  Object.values(scenarioResultsState).find(scenario => !!scenario.planId)
    ?.planId;

export const plannerReducer = (
  state = initialState,
  action: PlannerActionTypes
): PlannerState => {
  switch (action.type) {
    case GET_PLAN_METADATA_DATA_PENDING: {
      return {
        ...state,
        // since the source of the plans is an observable, it's technically
        // always 'pending'. We only want a pending state to be truthy
        // between the first invocation of the observable subscription and
        // the emission of the first value from that subscription.
        isPlanMetadataLoadingFirstFetch: true,
        receivedPlanMetadataAt: null
      };
    }

    case REFRESH_PLAN_METADATA_SUCCESS: {
      return {
        ...state,
        planMetadata: action.payload,
        receivedPlanMetadataAt: action.meta.timestamp,
        isPlanMetadataError: false,
        isPlanMetadataLoadingFirstFetch: false,
        scenarioResults: {}
      };
    }

    case REFRESH_SINGLE_PLAN_SUCCESS: {
      const currentPlanId = getCurrentPlanIdOfScenarioResults(
        state.scenarioResults
      );

      const refreshedScenariosById = keyBy(action.payload.scenarios, "id");

      return {
        ...state,
        planMetadata: [action.payload],
        receivedPlanMetadataAt: action.meta.timestamp,
        scenarioResults:
          action.payload.id === currentPlanId
            ? keyBy(
                Object.values(state.scenarioResults)
                  .filter(
                    resultFromReducerState =>
                      // ensure that we don't keep stale scenario results, e.g. results from a scenario that has just been refreshed by the user
                      refreshedScenariosById[resultFromReducerState.id] &&
                      refreshedScenariosById[resultFromReducerState.id]
                        .status === PlanStatus.READY
                  )
                  .map(resultFromReducerState => ({
                    ...resultFromReducerState,
                    ...(action.payload.scenarios.find(
                      ({ id }) => id === resultFromReducerState.id
                    ) || {})
                  })),
                "id"
              )
            : {},
        isPlanMetadataError: false,
        isPlanMetadataLoadingFirstFetch: false
      };
    }

    case REFRESH_PLAN_METADATA_FAILURE:
      return {
        ...state,
        isPlanMetadataError: true,
        isPlanMetadataLoadingFirstFetch: false
      };

    case GET_SCENARIO_RESULTS_SUCCESS: {
      const currentPlanId = getCurrentPlanIdOfScenarioResults(
        state.scenarioResults
      );
      // Plan results can be fairly large objects. Only hold onto results with
      // the same parent plan— as soon as the user switches to view scenarios
      // from a different plan, we clear the 'cache'.
      return {
        ...state,
        scenarioResults:
          action.meta.planId === currentPlanId
            ? {
                ...state.scenarioResults,
                ...keyBy(action.payload, "id")
              }
            : {
                ...keyBy(action.payload, "id")
              }
      };
    }

    default:
      return state;
  }
};

import { useCallback, useEffect, useState } from "react";
import { InputDateSingleProps } from "../../types";
import { DateErrorMessages } from "../constants";
import { UseDateInputRangeArguments, UseDateInputRange } from "./types";
import { useInputDateSingle } from "./useInputDateSingle";

export const useInputDateRange = ({
  value,
  time,
  error,
  min,
  max,
  setEndToEndOfDay,
  onChange,
  setError
}: UseDateInputRangeArguments): UseDateInputRange => {
  const [startError, setStartError] = useState<InputDateSingleProps["error"]>();
  const [endError, setEndError] = useState<InputDateSingleProps["error"]>();

  useEffect(() => {
    let formattedError: string | undefined =
      startError || endError ? "" : undefined;
    if (startError) {
      formattedError = `Start: ${startError}`;
    }
    if (endError) {
      formattedError = `${formattedError}${
        formattedError?.length ? " " : ""
      }End: ${endError}`;
    }
    if (formattedError !== error) {
      setError?.(formattedError);
    }
  }, [startError, endError]);

  const handleOnChange = useCallback(
    (
      change: Parameters<InputDateSingleProps["onChange"]>[0],
      startEnd: "start" | "end"
    ) => {
      const startDate = startEnd === "start" ? change : value?.[0];
      const endDate = startEnd === "end" ? change : value?.[1];

      if (!startError && !endError && startDate?.getTime && endDate?.getTime) {
        const startTime = startDate.getTime();
        const endTime = endDate.getTime();

        if (startTime >= endTime) {
          setError?.(DateErrorMessages.RANGE);
        } else if (error === DateErrorMessages.RANGE) {
          setError?.(undefined);
        }
      }

      onChange([startDate, endDate]);
    },
    [onChange, setError, startError, endError, value?.[0], value?.[1]]
  );

  const start = useInputDateSingle({
    value: value?.[0] || undefined,
    onChange: change => handleOnChange(change, "start"),
    setError: setStartError,
    error: startError,
    time,
    min,
    max
  });

  const end = useInputDateSingle({
    value: value?.[1] || undefined,
    onChange: change => handleOnChange(change, "end"),
    setError: setEndError,
    error: endError,
    time,
    isEndDate: setEndToEndOfDay,
    min,
    max
  });

  return [start, end];
};

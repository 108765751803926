import { EmbedBuilder as LookerEmbedBuilder } from "@looker/embed-sdk/lib/embed_builder";
import { EmbedClient } from "./client";
/**
 * This is an override of Looker's embedding builder.
 * In short, if you give the Looker embed SDK a method or
 * a request config to regenerate its api and nav tokens, it breaks: it never supplies the old tokens necessary for a proper refresh request.
 * See [this issue](https://github.com/looker-open-source/embed-sdk/issues/147).
 * To fix this, we override the methods with the broken logic:
 *  the embed client contains the broken logic, so we replace it with our own override here
 *
 * tl;dr: Looker's exported implementations of this does not respect its config. Use this.
 */
export class EmbedBuilder extends LookerEmbedBuilder {
    build() {
        return new EmbedClient(this);
    }
}

import axios from "axios";
import { NotImplementedError } from "../../errors";
import { Handler } from "../handlers";
import { CategoriesFetchFailed } from "./errors";
const IDENTIFIER_KEY = "rtb_id";
export default class Categories extends Handler {
    constructor(sdk) {
        super(sdk, "iab-categories", { atomize: true, keypath: IDENTIFIER_KEY });
    }
    /**
     * Returns all of the available categories.
     * @param filters: Optional filters for returning a subset of categories. Note that only the first filter supplied to the "where" field is read.
     * @returns A promise that resolves to an array of categories.
     */
    async findItems(filters) {
        const categoriesEndpoint = `${this.sdk.urls.baseAPIUrl}/iab-categories`;
        let categories = await this.cache.promise(categoriesEndpoint, () => {
            return axios
                .get(categoriesEndpoint)
                .then(({ data }) => data.data.payload)
                .catch(() => {
                new CategoriesFetchFailed();
            });
        });
        const filter = filters.where?.[0];
        if (filter) {
            const cacheId = btoa(JSON.stringify(filters));
            categories = await this.cache.promise(cacheId, () => {
                return new Promise((resolve) => {
                    resolve(categories.filter((category) => {
                        return filters.where?.some((filter) => {
                            return category[filter.field] === filter.value;
                        });
                    }));
                });
            });
        }
        return categories;
    }
    async saveItem() {
        throw new NotImplementedError("save");
    }
    async deleteItem() {
        throw new NotImplementedError("delete");
    }
    /**
     * Overrides the default identifier of "id".
     * @returns The ID used by IAB categories.
     */
    getIdentifierKey() {
        return IDENTIFIER_KEY;
    }
}

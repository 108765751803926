export const serviceSpecificationToSpecificationLegacy = (specification) => {
    return {
        nameOfSpecification: specification.name,
        acceptableAudioEncodingFormats: specification.audio_codecs,
        acceptableAspectRatio: "16x9",
        acceptableExactDurationsInSeconds: specification.durations,
        acceptableDimensions: specification.dimensions
            .map((dimension) => {
            return {
                width: dimension[0],
                height: dimension[1]
            };
        })
            .filter((dimension) => {
            /**
             * It's possible that our array accesses in the previous
             * iteration caused these properties to be undefined.
             * */
            return dimension.width !== undefined && dimension.height !== undefined;
        }),
        acceptableVideoEncodingFormats: specification.video_codecs,
        maximumVideoBitrateInBitsPerSecond: specification.max_video_bitrate,
        minimumVideoBitrateInBitsPerSecond: specification.min_video_bitrate,
        minimumAudioBitrateInBitsPerSecond: specification.min_audio_bitrate,
        acceptableImageDimensions: specification.image.dimensions
            .map((dimension) => {
            return {
                width: dimension[0],
                height: dimension[1]
            };
        })
            .filter((dimension) => {
            /**
             * It's possible that our array accesses in the previous
             * iteration caused these properties to be undefined.
             * */
            return dimension.width !== undefined && dimension.height !== undefined;
        }),
        acceptableTextDimensions: specification.text.dimensions
            .map((dimension) => {
            return {
                width: dimension[0],
                height: dimension[1]
            };
        })
            .filter((dimension) => {
            /**
             * It's possible that our array accesses in the previous
             * iteration caused these properties to be undefined.
             * */
            return dimension.width !== undefined && dimension.height !== undefined;
        })
    };
};

import { constrain } from "../../utils";

/**
 * @param value: the current value.
 * @param precision: how many places past the decimal to allow. Whole number.
 * @return: the value, properly truncated/expanded to fit the precision.
 */
export const clampNumberPrecise = (value: string, precision: number) => {
  const parsed = Number(value);

  if (Number.isNaN(parsed)) {
    return null;
  }
  return parsed.toFixed(Math.abs(precision));
};

/**
 * @param value: the current value.
 * @param incomingMin: the min value.
 * @param incomingMax: the max value.
 * @param precision: how many places past the decimal to allow. Whole number.
 * @returns: the value, properly truncated/expanded to fit the min/max cap.
 */
export const clampNumberInput = (
  value: string,
  min: number | undefined,
  max: number | undefined,
  precision?: number
) => {
  const parsed = typeof value === "number" ? value : parseFloat(value);
  const lower = typeof min === "number" ? min : -Infinity;
  const upper = typeof max === "number" ? max : Infinity;

  if (Number.isNaN(parsed)) {
    return null;
  }
  const constrained = constrain(lower, parsed, upper);
  if (typeof precision === "number") {
    return clampNumberPrecise(constrained.toString(), precision);
  }
  return constrained.toString();
};

/**
 * @param currentValue: current value of the input.
 * @param newValue: the value just put into the input.
 * @param key: the individual key pressed for the input event.
 * @returns: sanitized input value
 */
export const sanitizeOnInput = (
  currentValue: string,
  newValue: string,
  key: string
): string => {
  // if we're equal, or we get an invalid value because we input a +/-, just prevent it
  if (
    newValue === currentValue ||
    (newValue?.length === 0 && /^[+-]$/.test(key))
  ) {
    return currentValue;
  }

  if (newValue?.length === 0) {
    return newValue;
  }

  const parsed = parseFloat(newValue);

  if (Number.isNaN(parsed)) {
    return currentValue;
  }
  return newValue;
};
/**
 * @param currentValue: current value of the input.
 * @param lastValidKey: the last valid key pressed for the input event.
 * @param setLastValidKey: the setter for the last valid key pressed for the input event.
 * @param e: the input event.
 * @returns: sanitized input on key down value
 */
export const sanitizeOnKeyDown = (
  currentValue: string,
  lastValidKey: string,
  setLastValidKey: (key: string) => void,
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  if (/^[+]$/.test(e.key)) {
    e.preventDefault();
    return;
  }

  if (/^[eE]$/.test(e.key)) {
    e.preventDefault();
  }

  if (e.key === "-" && currentValue.length > 0 && /^-.*$/.test(currentValue)) {
    e.preventDefault();
    return;
  }

  if (e.key === "-" && lastValidKey === "-" && currentValue.length === 0) {
    e.preventDefault();
    return;
  }

  if (/[0-9-]|^Backspace$/.test(e.key)) {
    setLastValidKey(e.key);
  }
};

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const DragHandle: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-drag-handle"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill="none"
      stroke={stroke}
    >
      <path
        d="M7.5 3C7.5 2.17 8.17 1.5 9 1.5C9.83 1.5 10.5 2.17 10.5 3C10.5 3.83 9.83 4.5 9 4.5C8.17 4.5 7.5 3.83 7.5 3Z"
        fill={fill}
      />
      <path
        d="M13.5 3C13.5 2.17 14.17 1.5 15 1.5C15.83 1.5 16.5 2.17 16.5 3C16.5 3.83 15.83 4.5 15 4.5C14.17 4.5 13.5 3.83 13.5 3Z"
        fill={fill}
      />
      <path
        d="M7.5 9C7.5 8.17 8.17 7.5 9 7.5C9.83 7.5 10.5 8.17 10.5 9C10.5 9.83 9.83 10.5 9 10.5C8.17 10.5 7.5 9.83 7.5 9Z"
        fill={fill}
      />
      <path
        d="M13.5 9C13.5 8.17 14.17 7.5 15 7.5C15.83 7.5 16.5 8.17 16.5 9C16.5 9.83 15.83 10.5 15 10.5C14.17 10.5 13.5 9.83 13.5 9Z"
        fill={fill}
      />
      <path
        d="M7.5 15C7.5 14.17 8.17 13.5 9 13.5C9.83 13.5 10.5 14.17 10.5 15C10.5 15.83 9.83 16.5 9 16.5C8.17 16.5 7.5 15.83 7.5 15Z"
        fill={fill}
      />
      <path
        d="M13.5 15C13.5 14.17 14.17 13.5 15 13.5C15.83 13.5 16.5 14.17 16.5 15C16.5 15.83 15.83 16.5 15 16.5C14.17 16.5 13.5 15.83 13.5 15Z"
        fill={fill}
      />
      <path
        d="M7.5 21C7.5 20.17 8.17 19.5 9 19.5C9.83 19.5 10.5 20.17 10.5 21C10.5 21.83 9.83 22.5 9 22.5C8.17 22.5 7.5 21.83 7.5 21Z"
        fill={fill}
      />
      <path
        d="M13.5 21C13.5 20.17 14.17 19.5 15 19.5C15.83 19.5 16.5 20.17 16.5 21C16.5 21.83 15.83 22.5 15 22.5C14.17 22.5 13.5 21.83 13.5 21Z"
        fill={fill}
      />
    </svg>
  );
};

export default DragHandle;

import { User } from "@madhive/mad-sdk";

export const ADD_USER = "ADD_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_USERS_PENDING = "GET_USERS_PENDING";
export const GET_USERS_FAILURE = "GET_USERS_FAILURE";
export const GET_USERS_SUCCESS = "GET_USER_SUCCESS";

export interface UserState {
  readonly byId: Record<string, User>;
  readonly allIds: string[];
  readonly isLoading: boolean;
  readonly error: {
    id: string;
    message: string;
  } | null;
}

interface AddUserAction {
  type: typeof ADD_USER;
  payload: User;
}

interface DeleteUserAction {
  type: typeof DELETE_USER;
  payload: string;
}

interface UpdateUserAction {
  type: typeof UPDATE_USER;
  payload: User;
}

interface GetUserPendingAction {
  type: typeof GET_USERS_PENDING;
}

interface GetUserSuccess {
  type: typeof GET_USERS_SUCCESS;
  payload: User[];
}

interface GetUserFailure {
  type: typeof GET_USERS_FAILURE;
  payload: {
    id: string;
    message: string;
  };
}

export type UserActionTypes =
  | AddUserAction
  | DeleteUserAction
  | UpdateUserAction
  | GetUserPendingAction
  | GetUserSuccess
  | GetUserFailure;

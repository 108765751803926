export const clearAccessTokenCookie = () => {
  /**
   * On logout, clear the authentication cookie set from SSO (if applicable)
   * by setting an early expiry date.
   */
  const accessToken = document.cookie
    ? document.cookie.replace(
        // eslint-disable-next-line no-useless-escape
        /(?:(?:^|.*;\s*)access_token\s*\=\s*([^;]*).*$)|^.*$/,
        "$1"
      )
    : "";

  if (accessToken) {
    document.cookie = `access_token=${accessToken}; expires=${new Date(
      0
    ).toUTCString()};`;
  }
};

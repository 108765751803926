import { Handler } from "../../modules/handlers";
import { NotImplementedError } from "../../errors";
import { serviceOrganizationSettingsToOrganizationSettings } from "../../models/organization/settings";
/**
 * Organization Settings collection to handling gathering organization settings
 */
class OrganizationSettingsClass extends Handler {
    constructor(sdk) {
        super(sdk, "organization-settings");
    }
    async findItems(filters) {
        if (!filters?.where) {
            throw new NotImplementedError("Find only supports by id.");
        }
        const idFilter = filters.where?.find((filter) => filter.field === "id");
        if (idFilter?.value) {
            const orgSettings = await this.sdk.madFire.getDocument("organizations", idFilter?.value.toString());
            if (orgSettings.exists() && orgSettings.data()) {
                return Promise.resolve([
                    serviceOrganizationSettingsToOrganizationSettings(orgSettings.data())
                ]);
            }
        }
        return Promise.resolve([]);
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async saveItem(org) {
        throw new NotImplementedError("save");
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}
export default OrganizationSettingsClass;

export class PixelsFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PixelsFetchFailed);
        }
        this.name = "PixelsFetchFailed";
        this.message = "Failed to retrieve pixels. Please try again at a later time.";
    }
}
export class PixelSaveFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PixelSaveFailed);
        }
        this.name = "PixelSaveFailed";
        this.message = "Failed to save pixel. Please try again at a later time.";
    }
}
export class PixelIdCreationFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PixelIdCreationFailed);
        }
        this.name = "PixelIdCreationFailed";
        this.message = "Failed to create unique ID for pixel. Please try again at a later time.";
    }
}
export class PixelHistoryFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, PixelHistoryFetchFailed);
        }
        this.name = "PixelHistoryFetchFailed";
        this.message = "Failed to retrieve pixel history. Please try again at a later time.";
    }
}

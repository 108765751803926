import { AttributionDashboardOptionsFailedFetchError, NotImplementedError, UnauthenticatedError } from "../../../errors";
import axios from "axios";
import { Handler } from "../../handlers";
import { ApiVersion } from "../types";
import { buildUrl } from "../utils";
export class AttributionTableDateRange extends Handler {
    constructor(sdk) {
        super(sdk, "attribution-dashboard-table-date-range");
    }
    async findItems() {
        const email = this.sdk.getCurrentUser()?.email;
        if (!email) {
            throw new UnauthenticatedError();
        }
        const url = buildUrl(this.sdk, ApiVersion.V2, `attribution-dashboard/table-date-range?email=${email}`);
        return this.cache.promise(url, () => axios
            .get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
            return [response.data];
        })
            .catch((error) => {
            throw new AttributionDashboardOptionsFailedFetchError(error);
        }));
    }
    async saveItem() {
        throw new NotImplementedError("saveItem");
    }
    async deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
}

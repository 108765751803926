import { KnownOrganizationNames } from "../../types";
import { isValidUrlWithProtocol } from "../../utils";
/**
 *
 * @param url A standardized url (use standardizeMacroUrl if you need to clean the url)
 * @param isVast If it is a vast url or not
 */
export const isValidStandardTrackerUrl = (url, isVast) => {
    if (!isValidUrlWithProtocol(url)) {
        return false;
    }
    // In theory these regex could be built using the reduce
    // above I felt keeping them seperated might be better
    // to catch bugs where a new macro is added but the
    // standard is the same
    const replacedUrlWithMadMacrosOmitted = url.replace(isVast ? MAD_MACROS_REGEX_WITHOUT_EVT : MAD_MACROS_REGEX, "");
    /* eslint-disable-next-line no-useless-escape */
    const unsafeUrlCharRegex = /[\\[\]\{\}"*^#<>|]/g;
    // once we remove what macros we support we just verify that
    // there are now other macros in the url by assuming there
    // shoiuldn't be {},[],*,^,#<> in the url.
    if (unsafeUrlCharRegex.test(replacedUrlWithMadMacrosOmitted)) {
        return false;
    }
    return true;
};
// Map of potential incorrect macros seen from clients, will need to add more if need be until we get Tensorflow introduced
/* eslint-disable no-useless-escape */
const POTENTIAL_INCORRECT_MACROS = {
    protoMacroRegex: /^https|https|http|{proto}/g,
    protoMacroWithoutSlash: /#{proto}/g,
    timestampMacroRegex: /\[timestamp\]|\[TIMESTAMP\]|{timestamp}/g,
    ipMacroRegex: /\#{request.clientAddress\}|\#{ip\}|{IP}|{ip}/g,
    ifaMacroRegex: /\#{ifa\}|\[{ifa\}]|\{\DEVICE_ID\}|\[device_id\]|\[Device_ID\]|\{request.deviceId\}|\[INSERT_DEVICE_ID_HERE\]|{ifa}/g,
    randomMacroRegex: /\#{random\}|\{CACHEBUSTER\}|\{ad.ref.random\}|\[INSERT_CACHEBREAKER_HERE%20\[vs\.extremereach.io\]]|\[Device_ID]]|\[INSERT_CACHEBREAKER_HERE \[vs.extremereach.io]]|\[INSERT_CACHEBREAKER_HERE\]|\[INSERT_CACHEBUSTER_HERE\]|\[Cachebuster\]|\[CACHEBUSTER\]|{random}/g,
    unsupportedMacroRegex: /\{APP_BUNDLE\}|{DEVICE_MAKE}|{APP_NAME}|{APP_STORE_URL}|{DEVICE_MODEL}|#{TRR_30_TVC_REVISED_SUSTAINABILITY}|#{request.devicetype}|#{request.referrerDomain}|#e{request.pageUrl}|#{slot.position}|#e{series.title\("none"\)\}|\#e{series.title\(none\)}|#e{series.title\(""none""\)}|\#{ad.creative.id\}|#{TRR_30_TVC_FALL_HISPANIC}|\$\$http:\/\/premionmedia.com\/\$\$|\$\$keyword\$\$|#e{series.title\(%22none%22\)}|#{site.title}|\${US_PRIVACY\}|\[APIFRAMEWORKS\]|\#{TRR_30_TVC_FALL_HERO\}|\[OMIDPARTNER\]|\[APIFRAMEWORKS\]|#{ad.placement.id}|#{ad.creative.safeWidth}|#{ad.creative.safeHeight}|\[SERVERSIDE\]/g,
    advertiserNameMacroRegex: /#{ad.advertiser.name}|#{adv.name}|#{TheRealReal}|TheRealReal}|{adv.name}/g,
    creativeNameMacroRegex: /#{ad.creative.name}|#{creative.name}|{creative.name}/g,
    creativeLengthMacroRegex: /#{creative.len}|#{request.mediaDuration}|#{ad.len}|#{:30}|{creative.len}/g,
    lineitemIdMacroRegex: /#{OTT}|{lineitem.id}/g,
    lineitemNameMacroRegex: /{lineitem.name}/g,
    fwioMacroRegex: /#{ad.campaign.name}|#{camp.name}|#{ad.campaign.id}/g,
    campaignIdMacroRegex: /{camp.id}/g,
    campaignNameMacroRegex: /{camp.name}/g,
    advertiserIdMacroRegex: /{adv.id}/g,
    publisherIdMacroRegex: /#{site.id}|{site.id}|{pub.id}/g,
    siteUrlMacroRegex: /{site.url}/g,
    transactionIdMacroRegex: /{tx.id}/g,
    eventMacroRegex: /{evt}/g,
    eventTypeMacroRegex: /{event.type}/g,
    creativeIdMacroRegex: /{creative.id}/g,
    publisherNameMacroRegex: /{pub.name}/g,
    deviceUaMacroRegex: /\{USER_AGENT\}|{device.ua}/g,
    gdprMacroRegex: /\${GDPR\}|{regs.gdpr}/g,
    userConsentMacroRegex: /{user.consent}/g
};
const STANDARDIZE_VAST_URL = [
    {
        regex: POTENTIAL_INCORRECT_MACROS.campaignIdMacroRegex,
        standard: "{camp.id}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.userConsentMacroRegex,
        standard: "{user.consent}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.gdprMacroRegex,
        standard: "{regs.gdpr}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.deviceUaMacroRegex,
        standard: "{device.ua}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.publisherNameMacroRegex,
        standard: "{pub.name}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.creativeIdMacroRegex,
        standard: "{creative.id}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.eventTypeMacroRegex,
        standard: "{event.type}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.advertiserIdMacroRegex,
        standard: "{adv.id}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.publisherIdMacroRegex,
        standard: "{pub.id}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.transactionIdMacroRegex,
        standard: "{tx.id}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.campaignNameMacroRegex,
        standard: "{camp.name}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.timestampMacroRegex,
        standard: "{timestamp}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.ipMacroRegex,
        standard: "{ip}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.ifaMacroRegex,
        standard: "{ifa}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.randomMacroRegex,
        standard: "{random}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.unsupportedMacroRegex,
        standard: "",
        isSupported: false
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.advertiserNameMacroRegex,
        standard: "{adv.name}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.creativeNameMacroRegex,
        standard: "{creative.name}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.creativeLengthMacroRegex,
        standard: "{creative.len}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.lineitemIdMacroRegex,
        standard: "{lineitem.id}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.lineitemNameMacroRegex,
        standard: "{lineitem.name}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.fwioMacroRegex,
        standard: "{camp.id}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.fwioMacroRegex,
        standard: "{geo.dma}",
        isSupported: true
    },
    {
        regex: POTENTIAL_INCORRECT_MACROS.siteUrlMacroRegex,
        standard: "{site.url}",
        isSupported: true
    }
];
const STANDARDIZE_NON_VAST_URL = [
    {
        regex: POTENTIAL_INCORRECT_MACROS.eventMacroRegex,
        standard: "{evt}",
        isSupported: true
    }
];
// Need to wrap each macro in grouping () parens in order to exactly match group + to have separators show up when calling .split on parsed URL (see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/split#Examples)
export const MAD_MACROS_REGEX = /({proto})|({timestamp})|({ip})|({ifa})|({random})|({camp.id})|({lineitem.id})|({lineitem.name})|({creative.id})|({event.type})|({tx.id})|({evt})|({creative.name})|({camp.name})|({adv.name})|({adv.id})|({creative.len})|({pub.id})|({pub.name})|({device.ua})|({regs.gdpr})|({user.consent})|({geo.dma})|({site.url})/g;
// {evt} is only applicable for event trackers, so we need to distinguish between VAST vs. event trackers now
export const MAD_MACROS_REGEX_WITHOUT_EVT = /({proto})|({timestamp})|({ip})|({ifa})|({random})|({camp.id})|({lineitem.id})|({lineitem.name})|({creative.id})|({event.type})|({tx.id})|({creative.name})|({camp.name})|({adv.name})|({adv.id})|({creative.len})|({pub.id})|({pub.name})|({device.ua})|({regs.gdpr})|({user.consent})|({geo.dma})|({site.url})/g;
// Get original macros and indices where they existed in string, used to undo/replace macros
const getOriginalMacrosAndIndices = (regex, url, isFromUploadView) => {
    const macros = [];
    let match = regex.exec(url);
    while (match !== null) {
        const matchedString = match[0].match(MAD_MACROS_REGEX);
        macros.push({
            macro: match[0],
            index: match.index,
            isCheckedByUser: isFromUploadView
                ? true
                : // Yet again tricked by regex.test, need to use .match in order to ensure all matches boolean properties are returned
                    !!matchedString && !!matchedString.length
        });
        match = regex.exec(url);
    }
    return macros;
};
// Get original macros and indices where they existed in string, used to undo/replace macros
const getOriginalMacrosAndIndicesByMacroReplacements = (regex, url, isFromUploadView, combinedRegex) => {
    const macros = [];
    let match = regex.exec(url);
    while (match !== null) {
        let matchedString = null;
        if (combinedRegex) {
            matchedString = match[0].match(combinedRegex);
        }
        macros.push({
            macro: match[0],
            index: match.index,
            isCheckedByUser: isFromUploadView
                ? true
                : // Yet again tricked by regex.test, need to use .match in order to ensure all matches boolean properties are returned
                    !!matchedString && !!matchedString.length
        });
        match = regex.exec(url);
    }
    return macros;
};
/**
 *
 * @param url A standardized url (use standardizeMacroUrl if you need to clean the url)
 * @param isVast If it is a vast url or not
 */
const isValidStandardTrackerUrlByMacroReplacements = (url, macroRegex) => {
    if (!isValidUrlWithProtocol(url)) {
        return false;
    }
    if (!macroRegex) {
        return true;
    }
    // In theory these regex could be built using the reduce
    // above I felt keeping them separated might be better
    // to catch bugs where a new macro is added but the
    // standard is the same
    const replacedUrlWithMadMacrosOmitted = url.replace(macroRegex, "");
    /* eslint-disable-next-line no-useless-escape */
    const unsafeUrlCharRegex = /[\\[\]\{\}"*^#<>|]/g;
    // once we remove what macros we support we just verify that
    // there are now other macros in the url by assuming there
    // shouldn't be {},[],*,^,#<> in the url.
    if (unsafeUrlCharRegex.test(replacedUrlWithMadMacrosOmitted)) {
        return false;
    }
    return true;
};
/**
 * This method takes a url and returns a standardizedMacroUrl based on
 * it. eg. [TIMESTAMP] to {timestamp}
 * @param url URL to be standardized
 * @param macroReplacements
 */
const standardizeMacroUrlByMacroReplacements = (url, macroReplacements) => {
    if (!macroReplacements) {
        return url;
    }
    // We have a list of standard macros and what different
    // forms can come in. This replaces those macros to fit
    // one standard.
    return macroReplacements.reduce((cleaningUrl, macro) => {
        return cleaningUrl.replace(macro.regex, macro.standard);
    }, url);
};
/**
 * This method is used to create a list of parsed standards from a URL
 * eg. [TIMESTAMP] would be returned under {timestamp}
 * @param url URL to be parsed into replacements
 * @param isVast Is it a vast url which have different standards
 * @param isFromUploadViewOrUserClickedValidateAndParse  Was this and upload or validate request (need to review the needs for this)
 */
const parseUrlMacros = (url, isVast, isFromUploadViewOrUserClickedValidateAndParse) => {
    let standardizedMacros = STANDARDIZE_VAST_URL;
    if (!isVast) {
        standardizedMacros = standardizedMacros.concat(STANDARDIZE_NON_VAST_URL);
    }
    return standardizedMacros.reduce((macroMetaData, macro) => {
        return macroMetaData.concat([
            {
                macro: macro.standard,
                isSupported: macro.isSupported,
                original: getOriginalMacrosAndIndices(macro.regex, url, isFromUploadViewOrUserClickedValidateAndParse),
                isReplaced: true
            }
        ]);
    }, []);
};
/**
 * This method is used to create a list of parsed standards from a URL
 * eg. [TIMESTAMP] would be returned under {timestamp}
 * @param url URL to be parsed into replacements
 * @param isVast Is it a vast url which have different standards
 * @param isFromUploadViewOrUserClickedValidateAndParse  Was this and upload or validate request (need to review the needs for this)
 */
const parseUrlMacrosByMacroReplacements = (url, isFromUploadViewOrUserClickedValidateAndParse, macroReplacements) => {
    if (!macroReplacements) {
        return [];
    }
    const combinedRegex = transformMacroReplacementsToRegex(macroReplacements);
    return macroReplacements.reduce((macroMetaData, macro) => {
        return macroMetaData.concat([
            {
                macro: macro.standard,
                isSupported: macro.isSupported,
                original: getOriginalMacrosAndIndicesByMacroReplacements(macro.regex, url, isFromUploadViewOrUserClickedValidateAndParse, combinedRegex),
                isReplaced: true
            }
        ]);
    }, []);
};
/**
 * determine if string is vast
 * @param potentialVastString: string
 * @returns:boolean
 */
export const isVast = (potentialVastString) => STANDARDIZE_VAST_URL.some((macro) => macro.regex.test(potentialVastString));
export const validateAndParseFromServer = (url, isFromUploadView, isUrl, macroReplacements) => {
    // Check to see that it is a valid url + has http or https protocol
    if (isUrl && !isValidUrlWithProtocol(url) && isFromUploadView) {
        return {
            isValid: false,
            parsedUrl: "",
            macroMetadataWithOriginalValue: [],
            url
        };
    }
    const macroRegex = transformMacroReplacementsToRegex(macroReplacements);
    // Standardize the URL and validate it
    const replacedUrl = standardizeMacroUrlByMacroReplacements(url, macroReplacements);
    if (isUrl && !isValidStandardTrackerUrlByMacroReplacements(replacedUrl, macroRegex)) {
        return {
            isValid: false,
            parsedUrl: "",
            macroMetadataWithOriginalValue: [],
            url
        };
    }
    // Parse the url
    const macroMetadataWithOriginalValue = parseUrlMacrosByMacroReplacements(url, isFromUploadView, macroReplacements);
    const parsedAndValidatedResponse = {
        isValid: true,
        parsedUrl: replacedUrl,
        url,
        macroMetadataWithOriginalValue
    };
    return parsedAndValidatedResponse;
};
/**
 * This method takes a url and returns a standardizedMacroUrl based on
 * it. eg. [TIMESTAMP] to {timestamp}
 * @param url URL to be standardized
 * @param isVast If it is a vast url or not bc of extra standards
 * @returns standardized url
 */
export const standardizeMacroUrl = (url, isVast) => {
    let standardizedMacros = STANDARDIZE_VAST_URL;
    if (!isVast) {
        standardizedMacros = standardizedMacros.concat(STANDARDIZE_NON_VAST_URL);
    }
    // We have a list of standard macros and what different
    // forms can come in. This replaces those macros to fit
    // one standard.
    return standardizedMacros.reduce((cleaningUrl, macro) => {
        return cleaningUrl.replace(macro.regex, macro.standard);
    }, url);
};
export const transformMacroReplacementsToRegex = (macroReplacements) => {
    if (!macroReplacements?.length) {
        return undefined;
    }
    let regexString = "";
    macroReplacements.forEach((macro) => {
        if (macro.isSupported) {
            if (!regexString?.trim().length) {
                regexString = `(${macro.standard})`;
            }
            else {
                regexString = `${regexString}|(${macro.standard})`;
            }
        }
    });
    if (!regexString?.trim().length) {
        return undefined;
    }
    return new RegExp(regexString, "g");
};
export const getVASTGuideForOrg = (org) => {
    switch (org) {
        case KnownOrganizationNames.SCRIPPS:
            return "https://scripps-madhivesupport.zendesk.com/hc/en-us/articles/360042646653-Third-Party-VAST-Tag-Validation";
        case KnownOrganizationNames.PREMION:
            return "https://premion-madhivesupport.zendesk.com/hc/en-us/articles/360042646693-Third-Party-VAST-Tag-Validation";
        case KnownOrganizationNames.FOX:
            return "https://flx-madhivesupport.zendesk.com/hc/en-us/articles/360046346313-Third-Party-VAST-Tag-Validation";
        default:
            return "";
    }
};
export const validateAndParseVAST = (url, isFromUploadViewOrUserClickedValidateAndParse, isVast) => {
    // Check to see that it is a valid url + has http or https protocol
    if (!isValidUrlWithProtocol(url) && isFromUploadViewOrUserClickedValidateAndParse) {
        return {
            isValid: false,
            parsedUrl: "",
            macroMetadataWithOriginalValue: [],
            url
        };
    }
    // Standize the URL and validate it
    const replacedUrl = standardizeMacroUrl(url, isVast);
    if (!isValidStandardTrackerUrl(replacedUrl, isVast)) {
        return {
            isValid: false,
            parsedUrl: "",
            macroMetadataWithOriginalValue: [],
            url
        };
    }
    // Parse the url
    const macroMetadataWithOriginalValue = parseUrlMacros(url, isVast, isFromUploadViewOrUserClickedValidateAndParse);
    const parsedAndValidatedResponse = {
        isValid: true,
        parsedUrl: replacedUrl,
        url,
        macroMetadataWithOriginalValue
    };
    return parsedAndValidatedResponse;
};

import { DateTime } from "luxon";

export const DateErrorMessages = {
  INVALID: "Invalid date.",
  REQUIRED: "Date is required.",
  RANGE: "Start date must come before end date."
} as const;

export const DatePlaceholders = {
  SIMPLE: "MM/DD/YY",
  WITH_TIME: "MM/DD/YY HH:MM:SS"
} as const;

export enum DateRangePresets {
  LAST_1_DAY = "last-1-day",
  LAST_3_DAYS = "last-3-days",
  LAST_7_DAYS = "last-7-days",
  LAST_30_DAYS = "last-30-days",
  LAST_90_DAYS = "last-90-days",
  ONE_MONTH_AGO = "one-month-ago",
  TWO_MONTHS_AGO = "two-months-ago"
}

export const DateRangePresetOptions = {
  [DateRangePresets.LAST_1_DAY]: {
    action: "last-1-day",
    label: "Last 1 Day",
    section: "range-by-days"
  },
  [DateRangePresets.LAST_3_DAYS]: {
    action: "last-3-days",
    label: "Last 3 Days",
    section: "range-by-days"
  },
  [DateRangePresets.LAST_7_DAYS]: {
    action: "last-7-days",
    label: "Last 7 Days",
    section: "range-by-days"
  },
  [DateRangePresets.LAST_30_DAYS]: {
    action: "last-30-days",
    label: "Last 30 Days",
    section: "range-by-days"
  },
  [DateRangePresets.LAST_90_DAYS]: {
    action: "last-90-days",
    label: "Last 90 Days",
    section: "range-by-days"
  },
  // Dynamic month labels, will go back X amount of months back from current month
  [DateRangePresets.ONE_MONTH_AGO]: {
    action: "one-month-ago",
    label: DateTime.local().minus({ months: 1 }).toFormat("LLLL"),
    section: "range-by-month"
  },
  [DateRangePresets.TWO_MONTHS_AGO]: {
    action: "two-months-ago",
    label: DateTime.local().minus({ months: 2 }).toFormat("LLLL"),
    section: "range-by-month"
  }
} as const;

export const DATE_PRESET_SECTIONS = [
  {
    id: "range-by-days",
    title: "Range By Days"
  },
  {
    id: "range-by-month",
    title: "Range By Month"
  }
];

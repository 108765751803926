import { NotImplementedError, SummaryCampaignFetchFailed, SummaryCreativeFetchFailed, SummaryDashboardFetchFailed, SummaryFetchFailed, UniqueImpressionFetchFailed } from "../../errors";
import { buildCompletionRates, buildDayParts, buildDevices, buildQuartiles, buildTrafficSources, getTotalImpressions, sumAllDayParts, SUMMARY_FIELDS, summaryFiltersToServiceSummaryFilters } from "../../models/summaries";
import axios from "axios";
import { Observable } from "rxjs";
import { serviceCampaignSummariesToCampaignSummaries } from "../../models/summaries/campaign";
import { GEO_ENTITY_TO_HERMES_DELIVERY_ENDPOINT } from "../../types";
import { calculateTotalByField, divide } from "../../utils";
import { ObservableHandler } from "../handlers";
class Summaries extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "summaries");
        this.handleGeoSummary = (subscriber, filters) => {
            const summaryFilters = this.filterToServiceSummaryFilters(filters);
            const geoFilter = filters.where?.find((filter) => filter.field === "geographicEntityTypes");
            if (!geoFilter) {
                return Promise.resolve();
            }
            return new Promise((resolve, reject) => {
                const geoRequests = [];
                const geoTypeRequests = [];
                geoFilter.value.forEach((geoType) => {
                    geoTypeRequests.push(geoType);
                    const endpointURL = `${this.sdk.urls.baseHermesUrl}/${GEO_ENTITY_TO_HERMES_DELIVERY_ENDPOINT[geoType]}`;
                    geoRequests.push(axios.post(endpointURL, { ...summaryFilters, limit: 250 }, {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }));
                });
                Promise.all(geoRequests)
                    .then((geoData) => {
                    const geoSummaries = {
                        country: [],
                        district: [],
                        dma: [],
                        state: [],
                        zipCode: []
                    };
                    geoData.forEach((summary, index) => {
                        let totalImpressions = 0;
                        if (summary.data && Array.isArray(summary.data)) {
                            totalImpressions = calculateTotalByField(summary.data, "im_total");
                            // we want to have all the different geotype requests and map
                            // them to { type: {GeoSummary} }
                            geoSummaries[geoTypeRequests[index]] = summary.data.map((geoSummary) => {
                                return {
                                    impressions: geoSummary.im_total,
                                    uniques: geoSummary.unique_ips,
                                    regionCode: geoSummary.state_code ||
                                        geoSummary.district_code ||
                                        geoSummary.dma_code ||
                                        geoSummary.postal_code ||
                                        "No Data",
                                    distributionPct: divide(geoSummary.im_total, totalImpressions) * 100
                                };
                            });
                        }
                    });
                    subscriber.next({
                        geoSummaries
                    });
                    resolve();
                })
                    .catch((error) => {
                    subscriber.error({
                        geoSummary: error.message || error
                    });
                    reject(error);
                });
            });
        };
        this.handleCampaignSummary = async (subscriber, filters) => {
            return new Promise((resolve, reject) => {
                const modifiedFilters = filters.adbook_package_id
                    ? {
                        ...filters,
                        // Bandaid to set campaign id to fetch data until data fetching by adbook package id is ready
                        adbook_package_id: "",
                        campaignIds: [filters.adbook_package_id.split("--")[1]]
                    }
                    : filters;
                axios
                    .post(this.sdk.urls.pacingUrl, modifiedFilters, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .then((res) => {
                    try {
                        subscriber.next({
                            campaignSummaries: serviceCampaignSummariesToCampaignSummaries(res.data)
                        });
                        resolve();
                    }
                    catch (error) {
                        const e = error.response?.status === 401 ? new SummaryCampaignFetchFailed() : error;
                        subscriber.error({
                            campaignSummaries: e.message
                        });
                        subscriber.next({
                            campaignSummaries: []
                        });
                        reject();
                    }
                })
                    .catch((error) => {
                    const e = error.response?.status === 401 ? new SummaryCampaignFetchFailed() : error;
                    subscriber.error({
                        campaignSummaries: e.message
                    });
                    subscriber.next({
                        campaignSummaries: []
                    });
                    reject();
                });
            });
        };
        this.handleImpressionsByDay = (subscriber, filters) => {
            return new Promise((resolve, reject) => {
                return axios
                    .post(`${this.sdk.urls.baseHermesUrl}/impression_summary`, filters, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .then((res) => {
                    try {
                        let impressionsByDay = [];
                        if (res.data && res.data.length > 0) {
                            impressionsByDay = res.data.map((byDay) => {
                                return {
                                    dateEst: byDay.datenyc,
                                    delivered: byDay.delivered,
                                    estimated: byDay.estimated
                                };
                            });
                        }
                        subscriber.next({
                            impressionsByDay
                        });
                        resolve();
                    }
                    catch (e) {
                        const error = new SummaryDashboardFetchFailed();
                        subscriber.error({
                            impressionsByDay: error.message
                        });
                        subscriber.next({
                            impressionsByDay: []
                        });
                        reject(error);
                    }
                })
                    .catch((error) => {
                    const e = error.response?.status === 401 ? new SummaryDashboardFetchFailed() : error;
                    subscriber.error({
                        impressionsByDay: e.message
                    });
                    subscriber.next({
                        impressionsByDay: []
                    });
                    reject(e);
                });
            });
        };
        this.handleUniqueImpressions = (subscriber, filters) => {
            return new Promise((resolve, reject) => {
                const endpointURL = `${this.sdk.urls.baseHermesUrl}/unique_impressions`;
                axios
                    .post(endpointURL, filters, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .then((response) => {
                    let uniques = 0;
                    if (response.data && response.data.length > 0) {
                        uniques = response.data[0]?.uniques || 0;
                    }
                    subscriber.next({
                        uniques
                    });
                    resolve(uniques);
                })
                    .catch((error) => {
                    const e = error.response?.status === 401 ? new UniqueImpressionFetchFailed() : error;
                    subscriber.error({
                        uniqueImpressions: e.message
                    });
                    reject(e);
                });
            });
        };
        this.handleSummary = (subscriber, filters) => {
            const summaryRequest = axios
                .post(`${this.sdk.urls.baseHermesUrl}/summary`, filters, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((summaryResponse) => summaryResponse.data?.length > 0 ? summaryResponse.data[0] : null);
            const devicesRequest = axios
                .post(`${this.sdk.urls.baseHermesUrl}/device_summary
        `, filters, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((devicesResponse) => {
                return devicesResponse.data || [];
            });
            const completionRateRequest = axios
                .post(`${this.sdk.urls.baseHermesUrl}/completion_rate`, filters, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((completionRateResponse) => {
                return completionRateResponse.data || [];
            });
            const dayPartRequest = axios
                .post(`${this.sdk.urls.baseHermesUrl}/day_part_summary
        `, filters, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((dayPartResponse) => {
                return dayPartResponse.data || [];
            });
            const mediaTypeQuartilesRequest = axios
                .post(`${this.sdk.urls.baseHermesUrl}/quartiles`, filters, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((mediaTypeQuartilesResponse) => {
                return mediaTypeQuartilesResponse.data || [];
            });
            return Promise.all([
                summaryRequest,
                completionRateRequest,
                mediaTypeQuartilesRequest,
                dayPartRequest,
                devicesRequest
            ])
                .then(([summary, completionRates, mediaTypeQuartiles, dayParts, devices]) => {
                try {
                    const quartiles = buildQuartiles(mediaTypeQuartiles);
                    let results = {
                        trafficSources: [],
                        dayParts: buildDayParts(null, 0),
                        devices: buildDevices(null, 0),
                        quartiles: quartiles,
                        videoCompletionRate: 0,
                        listenThroughRate: 0,
                        clickThroughRate: 0
                    };
                    const impressions = getTotalImpressions(filters, quartiles);
                    if (summary) {
                        const trafficSources = buildTrafficSources(summary.pubs, impressions);
                        results = {
                            ...results,
                            trafficSources,
                            quartiles,
                            impressions
                        };
                    }
                    if (devices && devices.length) {
                        const builtDevices = buildDevices(devices, impressions);
                        results = {
                            ...results,
                            devices: builtDevices
                        };
                    }
                    if (dayParts && dayParts.length > 0) {
                        const allDayParts = sumAllDayParts(dayParts);
                        const builtDayParts = buildDayParts(allDayParts, impressions);
                        results = {
                            ...results,
                            dayParts: builtDayParts
                        };
                    }
                    if (completionRates && completionRates.length) {
                        results = {
                            ...results,
                            ...buildCompletionRates(completionRates)
                        };
                    }
                    subscriber.next(results);
                    return impressions;
                }
                catch (error) {
                    return Promise.reject(error);
                }
            })
                .catch((error) => {
                const e = error.response?.status === 401 ? new SummaryFetchFailed() : error;
                const summariesError = {
                    summariesFailed: e.message
                };
                subscriber.error(summariesError);
                return Promise.reject(summariesError);
            });
        };
        this.handleCreativeSummary = (subscriber, filters) => {
            return new Promise((resolve, reject) => {
                axios
                    .post(`${this.sdk.urls.baseHermesUrl}/creative_impression_summary`, filters, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .then((res) => {
                    subscriber.next({
                        creativeSummaries: res.data
                    });
                    resolve();
                })
                    .catch((error) => {
                    const e = error.response?.status === 401 ? new SummaryCreativeFetchFailed() : error;
                    subscriber.error({
                        creativeSummaries: e.message
                    });
                    subscriber.next({
                        creativeSummaries: []
                    });
                    reject();
                });
            });
        };
        this.filterToServiceSummaryFilters = (filters) => {
            if (!filters.where) {
                throw new Error("You must supply filters");
            }
            const summaryFilter = filters.where.reduce((serviceFilters, filter) => {
                return {
                    ...serviceFilters,
                    [filter.field]: filter.value
                };
            }, {});
            return summaryFiltersToServiceSummaryFilters(summaryFilter);
        };
    }
    findItems(filters) {
        return new Observable((subscriber) => {
            /**
             * Since Summary data comes from multiple different end points
             * we are using an Observable. When all end points have finally
             * resolved (allSummaries) we want to send a complete event to the
             * subscriber knows it is completed. We also don't want to block
             * all Summary requests by each other and since `frequency` needs
             * both Unique and Summary data to be calculated we have a handler
             * (handlesSummaryUniques) that fires when that data is ready so
             * `frequency` can be calculated without blocking each others other
             * results.
             */
            const allSummaries = [];
            const isSpecificFields = !!filters.fields;
            const isSummaryNeeded = !isSpecificFields ||
                filters.fields?.includes(SUMMARY_FIELDS.DAY_PARTS) ||
                filters.fields?.includes(SUMMARY_FIELDS.DEVICES) ||
                filters.fields?.includes(SUMMARY_FIELDS.QUARTILES) ||
                filters.fields?.includes(SUMMARY_FIELDS.FREQUENCY) ||
                filters.fields?.includes(SUMMARY_FIELDS.VIDEO_COMPLETION_RATE);
            const isUniquesNeeded = !isSpecificFields ||
                filters.fields?.includes(SUMMARY_FIELDS.UNIQUES) ||
                filters.fields?.includes(SUMMARY_FIELDS.FREQUENCY);
            const isImpressionsByDayNeeded = !isSpecificFields || filters.fields?.includes(SUMMARY_FIELDS.IMPRESSIONS_BY_DAY);
            const isGeoSummaryNeeded = !isSpecificFields || filters.fields?.includes(SUMMARY_FIELDS.GEO_SUMMARIES);
            const isCampaignSummaryNeeded = !isSpecificFields || filters.fields?.includes(SUMMARY_FIELDS.CAMPAIGN_SUMMARIES);
            const summaryFilters = this.filterToServiceSummaryFilters(filters);
            const isCreativeSummaryNeeded = !isSpecificFields || filters.fields?.includes(SUMMARY_FIELDS.CREATIVE_SUMMARIES);
            let gettingSummaryData;
            if (isSummaryNeeded) {
                gettingSummaryData = this.handleSummary(subscriber, summaryFilters);
                allSummaries.push(gettingSummaryData);
            }
            else {
                gettingSummaryData = Promise.resolve(0);
            }
            let gettingUniquesData;
            if (isUniquesNeeded) {
                gettingUniquesData = this.handleUniqueImpressions(subscriber, summaryFilters);
                allSummaries.push(gettingUniquesData);
            }
            else {
                gettingUniquesData = Promise.resolve(0);
            }
            if (isImpressionsByDayNeeded) {
                allSummaries.push(this.handleImpressionsByDay(subscriber, summaryFilters));
            }
            if (isGeoSummaryNeeded) {
                allSummaries.push(this.handleGeoSummary(subscriber, filters));
            }
            if (isCampaignSummaryNeeded) {
                allSummaries.push(this.handleCampaignSummary(subscriber, summaryFilters));
            }
            if (isSummaryNeeded && isUniquesNeeded) {
                Promise.all([gettingSummaryData, gettingUniquesData]).then(([totalImpressions, totalUnqiues]) => {
                    subscriber.next({
                        frequency: divide(totalImpressions, totalUnqiues)
                    });
                });
            }
            if (isCreativeSummaryNeeded) {
                allSummaries.push(this.handleCreativeSummary(subscriber, summaryFilters));
            }
            Promise.all(allSummaries).finally(() => {
                subscriber.complete();
            });
        });
    }
    /**
     * Make isn't implemented for users.
     */
    make() {
        throw new NotImplementedError("make");
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    saveItem(data) {
        throw new NotImplementedError("save");
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}
export default Summaries;

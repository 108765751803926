import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Paused: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-paused"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 8.25C14.4142 8.25 14.75 8.58579 14.75 9V15C14.75 15.4142 14.4142 15.75 14 15.75C13.5858 15.75 13.25 15.4142 13.25 15V9C13.25 8.58579 13.5858 8.25 14 8.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99988 8.25C10.4141 8.25 10.7499 8.58579 10.7499 9V15C10.7499 15.4142 10.4141 15.75 9.99988 15.75C9.58566 15.75 9.24988 15.4142 9.24988 15V9C9.24988 8.58579 9.58566 8.25 9.99988 8.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3.75C7.44321 3.75 3.75 7.44321 3.75 12C3.75 16.5568 7.44321 20.25 12 20.25C16.5568 20.25 20.25 16.5568 20.25 12C20.25 7.44321 16.5568 3.75 12 3.75ZM12 21.75C6.61479 21.75 2.25 17.3852 2.25 12C2.25 6.61479 6.61479 2.25 12 2.25C17.3852 2.25 21.75 6.61479 21.75 12C21.75 17.3852 17.3852 21.75 12 21.75Z"
      />
    </svg>
  );
};

export default Paused;

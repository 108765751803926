import { BehaviorSubject, of, tap, map } from "rxjs";
import { FilterTypes } from "./filter";
import Stream from "./stream";
import { isPage } from "./page";
import { Base } from "./base";
import { FindObservableBuilder } from "./builder";
import { isCacheableFind } from "./isCacheableFind";
/**
 * Abstract class that all handlers should extend to allow the
 * same interface for most common activities.
 *
 * currently supports: Find, Save, Delete
 *
 * Example:
 *  findItems(
 *       filters: Filter<any>
 *  ): Observable<Array<any>> {
 *      return new Observable(subscriber => {
 *          try {
 *            subscriber.next([42]);
 *            setTimeout(() => {
 *              subscriber.next([<value>]); // happens asynchronously
 *            }, 1000);
 *            setTimeout(() => {
 *             subscriber.next([<value>]); // happens asynchronously
 *            }, 2000);
 *            setTimeout(() => {
 *              subscriber.complete(); // happens asynchronously
 *            }, 4000);
 *          } catch (error) {
 *            subscriber.error(<error>);
 *          }
 *       });
 *  }
 */
export class ObservableHandler extends Base {
    /**
     * Handlers need access to MadSDK
     * @param sdk Instance of MadSDK to use for lookups
     * @param id is handler's ID — also used for cache namespace
     * @param {CacheConfig} cacheConfig
     */
    constructor(sdk, id, cachingConfig) {
        super(sdk, id, cachingConfig);
        /**
         * Find is used to search a collection using given filters, sort, and
         * hydration and will return an array of that collection type.
         *
         * @param filters Filters to be used on the find.
         * @param sort Sort to be used on the find.
         * @param hydratedFields Fields to be hydrated on the find.
         */
        this.find = (filters = {}, sort, hydratedFields) => {
            return new FindObservableBuilder({
                identifierKey: this.getIdentifierKey(),
                filters,
                sort,
                hydratedFields,
                execute: this.executeFind.bind(this)
            });
        };
        /**
         * There will be times when a single item will want to be subscribe to
         * this will current just complete after the first result has been
         * return but allows a future where we can sub to that single item
         * @param data
         * @returns
         */
        this.find_once = (filters = {}, hydratedFields) => {
            return new FindObservableBuilder({
                identifierKey: this.getIdentifierKey(),
                filters,
                hydratedFields,
                execute: this.executeFindOnce.bind(this)
            });
        };
        /**
         * Save will take data in form of the collections datatype.
         * Usually the collection will check an "id" field. If there
         * is and id they it will update the record otherwise it
         * will create a new record.
         *
         * @param data data to be saved.
         */
        this.save = (data, options) => {
            return this.saveItem(data, options).pipe(tap({
                next: (saved) => this.cache.reconcile("save", saved)
            }));
        };
        /**
         * Delete will take an ID and delete the associated
         * data object. Delete can just be a marker also
         * doesn't need to remove the data.
         *
         * @param id Id of the data to be deleted.
         */
        // TODO: this should be more specific than any
        this.delete = (id) => {
            return this.deleteItem(id).then((result) => {
                this.cache.reconcile("delete", result);
                return result;
            });
        };
        /**
         * Create a stream of this collection.
         * @param defaultFilter Default filter to use on the created stream.
         * @returns Stream for the current handler.
         */
        this.createStream = (defaultFilter, defaultSort) => {
            return new Stream(this, new BehaviorSubject([]), defaultFilter, defaultSort);
        };
    }
    executeFind(config) {
        const { filters, hydratedFields, sort } = config;
        /**
         * Don't pluck from cache if user has requested hydrated fields.
         * Hydrated requests are not cached.
         */
        if (isCacheableFind(config)) {
            const plucked = this.cache.pluck(filters);
            if (plucked) {
                return of(plucked);
            }
        }
        return this.findItems(filters || {}, sort, hydratedFields);
    }
    executeFindOnce(config) {
        const { filters, hydratedFields } = config;
        return this.find(filters, undefined, hydratedFields).pipe(map((results) => {
            if (Array.isArray(results)) {
                return results.length ? results[0] : null;
            }
            else if (isPage(results)) {
                return results?.data?.length ? results.data[0] : null;
            }
            return results;
        }));
    }
    /**
     * Usually this will be overriding via the extending handler but this will handle
     * a set of default key:value filters
     * @param filters A map of string filters. Key is the filter on the left of the : and
     *                the value Array is the value on each input
     * @returns Filter for the existing handler
     */
    parseFilter(filters) {
        const parsedFilters = { where: [] };
        const entries = filters.entries();
        for (const [key, entryValues] of entries) {
            entryValues.forEach((value) => {
                const id = key;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                parsedFilters.where.push({ field: id, type: FilterTypes.EQ, value });
            });
        }
        return parsedFilters;
    }
}

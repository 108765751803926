import { MediaType } from "../media";
import { AudioChannelLegacy as AudioChannel, MimeTypeLegacy as MimeType, MIME_TYPE_TO_MEDIA_TYPE_LEGACY as MIME_TYPE_TO_MEDIA_TYPE, TextTypeLegacy as TextType } from "./legacy";
export { TextTypeLegacy as TextType, AudioChannelLegacy as AudioChannel, MimeTypeLegacy as MimeType, ServiceAudioChannelLegacy as ServiceAudioChannel, MIME_TYPE_TO_MEDIA_TYPE_LEGACY as MIME_TYPE_TO_MEDIA_TYPE, MIME_TO_GCP_EXTENSION_LEGACY as MIME_TO_GCP_EXTENSION } from "./legacy";
/**
 * Based on the ServiceRendition determine the Audio Channel
 * @param rendition Redition to use to determine channel
 * @returns AudioChannel that was determined
 */
export const deriveAudioChannel = (rendition) => {
    if (rendition.audio_channel) {
        if (rendition.audio_channel === AudioChannel.STEREO || rendition.audio_channel === 2) {
            return AudioChannel.STEREO;
        }
        if (rendition.audio_channel === AudioChannel.MONO || rendition.audio_channel === 1) {
            return AudioChannel.MONO;
        }
    }
    return AudioChannel.NONE;
};
/**
 * Gets file extension from file type
 * @param fileType file type string (e.g. 'video/mp4')
 * @returns the file extension (e.g. 'mp4')
 */
export const getFileExtensionFromFileType = (fileType) => fileType.split("/")[1];
/**
 * This method builds the storage location based on id & bulk
 * @param id creative id
 * @param isBulk is it build
 * @returns string for location
 */
export const getStorageLocation = (id, isBulk, extension) => {
    return isBulk ? `${id}/master.${extension}` : `creatives/originals/${id}.${extension}`;
};
/**
 * Determines media type based on rendition information
 * @param data ServiceRendition information
 * @returns MediaType
 */
export const determineRenditionMediaType = (data) => {
    if (MIME_TYPE_TO_MEDIA_TYPE[data.mime] === MediaType.DISPLAY || !!data.image_format) {
        return MediaType.DISPLAY;
    }
    else if (MIME_TYPE_TO_MEDIA_TYPE[data.mime] === MediaType.VIDEO || !!data.video_codec) {
        return MediaType.VIDEO;
    }
    else if (MIME_TYPE_TO_MEDIA_TYPE[data.mime] === MediaType.AUDIO ||
        (!data.video_codec && !!data.audio_codec)) {
        return MediaType.AUDIO;
    }
    return;
};
/**
 * Creates the creative thumbnail based on media type
 * @param mediaType the current media type (display, audio or video)
 * @param id creative id
 * @param creativeAssetFolderBaseUrl sdk.urls.creativeAssetFolderBaseUrl
 */
export const getThumbnailSrcByMediaType = (mediaType, id, creativeAssetFolderBaseUrl) => {
    const thumbnailSrcByMediaType = {
        [MediaType.DISPLAY]: `${creativeAssetFolderBaseUrl}/${id}/master`,
        [MediaType.AUDIO]: "",
        [MediaType.VIDEO]: `${creativeAssetFolderBaseUrl}/${id}/thumb.jpg`
    };
    return thumbnailSrcByMediaType[mediaType];
};
/*validationDetails or FileUploadResults
 * @returns: boolean
 */
export const isHtmlTag = (assetMetadata) => {
    if (typeof assetMetadata === "string") {
        const urlOrTag = assetMetadata?.trim();
        return urlOrTag[0] === "<" && urlOrTag[urlOrTag.length - 1] === ">";
    }
    return (!!assetMetadata &&
        (assetMetadata.mime === MimeType.HTML ||
            assetMetadata.display?.textFormat === TextType.HTML ||
            assetMetadata.display?.textFormat === TextType.PLAIN));
};
/*validationDetails or FileUploadResults
 * @returns: boolean
 */
export const isVideo = (assetMetadata) => {
    return (!!assetMetadata &&
        (assetMetadata.mime === MimeType.MP4 || assetMetadata.mediaType === MediaType.VIDEO));
};

import axios from "axios";
import { Handler } from "../../handlers";
import { NotImplementedError, MadSDKAbortError } from "../../../errors";
import { serviceInscapeStatusToInscapeStatus, InscapeFilterFields } from "../../../models/creatives/inscape";
import { parseNewNewYorkApiResponse } from "../../../utils";
/**
 * This is a unique handler because it doesn't follow the standard
 * Type -> Service Type like most handlers. To make the interface the same
 * we still extend handler but need to override the types in findItem to
 * return 1 type and the filters to be based on a different type. To do this
 * we have to leave the Handler generics as "any"
 */
class Inscape extends Handler {
    constructor(sdk) {
        super(sdk, "inscape");
        this.toInscapeStatus = (status) => {
            return serviceInscapeStatusToInscapeStatus(status);
        };
    }
    async findItems(filters) {
        if (filters && filters.where) {
            const orgId = filters.where.find((filter) => filter.field === InscapeFilterFields.ORG_ID)?.value;
            if (orgId) {
                const url = `${this.sdk.urls.inscapeBaseUrl}/${orgId}`;
                if (this.cache.has(url)) {
                    return new Promise((resolve) => {
                        this.cache.get(url).then((statuses) => {
                            resolve(statuses);
                        });
                    });
                }
                const req = new Promise((resolve, reject) => {
                    axios
                        .get(url, {
                        headers: {
                            Accept: "application/json"
                        }
                    })
                        .then((res) => {
                        const { data: inscapeStatuses } = parseNewNewYorkApiResponse(res);
                        resolve(inscapeStatuses.map((status) => this.toInscapeStatus(status)));
                    })
                        .catch((error) => {
                        if (axios.isCancel(error)) {
                            reject(new MadSDKAbortError());
                        }
                        else {
                            reject(error);
                        }
                    });
                });
                this.cache.set(url, req);
                return req;
            }
        }
        return Promise.resolve([]);
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any  */
    async saveItem() {
        throw new NotImplementedError("save");
    }
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any  */
    async deleteItem() {
        throw new NotImplementedError("delete");
    }
}
export default Inscape;

import Cache from "../../cache";
/**
 * Abstract class that all template handlers should extend to
 * allow for a common interface of template-related activites.
 *
 * currently supports: Create
 */
export class Templator {
    /**
     * Handlers need access to MadSDK
     * @param sdk Instance of MadSDK to use for lookups
     */
    constructor(sdk) {
        /** Creates a template of the given type, and returns it. */
        this.create = async (template) => {
            return this.createTemplate(template);
        };
        this.sdk = sdk;
        this.cache = new Cache(sdk, "templates");
    }
}

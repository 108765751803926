import { IToastProps } from "@blueprintjs/core";
import { Pixel, History } from "@madhive/mad-sdk";

export const GET_ALL_PIXELS_PENDING = "GET_ALL_PIXELS_PENDING";
export const GET_ALL_PIXELS_FAILURE = "GET_ALL_PIXELS_FAILURE";
export const GET_ALL_PIXELS_SUCCESS = "GET_ALL_PIXELS_SUCCESS";

export const GET_PIXEL_HISTORY_PENDING = "GET_PIXEL_HISTORY_PENDING";
export const GET_PIXEL_HISTORY_FAILURE = "GET_PIXEL_HISTORY_FAILURE";
export const GET_PIXEL_HISTORY_SUCCESS = "GET_PIXEL_HISTORY_SUCCESS";

export const SAVE_PIXEL_PENDING = "SAVE_PIXEL_PENDING";
export const SAVE_PIXEL_FAILURE = "SAVE_PIXEL_FAILURE";
export const SAVE_PIXEL_SUCCESS = "SAVE_PIXEL_SUCCESS";

export const ABORT_PIXEL_REQUEST = "ABORT_PIXEL_REQUEST";

export interface TrackingPixelsState {
  // pixels
  byId: Record<string, Pixel>;
  availableById: Record<string, Pixel>;
  archivedById: Record<string, Pixel>;
  isPixelsLoading: boolean;
  isPixelSaving: boolean;
  updatingIds: string[];
  receivedAllAt: number | null;
  pixelsError: string | null;
  // history
  pixelHistoryById: Record<string, History[]>;
  isPixelHistoryLoading: boolean;
  receivedPixelHistoryAt: number | null;
  pixelHistoryError: string | null;
}

interface GetAllPixelsPendingAction {
  type: typeof GET_ALL_PIXELS_PENDING;
}

interface GetAllPixelsFailureAction {
  type: typeof GET_ALL_PIXELS_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface GetAllPixelsSuccessAction {
  type: typeof GET_ALL_PIXELS_SUCCESS;
  payload: Pixel[];
  meta: {
    timestamp: number;
  };
}

interface GetPixelHistoryPendingAction {
  type: typeof GET_PIXEL_HISTORY_PENDING;
}

interface GetPixelHistoryFailureAction {
  type: typeof GET_PIXEL_HISTORY_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface GetPixelHistorySuccessAction {
  type: typeof GET_PIXEL_HISTORY_SUCCESS;
  payload: History[];
  meta: {
    timestamp: number;
  };
}

interface SavePixelPendingAction {
  type: typeof SAVE_PIXEL_PENDING;
  meta?: {
    pixelId: string;
  };
}

interface SavePixelFailureAction {
  type: typeof SAVE_PIXEL_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
    pixelId?: string;
  };
}

interface SavePixelsSuccessAction {
  type: typeof SAVE_PIXEL_SUCCESS;
  payload: { pixel: Pixel };
  meta: {
    success: { message: string };
  };
}

interface AbortPixelRequestAction {
  type: typeof ABORT_PIXEL_REQUEST;
}

export type PixelActionTypes =
  | GetAllPixelsPendingAction
  | GetAllPixelsFailureAction
  | GetAllPixelsSuccessAction
  | GetPixelHistoryPendingAction
  | GetPixelHistoryFailureAction
  | GetPixelHistorySuccessAction
  | SavePixelPendingAction
  | SavePixelFailureAction
  | SavePixelsSuccessAction
  | AbortPixelRequestAction;

import { NotImplementedError } from "../../errors";
import axios from "axios";
import { UXConfigHandler } from "../handlers/ux";
class Values extends UXConfigHandler {
    constructor(sdk) {
        super(sdk, "values");
    }
    /**
     * Currently we don't need to support filtering on the find
     * @returns
     */
    async findItems() {
        const res = await axios.get(`${this.sdk.urls.baseAPIUrl}/ux/value-properties`);
        return res.data.data || [];
    }
    /**
     * API to save values takes an array of partial or full values and will update/create them
     * @param valueData The value data to update
     * @returns Promise that will resolve with the created/updated value
     */
    async saveItem(valueData) {
        return axios
            .post(`${this.sdk.urls.baseAPIUrl}/ux/value-properties`, [valueData], {
            headers: { "Content-Type": "application/json" }
        })
            .then((saved) => {
            return saved.data.data[0];
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async deleteItem() {
        throw new NotImplementedError("delete");
    }
}
export default Values;

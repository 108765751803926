import { FC } from "react";
import * as Icons from "../../../../Icons";
import Button from "../../../../Button";
import { styles } from "./styles";
import { CalendarHeaderProps } from "./types";
import { DAYS_OF_THE_WEEK } from "./constants";

export const CalendarHeader: FC<CalendarHeaderProps> = ({
  viewing,
  prevMonth,
  nextMonth
}) => {
  return (
    <div className="subKit-CalendarHeader" css={styles.header.wrapper}>
      <div css={styles.header.base}>
        <div className="prev-month" css={styles.header.nextPrev}>
          {prevMonth && (
            <Button
              fill
              onClick={e => {
                e.stopPropagation();
                prevMonth();
              }}
              css={styles.header.nextPrev}
              variant="ghost"
              prefix={<Icons.Chevron direction="left" />}
            />
          )}
        </div>
        {viewing?.get("monthShort") && (
          <span className="month-year">
            {viewing?.get("monthLong")} {viewing?.get("year")}
          </span>
        )}
        <div className="next-month" css={styles.header.nextPrev}>
          {nextMonth && (
            <Button
              fill
              onClick={e => {
                e.stopPropagation();
                nextMonth();
              }}
              css={styles.header.nextPrev}
              variant="ghost"
              prefix={<Icons.Chevron direction="right" />}
            />
          )}
        </div>
      </div>
      <div css={styles.header.daysOfWeek}>
        {DAYS_OF_THE_WEEK.map(day => (
          <span key={day}>{day}</span>
        ))}
      </div>
    </div>
  );
};

import { getDetailedErrorMessage } from "../../utils/validation";
export class SettingsSaveMissingIdsError extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, SettingsSaveMissingIdsError);
        }
        this.name = "SettingsSaveMissingIdsError";
        this.message =
            "Failed to save settings. The request included no user IDs to apply the settings to.";
    }
}
export class SettingsFetchFailed extends Error {
    constructor(apiError) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, SettingsFetchFailed);
        }
        this.name = "SettingsFetchFailed";
        this.message = `Failed to fetch user settings. ${getDetailedErrorMessage(apiError, "Please try again at a later time.")}`;
    }
}
export class SettingsSaveFailed extends Error {
    constructor(apiError) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, SettingsSaveFailed);
        }
        this.name = "SettingsSaveFailed";
        this.message = `Failed to save user settings. ${getDetailedErrorMessage(apiError, "Please try again at a later time.")}`;
    }
}

import {
  GET_ORG_SETTINGS_PENDING,
  GET_ORG_SETTINGS_SUCCESS,
  GET_ORG_SETTINGS_FAILURE,
  OrgSettingsState,
  OrgSettingsActionTypes
} from "./types";

export const initialState: OrgSettingsState = {
  settings: undefined,
  isLoading: false,
  isSaving: false,
  /* boolean indicating if ever loaded -- useful for some loading screen logic */
  error: null
};

export const orgSettingsReducer = (
  state = initialState,
  action: OrgSettingsActionTypes
): OrgSettingsState => {
  switch (action.type) {
    case GET_ORG_SETTINGS_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case GET_ORG_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: action.payload,
        isLoading: false,
        error: null
      };
    }
    case GET_ORG_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.meta.error
      };

    default:
      return state;
  }
};

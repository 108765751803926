import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Pen: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-pen"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3045 2.81783C14.988 2.13317 16.096 2.13432 16.7793 2.81767L18.1823 4.22067C18.8662 4.90456 18.8662 6.01244 18.1823 6.69633L16.4322 8.44642C16.422 8.45797 16.4114 8.46928 16.4003 8.48033C16.3893 8.49139 16.378 8.50202 16.3664 8.51224L7.34833 17.5303C7.20768 17.671 7.01691 17.75 6.818 17.75H4C3.58579 17.75 3.25 17.4142 3.25 17V14.182C3.25 13.9831 3.32903 13.7923 3.46969 13.6516L12.4821 4.64007C12.494 4.62626 12.5066 4.61279 12.5197 4.59967C12.5328 4.58655 12.5463 4.57401 12.5601 4.56205L14.3045 2.81783ZM17.1217 5.63567L15.869 6.88834L14.1121 5.13144L15.366 3.87765C15.4626 3.78082 15.6202 3.77985 15.7187 3.87833L17.1217 5.28133C17.2198 5.37944 17.2198 5.53756 17.1217 5.63567ZM4.75 14.4927L13.0514 6.19205L14.8083 7.949L6.50734 16.25H4.75V14.4927Z"
      />
      <path d="M3 20.25C2.58579 20.25 2.25 20.5858 2.25 21C2.25 21.4142 2.58579 21.75 3 21.75H21C21.4142 21.75 21.75 21.4142 21.75 21C21.75 20.5858 21.4142 20.25 21 20.25H3Z" />
    </svg>
  );
};

export default Pen;

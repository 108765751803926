import {
  OrganizationsState,
  OrganizationsActionTypes,
  GET_ORGANIZATION_PENDING,
  GET_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAILURE
} from "./types";

export const initialState: OrganizationsState = {
  byId: {},
  isLoading: false,
  error: null
};

export const organizationsReducer = (
  state = initialState,
  action: OrganizationsActionTypes
): OrganizationsState => {
  switch (action.type) {
    case GET_ORGANIZATION_PENDING:
      return {
        ...initialState,
        isLoading: true
      };
    case GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.payload.id]: action.payload },
        error: null
      };
    case GET_ORGANIZATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.meta.error
      };
    default:
      return state;
  }
};

import axios from "axios";
import { ServiceStatus, RemoteConfigProperties, IAB_CATEGORY_ERROR_MESSAGE, IAB_SUBCATEGORY_ERROR_MESSAGE, LAW_GOVT_POLITICS_CATEGORY } from "../../types";
import { Validation } from "../../validation";
import { Handler } from "../handlers";
import { parseIdFilter } from "../handlers/filter";
import { isErrorResponseValid } from "../../utils/validation";
import { AdvertisersFetchFailed, AdvertiserCreationFailed, AdvertiserCreationFailedHost, AdvertiserCreationFailedIdExists, AdvertiserUpdateFailedHost, AdvertiserUpdateFailedIdExists, AdvertiserUpdateFailed, AdvertiserDeleteFailed, AdvertiserInvalidAdomainSaveFailure } from "./errors";
import { toQueryParams } from "./utils";
import { AdvertisersBulk } from "./bulk";
function validators(sdk) {
    return {
        name: async (advertiser, value) => {
            if (!value || typeof value !== "string" || value.length === 0) {
                return "Name is required";
            }
            const advertisers = await sdk.advertisers.find();
            const match = advertisers?.find((adv) => adv.name.toLowerCase() === advertiser.name.toLowerCase() && adv.id !== advertiser.id);
            if (match) {
                if (match.status === ServiceStatus.ARCHIVED) {
                    return "This advertiser name is currently archived";
                }
                else {
                    return "This name already exists";
                }
            }
            return;
        },
        adomain: (advertiser, value) => {
            const advertiserDomainRegex = /^(?:[a-z0-9-]+\.)+[a-z]{2,}$/;
            if (value && value.match(advertiserDomainRegex)) {
                return;
            }
            return 'Invalid advertiser domain format. Please enter the domain in the format "adomain.com".';
        },
        iab_category_rtb_id: async (advertiser, value) => {
            const categoriesRequiredFeatureFlag = sdk.featureFlags.isFlagEnabled(RemoteConfigProperties.IAB_CATEGORIES_REQUIRED);
            // ADOPS-1384 always require subcategory if category is set to law/govt/politics
            if (value === LAW_GOVT_POLITICS_CATEGORY)
                return IAB_SUBCATEGORY_ERROR_MESSAGE;
            if (categoriesRequiredFeatureFlag) {
                if (value === undefined)
                    return IAB_CATEGORY_ERROR_MESSAGE;
            }
            return;
        }
    };
}
class Advertisers extends Handler {
    constructor(sdk) {
        super(sdk, "advertisers", { atomize: true });
        /**
         * @param error: the error to whittle down into something more specific/advertiser-or-sdk-related.
         * @param advertiser: optionally, the advertiser that was being saved. Leave it out if irrelevant at call time.
         * @returns: an appropriate Error if a specfic error can be determined. Else, null.
         */
        this.getSpecificAdvertiserError = (error, advertiser) => {
            if (!error) {
                return null; // can't determine a specific error if error is falsy
            }
            if (error.response?.data?.message?.includes("no such host")) {
                if (advertiser?.id) {
                    return new AdvertiserUpdateFailedHost();
                }
                return new AdvertiserCreationFailedHost();
            }
            if (error.response?.data?.message?.includes("organization with this external ID already exists")) {
                if (advertiser?.id) {
                    return new AdvertiserUpdateFailedIdExists();
                }
                return new AdvertiserCreationFailedIdExists();
            }
            if (isErrorResponseValid(error) && advertiser) {
                if (AdvertiserInvalidAdomainSaveFailure.is(error.response?.data?.errors)) {
                    return new AdvertiserInvalidAdomainSaveFailure(advertiser.adomain);
                }
            }
            if (error.response?.data?.message?.includes("invalid adomain")) {
                const validation = new Validation();
                validation.set("adomain", "bad domain");
                return validation;
            }
            // no specific error determined, so null
            return null;
        };
        this.bulk = new AdvertisersBulk(sdk);
        this.validators = validators(sdk);
    }
    async findItems(filters, sort = { field: "name", direction: "asc" }) {
        const idFilter = parseIdFilter(filters);
        return idFilter.size === 1
            ? this.getAdvertiser(idFilter.values().next().value).then((advertiser) => [advertiser])
            : this.getAdvertisers(filters, sort);
    }
    getAdvertiser(id) {
        return this.cache.promise(id, () => axios
            .get(`${this.collectionUrl}/${id}`)
            .then(({ data }) => data)
            .catch((error) => {
            throw this.getSpecificAdvertiserError(error) || new AdvertisersFetchFailed();
        }));
    }
    getAdvertisers(filters, sort) {
        const url = `${this.collectionUrl}${toQueryParams(filters, sort)}`;
        return this.cache
            .promise(url, () => axios.get(url).then(({ data }) => data))
            .catch((error) => {
            throw this.getSpecificAdvertiserError(error) || new AdvertisersFetchFailed();
        });
    }
    async saveItem(advertiser) {
        const errors = await this.validate(advertiser);
        if (errors.size) {
            return Promise.reject(errors);
        }
        if ("id" in advertiser && typeof advertiser.id === "string") {
            return this.update(advertiser);
        }
        return this.create(advertiser);
    }
    async create(advertiser) {
        try {
            const { data: saved } = await axios.post(this.collectionUrl, advertiser);
            return saved;
        }
        catch (error) {
            throw this.getSpecificAdvertiserError(error, advertiser) || new AdvertiserCreationFailed();
        }
    }
    async update(advertiser) {
        try {
            const { data: saved } = await axios.put(`${this.collectionUrl}/${advertiser.id}`, advertiser);
            return saved;
        }
        catch (error) {
            throw this.getSpecificAdvertiserError(error, advertiser) || new AdvertiserUpdateFailed();
        }
    }
    async deleteItem(id) {
        try {
            const { data: deleted } = await axios.delete(`${this.collectionUrl}/${id}`);
            return deleted;
        }
        catch (error) {
            throw this.getSpecificAdvertiserError(error) || new AdvertiserDeleteFailed();
        }
    }
    async make() {
        return Promise.resolve({
            adomain: "",
            name: "",
            status: ServiceStatus.READY
        });
    }
    get collectionUrl() {
        return `${this.sdk.urls.burnsBaseUrl}/advertisers`;
    }
}
export default Advertisers;

import { useMemo } from "react";

/**
 * hook for standardizing HTML size props (like: width, height, margin, etc.)
 * @param value: number, string, or undefined
 * - if number, or if string is a number without units, returns `${value}px`
 * - if string, and string is not all numbers, returns value
 * - if undefined, returns defaultValue
 * @param defaultValue: string — what should be returned if value is undefined — defaults to "auto"
 */
export const useSizingProp = (
  value: string | number | undefined,
  defaultValue: string = "auto"
) => {
  return useMemo(() => {
    if (!value && typeof value !== "number") {
      return defaultValue;
    }
    if (
      typeof value === "number" ||
      /**
       * we need to append px to a string that is only number, EX: "100",
       * typically, css is smart enough to know a number should be px,
       * but emotion/react does not infer px
       */
      /^\d+$/.test(value)
    ) {
      return `${value}px`;
    }
    return value;
  }, [value]);
};

import { css } from "@emotion/react";

/* Styles to be determined when the Dropdown component is created
 */
export const styles = {
  wrapper: css`
    padding: 0;
    margin: 0;
    box-shadow: none;
  `,
  base: css`
    box-shadow: var(--shadow-3);
    border-radius: 4px;
    border: 1px solid var(--color-border-primary);
    background: var(--color-background-primary);
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
  `,
  padding: css`
    padding: 1rem;
  `
};

import { FC, PropsWithChildren } from "react";
import { CommonIconProps } from "../../Icon/types";
import { DisplaySize } from "../types";
import { styles } from "./styles";

type Props = CommonIconProps & {
  size?: DisplaySize;
};

const Display: FC<PropsWithChildren<Props>> = ({
  fill,
  stroke,
  size = "medium",
  children
}) => {
  let sizeStyle = styles.medium;
  let fillOrStrokeColorStyle = {};

  switch (size) {
    case "extra-small":
      sizeStyle = styles.extraSmall;
      break;
    case "small":
      sizeStyle = styles.small;
      break;
    case "large":
      sizeStyle = styles.large;
      break;
    case "extra-large":
      sizeStyle = styles.extraLarge;
      break;
    case "medium":
    default:
      sizeStyle = styles.medium;
  }

  if (fill) {
    fillOrStrokeColorStyle = { [`--icon-fill-color` as any]: fill };
  }

  if (stroke) {
    fillOrStrokeColorStyle = {
      ...fillOrStrokeColorStyle,
      [`--icon-stroke-color` as any]: stroke
    };
  }

  return (
    <div
      className="kit-Display"
      css={[styles.display, sizeStyle]}
      style={fillOrStrokeColorStyle}
    >
      {children}
    </div>
  );
};

export default Display;

import {
  applyMiddleware,
  createStore,
  compose,
  StoreEnhancer,
  Reducer,
  AnyAction
} from "redux";
import thunk, { ThunkDispatch } from "redux-thunk";
import { __DEV__ } from "lib/constants/config";
import { getRootReducer, RootState, ReducerKey } from "./rootReducer";
import toasterMiddleware from "./middlewares/toasterMiddleware";
import { createNewFirestoreNotification } from "./lib/utils/notifications/methods";

const configureStore = (preloadedState: RootState | undefined = undefined) => {
  const middlewares = [
    thunk.withExtraArgument({ createNewFirestoreNotification }),
    toasterMiddleware
  ];

  const middlewareEnhancer = applyMiddleware(...middlewares);

  type WindowWithDevTools = Window & {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: (
      enhancers?: any
    ) => StoreEnhancer<unknown, Record<string, unknown>>;
  };

  const isReduxDevtoolsExtensionExist = (
    arg: Window | WindowWithDevTools
  ): arg is WindowWithDevTools => "__REDUX_DEVTOOLS_EXTENSION_COMPOSE__" in arg;

  const enhancers = [middlewareEnhancer];
  const composeEnhancers = isReduxDevtoolsExtensionExist(window)
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
  const composedEnhancers = composeEnhancers(...enhancers);

  const store = createStore(
    getRootReducer(),
    preloadedState,
    composedEnhancers
  );

  /* Add a mapping to keep track of the registered dynamic reducers */
  const dynamicReducerMapObject = {};

  const logReducerChange = (key: ReducerKey, isInjecting: boolean) => {
    const time = new Date();
    const repeat = (str: string, times: number) =>
      new Array(times + 1).join(str);
    const pad = (num: number, maxLength: number) =>
      repeat("0", maxLength - num.toString().length) + num;
    console.groupCollapsed(
      `%c ${isInjecting ? "inject" : "eject"} reducer: %c${key} %c@ ${`${pad(
        time.getHours(),
        2
      )}:${pad(time.getMinutes(), 2)}:${pad(time.getSeconds(), 2)}.${pad(
        time.getMilliseconds(),
        3
      )}`}`,
      `color: ${isInjecting ? "#00ff00" : "#FD1C03"}; font-weight: bold;`,
      "color: inherit; font-weight: bold;",
      "color: gray; font-weight: lighter;"
    );
    console.log(
      `%c state    `,
      "color: #03A9F4; font-weight: bold;",
      store.getState()
    );
    console.groupEnd();
  };

  /**
   * Create an inject reducer function
   * This function adds the dynamic reducer,
   * and creates a new combined reducer
   */
  const injectReducer = (key: ReducerKey, dynamicReducer: Reducer) => {
    dynamicReducerMapObject[key] = dynamicReducer;
    store.replaceReducer(getRootReducer(dynamicReducerMapObject));
    if (__DEV__) {
      logReducerChange(key, true);
    }
  };

  /**
   * Create an eject reducer function
   * This function removes the dynamic reducer,
   * and creates a new combined reducer
   */
  const ejectReducer = (key: ReducerKey) => {
    delete dynamicReducerMapObject[key];
    store.replaceReducer(getRootReducer(dynamicReducerMapObject));
    if (__DEV__) {
      logReducerChange(key, false);
    }
  };

  return {
    ...store,
    dynamicReducerMapObject,
    injectReducer,
    ejectReducer
  };
};

export type StoreType = ReturnType<typeof configureStore>;

export type AppDispatch = StoreType["dispatch"];

export type AppThunkDispatch = ThunkDispatch<
  ReturnType<StoreType["getState"]>,
  any,
  AnyAction
>;

export default configureStore;

import { SmithersNotificationFormatted } from "types";
import { NotificationCategory } from "lib/utils/notifications";
import { formatFilterDatesForDisplay } from "lib/utils/date";
import { FC } from "react";
import { css } from "@emotion/react";

const classes = {
  notificationSubtitleText: css`
    margin-top: var(--spacing-8);
    color: var(--semi-dark);
    font-size: var(--font-size-12);
    letter-spacing: 0.01em;
    line-height: 14px;
  `,
  notificationBodyContainer: css`
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: var(--spacing-8);
    margin: var(--spacing-24) 0px;
  }`
};

const Body: FC<{
  notification: SmithersNotificationFormatted;
}> = ({ notification }) => {
  const { id, content, category, meta } = notification;

  switch (category) {
    case NotificationCategory.DOWNLOAD:
      return (
        <div css={classes.notificationBodyContainer}>
          <span>Filter Start Date:</span>
          <span>{formatFilterDatesForDisplay(meta.filterStartDate)}</span>

          <span>Filter End Date:</span>
          <span>{formatFilterDatesForDisplay(meta.filterEndDate)}</span>

          <span>Report Type:</span>
          <span>
            {typeof meta.reportType === "string" ? meta.reportType : "--"}
          </span>
        </div>
      );
    default:
      return (
        <p css={classes.notificationSubtitleText} key={`content-${id}`}>
          {content}
        </p>
      );
  }
};

export default Body;

export var ServiceDealGroupType;
(function (ServiceDealGroupType) {
    ServiceDealGroupType[ServiceDealGroupType["ALL"] = 0] = "ALL";
    ServiceDealGroupType[ServiceDealGroupType["INVENTORY"] = 1] = "INVENTORY";
    ServiceDealGroupType[ServiceDealGroupType["GROUP"] = 2] = "GROUP";
})(ServiceDealGroupType || (ServiceDealGroupType = {}));
export var DealGroupType;
(function (DealGroupType) {
    DealGroupType["ALL"] = "All";
    DealGroupType["INVENTORY"] = "Inventory";
    DealGroupType["GROUP"] = "Group";
})(DealGroupType || (DealGroupType = {}));
export var DealArchiveStatus;
(function (DealArchiveStatus) {
    DealArchiveStatus["ACTIVE"] = "Active";
    DealArchiveStatus["ARCHIVED"] = "Archived";
    DealArchiveStatus["BOTH"] = "Both";
})(DealArchiveStatus || (DealArchiveStatus = {}));

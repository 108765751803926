import { FC, SVGProps } from "react";
import BulkCreativeUploadNotificationIcon from "static/assets/bulk-creative-upload-notification.svg";

export const DefaultBulkCreativeUploadIcon: FC<
  SVGProps<number | string>
> = () => (
  <img
    data-testid="bulk-creative-icon"
    src={BulkCreativeUploadNotificationIcon}
  />
);

import { RootState } from "rootReducer";
import { createSelector } from "reselect";
import { Product } from "@madhive/mad-sdk";

export const selectLastReceivedAllProducts = (
  state: RootState
): number | null => state.products.receivedAllAt;

export const selectIsAllProductsLoading = (state: RootState): boolean =>
  state.products.isLoading;

export const selectIsProductUpdating = (state: RootState): boolean =>
  state.products.isUpdating;

export const selectAvailableProductsById = (
  state: RootState
): Record<string, Product> => state.products.byId;

export const selectArchivedProducts = (
  state: RootState
): Record<string, Product> => state.products.archivedById;

// @return type: Product[]
export const selectAvailableProductsAsArray = createSelector(
  selectAvailableProductsById,
  (results: Record<string, Product>) => Object.values(results)
);

export const selectArchivedProductsAsArray = createSelector(
  selectArchivedProducts,
  (results: Record<string, Product>) => Object.values(results)
);

export const selectProductsGetError = (state: RootState): string | null =>
  state.products.getError;

export const selectProductsUpdateError = (state: RootState): string | null =>
  state.products.updateError;

import { createContext, FC, PropsWithChildren, useMemo, useState } from "react";
import { ProcessingListItem } from "frontier/lib/components/ProcessingList";
import ProcessingPane, {
  ProcessingListContextValue
} from "frontier/lib/components/ProcessingPane";

export const ActivationContext = createContext<ProcessingListContextValue>({
  items: [],
  setItems: () => {}
});

export const useActivationItems = () => {
  const [items, setItems] = useState<ProcessingListItem[]>([]);
  const itemsMemoized = useMemo(() => items, [items]);

  return {
    items: itemsMemoized,
    setItems
  };
};

export const ActivationContextProvider: FC<PropsWithChildren> = ({
  children
}) => {
  const value = useActivationItems();

  return (
    <ActivationContext.Provider value={value}>
      {children}
      <ProcessingPane
        title="Campaign creation"
        items={value.items}
        setItems={value.setItems}
      />
    </ActivationContext.Provider>
  );
};

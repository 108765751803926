import { KnownProjectIds, KnownApiKeys, KnownMeasurementIds, KnownAppIds } from "../types";
/**
 * Map of firebase project ID to corresponding GCP API key
 */
export const FIREBASE_PROJECT_ID_TO_API_KEY = {
    [KnownProjectIds.MAD_MASTER]: KnownApiKeys.MAD_MASTER,
    [KnownProjectIds.MAD_STAGING]: KnownApiKeys.MAD_STAGING,
    [KnownProjectIds.MAD_QA]: KnownApiKeys.MAD_QA,
    [KnownProjectIds.MAD_HACK]: KnownApiKeys.MAD_HACK,
    [KnownProjectIds.MAD_DEMO]: KnownApiKeys.MAD_DEMO,
    [KnownProjectIds.MAD_DATA]: KnownApiKeys.MAD_DATA,
    [KnownProjectIds.MADHIVE_TESTING]: KnownApiKeys.MADHIVE_TESTING,
    [KnownProjectIds.MAD_TALON]: KnownApiKeys.MAD_TALON
};
/**
 * Map of firebase project ID to firebase analytics measurement ID
 */
export const FIREBASE_PROJECT_ID_TO_ANALYTICS_MEASUREMENT_ID = {
    [KnownProjectIds.MAD_MASTER]: KnownMeasurementIds.MAD_MASTER,
    [KnownProjectIds.MAD_STAGING]: KnownMeasurementIds.MAD_STAGING,
    [KnownProjectIds.MAD_QA]: KnownMeasurementIds.MAD_QA,
    [KnownProjectIds.MAD_HACK]: KnownMeasurementIds.MAD_HACK,
    [KnownProjectIds.MAD_DEMO]: KnownMeasurementIds.MAD_DEMO,
    [KnownProjectIds.MAD_DATA]: KnownMeasurementIds.MAD_DATA,
    [KnownProjectIds.MADHIVE_TESTING]: KnownMeasurementIds.MADHIVE_TESTING,
    [KnownProjectIds.MAD_TALON]: KnownMeasurementIds.MAD_TALON
};
/**
 * Map of firebase project ID to firebase app  ID
 * TODO: Is this really needed?
 */
export const FIREBASE_PROJECT_ID_TO_APP_ID = {
    [KnownProjectIds.MAD_MASTER]: KnownAppIds.MAD_MASTER,
    [KnownProjectIds.MAD_STAGING]: KnownAppIds.MAD_STAGING,
    [KnownProjectIds.MAD_QA]: KnownAppIds.MAD_QA,
    [KnownProjectIds.MAD_HACK]: KnownAppIds.MAD_HACK,
    [KnownProjectIds.MAD_DEMO]: KnownAppIds.MAD_DEMO,
    [KnownProjectIds.MAD_DATA]: KnownAppIds.MAD_DATA,
    [KnownProjectIds.MADHIVE_TESTING]: KnownAppIds.MADHIVE_TESTING,
    [KnownProjectIds.MAD_TALON]: KnownAppIds.MAD_TALON
};

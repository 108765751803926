import { RemoteConfigProperties } from "@madhive/mad-sdk";
import { madSDK } from "lib/sdk";
import { featureFlag } from "lib/utils/featureFlags";

/**
 * Can be used within TS files to determine if a client has pace to budget enabled.
 * Note that is just a temporary solution, until CAMP-75 and CAMP-390 are completed.
 * After which this all instances of the functions in this file can be replaced with simpler solutions.
 * Those solutions are listed inside of CAMP-75.
 *
 * @returns A promise that resolves to either true or false.
 */
export const isPaceToBudgetEnabled = async () => {
  const {
    LEGACY_LINE_ITEM,
    LEGACY_PACE_TO_BUDGET,
    PACE_TO_BUDGET,
    PACE_TO_BUDGET_WITH_GOAL_INTERVAL
  } = RemoteConfigProperties;

  const isLegacy = await featureFlag(LEGACY_LINE_ITEM);

  const isDisplayedOnTargetingTab = !(await featureFlag(LEGACY_PACE_TO_BUDGET));

  const isDisplayedOnOverviewTab =
    (await featureFlag(LEGACY_PACE_TO_BUDGET)) &&
    (await featureFlag(PACE_TO_BUDGET_WITH_GOAL_INTERVAL));

  return isLegacy
    ? isDisplayedOnTargetingTab || isDisplayedOnOverviewTab
    : featureFlag(PACE_TO_BUDGET);
};

/**
 * A synchronous version of isPaceToBudgetEnabled.
 * Note that the asynchronous version is preferred, when possible, since it guarantees
 * the feature flags are loaded before checking their values.
 *
 * @returns true or false.
 */
export const isPaceToBudgetEnabledSync = () => {
  const {
    LEGACY_LINE_ITEM,
    LEGACY_PACE_TO_BUDGET,
    PACE_TO_BUDGET,
    PACE_TO_BUDGET_WITH_GOAL_INTERVAL
  } = RemoteConfigProperties;

  const isLegacy = madSDK.featureFlags.isFlagEnabled(LEGACY_LINE_ITEM);

  const isDisplayedOnTargetingTab = !madSDK.featureFlags.isFlagEnabled(
    LEGACY_PACE_TO_BUDGET
  );

  const isDisplayedOnOverviewTab =
    madSDK.featureFlags.isFlagEnabled(LEGACY_PACE_TO_BUDGET) &&
    madSDK.featureFlags.isFlagEnabled(PACE_TO_BUDGET_WITH_GOAL_INTERVAL);

  return isLegacy
    ? isDisplayedOnTargetingTab || isDisplayedOnOverviewTab
    : madSDK.featureFlags.isFlagEnabled(PACE_TO_BUDGET);
};

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Clipboard: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-clipboard"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.24646 6.99792C3.24646 5.47868 4.47805 4.24709 5.99729 4.24709H7.99813C8.41234 4.24709 8.74813 4.58287 8.74813 4.99709C8.74813 5.4113 8.41234 5.74709 7.99813 5.74709H5.99729C5.30648 5.74709 4.74646 6.3071 4.74646 6.99792V19.0029C4.74646 19.6937 5.30648 20.2538 5.99729 20.2538H10.9994C11.4136 20.2538 11.7494 20.5895 11.7494 21.0038C11.7494 21.418 11.4136 21.7538 10.9994 21.7538H5.99729C4.47805 21.7538 3.24646 20.5222 3.24646 19.0029V6.99792Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.24805 4.49625C7.24805 3.25361 8.25541 2.24625 9.49805 2.24625H14.5014C15.744 2.24625 16.7514 3.25361 16.7514 4.49625V5.49791C16.7514 6.74055 15.744 7.74791 14.5014 7.74791H9.49805C8.25541 7.74791 7.24805 6.74055 7.24805 5.49791V4.49625ZM9.49805 3.74625C9.08383 3.74625 8.74805 4.08203 8.74805 4.49625V5.49791C8.74805 5.91213 9.08383 6.24791 9.49805 6.24791H14.5014C14.9156 6.24791 15.2514 5.91213 15.2514 5.49791V4.49625C15.2514 4.08203 14.9156 3.74625 14.5014 3.74625H9.49805Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2516 4.99709C15.2516 4.58287 15.5874 4.24709 16.0016 4.24709H18.0024C19.5217 4.24709 20.7533 5.47868 20.7533 6.99792V10.9996C20.7533 11.4138 20.4175 11.7496 20.0033 11.7496C19.589 11.7496 19.2533 11.4138 19.2533 10.9996V6.99792C19.2533 6.3071 18.6932 5.74709 18.0024 5.74709H16.0016C15.5874 5.74709 15.2516 5.4113 15.2516 4.99709Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2521 14.7508C17.9797 14.7508 17.7284 14.8978 17.5948 15.1354L15.3497 19.1302C15.3497 19.1303 15.3497 19.1302 15.3497 19.1302C15.2185 19.3637 15.221 19.6493 15.3563 19.8805C15.4915 20.1117 15.7393 20.2537 16.0071 20.2537H20.4977C20.7656 20.2537 21.0134 20.1116 21.1486 19.8805C21.2839 19.6493 21.2863 19.3637 21.1551 19.1302L18.9096 15.1354C18.776 14.8979 18.5247 14.7508 18.2521 14.7508ZM16.2872 14.4006C16.6863 13.6904 17.4375 13.2508 18.2521 13.2508C19.0667 13.2508 19.818 13.6902 20.2171 14.4004C20.2171 14.4004 20.2171 14.4004 20.2171 14.4004L22.4627 18.3952C22.855 19.0932 22.8476 19.9469 22.4433 20.638C22.039 21.3291 21.2984 21.7537 20.4977 21.7537H16.0071C15.2065 21.7537 14.466 21.3291 14.0616 20.638C13.6573 19.947 13.6499 19.0933 14.0421 18.3954L16.2872 14.4006C16.2872 14.4005 16.2872 14.4006 16.2872 14.4006Z"
      />
    </svg>
  );
};

export default Clipboard;

import { findClickThroughFromEvents, fillInEmptyTrackingTypes } from "../../creatives";
import { TrackerType, filterOutEmptyTrackingUrls } from "../../creatives/trackers";
import { ServiceStatus, GeoTargetsKeys } from "../../../types";
import { satoshisToDollars, dollarsToSatoshis } from "../../../utils";
import { CampaignStatus, isActionRequiredStatus, daypartToServiceDaypart, campaignStatusToServiceStatus, instructionToServiceInstruction, toDayPartsFromServiceDateParts, isLiveStatus, fromServiceOwners, serviceStatusToCampaignStatus } from "../instruction";
import { metadataToExternalMetadata, serviceExternalMetaToExternalMeta } from "../meta";
// Using a new default that doesn't put in a [""] as default b/c
// this isn't an url and the UI should be able to handle []
const DEFAULT_EMPTY_TRACKING_PIXELS = [
    {
        type: TrackerType.IMPRESSION,
        urls: []
    },
    {
        type: TrackerType.START,
        urls: []
    },
    {
        type: TrackerType.Q1,
        urls: []
    },
    {
        type: TrackerType.Q2,
        urls: []
    },
    {
        type: TrackerType.Q3,
        urls: []
    },
    {
        type: TrackerType.Q4,
        urls: []
    },
    {
        type: TrackerType.CLICK_TRACKER,
        urls: []
    },
    {
        type: TrackerType.CLICK_THROUGH,
        urls: []
    }
];
export var DerivedLineItemStatus;
(function (DerivedLineItemStatus) {
    DerivedLineItemStatus[DerivedLineItemStatus["INVALID"] = 0] = "INVALID";
    DerivedLineItemStatus[DerivedLineItemStatus["MISSING_CREATIVE"] = 1] = "MISSING_CREATIVE";
    DerivedLineItemStatus[DerivedLineItemStatus["MISSING_WHITELIST"] = 2] = "MISSING_WHITELIST";
    DerivedLineItemStatus[DerivedLineItemStatus["READY"] = 3] = "READY";
    DerivedLineItemStatus[DerivedLineItemStatus["WAITING"] = 4] = "WAITING";
    DerivedLineItemStatus[DerivedLineItemStatus["NO_ACTIVE_CREATIVE"] = 5] = "NO_ACTIVE_CREATIVE";
    DerivedLineItemStatus[DerivedLineItemStatus["LIVE"] = 6] = "LIVE";
    DerivedLineItemStatus[DerivedLineItemStatus["COMPLETED"] = 7] = "COMPLETED";
    DerivedLineItemStatus[DerivedLineItemStatus["PAUSED"] = 8] = "PAUSED";
    DerivedLineItemStatus[DerivedLineItemStatus["CANCELLED"] = 9] = "CANCELLED";
    DerivedLineItemStatus[DerivedLineItemStatus["ARCHIVED"] = 10] = "ARCHIVED";
    DerivedLineItemStatus[DerivedLineItemStatus["MISSING_GEO"] = 11] = "MISSING_GEO";
    DerivedLineItemStatus[DerivedLineItemStatus["MISSING_PRODUCT"] = 12] = "MISSING_PRODUCT";
    DerivedLineItemStatus[DerivedLineItemStatus["MISSING_GOAL"] = 13] = "MISSING_GOAL";
    DerivedLineItemStatus[DerivedLineItemStatus["MISSING_FREQ_CAP"] = 14] = "MISSING_FREQ_CAP";
    DerivedLineItemStatus[DerivedLineItemStatus["MISSING_DETAILS"] = 999] = "MISSING_DETAILS"; // has more than one invalid status (only used by statusDetails.summary)
})(DerivedLineItemStatus || (DerivedLineItemStatus = {}));
/**
 * Builds a LineItem from a Service Line Item
 * @param serviceLineitem
 * @returns
 */
export const serviceCampaignLineItemToCampaignLineItem = async (serviceLineitem, crypto) => {
    /* eslint-disable camelcase */
    const { start_date, product_id, oms_id, name, end_date, start_asap, booked, external_id, budget, dayparts, frequencies, id, version, status: instructionStatus, geo_include, geo_exclude, derived_statuses, updated_by, publisher_group_id, parent_name, scenario_id } = serviceLineitem;
    /* eslint-enable camelcase */
    const extMeta = serviceLineitem.ext_metas
        ? serviceExternalMetaToExternalMeta(serviceLineitem.ext_metas)
        : undefined;
    const startDate = new Date(start_date);
    const endDate = new Date(end_date);
    let creativesCount = 0;
    const attachedCreatives = [];
    const archivedCreatives = [];
    const creatives = [];
    if (serviceLineitem.creatives) {
        serviceLineitem.creatives.forEach((serviceCreativeConfig) => {
            const creativeConfig = {
                id: serviceCreativeConfig.id,
                creativeId: serviceCreativeConfig.creative_vid_id,
                endDate: new Date(serviceCreativeConfig.end.seconds * 1000),
                startDate: new Date(serviceCreativeConfig.start.seconds * 1000),
                weight: serviceCreativeConfig.weight / 100,
                clickThroughUrl: findClickThroughFromEvents(serviceCreativeConfig.event_urls),
                trackingPixels: serviceCreativeConfig.event_urls
                    ? fillInEmptyTrackingTypes(serviceCreativeConfig.event_urls, DEFAULT_EMPTY_TRACKING_PIXELS)
                    : DEFAULT_EMPTY_TRACKING_PIXELS,
                status: serviceCreativeConfig.status,
                startAsap: serviceCreativeConfig?.start_asap || false
            };
            creatives.push(creativeConfig);
            if (creativeConfig.status !== ServiceStatus.ARCHIVED) {
                attachedCreatives.push(creativeConfig);
                creativesCount += 1;
            }
            if (creativeConfig.status === ServiceStatus.ARCHIVED) {
                archivedCreatives.push(creativeConfig);
            }
        });
    }
    const geoTargetingInclude = {
        country: geo_include?.country || "",
        districts: geo_include?.congressional_districts || [],
        dmas: geo_include?.dmas || [],
        regions: geo_include?.states || [],
        postalCodes: geo_include?.zips || []
    };
    const geoTargetingExclude = {
        country: geo_exclude?.country || "",
        districts: geo_exclude?.congressional_districts || [],
        dmas: geo_exclude?.dmas || [],
        regions: geo_exclude?.states || [],
        postalCodes: geo_exclude?.zips || []
    };
    const statusText = getCampaignStatus(derived_statuses?.[0], instructionStatus);
    const { advertiserId, stationId, agencyId, stationGroupId, ownersWithTypes } = await fromServiceOwners(serviceLineitem, crypto);
    const lineItem = {
        id,
        budget: satoshisToDollars(budget || 0),
        bookedImpressions: booked,
        archivedCreatives,
        attachedCreatives,
        creativesCount,
        dayparts: toDayPartsFromServiceDateParts(dayparts),
        frequencies: frequencies || [],
        endDate,
        startDate,
        startAsap: start_asap || false,
        name,
        mediaType: serviceLineitem.media_type,
        productId: product_id || "",
        externalOrderManagementSystemId: oms_id,
        externalId: external_id,
        instructionStatus,
        lastUpdated: serviceLineitem.updated ? new Date(serviceLineitem.updated) : undefined,
        version,
        geoTargeting: {
            exclude: geoTargetingExclude,
            include: geoTargetingInclude
        },
        meta: extMeta,
        status: statusText,
        criticalStatuses: getCriticalStatuses(serviceLineitem),
        effectiveCpm: satoshisToDollars(serviceLineitem.ecpm),
        parent: serviceLineitem.parent,
        parentName: parent_name,
        advertiserId,
        stationId,
        agencyId,
        stationGroupId,
        ownersWithTypes,
        actualizedImpressions: serviceLineitem.actualized,
        segments: serviceLineitem.segments || [],
        deviceCaps: {
            tv: serviceLineitem.device_caps?.tv ? serviceLineitem.device_caps.tv / 100 : 0,
            desktop: serviceLineitem.device_caps?.desktop ? serviceLineitem.device_caps.desktop / 100 : 0,
            tablet: serviceLineitem.device_caps?.tablet ? serviceLineitem.device_caps.tablet / 100 : 0,
            mobile: serviceLineitem.device_caps?.mobile ? serviceLineitem.device_caps.mobile / 100 : 0
        },
        get isEditable() {
            return (statusText !== CampaignStatus.ARCHIVED &&
                !!startDate &&
                !!endDate &&
                startDate.valueOf() < endDate.valueOf());
        },
        updatedBy: updated_by,
        publisherGroupId: publisher_group_id,
        targetingRules: serviceLineitem.targeting_rules,
        scenarioId: scenario_id,
        isLive() {
            return isLiveStatus(this.status);
        },
        iab_category_rtb_ids: serviceLineitem.iab_category_rtb_ids,
        statusDetails: {
            code: serviceLineitem.status_details.code,
            ...(serviceLineitem.status_details.invalid_reasons?.length
                ? {
                    invalidReasons: serviceLineitem.status_details.invalid_reasons.map((reason) => CampaignStatus[DerivedLineItemStatus[reason]])
                }
                : {}),
            summary: CampaignStatus[DerivedLineItemStatus[serviceLineitem.status_details.summary]],
            canBe: serviceLineitem.status_details.canBe
        },
        goalInterval: serviceLineitem.goal_interval,
        tpa: serviceLineitem.tpa,
        tpa_id: serviceLineitem.tpa_id
    };
    return lineItem;
};
/**
 * Takes a geoTargeting and returns the need format for service targeting
 * @param geoTargeting The geoTargeting to be convereted
 * @returns undefined if no targeting given (service doesn't support empty values) or
 * the correct targeting for a service.
 *
 * Validation logic on the service side of for geo targeting
 * is a bit wild so here is the current state of it
 * 1. Country is required if no DMAs or Districts
 * 2. Country is required if Zipcodes
 * 3. Country is required if Regions (states)
 * 4. If DMA then no country
 * 5. If DMA and Zipcode Or Regions then Country
 * 6. If District then no country
 * 7. If District and Zipcode Or Regions then Country
 */
export const geoTargetsToServiceGeoFields = (geoTargeting) => {
    if (!geoTargeting) {
        return {
            country: "",
            regions: [],
            districts: [],
            dmas: [],
            postalCodes: []
        };
    }
    if (geoTargeting.country === "" &&
        geoTargeting.regions.length === 0 &&
        geoTargeting.districts.length === 0 &&
        geoTargeting.dmas.length === 0 &&
        geoTargeting.postalCodes.length === 0) {
        return undefined;
    }
    let country = geoTargeting.country;
    if ((geoTargeting.dmas?.length !== 0 || geoTargeting.districts?.length !== 0) &&
        geoTargeting.postalCodes?.length === 0 &&
        geoTargeting.regions?.length === 0) {
        country = "";
    }
    return {
        country: country,
        states: geoTargeting.regions,
        congressional_districts: geoTargeting.districts,
        dmas: geoTargeting.dmas,
        zips: geoTargeting.postalCodes
    };
};
/**
 * @param lineItem: a service variant of a line item.
 * @return: an array of the critical (action required) statuses associated with it.
 */
export const getCriticalStatuses = (lineItem) => {
    const critical = new Set();
    for (const derived of lineItem.derived_statuses || []) {
        const status = getCampaignStatus(derived, lineItem.status);
        isActionRequiredStatus(status) && critical.add(status);
    }
    return Array.from(critical.values());
};
/**
 * @param derivedStatus: a derived status, from a service line item (or undefined, if there is none).
 * @param instructionStatus: a service status of a line item.
 * @return: the equivalent campaign status text, as far as it can be deduced.
 */
export const getCampaignStatus = (derivedStatus, instructionStatus) => {
    if (derivedStatus) {
        const derived = DerivedLineItemStatus[derivedStatus];
        const status = derived ? CampaignStatus[derived] : null;
        if (status) {
            return status;
        }
    }
    return serviceStatusToCampaignStatus(instructionStatus);
};
/**
 * Takes a lineItem and converts it into a Service Line Item
 * @param lineItem Line item to be converted.
 * @returns a formated service line item.
 */
export const lineItemToServiceCampaignLineItem = (lineItem) => {
    const base = instructionToServiceInstruction(lineItem);
    const startDate = lineItem.startAsap && !lineItem.startDate ? new Date() : lineItem.startDate;
    return {
        ...base,
        ...(("startDate" in lineItem || "startAsap" in lineItem) && {
            start_date: startDate?.toISOString()
        }),
        ...("endDate" in lineItem && { end_date: lineItem.endDate?.toISOString() }),
        ...("startAsap" in lineItem && { start_asap: lineItem.startAsap || false }),
        ...("effectiveCpm" in lineItem && { ecpm: dollarsToSatoshis(lineItem.effectiveCpm) || 0 }),
        ...("actualizedImpressions" in lineItem && { actualized: lineItem.actualizedImpressions || 0 }),
        ...("dayparts" in lineItem && { dayparts: daypartToServiceDaypart(lineItem.dayparts) }),
        ...("geoTargeting" in lineItem && {
            geo_include: geoTargetsToServiceGeoFields(lineItem.geoTargeting?.include),
            geo_exclude: geoTargetsToServiceGeoFields(lineItem.geoTargeting?.exclude)
        }),
        ...("segments" in lineItem && { segments: lineItem.segments || [] }),
        ...("productId" in lineItem && { product_id: lineItem.productId }),
        ...("status" in lineItem && {
            status: campaignStatusToServiceStatus(lineItem.status, lineItem.instructionStatus)
        }),
        ...("productEcpm" in lineItem && { product_ecpm: lineItem.productEcpm }),
        ...("publisherGroupId" in lineItem && { whitelist_id: lineItem.publisherGroupId }),
        ...("attachedCreatives" in lineItem && {
            creatives: lineItem.attachedCreatives?.map((item) => {
                const event_urls = filterOutEmptyTrackingUrls(item.trackingPixels)
                    .filter((el) => el.type !== 100)
                    .map((el) => {
                    return {
                        type: el.type,
                        urls: el.urls.map((url) => url.trim())
                    };
                });
                if (item.clickThroughUrl?.trim()?.length) {
                    event_urls.push({ type: 100, urls: [item.clickThroughUrl.trim()] });
                }
                return {
                    id: item.id,
                    parent: lineItem.id || "",
                    creative_vid_id: item.creativeId,
                    start_asap: item.startAsap || false,
                    start: {
                        seconds: item.startAsap && !item.startDate
                            ? Math.floor(new Date().getTime() / 1000)
                            : Math.floor(item.startDate.getTime() / 1000)
                    },
                    end: { seconds: Math.floor(item.endDate.getTime() / 1000) },
                    weight: Math.floor(item.weight * 100),
                    event_urls,
                    status: item.status
                };
            })
        }),
        ...("deviceCaps" in lineItem && {
            device_caps: {
                tv: lineItem.deviceCaps?.tv ? lineItem.deviceCaps.tv * 100 : 0,
                desktop: lineItem.deviceCaps?.desktop ? lineItem.deviceCaps.desktop * 100 : 0,
                tablet: lineItem.deviceCaps?.tablet ? lineItem.deviceCaps.tablet * 100 : 0,
                mobile: lineItem.deviceCaps?.mobile ? lineItem.deviceCaps.mobile * 100 : 0
            }
        }),
        ...("meta" in lineItem && { ext_metas: metadataToExternalMetadata(lineItem.meta) }),
        ...("targetingRules" in lineItem && { targeting_rules: lineItem.targetingRules }),
        ...("scenarioId" in lineItem && { scenario_id: lineItem.scenarioId }),
        ...("tpa_id" in lineItem && { tpa_id: lineItem.tpa_id }),
        ...("tpa" in lineItem && { tpa: lineItem.tpa }),
        ...("iab_category_rtb_ids" in lineItem && {
            iab_category_rtb_ids: lineItem.iab_category_rtb_ids
        })
    };
};
export const lineItemToBulkServiceLineItem = (lineItem) => {
    const serviceLineItem = lineItemToServiceCampaignLineItem(lineItem);
    // bulkLineItem endpoint uses `dayparts_include` instead of `dayparts`.
    serviceLineItem.dayparts_include = serviceLineItem.dayparts;
    delete serviceLineItem.dayparts;
    // bulkLineItem endpoint uses `recipes` instead of `segments`.
    serviceLineItem.recipes = serviceLineItem.segments;
    delete serviceLineItem.segments;
    return serviceLineItem;
};
/**
 * @param item: anything.
 * @return: type narrowed. True if the item is a line item. Else, false.
 */
export const isLineItem = (item) => {
    return (typeof item === "object" &&
        item !== null &&
        "creativesCount" in item &&
        "id" in item &&
        "instructionStatus" in item &&
        "attachedCreatives" in item &&
        "geoTargeting" in item &&
        "endDate" in item &&
        "startDate" in item);
};
export const DSP_OPTIONS = [
    { id: "0LDY6rsTok3wcPtS29QFr97Mtpw7", label: "Madhive" },
    { id: "NaYohTJvHtJVMSHZW0CIhepYt5Xi", label: "Google Ad Manager ( GAM )" },
    { id: "3SLsQgGodlAmVrUmZYoQ1GcZoMDj", label: "Xandr" }
];

import { Icon, IconSize } from "@blueprintjs/core";
import styled from "@emotion/styled";
import classNames from "classnames";

import { VARIANT_TO_ICON } from "./constants";
import { BannerProps } from "./types";

export const BaseComponent = (props: BannerProps) => {
  const { variant, message, action, id, className } = props;

  return (
    <div className={className}>
      <div
        data-testid={id}
        className={classNames("Banner-ContentContainer", `Banner--${variant}`)}
      >
        <Icon icon={VARIANT_TO_ICON[variant]} iconSize={IconSize.LARGE} />
        {message}
        {action && (
          <span data-testid={`action-${id}`} className="Banner-ClickableText">
            {action}
          </span>
        )}
      </div>
    </div>
  );
};

export const Banner = styled(BaseComponent)` 
position: sticky;
bottom: 0;
z-index: 4;

.Banner {
  &-Message {
    margin: var(--spacing-16) var(--spacing-0);
  }
  &--warning {
    background-color: var(--orange);
    color: #fff;
    min-height: var(--spacing-32)

  }
  &--info {
    background-color: var(--info);
    color: #fff;
    min-height: var(--spacing-32)

  }
  &--danger {
    background-color: var(--red);
    color: #fff;
    min-height: var(--spacing-32)

  }
  &--success {
    background-color: var(--green);
    color: #fff;
    min-height: var(--spacing-32)

  }
  &-ContentContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-right: var(--spacing-16);
    }
}

&-ClickableText {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  margin-left: var(--spacing-4)
}

`;

import axios from "axios";
import { Observable } from "rxjs";
import { parseFilterFields, parseIdFilter } from "../../modules/handlers/filter";
import { ObjType, ServiceStatus } from "../../types";
import { serviceCampaignToCampaign, campaignToServiceCampaign } from "../../models/campaigns";
import { ObservableHandler } from "../../modules/handlers";
import { CampaignsAudits } from "./audits";
import Templates from "./templates";
import Uploads from "./uploads";
import LineItems from "./lineitems";
import { isPage, isServicePage } from "../handlers/page";
import { toQueryParams, toFilterObjects, deriveNewCampaignStartDate } from "./utils";
import { CampaignStatus, isLiveStatus } from "../../models/campaigns/instruction";
import { lineItemToServiceCampaignLineItem } from "../../models/campaigns/lineitems";
import { addYearsToDate } from "../../utils/dates";
import { CampaignStatusManager } from "./status";
import { validators } from "./validators";
import { Validation, ValidationError } from "../../validation";
import { SummaryInjector } from "./summaryInjector";
import { BulkSaveMissingStatus } from "./errors";
/**
 * Campaigns handler is used when requesting campaigns from madhive services.
 * The handler will be using Observables to allow for a future case where
 * real time updates are detected.
 *
 *
 * For now the Observable will be used like a standard Promise with only
 * data returned when requested. In the future the service for campaigns will
 * push campaign data on changes that will then fire .next of those delta. This
 * will allow the UI to react to these deltas
 */
class Campaigns extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "campaigns", { atomize: true });
        /**
         * @param data Campaign(s) to validate before saving
         * @returns array of Validation instances
         */
        this.validateUpdates = (data) => {
            const errors = [];
            const items = Array.isArray(data) ? data : [data];
            items.forEach((item) => {
                const result = this.validate(item);
                if (result.size > 0) {
                    errors.push(result);
                }
            });
            return errors;
        };
        this.audits = new CampaignsAudits(sdk);
        this.lineItems = new LineItems(sdk);
        this.templates = new Templates(sdk);
        this.uploads = new Uploads(sdk);
        this.validators = validators(sdk);
        this.status = new CampaignStatusManager((object, value) => this.validate(object, "statusChange", value));
        this.summaryInjector = new SummaryInjector(sdk);
    }
    findItems(filters, sort) {
        return new Observable((subscriber) => {
            const idFilter = parseIdFilter(filters);
            const promise = idFilter.size === 1
                ? this.getCampaign(idFilter.values().next().value).then((campaign) => [campaign])
                : this.getCampaigns(filters, sort);
            promise
                .then((results) => {
                subscriber.next(results);
                const summaries = parseFilterFields(filters.fields, [
                    "pacing",
                    "videoCompletionRate",
                    "frequency"
                ]);
                // If summary is requested we need to fetch campaign summaries
                if (summaries.length > 0) {
                    const isPaged = isPage(results);
                    this.summaryInjector.inject(isPaged ? results.data : results, summaries).subscribe({
                        next: (campaigns) => subscriber.next(isPaged ? { page: results.page, data: campaigns } : campaigns),
                        error: (error) => subscriber.error(error),
                        complete: () => subscriber.complete()
                    });
                }
                else {
                    // Currently there is no reason to keep the observable open.
                    subscriber.complete();
                }
            })
                .catch((error) => {
                subscriber.error(error);
            });
        });
    }
    async parseServiceResults(results) {
        const converted = [];
        results.forEach((serviceCampaign) => {
            converted.push(this.toCampaign(serviceCampaign));
        });
        return Promise.all(converted);
    }
    /**
     * @param id: the id of a campaign to get.
     * @return: a promise resolving to that campaign.
     */
    getCampaign(id) {
        return this.cache.promise(id, () => axios
            .get(
        // When getting a single campaign, we will always want to include archived.
        `${this.sdk.urls.baseAPIUrl}/campaign/${id}?includeArchived=true`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => this.toCampaign(res.data.data)));
    }
    /**
     * @param filters: filters to determine what campaigns to get.
     * @param sort: how to sort the set of campaigns gotten.
     * @return: the requested set of campaigns.
     */
    getCampaigns(filters, sort) {
        const params = toQueryParams(filters, sort);
        const pageSize = filters?.paging?.size || 10;
        const url = `${this.sdk.urls.baseAPIUrl}/campaigns${params}`;
        return this.cache.promise(url, () => axios
            .get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async ({ data: res }) => {
            const campaigns = await this.parseServiceResults(res.data || []);
            let results = campaigns;
            if (isServicePage(res)) {
                results = {
                    page: {
                        count: res.paging_info.count,
                        token: res.paging_info.token,
                        size: pageSize
                    },
                    data: campaigns
                };
            }
            return results;
        }));
    }
    /**
     * Duplicate a campaign, including its descendants.
     * @param newCampaign A Campaign object including any fields that differ from the original.
     * @param fromCampaignId The campaign ID of the Campaign to copy from
     * @returns The new Campaign object.
     */
    duplicate(newCampaign, fromCampaignId) {
        return new Observable((subscriber) => {
            // Observable constructor expects a function that returns a `TeardownLogic`,
            // so we cannot make it itself async (returns a Promise).
            (async () => {
                axios
                    .post(`${this.sdk.urls.baseAPIUrl}/campaign/${fromCampaignId}/duplicate`, {
                    ...this.toServiceCampaign(newCampaign),
                    id: await this.sdk.cryptography.mintKey(ObjType.INST),
                    version: 0
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                    .then(async (res) => {
                    this.cache.clear("resources");
                    this.sdk.caches.clear(this.lineItems.id);
                    // The duplicate endpoint does not return the full Campaign object with descendants, so we need a fetch
                    const campaign = await this.getCampaign(res.data.data.id);
                    this.cache.set(campaign.id, campaign);
                    subscriber.next(campaign);
                })
                    .catch((error) => {
                    subscriber.error(error);
                });
            })();
        });
    }
    /**
     * Save supports three different types of saves.
     *  - create: if a single campaign is given with no ID.
     *  - update: if a single campaign is given with and ID.
     *  - bulk: if an array of campaigns is given to be updated.
     *    - Note that bulk only supports the fields "booked", "end_date", "id", "start_date", "status", "version".
     * @param input: either a single campaign to create/update or an array of campaigns to bulk update.
     * @return: the single campaign created/updated or an array of updated campaigns.
     */
    saveItem(input, options) {
        return new Observable((subscriber) => {
            const promise = Array.isArray(input)
                ? this.saveCampaigns(input, options)
                : this.saveCampaign(input, options);
            promise
                .then((saved) => {
                subscriber.next(saved);
                subscriber.complete();
            })
                .catch((error) => subscriber.error(error));
        });
    }
    /**
     * @param campaign: a single campaign to save.
     * @return: the saved campaign.
     */
    async saveCampaign(campaign, options) {
        const { skipValidation } = options || {};
        if (!skipValidation) {
            const errors = this.validate(campaign);
            if (errors.size) {
                return Promise.reject(errors);
            }
        }
        let includeDescendants = "";
        const descendants = {};
        // If campaign does not have an id, it is new.
        // Note: If we allow for partial objects in the future, we don't need to do this and we could avoid
        // sending superflous data.
        if (!campaign.id) {
            campaign = await this.make(campaign);
            for (const lineItem of campaign.lineItems) {
                lineItem.parent = campaign.id || "";
                descendants[lineItem.id] = lineItemToServiceCampaignLineItem(lineItem);
            }
            includeDescendants = "?includeDescendants=true";
        }
        if (!campaign.id) {
            return Promise.reject(new Validation([["error", "Minted key failure"]]));
        }
        const serviceCampaign = this.toServiceCampaign(campaign);
        !!includeDescendants && (serviceCampaign.descendants = descendants);
        return axios
            .post(`${this.sdk.urls.baseAPIUrl}/campaign/${campaign.id}${includeDescendants}`, serviceCampaign, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async (res) => {
            this.sdk.caches.clear(this.lineItems.id);
            /**
             * Backend tech debt: response from POST campaigns endpoint does
             * not include a derived status for the line item. As this status is
             * hugely important, we make another API request to ensure we have
             * the most up to date data.
             */
            campaign.id && this.cache.remove(campaign.id);
            return this.getCampaign(res.data.data.id);
        });
    }
    /**
     * @param campaigns: an array of campaigns to save.
     * @return: the resulting set of campaigns, after they have been saved.
     * @throws: BulkSaveMissingstatus if any of the given campaigns do not have a status set.
     */
    saveCampaigns(campaigns, options) {
        const { skipValidation } = options || {};
        if (!skipValidation) {
            const errors = this.validateUpdates(campaigns);
            if (errors.length) {
                return Promise.reject(new ValidationError(errors));
            }
        }
        const toSave = [];
        // We need to covert to a service campaign and only
        // get fields supported for bulk update.
        for (const campaign of campaigns) {
            if (!campaign.status) {
                throw new BulkSaveMissingStatus(campaign.id);
            }
            for (const lineItem of campaign.lineItems) {
                if (!campaign.startDate || lineItem.startDate < campaign.startDate) {
                    // Ensure that campaign startDate is derived from its line items.
                    campaign.startDate = lineItem.startDate;
                }
                const converted = this.lineItems.toServiceLineItem(lineItem);
                toSave.push(this.pickSupportedDefinedFields(converted));
            }
            toSave.push(this.toBulkServiceCampaign(campaign));
        }
        return axios
            .patch(`${this.sdk.urls.baseAPIUrl}/campaigns`, { data: toSave }, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async ({ data: res }) => {
            const campaigns = await Promise.all(res.data.map((service) => this.toCampaign(service)));
            this.sdk.caches.clear(this.lineItems.id);
            return campaigns;
        });
    }
    deleteItem(id) {
        return axios.delete(`${this.sdk.urls.baseAPIUrl}/campaign/${id}`).then((res) => {
            this.sdk.caches.clear(this.lineItems.id);
            return res.data.status;
        });
    }
    /**
     * Used to make a default campaign.
     * @param defaults these will be used to override the standard defaults
     * @return Promise<Campaign> that will return the default campaign object
     */
    make(defaults) {
        return this.sdk.cryptography.mintKey(ObjType.INST).then((id) => {
            const campaign = {
                id,
                descendantsBookedImpressions: 0,
                frequencies: [],
                name: "",
                externalOrderManagementSystemId: "",
                externalId: "",
                instructionStatus: ServiceStatus.DRAFT,
                version: 0,
                status: CampaignStatus.MISSING_LINE_ITEM,
                statusDetails: {
                    canBe: {
                        archived: true,
                        edited: true,
                        live: false,
                        paused: false,
                        unarchived: false
                    },
                    code: 100,
                    summary: CampaignStatus.MISSING_LINE_ITEM
                },
                parentName: "",
                parent: this.sdk.getCurrentUser()?.primaryOrganizationId || "",
                creativesCount: 0,
                liveLineItemsCount: 0,
                owners: [],
                lineItems: [],
                isDoubleVerify: false,
                organizationId: this.sdk.getCurrentUser()?.primaryOrganizationId || "",
                isLive() {
                    return isLiveStatus(this.status);
                },
                isEditable: true,
                startDate: deriveNewCampaignStartDate(),
                endDate: addYearsToDate(deriveNewCampaignStartDate(), 3),
                ...defaults
            };
            return campaign;
        });
    }
    async toCampaign(serviceCampaign) {
        return serviceCampaignToCampaign(serviceCampaign, this.sdk.cryptography);
    }
    toBulkServiceCampaign(campaign) {
        const serviceCampaign = this.toServiceCampaign(campaign);
        return this.pickSupportedDefinedFields(serviceCampaign);
    }
    pickSupportedDefinedFields(item) {
        const cleaned = {};
        ["booked", "end_date", "id", "start_date", "status", "version", "creatives"].forEach((key) => {
            if (item[key]) {
                cleaned[key] = item[key];
            }
        });
        return cleaned;
    }
    toServiceCampaign(campaign) {
        return campaignToServiceCampaign(campaign);
    }
    parseFilter(filters) {
        return toFilterObjects(filters);
    }
}
export default Campaigns;

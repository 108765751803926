import { Advertiser, Agency, User } from "@madhive/mad-sdk";

import configureStore from "configureStore";

export type Store = ReturnType<typeof configureStore>;

export enum Stores {
  agency = "agencies",
  advertiser = "advertisers",
  user = "users"
}

export type GetReturn<T> = T extends keyof typeof Stores
  ? T extends "agency"
    ? Agency | undefined
    : T extends "advertiser"
    ? Advertiser | undefined
    : T extends "user"
    ? User | undefined
    : unknown
  : undefined;

import { keyBy } from "lodash";
import {
  GET_PIO_PRODUCT_PACKAGES_FAILURE,
  GET_PIO_PRODUCT_PACKAGES_PENDING,
  GET_PIO_PRODUCT_PACKAGES_SUCCESS,
  PioProductPackagesActionType,
  PioProductPackagesState
} from "./types";

const initialState: PioProductPackagesState = {
  byId: {},
  receivedAllAt: null,
  isAllLoading: false
};

export const pioProductPackagesReducer = (
  state = initialState,
  action: PioProductPackagesActionType
): PioProductPackagesState => {
  switch (action.type) {
    case GET_PIO_PRODUCT_PACKAGES_SUCCESS: {
      return {
        ...state,
        isAllLoading: false,
        receivedAllAt: action.meta.timestamp,
        byId: keyBy(action.payload, "id")
      };
    }
    case GET_PIO_PRODUCT_PACKAGES_PENDING: {
      return {
        ...state,
        isAllLoading: true
      };
    }
    case GET_PIO_PRODUCT_PACKAGES_FAILURE: {
      return {
        ...state,
        isAllLoading: false
      };
    }
    default:
      return state;
  }
};

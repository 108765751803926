import { NotImplementedError } from "../../errors";
import { Base } from "./base";
import { FILTER_ALL } from "./filter";
import { FindPromiseBuilder } from "./builder";
import { isCacheableFind } from "./isCacheableFind";
/**
 * Abstract class that all handlers should extend to allow the
 * same interface for most common activities.
 *
 * currently supports: Find, Save, Delete
 *
 * TODO: Add support for sorting on collections.
 */
export class PromiseHandler extends Base {
    /**
     * Handlers need access to MadSDK
     * @param sdk Instance of MadSDK to use for lookups
     * @param id is handler's ID — also used for cache namespace
     * @param {CacheConfig} cacheConfig
     */
    constructor(sdk, id, cachingConfig) {
        super(sdk, id, cachingConfig);
        /**
         * Find is used to search a collection using given filters, sort, and
         * hydration and will return an array of that collection type.
         *
         * @param filters Filters to be used on the find.
         * @param sort Sort to be used on the find.
         * @param hydratedFields Fields to be hydrated on the find.
         */
        this.find = (filters, sort, hydratedFields) => {
            return new FindPromiseBuilder({
                identifierKey: this.getIdentifierKey(),
                filters,
                sort,
                hydratedFields,
                execute: this.executeFind.bind(this)
            });
        };
        /**
         * find_once is used to search a collection and only return the
         * top most record from the result set.
         *
         * by default this just called find and returns the first result
         * in the returned data. Some collections will override this default
         * action to call a better look up.
         * @param filters Filters to be used on the find
         */
        this.find_once = (filters, hydratedFields) => {
            return new FindPromiseBuilder({
                identifierKey: this.getIdentifierKey(),
                filters,
                hydratedFields,
                execute: this.executeFindOnce.bind(this)
            });
        };
        /**
         * Save will take data in form of the collections datatype.
         * Usually the collection will check an "id" field. If there
         * is and id they it will update the record otherwise it
         * will create a new record.
         *
         * @param data data to be saved.
         */
        this.save = async (data, options) => {
            return this.saveItem(data, options).then((saved) => {
                this.cache.reconcile("save", saved);
                return saved;
            });
        };
        /**
         * Delete will take an ID and delete the associated
         * data object. Delete can just be a marker also
         * doesn't need to remove the data.
         *
         * @param id Id of the data to be deleted.
         */
        this.delete = async (id) => {
            return this.deleteItem(id).then((result) => {
                this.cache.reconcile("delete", result);
                return result;
            });
        };
        this.keyFromFilter = (filters) => {
            if (filters.where) {
                return filters.where.reduce((acc, condition) => {
                    return `${acc}-${String(condition.field)}-${condition.type}-${condition.value}`;
                }, "");
            }
            return FILTER_ALL;
        };
    }
    executeFind(config) {
        const { filters, hydratedFields, sort } = config;
        /**
         * Don't pluck from cache if user has requested hydrated fields.
         * Hydrated requests are not cached.
         */
        if (isCacheableFind(config)) {
            const plucked = this.cache.pluck(filters);
            if (plucked) {
                return Promise.resolve(plucked);
            }
        }
        return this.findItems(filters || {}, sort, hydratedFields);
    }
    executeFindOnce(config) {
        const { filters, hydratedFields } = config;
        return this.find(filters, undefined, hydratedFields).then((results) => results.length > 0 ? results[0] : null);
    }
    /**
     * Make will return a generated version default version of the Model
     * @param defaults Default data to initialize the model with
     * @return Model with defaults filled in
     */
    make(defaults) {
        throw new NotImplementedError(`Make ${defaults ? `using ${defaults}` : ""}`);
    }
}

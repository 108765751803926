import { RootState } from "rootReducer";
import { selectCurrentUser } from "appReducers/authReducer";

export const selectCurrentOrganization = (state: RootState) => {
  const currentUser = selectCurrentUser(state);
  if (currentUser) {
    return state.organizations.byId[currentUser.primaryOrganizationId];
  }
  return null;
};

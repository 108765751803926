import { toQueryParam } from "../../handlers/filter";
/**
 * @param filters: array of SDK-style product filters.
 * @return: a proper query string for the supplied filters.
 */
const wheresToQueryParams = (filters) => {
    let params = "";
    if (filters?.where) {
        for (const filter of filters.where) {
            switch (filter.field) {
                case "status": {
                    const value = toQueryParam(filter);
                    params = `${params}&statuses=${value}`;
                    break;
                }
            }
        }
    }
    return params;
};
/**
 * @param filters: array of SDK-style advertisers filters.
 * @param sort: optional. Information on the field and direction of sorting, if any.
 * @return: a proper query string for the supplied filters and sort.
 */
export const toQueryParams = (filters, sort) => {
    let params = wheresToQueryParams(filters);
    if (sort && sort.field && sort.direction) {
        params = `${params}&sortBy=${sort.field}%20${sort.direction}`;
    }
    if (params.length) {
        return `?${params}`;
    }
    return "";
};

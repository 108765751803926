import { FC, MouseEventHandler } from "react";
import { styles } from "./styles";
import * as Icons from "../Icons";

const SelectClearButton: FC<{ onClick: MouseEventHandler }> = ({ onClick }) => {
  return (
    <button
      type="button"
      name="Clear Selection"
      css={[styles.clearButton, styles.iconSizing]}
      onClick={e => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
      tabIndex={-1}
    >
      <Icons.Circled.X />
    </button>
  );
};

export default SelectClearButton;

import axios from "axios";
import { NotImplementedError } from "../../../errors";
import { ObservableHandler } from "../../handlers";
import { Observable } from "rxjs";
import { isServicePage } from "../../handlers/page";
import { buildBody, toQueryParams } from "../utils";
import { ApiVersion } from "../types";
import { parseIdFilter } from "../../handlers/filter";
import ReportsSchedules from "../schedules";
import { validators } from "./validators";
export default class AdvancedExportsTemplates extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "advanced-exports-templates", { atomize: true });
        this.validators = validators;
        this.schedules = new ReportsSchedules(this.sdk);
    }
    getTemplates(filters, sort) {
        const params = toQueryParams(filters, sort);
        const pageSize = filters?.paging?.size || 10;
        const url = `${this.sdk.urls.advancedQueryToolBaseUrl}/v2/datafeeds/templates${params}`;
        return this.cache.promise(url, () => axios
            .post(url, buildBody(filters), {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => {
            let results = res.data;
            if (isServicePage(res)) {
                results = {
                    page: {
                        count: res.paging_info.count,
                        token: res.paging_info.token,
                        size: pageSize
                    },
                    data: res.data
                };
            }
            return results;
        }));
    }
    /**
     *
     * @param id the id of the template to get
     * @returns a promise resolving to the template
     */
    getTemplate(id) {
        return this.cache.promise(id, () => axios
            .get(`${this.sdk.urls.advancedQueryToolBaseUrl}/v2/datafeeds/template/${id}`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => Promise.resolve([res.data.data])));
    }
    findItems(filters, sort) {
        return new Observable((subscriber) => {
            const idFilter = parseIdFilter(filters);
            const promise = idFilter.size === 1
                ? this.getTemplate(idFilter.values().next().value)
                : this.getTemplates(filters, sort);
            promise
                .then((templates) => {
                subscriber.next(templates);
                subscriber.complete();
            })
                .catch((error) => {
                subscriber.error(error);
            });
        });
    }
    saveTemplate(template) {
        return new Promise((resolve, reject) => {
            const validation = this.validate(template);
            if (validation.size) {
                return reject(validation);
            }
            const isUpdatingExistingTemplate = "id" in template && !!template.id;
            let url = `${this.sdk.urls.advancedQueryToolBaseUrl}/${ApiVersion.V2}/datafeeds/template`;
            if (isUpdatingExistingTemplate) {
                url += `/${template.id}`;
            }
            const action = isUpdatingExistingTemplate ? axios.patch : axios.post;
            action(url, template, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(({ data: { data } }) => {
                this.cache.clear();
                resolve(data);
            })
                .catch((error) => {
                reject(error);
            });
        });
    }
    saveItem(template) {
        return new Observable((subscriber) => {
            this.saveTemplate(template)
                .then((templates) => {
                subscriber.next(templates);
                subscriber.complete();
            })
                .catch((e) => subscriber.error(e));
        });
    }
    async deleteItem(id) {
        return new Promise((resolve, reject) => {
            axios
                .delete(`${this.sdk.urls.advancedQueryToolBaseUrl}/${ApiVersion.V2}/datafeeds/template/${id}`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(({ data }) => {
                this.cache.clear();
                resolve(data);
            })
                .catch((error) => {
                reject(error);
            });
        });
    }
    make() {
        throw new NotImplementedError("make");
    }
}

export enum ServeType {
  THIRD_PARTY = "thirdParty",
  SITE_SERVED = "siteServed"
}

export {
  TrackerType,
  KnownCreativeAssetSpecificationId
} from "@madhive/mad-sdk";

export enum AssetDurationThresholds {
  LOWER = 2.0,
  UPPER = 0.1
}

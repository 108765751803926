import { IToastProps } from "@blueprintjs/core";
import { DataManagementPlatform } from "@madhive/mad-sdk";

export const GET_ALL_DMPS_PENDING = "GET_ALL_DMPS_PENDING";
export const GET_ALL_DMPS_FAILURE = "GET_ALL_DMPS_FAILURE";
export const GET_ALL_DMPS_SUCCESS = "GET_ALL_DMPS_SUCCESS";

export interface DmpsState {
  readonly byId: Record<string, DataManagementPlatform>;
  readonly isAllLoading: boolean;
  readonly receivedAllAt: null | number;
}

interface GetAllDmpsPendingAction {
  type: typeof GET_ALL_DMPS_PENDING;
}

interface GetAllDmpsSuccessAction {
  type: typeof GET_ALL_DMPS_SUCCESS;
  payload: DataManagementPlatform[];
  meta: {
    timestamp: number;
  };
}

interface GetAllDmpsFailureAction {
  type: typeof GET_ALL_DMPS_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

export type DmpActionTypes =
  | GetAllDmpsPendingAction
  | GetAllDmpsSuccessAction
  | GetAllDmpsFailureAction;

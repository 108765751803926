import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Stack: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-stack"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path d="M4.87082 6C4.38988 6 4 6.4018 4 6.89744C4 7.39308 4.38988 7.79487 4.87082 7.79487H19.7992C20.2801 7.79487 20.67 7.39308 20.67 6.89744C20.67 6.4018 20.2801 6 19.7992 6H4.87082Z" />
      <path d="M4.87082 11.1026C4.38988 11.1026 4 11.5044 4 12C4 12.4956 4.38988 12.8974 4.87082 12.8974H19.7992C20.2801 12.8974 20.67 12.4956 20.67 12C20.67 11.5044 20.2801 11.1026 19.7992 11.1026H4.87082Z" />
      <path d="M4.87082 16.2051C4.38988 16.2051 4 16.6069 4 17.1026C4 17.5982 4.38988 18 4.87082 18H19.7992C20.2801 18 20.67 17.5982 20.67 17.1026C20.67 16.6069 20.2801 16.2051 19.7992 16.2051H4.87082Z" />
    </svg>
  );
};

export default Stack;

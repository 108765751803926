import { parsePagingQuery, toQueryParam } from "../../../handlers/filter";
import { DealArchiveStatus, DealGroupType, DealMediaType, DealPriceType, DealStatus, DealType, FilterTypes, ServiceDealGroupType, ServiceDealPriceType, ServiceDealType, dealMediaTypesToServiceDealMediaTypes, dealStatusToServiceStatus, dealVideoTypesToServiceDealVideoTypes } from "../../../../types";
import { formatDate } from "../../../../utils";
import { sortToQueryParam } from "./sort";
export const DEFAULT_DEAL_TYPES_FILTER = [
    ServiceDealType.PMP,
    ServiceDealType.PG,
    ServiceDealType.SUPER_TAG
];
const statusParamValue = (incomingStatuses, archiveStatus) => {
    if (!incomingStatuses?.length &&
        (!archiveStatus?.length || archiveStatus === DealArchiveStatus.BOTH)) {
        return "";
    }
    const nonArchivedStatuses = (incomingStatuses && !Array.isArray(incomingStatuses)
        ? [incomingStatuses]
        : incomingStatuses || []).filter((status) => status && status !== DealStatus.ARCHIVED);
    const statuses = [];
    if (archiveStatus === DealArchiveStatus.ARCHIVED) {
        statuses.push(DealStatus.ARCHIVED);
    }
    else {
        statuses.push(...(nonArchivedStatuses?.length
            ? nonArchivedStatuses
            : [
                DealStatus.READY,
                DealStatus.TEST,
                DealStatus.CANCELLED,
                DealStatus.DRAFT,
                DealStatus.PAUSED
            ]));
        if (archiveStatus === DealArchiveStatus.BOTH && !statuses.includes(DealStatus.ARCHIVED)) {
            statuses.push(DealStatus.ARCHIVED);
        }
    }
    if (statuses.length) {
        return toQueryParam({
            field: "status",
            type: FilterTypes.IN,
            value: statuses.map(dealStatusToServiceStatus)
        });
    }
    return "";
};
/**
 * @param wheres: the `where` portion in an SDK filter object.
 * @return: a properly-formatted query parameter string, based on the filters. If there are none, the empty string.
 */
const wheresToQueryParams = (wheres) => {
    let params = "";
    const whereMap = new Map(wheres?.map((filter) => [filter.field, filter]) || []);
    // ensuring the following filters are present while looping through map.
    if (!whereMap.has("id") && !whereMap.has("dealTypes")) {
        whereMap.set("dealTypes", { value: [], type: FilterTypes.IN, field: "dealTypes" });
    }
    if (!whereMap.has("status")) {
        whereMap.set("status", { value: [], type: FilterTypes.IN, field: "status" });
    }
    if (wheres) {
        whereMap.forEach((filter, field) => {
            const valueArr = Array.isArray(filter.value) ? filter.value : [filter.value];
            switch (field) {
                case "search": {
                    const value = toQueryParam(filter);
                    if (value) {
                        params = `${params}&search=${value}`;
                    }
                    break;
                }
                case "groupType": {
                    let value = undefined;
                    switch (valueArr[0]) {
                        case DealGroupType.INVENTORY:
                            value = ServiceDealGroupType.INVENTORY;
                            break;
                        case DealGroupType.GROUP:
                            value = ServiceDealGroupType.GROUP;
                            break;
                    }
                    if (value) {
                        params = `${params}&typeFilter=${value}`;
                    }
                    break;
                }
                case "status": {
                    const archiveStatus = whereMap.get("archiveStatus")?.value;
                    const value = statusParamValue(valueArr.filter((status) => !!status), Array.isArray(archiveStatus) ? archiveStatus[0] : archiveStatus);
                    if (value) {
                        params = `${params}&statuses=${value}`;
                    }
                    break;
                }
                case "id": {
                    const value = valueArr.filter((id) => !!id);
                    if (value.length) {
                        params = `${params}&ids=${toQueryParam({
                            ...filter,
                            value
                        }, { array: true })}`;
                    }
                    break;
                }
                case "dealTypes": {
                    const value = [];
                    for (const dealType of valueArr) {
                        switch (dealType) {
                            case DealType.SUPER_TAG:
                                value.push(ServiceDealType.SUPER_TAG);
                                break;
                            case DealType.PMP:
                                value.push(ServiceDealType.PMP);
                                break;
                            case DealType.PG:
                                value.push(ServiceDealType.PG);
                                break;
                            case DealType.OPEN_MARKET:
                                value.push(ServiceDealType.OPEN_MARKET);
                                break;
                        }
                    }
                    if (!value.length) {
                        value.push(...DEFAULT_DEAL_TYPES_FILTER);
                    }
                    params = `${params}&dealTypes=${toQueryParam({
                        ...filter,
                        value
                    }, { array: true })}`;
                    break;
                }
                case "priceTypes": {
                    const value = [];
                    for (const priceType of valueArr) {
                        switch (priceType) {
                            case DealPriceType.FIXED:
                                value.push(ServiceDealPriceType.FIXED, ServiceDealPriceType.PG);
                                break;
                            case DealPriceType.FLOOR:
                                value.push(ServiceDealPriceType.FLOOR);
                                break;
                        }
                    }
                    if (value.length) {
                        params = `${params}&costFuncs=${toQueryParam({
                            ...filter,
                            value
                        }, { array: true })}`;
                    }
                    break;
                }
                case "mediaTypes": {
                    const value = dealMediaTypesToServiceDealMediaTypes(valueArr);
                    if (value.length) {
                        params = `${params}&mediaTypes=${toQueryParam({
                            ...filter,
                            value
                        }, { array: true })}`;
                    }
                    break;
                }
                case "videoTypes": {
                    const value = dealVideoTypesToServiceDealVideoTypes(valueArr, [DealMediaType.VIDEO]);
                    if (value.length) {
                        params = `${params}&videoTypes=${toQueryParam({
                            ...filter,
                            value
                        }, { array: true })}`;
                    }
                    break;
                }
                case "minDate": {
                    const value = whereMap.get("minDate")?.value?.[0];
                    const formattedValue = value instanceof Date && value.getTime() ? formatDate(value) : undefined;
                    if (formattedValue !== undefined) {
                        params = `${params}&minDate=${formattedValue}`;
                    }
                    break;
                }
                case "maxDate": {
                    const value = whereMap.get("maxDate")?.value?.[0];
                    const formattedValue = value instanceof Date && value.getTime() ? formatDate(value) : undefined;
                    if (formattedValue !== undefined) {
                        params = `${params}&maxDate=${formattedValue}`;
                    }
                    break;
                }
            }
        });
    }
    return params;
};
/**
 * @param filters: array of SDK-style deals filters.
 * @param sort: optional. Information on the field and direction of sorting, if any.
 * @return: a proper query string for the supplied filters.
 */
export const toQueryParams = (filters, sort) => {
    const params = `${wheresToQueryParams(filters.where)}${parsePagingQuery(filters.paging)}${sortToQueryParam(sort)}`;
    return params.length ? `?${params}` : "";
};

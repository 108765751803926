import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const SquareWithZigZag: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, transparent)"
}) => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 66 65"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        stroke={stroke}
        d="M0.500197 42.4707C0.499934 42.4916 0.499934 42.5126 0.500197 42.5335V49.1667C0.500197 57.9107 7.58948 65 16.3335 65H49.6669C58.4109 65 65.5002 57.9107 65.5002 49.1667V22.529C65.5005 22.5083 65.5005 22.4875 65.5002 22.4667V15.8333C65.5002 7.08929 58.4109 0 49.6669 0H16.3335C7.58948 0 0.500197 7.08929 0.500197 15.8333V42.4707ZM5.5002 15.8333C5.5002 9.85071 10.3509 5 16.3335 5H49.6669C55.6495 5 60.5002 9.85071 60.5002 15.8333V21.2134L39.9371 35.9014L28.1013 24.0656C27.2371 23.2014 25.8749 23.0887 24.8804 23.799L5.5002 37.642V15.8333ZM60.5002 27.358V49.1667C60.5002 55.1493 55.6495 60 49.6669 60H16.3335C10.3509 60 5.5002 55.1493 5.5002 49.1667V43.7865L26.0633 29.0986L37.8991 40.9344C38.7632 41.7986 40.1255 41.9113 41.12 41.201L60.5002 27.358Z"
        fill={fill}
      />
    </svg>
  );
};

export default SquareWithZigZag;

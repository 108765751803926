import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const ReticleCrossed: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-reticle-crossed"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.25a.75.75 0 0 1 .75.75v1.289a7.705 7.705 0 0 1 4.73 2.23 7.705 7.705 0 0 1 2.23 4.731H21a.75.75 0 0 1 0 1.5h-1.291a7.767 7.767 0 0 1-.484 2.044.75.75 0 0 1-1.4-.54c.188-.487.313-.992.375-1.504H17a.75.75 0 0 1 0-1.5h1.202a6.211 6.211 0 0 0-1.783-3.67 6.212 6.212 0 0 0-3.669-1.783V7a.75.75 0 0 1-1.5 0V5.799a6.268 6.268 0 0 0-1.505.375.75.75 0 1 1-.54-1.4 7.769 7.769 0 0 1 2.045-.484V3a.75.75 0 0 1 .75-.75ZM3.47 3.47a.75.75 0 0 1 1.06 0l3.028 3.027a.756.756 0 0 1 .046.046L20.53 19.47a.75.75 0 1 1-1.06 1.06l-2.545-2.545a7.715 7.715 0 0 1-4.175 1.73V21a.75.75 0 0 1-1.5 0v-1.286a7.716 7.716 0 0 1-4.73-2.234 7.719 7.719 0 0 1-2.233-4.73H3a.75.75 0 0 1 0-1.5h1.287a7.715 7.715 0 0 1 1.728-4.174L3.47 4.53a.75.75 0 0 1 0-1.06Zm3.612 4.673a6.22 6.22 0 0 0-1.287 3.107H7a.75.75 0 0 1 0 1.5H5.795a6.224 6.224 0 0 0 1.786 3.67 6.222 6.222 0 0 0 3.669 1.786V17a.75.75 0 0 1 1.5 0v1.206a6.22 6.22 0 0 0 3.108-1.287L7.082 8.143Z"
      />
    </svg>
  );
};

export default ReticleCrossed;

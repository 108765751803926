export const isTruthy = (x) => !!x;
export const sum = (nums) => nums.reduce((acc, cur) => acc + cur, 0);
export const deleteKeysWithUndefinedValues = (obj) => {
    return Object.entries(obj).reduce((acc, [key, value]) => {
        if (value === undefined) {
            delete acc[key];
        }
        return acc;
    }, { ...obj });
};
export const chunk = (arr, size) => size > 0
    ? arr.reduce((chunks, el, idx) => {
        if (idx === 0 || idx % size === 0) {
            const start = chunks.length * size;
            const end = (chunks.length + 1) * size;
            chunks.push(arr.slice(start, end));
        }
        return chunks;
    }, [])
    : [arr];

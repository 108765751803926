import { cleanStringField, camelToSnakeCase, snakeToCamelCase, cleanAnyFieldToString } from "../../utils";
const parseMetadataValue = (value) => {
    let newValue = value;
    if (typeof newValue !== "string") {
        newValue = cleanAnyFieldToString(value);
    }
    newValue = cleanStringField(newValue);
    if (newValue === "true") {
        newValue = true;
    }
    return newValue;
};
export const metadataToExternalMetadata = (metadata) => {
    const converted = {};
    if (!metadata) {
        return converted;
    }
    for (const entry of Object.entries(metadata)) {
        const [key, value] = entry;
        converted[camelToSnakeCase(key)] =
            typeof value === "string" ? cleanStringField(value, "") : value;
    }
    return converted;
};
/**
 * This method converts all external metadata to the "correct" format
 *
 * @param extMetas external metadata to convert
 * @returns ExternalMetadata formatted based on the given meta
 */
export const serviceExternalMetaToExternalMeta = (extMetas) => {
    const converted = {};
    for (const entry of Object.entries(extMetas)) {
        const [key, value] = entry;
        converted[snakeToCamelCase(key)] = parseMetadataValue(value);
    }
    return converted;
};

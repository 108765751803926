/* eslint-disable no-param-reassign */
import { FrequencyCapSettings } from "types";
import { ApplicationSettings } from "types/ui/application-settings";

const DEFAULT_FREQUENCY_CAP: FrequencyCapSettings = {
  hasFrequencyCap: true,
  dailyFrequencyCap: 6,
  hourlyFrequencyCap: 2
};

const OLD_DEFAULT_FREQUENCY_CAP: FrequencyCapSettings = {
  hasFrequencyCap: false,
  dailyFrequencyCap: 5,
  hourlyFrequencyCap: 1
};

export const DEFAULT_APPLICATION_SETTINGS: ApplicationSettings = {
  manageCampaignsDefaultFrequencySettings: DEFAULT_FREQUENCY_CAP
};

export const OLD_DEFAULT_APPLICATION_SETTINGS: ApplicationSettings = {
  manageCampaignsDefaultFrequencySettings: OLD_DEFAULT_FREQUENCY_CAP
};

import { KnownHostNames } from "lib/constants/config";
import { SegmentType } from "lib/constants/forecast";
import { DayOfTheWeek } from "lib/constants/misc";
import {
  FirestoreNotificationStatuses,
  NotificationActionCode,
  NotificationCategory
} from "lib/utils/notifications";
import {
  FirestoreDate,
  Recipe,
  KnownOrganizationNames,
  UserType,
  ViewId
} from "@madhive/mad-sdk";
import { Action } from "redux";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { RootState } from "rootReducer";
import { SerializedStyles } from "@emotion/react";
import { DashboardDeliveryMetrics } from "./features/Dashboard/Heatmap/types";
import { PlanStatus } from "./features/OOH/Planning/generated-types";

export interface SmithersNotificationActionRaw {
  buttonText: string;
  destinationUrl?: string;
  downloadTitle?: string;
}

export interface SmithersNotificationActionFormatted
  extends SmithersNotificationActionRaw {
  actionId: NotificationActionCode;
}

export type SmithersNotificationActionsRaw = Record<
  string,
  SmithersNotificationActionRaw
>;

export type SmithersNotificationActionsFormatted =
  SmithersNotificationActionFormatted[];

export interface SmithersNotificationRaw {
  status: FirestoreNotificationStatuses; // guaranteed field from Firestore query
  recipientId: string; // guaranteed field from Firestore query
  authorId?: string;
  title?: string;
  actions?: SmithersNotificationActionsRaw;
  category?: NotificationCategory;
  content?: string;
  createdDate?: FirestoreDate;
  lastUpdatedDate?: FirestoreDate;
  read?: boolean;
  meta?: Record<any, any>;
}

export interface OOHPlanMeta {
  id: number;
  status: PlanStatus;
}

export interface NotificationMetadata {
  // PK: This was the only "always" guaranteed existing property TODO: Make sure this is always populated,
  // seems like there's an issue with notificationListener.tsx
  submittedDate?: FirestoreDate;
  // PK: Path isn't always supplied. TODO: Identify if all notif. actions should have paths
  path?: string;
  filterEndDate?: FirestoreDate;
  filterStartDate?: FirestoreDate;
  reportType?: string;
  oohPlanMeta?: OOHPlanMeta;
}

export interface SmithersNotificationFormatted {
  status: FirestoreNotificationStatuses; // guaranteed field from Firestore query
  recipientId: string; // guaranteed field from Firestore query
  id: string; // guaranteed field from Firestore query
  authorId: string;
  title: string;
  content: string;
  category: NotificationCategory;
  actions: SmithersNotificationActionsFormatted;
  createdDate?: Date;
  lastUpdatedDate?: Date;
  read: boolean;
  meta: NotificationMetadata;
}

export interface PostgresUser {
  admin: boolean;
  developer: boolean;
  team_id?: string;
  advertiser_id?: string;
  agency_id?: string;
  id: string;
  email: string;
  name?: string;
  parent: string;
  owners: string[];
  updated: number | null;
  // PK TODO: Probably need to update this type
  settings?: { homepage: ViewId };
  type: UserType;
  views: ViewId[];
  last_login: Date | null;
  created: Date | null;
}

export interface StyleProps<T extends string | SerializedStyles = string> {
  classes: {
    [key: string]: T;
  };
}

export type OrganizationName = KnownOrganizationNames;

export type ProductionHostName =
  | KnownHostNames.PROD_MADHIVE_DEFAULT
  | KnownHostNames.PROD_SCRIPPS_LEGACY
  | KnownHostNames.PROD_SCRIPPS_OCTANE
  | KnownHostNames.PROD_PREMION_LEGACY
  | KnownHostNames.PROD_PREMION_CONSOLE
  | KnownHostNames.PROD_BOBMILLS
  | KnownHostNames.PROD_RUNNINGMATE_LEGACY
  | KnownHostNames.PROD_RUNNINGMATE_PLATFORM
  | KnownHostNames.PROD_BEACHFRONT
  | KnownHostNames.PROD_MEDIAMATH
  | KnownHostNames.PROD_FUTURESMEDIA
  | KnownHostNames.PROD_BIG_SANDY_PROMOTIONS_DEFAULT
  | KnownHostNames.PROD_BIG_SANDY_PROMOTIONS_OCTILLION
  | KnownHostNames.PROD_ASSOCIATED_VOLUME_BUYERS_DEFAULT
  | KnownHostNames.PROD_ASSOCIATED_VOLUME_BUYERS_OCTILLION
  | KnownHostNames.PROD_BTC_MEDIA
  | KnownHostNames.PROD_FOX
  | KnownHostNames.PROD_NEWSY
  | KnownHostNames.PROD_NEWSY_WITH_PORTAL_PREFIX
  | KnownHostNames.PROD_TALMONT;

export type StagingHostName =
  | KnownHostNames.STAGING_DEFAULT
  | KnownHostNames.STAGING_SCRIPPS
  | KnownHostNames.STAGING_PREMION;

export type DevHostName =
  | KnownHostNames.INTERNAL_QA
  | KnownHostNames.INTERNAL_HACK;

export type DemoHostName =
  | KnownHostNames.DEMO_DEFAULT
  | KnownHostNames.DEMO_LEGACY;

export type KnownHostName =
  | ProductionHostName
  | StagingHostName
  | DevHostName
  | DemoHostName;

export type DateString = string;

// Alias: a number between 0 and 1;
export type Percent = number;

// Custom utility type for objects that fulfil an interface except for certain properties.
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface Column {
  visible: boolean;
  order: number;
  sort: string;
}

export interface FormattedData {
  label: string;
  value: string;
}

export interface FormattedDataWithMaybeCategory extends FormattedData {
  category?: string;
}

export interface FormattedDataWithCategory extends FormattedData {
  category: string;
}

export type { DesignatedMarketingArea } from "@madhive/mad-sdk";

export type ProductionViews = Record<
  string,
  { production: boolean; title: string }
>;

export enum ServingStatus {
  DRAFT = 100, // in-progress workflow
  READY = 200, // eligible to serve / serving
  PAUSED = 300, // temporarily stopped serving
  CANCELLED = 400, // irrevocably stopped
  ARCHIVED = 500 // irrevocably stop (delete) AND hide from front-end
}

// TODO: MAD-2546 - an AudienceSegment is a recipe
// but it is an old version, that is relatively deprecated
export interface AudienceSegment {
  id: string;
  label: string;
  type: SegmentType;
  price: number;
  // PK TODO: This needs to be populated, but TBD since it's a really complex task for backend
  // and we aren't getting data for this yet
  // householdCount: number;
  status: ServingStatus;
  childSegmentIds?: string[];
}

// TODO: MAD-1708 - delete this once recipe sdk lands
// TODO: MAD-2524 - Audience is a recipe
// but it is a new version version, that is relatively in use
export interface Audience extends Omit<Recipe, "name" | "householdReach"> {
  label: string;
  maxPrice: number;
  minPrice: number;
  householdCount: number;
}

export interface ChartDataPoint {
  label: string;
  value: number;
}

export interface ChartDataPointDualAxis {
  label: string;
  value: number;
  lineValue?: number;
}

// added this for CSV (hour of the week) because we want to derive days and hours is an integer
export interface ChartDataPointCSV {
  label: string | number;
  value: number;
}

export interface ChartDataPointGrouped {
  label: string;
  primary: number;
  secondary: number;
  lineData?: number;
}

export type DaypartsArray = [
  {
    day: DayOfTheWeek.SUNDAY;
    hours: number[];
  },
  {
    day: DayOfTheWeek.MONDAY;
    hours: number[];
  },
  {
    day: DayOfTheWeek.TUESDAY;
    hours: number[];
  },
  {
    day: DayOfTheWeek.WEDNESDAY;
    hours: number[];
  },
  {
    day: DayOfTheWeek.THURSDAY;
    hours: number[];
  },
  {
    day: DayOfTheWeek.FRIDAY;
    hours: number[];
  },
  {
    day: DayOfTheWeek.SATURDAY;
    hours: number[];
  }
];

export interface DonutChartData {
  value: number;
  color: string;
  name: string;
}

export interface ToggleableColumn {
  id: string;
  label: string;
}

export type DashboardDeliveryByGeo = Record<
  DashboardDeliveryMetrics,
  number
> & { regionCode: string; isBelowMetricThreshold?: boolean };

export interface DistributionPercent {
  distributionPct: number;
}

export type DashboardDeliveryByGeoWithPct = DashboardDeliveryByGeo &
  DistributionPercent;

export interface HermesDeliveryDatumFormat {
  im_total: number;
  unique_ips: number;
  postal_code?: string;
  dma_code?: string;
  state_code?: string;
  district_code?: string;
}

export interface ZipCodesDatumFormat extends HermesDeliveryDatumFormat {
  postal_code: string;
}

export interface DashboardFilters {
  productFilter?: FormattedDataWithMaybeCategory[];
  advertiserFilter?: FormattedDataWithMaybeCategory[];
  stationFilter?: FormattedDataWithMaybeCategory[];
  teamFilter?: FormattedDataWithMaybeCategory;
  campaignFilter?: FormattedDataWithMaybeCategory[];
  lineItemFilter?: FormattedDataWithMaybeCategory[];
  adbookFilter?: FormattedDataWithMaybeCategory;
  agencyFilter?: FormattedDataWithMaybeCategory[];
  brandFilter?: FormattedDataWithMaybeCategory[];
  estimateFilter?: FormattedDataWithMaybeCategory[];
  mediaTypeFilter?: FormattedDataWithMaybeCategory[];
  dmaFilter?: FormattedDataWithMaybeCategory[];
  advertiserCategoryFilter?: FormattedDataWithMaybeCategory[];
  creativeCategoryFilter?: FormattedDataWithMaybeCategory[];
  estimateCodeFilter?: FormattedDataWithMaybeCategory[];
  productCodeFilter?: FormattedDataWithMaybeCategory[];
}

export interface LatLngLiteral {
  lat: number;
  lng: number;
}

// [lng, lat]
export type LngLatGeoJson = [number, number];

export interface ZipCodeWithCoordinates extends LatLngLiteral {
  zipCode: string;
}

export enum ZipFinderCensusData {
  TOTAL_POP = "totalPopulation",
  TOTAL_HH = "totalHouseholds",
  AVERAGE_INCOME = "averageIncome"
}

export type ZipCodeCensusDataFormatted = {
  cityName: string;
  stateAbbreviation: string;
  zipCode: string;
} & Record<ZipFinderCensusData, number>;

export interface FormattedPolygon {
  zipcode: string;
  latitude: number;
  longitude: number;
  zipcodePoints: Array<{
    lat: number;
    lng: number;
  }>;
}

export interface PotentiallyLoadingOrNullResource<T> {
  data: T | null;
  isLoading: boolean;
}

export interface FrequencyCapSettings {
  hasFrequencyCap: boolean;
  dailyFrequencyCap: number;
  hourlyFrequencyCap: number;
}
export interface WinRateSettings {
  winRateMultiplier: number;
}

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type ThunkDispatchWithReturnValue = ThunkDispatch<
  RootState,
  undefined,
  Action<string>
>;

export enum PaceStrategy {
  IMPRESSIONS = "impressions",
  BUDGET = "budget"
}

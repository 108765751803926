import { Callout, InputGroup, Intent, Icon } from "@blueprintjs/core";
import { madSDK } from "lib/sdk";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "appReducers/authReducer";
import { LoginLogo } from "components/LoginLogo";
import SmithersCard from "components/Reusable/SmithersCard";
import Layout from "components/Reusable/Layout";
import { FormGroup } from "frontier/lib/components";
import { Button, ButtonColor, ButtonVariant } from "madhive/components";
import { RootState } from "rootReducer";
import "./Login.scss";
import { ensureUserCanLoginFromThisAddress } from "lib/utils/env";
import * as Sentry from "@sentry/browser";
import { FC, MouseEvent, useState, useEffect, ChangeEventHandler } from "react";
import IconContainer from "./IconContainer";

interface UserEnteredCredentials {
  email: string;
  password: string;
}

interface Props {
  signInFn?: (email: string, password: string) => Promise<void>;
}

// eslint-disable-next-line import/prefer-default-export
export const LoginView: FC<Props> = ({ signInFn }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const selectInitializationError = (state: RootState) =>
    state.auth.initializationError;
  const userInitializationError = useSelector(selectInitializationError);

  const [loading, setLoading] = useState<boolean>(false);
  const [form, updateForm] = useState<UserEnteredCredentials>({
    email: "",
    password: ""
  });
  const [error, setError] = useState<string>("");

  useEffect(() => {
    if (loading) {
      setLoading(!userInitializationError);
    }
  }, [userInitializationError]);

  if (isLoggedIn) {
    return null;
  }

  const handleLoginAttempt = async (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();

    setError("");
    setLoading(true);
    const { email, password } = form;

    const attemptLogin = async (userEmail: string, userPassword: string) => {
      if (signInFn) {
        await signInFn(userEmail, userPassword);
      } else {
        await madSDK.authentication.auth(
          userEmail,
          userPassword,
          ensureUserCanLoginFromThisAddress
        );
      }
    };

    await attemptLogin(email.trim(), password)
      .catch(() => attemptLogin(email.trim(), password.trim()))
      .catch(() => {
        setError(
          "Login failed. Please check your username and password and try again."
        );
        setLoading(false);
        Sentry.captureMessage("Invalid username or password", {
          user: { email },
          extra: { email }
        });
      });
  };

  const updateField =
    (key: keyof UserEnteredCredentials): ChangeEventHandler<HTMLInputElement> =>
    e => {
      e.persist();

      if (!e.target) {
        return;
      }

      updateForm({
        ...form,
        [key]: e.target.value
      });
      setError("");
    };

  return (
    <Layout fillPage narrowWidth>
      <SmithersCard.Card
        sharpCorners
        className="login-box"
        data-testid="login-view"
      >
        <SmithersCard.Body className="card-body">
          <LoginLogo />

          <form data-heap-redact-attributes="value">
            <fieldset
              className="fieldset"
              disabled={loading}
              aria-busy={loading}
              data-testid="login-fieldset"
            >
              <FormGroup
                label="Email"
                labelFor="email"
                className="input-wrapper"
              >
                <InputGroup
                  required
                  id="email"
                  name="email"
                  type="email"
                  value={form.email}
                  onChange={updateField("email")}
                  rightElement={
                    <IconContainer>
                      <Icon icon="envelope" />
                    </IconContainer>
                  }
                  large
                />
              </FormGroup>
              <FormGroup
                label="Password"
                labelFor="password"
                className="input-wrapper"
              >
                <InputGroup
                  required
                  id="password"
                  name="password"
                  type="password"
                  value={form.password}
                  onChange={updateField("password")}
                  rightElement={
                    <IconContainer>
                      <Icon icon="lock" />
                    </IconContainer>
                  }
                  large
                />
              </FormGroup>
              <Button
                loading={loading}
                id="login-button"
                data-testid="login-button"
                variant={ButtonVariant.CONTAINED}
                type="submit"
                fill
                color={ButtonColor.PRIMARY}
                onClick={handleLoginAttempt}
              >
                Log In
              </Button>
              {error ? (
                <Callout
                  intent={Intent.DANGER}
                  data-testid="error-text"
                  className="success-callout"
                >
                  {error}
                </Callout>
              ) : null}
            </fieldset>
          </form>
          <span className="dma-disclaimer">
            The DMA information is provided pursuant to a license from The
            Nielsen Company. DMA® is a registered service mark of The Nielsen
            Company.
          </span>
        </SmithersCard.Body>
      </SmithersCard.Card>
    </Layout>
  );
};

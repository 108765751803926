import { useCallback } from "react";
import { useSingularMadHooksUtils } from "./singularUtils";
import { invalidateCollection } from "../rxjs";
/**
 * Deletes a single piece of data
 * @param collection Collection the data belongs to
 * @param id id of the data to delete
 * @returns a Promise
 */
export const useDelete = (collection, id, options) => {
    const { observer, handler } = useSingularMadHooksUtils(collection, id);
    const { dependentCollections } = options || {};
    const deleteFunction = useCallback(() => new Promise((resolve, reject) => {
        if (!id) {
            reject(new Error(`${collection}: id must be defined to delete`));
            return;
        }
        const current = observer.value;
        observer.next({
            ...current,
            isSaving: true
        });
        handler
            .delete(id)
            .then((value) => {
            observer.next({
                ...current,
                isDeleted: true,
                isSaving: false
            });
            invalidateCollection(collection);
            for (const dependentCollection of dependentCollections || []) {
                invalidateCollection(dependentCollection);
            }
            resolve(value);
        })
            .catch((e) => {
            observer.next({
                ...current,
                isSaving: false,
                error: e
            });
            reject(e);
        });
    }), [id]);
    return deleteFunction;
};

import { css, keyframes } from "@emotion/react";
import { kitText } from "../../../../styles";

export const transitions = {
  next: keyframes`
    from, to {
      transform: translate(0, 0);
      opacity: 1;
    }
    50% {
      transform: translate(-50%, 0);
      opacity: 0;
    }
    50.01% {
      transform: translate(50%, 0);
      opacity: 0;
    }
  `,
  prev: keyframes`
    from, to {
      transform: translate(0, 0);
      opacity: 1;
    }
    40% {
      transform: translate(50%, 0);
      opacity: 0;
    }
    50% {
      transform: translate(-50%, 0);
      opacity: 0;
    }
    80% {
      opacity: 0;
    }
  `
};
export const styles = {
  base: css`
    display: flex;
  `,
  calendar: {
    base: css`
      padding: var(--spacing-16);
      gap: var(--spacing-32);
      box-shadow: var(--shadow-1);
      border-radius: var(--spacing-8);
      background-color: var(--color-background-primary);
      display: flex;
    `,
    baseDatePresets: css`
      border-radius: 0 var(--spacing-8) var(--spacing-8) 0;
    `
  },
  datePresetDropdown: css`
    box-shadow: var(--shadow-3);
    border-radius: var(--spacing-8) 0 0 var(--spacing-8);
    border-right: 1px solid var(--color-border-primary);
    background: var(--color-background-primary);
    display: flex;
    flex-direction: column;
    gap: var(--spacing-4);
    overflow-y: auto;
  `,
  header: {
    wrapper: css`
      display: flex;
      flex-direction: column;
      gap: var(--spacing-8);
      padding-bottom: 2px;
    `,
    base: css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      ${kitText.style.bold}
    `,
    nextPrev: css`
      width: var(--spacing-24);
      height: var(--spacing-24);
    `,
    daysOfWeek: css`
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      > span {
        display: flex;
        justify-content: center;
        align-items: center;
        width: var(--spacing-32);
        color: var(--color-tertiary);
      }
    `
  },
  month: {
    base: css`
      display: flex;
      flex-direction: column;
      gap: var(--spacing-4);
      overflow: hidden;
      width: calc(var(--spacing-32) * 7);
      .kit-InputTime {
        margin: 0 auto;
      }
    `,
    grid: css`
      display: flex;
      flex-direction: column;
      gap: 2px;
      height: calc(var(--spacing-32) * 6 + 2px * 5);
    `,
    transition: {
      next: css`
        .month-year,
        .subKit-CalendarMonth {
          animation: ${keyframes`
            from, to {
              transform: translate(0, 0);
              opacity: 1;
            }
            50% {
              transform: translate(-50%, 0);
              opacity: 0;
            }
            50.01% {
              transform: translate(50%, 0);
              opacity: 0;
            }`} var(--month-transition-speed) ease-in-out;
        }
      `,
      prev: css`
        .month-year,
        .subKit-CalendarMonth {
          animation: ${keyframes`
            from, to {
              transform: translate(0, 0);
              opacity: 1;
            }
            40% {
              transform: translate(50%, 0);
              opacity: 0;
            }
            50% {
              transform: translate(-50%, 0);
              opacity: 0;
            }
            80% {
              opacity: 0;
            }`} var(--month-transition-speed) ease-in-out;
        }
      `
    },
    transitionPrev: css``
  },
  week: css`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  day: {
    base: css`
      ${kitText.style.monospace}
      width: var(--spacing-32);
      height: var(--spacing-32);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      background-color: var(--color-background-primary);
      border: 1px solid transparent;
      border-radius: var(--spacing-4);
      &:focus {
        outline: none;
      }
      &:not(:disabled) {
        cursor: pointer;
        &:hover,
        &:focus {
          border-color: var(--color-border-primary);
        }
      }
    `,
    selected: css`
      background-color: var(--color-splash-primary);
      color: var(--color-contrast);
      &:hover:not(:disabled) {
        background-color: var(--color-splash-primary) !important;
      }
      &:focus:not(:disabled) {
        background-color: var(--color-splash-tertiary);
      }
      &:disabled {
        background-color: var(--color-background-primary);
        color: var(--color-primary);
        border-color: var(--color-border-primary);
      }
    `,
    inRange: css`
      background-color: var(--color-background-tertiary);
      &:not(:hover) {
        border-radius: 0;
      }
    `,
    disabled: css`
      color: var(text-color-tertiary);
      background-color: var(--color-background-primary);
    `,
    empty: css`
      opacity: 0;
      mouse-events: none;
      background-color: var(--color-background-primary);
    `,
    error: css`
      color: var(--color-danger) !important;
      border-color: var(--color-danger) !important;
      background-color: var(--color-background-primary);
    `
  }
};

export class CategoriesFetchFailed extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CategoriesFetchFailed);
        }
        this.name = "CategoriesFetchFailed";
        this.message = "Failed to fetch the IAB categories. Please try again at a later time.";
    }
}

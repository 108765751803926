import { deriveErrorResponse } from "../../utils/validation";
export class OrganizationFetchFailed extends Error {
    constructor(baseError) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, OrganizationFetchFailed);
        }
        const apiErrorMessages = deriveErrorResponse(baseError);
        const detailMessage = apiErrorMessages.length > 0 ? `Details: ${apiErrorMessages.join(",")}` : "Please try again.";
        this.name = "OrganizationFetchFailed";
        this.message = `Failed to fetch organization details for the current user. ${detailMessage}`;
    }
}
export class OrganizationSaveFailure extends Error {
    constructor(orgName, baseError) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, OrganizationSaveFailure);
        }
        const apiErrorMessages = deriveErrorResponse(baseError);
        const detailMessage = apiErrorMessages.length > 0 ? `Details: ${apiErrorMessages.join(",")}` : "Please try again.";
        this.name = "OrganizationSaveFailure";
        this.message = `Failed to save organization "${orgName}". ${detailMessage}`;
    }
}
export class OrganizationImageNoFileSaveFailure extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, OrganizationImageNoFileSaveFailure);
        }
        this.name = "OrganizationImageSaveFailure";
        this.message = `Failed to save image because no file data given.`;
    }
}

import { AdbookCampaign } from "campaign-types";

export interface AdbookCampaignsState {
  readonly byId: Record<string, AdbookCampaign>;
  readonly isLoading: boolean;
  readonly isLoaded: boolean;
  readonly error: string | null;
  readonly firstFetchFinished: boolean;
}

export const GET_ADBOOK_CAMPAIGNS_PENDING = "GET_ADBOOK_CAMPAIGNS_PENDING";
export const GET_ADBOOK_CAMPAIGNS_SUCCESS = "GET_ADBOOK_CAMPAIGNS_SUCCESS";
export const GET_ADBOOK_CAMPAIGNS_FAILURE = "GET_ADBOOK_CAMPAIGNS_FAILURE";

interface GetAdbookCampaignsPendingAction {
  type: typeof GET_ADBOOK_CAMPAIGNS_PENDING;
}

interface GetAdbookCampaignsSuccessAction {
  type: typeof GET_ADBOOK_CAMPAIGNS_SUCCESS;
  payload: AdbookCampaign[];
}

interface GetAdbookCampaignsFailureAction {
  type: typeof GET_ADBOOK_CAMPAIGNS_FAILURE;
  meta: {
    error: string;
  };
}

export type AdbookCampaignsActionTypes =
  | GetAdbookCampaignsPendingAction
  | GetAdbookCampaignsSuccessAction
  | GetAdbookCampaignsFailureAction;

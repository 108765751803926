import axios from "axios";
import { Handler } from "../handlers";
import { pixelBaseToServicePixel, pixelToServicePixel, servicePixelToPixel } from "../../models/pixel";
import Histories from "./histories";
import { parseIdFilter } from "../handlers/filter";
import { NotImplementedError } from "../../errors";
import { ObjType, ServiceStatus } from "../../types";
import { PixelIdCreationFailed, PixelSaveFailed, PixelsFetchFailed } from "./errors";
/**
 * Handles pixels collections find and manangement.
 */
class Pixels extends Handler {
    constructor(sdk) {
        super(sdk, "pixels", { atomize: true });
        this.histories = new Histories(sdk);
    }
    async findItems(filters) {
        const url = `${this.sdk.urls.baseAPIUrl}/pixels`;
        const pixels = await this.cache.promise(url, () => axios
            .get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async ({ data: res }) => await Promise.all(res.data.map(async (pixel) => await servicePixelToPixel(pixel, this.sdk.cryptography))))
            .catch(() => {
            throw new PixelsFetchFailed();
        }));
        const filterIds = parseIdFilter(filters);
        return filterIds.size === 0 ? pixels : pixels.filter((pixel) => filterIds.has(pixel.id));
    }
    /**
     * Saves a pixel. Determines whether to call create or update based on presence of an id.
     * @param pixel: the pixel to save.
     */
    async saveItem(pixel) {
        if ("id" in pixel) {
            return this.update(pixel);
        }
        return this.create(pixel);
    }
    async update(pixel) {
        return axios
            .post(`${this.sdk.urls.baseAPIUrl}/pixel`, pixelToServicePixel(pixel), {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => {
            return servicePixelToPixel(res.data, this.sdk.cryptography);
        })
            .catch(() => {
            throw new PixelSaveFailed();
        });
    }
    async create(pixel) {
        return this.sdk.cryptography.mintKey(ObjType.BEACON).then((pixelId) => {
            if (!pixelId) {
                throw new PixelIdCreationFailed();
            }
            const status = pixel.status || ServiceStatus.READY;
            const pixelBaseWithId = { id: pixelId, ...pixel, status };
            return axios
                .post(`${this.sdk.urls.baseAPIUrl}/pixel`, pixelBaseToServicePixel(pixelBaseWithId), {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(({ data: res }) => {
                return servicePixelToPixel(res.data, this.sdk.cryptography);
            })
                .catch(() => {
                throw new PixelSaveFailed();
            });
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}
export default Pixels;

import {
  HostNamesDefaults,
  ORG_ID_TO_HOSTNAMES
} from "lib/constants/organizations";
import { User } from "@madhive/mad-sdk";
import { NODE_ENV } from "lib/viteEnvConfig";

export const hostname = window.location.hostname;

export const isLocalhost = Boolean(
  hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

export const isTesthost = Boolean(
  NODE_ENV === "test" ||
    hostname.includes("-ue.a.run.app") ||
    hostname.includes("madhive-testing")
);

export const isSandboxHost = Boolean(
  hostname.match(/pr-[\d]+---smithers-[\w]+-[\w]+\.a\.run\.app/)
);

const isProperHostname = (user: User) => {
  const validHostnames = ORG_ID_TO_HOSTNAMES[user.primaryOrganizationId];
  return validHostnames.includes(hostname);
};

export function ensureUserCanLoginFromThisAddress(user: User) {
  if (
    /^preview\..*@madhive\.com$/.test(user.email) &&
    !isSandboxHost &&
    !isLocalhost
  ) {
    return false;
  }

  if (isLocalhost || isTesthost || isSandboxHost) {
    return true;
  }

  if ((HostNamesDefaults as string[]).includes(hostname)) {
    return true;
  }

  return isProperHostname(user);
}

import { NewNewYorkHumanReadableStatusCode, OrganizationType, DayOfTheWeek, PacingStatus, PacingType, State, ServiceStatus, URL_REGEX, URL_REGEX_WITH_REQUIRED_PROTOCOL_OR_PROTO_TAG } from "../types";
export * from "./csv";
export * from "./dates";
export * from "./dimensions";
export * from "./download";
export * from "./format";
export * from "./fp";
export * from "./frequency";
export * from "./general";
export * from "./geography";
export * from "./observable";
export * from "./strings";
export * from "./validation";
export * from "./dayparts";
/**
 * @param object: an object to get a value from.
 * @param path: a path within the object, leading to the value you want. It is dot-separated. Example: "user.meta.nickname".
 * @return: the value from the object at that path, or undefined if nothing exists within the object at that path.
 */
export const objectLookup = (object, path) => {
    if (!path || typeof object !== "object" || !object) {
        return undefined;
    }
    const depth = path.split(".");
    let result = object[depth[0]];
    if (result !== undefined) {
        for (let i = 1; i < depth.length; i++) {
            result = typeof result === "object" ? result[depth[i]] : undefined;
            if (result === undefined) {
                break;
            }
        }
    }
    return result;
};
/**
 * Helper to take a date and return unix timestamp
 * @param date Date to be converted to unix timestamp. null will return current time
 */
export const convertTimeToUnixTimestamp = (date) => {
    // If date is null return current date in unix timestamp
    if (!date) {
        return Math.round(new Date().getTime() / 1000);
    }
    return Math.round(new Date(date).getTime() / 1000);
};
/**
 * Helper to handle NewNewYork Api Responses (verify the data)
 * @param rawAxiosResponse A response from a NewNewYork API call.
 */
export const parseNewNewYorkApiResponse = (rawAxiosResponse) => {
    if (!rawAxiosResponse || typeof rawAxiosResponse !== "object") {
        throw Error(`Empty response received from API.`);
    }
    const rawNewNewYorkResponse = rawAxiosResponse.data;
    if (!rawNewNewYorkResponse || typeof rawNewNewYorkResponse !== "object") {
        throw Error(`API response payload received in invalid structure.`);
    }
    const { status: nnyHumanReadableStatus } = rawNewNewYorkResponse;
    const formattedNewNewYorkResponse = {
        ...rawNewNewYorkResponse,
        pagingInfo: rawNewNewYorkResponse.paging_info,
        visibleError: !!rawNewNewYorkResponse.visible_error
    };
    /**
     * If NewNewYork API returns an error, we need to allow consumers of this utility function the flexibility to handle
     * an error in different ways. For completeness, we should bubble up:
     *   1) A default error message
     *   2) The formatted NNY payload from the response
     *   3) HTTP status code corresponding to the error
     *
     * These three data points should allow consumers to either show the NNY human-readable error messages directly,
     * show a custom error message, or fall back to a default message.
     */
    if (nnyHumanReadableStatus !== NewNewYorkHumanReadableStatusCode.OK) {
        throw {
            message: "Failed to fetch data.",
            formattedNewNewYorkResponse,
            httpStatus: rawAxiosResponse.status
        };
    }
    return formattedNewNewYorkResponse;
};
export const isAdvertiser = (user) => {
    if (user &&
        user.userPrimaryOrganizationType &&
        user.userPrimaryOrganizationType === OrganizationType.ADVERTISER) {
        return true;
    }
    return false;
};
export const isNotAdvertiser = (user) => {
    if (user &&
        user.userPrimaryOrganizationType &&
        user.userPrimaryOrganizationType !== OrganizationType.ADVERTISER) {
        return true;
    }
    return false;
};
export const removeWhitespace = (str) => {
    return str.replace(/\s/g, "");
};
export const validateUrl = (url) => {
    return URL_REGEX.test(url);
};
// Need to account for {proto} in url if replaced for VAST workflow
export const isValidUrlWithProtocol = (url) => {
    return URL_REGEX_WITH_REQUIRED_PROTOCOL_OR_PROTO_TAG.test(url);
};
/**
 * This method takes a URL and will return both the parsedUrl and
 * array of replacements of that url plus validation.
 * @param str to be santized
 */
export const sanitizeUserInput = (str) => {
    if (!str) {
        return "";
    }
    const sanitizedUserInput = str
        .replace(/^\W\s/g, "") // Allow alphanumeric and whitespace only
        .replace(/([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, // Disallow emojis
    "");
    return sanitizedUserInput;
};
export const addTrailingSlash = (clickThrough) => {
    const sanitizedUserInput = sanitizeUserInput(clickThrough);
    /**
     * The following regex aims to match any string matching a . followed by lower case characters followed by a ?
     * i.e. the domain name of a url followed by a `?` for query params
     */
    const regex = /\.[a-z]+\?/g;
    const regexForwardSlash = /\//g;
    /**
     * If a ? is present in the clickThrough url that can mean 2 things:
     * - either we have a url like foo.com/? in which case we can directly return it
     * - or we have a url like foo.com? in which case we need to insert a trailing /
     * between the .com and the ?
     */
    if (sanitizedUserInput.indexOf("?") !== -1) {
        /**
         * The following is the extracted string matching the regex
         * (e.g. for google.com??a=fsfs it would be:
         * [ '.com?',
         *    index: 6,
         *    input: 'google.com??a=fsfs',
         *    groups: undefined
         * ])
         */
        const stringWithoutTrailingSlash = regex.exec(sanitizedUserInput);
        /**
         * PK: Perhaps there is a better implementation,
         * but this will count the # of forward slashes '/'
         * and if there aren't any (google.com?asdf) or less than 3 (https://abcdef.com?)
         * it'll add a trailing slash. If there are more than 3 slashes (https://abcdef.com/?)
         * we've already met the condition of adding a trailing slash so it'll skip this logic.
         */
        const forwardSlashCount = sanitizedUserInput.match(regexForwardSlash);
        if ((stringWithoutTrailingSlash && forwardSlashCount && forwardSlashCount.length < 3) ||
            (stringWithoutTrailingSlash && !forwardSlashCount)) {
            /**
             * Here we split the extracted string matching the regex by `?`
             * which for `.com?` would give ['.com','']
             */
            const domainWithoutTrailingSlash = stringWithoutTrailingSlash[0].split("?")[0];
            return sanitizedUserInput.replace(regex, `${domainWithoutTrailingSlash}/?`);
            /**
             * Keeping this for the future: This was the original regex used by @Patrickk using
             * lookbehind to have a more elegant way to extract `/\.[a-z]+\?/g` missing a trailing
             * / . Sadly it is not as of May 2020 supported on Firefox and it led to the UI
             * not rendering on Firefox and any browser not supporting lookbehind regex expressions.
             * See https://github.com/MadHive/taco/issues/3493
             * Update (08-13-2020): Lookbehind is now supported by FF, but Safari sadly does not.
             * Once they do we should be able to bring this back.
             */
            // return sanitizedUserInput.replace(/(?<=\.[a-zA-Z]{2,})\?/, "/$&");
        }
    }
    return sanitizedUserInput;
};
const urlRegexWithRequiredProtocol = 
// eslint-disable-next-line no-useless-escape
/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?((\/|\?).*)?$/;
export const isValidUrlWithRequiredProtocol = (url) => {
    // [PK 01-21-2020] Won't be checking for https:// since we need to relax our validation
    // Once we require secure protocol again we can use the regex above
    return urlRegexWithRequiredProtocol.test(url);
};
export const validateClickThroughUrl = (clickThroughUrlInput) => {
    if (!clickThroughUrlInput) {
        /** Blank clickThrough URL's are fine */
        return null;
    }
    if (
    // Check for known file storage providers, need to update as we find more invalid clickThrough urls
    clickThroughUrlInput.match(/dropbox/gim) ||
        clickThroughUrlInput.match(/storage.googleapis/gim) ||
        clickThroughUrlInput.match(/wetransfer/gim)) {
        return "The url is for a storage service, please put in a valid url.";
    }
    return isValidUrlWithRequiredProtocol(clickThroughUrlInput)
        ? null
        : "ClickThrough URL is invalid.";
};
/**
 * @param rangeLength: the length of the range.
 * @return: an array representing the range [0, @rangeLength). Ex: makeRange(3) => [0, 1, 2].
 */
export const makeRange = (rangeLength) => Array.from(Array(rangeLength).keys());
/**
 * @param dayparts: a set of dayparts.
 * @return: the mirror opposite of that daypart set. Ex: input: [{monday, hours: [0-22]}]; output: [{monday, hours: [23]}, (...all other days with full hours)]
 */
export const invertDaypartsArray = (dayparts) => {
    const invertedHours = makeRange(24);
    const invertedDayparts = [];
    for (const daypart of dayparts) {
        invertedDayparts.push({
            day: daypart.day,
            hours: invertedHours.filter((hour) => !daypart.hours.includes(hour))
        });
    }
    // We know the model matches because it is looping through a DaypartsArray.
    return invertedDayparts;
};
/**
 * @param dayparts: a set of dayparts.
 * @return: the mirror opposite of that daypart set. Ex: input: [{monday, hours: [0-22]}]; output: [{monday, hours: [23]}, (...all other days with full hours)]
 */
export const invertDayparts = (dayparts) => {
    const emptyDaypartsObject = {
        Sunday: { day: DayOfTheWeek.SUNDAY, hours: [] },
        Monday: { day: DayOfTheWeek.MONDAY, hours: [] },
        Tuesday: { day: DayOfTheWeek.TUESDAY, hours: [] },
        Wednesday: { day: DayOfTheWeek.WEDNESDAY, hours: [] },
        Thursday: { day: DayOfTheWeek.THURSDAY, hours: [] },
        Friday: { day: DayOfTheWeek.FRIDAY, hours: [] },
        Saturday: { day: DayOfTheWeek.SATURDAY, hours: [] }
    };
    /**
     * If the user only selected hours on certain days, they won't appear in the
     * array passed into this function. So we construct an array with default values for every day */
    const daypartsWithAllDays = Object.values(dayparts.reduce((filledDaypartsObject, daypart) => {
        return {
            ...filledDaypartsObject,
            [daypart.day]: daypart
        };
    }, emptyDaypartsObject));
    const rangeOfHoursInADay = makeRange(24);
    // invert hours, and exclude any days whose inverted hour count leaves them with 0 hours.
    const invertedDayparts = daypartsWithAllDays.reduce((invertedDaypartAcc, daypart) => {
        const originalSelectedHours = new Set(daypart.hours);
        const invertedHours = rangeOfHoursInADay.filter((hour) => !originalSelectedHours.has(hour));
        if (invertedHours.length > 0) {
            const invertedDaypart = { day: daypart.day, hours: invertedHours };
            invertedDaypartAcc.push(invertedDaypart);
        }
        return invertedDaypartAcc;
    }, []);
    return invertedDayparts;
};
const DOLLARS_TO_SATOSHIS_MULTIPLIER = 100000000;
export const satoshisToDollars = (satoshis) => satoshis / DOLLARS_TO_SATOSHIS_MULTIPLIER;
export const dollarsToSatoshis = (dollars) => {
    if (!dollars) {
        return 0;
    }
    return Math.round(dollars * DOLLARS_TO_SATOSHIS_MULTIPLIER);
};
export const sum = (number1, number2) => {
    return (number1 || 0) + (number2 || 0);
};
export const divide = (numerator, divisor) => {
    if (!numerator || !divisor) {
        return 0;
    }
    return divisor > 0 ? numerator / divisor : 0;
};
/**
 *
 * @param pacing
 * @param pacingType
 * @returns the pacing status based on the pacing value
 */
export const getPacingStatus = (pacing, pacingType = PacingType.ACTIVE) => {
    if (pacingType === PacingType.WAITING) {
        return PacingStatus.WAITING;
    }
    if (pacing === 0 && pacingType === PacingType.ACTIVE_WAITING) {
        return PacingStatus.ACTIVE_WAITING;
    }
    if (pacing >= 120) {
        return PacingStatus.WAY_OVER;
    }
    if (pacing < 120 && pacing >= 110) {
        return PacingStatus.OVER;
    }
    if (pacing < 110 && pacing >= 90) {
        return PacingStatus.ON;
    }
    if (pacing < 90 && pacing >= 80) {
        return PacingStatus.UNDER;
    }
    if (pacing < 80) {
        return PacingStatus.WAY_UNDER;
    }
    return PacingStatus.WAITING;
};
export const getPacingType = (delivered, currentTimeInMilliseconds, startDateInMilliseconds, endDateInMilliseconds) => {
    if (startDateInMilliseconds > currentTimeInMilliseconds) {
        return PacingType.WAITING;
    }
    if (startDateInMilliseconds < currentTimeInMilliseconds &&
        endDateInMilliseconds > currentTimeInMilliseconds &&
        delivered === 0) {
        return PacingType.ACTIVE_WAITING;
    }
    if (endDateInMilliseconds < currentTimeInMilliseconds) {
        return PacingType.COMPLETED;
    }
    if (startDateInMilliseconds < currentTimeInMilliseconds &&
        endDateInMilliseconds > currentTimeInMilliseconds) {
        return PacingType.ACTIVE;
    }
    return PacingType.WAITING;
};
/**
 *
 * @param data: an array of anything.
 * @param field: a field in the object type in the array.
 * @return: the total of each object's value for that field, if it is a number. Returns 0 if unable to calculate.
 */
export const calculateTotalByField = (data, field) => {
    if (!data || data.length === 0) {
        return 0;
    }
    let total = 0;
    for (let i = 0; i < data.length; ++i) {
        const item = data[i][field];
        if (typeof item === "number") {
            total += item;
        }
    }
    return total;
};
/**
 * Simple method to just do a toString or return undefined
 * @param field item to check
 * @returns undefined if nothing, toString() if something
 */
export const cleanAnyFieldToString = (field) => {
    return field ? field.toString() : undefined;
};
/**
 * Simple method to clean up a field that might be undefined
 * or a string.
 * @param field Field to be cleaned
 * @param notExistentValue: what to use if the field is undefined.
 * @returns either a trimed string or undefined.
 */
export const cleanStringField = (field, notExistentValue) => {
    return field ? field.trim() : notExistentValue;
};
/**
 * Simple method to await a timeout in ms
 * @param ms Value in milliseconds to delay
 * @returns
 */
export const delay = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
};
/**
 * @param state: the state to convert.
 * @return: the equivalent service state. Defaults to ServiceStatus.INVALID if a clearer match cannot be found.
 */
export const stateToServiceStatus = (state) => {
    switch (state) {
        case State.ARCHIVED:
            return ServiceStatus.ARCHIVED;
        case State.CANCELLED:
            return ServiceStatus.CANCELLED;
        case State.DRAFT:
            return ServiceStatus.DRAFT;
        case State.PAUSED:
            return ServiceStatus.PAUSED;
        case State.READY:
            return ServiceStatus.READY;
        default:
            return ServiceStatus.INVALID;
    }
};
/**
 * @param state: the state to convert.
 * @return: the equivalent service state. Defaults to ServiceStatus.INVALID if a clearer match cannot be found.
 */
export const serviceStateToState = (state) => {
    switch (state) {
        case ServiceStatus.ARCHIVED:
            return State.ARCHIVED;
        case ServiceStatus.CANCELLED:
            return State.CANCELLED;
        case ServiceStatus.DRAFT:
            return State.DRAFT;
        case ServiceStatus.PAUSED:
            return State.PAUSED;
        case ServiceStatus.READY:
            return State.READY;
        default:
            return State.INVALID;
    }
};
/**
 * Converts `string` to [start case](https://en.wikipedia.org/wiki/Letter_case#Stylistic_or_specialised_usage).
 *
 * @param string: the string to convert.
 * @return: the start cased string.
 * @example
 *
 * startCase('--foo-baz--')
 * // => 'Foo Baz'
 *
 * startCase('fooBaz')
 * // => 'Foo Baz'
 *
 * startCase('__FOO_BAZ__')
 * // => 'FOO BAZ'
 */
export const toStartCase = (string) => {
    // matches all groupings of alphanumeric characters
    const words = string.match(/[A-Za-z0-9]+/g) || [];
    return words
        .map((word) => word
        // breaks camelcase into separate entitites (e.g., bA, bZ, etc.)
        .replace(/([a-z])([A-Z])/g, (_, lowercaseLetter, uppercaseLetter) => `${lowercaseLetter} ${uppercaseLetter}`)
        // ensures lowercase letters preceded by space or line start are uppercased
        .replace(/(\s|^)(\w)/g, (_, spacing, lowercaseLetter) => `${spacing}${lowercaseLetter.toUpperCase()}`))
        .join(" ");
};

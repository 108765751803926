import { RemoteConfigProperties } from "../../../types";
import { validateIabCategoryIdInput, validateIabSubategoryIdInput } from "../../../models/creatives/legacy";
import { FilterTypes } from "../../handlers/filter";
export * from "./asset";
export * from "./trackingPixels";
export function validators(sdk) {
    return {
        name: (creative, value) => {
            if (!value?.trim()) {
                return "A creative cannot have a blank name.";
            }
            return undefined;
        },
        advertiserId: async (creative, value) => {
            if (!value) {
                return "Creatives must have an advertiser.";
            }
            const advertiser = await sdk.advertisers.find_once({
                where: [
                    {
                        field: "id",
                        type: FilterTypes.EQ,
                        value
                    }
                ]
            });
            if (!advertiser?.id !== value) {
                return "The selected is not valid. Please choose a different advertiser.";
            }
            return undefined;
        },
        iabCategoryId: (iabCategoryId) => {
            const isIabCategoriesRequired = sdk.featureFlags.isFlagEnabled(RemoteConfigProperties.IAB_CATEGORIES_REQUIRED);
            // only running this validation if the feature flags are enabled
            if (isIabCategoriesRequired) {
                const iabCategoryError = validateIabCategoryIdInput(iabCategoryId);
                if (iabCategoryError) {
                    return iabCategoryError;
                }
            }
            return undefined;
        },
        iabSubcategoryId: (iabCategoryId, iabSubcategoryId) => {
            // ADOPS-1384 always require subcategory if category is set to law/govt/politics
            const iabSubcategoryError = validateIabSubategoryIdInput(iabCategoryId, iabSubcategoryId);
            if (iabSubcategoryError) {
                return iabSubcategoryError;
            }
            return undefined;
        }
    };
}

import {
  GeographicEntityTypes,
  KNOWN_COUNTRY_CODES
} from "lib/utils/geography";
import {
  LineItemGeoTargetingActionTypes,
  LineItemGeoTargetingState,
  RESET_STATE,
  SET_IS_LINEITEM_TARGETING_MAPPED_TO_STATE,
  UPDATE_ENTITIES,
  UPDATE_SELECT_TYPE
} from "./types";
import {
  clearCountryIncludeInNextState,
  makeInitialLineitemGeoEntitiesState,
  clearOtherIncludesOnCountryInclude
} from "./utils";

export const lineitemGeoTargetingInitialState: LineItemGeoTargetingState = {
  ...makeInitialLineitemGeoEntitiesState(),
  targetingTranslatedToZipCodes: new Set(),
  isLineItemTargetingMappedToState: false,
  currentCountry: KNOWN_COUNTRY_CODES.US
};

export const lineItemGeoTargetingReducer = (
  state = lineitemGeoTargetingInitialState,
  action: LineItemGeoTargetingActionTypes
): LineItemGeoTargetingState => {
  switch (action.type) {
    case UPDATE_ENTITIES:
      if (action.meta === GeographicEntityTypes.COUNTRY) {
        return {
          ...clearOtherIncludesOnCountryInclude(action.payload.entities, state),
          targetingTranslatedToZipCodes:
            action.payload.targetingTranslatedToZips
        };
      }
      return clearCountryIncludeInNextState({
        ...state,
        [action.meta]: {
          entities: action.payload.entities,
          selectType: state[action.meta].selectType
        },
        targetingTranslatedToZipCodes: action.payload.targetingTranslatedToZips
      });

    case UPDATE_SELECT_TYPE:
      return clearCountryIncludeInNextState({
        ...state,
        [action.meta]: {
          entities: state[action.meta].entities,
          selectType: action.payload.selectType
        },
        targetingTranslatedToZipCodes: action.payload.targetingTranslatedToZips
      });

    case SET_IS_LINEITEM_TARGETING_MAPPED_TO_STATE:
      return {
        ...action.payload.lineItemTargeting,
        isLineItemTargetingMappedToState: true,
        targetingTranslatedToZipCodes: action.payload.targetingTranslatedToZips,
        currentCountry: action.payload.currentCountry
      };

    case RESET_STATE:
      return lineitemGeoTargetingInitialState;
    default:
      return state;
  }
};

import { useCallback } from "react";
import { Validation } from "@madhive/mad-sdk";
import { toObservable, invalidateCollection } from "../rxjs";
import { useSingularMadHooksUtils } from "./singularUtils";
import { useLoadSingleData } from "./singular/useLoadData";
/**
 * Returns a function that will save the pending changes or the provided data.
 * @param collection Collection the data belongs to
 * @param id The id of the data
 * @returns a Promise
 */
export const useSave = (collection, id, options) => {
    const { observer, handler } = useSingularMadHooksUtils(collection, id);
    const { dependentCollections, noData } = options || {};
    const load = useLoadSingleData(collection, id, noData);
    const saveFunction = useCallback((data, sdkOptions) => new Promise((resolve, reject) => {
        const current = observer.value;
        observer.next({
            ...current,
            isSaving: true
        });
        // This sends the full object to Mad-SDK because that is what it currently expects for api
        // interaction, but if we update which endpoints Mad-SDK uses, we can change this to only
        // send the necessary data (deltas).
        // Note: Since the pendingChanges proxy cannot iterate, data and deltas are combined to
        // reproduce the same result.
        const dataToSave = data || { ...current.data, ...current.deltas };
        toObservable(handler.save(dataToSave, sdkOptions || undefined)).subscribe({
            next(value) {
                observer.next({
                    ...observer.value,
                    data: value,
                    isSaving: false
                });
                invalidateCollection(collection);
                for (const dependentCollection of dependentCollections || []) {
                    invalidateCollection(dependentCollection);
                }
                load({ resetChanges: true });
                resolve(value);
            },
            error(error) {
                // Mad-SDK currently doesn't discern between validation errors or others, so
                // ValidationErrors and error are the same here, but should be changed in the future.
                observer.next({
                    ...observer.value,
                    isSaving: false,
                    validationErrors: error instanceof Validation ? error : current.validationErrors,
                    error: error instanceof Validation ? current.error : error
                });
                // We only want to throw hard errors not validation errors.
                if (error instanceof Validation) {
                    reject();
                }
                else {
                    reject(error);
                }
            }
        });
    }), [id, observer, handler]);
    return saveFunction;
};

import { objectLookup } from "../utils";
/**
 * A signaller manages what things are listening, and executes the associated callbacks when the matching action takes place.
 * @template {U}: the structure of user traits. Defaults to `Record<string, unknown>`.
 */
export class Signaller {
    constructor() {
        Object.defineProperty(this, "listeners", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.listeners = new Map();
    }
    register(id, listener) {
        this.listeners.set(id, listener);
    }
    async signal(action, data) {
        const executing = [];
        // Checks the listeners (IE Heap, Spring) for any methods that match the action.
        for (const listener of this.listeners.values()) {
            const executor = objectLookup(listener, action);
            if (typeof executor === "function") {
                executing.push(executor(data));
            }
        }
        await Promise.all(executing);
    }
    remove(id) {
        return this.listeners.delete(id);
    }
    clear() {
        this.listeners.clear();
    }
}

/**
 * Abstract class that all bulk upload handlers should extend to
 * allow for a common interface of upload-related activities.
 *
 * currently supports: Save
 */
export class Uploader {
    /**
     * Handlers need access to MadSDK
     * @param sdk Instance of MadSDK to use for lookups
     */
    constructor(sdk) {
        /** Saves/creates assets based on given file */
        this.save = async (file) => {
            if (!file) {
                throw new Error("file not provided");
            }
            const user = this.sdk.getCurrentUser();
            if (!user) {
                throw new Error("user is not logged in");
            }
            return this.saveUpload(file);
        };
        this.sdk = sdk;
    }
}

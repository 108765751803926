/**
 * @param instruction: either a campaign or a line item.
 * @param startDate: the value to use for start date.
 * @param updating: true if this validation is for an update to the instruction, else false (i.e., an as-is validation).
 * @return: an error message, if the date is invalid. Else, undefined.
 */
export const validateStartDate = (instruction, startDate, updating) => {
    if (!startDate && !instruction.startAsap) {
        return "Start date is required.";
    }
    if (updating && !(startDate instanceof Date) && !instruction.startAsap) {
        return "Start date must be a Date.";
    }
    if (startDate instanceof Date && !instruction?.startAsap) {
        if (instruction?.endDate instanceof Date) {
            if (instruction.endDate.getTime() <= startDate.getTime()) {
                return "Start date should be less than the end date.";
            }
        }
    }
    return;
};
/**
 * @param instruction: either a campaign or a line item.
 * @param startDate: the value to use for end date.
 * @param updating: true if this validation is for an update to the instruction, else false (i.e., an as-is validation).
 * @return: an error message, if the date is invalid. Else, undefined.
 */
export const validateEndDate = (instruction, endDate, updating) => {
    if (!endDate) {
        return "End date is required.";
    }
    if (updating && !(endDate instanceof Date)) {
        return "End date must be a Date.";
    }
    if (endDate instanceof Date) {
        if (!instruction?.startAsap &&
            instruction?.startDate instanceof Date &&
            instruction.startDate.getTime() >= endDate.getTime()) {
            return "End date should be greater than the start date.";
        }
        if (instruction?.version === 0) {
            const today = new Date();
            const past = today.getTime() >= endDate.getTime();
            if (past) {
                return "End date must be in the future.";
            }
        }
    }
    return;
};

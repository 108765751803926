import axios from "axios";
import { NotImplementedError } from "../../errors";
import { Handler as PromiseHandler } from "../handlers";
import { GenreContentObjectsFetchFailed } from "./errors";
export class GenreContentObjects extends PromiseHandler {
    constructor(sdk) {
        super(sdk, "contentObjects.genre", { atomize: true });
        this.url = `${this.sdk.urls.baseAPIUrl}/genres`;
    }
    findItems() {
        return this.cache.promise(this.url, () => axios
            .get(this.url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => res.data || [])
            .catch((error) => {
            throw new GenreContentObjectsFetchFailed(error);
        }));
    }
    /**
     * @deprecated
     * Genres does not support save.
     */
    saveItem() {
        return new Promise((_, reject) => {
            reject(new NotImplementedError("save"));
        });
    }
    /**
     * @deprecated
     * Genres does not support make.
     */
    make() {
        return new Promise((_, reject) => {
            reject(new NotImplementedError("make"));
        });
    }
    async deleteItem() {
        return new Promise((_, reject) => {
            reject(new NotImplementedError("delete"));
        });
    }
}

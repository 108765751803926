import { AdvancedExportsQueryError, MadSDKAbortError, NotImplementedError } from "../../errors";
import { buildNewReportExport, queryParametersToReportJobRequest } from "../../models/advancedExports";
import axios from "axios";
import { Observable, Subject } from "rxjs";
import { ObservableHandler } from "../handlers";
import { parseIdFilter } from "../handlers/filter";
import Options from "./options";
import StoragePoller from "./storage/poller";
import ExportedReportStorage from "./storage";
import AdvancedExportsTemplates from "./templates";
import { ApiVersion } from "./types";
import { buildUrl } from "./utils";
import validators from "./validators";
class AdvancedExports extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "advanced-exports");
        this.validators = validators;
        this.storage = new ExportedReportStorage(this.sdk);
        this.templates = new AdvancedExportsTemplates(this.sdk);
        this.options = new Options(this.sdk);
    }
    findItems(filters) {
        const subject = new Subject();
        const idFilter = parseIdFilter(filters)?.values().next()?.value;
        this.storage.getExports().then((exports) => {
            // return the report export if filtering by the id
            if (idFilter) {
                const reportExport = exports.find(({ id }) => id === idFilter);
                if (reportExport) {
                    subject.next(reportExport);
                    subject.complete();
                }
                else {
                    subject.error(new Error("No export found"));
                }
                return;
            }
            this.poller = new StoragePoller(this.sdk, this.storage, {
                subject: subject,
                data: exports
            });
            this.poller.start();
            subject.next(exports);
        });
        return subject;
    }
    saveItem(queryParams) {
        return new Observable((subscriber) => {
            const errors = this.validate(queryParams);
            if (errors.size) {
                subscriber.error(errors);
                return;
            }
            this.exportReport(queryParams)
                .then((report) => {
                subscriber.next(report);
                subscriber.complete();
            })
                .catch((e) => subscriber.error(e));
        });
    }
    async exportReport(queryParams) {
        try {
            const url = buildUrl(this.sdk, ApiVersion.V2, "report");
            const { data: reportJobResponse } = await axios.post(url, queryParametersToReportJobRequest(queryParams), {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const reportExport = buildNewReportExport(reportJobResponse.id, queryParams.name);
            await this.storage.saveExport(reportExport);
            await this.poller?.refresh();
            return Promise.resolve(reportExport);
        }
        catch (error) {
            if (axios.isCancel(error)) {
                return Promise.reject(new MadSDKAbortError());
            }
            return Promise.reject(new AdvancedExportsQueryError(error, error.response?.data || error.message));
        }
    }
    make() {
        throw new NotImplementedError("make");
    }
    async deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
    // stops the poller if its instantiated
    complete() {
        this.poller?.stop();
    }
}
export default AdvancedExports;

import axios from "axios";
import { Observable } from "rxjs";
import { InvalidCreativeError, UnauthenticatedError } from "../../errors";
import { serviceToCreative, creativeToService, standardizeMacroUrl } from "../../models/creatives";
import { InscapeFilterFields } from "../../models/creatives/inscape";
import Inscape from "./inscape";
import Templates from "./templates";
import Asset from "./asset";
import { ObservableHandler } from "../handlers";
import { FilterTypes, parseIdFilter } from "../handlers/filter";
import { ObjType } from "../../types";
import { parseNewNewYorkApiResponse } from "../../utils";
import { validateAsset } from "./validators/asset";
import { isServicePage } from "../handlers/page";
import { toQueryParams } from "./utils";
import { Validation } from "../../validation";
import { TrackingPixels } from "./trackingPixels";
import { CreativeSaveFailed } from "./errors";
import { validators } from "./validators";
import { toFilterObjects } from "./utils/filters";
/**
 * Handler for creatives
 */
class Creatives extends ObservableHandler {
    /**
     * Handlers need access to MadSDK
     * @param sdk Instance of MadSDK to use for lookups
     */
    constructor(sdk) {
        super(sdk, "creatives", { atomize: true });
        /** fetches inscape status for creative */
        this.getCreativeInscapeStatus = async (serviceCreative) => {
            return this.inscape.find_once({
                where: [
                    {
                        field: InscapeFilterFields.CREATIVE_ID,
                        type: FilterTypes.EQ,
                        value: serviceCreative.id
                    }
                ]
            });
        };
        /**
         * Used to covert a ServiceCreative to a Creative
         * This non-legacy version of toCreative is used for flights, amongst other places
         * @param serviceCreative Service Creative to transform
         * @returns Creative based on that ServiceCreative
         */
        this.toCreative = async (serviceCreative, isInscape) => {
            const user = this.sdk.getCurrentUser();
            if (!user) {
                throw new UnauthenticatedError();
            }
            const creative = serviceToCreative(serviceCreative, this.sdk.urls.creativeAssetFolderBaseUrl);
            const validationDetails = creative?.validationDetails || {
                assetMetadata: {}
            };
            const hydrations = [
                /** setting derived problems & standards */
                this.asset.specifications
                    .deriveProblemsAndStandards(validationDetails?.assetMetadata || {})
                    .then((res) => {
                    if (!validationDetails?.problems) {
                        validationDetails.problems = {};
                    }
                    validationDetails.problems.derived = res.problems;
                    validationDetails.standards = res.standards;
                })
            ];
            if (isInscape) {
                hydrations.push(
                /** setting inscape status */
                this.getCreativeInscapeStatus(serviceCreative).then((res) => {
                    const { creativeInscapeStatus, timestamp: timestampMs } = res || {};
                    validationDetails.inscape = {
                        status: creativeInscapeStatus,
                        timestamp: timestampMs * 1000
                    };
                }));
            }
            await Promise.all(hydrations);
            creative.validationDetails = validationDetails;
            creative.iab_category_rtb_ids = serviceCreative.iab_category_rtb_ids;
            if (serviceCreative.asset_validations) {
                creative.asset_validations = serviceCreative.asset_validations;
            }
            return creative;
        };
        /**
         * Currently this only transforms a creative to the most basic needs
         * for a service. For non-linear and vast there is an extra step of
         * transforming event_urls
         * @param creative creative to transforms
         */
        this.toServiceCreative = (creative) => {
            return creativeToService(creative);
        };
        this.templates = new Templates(sdk);
        this.inscape = new Inscape(sdk);
        this.asset = new Asset(sdk);
        this.trackingPixels = new TrackingPixels(sdk);
        this.validators = validators(sdk);
    }
    /**
     * This method runs all the different validation we have on a creative
     * and throws errors when things are wrong
     * @param creative creative to validate
     */
    async validateInteral(creative) {
        const validationErrors = new Validation();
        if (creative.cloudStorageUrl) {
            if (!creative.asset?.file) {
                return;
            }
            try {
                await validateAsset(creative.cloudStorageUrl, this.sdk.urls.madhiveEncoderBaseUrl);
            }
            catch (error) {
                validationErrors.set("validateAsset", error.message);
            }
        }
        if (validationErrors.size > 0) {
            throw new InvalidCreativeError(creative.id ? creative.id : "", [
                ...validationErrors.values()
            ]);
        }
    }
    /**
     * Gets a single creative.
     * @param id: the id of a creative to get.
     * @return: a promise resolving to that creative.
     */
    getCreative(id) {
        return this.cache.promise(id, () => axios
            .get(
        // When getting a single creative, we will always want to include archived.
        `${this.sdk.urls.madhiveEncoderBaseUrl}/creative/${id}?includeArchived=true`, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => this.toCreative(res.data.data, true)));
    }
    /**
     * Gets many creatives.
     * @param filters: filters to determine what creatives to get.
     * @param sort: how to sort the set of creatives gotten.
     * @return: the requested set of creatives.
     */
    getCreatives(filters, sort, isInscape) {
        const params = toQueryParams(filters, sort);
        const pageSize = filters?.paging?.size || 10;
        const url = `${this.sdk.urls.madhiveEncoderBaseUrl}/creatives${params}`;
        return this.cache.promise(url, () => axios
            .get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(async ({ data: res }) => {
            const creatives = await this.parseServiceResults(res.data || [], isInscape);
            let results = creatives;
            if (isServicePage(res)) {
                results = {
                    page: {
                        count: res.paging_info.count,
                        token: res.paging_info.token,
                        size: pageSize
                    },
                    data: creatives
                };
            }
            return results;
        }));
    }
    async parseServiceResults(results, isInscape) {
        return Promise.all(results.map((serviceCreative) => this.toCreative(serviceCreative, isInscape)));
    }
    make(defaults) {
        return new Promise((resolve, reject) => {
            this.sdk.cryptography
                .mintKey(ObjType.INST)
                .then((id) => {
                const creative = {
                    id,
                    name: "",
                    advertiserId: "",
                    clickThroughUrl: "",
                    trackingPixels: [],
                    ...defaults
                };
                resolve(creative);
            })
                .catch((error) => {
                reject(error);
            });
        });
    }
    findItems(filters, sort, hydratedFields = []) {
        return new Observable((subscriber) => {
            const idFilter = parseIdFilter(filters);
            const isInscape = hydratedFields.some((hydratedField) => hydratedField === "inscape");
            const promise = idFilter.size === 1
                ? this.getCreative(idFilter.values().next().value).then((creative) => [creative])
                : this.getCreatives(filters, sort, isInscape);
            promise
                .then((results) => {
                subscriber.next(results);
                subscriber.complete();
            })
                .catch((error) => subscriber.error(error));
        });
    }
    /**
     * Save for creative only supports updating.
     * @param creative single creative to be updated
     * @returns A single updated creative
     */
    saveItem(creative) {
        return new Observable((subscriber) => {
            const promise = creative.id ? this.update(creative) : this.create(creative);
            promise
                .then((saved) => {
                subscriber.next(saved);
                subscriber.complete();
            })
                .catch((error) => {
                subscriber.error(error);
            });
        });
    }
    /**
     * Because of the nature of creatives we need to have a separate create method
     * @param creative creative to be created
     */
    async create(creative) {
        let newCreative = creative;
        // if no id given mint a new id
        if (!newCreative.id) {
            newCreative.id = await this.sdk.cryptography.mintKey(ObjType.CREATIVE_VID);
        }
        newCreative = await this.asset.create(newCreative);
        // even if vastUrl came in via asset.url we still want to clean
        // incase we were given a vastUrl
        if (newCreative.vastUrl) {
            newCreative = {
                ...newCreative,
                vastUrl: standardizeMacroUrl(newCreative.vastUrl, true)
            };
        }
        // validating before save
        await this.validateInteral(newCreative);
        const url = `${this.sdk.urls.madhiveEncoderBaseUrl}/${newCreative.vastUrl ? "new-vast" : "new"}`;
        return axios
            .post(url, this.toServiceCreative(newCreative), {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then((res) => {
            const newCreative = res?.data?.data?.creative || res?.data?.data || res.data;
            return this.toCreative(newCreative, true);
        })
            .catch(() => {
            throw new CreativeSaveFailed();
        });
    }
    async update(creative) {
        let payload = creative;
        if (creative.vastUrl) {
            payload = {
                ...creative,
                vastUrl: standardizeMacroUrl(creative.vastUrl, true)
            };
        }
        await this.validateInteral(payload);
        const response = await axios.post(`${this.sdk.urls.madhiveEncoderBaseUrl}/creative`, this.toServiceCreative(payload), {
            headers: {
                "Content-Type": "application/json"
            }
        });
        const { data } = parseNewNewYorkApiResponse(response);
        return this.toCreative(data, true);
    }
    /**
     * Deletes given creative id
     * @param id id of creative to be deleted
     * @returns Creative that was deleted.
     */
    deleteItem(id) {
        return axios.delete(`${this.sdk.urls.madhiveEncoderBaseUrl}/creative/${id}`).then((res) => {
            return res.data.status;
        });
    }
    parseFilter(filters) {
        return toFilterObjects(filters);
    }
}
export default Creatives;

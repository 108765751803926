import React, { FC, Fragment, useMemo } from "react";
import { css } from "@emotion/react";
import { DoNotCare } from "../../types";
import * as Icons from "../Icons";
import { IconNames, IconComponentProps } from "../Icons/types";
import { IconProps } from "./types";
import Tooltip from "../Tooltip";

/**
 * `Icon` component is a wrapper for all icons that takes name of the icon,
 * and adds display component around it — if size is set to anything except "fill".
 */
const Icon: FC<IconProps> = <Path extends IconNames = IconNames>({
  name,
  size = "small",
  fill,
  stroke,
  tooltip,
  ...iconSpecificProps
}: IconProps<Path>) => {
  const IconComponent = useMemo(() => {
    let found: FC<IconComponentProps<Path>> | undefined;
    name.split(".").forEach((namePart: string) => {
      found = found ? found?.[namePart] : Icons[namePart];
    });
    return found || null;
  }, [name]);

  const DisplayComponent = useMemo(() => {
    if (!IconComponent) {
      return null;
    }
    if (size === "fill") {
      return React.Fragment;
    }
    return Icons.Display;
  }, [size, IconComponent]);

  const wrapperProps = useMemo(() => {
    if (!tooltip) {
      return {} as DoNotCare;
    }
    if (typeof tooltip === "object" && "content" in tooltip) {
      return {
        ...tooltip,
        triggerAction: tooltip?.triggerAction || "hover",
        position: tooltip?.position || "top"
      };
    }
    return { content: tooltip, triggerAction: "hover" };
  }, [tooltip]);

  const Wrapper = useMemo(() => (tooltip ? Tooltip : Fragment), [tooltip]);

  if (!DisplayComponent || !IconComponent) {
    return null;
  }

  return (
    <span
      className="kit-Icon"
      css={css`
        display: inline-flex;
        align-items: center;
      `}
    >
      <Wrapper {...wrapperProps}>
        <DisplayComponent {...(size === "fill" ? {} : { size })}>
          <IconComponent
            {...(iconSpecificProps as DoNotCare)}
            fill={fill}
            stroke={stroke}
          />
        </DisplayComponent>
      </Wrapper>
    </span>
  );
};

export default Icon;
export * from "./types";

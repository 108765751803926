/**
 * Validates the creative flights attached to a line item.
 * Since the attached creatives are an array,
 * we loop through and run CreativeFlights.validate on each flight,
 * and return the first error we find.
 * @param key key(s) of CreativeFlights.validators
 * @param lineItem line item to validate
 * @param sdk MadSDK instance
 * @returns string | undefined
 */
export const validateLineItemCreativeFlights = (key, lineItem, sdk) => {
    for (const flight of lineItem?.attachedCreatives || []) {
        const error = sdk.campaigns.lineItems.creativeFlights.validate({ ...flight, [sdk.campaigns.lineItems.creativeFlights.getKeyToPutParentOn()]: lineItem }, key);
        // If there are no errors, continue to the next flight
        if (!error) {
            continue;
        }
        return error;
    }
    return undefined;
};

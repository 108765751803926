import { requiredOptions, requiredString } from "../utils/validations";
import { requiredDate } from "./validations";
export const validators = {
    name: (_report, value) => requiredString(value),
    startDate: (_report, value) => requiredDate(value),
    endDate: (_report, value) => requiredDate(value),
    dimensions: (_report, value) => requiredOptions(value),
    metrics: (_report, value) => requiredOptions(value)
    // TODO: filters validation will be done in another ticket
    // filters: (_report: QueryParameters, _value: DoNotCare) => undefined
};

import { css } from "@emotion/react";

export const styles = {
  input: css`
    color: var(--color-primary);
    font-size: var(--font-size-body);
    font-family: var(--font-family-body);
    padding-left: var(--spacing-24) !important;
    color: var(--color-primary) !important;
    --indicator-border-color: var(--color-border-primary);
    --indicator-bg-color: var(--color-background-secondary);
    .bp4-control-indicator {
      width: var(--spacing-16);
      height: var(--spacing-16);
      background: var(--indicator-bg-color);
      outline: none !important;
      &::before {
        background-color: var(--indicator-bg-color) !important;
        border-radius: 50% !important;
        opacity: 1 !important;
        box-shadow: inset 0 0 0 1px var(--indicator-border-color);
      }
    }
    &:not(.bp4-align-right) .bp4-control-indicator {
      margin-left: calc(var(--spacing-24) * -1) !important;
    }
    input:focus ~ .bp4-control-indicator {
      --indicator-border-color: var(--color-border-primary);
      --indicator-bg-color: var(--color-background-secondary);
    }
    input:checked ~ .bp4-control-indicator {
      --indicator-bg-color: var(--color-splash-primary);
      --indicator-border-color: var(--color-splash-primary);
    }
    input:checked:focus ~ .bp4-control-indicator {
      --indicator-bg-color: var(--color-splash-tertiary);
      --indicator-border-color: var(--color-splash-tertiary);
    }
    input:checked:hover ~ .bp4-control-indicator {
      --indicator-bg-color: var(--color-splash-secondary);
      --indicator-border-color: var(--color-splash-secondary);
    }
    input:disabled ~ .bp4-control-indicator {
      --indicator-bg-color: var(--color-background-tertiary);
      --indicator-border-color: var(--color-border-secondary);
    }
    input:disabled:checked ~ .bp4-control-indicator {
      background: transparent !important;
      --indicator-bg-color: var(--color-border-primary);
      --indicator-border-color: var(--color-border-primary);
    }
  `
};

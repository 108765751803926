import { keyBy } from "lodash";
import { createSelector } from "reselect";
import { RootState } from "rootReducer";
import { AudienceSegment } from "types";

// TODO: MAD-1708 - will need to see where these are used, and remove
export const deprecatedSelectIsAllSegmentsLoading = (state: RootState) =>
  state.deprecatedSegments.isAllLoading;

export const deprecatedSelectIsAllSegmentsLoaded = (state: RootState) =>
  !!state.deprecatedSegments.receivedAllAt;

export const deprecatedSelectLoadAllSegmentsCancellationSource = (
  state: RootState
) => state.deprecatedSegments.loadAllCancellationSource;

export const deprecatedSelectAllSegmentsById = (state: RootState) =>
  state.deprecatedSegments.byId;

export const deprecatedSelectAllSegmentsByName = createSelector(
  deprecatedSelectAllSegmentsById,
  (results: Record<string, AudienceSegment>) =>
    keyBy(Object.values(results), "label")
);

/* eslint-disable import/prefer-default-export */
import styled from "@emotion/styled";

const DotIndicator = styled("span")`
  display: inherit;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background-color: var(--red);
  margin-right: var(--spacing-8);

  /* Note @MaximeHeckel: we do not have any color variable defined 
   * for this box-shadow at the moment of the implementation
   */
  box-shadow: 0px 0px 4px #e55555;
`;

export { DotIndicator };

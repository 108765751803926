import { memo } from "react";
import styled from "@emotion/styled";
import { v4 as uuid } from "uuid";
import Row from "./row";
import { useRowContext } from "./table";

interface TableFooterProps {
  renderFooter: (items: any[]) => JSX.Element[];
}

const FooterCellWrapper = styled.div`
  display: inline-block;
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-width: 100%;
  min-width: 0;
  white-space: nowrap;
  font-weight: var(--font-weight-bold);
  line-height: var(--spacing-16);
  font-size: var(--font-size-12);
  user-select: none;
`;

const TableFooter = memo((props: TableFooterProps) => {
  const { data } = useRowContext();

  return (
    <Row header>
      {props.renderFooter(data).map(cellContent => (
        <FooterCellWrapper key={uuid()}>{cellContent}</FooterCellWrapper>
      ))}
    </Row>
  );
});

export default TableFooter;

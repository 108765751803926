import axios from "axios";
import { Observable } from "rxjs";
import { ObservableHandler } from "../../../handlers";
import { DerivedLineItemStatus, serviceCampaignLineItemToCampaignLineItem } from "../../../../models/campaigns/lineitems";
import { NotImplementedError } from "../../../../errors";
import { Audit } from "../../../../models/base";
import { ServiceStatus } from "../../../../types";
import { formatDeviceCaps, formatDayparts } from "../../../../utils/format";
export class LineItemAudits extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "lineitem-audits");
    }
    findItems(filters) {
        const idFilter = filters.where?.find((filter) => filter.field === "id");
        if (!idFilter) {
            throw Error("Audits need to be filtered by id.");
        }
        return new Observable((subscriber) => {
            axios
                .get(`${this.sdk.urls.baseAPIUrl}/lineitem/${idFilter.value}/audit`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(async (res) => {
                const lineItems = await Promise.all(res.data.data
                    .map((serviceLineItem) => this.toLineItem(serviceLineItem))
                    .sort((a, b) => b.version - a.version));
                const newest = lineItems.length > 0 ? lineItems[lineItems.length - 1] : null;
                const today = new Date();
                const name = `LineItem-${newest?.name || ""}-${today.toLocaleDateString()} ${today.toLocaleTimeString()}`;
                const audit = new Audit({
                    headers: LineItemAudits.CSVHeaders,
                    name,
                    items: lineItems
                });
                subscriber.next(audit);
                subscriber.complete();
            })
                .catch((error) => {
                subscriber.error(error);
            });
        });
    }
    /**
     * Make isn't implemented for line item audits.
     */
    make() {
        throw new NotImplementedError("make");
    }
    saveItem() {
        throw new NotImplementedError("save");
    }
    deleteItem() {
        throw new NotImplementedError("delete");
    }
    toLineItem(serviceLineItem) {
        // Although the api documentation defines the `audit` endpoint's response as
        // using the same model as lineitems, it is missing `status_details`.
        // This will be addressed in https://madhive.atlassian.net/browse/ADOPS-1808
        if (!serviceLineItem.status_details) {
            // Defaulting all `canBe` states to false so they aren't used from the audit.
            const status_details = {
                summary: serviceLineItem.derived_statuses?.[0] || DerivedLineItemStatus.INVALID,
                code: serviceLineItem.status,
                canBe: {
                    archived: false,
                    unarchived: false,
                    live: false,
                    edited: false,
                    paused: false
                }
            };
            serviceLineItem.status_details = status_details;
            for (const descendantId in serviceLineItem.descendants) {
                serviceLineItem.descendants[descendantId].status_details = status_details;
            }
        }
        return serviceCampaignLineItemToCampaignLineItem(serviceLineItem, this.sdk.cryptography);
    }
}
/** What headers are used in the line item audit CSV. */
LineItemAudits.CSVHeaders = [
    "name",
    { key: "id", name: "MH ID" },
    "version",
    "updatedBy",
    {
        key: "lastUpdated",
        name: "Updated",
        format: (date) => date?.toLocaleString()
    },
    {
        key: "startDate",
        format: (date) => date.toLocaleString()
    },
    {
        key: "endDate",
        format: (date) => date.toLocaleString()
    },
    "bookedImpressions",
    {
        key: "dayparts",
        format: (dayparts) => formatDayparts(dayparts)
    },
    {
        key: "deviceCaps",
        format: (deviceCaps) => formatDeviceCaps(deviceCaps)
    },
    "productId",
    "publisherGroupId",
    "geoTargeting.include",
    "geoTargeting.exclude",
    {
        key: "frequencies",
        name: "Frequency Caps",
        format: (caps) => caps.map(({ duration: { time_unit }, max }) => `${time_unit}: ${max}`).join(", ")
    },
    "segments",
    "attachedCreatives",
    { key: "meta", name: "Ext Metas" },
    "externalId",
    {
        key: "instructionStatus",
        name: "Status",
        format: (status) => ServiceStatus[status]
    }
];

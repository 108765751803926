import { lastValueFrom, Observable } from "rxjs";
import { FilterTypes } from "../../handlers/filter";
export class SummaryInjector {
    constructor(sdk) {
        this.sdk = sdk;
    }
    inject(lineItems, fields) {
        return new Observable((subscriber) => {
            if (lineItems.length === 0 || fields.length === 0) {
                return subscriber.next(lineItems);
            }
            else {
                lastValueFrom(this.sdk.summaries.find(this.getSummaryFilters(lineItems, fields))).then((summary) => {
                    subscriber.next(this.hydrateLineItems(lineItems, summary));
                    subscriber.complete();
                });
            }
        });
    }
    /**
     * Summarizes each line item with individual requests.
     * This is necessary for any request that requires vcr/frequency, due to a quirk in the API.
     * @param lineItems: the line items to hydrate.
     * @param fields: what to get summaries of.
     * @param subscriber: who is interested in this summary info.
     */
    hydrateLineItems(lineItems, { campaignSummaries }) {
        if (campaignSummaries) {
            const lookup = new Map();
            for (const campaignSummary of campaignSummaries) {
                for (const lineItemSummary of campaignSummary.lineItems) {
                    lookup.set(lineItemSummary.id, lineItemSummary);
                }
            }
            return lineItems.map((lineItem) => {
                const lineItemSummary = lookup.get(lineItem.id);
                lineItem.summary = {
                    pacing: lineItemSummary || null,
                    vcr: typeof lineItemSummary?.videoCompleteRate === "number"
                        ? lineItemSummary.videoCompleteRate
                        : null
                };
                return lineItem;
            });
        }
        return lineItems;
    }
    /**
     * There are some oddities with the current service that we need to account for.
     * 1. Even though we are giving a list of campaign IDs we still need to give a start
     *    end date which is usually used to limit the request (b/c of a slow query). Now
     *    we have IDs we shouldn't need this but it is required so we are going to "hard code"
     *    them to be in the past (2018) and in the future (01/01/(current-year + 2)). There is a new service being
     *    built that won't have this limitation.
     * 2. The request also needs parentId and owners set b/c they are required but are just the
     *    org id of the current user (which the service also has)
     * @param lineItems: line items to get summaries for.
     * @param fields: which summaries to request.
     * @return: a filter set for requesting campaign summaries.
     */
    getSummaryFilters(lineItems, fields) {
        const user = this.sdk.getCurrentUser();
        const translatedFields = fields.includes("pacing") || fields.includes("videoCompletionRate")
            ? ["campaignSummaries"]
            : undefined;
        const campaignIds = Array.from(new Set(lineItems.map((li) => li.parent)));
        return {
            where: [
                {
                    field: "campaignIds",
                    type: FilterTypes.EQ,
                    value: campaignIds
                },
                {
                    field: "start",
                    type: FilterTypes.EQ,
                    value: "2018-01-01"
                },
                {
                    field: "end",
                    type: FilterTypes.EQ,
                    value: `${new Date().getFullYear() + 2}-01-01`
                },
                {
                    field: "parentId",
                    type: FilterTypes.EQ,
                    value: user.primaryOrganizationId
                },
                {
                    field: "ownerIds",
                    type: FilterTypes.EQ,
                    value: [user.primaryOrganizationId]
                }
            ],
            fields: translatedFields
        };
    }
}

import {
  ToastAction,
  ToastActionWithPayload,
  ToastMetaBody,
  ToastType
} from "appReducers/toasterReducer/types";
import { CancelTokenSource } from "axios";
import { RootCampaign, ShallowLineItem } from "campaign-types";
import { ManageCampaignColumn, PagingInfoResponse } from "@madhive/mad-sdk";
import { SortDirection } from "madhive/components";

export const GET_ALL_CAMPAIGNS_PENDING = "GET_ALL_CAMPAIGNS_PENDING";
export const GET_ALL_CAMPAIGNS_FAILURE = "GET_ALL_CAMPAIGNS_FAILURE";
export const GET_ALL_CAMPAIGNS_SUCCESS = "GET_ALL_CAMPAIGNS_SUCCESS";

export const GET_ALL_LINEITEMS_PENDING = "GET_ALL_LINEITEMS_PENDING";
export const GET_ALL_LINEITEMS_FAILURE = "GET_ALL_LINEITEMS_FAILURE";
export const GET_ALL_LINEITEMS_SUCCESS = "GET_ALL_LINEITEMS_SUCCESS";

export const GET_CAMPAIGN_PENDING = "GET_CAMPAIGN_PENDING";
export const GET_CAMPAIGN_FAILURE = "GET_CAMPAIGN_FAILURE";
export const GET_CAMPAIGN_SUCCESS = "GET_CAMPAIGN_SUCCESS";
export const ABORT_GET_CAMPAIGN_REQUEST = "ABORT_GET_CAMPAIGN_REQUEST";

export const SAVE_CAMPAIGN_SUCCESS = "SAVE_CAMPAIGN_SUCCESS";
export const SAVE_CAMPAIGN_FAILURE = "SAVE_CAMPAIGN_FAILURE";

export const CREATE_CAMPAIGN_PENDING = "CREATE_CAMPAIGN_PENDING";

export const UPDATE_CAMPAIGN_PENDING = "UPDATE_CAMPAIGN_PENDING";
export const UPDATE_CAMPAIGN_FAILURE = "UPDATE_CAMPAIGN_FAILURE";

export const DELETE_CAMPAIGN_PENDING = "DELETE_CAMPAIGN_PENDING";
export const DELETE_CAMPAIGN_FAILURE = "DELETE_CAMPAIGN_FAILURE";
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS";

export const GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_PENDING =
  "GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_PENDING";
export const GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_FAILURE =
  "GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_FAILURE";
export const GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_SUCCESS =
  "GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_SUCCESS";

export const GET_BULK_ASSIGN_TEMPLATE_UPLOAD_PENDING =
  "GET_BULK_ASSIGN_TEMPLATE_UPLOAD_PENDING";
export const GET_BULK_ASSIGN_TEMPLATE_UPLOAD_FAILURE =
  "GET_BULK_ASSIGN_TEMPLATE_UPLOAD_FAILURE";
export const GET_BULK_ASSIGN_TEMPLATE_UPLOAD_SUCCESS =
  "GET_BULK_ASSIGN_TEMPLATE_UPLOAD_SUCCESS";

export const GET_CAMPAIGN_TEMPLATE_DOWNLOAD_PENDING =
  "GET_CAMPAIGN_TEMPLATE_DOWNLOAD_PENDING";
export const GET_CAMPAIGN_TEMPLATE_DOWNLOAD_FAILURE =
  "GET_CAMPAIGN_TEMPLATE_DOWNLOAD_FAILURE";
export const GET_CAMPAIGN_TEMPLATE_DOWNLOAD_SUCCESS =
  "GET_CAMPAIGN_TEMPLATE_DOWNLOAD_SUCCESS";

export const GET_CAMPAIGN_TEMPLATE_UPLOAD_PENDING =
  "GET_CAMPAIGN_TEMPLATE_UPLOAD_PENDING";
export const GET_CAMPAIGN_TEMPLATE_UPLOAD_FAILURE =
  "GET_CAMPAIGN_TEMPLATE_UPLOAD_FAILURE";
export const GET_CAMPAIGN_TEMPLATE_UPLOAD_SUCCESS =
  "GET_CAMPAIGN_TEMPLATE_UPLOAD_SUCCESS";

export const CLEAR_CAMPAIGN_SORTBY = "CLEAR_CAMPAIGN_SORTBY";
export const CLEAR_LINEITEM_SORTBY = "CLEAR_LINEITEM_SORTBY";

export interface CampaignsState {
  readonly byId: Record<string, RootCampaign>;
  readonly loadAllCancellationSource: CancelTokenSource | null;
  readonly loadSingleCancellationSource: Record<string, CancelTokenSource>;
  readonly receivedAllAt: number | null;
  readonly isSaving: boolean;
  readonly paging: PagingInfo;
  readonly sortBy: SortInfo | null;
  readonly lineItems: LineItems;
}

export interface PagingChanges {
  offset?: number;
  newPageSize?: number;
}

export interface PagingInfo {
  totalCount: number;
  pageSize: number;
  currentPage: number;
  currentPageToken: string;
  offset: number;
}

export interface SortInfo {
  fieldId: ManageCampaignColumn;
  order: SortDirection;
}

export interface LineItems {
  cancellationSource: CancelTokenSource | null;
  byId: Record<string, ShallowLineItem>;
  campaignsById: Record<string, RootCampaign>;
  sortBy: SortInfo | null;
  paging: PagingInfo;
}

interface GetAllCampaignsPendingAction {
  type: typeof GET_ALL_CAMPAIGNS_PENDING;
  meta: {
    cancellationSource: CancelTokenSource;
    pagingChanges?: PagingChanges;
    sortInfo?: SortInfo;
    isPreviousOrNextPage?: boolean;
  };
}

interface GetAllCampaignsSuccessAction {
  type: typeof GET_ALL_CAMPAIGNS_SUCCESS;
  payload: RootCampaign[];
  meta: {
    timestamp: number;
    pagingInfoResponse?: PagingInfoResponse;
  };
}

interface GetCampaignPendingAction {
  type: typeof GET_CAMPAIGN_PENDING;
  meta: {
    campaignId: string;
    cancellationSource: CancelTokenSource;
  };
}

interface GetCampaignSuccessAction {
  type: typeof GET_CAMPAIGN_SUCCESS;
  payload: RootCampaign;
  meta: {
    timestamp: number;
  };
}

interface AbortGetCampaignRequestAction {
  type: typeof ABORT_GET_CAMPAIGN_REQUEST;
  meta: {
    campaignId: string;
  };
}

interface GetCampaignFailureAction {
  type: typeof GET_CAMPAIGN_FAILURE;
  meta: {
    campaignId: string;
  } & ToastMetaBody<ToastType.error>;
}

interface UpdateCampaignPendingAction {
  type: typeof UPDATE_CAMPAIGN_PENDING;
  meta: {
    campaign: RootCampaign;
  };
}

interface GetAllLineItemsPendingAction {
  type: typeof GET_ALL_LINEITEMS_PENDING;
  meta: {
    cancellationSource: CancelTokenSource;
    pagingChanges?: PagingChanges;
    sortInfo?: SortInfo;
    isPreviousOrNextPage?: boolean;
  };
}

interface GetAllLineItemsSuccessAction {
  type: typeof GET_ALL_LINEITEMS_SUCCESS;
  payload: ShallowLineItem[];
  meta: {
    timestamp: number;
    campaigns: RootCampaign[];
    pagingInfoResponse?: PagingInfoResponse;
  };
}

interface Action<T> {
  type: T;
}

export type CampaignsActionTypes =
  | GetAllCampaignsPendingAction
  | Action<typeof GET_ALL_CAMPAIGNS_FAILURE>
  | GetAllCampaignsSuccessAction
  | GetAllLineItemsPendingAction
  | Action<typeof GET_ALL_LINEITEMS_FAILURE>
  | GetAllLineItemsSuccessAction
  | GetCampaignPendingAction
  | GetCampaignFailureAction
  | GetCampaignSuccessAction
  | AbortGetCampaignRequestAction
  | Action<typeof CREATE_CAMPAIGN_PENDING>
  | ToastAction<typeof SAVE_CAMPAIGN_FAILURE, ToastType.error>
  | ToastActionWithPayload<
      typeof SAVE_CAMPAIGN_SUCCESS,
      ToastType.success,
      { campaign: RootCampaign }
    >
  | UpdateCampaignPendingAction
  | Action<typeof DELETE_CAMPAIGN_PENDING>
  | ToastAction<typeof DELETE_CAMPAIGN_FAILURE, ToastType.error>
  | ToastActionWithPayload<
      typeof DELETE_CAMPAIGN_SUCCESS,
      ToastType.success,
      { campaignId: string }
    >
  | Action<typeof GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_PENDING>
  | ToastAction<
      typeof GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_FAILURE,
      ToastType.error
    >
  | ToastAction<
      typeof GET_BULK_ASSIGN_TEMPLATE_DOWNLOAD_SUCCESS,
      ToastType.success
    >
  | Action<typeof GET_BULK_ASSIGN_TEMPLATE_UPLOAD_PENDING>
  | ToastAction<typeof GET_BULK_ASSIGN_TEMPLATE_UPLOAD_FAILURE, ToastType.error>
  | ToastAction<
      typeof GET_BULK_ASSIGN_TEMPLATE_UPLOAD_SUCCESS,
      ToastType.success
    >
  | Action<typeof GET_CAMPAIGN_TEMPLATE_DOWNLOAD_PENDING>
  | ToastAction<typeof GET_CAMPAIGN_TEMPLATE_DOWNLOAD_FAILURE, ToastType.error>
  | ToastActionWithPayload<
      typeof GET_CAMPAIGN_TEMPLATE_DOWNLOAD_SUCCESS,
      ToastType.success,
      ArrayBuffer
    >
  | Action<typeof GET_CAMPAIGN_TEMPLATE_UPLOAD_PENDING>
  | ToastAction<typeof GET_CAMPAIGN_TEMPLATE_UPLOAD_FAILURE, ToastType.error>
  | ToastAction<typeof GET_CAMPAIGN_TEMPLATE_UPLOAD_SUCCESS, ToastType.success>
  | Action<typeof CLEAR_CAMPAIGN_SORTBY>
  | Action<typeof CLEAR_LINEITEM_SORTBY>;

import axios from "axios";
import { GetPioProductPackagesError, NotImplementedError } from "../errors";
import { toPioProductPackage } from "../models/pioProductPackage";
import { Handler } from "./handlers";
export default class PioProductPackages extends Handler {
    constructor(sdk) {
        super(sdk, "pio-product-packages");
    }
    async findItems() {
        try {
            const response = await axios
                .get(`${this.sdk.urls.baseHermesUrlStaticAssetsTempV2}/pio_packages`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((response) => response.data);
            // some packages have null products, ignoring them
            return response.filter((data) => data.product_ids).map(toPioProductPackage);
        }
        catch (error) {
            throw new GetPioProductPackagesError(error);
        }
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveItem(data) {
        throw new NotImplementedError("create");
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}

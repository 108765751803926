import { FrequencyCount } from "hermes";
import { IToastProps } from "@blueprintjs/core";
import { CancelTokenSource } from "axios";

export interface UniquesHistogramState {
  cancellationSource: CancelTokenSource | null;
  lastReceived: number | null;
  data: FrequencyCount[];
  error:
    | ({
        message: string;
      } & IToastProps)
    | null;
}

export const GET_UNIQUES_HISTOGRAM_PENDING = "GET_UNIQUES_HISTOGRAM_PENDING";

export const GET_UNIQUES_HISTOGRAM_SUCCESS = "GET_UNIQUES_HISTOGRAM_SUCCESS";

export const GET_UNIQUES_HISTOGRAM_FAILURE = "GET_UNIQUES_HISTOGRAM_FAILURE";

export const ABORT_GET_UNIQUES_HISTOGRAM = "ABORT_GET_UNIQUES_HISTOGRAM";

interface GetUniquesHistogramPendingAction {
  type: typeof GET_UNIQUES_HISTOGRAM_PENDING;
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

interface GetUniquesHistogramSuccessAction {
  type: typeof GET_UNIQUES_HISTOGRAM_SUCCESS;
  payload: FrequencyCount[];
  meta: {
    timestamp: number;
  };
}

interface GetUniquesHistogramFailureAction {
  type: typeof GET_UNIQUES_HISTOGRAM_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface AbortGetUniquesHistogramAction {
  type: typeof ABORT_GET_UNIQUES_HISTOGRAM;
}

export type UniquesHistogramActions =
  | GetUniquesHistogramPendingAction
  | GetUniquesHistogramSuccessAction
  | GetUniquesHistogramFailureAction
  | AbortGetUniquesHistogramAction;

import { IToastProps } from "@blueprintjs/core";
import { Product } from "@madhive/mad-sdk";

export const GET_ALL_PRODUCTS_PENDING = "GET_ALL_PRODUCTS_PENDING";
export const GET_ALL_PRODUCTS_FAILURE = "GET_ALL_PRODUCTS_FAILURE";
export const GET_ALL_PRODUCTS_SUCCESS = "GET_ALL_PRODUCTS_SUCCESS";
export const ABORT_PRODUCT_REQUEST = "ABORT_PRODUCT_REQUEST";

export const UPDATE_PRODUCT_PENDING = "UPDATE_PRODUCT_PENDING";
export const CREATE_PRODUCT_PENDING = "CREATE_PRODUCT_PENDING";
export const SAVE_PRODUCT_FAILURE = "SAVE_PRODUCT_FAILURE";
export const SAVE_PRODUCT_SUCCESS = "SAVE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_PENDING = "DELETE_PRODUCT_PENDING";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILURE = "DELETE_PRODUCT_FAILURE";

export interface ProductsState {
  readonly byId: Record<string, Product>;
  readonly archivedById: Record<string, Product>;
  readonly isLoading: boolean;
  readonly receivedAllAt: number | null;
  readonly isUpdating: boolean;
  readonly getError: string | null;
  readonly updateError: string | null;
}

interface GetAllProductsPendingAction {
  type: typeof GET_ALL_PRODUCTS_PENDING;
}

interface GetAllProductsFailureAction {
  type: typeof GET_ALL_PRODUCTS_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface GetAllProductsSuccessAction {
  type: typeof GET_ALL_PRODUCTS_SUCCESS;
  payload: Product[];
  meta: {
    timestamp: number;
  };
}

interface AbortProductRequestAction {
  type: typeof ABORT_PRODUCT_REQUEST;
}

interface UpdateProductPendingAction {
  type: typeof UPDATE_PRODUCT_PENDING;
  meta: {
    product: Product;
  };
}

interface CreateProductPendingAction {
  type: typeof CREATE_PRODUCT_PENDING;
}

interface SaveProductsFailureAction {
  type: typeof SAVE_PRODUCT_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface SaveProductsSuccessAction {
  type: typeof SAVE_PRODUCT_SUCCESS;
  payload: { product: Product };
}

interface DeleteProductPendingAction {
  type: typeof DELETE_PRODUCT_PENDING;
}

interface DeleteProductSuccessAction {
  type: typeof DELETE_PRODUCT_SUCCESS;
  payload: { productId: string };
}

interface DeleteProductFailuresAction {
  type: typeof DELETE_PRODUCT_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

export type ProductsActionTypes =
  | GetAllProductsPendingAction
  | GetAllProductsFailureAction
  | GetAllProductsSuccessAction
  | AbortProductRequestAction
  | UpdateProductPendingAction
  | CreateProductPendingAction
  | SaveProductsFailureAction
  | SaveProductsSuccessAction
  | DeleteProductPendingAction
  | DeleteProductSuccessAction
  | DeleteProductFailuresAction;

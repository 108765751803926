import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Lock: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-lock"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13.25C12.4142 13.25 12.75 13.5858 12.75 14V17C12.75 17.4142 12.4142 17.75 12 17.75C11.5858 17.75 11.25 17.4142 11.25 17V14C11.25 13.5858 11.5858 13.25 12 13.25Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.25 9.25V7C7.25 4.37679 9.37679 2.25 12 2.25C14.6232 2.25 16.75 4.37679 16.75 7V9.25H17C18.5192 9.25 19.75 10.4808 19.75 12V19C19.75 20.5192 18.5192 21.75 17 21.75H7C5.48079 21.75 4.25 20.5192 4.25 19V12C4.25 10.4808 5.48079 9.25 7 9.25H7.25ZM8.75 7C8.75 5.20521 10.2052 3.75 12 3.75C13.7948 3.75 15.25 5.20521 15.25 7V9.25H8.75V7ZM7 10.75C6.30921 10.75 5.75 11.3092 5.75 12V19C5.75 19.6908 6.30921 20.25 7 20.25H17C17.6908 20.25 18.25 19.6908 18.25 19V12C18.25 11.3092 17.6908 10.75 17 10.75H7Z"
      />
    </svg>
  );
};

export default Lock;

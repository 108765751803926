import { LinearDimensionList, OTTDimensionList } from "@madhive/mad-sdk";

const ottDimensionValues = Object.values(OTTDimensionList);
const linearDimensionValues = Object.values(LinearDimensionList);

/**
 * linear dimensions coming with values as  linearnetwork | linearnetwork-program
 */
export const sanitizeDimension = (dimension: string) =>
  dimension.replace(/linear/, "") || "";

export const getDimensionType = (
  dimension: string
): "ott" | "linear" | "metrics" => {
  const sanitizedDimension = sanitizeDimension(dimension);

  if (
    dimension.includes("linear") &&
    linearDimensionValues.includes(sanitizedDimension)
  ) {
    return "linear";
  }

  if (ottDimensionValues.includes(sanitizedDimension)) {
    return "ott";
  }

  return "metrics";
};

import axios from "axios";
import { RemoteConfigProperties } from "../models/featureFlags";
export default class FeatureFlags {
    /**
     * Handlers need access to MadSDK
     * @param sdk Instance of MadSDK to use for lookups
     */
    constructor(sdk) {
        this.sdk = sdk;
        this.state = {};
        this.loading = new Promise((resolve) => {
            this.resolveLoading = resolve;
        });
    }
    isFlagEnabled(key) {
        return this.state[key] === true;
    }
    async load() {
        const res = await axios.get(`${this.sdk.urls.burnsBaseUrl}/feature-flags`);
        this.state = res.data;
        this.resolveLoading();
    }
    async loaded() {
        return this.loading;
    }
    /**
     * Enabling functionality in all environments
     */
    // eslint-disable-next-line class-methods-use-this
    get useSDKFeatureFlags() {
        return true;
    }
}
export { RemoteConfigProperties };

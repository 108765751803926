import axios from "axios";
import { NotImplementedError } from "../../errors";
import { parseIdFilter } from "../handlers/filter";
import { Handler } from "../handlers";
import { InvalidStation, StationSaveFailed, StationsFetchFailed } from "./errors";
import { validators } from "./validators";
import { toQueryParams } from "./utils";
import { StationsHydrater } from "./hydrater";
/**
 * Handles stations collections find and manangements.
 */
class Stations extends Handler {
    constructor(sdk) {
        super(sdk, "stations", { atomize: true });
        this.url = `${this.sdk.urls.burnsBaseUrl}/stations`;
        this.validators = validators;
        this.hydrater = new StationsHydrater(sdk, {
            requiredFields: ["dmaName"]
        });
    }
    async findItems(filters, sort) {
        const idFilter = parseIdFilter(filters);
        const promise = idFilter.size === 1
            ? this.getStation(idFilter.values().next().value).then((station) => [station])
            : this.getStations(filters, sort);
        return promise.catch((error) => {
            throw new StationsFetchFailed(error);
        });
    }
    getStations(filters, sort) {
        const url = `${this.url}${toQueryParams(filters, sort)}`;
        return this.cache.promise(url, () => axios.get(url).then(async ({ data: stations }) => {
            const hydrated = await this.hydrater.hydrate(stations, []);
            return hydrated;
        }));
    }
    getStation(id) {
        return this.cache.promise(id, () => axios.get(`${this.url}/${id}`).then(async ({ data: station }) => {
            const hydrated = await this.hydrater.hydrate([station], []);
            return hydrated[0];
        }));
    }
    async saveItem(station) {
        const validation = this.validate(station);
        if (validation.size) {
            throw new InvalidStation(Array.from(validation.values()));
        }
        const updating = "id" in station;
        const action = updating ? axios.put : axios.post;
        const url = updating ? `${this.url}/${station.id}` : this.url;
        return action(url, station)
            .then(({ data: saved }) => {
            return saved;
        })
            .catch((error) => {
            throw new StationSaveFailed(error);
        });
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}
export default Stations;

import Smithers from "components/Smithers";
import { createRoot } from "react-dom/client";
import "./styles/index.scss";
import { initializeSideEffects } from "lib/utils/sideEffects";
import "./api/unhandledErrorReporter";

initializeSideEffects().finally(() => {
  const container = document.getElementById("root") as HTMLElement;
  const root = createRoot(container!);
  root.render(<Smithers />);
});

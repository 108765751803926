export const getIsUnchangedRenderDependencies = (
  prevDeps?: any[],
  nextDeps?: any[]
) => {
  if (!prevDeps || !nextDeps) {
    return true;
  }

  let isDelta = false;

  for (let index = 0; index < prevDeps.length; index++) {
    if (isDelta) break;

    if (prevDeps[index] !== nextDeps[index]) {
      isDelta = true;
    }
  }

  return !isDelta;
};

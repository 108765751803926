import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const ClipboardWithCheckmark: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-clipboard-with-checkmark"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.99874 3.74628C8.30793 3.74628 7.74791 4.30629 7.74791 4.99711V6.24792H11.0004C12.2884 6.24792 13.3696 7.13339 13.6684 8.3288H20.2537V4.99711C20.2537 4.30629 19.6937 3.74628 19.0029 3.74628H8.99874ZM21.7537 9.07638V4.99711C21.7537 3.47787 20.5222 2.24628 19.0029 2.24628H8.99874C7.4795 2.24628 6.24791 3.47787 6.24791 4.99711V6.24792H4.99625C3.47746 6.24792 2.24625 7.47914 2.24625 8.99792V15.0021C2.24625 16.5209 3.47746 17.7521 4.99624 17.7521H6.24791V19.0029C6.24791 20.5222 7.4795 21.7538 8.99874 21.7538H19.0029C20.5222 21.7538 21.7537 20.5222 21.7537 19.0029V15.0037L21.7537 15.0013L21.7537 14.9989V9.08121L21.7537 9.0788L21.7537 9.07638ZM20.2537 9.8288V14.2513H13.7504V9.8288H20.2537ZM20.2537 15.7513H13.6471C13.3209 16.9059 12.2595 17.7521 11.0004 17.7521H7.74791V19.0029C7.74791 19.6938 8.30793 20.2538 8.99874 20.2538H19.0029C19.6937 20.2538 20.2537 19.6938 20.2537 19.0029V15.7513ZM12.2504 15.0001L12.2504 15.0013L12.2504 15.0021C12.2504 15.6924 11.6908 16.2521 11.0004 16.2521H4.99624C4.30589 16.2521 3.74625 15.6924 3.74625 15.0021V8.99792C3.74625 8.30757 4.30589 7.74792 4.99625 7.74792H6.99257L6.99791 7.74794L7.00325 7.74792H11.0004C11.6908 7.74792 12.2504 8.30757 12.2504 8.99792V9.07759L12.2504 9.0788L12.2504 9.08001V15.0001ZM10.3914 11.4919C10.6843 11.199 10.6843 10.7242 10.3914 10.4313C10.0985 10.1384 9.62367 10.1384 9.33077 10.4313L7.36006 12.402L6.38977 11.4317C6.09687 11.1388 5.622 11.1388 5.32911 11.4317C5.03621 11.7246 5.03621 12.1995 5.32911 12.4924L6.82973 13.993C6.97038 14.1336 7.16115 14.2127 7.36006 14.2127C7.55897 14.2127 7.74974 14.1336 7.89039 13.993L10.3914 11.4919Z"
      />
    </svg>
  );
};

export default ClipboardWithCheckmark;

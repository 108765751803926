import { RecipeType } from "@madhive/mad-sdk";
import { createSelector, defaultMemoize } from "reselect";
import { RootState } from "rootReducer";
import { Audience } from "types";

export const selectAllAudiencesById = (state: RootState) =>
  state.audiences.byId;

export const selectAllActiveAudiencesById = createSelector(
  selectAllAudiencesById,
  audiencesById =>
    Object.values(audiencesById).reduce<Record<string, Audience>>(
      (mapping, audience) => {
        /* eslint-disable-next-line */
        mapping[audience.id] = audience;

        return mapping;
      },
      {}
    )
);

export const selectAudienceIdsToNames = createSelector(
  selectAllAudiencesById,
  audiencesById =>
    Object.values(audiencesById).reduce<Record<string, string>>(
      (mapping, audience) => {
        /* eslint-disable-next-line */
        mapping[audience.id] = audience.label;

        return mapping;
      },
      {}
    )
);

export const selectAllStandardAudiencesById = createSelector(
  selectAllAudiencesById,
  audiencesById =>
    Object.values(audiencesById).reduce((acc, cur) => {
      if (cur.type === RecipeType.STANDARD) {
        acc[cur.id] = cur;
      }
      return acc;
    }, {} as Record<string, Audience>)
);

export const makeAudienceSelector = createSelector(
  selectAllAudiencesById,
  audiencesById =>
    defaultMemoize((id: string) => {
      const maybeAudience = audiencesById[id];
      // eslint-disable-next-line no-unneeded-ternary
      return maybeAudience ? maybeAudience : null;
    })
);

export const selectIsAllAudiencesLoading = (state: RootState) =>
  state.audiences.isAllLoading;

export const selectIsAllAudiencesLoaded = (state: RootState) =>
  !!state.audiences.receivedAllAt;

export const selectIsCachedAudiencesLoaded = (state: RootState) =>
  state.audiences.isCachedLoaded;

export const selectAudienceCreatingByRequestId = (state: RootState) =>
  state.audiences.creatingByRequestId;

export const selectIsAudiencesCreating = (state: RootState) =>
  !!Object.keys(selectAudienceCreatingByRequestId(state)).length;

export const makeIsAudienceCreatePendingSelector = createSelector(
  selectAudienceCreatingByRequestId,
  audienceCreatingByRequestId =>
    defaultMemoize(
      (requestId: string) => !!audienceCreatingByRequestId[requestId]
    )
);

export const selectAudiencePendingById = (state: RootState) =>
  state.audiences.pendingById;

export const makeIsAudiencePendingSelector = createSelector(
  selectAudiencePendingById,
  audiencePendingById =>
    defaultMemoize((audienceId: string) => !!audiencePendingById[audienceId])
);

import { AppThunk } from "types";
import { FilterTypes, User, OrganizationSettings } from "@madhive/mad-sdk";
import { madSDK } from "lib/sdk";
import { selectOrgSettings, selectOrgSettingsError } from "./selectors";
import {
  GET_ORG_SETTINGS_FAILURE,
  GET_ORG_SETTINGS_PENDING,
  GET_ORG_SETTINGS_SUCCESS
} from "./types";

const setGetOrganizationSettingsPending = () => ({
  type: GET_ORG_SETTINGS_PENDING
});

const setGetOrganizationSettingsSuccess = (
  orgSettings: OrganizationSettings
) => ({
  type: GET_ORG_SETTINGS_SUCCESS,
  payload: orgSettings
});

const setGetOrganizationSettingsFailure = (serverError?: string) => ({
  type: GET_ORG_SETTINGS_FAILURE,
  meta: {
    error: {
      message:
        serverError ||
        "There was an error retrieving the organization settings."
    }
  }
});

export const requestOrgSettings =
  (user: User | null): AppThunk<Promise<OrganizationSettings | undefined>> =>
  async (dispatch, getState) => {
    if (!user) {
      return Promise.resolve(undefined);
    }
    dispatch(setGetOrganizationSettingsPending());
    try {
      const organizationSettings = selectOrgSettings(getState());
      if (
        !selectOrgSettingsError(getState()) &&
        organizationSettings &&
        Object.keys(organizationSettings).length
      ) {
        return Promise.resolve(organizationSettings);
      }

      const orgSettings = await madSDK.organizations.settings.find_once({
        where: [
          {
            field: "id",
            type: FilterTypes.EQ,
            value: user.primaryOrganizationId
          }
        ]
      });

      if (!orgSettings) {
        return Promise.resolve(organizationSettings);
      }

      dispatch(setGetOrganizationSettingsSuccess(orgSettings));
      return Promise.resolve(orgSettings);
    } catch (error) {
      dispatch(setGetOrganizationSettingsFailure(error));
      throw error;
    }
  };

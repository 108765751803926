import { NotImplementedError } from "../../errors";
import { ServicePlanArchiveStatus } from "../../models/plans/index";
import { ObservableHandler } from "../handlers";
import axios from "axios";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import PlanPoller from "./poller";
import { PlanSaveFailed } from "./errors";
import Scenarios from "./scenarios";
class Plans extends ObservableHandler {
    constructor(sdk) {
        super(sdk, "planner");
        this.scenarios = new Scenarios(sdk);
    }
    instantiatePoller() {
        this.poller?.stop();
        this.poller = new PlanPoller(this.sdk, this.appliedFilters);
        this.sdk.legacy.plans.scenarios.setPlanPoll(this.poller);
        return this.poller.start();
    }
    findItems(filters) {
        this.appliedFilters = {
            currentPlanId: filters.where?.find((filter) => filter.field === "id")?.value,
            archiveStatus: filters.where?.find((filter) => filter.field === "status")
                ?.value,
            allUsers: filters.where?.find((filter) => filter.field === "allUsers")?.value,
            sinceDate: filters.where?.find((filter) => filter.field === "sinceDate")?.value
        };
        const subject = this.instantiatePoller();
        return this.appliedFilters.currentPlanId
            ? subject.pipe(map((plans) => [plans.find((plan) => plan.id === this.appliedFilters.currentPlanId)]))
            : subject;
    }
    async update(update) {
        await axios.put(`${this.collectionUrl}/${update.id}?skip_processing_if_possible=true`, update, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
    async create(plan) {
        await axios.post(this.collectionUrl, plan, {
            headers: {
                "Content-Type": "application/json"
            }
        });
    }
    async handleArchive(planId, action) {
        const ids = Array.isArray(planId) ? planId : [planId];
        await axios.post(`${this.sdk.urls.basePlannerServiceUrl}/plans/${action}`, { ids });
        this.poller?.restartPoll();
    }
    saveItem(plan) {
        let saveFn;
        if (Array.isArray(plan)) {
            // There is no 'bulk save' for plans. Any array of plans will be unarchived.
            saveFn = () => this.handleArchive(plan.map(({ id }) => id), "unarchive");
        }
        else if (plan.id && plan.status === ServicePlanArchiveStatus.ARCHIVED) {
            const planId = plan.id;
            saveFn = () => this.handleArchive(planId, "unarchive");
        }
        else if (plan.id) {
            const planId = plan.id;
            saveFn = () => this.update({ ...plan, id: planId });
        }
        else {
            saveFn = () => this.create(plan);
        }
        return new Observable((subscriber) => {
            saveFn()
                .then(() => {
                subscriber.next();
            })
                .catch(() => {
                subscriber.error(new PlanSaveFailed());
            })
                .finally(() => {
                subscriber.complete();
            });
        });
    }
    async refresh(planId) {
        return axios
            .post(`${this.collectionUrl}/${planId}/trigger-process`, {
            force_latest_build: true
        })
            .then(() => {
            this.poller?.restartPoll();
        });
    }
    async deleteItem(id) {
        return this.handleArchive(id, "archive");
    }
    get collectionUrl() {
        return `${this.sdk.urls.basePlannerServiceUrl}/plan`;
    }
    /**
     * Make isn't implemented for plans.
     */
    make() {
        throw new NotImplementedError("make");
    }
    // stops the poller if its instantiated
    complete() {
        this.poller?.stop();
    }
}
export default Plans;

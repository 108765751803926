import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Geo: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-geo"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.059 9.75A8.298 8.298 0 0 0 3.75 12c0 .78.108 1.535.309 2.25H7.93a14.053 14.053 0 0 1 0-4.5H4.059Zm.586-1.5H8.26a13.537 13.537 0 0 1 2.09-4.336A8.238 8.238 0 0 0 4.645 8.25ZM12 4.232A11.984 11.984 0 0 0 9.825 8.25h4.35A11.984 11.984 0 0 0 12 4.232Zm3.74 4.018a13.536 13.536 0 0 0-2.09-4.336 8.238 8.238 0 0 1 5.705 4.336H15.74Zm-1.193 1.5H9.453a12.542 12.542 0 0 0 0 4.5h5.094a12.542 12.542 0 0 0 0-4.5Zm1.522 4.5a14.054 14.054 0 0 0 0-4.5h3.872c.201.715.309 1.47.309 2.25s-.108 1.535-.309 2.25H16.07Zm-1.894 1.5h-4.35A11.983 11.983 0 0 0 12 19.768a11.984 11.984 0 0 0 2.175-4.018Zm-3.825 4.336a13.537 13.537 0 0 1-2.09-4.336H4.645a8.238 8.238 0 0 0 5.705 4.336Zm3.3 0a13.537 13.537 0 0 0 2.09-4.336h3.615a8.238 8.238 0 0 1-5.705 4.336ZM2.25 12A9.735 9.735 0 0 1 12 2.25 9.735 9.735 0 0 1 21.75 12 9.735 9.735 0 0 1 12 21.75 9.735 9.735 0 0 1 2.25 12Z"
      />
    </svg>
  );
};

export default Geo;

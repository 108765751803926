export enum FirestoreNotificationStatuses {
  IN_QUEUE = "IN_QUEUE",
  SENT = "SENT",
  ARCHIVED = "ARCHIVED"
}

export enum NotificationCategory {
  CREATIVE_DELIVERY = "creative-delivery",
  DOWNLOAD = "download",
  ACTION = "action",
  RELEASE = "release",
  MAINTENANCE = "maintenance",
  WORKFLOW = "workflow",
  BULK_CREATIVE_UPLOAD = "creative-upload",
  BULK_CAMPAIGN_UPLOAD = "campaign upload",
  BULK_EDIT_LINE_ITEM = "bulk-edit-line-item"
}

export enum NotificationActionCode {
  DOWNLOAD = 0,
  VIEW_CAMPAIGNS = 1,
  REMOVE_CAPS = 2,
  REMIND_ME = 3,
  WHATS_NEW = 4,
  LEARN_MORE = 5,
  SEND_TO = 6,
  VIEW_CREATIVES = 7
}

export enum ActionText {
  DOWNLOAD_REPORT = "Download Report",
  VIEW_CAMPAIGNS = "View Campaigns",
  REMOVE_CAPS = "Remove Caps",
  REMIND_ME = "Remind Me",
  LEARN_MORE = "Learn More",
  SEND_TO = "Send To",
  WHATS_NEW = "What's New",
  VIEW_CREATIVES = "View Creatives"
}

export const NOTIFICATION_CATEGORY_TO_TOAST_MESSAGE: Record<
  NotificationCategory,
  string
> = {
  [NotificationCategory.CREATIVE_DELIVERY]:
    "Your creative delivery report is ready for download.",
  [NotificationCategory.DOWNLOAD]: "Your report is ready for download.",
  [NotificationCategory.ACTION]: "",
  [NotificationCategory.RELEASE]: "",
  [NotificationCategory.MAINTENANCE]: "",
  [NotificationCategory.WORKFLOW]: "",
  [NotificationCategory.BULK_CREATIVE_UPLOAD]: "",
  [NotificationCategory.BULK_CAMPAIGN_UPLOAD]: "",
  [NotificationCategory.BULK_EDIT_LINE_ITEM]: ""
};

export const NOTIFICATION_ACTION_TYPE_TO_CUSTOM_ACTION: Record<
  NotificationActionCode,
  {
    buttonText: string;
  }
> = {
  [NotificationActionCode.DOWNLOAD]: {
    buttonText: ActionText.DOWNLOAD_REPORT
  },
  [NotificationActionCode.VIEW_CAMPAIGNS]: {
    buttonText: ActionText.VIEW_CAMPAIGNS
  },
  [NotificationActionCode.REMOVE_CAPS]: {
    buttonText: ActionText.REMOVE_CAPS
  },
  [NotificationActionCode.REMIND_ME]: {
    buttonText: ActionText.REMIND_ME
  },
  [NotificationActionCode.WHATS_NEW]: {
    buttonText: ActionText.WHATS_NEW
  },
  [NotificationActionCode.LEARN_MORE]: {
    buttonText: ActionText.LEARN_MORE
  },
  [NotificationActionCode.SEND_TO]: {
    buttonText: ActionText.SEND_TO
  },
  [NotificationActionCode.VIEW_CREATIVES]: {
    buttonText: ActionText.VIEW_CREATIVES
  }
};

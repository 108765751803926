import { Dispatch, SetStateAction, FC, ChangeEvent } from "react";
import { Icon, IconSize, InputGroup } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import { sanitizeUserInput, ViewId } from "@madhive/mad-sdk";
import { selectCurrentUser } from "appReducers/authReducer";
import { copyAppliedFiltersToSelectedFilters } from "appReducers/campaignsReducer/dashboard";
import { ManageCampaignsInstructionFilter } from "campaign-types";
import usePermissionView from "components/Auth/usePermissionView";
import ButtonGroup from "components/Reusable/ButtonGroup";
import PageHeader from "components/Reusable/SmithersPageHeader/PageHeader";
import useDebouncedSearch from "hooks/useDebouncedSearch";
import { RemoteConfigProperties, useFeatureFlag } from "hooks/useFeatureFlag";
import { ManageCampaignsEvents } from "lib/constants/events";
import { ManageCampaignsRoute } from "lib/constants/routes";
import { logEvent } from "lib/utils/analytics";
import {
  Button,
  ButtonColor,
  ButtonVariant,
  Tab,
  Tabs
} from "madhive/components";
import { useDispatch, useSelector } from "react-redux";

interface Props {
  updateSelectedTabId?: (tab: ManageCampaignTabId) => void;
  selectedTabId?: ManageCampaignTabId;
  campaignsTab?: string;
  lineItemsTab?: string;
  isLineItemsTabVisible: boolean;
  setIsCreateCampaignDialogOpen: Dispatch<boolean>;
  isMarginHidden?: boolean;
  appliedFilters: ManageCampaignsInstructionFilter[];
  isBulkEditing: boolean;
  setAppliedFilters: Dispatch<ManageCampaignsInstructionFilter[]>;
  setIsSettingsDrawerOpen: Dispatch<boolean>;
  setIsFilterDialogOpen: Dispatch<boolean>;
  searchTerm: string;
  setSearchTerm: Dispatch<string>;
  setRestrictedViewDialog: Dispatch<SetStateAction<boolean>>;
}
export enum ManageCampaignTabId {
  CAMPAIGNS = "campaigns",
  LINE_ITEMS = "lineItems"
}

const MainScreenHeader: FC<Props> = props => {
  const {
    appliedFilters,
    isBulkEditing,
    setAppliedFilters,
    setIsSettingsDrawerOpen,
    setIsFilterDialogOpen,
    searchTerm,
    setSearchTerm,
    setRestrictedViewDialog
  } = props;

  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const [rawSearchTerm, setRawSearchTerm] = useDebouncedSearch(
    setSearchTerm,
    searchTerm
  );

  const { readOnly } = usePermissionView(ViewId.MANAGE_CAMPAIGNS);

  const fireSearchEvent = () => {
    const event =
      props.selectedTabId &&
      props.selectedTabId === ManageCampaignTabId.CAMPAIGNS
        ? ManageCampaignsEvents.SEARCHED_CAMPAIGN
        : ManageCampaignsEvents.SEARCHED_LINE_ITEM;

    logEvent(event);
  };

  if (!user) {
    throw Error("missing user ...");
  }

  const FILTER_SEPARATOR = "_____";

  const shouldCampaignUploadBeVisible = useFeatureFlag(
    RemoteConfigProperties.CAMPAIGN_UPLOAD
  );

  const filters = appliedFilters.map(
    (el: ManageCampaignsInstructionFilter) => ({
      id: el.category + FILTER_SEPARATOR + el.value,
      label: `${el.category}: ${el.label}`
    })
  );

  if (searchTerm.length > 0) {
    filters.push({
      id: "search-filter",
      label: `Search: ${searchTerm}`
    });
  }

  return (
    <>
      <PageHeader.Wrapper
        fullWidth
        lowPadding
        headerOptions={{
          shouldHideFiltersCategoryLabel: true,
          hasRoundedCorners: false
        }}
        onClearAll={() => {
          setRawSearchTerm("");
          setAppliedFilters([]);
        }}
        appliedFilters={filters}
        onFilterClick={id => {
          if (id === "search-filter") {
            setRawSearchTerm("");
          } else {
            const [category, value] = id.split(FILTER_SEPARATOR);
            setAppliedFilters(
              appliedFilters.filter((el: ManageCampaignsInstructionFilter) => {
                // Dont remove the filter if the filter we need to remove is for a different category
                if (el.category !== category) {
                  return true;
                }

                return el.value !== value;
              })
            );
          }
        }}
        style={{ marginBottom: "0px" }}
      >
        <PageHeader.Title data-testid="manage-campaigns-header">
          Campaigns
        </PageHeader.Title>
        <PageHeader.ActionsContainer>
          <ButtonGroup fullHeight>
            <InputGroup
              id="manage-campaigns-screen-search"
              data-testid="manage-campaigns-screen-search"
              large
              leftIcon={IconNames.SEARCH}
              placeholder="Search..."
              round
              onChange={(ev: ChangeEvent<HTMLInputElement>) => {
                const sanitizedUserInput = sanitizeUserInput(ev.target.value);
                setRawSearchTerm(sanitizedUserInput);
              }}
              value={rawSearchTerm}
              onBlur={() => fireSearchEvent()}
            />
            <Button
              name="manage-campaigns-filter-drawer-toggle"
              variant={ButtonVariant.ICON}
              icon={
                <Icon
                  icon={IconNames.FILTER_LIST}
                  iconSize={IconSize.LARGE}
                  className="Smithers--semiDark"
                />
              }
              onClick={() => {
                dispatch(copyAppliedFiltersToSelectedFilters());
                setIsFilterDialogOpen(true);
              }}
            />
            <Button
              name="manage-campaigns-settings-drawer-toggle"
              variant={ButtonVariant.ICON}
              icon={
                <Icon
                  icon={IconNames.COG}
                  iconSize={IconSize.LARGE}
                  className="Smithers--semiDark"
                />
              }
              onClick={() => setIsSettingsDrawerOpen(true)}
              data-testid="manage-campaigns-settings-drawer-toggle"
            />
            {!isBulkEditing && !readOnly ? (
              <Button
                name="manage-campaigns-add-campaign"
                data-testid="manage-campaigns-add-campaign"
                color={ButtonColor.PRIMARY}
                onClick={e => {
                  if (readOnly) {
                    setRestrictedViewDialog(true);
                    return;
                  }

                  if (shouldCampaignUploadBeVisible) {
                    props.setIsCreateCampaignDialogOpen(true);
                  }
                  logEvent("click_add_campaign", { event: e });
                }}
                href={
                  shouldCampaignUploadBeVisible
                    ? ""
                    : ManageCampaignsRoute.CREATE_CAMPAIGN
                }
                variant={ButtonVariant.CONTAINED}
                icon={<Icon icon={IconNames.PLUS} iconSize={IconSize.LARGE} />}
              >
                Create Campaign
              </Button>
            ) : null}
          </ButtonGroup>
        </PageHeader.ActionsContainer>
      </PageHeader.Wrapper>

      <PageHeader.Wrapper
        data-testid="manage-campaigns-tab-header"
        lowPadding
        style={{
          borderTop: "1px solid var(--border-light)",
          borderBottom: "1px solid var(--border-light)",
          marginBottom: "0px"
        }}
      >
        <Tabs
          id="manage-campaigns-tab-wrapper"
          data-testid="manage-campaigns-tab-wrapper"
          selectedTabId={props.selectedTabId}
          onChange={newTab => {
            if (newTab && props.updateSelectedTabId) {
              const eventName =
                newTab === ManageCampaignTabId.CAMPAIGNS
                  ? ManageCampaignsEvents.CLICKED_CAMPAIGNS_TAB
                  : ManageCampaignsEvents.CLICKED_LINE_ITEMS_TAB;

              logEvent(eventName);
              props.updateSelectedTabId(newTab as ManageCampaignTabId);
            }
          }}
        >
          <Tab
            tabPanelId={`${ManageCampaignTabId.CAMPAIGNS}-panel`}
            data-testid="campaigns-tab"
            id={ManageCampaignTabId.CAMPAIGNS}
            title={props.campaignsTab ? props.campaignsTab : "Campaigns"}
          />
          {props.isLineItemsTabVisible ? (
            <Tab
              tabPanelId={`${ManageCampaignTabId.LINE_ITEMS}-panel`}
              data-testid="lineItems-tab"
              id={ManageCampaignTabId.LINE_ITEMS}
              title={props.lineItemsTab ? props.lineItemsTab : "Line Items"}
            />
          ) : null}
        </Tabs>
      </PageHeader.Wrapper>
    </>
  );
};

export default MainScreenHeader;

import { firstValueFrom } from "rxjs";
import { Hydrater } from "../handlers";
/**
 * This hydrater is temporary; it should be replaced once campaignmanager is
 * populating dma names for stations, and Burns is reading those names from
 * campaignmanager. Currently Burns uses deprecated, hardcoded DMA names that
 * have caused confusion for clients.
 * */
export class StationsHydrater extends Hydrater {
    async hydrateItems(stations, fields) {
        return new Promise((resolve) => {
            if (stations.length === 0 || fields.length === 0) {
                resolve(stations);
            }
            else {
                if (stations.length === 0) {
                    resolve([]);
                }
                firstValueFrom(this.sdk.geo.find()).then((geo) => {
                    resolve(stations.map((station) => {
                        station.dmaName = geo.get("US")?.dmas.get(station.dmaId || "")?.name || "--";
                        return station;
                    }));
                });
            }
        });
    }
}

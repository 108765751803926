import { AttributionDashboardFailedFetchError, UnauthenticatedError } from "../../errors";
import axios from "axios";
import { FilterTypes, FilterValueTypes, validateFilterStructure } from "../../modules/handlers/filter";
import { Handler } from "../handlers";
import { Aggregator } from "./aggregator";
import { toQueryParams, validateFilterContent } from "./query";
import { AttributionPreFilters } from "./preFilters";
import { AttributionTableDateRange } from "./tableDateRange";
import { RemoteConfigProperties } from "../../types";
export default class AttributionDashboard extends Handler {
    constructor(sdk) {
        super(sdk, "attributionDashboard");
        this.aggregator = new Aggregator(sdk, this);
        this.preFilters = new AttributionPreFilters(this.sdk);
        this.tableDateRange = new AttributionTableDateRange(this.sdk);
    }
    async findItems(filters, 
    // TO-DO: ask backend for asc/desc instead of +/- for sort
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sort) {
        const email = this.sdk.getCurrentUser()?.email;
        if (!email) {
            throw new UnauthenticatedError();
        }
        validateFilterStructure(filters, [
            { filterType: FilterTypes.EQ, valueType: FilterValueTypes.STRING },
            { filterType: FilterTypes.EQ, valueType: FilterValueTypes.OBJECT },
            { filterType: FilterTypes.IN, valueType: FilterValueTypes.OBJECT }
        ]);
        validateFilterContent(filters);
        const url = `${this.sdk.urls.advancedQueryToolBaseUrl}/v2/attribution-dashboard?${toQueryParams(
        // email filter currently necessary for server side authorization
        {
            ...filters,
            // we can rely on filters.where to be defined since validateFilterContent will throw an error if it's not
            where: [...filters.where, { field: "email", type: FilterTypes.EQ, value: email }]
        }, this.sdk.featureFlags.isFlagEnabled(RemoteConfigProperties.CONVERSIONS_BLACKLIST))}`;
        return this.cache
            .promise(url, () => axios.get(url).then(({ data }) => [
            {
                params: data.params,
                data: data.data || [],
                metadata: data.metadata
            }
        ]))
            .catch((error) => {
            throw new AttributionDashboardFailedFetchError(error);
        });
    }
    saveItem() {
        throw new Error("Method not implemented.");
    }
    deleteItem() {
        throw new Error("Method not implemented.");
    }
}

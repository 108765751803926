import { RootState } from "rootReducer";

export const selectLastReceivedAllLineItems = (state: RootState) =>
  state.lineItems.receivedAllAt;

export const selectCreateLineItemsCancellationSource = (state: RootState) =>
  state.lineItems.loadCreateCancellationSource;

export const selectLoadArchiveLineIitemCancellationSource = (
  state: RootState
) => state.lineItems.loadArchiveCancellationSource;

export const selectUpdateLineItemsCancellationSource = (state: RootState) =>
  state.lineItems.loadUpdateCancellationSource;

export const selectIsLineItemSaving = (state: RootState) =>
  state.lineItems.isSaving;

export const selectLineItemsById = (state: RootState) => state.lineItems.byId;

export const selectLineItemDetails = (state: RootState) =>
  state.lineItems.details;

export const selectBulkLineItems = (state: RootState) =>
  state.lineItems.selectedForBulkAction;

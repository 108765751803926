import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Deals: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-deals"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.8404 6.74496C15.2301 6.3592 15.8576 6.35868 16.248 6.7434C16.2485 6.74392 16.2491 6.74444 16.2496 6.74496C16.2505 6.74586 16.2514 6.74676 16.2523 6.74767M16.2539 6.74924C16.6432 7.14025 16.6427 7.77196 16.2523 8.16233C15.8614 8.55322 15.2286 8.55322 14.8377 8.16233C14.4468 7.77144 14.4468 7.13856 14.8377 6.74767L14.8404 6.74496M16.2539 6.74924C16.2534 6.74871 16.2528 6.74819 16.2523 6.74767L16.2539 6.74924Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.512 3.75C13.1543 3.75 12.81 3.8927 12.5563 4.14633L4.14632 12.5563C3.61822 13.0844 3.61822 13.9406 4.14632 14.4687L8.53132 18.8537C9.05943 19.3818 9.91556 19.3818 10.4437 18.8537L18.853 10.4443C18.8529 10.4444 18.8531 10.4443 18.853 10.4443C19.1074 10.1892 19.25 9.84525 19.25 9.487V5.102C19.25 4.35545 18.645 3.75 17.897 3.75H13.512ZM11.4957 3.08567C12.03 2.5513 12.7557 2.25 13.512 2.25H17.897C19.473 2.25 20.75 3.52655 20.75 5.102V9.487C20.75 10.2447 20.4485 10.9688 19.915 11.5037L11.5043 19.9143C10.3904 21.0282 8.58456 21.0282 7.47066 19.9143L3.08566 15.5293C1.97177 14.4154 1.97177 12.6096 3.08566 11.4957L11.4957 3.08567Z"
      />
    </svg>
  );
};

export default Deals;

/** inventory_set can be added to many of our main data types — such as: insts, products, deals, etc. */
/**
 * Convert a ServiceInventorySet to InventorySet.
 * @param inventory_set The ServiceInventorySet to convert.
 * @returns The InventorySet.
 */
export const serviceToInventorySet = (inventory_set) => ({
    includeIds: inventory_set?.include_ids,
    excludeIds: inventory_set?.exclude_ids,
    genres: inventory_set?.genres,
    languages: inventory_set?.languages
});
/**
 * Convert an InventorySet to ServiceInventorySet.
 * @param inventorySet The InventorySet to convert.
 * @returns The ServiceInventorySet.
 */
export const inventorySetToService = (inventorySet) => ({
    include_ids: inventorySet?.includeIds,
    exclude_ids: inventorySet?.excludeIds,
    genres: inventorySet?.genres,
    languages: inventorySet?.languages
});

import { parseInclusionFilter } from "../modules/handlers/filter";
import { objectLookup } from "../utils";
import { atomization, categorize } from "./atomizer";
import { BadItemKey, CacheKeyError, InvalidCacheSpecifier } from "./errors";
import { Reaper } from "./reaper";
export * from "./atomizer";
export * from "./errors";
export * from "./global";
export * from "./types";
export default class Cache {
    constructor(sdk, namespace, config) {
        /** pending promises */
        this.pending = new Map();
        this.config = {
            ttl: config?.ttl || 5000,
            atomize: this.deriveAtomizer(config),
            categorize: config?.categorize || categorize,
            keypath: config?.keypath || "id"
        };
        this.reaper = new Reaper(sdk, this.config.ttl);
        this.sdk = sdk;
        this.namespace = namespace;
        if (this.namespace.includes("::")) {
            throw new InvalidCacheSpecifier(this.namespace);
        }
        setInterval(() => this.reaper.trim(), this.config.ttl * 1000);
    }
    /**
     * Sets the given `value` to be associated with `key` within the cache.
     * @param key: a unique identifier for `value`.
     * @param value: the value to associate with `key`.
     * @param options: options to control the cache's handling of `value`.
     * @param options.atomize: defaults to false. If true - and the cache has been configed with an atomizer - this result will be atomized, and its individual items will be cached, too. Otherwise, no effect.
     */
    set(key, value, options) {
        const cacheKey = this.getCacheKey(key);
        const injected = new Set();
        this.sdk.caches.set(cacheKey, value);
        injected.add(cacheKey);
        if (options?.atomize && typeof this.config.atomize === "function") {
            const iterator = this.config.atomize(value);
            let item = iterator.next();
            while (!item.done) {
                const { key, value } = item.value;
                const atomCacheKey = this.getCacheKey(key);
                this.sdk.caches.set(atomCacheKey, value);
                injected.add(atomCacheKey);
                item = iterator.next();
            }
        }
        this.reaper.track(injected);
    }
    /**
     * Removes the value(s) associated with the given key/regex (if any).
     * @param key: a unique identifier for a value in the cache - or a regular expression, matching many keys.
     * @return: true if the key exists and was removed. Else, false.
     */
    remove(key) {
        const cacheKey = this.getCacheKey(key);
        this.reaper.remove(cacheKey);
        return this.sdk.caches.remove(cacheKey);
    }
    /**
     * Clears all entries from the cache.
     * @param category: optional. If provided, will only clear entries with this category.
     */
    clear(category) {
        this.sdk.caches.clear(this.namespace, category);
        this.reaper.clear(category);
    }
    /**
     * @param key: a unique identifier for a value in the cache.
     * @return: true if there is some value associated with this key. Else, false.
     */
    has(key) {
        const cacheKey = this.getCacheKey(key);
        return this.sdk.caches.has(cacheKey);
    }
    /**
     * @param key: a unique identifier for a value in the cache.
     * @return: the given value, if one exists. TODO: this should return undefined instead of throwing if it doesn't exist
     */
    // TODO: this should return `undefined`, instead of throwing, on a bad key
    get(key) {
        const cacheKey = this.getCacheKey(key);
        const value = this.sdk.caches.get(cacheKey);
        if (value) {
            return value;
        }
        throw new CacheKeyError(cacheKey);
    }
    /**
     * @param filters: the filters to apply (if any).
     * @return: if the result set matching the given filters can be coalesced from items in the cache, then an array of the result set. Else, `null`.
     */
    pluck(filters) {
        const uniques = parseInclusionFilter(filters, this.config.keypath, ["string"]);
        if (uniques.size === 0) {
            return null;
        }
        const plucked = [];
        for (const unique of uniques) {
            if (this.has(unique)) {
                plucked.push(this.get(unique));
            }
            else {
                // as soon as we know we can't fulfill the entire set, we give up
                return null;
            }
        }
        return plucked;
    }
    /**
     * Promise to obtain the value for the given key, if it does not exist.
     * @param key: a unique identifier for a value in the cache.
     * @param fallback: function to obtain the value to be associated with that key.
     * @param options: options to control the cache's handling of the result.
     * @param options.atomize: defaults to true. If true - and the cache has been configed with an atomizer - this result will be atomized, and its individual items will be cached, too. Otherwise, no effect.
     * @return: a promise which resolves to the cached item.
     */
    promise(key, fallback, options = { atomize: true }) {
        if (this.has(key)) {
            return Promise.resolve(this.get(key));
        }
        if (this.pending.has(key)) {
            return this.pending.get(key);
        }
        return this.commit(key, fallback(), options);
    }
    /**
     * Reconciles the cache with an update, indicated by `type` and `changed`.
     *   - all resources will be cleared
     *   - for atoms:
     *     - if `changed` is an atom or set of atoms and atomization is supported, the atom(s) will either be updated in the cache (on `save`), or removed from it (on `delete`)
     *     - otherwise, atoms are unaffected
     * @type: the type of update this is.
     * @param changed: presumably, an atom or array of atoms that have been updated in some way.
     */
    reconcile(type, changed) {
        this.clear("resources");
        // we can skip over atom-related logic if we're not atomizing
        if (!this.config.atomize) {
            return;
        }
        const items = Array.isArray(changed) ? changed : [changed];
        for (const item of items) {
            const key = objectLookup(item, this.config.keypath);
            // it's a valid atom if the key exists according to keypath
            if (typeof key === "string") {
                switch (type) {
                    case "save":
                        this.set(key, item);
                        break;
                    case "delete":
                        this.remove(key);
                        break;
                }
            }
        }
    }
    /**
     * Resolves the input promise, caching the given result, and caching its atomization (if any).
     * @param key: a unique identifier for a value in the cache.
     * @param promised: the promise resolving in the item to cache.
     * @param options: options to control the cache's handling of the result.
     * @param options.atomize: if true - and the cache has been configed with an atomizer - this result will be atomized, and its individual items will be cached, too. Otherwise, no effect.
     * @return: the resulting promise.
     */
    commit(key, promised, options) {
        this.pending.set(key, promised);
        return promised
            .then((resource) => {
            if (typeof resource !== "undefined") {
                this.set(key, resource, options);
            }
            return resource;
        })
            .finally(() => {
            this.pending.delete(key);
        });
    }
    /**
     * @param key: a unique identifier for a value in the cache.
     * @return: the key, namespaced as appropriate for the global set of caches.
     */
    getCacheKey(key) {
        if (key.includes("::")) {
            throw new InvalidCacheSpecifier(key);
        }
        return `${this.namespace}::${this.config.categorize(key)}::${key}`;
    }
    /**
     * If config'd to `true` for atomization, we use a default atomizer.
     * If config'd to a function, we use that.
     * Otherwise, we set atomization to false.
     * @param config: the cache configuration.
     * @return: the proper atomization settings, based on the config.
     */
    deriveAtomizer(config) {
        const { keypath, atomize } = config || {};
        let atomizer = false;
        if (typeof atomize === "boolean" && atomize) {
            let key;
            if (typeof keypath === "string" && keypath.length) {
                key = (item) => {
                    const value = objectLookup(item, keypath);
                    if (!value) {
                        throw new BadItemKey(keypath);
                    }
                    return value;
                };
            }
            atomizer = atomization({ key });
        }
        else if (typeof atomize === "function") {
            atomizer = atomize;
        }
        return atomizer;
    }
}

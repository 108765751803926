import axios from "axios";
import { organizationToServiceOrganizationBase, serviceOrganizationToOrganization } from "../../models/organization";
import { Handler } from "../handlers";
import Settings from "./settings";
import { MadSDKAbortError, NotImplementedError } from "../../errors";
import { parseNewNewYorkApiResponse } from "../../utils";
import { OrganizationFetchFailed, OrganizationSaveFailure } from "./errors";
import Images from "./images";
/**
 * Orginizations collection handles organizations-related activities. Currently, supports only .save and .find.
 */
class Organizations extends Handler {
    /**
     * Handlers need access to MadSDK
     * @param sdk Instance of MadSDK to use for lookups
     */
    constructor(sdk) {
        super(sdk, "organizations");
        /**
         * Used to force services
         */
        this.updateServiceCache = async () => {
            return axios
                .get(`${this.sdk.urls.burnsBaseUrl}/users/cache/update`, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((res) => res.data);
        };
        this.settings = new Settings(sdk);
        this.images = new Images(sdk);
        this.url = `${this.sdk.urls.baseAPIUrl}/organization`;
    }
    async findItems(filters) {
        let url = this.url;
        const idFilter = filters.where ? filters.where.find((filter) => filter.field === "id") : null;
        if (idFilter) {
            url = `${this.url}/${idFilter.value}`;
            try {
                const res = await axios.get(url, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                });
                const { data: rawOrganization } = parseNewNewYorkApiResponse(res);
                return [serviceOrganizationToOrganization(rawOrganization)];
            }
            catch (error) {
                throw new OrganizationFetchFailed(error);
            }
        }
        // When no filters are given we should fetch all organizations using /organizations. This will only return
        // the organizations that the user has access for.
        url = `${this.url}s`;
        try {
            const res = await axios.get(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const orgs = [];
            const { data: rawOrganizations } = parseNewNewYorkApiResponse(res);
            rawOrganizations.forEach((org) => {
                orgs.push(serviceOrganizationToOrganization(org));
            });
            return orgs;
        }
        catch (error) {
            throw new OrganizationFetchFailed(error);
        }
    }
    async saveItem(org) {
        return new Promise((resolve, reject) => {
            axios
                .post(this.url, organizationToServiceOrganizationBase(org), {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then(async (res) => {
                const { data: rawOrganization } = parseNewNewYorkApiResponse(res);
                // this can change user settings to update backend cache
                const user = await this.updateServiceCache();
                this.sdk.authentication.refreshUserInfo(user);
                resolve(serviceOrganizationToOrganization(rawOrganization));
            })
                .catch((error) => {
                if (axios.isCancel(error)) {
                    reject(new MadSDKAbortError());
                }
                else {
                    reject(new OrganizationSaveFailure(org.name, error));
                }
            });
        });
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}
export default Organizations;

import { MhBaseProps } from "../types";

export enum SortDirection {
  ASCENDING = "ascending",
  DESCENDING = "descending"
}

export type RenderCell = (
  rowIndex: number,
  columnIndex: number,
  subrowIndex?: number
) => JSX.Element;

export interface StickyColumn {
  columnIndex: number;
  left: number;
}

export interface TableColumn {
  id: string;
  title: string;
  description?: string;
  sortable: boolean;
  width?: number;
  sticky?: boolean;
  defaultSort?: boolean;
  sortFunction?: (direction: SortDirection) => void;
}

export interface NoSelectItemProps {
  toggleSelected?: never;
  selectedIds?: never;
  disableSubRowSelection?: never;
}

export type ToggleSelected = (item?: any, isHeader?: boolean) => void;

export interface SelectItemProps {
  /**
   * @param toggleSelected called in the onChange of whichever checkbox is
   * clicked by user.
   * @param item currently is 'any' but should be of same type as T in table
   * data T[]. Item corresponds to the data in the row clicked by user.
   * @param isHeader toggleSelected will be invoked with isHeader = true when
   * the checkbox in the header row is clicked. Indeterminate state of header
   * checkbox is handled in Table/row/tsx
   */
  toggleSelected: ToggleSelected;
  /**
   * @param itemKey Should correspond to the property name on the row
   * that you use to keep track of selected state in selecetedIds. Generally,
   * this should just be "id", if you're using selectedIds to match row ids to
   * their selction state
   */
  itemKey: string;
  /**
   * @param selectedIds mapping of a unique row value (so, 9/10 times it'll be
   * 'id') to a boolean determining whether or not the row checkbox is selected
   */
  selectedIds: Record<string, boolean>;
  /**
   * @param disableSubRowSelection if true, subrows will not be selectable
   */
  disableSubRowSelection?: boolean;
}

export interface TableBaseProps {
  columns: TableColumn[];
  numberOfRows?: number;
  possibleNumberOfRows?: number[];
  data: any[];
  canFixColumns?: boolean;
  canFixRows?: boolean;
  onSort?: (
    columnId: string,
    columnIndex: number,
    direction: SortDirection
  ) => void; // either pass this or sorting function in columns
  renderRow?: (item: any) => JSX.Element[];
  renderSubrow?: (item: any) => JSX.Element[];
  renderCell?: RenderCell;
  renderFooter?: (items: any[]) => JSX.Element[];
  rightMenuContentRenderFn?: (...args: any) => JSX.Element;
  scrollParentClassName?: string;
  isSortedExternally?: boolean;
  maxHeightCssString?: string;
  /**
   * @param isRowHeightDynamic allows rows to expand beyond their
   * default height of 40px. Append a .height property to any
   * elements in data[] that should be expanded. .height must
   * reflect the current height of the row, not the desired height—
   * the easiest way to calculate this is to attach a ref to the row
   * content and call ref.current?.getBoundingClientRect().height
   */
  isRowHeightDynamic?: boolean;
  itemKey?: string;
  subRowKey?: string;
  rowClassname?: (item: any) => string | undefined;
  rowHeight?: number;
  onRowClick?: (item: any) => void;
  renderDependencies?: any[];
}

type TableBaseWithSelection =
  | (TableBaseProps & NoSelectItemProps)
  | (TableBaseProps & SelectItemProps);

export type TableProps = MhBaseProps<TableBaseWithSelection>;

import Cookies from "js-cookie";
class CookieStorage {
    /**
     * The access_token cookie is a custom firebase auth token set during the SSO process.
     * @return: the cookie, if it is defined in the document. Else, null.
     */
    get() {
        let cookie = null;
        if (typeof document !== "undefined" && typeof document.cookie !== "undefined") {
            // .replace with $1 will return either the matching capture group text, or empty string
            const accessTokenValue = document.cookie.replace(/(?:(?:^|.*;\s*)access_token\s*=\s*([^;]*).*$)|^.*$/, "$1");
            if (accessTokenValue !== "") {
                cookie = accessTokenValue;
            }
        }
        return cookie;
    }
    /** sets the cookie to expire immediately, if it is present in the document */
    clear() {
        if (typeof document !== "undefined") {
            const accessToken = document.cookie
                ? document.cookie.replace(
                // eslint-disable-next-line no-useless-escape
                /(?:(?:^|.*;\s*)access_token\s*\=\s*([^;]*).*$)|^.*$/, "$1")
                : "";
            if (accessToken) {
                document.cookie = `access_token=${accessToken}; expires=${new Date(0).toUTCString()};`;
            }
        }
    }
}
class TokenStorage {
    constructor() {
        this.EXPIRE_TIME = 180;
    }
    getDomain() {
        if (window === null) {
            return "localhost";
        }
        const matches = window.location.hostname.match(/^(?:.*?\.)?([a-zA-Z0-9\-_]{3,}\.(?:\w{2,8}|\w{2,4}\.\w{2,4}))$/);
        if (matches === null) {
            return "localhost";
        }
        return matches[1];
    }
    /**
     * @return: either the token, or undefined, if it is not present in localStorage and/or the window is undefined.
     */
    get() {
        if (typeof document === "undefined" || typeof window === "undefined") {
            return undefined;
        }
        // first check localStorage for auth in case they were already logged in
        let token = window.localStorage.getItem("MAD-AUTH");
        if (token && token !== "undefined") {
            // store in the new storage and clear this storage
            this.set(token);
            window.localStorage.removeItem("MAD-AUTH");
        }
        // now lets get the cookie version
        token = Cookies.get("MAD-AUTH");
        if (token && token !== "undefined") {
            return token;
        }
        return undefined;
    }
    /**
     * @param token: the token to set. If undefined is provided, the token will be removed from local storage.
     */
    set(token) {
        if (typeof document === "undefined" || typeof window === "undefined") {
            return;
        }
        if (token) {
            const detectedDomain = this.getDomain();
            if (detectedDomain === "localhost" || detectedDomain === "run.app") {
                Cookies.set("MAD-AUTH", token, { expires: this.EXPIRE_TIME, secure: true });
            }
            else {
                Cookies.set("MAD-AUTH", token, {
                    domain: detectedDomain,
                    expires: this.EXPIRE_TIME,
                    secure: true
                });
            }
        }
        else {
            Cookies.remove("MAD-AUTH");
        }
    }
    clear() {
        const detectedDomain = this.getDomain();
        if (detectedDomain === "localhost" || detectedDomain === "run.app") {
            Cookies.remove("MAD-AUTH");
        }
        else {
            Cookies.remove("MAD-AUTH", { domain: detectedDomain });
        }
    }
}
export default class BrowserCredentialStorage {
    constructor() {
        this.cookie = new CookieStorage();
        this.token = new TokenStorage();
    }
}

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const UserWithSetting: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-user-with-setting"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.00051 2.49245C8.65711 0.835849 11.343 0.835849 12.9996 2.49245C14.6562 4.14906 14.6562 6.83494 12.9996 8.49154C11.343 10.1481 8.65711 10.1481 7.00051 8.49154C5.34391 6.83494 5.34391 4.14906 7.00051 2.49245ZM8.06117 3.55311C9.13199 2.4823 10.8681 2.4823 11.9389 3.55311C13.0098 4.62393 13.0098 6.36007 11.9389 7.43088C10.8681 8.5017 9.13199 8.5017 8.06117 7.43088C6.99035 6.36007 6.99035 4.62393 8.06117 3.55311Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.75 15C17.75 14.5858 17.4142 14.25 17 14.25C16.5858 14.25 16.25 14.5858 16.25 15V15.0608C16.0489 15.139 15.863 15.2474 15.6977 15.3805L15.6408 15.3481C15.2808 15.1433 14.8229 15.2691 14.618 15.6291C14.4132 15.9891 14.539 16.447 14.899 16.6519L14.9454 16.6783C14.9286 16.7847 14.9199 16.8938 14.9199 17.005C14.9199 17.113 14.9281 17.219 14.944 17.3225L14.899 17.3481C14.539 17.553 14.4132 18.0109 14.618 18.3709C14.8229 18.7309 15.2808 18.8567 15.6408 18.6519L15.6905 18.6236C15.8575 18.7594 16.046 18.8698 16.25 18.9491V19C16.25 19.4142 16.5858 19.75 17 19.75C17.4142 19.75 17.75 19.4142 17.75 19V18.9529C17.9563 18.874 18.1468 18.7632 18.3155 18.6266L18.3543 18.6491C18.7127 18.8566 19.1716 18.7342 19.3791 18.3758C19.5866 18.0173 19.4643 17.5585 19.1058 17.3509L19.0651 17.3274C19.0815 17.2223 19.0899 17.1146 19.0899 17.005C19.0899 16.8922 19.081 16.7814 19.0637 16.6734L19.1058 16.6491C19.4643 16.4415 19.5866 15.9827 19.3791 15.6242C19.1716 15.2658 18.7127 15.1434 18.3543 15.3509L18.3083 15.3775C18.1414 15.2436 17.9533 15.1349 17.75 15.0571V15ZM17.5302 17.2627C17.5685 17.1849 17.5899 17.0975 17.5899 17.005C17.5899 16.682 17.3281 16.4201 17.0051 16.42C17.0036 16.42 17.0021 16.42 17.0006 16.42C16.6795 16.4223 16.4199 16.6833 16.4199 17.005C16.4199 17.0972 16.4413 17.1845 16.4793 17.262C16.4871 17.2741 16.4946 17.2865 16.5018 17.2991C16.5076 17.3093 16.5131 17.3196 16.5184 17.3299C16.6087 17.4648 16.7536 17.5601 16.9215 17.5841C16.9473 17.5814 16.9735 17.58 17 17.58C17.0284 17.58 17.0565 17.5816 17.0841 17.5847C17.254 17.5617 17.4008 17.4657 17.4918 17.3294C17.4979 17.3175 17.5042 17.3058 17.511 17.2942C17.5172 17.2835 17.5236 17.273 17.5302 17.2627Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 11.241C7.86705 11.241 5.73203 11.757 4.10462 12.7045C2.48403 13.6481 1.25 15.1018 1.25 16.983V17.983C1.25 18.9492 2.03379 19.733 3 19.733H11.9397C12.9122 21.5299 14.8138 22.75 17 22.75C20.1763 22.75 22.75 20.1751 22.75 17C22.75 13.8262 20.1788 11.2525 17.0048 11.25C16.0252 11.249 15.093 11.5083 14.2754 11.9611C12.9678 11.4928 11.4877 11.241 10 11.241ZM13.0701 18.6213C13.093 18.6626 13.112 18.7064 13.1268 18.7521C13.7942 20.2254 15.2776 21.25 17 21.25C19.3474 21.2497 21.25 19.3467 21.25 17C21.25 14.6542 19.3498 12.7521 17.0042 12.75L17.0032 12.75C16.2338 12.7491 15.5012 12.9703 14.8675 13.3512C14.8051 13.4078 14.7339 13.4532 14.6573 13.4859C13.5198 14.261 12.75 15.5664 12.75 17C12.75 17.5742 12.8638 18.1217 13.0701 18.6213ZM11.25 17C11.25 15.506 11.8551 14.1229 12.8285 13.0877C11.9365 12.8626 10.9734 12.741 10 12.741C8.08495 12.741 6.21997 13.2086 4.85938 14.0008C3.49197 14.797 2.75 15.8393 2.75 16.983V17.983C2.75 18.1208 2.86221 18.233 3 18.233H11.3825C11.2957 17.8358 11.25 17.4233 11.25 17Z"
      />
    </svg>
  );
};

export default UserWithSetting;

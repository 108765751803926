import {
  UserState,
  UserActionTypes,
  ADD_USER,
  DELETE_USER,
  UPDATE_USER,
  GET_USERS_PENDING,
  GET_USERS_FAILURE,
  GET_USERS_SUCCESS
} from "./types";

const initialState: UserState = {
  byId: {},
  allIds: [],
  isLoading: false,
  error: null
};

export const usersReducer = (
  state = initialState,
  action: UserActionTypes
): UserState => {
  switch (action.type) {
    case ADD_USER: {
      if (action.payload.id) {
        return {
          ...state,
          byId: { ...state.byId, [action.payload.id]: action.payload },
          allIds: [
            ...state.allIds.filter(id => id !== action.payload.id),
            action.payload.id
          ]
        };
      }
      return state;
    }
    case DELETE_USER:
      if (action.payload) {
        const byId = { ...state.byId };
        delete byId[action.payload];
        return {
          ...state,
          byId,
          allIds: [...state.allIds.filter(id => id !== action.payload)]
        };
      }
      return state;
    case UPDATE_USER:
      if (action.payload.id) {
        return {
          ...state,
          byId: { ...state.byId, [action.payload.id]: action.payload },
          allIds: state.allIds
        };
      }
      return state;
    case GET_USERS_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: {
          id: action.payload.id,
          message: action.payload.message
        }
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        byId: action.payload.reduce((byId, user) => {
          if (user.id) {
            return {
              ...byId,
              [user.id]: user
            };
          }
          return byId;
        }, {}),
        isLoading: false
      };
    default:
      return state;
  }
};

import { useMemo } from "react";
import { DateTime } from "luxon";
import { InputDateProps } from "../../types";
import { dateTimeFromAny } from "../utils";

type Props = {
  min?: InputDateProps["min"];
  max?: InputDateProps["max"];
  time?: InputDateProps["time"];
  setEndToEndOfDay?: boolean;
};

export const useDateTimeMinMax = ({
  min: incomingMin,
  max: incomingMax,
  time,
  setEndToEndOfDay
}: Props) => {
  const min = useMemo<DateTime | undefined>(() => {
    if (incomingMin instanceof Date) {
      return dateTimeFromAny(incomingMin)?.startOf(!time ? "day" : "second");
    }
    return undefined;
  }, [incomingMin, time]);

  const max = useMemo<DateTime | undefined>(() => {
    if (incomingMax instanceof Date) {
      const maxDateTime = dateTimeFromAny(incomingMax);
      if (!maxDateTime?.isValid) {
        return undefined;
      }
      if (!time) {
        if (setEndToEndOfDay) {
          return maxDateTime.endOf("day").startOf("second");
        }
        return maxDateTime.startOf("day");
      }
      return maxDateTime.startOf("second");
    }
    return undefined;
  }, [incomingMax, time]);

  return { min, max };
};

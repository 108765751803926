import { Handler } from "../../modules/handlers";
import { servicePublisherToPublisher } from "../../models/publisher";
import { FilterTypes, FilterValueTypes, validateFilterStructure } from "../../modules/handlers/filter";
import { FilterErrorName, MadSDKAbortError, NotImplementedError, PublishersFetchFailed } from "../../errors";
import axios from "axios";
import { parseNewNewYorkApiResponse } from "../../utils";
import Lists from "./lists";
import { ObjType } from "../../types";
/**
 * Handles publisher collections find and management.
 */
class Publishers extends Handler {
    /**
     * Child handlers also need access to MadSDK, so we must instantiate them with it.
     * @param sdk: instance of MadSDK to use for lookups.
     */
    constructor(sdk) {
        super(sdk, "publishers");
        /**
         * @param publishers: the set of publishers.
         * @param filters: the filters to apply.
         * @return: the filtered set of publishers.
         * @throws: FilterInvalidError if a filter is defined, and it is not of the form {FilterTypes.EQ, "id"}.
         */
        this.filterPublishers = (publishers, filters) => {
            validateFilterStructure(filters, [
                { filterType: FilterTypes.EQ, valueType: FilterValueTypes.STRING }
            ]);
            const idFilter = filters.where?.find((filter) => filter.field === "id")
                ?.value;
            if (idFilter) {
                const matchedPublishers = publishers.filter((publisher) => publisher.id === idFilter);
                return matchedPublishers;
            }
            return publishers;
        };
        this.toServicePublisher = (publisher, id) => ({
            id: id ? id : publisher.id,
            name: publisher.name,
            bundles: publisher.bundles?.map((el) => ({
                id: el.id
            }))
            // rtbImps: publisher.rtbImps
        });
        this.toPublisher = (publisher) => ({
            id: publisher.id,
            name: publisher.name,
            bundles: publisher.bundles?.map((el) => ({
                id: el.id
            })),
            rtbImps: publisher.rtbImps
        });
        this.updatePublisher = async (publisher) => {
            const cancellationSource = axios.CancelToken.source();
            try {
                const res = await axios.post(`${this.sdk.urls.baseAPIUrl}/publisher`, this.toServicePublisher(publisher), {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    cancelToken: cancellationSource.token
                });
                const { data: pubResponse } = parseNewNewYorkApiResponse(res);
                return this.toPublisher(pubResponse);
            }
            catch (error) {
                if (axios.isCancel(error)) {
                    throw new MadSDKAbortError();
                }
                else {
                    throw error;
                }
            }
        };
        this.createPublisher = async (publisher, id) => {
            const cancellationSource = axios.CancelToken.source();
            try {
                const res = await axios.post(`${this.sdk.urls.baseAPIUrl}/publisher`, this.toServicePublisher(publisher, id), {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    cancelToken: cancellationSource.token
                });
                const { data: pubResponse } = parseNewNewYorkApiResponse(res);
                return this.toPublisher(pubResponse);
            }
            catch (error) {
                if (axios.isCancel(error)) {
                    throw new MadSDKAbortError();
                }
                else {
                    throw error;
                }
            }
        };
        this.create = async (publisher) => {
            this.cache.remove(`${this.sdk.urls.baseAPIUrl}/publishers-all`);
            const id = await this.sdk.cryptography.mintKey(ObjType.PUB);
            return this.createPublisher(publisher, id);
        };
        this.update = async (publisher) => {
            this.cache.remove(`${this.sdk.urls.baseAPIUrl}/publishers-all`);
            return this.updatePublisher(publisher);
        };
        /**
         * @param error: the error to whittle down into something more specific/publisher-or-sdk-related.
         * @return: an appropriate Error if a specific error can be determined. Else, null.
         */
        this.getSpecificPublisherError = (error) => {
            if (!error) {
                return null; // can't determine a specific error if error is falsy
            }
            if (axios.isCancel(error)) {
                return new MadSDKAbortError();
            }
            if (error.name && error.name === FilterErrorName.FilterInvalidError) {
                return error;
            }
            // no specific error determined, so null
            return null;
        };
        this.lists = new Lists(sdk);
    }
    /**
     * Find publishers using filters.
     *
     * @param filters: filters publishers.
     *          Supports:
     *              {FilterTypes.EQ, "id"}
     * @return: an array of publishers.
     */
    async findItems(filters) {
        const url = `${this.sdk.urls.baseAPIUrl}/publishers`;
        const key = `${url}-${this.keyFromFilter(filters)}`;
        if (this.cache.has(key)) {
            return new Promise((resolve) => {
                this.cache.get(key).then((publishers) => {
                    resolve(this.filterPublishers(publishers, filters));
                });
            });
        }
        const req = new Promise((resolve, reject) => {
            axios
                .get(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((res) => {
                const { data: baseRawPublishers } = parseNewNewYorkApiResponse(res);
                const publishers = baseRawPublishers.map(servicePublisherToPublisher);
                resolve(this.filterPublishers(publishers, filters));
            })
                .catch((error) => {
                reject(this.getSpecificPublisherError(error) || new PublishersFetchFailed());
            });
        });
        this.cache.set(key, req);
        return req;
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async saveItem(history) {
        throw new NotImplementedError("save");
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}
export default Publishers;

import { addDaysToDate } from "../../utils/dates";
import axios from "axios";
import { isTruthy } from "../../utils/fp";
import { RemoteConfigProperties } from "../../types";
import { collection, deleteDoc, doc, getDocs, onSnapshot, query, where } from "firebase/firestore";
import { getDownloadURL, getStorage, ref } from "firebase/storage";
import { DeleteCustomReportingDocFailure, FetchCustomReportMissingReport, FetchCustomReportNoResults, FetchCustomReportResultFailure, MadSDKAbortError, UnauthenticatedError } from "../../errors";
import { CustomReportStatus, CustomReportType, LinearDimensionList, OTTDimensionList, customReportBaseToServiceCustomReportBase, firestoreCustomReportToCustomReport, makeZeroedOverlap, serviceCustomReportToCustomReport } from "../../models/customReports";
import { convertTimeToUnixTimestamp, delay } from "../../utils";
import { getDimensionMatch, getMultiDimensions } from "../../utils/dimensions";
import { Handler } from ".././handlers";
import { FilterTypes, FirestoreFilterTypes } from "../handlers/filter";
import { CustomReportsHydrater } from "./hydrater";
/**
 * Linear dimension result files stored on firebase storage are named with the following
 * template: `linearDIMENSION`
 *
 * e.g. linearcreative, linearday
 *
 * The object below is built off the LinearDimension enum thus, when product asks for the UI
 * to support new linear dimensions, we still only need to update the LinearDimension enum
 * and nothing else.
 */
const LinearDimensionResultKey = Object.values(LinearDimensionList).map((dimension) => `linear${dimension}`);
/**
 * ResultKeys contains the set of document names to look for in the Results field of a report.
 * Each document key is associated to an OTTDimension + "topLine" which is the key for the document
 * containing the results for the TopLineMetrics component in CustomReportDetailsView
 */
const ResultKeys = [
    ...LinearDimensionResultKey,
    ...Object.values(OTTDimensionList),
    "lineartopline",
    "topline",
    "overlap"
];
/**
 * parseDimensionData aims to parse the BigQuery output that is written in the result JSON files of a report on firebase storage
 * If the result of a given dimension only has one element, that element will be sent as an object to the UI
 * If the result of a given dimension has multiple elements, they will be sent as a stringified list of object separated by a \n character
 * that we need to parse on our end to convert to a proper data structure
 * @param {string | Record<string, string | number>} dimensionData The data returned by BigQuery that is written in the result files on firebase storage, it can either be a string or an JSON object
 * @returns {Array<Record<string, string | number>>} A properly formatted array of objects ready to be consumed by Redux
 */
export const parseDimensionData = (dimensionData) => {
    if (typeof dimensionData === "string") {
        return dimensionData
            .split("\n")
            .filter((item) => isTruthy(item))
            .map((item) => JSON.parse(item));
    }
    return [dimensionData];
};
const fetchData = async (URL) => axios.get(URL, {
    headers: {
        "Content-Type": "application/json"
    },
    withCredentials: false
});
const CUSTOM_REPORT_COLLECTION = "crossPlatformReports";
class CustomReports extends Handler {
    constructor(sdk, madFire) {
        super(sdk, "custom-reports");
        this.madFire = madFire;
        this.hydrater = new CustomReportsHydrater(sdk, {
            requiredFields: ["parameters.advertiserName"]
        });
    }
    /**
     * Get the list of a reports of a given organization
     * @param {Filter<CustomReport>} filters
     */
    async findItems(filters, sort, hydratedFields) {
        const user = await this.sdk.getCurrentUser();
        if (!user)
            throw new UnauthenticatedError();
        let querying;
        if (filters.where && filters.where.length > 0) {
            querying = query(collection(this.madFire.firestore, CUSTOM_REPORT_COLLECTION), where("Org", "==", user.primaryOrganizationId), where(`${filters.where[0].field.charAt(0).toUpperCase()}${filters.where[0].field.slice(1)}`, FirestoreFilterTypes[filters.where[0].type], filters.where[0].value));
        }
        else {
            querying = query(collection(this.madFire.firestore, CUSTOM_REPORT_COLLECTION), where("Org", "==", user.primaryOrganizationId));
        }
        try {
            let snapshot = await getDocs(querying);
            // retry firestore request to try and bypass issue of client being offline
            if (snapshot.docs.length === 0) {
                await delay(1000);
                snapshot = await getDocs(querying);
            }
            const rawReports = snapshot.docs
                .map((doc) => doc.data())
                .map((rawReport) => firestoreCustomReportToCustomReport(rawReport));
            return await this.hydrater.hydrate(rawReports, hydratedFields || []);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    /**
     * Watches for IN_PROGRESS reports in the CustomReportingTableView, this functions
     * helps us updating the status of reports
     * @param {Function} callbackFn A callback function to execute whenever a change in the snapshot is detected
     */
    async watchReportsInCollection(callbackFn) {
        const user = await this.sdk.getCurrentUser();
        if (!user)
            throw new UnauthenticatedError();
        const pastDay = convertTimeToUnixTimestamp(addDaysToDate(new Date(), -1));
        const emptyUnsubscribe = () => Promise.resolve();
        try {
            const inProgressReports = await getDocs(query(collection(this.madFire.firestore, CUSTOM_REPORT_COLLECTION), where("Org", "==", user.primaryOrganizationId), where("Status", "==", CustomReportStatus.IN_PROGRESS)));
            if (inProgressReports.size === 0) {
                return emptyUnsubscribe;
            }
        }
        catch (error) {
            console.error(error);
            return emptyUnsubscribe;
        }
        const unsubscribe = onSnapshot(query(collection(this.madFire.firestore, CUSTOM_REPORT_COLLECTION), where("Org", "==", user.primaryOrganizationId), 
        // only watch change in reports from past day - so we don't watch a high number of unnecessary reports
        where("QueryStarted", ">=", pastDay), where("Status", "in", [CustomReportStatus.DONE, CustomReportStatus.ERROR])), async (snapshot) => {
            const customReports = [];
            snapshot.docChanges().forEach((change) => {
                // report has changed status from IN_PROGRESS -> ERROR or DONE and has been added to the query snapshot
                if (change.type === "added") {
                    customReports.push(firestoreCustomReportToCustomReport(change.doc.data()));
                }
            });
            await this.hydrater.hydrate(customReports, ["parameters.advertiserName"]);
            callbackFn(customReports);
        });
        return unsubscribe;
    }
    /**
     * Sends a query to the xplatform endpoint to create a report
     * @param {CustomReportBase} data The payload sent by the CustomReportCreationView
     */
    async saveItem(data) {
        try {
            const isConversionBlacklistFilteringEnabled = this.sdk.featureFlags.isFlagEnabled(RemoteConfigProperties.CONVERSIONS_BLACKLIST);
            const axiosResponse = await axios.post(`${this.sdk.urls.madhiveReportingBaseUrl}/xplatform`, customReportBaseToServiceCustomReportBase(data, isConversionBlacklistFilteringEnabled), {
                headers: {
                    "Content-Type": "application/json"
                }
            });
            const { data: responseData } = axiosResponse;
            return Promise.resolve(serviceCustomReportToCustomReport(responseData.data));
        }
        catch (error) {
            if (axios.isCancel(error)) {
                return Promise.reject(new MadSDKAbortError());
            }
            return Promise.reject(error);
        }
    }
    /**
     * Deletes a report associated to the reportKey argument
     * @param {string} reportKey the "ResultKey" of the report, its unique identifier
     */
    async deleteItem(reportKey) {
        /**
         * Ensure the report exists before deleting it
         */
        const report = await this.find_once({
            where: [{ field: "resultKey", type: FilterTypes.EQ, value: reportKey }]
        });
        if (!report) {
            /**
             * If the report does not exist we throw a DeleteCustomReportingDocFailure
             */
            throw new DeleteCustomReportingDocFailure(reportKey);
        }
        try {
            await deleteDoc(doc(collection(this.madFire.firestore, CUSTOM_REPORT_COLLECTION), reportKey));
            return Promise.resolve(report);
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    /**
     * This method takes a report ID and returns its corresponding results
     * @param {string} reportId The ID of the report
     * @returns {ReportResults} An object of type ReportResult
     */
    async getResults(reportId, isCSV, dimensions) {
        try {
            const report = await this.find_once({
                where: [
                    {
                        field: "resultKey",
                        type: FilterTypes.EQ,
                        value: reportId
                    }
                ]
            });
            if (!report) {
                throw new FetchCustomReportMissingReport(reportId);
            }
            // only get the requested dimension (both json and csv) if the dimension arg is passed
            const filteredReports = dimensions && dimensions.length
                ? dimensions
                    .map((el) => {
                    const dm = isCSV ? `${el}.csv` : `${el}.json`;
                    return report.fullReports[dm];
                })
                    .filter(isTruthy)
                : [];
            const documents = filteredReports?.length
                ? filteredReports
                : Object.values(report.fullReports)
                    // PK TODO: Determine what other logic below needs to be modified to account for .CSV files
                    // May want to create a separate method for fetching CSVs if logic deviates a ton rather than reusing this logic
                    .filter((url) => url.includes(isCSV ? "csv" : "json"))
                    .map((url) => {
                    return url.replace(`https://storage.googleapis.com/${this.madFire.projectId}-xplatform-reports`, "");
                });
            if (documents.length === 0) {
                throw new FetchCustomReportNoResults();
            }
            const storage = getStorage(this.madFire.firebase, `gs://${this.madFire.config.projectId}-xplatform-reports`);
            /**
             * Fetch all the JSON docs
             */
            try {
                /**
                 * Get the download URLs
                 */
                const downloadURLS = (await Promise.all(documents.map(async (doc) => {
                    const url = await getDownloadURL(ref(storage, doc));
                    return url;
                })));
                if (isCSV) {
                    return Promise.resolve(downloadURLS);
                }
                const results = (await Promise.all(downloadURLS.map(fetchData)));
                const urls = results.map((r) => r.config.url).filter(isTruthy);
                const multiDimensions = getMultiDimensions(ResultKeys, urls);
                // combining single and multi dimensions
                const allResultKeys = [...ResultKeys, ...multiDimensions];
                const resultsByType = results.reduce((acc, result) => {
                    const key = allResultKeys.find((item) => {
                        const dimension = getDimensionMatch(result.config.url);
                        return dimension ? dimension === item : null;
                    });
                    if (!key) {
                        return acc;
                    }
                    const multiDimensionName = key.split("-");
                    const isLinear = multiDimensionName[0].includes("linear");
                    const topLineKey = "topline";
                    const linearToplineKey = "lineartopline";
                    acc[key] = {
                        url: result.config.url,
                        dimension: key,
                        // Parse the dimension data (it can be JSON or stringified JSON)
                        data: parseDimensionData(result.data).map((res) => {
                            // res[`${key}_name`]  does not work for multi dimensions. Multi dimensions have names saved separately (pub_name, dma_name, day_name etc). We need to combine them and show in the table.
                            /**
                             * conversion_index: is being deprecated from the custom reports and moving forward new reports will be generated with ott_conversion_index field
                             * reports previous to the change will still have the conversion_index field and we need to keep it compatible
                             */
                            const OTTDimensionValues = Object.values(OTTDimensionList);
                            if (OTTDimensionValues.includes(key)) {
                                res.ott_conversion_index = res.ott_conversion_index || res.conversion_index || 0;
                                // remove deprecated key
                                delete res.conversion_index;
                            }
                            return {
                                ...res,
                                name: multiDimensionName.length > 1
                                    ? multiDimensionName
                                        .map((dimensionName, idx) => {
                                        /**
                                         * all "_name" properties in the linear dimension data that come back from BE are prepended with "linear".
                                         * Example: data: [{lineardma_name: "", linearday_name: ""}] etc. But the dimension names come back like this "lineardma-day", so when we split by "-", we get [lineardma, day].
                                         * So to combine all names we need to prepend "linear" to all dimension names. Without pre-pending, it will try to find "day_name" instead of
                                         * linearday_name and that will return undefined.
                                         *  */
                                        const formattedDimensionName = isLinear && idx > 0 ? `linear${dimensionName}` : dimensionName;
                                        return `${res[`${formattedDimensionName}_name`]}`;
                                    })
                                        .join(" ")
                                    : res[`${key}_name`] || res.name
                            };
                        })
                    };
                    // PK: Unfortunate we have to do this, but given that we do substring matches
                    // we need to be explicit in assigning topline data until BE makes their keys more descriptive
                    if (result.config.url.includes(topLineKey) &&
                        !result.config.url.includes(linearToplineKey)) {
                        acc[topLineKey] = {
                            url: result.config.url,
                            dimension: key,
                            // Parse the dimension data (it can be JSON or stringified JSON)
                            data: parseDimensionData(result.data).map((res) => {
                                const toplineName = res[`${topLineKey}_name`]
                                    ? res[`${topLineKey}_name`]
                                    : res.name;
                                return {
                                    ...res,
                                    name: toplineName || topLineKey // returned undefined for name, so added "currentToplineKey"
                                };
                            })
                        };
                    }
                    return acc;
                }, {});
                /**
                 * In the rare event that an overlap report has no overlap
                 * between OTT and linear, sometimes no overlap report data
                 * is available; the file is just a blank csv or empty json.
                 * In this case, we populate a 'blank' overlap report using
                 * the available OTT and linear topline data.
                 * */
                if (resultsByType.overlap && !resultsByType.overlap.data.length) {
                    resultsByType.overlap.data = makeZeroedOverlap(resultsByType.topline.data[0], resultsByType.lineartopline.data[0]);
                }
                return Promise.resolve(resultsByType);
            }
            catch (error) {
                throw new FetchCustomReportResultFailure(reportId);
            }
        }
        catch (error) {
            return Promise.reject(error);
        }
    }
    getIdentifierKey() {
        return "resultKey";
    }
    make(defaults) {
        return Promise.resolve({
            advertiser: "",
            beacons: [],
            campaigns: [],
            commercials: [],
            dimensions: [],
            linearDimensions: [],
            dmas: [],
            lookback: 30,
            reportType: CustomReportType.OTT,
            startDate: "",
            endDate: "",
            timezone: "America/New_York",
            ...(defaults || {})
        });
    }
}
export default CustomReports;

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectCurrentUserViews } from "appReducers/authReducer";
import { RootState } from "rootReducer";
import { Routes } from "lib/constants/routes";
import { useNavigate } from "react-router-dom";

const currentPathWithQuery = () =>
  `${window.location.pathname}${window.location.search}`;

// eslint-disable-next-line import/prefer-default-export
export const usePostLoginRedirect = () => {
  const [initialPath] = useState(currentPathWithQuery());

  const selectUser = (state: RootState) => state.auth.actualUser;
  const user = useSelector(selectUser);
  const currentViews = useSelector(selectCurrentUserViews);

  const navigate = useNavigate();

  useEffect(() => {
    /**
     * Navigate to user's homepage if:
     * 1) `homepage` setting exists within user doc
     * 2) `homepage` is a valid view option (i.e. within organization views AND user views)
     * 3) user is NOT currently navigating to a predetermined path
     */

    if (!user) return;

    if (
      window.location.pathname === Routes.LOGIN ||
      window.location.pathname === Routes.LOGIN_LOCAL ||
      window.location.pathname === "/"
    ) {
      if (
        !initialPath.match(Routes.LOGIN) &&
        !initialPath.match(Routes.LOGIN_LOCAL) &&
        initialPath !== "/"
      ) {
        navigate(initialPath);
      } else if (user.homepage && currentViews.includes(user.homepage)) {
        if (user.homepage === "pii") {
          navigate(Routes.FIRST_PARTY_DATA_ONBOARDING);
        } else {
          navigate(user.homepage);
        }
      } else {
        navigate(Routes.USER_PROFILE);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
};

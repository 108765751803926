import { CancelTokenSource } from "axios";

import { IToastProps } from "@blueprintjs/core";
import { CampaignSummary } from "@madhive/mad-sdk";

export interface CampaignSummaryState {
  cancellationSource: CancelTokenSource | null;
  lastReceived: number | null;
  data: CampaignSummary[];
  error:
    | ({
        message: string;
      } & IToastProps)
    | null;
}

export const GET_CAMPAIGN_SUMMARIES_PENDING = "GET_CAMPAIGN_SUMMARIES_PENDING";
export const GET_CAMPAIGN_SUMMARIES_SUCCESS = "GET_CAMPAIGN_SUMMARIES_SUCCESS";
export const GET_CAMPAIGN_SUMMARIES_FAILURE = "GET_CAMPAIGN_SUMMARIES_FAILURE";
export const ABORT_GET_CAMPAIGN_SUMMARIES = "ABORT_GET_CAMPAIGN_SUMMARIES";

interface GetCampaignSummariesPendingAction {
  type: typeof GET_CAMPAIGN_SUMMARIES_PENDING;
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

interface GetCampaignSummariesSuccessAction {
  type: typeof GET_CAMPAIGN_SUMMARIES_SUCCESS;
  payload: CampaignSummary[];
  meta: {
    timestamp: number;
  };
}

interface GetCampaignSummariesFailureAction {
  type: typeof GET_CAMPAIGN_SUMMARIES_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface AbortGetCampaignSummariesAction {
  type: typeof ABORT_GET_CAMPAIGN_SUMMARIES;
}

export type CampaignSummaryActions =
  | GetCampaignSummariesPendingAction
  | GetCampaignSummariesSuccessAction
  | GetCampaignSummariesFailureAction
  | AbortGetCampaignSummariesAction;

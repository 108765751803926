import { FC } from "react";
import { v4 as uuid } from "uuid";
import { CommonIconProps, UpDownDirection } from "./types";

type Props = CommonIconProps & {
  direction?: UpDownDirection;
};

const clipId = `kit-${uuid()}`;

const UpDown: FC<Props> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)",
  direction = "down"
}) => {
  return (
    <svg
      className="icon-up-down"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      {direction === "down" && (
        <>
          <g clipPath={`url(#${clipId})`}>
            <path d="M7.53341 13.8L11.5334 17.8C11.6667 17.9333 11.8001 18 12.0001 18C12.2001 18 12.3334 17.9333 12.4667 17.8L16.4667 13.8C16.6001 13.6667 16.6667 13.5333 16.6667 13.3333C16.6667 12.9333 16.3334 12.6667 16.0001 12.6667C15.8001 12.6667 15.6667 12.7333 15.5334 12.8667L12.6667 15.7333L12.6667 6.66667C12.6667 6.33333 12.4001 6 12.0001 6C11.6001 6 11.3334 6.33333 11.3334 6.66667L11.3334 15.7333L8.46675 12.8667C8.33342 12.7333 8.20008 12.6667 8.00008 12.6667C7.66675 12.6667 7.33341 12.9333 7.33341 13.3333C7.33341 13.5333 7.40008 13.6667 7.53341 13.8Z" />
          </g>
          <defs>
            <clipPath id={`${clipId}`}>
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </>
      )}
      {direction === "up" && (
        <>
          <g clipPath={`url(#${clipId})`}>
            <path d="M16.4666 10.2L12.4666 6.2C12.3333 6.06667 12.1999 6 11.9999 6C11.7999 6 11.6666 6.06667 11.5333 6.2L7.53325 10.2C7.39992 10.3333 7.33325 10.4667 7.33325 10.6667C7.33325 11.0667 7.66659 11.3333 7.99992 11.3333C8.19992 11.3333 8.33325 11.2667 8.46659 11.1333L11.3333 8.26667V17.3333C11.3333 17.6667 11.5999 18 11.9999 18C12.3999 18 12.6666 17.6667 12.6666 17.3333V8.26667L15.5333 11.1333C15.6666 11.2667 15.7999 11.3333 15.9999 11.3333C16.3333 11.3333 16.6666 11.0667 16.6666 10.6667C16.6666 10.4667 16.5999 10.3333 16.4666 10.2Z" />
          </g>
          <defs>
            <clipPath id={`${clipId}`}>
              <rect width="24" height="24" fill="white" />
            </clipPath>
          </defs>
        </>
      )}
    </svg>
  );
};

export default UpDown;

import { useDispatch, useSelector } from "react-redux";
import { requestOrgSettings } from "appReducers/orgSettingsReducer";
import { selectCurrentUser } from "appReducers/authReducer";
import { FC, PropsWithChildren, useEffect } from "react";

export const OrganizationSettings: FC<PropsWithChildren> = props => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  useEffect(() => {
    try {
      dispatch(requestOrgSettings(user));
    } catch (error) {
      console.log(error);
    }
  }, [user]);

  return <>{props.children}</>;
};

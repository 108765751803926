import { FC, useMemo } from "react";
import { styles } from "./styles";
import { CalendarDayProps } from "./types";

export const CalendarDay: FC<CalendarDayProps> = ({
  date,
  isSelected,
  isInRange,
  isDisabled,
  isEmpty,
  error,
  selectDay
}) => {
  const day = useMemo(
    () => (date?.get("day") ? date.get("day") : undefined),
    [date?.get("day")]
  );

  return (
    <button
      tabIndex={day}
      type="button"
      className="subKit-CalendarDay"
      onClick={() => selectDay(date)}
      disabled={isDisabled || isEmpty}
      css={[
        styles.day.base,
        isSelected && styles.day.selected,
        isInRange && !isSelected && styles.day.inRange,
        isEmpty && styles.day.empty,
        isDisabled && styles.day.disabled,
        error && styles.day.error
      ]}
    >
      {`${day || ""}`}
    </button>
  );
};

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Reticle: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-reticle"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 3C12.75 2.58579 12.4142 2.25 12 2.25C11.5858 2.25 11.25 2.58579 11.25 3V4.28615C9.52346 4.45303 7.84222 5.19762 6.51992 6.51992C5.19762 7.84222 4.45303 9.52346 4.28615 11.25H3C2.58579 11.25 2.25 11.5858 2.25 12C2.25 12.4142 2.58579 12.75 3 12.75H4.28615C4.45304 14.4765 5.19763 16.1578 6.51992 17.4801C7.84222 18.8024 9.52346 19.547 11.25 19.7138V21C11.25 21.4142 11.5858 21.75 12 21.75C12.4142 21.75 12.75 21.4142 12.75 21V19.7138C14.4765 19.547 16.1578 18.8024 17.4801 17.4801C18.8024 16.1578 19.547 14.4765 19.7138 12.75H21C21.4142 12.75 21.75 12.4142 21.75 12C21.75 11.5858 21.4142 11.25 21 11.25H19.7138C19.547 9.52346 18.8024 7.84222 17.4801 6.51992C16.1578 5.19763 14.4765 4.45304 12.75 4.28615V3ZM18.2051 11.25C18.0439 9.90881 17.4487 8.60986 16.4194 7.58058C15.3901 6.55131 14.0912 5.95608 12.75 5.79489V7C12.75 7.41421 12.4142 7.75 12 7.75C11.5858 7.75 11.25 7.41421 11.25 7V5.79489C9.90881 5.95608 8.60986 6.55131 7.58058 7.58058C6.55131 8.60986 5.95608 9.90881 5.79489 11.25H7C7.41421 11.25 7.75 11.5858 7.75 12C7.75 12.4142 7.41421 12.75 7 12.75H5.79489C5.95608 14.0912 6.55131 15.3901 7.58058 16.4194C8.60986 17.4487 9.90881 18.0439 11.25 18.2051V17C11.25 16.5858 11.5858 16.25 12 16.25C12.4142 16.25 12.75 16.5858 12.75 17V18.2051C14.0912 18.0439 15.3901 17.4487 16.4194 16.4194C17.4487 15.3901 18.0439 14.0912 18.2051 12.75H17C16.5858 12.75 16.25 12.4142 16.25 12C16.25 11.5858 16.5858 11.25 17 11.25H18.2051Z"
      />
    </svg>
  );
};

export default Reticle;

/**
 * Checks if we're in dev mode for us
 */
export const isDev = !!import.meta.env.DEV;
/**
 * Function that does nothing
 */
export const NOOP = () => { };
// TODO replace with a future mad-logging project
// eslint-disable-next-line no-console
export const log = isDev ? console.log : NOOP;

import {
  ToastProps,
  Position,
  Toaster,
  Intent,
  IconName
} from "@blueprintjs/core";
import { ToasterContext } from "lib/context";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";
import { selectAllToasts, hide } from "appReducers/toasterReducer";
import { ToastOptionsType } from "appReducers/toasterReducer/types";
import { css } from "@emotion/css";
import { TextColor, TextVariant, Typography } from "madhive/components";
import { FC, useRef, useState, isValidElement, PropsWithChildren } from "react";

type AvailablePositions =
  | typeof Position.BOTTOM_RIGHT
  | typeof Position.BOTTOM_LEFT;

type ToastTitle = {
  title?: string;
};
export type MadhiveToastProps = ToastProps & ToastTitle;

const INTENT_TO_TITLE_FALLBACK: Record<Intent, string> = {
  [Intent.NONE]: "Info",
  [Intent.PRIMARY]: "Info",
  [Intent.SUCCESS]: "Success",
  [Intent.WARNING]: "Attention",
  [Intent.DANGER]: "Error"
};

const INTENT_TO_ICON: Record<Intent, IconName> = {
  [Intent.NONE]: "info-sign",
  [Intent.PRIMARY]: "info-sign",
  [Intent.SUCCESS]: "tick-circle",
  [Intent.WARNING]: "warning-sign",
  [Intent.DANGER]: "error"
};

const toasterCss = css`
  .bp4-toast {
    border-width: 0 0 0 var(--spacing-4);
    border-style: solid;
    // border and background color when no intent
    border-color: var(--gray-5);
    background-color: var(--gray-1);

    & button.bp4-button,
    & button.bp4-button:hover,
    & a.bp4-button,
    & a.bp4-button:hover {
      background-color: transparent !important;
    }
  }
  // icon color when no intent
  .bp4-toast > .bp4-icon {
    color: var(--gray-5);
  }

  .bp4-toast-message {
    padding: var(--spacing-16);
    color: var(--gray-5);
  }

  .bp4-icon {
    align-self: center;
  }

  .bp4-button-group {
    align-self: center;
    background-color: transparent;
  }

  .bp4-toast.bp4-intent-danger {
    border-color: var(--red);
    background-color: var(--red-light);
  }

  .bp4-toast.bp4-intent-danger > .bp4-icon {
    color: var(--red) !important;
  }

  .bp4-toast.bp4-intent-primary {
    border-color: var(--blue);
    background-color: var(--blue-light);
  }

  .bp4-toast.bp4-intent-primary > .bp4-icon {
    color: var(--blue);
  }

  .bp4-toast.bp4-intent-success {
    border-color: var(--green) !important;
    background-color: var(--green-light);
  }

  .bp4-icon-cross {
    & > svg {
      fill: var(--gray-5) !important;
    }
  }

  .bp4-toast.bp4-intent-success > .bp4-icon {
    color: var(--green) !important;
  }

  .bp4-toast.bp4-intent-warning {
    border-color: var(--orange);
    background-color: var(--orange-light);
  }

  .bp4-toast.bp4-intent-warning > .bp4-icon {
    color: var(--orange);
  }

  .bp4-toast[class*="bp4-intent-"] .bp4-button:focus {
    outline-style: none;
  }

  .bp4-toast[class*="bp4-intent-"] .bp4-button,
  .bp4-toast[class*="bp4-intent-"] .bp4-button::before,
  .bp4-toast[class*="bp4-intent-"] .bp4-button .bp4-icon,
  .bp4-toast[class*="bp4-intent-"] .bp4-button:active {
    color: var(--gray-5) !important;
  }
`;

const ToasterComponent: FC<PropsWithChildren> = ({ children }) => {
  const toaster = useRef<Toaster | null>(null);
  const [position] = useState<AvailablePositions>(Position.BOTTOM_RIGHT);
  const [toastKey, setToastKey] = useState(1);
  const toasts = useSelector(selectAllToasts);
  const dispatch = useDispatch();

  const addToast = (
    toast: MadhiveToastProps | ToastOptionsType,
    key?: string
  ) => {
    // title is either directly informed, inferred from intent or set to Info as default
    let title = toast.intent ? INTENT_TO_TITLE_FALLBACK[toast.intent] : "Info";
    if ("title" in toast) {
      title = toast.title ? toast.title : title;
    }

    // icon is either directly informed, inferred from intent or set to info-sign as default
    const icon = toast.icon
      ? toast.icon
      : toast.intent
      ? INTENT_TO_ICON[toast.intent]
      : "info-sign";

    if (toaster.current) {
      const isDangerIntent = toast.intent === Intent.DANGER;
      // @ts-ignore global heap
      if (isDangerIntent && window.heap && window.heap.track) {
        // @ts-ignore global heap
        window.heap.track("Error Toast", {
          message: toast.message,
          title
        });
      }
      // this value is only used for error toasts, down below. However, placing it up here could be useful for debugging
      const currentDate = new Date().toLocaleString("en-US", {
        timeZone: "America/New_York"
      });

      toaster.current.show(
        {
          intent: Intent.PRIMARY,
          className: classNames("top-level", toast.className),
          ...toast,
          icon,
          message: (
            <div>
              {title && <Typography bold>{title}</Typography>}
              {isValidElement(toast.message) ? (
                toast.message
              ) : (
                <Typography color={TextColor.DESCRIPTION_TEXT}>
                  {toast.message as string}
                </Typography>
              )}

              {isDangerIntent && (
                <Typography
                  color={TextColor.DESCRIPTION_TEXT}
                  variant={TextVariant.MICRO}
                >
                  {currentDate}
                </Typography>
              )}
            </div>
          ),
          // When the last visible toast closes, wait for fadeout then remount Toaster component
          // to destroy the bp portal, ensuring the next toast is always above the top-most content.
          onDismiss: () => {
            if ("uid" in toast) {
              dispatch(hide(toast.uid!));
            }
            setTimeout(
              () =>
                !toaster.current?.state.toasts.length &&
                setToastKey(prev => prev + 1),
              1000
            );
          }
        },
        key
      );
    }
  };

  toasts.forEach((toast: ToastOptionsType) => {
    if (toaster.current) {
      if (
        !toaster.current.state.toasts.some(
          activeToasts =>
            (activeToasts as unknown as ToastOptionsType).uid === toast.uid
        )
      ) {
        addToast(toast);
      }
    }
  });

  return (
    <>
      <Toaster
        key={toastKey}
        position={position}
        ref={toaster}
        className={classNames("top-level", toasterCss)}
      />
      <ToasterContext.Provider value={{ addToast }}>
        {children}
      </ToasterContext.Provider>
    </>
  );
};

export default ToasterComponent;

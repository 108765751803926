import { Fragment, ReactElement, useMemo } from "react";
import { css } from "@emotion/react";
import { styles } from "./styles";
import { defaultLookupId, lookupChildrenByKitType } from "../utils";
import {
  RowActionsOnTable,
  TableColumnDef,
  TableColumnProps,
  TableProps,
  TableRowActionDef,
  TableRowActionProps,
  TableRowType
} from "./types";
import RowBorder from "./RowBorder";
import { withKitType } from "../HOC";
import { IdType, isIdType } from "../types";
import TableRows from "./Rows";
import { useColumnDefs } from "./hooks";
import Icon from "../Icon";
import OverflowShadows from "../OverflowShadows";
import { useSizingProp } from "../../hooks/props/useSizingProp";

const tableHasRowActions = (
  rowActions: RowActionsOnTable<any> | undefined,
  children: ReactElement | ReactElement[] | undefined
) => {
  if (!rowActions && !children) {
    return false;
  }
  return (
    !!rowActions?.list?.some(row => isIdType(row) || !row?.hidden) ||
    !!lookupChildrenByKitType("Table.RowAction", children).find(
      child => !child.props.hidden
    ) ||
    !!rowActions?.render
  );
};

export const Table = <
  Row extends TableRowType,
  Column extends TableColumnDef<Row>,
  Action extends TableRowActionDef<Row>
>({
  columns,
  data: incomingData,
  rowMinHeight,
  children,
  sorting = {},
  rowActions,
  stickyHeader = true,
  stickyFirstColumn,
  stickyLastColumn,
  maxHeight: _maxHeight,
  lookupRowId = defaultLookupId,
  rowTestId
}: TableProps<Row, Column, Action>) => {
  const columnDefs = useColumnDefs({
    columns: columns || [],
    rowSample: incomingData[0],
    children,
    sorting
  });
  const hasRowActions = tableHasRowActions(rowActions, children);

  const maxHeight = useSizingProp(_maxHeight, "100%");

  const data = useMemo(() => {
    // if columns are not provided, assume the first row is the column headers
    if (!columns?.length && Array.isArray(incomingData[0])) {
      return incomingData.slice(1);
    }
    return incomingData || [];
  }, [incomingData]);

  const overflowShadows: Array<"top" | "left" | "right"> = [];
  if (stickyHeader || typeof stickyHeader === "number") {
    overflowShadows.push("top");
  }
  if (stickyFirstColumn) {
    overflowShadows.push("left");
  }
  if (stickyLastColumn) {
    overflowShadows.push("right");
  }

  return (
    <OverflowShadows
      {...(_maxHeight
        ? { overflow: "auto", maxHeight }
        : { overflow: "visible" })}
      overflowShadows={overflowShadows}
    >
      <table
        className="kit-Table"
        css={[
          styles.table,
          css`
            --table-row-min-height: ${rowMinHeight};
            --table-column-count: ${columnDefs.length};
            --table-header-offset: ${typeof stickyHeader === "number"
              ? stickyHeader
              : 0}px;
            grid-template-columns:
              repeat(var(--table-column-count), minmax(max-content, auto))
              ${hasRowActions ? "3rem" : ""};
          `,
          stickyFirstColumn && styles.stickyFirstColumn,
          stickyLastColumn && styles.stickyLastColumn,
          stickyHeader && styles.stickyHeader
        ]}
      >
        <thead className="subKit-TableHeader" css={[styles.tableHeader]}>
          <tr css={[styles.tr]}>
            {columnDefs.map((column, columnIndex) => (
              <Fragment key={String(column.key)}>
                <th
                  css={[
                    styles.th,
                    column.isSortable && styles.thSortable,
                    column.key === sorting.column && styles.thSorted
                  ]}
                  aria-sort={
                    sorting.column === column.key && sorting.direction
                      ? (`${sorting.direction}ending` as const)
                      : undefined
                  }
                  className={column.isSortable ? "sortable" : ""}
                  role={column.isSortable ? "button" : undefined}
                  onClick={
                    column.isSortable
                      ? () =>
                          column.onSort?.(
                            sorting?.column === column.key &&
                              sorting?.direction === "asc"
                              ? "desc"
                              : "asc"
                          )
                      : undefined
                  }
                >
                  {column.label}
                  {sorting?.column === column.key && (
                    <Icon
                      name="Chevron"
                      direction={sorting?.direction === "asc" ? "up" : "down"}
                    />
                  )}
                </th>

                {/* row actions */}
                {columnIndex === columnDefs.length - 1 && hasRowActions && (
                  <th
                    css={[styles.th, styles.actionsCell]}
                    aria-label="actions"
                  />
                )}
              </Fragment>
            ))}
          </tr>
          <RowBorder />
        </thead>

        <tbody css={styles.tableBody}>
          <TableRows
            data={data}
            columns={columnDefs}
            lookupRowId={lookupRowId}
            rowTestId={rowTestId}
            rowActions={rowActions}
          >
            {children}
          </TableRows>
        </tbody>
      </table>
    </OverflowShadows>
  );
};

/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */

Table.Column = withKitType(
  "Table.Column",
  <Row extends TableRowType, ColumnKey extends IdType = IdType>(
    props: TableColumnProps<Row, ColumnKey>
  ) => <></>
);

Table.RowAction = withKitType(
  "Table.RowAction",
  <Row extends TableRowType>(props: TableRowActionProps<Row>) => <></>
);

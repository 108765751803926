import { omit } from "lodash";
import {
  ABORT_ARCHIVE_LINE_ITEM_REQUEST,
  ABORT_CREATE_LINE_ITEM_REQUEST,
  ABORT_UPDATE_LINE_ITEM_REQUEST,
  CREATE_LINE_ITEM_FAILURE,
  CREATE_LINE_ITEM_PENDING,
  CREATE_LINE_ITEM_SUCCESS,
  DELETE_LINE_ITEM_FAILURE,
  DELETE_LINE_ITEM_PENDING,
  DELETE_LINE_ITEM_SUCCESS,
  GET_LINE_ITEM_DETAILS_FAILURE,
  GET_LINE_ITEM_DETAILS_PENDING,
  GET_LINE_ITEM_DETAILS_SUCCESS,
  lineItemsActionTypes,
  lineItemsState,
  UPDATE_LINE_ITEM_FAILURE,
  UPDATE_LINE_ITEM_PENDING,
  UPDATE_LINE_ITEM_SUCCESS,
  SET_SELECTED_FOR_BULK_ACTION
} from "./types";

export const lineItemsInitialState: lineItemsState = {
  byId: {},
  loadCreateCancellationSource: null,
  loadArchiveCancellationSource: null,
  loadUpdateCancellationSource: null,
  receivedAllAt: null,
  isSaving: false,
  details: {},
  selectedForBulkAction: []
};

export const lineItemsReducer = (
  state = lineItemsInitialState,
  action: lineItemsActionTypes
): lineItemsState => {
  switch (action.type) {
    case ABORT_CREATE_LINE_ITEM_REQUEST:
      return {
        ...state,
        loadCreateCancellationSource: null
      };
    case ABORT_ARCHIVE_LINE_ITEM_REQUEST:
      return {
        ...state,
        loadArchiveCancellationSource: null
      };

    case ABORT_UPDATE_LINE_ITEM_REQUEST:
      return {
        ...state,
        loadUpdateCancellationSource: null
      };

    case CREATE_LINE_ITEM_PENDING:
      return {
        ...state,
        isSaving: true,
        loadCreateCancellationSource: action.meta.cancellationSource
      };
    case CREATE_LINE_ITEM_FAILURE:
      return {
        ...state,
        isSaving: false,
        loadCreateCancellationSource: null
      };
    case CREATE_LINE_ITEM_SUCCESS:
      return {
        ...state,
        isSaving: false,
        byId: { ...state.byId, [action.payload.id]: action.payload }
      };

    case UPDATE_LINE_ITEM_PENDING:
      return {
        ...state,
        isSaving: true,
        loadUpdateCancellationSource: action.meta.cancellationSource
      };
    case UPDATE_LINE_ITEM_SUCCESS:
      return {
        ...state,
        isSaving: false
      };

    case UPDATE_LINE_ITEM_FAILURE:
      return {
        ...state,
        isSaving: false,
        loadUpdateCancellationSource: null
      };

    case DELETE_LINE_ITEM_PENDING:
      return {
        ...state,
        isSaving: true,
        loadArchiveCancellationSource: action.meta.cancellationSource
      };
    case DELETE_LINE_ITEM_SUCCESS:
      return {
        ...state,
        isSaving: false,
        byId: omit(state.byId, action.payload)
      };
    case DELETE_LINE_ITEM_FAILURE:
      return {
        ...state,
        isSaving: false
      };
    case GET_LINE_ITEM_DETAILS_PENDING:
      return {
        ...state,
        loadCreateCancellationSource: null,
        isSaving: true,
        details: action.meta.lineItemFetchMap
      };
    case GET_LINE_ITEM_DETAILS_SUCCESS:
      return {
        ...state,
        isSaving: false,
        details: action.payload
      };
    case GET_LINE_ITEM_DETAILS_FAILURE:
      return {
        ...state,
        details: action.payload,
        isSaving: false,
        loadArchiveCancellationSource: null
      };
    case SET_SELECTED_FOR_BULK_ACTION:
      return {
        ...state,
        selectedForBulkAction: action.payload
      };
    default:
      return state;
  }
};

export var InscapeStatuses;
(function (InscapeStatuses) {
    InscapeStatuses["SUCCESS"] = "SUCCESS";
    InscapeStatuses["FAILURE"] = "FAILURE";
    InscapeStatuses["PENDING"] = "PENDING";
    InscapeStatuses["DETECTION_COMPLETED"] = "DETECTION_COMPLETED";
    InscapeStatuses["FILE_RECEIVED"] = "FILE_RECEIVED";
    InscapeStatuses["DETECTION_STARTED"] = "DETECTION_STARTED";
})(InscapeStatuses || (InscapeStatuses = {}));
export var InscapeFilterFields;
(function (InscapeFilterFields) {
    InscapeFilterFields["ORG_ID"] = "orgId";
    InscapeFilterFields["CREATIVE_ID"] = "creativeId";
})(InscapeFilterFields || (InscapeFilterFields = {}));
export const serviceInscapeStatusToInscapeStatus = (status) => {
    let creativeInscapeStatus;
    if (status.Status === InscapeStatuses.DETECTION_COMPLETED && status.DetectionResult === "pass") {
        creativeInscapeStatus = InscapeStatuses.SUCCESS;
    }
    else if (status.Status === InscapeStatuses.FAILURE ||
        status.Status.toLowerCase().includes("fail") ||
        status.DetectionResult === "fail") {
        creativeInscapeStatus = InscapeStatuses.FAILURE;
    }
    else {
        creativeInscapeStatus = InscapeStatuses.PENDING;
    }
    return {
        advertiserID: status.AdvertiserID,
        advertiserName: status.AdvertiserName,
        advertiserParent: status.AdvertiserParent,
        contentID: status.ContentID,
        creativeID: status.CreativeID,
        creativeURL: status.CreativeURL,
        detectionResult: status.DetectionResult,
        status: status.Status,
        timestamp: status.Timestamp,
        creativeInscapeStatus
    };
};

export class ArchiveFootTrafficReportFailure extends Error {
    constructor(reportId) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ArchiveFootTrafficReportFailure);
        }
        this.name = "ArchiveFootTrafficReportFailure";
        this.reportId = reportId;
        this.message = `Failed to archive the report with ID ${reportId}.`;
    }
}
export class UnarchiveFootTrafficReportFailure extends Error {
    constructor(reportId) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, UnarchiveFootTrafficReportFailure);
        }
        this.name = "UnarchiveFootTrafficReportFailure";
        this.reportId = reportId;
        this.message = `Failed to unarchive the report with ID ${reportId}.`;
    }
}
export class FootTrafficAttributionReportsFetchFailure extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, FootTrafficAttributionReportsFetchFailure);
        }
        this.name = "FootTrafficAttributionReportsFetchFailure";
        this.message =
            "Failed to retrieve foot traffic attribution reports. Please try again at a later time.";
    }
}

import axios from "axios";
import { Handler } from "../../handlers";
import { serviceDmpToDmp } from "../../../models/dataManagementPlatform";
import { parseNewNewYorkApiResponse } from "../../../utils";
import { MadSDKAbortError, NotImplementedError } from "../../../errors";
import { Segments } from "..";
import { DmpsFetchFailed } from "./errors";
class DataManagementPlatforms extends Handler {
    /**
     * Child handlers also need access to MadSDK, so we must instantiate them with it.
     * @param sdk: instance of MadSDK to use for lookups.
     */
    constructor(sdk) {
        super(sdk, "data-management-platforms");
        this.toDmp = (dmp) => {
            return serviceDmpToDmp(dmp);
        };
        this.segments = new Segments(sdk);
    }
    async findItems() {
        const url = `${this.sdk.urls.baseAPIUrl}/dmp`;
        if (this.cache.has(url)) {
            return this.cache.get(url);
        }
        const req = new Promise((resolve, reject) => {
            axios
                .get(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((res) => {
                const dmps = parseNewNewYorkApiResponse(res).data;
                const convertedDmps = dmps.map((dmp) => this.toDmp(dmp));
                resolve(convertedDmps);
            })
                .catch((error) => {
                if (axios.isCancel(error)) {
                    reject(new MadSDKAbortError());
                }
                else {
                    reject(new DmpsFetchFailed(error));
                }
            });
        });
        this.cache.set(url, req);
        return req;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async saveItem(dmp) {
        throw new NotImplementedError("save");
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async deleteItem(id) {
        throw new NotImplementedError("delete");
    }
}
export default DataManagementPlatforms;

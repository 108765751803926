import { ReactNode } from "react";
import styled from "@emotion/styled";
import { useCellContext } from "./table";

interface CellProps {
  columnIndex: number;
  rowIndex: number;
  element?: ReactNode;
}

export const CellWrapperStyle = `
  display: inline-block;
  height: 100%;
  max-height: calc(var(--spacing-16) * 3);
  padding: var(--spacing-8);
  &:not(:nth-of-type(2)) {
    padding-left: var(--spacing-16);
  }
  overflow: hidden;
  line-height: var(--spacing-24);
  font-size: var(--font-size-12);
  border-bottom: 1px solid var(--gray-3);
`;
// transition: all 500ms ease-in-out;
// background shadowing the prop in tableWrapper atm

const Wrapper = styled.div``;

const Cell = (props: CellProps) => {
  const { columnIndex, rowIndex, element } = props;

  const { renderCell } = useCellContext();

  return (
    <Wrapper>
      {renderCell ? renderCell(rowIndex, columnIndex) : element}
    </Wrapper>
  );
};

export default Cell;

import { FC, useContext, useRef, useEffect } from "react";
import styled from "@emotion/styled";
import { DotIndicator } from "./DotIndicator";
import { TabsContext } from "./TabsContext";
import { TabProps } from "./types";

const TabWrapper = styled("li")`
  list-style: none;
`;

const TabIndicator = styled("div")`
  height: var(--spacing-4);
  width: 100%;
  border-radius: 2px;
  background-color: var(--primary-color);
`;

const StyledTab = styled("button")`
  border: none;
  cursor: pointer;
  height: 30px;
  background: transparent;
  white-space: nowrap;
  padding: 0;
`;

const Tab: FC<TabProps> = props => {
  const { id, title, tabPanelId, showDot } = props;
  const contextValue = useContext(TabsContext);

  if (!contextValue) {
    throw new Error(
      "You cannot use Tab on its own. Tab should only be used as a child of the Tabs component."
    );
  }

  const { onChange, selectedTabId } = contextValue;

  const isSelected = id === selectedTabId;

  const tabRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    /**
     * If the ref is defined AND the current tab is selected
     * focus on the element
     */
    if (tabRef.current && isSelected) {
      tabRef.current.focus();
    }
  }, [isSelected]);

  return (
    <TabWrapper role="presentation">
      <StyledTab
        id={id}
        data-testid={props["data-testid"]}
        /**
         * Elements with role=”tab” has to be the direct children of an element with role=”tablist”
         * Thus we add the attribute role="tab" to StyledTab
         *
         * However, it's wrapped in a li which goes against the rule for role="tab". Thus we need
         * to add role="presentation" to the li element to work around this
         */
        role="tab"
        data-tab-id={id}
        /**
         * This indicates whether the tab control is activated and its associated tab panel displayed.
         */
        aria-selected={isSelected}
        onClick={() => onChange(id)}
        /**
         * If the tab is active it needs to be part of the "tab order" i.e. we can focus on it with the TAB key
         * If the tab is not active it should NOT be part of the "tab order" i.e. it's not focusable with the TAB key
         */
        tabIndex={isSelected ? 0 : -1}
        /**
         * This referes to the TabPanel assosicated to that tab.
         * We need to pass the id of that TabPanel.
         */
        aria-controls={tabPanelId}
        /**
         * This ref is used to programmatically focus this element.
         */
        ref={tabRef}
      >
        {showDot ? (
          <>
            <DotIndicator data-testid={`${id}-dot-indicator`} />
            {title}
          </>
        ) : (
          title
        )}
      </StyledTab>
      {isSelected ? <TabIndicator data-testid={`${id}-indicator`} /> : null}
    </TabWrapper>
  );
};

Tab.displayName = "Tab";

export { Tab };

import { ServiceStatus, Advertiser } from "@madhive/mad-sdk";
import { RootState } from "rootReducer";
import { createSelector } from "reselect";
import { mapAdvertiserIdsToNames } from "../../lib/utils/advertisers";

export const selectLastReceivedAdvertisers = (state: RootState) =>
  state.advertisers.receivedAllAt;

export const selectIsAdvertiserCreatingOrUpdating = (state: RootState) =>
  !!(
    Object.keys(state.advertisers.creatingByRequestId).length ||
    Object.keys(state.advertisers.updatingById).length
  );

export const selectAreAllAdvertisersLoading = (state: RootState) =>
  state.advertisers.isAllLoading;

export const selectAreRunningAdvertisersLoading = (state: RootState) =>
  state.advertisers.isRunningLoading;

export const selectUpdatingAdvertiserIds = (state: RootState) =>
  state.advertisers.updatingById;

export const selectAvailableAdvertisersById = (state: RootState) =>
  state.advertisers.byId;

export const selectRunningAdvertisersById = (state: RootState) =>
  state.advertisers.runningAdvertisers;

export const selectAdvertiserById =
  (id: string) =>
  (state: RootState): Advertiser | undefined =>
    state.advertisers.byId[id];

export const selectAvailableAdvertisers = createSelector(
  selectAvailableAdvertisersById,
  (results: Record<string, Advertiser>) => Object.values(results)
);

export const selectRunningAdvertisers = createSelector(
  selectRunningAdvertisersById,
  (results: Record<string, Advertiser>) => Object.values(results)
);

export const selectReadyRunningAdvertisers = createSelector(
  selectRunningAdvertisersById,
  (results: Record<string, Advertiser>) =>
    Object.values(results).filter(a => a.status === ServiceStatus.READY)
);

export const selectAvailableAdvertisersUnarchived = createSelector(
  selectAvailableAdvertisersById,
  (results: Record<string, Advertiser>) =>
    Object.values(results).filter(a => a.status !== ServiceStatus.ARCHIVED)
);

export const selectAdvertiserIdsToNames = createSelector(
  selectAvailableAdvertisers,
  (results: Advertiser[]) => mapAdvertiserIdsToNames(results)
);

export const selectSortedAdvertisers = createSelector(
  selectAvailableAdvertisers,
  (advertisers: Advertiser[]) => {
    if (!Array.isArray(advertisers)) {
      return [];
    }
    return advertisers.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
  }
);

export const selectSortedRunningAdvertisers = createSelector(
  selectRunningAdvertisers,
  (advertisers: Advertiser[]) => {
    if (!Array.isArray(advertisers)) {
      return [];
    }
    return advertisers.sort((a, b) =>
      a.name.toLowerCase().localeCompare(b.name.toLowerCase())
    );
  }
);

export const selectSortedAdvertisersUnarchived = createSelector(
  selectAvailableAdvertisers,
  (advertisers: Advertiser[]) => {
    if (!Array.isArray(advertisers)) {
      return [];
    }
    return advertisers
      .filter(a => a.status !== ServiceStatus.ARCHIVED)
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  }
);

import {
  DetailedHTMLProps,
  HTMLAttributes,
  FC,
  useMemo,
  useCallback
} from "react";
import { Icon, IconSize, Intent } from "@blueprintjs/core";
import { css } from "@emotion/react";
import { IconNames } from "@blueprintjs/icons";
import { MhBaseProps } from "../../../../madhive/components/types";

interface ChipProps
  extends MhBaseProps<
    DetailedHTMLProps<HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>
  > {
  /**
   * Descriptive text for the Chip element
   */
  label: string;
  /**
   * Visual intent color to apply to element.
   */
  intent?: Intent;
  /**
   * Whether the Chip should has round corners or not
   *
   * @default false
   */
  round?: boolean;
  /**
   * Handler that is triggered once the user click on the Remove Icon
   * By default is renders an icon to trigger this handler
   */
  onRemove?: () => void | undefined;
  /**
   * JSX element to be displayed as custom Remove Icon. It will be only displayed if `onRemove` is defined
   * The icon is only rendered if `onRemove` is present
   */
  removeIcon?: JSX.Element;
}

const CLOSING_INTERVAL = 100;
const LIGHT_GREEN_COLOR = "#32c37a";

const styles = {
  base: css`
    display: flex;
    align-items: center;
    color: var(--white);
    font-size: var(--spacing-16);
    padding: var(--spacing-4) var(--spacing-12);
    margin: var(--spacing-4) var(--spacing-0);
    transition: all ${CLOSING_INTERVAL || 0}ms ease-in;
    border-radius: var(--spacing-0);
    flex-shrink: 0;

    & + * {
      margin-left: var(--spacing-4);
    }
  `,
  round: css`
    border-radius: var(--small-radius);
  `,
  success: css`
    background: var(--green);

    &:focus,
    &:hover {
      background: ${LIGHT_GREEN_COLOR};
    }
  `,
  danger: css`
    background: var(--red);

    &:focus,
    &:hover {
      background: var(--red-light);
    }
  `,
  removeIcon: css`
    color: var(--gray-1);
    cursor: pointer;

    &:hover,
    &:focus {
      color: var(--white);
    }
  `,
  removeIconWrapper: css`
    display: flex;
    border: none;
    padding: 0;
    outline: none;
    background: none;
    margin-left: var(--spacing-4);
    // this is to make the icon fit better the container's right padding
    margin-right: calc((var(--spacing-12) / 2 * -1));
    cursor: pointer;
  `,
  label: css`
    font-size: inherit;
    margin: var(--spacing-0);
  `,
  labelWrapper: css`
    display: flex;
  `
};

export const Chip: FC<ChipProps> = props => {
  const { label, intent, onRemove, removeIcon, round, ...spanProps } = props;

  const defaultRemoveIcon = useMemo(
    () => (
      <Icon
        icon={IconNames.SMALL_CROSS}
        iconSize={IconSize.STANDARD}
        data-testid={`chip-icon-${label}`}
        css={styles.removeIcon}
      />
    ),
    []
  );

  const renderRemoveIcon = useCallback(
    (icon: ChipProps["removeIcon"]) => (
      <button
        css={styles.removeIconWrapper}
        data-testid={`chip-icon-wrap-${label}`}
        onClick={onRemove}
        type="button"
      >
        {icon}
      </button>
    ),
    [removeIcon, onRemove]
  );

  return (
    <span
      css={[
        styles.base,
        intent === Intent.SUCCESS && styles.success,
        intent === Intent.DANGER && styles.danger,
        round && styles.round
      ]}
      {...spanProps}
    >
      <span css={styles.labelWrapper}>
        <span css={styles.label}>{label}</span>
      </span>
      {onRemove && renderRemoveIcon(removeIcon || defaultRemoveIcon)}
    </span>
  );
};

import axios from "axios";
import { Templator } from "../handlers";
class Templates extends Templator {
    constructor() {
        super(...arguments);
        this.fetchBulkUploadTemplate = async () => {
            const { data } = await axios.get(`${this.sdk.urls.baseAPIUrl}/inventory/bulk`, {
                responseType: "blob"
            });
            return data;
        };
    }
    createTemplate() {
        return this.fetchBulkUploadTemplate();
    }
}
export default Templates;

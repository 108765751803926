import { IToastProps } from "@blueprintjs/core";
import { FreewheelProduct } from "@madhive/mad-sdk";

export const GET_FW_PRODUCTS_PENDING = "GET_FW_PRODUCTS_PENDING";
export const GET_FW_PRODUCTS_SUCCESS = "GET_FW_PRODUCTS_SUCCESS";
export const GET_FW_PRODUCTS_FAILURE = "GET_FW_PRODUCTS_FAILURE";

export interface FreewheelProductsState {
  readonly byId: Record<string, FreewheelProduct>;
  readonly legacyById: Record<string, FreewheelProduct>;
  readonly isAllLoading: boolean;
  readonly receivedAllAt: number | null;
  readonly error: boolean;
}

interface GetFwProductsPendingAction {
  type: typeof GET_FW_PRODUCTS_PENDING;
}

interface GetFwProductsSuccessAction {
  type: typeof GET_FW_PRODUCTS_SUCCESS;
  payload: {
    products: FreewheelProduct[];
    legacyProducts: FreewheelProduct[];
  };
  meta: {
    timestamp: number;
  };
}

interface GetFwProductsFailureAction {
  type: typeof GET_FW_PRODUCTS_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

export type FwProductsActionTypes =
  | GetFwProductsPendingAction
  | GetFwProductsSuccessAction
  | GetFwProductsFailureAction;

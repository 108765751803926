/** The members of this enum are the string equivalents of the collection names in firestore. */
export enum FirestoreCollectionId {
  FLIGHTS = "flights",
  CAMPAIGNS = "campaigns",
  LINE_ITEMS = "lineItems",
  ORGANIZATION = "organizations",
  ADVERTISERS = "advertisers",
  AGENCIES = "agencies",
  SAVED_FORECASTS = "saved-forecasts",
  /** this collection only exists on  `mad-hack`. */
  SAVED_FORECASTS_MOCK = "saved-forecasts-mock",
  USERS = "users",
  AUDIT = "audit",
  SUMMARY = "summary",
  CONFIG = "config",
  NOTIFICATIONS = "notifications",
  ORGANIZATION_TEAM = "organization_team",
  PUBLISHERS = "providers",
  DASHBOARD = "dashboard"
}

/**
 * @param input: anything.
 * @returns: type narrowed. True if the input is a page. Else, false.
 */
export const isPage = (input) => {
    if (typeof input === "object" && input !== null && "data" in input && "page" in input) {
        const page = input;
        return Array.isArray(page.data) && "count" in page.page && "size" in page.page;
    }
    return false;
};
/**
 * @param input: anything.
 * @return: type narrowed. True if the input is a service page. Else, false.
 */
export const isServicePage = (input) => {
    if (typeof input === "object" && input !== null && "paging_info" in input && "data" in input) {
        const page = input;
        return (Array.isArray(page.data) &&
            typeof page.paging_info !== "undefined" &&
            "count" in page.paging_info &&
            "token" in page.paging_info);
    }
    return false;
};

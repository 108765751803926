import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const WindowWithLock: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-window-with-lock"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99668 3.74628C3.30587 3.74628 2.74585 4.30629 2.74585 4.99711V14.0009C2.74585 14.6917 3.30587 15.2517 3.99668 15.2517H5.24752V8.99794C5.24752 7.47916 6.47873 6.24794 7.99752 6.24794H17.2525V4.99711C17.2525 4.30629 16.6925 3.74628 16.0017 3.74628H3.99668ZM18.7525 6.24794V4.99711C18.7525 3.47787 17.5209 2.24628 16.0017 2.24628H3.99668C2.47744 2.24628 1.24585 3.47787 1.24585 4.99711V14.0009C1.24585 15.5201 2.47744 16.7517 3.99668 16.7517H5.24752V19.0038C5.24752 20.5226 6.47873 21.7538 7.99752 21.7538H20.0042C21.523 21.7538 22.7542 20.5226 22.7542 19.0038V8.99794C22.7542 7.47916 21.523 6.24794 20.0042 6.24794H18.7525ZM7.99752 7.74794C7.30716 7.74794 6.74752 8.30759 6.74752 8.99794V19.0038C6.74752 19.6941 7.30716 20.2538 7.99752 20.2538H20.0042C20.6945 20.2538 21.2542 19.6941 21.2542 19.0038V8.99794C21.2542 8.30759 20.6945 7.74794 20.0042 7.74794H7.99752ZM14.0008 11.1794C13.7222 11.1794 13.4549 11.2901 13.2579 11.4871C13.0608 11.6842 12.9501 11.9514 12.9501 12.2301L12.9499 12.6206H15.0518L15.0516 12.2305C15.0516 11.9518 14.9409 11.6842 14.7438 11.4871C14.5468 11.2901 14.2795 11.1794 14.0008 11.1794ZM16.5518 12.7699C17.2567 13.0637 17.7521 13.7593 17.7521 14.5706V16.3723C17.7521 17.4493 16.8791 18.3223 15.8021 18.3223H12.1996C11.1226 18.3223 10.2496 17.4493 10.2496 16.3723V14.5706C10.2496 13.7593 10.745 13.0637 11.4499 12.7699L11.4501 12.2301C11.4501 12.23 11.4501 12.2302 11.4501 12.2301C11.4502 11.5537 11.7189 10.9048 12.1972 10.4265C12.6756 9.94811 13.3243 9.67937 14.0008 9.67937C14.6774 9.67937 15.3261 9.94811 15.8045 10.4265C16.2828 10.9048 16.5515 11.5534 16.5516 12.2298C16.5516 12.2299 16.5516 12.2297 16.5516 12.2298L16.5518 12.7699ZM12.1996 14.1206C11.9511 14.1206 11.7496 14.3221 11.7496 14.5706V16.3723C11.7496 16.6209 11.9511 16.8223 12.1996 16.8223H15.8021C16.0506 16.8223 16.2521 16.6209 16.2521 16.3723V14.5706C16.2521 14.3221 16.0506 14.1206 15.8021 14.1206H12.1996Z"
      />
    </svg>
  );
};

export default WindowWithLock;

import axios from "axios";
import { NotImplementedError } from "../../errors";
import { Handler as PromiseHandler } from "../handlers";
import { LanguageContentObjectsFetchFailed } from "./errors";
export class LanguageContentObjects extends PromiseHandler {
    constructor(sdk) {
        super(sdk, "contentObjects.language", { atomize: true });
        this.url = `${this.sdk.urls.baseAPIUrl}/languages`;
    }
    findItems() {
        return this.cache.promise(this.url, () => axios
            .get(this.url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => res?.data?.map(this.toLanguage) || [])
            .catch((error) => {
            throw new LanguageContentObjectsFetchFailed(error);
        }));
    }
    /**
     * Converts from a service Language to a client Language.
     * @param language: the Language to convert.
     * @return: the converted Language.
     */
    toLanguage(language) {
        const { code, name, native_name: nativeName } = language;
        return { code, name, nativeName };
    }
    /**
     * @deprecated
     * Languages does not support save.
     */
    saveItem() {
        return new Promise((_, reject) => {
            reject(new NotImplementedError("save"));
        });
    }
    /**
     * @deprecated
     * Languages does not support make.
     */
    make() {
        return new Promise((_, reject) => {
            reject(new NotImplementedError("make"));
        });
    }
    async deleteItem() {
        return new Promise((_, reject) => {
            reject(new NotImplementedError("delete"));
        });
    }
}

import { useSelector } from "react-redux";
import { selectCurrentUser } from "appReducers/authReducer";
import { ViewId } from "@madhive/mad-sdk";
import { getUserPermissions } from "./utils";

const usePermissionView = (viewId: ViewId) => {
  const user = useSelector(selectCurrentUser);
  return getUserPermissions(viewId, user?.abilities || []);
};

export default usePermissionView;

import {
  CampaignAuditColumns,
  KnownOrganizationNames,
  ManageCampaignColumn,
  ORGANIZATION_ID_TO_SMITHERS_ORG_NAME,
  User
} from "@madhive/mad-sdk";
import { isPaceToBudgetEnabledSync } from "lib/utils/budget";

export interface ManageCampaignTableColumn {
  id: ManageCampaignColumn;
  label: string;
}

export const ALL_COLUMNS_MAP = () => {
  // Note that this is broken up into 2 maps because we add items
  // to the end of initialColumnsMap and ordering matters
  const initialColumnsMap = new Map([
    [ManageCampaignColumn.NAME, () => "Name"],
    [ManageCampaignColumn.ASSOCIATED_CAMPAIGN, () => "Associated Campaign"],
    // PK TODO: Uncomment when we support for campaigns
    //  [
    //    ManageCampaignColumn.DOUBLE_VERIFY,
    //    (user: User | null) =>
    //      user && user.primaryOrganizationId === KnownOrganizationIds.SCRIPPS
    //        ? "Features"
    //        : "Third Party"
    //  ],
    [ManageCampaignColumn.STATUS, () => "Status"],
    [ManageCampaignColumn.SET_LIVE, () => "Set Live"],
    [ManageCampaignColumn.SCRIPPS_ADBOOK_STATUS, () => "AdBook Status"],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_DROP_STATUS,
      () => "AdBook Drop Status"
    ],
    [ManageCampaignColumn.LINE_ITEMS_COUNT, () => "Line Items"],
    [ManageCampaignColumn.CREATIVES_COUNT, () => "Creatives"],
    [ManageCampaignColumn.ASSOCIATED_SCENARIO, () => "Associated Scenario"],
    [ManageCampaignColumn.LAST_UPDATED, () => "Last Updated"],
    [ManageCampaignColumn.UPDATED_BY, () => "Updated By"],
    [ManageCampaignColumn.START_DATE, () => "Start Date"],
    [ManageCampaignColumn.END_DATE, () => "End Date"],
    [ManageCampaignColumn.CATEGORY, () => "Categories"],
    [ManageCampaignColumn.MADHIVE_ID, () => "ID"],
    [ManageCampaignColumn.SCRIPPS_WIDE_ORBIT_ID, () => "WO ID"],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_CAMPAIGN_ID,
      () => "AdBook Campaign ID"
    ],
    [ManageCampaignColumn.SCRIPPS_ADBOOK_DROP_ID, () => "AdBook Drop ID"],
    [ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_ID, () => "AdBook Package ID"],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_NAME,
      () => "AdBook Package Name"
    ],

    [ManageCampaignColumn.PREMION_PLACEMENTS_IO_ID, () => "Placements.IO ID"],
    [ManageCampaignColumn.PREMION_PLACEMENTS_IO_GROUP_ID, () => "Group ID"],

    [
      ManageCampaignColumn.EXTERNAL_ID,
      (user: User | null) =>
        (user && user.settings.extMeta.externalIdFieldName) || "External ID"
    ],
    [ManageCampaignColumn.SCRIPPS_ADBOOK_AGENCY_ID, () => "AdBook Agency ID"],
    [ManageCampaignColumn.SCRIPPS_ADBOOK_STATION_ID, () => "AdBook Station ID"],
    [ManageCampaignColumn.SCRIPPS_ADBOOK_STATION, () => "AdBook Station"],

    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_CLIENT_NAME,
      () => "AdBook Advertiser"
    ],
    [ManageCampaignColumn.SCRIPPS_ADBOOK_MARKET, () => "AdBook Market"],
    [
      ManageCampaignColumn.SCRIPPS_OCTANE_PRODUCT,
      () => "Adbook Octane Product"
    ],

    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_POSTAL_CODES,
      () => "AdBook Postal Codes"
    ],
    [ManageCampaignColumn.SCRIPPS_ADBOOK_GEO, () => "AdBook Geo"],
    [ManageCampaignColumn.SCRIPPS_ADBOOK_GEO_IDS, () => "AdBook Geo IDs"]
  ]);

  const remainingColumnsMap = new Map([
    [ManageCampaignColumn.MEDIA_TYPE, () => "Media Type"],
    [
      ManageCampaignColumn.OMS_ID,
      (user: User | null) =>
        (user && user.settings.extMeta.omsIdFieldName) || "OMS ID"
    ],
    [ManageCampaignColumn.PREMION_CLIENT_CODE, () => "Client Code"],
    [
      ManageCampaignColumn.PREMION_CLIENT_ESTIMATE_CODE,
      () => "Client Estimate Code"
    ],
    [ManageCampaignColumn.PREMION_RFP_NAME, () => "RFP Name"],
    [ManageCampaignColumn.PREMION_RFP_DETAILS, () => "RFP Detail Name"],
    [ManageCampaignColumn.PREMION_REVENUE_TYPE, () => "Revenue Type"],
    [ManageCampaignColumn.SCRIPPS_ADBOOK_PRICE_TYPE, () => "AdBook Price Type"],
    [ManageCampaignColumn.SCRIPPS_ADBOOK_PRICE, () => "AdBook Price"],
    [ManageCampaignColumn.SCRIPPS_ADBOOK_GROSS_PRICE, () => "AdBook Drop Cost"],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_STN_DEMO_AUDIENCE,
      () => "AdBook Demo Audience"
    ],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_STN_CONSUMER_AUDIENCE,
      () => "AdBook Consumer Audience"
    ],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_SEGMENT_NOTE,
      () => "AdBook Segment Notes"
    ],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_CUSTOM_SEGMENT,
      () => "AdBook Custom Audience"
    ],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_CUSTOM_SEGMENT_NOTES,
      () => "AdBook Custom Audience Name"
    ],
    [ManageCampaignColumn.SCRIPPS_ID, () => "AdBook Scripps ID"],

    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_ADVERTISER_ID,
      () => "AdBook Advertiser ID"
    ],

    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_LAST_UPDATED,
      () => "AdBook Last Updated"
    ],
    [ManageCampaignColumn.SCRIPPS_ADBOOK_REVISION, () => "AdBook Revision"],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_WHITE_LIST,
      () => "Adbook Advertiser Publisher Group"
    ],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_WHITE_LIST_NAME,
      () => "Adbook Advertiser Publisher Group Name"
    ],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_AGENCY_NAME,
      () => "Adbook Agency Name"
    ],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_POSITION_PATH,
      () => "Adbook Package Position Path"
    ],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_POSITION_NAME,
      () => "Adbook Position Name"
    ],
    [
      ManageCampaignColumn.SCRIPPS_OCTANE_ADVERTISER_DOMAIN,
      () => "Adbook Advertiser Domain"
    ],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_LEVEL,
      () => "Adbook Package Level"
    ],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_LAST_CHANGE_BY,
      () => "Adbook Last Changed By"
    ],
    [
      ManageCampaignColumn.SCRIPPS_ADBOOK_CONTRACT_DATE,
      () => "Adbook Contract Date"
    ],
    [ManageCampaignColumn.FOX_WIDEORBIT_LINE_ID, () => "WideOrbit Line ID"],
    [ManageCampaignColumn.GROUNDTRUTH_BRANDS, () => "Brands"],
    [ManageCampaignColumn.GROUNDTRUTH_LOCATION_GROUPS, () => "Location Groups"],
    [
      ManageCampaignColumn.SALESFORCE_INSERTION_ORDER_ID,
      () => "SalesForce IO ID"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_ESTIMATE_NUMBER,
      () => "WideOrbit Estimate Number"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_ACCOUNT_EXECUTIVE,
      () => "Account Executive"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_PRODUCT_DESCRIPTION,
      () => "WideOrbit Product Description"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_IS_ORDER_DELETED,
      () => "WideOrbit Cancellation Status"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_UNIT_CODE_DISPLAY,
      () => "WideOrbit Unit Code Display"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_NUMBER_OF_TIMES_PROCESSED,
      () => "Version Number"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_AGENCY_COMMISSION,
      () => "WideOrbit Agency Commission"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_ORDER_IS_COOP,
      () => "WideOrbit Order Co-op"
    ],
    [ManageCampaignColumn.WIDEORBIT_CLIENT_CODE, () => "WideOrbit Client Code"],
    [
      ManageCampaignColumn.WIDEORBIT_AGENCY_PRODUCT_CODE,
      () => "WideOrbit Agency Product Code"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_BILLING_ADDRESS_STREET,
      () => "WideOrbit Billing Street"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_BILLING_ADDRESS_CITY,
      () => "WideOrbit Billing City"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_BILLING_ADDRESS_STATE,
      () => "WideOrbit Billing State"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_BILLING_ADDRESS_ZIP,
      () => "WideOrbit Billing ZIP"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_SALES_OFFICE,
      () => "WideOrbit Sales Office"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_SALES_REGION,
      () => "WideOrbit Sales Region"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_BILLING_CALENDAR,
      () => "WideOrbit Billing Calendar"
    ],
    [
      ManageCampaignColumn.WIDEORBIT_BILLING_CYCLE,
      () => "WideOrbit Billing Cycle"
    ],
    [ManageCampaignColumn.PRODUCT_CODE, () => "Product Code"],
    [ManageCampaignColumn.ESTIMATE_CODE, () => "Estimate Code"]
  ]);

  // if Goal Flag is set, add the `Goal` column, else, add the `Booked Impressions` column
  if (isPaceToBudgetEnabledSync()) {
    initialColumnsMap.set(ManageCampaignColumn.GOAL, () => "Goal");
  } else {
    initialColumnsMap.set(
      ManageCampaignColumn.BOOKED_IMPRESSIONS,
      (user: User | null) =>
        user &&
        ORGANIZATION_ID_TO_SMITHERS_ORG_NAME[user.primaryOrganizationId] ===
          KnownOrganizationNames.SCRIPPS
          ? "Impressions"
          : "Booked Impressions"
    );
  }

  return new Map([...initialColumnsMap, ...remainingColumnsMap]);
};
export const CAMPAIGN_AUDIT_COLUMN_HEADERS: CampaignAuditColumns[] = [
  CampaignAuditColumns.NAME,
  CampaignAuditColumns.ID,
  CampaignAuditColumns.VERSION,
  CampaignAuditColumns.UPDATED_BY,
  CampaignAuditColumns.UPDATED,
  CampaignAuditColumns.START_DATE,
  CampaignAuditColumns.END_DATE,
  CampaignAuditColumns.LINE_ITEMS,
  CampaignAuditColumns.EXT_METAS,
  CampaignAuditColumns.EXTERNAL_ID,
  CampaignAuditColumns.STATUS,
  CampaignAuditColumns.ADVERTISER,
  CampaignAuditColumns.CATEGORIES
];

export const LINEITEM_AUDIT_COLUMN_HEADERS: CampaignAuditColumns[] = [
  CampaignAuditColumns.NAME,
  CampaignAuditColumns.ID,
  CampaignAuditColumns.VERSION,
  CampaignAuditColumns.UPDATED_BY,
  CampaignAuditColumns.UPDATED,
  CampaignAuditColumns.START_DATE,
  CampaignAuditColumns.END_DATE,
  CampaignAuditColumns.IMPRESSIONS,
  CampaignAuditColumns.DAYPARTS,
  CampaignAuditColumns.DEVICE_CAPS,
  CampaignAuditColumns.PRODUCT_ID,
  CampaignAuditColumns.PUB_GROUP_ID,
  CampaignAuditColumns.GEO_INCLUDE,
  CampaignAuditColumns.GEO_EXCLUDE,
  CampaignAuditColumns.FREQUENCIES,
  CampaignAuditColumns.SEGMENTS,
  CampaignAuditColumns.CREATIVES,
  CampaignAuditColumns.EXT_METAS,
  CampaignAuditColumns.EXTERNAL_ID,
  CampaignAuditColumns.STATUS,
  CampaignAuditColumns.CATEGORIES
];

export const BASE_COLUMN_IDS = new Set([
  ManageCampaignColumn.NAME,
  ManageCampaignColumn.ASSOCIATED_CAMPAIGN,
  ManageCampaignColumn.STATUS,
  ManageCampaignColumn.SET_LIVE,
  ManageCampaignColumn.LINE_ITEMS_COUNT,
  ManageCampaignColumn.CREATIVES_COUNT,
  ManageCampaignColumn.ASSOCIATED_SCENARIO,
  ManageCampaignColumn.LAST_UPDATED,
  ManageCampaignColumn.UPDATED_BY,
  ManageCampaignColumn.START_DATE,
  ManageCampaignColumn.END_DATE,
  ManageCampaignColumn.CATEGORY,
  ManageCampaignColumn.MADHIVE_ID,
  ManageCampaignColumn.EXTERNAL_ID,
  ManageCampaignColumn.GOAL,
  ManageCampaignColumn.BOOKED_IMPRESSIONS
]);

export const BASE_LOGGED_IN_USER_COLUMN_IDS = new Set([
  ...BASE_COLUMN_IDS,
  ManageCampaignColumn.OMS_ID,
  ManageCampaignColumn.MEDIA_TYPE,
  /** SALESFORCE_INSERTION_ORDER_ID column is feature flagged now but will be extended to all orgs in future. */
  ManageCampaignColumn.SALESFORCE_INSERTION_ORDER_ID
]);

export const WIDE_ORBIT_COLUMN_IDS = new Set([
  ManageCampaignColumn.WIDEORBIT_ESTIMATE_NUMBER,
  ManageCampaignColumn.WIDEORBIT_ACCOUNT_EXECUTIVE,
  ManageCampaignColumn.WIDEORBIT_PRODUCT_DESCRIPTION,
  ManageCampaignColumn.WIDEORBIT_IS_ORDER_DELETED,
  ManageCampaignColumn.WIDEORBIT_UNIT_CODE_DISPLAY,
  ManageCampaignColumn.WIDEORBIT_NUMBER_OF_TIMES_PROCESSED,
  ManageCampaignColumn.WIDEORBIT_AGENCY_COMMISSION,
  ManageCampaignColumn.WIDEORBIT_ORDER_IS_COOP,
  ManageCampaignColumn.WIDEORBIT_CLIENT_CODE,
  ManageCampaignColumn.WIDEORBIT_AGENCY_PRODUCT_CODE,
  ManageCampaignColumn.WIDEORBIT_BILLING_ADDRESS_STREET,
  ManageCampaignColumn.WIDEORBIT_BILLING_ADDRESS_CITY,
  ManageCampaignColumn.WIDEORBIT_BILLING_ADDRESS_STATE,
  ManageCampaignColumn.WIDEORBIT_BILLING_ADDRESS_ZIP,
  ManageCampaignColumn.WIDEORBIT_SALES_OFFICE,
  ManageCampaignColumn.WIDEORBIT_SALES_REGION,
  ManageCampaignColumn.WIDEORBIT_BILLING_CALENDAR,
  ManageCampaignColumn.WIDEORBIT_BILLING_CYCLE
]);

export const PREMION_COLUMN_IDS = new Set([
  ...[...BASE_LOGGED_IN_USER_COLUMN_IDS].filter(
    id => id !== ManageCampaignColumn.OMS_ID
  ),
  ManageCampaignColumn.PREMION_CLIENT_CODE,
  ManageCampaignColumn.PREMION_CLIENT_ESTIMATE_CODE,
  ManageCampaignColumn.PREMION_RFP_NAME,
  ManageCampaignColumn.PREMION_RFP_DETAILS,
  ManageCampaignColumn.PREMION_REVENUE_TYPE,
  ManageCampaignColumn.PREMION_PLACEMENTS_IO_ID,
  ManageCampaignColumn.PREMION_PLACEMENTS_IO_GROUP_ID
]);

export const SCRIPPS_COLUMN_IDS = new Set([
  ...[...BASE_LOGGED_IN_USER_COLUMN_IDS].filter(
    id => id !== ManageCampaignColumn.OMS_ID
  ),
  // ManageCampaignColumn.DOUBLE_VERIFY,
  ManageCampaignColumn.SCRIPPS_ADBOOK_DROP_STATUS,
  ManageCampaignColumn.SCRIPPS_WIDE_ORBIT_ID,
  ManageCampaignColumn.SCRIPPS_ADBOOK_CAMPAIGN_ID,
  ManageCampaignColumn.SCRIPPS_ADBOOK_CLIENT_NAME,
  ManageCampaignColumn.SCRIPPS_ADBOOK_ADVERTISER_ID,

  ManageCampaignColumn.SCRIPPS_ADBOOK_STATUS,
  ManageCampaignColumn.SCRIPPS_ADBOOK_MARKET,
  ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_ID,
  ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_NAME,
  ManageCampaignColumn.SCRIPPS_ADBOOK_SEGMENT_NOTE,
  ManageCampaignColumn.SCRIPPS_ADBOOK_GEO,
  ManageCampaignColumn.SCRIPPS_ADBOOK_GEO_IDS,
  ManageCampaignColumn.SCRIPPS_ADBOOK_AGENCY_ID,
  ManageCampaignColumn.SCRIPPS_ADBOOK_STATION,
  ManageCampaignColumn.SCRIPPS_ADBOOK_STATION_ID,
  ManageCampaignColumn.SCRIPPS_ADBOOK_DROP_ID,
  ManageCampaignColumn.SCRIPPS_ID,

  ManageCampaignColumn.SCRIPPS_ADBOOK_PRICE_TYPE,
  ManageCampaignColumn.SCRIPPS_ADBOOK_PRICE,
  ManageCampaignColumn.SCRIPPS_ADBOOK_GROSS_PRICE,
  ManageCampaignColumn.SCRIPPS_ADBOOK_STN_DEMO_AUDIENCE,
  ManageCampaignColumn.SCRIPPS_ADBOOK_STN_CONSUMER_AUDIENCE,
  ManageCampaignColumn.SCRIPPS_ADBOOK_CUSTOM_SEGMENT,
  ManageCampaignColumn.SCRIPPS_ADBOOK_CUSTOM_SEGMENT_NOTES,
  ManageCampaignColumn.SCRIPPS_ADBOOK_POSTAL_CODES,
  ManageCampaignColumn.SCRIPPS_OCTANE_PRODUCT,
  ManageCampaignColumn.SCRIPPS_ADBOOK_LAST_UPDATED,
  ManageCampaignColumn.SCRIPPS_ADBOOK_REVISION,
  ManageCampaignColumn.SCRIPPS_ADBOOK_WHITE_LIST,
  ManageCampaignColumn.SCRIPPS_ADBOOK_WHITE_LIST_NAME,
  ManageCampaignColumn.SCRIPPS_ADBOOK_AGENCY_NAME,
  ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_POSITION_PATH,
  ManageCampaignColumn.SCRIPPS_ADBOOK_POSITION_NAME,
  ManageCampaignColumn.SCRIPPS_OCTANE_ADVERTISER_DOMAIN,
  ManageCampaignColumn.SCRIPPS_ADBOOK_PACKAGE_LEVEL,
  ManageCampaignColumn.SCRIPPS_ADBOOK_LAST_CHANGE_BY,
  ManageCampaignColumn.SCRIPPS_ADBOOK_CONTRACT_DATE
]);

export const FOX_COLUMN_IDS = new Set([
  ...[...BASE_LOGGED_IN_USER_COLUMN_IDS].filter(
    id => id !== ManageCampaignColumn.EXTERNAL_ID
  ),
  ...[...WIDE_ORBIT_COLUMN_IDS],
  ManageCampaignColumn.FOX_WIDEORBIT_LINE_ID
]);

export const GROUNDTRUTH_COLUMN_IDS = new Set([
  ...[...BASE_LOGGED_IN_USER_COLUMN_IDS].filter(
    id => id !== ManageCampaignColumn.EXTERNAL_ID
  ),
  ManageCampaignColumn.GROUNDTRUTH_BRANDS,
  ManageCampaignColumn.GROUNDTRUTH_LOCATION_GROUPS
]);

export const COMMON_COLUMN_IDS = new Set([
  ...BASE_LOGGED_IN_USER_COLUMN_IDS,
  ManageCampaignColumn.PRODUCT_CODE,
  ManageCampaignColumn.ESTIMATE_CODE
]);

export enum ColumnNamesForCSV {
  ID = "id",
  NAME = "name",
  ORDER_MANAGEMENT_SYSTEM_ID = "orderManagementSystemId",
  GOAL = "goal",
  BOOKED_IMPRESSIONS = "bookedImpressionsAsSumOfDescendantsBookedImpressions",
  START_DATE = "startDate",
  END_DATE = "endDate",
  PREMION_CLIENT_CODE = "premionFieldClientCode",
  PREMION_RFP_NAME = "premionFieldRfpName",
  PREMION_RFP_DETAILS = "premionFieldRfpDetailName",
  PREMION_REVENUE_TYPE = "premionFieldRevenueType",
  PREMION_PLACEMENTS_IO_ID = "premionPlacementsIoId",
  PREMION_PLACEMENTS_IO_GROUP_ID = "premionPlacementsIoGroupId",
  ESTIMATE_CODE = "estimateCode",
  PRODUCT_CODE = "productCode",
  SCRIPPS_ADBOOK_STATUS = "scrippsFieldAdbookStatus",
  SCRIPPS_ADBOOK_MARKET = "scrippsFieldAdbookMarket",
  SCRIPPS_ADBOOK_CLIENT_NAME = "scrippsFieldAdbookClientName",
  SCRIPPS_ADBOOK_EXTERNAL_ADVERTISER_ID = "scrippsFieldAdbookExternalAdvertiserId",
  SCRIPPS_ADBOOK_ADVERTISER = "scrippsFieldAdbookAdvertiser",
  SCRIPPS_ADBOOK_PACKAGE_ID = "scrippsFieldAdbookPackageId",
  SCRIPPS_ADBOOK_PACKAGE_NAME = "scrippsFieldAdbookPackageName",
  SCRIPPS_WIDE_ORBIT_ID = "scrippsWideOrbitId",
  SCRIPPS_ADBOOK_SEGMENT_NOTE = "scrippsFieldAdbookSegmentNotes",
  SCRIPPS_ADBOOK_GEO = "scrippsFieldAdbookGeo",
  SCRIPPS_ADBOOK_GEO_IDS = "scrippsFieldAdbookGeoIds",
  SCRIPPS_ADBOOK_AGENCY_ID = "scrippsFieldAdbookAgencyId",
  SCRIPPS_ADBOOK_CAMPAIGN_ID = "scrippsFieldAdbookCampaignId",
  SCRIPPS_ADBOOK_STATION = "scrippsFieldAdbookStation",
  SCRIPPS_ADBOOK_STATION_ID = "scrippsFieldAdbookStationId",
  SCRIPPS_ADBOOK_DROP_ID = "scrippsFieldAdbookDropId",
  SCRIPPS_ADBOOK_DROP_STATUS = "scrippsFieldAdbookLineItemStatus",
  SCRIPPS_ID = "scrippsFieldAdbookScrippsId",
  SCRIPPS_ADBOOK_ADVERTISER_ID = "scrippsFieldAdbookAdvertiserId",
  SCRIPPS_ADBOOK_PRICE = "scrippsFieldAdbookPrice",
  SCRIPPS_ADBOOK_PRICE_TYPE = "scrippsFieldAdbookPriceType",
  SCRIPPS_ADBOOK_GROSS_PRICE = "scrippsFieldAdbookGrossPrice",
  SCRIPPS_ADBOOK_STN_DEMO_AUDIENCE = "scrippsFieldAdbookStnDemoAudience",
  SCRIPPS_ADBOOK_STN_CONSUMER_AUDIENCE = "scrippsFieldAdbookStnConsumerAudience",
  SCRIPPS_ADBOOK_CUSTOM_SEGMENT = "scrippsFieldAdbookCustomSegment",
  SCRIPPS_ADBOOK_CUSTOM_SEGMENT_NOTES = "scrippsFieldAdbookCustomSegmentNotes",
  SCRIPPS_OCTANE_PRODUCT = "scrippsFieldAdbookProduct",
  SCRIPPS_ADBOOK_POSTAL_CODES = "scrippsFieldAdbookPostalCodes",
  SCRIPPS_ADBOOK_REVISION = "scrippsFieldAdbookRevision",
  SCRIPPS_ADBOOK_LAST_UPDATED = "scrippsFieldAdbookLastUpdated",
  SCRIPPS_ADBOOK_WHITE_LIST = "scrippsFieldAdbookAdvertiserWhitelist",
  SCRIPPS_ADBOOK_WHITE_LIST_NAME = "scrippsFieldAdbookAdvertiserWhitelistName",
  SCRIPPS_ADBOOK_AGENCY_NAME = "scrippsFieldAdbookAgencyName",
  SCRIPPS_ADBOOK_PACKAGE_POSITION_PATH = "scrippsFieldAdbookPackagePositionPath",
  SCRIPPS_ADBOOK_POSITION_NAME = "scrippsFieldAdbookPositionName",
  SCRIPPS_OCTANE_ADVERTISER_DOMAIN = "scrippsFieldAdbookAdvertiserDomain",
  SCRIPPS_ADBOOK_PACKAGE_LEVEL = "scrippsFieldAdbookPackageLevel",
  SCRIPPS_ADBOOK_LAST_CHANGE_BY = "scrippsFieldAdbookLastChangedBy",
  SCRIPPS_ADBOOK_CONTRACT_DATE = "scrippsFieldAdbookContractDate",
  EXTERNAL_ID = "externalId",
  FOX_WIDEORBIT_LINE_ID = "foxWideorbitLineId",
  GROUNDTRUTH_BRANDS = "groundTruthBrands",
  GROUNDTRUTH_LOCATION_GROUPS = "groundTruthLocationGroups",
  ASSOCIATED_CAMPAIGN = "campaignName",
  MEDIA_TYPE = "mediaType",
  CATEGORY = "category"
}

export enum DialogName {
  POSTAL_CODES = "Postal Codes",
  GEOS = "Geos",
  GEO_IDS = "Geo IDs"
}

import { memo, useState, useEffect } from "react";
import Row from "./row";
import Cell from "./headerCell";
import { ToggleSelected, SortDirection, TableColumn } from "./types";

interface TableHeaderProps {
  columns: TableColumn[];
  widths: number[];
  toggleSelected?: ToggleSelected;
  sortColumn: number;
  sortDirection: SortDirection;
  setSortColumn: (columnIndex: number) => void;
  canFixColumns?: boolean;
  stickyColumns: number[];
  setStickyColumn: (columnIndex: number) => void;
  setColumnWidth: (columnIndex: number, width: number) => void;
  setOpenRows: (rows: Set<number>) => void;
}

const TableHeader = memo((props: TableHeaderProps) => {
  const [resizing, setResizing] = useState(-1);
  const [resizeStart, setResizeStart] = useState(0);
  const [resizeInitWidth, setResizeInitWidth] = useState(0);

  useEffect(() => {
    if (resizing === -1) return;
    setResizeInitWidth(props.widths[resizing]);
  }, [resizing]);

  const stopResizing = () => setResizing(-1);

  const handleMouseMove = (mouseMoveEvent: { screenX: number }) => {
    if (resizing === -1) return;
    props.setColumnWidth(
      resizing,
      mouseMoveEvent.screenX - resizeStart + resizeInitWidth
    );
  };

  return (
    <Row
      header
      toggleSelected={props.toggleSelected}
      onMouseDown={onMouseDownEvent => setResizeStart(onMouseDownEvent.screenX)}
      onMouseMove={handleMouseMove}
      onMouseUp={stopResizing}
      onMouseLeave={stopResizing}
    >
      {props.columns.map((column, columnIndex) => (
        <Cell
          column={column}
          key={columnIndex}
          columnIndex={columnIndex}
          rowIndex={-1}
          isResizing={resizing > -1}
          setResizing={setResizing}
          isSortColumn={props.sortColumn === columnIndex}
          sortDirection={props.sortDirection}
          setSortColumn={props.setSortColumn}
          fixable={!!props.canFixColumns}
          setStickyColumn={props.setStickyColumn}
          setColumnWidth={props.setColumnWidth}
          isSticky={props.stickyColumns.includes(columnIndex)}
          setOpenRows={props.setOpenRows}
        />
      ))}
    </Row>
  );
});

export default TableHeader;

import { Venue } from "appReducers/oohReducer/types";
import { setDateToLastSecondOfDay, setDateToMidnight } from "lib/utils/date";
import { PlanInventoryMetric } from "ooh-types";
import {
  DaypartMetrics,
  InventoryMetrics,
  InventoryDimension
} from "./generated-types";

export const MIN_REPORT_DATE = setDateToMidnight(new Date("2019-01-01 00:00"));
const tempDate = new Date();
tempDate.setDate(tempDate.getDate() - 3);
export const MAX_REPORT_DATE = setDateToLastSecondOfDay(tempDate);
export const MIN_PLAN_DATE = setDateToMidnight(new Date("2021-01-01 00:00"));

export enum PlanFilterType {
  CONSTRAINTS = "CONSTRAINTS",
  HEATMAP = "HEATMAP",
  DATATABLE = "DATATABLE",
  BARCHART = "BARCHART",
  BUBBLECHART = "BUBBLECHART",
  SCATTERPLOT = "SCATTERPLOT"
}

/**
 * This is a mapping between the available metrics for a piece of inventory and
 * their correspoding field in the query response, this facilitates to dynamically
 * access an specific metric using filters. The dimension metric is omitted as it
 * is not considered a metric perse.
 */
export const InventoryMetricsFields: Record<
  Exclude<InventoryMetrics, InventoryMetrics.Dimension>,
  PlanInventoryMetric
> = {
  [InventoryMetrics.TotalAdUnits]: "totalAdUnits",
  [InventoryMetrics.TotalImpressions]: "totalImpressions",
  [InventoryMetrics.AudienceImpressions]: "audienceImpressions",
  [InventoryMetrics.AudienceComposition]: "audienceComposition",
  [InventoryMetrics.AudienceIndex]: "audienceIndex",
  [InventoryMetrics.Cpm]: "cpm"
} as const;

export const inventoryDimensionOptions = [
  {
    label: "Venue",
    id: InventoryDimension.Venue
  },
  {
    label: "Publisher",
    id: InventoryDimension.Publisher
  },
  {
    label: "Bundle",
    id: InventoryDimension.Bundle
  },
  {
    label: "Orientation",
    id: InventoryDimension.Orientation
  }
];

export const daypartFilterOptions: Array<{
  label: string;
  id: DaypartMetrics;
}> = [
  { label: "Impressions", id: DaypartMetrics.Impressions },
  { label: "Reach", id: DaypartMetrics.Reach },
  { label: "Frequency", id: DaypartMetrics.Frequency }
];

export const pivotMetricsOptions: Array<{
  label: string;
  id: InventoryMetrics;
}> = [
  {
    label: "Ad Units",
    id: InventoryMetrics.TotalAdUnits
  },
  {
    label: "Total Impression",
    id: InventoryMetrics.TotalImpressions
  },
  {
    label: "Audience Impressions",
    id: InventoryMetrics.AudienceImpressions
  },
  {
    label: "Audience Composition %",
    id: InventoryMetrics.AudienceComposition
  },
  {
    label: "Audience Composition Index",
    id: InventoryMetrics.AudienceIndex
  },
  {
    label: "CPM",
    id: InventoryMetrics.Cpm
  }
];

export const getPivotDimensionLabel = (dimension: InventoryDimension) =>
  inventoryDimensionOptions.find(i => i.id === dimension)?.label || "Unknown";

export const getPivotMetricLabel = (metricId: string): string | "None" => {
  const option = pivotMetricsOptions.find(opt => opt.id === metricId);
  return option ? option.label : "None";
};

export const getPivotMetricUnit = (metricId: InventoryMetrics) => {
  switch (metricId) {
    case InventoryMetrics.AudienceComposition:
      return "%";
    default:
      return " ";
  }
};

export const DEFAULT_NYC_LAT = 40.749767;
export const DEFAULT_NYC_LNG = -73.9900567;
export const DEFAULT_ZOOM = 14;
export const MAX_ZOOM = 19;

export const HEATMAP_COLORS = [
  "rgba(234, 209, 157, 0)",
  "rgba(234, 209, 157, 0.7)",
  "rgba(234, 209, 157, 1)",
  "rgba(232, 186, 86, 1)",
  "rgba(219, 149, 92, 1)"
];

export const customClusterIcons = [
  {
    url: "/mapClusterMarkers/blue20.png",
    height: 20,
    width: 20,
    textColor: "#fff"
  },
  {
    url: "/mapClusterMarkers/blue30.png",
    height: 30,
    width: 30,
    textColor: "#fff"
  },
  {
    url: "/mapClusterMarkers/blue40.png",
    height: 40,
    width: 40,
    textColor: "#fff"
  },
  {
    url: "/mapClusterMarkers/blue50.png",
    height: 50,
    width: 50,
    textColor: "#fff"
  },
  {
    url: "/mapClusterMarkers/blue60.png",
    height: 60,
    width: 60,
    textColor: "#fff"
  },
  {
    url: "/mapClusterMarkers/blue70.png",
    height: 70,
    width: 70,
    textColor: "#fff"
  },
  {
    url: "/mapClusterMarkers/blue80.png",
    height: 80,
    width: 80,
    textColor: "#fff"
  }
];

export const MAP_STYLES: google.maps.MapTypeStyle[] = [
  {
    elementType: "geometry",
    stylers: [
      {
        color: "#f5f5f5"
      }
    ]
  },
  {
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off"
      }
    ]
  },
  {
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161"
      }
    ]
  },
  {
    elementType: "labels.text.stroke",
    stylers: [
      {
        color: "#f5f5f5"
      }
    ]
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#bdbdbd"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee"
      }
    ]
  },
  {
    featureType: "poi",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5"
      }
    ]
  },
  {
    featureType: "poi.park",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        color: "#ffffff"
      }
    ]
  },
  {
    featureType: "road.arterial",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#757575"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        color: "#dadada"
      }
    ]
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#616161"
      }
    ]
  },
  {
    featureType: "road.local",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  },
  {
    featureType: "transit.line",
    elementType: "geometry",
    stylers: [
      {
        color: "#e5e5e5"
      }
    ]
  },
  {
    featureType: "transit.station",
    elementType: "geometry",
    stylers: [
      {
        color: "#eeeeee"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#c9c9c9"
      }
    ]
  },
  {
    featureType: "water",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#9e9e9e"
      }
    ]
  }
];

export const VENUE_TYPES = [
  {
    slug: "Mobility",
    label: "Mobility",
    labelWithParents: "Mobility",
    id: "1",
    children: [
      {
        slug: "AIRBORNE",
        label: "Airborne",
        labelWithParents: "Mobility > Airborne",
        id: "1.1"
      },
      {
        slug: "AIRPORTS",
        label: "Airports",
        labelWithParents: "Mobility > Airports",
        id: "1.2",
        children: [
          {
            slug: "AIRPORTS_BAGGAGE_CLAIM",
            label: "Baggage Claim",
            labelWithParents: "Mobility > Airports > Baggage Claim",
            id: "1.2.1"
          },
          {
            slug: "AIRPORTS_IN_TERMINAL",
            label: "Terminals",
            labelWithParents: "Mobility > Airports > Terminals",
            id: "1.2.2"
          },
          {
            slug: "AIRPORTS_LOUNGES",
            label: "Lounges",
            labelWithParents: "Mobility > Airports > Lounges",
            id: "1.2.3"
          },
          {
            slug: "AIRPORTS_ARRIVALS",
            label: "Arrivals",
            labelWithParents: "Mobility > Airports > Arrivals",
            id: "1.2.4"
          },
          {
            slug: "AIRPORTS_DEPARTURES",
            label: "Departures",
            labelWithParents: "Mobility > Airports > Departures",
            id: "1.2.5"
          },
          {
            slug: "AIRPORTS_DUTY_FREE",
            label: "Duty Free",
            labelWithParents: "Mobility > Airports > Duty Free",
            id: "1.2.6"
          },
          {
            slug: "AIRPORTS_GATES",
            label: "Gates",
            labelWithParents: "Mobility > Airports > Gates",
            id: "1.2.7"
          },
          {
            slug: "AIRPORTS_PUBLIC_AREAS",
            label: "Public Areas",
            labelWithParents: "Mobility > Airports > Public Areas",
            id: "1.2.8"
          },
          {
            slug: "AIRPORTS_RESTRICTED_AREAS",
            label: "Restricted Areas",
            labelWithParents: "Mobility > Airports > Restricted Areas",
            id: "1.2.9"
          }
        ]
      },
      {
        slug: "HIGHWAYS",
        label: "Highways",
        labelWithParents: "Mobility > Highways",
        id: "1.3",
        children: [
          {
            slug: "HIGHWAYS_REST_AREAS",
            label: "Rest Areas",
            labelWithParents: "Mobility > Highways > Rest Areas",
            id: "1.3.1"
          },
          {
            slug: "HIGHWAYS_ROADSIDE",
            label: "Roadside",
            labelWithParents: "Mobility > Highways > Roadside",
            id: "1.3.2"
          }
        ]
      },
      {
        slug: "GAS_STATIONS",
        label: "Gas Stations",
        labelWithParents: "Mobility > Gas Stations",
        id: "1.4"
      },
      {
        slug: "PUBLIC_TRANSPORT",
        label: "Public Transport",
        labelWithParents: "Mobility > Public Transport",
        id: "1.5",
        children: [
          {
            slug: "RAIL",
            label: "Rail",
            labelWithParents: "Mobility > Public Transport > Rail",
            id: "1.5.1"
          },
          {
            slug: "SUBWAY",
            label: "Subway",
            labelWithParents: "Mobility > Public Transport > Subway",
            id: "1.5.2"
          },
          {
            slug: "BUSSES",
            label: "Buses",
            labelWithParents: "Mobility > Public Transport > Buses",
            id: "1.5.3",
            children: [
              {
                slug: "BUSSES_INSIDE",
                label: "Inside",
                labelWithParents:
                  "Mobility > Public Transport > Buses > Inside",
                id: "1.5.3.1"
              },
              {
                slug: "BUSSES_OUTSIDE",
                label: "Outside",
                labelWithParents:
                  "Mobility > Public Transport > Buses > Outside",
                id: "1.5.3.2"
              }
            ]
          },
          {
            slug: "TRAMS",
            label: "Trams",
            labelWithParents: "Mobility > Public Transport > Trams",
            id: "1.5.4",
            children: [
              {
                slug: "TRAMS_INSIDE",
                label: "Inside",
                labelWithParents:
                  "Mobility > Public Transport > Trams > Inside",
                id: "1.5.4.1"
              },
              {
                slug: "TRAMS_OUTSIDE",
                label: "Outside",
                labelWithParents:
                  "Mobility > Public Transport > Trams > Outside",
                id: "1.5.4.2"
              }
            ]
          },
          {
            slug: "TAXIS",
            label: "Taxis",
            labelWithParents: "Mobility > Public Transport > Taxis",
            id: "1.5.5",
            children: [
              {
                slug: "TAXIS_INSIDE",
                label: "Inside",
                labelWithParents:
                  "Mobility > Public Transport > Taxis > Inside",
                id: "1.5.5.1"
              },
              {
                slug: "TAXIS_OUTSIDE",
                label: "Outside",
                labelWithParents:
                  "Mobility > Public Transport > Taxis > Outside",
                id: "1.5.5.2"
              }
            ]
          }
        ]
      },
      {
        slug: "STATIONS",
        label: "Stations",
        labelWithParents: "Mobility > Stations",
        id: "1.6",
        children: [
          {
            slug: "STATIONS_RAILWAY",
            label: "Railway",
            labelWithParents: "Mobility > Stations > Railway",
            id: "1.6.1",
            children: [
              {
                slug: "STATIONS_RAILWAY_ENTRANCES",
                label: "Entrances",
                labelWithParents: "Mobility > Stations > Railway > Entrances",
                id: "1.6.1.1"
              },
              {
                slug: "STATIONS_RAILWAY_PLATFORMS",
                label: "Platforms",
                labelWithParents: "Mobility > Stations > Railway > Platforms",
                id: "1.6.1.2"
              }
            ]
          },
          {
            slug: "STATIONS_SUBWAY",
            label: "Subway",
            labelWithParents: "Mobility > Stations > Subway",
            id: "1.6.2",
            children: [
              {
                slug: "STATIONS_SUBWAY_ENTRANCES",
                label: "Entrances",
                labelWithParents: "Mobility > Stations > Subway > Entrances",
                id: "1.6.2.1"
              },
              {
                slug: "STATIONS_SUBWAY_PLATFORMS",
                label: "Platforms",
                labelWithParents: "Mobility > Stations > Subway > Platforms",
                id: "1.6.2.2"
              }
            ]
          },
          {
            slug: "STATIONS_BUS",
            label: "Bus",
            labelWithParents: "Mobility > Stations > Bus",
            id: "1.6.3"
          },
          {
            slug: "STATIONS_TRAM",
            label: "Tram",
            labelWithParents: "Mobility > Stations > Tram",
            id: "1.6.4"
          }
        ]
      },
      {
        slug: "PARKING",
        label: "Parking",
        labelWithParents: "Mobility > Parking",
        id: "1.7",
        children: [
          {
            slug: "PARKING_GARAGES",
            label: "Garages",
            labelWithParents: "Mobility > Parking > Garages",
            id: "1.7.1"
          },
          {
            slug: "PARKING_CAR_PARKS",
            label: "Car Parks",
            labelWithParents: "Mobility > Parking > Car Parks",
            id: "1.7.2"
          }
        ]
      },
      {
        slug: "TRUCKSIDE",
        label: "Truckside",
        labelWithParents: "Mobility > Truckside",
        id: "1.8"
      },
      {
        slug: "MARINE",
        label: "Marine",
        labelWithParents: "Mobility > Marine",
        id: "1.9"
      },
      {
        slug: "FERRIES",
        label: "Ferries",
        labelWithParents: "Mobility > Ferries",
        id: "1.10"
      },
      {
        slug: "CHARGING_STATION",
        label: "Charging Station",
        labelWithParents: "Mobility > Charging Station",
        id: "1.11"
      },
      {
        slug: "RENTAL_CAR_SERVICES",
        label: "Rental Car Services",
        labelWithParents: "Mobility > Rental Car Services",
        id: "1.12"
      }
    ]
  },
  {
    slug: "RETAIL_POS",
    label: "Retail",
    labelWithParents: "Retail",
    id: "2",
    children: [
      {
        slug: "CONVENIENCE_STORES",
        label: "Convenience Stores",
        labelWithParents: "Retail > Convenience Stores",
        id: "2.1"
      },
      {
        slug: "ELECTRONICS_STORES",
        label: "Electronics Stores",
        labelWithParents: "Retail > Electronics Stores",
        id: "2.2",
        children: [
          {
            slug: "ELECTRONICS_STORES_CHECKOUT",
            label: "Checkout",
            labelWithParents: "Retail > Electronics Stores > Checkout",
            id: "2.2.1"
          },
          {
            slug: "ELECTRONICS_STORES_INSTORE",
            label: "In Store",
            labelWithParents: "Retail > Electronics Stores > In Store",
            id: "2.2.2"
          }
        ]
      },
      {
        slug: "KIOSKS",
        label: "Kiosks",
        labelWithParents: "Retail > Kiosks",
        id: "2.3"
      },
      {
        slug: "MALLS",
        label: "Malls",
        labelWithParents: "Retail > Malls",
        id: "2.4",
        children: [
          {
            slug: "MALLS_FOOD_COURTS",
            label: "Food Courts",
            labelWithParents: "Retail > Malls > Food Courts",
            id: "2.4.1"
          }
        ]
      },
      {
        slug: "NEWSSTANDS",
        label: "Newsstands",
        labelWithParents: "Retail > Newsstands",
        id: "2.5"
      },
      {
        slug: "LOTTERY_RETAILER",
        label: "Lottery Retailer",
        labelWithParents: "Retail > Lottery",
        id: "2.6"
      },
      {
        slug: "POST_OFFICES",
        label: "Post Office",
        labelWithParents: "Retail > Post Office",
        id: "2.7"
      },
      {
        slug: "SUPERMARKETS",
        label: "Supermarkets",
        labelWithParents: "Retail > Supermarkets",
        id: "2.8",
        children: [
          {
            slug: "SUPERMARKETS_CHECKOUT",
            label: "Checkout",
            labelWithParents: "Retail > Supermarkets > Checkout",
            id: "2.8.1"
          },
          {
            slug: "SUPERMARKETS_INSTORE",
            label: "In Store",
            labelWithParents: "Retail > Supermarkets > In Store",
            id: "2.8.2"
          }
        ]
      },
      {
        slug: "HARDWARE_AND_FURNITURE_STORES",
        label: "Hardware & Furniture",
        labelWithParents: "Retail > Hardware & Furniture",
        id: "2.10"
      },
      {
        slug: "FASHION",
        label: "Fashion",
        labelWithParents: "Retail > Fashion",
        id: "2.11"
      },
      {
        slug: "SPORTS",
        label: "Sports",
        labelWithParents: "Retail > Sports",
        id: "2.12"
      },
      {
        slug: "BAKERIES",
        label: "Bakeries",
        labelWithParents: "Retail > Bakeries",
        id: "2.13"
      },
      {
        slug: "MEAT_FISH",
        label: "Meat & Fish",
        labelWithParents: "Retail > Meat & Fish",
        id: "2.14"
      },
      {
        slug: "OPTICIAN",
        label: "Optician",
        labelWithParents: "Retail > Optician",
        id: "2.15"
      },
      {
        slug: "TOURISM",
        label: "Tourism",
        labelWithParents: "Retail > Tourism",
        id: "2.16",
        children: [
          {
            slug: "TOURISM_TRAVEL_AGENCY",
            label: "Travel Agency",
            labelWithParents: "Retail > Tourism > Travel Agency",
            id: "2.16.1"
          },
          {
            slug: "TOURISM_TOURIST_INFORMATION",
            label: "Tourist Info",
            labelWithParents: "Retail > Tourism > Tourist Info",
            id: "2.16.2"
          }
        ]
      },
      {
        slug: "CAR_DEALERSHIP_OR_WORKSHOP",
        label: "Car Dealership or Workshop",
        labelWithParents: "Retail > Car Dealership or Workshop",
        id: "2.17"
      },
      {
        slug: "FINANCIAL_SERVICES",
        label: "Financial Services",
        labelWithParents: "Retail > Financial Services",
        id: "2.18",
        children: [
          {
            slug: "FINANCIAL_SERVICES_BANKS",
            label: "Banks",
            labelWithParents: "Retail > Financial Services > Banks",
            id: "2.18.1"
          },
          {
            slug: "FINANCIAL_SERVICES_ATMS",
            label: "ATMs",
            labelWithParents: "Retail > Financial Services > ATMs",
            id: "2.18.2"
          }
        ]
      },
      {
        slug: "PRESS_AND_BOOK_STORES",
        label: "Press & Book Stores",
        labelWithParents: "Retail > Press & Book Stores",
        id: "2.19"
      },
      {
        slug: "BOOK_STORES",
        label: "Book Stores",
        labelWithParents: "Retail > Book Stores",
        id: "2.20"
      },
      {
        slug: "DRUG_STORES",
        label: "Drug Stores",
        labelWithParents: "Retail > Drug Stores",
        id: "2.21"
      },
      {
        slug: "OTHER",
        label: "Other",
        labelWithParents: "Retail > Other",
        id: "2.22"
      }
    ]
  },
  {
    slug: "OUTDOOR",
    label: "Outdoor",
    labelWithParents: "Outdoor",
    id: "3",
    children: [
      {
        slug: "Street",
        label: "Street",
        labelWithParents: "Outdoor > Street",
        id: "3.1",
        children: [
          {
            slug: "Street_dCLP",
            label: "City Light Poster",
            labelWithParents: "Outdoor > Street > City Light Poster",
            id: "3.1.1"
          },
          {
            slug: "Street_dCLB",
            label: "City Light Boards",
            labelWithParents: "Outdoor > Street > City Light Boards",
            id: "3.1.2"
          },
          {
            slug: "Street_Videoboard",
            label: "Videoboard",
            labelWithParents: "Outdoor > Street > Videoboard",
            id: "3.1.3"
          }
        ]
      },
      {
        slug: "Pedestrian_Zone",
        label: "Pedestrian Zone",
        labelWithParents: "Outdoor > Pedestrian Zone",
        id: "3.2",
        children: [
          {
            slug: "Pedestrian_Zone_dCLP",
            label: "City Light Poster",
            labelWithParents: "Outdoor > Pedestrian Zone > City Light Poster",
            id: "3.2.1"
          },
          {
            slug: "Pedestrian_Zone_dCLB",
            label: "City Light Boards",
            labelWithParents: "Outdoor > Pedestrian Zone > City Light Boards",
            id: "3.2.2"
          },
          {
            slug: "Pedestrian_Zone_Videoboard",
            label: "Videoboard",
            labelWithParents: "Outdoor > Pedestrian Zone > Videoboard",
            id: "3.2.3"
          }
        ]
      }
    ]
  },
  {
    slug: "Healthcare",
    label: "Healthcare",
    labelWithParents: "Healthcare",
    id: "4",
    children: [
      {
        slug: "DOCTORS_OFFICES",
        label: "Doctor's Offices",
        labelWithParents: "Doctor's Offices",
        id: "4.1",
        children: [
          {
            slug: "GENERAL MEDICINE",
            label: "General Medicine",
            labelWithParents: "Doctor's Offices > General Medicine",
            id: "4.1.1"
          },
          {
            slug: "ANGIOLOGY",
            label: "Angiology",
            labelWithParents: "Doctor's Offices > Angiology",
            id: "4.1.2"
          },
          {
            slug: "OCCUPATIONAL MEDICINE",
            label: "Occupational Medicine",
            labelWithParents: "Doctor's Offices > Occupational Medicine",
            id: "4.1.3"
          },
          {
            slug: "OPHTHALMOLOGY",
            label: "Ophthalmology",
            labelWithParents: "Doctor's Offices > Ophthalmology",
            id: "4.1.4"
          },
          {
            slug: "SURGERY",
            label: "Surgery",
            labelWithParents: "Doctor's Offices > Surgery",
            id: "4.1.5"
          },
          {
            slug: "DIABETOLOGY",
            label: "Diabetology",
            labelWithParents: "Doctor's Offices > Diabetology",
            id: "4.1.6"
          },
          {
            slug: "ENDOCRINOLOGY",
            label: "Endocrinology",
            labelWithParents: "Doctor's Offices > Endocrinology",
            id: "4.1.7"
          },
          {
            slug: "GYNECOLOGY AND OBSTETRICS",
            label: "OB/GYN",
            labelWithParents: "Doctor's Offices > OB/GYN",
            id: "4.1.8"
          },
          {
            slug: "GASTROENTEROLOGY",
            label: "Gastroenterology",
            labelWithParents: "Doctor's Offices > Gastroenterology",
            id: "4.1.9"
          },
          {
            slug: "VASCULAR SURGERY",
            label: "Vascular Surgery",
            labelWithParents: "Doctor's Offices > Vascular Surgery",
            id: "4.1.10"
          },
          {
            slug: "OTOLARYNGOLOGY",
            label: "Otorhinolaryngology",
            labelWithParents: "Doctor's Offices > Otorhinolaryngology",
            id: "4.1.11"
          },
          {
            slug: "DERMATOLOGY",
            label: "Dermatology",
            labelWithParents: "Doctor's Offices > Dermatology",
            id: "4.1.12"
          },
          {
            slug: "CARDIOSURGERY",
            label: "Cardiosurgery",
            labelWithParents: "Doctor's Offices > Cardiosurgery",
            id: "4.1.13"
          },
          {
            slug: "HYGIENE AND ENVIROMENTAL MEDICINE",
            label: "Hygiene & Environmental Health",
            labelWithParents:
              "Doctor's Offices > Hygiene & Environmental Health",
            id: "4.1.14"
          },
          {
            slug: "INTERNAL MEDICINE",
            label: "Internal Medicine",
            labelWithParents: "Doctor's Offices > Internal Medicine",
            id: "4.1.15"
          },
          {
            slug: "CARDIOLOGY",
            label: "Cardiology",
            labelWithParents: "Doctor's Offices > Cardiology",
            id: "4.1.16"
          },
          {
            slug: "ORTHODENTICS",
            label: "Orthodontics",
            labelWithParents: "Doctor's Offices > Orthodontics",
            id: "4.1.17"
          },
          {
            slug: "PAEDIATRICS & ADOLESCENT MEDICINE",
            label: "Pediatrics & Adolescent Medicine",
            labelWithParents:
              "Doctor's Offices > Pediatrics & Adolescent Medicine",
            id: "4.1.18"
          },
          {
            slug: "PAEDIATRIC & ADOLOSCENT PSYACHIATRY",
            label: "Pediatrics & Adolescent Psychiatry",
            labelWithParents:
              "Doctor's Offices > Pediatrics & Adolescent Psychiatry",
            id: "4.1.19"
          },
          {
            slug: "ORAL AND MAXILLOFACIAL SURGERY",
            label: "Oral & Maxillofacial Surgery",
            labelWithParents: "Doctor's Offices > Oral & Maxillofacial Surgery",
            id: "4.1.20"
          },
          {
            slug: "NEUROLOGY",
            label: "Neurology",
            labelWithParents: "Doctor's Offices > Neurology",
            id: "4.1.21"
          },
          {
            slug: "ORTHOPAEDICS AND TRAUMATOLOGY",
            label: "Orthopedics and Traumatology",
            labelWithParents: "Doctor's Offices > Orthopedics and Traumatology",
            id: "4.1.22"
          },
          {
            slug: "PLASTIC AND AESTHETIC SURGERY",
            label: "Plastic & Aesthetic Surgery",
            labelWithParents: "Doctor's Offices > Plastic & Aesthetic Surgery",
            id: "4.1.23"
          },
          {
            slug: "PNEUMOLOGY",
            label: "Pulmonology",
            labelWithParents: "Doctor's Offices > Pulmonology",
            id: "4.1.24"
          },
          {
            slug: "PSYCHOTHERAPEUTIC MEDICINE",
            label: "Psychotherapeutic Medicine",
            labelWithParents: "Doctor's Offices > Psychotherapeutic Medicine",
            id: "4.1.25"
          },
          {
            slug: "PSYCHIATRY AND PSYCHOTHERAPY",
            label: "Psychiatry & Psychotherapy",
            labelWithParents: "Doctor's Offices > Psychiatry & Psychotherapy",
            id: "4.1.26"
          },
          {
            slug: "RADIOLOGY / NUCLEAR MEDICINE",
            label: "Radiology & Nuclear Medicine",
            labelWithParents: "Doctor's Offices > Radiology & Nuclear Medicine",
            id: "4.1.27"
          },
          {
            slug: "RHEUMATOLOGY",
            label: "Rheumatology",
            labelWithParents: "Doctor's Offices > Rheumatology",
            id: "4.1.28"
          }
        ]
      },
      {
        slug: "PHARMACIES",
        label: "Pharmacies",
        labelWithParents: "Pharmacies",
        id: "4.2"
      },
      {
        slug: "HOSPITALS",
        label: "Hospitals",
        labelWithParents: "Hospitals",
        id: "4.3"
      },
      {
        slug: "VETERINARIAN_OFFICES",
        label: "Veterinarian Offices",
        labelWithParents: "Veterinarian Offices",
        id: "4.4"
      },
      {
        slug: "PHYSIOTHERAPIST",
        label: "Physical Therapist",
        labelWithParents: "Physical Therapist",
        id: "4.5"
      }
    ]
  },
  {
    slug: "EDUCATION_WORKPLACE_SERVICES",
    label: "Education & Workplace Services",
    labelWithParents: "Education & Workplace Services",
    id: "5",
    children: [
      {
        slug: "SCHOOLS",
        label: "Schools",
        labelWithParents: "Education & Workplace Services > Schools",
        id: "5.1"
      },
      {
        slug: "UNIVERSITY",
        label: "University",
        labelWithParents: "Education & Workplace Services > University",
        id: "5.2",
        children: [
          {
            slug: "UNIVERSITY_LIBRARIES",
            label: "Libraries",
            labelWithParents:
              "Education & Workplace Services > University > Libraries",
            id: "5.2.1"
          },
          {
            slug: "UNIVERSITY_BIOCENTERS",
            label: "Biocenters",
            labelWithParents:
              "Education & Workplace Services > University > Biocenters",
            id: "5.2.2"
          },
          {
            slug: "UNIVERSITY_LOBBY",
            label: "Lobby",
            labelWithParents:
              "Education & Workplace Services > University > Lobby",
            id: "5.2.3"
          },
          {
            slug: "UNIVERSITY_AUDITORIUM",
            label: "Auditorium",
            labelWithParents:
              "Education & Workplace Services > University > Auditorium",
            id: "5.2.4"
          },
          {
            slug: "UNIVERSITY_CANTEEN",
            label: "Canteen",
            labelWithParents:
              "Education & Workplace Services > University > Canteen",
            id: "5.2.5"
          },
          {
            slug: "UNIVERSITY_GYM",
            label: "Gym",
            labelWithParents:
              "Education & Workplace Services > University > Gym",
            id: "5.2.6"
          },
          {
            slug: "UNIVERSITY_DEPT_Science & Mathematics",
            label: "Science & Mathematics",
            labelWithParents:
              "Education & Workplace Services > University > Science & Mathematics",
            id: "5.2.7"
          },
          {
            slug: "UNIVERSITY_DEPT_Engineering",
            label: "Engineering",
            labelWithParents:
              "Education & Workplace Services > University > Engineering",
            id: "5.2.8"
          },
          {
            slug: "UNIVERSITY_DEPT_Social_Sciences",
            label: "Social_Sciences",
            labelWithParents:
              "Education & Workplace Services > University > Social_Sciences",
            id: "5.2.9"
          },
          {
            slug: "UNIVERSITY_DEPT_Law & Economics",
            label: "Law & Economics",
            labelWithParents:
              "Education & Workplace Services > University > Law & Economics",
            id: "5.2.10"
          },
          {
            slug: "UNIVERSITY_DEPT_Medicine & Healthcare",
            label: "Medicine & Healthcare",
            labelWithParents:
              "Education & Workplace Services > University > Medicine & Healthcare",
            id: "5.2.11"
          },
          {
            slug: "UNIVERSITY_DEPT_Teaching_Professions",
            label: "Teaching_Professions",
            labelWithParents:
              "Education & Workplace Services > University > Teaching_Professions",
            id: "5.2.12"
          },
          {
            slug: "UNIVERSITY_DEPT_Linguistics & Cultural Studies",
            label: "Linguistics & Cultural Studies",
            labelWithParents:
              "Education & Workplace Services > University > Linguistics & Cultural Studies",
            id: "5.2.13"
          },
          {
            slug: "UNIVERSITY_DEPT_Agriculture and Forestry",
            label: "Agriculture and Forestry",
            labelWithParents:
              "Education & Workplace Services > University > Agriculture and Forestry",
            id: "5.2.14"
          },
          {
            slug: "UNIVERSITY_DEPT_Officials courses",
            label: "Officials courses",
            labelWithParents:
              "Education & Workplace Services > University > Officials courses",
            id: "5.2.15"
          },
          {
            slug: "UNIVERSITY_DEPT_Art, Design & Music",
            label: "Art, Design & Music",
            labelWithParents:
              "Education & Workplace Services > University > Art, Design & Music",
            id: "5.2.16"
          }
        ]
      },
      {
        slug: "TECHNICAL_COLLEGES",
        label: "Technical",
        labelWithParents: "Education & Workplace Services > Technical",
        id: "5.3"
      },
      {
        slug: "CHILD_CARE",
        label: "Child Care",
        labelWithParents: "Education & Workplace Services > Child Care",
        id: "5.4"
      },
      {
        slug: "OFFICE_BUILDINGS",
        label: "Office Buildings",
        labelWithParents: "Education & Workplace Services > Office Buildings",
        id: "5.5"
      },
      {
        slug: "TRADE_FAIR",
        label: "Trade Fair",
        labelWithParents: "Education & Workplace Services > Trade Fair",
        id: "5.6"
      },
      {
        slug: "GOVERNMENT OFFICES",
        label: "Government Offices",
        labelWithParents: "Education & Workplace Services > Government Offices",
        id: "5.7",
        children: [
          {
            slug: "GOVERNMENT OFFICES_NATIONAL",
            label: "Federal",
            labelWithParents:
              "Education & Workplace Services > Government Offices > Federal",
            id: "5.7.1"
          },
          {
            slug: "GOVERNMENT OFFICES_REGIONAL",
            label: "State",
            labelWithParents:
              "Education & Workplace Services > Government Offices > State",
            id: "5.7.2"
          },
          {
            slug: "GOVERNMENT OFFICES_LOCAL",
            label: "Local",
            labelWithParents:
              "Education & Workplace Services > Government Offices > Local",
            id: "5.7.3"
          }
        ]
      },
      {
        slug: "MILITARY",
        label: "Military",
        labelWithParents: "Education & Workplace Services > Military",
        id: "5.8"
      }
    ]
  },
  {
    slug: "ENTERTAINMENT_LEISURE",
    label: "Entertainment & Leisure",
    labelWithParents: "Entertainment & Leisure",
    id: "6",
    children: [
      {
        slug: "HOSPITALITY",
        label: "Hospitality",
        labelWithParents: "Entertainment & Leisure > Hospitality",
        id: "6.1",
        children: [
          {
            slug: "HOSPITALITY_BARS",
            label: "Bars",
            labelWithParents: "Entertainment & Leisure > Hospitality > Bars",
            id: "6.1.1"
          },
          {
            slug: "HOSPITALITY_CLUBS_DISCOS",
            label: "Clubs & Discos",
            labelWithParents:
              "Entertainment & Leisure > Hospitality > Clubs & Discos",
            id: "6.1.2"
          },
          {
            slug: "HOSPITALITY_CAFES_AND_COFFEESHOPS",
            label: "Cafés & Coffeeshops",
            labelWithParents:
              "Entertainment & Leisure > Hospitality > Cafés & Coffee Shops",
            id: "6.1.3"
          },
          {
            slug: "HOSPITALITY_RESTAURANTS",
            label: "Restaurants",
            labelWithParents:
              "Entertainment & Leisure > Hospitality > Restaurants",
            id: "6.1.7"
          },
          {
            slug: "HOSPITALITY_HIGH_END_RESTAURANTS",
            label: "High End Restaurants",
            labelWithParents:
              "Entertainment & Leisure > Hospitality > High End Restaurants",
            id: "6.1.8"
          },
          {
            slug: "HOSPITALITY_QSR",
            label: "Quick Service",
            labelWithParents:
              "Entertainment & Leisure > Hospitality > Quick Service",
            id: "6.1.9"
          }
        ]
      },
      {
        slug: "HOTELS",
        label: "Hotels",
        labelWithParents: "Entertainment & Leisure > Hotels",
        id: "6.2"
      },
      {
        slug: "MOVIE_THEATER",
        label: "Movie Theater",
        labelWithParents: "Entertainment & Leisure > Movie Theater",
        id: "6.3",
        children: [
          {
            slug: "MOVIE_THEATER_LOBBIES",
            label: "Lobbies",
            labelWithParents:
              "Entertainment & Leisure > Movie Theater > Lobbies",
            id: "6.3.1"
          }
        ]
      },
      {
        slug: "THEATER_OPERA_MUSICAL",
        label: "Musical Theater",
        labelWithParents: "Entertainment & Leisure > Musical Theater",
        id: "6.4"
      },
      {
        slug: "SPORTS",
        label: "Sports",
        labelWithParents: "Entertainment & Leisure > Sports",
        id: "6.5",
        children: [
          {
            slug: "SPORTS_GOLF_COURSES",
            label: "Golf Sources",
            labelWithParents: "Entertainment & Leisure > Sports > Golf Sources",
            id: "6.5.1"
          },
          {
            slug: "SPORTS_GYMS",
            label: "Gyms",
            labelWithParents: "Entertainment & Leisure > Sports > Gyms",
            id: "6.5.2"
          },
          {
            slug: "SPORTS_SWIMMING",
            label: "Swimming",
            labelWithParents: "Entertainment & Leisure > Sports > Swimming",
            id: "6.5.3"
          },
          {
            slug: "SPORTS_ARENAS",
            label: "Arenas",
            labelWithParents: "Entertainment & Leisure > Sports > Arenas",
            id: "6.5.4"
          }
        ]
      },
      {
        slug: "RESORTS_LEISURE",
        label: "Resorts",
        labelWithParents: "Entertainment & Leisure > Resorts",
        id: "6.6"
      },
      {
        slug: "SALONS",
        label: "Salons",
        labelWithParents: "Entertainment & Leisure > Salons",
        id: "6.7"
      },
      {
        slug: "TANNING_STUDIOS",
        label: "Tanning Studios",
        labelWithParents: "Entertainment & Leisure > Tanning Studios",
        id: "6.8"
      },
      {
        slug: "FAMILY_ENTERTAINMENT",
        label: "Family Entertainment",
        labelWithParents: "Entertainment & Leisure > Family Entertainment",
        id: "6.9"
      },
      {
        slug: "NIGHTLIFE",
        label: "Nightlife",
        labelWithParents: "Entertainment & Leisure > Nightlife",
        id: "6.10"
      }
    ]
  },
  {
    slug: "UNKNOWN",
    label: "Unknown",
    labelWithParents: "Unknown",
    id: "0"
  }
];
/* eslint-disable no-param-reassign */
const reduceVenue = (
  accum: Record<string, Venue>,
  cur: Venue
): Record<string, Venue> => {
  accum[cur.slug] = cur;
  if (cur.children) {
    return cur.children.reduce(reduceVenue, accum);
  }
  return accum;
};
/* eslint-enable no-param-reassign */
export const VENUE_TYPES_BY_SLUG: Record<string, Venue> = VENUE_TYPES.reduce(
  reduceVenue,
  {}
);

import { FiltersRequiredError, NotImplementedError, ReportingCreativesFetchFailed } from "../../index";
import { Handler } from "../handlers";
import axios from "axios";
class ReportingCreatives extends Handler {
    constructor(sdk) {
        super(sdk, "reporting-creatives", { atomize: true });
    }
    async findItems(filters) {
        const advertiserId = filters.where?.find((filter) => filter.field === "advertiserId")?.value;
        const email = filters.where?.find((filter) => filter.field === "email")?.value;
        if (!advertiserId || !email) {
            throw new FiltersRequiredError();
        }
        const url = `${this.reportingCreativesUrl}/${advertiserId}?email=${email}`;
        return this.cache.promise(url, () => axios
            .get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data }) => data)
            .catch(() => {
            throw new ReportingCreativesFetchFailed();
        }));
    }
    get reportingCreativesUrl() {
        return `${this.sdk.urls.madhiveReportingBaseUrl}/creatives`;
    }
    /**
     * saveItem isn't implemented for reporting creatives.
     */
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    saveItem(creatives) {
        throw new NotImplementedError("save");
    }
    /**
     * deleteItem isn't implemented for reporting creatives.
     */
    deleteItem() {
        throw new NotImplementedError("deleteItem");
    }
}
export default ReportingCreatives;

import { getDetailedErrorMessage } from "../../../utils/validation";
export class DmpSegmentsFetchFailed extends Error {
    constructor(apiError, fallbackMessage = "Failed to retrieve data management platform segments. Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, DmpSegmentsFetchFailed);
        }
        this.name = "DmpSegmentsFetchFailed";
        this.message = getDetailedErrorMessage(apiError, fallbackMessage);
    }
}

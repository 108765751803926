import { FC, useEffect, useRef, useState } from "react";
import { css } from "@emotion/react";
import { AvailableOption } from "components/Reusable/SmithersMultiSelect/SmithersMultiSelect";
import { useIABCategoriesSmithers } from "frontier/lib/hooks/useCategories";
import { Drawer } from "frontier/lib/components";
import { ArchiveFilter } from "lib/constants/filters";
import { Advertiser, PublisherValidation } from "@madhive/mad-sdk";
import { FormattedDataWithCategory } from "types";
import { useActiveAdvertisers } from "@madhive/mad-hooks";
import { madSDK } from "lib/sdk";
import {
  Button,
  FormField,
  Grid,
  Icons,
  RadioGroup,
  Select
} from "frontier/lib/kit";
import {
  ArchiveStatus,
  ArchiveStatusSelector
} from "frontier/lib/forms/selectors/archive_status";
import {
  CreativeLibraryFilterCategory,
  VideoStandardsStatus
} from "../constants";

export enum CreativeTypeOptions {
  VIDEO = "Video Creatives",
  VIDEO_AND_LINEAR = "Video & Linear"
}

const style = {
  drawer: css`
    width: 100%;
    height: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  `,
  header: css`
    display: flex;
    height: 3.25rem;
    gap: 1rem;
    align-items: center;
    border-bottom: solid 1px var(--color-border-primary);
    padding: 0 1rem;
    font-size: var(--font-size-heading-md);
    font-weight: var(--font-weight-heading);
  `,
  icon: css`
    width: var(--font-size-heading-md);
    height: var(--font-size-heading-md);
  `,
  blueprintLabelContainer: css`
    margin-top: var(--spacing-32);
    .bp4-label {
      font-weight: bold;
      margin-bottom: var(--spacing-16) !important;
      .bp4-text-muted {
        font-weight: normal;
      }
    }
  `
};

interface FilterSet {
  archive: string | null;
  advertisers: Array<string>;
  categories: Array<string>;
  publishers: Array<string>;
  validations: string | null;
}
const FiltersDrawer: FC<{
  onClose: () => void;
  onApply: (filter: FormattedDataWithCategory[]) => void;
  filters: FormattedDataWithCategory[];
}> = props => {
  const { onClose, filters, onApply } = props;

  const [activeFilters, setActiveFilters] = useState<FilterSet>({
    archive: ArchiveFilter.ACTIVE.toString(),
    advertisers: [],
    categories: [],
    publishers: [],
    validations: null
  });

  const publishers = madSDK.getCurrentUser()?.settings.publisher_validations;

  const validations = [
    {
      value: "PASSED",
      label: `Show ${VideoStandardsStatus.PASSED}`
    },
    {
      value: "FAILED",
      label: `Show ${VideoStandardsStatus.FAILED_NON_DURATION_SPEC}`
    }
  ];

  const { data: advertisers, byId: advertiserById } = useActiveAdvertisers();
  const { filterCategories, byId: categoryById } = useIABCategoriesSmithers();
  const drawerRef = useRef<HTMLDivElement | null>(null);

  const onCloseDrawer = () => {
    onClose();
  };

  const onChange = (key: string, selections: Array<string>) => {
    setActiveFilters({
      ...activeFilters,
      [key]: selections
    });
  };

  const onChangeValidation = (newSelection: string | null) => {
    setActiveFilters({ ...activeFilters, validations: newSelection });
  };

  // -------------------------------
  // The following is to take the legacy way of filtering and convert
  // into something usable and back again
  // Given legacy filter to a simpliar interface
  useEffect(() => {
    const filter: FilterSet = {
      archive: ArchiveFilter.ACTIVE.toString(),
      advertisers: [],
      categories: [],
      publishers: [],
      validations: null
    };
    for (let i = 0; i < filters.length; i++) {
      switch (filters[i].category) {
        case CreativeLibraryFilterCategory.ARCHIVED_STATUS:
          filter.archive = filters[i].value;
          break;
        case CreativeLibraryFilterCategory.ADVERTISER:
          filter.advertisers.push(filters[i].value);
          break;
        case CreativeLibraryFilterCategory.CATEGORY:
          filter.categories.push(filters[i].value);
          break;
        case CreativeLibraryFilterCategory.PUBLISHER:
          filter.publishers.push(filters[i].value);
          break;
        case CreativeLibraryFilterCategory.PUBLISHER_STATUS:
          filter.validations = filters[i].value;
          break;
        default:
          break;
      }
    }
    if (publishers?.length === 1) {
      filter.publishers = publishers.map(v => v.id);
    }
    setActiveFilters(filter);
  }, [filters, publishers]);

  // Take the simple interface and converts to a legacy filter
  const applyFilters = () => {
    const filters: Array<FormattedDataWithCategory> = [];
    if (activeFilters?.archive) {
      filters.push({
        category: CreativeLibraryFilterCategory.ARCHIVED_STATUS,
        value: activeFilters.archive,
        label:
          ArchiveStatus.find(status => status.value === activeFilters.archive)
            ?.label || ""
      });
    }
    if (activeFilters.advertisers) {
      activeFilters.advertisers.forEach(adv => {
        filters.push({
          category: CreativeLibraryFilterCategory.ADVERTISER,
          value: adv,
          label: advertiserById?.get(adv)?.name || ""
        });
      });
    }
    if (activeFilters.categories) {
      activeFilters.categories.forEach(cat => {
        filters.push({
          category: CreativeLibraryFilterCategory.CATEGORY,
          value: cat,
          label: categoryById?.get(cat)?.description || ""
        });
      });
    }
    if (
      activeFilters.publishers.length > 0 &&
      activeFilters.validations != null
    ) {
      activeFilters.publishers.forEach(pub => {
        filters.push({
          category: CreativeLibraryFilterCategory.PUBLISHER,
          value: pub,
          label: publishers?.find(publisher => publisher.id === pub)?.name || ""
        });
      });
      filters.push({
        category: CreativeLibraryFilterCategory.PUBLISHER_STATUS,
        value: activeFilters.validations,
        label:
          validations.find(val => val.value === activeFilters.validations)
            ?.label || ""
      });
    }
    onApply(filters);
  };
  // -------------------------------

  return (
    <Drawer isOpen onClose={onCloseDrawer} position="right" size="400px">
      <div css={style.header}>
        <div css={style.icon}>
          <Icons.FilterList />
        </div>
        Filters
      </div>
      <div css={style.drawer} ref={drawerRef}>
        <Grid columns={1}>
          <Select
            fill
            options={advertisers || []}
            lookupId={(v: Advertiser) => {
              return v.id;
            }}
            lookupLabel={(v: Advertiser) => {
              return v.name;
            }}
            selected={activeFilters.advertisers || []}
            onSelect={(v: Array<string>) => onChange("advertisers", v)}
            label="Advertiser"
            height="10rem"
            searchable
            search={{
              keys: ["name"]
            }}
            lockedWidth
            displayCap={25}
          />
          <Select
            fill
            options={filterCategories || []}
            lookupId={(v: AvailableOption) => {
              return v.id;
            }}
            lookupLabel={(v: AvailableOption) => {
              return v.name;
            }}
            selected={activeFilters.categories || []}
            onSelect={(v: Array<string>) => onChange("categories", v)}
            label="Category"
            height="10rem"
            searchable
            search={{
              keys: ["name"]
            }}
            lockedWidth
          />
          {publishers && publishers?.length > 1 && (
            <Select
              fill
              options={publishers || []}
              lookupId={(v: PublisherValidation) => {
                return v.id;
              }}
              lookupLabel={(v: PublisherValidation) => {
                return v.name;
              }}
              selected={activeFilters.publishers || []}
              onSelect={(v: Array<string>) => onChange("publishers", v)}
              label="Publisher"
              height="10rem"
              searchable
              search={{
                keys: ["name"]
              }}
              lockedWidth
            />
          )}
          {activeFilters.publishers.length > 0 && (
            <FormField label="Validations">
              <RadioGroup
                options={validations}
                selectedValue={activeFilters.validations}
                onChange={val => {
                  onChangeValidation(val);
                }}
                lookupLabel={i => i?.label}
                lookupValue={i => i?.value}
                stacked
              />
            </FormField>
          )}
          <FormField label="Archive">
            <ArchiveStatusSelector
              onSelect={value => {
                setActiveFilters({ ...activeFilters, archive: value });
              }}
              selected={activeFilters?.archive || null}
            />
          </FormField>
        </Grid>
        <Button onClick={applyFilters}>+ Apply Filters</Button>
      </div>
    </Drawer>
  );
};

export default FiltersDrawer;

import React, {
  FocusEvent,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import { css } from "@emotion/react";
import { InputDateProps } from "../types";
import { isInputDateRangeProps, isInputDateSingleProps } from "../types/guards";
import { InputDateSingle } from "./Single";
import { InputDateRange } from "./Range";
import { styles } from "./styles";
import DatePickerFlyout from "./Picker/Flyout";
import Button from "../../Button";
import * as Icons from "../../Icons";
import { DateRange } from "../../../types";

export const InputDate = <Value extends DateRange | Date | undefined>({
  pickerPlacement = "start",
  noPicker,
  error,
  time,
  min,
  max,
  flyoutProps = {},
  disabled,
  placeholder,
  focusOnMount,
  setError,
  /**
   * Destructuring props used at this base level.
   * Typically, all props should be destructured, but in this case,
   * the benefits of destructuring do not outweigh the added lift
   * of inferring and passing each individual props.
   */
  ...uniqueProps
}: InputDateProps<Value>) => {
  const [selecting, setSelecting] = useState<"start" | "end" | undefined>();
  const [targetIsFocused, setTargetIsFocused] = useState(false);
  const [isOpen, setIsOpen] = useState(!!flyoutProps?.isOpen);

  useMemo(() => setIsOpen(!!flyoutProps?.isOpen), [flyoutProps?.isOpen]);

  const isRange = Array.isArray(uniqueProps.value);

  const handleOnPickerChange = (value: DateRange) => {
    if (isInputDateRangeProps(uniqueProps)) {
      uniqueProps.onChange(value);
      if (selecting === "end") {
        setIsOpen(false);
        setTargetIsFocused(false);
      }
    } else if (isInputDateSingleProps(uniqueProps)) {
      uniqueProps.onChange(value[0]);
    }
  };

  const onTargetFocus = useCallback(
    (e: FocusEvent<HTMLDivElement>) => {
      if (disabled) {
        e.currentTarget?.blur();
        return;
      }
      setSelecting(prev => (!prev ? "start" : prev));
      setTargetIsFocused(true);
    },
    [setIsOpen, setSelecting]
  );

  const onTargetKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      switch (e.key) {
        case "Escape":
          e.preventDefault();
          e.stopPropagation();
          e.currentTarget?.blur();
          setTargetIsFocused(false);
          setSelecting(undefined);
          setIsOpen(false);
          break;
        case "Enter": {
          if (isOpen) {
            e.currentTarget?.blur();
            setSelecting(undefined);
            setTargetIsFocused(false);
          } else {
            setIsOpen(true);
            setTargetIsFocused(true);
          }
          break;
        }
        case "Tab": {
          if (!isRange || selecting === "end") {
            e.currentTarget?.blur();
            setIsOpen(false);
            setTargetIsFocused(false);
            setSelecting(undefined);
          }
          break;
        }
        default:
          break;
      }
    },
    [setTargetIsFocused, setIsOpen, isOpen, selecting]
  );

  useEffect(() => () => setIsOpen(false), []);

  const PickerTarget = useMemo(() => {
    if (pickerPlacement === "hidden") {
      return undefined;
    }
    const className = `date-input-icon ${pickerPlacement}`;
    if (noPicker) {
      return (
        <div className={className}>
          <Icons.Calendar />
        </div>
      );
    }
    return (
      <Button
        disabled={disabled}
        variant="ghost"
        className={className}
        css={css`
          --icon-fill-color: unset !important;
        `}
        prefix="Calendar"
      />
    );
  }, [noPicker, pickerPlacement]);

  return (
    <DatePickerFlyout
      range={isRange}
      time={time}
      disabled={disabled || noPicker}
      selected={
        Array.isArray(uniqueProps.value)
          ? uniqueProps.value
          : [uniqueProps.value]
      }
      setSelected={handleOnPickerChange}
      selecting={selecting}
      setSelecting={setSelecting}
      isOpen={targetIsFocused || isOpen}
      setIsOpen={setIsOpen}
      min={min}
      max={max}
      hasBackdrop={flyoutProps.hasBackdrop}
      presets={uniqueProps.presets}
      transitionMs={flyoutProps.transitionMs}
      setEndToEndOfDay={
        (isInputDateRangeProps(uniqueProps) && uniqueProps.setEndToEndOfDay) ||
        (isInputDateSingleProps(uniqueProps) && uniqueProps.isEndDate)
      }
    >
      <div
        role="button"
        tabIndex={-1}
        onFocus={onTargetFocus}
        onBlur={e => {
          e.currentTarget?.blur();
          setTargetIsFocused(false);
          setIsOpen(false);
        }}
        onClick={e => targetIsFocused && e.stopPropagation()}
        onKeyDown={onTargetKeyDown}
        className="subKit-InputDate"
        css={[
          styles.base,
          isRange && styles.range.base,
          error && styles.withError,
          disabled && styles.disabled,
          time && styles.withTime,
          pickerPlacement === "start" && styles.withPrefix,
          pickerPlacement === "end" && styles.withSuffix,
          isRange && pickerPlacement === "start" && styles.range.withPrefix,
          isRange && pickerPlacement === "end" && styles.range.withSuffix
        ]}
      >
        {/*
         * typically, props should be destructured, but in this case,
         * the benefits of destructuring do not outweigh the added lift
         * of inferring and passing each individual prop
         */}
        {isInputDateRangeProps(uniqueProps) && (
          <InputDateRange
            type={uniqueProps.type}
            value={uniqueProps.value}
            setEndToEndOfDay={uniqueProps.setEndToEndOfDay}
            onChange={uniqueProps.onChange}
            name={uniqueProps.name}
            id={uniqueProps.id}
            onKeyUp={uniqueProps.onKeyUp}
            onBlur={uniqueProps.onBlur}
            onClick={uniqueProps.onClick}
            onInput={uniqueProps.onInput}
            onFocus={(
              e: FocusEvent<HTMLInputElement>,
              startEnd: "start" | "end" | undefined
            ) => {
              setSelecting(startEnd);
              uniqueProps.onFocus?.(e, startEnd);
            }}
            onKeyDown={(
              e: KeyboardEvent<HTMLInputElement>,
              startEnd: "start" | "end" | undefined
            ) => {
              onTargetKeyDown(e);
              uniqueProps.onKeyDown?.(e, startEnd);
            }}
            picker={PickerTarget}
            pickerPlacement={pickerPlacement}
            error={error}
            time={time}
            min={min}
            max={max}
            disabled={disabled}
            placeholder={placeholder}
            focusOnMount={focusOnMount}
            setError={setError}
          />
        )}
        {isInputDateSingleProps(uniqueProps) && (
          <InputDateSingle
            type={uniqueProps.type}
            value={uniqueProps.value}
            onChange={uniqueProps.onChange}
            name={uniqueProps.name}
            id={uniqueProps.id}
            isEndDate={uniqueProps.isEndDate}
            onFocus={uniqueProps.onFocus}
            onKeyUp={uniqueProps.onKeyUp}
            onBlur={uniqueProps.onBlur}
            onClick={uniqueProps.onClick}
            onInput={uniqueProps.onInput}
            onKeyDown={e => {
              onTargetKeyDown(e);
              uniqueProps.onKeyDown?.(e);
            }}
            picker={PickerTarget}
            pickerPlacement={pickerPlacement}
            error={error}
            time={time}
            min={min}
            max={max}
            disabled={disabled}
            placeholder={placeholder}
            focusOnMount={focusOnMount}
            setError={setError}
          />
        )}
      </div>
    </DatePickerFlyout>
  );
};

export var FilterTypes;
(function (FilterTypes) {
    FilterTypes["EQ"] = "eq";
    FilterTypes["IN"] = "in";
    FilterTypes["GT"] = "gt";
    FilterTypes["INTERSECTION"] = "intersection";
    FilterTypes["BETWEEN"] = "between";
    FilterTypes["LT"] = "lt";
})(FilterTypes || (FilterTypes = {}));
// Mapping of FilterType to ServiceFilterType (e.g "eq" === "=")
// If for some reason the API changes its eq operator, only need to make the change here
export const ServiceFilterTypes = {
    [FilterTypes.EQ]: "="
    // TODO: Implement rest of FilterTypes if they exist
};
export const FILTER_ALL = "all";
export const FirestoreFilterTypes = {
    [FilterTypes.EQ]: "==",
    [FilterTypes.IN]: "in",
    [FilterTypes.GT]: ">=",
    [FilterTypes.INTERSECTION]: "in",
    [FilterTypes.BETWEEN]: "in",
    [FilterTypes.LT]: "<="
};
export var FilterValueTypes;
(function (FilterValueTypes) {
    FilterValueTypes["NUMBER"] = "number";
    FilterValueTypes["STRING"] = "string";
    FilterValueTypes["OBJECT"] = "object";
    FilterValueTypes["BOOLEAN"] = "boolean";
})(FilterValueTypes || (FilterValueTypes = {}));

import slugify from "slugify";

export const pluralize =
  (nonPluralForm: string, pluralForm = `${nonPluralForm}s`) =>
  (groupSize = 2) =>
    groupSize === 1 ? nonPluralForm : pluralForm;

export const generateUrlSafeSlug = (string: string = "") =>
  slugify(string, {
    lower: true,
    // eslint-disable-next-line no-useless-escape
    remove: /\/|\.|\,|\:|\(|\)|\#/g // Remove periods, commas, colons, parentheses, and slashes
  });

export const formatLongList = (array: string[], maxAmountToShow = 5) => {
  const [elementsToDisplay, restOfTheArray] = [
    array.slice(0, maxAmountToShow),
    array.slice(maxAmountToShow)
  ];

  return `${elementsToDisplay.join(", ")}${
    restOfTheArray.length ? ` +${restOfTheArray.length} more` : ""
  }`;
};

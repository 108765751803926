import { dateFromFirestoreDate } from "../../utils/dates";
/**
 * Tiles are a concept from the dashboard where each "card"
 * on that page are it's own tile that can be customized
 * based on organization. Usually tiles have a title (customName)
 * the ability to be enabled/disabled and in theory have the ability
 * to be moved.
 *
 * Movement isn't really used currently in the application and might
 * be something to revisit (or remove) in the near future.
 */
export var TileId;
(function (TileId) {
    TileId["CAMPAIGNS_TABLE"] = "campaigns_table";
    TileId["DAILY_BOOKED_IMPRESSIONS"] = "daily_booked_impressions";
    TileId["DAYPART_DISTRIBUTION"] = "daypart_distribution";
    TileId["DEVICE_DISTRIBUTION"] = "device_distribution";
    TileId["HEATMAP"] = "heat_map";
    TileId["PROVIDERS_TABLE"] = "providers_table";
    TileId["ZIPS_TABLE"] = "zips_table";
})(TileId || (TileId = {}));
export const DEFAULT_DASHBOARD_LAYOUT = {
    [TileId.CAMPAIGNS_TABLE]: {
        enabled: false,
        customName: "Campaigns"
    },
    [TileId.DAILY_BOOKED_IMPRESSIONS]: {
        enabled: true,
        customName: "Estimated Booked Impressions"
    },
    [TileId.DAYPART_DISTRIBUTION]: {
        enabled: false,
        customName: "Daypart Distribution"
    },
    [TileId.DEVICE_DISTRIBUTION]: {
        enabled: false,
        customName: "Device Distribution"
    },
    [TileId.HEATMAP]: {
        enabled: false,
        customName: "Heatmap"
    },
    [TileId.PROVIDERS_TABLE]: {
        enabled: false,
        customName: "Content Providers"
    },
    [TileId.ZIPS_TABLE]: {
        enabled: false,
        customName: "Media Market"
    }
};
export const serviceTileSettingsToTileSettings = (serviceTileSettings) => {
    return {
        enabled: !!serviceTileSettings?.enabled,
        customName: serviceTileSettings?.custom_name || ""
    };
};
export const serviceOrganizationSettingsToOrganizationSettings = (serviceOrganizationSettings) => {
    if (serviceOrganizationSettings?.settings?.dashboard) {
        const serviceDashboardSettings = serviceOrganizationSettings.settings.dashboard;
        const dateMin = serviceDashboardSettings["available-dates"] && serviceDashboardSettings["available-dates"].min
            ? dateFromFirestoreDate(serviceDashboardSettings["available-dates"].min)
            : undefined;
        const dateMax = serviceDashboardSettings["available-dates"] && serviceDashboardSettings["available-dates"].max
            ? dateFromFirestoreDate(serviceDashboardSettings["available-dates"].max)
            : undefined;
        /**
         * Format tile layout
         */
        const layout = serviceDashboardSettings?.layout
            ? Object.keys(serviceDashboardSettings.layout).reduce((mergedSettings, currId) => {
                return {
                    ...mergedSettings,
                    [currId]: {
                        ...mergedSettings[currId],
                        ...serviceTileSettingsToTileSettings(serviceDashboardSettings.layout[currId])
                    }
                };
            }, DEFAULT_DASHBOARD_LAYOUT)
            : DEFAULT_DASHBOARD_LAYOUT;
        return {
            settings: {
                dashboard: {
                    availableDates: {
                        min: dateMin,
                        max: dateMax
                    },
                    title: {
                        customName: ""
                    },
                    subtitle: {
                        customName: ""
                    },
                    layout
                }
            },
            suits: serviceOrganizationSettings?.suits || []
        };
    }
    return {
        settings: {},
        suits: serviceOrganizationSettings?.suits || []
    };
};

export class ValidationError extends Error {
    /**
     * @param validations: an array of validations.
     */
    constructor(validations) {
        super();
        this.validations = validations;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, ValidationError);
        }
        const errors = Array.from(new Set(validations.flatMap((validation) => Array.from(validation.values()))));
        this.name = "ValidationError";
        this.message = `Invalid data because of the following errors: ${errors.join(", ")}.`;
    }
}
export class InvalidAspectRatioError extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidAspectRatioError);
        }
        this.name = "InvalidAspectRatioError";
        this.message =
            "Invalid sample aspect ratio. The pixel aspect ratio does not match dimensions. Reformat creative and try again.";
    }
}

import { madSDK } from "lib/sdk";
import { SmithersNotificationRaw } from "types";
import { User } from "@madhive/mad-sdk";
import { FirestoreNotificationStatuses } from "./constants";

export const markAllFirestoreNotificationsForUserAsRead = async (
  user: User
) => {
  try {
    madSDK.madFire
      .queryDocuments(
        "notifications",
        { fieldPath: "recipientId", opStr: "==", value: user.email },
        { fieldPath: "read", opStr: "==", value: false }
      )
      .then(res => {
        if (!res.empty) {
          res.docs.forEach(doc => {
            madSDK.madFire
              .runTransaction(transaction =>
                transaction.get(doc.ref).then(snapshot => {
                  const data = snapshot.data();

                  if (!data) {
                    return;
                  }

                  transaction.update(doc.ref, {
                    read: true,
                    lastUpdatedDate: new Date()
                  });
                })
              )
              .catch(error => {
                console.error("Error updating notification : ", doc.ref.id);
                console.error(error);
              });
          });
        }
      });
  } catch (e) {
    console.error(e);
  }
};

export const archiveFirestoreNotification = async (id: string) => {
  try {
    const docRef = madSDK.madFire.getDocRef("notifications", id);

    return madSDK.madFire.runTransaction(transaction =>
      transaction.get(docRef).then(snapshot => {
        const data = snapshot.data();

        if (!data) {
          return;
        }

        transaction.update(docRef, {
          status: FirestoreNotificationStatuses.ARCHIVED
        });
      })
    );
  } catch (e) {
    console.error(e);
  }
};

export const createNewFirestoreNotification = (
  notification: SmithersNotificationRaw,
  user: User,
  now: Date
) => {
  if (typeof notification !== "object" || typeof user !== "object") {
    console.error(
      "Invalid notification format and/or missing user -- notification: ",
      notification,
      " user: ",
      user.email
    );
    return;
  }
  const {
    title,
    content,
    status,
    category,
    recipientId,
    actions = {},
    meta = {}
  } = notification;
  if (
    !title ||
    !content ||
    !status ||
    typeof category !== "string" ||
    !recipientId
  ) {
    console.error("Missing notification params: ", notification);
    return;
  }

  const formattedNotification = {
    authorId: user.email,
    recipientId,
    status: FirestoreNotificationStatuses[status]
      ? status
      : FirestoreNotificationStatuses.IN_QUEUE,
    title,
    actions,
    category,
    content,
    read: false,
    createdDate: now,
    lastUpdatedDate: now,
    meta
  };

  return madSDK.madFire
    .addDoc("notifications", {
      ...formattedNotification
    })
    .then(docRef => docRef.id)
    .catch(err => {
      console.error(err);
    });
};

export const updateExistingFirestoreNotification = (
  notificationId: string,
  notification: SmithersNotificationRaw,
  user: User
) => {
  if (
    typeof notification !== "object" ||
    !notificationId ||
    typeof user !== "object"
  ) {
    console.error(
      "Invalid notification format and/or missing user -- notification: ",
      notification,
      " user: ",
      user.email
    );
    return;
  }
  const {
    title,
    content,
    status,
    category,
    recipientId,
    actions = {},
    meta = {}
  } = notification;

  if (
    !title ||
    !content ||
    !status ||
    typeof category !== "string" ||
    !recipientId
  ) {
    console.error("Missing notification params: ", notification);
    return;
  }

  const formattedNotification = {
    authorId: user.email,
    recipientId,
    status: FirestoreNotificationStatuses[status]
      ? status
      : FirestoreNotificationStatuses.IN_QUEUE,
    category,
    title,
    content,
    actions,
    lastUpdatedDate: new Date(),
    meta
  };

  return madSDK.madFire
    .setDoc(
      "notifications",
      notificationId,
      { ...formattedNotification },
      { merge: true }
    )
    .then(() => notificationId)
    .catch(error => {
      console.error(error);
    });
};

/**
 * @param filter: an individual filter.
 * @param options: options to control how the filter is parsed.
 * @param options.array: should resulting string be coerced into an array (e.g., ["v1", "v2"]).
 * @param options.join: the symbol that multiple values should be joined with. Defaults to ",".
 * @return: a properly-assembled query param for the filter, in the form {key}={value}.
 */
export const toQueryParam = (filter, options) => {
    const values = Array.isArray(filter.value) ? filter.value : [filter.value];
    const transformed = [];
    for (let value of values) {
        if (value instanceof Date) {
            value = value.toISOString();
        }
        value = encodeURIComponent(value);
        if (options?.array) {
            value = `"${value}"`;
        }
        transformed.push(value);
    }
    const stringified = transformed.join(options?.join || ",");
    return options?.array ? `[${stringified}]` : stringified;
};

import React from "react";

import { FormGroup as BPFormGroup } from "@blueprintjs/core";
import styled from "@emotion/styled";

interface Props {
  className?: string;
  helperText?: string | boolean;
  intent?: string;
  label?: string | JSX.Element;
  labelFor?: string;

  /**
   * Whether the label should be visible or not. This is useful for
   * when you want to hide the label but keep the label space and
   * maintain alignment with other form groups.
   * @default true
   * */
  visibleLabel?: boolean;
  labelInfo?: string | JSX.Element;
  style?: Record<string, unknown>;
}

const StyledBPFormGroup = styled(BPFormGroup)`
  label {
    visibility: ${(props: Props) =>
      props.visibleLabel ? "visible" : "hidden"};
  }
`;

/**
 * A FormGroup is a lightweight wrapper around its children with props for the
 * label above and helper text below.
 */
export const FormGroup = ({
  className,
  label,
  labelFor,
  labelInfo,
  children,
  visibleLabel = true
}: React.PropsWithChildren<Props>) => {
  return (
    <StyledBPFormGroup
      visibleLabel={visibleLabel}
      label={label}
      labelFor={labelFor}
      labelInfo={labelInfo}
      className={className}
    >
      {children}
    </StyledBPFormGroup>
  );
};

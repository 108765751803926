import { FilterTypes, parsePagingQuery, toQueryParam } from "../../handlers/filter";
import { buildSort } from "./sorting";
/**
 * @param filters: array of SDK-style creative filters.
 * @return: a proper query string for the supplied filters.
 */
const wheresToQueryParams = (wheres) => {
    let params = "";
    const ids = [];
    if (wheres) {
        wheres.forEach((filter) => {
            let key;
            switch (filter.field) {
                case "lastUpdated": {
                    key = "lastModified";
                    break;
                }
                case "name": {
                    key = "name";
                    break;
                }
                case "advertiserId": {
                    key = "parent";
                    break;
                }
                case "state": {
                    key = "statuses";
                    break;
                }
                case "mediaTypes": {
                    key = "media_types";
                    break;
                }
                case "id": {
                    key = "ids";
                    ids.push(filter.value);
                    break;
                }
                case "category": {
                    key = "advertiser_categories";
                    break;
                }
                default: {
                    key = filter.field;
                    break;
                }
            }
            if (key !== "ids") {
                params = `${params}&${key}=${toQueryParam(filter)}`;
            }
        });
    }
    if (ids.length > 0) {
        params = `${params}&ids=${toQueryParam({ field: "ids", type: FilterTypes.EQ, value: ids })}`;
    }
    return params;
};
/**
 * Method takes a filter and pull the fields from it to build param
 * @param filters Filters object to pull Fields from
 * @returns either a fields param string or empty string.
 */
const fieldsToQueryParams = (fields) => {
    let params = "";
    if (fields) {
        let mod = "";
        for (const field of fields) {
            params = `${params}${mod}${field}`;
            mod = ",";
        }
    }
    return params.length > 0 ? `&fields=${params}` : "";
};
/**
 * @param sort: information on the field and direction of sorting, if any.
 * @return: a properly-formatted query string, based on the provided sorting info. Empty if there is no sort.
 */
const sortToQueryParam = (sort) => {
    let params = "";
    if (sort && sort.field && sort.direction) {
        params = `${params}&sortBy=${buildSort(sort)}`;
    }
    return params;
};
/**
 * @param filters: array of SDK-style isntruction filters.
 * @param sort: optional. Information on the field and direction of sorting, if any.
 * @return: a proper query string for the supplied filters.
 */
export const toQueryParams = (filters, sort) => {
    const params = `${fieldsToQueryParams(filters.fields)}${wheresToQueryParams(filters.where)}${parsePagingQuery(filters.paging)}${sortToQueryParam(sort)}`;
    return params.length ? `?${params}` : "";
};

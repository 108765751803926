import { AttributionDashboardFailedFetchError } from "../../errors";
import { Observable } from "rxjs";
import { ObservableHandler } from "../handlers";
import { groupFiltersByDimension } from "./query";
export class Aggregator extends ObservableHandler {
    constructor(sdk, attribtutionDashboard) {
        super(sdk, "attributionDashboardAggregator");
        this.attributionDashboard = attribtutionDashboard;
    }
    findItems(filters, 
    // TO-DO: ask backend for asc/desc instead of +/- for sort
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    sort) {
        const filtersGroupedByDimension = groupFiltersByDimension(filters);
        return new Observable((subscriber) => {
            let aggregatedReports = {};
            filtersGroupedByDimension.forEach((groupedFilters) => {
                const dimension = (groupedFilters.where?.find((filter) => filter.field === "dimensions")?.value).join("-");
                this.attributionDashboard
                    .find_once(groupedFilters)
                    .then((report) => {
                    if (!report) {
                        throw new AttributionDashboardFailedFetchError(new Error("No report found"));
                    }
                    aggregatedReports = {
                        ...aggregatedReports,
                        [dimension]: report
                    };
                    subscriber.next([aggregatedReports]);
                })
                    .catch(() => {
                    aggregatedReports = {
                        ...aggregatedReports,
                        [dimension]: {
                            data: [],
                            error: true
                        }
                    };
                    subscriber.next([aggregatedReports]);
                });
            });
        });
    }
    saveItem() {
        throw new Error("Method not implemented.");
    }
    deleteItem() {
        throw new Error("Method not implemented.");
    }
    make() {
        throw new Error("Method not implemented.");
    }
}

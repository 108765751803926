import { SyntheticEvent, FC } from "react";
import { css } from "@emotion/react";
import {
  DrawerProps as BlueprintDrawerProps,
  Drawer as BlueprintDrawer,
  Position
} from "@blueprintjs/core";

interface DrawerProps extends BlueprintDrawerProps {
  isOpen: boolean;
  onClose?: (event: SyntheticEvent<HTMLElement>) => void;
  size?: string; // default: 50%
  position?: Position; // default: Position.RIGHT
}

const style = {
  menuDrawer: css`
    overflow-y: auto;
  `
};

const Drawer: FC<DrawerProps> = props => {
  const { isOpen, onClose, position, size } = props;

  return (
    <BlueprintDrawer
      {...props}
      isOpen={isOpen}
      onClose={onClose}
      position={position}
      size={size}
      css={style.menuDrawer}
    />
  );
};

export default Drawer;

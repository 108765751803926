import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Image: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-image"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.00299 3.75C3.3122 3.75 2.75299 4.30921 2.75299 5V19C2.75299 19.6908 3.3122 20.25 4.00299 20.25H19.997C20.687 20.25 21.247 19.6905 21.247 19V5C21.247 4.30921 20.6878 3.75 19.997 3.75H4.00299ZM1.25299 5C1.25299 3.48079 2.48378 2.25 4.00299 2.25H19.997C21.5162 2.25 22.747 3.48079 22.747 5V19C22.747 20.5195 21.515 21.75 19.997 21.75H4.00299C2.48378 21.75 1.25299 20.5192 1.25299 19V5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.88389 8.11612C8.39573 7.62796 7.60427 7.62796 7.11612 8.11612C6.62796 8.60427 6.62796 9.39573 7.11612 9.88389C7.60427 10.372 8.39573 10.372 8.88389 9.88389C9.37204 9.39573 9.37204 8.60427 8.88389 8.11612ZM9.94369 7.0546C8.86968 5.98151 7.12911 5.9818 6.05546 7.05546L6.05546 7.05546C4.98151 8.1294 4.98151 9.8706 6.05546 10.9445C7.1294 12.0185 8.8706 12.0185 9.94455 10.9445C11.0185 9.8706 11.0185 8.1294 9.94455 7.05546C9.94426 7.05517 9.94397 7.05488 9.94369 7.0546Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.7895 12.3123C16.3932 11.5581 17.4951 11.4355 18.2497 12.0395L22.4685 15.4143C22.7919 15.6731 22.8444 16.1451 22.5857 16.4685C22.3269 16.792 21.8549 16.8444 21.5315 16.5857L17.3125 13.2107C17.2051 13.1247 17.047 13.1419 16.9606 13.2495M16.9606 13.2495L13.6246 17.4195C13.0207 18.174 11.92 18.296 11.165 17.6931L9.29647 16.1977C9.29643 16.1976 9.2965 16.1977 9.29647 16.1977C9.19016 16.1127 9.03564 16.1281 8.94827 16.233L4.57618 21.4801C4.31103 21.7983 3.8381 21.8414 3.51988 21.5762C3.20166 21.311 3.15864 20.8381 3.4238 20.5199L7.79571 15.273C8.40631 14.5399 9.48982 14.4314 10.2335 15.0264L12.101 16.521C12.1011 16.521 12.1009 16.5209 12.101 16.521C12.21 16.6078 12.3675 16.5898 12.4535 16.4823L15.7895 12.3123"
      />
    </svg>
  );
};

export default Image;

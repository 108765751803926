export class InvalidStation extends Error {
    constructor(errors) {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, InvalidStation);
        }
        this.name = "InvalidStation";
        this.message = `Provided station is invalid: ${errors.join(", ")}`;
    }
}
export class StationsFetchFailed extends Error {
    constructor(error, fallback = "Failed to retrieve stations. Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, StationsFetchFailed);
        }
        this.name = "StationsFetchFailed";
        this.message = error?.response?.data?.message || fallback;
    }
}
export class StationSaveFailed extends Error {
    constructor(error, fallback = "Failed to save station. Please try again at a later time.") {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, StationSaveFailed);
        }
        this.name = "StationSaveFailed";
        this.message = error?.response?.data?.message || fallback;
    }
}

/**
 * The User module manages the identification of the currently-tracked user.
 * @template {T}: the structure of user traits. Defaults to `Record<string, unknown>`.
 */
export class User {
    /**
     * @param signal: a function that will signal a given action has taken place, and allows data associated with that action to be passed along.
     */
    constructor(signal) {
        Object.defineProperty(this, "signal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: signal
        });
        Object.defineProperty(this, "user", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        this.user = undefined;
    }
    /**
     * @param id: the unique identifier for the user.
     * @param traits: other information that should be tied to the user.
     * @return: a promise which resolves once all listeners have been signalled, and completed their response.
     */
    set(id, traits) {
        this.user = {
            id,
            traits
        };
        return this.signal("user.set", this.user);
    }
    /** @return: the currently-identifed user. `undefined`, if none is identified. */
    get() {
        return this.user;
    }
    /**
     * Clears the currently-set user, returning to a pre-identification state.
     * @return: a promise which resolves once all listeners have been signalled, and completed their response.
     */
    reset() {
        this.user = undefined;
        return this.signal("user.reset");
    }
}

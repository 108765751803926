import {
  ReactNode,
  Children,
  FC,
  isValidElement,
  PropsWithChildren
} from "react";
import styled from "@emotion/styled";
import { isElementOfType } from "../utils/isElementOfType";
import { Tab } from "./Tab";
import { TabsContext } from "./TabsContext";
import { TabsProps } from "./types";

const StyledTabs = styled("ul")`
  display: flex;
  flex-direction: row;
  gap: var(--spacing-24);
  padding: 0px;
  margin: 0px;
`;

const getTabChildren = (props: TabsProps & { children?: ReactNode }) =>
  Children.toArray(props.children).filter(child => isElementOfType(child, Tab));

const Tabs: FC<PropsWithChildren<TabsProps>> = props => {
  const { id, selectedTabId, children, onChange } = props;

  // The array of ids from the children 'Tab' component
  const tabIds = Children.toArray(children)
    .map(child => {
      if (isValidElement(child)) {
        return child.props.id;
      }

      return null;
    })
    .filter(tabId => tabId !== undefined);

  // The number of children 'Tab' component
  const tabsCount = tabIds.length;

  /**
   *
   * @param {string} firstTabBasedOnKeyDirection the tabId that is "first" based on the direction i.e. for ArrowLeft it's the "last" tab in tabIds for ArrowRight it's the "first"
   * @param {string} nextTab the potential "next" tab
   * @param {string} lastTabBasedOnKeyDirection the tabId that is "last" based on the direction i.e. for ArrowLeft it's the "first" tab in tabIds for ArrowRight it's the "last"
   */
  const handleNextTab = (
    firstTabBasedOnKeyDirection: string,
    nextTab: string,
    lastTabBasedOnKeyDirection: string
  ) => {
    // If the next tab exceeds any bound we go select the "first" or the "last" accordingly
    const tabIdToSelect =
      selectedTabId === lastTabBasedOnKeyDirection
        ? firstTabBasedOnKeyDirection
        : nextTab;
    // Trigger a onChange so the state controlling Tabs gets updated accordingly
    onChange(tabIdToSelect);
  };

  /**
   * Handle "ArrowRight" and "ArrowLeft" keypress
   * @param {KeyboardEvent<HTMLUListElement>} event
   */
  const handleKeyPress = (event: { key: string }) => {
    const currentIndex = tabIds.findIndex(tabId => tabId === selectedTabId);

    const first = tabIds[0];
    const last = tabIds[tabsCount - 1];

    /**
     * If the ArrowLeft key is pressed, we select the *previous* tab
     * OR the last one in the list if currentIndex is equal to 0
     */
    if (event.key === "ArrowLeft") {
      const next = currentIndex - 1;
      handleNextTab(last, tabIds[next], first);
    }
    /**
     * If the ArrowRight key is pressed, we select the *next* tab
     * OR the first one in the list if currentIndex is equal to tabsCount - 1
     */
    if (event.key === "ArrowRight") {
      const next = currentIndex + 1;
      handleNextTab(first, tabIds[next], last);
    }
  };

  return (
    /**
     * Note @MaximeHeckel
     *
     * MAYBE TODO once we tackle the Flex component:
     *
     * This should be based of our future Flex/Row component which
     * should take a "as" prop so we can pass any html element
     *
     * const StyledBased = styled(Flex)`...`
     *
     * <StyledTabs as="ul" .../>
     */
    <StyledTabs
      id={id}
      // If the `tablist` element is vertically oriented, it has the property
      // `aria-orientation` set to `"vertical"`. The default value of
      // `aria-orientation` for a tablist element is `"horizontal"`.
      // https://www.w3.org/TR/wai-aria-practices-1.2/#tabpanel
      // Our current design only contains horizontal tabs
      aria-orientation="horizontal"
      role="tablist"
      aria-label={props["aria-label"] || "List of Tabs"}
      data-testid={props["data-testid"]}
      onKeyDown={handleKeyPress}
    >
      <TabsContext.Provider value={{ onChange, selectedTabId }}>
        {getTabChildren(props)}
      </TabsContext.Provider>
    </StyledTabs>
  );
};

Tabs.displayName = "Tabs";

export { Tabs };

import { SyntheticEvent } from "react";
import * as FullStory from "@fullstory/browser";
import { NODE_ENV } from "lib/viteEnvConfig";
import { madSDK } from "../../sdk";

type LogEventParams = {
  /**
   *  If set to true, and if FullStory (FS) is in scope, enables event recording
   *  in FullStory.
   */
  recordSession?: boolean;

  /**
   * Pass an optional Synthetic Event here that will be track in FullStory.
   */
  event?: SyntheticEvent;
  /**
   *  Custom key value pair of parameters we want to show in google analytics for
   *  a given event.
   */
  params?: {
    [key: string]: string;
  };
};

/**
 * This function logs an event in google analytics, and optionaly in
 * FullStory if options.recordSession is set to true.
 * @param  {string} name
 * @param  {LogEventParams} options
 */
const logEvent = (
  name: string,
  options: LogEventParams = {
    event: undefined,
    recordSession: false,
    params: {}
  }
) => {
  /**
   * If the UI NODE_ENV is test (i.e. CI) skip logging events to google analytics
   */
  if (NODE_ENV === "test") {
    return;
  }

  if (options.recordSession) {
    FullStory.event(name, options.event || {});
  }
  madSDK.madFire.logEvent(name, options.params);
};

export { logEvent };

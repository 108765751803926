import { isLiveStatus } from "../../../types";
export const requiredString = (value) => {
    return !value?.length ? "This field is required" : undefined;
};
/**
 * Determine if a line item should be validated based on provided key & lineItem's status.
 */
export const shouldValidateOnLineItem = (lineItem, key) => {
    switch (key) {
        case "name":
        case "endDate":
        case "startDate":
        case "dayparts":
        case "deviceCaps":
        case "attachedCreatives.trackingPixels": // BE blocks invalid URLS
        case "attachedCreatives.clickThroughUrl": // BE blocks invalid URLS
        case "attachedCreatives.startDatesInRange":
        case "attachedCreatives.endDatesInRange":
        case "attachedCreatives.weight": // BE blocks if weight is 0
            return true;
        default:
            return isLiveStatus(lineItem?.status);
    }
};

import { FC, PropsWithChildren } from "react";
import styled from "@emotion/styled";

import { TabPanelProps } from "./types";

const StyledTabPanel = styled("section")`
  /**
   * Note @MaximeHeckel: Adding the following CSS property as !important
   * as it can potentially be overriden via className which will show
   * the content of the TabPanel even if hidden
   */

  &[hidden] {
    display: none !important;
  }
`;

/**
 * Represent the "panel" associated to a given Tab
 * @param {FC<TabPanelProps>} props
 */
const TabPanel: FC<PropsWithChildren<TabPanelProps>> = props => {
  const { id, tabId, selectedTabId, children, className, onlyRenderIfActive } =
    props;

  const maybeRenderChildren = () => {
    if (onlyRenderIfActive) {
      return selectedTabId === tabId ? children : null;
    }

    return children;
  };

  return (
    <StyledTabPanel
      role="tabpanel"
      /**
       * The id of the TabPanel.
       * This id should be the same as the one used in Tab for the aria-controls attribute
       */
      id={id}
      /**
       * Each TabPanel should be focusable
       * This is only in case the children of the TabPanel do not have any focusable element.
       * Without it assistive technologies may simply skip it
       */
      tabIndex={0}
      /**
       * Points to the Id of the Tab controlling this TabPanel which in return
       * gives it an accessible name
       */
      aria-labelledby={tabId}
      /**
       * Since only one TabPanel can be visible at a time we have to hide
       * the ones which Tab controller are not "selected"
       *
       * Read more about the hidden attribute here: https://developer.mozilla.org/en-US/docs/Web/HTML/Global_attributes/hidden
       */
      hidden={selectedTabId !== tabId}
      /**
       * We may allow delevopers to add custom styles to the Panel itself
       */
      className={className}
    >
      {maybeRenderChildren()}
    </StyledTabPanel>
  );
};

TabPanel.displayName = "TabPanel";

export { TabPanel };

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const AudienceDataProvisioning: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-audience-data-provisioning"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.49672 3.74625C4.08216 3.74625 3.74609 4.08231 3.74609 4.49687V7.49812C3.74609 7.91268 4.08216 8.24875 4.49672 8.24875H7.49797C7.91253 8.24875 8.24859 7.91268 8.24859 7.49812V4.49687C8.24859 4.08231 7.91253 3.74625 7.49797 3.74625H4.49672ZM2.24609 4.49687C2.24609 3.25389 3.25373 2.24625 4.49672 2.24625H7.49797C8.74095 2.24625 9.74859 3.25389 9.74859 4.49687V7.49812C9.74859 8.74111 8.74095 9.74875 7.49797 9.74875H4.49672C3.25373 9.74875 2.24609 8.74111 2.24609 7.49812V4.49687Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5006 3.74625C13.0861 3.74625 12.75 4.08231 12.75 4.49687V7.49812C12.75 7.91268 13.0861 8.24875 13.5006 8.24875H19.5031C19.9177 8.24875 20.2537 7.91268 20.2537 7.49812V4.49687C20.2537 4.08231 19.9177 3.74625 19.5031 3.74625H13.5006ZM11.25 4.49687C11.25 3.25389 12.2576 2.24625 13.5006 2.24625H19.5031C20.7461 2.24625 21.7537 3.25389 21.7537 4.49687V7.49812C21.7537 8.74111 20.7461 9.74875 19.5031 9.74875H13.5006C12.2576 9.74875 11.25 8.74111 11.25 7.49812V4.49687Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.1065 12.976C12.3962 13.2719 12.3912 13.7468 12.0952 14.0366L7.7714 18.2693C7.47748 18.557 7.00667 18.5544 6.71604 18.2633L5.29645 16.8417C5.00376 16.5486 5.00409 16.0738 5.29719 15.7811C5.59029 15.4884 6.06516 15.4887 6.35785 15.7818L7.25273 16.678L11.0459 12.9647C11.3419 12.6749 11.8167 12.68 12.1065 12.976Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.24609 14.9202C2.24609 13.1302 3.69716 11.6792 5.48713 11.6792H8.35533C8.76954 11.6792 9.10533 12.015 9.10533 12.4292C9.10533 12.8434 8.76954 13.1792 8.35533 13.1792H5.48713C4.52558 13.1792 3.74609 13.9587 3.74609 14.9202V18.5127C3.74609 19.4743 4.52558 20.2537 5.48713 20.2537H9.07963C10.0412 20.2537 10.8207 19.4743 10.8207 18.5127V17.7884C10.8207 17.3742 11.1565 17.0384 11.5707 17.0384C11.9849 17.0384 12.3207 17.3742 12.3207 17.7884V18.5127C12.3207 20.3027 10.8696 21.7537 9.07963 21.7537H5.48713C3.69716 21.7537 2.24609 20.3027 2.24609 18.5127V14.9202Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.5016 12.75C16.087 12.75 15.751 13.0861 15.751 13.5006V16.5019C15.751 16.9164 16.087 17.2525 16.5016 17.2525H19.5029C19.9174 17.2525 20.2535 16.9164 20.2535 16.5019V13.5006C20.2535 13.0861 19.9174 12.75 19.5029 12.75H16.5016ZM14.251 13.5006C14.251 12.2576 15.2586 11.25 16.5016 11.25H19.5029C20.7458 11.25 21.7535 12.2576 21.7535 13.5006V16.5019C21.7535 17.7449 20.7458 18.7525 19.5029 18.7525H16.5016C15.2586 18.7525 14.251 17.7449 14.251 16.5019V13.5006Z"
      />
    </svg>
  );
};

export default AudienceDataProvisioning;

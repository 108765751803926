import {
  AssetSpecificationFailuresLegacy,
  SpecificationLegacy,
  CreativeAssetMetadataLegacy,
  MimeTypeLegacy,
  TextTypeLegacy,
  AssetDimensions,
  Specification
} from "@madhive/mad-sdk";
import { AssetDurationThresholds } from "../constants/creatives";
import { getAspectRatio } from "./number";

export const isValueValid = (value: number | undefined) =>
  value !== undefined && value !== 0;

export const determineHtmlTag = (
  assetMetadata: CreativeAssetMetadataLegacy | undefined
) =>
  !!assetMetadata &&
  (assetMetadata.mime === MimeTypeLegacy.HTML ||
    assetMetadata.textFormat === TextTypeLegacy.HTML ||
    assetMetadata.textFormat === TextTypeLegacy.PLAIN);

const validateAssetAudioEncodingFormat = (
  assetAudioEncodingFormat: string,
  specificationAcceptableAudioEncodingFormats: string[] | undefined | null
): boolean => {
  if (
    !specificationAcceptableAudioEncodingFormats ||
    !specificationAcceptableAudioEncodingFormats.length ||
    !assetAudioEncodingFormat
  ) {
    /** If the spec sheet does not have any listed, assume any audio encoding format is acceptable. */
    return true;
  }

  return specificationAcceptableAudioEncodingFormats.some(
    format => format === assetAudioEncodingFormat
  );
};

const validateAssetDurationInSeconds = (
  assetDurationInSeconds: number,
  specificationAcceptableDurationsInSeconds: number[] | undefined | null
): boolean => {
  if (
    !specificationAcceptableDurationsInSeconds ||
    !specificationAcceptableDurationsInSeconds.length ||
    !isValueValid(assetDurationInSeconds)
  ) {
    /**
     * If the spec sheet does not have any listed, assume any duration is acceptable.
     */

    return true;
  }

  return specificationAcceptableDurationsInSeconds.some(
    durationInSeconds =>
      /**
       * We need to ensure precision to 1/100th of a second for a creative asset.
       */
      durationInSeconds - AssetDurationThresholds.LOWER <=
        assetDurationInSeconds &&
      durationInSeconds + AssetDurationThresholds.UPPER >=
        assetDurationInSeconds
  );
};

const validateAssetDimensions = (
  assetDimensions: AssetDimensions,
  specificationAcceptableDimensions: AssetDimensions[] | null | undefined
): boolean => {
  if (
    !specificationAcceptableDimensions ||
    !specificationAcceptableDimensions.length ||
    !isValueValid(assetDimensions.height) ||
    !isValueValid(assetDimensions.width)
  ) {
    /** If the spec sheet does not have any listed, assume any dimensions are acceptable. */

    return true;
  }

  return specificationAcceptableDimensions.some(
    acceptableDimensions =>
      assetDimensions.height === acceptableDimensions.height &&
      assetDimensions.width === acceptableDimensions.width
  );
};

const validateAssetVideoEncodingFormat = (
  assetAudioEncodingFormat: string,
  specificationAcceptableVideoEncodingFormats: string[] | undefined | null
): boolean => {
  if (
    !specificationAcceptableVideoEncodingFormats ||
    !specificationAcceptableVideoEncodingFormats.length ||
    !assetAudioEncodingFormat
  ) {
    /** If the spec sheet does not have any listed, assume any audio encoding format is acceptable. */
    return true;
  }

  return specificationAcceptableVideoEncodingFormats.some(
    format => format === assetAudioEncodingFormat
  );
};

const validateAssetMaximumVideoBitrate = (
  assetVideoBitrate: number,
  specificationMaxVideoBitrate: number | undefined | null
) => {
  if (
    specificationMaxVideoBitrate === null ||
    specificationMaxVideoBitrate === undefined ||
    !isValueValid(assetVideoBitrate)
  ) {
    /** If the spec sheet does not have any listed, assume any video bitrate is acceptable. */
    return true;
  }

  return assetVideoBitrate <= specificationMaxVideoBitrate;
};

const validateAssetMinimumVideoBitrate = (
  assetVideoBitrate: number,
  specificationMinVideoBitrate: number | undefined | null
): boolean => {
  if (
    specificationMinVideoBitrate === null ||
    specificationMinVideoBitrate === undefined ||
    !isValueValid(assetVideoBitrate)
  ) {
    /** If the spec sheet does not have any listed, assume any video bitrate is acceptable. */
    return true;
  }

  return assetVideoBitrate >= specificationMinVideoBitrate;
};

const validateAssetMinimumAudioBitrate = (
  assetVideoBitrate: number,
  specificationMinVideoBitrate: number | undefined | null
): boolean => {
  if (
    specificationMinVideoBitrate === null ||
    specificationMinVideoBitrate === undefined ||
    !isValueValid(assetVideoBitrate)
  ) {
    /** If the spec sheet does not have any listed, assume any video bitrate is acceptable. */
    return true;
  }

  return assetVideoBitrate >= specificationMinVideoBitrate;
};
const validateAspectRatio = (pixelWidth: number, pixelHeight: number) => {
  const { aspectRatioWidth, aspectRatioHeight } = getAspectRatio(
    pixelWidth,
    pixelHeight
  );

  if (pixelWidth === 0 || pixelHeight === 0) {
    return true;
  }

  return aspectRatioWidth === 16 && aspectRatioHeight === 9;
};

export enum CreativeEncoderSpecifications {
  ACCEPTABLE_DURATION_IN_SECONDS = "acceptableExactDurationsInSeconds",
  ACCEPTABLE_RATIO = "acceptableAspectRatio",
  ACCEPTABLE_DIMENSIONS = "acceptableDimensions",
  ACCEPTABLE_VIDEO_ENCODING_FORMATS = "acceptableVideoEncodingFormats",
  ACCEPTABLE_AUDIO_ENCODING_FORMATS = "acceptableAudioEncodingFormats",
  MAXIMUM_VIDEO_BITRATE_PER_SECOND = "maximumVideoBitrateInBitsPerSecond",
  MINIMUM_AUDIO_BITRATE_PER_SECONS = "minimumAudioBitrateInBitsPerSecond",
  MINIMUM_VIDEO_BITRATE_PER_SECONS = "minimumVideoBitrateInBitsPerSecond"
}

export const generateCreativeSpecificationFailures = (
  specifications: Partial<SpecificationLegacy & Specification>,
  assetMetadata: CreativeAssetMetadataLegacy
): AssetSpecificationFailuresLegacy => {
  const width = assetMetadata.width || 0;
  const height = assetMetadata.height || 0;
  const { isDisplayType } = assetMetadata;
  const isHTMLTag = determineHtmlTag(assetMetadata);

  const acceptableAspectRatio =
    isDisplayType || isHTMLTag || validateAspectRatio(width, height);

  const acceptableDimensions = validateAssetDimensions(
    {
      height,
      width
    },
    (isDisplayType &&
      (specifications.acceptableImageDimensions ||
        specifications.image?.dimensions)) ||
      (isHTMLTag &&
        (specifications.acceptableTextDimensions ||
          specifications.text?.dimensions)) ||
      specifications.acceptableDimensions ||
      specifications.video?.dimensions
  );

  const acceptableImageDimensions = isDisplayType
    ? validateAssetDimensions(
        {
          height,
          width
        },
        specifications.acceptableImageDimensions ||
          specifications.image?.dimensions
      )
    : true;

  const acceptableTextDimensions = isHTMLTag
    ? validateAssetDimensions(
        {
          height,
          width
        },
        specifications.acceptableTextDimensions ||
          specifications.text?.dimensions
      )
    : true;

  return {
    acceptableAudioEncodingFormats: validateAssetAudioEncodingFormat(
      assetMetadata.audioEncodingFormat
        ? assetMetadata.audioEncodingFormat
        : "",
      specifications.acceptableAudioEncodingFormats ||
        specifications.audio?.codec
    ),
    acceptableExactDurationsInSeconds: validateAssetDurationInSeconds(
      assetMetadata.durationInSeconds ? assetMetadata.durationInSeconds : 0,
      specifications.acceptableExactDurationsInSeconds ||
        specifications.duration
    ),
    acceptableDimensions,
    acceptableVideoEncodingFormats: validateAssetVideoEncodingFormat(
      assetMetadata.videoEncodingFormat || "",
      specifications.acceptableVideoEncodingFormats ||
        specifications.video?.codec
    ),
    maximumVideoBitrateInBitsPerSecond: validateAssetMaximumVideoBitrate(
      assetMetadata.videoBitrateInBitsPerSecond || 0,
      specifications.maximumVideoBitrateInBitsPerSecond ||
        specifications.video?.bitrate.max
    ),
    minimumVideoBitrateInBitsPerSecond: validateAssetMinimumVideoBitrate(
      assetMetadata.videoBitrateInBitsPerSecond || 0,
      specifications.minimumVideoBitrateInBitsPerSecond ||
        specifications.video?.bitrate.min
    ),
    minimumAudioBitrateInBitsPerSecond: validateAssetMinimumAudioBitrate(
      assetMetadata.audioBitrateInBitsPerSecond || 0,
      specifications.minimumAudioBitrateInBitsPerSecond ||
        specifications.audio?.bitrate.min
    ),
    acceptableAspectRatio,
    acceptableImageDimensions,
    acceptableTextDimensions
  };
};

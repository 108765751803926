import * as FullStory from "@fullstory/browser";

import { LoginEvents } from "lib/constants/events";
import { FullStoryCustomEvent } from "lib/constants/fullStory";
import { Routes } from "lib/constants/routes";
import { madSDK } from "lib/sdk";
import { logEvent } from "lib/utils/analytics";
import { clearAccessTokenCookie } from "lib/utils/cookie";
import { isLocalhost } from "lib/utils/env";
import { AppThunk } from "types";
import { User } from "@madhive/mad-sdk";

import { NavigateFunction } from "react-router-dom";
import {
  AuthActionTypes,
  LOGIN_FIREBASE_VIA_TOKEN_PENDING,
  LOGIN_USER_FAILURE,
  LOGIN_USER_SUCCESS,
  LOGOUT_USER,
  MASK_OFF_USER,
  MASQUERADE_USER
} from "./types";

const setLoginFirebaseViaTokenPending = () => ({
  type: LOGIN_FIREBASE_VIA_TOKEN_PENDING
});

export const setLoginSuccess = (user: User) => ({
  type: LOGIN_USER_SUCCESS,
  payload: user,
  meta: { timestamp: Date.now() }
});

const setLoginFailure = (serverError?: string) => ({
  type: LOGIN_USER_FAILURE,
  meta: {
    error: {
      message:
        serverError ||
        "Failed to initialize user after successful authentication."
    }
  }
});

export const loginFirebaseViaAccessToken =
  (accessToken: string): AppThunk<Promise<void>> =>
  async dispatch => {
    dispatch(setLoginFirebaseViaTokenPending());
    FullStory.event(FullStoryCustomEvent.SSO_ATTEMPT, {});
    logEvent(LoginEvents.RECORD_USER_SYSTEM_TIME, {
      params: { userTime: new Date().toUTCString() }
    });
    try {
      await madSDK.authentication.authFirebaseWithToken(accessToken);
      console.info("signed in successfully ...");
      FullStory.event(FullStoryCustomEvent.SSO_SUCCESS, {});
      return;
    } catch (error) {
      console.error("sign in error ... ", error);
      dispatch(setLoginFailure(error));
      FullStory.event(FullStoryCustomEvent.SSO_FAILURE, {});
      throw error;
    }
  };

export const logoutUser = (navigate: NavigateFunction): AuthActionTypes => {
  madSDK.authentication.signOut();
  clearAccessTokenCookie();

  if (isLocalhost) {
    navigate(Routes.LOGIN_LOCAL, { replace: true });
  } else {
    navigate(Routes.LOGIN, { replace: true });
  }

  /* LOGOUT_USER is handled in the rootReducer */
  return {
    type: LOGOUT_USER
  };
};

export const masquerade = (user: User): AuthActionTypes => ({
  type: MASQUERADE_USER,
  payload: user
});

export const maskOff = (): AuthActionTypes => ({
  type: MASK_OFF_USER
});

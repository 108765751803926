import { IToastProps } from "@blueprintjs/core";
import { Agency, AgencyBase } from "@madhive/mad-sdk";

export enum Pending {
  GET = "get",
  UPDATE = "update",
  DELETE = "delete"
}

export const GET_ALL_AGENCIES_PENDING = "GET_ALL_AGENCIES_PENDING";
export const GET_ALL_AGENCIES_SUCCESS = "GET_ALL_AGENCIES_SUCCESS";
export const GET_ALL_AGENCIES_FAILURE = "GET_ALL_AGENCIES_FAILURE";

export const GET_AGENCY_PENDING = "GET_AGENCY_PENDING";
export const GET_AGENCY_SUCCESS = "GET_AGENCY_SUCCESS";
export const GET_AGENCY_FAILURE = "GET_AGENCY_FAILURE";

export const CREATE_AGENCY_PENDING = "CREATE_AGENCY_PENDING";
export const CREATE_AGENCY_SUCCESS = "CREATE_AGENCY_SUCCESS";
export const CREATE_AGENCY_FAILURE = "CREATE_AGENCY_FAILURE";

export const UPDATE_AGENCY_PENDING = "UPDATE_AGENCY_PENDING";
export const UPDATE_AGENCY_SUCCESS = "UPDATE_AGENCY_SUCCESS";
export const UPDATE_AGENCY_FAILURE = "UPDATE_AGENCY_FAILURE";

export const DELETE_AGENCY_PENDING = "DELETE_AGENCY_PENDING";
export const DELETE_AGENCY_SUCCESS = "DELETE_AGENCY_SUCCESS";
export const DELETE_AGENCY_FAILURE = "DELETE_AGENCY_FAILURE";

export const ABORT_GET_ALL_AGENCIES_REQUEST = "ABORT_GET_ALL_AGENCIES_REQUEST";
export const ABORT_AGENCY_REQUEST = "ABORT_AGENCY_REQUEST";

export interface AgenciesState {
  readonly byId: Record<string, Agency>;
  readonly isAllLoading: boolean;
  readonly pendingById: Record<string, Pending>;
  readonly creatingByRequestId: Record<string, AgencyBase>;
  readonly receivedAllAt: null | number;
}

interface GetAllAgenciesPendingAction {
  type: typeof GET_ALL_AGENCIES_PENDING;
}

interface GetAllAgenciesSuccessAction {
  type: typeof GET_ALL_AGENCIES_SUCCESS;
  payload: Agency[];
  meta: {
    timestamp: number;
  };
}

interface GetAllAgenciesFailureAction {
  type: typeof GET_ALL_AGENCIES_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface GetAgencyPendingAction {
  type: typeof GET_AGENCY_PENDING;
  meta: {
    id: string;
  };
}

interface GetAgencySuccessAction {
  type: typeof GET_AGENCY_SUCCESS;
  payload: Agency;
}

interface GetAgencyFailureAction {
  type: typeof GET_AGENCY_FAILURE;
  meta: {
    id?: string;
    error: {
      message: string;
    } & IToastProps;
  };
}

interface CreateAgencyPendingAction {
  type: typeof CREATE_AGENCY_PENDING;
  meta: {
    requestId: string;
    agency: AgencyBase;
  };
}

interface CreateAgencySuccessAction {
  type: typeof CREATE_AGENCY_SUCCESS;
  payload: Agency;
  meta: {
    requestId: string;
    success: {
      message: string;
    } & IToastProps;
  };
}

interface CreateAgencyFailureAction {
  type: typeof CREATE_AGENCY_FAILURE;
  meta: {
    requestId: string;
    error: {
      message: string;
    } & IToastProps;
  };
}

interface UpdateAgencyPendingAction {
  type: typeof UPDATE_AGENCY_PENDING;
  meta: {
    id: string;
  };
}

interface UpdateAgencySuccessAction {
  type: typeof UPDATE_AGENCY_SUCCESS;
  payload: Agency;
  meta: {
    success: {
      message: string;
    } & IToastProps;
  };
}

interface UpdateAgencyFailureAction {
  type: typeof UPDATE_AGENCY_FAILURE;
  meta: {
    id?: string;
    error: {
      message: string;
    } & IToastProps;
  };
}

interface DeleteAgencyPendingAction {
  type: typeof DELETE_AGENCY_PENDING;
  meta: {
    id: string;
  };
}

interface DeleteAgencySuccessAction {
  type: typeof DELETE_AGENCY_SUCCESS;
  meta: {
    id: string;
    success: {
      message: string;
    } & IToastProps;
  };
}

interface DeleteAgencyFailureAction {
  type: typeof DELETE_AGENCY_FAILURE;
  meta: {
    id?: string;
    error: {
      message: string;
    } & IToastProps;
  };
}

interface AbortGetAllAgenciesRequestAction {
  type: typeof ABORT_GET_ALL_AGENCIES_REQUEST;
}

interface AbortAgencyRequestAction {
  type: typeof ABORT_AGENCY_REQUEST;
  meta: {
    id: string;
  };
}

export type AgenciesActionTypes =
  | GetAllAgenciesPendingAction
  | GetAllAgenciesSuccessAction
  | GetAllAgenciesFailureAction
  | GetAgencyPendingAction
  | GetAgencySuccessAction
  | GetAgencyFailureAction
  | CreateAgencyPendingAction
  | CreateAgencySuccessAction
  | CreateAgencyFailureAction
  | UpdateAgencyPendingAction
  | UpdateAgencySuccessAction
  | UpdateAgencyFailureAction
  | DeleteAgencyPendingAction
  | DeleteAgencySuccessAction
  | DeleteAgencyFailureAction
  | AbortGetAllAgenciesRequestAction
  | AbortAgencyRequestAction;

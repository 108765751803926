import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const User: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-user"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.34833 7.34835C10.8128 5.88388 13.1872 5.88388 14.6516 7.34835C16.1161 8.81281 16.1161 11.1872 14.6516 12.6516C13.1872 14.1161 10.8128 14.1161 9.34833 12.6516C7.88387 11.1872 7.88387 8.81281 9.34833 7.34835ZM10.409 8.40901C11.2877 7.53033 12.7123 7.53033 13.591 8.40901C14.4696 9.28768 14.4696 10.7123 13.591 11.591C12.7123 12.4697 11.2877 12.4697 10.409 11.591C9.53032 10.7123 9.53032 9.28768 10.409 8.40901Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.8943 5.10571C22.7019 8.91332 22.7019 15.0867 18.8943 18.8943C15.0867 22.7019 8.91336 22.7019 5.10577 18.8943C1.29816 15.0867 1.29816 8.9133 5.10577 5.10571C8.91338 1.2981 15.0867 1.2981 18.8943 5.10571ZM17.8337 6.16637C14.6119 2.94454 9.38826 2.94454 6.16643 6.16637C2.94461 9.38818 2.9446 14.6118 6.16643 17.8336C6.2318 17.899 6.298 17.963 6.36498 18.0257C7.3259 16.3124 9.58769 15.25 12 15.25C14.4123 15.25 16.6742 16.3124 17.6351 18.0258C17.7021 17.9631 17.7683 17.899 17.8337 17.8336C21.0554 14.6119 21.0552 9.38823 17.8337 6.16637ZM12 16.75C14.2449 16.75 15.8902 17.789 16.4253 18.9648C13.7339 20.6784 10.2661 20.6784 7.57476 18.9648C8.1098 17.789 9.75512 16.75 12 16.75Z"
      />
    </svg>
  );
};

export default User;

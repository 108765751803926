import { CLIENT_TO_SERVICE_DEVICE_CAP, booleanExpressionLegacyToBooleanExpression } from "../../types";
import { ArchiveFilter, DayOfTheWeek, GeographicEntityTypes, LogicalOperator, makeEmptyDeviceCaps, SERVICE_TO_CLIENT_DEVICE_CAP } from "../../types";
export const ARCHIVE_FILTER_TO_URL_PARAM = {
    [ArchiveFilter.ACTIVE]: "active",
    [ArchiveFilter.ARCHIVED]: "archived",
    [ArchiveFilter.BOTH]: "archived,active"
};
export var PlanArchiveStatus;
(function (PlanArchiveStatus) {
    PlanArchiveStatus["ARCHIVED"] = "archived";
    PlanArchiveStatus["ACTIVE"] = "active";
})(PlanArchiveStatus || (PlanArchiveStatus = {}));
export var ServicePlanArchiveStatus;
(function (ServicePlanArchiveStatus) {
    ServicePlanArchiveStatus["ARCHIVED"] = "ARCHIVED";
    ServicePlanArchiveStatus["ACTIVE"] = "ACTIVE";
})(ServicePlanArchiveStatus || (ServicePlanArchiveStatus = {}));
const SERVICE_TO_CLIENT_ARCHIVE_STATUS = {
    [ServicePlanArchiveStatus.ARCHIVED]: PlanArchiveStatus.ARCHIVED,
    [ServicePlanArchiveStatus.ACTIVE]: PlanArchiveStatus.ACTIVE
};
export var PlanStatus;
(function (PlanStatus) {
    PlanStatus["UNKNOWN"] = "UNKNOWN";
    PlanStatus["CREATED"] = "CREATED";
    PlanStatus["PROCESSING"] = "PROCESSING";
    PlanStatus["READY"] = "READY";
    PlanStatus["ERROR"] = "ERROR";
    PlanStatus["EXPIRED"] = "EXPIRED";
})(PlanStatus || (PlanStatus = {}));
var ServicePlanDimension;
(function (ServicePlanDimension) {
    ServicePlanDimension["PUBLISHER"] = "publishers";
    ServicePlanDimension["DEVICE"] = "devices";
    ServicePlanDimension["DMA"] = "dmas";
    ServicePlanDimension["STATE"] = "regions";
    ServicePlanDimension["ZIP"] = "zips";
    ServicePlanDimension["DISTRICT"] = "congressional_districts";
})(ServicePlanDimension || (ServicePlanDimension = {}));
export var PlanDimension;
(function (PlanDimension) {
    PlanDimension["PUBLISHER"] = "publishers";
    PlanDimension["DEVICE"] = "devices";
    PlanDimension["DMA"] = "dmas";
    PlanDimension["STATE"] = "regions";
    PlanDimension["ZIP"] = "zips";
    PlanDimension["DISTRICT"] = "district";
})(PlanDimension || (PlanDimension = {}));
export var ServiceRunTimeInventoryType;
(function (ServiceRunTimeInventoryType) {
    ServiceRunTimeInventoryType["CLIENT_OWNED_AND_OPERATED"] = "Owned and Operated";
    ServiceRunTimeInventoryType["STANDARD"] = "Standard";
})(ServiceRunTimeInventoryType || (ServiceRunTimeInventoryType = {}));
const SERVICE_TO_CLIENT_DIMENSION = {
    [ServicePlanDimension.PUBLISHER]: PlanDimension.PUBLISHER,
    [ServicePlanDimension.DEVICE]: PlanDimension.DEVICE,
    [ServicePlanDimension.DMA]: PlanDimension.DMA,
    [ServicePlanDimension.STATE]: PlanDimension.STATE,
    [ServicePlanDimension.ZIP]: PlanDimension.ZIP,
    [ServicePlanDimension.DISTRICT]: PlanDimension.DISTRICT
};
const derivePlanStatusFromScenarios = (servicePlan) => {
    const statuses = servicePlan.scenarios
        ? servicePlan.scenarios.map((scenario) => scenario.status)
        : [];
    // TO-DO: figure out how to handle UNKNOWN status
    if (statuses.includes(PlanStatus.EXPIRED))
        return PlanStatus.EXPIRED;
    if (statuses.includes(PlanStatus.ERROR))
        return PlanStatus.ERROR;
    if (statuses.includes(PlanStatus.PROCESSING) || statuses.includes(PlanStatus.CREATED))
        return PlanStatus.PROCESSING;
    return PlanStatus.READY;
};
const serviceToClientPlanBase = (servicePlan) => ({
    id: servicePlan.id,
    name: servicePlan.name,
    startDate: servicePlan.scenarios && new Date(servicePlan.scenarios[0].start_date),
    endDate: servicePlan.scenarios && new Date(servicePlan.scenarios[0].end_date),
    createdAt: new Date(servicePlan.created_at),
    lastUpdated: new Date(servicePlan.updated_at),
    status: derivePlanStatusFromScenarios(servicePlan),
    createdBy: servicePlan.user_id,
    archiveStatus: SERVICE_TO_CLIENT_ARCHIVE_STATUS[servicePlan.status]
});
export const serviceToClientScenario = (scenario, plan) => ({
    id: scenario.id,
    name: scenario.name,
    startDate: new Date(scenario.start_date),
    endDate: new Date(scenario.end_date),
    createdAt: new Date(scenario.created_at),
    lastUpdated: new Date(scenario.updated_at),
    status: scenario.status,
    createdBy: scenario.user_id,
    planId: scenario.plan_id,
    forecastId: scenario.forecast_id,
    constraints: toClientConstraints(scenario),
    frequencies: scenario.ott_constraints.frequencies || [],
    archiveStatus: SERVICE_TO_CLIENT_ARCHIVE_STATUS[plan.status],
    lineItemId: scenario.inst_id
});
export const clientToServiceScenarioBase = (scenario) => ({
    name: scenario.name,
    start_date: scenario.startDate.toISOString(),
    end_date: scenario.endDate.toISOString(),
    ...(scenario.constraints.audienceId ? { audience_id: scenario.constraints.audienceId } : {}),
    product_id: scenario.constraints.productId,
    whitelist_id: scenario.constraints.pubisherListId,
    ott_constraints: {
        ...clientToServiceGeos(scenario.constraints.geoTargeting),
        dayparts_include: scenario.constraints.dayparts,
        device_caps: clientToServiceDevices(scenario.constraints.deviceCaps),
        creative_duration: scenario.constraints.creativeDuration,
        frequencies: scenario.frequencies,
        iab_categories: scenario.constraints.iabCategoryIds,
        ...(scenario.constraints.audienceExpression
            ? { expression: scenario.constraints.audienceExpression }
            : {})
    },
    id: scenario.id,
    plan_id: scenario.planId,
    inst_id: scenario.lineItemId
});
export const serviceToClientPlan = (servicePlan) => ({
    ...serviceToClientPlanBase(servicePlan),
    scenarios: servicePlan.scenarios
        ? servicePlan.scenarios.map((scenario) => serviceToClientScenario(scenario, servicePlan))
        : []
});
const deriveCountry = (includes = {}) => {
    let country = "US";
    if (includes.country) {
        country = includes.country;
    }
    return { logicalOperator: LogicalOperator.INCLUDE, values: [{ id: country, name: country }] };
};
const deriveTargetForSingleGeo = (includes = {}, excludes = {}, geoType) => {
    const logicalOperator = excludes[geoType]?.length
        ? LogicalOperator.EXCLUDE
        : LogicalOperator.INCLUDE;
    const valuesRaw = logicalOperator === LogicalOperator.INCLUDE ? includes[geoType] : excludes[geoType];
    const values = valuesRaw
        ? Array.isArray(valuesRaw)
            ? valuesRaw.map((value) => ({ id: value.toString(), name: null }))
            : [{ id: valuesRaw, name: null }]
        : [];
    return {
        logicalOperator,
        values
    };
};
export const toClientGeoTargeting = (includes, excludes) => ({
    [GeographicEntityTypes.COUNTRY]: deriveCountry(includes),
    [GeographicEntityTypes.STATE]: deriveTargetForSingleGeo(includes, excludes, "regions"),
    [GeographicEntityTypes.DMA]: deriveTargetForSingleGeo(includes, excludes, "dmas"),
    [GeographicEntityTypes.DISTRICT]: deriveTargetForSingleGeo(includes, excludes, "congressional_districts"),
    [GeographicEntityTypes.ZIP_CODE]: deriveTargetForSingleGeo(includes, excludes, "post_codes")
});
export const toClientDeviceTargeting = (serviceConstraints) => serviceConstraints.device_caps
    ? serviceConstraints.device_caps.reduce((clientCaps, serviceCap) => {
        const clientCapName = SERVICE_TO_CLIENT_DEVICE_CAP[serviceCap.type];
        clientCaps[clientCapName] = serviceCap.cap / 100;
        return clientCaps;
    }, makeEmptyDeviceCaps())
    : makeEmptyDeviceCaps();
const fillDay = () => Array.from(Array(24)).map((el, idx) => idx);
const fillClientWeek = () => Object.values(DayOfTheWeek).reduce((acc, day) => {
    acc.push({
        day,
        hours: fillDay()
    });
    return acc;
}, []);
const excludeToIncludeDaypart = (serviceDay, isInclude) => {
    if (!serviceDay)
        return;
    const day = `${serviceDay.day.charAt(0).toUpperCase()}${serviceDay.day
        .slice(1)
        .toLowerCase()}`;
    if (isInclude) {
        return {
            hours: serviceDay.hours ? serviceDay.hours : [],
            day
        };
    }
    const excludedHours = new Set(serviceDay.hours);
    return {
        day,
        hours: fillDay().reduce((acc, el) => (excludedHours.has(el) ? acc : acc.concat(el)), [])
    };
};
const serviceToClientWeek = (serviceWeek, isInclude) => fillClientWeek().map((clientDaypart) => excludeToIncludeDaypart(serviceWeek.find((serviceDaypart) => serviceDaypart.day.toLocaleLowerCase() === clientDaypart.day.toLocaleLowerCase()), isInclude) || clientDaypart);
export const toClientDayparts = (serviceConstraints) => {
    const isInclude = !!serviceConstraints.dayparts_include?.length;
    if (serviceConstraints.dayparts_include?.length) {
        return serviceToClientWeek(serviceConstraints.dayparts_include, isInclude);
    }
    if (serviceConstraints.dayparts_exclude?.length) {
        return serviceToClientWeek(serviceConstraints.dayparts_exclude, isInclude);
    }
    return fillClientWeek();
};
const toClientConstraints = (serviceScenario) => ({
    audienceId: serviceScenario.audience_id,
    // Backend may use an older version of BooleanExpression as the `Expressions` property.
    audienceExpression: serviceScenario.ott_constraints.expression ||
        (serviceScenario.ott_constraints.Expressions
            ? booleanExpressionLegacyToBooleanExpression(serviceScenario.ott_constraints.Expressions)
            : undefined),
    productId: serviceScenario.product_id,
    pubisherListId: serviceScenario.whitelist_id,
    geoTargeting: toClientGeoTargeting(serviceScenario.ott_constraints.geo_include, serviceScenario.ott_constraints.geo_exclude),
    creativeDuration: serviceScenario.ott_constraints.creative_duration || 30,
    deviceCaps: toClientDeviceTargeting(serviceScenario.ott_constraints),
    dayparts: toClientDayparts(serviceScenario.ott_constraints),
    inventoryType: serviceScenario.ott_constraints.run_time_settings?.inventory_type,
    lowAvails: serviceScenario.ott_constraints.run_time_settings?.low_avails,
    iabCategoryIds: serviceScenario.ott_constraints.iab_categories
});
const emptyDimensions = () => Object.values(PlanDimension).reduce((acc, el) => ({ ...acc, [el]: [] }), {});
export const toClientDimensions = (serviceDimensions, serviceSummary) => Object.keys(serviceDimensions).reduce((acc, el) => {
    const clientDimension = SERVICE_TO_CLIENT_DIMENSION[el];
    acc[clientDimension] = serviceDimensions[el].map(({ id, impressions, reach, average_frequency, universe_reach, daily, weekly, monthly }) => ({
        id,
        impressions,
        reach,
        universeReach: universe_reach,
        avgFrequency: average_frequency,
        // Dimension estimated reach percent =  dimension reach / scenario reach
        reachPct: (reach / serviceSummary.reach_count) * 100,
        // The following 3 are calculated impressions and rounded by BE.
        // `impressions` is the raw number for FE but there was issue of
        // double rounding during feature implementation so we'll eventually deprecate.
        daily,
        weekly,
        monthly
    }));
    return acc;
}, emptyDimensions());
export const toClientScenarioResults = (serviceResults, clientScenario) => ({
    ...clientScenario,
    summary: {
        impressions: serviceResults.summary_result.impressions_count,
        reach: serviceResults.summary_result.reach_count,
        universeReach: serviceResults.summary_result.universe_reach_count,
        avgFrequency: serviceResults.summary_result.average_frequency,
        reachPct: Math.round((serviceResults.summary_result.reach_count /
            serviceResults.summary_result.universe_reach_count) *
            100 *
            100) / 100,
        daily: serviceResults.summary_result.daily,
        weekly: serviceResults.summary_result.weekly,
        monthly: serviceResults.summary_result.monthly
    },
    status: clientScenario.status,
    resultsByDimension: toClientDimensions(serviceResults.results_by_dimension, serviceResults.summary_result)
});
export const isIncompletePlanStatus = (response) => response === PlanStatus.ERROR || response === PlanStatus.PROCESSING;
export const isScenario = (obj) => !obj["scenarios"];
export const isScenarioResults = (obj) => !!obj["resultsByDimension"];
export const isHydratedScenarioResults = (obj) => !!obj["resultsByDimension"] && !!obj.constraints["productName"];
const formatServiceGeoFields = (serviceGeo = {}, country) => {
    const includedRegions = Object.keys(serviceGeo)
        .filter((geoType) => geoType !== "country")
        .filter((geoType) => serviceGeo[geoType]?.length);
    if (!serviceGeo.country && !includedRegions.length)
        return;
    const shouldExcludeCountry = includedRegions.length &&
        includedRegions.every((geoType) => ["dmas", "congressional_districts"].includes(geoType));
    return {
        ...serviceGeo,
        country: shouldExcludeCountry ? undefined : country
    };
};
export const formatServiceGeo = (serviceGeo) => {
    if (!serviceGeo.geo_include?.country &&
        !serviceGeo.geo_exclude?.country &&
        !serviceGeo.geo_include?.dmas?.length &&
        !serviceGeo.geo_include?.congressional_districts?.length)
        throw "unable to derive country; bad geo payload";
    /* "US" covers the case where there are only dmas or districts included.
    dmas and districts only exist within the US.
    */
    const country = serviceGeo.geo_include?.country || serviceGeo.geo_exclude?.country || "US";
    return {
        geo_include: formatServiceGeoFields(serviceGeo.geo_include, country),
        geo_exclude: formatServiceGeoFields(serviceGeo.geo_exclude, country)
    };
};
const PLANNER_CLIENT_TO_SERVICE_GEO_KEYS = {
    [GeographicEntityTypes.COUNTRY]: "country",
    [GeographicEntityTypes.STATE]: "regions",
    [GeographicEntityTypes.DISTRICT]: "congressional_districts",
    [GeographicEntityTypes.DMA]: "dmas",
    [GeographicEntityTypes.ZIP_CODE]: "post_codes"
};
const PLANNER_TO_CLIENT_INCLUSIONS = {
    [LogicalOperator.INCLUDE]: "geo_include",
    [LogicalOperator.EXCLUDE]: "geo_exclude"
};
export const clientToServiceGeos = (clientGeo) => formatServiceGeo(Object.keys(clientGeo).reduce((serviceGeos, clientGeoKey) => {
    const clientGeoCategory = clientGeo[clientGeoKey];
    if (!clientGeoCategory)
        return serviceGeos;
    const { logicalOperator } = clientGeoCategory;
    const targetedEntities = clientGeoCategory.values.map((entity) => entity.id);
    if (!targetedEntities.length)
        return serviceGeos;
    const serviceGeoKey = PLANNER_CLIENT_TO_SERVICE_GEO_KEYS[clientGeoKey];
    const serviceOperator = PLANNER_TO_CLIENT_INCLUSIONS[logicalOperator];
    if (clientGeoKey === GeographicEntityTypes.COUNTRY) {
        serviceGeos[serviceOperator] = {
            ...serviceGeos[serviceOperator],
            [serviceGeoKey]: targetedEntities[0]
        };
        return serviceGeos;
    }
    if (clientGeoKey === GeographicEntityTypes.DMA) {
        serviceGeos[serviceOperator] = {
            ...serviceGeos[serviceOperator],
            [serviceGeoKey]: targetedEntities.map((dmaCode) => parseInt(dmaCode, 10))
        };
        return serviceGeos;
    }
    serviceGeos[serviceOperator] = {
        ...serviceGeos[serviceOperator],
        [serviceGeoKey]: targetedEntities
    };
    return serviceGeos;
}, {
    geo_include: {},
    geo_exclude: {}
}));
export const clientToServiceDevices = (clientDevices) => {
    const serviceCaps = [];
    Object.keys(clientDevices).forEach((deviceName) => {
        const cap = clientDevices[deviceName] * 100;
        if (cap > 0) {
            serviceCaps.push({
                cap,
                type: CLIENT_TO_SERVICE_DEVICE_CAP[deviceName]
            });
        }
    });
    return serviceCaps;
};
export const isServiceScenario = (obj) => "plan_id" in obj;

import { NoAuthorizedUser } from "../../../errors";
import { lineItemToServiceCampaignLineItem } from "../../../models/campaigns/lineitems";
import { NotificationCategory, NotificationStatuses } from "../../../models/notifications";
import axios from "axios";
const BULK_EDIT_STORAGE_FOLDER = "bulk-edit-line-item-reports";
export class BulkLineItemHandler {
    constructor(sdk) {
        this.sdk = sdk;
    }
    /**
     * Save specific fields on multiple line items.
     * @param lineItems An array of partial line items.
     * @return An API call promise response.
     */
    async save(lineItems) {
        return new Promise((resolve, reject) => {
            const bulkEditValidatableFields = ["bookedImpressions", "frequencies"];
            lineItems.forEach((lineItem) => {
                bulkEditValidatableFields.forEach((field) => {
                    const error = field in lineItem &&
                        this.sdk.campaigns.lineItems.validate(lineItem, field, lineItem[field]);
                    error && reject(error);
                });
            });
            const serviceReadyLineItems = lineItems.map((item) => {
                const serviceLineItem = lineItemToServiceCampaignLineItem(item);
                // bulkLineItem endpoint uses `dayparts_include` instead of `dayparts`.
                serviceLineItem.dayparts_include = serviceLineItem.dayparts;
                delete serviceLineItem.dayparts;
                // bulkLineItem endpoint uses `recipes` instead of `segments`.
                serviceLineItem.recipes = serviceLineItem.segments;
                delete serviceLineItem.segments;
                return serviceLineItem;
            });
            this.createNotification(lineItems)
                .then((notificationId) => axios
                .patch(`${this.sdk.urls.baseAPIUrl}/bulkLineItem`, { data: serviceReadyLineItems }, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((csvData) => {
                this.updateNotification(notificationId, csvData.data).catch((error) => console.error(error));
                resolve(csvData.data);
            })
                .catch((error) => {
                this.updateNotification(notificationId).catch((error) => console.error(error));
                reject(error?.response?.data?.errors || []);
            }))
                .catch((error) => {
                reject(error);
            })
                .finally(() => {
                // we can't be sure where in the process we got for saving line items
                // (i.e., the remote could have processed 2 then rejected)
                // so, clear all related caches for safety
                this.sdk.caches.clear("campaigns");
                this.sdk.caches.clear("lineitems");
            });
        });
    }
    /**
     * Create a notification in firestore corresponding to the bulk edit action.
     * @param lineItems An array of partial line items.
     * @return The id of the firestore notification
     */
    async createNotification(lineItems) {
        const user = this.sdk.getCurrentUser();
        if (!user) {
            throw new NoAuthorizedUser();
        }
        const numCampaigns = lineItems.reduce((parentIds, line) => {
            line.parent && parentIds.add(line.parent);
            return parentIds;
        }, new Set()).size;
        const notification = {
            title: "Bulk Edit Line Items Report.csv",
            content: `${numCampaigns} Campaigns, ${lineItems.length} Line Items affected`,
            status: NotificationStatuses.IN_QUEUE,
            category: NotificationCategory.BULK_EDIT_LINE_ITEM,
            recipientId: user.email,
            authorId: user.email,
            actions: {
                0: { buttonText: "Download Report" }
            },
            meta: {
                submittedDate: new Date()
            }
        };
        return await this.sdk.notifications.createNotification(notification);
    }
    /**
     * Uploads the CSV string to cloud storage in mad-data as a .csv file,
     * then updates the firestore notification status to complete and updates
     * the notifcation meta with a link to the CSV. If no CSV string is
     * provided, it upates the notification status to complete with an error message.
     * NB: usually the backend API uploads CSVs to cloud storage, so we may
     * want to offload this responsiblity to it in the future.
     * @param notificationId The id of the firestore notification.
     * @param csvString The CSV string generated by the Campaign Manager API,
     * which details the user's targeting changes
     */
    async updateNotification(notificationId, csvString) {
        const notification = await this.sdk.notifications.getNotification(notificationId);
        const path = `${BULK_EDIT_STORAGE_FOLDER}/${notificationId}-report.csv`;
        if (!csvString) {
            await this.sdk.notifications.updateNotification(notificationId, {
                status: NotificationStatuses.SENT,
                content: "Unable to bulk update line items",
                /** Clear out actions so no 'download' button displays in notification drawer */
                actions: {}
            });
            return;
        }
        await this.sdk.madFire.uploadToStorage(`${this.sdk.madFire.config.projectId}/${path}`, new Blob([csvString], { type: "text/csv;charset=utf-8;" }));
        await this.sdk.notifications.updateNotification(notificationId, {
            meta: {
                ...notification.meta,
                /** Updating the path here provides the notification button with a download path */
                path
            },
            status: NotificationStatuses.SENT
        });
    }
}

export enum SpinnerSize {
  TINY = "tiny",
  SMALL = "small",
  MEDIUM = "medium"
}

export enum SpinnerColor {
  PRIMARY = "var(--primary-color)",
  SECONDARY = "var(--secondary-color)",
  GRAY_4 = "var(--gray-4)",
  SUCCESS = "var(--color-success)"
}

export interface SpinnerProps {
  /** The size of the Spinner */
  size?: SpinnerSize;
  /** The color of the Spinner */
  color?: SpinnerColor;
  /** An optional data-testid prop for testing */
  "data-testid"?: string;
}

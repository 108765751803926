/**
 * @param direction: ascending or descending.
 * @param sort: the sort info.
 * @return: true if it is the provided `direction`. Else, false.
 */
const isDirection = (direction, sort) => {
    const compare = typeof sort === "string" ? sort : sort.direction;
    switch (direction) {
        case "ascending":
            return compare === "asc" || compare === "ascending";
        case "descending":
            return compare === "desc" || compare === "descending";
        default:
            return false;
    }
};
/**
 * @param sort: the sort info.
 * @return: true if it is sorted to be ascending. Else, false.
 */
export const isAscending = (sort) => isDirection("ascending", sort);
/**
 * @param sort: the sort info.
 * @return: true if it is sorted to be descending. Else, false.
 */
export const isDescending = (sort) => isDirection("descending", sort);

import { css } from "@emotion/react";

export const styles = {
  fill: css`
    width: 100%;
  `,
  base: css`
    display: flex;
    flex-wrap: no-wrap;
    gap: var(--spacing-24);
    .bp4-control {
      margin-bottom: 0;
    }
  `,
  stacked: css`
    flex-direction: column;
    gap: var(--spacing-16);
  `,
  withLabel: css`
    margin-top: var(--spacing-8);
  `
};

import axios from "axios";
import { Handler } from "../../handlers";
import { serviceSpecificationToSpecification } from "../../../models/creatives/specifications";
import { NotImplementedError } from "../../../errors";
import { deriveProblems, deriveStandards } from "./utils";
class Specifications extends Handler {
    constructor(sdk) {
        super(sdk, "specifications");
        this.getOrgSpecsMap = async () => {
            return this.find().then((specs) => {
                const orgSpecs = new Map();
                specs.forEach((spec) => orgSpecs.set(spec.name, spec));
                return orgSpecs;
            });
        };
        this.deriveProblemsAndStandards = async (assetMetadata) => {
            const orgSpecs = await this.getOrgSpecsMap();
            return {
                problems: deriveProblems(assetMetadata, orgSpecs),
                standards: deriveStandards(assetMetadata, orgSpecs)
            };
        };
        this.toSpecification = (serviceSpecification) => {
            return serviceSpecificationToSpecification(serviceSpecification);
        };
    }
    getIdentifierKey() {
        return "name";
    }
    async findItems() {
        const url = `${this.sdk.urls.madhiveEncoderBaseUrl}/specs`;
        if (this.cache.has(url)) {
            return new Promise((resolve) => {
                this.cache.get(url).then((specifications) => {
                    resolve(specifications);
                });
            });
        }
        const req = new Promise((resolve, reject) => {
            axios
                .get(url, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
                .then((res) => {
                if (res.data?.data) {
                    resolve(res.data.data.map((spec) => this.toSpecification(spec)));
                }
                else {
                    throw new Error();
                }
            })
                .catch((error) => {
                reject(error);
            });
        });
        this.cache.set(url, req);
        return req;
    }
    /* eslint-disable-next-line class-methods-use-this, no-shadow */
    async saveItem() {
        throw new NotImplementedError("Save");
    }
    /* eslint-disable-next-line class-methods-use-this */
    async deleteItem() {
        throw new NotImplementedError("Delete");
    }
}
export default Specifications;

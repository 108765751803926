export var MediaType;
(function (MediaType) {
    MediaType[MediaType["NONE"] = 0] = "NONE";
    MediaType[MediaType["VIDEO"] = 1] = "VIDEO";
    MediaType[MediaType["AUDIO"] = 2] = "AUDIO";
    MediaType[MediaType["DISPLAY"] = 3] = "DISPLAY";
})(MediaType || (MediaType = {}));
export var VideoType;
(function (VideoType) {
    VideoType[VideoType["VT_NONE"] = 0] = "VT_NONE";
    VideoType[VideoType["OTT"] = 1] = "OTT";
    VideoType[VideoType["OLV"] = 2] = "OLV";
})(VideoType || (VideoType = {}));

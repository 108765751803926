import { createSelector } from "reselect";
import { RootState } from "rootReducer";

export const selectAgencyIdsToNames = (state: RootState) =>
  Object.entries(state.agencies.byId).reduce((acc, [id, { name }]) => {
    acc[id] = name;
    return acc;
  }, {});

export const selectAllAgenciesById = (state: RootState) => state.agencies.byId;

export const selectIsAllAgenciesLoading = (state: RootState) =>
  state.agencies.isAllLoading;

export const selectLoadingAgencyIds = (state: RootState) =>
  Object.keys(state.agencies.pendingById);

export const selectAgenciesPendingStateById = (state: RootState) =>
  state.agencies.pendingById;

export const selectIsAgenciesCreating = (state: RootState) =>
  !!Object.keys(state.agencies.creatingByRequestId).length;

export const selectIsAgencyCreatePending = (
  state: RootState,
  requestId: string
) => !!state.agencies.creatingByRequestId[requestId];

export const selectIsAgencyPending = (state: RootState, id: string) =>
  !!state.agencies.pendingById[id];

export const selectIsAgenciesLoadingForFirstTime = (state: RootState) =>
  !state.agencies.receivedAllAt && state.agencies.isAllLoading;

export const selectAgencyIdsNoOrder = createSelector(
  selectAllAgenciesById,
  agenciesById => Object.keys(agenciesById)
);

export const selectAgencyIdsOrderedByIdAscending = createSelector(
  selectAllAgenciesById,
  agenciesById =>
    Object.keys(agenciesById).sort((idA, idB) =>
      idA.toLowerCase().localeCompare(idB.toLowerCase())
    )
);

export const selectAgencyIdsOrderedByIdDescending = createSelector(
  selectAllAgenciesById,
  agenciesById =>
    Object.keys(agenciesById).sort((idA, idB) =>
      idB.toLowerCase().localeCompare(idA.toLowerCase())
    )
);

export const selectAgencyIdsOrderedByNameAscending = createSelector(
  selectAllAgenciesById,
  agenciesById =>
    Object.keys(agenciesById).sort((idA, idB) =>
      agenciesById[idA].name
        .toLowerCase()
        .localeCompare(agenciesById[idB].name.toLowerCase())
    )
);

export const selectAgencyIdsOrderedByNameDescending = createSelector(
  selectAllAgenciesById,
  agenciesById =>
    Object.keys(agenciesById).sort((idA, idB) =>
      agenciesById[idB].name
        .toLowerCase()
        .localeCompare(agenciesById[idA].name.toLowerCase())
    )
);

export const selectAgencyIdsOrderedByExternalIdAscending = createSelector(
  selectAllAgenciesById,
  agenciesById =>
    Object.keys(agenciesById).sort((idA, idB) =>
      agenciesById[idA].externalId
        .toLowerCase()
        .localeCompare(agenciesById[idB].externalId.toLowerCase())
    )
);

export const selectAgencyIdsOrderedByExternalIdDescending = createSelector(
  selectAllAgenciesById,
  agenciesById =>
    Object.keys(agenciesById).sort((idA, idB) =>
      agenciesById[idB].externalId
        .toLowerCase()
        .localeCompare(agenciesById[idA].externalId.toLowerCase())
    )
);

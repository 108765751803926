import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const PlayWindow: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-play-window"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 17.556C1.25 16.5898 2.03379 15.806 3 15.806H7.02C7.60391 15.806 8.15174 16.0977 8.47638 16.5855C8.56847 16.7239 8.65112 16.8476 8.73415 16.9719C8.81704 17.096 8.90056 17.221 8.99338 17.3605C9.03874 17.4287 9.11691 17.471 9.201 17.471H14.798C14.8821 17.471 14.9603 17.4287 15.0056 17.3605C15.0985 17.221 15.1818 17.0963 15.2647 16.9722C15.3477 16.8479 15.4305 16.7239 15.5226 16.5855C15.8473 16.0977 16.3951 15.806 16.979 15.806H21C21.9657 15.806 22.75 16.5883 22.75 17.556V19C22.75 20.5192 21.5192 21.75 20 21.75H4C2.48079 21.75 1.25 20.5192 1.25 19V17.556ZM3 17.306C2.86221 17.306 2.75 17.4182 2.75 17.556V19C2.75 19.6908 3.30921 20.25 4 20.25H20C20.6908 20.25 21.25 19.6908 21.25 19V17.556C21.25 17.4177 21.1383 17.306 21 17.306H16.979C16.8949 17.306 16.8167 17.3484 16.7714 17.4165C16.6785 17.5561 16.5953 17.6807 16.5123 17.8048C16.4293 17.9291 16.3465 18.0532 16.2544 18.1915C15.9297 18.6794 15.3819 18.971 14.798 18.971H9.201C8.61709 18.971 8.06926 18.6794 7.74462 18.1915C7.65253 18.0532 7.56988 17.9294 7.48685 17.8051C7.40396 17.6811 7.32044 17.556 7.22762 17.4165C7.18226 17.3484 7.10409 17.306 7.02 17.306H3Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.36099 4.746C2.36099 3.282 3.67199 2.25 5.08699 2.25H18.913C20.328 2.25 21.639 3.282 21.639 4.746V16.5555C21.639 16.5556 21.639 16.556 20.889 16.556C20.139 16.556 20.139 16.556 20.139 16.5559V4.746C20.139 4.28 19.68 3.75 18.913 3.75H5.08699C4.32 3.75 3.86099 4.28 3.86099 4.746V16.5555C3.86099 16.5556 3.86099 16.556 3.11099 16.556C2.36099 16.556 2.36099 16.556 2.36099 16.5559V4.746ZM3.11099 16.556L2.36099 16.5559C2.36099 16.9701 2.69678 17.306 3.11099 17.306C3.52521 17.306 3.86099 16.9697 3.86099 16.5555L3.11099 16.556ZM20.889 17.306C20.4748 17.306 20.139 16.9701 20.139 16.5559L20.889 16.556L21.639 16.5555C21.639 16.9697 21.3032 17.306 20.889 17.306Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.107 9.35591V12.1421C11.6917 11.796 12.8844 11.0902 13.4617 10.749C12.8844 10.4077 11.6917 9.70199 11.107 9.35591ZM9.60699 9.02798C9.60699 8.01302 10.7114 7.37859 11.5881 7.8976C11.9634 8.11979 14.1307 9.40236 14.498 9.61894C15.358 10.1261 15.358 11.3719 14.498 11.879C14.1307 12.0956 11.9634 13.3782 11.5881 13.6004C10.7124 14.1188 9.60699 13.4861 9.60699 12.471V9.02798Z"
      />
    </svg>
  );
};

export default PlayWindow;

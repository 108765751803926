import { CampaignStatus } from "../../../models/campaigns/instruction";
/**
 * @param campaign: a campaign.
 * @param value: the status value to validate.
 * @param current: the current status value of the campaign.
 * @param updating: true if this validation is for an update to the campaign, else false (i.e., an as-is validation).
 * @return: a validation error message, if it is not valid. Else, undefined.
 */
export const validateStatus = (campaign, value, current, updating, validateLineItemStatus) => {
    // Not setting status (new campaign).
    if (!value && !current) {
        return;
    }
    if (!Object.values(CampaignStatus).includes(value)) {
        return "Status should be a valid campaign status.";
    }
    try {
        switch (value) {
            case CampaignStatus.LIVE:
                return validateLiveStatus(campaign, current, updating, validateLineItemStatus);
            case CampaignStatus.PAUSED:
                return validatePausedStatus(campaign, current, updating, validateLineItemStatus);
            case CampaignStatus.ARCHIVED:
                return validateArchivedStatus(campaign, current, updating, validateLineItemStatus);
            case CampaignStatus.READY:
                return validateReadyStatus(campaign, current, updating);
        }
    }
    catch {
        return "Campaign has an invalid structure.";
    }
    return;
};
const validateLiveStatus = (campaign, current, updating, validateLineItemStatus) => {
    if (updating) {
        if (current === CampaignStatus.MISSING_LINE_ITEM) {
            return "No line items on this campaign.";
        }
    }
    for (const lineItem of campaign.lineItems) {
        if (lineItem.isLive()) {
            return undefined;
        }
        const result = validateLineItemStatus(lineItem, CampaignStatus.LIVE);
        if (!result) {
            return undefined;
        }
    }
    return "No line items of this campaign can be set live.";
};
const validatePausedStatus = (campaign, current, updating, validateLineItemStatus) => {
    for (const lineItem of campaign.lineItems) {
        if (lineItem.status === CampaignStatus.PAUSED) {
            return undefined;
        }
        const result = validateLineItemStatus(lineItem, CampaignStatus.PAUSED);
        if (!result) {
            return undefined;
        }
    }
    return "No line items of this campaign can be paused.";
};
const validateArchivedStatus = (campaign, current, updating, validateLineItemStatus) => {
    if (updating && current === CampaignStatus.ARCHIVED) {
        return "This campaign is already archived. An archived campaign cannot be archived again.";
    }
    for (const lineItem of campaign.lineItems) {
        if (lineItem.status === CampaignStatus.ARCHIVED) {
            continue;
        }
        const result = validateLineItemStatus(lineItem, CampaignStatus.ARCHIVED);
        if (result) {
            return `A line item of this campaign (${lineItem.name}) can't be archived: ${result}`;
        }
    }
    return;
};
/**
 * @param campaign: the campaign.
 * @return: true if its status can be ready. Else, false.
 */
const validateReadyStatus = (campaign, current, updating) => {
    if (updating && current !== CampaignStatus.ARCHIVED) {
        return "A campaign which is not archived cannot be set to ready.";
    }
    return;
};

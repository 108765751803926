export var TrackerType;
(function (TrackerType) {
    TrackerType[TrackerType["IMPRESSION"] = 10] = "IMPRESSION";
    TrackerType[TrackerType["START"] = 20] = "START";
    TrackerType[TrackerType["Q1"] = 21] = "Q1";
    TrackerType[TrackerType["Q2"] = 22] = "Q2";
    TrackerType[TrackerType["Q3"] = 23] = "Q3";
    TrackerType[TrackerType["Q4"] = 24] = "Q4";
    TrackerType[TrackerType["CLICK_THROUGH"] = 100] = "CLICK_THROUGH";
    TrackerType[TrackerType["CLICK_TRACKER"] = 101] = "CLICK_TRACKER";
})(TrackerType || (TrackerType = {}));
export const DEFAULT_EMPTY_TRACKING_PIXELS = [
    {
        type: TrackerType.IMPRESSION,
        urls: [""]
    },
    {
        type: TrackerType.START,
        urls: [""]
    },
    {
        type: TrackerType.Q1,
        urls: [""]
    },
    {
        type: TrackerType.Q2,
        urls: [""]
    },
    {
        type: TrackerType.Q3,
        urls: [""]
    },
    {
        type: TrackerType.Q4,
        urls: [""]
    },
    {
        type: TrackerType.CLICK_TRACKER,
        urls: [""]
    },
    {
        type: TrackerType.CLICK_THROUGH,
        urls: [""]
    }
];
export const TRACKER_TYPES = DEFAULT_EMPTY_TRACKING_PIXELS.map(({ type }) => type);
export const TRACKER_EVENT_TYPE_TO_SORT_INDEX = {
    [TrackerType.IMPRESSION]: 0,
    [TrackerType.START]: 1,
    [TrackerType.Q1]: 2,
    [TrackerType.Q2]: 3,
    [TrackerType.Q3]: 4,
    [TrackerType.Q4]: 5,
    [TrackerType.CLICK_TRACKER]: 6,
    [TrackerType.CLICK_THROUGH]: 7
};
/**
 * Looks through the event urls looking for a TrackerType.CLICK_THROUGH
 * @param event_urls array of event_urls
 * @returns the clickThrough url or empty string
 */
export const findClickThroughFromEvents = (event_urls) => {
    if (event_urls && event_urls.length > 0) {
        const clickThrough = event_urls.find((item) => item.type === TrackerType.CLICK_THROUGH);
        if (clickThrough?.urls?.length) {
            return clickThrough.urls[0];
        }
        return "";
    }
    return "";
};
/**
 * Filters our trackers that are only empty urls
 * @param trackers array of trackers to clean out
 * @returns
 */
export const filterOutEmptyTrackingUrls = (trackers) => {
    if (trackers) {
        return trackers
            .map((el) => ({
            ...el,
            urls: el.urls ? el.urls.filter((url) => !!url) : []
        }))
            .filter((el) => el.urls && el.urls.length > 0);
    }
    return [];
};
/**
 * Cleans out the trackers object to make sure they are standard
 * @param trackers array of all TrackerObjects
 * @returns tracker array with added types
 */
export const fillInEmptyTrackingTypes = (trackers, defaultTrackers = DEFAULT_EMPTY_TRACKING_PIXELS) => {
    // Get existing trackers in creative/creative flight
    const existingTrackers = filterOutEmptyTrackingUrls(trackers);
    // Get tracker types that exist in creative/creative flight
    const existingTrackerTypes = existingTrackers.map((item) => item.type);
    // Get non-existing types to fill tracking pixels data with and show in UI
    const trackersToFill = defaultTrackers.filter((tracker) => existingTrackerTypes.every((item) => item !== tracker.type));
    return [...existingTrackers, ...trackersToFill].sort((trackerA, trackerB) => TRACKER_EVENT_TYPE_TO_SORT_INDEX[trackerA.type] -
        TRACKER_EVENT_TYPE_TO_SORT_INDEX[trackerB.type]);
};
/**
 * clickThrough url needs to be part of Trackers. If
 * it isn't in the trackers add it.
 * @param trackers Array of trackers
 * @param clickThrough url fo the clickThrough
 * @returns new trackers array
 */
export const addClickThroughToTrackers = (trackers, clickThrough) => {
    // If clickThrough is empty then we filter out the associated type in trackers array
    if (!clickThrough) {
        return trackers.filter((el) => el.type !== TrackerType.CLICK_THROUGH);
    }
    // If clickThrough exists and type doesn't exist in event_urls, we'll want to append it
    return !trackers.find((el) => el.type === TrackerType.CLICK_THROUGH && el.urls[0])
        ? trackers.concat([{ type: TrackerType.CLICK_THROUGH, urls: [clickThrough.trim()] }])
        : trackers.map((item) => 
        // This ensures that the latest clickThrough is copied over to the event_urls[]
        item.type === TrackerType.CLICK_THROUGH
            ? { type: TrackerType.CLICK_THROUGH, urls: [clickThrough.trim()] }
            : item);
};
/**
 * Takes a Creative and returns eventUrls based off the data given
 * @param creative to get the eventUrls
 * @returns Array of TrackerObjects
 */
export const parseEventUrls = (creative) => {
    let eventUrls;
    if (creative.trackingPixels || creative.clickThroughUrl) {
        // Ensures that at least one tracker url exists since 0th index is empty strings
        const filteredOutEmptyTrackingUrls = filterOutEmptyTrackingUrls(creative.trackingPixels);
        eventUrls = addClickThroughToTrackers(filteredOutEmptyTrackingUrls, creative.clickThroughUrl);
    }
    return eventUrls;
};

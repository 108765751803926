import { captureException } from "@sentry/browser";
import { madSDK } from "lib/sdk";
import { User } from "@madhive/mad-sdk";

interface LogErrorCloudFunctionRequestBody {
  user: string;
  source: string;
  error: string;
}

/**
 * We want to support calling this function with things that have a `.type`
 * */
interface CustomError {
  type: string;
}

const errorIsCustomError = (
  error: unknown | CustomError
): error is CustomError =>
  typeof error === "object" && !!error && !!(error as CustomError).type;

/**
 * Errors can be thrown from any part of the application and can be anything.
 * */
const normalizeError = (error: unknown | CustomError): Error => {
  if (error instanceof Error) {
    return error;
  }

  if (typeof error === "string") {
    return new Error(error);
  }

  if (errorIsCustomError(error)) {
    return new Error(error.type);
  }

  return new Error("Unknown Error");
};

const normalizeUser = (user: User | null): string => {
  if (!user) {
    return "Non-logged in user";
  }

  return `User email: ${user.email || "N/A"}. User UID: ${user.id}`;
};

const printError = (error: Error): string =>
  `Error Name: ${error.name}\nErrorMessage:${error.message}\nError Stack: ${error.stack}`;

/**
 * At runtime, this function could potentially be called with anything (an error
 * can be thrown at any time from any part of the application and is technically
 * of type `unknown`), at compile time we enforce a specific type for `error` so
 * that when we explicitly call it, TypeScript will remind us to call it with
 * something that will produce a useful error message.
 *
 * We handle `error`s of unsupported types via `normalizeError`
 */
// TODO: Move functions to sdk so firebase upgrades are easier
export const logErrorToStackdriver = (error: Error | string | CustomError) => {
  const user = madSDK.getCurrentUser();

  const normalizedError = normalizeError(error);

  const requestBody: LogErrorCloudFunctionRequestBody = {
    error: printError(normalizedError),
    source: window.location.href,
    user: normalizeUser(user || null)
  };

  captureException(normalizedError);
  try {
    madSDK.madFire.logError(requestBody);
  } catch (e) {
    console.log("Failed to log an error.", e);
  }
};

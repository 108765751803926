import {
  maskOff,
  selectCurrentUser,
  selectIsMasquerading
} from "appReducers/authReducer";
import classNames from "classnames";
import {
  DATE_OF_CURRENT_RELEASE,
  SET_OF_DEMO_DOMAINS,
  SET_OF_NON_PROD_DOMAINS
} from "lib/constants/config";
import { prettyPrintDate } from "lib/utils/date";
import Banner, { PersistentBottomBannerProps } from "madhive/components/Banner";

import { useDispatch, useSelector } from "react-redux";
import { KnownHostName } from "types";
import "./PersistentBottomBanners.scss";
import { FC, useCallback } from "react";
import { VITE_SMITHERS_VERSION } from "lib/viteEnvConfig";

interface Props {}

enum PersistentBannerVariant {
  INFO = "info",
  WARNING = "warning"
}

export const PersistentBottomBanners: FC<Props> = () => {
  const user = useSelector(selectCurrentUser);
  const isMasquerading = useSelector(selectIsMasquerading);
  const dispatch = useDispatch();
  const onMaskOff = useCallback(() => dispatch(maskOff()), [dispatch]);

  const currentHostName = window.location.hostname as KnownHostName;
  const isInNonProductionDomain = SET_OF_NON_PROD_DOMAINS.has(currentHostName);
  const isInDemoDomain = SET_OF_DEMO_DOMAINS.has(currentHostName);
  const isNonProd = isInNonProductionDomain || isInDemoDomain;

  /**
   * currentAppTag will only be defined in staging and production.
   * These are the only two environments that have an associated git tag.
   * */
  const currentAppTag =
    VITE_SMITHERS_VERSION && VITE_SMITHERS_VERSION[0] === "v"
      ? `App Version: ${VITE_SMITHERS_VERSION}`
      : undefined;

  if (!user) {
    return null;
  }

  const bannersToDisplay: PersistentBottomBannerProps[] = [];

  if (isMasquerading) {
    bannersToDisplay.push({
      id: "masquerade-banner",
      message: `You are masquerading as ${user.email}. `,
      variant: PersistentBannerVariant.INFO,
      action: (
        <span
          className={classNames("clickableText", "ml-8")}
          role="button"
          tabIndex={0}
          onClick={onMaskOff}
        >
          Stop Masquerading
        </span>
      )
    });
  }

  if (isNonProd) {
    bannersToDisplay.push({
      id: "non-production-banner",
      message: (
        <span>
          You are logged into a non-production environment meant for testing and
          shouldn't expect to see real-time data.{" "}
          <span style={{ fontWeight: "bold" }}>
            Last Deployed {prettyPrintDate(DATE_OF_CURRENT_RELEASE)}{" "}
          </span>
          {user.isDev && currentAppTag ? currentAppTag : ""}
        </span>
      ),
      variant: PersistentBannerVariant.WARNING
    });
  }

  if (user.isDev && currentAppTag && !isNonProd) {
    bannersToDisplay.push({
      id: "smithers-version-banner",
      message: <span>{currentAppTag}</span>,
      variant: PersistentBannerVariant.INFO
    });
  }

  return (
    <div className="persistentBannersContainer" data-pdfignore>
      {bannersToDisplay.map(el => (
        <Banner {...el} key={el.id} />
      ))}
    </div>
  );
};

import { FC } from "react";
import { CommonIconProps } from "../../Icon/types";
import { FourDirection } from "../types";

type Props = CommonIconProps & {
  direction?: FourDirection;
};

const Arrow: FC<Props> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)",
  direction = "right"
}) => {
  return (
    <svg
      className="icon-circled-arrow"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      {direction === "up" && (
        <>
          <path d="M11.4697 7.46967C11.5416 7.39776 11.6245 7.34351 11.7129 7.30691C11.8013 7.27024 11.8983 7.25 12 7.25C12.1919 7.25 12.3839 7.32322 12.5303 7.46967L15.5303 10.4697C15.8232 10.7626 15.8232 11.2374 15.5303 11.5303C15.2374 11.8232 14.7626 11.8232 14.4697 11.5303L12.75 9.81066V16C12.75 16.4142 12.4142 16.75 12 16.75C11.5858 16.75 11.25 16.4142 11.25 16V9.81066L9.53033 11.5303C9.23744 11.8232 8.76256 11.8232 8.46967 11.5303C8.17678 11.2374 8.17678 10.7626 8.46967 10.4697L11.4697 7.46967Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.25 12C2.25 17.3852 6.61479 21.75 12 21.75C17.3852 21.75 21.75 17.3852 21.75 12C21.75 6.61479 17.3852 2.25 12 2.25C6.61479 2.25 2.25 6.61479 2.25 12ZM3.75 12C3.75 7.44321 7.44321 3.75 12 3.75C16.5568 3.75 20.25 7.44321 20.25 12C20.25 16.5568 16.5568 20.25 12 20.25C7.44321 20.25 3.75 16.5568 3.75 12Z"
          />
        </>
      )}
      {direction === "right" && (
        <>
          <path d="M12.4697 8.46967C12.7626 8.17678 13.2374 8.17678 13.5303 8.46967L16.5303 11.4697C16.6022 11.5416 16.6565 11.6245 16.6931 11.7129C16.7298 11.8013 16.75 11.8983 16.75 12C16.75 12.1919 16.6768 12.3839 16.5303 12.5303L13.5303 15.5303C13.2374 15.8232 12.7626 15.8232 12.4697 15.5303C12.1768 15.2374 12.1768 14.7626 12.4697 14.4697L14.1893 12.75H8C7.58579 12.75 7.25 12.4142 7.25 12C7.25 11.5858 7.58579 11.25 8 11.25H14.1893L12.4697 9.53033C12.1768 9.23744 12.1768 8.76256 12.4697 8.46967Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 2.25C6.61479 2.25 2.25 6.61479 2.25 12C2.25 17.3852 6.61479 21.75 12 21.75C17.3852 21.75 21.75 17.3852 21.75 12C21.75 6.61479 17.3852 2.25 12 2.25ZM3.75 12C3.75 7.44321 7.44321 3.75 12 3.75C16.5568 3.75 20.25 7.44321 20.25 12C20.25 16.5568 16.5568 20.25 12 20.25C7.44321 20.25 3.75 16.5568 3.75 12Z"
          />
        </>
      )}
      {direction === "down" && (
        <>
          <path d="M12 7.25C12.4142 7.25 12.75 7.58579 12.75 8V14.1893L14.4697 12.4697C14.7626 12.1768 15.2374 12.1768 15.5303 12.4697C15.8232 12.7626 15.8232 13.2374 15.5303 13.5303L12.5303 16.5303C12.3839 16.6768 12.1919 16.75 12 16.75C11.8983 16.75 11.8013 16.7298 11.7129 16.6931C11.6245 16.6565 11.5416 16.6022 11.4697 16.5303L8.46967 13.5303C8.17678 13.2374 8.17678 12.7626 8.46967 12.4697C8.76256 12.1768 9.23744 12.1768 9.53033 12.4697L11.25 14.1893V8C11.25 7.58579 11.5858 7.25 12 7.25Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.25 12C2.25 17.3852 6.61479 21.75 12 21.75C17.3852 21.75 21.75 17.3852 21.75 12C21.75 6.61479 17.3852 2.25 12 2.25C6.61479 2.25 2.25 6.61479 2.25 12ZM3.75 12C3.75 7.44321 7.44321 3.75 12 3.75C16.5568 3.75 20.25 7.44321 20.25 12C20.25 16.5568 16.5568 20.25 12 20.25C7.44321 20.25 3.75 16.5568 3.75 12Z"
          />
        </>
      )}
      {direction === "left" && (
        <>
          <path d="M11.5303 8.46967C11.8232 8.76256 11.8232 9.23744 11.5303 9.53033L9.81066 11.25H16C16.4142 11.25 16.75 11.5858 16.75 12C16.75 12.4142 16.4142 12.75 16 12.75H9.81066L11.5303 14.4697C11.8232 14.7626 11.8232 15.2374 11.5303 15.5303C11.2374 15.8232 10.7626 15.8232 10.4697 15.5303L7.46967 12.5303C7.39776 12.4584 7.34351 12.3755 7.30691 12.2871C7.27024 12.1987 7.25 12.1017 7.25 12C7.25 11.8081 7.32322 11.6161 7.46967 11.4697L10.4697 8.46967C10.7626 8.17678 11.2374 8.17678 11.5303 8.46967Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.25 12C2.25 17.3852 6.61479 21.75 12 21.75C17.3852 21.75 21.75 17.3852 21.75 12C21.75 6.61479 17.3852 2.25 12 2.25C6.61479 2.25 2.25 6.61479 2.25 12ZM3.75 12C3.75 7.44321 7.44321 3.75 12 3.75C16.5568 3.75 20.25 7.44321 20.25 12C20.25 16.5568 16.5568 20.25 12 20.25C7.44321 20.25 3.75 16.5568 3.75 12Z"
          />
        </>
      )}
    </svg>
  );
};

export default Arrow;

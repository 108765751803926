import { ProxySession } from "@looker/sdk-rtl";
import axios from "axios";
export class MadLookerSession extends ProxySession {
    /**
     * @param settings: Looker API settings.
     * @param transport: the actual transport layer.
     */
    constructor(sdk, settings, transport) {
        const proxyUrl = sdk.urls.burnsBaseUrl + "/looker/api/token";
        super(settings, proxyUrl, transport);
        this.settings = settings;
        this.transport = transport;
    }
    async getToken() {
        if (!this.activeToken) {
            this.activeToken = await axios.get(this.proxyUrl).then((res) => res.data);
        }
        return this.activeToken;
    }
    isAuthenticated() {
        return !!this.activeToken;
    }
    logout() {
        this.activeToken = undefined;
        return Promise.resolve(true);
    }
    async authenticate(props) {
        const token = await this.getToken();
        if (token) {
            props.mode = "cors";
            delete props["credentials"];
            props.headers = {
                ...props.headers,
                Authorization: `Bearer ${token.access_token}`
            };
        }
        return props;
    }
}

export const serviceDmpToDmp = (dmp) => {
    return {
        code: dmp.code,
        id: dmp.id,
        name: dmp.name,
        status: dmp.status,
        parent: dmp.parent ? dmp.parent : undefined,
        updatedAt: new Date(dmp.updated_at.seconds * 1000)
    };
};

import { DaypartsArray } from "types";
import { DayOfTheWeek } from "lib/constants/misc";
import { makeRange } from "@madhive/mad-sdk";

export const HOURS = [
  "12am",
  "1am",
  "2am",
  "3am",
  "4am",
  "5am",
  "6am",
  "7am",
  "8am",
  "9am",
  "10am",
  "11am",
  "12pm",
  "1pm",
  "2pm",
  "3pm",
  "4pm",
  "5pm",
  "6pm",
  "7pm",
  "8pm",
  "9pm",
  "10pm",
  "11pm"
];

export const EMPTY_DAYPARTS: DaypartsArray = [
  {
    day: DayOfTheWeek.SUNDAY,
    hours: []
  },
  {
    day: DayOfTheWeek.MONDAY,
    hours: []
  },
  {
    day: DayOfTheWeek.TUESDAY,
    hours: []
  },
  {
    day: DayOfTheWeek.WEDNESDAY,
    hours: []
  },
  {
    day: DayOfTheWeek.THURSDAY,
    hours: []
  },
  {
    day: DayOfTheWeek.FRIDAY,
    hours: []
  },
  {
    day: DayOfTheWeek.SATURDAY,
    hours: []
  }
];

export const allHours = makeRange(24);

export const FILLED_DAYPARTS: DaypartsArray = [
  {
    day: DayOfTheWeek.SUNDAY,
    hours: allHours
  },
  {
    day: DayOfTheWeek.MONDAY,
    hours: allHours
  },
  {
    day: DayOfTheWeek.TUESDAY,
    hours: allHours
  },
  {
    day: DayOfTheWeek.WEDNESDAY,
    hours: allHours
  },
  {
    day: DayOfTheWeek.THURSDAY,
    hours: allHours
  },
  {
    day: DayOfTheWeek.FRIDAY,
    hours: allHours
  },
  {
    day: DayOfTheWeek.SATURDAY,
    hours: allHours
  }
];

export enum DRAG_MODE {
  NOT_DRAGGING = 0,
  ADDING_DAYPARTS = 1,
  REMOVING_DAYPARTS = 2
}

import { FC } from "react";
import { CommonIconProps, Size } from "./types";

type Props = CommonIconProps & {
  variant?: Size;
};

const Calendar: FC<Props> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)",
  variant = "medium"
}) => {
  return (
    <svg
      className={`icon-calendar-${variant}`}
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      {variant === "small" && (
        <path d="M15.7036 8.29634C16.1481 8.29634 16.4444 8.00004 16.4444 7.5556V6.07411C16.4444 5.70374 16.1481 5.33337 15.7036 5.33337C15.2592 5.33337 14.9629 5.70374 14.9629 6.07411V7.5556C14.9629 8.00004 15.2592 8.29634 15.7036 8.29634ZM8.29622 8.29634C8.74066 8.29634 9.03696 8.00004 9.03696 7.5556V6.07411C9.03696 5.70374 8.74066 5.33337 8.29622 5.33337C7.85177 5.33337 7.55547 5.70374 7.55547 6.07411V7.5556C7.55547 8.00004 7.85177 8.29634 8.29622 8.29634ZM17.9258 6.81486H17.1851V7.5556C17.1851 8.37041 16.5184 9.03708 15.7036 9.03708C14.8888 9.03708 14.2221 8.37041 14.2221 7.5556V6.81486H9.7777V7.5556C9.7777 8.37041 9.11103 9.03708 8.29622 9.03708C7.4814 9.03708 6.81473 8.37041 6.81473 7.5556V6.81486H6.07399C5.70362 6.81486 5.33325 7.18523 5.33325 7.5556V17.926C5.33325 18.2963 5.70362 18.6667 6.07399 18.6667H17.9258C18.2962 18.6667 18.6666 18.2963 18.6666 17.926V7.5556C18.6666 7.18523 18.2962 6.81486 17.9258 6.81486ZM9.7777 17.1852H6.81473V14.2223H9.7777V17.1852ZM9.7777 13.4815H6.81473V10.5186H9.7777V13.4815ZM13.4814 17.1852H10.5184V14.2223H13.4814V17.1852ZM13.4814 13.4815H10.5184V10.5186H13.4814V13.4815ZM17.1851 17.1852H14.2221V14.2223H17.1851V17.1852ZM17.1851 13.4815H14.2221V10.5186H17.1851V13.4815Z" />
      )}
      {variant === "medium" && (
        <>
          <path d="M6.014 12.9817C6.01145 12.4255 6.46274 11.979 7.013 11.979C7.56426 11.979 8.014 12.4248 8.014 12.979C8.014 13.5312 7.56621 13.979 7.014 13.979C6.46269 13.979 6.01547 13.5327 6.014 12.9817Z" />
          <path d="M11.014 12.9817C11.0114 12.4255 11.4627 11.979 12.013 11.979C12.5643 11.979 13.014 12.4248 13.014 12.979C13.014 13.5312 12.5662 13.979 12.014 13.979C11.4627 13.979 11.0155 13.5327 11.014 12.9817Z" />
          <path d="M16.014 12.9817C16.0114 12.4255 16.4627 11.979 17.013 11.979C17.5643 11.979 18.014 12.4248 18.014 12.979C18.014 13.5312 17.5662 13.979 17.014 13.979C16.4627 13.979 16.0155 13.5327 16.014 12.9817Z" />
          <path d="M6.014 16.9817C6.01145 16.4255 6.46274 15.979 7.013 15.979C7.56426 15.979 8.014 16.4248 8.014 16.979C8.014 17.5312 7.56621 17.979 7.014 17.979C6.46269 17.979 6.01547 17.5327 6.014 16.9817Z" />
          <path d="M11.014 16.9817C11.0114 16.4255 11.4627 15.979 12.013 15.979C12.5643 15.979 13.014 16.4248 13.014 16.979C13.014 17.5312 12.5662 17.979 12.014 17.979C11.4627 17.979 11.0155 17.5327 11.014 16.9817Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8 1.25C8.41421 1.25 8.75 1.58579 8.75 2V3.25H15.25V2C15.25 1.58579 15.5858 1.25 16 1.25C16.4142 1.25 16.75 1.58579 16.75 2V3.25H19C20.5192 3.25 21.75 4.48079 21.75 6V19C21.75 20.5192 20.5192 21.75 19 21.75H5C3.48079 21.75 2.25 20.5192 2.25 19V6C2.25 4.48079 3.48079 3.25 5 3.25H7.25V2C7.25 1.58579 7.58579 1.25 8 1.25ZM7.25 6V4.75H5C4.30921 4.75 3.75 5.30921 3.75 6V8.25H20.25V6C20.25 5.30921 19.6908 4.75 19 4.75H16.75V6C16.75 6.41421 16.4142 6.75 16 6.75C15.5858 6.75 15.25 6.41421 15.25 6V4.75H8.75V6C8.75 6.41421 8.41421 6.75 8 6.75C7.58579 6.75 7.25 6.41421 7.25 6ZM3.75 19V9.75H20.25V19C20.25 19.6908 19.6908 20.25 19 20.25H5C4.30921 20.25 3.75 19.6908 3.75 19Z"
          />
        </>
      )}
      {variant === "large" && (
        <>
          <path d="M4.5 14.25C4.91421 14.25 5.25 13.9142 5.25 13.5C5.25 13.0858 4.91421 12.75 4.5 12.75C4.08579 12.75 3.75 13.0858 3.75 13.5C3.75 13.9142 4.08579 14.25 4.5 14.25Z" />
          <path d="M5.25 16.5C5.25 16.9142 4.91421 17.25 4.5 17.25C4.08579 17.25 3.75 16.9142 3.75 16.5C3.75 16.0858 4.08579 15.75 4.5 15.75C4.91421 15.75 5.25 16.0858 5.25 16.5Z" />
          <path d="M4.5 20.25C4.91421 20.25 5.25 19.9142 5.25 19.5C5.25 19.0858 4.91421 18.75 4.5 18.75C4.08579 18.75 3.75 19.0858 3.75 19.5C3.75 19.9142 4.08579 20.25 4.5 20.25Z" />
          <path d="M17.25 10.5C17.25 10.9142 16.9142 11.25 16.5 11.25C16.0858 11.25 15.75 10.9142 15.75 10.5C15.75 10.0858 16.0858 9.75 16.5 9.75C16.9142 9.75 17.25 10.0858 17.25 10.5Z" />
          <path d="M16.5 14.25C16.9142 14.25 17.25 13.9142 17.25 13.5C17.25 13.0858 16.9142 12.75 16.5 12.75C16.0858 12.75 15.75 13.0858 15.75 13.5C15.75 13.9142 16.0858 14.25 16.5 14.25Z" />
          <path d="M17.25 16.5C17.25 16.9142 16.9142 17.25 16.5 17.25C16.0858 17.25 15.75 16.9142 15.75 16.5C15.75 16.0858 16.0858 15.75 16.5 15.75C16.9142 15.75 17.25 16.0858 17.25 16.5Z" />
          <path d="M16.5 20.25C16.9142 20.25 17.25 19.9142 17.25 19.5C17.25 19.0858 16.9142 18.75 16.5 18.75C16.0858 18.75 15.75 19.0858 15.75 19.5C15.75 19.9142 16.0858 20.25 16.5 20.25Z" />
          <path d="M20.25 10.5C20.25 10.9142 19.9142 11.25 19.5 11.25C19.0858 11.25 18.75 10.9142 18.75 10.5C18.75 10.0858 19.0858 9.75 19.5 9.75C19.9142 9.75 20.25 10.0858 20.25 10.5Z" />
          <path d="M19.5 14.25C19.9142 14.25 20.25 13.9142 20.25 13.5C20.25 13.0858 19.9142 12.75 19.5 12.75C19.0858 12.75 18.75 13.0858 18.75 13.5C18.75 13.9142 19.0858 14.25 19.5 14.25Z" />
          <path d="M20.25 16.5C20.25 16.9142 19.9142 17.25 19.5 17.25C19.0858 17.25 18.75 16.9142 18.75 16.5C18.75 16.0858 19.0858 15.75 19.5 15.75C19.9142 15.75 20.25 16.0858 20.25 16.5Z" />
          <path d="M10.5 11.25C10.9142 11.25 11.25 10.9142 11.25 10.5C11.25 10.0858 10.9142 9.75 10.5 9.75C10.0858 9.75 9.75 10.0858 9.75 10.5C9.75 10.9142 10.0858 11.25 10.5 11.25Z" />
          <path d="M11.25 13.5C11.25 13.9142 10.9142 14.25 10.5 14.25C10.0858 14.25 9.75 13.9142 9.75 13.5C9.75 13.0858 10.0858 12.75 10.5 12.75C10.9142 12.75 11.25 13.0858 11.25 13.5Z" />
          <path d="M10.5 17.25C10.9142 17.25 11.25 16.9142 11.25 16.5C11.25 16.0858 10.9142 15.75 10.5 15.75C10.0858 15.75 9.75 16.0858 9.75 16.5C9.75 16.9142 10.0858 17.25 10.5 17.25Z" />
          <path d="M11.25 19.5C11.25 19.9142 10.9142 20.25 10.5 20.25C10.0858 20.25 9.75 19.9142 9.75 19.5C9.75 19.0858 10.0858 18.75 10.5 18.75C10.9142 18.75 11.25 19.0858 11.25 19.5Z" />
          <path d="M7.5 11.25C7.91421 11.25 8.25 10.9142 8.25 10.5C8.25 10.0858 7.91421 9.75 7.5 9.75C7.08579 9.75 6.75 10.0858 6.75 10.5C6.75 10.9142 7.08579 11.25 7.5 11.25Z" />
          <path d="M8.25 13.5C8.25 13.9142 7.91421 14.25 7.5 14.25C7.08579 14.25 6.75 13.9142 6.75 13.5C6.75 13.0858 7.08579 12.75 7.5 12.75C7.91421 12.75 8.25 13.0858 8.25 13.5Z" />
          <path d="M7.5 17.25C7.91421 17.25 8.25 16.9142 8.25 16.5C8.25 16.0858 7.91421 15.75 7.5 15.75C7.08579 15.75 6.75 16.0858 6.75 16.5C6.75 16.9142 7.08579 17.25 7.5 17.25Z" />
          <path d="M8.25 19.5C8.25 19.9142 7.91421 20.25 7.5 20.25C7.08579 20.25 6.75 19.9142 6.75 19.5C6.75 19.0858 7.08579 18.75 7.5 18.75C7.91421 18.75 8.25 19.0858 8.25 19.5Z" />
          <path d="M13.5 11.25C13.9142 11.25 14.25 10.9142 14.25 10.5C14.25 10.0858 13.9142 9.75 13.5 9.75C13.0858 9.75 12.75 10.0858 12.75 10.5C12.75 10.9142 13.0858 11.25 13.5 11.25Z" />
          <path d="M14.25 13.5C14.25 13.9142 13.9142 14.25 13.5 14.25C13.0858 14.25 12.75 13.9142 12.75 13.5C12.75 13.0858 13.0858 12.75 13.5 12.75C13.9142 12.75 14.25 13.0858 14.25 13.5Z" />
          <path d="M13.5 17.25C13.9142 17.25 14.25 16.9142 14.25 16.5C14.25 16.0858 13.9142 15.75 13.5 15.75C13.0858 15.75 12.75 16.0858 12.75 16.5C12.75 16.9142 13.0858 17.25 13.5 17.25Z" />
          <path d="M14.25 19.5C14.25 19.9142 13.9142 20.25 13.5 20.25C13.0858 20.25 12.75 19.9142 12.75 19.5C12.75 19.0858 13.0858 18.75 13.5 18.75C13.9142 18.75 14.25 19.0858 14.25 19.5Z" />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22.122 24H1.878C0.843 24 0 23.1578 0 22.122V3.37875C0 2.343 0.843 1.5 1.87875 1.5H3.75V0.75C3.75 0.336 4.086 0 4.5 0C4.914 0 5.25 0.336 5.25 0.75V1.5H18.75V0.75C18.75 0.336 19.0853 0 19.5 0C19.9147 0 20.25 0.336 20.25 0.75V1.5H22.1213C23.157 1.5 24 2.343 24 3.37875V22.122C24 23.1578 23.1578 24 22.122 24ZM22.5 6H1.5V3.37875C1.5 3.17025 1.67025 3 1.87875 3H3.75V3.75C3.75 4.164 4.086 4.5 4.5 4.5C4.914 4.5 5.25 4.164 5.25 3.75V3H18.75V3.75C18.75 4.164 19.0853 4.5 19.5 4.5C19.9147 4.5 20.25 4.164 20.25 3.75V3H22.1213C22.3298 3 22.5 3.17025 22.5 3.37875V6ZM22.5 7.5H1.5V22.122C1.5 22.3305 1.6695 22.5 1.878 22.5H22.122C22.3305 22.5 22.5 22.3305 22.5 22.122V7.5Z"
          />
        </>
      )}
    </svg>
  );
};

export default Calendar;

import { getAnalytics, logEvent, setUserId, setUserProperties } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { browserSessionPersistence, initializeAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, initializeFirestore, onSnapshot, query, runTransaction, setDoc, updateDoc, where } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getDownloadURL, getStorage, ref, uploadBytes, deleteObject, listAll } from "firebase/storage";
import { generateFirebaseConfig } from "./utils";
/**
 * Handles connections to firebase.
 * firebase, fireStorage, firestore
 */
class MadFire {
    constructor(config) {
        this.firebase = initializeApp(generateFirebaseConfig(config.projectId), config.name);
        this.config = config;
        this.fireStorage = getStorage(initializeApp(generateFirebaseConfig(config.storageId), config.name ? `${config.name}-mad-data` : "mad-data"));
        this.dropStorage = getStorage(this.firebase, `gs://${config.projectId}.appspot.com`);
        this.persistence = config.persistence || browserSessionPersistence;
    }
    /**
     * Used to only get an instance of firestore if one is need
     * when it is needed.
     */
    get firestore() {
        if (!this.firestoreInstance) {
            this.firestoreInstance = initializeFirestore(this.firebase, {
                experimentalForceLongPolling: true
            });
        }
        return this.firestoreInstance;
    }
    get projectId() {
        return this.config.projectId;
    }
    get functions() {
        return getFunctions(this.firebase);
    }
    get analytics() {
        if (!this.analyticsInstance) {
            this.analyticsInstance = getAnalytics(this.firebase);
        }
        return this.analyticsInstance;
    }
    /**
     * When using firebase authorization you need to initalize
     * an Auth object. This can be done with getAuth from
     * the firebsae UI but then browser session persistence
     * doesn't work. To avoid this we can use initializeAuth
     * but this can only be called once per session thus why
     * we treat it like a singleton and only call it the first
     * time getAuth is called and after that return the already
     * initialized Auth.
     * @returns initialized firebase Auth
     */
    getAuth() {
        if (this.authorization) {
            return this.authorization;
        }
        this.authorization = initializeAuth(this.firebase, {
            persistence: this.persistence
        });
        return this.authorization;
    }
    async signInWithPersistence(email, password) {
        return signInWithEmailAndPassword(this.getAuth(), email, password);
    }
    /**
     * temporary exposure to help integration
     * @deprecated.
     */
    // eslint-disable-next-line class-methods-use-this
    get storage() {
        return getStorage(this.firebase);
    }
    getCollection(path, ...pathSegments) {
        if (pathSegments) {
            return collection(this.firestore, path, ...pathSegments);
        }
        return collection(this.firestore, path);
    }
    getDocRef(path, id) {
        return doc(this.getCollection(path), id);
    }
    async getDocument(path, id) {
        return getDoc(this.getDocRef(path, id));
    }
    async getDocuments(path, id, ...pathSegments) {
        return getDocs(this.getCollection(path, id, ...pathSegments));
    }
    onAuthStateChanged(nextOrObserver, error, completed) {
        const auth = this.getAuth();
        return onAuthStateChanged(auth, nextOrObserver, error, completed);
    }
    query(path, ...clauses) {
        const wheres = clauses.map((whereCondition) => where(whereCondition.fieldPath, whereCondition.opStr, whereCondition.value));
        return query(this.getCollection(path), ...wheres);
    }
    async queryDocuments(path, ...clauses) {
        return getDocs(this.query(path, ...clauses));
    }
    getDownloadUrl(path, bucketUrl) {
        if (bucketUrl) {
            return getDownloadURL(ref(getStorage(this.firebase, bucketUrl), path));
        }
        return getDownloadURL(ref(this.storage, path));
    }
    getDownloadUrlMadData(path) {
        return getDownloadURL(ref(this.fireStorage, path));
    }
    uploadToStorage(path, file) {
        return uploadBytes(ref(this.fireStorage, path), file);
    }
    uploadToDropStorage(path, file) {
        return uploadBytes(ref(this.dropStorage, path), file);
    }
    uploadToCustomStorage(path, file, bucketName) {
        return uploadBytes(ref(getStorage(this.firebase, `gs://${bucketName}`), path), file);
    }
    /**
     * @param path path to the directory to delete
     * @param bucketName name of the bucket to delete from
     * Firebase storage does not have a way to delete a directory via the
     * browser SDK, but deleting all the files in a directory will delete the
     * directory itself.
     * */
    deleteDirectoryFromCustomStorage(path, bucketName) {
        return listAll(ref(getStorage(this.firebase, `gs://${bucketName}`), path)).then((res) => {
            res.items.forEach((item) => {
                deleteObject(item);
            });
        });
    }
    onSnapshot(path, clauses, onNext, onError) {
        const wheres = clauses.map((whereCondition) => where(whereCondition.fieldPath, whereCondition.opStr, whereCondition.value));
        return onSnapshot(query(this.getCollection(path), ...wheres), onNext, onError);
    }
    setDoc(path, id, data, options) {
        if (options) {
            return setDoc(this.getDocRef(path, id), data, options);
        }
        return setDoc(this.getDocRef(path, id), data);
    }
    updateDoc(path, id, data) {
        return updateDoc(this.getDocRef(path, id), data);
    }
    addDoc(path, data) {
        return addDoc(this.getCollection(path), data);
    }
    deleteDoc(path, id) {
        return deleteDoc(this.getDocRef(path, id));
    }
    // returns any because that's what the underlying firestore function does
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    runTransaction(updateFunction) {
        return runTransaction(this.firestore, updateFunction);
    }
    // The following functions need to be moved to the correct collections
    findZipCodesWithinRadius(coords, radius) {
        const findZipCodesWithinRadiusFunction = httpsCallable(this.functions, "findZipCodesWithinRadius");
        return findZipCodesWithinRadiusFunction({
            coords: {
                latitude: coords.latitude,
                longitude: coords.longitude
            },
            radius: radius
        });
    }
    logError(error) {
        const logErrorCloudFunction = httpsCallable(this.functions, "logErrors");
        logErrorCloudFunction(error);
    }
    logEvent(name, params) {
        logEvent(this.analytics, name, params);
    }
    setUserProperties(properties, options) {
        setUserProperties(this.analytics, properties, options);
    }
    setUserId(id) {
        setUserId(this.analytics, id);
    }
}
export default MadFire;

import { omit } from "lodash";
import {
  AdvertisersState,
  AdvertisersActionTypes,
  GET_AVAILABLE_ADVERTISERS_PENDING,
  GET_AVAILABLE_ADVERTISERS_SUCCESS,
  GET_AVAILABLE_ADVERTISERS_FAILURE,
  GET_RUNNING_ADVERTISERS_PENDING,
  GET_RUNNING_ADVERTISERS_SUCCESS,
  GET_RUNNING_ADVERTISERS_FAILURE,
  CREATE_ADVERTISER_SUCCESS,
  UPDATE_ADVERTISER_PENDING,
  UPDATE_ADVERTISER_SUCCESS,
  UPDATE_ADVERTISER_FAILURE,
  ABORT_ADVERTISER_REQUEST
} from "./types";

export const initialAdvertiserState: AdvertisersState = {
  byId: {},
  isAllLoading: false,
  isRunningLoading: false,
  runningAdvertisers: {},
  updatingById: {},
  creatingByRequestId: {},
  receivedAllAt: null
};

export const advertisersReducer = (
  state = initialAdvertiserState,
  action: AdvertisersActionTypes
): AdvertisersState => {
  switch (action.type) {
    case GET_AVAILABLE_ADVERTISERS_PENDING:
      return {
        ...state,
        isAllLoading: true
      };
    case GET_AVAILABLE_ADVERTISERS_SUCCESS:
      return {
        ...state,
        byId: action.payload,
        isAllLoading: false,
        receivedAllAt: action.meta.timestamp
      };

    case GET_AVAILABLE_ADVERTISERS_FAILURE:
      return {
        ...state,
        isAllLoading: false
      };
    case GET_RUNNING_ADVERTISERS_PENDING:
      return {
        ...state,
        isRunningLoading: true
      };
    case GET_RUNNING_ADVERTISERS_SUCCESS:
      return {
        ...state,
        runningAdvertisers: action.payload,
        isRunningLoading: false
      };

    case GET_RUNNING_ADVERTISERS_FAILURE:
      return {
        ...state,
        isRunningLoading: false
      };
    case UPDATE_ADVERTISER_PENDING:
      return {
        ...state,
        updatingById: {
          ...state.updatingById,
          [action.meta.advertiser.id]: action.meta.advertiser
        }
      };
    case UPDATE_ADVERTISER_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.payload.id]: action.payload },
        updatingById: omit(state.updatingById, action.payload.id)
      };
    case UPDATE_ADVERTISER_FAILURE:
      return {
        ...state,
        updatingById: omit(state.updatingById, action.meta.id)
      };
    case CREATE_ADVERTISER_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, [action.payload.id]: action.payload },
        creatingByRequestId: omit(
          state.creatingByRequestId,
          action.meta.requestId
        )
      };
    case ABORT_ADVERTISER_REQUEST:
      return {
        ...state,
        isAllLoading: false
      };
    default:
      return state;
  }
};

import { PromiseHandler } from "./promise";
/**
 * Abstract class that all UX config handlers should extend.
 *
 * supports the same methods as PromiseHandler, plus easy lookup of user-specific configuration properties.
 */
export class UXConfigHandler extends PromiseHandler {
    constructor(sdk, type, cacheConfig) {
        super(sdk, `ux-${type}`, cacheConfig);
        /** Access to UX configs as they are for a particular user. */
        this.configured = {
            /**
             * @param key: the lookup key of the particular UX property.
             * @param user: optional. The user to get the configured setting for. Defaults to the current user.
             * @return: true if the user has a configuration for that key. Else, false.
             */
            has: (key, user) => {
                return (typeof (user || this.sdk.getCurrentUser())?.settings?.[this.type]?.[key] !== "undefined");
            },
            /**
             * @param key: the lookup key of the particular UX property.
             * @param user: optional. The user to get the configured setting for. Defaults to the current user.
             * @return: the property, if the user has a configuration for that key. Else, undefined.
             */
            get: (key, user) => {
                return (user || this.sdk.getCurrentUser())?.settings?.[this.type]?.[key];
            }
        };
        this.type = type;
    }
}

import { User } from "@madhive/mad-sdk";

export const LOGIN_FIREBASE_VIA_TOKEN_PENDING =
  "LOGIN_FIREBASE_VIA_TOKEN_PENDING";
export const LOGIN_USER_PENDING = "LOGIN_USER_PENDING";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const LOGIN_USER_FAILURE = "LOGIN_USER_FAILURE";
export const LOGOUT_USER = "LOGOUT_USER";
export const MASQUERADE_USER = "MASQUERADE_USER";
export const MASK_OFF_USER = "MASK_OFF_USER";

export interface AuthState {
  readonly actualUser: User | null;
  readonly masqueradingUser: User | null;
  readonly isLoading: boolean;
  readonly initializationError: string | null;
  readonly loginRequestTimestamp: number | null;
  readonly loginSuccessTimestamp: number | null;
}

interface LoginFirebaseViaTokenPendingAction {
  type: typeof LOGIN_FIREBASE_VIA_TOKEN_PENDING;
}

interface LoginPendingUserAction {
  type: typeof LOGIN_USER_PENDING;
  meta: {
    timestamp: number;
  };
}

interface LoginSuccessUserAction {
  type: typeof LOGIN_USER_SUCCESS;
  payload: User;
  meta: {
    timestamp: number;
  };
}

interface LoginFailureUserAction {
  type: typeof LOGIN_USER_FAILURE;
  meta: {
    error: string;
  };
}

interface LogoutUserAction {
  type: typeof LOGOUT_USER;
}

interface MasqueradeUserAction {
  type: typeof MASQUERADE_USER;
  payload: User;
}

interface MaskOffUserAction {
  type: typeof MASK_OFF_USER;
}

export type AuthActionTypes =
  | LoginFirebaseViaTokenPendingAction
  | LoginPendingUserAction
  | LoginSuccessUserAction
  | LoginFailureUserAction
  | LogoutUserAction
  | MasqueradeUserAction
  | MaskOffUserAction;

import { UploadedFileResultsLegacy } from "@madhive/mad-sdk";
import { ServeType } from "lib/constants/creatives";

export const GET_UPLOAD_ASSET_PENDING = "GET_UPLOAD_ASSET_PENDING";
export const GET_UPLOAD_ASSET_SUCCESS = "GET_UPLOAD_ASSET_SUCCESS";
export const GET_UPLOAD_ASSET_FAILURE = "GET_UPLOAD_ASSET_FAILURE";
export const GET_INITIAL_FILE_INFO_UPDATE = "GET_INITIAL_FILE_INFO_UPDATE";
export const GET_BYTES_TRANSFERRED_UPDATE = "GET_BYTES_TRANSFERRED_UPDATE";

export const GET_RESET_ASSET_UPLOAD_STATE = "GET_RESET_ASSET_UPLOAD_STATE";
export const GET_UPLOAD_ASSET_VALIDATION_FAILURE =
  "GET_UPLOAD_ASSET_VALIDATION_FAILURE";
export const SET_VALIDATED_THIRD_PARTY_TAG = "SET_VALIDATED_THIRD_PARTY_TAG";
export const GET_VALIDATE_AND_PARSE = "GET_VALIDATE_AND_PARSE";
export const SET_SERVE_TYPE = "SET_SERVE_TYPE";
export const SET_RESET_UPLOADED_ASSET = "SET_RESET_UPLOADED_ASSET";
export interface InitialFileInfo {
  name: string;
  fileSize: number;
}

export interface UploadAssetState {
  readonly isUploading: boolean;
  readonly isSaving: boolean;
  readonly fatalUploadError: string | null;
  readonly uploadedAsset: UploadedFileResultsLegacy | undefined;
  readonly initialFileInfo: InitialFileInfo | undefined;
  readonly bytesTransferred: number;
  readonly validatedThirdPartyTag: string | undefined;
  readonly serveType: ServeType | undefined;
  readonly isVast: boolean;
  readonly isTag: boolean;
}

interface GetUploadAssetPendingAction {
  type: typeof GET_UPLOAD_ASSET_PENDING;
}

interface GetUploadAssetSuccessAction {
  type: typeof GET_UPLOAD_ASSET_SUCCESS;
  payload: UploadedFileResultsLegacy;
}

interface GetUploadAssetFailureAction {
  type: typeof GET_UPLOAD_ASSET_FAILURE;
  meta: {
    error: string;
  };
}

interface GetBytesTransferredAction {
  type: typeof GET_BYTES_TRANSFERRED_UPDATE;
  payload: number;
}

interface GetInitialInfoUpdateAction {
  type: typeof GET_INITIAL_FILE_INFO_UPDATE;
  payload: InitialFileInfo | undefined;
}

interface GetAssetUploadReset {
  type: typeof GET_RESET_ASSET_UPLOAD_STATE;
}

interface SetValidatedThirdPartyTag {
  type: typeof SET_VALIDATED_THIRD_PARTY_TAG;
  payload: string | undefined;
}
interface GetValidateAndParse {
  type: typeof GET_VALIDATE_AND_PARSE;
}

interface SetServeType {
  type: typeof SET_SERVE_TYPE;
  payload: ServeType | undefined;
}

interface SetResetUploadedAsset {
  type: typeof SET_RESET_UPLOADED_ASSET;
}

export type UploadAssetActionTypes =
  | GetUploadAssetPendingAction
  | GetUploadAssetSuccessAction
  | GetUploadAssetFailureAction
  | GetBytesTransferredAction
  | GetInitialInfoUpdateAction
  | GetAssetUploadReset
  | SetValidatedThirdPartyTag
  | SetServeType
  | GetValidateAndParse
  | SetResetUploadedAsset;

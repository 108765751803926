import { Segment } from "@madhive/mad-sdk";
import { selectAllDmpsById } from "appReducers/dmpReducer";
import { selectCurrentOrganization } from "appReducers/organizationsReducer";
import { pickBy } from "lodash";
import { MADHIVE_DMP_ID } from "madhive/components/ExpressionBuilder/types";
import { createSelector } from "reselect";
import { RootState } from "rootReducer";
import { ServingStatus } from "types";

export const selectIsAllDmpSegmentsLoading = (state: RootState) =>
  state.dmpSegments.isAllLoading;

export const selectIsAllDmpSegmentsLoaded = (state: RootState) =>
  !!state.dmpSegments.receivedAllAt;

export const selectAllActiveDmpSegmentsById = (state: RootState) =>
  pickBy(
    state.dmpSegments.byId,
    (segment: Segment) => segment.status === ServingStatus.READY
  );

export const selectAllDmpSegmentsIncludingArchivedById = (state: RootState) =>
  state.dmpSegments.byId;

export const selectAllDmpSegments = createSelector(
  selectAllDmpSegmentsIncludingArchivedById,
  dmpSegmentsById => Object.values(dmpSegmentsById)
);

export const selectRetargetingSegmentsById = (state: RootState) =>
  pickBy(
    state.dmpSegments.byId,
    (segment: Segment) =>
      // TO-DO: When 'segment.parent === MADHIVE_DMP_ID', the segment is an
      // 'FBR' segment, which is a subcategory of retargeting segment. We
      // should have a distinction between conventional retargeting segments
      // and 'FBR' segments in our code.
      "retargetingType" in segment || segment.parent === MADHIVE_DMP_ID
  );

export const selectFirstPartySegmentsById = createSelector(
  selectAllDmpsById,
  selectAllDmpSegmentsIncludingArchivedById,
  selectCurrentOrganization,
  (dmpsById, segments, org) => {
    if (!org) {
      return {};
    }
    const orgDmp = Object.values(dmpsById).find(dmp => dmp.id === org.id);
    if (!orgDmp) {
      return {};
    }
    return pickBy(
      segments,
      segment => !("retargetingType" in segment) && segment.parent === orgDmp.id
    );
  }
);

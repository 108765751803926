import axios from "axios";
import { toStartCase } from "../../utils";
import { NotImplementedError } from "../../errors";
import { Handler } from "../handlers";
import { ParameterFetchFailed } from "./errors";
/**
 * `Parameters` knows how to get the params for the type assigned at construction time (e.g., metrics).
 */
export class Parameters extends Handler {
    /**
     * @param sdk: a reference to the top-level SDK object.
     * @param id: the ID of this specifier.
     */
    constructor(sdk, type) {
        super(sdk, `statistics-${type}`, { atomize: true });
        this.url = `${this.sdk.urls.advancedQueryToolBaseUrl}/v1/${type}`;
        this.type = type;
    }
    findItems() {
        return this.cache.promise(this.url, () => axios
            .get(this.url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: ids }) => ids.map((id) => this.toParameter(id)))
            .catch((error) => {
            throw new ParameterFetchFailed(error, this.type);
        }));
    }
    /**
     * @param id: some parameter ID.
     * @return: the parameter, in its fully-fledged format.
     */
    toParameter(id) {
        return {
            id,
            label: toStartCase(id),
            type: this.type
        };
    }
    saveItem() {
        throw new NotImplementedError("save");
    }
    deleteItem() {
        throw new NotImplementedError("delete");
    }
    /**
     * @deprecated
     * `make` is not supported.
     */
    make() {
        throw new NotImplementedError("make");
    }
}

import {
  SHOW_TOAST,
  HIDE_TOAST,
  CLEAR_TOASTS,
  ToastOptionsType,
  ToastActionTypes
} from "./types";

const initialState = [] as ToastOptionsType[];

const ToastReducer = (
  state: ToastOptionsType[] = initialState,
  action: ToastActionTypes
) => {
  switch (action.type) {
    case SHOW_TOAST:
      const { type, ...rest } = action;
      return [
        ...state,
        {
          ...rest
        }
      ];
    case HIDE_TOAST:
      return state.filter(notification => notification.uid !== action.uid);
    case CLEAR_TOASTS:
      return [];
    default:
      return state;
  }
};

export { initialState, ToastReducer };

import { isNil } from "lodash";

export const formatNumber = (value: string | number) => {
  const n = +value;
  return Number.isFinite(n)
    ? n.toFixed(0).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : "--";
};
export const formatNumberWithPadding = (value: string | number, len = 2) => {
  const n = +value;
  // eslint-disable-next-line no-restricted-globals
  if (typeof n !== "number" || isNaN(n)) {
    return "--";
  }

  const valueStr = `${n}`;
  return valueStr.length >= len
    ? valueStr
    : new Array(len - valueStr.length + 1).join("0") + valueStr;
};
export const formatAsPercent = (
  value: string | number,
  precision: number = 2
) => {
  const n = +value;
  // eslint-disable-next-line no-restricted-globals
  return typeof n !== "number" || isNaN(n) || n === Infinity || n === -Infinity
    ? "--"
    : `${n.toFixed(precision)}%`;
};

export const isValidNumber = (n: any): n is number => Number.isFinite(n);

export const getGcd = (a: number, b: number): number => {
  const formatA = Math.abs(Math.floor(a));
  const formatB = Math.abs(Math.floor(b));
  if (b === 0) {
    return formatA;
  }
  return getGcd(formatB, formatA % formatB);
};

export const getAspectRatio = (width: number, height: number) => {
  const gcd = getGcd(width, height);
  const aspectRatioWidth = width / gcd;

  const aspectRatioHeight = height / gcd;

  return {
    aspectRatioWidth,
    aspectRatioHeight
  };
};

const getNumberOfZerosAfterDecimalPlace = (value: number): number => {
  const str = value.toString();

  let numberOfZeros = 0;
  if (str.includes(".")) {
    const fractional = str.split(".")[1];

    for (let index = 0; index < fractional.length; index++) {
      if (+fractional[index] > 0) {
        break;
      }
      // eslint-disable-next-line no-plusplus
      numberOfZeros++;
    }
  }

  return numberOfZeros;
};

export const formatToNearestPrecision = (
  value: number,
  displayPrecisionAfterZeros: number = 2
): string =>
  value.toFixed(
    displayPrecisionAfterZeros + getNumberOfZerosAfterDecimalPlace(value)
  );

export const formatNumberToPrecision = ({
  value,
  precision = 2,
  wholeNumberWithoutPrecision,
  asPercent
}: {
  value?: string | number;
  precision?: number;
  wholeNumberWithoutPrecision?: boolean;
  asPercent?: boolean;
}): string => {
  const numberValue = Number(value);

  if (typeof value === "string" && Number.isNaN(numberValue)) {
    // when value is a string
    return value;
  }

  if (isNil(value) || Number.isNaN(numberValue)) {
    return "--";
  }

  // precision 0 if value does not have decimals and 2 when they do
  const toPrecision =
    wholeNumberWithoutPrecision && Number(numberValue.toFixed(2)) % 1 === 0
      ? 0
      : precision;

  const formattedNumber = Number(
    numberValue.toFixed(toPrecision)
  ).toLocaleString("en-US", {
    minimumFractionDigits: toPrecision,
    maximumFractionDigits: toPrecision
  });

  return asPercent ? `${formattedNumber}%` : formattedNumber;
};

import { CancelTokenSource } from "axios";
import { IToastProps } from "@blueprintjs/core";
import { AudienceSegment } from "types";

export const GET_ALL_SEGMENTS_PENDING = "GET_ALL_SEGMENTS_PENDING";
export const GET_ALL_SEGMENTS_FAILURE = "GET_ALL_SEGMENTS_FAILURE";
export const GET_ALL_SEGMENTS_SUCCESS = "GET_ALL_SEGMENTS_SUCCESS";

export const ABORT_GET_ALL_SEGMENTS_REQUEST = "ABORT_GET_ALL_SEGMENTS_REQUEST";

export interface DeprecatedSegmentsState {
  readonly byId: Record<string, AudienceSegment>;
  readonly isAllLoading: boolean;
  readonly loadAllCancellationSource: CancelTokenSource | null;
  readonly receivedAllAt: null | number;
}

interface DeprecatedGetAllSegmentsPendingAction {
  type: typeof GET_ALL_SEGMENTS_PENDING;
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

interface DeprecatedGetAllSegmentsFailureAction {
  type: typeof GET_ALL_SEGMENTS_FAILURE;
  meta: {
    error: {
      message: string;
    } & IToastProps;
  };
}

interface DeprecatedGetAllSegmentsSuccessAction {
  type: typeof GET_ALL_SEGMENTS_SUCCESS;
  payload: AudienceSegment[];
  meta: {
    timestamp: number;
  };
}

interface DeprecatedAbortGetAllSegmentsRequestAction {
  type: typeof ABORT_GET_ALL_SEGMENTS_REQUEST;
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

export type SegmentsActionTypes =
  | DeprecatedGetAllSegmentsPendingAction
  | DeprecatedGetAllSegmentsSuccessAction
  | DeprecatedGetAllSegmentsFailureAction
  | DeprecatedAbortGetAllSegmentsRequestAction;

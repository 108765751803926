import { useNotificationListener } from "hooks/notificationListener";

import { SmithersNotificationFormatted } from "types";
import { useSelector } from "react-redux";
import { RootState } from "rootReducer";
import {
  createContext,
  useContext,
  FC,
  useMemo,
  PropsWithChildren
} from "react";

interface NotificationDataContextValue {
  firestoreNotifications: SmithersNotificationFormatted[];
}

export const NotificationDataContext = createContext<
  NotificationDataContextValue | undefined
>(undefined);

export const useNotificationsData = () => {
  const contextValue = useContext(NotificationDataContext);

  if (!contextValue) {
    throw new Error(
      "You tried to use NotificationDataContext in a component that is not a descendant of a provider. To fix this, render the NotificationDataProvider.Provider somewhere above this component in the tree."
    );
  }

  return contextValue;
};

const NotificationsDataProvider: FC<PropsWithChildren> = props => {
  const selectUser = (state: RootState) => state.auth.actualUser;
  const user = useSelector(selectUser);

  const { firestoreNotifications } = useNotificationListener(user);

  const contextValueMemoized = useMemo(
    (): NotificationDataContextValue => ({
      firestoreNotifications
    }),
    [firestoreNotifications]
  );

  return (
    <NotificationDataContext.Provider value={contextValueMemoized}>
      {props.children}
    </NotificationDataContext.Provider>
  );
};

export default NotificationsDataProvider;

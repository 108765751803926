import { CancelTokenSource } from "axios";
import { GeographicEntityTypes } from "lib/utils/geography";
import { LngLatGeoJson } from "types";

export interface PolygonWithId {
  id: string;
  groupingId: string;
  geometry: PolygonGeometry;
  geoType: GeographicEntityTypes;
}

export interface PolygonGeometry {
  lat: number;
  lng: number;
  polygonPoints: LngLatGeoJson[] | LngLatGeoJson[][];
  multiPolygonPoints?: LngLatGeoJson[][][];
}

export const GET_POLYGONS_PENDING = "GET_POLYGONS_PENDING";
export const GET_POLYGONS_SUCCESS = "GET_POLYGONS_SUCCESS";
export const GET_POLYGONS_FAILURE = "GET_POLYGONS_FAILURE";
export const ABORT_POLYGONS_REQUEST = "ABORT_POLYGONS_REQUEST";
export const CLEAR_POLYGON_ERROR = "CLEAR_POLYGON_ERROR";

export interface PolygonState {
  readonly polygons: PolygonWithId[];
  readonly errorPolygonIds: string[];
  readonly loadingPolygonIds: string[];
  readonly idsForPolygonsWithNoData: string[];
  readonly activeCancelTokenSources: Array<CancelTokenSource>;
  readonly isLoading: boolean;
  readonly error: { message: string | null };
}

interface GetPolygonsPendingAction {
  type: typeof GET_POLYGONS_PENDING;
  payload: string[];
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

interface GetPolygonsSuccessAction {
  type: typeof GET_POLYGONS_SUCCESS;
  payload: {
    success: PolygonWithId[];
    failure: string[];
  };
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

interface GetPolygonsFailureAction {
  type: typeof GET_POLYGONS_FAILURE;
  payload: string[];
  meta: {
    error: { message: string };
    cancellationSource: CancelTokenSource;
  };
}

interface AbortPolygonsRequestAction {
  type: typeof ABORT_POLYGONS_REQUEST;
  payload: string[];
  meta: {
    cancellationSource: CancelTokenSource;
  };
}

interface ClearPolygonErrorAction {
  type: typeof CLEAR_POLYGON_ERROR;
}

export type PolygonsActionTypes =
  | GetPolygonsPendingAction
  | GetPolygonsSuccessAction
  | GetPolygonsFailureAction
  | AbortPolygonsRequestAction
  | ClearPolygonErrorAction;

import {
  AdbookCampaignsActionTypes,
  AdbookCampaignsState,
  GET_ADBOOK_CAMPAIGNS_FAILURE,
  GET_ADBOOK_CAMPAIGNS_PENDING,
  GET_ADBOOK_CAMPAIGNS_SUCCESS
} from "./types";

export const initialState: AdbookCampaignsState = {
  byId: {},
  isLoading: false,
  /* boolean indicating if ever loaded -- useful for some loading screen logic */
  isLoaded: false,
  error: null,
  firstFetchFinished: false
};

export const adbookReducer = (
  state = initialState,
  action: AdbookCampaignsActionTypes
): AdbookCampaignsState => {
  switch (action.type) {
    case GET_ADBOOK_CAMPAIGNS_PENDING:
      return {
        ...state,
        isLoading: true
      };
    case GET_ADBOOK_CAMPAIGNS_SUCCESS: {
      return {
        ...state,
        // Given we want to show all drop id errors and some do not have dropIds
        // this is an okay implementation until we find out from product how we want to take care of scenarios
        // where there are duplicate campaign ids with different error messages
        byId: action.payload.reduce((byId, adbookCampaign) => {
          // Only doing a check here for if campaignId doesn't exist since first error is what the user should be seeing
          // (when there are duplicate campaignIds with one error being readable vs. server error msg)
          if (!byId[adbookCampaign.campaignID]) {
            // eslint-disable-next-line no-param-reassign
            byId[
              adbookCampaign.dropID
                ? adbookCampaign.dropID
                : adbookCampaign.campaignID
            ] = adbookCampaign;
          }
          return byId;
        }, {}),
        isLoading: false,
        isLoaded: true,
        error: null,
        firstFetchFinished: true
      };
    }
    case GET_ADBOOK_CAMPAIGNS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.meta.error
      };
    default:
      return state;
  }
};

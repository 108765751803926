import { Finder } from "../operators";
import { ObservableBuilder } from "./base";
/**
 * FindObservableBuilder knows how to create a fluent observable specifically for find calls,
 * allowing additional changes to filtering, etc. via method chaining, alongside the usual `.subscribe`, etc. from `Observable`.
 * @template {ReturnType}: the type of object returned from the promise.
 * @template {FilterType}: the shape of allowed filters.
 * @template {SortType}: the shape of an allowed sort field.
 * @template {HydrateType}: the shape of allowed hydrated fields.
 */
export class FindObservableBuilder extends ObservableBuilder {
    /**
     * @param identifierKey: the key into the UniqueType which uniquely identifies the object.
     * @param filters: any set of filters provided to the find.
     * @param hydratedFields: any set of hydrated fields provided to the find.
     * @param execute: what to actually do in order to make a `find` happen.
     */
    constructor(config) {
        const { identifierKey, filters = {}, sort, hydratedFields = [], execute } = config;
        super(() => execute({
            filters: this.finder.filters,
            hydratedFields: this.finder.hydratedFields,
            sort: this.finder.sortField
        }));
        this.finder = new Finder(identifierKey, filters, sort, new Set(hydratedFields));
    }
    byId(id, options) {
        this.finder.byId(id, options);
        return this;
    }
    filter(input, options) {
        this.finder.filter(input, options);
        return this;
    }
    sort(input, options) {
        this.finder.sort(input, options);
        return this;
    }
    hydrate(hydratedFields, options) {
        this.finder.hydrate(hydratedFields, options);
        return this;
    }
}

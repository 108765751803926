class GenericGeoDetail {
    constructor() {
        this._data = {};
        this._values = [];
    }
    add(item) {
        this._data[this.lookupId(item)] = item;
        this._values.push(this.lookupId(item));
    }
    get(id) {
        return this._data[id];
    }
    has(id) {
        return this._data[id] !== undefined;
    }
    get data() {
        return Object.values(this._data);
    }
    get values() {
        return this._values;
    }
}
export class GeoDetailByCode extends GenericGeoDetail {
    lookupId(item) {
        return item.code;
    }
}
export class GeoDetailById extends GenericGeoDetail {
    lookupId(item) {
        return item.id;
    }
}

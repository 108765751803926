import { useMemo, useState, useCallback } from "react";
import { useDeepCompareEffect } from "../utils";
export const DEFAULT_PAGE_SIZE = 10;
/**
 * Builds first page of a pagination filter; effectively resets pagination to page 1
 */
const buildFirstPage = (pageSize = DEFAULT_PAGE_SIZE) => ({
    token: undefined,
    size: pageSize,
    offset: 0
});
/**
 * derives whether next page request is a request for incrementation, decrementation, or neither
 */
const getOffset = (currentPage, newPage) => {
    if (newPage > currentPage) {
        return 1;
    }
    if (newPage < currentPage) {
        return -1;
    }
    return 0;
};
const refreshState = (options, size) => ({
    page: {
        number: 0,
        count: 0,
        token: undefined
    },
    filters: {
        ...options.filters,
        ...(options.paginate ? { paging: buildFirstPage(size || options.initialPageSize) } : {})
    },
    sort: options.sort
});
/**
 * Loads paginated list data with optional sort and 'where' filters.
 * Pagination filters are derived and maintained internally.
 * @param collection Collection the data belongs to
 * @param options Optional 'where' filters and sort to apply to madSDK
 * @returns ListData of type from madSDK collection
 */
export const usePagination = (options) => {
    const { paginate } = options;
    const [state, setState] = useState(refreshState(options));
    useDeepCompareEffect(() => {
        setState((prev) => ({
            ...refreshState(options, state.filters.paging?.size),
            // retain sort when filters change
            sort: prev.sort
        }));
    }, [options.filters]);
    const onStalePage = useCallback(() => {
        setState((prev) => ({
            ...prev,
            page: {
                number: 0,
                count: 0,
                token: undefined
            },
            filters: {
                // reset internally managed pagination filter; keep other filters
                ...prev.filters,
                paging: buildFirstPage(prev.filters.paging?.size)
            }
        }));
    }, []);
    const setPageMetadata = useCallback(({ count, token }) => {
        // skip update if nothing's changed
        if (count === state.page.count && token === state.page.token) {
            return;
        }
        setState((prev) => ({
            ...prev,
            // DO NOT update filters here, as it can easily trigger an infinite loop. The token will be stored in state and used for the next API call, but should not trigger this hook's consumers to re-render.
            page: {
                ...prev.page,
                count,
                token
            }
        }));
    }, []);
    const setSort = useCallback((sort) => setState((prev) => ({
        sort,
        filters: {
            // retain page size from previous filters
            ...(options.paginate
                ? { paging: buildFirstPage(prev.filters.paging?.size || options.initialPageSize) }
                : {}),
            // retain non-pagination filters when sorting
            ...(prev.filters.where ? { where: prev.filters.where } : {})
        },
        page: {
            // maintain count from previous state, since sorting doesn't filter data and thus does not change the amount of records
            ...prev.page,
            number: 0,
            token: undefined
        }
    })), []);
    const setPage = useCallback((newPage) => {
        setState((prevState) => {
            const offset = getOffset(prevState.page.number, newPage);
            return {
                ...prevState,
                page: {
                    ...prevState.page,
                    number: prevState.page.number + offset
                },
                filters: {
                    ...prevState.filters,
                    paging: {
                        ...(prevState.filters?.paging || {}),
                        offset,
                        // use the previously stored api token in the api request for the next page of data
                        token: prevState.page.token
                    }
                }
            };
        });
    }, []);
    const setPageSize = useCallback((pageSize) => {
        setState((prevState) => ({
            ...prevState,
            page: {
                ...prevState.page,
                number: 0
            },
            filters: {
                ...prevState.filters,
                // resets pagination
                paging: buildFirstPage(pageSize)
            }
        }));
    }, []);
    return useMemo(() => ({
        filters: state.filters,
        sort: state.sort,
        setSort,
        ...(paginate
            ? {
                pagination: {
                    page: state.page.number,
                    pageSize: state.filters.paging?.size,
                    totalResults: state.page.count,
                    setPage,
                    setPageSize
                }
            }
            : {}),
        setPageMetadata,
        onStalePage
    }), [state.filters, state.sort, state.page.number, state.page.count]);
};

export class Properties {
    constructor(sdk) {
        this.sdk = sdk;
    }
    /**
     * It will find the current user and get the correct property of the current user
     * @param key The key of property to look up
     * @returns null or the property
     */
    getProperty(key, type = "any") {
        const user = this.sdk.getCurrentUser();
        if (user && user.settings) {
            if (type !== "any") {
                return user.settings[type][key];
            }
            else {
                if (user.settings.values && user.settings.values[key]) {
                    return user.settings.values[key];
                }
                else if (user.settings.switches && user.settings.switches[key]) {
                    return user.settings.switches[key];
                }
                else if (user.settings.components && user.settings.components[key]) {
                    return user.settings.components[key];
                }
            }
        }
        return null;
    }
    /**
     * Will return true if a property is on. This can
     * be because nothing is set or that it is set
     * to boolean
     * @param key the key of the property to lookup
     * @return boolean true if the feature is on
     */
    isOn(key) {
        const prop = this.getProperty(key);
        if (prop) {
            if (typeof prop === "boolean") {
                return prop;
            }
            return true;
        }
        return false;
    }
    /**
     * Will return true if a property is off. This can be
     * because the property is set to nothing or that it has a
     * boolean
     * @param key the key of hte property to lookup
     * @return boolean true if the feature is off
     */
    isOff(key) {
        return !this.isOn(key);
    }
    /**
     * This is used to load generic properties of a specific type.
     * Using the generic will make the method convert to that type
     * @param key the key of the property to lookup
     * @returns the value of that propery or null
     */
    getConfig(key, type = "any") {
        const prop = this.getProperty(key, type);
        if (prop) {
            return prop;
        }
        return null;
    }
}

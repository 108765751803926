import { Templator } from "../../handlers";
import { lineItemToBulkServiceLineItem } from "../../../types";
import axios from "axios";
export class Templates extends Templator {
    constructor() {
        super(...arguments);
        this.createTemplate = async (template) => {
            let lineItems = template.data.lineItems.map((item) => {
                return { ...lineItemToBulkServiceLineItem(item), campaign_name: item.parentName };
            });
            if (template.data?.creatives.length) {
                // mapping over the lineItems to add a name property and adjust weight logic for the template
                lineItems = lineItems.map((item) => {
                    return {
                        ...item,
                        creatives: item.creatives?.map((creative, i) => {
                            return {
                                ...creative,
                                weight: 
                                // if creative hasn't finished serving, default to 100, else multiply by 100
                                (template.data.creatives[i]?.endDate?.valueOf() || 0) > new Date().valueOf()
                                    ? 100
                                    : Math.floor(template.data.creatives[i]?.weight * 100),
                                name: template.data.creatives[i]?.name || ""
                            };
                        })
                    };
                });
            }
            return axios
                .post(`${this.sdk.urls.baseAPIUrl}/populateTemplate`, { data: lineItems }, {
                responseType: "arraybuffer",
                headers: {
                    Accept: "application/json"
                }
            })
                .then((res) => res.data);
        };
    }
}

/**
 * A hydrater knows how to hydrate a given item.
 * @param T: item type.
 * @param Fields: the fields that can be hydrated.
 */
export class Hydrater {
    constructor(sdk, config) {
        this.sdk = sdk;
        this.config = config || {};
    }
    /**
     * @param items: the items to hydrate.
     * @param fields: what fields to hydrate.
     * @return: an observable or promise which sends out subscription updates as the requested fields are hydrated.
     */
    hydrate(items, fields) {
        const allFields = new Set(fields);
        for (const field of this.config.requiredFields || []) {
            allFields.add(field);
        }
        return this.hydrateItems(items, [...allFields]);
    }
}

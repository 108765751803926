import { Icon, IconSize } from "@blueprintjs/core";
import { IconNames } from "@blueprintjs/icons";
import MenuItem from "frontier/lib/components/Menu/Item";
import { css } from "@emotion/react";
import { Flyout } from "frontier/lib/components/Flyout/index";
import { Menu } from "frontier/lib/components/Menu/Menu";
import { Routes } from "lib/constants/routes";
import { useSelector, useDispatch } from "react-redux";
import { selectCurrentUser, logoutUser } from "appReducers/authReducer";
import { madSDK } from "lib/sdk";
import { isLocalhost } from "lib/utils/env";
import { useNavigate } from "react-router-dom";
import { Button, ButtonVariant } from "madhive/components";
import { FC, useCallback, useState } from "react";

interface Props {
  disabled?: boolean;
  colorOverride: string | undefined;
}

const styles = {
  noPadding: css`
    padding: 0;
  `,
  fullWidthMenuItem: css`
    width: 100%;
  `,
  userEmail: css`
    min-width: 300px;
    margin-bottom: 5px;
    background: var(--subtle-gray);
    pointer-events: none;
    user-select: 
    &:focus {
      outline: none;
    }
    > * {
      user-select: auto;
    }
  `,
  preferenceBtn: css`
    align-items: center;
    display: flex;
    color: black;
  `
};

const ProfileButton: FC<Props> = props => {
  const { disabled, colorOverride } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onLogout = useCallback(
    () => dispatch(logoutUser(navigate)),
    [dispatch]
  );
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  const user = useSelector(selectCurrentUser);

  const handleLogoutClick = async () => {
    if (onLogout) {
      onLogout();
    }

    await madSDK.authentication.signOut();

    if (isLocalhost) {
      navigate(Routes.LOGIN_LOCAL);
    } else {
      navigate(Routes.LOGIN);
    }
  };

  return (
    <>
      {user ? (
        <Flyout
          isOpen={menuIsOpen}
          onClose={() => setMenuIsOpen(false)}
          offset="15px,-1px"
          position="bottom-right"
          target={
            <Button
              variant={ButtonVariant.ICON}
              icon={
                <Icon
                  icon={IconNames.USER}
                  iconSize={IconSize.LARGE}
                  color={colorOverride}
                />
              }
              onClick={() => setMenuIsOpen(true)}
              aria-haspopup="true"
              aria-label="user profile"
              data-testid="profile-button"
              disabled={disabled}
            />
          }
        >
          <Menu css={styles.noPadding}>
            <MenuItem
              text={user.email}
              shouldDismissPopover={false}
              icon={IconNames.USER}
              css={[styles.userEmail]}
              tabIndex={-1}
            />
            <MenuItem
              data-testid="user-profile-link"
              text="Preferences"
              tagName="a"
              to={Routes.USER_PROFILE}
              icon={IconNames.COG}
              css={[styles.fullWidthMenuItem, styles.preferenceBtn]}
            />
            <MenuItem
              data-testid="logout-button"
              text="Logout"
              icon={IconNames.LOG_OUT}
              css={styles.fullWidthMenuItem}
              onClick={handleLogoutClick}
            />
          </Menu>
        </Flyout>
      ) : null}
    </>
  );
};

export default ProfileButton;

/**
 * @param thing: any thing.
 * @param options
 * 1. fallback: the value to use if the thing cannot be pleasantly stringified (it's undefined, it's a function, a symbol, etc.).
 * 2. escapeDoubleQuotes: A CSV should use an escape quote inside fields that are wrapped in double quotes, https://www.rfc-editor.org/rfc/rfc4180.
 * However, there are cases where users directly copy & paste from our CSV cells, and we don't want wrapped double quotes (ex: tracking pixel javascript and html codes)
 * @returns: a CSV-friendly stringified version of that thing.
 */
export const stringify = (thing, options) => {
    const { fallback = "", escapeDoubleQuotes = true } = options || {};
    if (typeof thing === "undefined" || typeof thing === "symbol" || typeof thing === "function") {
        return fallback;
    }
    let stringified = typeof thing === "string" ? thing : JSON.stringify(thing);
    // it's best practice for csv's to use "" instead of " to represent a pair of quotes you want to show
    if (escapeDoubleQuotes) {
        stringified = stringified.replace(/"/g, '""');
    }
    // if we have commas in our fields, we need to wrap it in double quotes to have that comma count as a display item, and not separators
    return /,/g.test(stringified) ? `"${stringified}"` : stringified;
};

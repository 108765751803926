import { RemoteConfigProperties } from "@madhive/mad-sdk";
import StackdriverErrorReporter from "stackdriver-errors-js";

import { __DEV__ } from "lib/constants/config";
import { madSDK } from "lib/sdk";

import { logErrorToStackdriver } from "./logErrors";

const errorHandler = new StackdriverErrorReporter();

errorHandler.start({
  // we don't need key or project id here since we use the customReportingFunction, but typescript doesn't seem to know this.
  // if we were logging directly to the Error Reporting API then we would need these and not the customReportingFunction
  key: "",
  projectId: "",
  customReportingFunction: payload => {
    return new Promise(resolve => {
      const isDev =
        __DEV__ ||
        document.location.host === "127.0.0.1" ||
        document.location.host === "localhost";

      if (isDev) {
        // eslint-disable-next-line no-console
        console.error(payload.message);
      } else if (
        madSDK.featureFlags.isFlagEnabled(
          RemoteConfigProperties.UNHANDLED_ERROR_LOGGING
        )
      ) {
        logErrorToStackdriver(payload.message);
      }

      resolve();
    });
  }
});

/**
 * Report a single error to the error reporting service
 * @param error {Error|String} err - The Error object or message string to report.
 * @returns
 */
export const reportError = (error: Error | string) =>
  errorHandler.report(error);

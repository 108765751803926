import deepEqual from "deep-equal";
import { useEffect, useRef, useMemo } from "react";
const useMemoizeDeps = (dependencies) => {
    const ref = useRef(dependencies);
    const signalRef = useRef(0);
    if (!deepEqual(dependencies, ref.current)) {
        ref.current = dependencies;
        signalRef.current += 1;
    }
    return useMemo(() => ref.current, [signalRef.current]);
};
/**
 * A wrapper around React's useEffect hook that re-renders based on deep equality instead of reference. Avoid using on large objects.
 * @param cb Coneventional useEffect callback function; returns cleanup function or void/undefined
 * @param dependencies Array of useEffect dependencies
 * @returns void
 */
export const useDeepCompareEffect = (cb, dependencies) => useEffect(cb, useMemoizeDeps(dependencies));

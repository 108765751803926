import { LookupMethod } from "../types";

/**
 * The placeholder text to display when no option is selected.
 */
export const EMPTY_SELECT_TEXT = "- Select -";

/**
 * Find the width of the longest option in a list of options.
 */
export const findLengthOfLongestOption = (
  options: any[],
  lookupLabel: LookupMethod<string>
) => {
  let longest = 0;
  for (let i = 0; i < options.length; i++) {
    const label = lookupLabel(options[i]);
    if (label && label.length > longest) {
      longest = label.length;
    }
  }
  return longest;
};

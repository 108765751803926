import { RootState } from "rootReducer";
import { createSelector } from "reselect";
import { LineItemGeoTargetingState } from "./types";
import { formatLineItemGeoTargetingForNNY } from "./utils";

export const selectIsLineItemGeoTargetingMappedToState = (state: RootState) =>
  state.lineItemGeoTargeting.isLineItemTargetingMappedToState;

export const selectLineItemGeoTargetingEditState = (state: RootState) =>
  state.lineItemGeoTargeting;

export const selectLineItemGeoTargetingCountry = (state: RootState) =>
  state.lineItemGeoTargeting.currentCountry;

export const selectZipCodesYieldedByLineitemGeographicTargeting = (
  state: RootState
) => state.lineItemGeoTargeting.targetingTranslatedToZipCodes;

export const selectTargetingAsLineItemTargetsFormat = createSelector(
  selectLineItemGeoTargetingEditState,
  (results: LineItemGeoTargetingState) =>
    formatLineItemGeoTargetingForNNY(results)
);

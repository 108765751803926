import { KnownOrganizationNames, LinearDimensionList } from "@madhive/mad-sdk";

import { DimensionOption } from "features/CustomReporting/types";

/**
 * These are default dimensions for linear reports.
 * These will only be used if the given user/org has no value configured for the relevant `Dimension`.
 *
 * @deprecated: long-term, this live within the UX config system.
 */
export const DEFAULT_LINEAR_DIMENSIONS = [
  LinearDimensionList.NETWORK,
  LinearDimensionList.PROGRAM
];

/** All linear dimensions that exist. */
export const LINEAR_DIMENSIONS: DimensionOption[] = [
  {
    name: "Networks",
    id: LinearDimensionList.NETWORK
  },
  {
    name: "Programs",
    id: LinearDimensionList.PROGRAM
  },
  {
    name: "Day of the Week",
    id: LinearDimensionList.DAY_OF_THE_WEEK
  },
  {
    name: "DMA",
    id: LinearDimensionList.DMA
  },
  {
    name: "Dayparts",
    id: LinearDimensionList.DAYPART
  },
  {
    name: "Creatives",
    id: LinearDimensionList.CREATIVE
  },
  {
    name: "Daily",
    id: LinearDimensionList.DAILY
  },
  {
    name: "Weekly",
    id: LinearDimensionList.WEEKLY
  },
  {
    name: "Monthly",
    id: LinearDimensionList.MONTHLY
  },
  {
    name: "Hours of the Week",
    id: LinearDimensionList.HOUR_OF_THE_WEEK
  },
  {
    name: "Hourly",
    id: LinearDimensionList.HOURLY
  }
];

/** Default static options for the linear dimension selector. These defaults are true for *most* orgs. */
export const DEFAULT_STATIC_LINEAR_DIMENSIONS = [
  LinearDimensionList.NETWORK,
  LinearDimensionList.PROGRAM
];

export const STATIC_LINEAR_DIMENSIONS_BY_ORGANIZATION = new Map<
  KnownOrganizationNames,
  string[]
>([[KnownOrganizationNames.IHEART, [LinearDimensionList.PROGRAM]]]);

export const INITIAL_LINEAR_DIMENSIONS_BY_ORGANIZATION: Map<
  KnownOrganizationNames,
  string[]
> = new Map([
  [
    KnownOrganizationNames.SCRIPPS,
    [
      LinearDimensionList.NETWORK,
      LinearDimensionList.PROGRAM,
      LinearDimensionList.CREATIVE,
      LinearDimensionList.MONTHLY,
      LinearDimensionList.DAYPART
    ]
  ],
  [KnownOrganizationNames.IHEART, [LinearDimensionList.PROGRAM]]
]);

export const INITIAL_LINEAR_MULTI_DIMENSIONS_BY_ORGANIZATION: Map<
  KnownOrganizationNames,
  string[][]
> = new Map([
  [
    KnownOrganizationNames.SCRIPPS,
    [
      [
        LinearDimensionList.DAILY,
        LinearDimensionList.NETWORK,
        LinearDimensionList.PROGRAM
      ],
      [
        LinearDimensionList.CREATIVE,
        LinearDimensionList.NETWORK,
        LinearDimensionList.PROGRAM
      ],
      [
        LinearDimensionList.DAILY,
        LinearDimensionList.CREATIVE,
        LinearDimensionList.PROGRAM
      ],
      [LinearDimensionList.NETWORK, LinearDimensionList.DMA]
    ]
  ]
]);

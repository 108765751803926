export class AdvancedExportsSchedulesCouldNotFindError extends Error {
    constructor() {
        super();
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, AdvancedExportsSchedulesCouldNotFindError);
        }
        this.name = "AdvancedExportsSchedulesCouldNotFindError";
        this.message =
            "Failed to find a report schedule. The request requires a filter with an id value.";
    }
}

import axios from "axios";
import { Handler } from "../handlers";
import { serviceTeamToTeam, teamToServiceTeam } from "../../models/team";
import { parseIdFilter } from "../handlers/filter";
import { NotImplementedError } from "../../errors";
import { TeamsFetchFailed } from "./errors";
/**
 * Handles teams collections find and management.
 */
class Teams extends Handler {
    constructor(sdk) {
        super(sdk, "teams", { atomize: true });
    }
    async findItems(filters) {
        const url = `${this.sdk.urls.baseAPIUrl}/teams`;
        // teams does not support filtering by ID on the API side
        // so, fetch all teams, and cache them
        const teams = await this.cache.promise(url, () => axios
            .get(url, {
            headers: {
                "Content-Type": "application/json"
            }
        })
            .then(({ data: res }) => {
            return res.data.map((team) => this.toTeam(team));
        })
            .catch(() => {
            throw new TeamsFetchFailed();
        }));
        const idFilter = parseIdFilter(filters);
        return idFilter.size === 0 ? teams : teams.filter((team) => idFilter.has(team.id));
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async saveItem(team) {
        throw new NotImplementedError("save");
    }
    /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
    async deleteItem(id) {
        throw new NotImplementedError("delete");
    }
    toTeam(team) {
        return serviceTeamToTeam(team);
    }
    fromTeam(team) {
        return teamToServiceTeam(team);
    }
}
export default Teams;

import { objectLookup, toStartCase } from "../../utils";
import { stringify } from "../../utils/csv";
/**
 * The Audit base model allows users to have an easy and straight-forward way of turning their audit data into a CSV.
 */
export class Audit extends Array {
    /**
     * @param config:
     * @param items: the data contained within the audit.
     * @param headers: what headers to use for CSV calls.
     * @param name: the aname of the audit.
     */
    constructor(config) {
        const items = config?.items || [];
        const headers = config?.headers || [];
        super(...items);
        this.headers = headers;
        if (config?.name) {
            this.name = config.name;
        }
        else {
            const today = new Date();
            this.name = `Audit-${today.toLocaleDateString()} ${today.toLocaleTimeString()}`;
        }
    }
    /**
     * @param headers: the headers to use for generating the CSV. Defaults to the ones provided at construction time.
     * @return: an array, where each entry corresponds to a row of the CSV version of the audit report (including the header as the first entry).
     */
    csv(headers = this.headers) {
        if (headers.length === 0) {
            return Promise.resolve([]);
        }
        // the first element in the rows array should be the CSV's headers - which is why instantiate it like this here
        const rows = [this.getHeaderNames(headers).join(",")];
        for (const item of this.values()) {
            const row = [];
            for (const header of headers) {
                const { key, format = undefined } = typeof header == "string" ? { key: header } : header;
                const value = objectLookup(item, key);
                let entry = "";
                if (typeof format === "function") {
                    const formatted = format(value);
                    entry =
                        formatted instanceof Promise
                            ? formatted.then((result) => {
                                return stringify(result);
                            })
                            : stringify(formatted);
                }
                else {
                    entry = stringify(value);
                }
                row.push(entry);
            }
            rows.push(Promise.all(row).then((entries) => entries.join(",")));
        }
        return Promise.all(rows);
    }
    /**
     * @param headers: a list of headers.
     * @return: an array of the various headers - either using the provided .name field, or the keys converted to start case.
     */
    getHeaderNames(headers) {
        const names = [];
        for (const header of headers) {
            const name = typeof header === "string" ? toStartCase(header) : header.name || toStartCase(header.key);
            names.push(name);
        }
        return names;
    }
}

import { HOURS } from "./constants";
import { DayOfTheWeek, Daypart } from "./types";

/**
 * whether all hours are selected for a given daypart(s).
 * @param dayparts - array of dayparts
 * @param day
 * - if provided, only checks if all hours are selected for that day.
 * - if not provided, checks if all hours are selected for all days.
 * @returns boolean
 */
export const hasAllDaypartsSelected = (
  dayparts: Daypart[] = [],
  day?: DayOfTheWeek
) => {
  if (day) {
    const daypart = dayparts.find(d => d.day === day);
    return daypart?.hours.length === HOURS.length;
  }
  return dayparts.flatMap(d => d.hours).length === HOURS.length * 7;
};

/**
 * whether no hours are selected for a given daypart(s).
 * @param dayparts - array of dayparts
 * @param day
 * - if provided, only checks if all hours are selected for that day.
 * - if not provided, checks if all hours are selected for all days.
 * @returns boolean
 */
export const hasNoDaypartsSelected = (
  dayparts: Daypart[] = [],
  day?: DayOfTheWeek
) => {
  if (day) {
    const daypart = dayparts.find(d => d.day === day);
    return daypart?.hours.length === HOURS.length;
  }
  return dayparts.flatMap(d => d.hours).length === 0;
};

/**
 * @param rangeLength: the length of the range.
 * @return: an array representing the range [0, @rangeLength). Ex: makeRange(3) => [0, 1, 2].
 */
export const makeRange = (rangeLength: number): number[] =>
  Array.from(Array(rangeLength).keys());

/**
 * @param dayparts: a set of dayparts.
 * @return: the mirror opposite of that daypart set. Ex: input: [{monday, hours: [0-22]}]; output: [{monday, hours: [23]}, (...all other days with full hours)]
 */
export const invertDayparts = (dayparts: Daypart[]): Daypart[] => {
  const emptyDaypartsObject = {
    Sunday: { day: DayOfTheWeek.SUNDAY, hours: [] },
    Monday: { day: DayOfTheWeek.MONDAY, hours: [] },
    Tuesday: { day: DayOfTheWeek.TUESDAY, hours: [] },
    Wednesday: { day: DayOfTheWeek.WEDNESDAY, hours: [] },
    Thursday: { day: DayOfTheWeek.THURSDAY, hours: [] },
    Friday: { day: DayOfTheWeek.FRIDAY, hours: [] },
    Saturday: { day: DayOfTheWeek.SATURDAY, hours: [] }
  };

  /**
   * If the user only selected hours on certain days, they won't appear in the
   * array passed into this function. So we construct an array with default values for every day */
  const daypartsWithAllDays: Daypart[] = Object.values(
    dayparts.reduce((filledDaypartsObject, daypart) => {
      return {
        ...filledDaypartsObject,
        [daypart.day]: daypart
      };
    }, emptyDaypartsObject)
  );

  const rangeOfHoursInADay = makeRange(24);
  // invert hours, and exclude any days whose inverted hour count leaves them with 0 hours.
  const invertedDayparts: Daypart[] = daypartsWithAllDays.reduce(
    (invertedDaypartAcc: Daypart[], daypart: Daypart) => {
      const originalSelectedHours = new Set(daypart.hours);
      const invertedHours = rangeOfHoursInADay.filter(
        hour => !originalSelectedHours.has(hour)
      );

      if (invertedHours.length > 0) {
        const invertedDaypart = { day: daypart.day, hours: invertedHours };
        invertedDaypartAcc.push(invertedDaypart);
      }

      return invertedDaypartAcc;
    },
    []
  );

  return invertedDayparts;
};

import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const FlagWithCheck: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-flag-with-check"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.99658 3.63031C4.4108 3.63031 4.74658 3.9661 4.74658 4.38031V20.8381C4.74658 21.2524 4.4108 21.5881 3.99658 21.5881C3.58237 21.5881 3.24658 21.2524 3.24658 20.8381V4.38031C3.24658 3.9661 3.58237 3.63031 3.99658 3.63031Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1164 8.52304C14.4094 8.81586 14.4095 9.29073 14.1167 9.5837L11.9294 11.7721C11.6367 12.0651 11.1619 12.0652 10.8689 11.7725L9.55465 10.4594C9.26163 10.1667 9.26142 9.69181 9.55418 9.39879C9.84694 9.10576 10.3218 9.10555 10.6148 9.39831L11.3986 10.1814L13.0557 8.52333C13.3485 8.23036 13.8234 8.23023 14.1164 8.52304Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.74658 4.7093C4.81698 4.6751 4.897 4.63773 4.98594 4.59841C5.38542 4.42185 5.95822 4.20927 6.64465 4.06785C8.01453 3.7856 9.8069 3.79038 11.6067 4.89857C14.671 6.78539 17.7123 6.05157 19.2532 5.44643V15.793C19.195 15.8162 19.1309 15.8409 19.0613 15.8665C18.6636 16.0128 18.0911 16.1863 17.4025 16.2905C16.0272 16.4988 14.2134 16.4289 12.3834 15.3399C10.1474 14.0093 7.92919 14.0974 6.30327 14.5034C5.69884 14.6544 5.17126 14.8501 4.74658 15.0366V4.7093ZM4.41754 16.8995C4.18784 17.0552 3.89087 17.0714 3.64558 16.9415C3.4001 16.8115 3.24658 16.5565 3.24658 16.2787V4.25992C3.24658 4.00023 3.38092 3.75902 3.6017 3.62229L3.99658 4.25992C3.6017 3.62229 3.6017 3.62229 3.6017 3.62229L3.60301 3.62149L3.60468 3.62045L3.60915 3.61772L3.62248 3.60967L3.66646 3.58378C3.70335 3.56242 3.75529 3.53309 3.8213 3.49768C3.95322 3.4269 4.14192 3.33147 4.37955 3.22645C4.85364 3.01691 5.52919 2.76616 6.34195 2.5987C7.97044 2.26318 10.1797 2.25841 12.3931 3.62128C14.9022 5.16618 17.4123 4.56598 18.7451 4.03436C19.028 3.92151 19.2506 3.81435 19.3996 3.73717C19.474 3.69863 19.5297 3.66771 19.5652 3.64749C19.5829 3.63739 19.5955 3.62998 19.6027 3.62565L19.6084 3.62229C19.8397 3.47913 20.1305 3.47233 20.3682 3.60469C20.6059 3.73708 20.7532 3.98783 20.7532 4.25992V16.2787C20.7532 16.5562 20.6 16.811 20.355 16.9412L20.0032 16.2787C20.355 16.9412 20.355 16.9412 20.355 16.9412L20.3519 16.9428L20.3474 16.9451L20.334 16.9521L20.2899 16.9742C20.253 16.9925 20.2012 17.0174 20.1353 17.0473C20.0037 17.1072 19.8157 17.1873 19.5791 17.2743C19.1067 17.4481 18.435 17.6513 17.6271 17.7736C16.0096 18.0186 13.8217 17.9413 11.6164 16.6289C9.81713 15.5583 8.03366 15.6173 6.66674 15.9587C5.98198 16.1298 5.41084 16.3701 5.01216 16.5673C4.81346 16.6656 4.65941 16.7523 4.55753 16.8127C4.50664 16.8429 4.46892 16.8664 4.44532 16.8815L4.42069 16.8974L4.41754 16.8995Z"
      />
    </svg>
  );
};

export default FlagWithCheck;

import {
  DashboardReportsState,
  DashboardReportActions,
  PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_PENDING,
  PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_SUCCESS,
  PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_FAILURE,
  PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_FAILURE,
  PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_SUCCESS,
  PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_PENDING
} from "./types";

export const dashboardReportsInitialState: DashboardReportsState = {
  isProcessingRequest: false,
  error: null
};

export const dashboardReportsReducer = (
  state = dashboardReportsInitialState,
  action: DashboardReportActions
): DashboardReportsState => {
  switch (action.type) {
    case PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_PENDING:
    case PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_PENDING:
      return {
        ...state,
        isProcessingRequest: true,
        error: null
      };
    case PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_SUCCESS:
    case PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_SUCCESS:
      return {
        ...state,
        isProcessingRequest: false,
        error: null
      };
    case PROCESS_LINE_ITEM_ONLY_REPORT_REQUEST_FAILURE:
    case PROCESS_LINE_ITEM_AND_DAY_REPORT_REQUEST_FAILURE:
      return {
        ...state,
        isProcessingRequest: false,
        error: action.meta.error
      };

    default:
      return state;
  }
};

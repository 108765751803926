import { FC } from "react";
import { CommonIconProps } from "../Icon/types";

const Megaphone: FC<CommonIconProps> = ({
  fill = "var(--icon-fill-color, var(--color-primary))",
  stroke = "var(--icon-stroke-color, none)"
}) => {
  return (
    <svg
      className="icon-megaphone"
      width="100%"
      height="100%"
      viewBox="0 0 24 24"
      fill={fill}
      stroke={stroke}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.87668 13.0222C7.28525 12.9541 7.67168 13.2301 7.73979 13.6387L8.55579 18.5337C8.67362 19.2426 9.28651 19.762 10.005 19.762C10.9124 19.762 11.6031 18.9473 11.4542 18.0512L10.7602 13.8852C10.6921 13.4766 10.9682 13.0902 11.3768 13.0222C11.7853 12.9541 12.1717 13.2302 12.2398 13.6387L12.9338 17.8047C13.2348 19.6146 11.8396 21.262 10.005 21.262C8.55361 21.262 7.31453 20.2132 7.07621 18.7803L6.26021 13.8853C6.1921 13.4767 6.4681 13.0903 6.87668 13.0222Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.875 7.76198C4.42521 7.76198 3.25 8.93719 3.25 10.387C3.25 11.8368 4.42521 13.012 5.875 13.012H13.75C14.1642 13.012 14.5 13.3478 14.5 13.762C14.5 14.1762 14.1642 14.512 13.75 14.512H5.875C3.59679 14.512 1.75 12.6652 1.75 10.387C1.75 8.10877 3.59679 6.26198 5.875 6.26198H13.75C14.1642 6.26198 14.5 6.59777 14.5 7.01198C14.5 7.42619 14.1642 7.76198 13.75 7.76198H5.875Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.1666 4.30227L14.166 7.63602C13.8214 7.86578 13.3557 7.77265 13.126 7.42801C12.8962 7.08336 12.9893 6.61771 13.334 6.38794L18.335 3.05394C19.5815 2.22404 21.25 3.11726 21.25 4.61498V16.16C21.25 17.6589 19.5809 18.5495 18.3354 17.7203L13.334 14.386C12.9893 14.1563 12.8962 13.6906 13.126 13.346C13.3557 13.0013 13.8214 12.9082 14.166 13.1379L19.1666 16.4717C19.4171 16.6382 19.75 16.459 19.75 16.16V4.61498C19.75 4.31475 19.4161 4.13632 19.1666 4.30227Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.75 6.25998C14.1642 6.25998 14.5 6.59577 14.5 7.00998V13.76C14.5 14.1742 14.1642 14.51 13.75 14.51C13.3358 14.51 13 14.1742 13 13.76V7.00998C13 6.59577 13.3358 6.25998 13.75 6.25998Z"
      />
    </svg>
  );
};

export default Megaphone;
